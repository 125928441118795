import { takeEvery, put, call, all } from 'redux-saga/effects';
import { manageLevelActionType } from '../actions/actionTypes';

import { processError } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';

export function* getMngLevelList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(manageLevelActionType.RESET_SERVER_VALUE, null)),
    put(getAction(manageLevelActionType.LOG_MNG_LEVEL_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const lang = action.data === 'en' ? 'E' : 'F';
    const manageLevelInitURL = moduleAPI.ADMINISTRATION_MNG_LEVEL_LIST.endpoint;
    const manageLevelUpdateURL = moduleAPI.ADMINISTRATION_MNG_LEVEL_LIST.endpoint + lang;
    moduleAPI.ADMINISTRATION_MNG_LEVEL_LIST.endpoint = manageLevelUpdateURL;
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_LEVEL_LIST, lang);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_MNG_LEVEL_LIST.endpoint = manageLevelInitURL;
    yield all([
      put(getAction(manageLevelActionType.GET_MNG_LEVEL_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageLevelActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(manageLevelActionType.GET_MNG_LEVEL_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
// manage level
  yield takeEvery(manageLevelActionType.GET_MNG_LEVEL_LIST_REQUEST, getMngLevelList);
}
export default moduleWatcher;
