/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/**
 * @Description This is FasatGridBar container page
 * @FileName fasatGridBar.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 25 January, 2021 18:32:32
 * @IssueID CDTFASATMOD-502
 */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton, colors, Icon } from '@manulife/mux';
import { ButtonCheckmarkOutlined, Coin, Delete, Edit, Export, FundsSwitches, Import, Reload,
  RollOverFunds, Write, Email, ButtonXOutlined, Download, Print, Eye1, Eye2, ArrowLeft, Duplicate } from '@manulife/mux-cds-icons';
import FasatDropdown from '../fasatDropdown/fasatDropdown';
import '../../../../index.scss';
import './fasatGridBar.scss';
import FasatChip from '../fasatChip/fasatChip';
import { globalConstants, staticCommonLabelKeys } from '../../moduleConstants';
import { getCurrentLocale } from '../../i18n';
import { formatOrder, getListRequest } from '../fasatFilterSetting/filterUtil';
import { getAction } from '../../../../stateManagement/reduxUtil';
import FasatRBAC from '../fasatRBAC/fasatRBAC';
import FasatCheckbox from '../fasatCheckbox/fasatCheckbox';
import UserContext from '../../../../userContext';
import { staticLabelKeys } from '../../../modules/compcal/moduleConstants';
import { setParam } from '../../util';

const privateCheckboxClass = 'privateCheckbox';
const { external } = globalConstants;
const externalUser = external;

/**
   * validate and return class for export icon
   * @param {bool} activExBtn
   * @param {object} color
   * @returns
   */
const icnExtCls = (activExBtn, color) => (!activExBtn ? color.dark_3_light_grey : color.m_green);
/**
   *validate and return class for hover export icon
   * @param {bool} actExBtn
   * @param {object} color
   * @returns
   */
const icnHvExtCls = (actExBtn, color) => (!actExBtn ? color.dark_3_light_grey : color.dark_2_green);
/**
   * validate and return class for reset icon
   * @param {bool} actExBtn
   * @param {object} color
   * @returns
   */
const reLdExtCls = (restEn, color) => (restEn ? color.m_green : color.dark_3_light_grey);
/**
   *validate and return class for hover reset icon
   * @param {bool} actExBtn
   * @param {object} color
   * @returns
   */
const reLdHvExtCls = (restEn, color) => (restEn ? color.dark_2_green : color.dark_3_light_grey);
/**
   * validate and return class for add icon
   * @param {bool} activExBtn
   * @param {object} color
   * @returns
   */
const icnAddCls = (activExBtn, color) => (activExBtn ? color.dark_3_light_grey : color.m_green);
/**
    *validate and return class for hover add icon
    * @param {bool} actExBtn
    * @param {object} color
    * @returns
    */
const icnHvAddCls = (actExBtn, color) => (actExBtn ? color.dark_3_light_grey : color.dark_2_green);

const formatListCol = ({ listColumnOrder, selectedFilter, isGroupActive }) => {
  const selListCol = listColumnOrder || selectedFilter.detail.columnOrders || selectedFilter.detail.sortOptions;
  if (isGroupActive && selListCol.indexOf('sumRecordCount') === -1) {
    selListCol.push('sumRecordCount');
  }
  return selListCol;
};

const setFltDetails = ({ formRequest, relationshipSortOrder, selectedSortOptions, selectedListColumnOrder,
  groupByParams, isGroupActive, isDistinct, relationshipParameter, isQuickSearchParam }) => {
  let fltDetls = {};
  if (formRequest?.parameters) {
    fltDetls = {
      parameters: formRequest?.parameters,
      sortOptions: relationshipSortOrder || selectedSortOptions,
      columnOrder: selectedListColumnOrder,
      groupByParameters: groupByParams,
      groupByActive: isGroupActive,
      isCurrentRate: formRequest?.isCurrentRate?.toString(),
      isNonBandRate: formRequest?.isNonBandRate?.toString(),
      isDistinct,
    };
  } else {
    fltDetls = {
      parameters: relationshipParameter || isQuickSearchParam,
      sortOptions: relationshipSortOrder || selectedSortOptions,
      columnOrder: selectedListColumnOrder,
      groupByParameters: groupByParams,
      groupByActive: isGroupActive,
      isCurrentRate: formRequest?.isCurrentRate?.toString(),
      isNonBandRate: formRequest?.isNonBandRate?.toString(),
      isDistinct,
    };
  }
  return fltDetls;
};

const onClickExport = ({ entityFilterConfig, listSortOptions, selectedFilter, listActive, emitExport, externalExport,
  listColumnOrder, relationshipParameter, listParamOptions, listGroupBy, relationshipSortOrder, locale, pageSize,
  exportFileName, dispatch, processingInProgress, isDistinctActive, quickSearchParam, isQuickSearchFilter,
  formRequest, currentPage }) => {
  if (entityFilterConfig.EXPORT_ACTION && !processingInProgress) {
    const selectedSortOptions = listSortOptions || selectedFilter.detail.sortOptions;
    const groupByParams = listGroupBy || selectedFilter.detail.groupByParameters;
    const isGroupActive = !!(groupByParams && groupByParams.length > 0);
    const selectedListColumnOrder = formatListCol({ listColumnOrder, selectedFilter, isGroupActive });
    const isDistinct = isDistinctActive || selectedFilter.detail?.isDistinct;
    const isQuickSearchParam = (isQuickSearchFilter === '1' ? quickSearchParam : listParamOptions);
    const reqParam = setParam(relationshipParameter || listParamOptions);
    const filterDetails = setFltDetails({ formRequest,
      relationshipSortOrder,
      selectedSortOptions,
      selectedListColumnOrder,
      groupByParams,
      isGroupActive,
      isDistinct,
      relationshipParameter: reqParam,
      isQuickSearchParam });
    const exportType = 'ALL';
    const reqListObject = {
      data: { ...getListRequest({ filterDetails, locale, exportType, listActive, customPageSize: pageSize, customCurrentPage: currentPage }) },
      columnOrders: formatOrder(selectedListColumnOrder),
      fileName: exportFileName,
      sheetName: '',
    };
    if (externalExport) {
      emitExport(reqListObject);
    } else {
      dispatch(getAction(entityFilterConfig.EXPORT_ACTION, reqListObject));
    }
  }
};

const showFasatChip = (defaultChipText) => (
  <FasatChip
    data={defaultChipText}
    hasCloseBtn={false}
    isDefaultChip
  />
);

const showResetActionBtn = (resetEnabled, onClickReset, resetLabel, resetAriaLabel) => (
  <ActionButton
    id="resetActionButton"
    disabled={!resetEnabled}
    ariaLabel={resetAriaLabel}
    icon={(
      <Reload
        color={reLdExtCls(resetEnabled, colors)}
        style={{ width: '20px', height: '20px', marginTop: '5px' }}
      />
          )}
    hoverIcon={(
      <Reload
        color={reLdHvExtCls(resetEnabled, colors)}
        style={{ width: '20px', height: '20px', marginTop: '5px' }}
      />
          )}
    onClick={() => onClickReset()}
  >
    {resetLabel}
  </ActionButton>
);

const showApproveActionBtn = (approveAclKey, approveEnabled, approveLabel, onClickApprove, rbacData) => (
  <FasatRBAC aclKey={approveAclKey} userClaim={rbacData}>
    <ActionButton
      id="approveActionButton"
      disabled={!approveEnabled}
      ariaLabel={approveLabel}
      icon={(
        <ButtonCheckmarkOutlined
          color={reLdExtCls(approveEnabled, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      hoverIcon={(
        <ButtonCheckmarkOutlined
          color={reLdHvExtCls(approveEnabled, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => onClickApprove()}
    >
      {approveLabel}
    </ActionButton>
  </FasatRBAC>
);

const showEditActionBtn = (editAclKey, rbacData, onClickEdit, t) => (
  <FasatRBAC aclKey={editAclKey} userClaim={rbacData}>
    <ActionButton
      id="ptEditBtn"
      ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_EDIT)}
      hoverIcon={<Edit color={colors.dark_2_green} />}
      icon={<Edit color={colors.m_green} />}
      onClick={() => onClickEdit()}
    >
      {t(staticCommonLabelKeys.COMMON_BUTTON_EDIT)}
    </ActionButton>
  </FasatRBAC>
);

const showReverseActionBtn = (reverseAclKey, activateReprocessButton, rbacData, onClickReverse, t) => (
  <FasatRBAC aclKey={reverseAclKey} userClaim={rbacData}>
    <ActionButton
      id="ptReverseBtn"
      disabled={!activateReprocessButton}
      ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_REVERSE)}
      icon={(
        <RollOverFunds
          color={icnExtCls(activateReprocessButton, colors)}
          style={{ width: '20px', height: '20px' }}
        />
)}
      hoverIcon={(
        <RollOverFunds
          color={icnHvExtCls(activateReprocessButton, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => onClickReverse()}
    >
      {t(staticCommonLabelKeys.COMMON_BUTTON_REVERSE)}
    </ActionButton>
  </FasatRBAC>
);

const showReprocessActionBtn = (reprocessAclKey, activateReprocessButton, rbacData, onClickReprocess, t) => (
  <FasatRBAC aclKey={reprocessAclKey} userClaim={rbacData}>
    <ActionButton
      id="ptReprocessBtn"
      disabled={!activateReprocessButton}
      ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_REPROCESS)}
      icon={(
        <FundsSwitches
          color={icnExtCls(activateReprocessButton, colors)}
          style={{ width: '20px', height: '20px' }}
        />
)}
      hoverIcon={(
        <FundsSwitches
          color={icnHvExtCls(activateReprocessButton, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => onClickReprocess()}
    >
      {t(staticCommonLabelKeys.COMMON_BUTTON_REPROCESS)}
    </ActionButton>
  </FasatRBAC>
);

const showBulkActionBtn = (reprocessAclKey, activateBulkButton, rbacData, onClickBulk, t) => (
  <FasatRBAC aclKey={reprocessAclKey} userClaim={rbacData}>
    <ActionButton
      id="ptBulkBtn"
      disabled={!activateBulkButton}
      ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_BULK)}
      icon={(
        <FundsSwitches
          color={icnExtCls(activateBulkButton, colors)}
          style={{ width: '20px', height: '20px' }}
        />
)}
      hoverIcon={(
        <FundsSwitches
          color={icnHvExtCls(activateBulkButton, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => onClickBulk()}
    >
      {t(staticCommonLabelKeys.COMMON_BUTTON_BULK_REPROCESS)}
    </ActionButton>
  </FasatRBAC>
);

const showProcessNowActionBtn = (processNowAclKey, activateAUPButton, rbacData, onClickProcessNow, t) => (
  <FasatRBAC aclKey={processNowAclKey} userClaim={rbacData}>
    <ActionButton
      id="ptReprocessBtn"
      disabled={!activateAUPButton}
      ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_PROCESS_NOW)}
      icon={(
        <Coin
          color={icnExtCls(activateAUPButton, colors)}
          style={{ width: '20px', height: '20px' }}
        />
)}
      hoverIcon={(
        <Coin
          color={icnHvExtCls(activateAUPButton, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => onClickProcessNow()}
    >
      {t(staticCommonLabelKeys.COMMON_BUTTON_PROCESS_NOW)}
    </ActionButton>
  </FasatRBAC>
);

const showChangeStatusActionBtn = (t, changeStatusAclKey, onClickChangeStatus,
  changeStatusLabel, changeStatusEnabled, rbacData) => (
    <FasatRBAC aclKey={changeStatusAclKey} userClaim={rbacData}>
      <ActionButton
        id="showChangeStatusActionBtn"
        disabled={!changeStatusEnabled}
        ariaLabel={t(changeStatusLabel)}
        icon={(<Write color={reLdExtCls(changeStatusEnabled, colors)} style={{ width: '20px', height: '20px' }} />)}
        hoverIcon={(
          <Write
            color={reLdHvExtCls(changeStatusEnabled, colors)}
            style={{ width: '20px', height: '20px' }}
          />
)}
        onClick={() => onClickChangeStatus()}
      >
        {t(changeStatusLabel)}
      </ActionButton>
    </FasatRBAC>
);

const showSelectLetterActionBtn = (t, selectLetterAclKey, onClickSelectLetter, selectLetterLabel,
  selectLetterEnabled, rbacData) => (
    <FasatRBAC aclKey={selectLetterAclKey} userClaim={rbacData}>
      <ActionButton
        id="showselectLetterActionBtn"
        disabled={!selectLetterEnabled}
        ariaLabel={t(selectLetterLabel)}
        icon={(<Email color={reLdExtCls(selectLetterEnabled, colors)} style={{ width: '20px', height: '20px' }} />)}
        hoverIcon={(
          <Email
            color={reLdHvExtCls(selectLetterEnabled, colors)}
            style={{ width: '20px', height: '20px' }}
          />
)}
        onClick={() => onClickSelectLetter()}
      >
        {t(selectLetterLabel)}
      </ActionButton>
    </FasatRBAC>
);

const showAddActionBtn = (addAclKey, rbacData, onClickAdd, t, addBtnAriaLabel, pdDisableAdd) => (
  <FasatRBAC aclKey={addAclKey} userClaim={rbacData}>
    <ActionButton
      ariaLabel={addBtnAriaLabel || t(staticCommonLabelKeys.COMMON_BUTTON_ADD)}
      disabled={pdDisableAdd}
       // icon={<Icon manulifeName="plus" fill={colors.m_green} />}
       // hoverIcon={<Icon manulifeName="plus" fill={colors.dark_2_green} />}
      icon={<Icon manulifeName="plus" fill={icnAddCls(pdDisableAdd, colors)} />}
      hoverIcon={<Icon manulifeName="plus" fill={icnHvAddCls(pdDisableAdd, colors)} />}
      onClick={() => onClickAdd()}
      id="addNewButton"
    >
      {t(staticCommonLabelKeys.COMMON_BUTTON_ADD)}
    </ActionButton>
  </FasatRBAC>
);
const showCloneActionBtn = (addAclKey, rbacData, onClickAdd, t, addBtnAriaLabel, pdDisableAdd) => (
  <FasatRBAC aclKey={addAclKey} userClaim={rbacData}>
    <ActionButton
      ariaLabel={addBtnAriaLabel || t(staticLabelKeys.SWB_HEADER)}
      disabled={pdDisableAdd}
       // icon={<Icon manulifeName="plus" fill={colors.m_green} />}
       // hoverIcon={<Icon manulifeName="plus" fill={colors.dark_2_green} />}
      // icon={<Icon manulifeName="plus" fill={icnAddCls(pdDisableAdd, colors)} />}
      // hoverIcon={<Icon manulifeName="plus" fill={icnHvAddCls(pdDisableAdd, colors)} />}
      icon={(
        <Duplicate
          color={icnAddCls(pdDisableAdd, colors)}
          style={{ width: '20px', height: '20px' }}
        />
)}
      hoverIcon={(
        <Duplicate
          color={icnHvAddCls(pdDisableAdd, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => onClickAdd()}
      id="addNewButton"
    >
      {t(staticLabelKeys.SWB_HEADER)}
    </ActionButton>
  </FasatRBAC>
);

const showDeleteActionBtn = (deleteAclKey, deleteEnabled, deleteLabel, onClickDelete, rbacData) => (
  <FasatRBAC aclKey={deleteAclKey} userClaim={rbacData}>
    <ActionButton
      id="deleteActionButton"
      disabled={!deleteEnabled}
      ariaLabel={deleteLabel}
      icon={(<Delete color={reLdExtCls(deleteEnabled, colors)} style={{ width: '20px', height: '20px' }} />)}
      hoverIcon={(<Delete color={reLdHvExtCls(deleteEnabled, colors)} style={{ width: '20px', height: '20px' }} />)}
      onClick={() => onClickDelete()}
    >
      {deleteLabel}
    </ActionButton>
  </FasatRBAC>
);

const showImportActionBtn = (importAclKey, importEnabled, importLabel, onClickImport, rbacData) => (
  <FasatRBAC aclKey={importAclKey} userClaim={rbacData}>
    <ActionButton
      id="importActionButton"
      disabled={!importEnabled}
      ariaLabel={importLabel}
      icon={(<Import color={reLdExtCls(importEnabled, colors)} style={{ width: '20px', height: '20px' }} />)}
      hoverIcon={(<Import color={reLdHvExtCls(importEnabled, colors)} style={{ width: '20px', height: '20px' }} />)}
      onClick={() => onClickImport()}
    >
      {importLabel}
    </ActionButton>
  </FasatRBAC>
);

const showExportActionBtn = ({ exportAclKey, rbacData, activateExportButton, t, listActive, emitExport, externalExport,
  entityFilterConfig, listSortOptions, selectedFilter, listColumnOrder, listParamOptions, listGroupBy, pageSize,
  relationshipSortOrder, locale, exportFileName, dispatch, relationshipParameter, processingInProgress,
  isDistinctActive, ffExport, quickSearchParam, isQuickSearchFilter, exportBtnID, formRequest, currentPage }) => (
    <FasatRBAC aclKey={exportAclKey} userClaim={rbacData}>
      <ActionButton
        id={exportBtnID}
        disabled={!activateExportButton}
        ariaLabel={t(staticCommonLabelKeys.ARIA_COMMON_BUTTON_EXPORT_LIST)}
        icon={<Export color={icnExtCls(activateExportButton, colors)} style={{ width: '20px', height: '20px' }} />}
        hoverIcon={(<Export color={icnHvExtCls(activateExportButton, colors)} style={{ width: '20px', height: '20px' }} />)}
        onClick={() => (!ffExport ? onClickExport({ entityFilterConfig,
          listSortOptions,
          selectedFilter,
          listColumnOrder,
          relationshipParameter,
          listParamOptions,
          listGroupBy,
          relationshipSortOrder,
          locale,
          exportFileName,
          dispatch,
          processingInProgress,
          listActive,
          isDistinctActive,
          emitExport,
          externalExport,
          quickSearchParam,
          isQuickSearchFilter,
          pageSize,
          formRequest,
          currentPage }) : ffExport())}
      >
        {t(staticCommonLabelKeys.COMMON_BUTTON_EXPORT)}
      </ActionButton>
    </FasatRBAC>
);

/**
* Handle Private click handle
* @param {boolean} val
*/
const privateClickHandler = (val, setIsPrivate, privateHandler) => {
  setIsPrivate(val);
  privateHandler(val);
};

const displayPageSizePart1 = (t) => (
  <span>
    {t(staticCommonLabelKeys.COMMON_LABEL_PAGESIZE_PART1)}
    &nbsp;
  </span>
);

const displayPageSizePart2 = (t) => (
  <span>
    &nbsp;
    {t(staticCommonLabelKeys.COMMON_LABEL_PAGESIZE_PART2)}
  </span>
);

const itemPerPage = ({ pageSizeOptions, onChangePageSize, pageSize, t, pageSizeID }) => (
  <span className="pageSizeDropdown">
    <FasatDropdown
      ariaLabel={t(staticCommonLabelKeys.COMMON_LABEL_PAGESIZE_PART1)}
      dropdownItems={pageSizeOptions}
      id={pageSizeID}
      onHandleChange={(val) => onChangePageSize(val)}
      placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
      value={`${pageSize}`}
      variant="basic"
      customStyle={{
        rootStyle: {
          width: 'auto',
        },
      }}
    />
  </span>
);
const showRemitPayment = (remitAclKey, approveEnabled, remitLabel, onClickRemit, rbacData) => (
  <FasatRBAC aclKey={remitAclKey} userClaim={rbacData}>
    <ActionButton
      id="approveRemitButton"
      disabled={!approveEnabled}
      ariaLabel={remitLabel}
      icon={(
        <Coin
          color={reLdExtCls(approveEnabled, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      hoverIcon={(
        <Coin
          color={reLdHvExtCls(approveEnabled, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => {
        onClickRemit();
      }}
    >
      {remitLabel}
    </ActionButton>
  </FasatRBAC>
);

const showDownloadButton = (downloadBtn, rbacData) => (
  <FasatRBAC aclKey={downloadBtn.downloadAclKey} userClaim={rbacData}>
    <ActionButton
      id="downloadActionButton"
      disabled={!downloadBtn.enableDownload}
      ariaLabel={downloadBtn.downloadLabel}
      icon={(
        <Download
          color={reLdExtCls(downloadBtn.enableDownload, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      hoverIcon={(
        <Download
          color={reLdHvExtCls(downloadBtn.enableDownload, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => {
        downloadBtn.onClickDownload();
      }}
    >
      {downloadBtn.downloadLabel}
    </ActionButton>
  </FasatRBAC>
);

const showPrintButton = (printBtn, rbacData) => (
  <FasatRBAC aclKey={printBtn.printAclKey} userClaim={rbacData}>
    <ActionButton
      id="printActionButton"
      disabled={!printBtn.enablePrint}
      ariaLabel={printBtn.printLabel}
      icon={(
        <Print
          color={reLdExtCls(printBtn.enablePrint, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      hoverIcon={(
        <Print
          color={reLdHvExtCls(printBtn.enablePrint, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => {
        printBtn.onClickPrint();
      }}
    >
      {printBtn.printLabel}
    </ActionButton>
  </FasatRBAC>
);

const showViewButton = (viewBtn, rbacData) => (
  <FasatRBAC aclKey={viewBtn.viewAclKey} userClaim={rbacData}>
    <ActionButton
      id="viewActionButton"
      disabled={!viewBtn.enableBtn}
      ariaLabel={viewBtn.label}
      icon={(
        <Eye1
          color={reLdExtCls(viewBtn.enableBtn, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      hoverIcon={(
        <Eye1
          color={reLdHvExtCls(viewBtn.enableBtn, colors)}
          style={{ width: '20px', height: '20px' }}
        />
            )}
      onClick={() => {
        viewBtn.onClick();
      }}
    >
      {viewBtn.label}
    </ActionButton>
  </FasatRBAC>
);

const checkOperation = ({ approveAclKey, approveAction, approveEnabled, approveLabel, onClickApprove, defaultChipText,
  resetAction, showDefaultChip, resetEnabled, onClickReset, resetLabel, rbacData, resetAriaLabel }) => (
    <>
      {approveAction && (showApproveActionBtn(approveAclKey, approveEnabled, approveLabel, onClickApprove, rbacData))}
      {showDefaultChip && (showFasatChip(defaultChipText))}
      {resetAction && (showResetActionBtn(resetEnabled, onClickReset, resetLabel, resetAriaLabel))}
    </>
);

const ButtonOperation = ({ downloadBtn, printBtn, rbacData, viewBtn }) => (
  <>
    {viewBtn && viewBtn.action && (showViewButton(viewBtn, rbacData))}
    {downloadBtn && downloadBtn.downloadAction && (showDownloadButton(downloadBtn, rbacData))}
    {printBtn && printBtn.printAction && (showPrintButton(printBtn, rbacData))}
  </>
);

const configPrivate = (sharedFilter, isPrivate, setIsPrivate) => {
  if (sharedFilter && isPrivate) {
    document.getElementById('isPrivate').click();
    setIsPrivate(false);
  }
};
const setDeleteEnabled = (cpStore) => (cpStore ? useSelector((state) => state[cpStore].enableDelete) : false);
const setDelRefEnbl = (deleteEnabled, cpStore) => ((deleteEnabled) || setDeleteEnabled(cpStore));
const setApproveEnabled = (cpStore) => (cpStore ? useSelector((state) => state[cpStore].enableApprove) : false);
const setDelRefLbl = (deleteLabel, t) => ((deleteLabel !== '')
  ? deleteLabel : t(staticCommonLabelKeys.COMMON_BUTTON_DELETE));

const getStateSelector = (defaultEntityFilterConfig, entityFilterConfig) => {
  const listSortOptions = defaultEntityFilterConfig?.listSortOptions || useSelector((state) => entityFilterConfig
   && state[entityFilterConfig.REDUX_STATE]?.listSortOptions);
  const listParamOptions = defaultEntityFilterConfig?.listParamOptions || useSelector((state) => entityFilterConfig
   && state[entityFilterConfig.REDUX_STATE]?.listParamOptions);
  const listColumnOrder = defaultEntityFilterConfig?.listColumnOrder || useSelector((state) => entityFilterConfig
   && state[entityFilterConfig.REDUX_STATE]?.listColumnOrder);
  const listGroupBy = defaultEntityFilterConfig?.listGroupBy || useSelector((state) => entityFilterConfig
   && state[entityFilterConfig.REDUX_STATE]?.listGroupBy);
  const isDistinctActive = defaultEntityFilterConfig?.isDistinctActive || useSelector((state) => entityFilterConfig
   && state[entityFilterConfig.REDUX_STATE]?.isDistinctActive);
  const selectedFilter = defaultEntityFilterConfig?.selectedFilter || useSelector((state) => entityFilterConfig
   && state[entityFilterConfig.REDUX_STATE]?.selectedFilter);
  const processingInProgress = useSelector(
    ({ ApplicationStateFilterList }) => ApplicationStateFilterList.processingInProgress,
  );
  return { listSortOptions,
    listParamOptions,
    listColumnOrder,
    listGroupBy,
    isDistinctActive,
    selectedFilter,
    processingInProgress,
  };
};
const showCancelActionBtn = (cancelEnabled, onClickCancel, t, cancelAclKey, rbacData) => (
  <FasatRBAC aclKey={cancelAclKey} userClaim={rbacData}>
    <ActionButton
      id="cancelActionButton"
      disabled={!cancelEnabled}
      ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_CANCEL)}
      icon={(
        <ButtonXOutlined
          color={reLdExtCls(cancelEnabled, colors)}
          style={{ width: '20px', height: '20px' }}
        />
              )}
      hoverIcon={(
        <ButtonXOutlined
          color={reLdHvExtCls(cancelEnabled, colors)}
          style={{ width: '20px', height: '20px' }}
        />
              )}
      onClick={() => {
        onClickCancel();
      }}
    >
      {t(staticCommonLabelKeys.COMMON_BUTTON_CANCEL)}
    </ActionButton>
  </FasatRBAC>
);
const BackButton = (backBtnParams, t) => {
  const { showBtn, onClick, label } = backBtnParams;
  return (
    <>
      {!!showBtn
      && (
      <ActionButton
        id="backBttn"
        ariaLabel={t(label)}
        icon={(
          <ArrowLeft
            color={colors.red_error}
            style={{ width: '20px', height: '20px' }}
          />
        )}
        hoverIcon={(
          <ArrowLeft
            color={reLdHvExtCls(true, colors)}
            style={{ width: '20px', height: '20px' }}
          />
        )}
        onClick={() => {
          onClick();
        }}
      >
        {t(label)}
      </ActionButton>
      )}
    </>
  );
};

const showPageSection = ({ showPageSize, t, pageSizeOptions, onChangePageSize, pageSize, pageSizeID, backBtnParams }) => (
  <div className="pageSizeSection">
    {backBtnParams && BackButton(backBtnParams, t)}
    {showPageSize && (
    <>
      {displayPageSizePart1(t)}
      {itemPerPage({ pageSizeOptions, onChangePageSize, pageSize, t, pageSizeID })}
      {displayPageSizePart2(t)}
    </>
    )}
  </div>
);

const ApprovePaymentBtn = ({ approvePayment, approveAclKey, approveEnabled, approveLabel, onClickApprove, rbacData, remitAclKey, remitLabel, onClickRemit, onClickCancel, t, cancelAclKey }) => (
  <>
    {approvePayment && (showApproveActionBtn(approveAclKey, approveEnabled, approveLabel, onClickApprove, rbacData))}
    {approvePayment && (showRemitPayment(remitAclKey, approveEnabled, remitLabel, onClickRemit, rbacData))}
    {approvePayment && (showCancelActionBtn(approveEnabled, onClickCancel, t, cancelAclKey, rbacData))}
  </>
);

const ActivityProcessBtn = ({ reverseAction, reprocessAction, processNowAction, reverseAclKey, activateReprocessButton, rbacData, onClickReverse, t, reprocessAclKey, onClickReprocess, processNowAclKey, activateAUPButton,
  onClickProcessNow,
  onClickBulk,
  bulkAction,
  activateBulkButton }) => (
    <>
      {reverseAction && (showReverseActionBtn(reverseAclKey, activateReprocessButton, rbacData, onClickReverse, t))}
      {reprocessAction && (showReprocessActionBtn(reprocessAclKey, activateReprocessButton,
        rbacData, onClickReprocess, t))}
      {processNowAction && (showProcessNowActionBtn(processNowAclKey, activateAUPButton,
        rbacData, onClickProcessNow, t))}
      {bulkAction && (showBulkActionBtn(reprocessAclKey, activateBulkButton, rbacData, onClickBulk, t))}
    </>
);

const TerminatedRecordButton = (terminatedRecordBtnParams, t) => {
  const { showBtn, onClick, showText, hideText, showTerminatedRecords } = terminatedRecordBtnParams;
  return (
    <>
      {showBtn && (
      <>
        {showTerminatedRecords && (
        <ActionButton
          id="showTerminatedRecordsActionButton"
          ariaLabel={t(hideText)}
          icon={(
            <Eye2
              color={reLdExtCls(true, colors)}
              style={{ width: '20px', height: '20px' }}
            />
              )}
          hoverIcon={(
            <Eye2
              color={reLdHvExtCls(true, colors)}
              style={{ width: '20px', height: '20px' }}
            />
              )}
          onClick={() => {
            onClick();
          }}
        >
          {t(hideText)}
        </ActionButton>
        )}
        {!showTerminatedRecords && (
        <ActionButton
          id="hideTerminatedRecordsActionButton"
          ariaLabel={t(showText)}
          icon={(
            <Eye1
              color={reLdExtCls(true, colors)}
              style={{ width: '20px', height: '20px' }}
            />
              )}
          hoverIcon={(
            <Eye1
              color={reLdHvExtCls(true, colors)}
              style={{ width: '20px', height: '20px' }}
            />
              )}
          onClick={() => {
            onClick();
          }}
        >
          {t(showText)}
        </ActionButton>
        )}
      </>
      )}
    </>
  );
};
const actionSection = ({ t, showPrivateCheckbox, userType, isPrivate, setIsPrivate, privateHandler }) => {
  if (showPrivateCheckbox && (userType !== externalUser)) {
    return (
      <div className={privateCheckboxClass}>
        <FasatCheckbox
          id="isPrivate"
          name="isPrivate"
          labelText={t(staticCommonLabelKeys.COMMON_LABEL_SHOW_PRIVATE)}
          onHandleChange={(val) => privateClickHandler(val, setIsPrivate, privateHandler)}
          defaultChecked={isPrivate}
        />
      </div>
    );
  }
  return (<></>);
};

const FasatGridBar = ({ addAction, exportAction, resetAction, resetEnabled, addBtnAriaLabel, cloneAction,
  onClickReset, onClickApprove, pageSize, onChangePageSize, pageSizeOptions, showDefaultChip, bulkAction,
  showPrivateCheckbox, approveAction, defaultChipText, activateExportButton, entityFilterConfig, onClickDelete,
  resetLabel, addAclKey, exportAclKey, privateHandler, resetButtonClick, onClickAdd, deleteAction, deleteEnabled,
  sharedFilter, relationshipParameter, relationshipSortOrder, exportFileName, approveLabel, deleteLabel,
  importAction, importEnabled, importLabel, onClickImport, listActive, showPageSize, cpStore, deleteAclKey,
  importAclKey, approveAclKey, remitAclKey, editAclKey, onClickEdit, editAction, pdDisableAdd, activateBulkButton,
  resetAriaLabel, reverseAction, reprocessAction, reverseAclKey, onClickReverse, reprocessAclKey, onClickReprocess,
  activateReprocessButton, ffExport, processNowAction, onClickProcessNow, defaultEntityFilterConfig,
  processNowAclKey, activateAUPButton, changeStatusAction, changeStatusAclKey, onClickChangeStatus,
  changeStatusLabel, changeStatusEnabled, selectLetterAclKey, onClickSelectLetter, selectLetterLabel,
  selectLetterEnabled, selectLetterAction, cancelAclKey, onClickCancel, onClickRemit, remitLabel, onClickBulk,
  approvePayment, downloadBtn, printBtn, viewBtn, externalExport, emitExport, quickSearchParam, isQuickSearchFilter,
  terminatedRecordBtnParams, backBtnParams, isExtrnalUser, pageSizeID, exportBtnID, formRequest, currentPage,
}) => {
  const rbacData = useSelector(({ ApplicationStateRBAC, CodeAndHierarchy }) => (isExtrnalUser
    ? CodeAndHierarchy?.levelRBAC
    : ApplicationStateRBAC.rbacData));
  const [isPrivate, setIsPrivate] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const locale = getCurrentLocale();
  const userType = useContext(UserContext);
  const { listSortOptions, listParamOptions, listColumnOrder, listGroupBy, isDistinctActive, selectedFilter,
    processingInProgress } = getStateSelector(defaultEntityFilterConfig, entityFilterConfig);
  /**
    * Format Role list for dropdown
    */
  useEffect(() => {
    if (!resetButtonClick && isPrivate) {
      setIsPrivate(false);
      document.getElementById('isPrivate').click();
    }
  }, [resetButtonClick]);
  /**
    * Check and Disable Private Field
    */
  useEffect(() => configPrivate(sharedFilter, isPrivate, setIsPrivate), [sharedFilter]);
  const approveEnabled = setApproveEnabled(cpStore);
  const chkOptPrms = { approveAclKey, approveAction, approveEnabled, approveLabel, onClickApprove, defaultChipText };
  const buttonParams = { downloadBtn, printBtn, rbacData, viewBtn };
  const deleteRefEnabled = setDelRefEnbl(deleteEnabled, cpStore);
  const deleteRefLabel = setDelRefLbl(deleteLabel, t);
  const paymentBtnParams = { approvePayment, approveAclKey, approveEnabled, approveLabel, onClickApprove, rbacData, remitAclKey, remitLabel, onClickRemit, onClickCancel, t, cancelAclKey };
  const activityProcessBtnParams = { reverseAction, reprocessAction, processNowAction, reverseAclKey, activateReprocessButton, rbacData, onClickReverse, t, reprocessAclKey, onClickReprocess, processNowAclKey, activateAUPButton, onClickProcessNow, onClickBulk, bulkAction, activateBulkButton };
  return (
    <div className="fasatGridBar" data-testid="FasatGridBarComponent">
      {showPageSection({ showPageSize, t, pageSizeOptions, onChangePageSize, pageSize, pageSizeID, backBtnParams })}
      <div className="actionSection">
        {actionSection({ t, showPrivateCheckbox, userType, isPrivate, setIsPrivate, privateHandler })}
        {checkOperation({ ...chkOptPrms,
          resetAction,
          showDefaultChip,
          resetEnabled,
          onClickReset,
          resetLabel,
          resetAriaLabel,
          rbacData })}
        {terminatedRecordBtnParams && TerminatedRecordButton(terminatedRecordBtnParams, t)}
        {ButtonOperation({ ...buttonParams })}
        {ApprovePaymentBtn({ ...paymentBtnParams })}
        {ActivityProcessBtn({ ...activityProcessBtnParams })}
        {editAction && (showEditActionBtn(editAclKey, rbacData, onClickEdit, t))}
        {addAction && (showAddActionBtn(addAclKey, rbacData, onClickAdd, t, addBtnAriaLabel, pdDisableAdd))}
        {cloneAction && (showCloneActionBtn(addAclKey, rbacData, onClickAdd, t, addBtnAriaLabel, pdDisableAdd))}
        {deleteAction && (showDeleteActionBtn(deleteAclKey, deleteRefEnabled, deleteRefLabel, onClickDelete, rbacData))}
        {importAction && (showImportActionBtn(importAclKey, importEnabled, importLabel, onClickImport, rbacData))}
        {changeStatusAction && (showChangeStatusActionBtn(t, changeStatusAclKey, onClickChangeStatus, changeStatusLabel,
          changeStatusEnabled, rbacData))}
        { selectLetterAction && (showSelectLetterActionBtn(t, selectLetterAclKey, onClickSelectLetter,
          selectLetterLabel,
          selectLetterEnabled, rbacData))}
        {exportAction && (
          showExportActionBtn({ exportAclKey,
            rbacData,
            activateExportButton,
            t,
            entityFilterConfig,
            listSortOptions,
            selectedFilter,
            listColumnOrder,
            listParamOptions,
            listGroupBy,
            relationshipSortOrder,
            locale,
            exportFileName,
            dispatch,
            relationshipParameter,
            processingInProgress,
            listActive,
            isDistinctActive,
            ffExport,
            emitExport,
            externalExport,
            quickSearchParam,
            isQuickSearchFilter,
            pageSize,
            exportBtnID,
            formRequest,
            currentPage })
        )}
      </div>
    </div>
  );
};

FasatGridBar.propTypes = {
  selectLetterAction: PropTypes.bool,
  changeStatusAction: PropTypes.bool,
  editAction: PropTypes.bool,
  addAction: PropTypes.bool,
  cloneAction: PropTypes.bool,
  exportAction: PropTypes.bool,
  resetAction: PropTypes.bool,
  resetEnabled: PropTypes.bool,
  showDefaultChip: PropTypes.bool,
  showPrivateCheckbox: PropTypes.bool,
  approveAction: PropTypes.bool,
  approvePayment: PropTypes.bool,
  deleteEnabled: PropTypes.bool,
  deleteAction: PropTypes.bool,
  showPageSize: PropTypes.bool,
  defaultChipText: PropTypes.string,
  approveLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
  pageSize: PropTypes.number,
  onClickAdd: PropTypes.func,
  onClickReset: PropTypes.func,
  onClickApprove: PropTypes.func,
  onClickRemit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onChangePageSize: PropTypes.func,
  emitExport: PropTypes.func,
  quickSearchParam: PropTypes.shape(),
  isQuickSearchFilter: PropTypes.string,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  activateExportButton: PropTypes.bool,
  resetLabel: PropTypes.string,
  resetAriaLabel: PropTypes.string,
  exportFileName: PropTypes.string,
  addBtnAriaLabel: PropTypes.string,
  cpStore: PropTypes.string, // Required for enabling approve and delete button
  pageSizeID: PropTypes.string,
  exportBtnID: PropTypes.string,
  formRequest: PropTypes.shape({}),
  currentPage: PropTypes.number,
};

FasatGridBar.defaultProps = {
  selectLetterAction: false,
  changeStatusAction: false,
  editAction: false,
  cloneAction: false,
  addAction: true,
  exportAction: true,
  resetAction: false,
  resetEnabled: true,
  showDefaultChip: false,
  showPrivateCheckbox: false,
  activateExportButton: false,
  approveAction: false,
  approvePayment: false,
  deleteAction: false,
  deleteEnabled: false,
  showPageSize: true,
  approveLabel: '',
  deleteLabel: '',
  pageSize: 0,
  defaultChipText: 'My default',
  onClickAdd: undefined,
  onClickReset: () => undefined,
  onClickApprove: () => undefined,
  onClickDelete: () => undefined,
  // pageSize: 10,
  onChangePageSize: () => undefined,
  onClickRemit: () => undefined,
  emitExport: () => undefined,
  quickSearchParam: [],
  isQuickSearchFilter: '',
  resetLabel: null,
  resetAriaLabel: null,
  exportFileName: null,
  pageSizeOptions: [
    {
      label: '10',
      value: '10',
    },
    {
      label: '20',
      value: '20',
    },
    {
      label: '50',
      value: '50',
    },
    {
      label: '100',
      value: '100',
    },
  ],
  addBtnAriaLabel: null,
  cpStore: null,
  pageSizeID: 'pageSize',
  exportBtnID: 'exportListButton',
  formRequest: {},
  currentPage: 1,
};

export default FasatGridBar;
