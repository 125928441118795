/**
 * @Description FasatColumnOrder language Shape Prototypes File
 * @FileName languageShape.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
import PropTypes from 'prop-types';

export default PropTypes.shape({
  moveLeft: PropTypes.string.isRequired,
  moveAllLeft: PropTypes.string.isRequired,
  moveRight: PropTypes.string.isRequired,
  moveAllRight: PropTypes.string.isRequired,

  // Optional properties
  availableFilterHeader: PropTypes.string,
  availableHeader: PropTypes.string,
  moveDown: PropTypes.string,
  moveUp: PropTypes.string,
  noAvailableOptions: PropTypes.string,
  noSelectedOptions: PropTypes.string,
  selectedFilterHeader: PropTypes.string,
  selectedHeader: PropTypes.string,
  moveTop: PropTypes.string,
  moveBottom: PropTypes.string,
});
