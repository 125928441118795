/**
 * @Description Constant for the module
 * @FileName moduleConstants.js
 * @Author PUT YOUR NAME
 * @CreatedOn 08 February, 2021 16:08:16
 * @IssueID Issue Detail
 */
const NS = 'static:';
const ROUTE_HOME = '/admin';

export const routeConstant = {
  HOME: `${ROUTE_HOME}/home`,
  SYS_CONFIG_ROUTE: `${ROUTE_HOME}/systemConfiguration`,
  FIELD_FORCE_ROUTE: `${ROUTE_HOME}/fieldforceStructure`,
  GEN_EDIT_ROUTE: `${ROUTE_HOME}/genericEdit`,
  PERIOD_DEFINITION_ROUTE: `${ROUTE_HOME}/periodDefinition`,
  SYSTEM_PARAMETER_ROUTE: `${ROUTE_HOME}/systemParameters`,
  SYSTEM_OPTIONS_ROUTE: `${ROUTE_HOME}/systemOptions`,
  NOTIFICATIONS_ROUTE: `${ROUTE_HOME}/notification`,
  PRODUCT_CONFIG_ROUTE: `${ROUTE_HOME}/productConfiguration`,
  PLAN_CODE_ROUTE: `${ROUTE_HOME}/planCode`,
  INTERFACE_MAPPING_ROUTE: `${ROUTE_HOME}/interfaceMapping`,
  TRANSACTION_DEFINITION_ROUTE: `${ROUTE_HOME}/transactionDefinition`,
  TRANSACTION_DEFINITION_DETAIL_ROUTE: `${ROUTE_HOME}/transactionDefinitionDetail`,
  TRANSACTION_DEFINITION_STEP_ROUTE: `${ROUTE_HOME}/transactionDefinitionStep`,
  PAYMENT_CONFIGURATION_ROUTE: `${ROUTE_HOME}/paymentConfiguration`,
  TRANSACTION_DEFINITION_SECDETAIL_STEP_ROUTE: `${ROUTE_HOME}/securityDetailStep`,
  TRANSACTION_DEFINITION_ASSOCTRANS_STEP_ROUTE: `${ROUTE_HOME}/associatedTransactionStep`,
  PAYMENT_THRESHOLD_ROUTE: `${ROUTE_HOME}/paymentThreshold`,
  CHEQUE_SYSTEM_ROUTE: `${ROUTE_HOME}/chequeSystem`,
  REPORTING_ROUTE: `${ROUTE_HOME}/reporting`,
  INCOME_EXPENSE_MAP_ROUTE: `${ROUTE_HOME}/incomeExpenseMap`,
  GENERIC_EDIT_RELATIONSHIP_ROUTE: `${ROUTE_HOME}/genEditRelation`,
  PRODUCER_AUDIT_TRAIL_ROUTE: `${ROUTE_HOME}/producerAuditTrail`,
  DATA_DICTIONARY_ROUTE: `${ROUTE_HOME}/dataDictionary`,
  BATCH_PROCESS_ROUTE: `${ROUTE_HOME}/batchProcess`,
  INTERFACE_SETUP_ROUTE: `${ROUTE_HOME}/interfaceSetup`,
  BATCH_MONITORING_ROUTE: `${ROUTE_HOME}/batchMonitoring`,
  SECURITY_ROUTE: `${ROUTE_HOME}/security`,
  CHEQUE_SECURITY_ROUTE: `${ROUTE_HOME}/chequeSecurity`,
  SECURITY_SETTINGS_ROUTE: `${ROUTE_HOME}/securitySettings`,
  GEN_EDIT_VAL_ROUTE: `${ROUTE_HOME}/geneditval`,
  GENERIC_EDIT_RELATIONSHIP_VAL_ROUTE: `${ROUTE_HOME}/genEditRelVal`,
  EDIT_SECURITY_SETUP: `${ROUTE_HOME}/editSecuritySetup`,
  BATCH_PROCESSING_ROUTE: `${ROUTE_HOME}/batchProcessing`,
  FIELD_FORCE_STRUCTURE_ROUTE: `${ROUTE_HOME}/ffStructure`,
  FIELD_FORCE_STRUCTURE_ROUTE_ADD: `${ROUTE_HOME}/ffStructure/add`,
  FIELD_FORCE_STRUCTURE_ROUTE_EDIT: `${ROUTE_HOME}/ffStructure/edit`,
  CONFIGURE_LETTERS_ROUTE: `${ROUTE_HOME}/configureLetters`,
  MANAGE_USER_ROUTE: `${ROUTE_HOME}/manageUser`,
  MANAGE_LEVEL_ROUTE: `${ROUTE_HOME}/manageLevel`,
  FIELD_FORCE_SECURITY_ROUTE: `${ROUTE_HOME}/fieldforcesecurity`,
};

/**
  * Constants for ACL Keys
  */
export const aclKeysConstant = {
  ADMIN_MENU: 'RBAC_ADMINISTRATION',
  GEN_EDIT: 'RBAC_GE_MENU',
  // GET Feature
  GET_ADD: 'RBAC_GET_ADD',
  GET_EDIT: 'RBAC_GET_EDIT',
  GET_DELETE: 'RBAC_GET_DELETE',
  GET_EXPORT: 'RBAC_GET_EXPORT',
  RBAC_GET_PARENT_DETAIL: 'RBAC_GET_PARENT_DETAIL',
  // GEV feature
  GEV_ADD: 'RBAC_GEV_ADD',
  GEV_EDIT: 'RBAC_GEV_EDIT',
  GEV_DELETE: 'RBAC_GEV_DELETE',
  GEV_EXPORT: 'RBAC_GEV_EXPORT',
  GEV_PARENT_DETAIL: 'RBAC_GEV_PARENT_DETAIL',
  // SYSPARMS feature
  SYSPARMS_ADD: 'RBAC_SYSPARMS_ADD',
  SYSPARMS_EDIT: 'RBAC_SYSPARMS_EDIT',
  SYSPARMS_DELETE: 'RBAC_SYSPARMS_DELETE',
  SYSPARMS_EXPORT: 'RBAC_SYSPARMS_EXPORT',
  SYSPARMS_MENU: 'RBAC_SYSPARMS_MENU',
  // PlanCode feature
  PLC_ADD: 'RBAC_PLC_ADD',
  PLC_EDIT: 'RBAC_PLC_EDIT',
  PLC_DELETE: 'RBAC_PLC_DELETE',
  PLC_EXPORT: 'RBAC_PLC_EXPORT',
  PLC_MENU: 'RBAC_PLC_MENU',
  // Data dictionary
  REPORTING_MENU: 'RBAC_REPORTING_MENU',
  DD_MENU: 'RBAC_DD_MENU',
  DD_EDIT: 'RBAC_DD_EDIT',
  DD_EXPORT: 'RBAC_DD_EXPORT',
  // Payment Threshold
  RBAC_PAY_CONFIG_MENU: 'RBAC_PAY_CONFIG_MENU',
  RBAC_PTH_MENU: 'RBAC_PTH_MENU',
  RBAC_PTH_ADD: 'RBAC_PTH_ADD',
  RBAC_PTH_EDIT: 'RBAC_PTH_EDIT',
  RBAC_PTH_DELETE: 'RBAC_PTH_DELETE',
  RBAC_PTH_EXPORT: 'RBAC_PTH_EXPORT',
  // genedit relationship
  RBAC_GER_MENU: 'RBAC_GER_MENU',
  RBAC_GERT_ADD: 'RBAC_GERT_ADD',
  RBAC_GERT_EDIT: 'RBAC_GERT_EDIT',
  RBAC_GERT_DELETE: 'RBAC_GERT_DELETE',
  RBAC_GERT_EXPORT: 'RBAC_GERT_EXPORT',
  RBAC_GERV_ADD: 'RBAC_GERV_ADD',
  RBAC_GERV_EDIT: 'RBAC_GERV_EDIT',
  RBAC_GERV_DELETE: 'RBAC_GERV_DELETE',
  RBAC_GERV_EXPORT: 'RBAC_GERV_EXPORT',
  // interface mapping
  RBAC_IM_MENU: 'RBAC_IM_MENU',
  RBAC_IM_ADD: 'RBAC_IM_ADD',
  RBAC_IM_EDIT: 'RBAC_IM_EDIT',
  RBAC_IM_DELETE: 'RBAC_IM_DELETE',
  RBAC_IM_EXPORT: 'RBAC_IM_EXPORT',
  // Manage group
  RBAC_SECURITY_SETUP_MENU: 'RBAC_SECURITY_SETUP_MENU',
  RBAC_MG_MENU: 'RBAC_MG_MENU',
  RBAC_MGA_VIEW: 'RBAC_MGA_VIEW',
  RBAC_MGA_EDIT: 'RBAC_MGA_EDIT',
  RBAC_MGA_DELETE: 'RBAC_MGA_DELETE',
  RBAC_MG_ADD: 'RBAC_MG_ADD',
  RBAC_MG_EDIT: 'RBAC_MG_EDIT',
  RBAC_MG_DELETE: 'RBAC_MG_DELETE',
  // product configuration
  RBAC_PROD_CONFIG_MENU: 'RBAC_PROD_CONFIG_MENU',
  // system configuration
  RBAC_SYS_CONFIG_MENU: 'RBAC_SYS_CONFIG_MENU',
  // Income Expense Map
  RBAC_IEM_MENU: 'RBAC_IEM_MENU',
  RBAC_IEM_ADD: 'RBAC_IEM_ADD',
  RBAC_IEM_EDIT: 'RBAC_IEM_EDIT',
  RBAC_IEM_DELETE: 'RBAC_IEM_DELETE',
  RBAC_IEM_EXPORT: 'RBAC_IEM_EXPORT',
  // PD feature
  RBAC_PD_ADD: 'RBAC_PD_ADD',
  RBAC_PD_EDIT: 'RBAC_PD_EDIT',
  RBAC_PD_DELETE: 'RBAC_PD_DELETE',
  RBAC_PD_SPLIT: 'RBAC_PD_SPLIT',
  RBAC_PD_MENU: 'RBAC_PD_MENU',
  RBAC_PD_VIEW: 'RBAC_PD_VIEW',

  // System options
  RBAC_SO_MENU: 'RBAC_SO_MENU',
  RBAC_SO_VIEW: 'RBAC_SO_VIEW',
  RBAC_SO_EDIT: 'RBAC_SO_EDIT',

  // Transaction Definition
  RBAC_TD_VIEW: 'RBAC_TD_VIEW',
  RBAC_TD_MENU: 'RBAC_TD_MENU',
  RBAC_TD_ADD: 'RBAC_TD_ADD',
  RBAC_TD_EDIT: 'RBAC_TD_EDIT',
  RBAC_TD_DELETE: 'RBAC_TD_DELETE',
  RBAC_TD_EXPORT: 'RBAC_TD_EXPORT',

  // Cheque Security
  RBAC_CHS_MENU: 'RBAC_CHS_MENU',
  RBAC_CHS_ADD: 'RBAC_CHS_ADD',
  RBAC_CHS_EDIT: 'RBAC_CHS_EDIT',
  RBAC_CHS_DELETE: 'RBAC_CHS_DELETE',
  RBAC_CHS_EXPORT: 'RBAC_CHS_EXPORT',

  // Configure Letters
  RBAC_CLTR_MENU: 'RBAC_CLTR_MENU',
  RBAC_CL_ADD: 'RBAC_CL_ADD',
  RBAC_CL_EDIT: 'RBAC_CL_EDIT',
  RBAC_CL_DELETE: 'RBAC_CL_DELETE',
  RBAC_CL_EXPORT: 'RBAC_CL_EXPORT',

  // // Daily Activity Process
  RBAC_AUTO_PROCESSING_MENU: 'RBAC_AUTO_PROCESSING_MENU',
  RBAC_DP_MENU: 'RBAC_DP_MENU',
  RBAC_DP_SCDLNPRO_MENU: 'RBAC_DP_SCDLNPRO_MENU',
  RBAC_DP_SHOW_SCHEDULE: 'RBAC_DP_SHOW_SCHEDULE',
  RBAC_DP_START_PROCESS: 'RBAC_DP_START_PROCESS',
  RBAC_DP_LIVE_PROCESS: 'RBAC_DP_LIVE_PROCESS',
  RBAC_DP_FCR_MENU: 'RBAC_DP_FCR_MENU',
  RBAC_DP_FCR_ADD: 'RBAC_DP_FCR_ADD',
  RBAC_DP_FCR_ABORT: 'RBAC_DP_FCR_ABORT',
  RBAC_DP_PROCESS_HISTORY: 'RBAC_DP_PROCESS_HISTORY',
  RBAC_DP_RESUME_PROCESS: 'RBAC_DP_RESUME_PROCESS',

  // Producer Audit Trail
  RBAC_PAT_MENU: 'RBAC_PAT_MENU',
  RBAC_PAT_EXPORT: 'RBAC_PAT_EXPORT',

  // Auto Reports
  RBAC_AR_SUBMENU: 'RBAC_AR_SUBMENU',
  RBAC_AR_VIEW: 'RBAC_AR_VIEW',
  RBAC_AR_EXPORT: 'RBAC_AR_EXPORT',

  // Notification Events RBAC
  RBAC_NF_MENU: 'RBAC_NF_MENU',
  RBAC_NFC_ADD: 'RBAC_NFC_ADD',
  RBAC_NFC_EDIT: 'RBAC_NFC_EDIT',
  RBAC_NFC_DELETE: 'RBAC_NFC_DELETE',
  RBAC_NFC_EXPORT: 'RBAC_NFC_EXPORT',

  // Notification Users RBAC
  RBAC_NFL_EDIT: 'RBAC_NFL_EDIT',
  RBAC_NFL_DELETE: 'RBAC_NFL_DELETE',
  RBAC_NFL_EXPORT: 'RBAC_NFL_EXPORT',

  RBAC_BR_STARTMENU: 'RBAC_BR_STARTMENU',
  RBAC_BR_STOPMENU: 'RBAC_BR_STOPMENU',
  RBAC_BR_SUBMENU: 'RBAC_BR_SUBMENU',

  // Data Transfer RBAC

  RBAC_DTS_MENU: 'RBAC_DTS_MENU',
  RBAC_DTS_CANCEL_UPLOAD: 'RBAC_DTS_CANCEL_UPLOAD',
  RBAC_DTS_START_UPLOAD: 'RBAC_DTS_START_UPLOAD',
  RBAC_DTS_RESET: 'RBAC_DTS_RESET',
  RBAC_DTS_CLEAR: 'RBAC_DTS_CLEAR',

  // interface setup
  RBAC_IS_MENU: 'RBAC_IFS_MENU',
  RBAC_IS_ADD: 'RBAC_IFS_ADD',
  RBAC_IS_EDIT: 'RBAC_IFS_EDIT',
  RBAC_IS_DELETE: 'RBAC_IFS_DELETE',
  RBAC_IS_EXPORT: 'RBAC_IFS_EXPORT',

  // Access Options
  RBAC_AO_MENU: 'RBAC_AO_MENU',
  RBAC_AO_ADD: 'RBAC_AO_ADD',
  RBAC_AO_EDIT: 'RBAC_AO_EDIT',
  RBAC_AO_DELETE: 'RBAC_AO_DELETE',
  RBAC_AO_EXPORT: 'RBAC_AO_EXPORT',

  // Client Relationship screen
  RBAC_FF_STRUCTURE_MENU: 'RBAC_FF_STRUCTURE_MENU',
  RBAC_FF_STRUCTURE_ADD: 'RBAC_FF_STRUCTURE_ADD',
  RBAC_FF_STRUCTURE_EDIT: 'RBAC_FF_STRUCTURE_EDIT',
  RBAC_FF_STRUCTURE_DELETE: 'RBAC_FF_STRUCTURE_DELETE',
  RBAC_FF_STRUCTURE_EXPORT: 'RBAC_FF_STRUCTURE_EXPORT',

  // FF Structure screen
  RBAC_FFS_MENU: 'RBAC_FFS_MENU',
  RBAC_FFS_EDIT: 'RBAC_FFS_EDIT',
  RBAC_FFS_DELETE: 'RBAC_FFS_DELETE',
  RBAC_FFS_EXPORT: 'RBAC_FFS_EXPORT',
  RBAC_FFS_ADD: 'RBAC_FFS_ADD',
  // manage user
  RBAC_SSU_MENU: 'RBAC_SSU_MENU',
  RBAC_SSU_ADD: 'RBAC_SSU_ADD',
  RBAC_SSU_EDIT: 'RBAC_SSU_EDIT',
  RBAC_SSU_DELETE: 'RBAC_SSU_DELETE',
  RBAC_SSU_EXPORT: 'RBAC_SSU_EXPORT',
  // field force security
  RBAC_FFSE_MENU: 'RBAC_FFSE_MENU',
  RBAC_FFSE_ADD: 'RBAC_FFSE_ADD',
  RBAC_FFSE_DELETE: 'RBAC_FFSE_DELETE',
  RBAC_IAR_EXPORT: 'RBAC_IAR_EXPORT',
  RBAC_EXTIAR_EXPORT: 'RBAC_EXTIAR_EXPORT',
};

/**
  * Constants for static content
  */
export const staticLabelKeys = {
  ADMIN_MENU: 'RBAC_ADMINISTRATION',
  SYS_CONFIG_MENU: 'RBAC_SYS_CONFIG_MENU',
  FIELD_FORCE_MENU: `${NS}administration.fieldforceStructure.menu`,
  PERIOD_DEFINITION_MENU: 'RBAC_PD_MENU',
  GEN_EDIT_MENU: 'RBAC_GE_MENU',
  SYSTEM_PARAMETER_MENU: 'RBAC_SYSPARMS_MENU',
  RBAC_SO_MENU: 'RBAC_SO_MENU',
  SYSTEM_OPTIONS_MENU: `${NS}administration.systemOptions.menu`,
  NOTIFICATIONS_MENU: `${NS}administration.notification.menu`,
  PRODUCT_CONFIG_MENU: 'RBAC_PROD_CONFIG_MENU',
  PLAN_CODE_MENU: 'RBAC_PLC_MENU',
  INTERFACE_MAPPING_MENU: 'RBAC_IM_MENU',
  TRANSACTION_DEFINITION_MENU: 'RBAC_TD_MENU',
  PAYMENT_CONFIGURATION_MENU: 'RBAC_PAY_CONFIG_MENU',
  PAYMENT_THRESHOLD_MENU: 'RBAC_PTH_MENU',
  CHEQUE_SYSTEM_MENU: `${NS}administration.chequeSystem.menu`,
  REPORTING_MENU: 'RBAC_REPORTING_MENU',
  GENERIC_EDIT_RELATIONSHIP_MENU: 'RBAC_GER_MENU',
  PRODUCER_AUDIT_TRAIL_MENU: 'RODUCER_AUDIT_TRAIL_MENU',
  DATA_DICTIONARY_MENU: 'RBAC_DD_MENU',
  BATCH_PROCESS_MENU: `${NS}administration.batchProcess.menu`,
  INTERFACE_SETUP_MENU: `${NS}administration.interfaceSetup.menu`,
  BATCH_MONITORING_MENU: `${NS}administration.batchMonitoring.menu`,
  SECURITY_MENU: 'RBAC_SECURITY_SETUP_MENU',
  CHEQUE_SECURITY_MENU: 'CHEQUE_SECURITY_MENU',
  CONFIGURE_LETTERS_MENU: 'CONFIGURE_LETTERS_MENU',
  SECURITY_SETTINGS_MENU: 'RBAC_MG_MENU',
  GET_ADD_SAVE: `${NS}get.add.save`,
  GET_ADD_CANCEL: `${NS}get.add.cancel`,
  INCOME_EXPENSE_MAP_MENU: 'RBAC_IEM_MENU',
  DP_MENU: 'RBAC_DP_MENU',
  DP_MAIN_MENU: 'RBAC_AUTO_PROCESSING_MENU',

  /**
   * Static Label Keys (MSG_ID) for System Parameter Page Contents - START
   */
  SYSPARM_TITLE_LIST: 'SYSPARM_TITLE_LIST',
  SYSPARM_TITLE_ADD: 'SYSPARM_TITLE_ADD',
  SYSPARM_TITLE_EDIT: 'SYSPARM_TITLE_EDIT',
  SYSPARM_ADD_SUCCESS: 'SYSPARM_ADD_SUCCESS',
  SYSPARM_EDIT_SUCCESS: 'SYSPARM_EDIT_SUCCESS',
  SYSPARM_DELETE_SUCCESS: 'SYSPARM_DELETE_SUCCESS',
  SYSPARAM_DEL_CONF_MSG: 'DELREC',
  // SYSPARAM_DEL_SUCCESS_MSG: 'SYSPARAM_DEL_SUCCESS_MSG',
  /**
   * Static Label Keys (MSG_ID) for System Parameter Page Contents - END
   */

  /**
  * Static Label Keys (MSG_ID) for Period Definition Page Contents
  */

  PD_PAGE_TITLE: 'PD_PAGE_TITLE',
  PD_TITLE_ADD: 'PD_TITLE_ADD',
  PD_TITLE_EDIT: 'PD_TITLE_EDIT',
  PD_TITLE_SPLIT: 'PD_TITLE_SPLIT',
  PD_ADD_SUCCESS: 'PD_ADD_SUCCESS',
  PD_EDIT_SUCCESS: 'PD_EDIT_SUCCESS',
  PD_SPLIT_SUCCESS: 'PD_SPLIT_SUCCESS',
  PD_DELETE_SUCCESS: 'PD_DELETE_SUCCESS',
  PD_DEL_CONF_MSG: 'PD_DELETE_IER',
  PD_LABEL_PERIODID: 'PD_LABEL_PERIODID',
  PD_LABEL_STARTDATE: 'PD_LABEL_STARTDATE',
  PD_LABEL_ENDDATE: 'PD_LABEL_ENDDATE',
  PD_LABEL_AME: 'PD_LABEL_AME',
  PD_LABEL_AQE: 'PD_LABEL_AQE',
  PD_LABEL_AYE: 'PD_LABEL_AYE',
  PD_LABEL_YEAR: 'PD_LABEL_YEAR',
  PD_LABEL_PROCESS: 'PD_LABEL_PROCESS',
  PD_LABEL_DAYS: 'PD_LABEL_DAYS',
  PD_LABEL_STARTDAY: 'PD_LABEL_STARTDAY',
  PD_LABEL_CURRENTDATE: 'PD_LABEL_CURRENTDATE',
  PD_LABEL_CURRENTYEAR: 'PD_LABEL_CURRENTYEAR',
  PD_LABEL_NEXTYEAR: 'PD_LABEL_NEXTYEAR',
  PD_BUTTON_GENERATE: 'PD_BUTTON_GENERATE',
  PD_LABEL_MONDAY: 'PD_LABEL_MONDAY',
  PD_LABEL_TUESDAY: 'PD_LABEL_TUESDAY',
  PD_LABEL_WEDNESDAY: 'PD_LABEL_WEDNESDAY',
  PD_LABEL_THURSDAY: 'PD_LABEL_THURSDAY',
  PD_LABEL_FRIDAY: 'PD_LABEL_FRIDAY',
  PD_LABEL_SATURDAY: 'PD_LABEL_SATURDAY',
  PD_LABEL_SUNDAY: 'PD_LABEL_SUNDAY',
  PD_LABEL_NOTAPPLICABLE: 'PD_LABEL_NOTAPPLICABLE',
  PD_CHECKMARK_ICON: 'PD_CHECKMARK_ICON',

  /**
  * Static Label Keys (MSG_ID) for Period Definition Page Contents - END
  */

  /**
  * Static Label Keys (MSG_ID) for Gen Edit type Page Contents
  */
  GET_PAGE_TITLE: 'GET_PAGE_TITLE',
  GET_MODAL_TITLE: 'GET_TITLE_ADD',
  GET_EDIT_MODAL_TITLE: 'GET_TITLE_EDIT',
  // GET_FORM_DROPDOWNPLACEHOLDER: `${NS}get.form.dropdownplaceholder`,

  /**
   * Static Label Keys for GET ADD/EDIT Page Contents
   */
  GET_FORM_TYPE: 'GET_LABEL_TYPE',
  GET_FORM_CASE: 'GET_LABEL_CASE',
  GET_LABEL_PARENTTYPE: 'GET_LABEL_PARENTTYPE',
  GET_LABEL_SYSTYPE: 'GET_LABEL_SYSTYPE',
  GET_LABEL_MAXVAL: 'GET_LABEL_MAXVAL',
  GET_DEL_CONF_MSG2: 'GENDELREL',
  GET_REL_BUTTON_DELETE: 'GET_REL_BUTTON_DELETE',
  // GET_NO_TEXT: 'COMMON_BUTTON_NO',

  GET_DEL_CONF_MSG: 'GENDELTYPE',
  GET_DEL_SUCCESS_MSG: 'GET_DELETE_SUCCESS',
  GET_DEL_REL_SUCCESS: 'GET_DELETE_REL_SUCCESS',
  GET_ADD_SUCCESS: 'GET_ADD_SUCCESS',
  GET_EDIT_SUCCESS: 'GET_EDIT_SUCCESS',
  INSFAILVAL: 'INSFAILVAL',
  UPDFAIL: 'UPDFAIL',
  INSFAIL: 'INSFAIL',
  UPDFAILVAL: 'UPDFAILVAL',
  /**
   // * Static Label Keys for GEV ADD/EDIT Page Contents
   // */
  GEV_LABEL_DESC: 'GEV_LABEL_DESC',
  GEV_LABEL_VALUE: 'GEV_LABEL_VALUE',
  GEV_LABEL_LANG: 'GEV_LABEL_LANG',
  GEV_LABEL_PARENTVALUE: 'GEV_LABEL_PARENTVALUE',
  GEV_ADD_SUCCESS: 'GEV_ADD_SUCCESS',
  GEV_EDIT_SUCCESS: 'GEV_EDIT_SUCCESS',
  GEV_TITLE_ADD: 'GEV_TITLE_ADD',
  GEV_TITLE_EDIT: 'GEV_TITLE_EDIT',
  GEV_PAGE_TITLE: 'GEV_PAGE_TITLE',
  GEV_DETAILS_TITLE: 'GEV_DETAILS_TITLE',
  GEV_SCREEN_NAME: 'GEV_SCREEN_NAME',

  // GEV delete message
  GEV_DEL_CONF_MSG: 'GENDELVAL',
  GEV_DEL_CONF_MSG2: 'GENDRVAL',
  GEV_DELETE_SUCCESS: 'GEV_DELETE_SUCCESS',
  GEV_TITLE_VALUES: 'GEV_TITLE_VALUES',
  COMMON_LABEL_VALUE: 'COMMON_LABEL_VALUE',
  /**
    * Static Label keys for Gen Edit Relationship values page contents
    */
  GERV_TITLE_LIST: 'GERV_TITLE_LIST',
  GERV_TITLE_ADD: 'GERV_TITLE_ADD',
  GERV_TITLE_EDIT: 'GERV_TITLE_EDIT',
  GERV_LABEL_FROMVALUE: 'GERV_LABEL_FROMVALUE',
  GERV_LABEL_TOVALUE: 'GERV_LABEL_TOVALUE',
  GERV_LABEL_FROMPARENTVAL: 'GERV_LABEL_FROMPARENTVAL',
  GERV_ADD_SUCCESS: 'GERV_ADD_SUCCESS',
  GERV_EDIT_SUCCESS: 'GERV_EDIT_SUCCESS',
  GERV_DELETE_CONFIRMATION: 'GERV_DELETE_CONFIRMATION',
  GERV_DELETE_SUCCESS: 'GERV_DELETE_SUCCESS',
  GERV_SCREEN_NAME: 'GERV_SCREEN_NAME',

  /**
    * Static Label keys for Plan Code page contents
    */
  PLC_TITLE_LIST: 'PLC_TITLE_LIST',
  PLC_TITLE_ADD: 'PLC_TITLE_ADD',
  PLC_TITLE_EDIT: 'PLC_TITLE_EDIT',
  PLC_LABEL_PRODUCTID: 'PLC_LABEL_PRODUCTID',
  PLC_LABEL_PRODUCT: 'PLC_LABEL_PRODUCT',
  PLC_LABEL_LOB: 'PLC_LABEL_LOB',
  PLC_LABEL_DIVISION: 'PLC_LABEL_DIVISION',
  PLC_LABEL_ACNTUNIT: 'PLC_LABEL_ACNTUNIT',

  // Plan Code modal for addEdit
  PLC_LABEL_PLANCODE: 'PLC_LABEL_PLANCODE',
  PLC_LABEL_DESCRIPTION: 'PLC_LABEL_DESCRIPTION',
  PLC_LABEL_LANGUAGE: 'PLC_LABEL_LANGUAGE',
  PLC_LABEL_PLANTYPE: 'PLC_LABEL_PLANTYPE',
  PLC_LABEL_INFO: 'PLC_LABEL_PLANCODEINFO',
  PLC_CHECK_FUND: 'PLC_CHECK_FUND',
  PLC_CHECK_PLAN: 'PLC_CHECK_PLAN',
  PLC_LABEL_BASICPLAN: 'PLC_LABEL_BASICPLAN',
  PLC_LABEL_BASIC_PLAN: 'PLC_LABEL_BASIC_PLAN',
  PLC_LABEL_RATESCALE: 'PLC_LABEL_RATESCALE',
  PLC_LABEL_PARCODE: 'PLC_LABEL_PARCODE',
  PLC_EDIT_SUCCESS: 'PLC_EDIT_SUCCESS',
  PLC_ADD_SUCCESS: 'PLC_ADD_SUCCESS',
  PLC_DELETE_SUCCESS: 'PLC_DELETE_SUCCESS',
  PLCDELDESC: 'PLCDELDESC',
  PLANCOD001: 'PLANCOD001',

  /**
    * Static Label keys for GenEdit Relationship page contents
    */
  GERT_LABEL_RELTYPE: 'GERT_LABEL_RELTYPE',
  GERT_LABEL_FROMTYPE: 'GERT_LABEL_FROMTYPE',
  GERT_LABEL_TOTYPE: 'GERT_LABEL_TOTYPE',
  GERT_TITLE_ADD: 'GERT_TITLE_ADD',
  GERT_TITLE_EDIT: 'GERT_TITLE_EDIT',
  GERT_ADD_SUCCESS: 'GERT_ADD_SUCCESS',
  GERT_EDIT_SUCCESS: 'GERT_EDIT_SUCCESS',
  GERT_DELETE_SUCCESS: 'GERT_DELETE_SUCCESS',
  GERT_DELETE_CONFIRMATION: 'GERT_DELETE_CONFIRMATION',
  GERT_TITLE_LIST: 'GERT_TITLE_LIST',

  /*
    * Static Label Keys for DataDictionary Page Contents
    */
  DD_TITLE_LIST: 'DD_TITLE_LIST',
  DD_LABEL_TABLE: 'DD_LABEL_TABLE',
  DD_LABEL_FIELD: 'DD_LABEL_FIELD',
  DD_LABEL_LENGTH: 'DD_LABEL_LENGTH',
  DD_LABEL_PRECISION: 'DD_LABEL_PRECISION',
  DD_LABEL_SCALE: 'DD_LABEL_SCALE',
  DD_LABEL_SEQUENCE: 'DD_LABEL_SEQUENCE',
  DD_LABEL_EXAMPLE: 'DD_LABEL_EXAMPLE',
  DD_LABEL_DESCRIPTION: 'DD_LABEL_DESCRIPTION',
  DD_LABEL_GENEDITTYPE: 'DD_LABEL_GENEDITTYPE',
  DD_LABEL_GENEDITPARENTTYPE: 'DD_LABEL_GENEDITPARENTTYPE',
  DD_LABEL_SRCFLD_NAME: 'DD_LABEL_SRCFLD_NAME',
  DD_LABEL_SRCSYSTEM: 'DD_LABEL_SRCSYSTEM',
  DD_LABEL_OBSOLETE: 'DD_LABEL_OBSOLETE',

  /* Static Label Keys for Data dictionary Page Contents */

  DD_PAGE_TITLE: 'DD_PAGE_TITLE',
  DD_MODAL_TITLE: 'DD_TITLE_ADD',
  DD_EDIT_MODAL_TITLE: 'DD_TITLE_EDIT',
  DD_EDIT_SUCCESS: 'DD_EDIT_SUCCESS',
  /**
   * Static Label Keys (MSG_ID) for Income Expense Map Page Contents - START
   */
  IEM_PAGE_TITLE: 'IEM_PAGE_TITLE',
  IEM_TITLE_ADD: 'IEM_TITLE_ADD',
  IEM_TITLE_EDIT: 'IEM_TITLE_EDIT',
  IEM_ADD_SUCCESS: 'IEM_ADD_SUCCESS',
  IEM_EDIT_SUCCESS: 'IEM_EDIT_SUCCESS',
  IEM_DELETE_SUCCESS: 'IEM_DELETE_SUCCESS',
  IEM_DEL_CONF_MSG: 'IEM_DELETE_IER',
  // SYSPARAM_DEL_SUCCESS_MSG: 'SYSPARAM_DEL_SUCCESS_MSG',
  COMMON_LABEL_COMMTYPE: 'COMMON_LABEL_COMMTYPE',
  COMMON_LABEL_COMMSUBTYPE: 'COMMON_LABEL_COMMSUBTYPE',
  COMMON_LABEL_DIVISION: 'COMMON_LABEL_DIVISION',
  COMMON_LABEL_LOB: 'COMMON_LABEL_LOB',
  COMMON_LABEL_PRODUCT: 'COMMON_LABEL_PRODUCT',
  IEM_LABEL_CATEGORY: 'IEM_LABEL_CATEGORY',
  IEM_LABEL_COMMTYPELABEL: 'IEM_LABEL_COMMTYPELABEL',
  IEM_LABEL_DESCLABEL: 'IEM_LABEL_DESCLABEL',
  COMMON_LABEL_IDENTITY: 'COMMON_LABEL_IDENTITY',
  IEM_LABEL_OVERRIDE: 'IEM_LABEL_OVERRIDE',
  // payment threshold contents
  PTH_TITLE_LIST: 'PTH_TITLE_LIST',
  PTH_LABEL_LEVEL: 'PTH_LABEL_LEVEL',
  PTH_LABEL_ID: 'PTH_LABEL_ID',
  PTH_LABEL_CODESTATUS: 'PTH_LABEL_CODESTATUS',
  PTH_LABEL_THRESHOLDAMOUNT: 'PTH_LABEL_THRESHOLDAMOUNT',
  PTH_LABEL_TASKFREQ: 'PTH_LABEL_TASKFREQ',

  // add edit payment threshold labels
  PTH_TITLE_ADD: 'PTH_TITLE_ADD',
  PTH_TITLE_EDIT: 'PTH_TITLE_EDIT',

  // delete payment threshold labels
  PAY_TRHLD5: 'PAY_TRHLD5',

  // success messages
  PTH_ADD_SUCCESS: 'PTH_ADD_SUCCESS',
  PTH_EDIT_SUCCESS: 'PTH_EDIT_SUCCESS',
  PTH_DELETE_SUCCESS: 'PTH_DELETE_SUCCESS',

  // static label keys for interface mapping
  IM_TITLE_ADD: 'IM_TITLE_ADD',
  IM_TITLE_EDIT: 'IM_TITLE_EDIT',
  IM_TITLE_LIST: 'IM_TITLE_LIST',
  IM_LABEL_CARDCODE: 'IM_LABEL_CARDCODE',
  IM_LABEL_ELEMENT: 'IM_LABEL_ELEMENT',
  COMMON_LABEL_TRANSACTIONID: 'COMMON_LABEL_TRANSACTIONID',
  IM_LABEL_DURATION: 'IM_LABEL_DURATION',
  IM_LABEL_CHAHIER: 'IM_LABEL_CHAHIER',
  IM_LABEL_PRODHIER: 'IM_LABEL_PRODHIER',
  IM_LABEL_TRANSDETAILS: 'IM_LABEL_TRANSDETAILS',
  IM_ADD_SUCCESS: 'IM_ADD_SUCCESS',
  IM_EDIT_SUCCESS: 'IM_EDIT_SUCCESS',
  IM_DELETE_SUCCESS: 'IM_DELETE_SUCCESS',
  IM_DELETE_CONFIRMATION_MSG: 'INTERFM003',

  // static label keys for Cheaque Security
  CHS_TITLE_LIST: 'CHS_TITLE_LIST',
  CHS_TITLE_ADD: 'CHS_TITLE_ADD',
  CHS_TITLE_EDIT: 'CHS_TITLE_EDIT',
  CHS_LABEL_USER_ID: 'CHS_LABEL_USER_ID',
  CHS_LABEL_GENERATING_SYSTEM: 'CHS_LABEL_GENERATING_SYSTEM',
  CHS_LABEL_DEPARTMENT: 'CHS_LABEL_DEPARTMENT',
  CHS_LABEL_IDENTIFIER: 'CHS_LABEL_IDENTIFIER',
  CHS_LABEL_ACTION: 'CHS_LABEL_ACTION',
  CHS_LABEL_MAX_AMOUNT: 'CHS_LABEL_MAX_AMOUNT',
  CHS_LABEL_SYSTEM: 'CHS_LABEL_SYSTEM',
  CHS_MSG_ADD_SUCCESS: 'CHS_MSG_ADD_SUCCESS',
  CHS_MSG_UPDATE_SUCCESS: 'CHS_MSG_UPDATE_SUCCESS',
  CHS_MSG_DELETE_SUCCESS: 'CHS_MSG_DELETE_SUCCESS',
  CHS_MSG_DELETE: 'CHS_MSG_DELETE',

  // static label keys for Configure Letters
  CL_PAGE_TITLE: 'CL_PAGE_TITLE',
  CL_LABEL_ADD_TITLE: 'CL_LABEL_ADD_TITLE',
  CL_LABEL_EDIT_TITLE: 'CL_LABEL_EDIT_TITLE',
  CL_LABEL_LAST_UPDATED: 'CL_LABEL_LAST_UPDATED',
  CL_LABEL_PROGRAM: 'CL_LABEL_PROGRAM',
  CL_LABEL_PATH_FILE_NAME: 'CL_LABEL_PATH_FILE_NAME',
  CL_LABEL_AUTO_PRINT: 'CL_LABEL_AUTO_PRINT',
  CL_LABEL_DOCUMENT_DESCRIPTION: 'CL_LABEL_DOCUMENT_DESCRIPTION',
  CL_LABEL_DOCUMENT_TYPE: 'CL_LABEL_DOCUMENT_TYPE',
  CL_LABEL_SYSTEM: 'CL_LABEL_SYSTEM',
  CL_MSG_ADD_SUCCESS: 'CL_MSG_ADD_SUCCESS',
  CL_MSG_EDIT_SUCCESS: 'CL_MSG_EDIT_SUCCESS',
  CL_MSG_DELETE_SUCCESS: 'CL_MSG_DELETE_SUCCESS',
  CL_MSG_DELETE: 'CL_MSG_DELETE',
  CL_ERROR_FILE_NAME: 'CL_ERROR_FILE_NAME',

  // static label keys for Daily Process
  DP_PAGE_TITLE: 'DP_PAGE_TITLE',
  DP_SCHEDULE_TAB: 'DP_SCHEDULE_TAB',
  DP_FCR_TAB: 'DP_FCR_TAB',
  DP_HISTORY_TAB: 'DP_HISTORY_TAB',
  DP_SCHEDULE_SUBTAB: 'DP_SCHEDULE_SUBTAB',
  DP_PROCESS_SUBTAB: 'DP_PROCESS_SUBTAB',
  DP_FCR_PROCESS_SUBTAB: 'DP_FCR_PROCESS_SUBTAB',
  DP_FCR_HISTORY_SUBTAB: 'DP_FCR_HISTORY_SUBTAB',
  DP_LABEL_PROCESSDATE: 'DP_LABEL_PROCESSDATE',
  DP_LABEL_SHOWSCHEDULE: 'DP_LABEL_SHOWSCHEDULE',
  DP_LABEL_SCHEDULEFOR: 'DP_LABEL_SCHEDULEFOR',
  DP_LABEL_ACTIVE: 'DP_LABEL_ACTIVE',
  DP_LABEL_PROCESSSTEPS: 'DP_LABEL_PROCESSSTEPS',
  DP_LABEL_CURRENTDATE: 'DP_LABEL_CURRENTDATE',
  DP_LABEL_NEXTDATE: 'DP_LABEL_NEXTDATE',
  DP_LABEL_PSTARTTIME: 'DP_LABEL_PSTARTTIME',
  DP_LABEL_UNPROCESS: 'DP_LABEL_UNPROCESS',
  DP_LABEL_SHOW: 'DP_LABEL_SHOW',
  DP_LABEL_HIDE: 'DP_LABEL_HIDE',
  DP_LABEL_STARTPROCESS: 'DP_LABEL_STARTPROCESS',
  DP_LABEL_RESET: 'DP_LABEL_RESET',
  DP_LABEL_LASTREFRESH: 'DP_LABEL_LASTREFRESH',
  DP_LABEL_STATUS: 'DP_LABEL_STATUS',
  DP_LABEL_ACTIVEPROCESS: 'DP_LABEL_ACTIVEPROCESS',
  DP_LABEL_REFRESH: 'DP_LABEL_REFRESH',
  DP_LABEL_STARTDATE: 'DP_LABEL_STARTDATE',
  DP_LABEL_LASTTIME: 'DP_LABEL_LASTTIME',
  DP_LABEL_PROCESSID: 'DP_LABEL_PROCESSID',
  DP_LABEL_PROCESSID_ONE: 'DP_LABEL_PROCESSID_ONE',
  DP_LABEL_STARTTIME: 'DP_LABEL_STARTTIME',
  DP_LABEL_ENDTIME: 'DP_LABEL_ENDTIME',
  DP_LABEL_TOTALRECORDS: 'DP_LABEL_TOTALRECORDS',
  DP_LABEL_SUCCESS: 'DP_LABEL_SUCCESS',
  DP_LABEL_FAILURE: 'DP_LABEL_FAILURE',
  DP_LABEL_APROCESSING: 'DP_LABEL_APROCESSING',
  DP_BATCH_RUN: 'DP_BATCH_RUN',
  DP_NOBATCH_RUN: 'DP_NOBATCH_RUN',
  DP_SCHEDULE_ERROR: 'DP_SCHEDULE_ERROR',
  DP_LABEL_JANUARY: 'DP_LABEL_JANUARY',
  DP_LABEL_FEBRUARY: 'DP_LABEL_FEBRUARY',
  DP_LABEL_MARCH: 'DP_LABEL_MARCH',
  DP_LABEL_APRIL: 'DP_LABEL_APRIL',
  DP_LABEL_MAY: 'DP_LABEL_MAY',
  DP_LABEL_JUNE: 'DP_LABEL_JUNE',
  DP_LABEL_JULY: 'DP_LABEL_JULY',
  DP_LABEL_AUGUST: 'DP_LABEL_AUGUST',
  DP_LABEL_SEPTEMBER: 'DP_LABEL_SEPTEMBER',
  DP_LABEL_OCTOBER: 'DP_LABEL_OCTOBER',
  DP_LABEL_NOVEMBER: 'DP_LABEL_NOVEMBER',
  DP_LABEL_DECEMBER: 'DP_LABEL_DECEMBER',
  DP_BATCH_FAILED: 'DP_BATCH_FAILED',
  DP_VIEW_ERROR: 'DP_VIEW_ERROR',
  DP_LABEL_PROCESSNAME: 'DP_LABEL_PROCESSNAME',
  DP_LABEL_SDATETIME: 'DP_LABEL_SDATETIME',
  DP_LABEL_EDATETIME: 'DP_LABEL_EDATETIME',
  DP_DATEFORMAT: 'DP_DATEFORMAT',
  DP_BUTTON_FORCECOMP: 'DP_BUTTON_FORCECOMP',
  DP_FUTUREDATE_ERROR: 'DP_FUTUREDATE_ERROR',
  DP_PROCESS_TIME: 'DP_PROCESS_TIME',
  DP_PROCESS_END: 'DP_PROCESS_END',
  DP_RESUME_FAILED: 'DP_RESUME_FAILED',
  DP_RESUME_SUCCESS: 'DP_RESUME_SUCCESS',
  DP_LABEL_RESUME: 'DP_LABEL_RESUME',
  DP_LABEL_RUN_DATE: 'DP_LABEL_RUN_DATE',
  DP_LABEL_BATCH_SCHEDULED: 'DP_LABEL_BATCH_SCHEDULED',

  // FCR Process
  FCR_LABEL_FILENAME: 'FCR_LABEL_FILENAME',
  FCR_LABEL_FILEFORMAT: 'FCR_LABEL_FILEFORMAT',
  FCR_LABEL_SSNAME: 'FCR_LABEL_SSNAME',
  FCR_LABEL_FILERECEIVED: 'FCR_LABEL_FILERECEIVED',
  FCR_LABEL_REALTIME: 'FCR_LABEL_REALTIME',
  FCR_LABEL_SHOWRECORDS: 'FCR_LABEL_SHOWRECORDS',
  FCR_LABEL_FCRHISTORY: 'FCR_LABEL_FCRHISTORY',
  FCR_ADD_LABEL: 'FCR_ADD_LABEL',
  FCR_ADD_MESSAGE: 'FCR_ADD_MESSAGE',
  FCR_CONFIRM_MESSAGE: 'FCR_CONFIRM_MESSAGE',
  FCR_DELETE_MESSAGE: 'FCR_DELETE_MESSAGE',
  FCR_BUTTON_LABEL: 'FCR_BUTTON_LABEL',
  DP_FCR_DATEFORMAT: 'DP_FCR_DATEFORMAT',
  DP_SHOWRECORDS_ERROR: 'DP_SHOWRECORDS_ERROR',
  FCR_LABEL_PCR_ERR_MSG_COUNT: 'FCR_LABEL_PCR_ERR_MSG_COUNT',
  FCR_LABEL_PCR_FAILED_COUNT: 'FCR_LABEL_PCR_FAILED_COUNT',
  FCR_LABEL_PCR_PROCESSED_COUNT: 'FCR_LABEL_PCR_PROCESSED_COUNT',
  FCR_LABEL_PCR_SUCCESS_COUNT: 'FCR_LABEL_PCR_SUCCESS_COUNT',
  // Data Transfer Labels
  // static label keys for Data Transfer
  DTS_LABEL_FILES: 'DTS_LABEL_FILES',
  DTS_LABEL_ORDER: 'DTS_LABEL_ORDER',
  DTS_BUTTON_CLEAR_RESULTS: 'DTS_BUTTON_CLEAR_RESULTS',
  DTS_BUTTON_START_UPLOAD: 'DTS_BUTTON_START_UPLOAD',
  DTS_LABEL_TRANSFER: 'DTS_LABEL_TRANSFER',
  DTS_TITLE_DATA_TRANSFERS: 'DTS_TITLE_DATA_TRANSFERS',
  DTS_BUTTON_CANCEL_UPLOAD: 'DTS_BUTTON_CANCEL_UPLOAD',
  DTS_LABEL_PROCESS_STEPS: 'DTS_LABEL_PROCESS_STEPS',
  DTS_ERR_TRANSFER_INCOMPLETE: 'DTS_ERR_TRANSFER_INCOMPLETE',
  DTS_SUC_TRANSFER_COMPLETE: 'DTS_SUC_TRANSFER_COMPLETE',
  DTS_MSG_STEP_FAIL: 'DTS_MSG_STEP_FAIL',
  DTS_VIEW_ERROR_DETAILS: 'DTS_VIEW_ERROR_DETAILS',
  DTS_MSG_PROCESSING_SETPS: 'DTS_MSG_PROCESSING_SETPS',
  DTS_SUC_TRANSFER_SUCCESS: 'DTS_SUC_TRANSFER_SUCCESS',
  DTS_MSG_TRANSFER_SUCCESS: 'DTS_MSG_TRANSFER_SUCCESS',
  DTS_MSG_TRANSFER_COMPLETEWITHERR: 'DTS_MSG_TRANSFER_COMPLETEWITHERR',
  DTS_CANCEL_CONFIRMATION_MSG: 'DTS_CANCEL_CONFIRMATION_MSG',
  DTS_MSG_STEP_PARTIAL_FAIL: 'DTS_MSG_STEP_PARTIAL_FAIL',
  DTS_MSG_CANCEL_ACKN: 'DTS_MSG_CANCEL_ACKN',
  DTS_CANCEL_SUBMITTED_MSG: 'DTS_CANCEL_SUBMITTED_MSG',
  DTS_MSG_TRANSFER_ALL_CANCELLED: 'DTS_MSG_TRANSFER_ALL_CANCELLED',
  DTS_MSG_TRANSFER_CANCELLED: 'DTS_MSG_TRANSFER_CANCELLED',
  DTS_NO_PROCESS_RUNNING: 'DTS_NO_PROCESS_RUNNING',

  // transaction definition labels
  // static label keys for transaction definition
  TD_TITLE_ADD_TRANSDEF: 'TD_TITLE_ADD_TRANSDEF',
  TD_TITLE_ADD: 'TD_TITLE_ADD',
  TD_TITLE_EDIT: 'TD_TITLE_EDIT',
  TD_ASSOCIATEDTRANS_TITLE_ADD: 'TD_ASSOCIATEDTRANS_TITLE_ADD',
  TD_ASSOCIATEDTRANS_TITLE_EDIT: 'TD_ASSOCIATEDTRANS_TITLE_EDIT',
  TD_ASSOCIATEDTRANS_TITLE: 'TD_ASSOCIATEDTRANS_TITLE',
  TD_TITLE_ADD_SECGRP: 'TD_TITLE_ADD_SECGRP',
  TD_TITLE_ADD_ASSOCIATEDTRANS: 'TD_TITLE_ADD_ASSOCIATEDTRANS',
  TD_ADD_SUCCESS: 'TD_ADD_SUCCESS',
  TD_EDIT_SUCCESS: 'TD_EDIT_SUCCESS',
  TD_DELETE_SUCCESS: 'SYSPARM_DELETE_SUCCESS',
  TD_TITLE_LIST: 'TD_TITLE_LIST',
  TD_TITLE_VIEW_DETAILS: 'TD_TITLE_VIEW_DETAILS',
  TD_ADD_LABEL_ACCOUNTING: 'TD_ADD_LABEL_ACCOUNTING',
  TD_ADD_LABEL_SETUPDETAILS: 'TD_ADD_LABEL_SETUPDETAILS',
  TD_TITLE_VIEW_SECGRP: 'TD_TITLE_VIEW_SECGRP',
  TD_TITLE_VIEW_ASSOCIATEDTRANS: 'TD_TITLE_VIEW_ASSOCIATEDTRANS',
  TD_ADD_LABEL_VISIBILITY: 'TD_ADD_LABEL_VISIBILITY',
  TD_ASSOCIATEDTRANS_ADD_SUCCESS: 'TD_ASSOCIATEDTRANS_ADD_SUCCESS',
  TD_ASSOCIATEDTRANS_EDIT_SUCCESS: 'TD_ASSOCIATEDTRANS_EDIT_SUCCESS',
  TD_ASSOCIATEDTRANS_DEL_CONFRMSG: 'TD_ASSOCIATEDTRANS_DEL_CONFRMSG',
  TD_ASSOCIATEDTRANS_DEL_SUCCESS: 'TD_ASSOCIATEDTRANS_DEL_SUCCESS',
  TRANSD001: 'TRANSD001',
  TD_DEL_SUCCESS: 'TD_DEL_SUCCESS',
  TD_SECGRP_UPD_SUCCESS: 'TD_SECGRP_UPD_SUCCESS',
  TD_ADD_SECGRP_LABEL_ROLES: 'TD_ADD_SECGRP_LABEL_ROLES',
  TD_ADD_SECGRP_LABEL_ROLESADDED: 'TD_ADD_SECGRP_LABEL_ROLESADDED',

  TD_BUTTON_BACKTOTRANSDEF: 'TD_BUTTON_BACKTOTRANSDEF',
  TD_BUTTON_BACKTOSECGRP: 'TD_BUTTON_BACKTOSECGRP',

  TD_LABEL_TRANSTYPE: 'TD_LABEL_TRANSTYPE',
  TD_LABEL_OFFSETACCT: 'TD_LABEL_OFFSETACCT',
  TD_LABEL_OFFSETACCTUNIT: 'TD_LABEL_OFFSETACCTUNIT',
  TD_LABEL_BALACCT: 'TD_LABEL_BALACCT',
  TD_LABEL_BALACCTUNIT: 'TD_LABEL_BALACCTUNIT',
  TD_LABEL_PENDINGACCT: 'TD_LABEL_PENDINGACCT',
  TD_LABEL_GAINLOSSACCT: 'TD_LABEL_GAINLOSSACCT',
  TD_LABEL_OVRDCOSTCENTER: 'TD_LABEL_OVRDCOSTCENTER',

  TD_LABEL_ASSOCIATEDTRANS: 'TD_LABEL_ASSOCIATEDTRANS',
  TD_LABEL_TAXEARNINGS: 'TD_LABEL_TAXEARNINGS',
  TD_LABEL_REVERSABLE_TAXABLE_EARNING: 'TD_LABEL_REVERSABLE_TAXABLE_EARNING',
  TD_LABEL_COSTCENTER: 'TD_LABEL_COSTCENTER',
  TD_LABEL_TRANSFERTOAGENT: 'TD_LABEL_TRANSFERTOAGENT',
  TD_LABEL_POLINFOREQ: 'TD_LABEL_POLINFOREQ',
  TD_LABEL_MDRTELIGIBILITY: 'TD_LABEL_MDRTELIGIBILITY',
  TD_LABEL_STATACCT: 'TD_LABEL_STATACCT',
  TD_LABEL_GROSSCOMMUSED: 'TD_LABEL_GROSSCOMMUSED',
  TD_LABEL_COMMPREUSED: 'TD_LABEL_COMMPREUSED',
  TD_LABEL_LEAVEPENDING: 'TD_LABEL_LEAVEPENDING',
  TD_LABEL_AMOUNTUSED: 'TD_LABEL_AMOUNTUSED',
  TD_LABEL_POLCOUNTUSED: 'TD_LABEL_POLCOUNTUSED',
  TD_LABEL_SALESCREDITUSED: 'TD_LABEL_SALESCREDITUSED',
  TD_LABEL_PERSISTENCYCREDIT: 'TD_LABEL_PERSISTENCYCREDIT',
  TD_LABEL_PERSISTENCYLAPSE: 'TD_LABEL_PERSISTENCYLAPSE',
  TD_LABEL_RESTRICTTOPAYEE: 'TD_LABEL_RESTRICTTOPAYEE',
  TD_LABEL_SALESCREDITFOROR: 'TD_LABEL_SALESCREDITFOROR',
  TD_LABEL_APPLYTOGRPBAL: 'TD_LABEL_APPLYTOGRPBAL',
  TD_LABEL_RFHST: 'TD_LABEL_RFHST',
  TD_LABEL_GST: 'TD_LABEL_GST',
  TD_LABEL_PST: 'TD_LABEL_PST',
  TD_LABEL_COMPSTRUCT: 'TD_LABEL_COMPSTRUCT',
  TD_LABEL_POLBALUPDATED: 'TD_LABEL_POLBALUPDATED',
  TD_LABEL_VISIBLEADJUST: 'TD_LABEL_VISIBLEADJUST',
  TD_LABEL_VISIBLEDEDUCTION: 'TD_LABEL_VISIBLEDEDUCTION',
  TD_LABEL_VISIBLEFORBULK: 'TD_LABEL_VISIBLEFORBULK',
  TD_LABEL_ADJUSTMENTS: 'TD_LABEL_ADJUSTMENTS',
  TD_LABEL_PRIORITY: 'TD_LABEL_PRIORITY',
  TD_LABEL_DEDUCTIONS: 'TD_LABEL_DEDUCTIONS',
  TD_LABEL_BULK: 'TD_LABEL_BULK',
  TD_LABEL_MAXAMOUNTUSED: 'TD_LABEL_MAXAMOUNTUSED',
  COMMON_LABEL_MAXAMOUNT: 'COMMON_LABEL_MAXAMOUNT',
  CM_DE_MONTHCUML: 'CM_DE_MONTHCUML',
  CM_DE_CALCMTHD: 'CM_DE_CALCMTHD',
  CM_DE_FREQ: 'CM_DE_FREQ',
  CM_DE_PERCDAYS: 'CM_DE_PERCDAYS',
  CM_DE_ENDDATE: 'CM_DE_ENDDATE',
  CM_DE_ACTIVE: 'CM_DE_ACTIVE',
  CM_DE_AMOUNT: 'CM_DE_AMOUNT',
  CM_DE_CURRENCY: 'CM_DE_CURRENCY ',
  CM_DE_PRIORITY: 'CM_DE_PRIORITY',
  CM_DE_MONTHTRG: 'CM_DE_MONTHTRG',
  CM_DE_COSTCENTER: 'CM_DE_COSTCENTER',
  CM_DE_SPECIALPAY: 'CM_DE_SPECIALPAY',
  CM_DE_CUMAMT: 'CM_DE_CUMAMT',
  CM_DE_TARGETAMT: 'CM_DE_TARGETAMT',
  CM_DE_MONTHTRGAMT: 'CM_DE_MONTHTRGAMT',
  CM_DE_PERFRSTYEARCOMM: 'CM_DE_PERFRSTYEARCOMM',
  CM_DE_PERRENCOMM: 'CM_DE_PERRENCOMM',
  CM_DE_PERFRSTYEAROVERRIDE: 'CM_DE_PERFRSTYEAROVERRIDE',
  CM_DE_TRANSFERADV: 'CM_DE_TRANSFERADV',
  CM_DE_CHEQMEMO: 'CM_DE_CHEQMEMO',
  CM_DE_LSTPROCESSED: 'CM_DE_LSTPROCESSED',
  CM_DE_COMMSUBTYPE: 'CM_DE_COMMSUBTYPE',
  CM_DE_TYPE: 'CM_DE_TYPE',
  CM_DE_PERRENOVERRIDE: 'CM_DE_PERRENOVERRIDE',
  CM_DE_TRANSACTION: 'CM_DE_TRANSACTION',
  CM_DE_COMMTYPE: 'CM_DE_COMMTYPE',
  CM_DE_PERBALANCE: 'CM_DE_PERBALANCE',

  // static label keys for interface Setup
  IS_TITLE_ADD: 'IFS_LABEL_ADD_INTERFACE_SETUP',
  IS_TITLE_EDIT: 'IFS_LABEL_EDIT_INTERFACE_SETUP',
  IS_TITLE_LIST: 'IS_TITLE_LIST',
  IS_LABEL_METHOD: 'IS_LABEL_METHOD',
  IFS_LABEL_ORDER: 'IFS_LABEL_ORDER',
  IFS_LABEL_APPEND: 'IFS_LABEL_APPEND',
  IFS_LABEL_UPLOAD: 'IFS_LABEL_UPLOAD',
  IFS_LABEL_PATH_FILE_NAME: 'IFS_LABEL_PATH_FILE_NAME',
  IFS_LABEL_PICKUPPATH_FILENAME: 'IFS_LABEL_PICKUPPATH_FILENAME',
  IFS_LABEL_SCHEMAPATH_FILENAME: 'IFS_LABEL_SCHEMAPATH_FILENAME',
  IFS_TITLE_INTERFACESETUP: 'IFS_TITLE_INTERFACESETUP',
  IFS_LABEL_LASTRUN: 'IFS_LABEL_LASTRUN',
  IS_ADD_SUCCESS: 'IFS_ADD_SUCCESS',
  IS_EDIT_SUCCESS: 'IFS_EDIT_SUCCESS',
  IS_DELETE_SUCCESS: 'IFS_DELETE_SUCCESS',
  IS_DELETE_CONFIRMATION_MSG: 'IFS_DELETE_CONFIRMATION',
  IFS_LABEL_METHOD: 'IFS_LABEL_METHOD',
  IFS_LABEL_UPLOAD1: 'IFS_LABEL_UPLOAD1',
  IFS_DELETE_CONF_WITHOUTORD: 'IFS_DELETE_CONF_WITHOUTORD',
  IFS_PATH_LABEL: 'IFS_PATH_LABEL',
  ARIA_IS_BUTTON_ADD: 'IFS_LABEL_ADD_INTERFACE_SETUP',

  // manage groups labels
  MGA_LABEL_APPLICATION: 'MGA_LABEL_APPLICATION',
  MGA_LABEL_SCREEN: 'MGA_LABEL_SCREEN',
  MG_TITLE_LIST: 'MG_TITLE_LIST',
  MG_LABEL_HELP: 'MG_LABEL_HELP',
  MG_ADD_SUCCESS: 'MG_ADD_SUCCESS',
  MG_EDIT_SUCCESS: 'MG_EDIT_SUCCESS',
  MG_DELETE_SUCCESS: 'MG_DELETE_SUCCESS',
  MG_DELETE_CONFIRMATION2: 'MG_DELETE_CONFIRMATION2',
  MG_LABEL_ADD_GROUP: 'MG_LABEL_ADD_GROUP',
  MG_LABEL_GROUP_TYPE: 'MG_LABEL_GROUP_TYPE',
  MG_LABEL_GROUP_NAME: 'MG_LABEL_GROUP_NAME',
  MG_LABEL_COPY_FROM_GROUP: 'MG_LABEL_COPY_FROM_GROUP',
  MG_LABEL_EDIT_GROUP: 'MG_LABEL_EDIT_GROUP',
  // Manage user
  SSU_TITLE_MANAGE_USER: 'SSU_TITLE_MANAGE_USER',
  SSU_LABEL_USER_ID: 'SSU_LABEL_USER_ID',
  SSU_LABEL_FIRST_NAME: 'SSU_LABEL_FIRST_NAME',
  SSU_LABEL_LAST_NAME: 'SSU_LABEL_LAST_NAME',
  SSU_LABEL_STATUS: 'SSU_LABEL_STATUS',
  SSU_LABEL_SECURITY_GROUP: 'SSU_LABEL_SECURITY_GROUP',
  SSU_LABEL_GROUP_TYPE: 'SSU_LABEL_GROUP_TYPE',
  SSU_LABEL_DEPARTMENT: 'SSU_LABEL_DEPARTMENT',
  SSU_LABEL_CLIENT_NUMBER: 'SSU_LABEL_CLIENT_NUMBER',
  SSU_LABEL_MAXIMUM_ROWS: 'SSU_LABEL_MAXIMUM_ROWS',
  SSU_LABEL_EMAIL_ADDRESS: 'SSU_LABEL_EMAIL_ADDRESS',
  SSU_LABEL_DESCRIPTOR: 'SSU_LABEL_DESCRIPTOR',
  SSU_LABEL_ADD_USER: 'SSU_LABEL_ADD_USER',
  SSU_LABEL_EDIT_USER: 'SSU_LABEL_EDIT_USER',
  SSU_MSG_ADD_SUCCESS: 'SSU_MSG_ADD_SUCCESS',
  SSU_MSG_CONFIRM_DELETE: 'SSU_MSG_CONFIRM_DELETE',
  SSU_MSG_DELETE_SUCCESS: 'SSU_MSG_DELETE_SUCCESS',
  SSU_MSG_EDIT_SUCCESS: 'SSU_MSG_EDIT_SUCCESS',

  // Manage Level
  MGL_TITLE_MANAGE_LEVEL: 'MGL_TITLE_MANAGE_LEVEL',
  MGL_DESCRIPTION_SELECT_LEVEL: 'MGL_DESCRIPTION_SELECT_LEVEL',
  MGL_LABEL_LEVEL_CODE: 'MGL_LABEL_LEVEL_CODE',
  MGL_LABEL_LEVEL_NUMBER: 'MGL_LABEL_LEVEL_NUMBER',
  MGL_TITLE_MANAGE_LEVEL_ACCESS: 'MGL_TITLE_MANAGE_LEVEL_ACCESS',
  MGL_LABEL_LEVEL_ACCESS: 'MGL_LABEL_LEVEL_ACCESS',
  MGL_MSG_SUCCESS_UPDATE: 'MGL_MSG_SUCCESS_UPDATE',

  // Field Force Security
  FFSE_LABEL_FIELD_FORCE_SECURITY: 'FFSE_LABEL_FIELD_FORCE_SECURITY',
  FFSE_DESCRIPTION_FIELD_FORCE_SECURITY_EDIT: 'FFSE_DESCRIPTION_FIELD_FORCE_SECURITY_EDIT',
  FFSE_LABEL_SEARCH_BY: 'FFSE_LABEL_SEARCH_BY',
  FFSE_LABEL_USER_NAME: 'FFSE_LABEL_USER_NAME',
  FFSE_LABEL_USER_ID: 'FFSE_LABEL_USER_ID',
  FFSE_LABEL_GROUP_TYPE: 'FFSE_LABEL_GROUP_TYPE',
  FFSE_LABEL_INT_GROUP_TYPE: 'FFSE_LABEL_GROUP_TYPE',
  FFSE_LABEL_EXT_GROUP_TYPE: 'FFSE_LABEL_GROUP_TYPE',
  FFSE_LABEL_ASSIGNED_FIELD_FORCE: 'FFSE_LABEL_ASSIGNED_FIELD_FORCE',
  FFSE_LABEL_ADD_MLC_SELLINGG_CODE: 'FFSE_LABEL_ADD_MLC_SELLINGG_CODE',
  FFSE_MESSAGE_ADD_SUCCESS: 'FFSE_MESSAGE_ADD_SUCCESS',
  FFSE_MESSAGE_DELETE_CONFIRM: 'FFSE_MESSAGE_DELETE_CONFIRM',
  FFSE_MESSAGE_DELETE_SUCCESS: 'FFSE_MESSAGE_DELETE_SUCCESS',

  /**
   * Static Label Keys (MSG_ID) for System Option Details Page Contents - START
   */
  // Tabs labels
  SO_TITLE_SYSTEMOPTIONS: 'SO_TITLE_SYSTEMOPTIONS',
  // SO_LABEL_SELECT_ALL: 'SO_LABEL_SELECT_ALL',
  // Date Options Labels
  SO_SO_LABEL_DATE_OPTIONS: 'SO_SO_LABEL_DATE_OPTIONS',
  SO_SO_LABEL_AUTOSYSDATE: 'SO_SO_LABEL_AUTOSYSDATE',
  SO_SO_LABEL_MINSYSDATE: 'SO_SO_LABEL_MINSYSDATE',
  SO_SO_LABEL_LASTSYSDATE: 'SO_SO_LABEL_LASTSYSDATE',
  SO_SO_LABEL_GENSYSDATE: 'SO_SO_LABEL_GENSYSDATE',
  SO_SO_LABEL_MAXSYSDATE: 'SO_SO_LABEL_MAXSYSDATE',
  SO_SO_LABEL_SYSDATE: 'SO_SO_LABEL_SYSDATE',
  SO_SO_LABEL_LYP: 'SO_SO_LABEL_LYP',

  // Background processing options labels
  SO_SO__LABEL_BGPO: 'SO_SO__LABEL_BGPO',
  SO_SO_LABEL_NEXSYSDATE: 'SO_SO_LABEL_NEXSYSDATE',
  SO_SO_LABEL_SF: 'SO_SO_LABEL_SF',
  SO_SO_LABEL_DATETOADVANCE: 'SO_SO_LABEL_DATETOADVANCE',
  SO_SO_LABEL_MONTOFRIONLY: 'SO_SO_LABEL_MONTOFRIONLY',
  SO_SO_LABEL_EST: 'SO_SO_LABEL_EST',
  SO_SO_LABEL_NRMC: 'SO_SO_LABEL_NRMC',
  SO_SO_LABEL_EFTLEADTIME: 'SO_SO_LABEL_EFTLEADTIME',
  SO_SO_LABEL_MONTHENDDBA: 'SO_SO_LABEL_MONTHENDDBA',
  SO_SO_EDIT_SUCCESS: 'SO_SO_EDIT_SUCCESS',

  // AODA Labels

  ARIA_SO_MINUS_MAX_ADV_DAYS: 'ARIA_SO_MINUS_MAX_ADV_DAYS',
  ARIA_SO_PLUS_MAX_ADV_DAYS: 'ARIA_SO_PLUS_MAX_ADV_DAYS',
  ARIA_SO_MINUS_HOUR_EARLY_SW_TIME: 'ARIA_SO_MINUS_HOUR_EARLY_SW_TIME',
  ARIA_SO_PLUS_HOUR_EARLY_SW_TIME: 'ARIA_SO_PLUS_HOUR_EARLY_SW_TIME',
  ARIA_SO_MINUS_MINUTE_EARLY_SW_TIME: 'ARIA_SO_MINUS_MINUTE_EARLY_SW_TIME',
  ARIA_SO_PLUS_MINUTE_EARLY_SW_TIME: 'ARIA_SO_PLUS_MINUTE_EARLY_SW_TIME',
  ARIA_SO_MINUS_EFT_LEAD_TIME: 'ARIA_SO_MINUS_EFT_LEAD_TIME',
  ARIA_SO_PLUS_EFT_LEAD_TIME: 'ARIA_SO_PLUS_EFT_LEAD_TIME',

  /**
   * Static Label Keys (MSG_ID) for System Option Details Page Contents - END
   */

  /**
   * Static Label Keys (MSG_ID) for General preference Page Contents - START
   */

  SO_GP_EDIT_SUCCESS: 'SO_GP_EDIT_SUCCESS',
  SO_TITLE_GENERALPREFERENCE: 'SO_TITLE_GENERALPREFERENCE',
  SO_GP_LABEL_TAXFORMMIN: 'SO_GP_LABEL_TAXFORMMIN',
  SO_GP_LABEL_GENERAL: 'SO_GP_LABEL_GENERAL',
  SO_GP_LABEL_TAXFORMTYPE: 'SO_GP_LABEL_TAXFORMTYPE',
  SO_GP_LABEL_CLIENTNUM: 'SO_GP_LABEL_CLIENTNUM',
  SO_GP_LABEL_INDIVIDUALNAME: 'SO_GP_LABEL_INDIVIDUALNAME',
  SO_GP_LABEL_COMPNAME: 'SO_GP_LABEL_COMPNAME',
  SO_GP_LABEL_AGENTLABEL: 'SO_GP_LABEL_AGENTLABEL',
  SO_GP_LABEL_BRANCHLEVEL: 'SO_GP_LABEL_BRANCHLEVEL',
  SO_GP_LABEL_AGENTSTATUS: 'SO_GP_LABEL_AGENTSTATUS',
  SO_GP_LABEL_CURRENCY: 'SO_GP_LABEL_CURRENCY',
  SO_LABEL_COMBOBOXDEF: 'SO_LABEL_COMBOBOXDEF',

  /**
   * Static Label Keys (MSG_ID) for General preference Page Contents - END
   */

  /**
   * Static Label Keys (MSG_ID) for Agent preference Page Contents - START
   */

  SO_AP_EDIT_SUCCESS: 'SO_AP_EDIT_SUCCESS',
  SO_TITLE_AGENTPREFERENCE: 'SO_TITLE_AGENTPREFERENCE',
  SO_TITLE_ADVISORPREFERENCE: 'SO_TITLE_ADVISORPREFERENCE',
  SO_AP_LABEL_PAYMETHOD: 'SO_AP_LABEL_PAYMETHOD',
  SO_AP_LABEL_LICENCESTATUS: 'SO_AP_LABEL_LICENCESTATUS',
  SO_AP_LABEL_ACTIVEPERCENT: 'SO_AP_LABEL_ACTIVEPERCENT',
  SO_AP_LABEL_TERMINPERCENT: 'SO_AP_LABEL_TERMINPERCENT',
  SO_AP_LABEL_MINAMOUNT: 'SO_AP_LABEL_MINAMOUNT',
  SO_AP_LABEL_MISFLAGNDSWITCH: 'SO_AP_LABEL_MISFLAGNDSWITCH',
  SO_AP_LABEL_FYCADV: 'SO_AP_LABEL_FYCADV',
  SO_AP_LABEL_FYCMOD: 'SO_AP_LABEL_FYCMOD',
  SO_AP_LABEL_RENADV: 'SO_AP_LABEL_RENADV',
  SO_AP_LABEL_RENMOD: 'SO_AP_LABEL_RENMOD',
  SO_AP_LABEL_SETTLEUP: 'SO_AP_LABEL_SETTLEUP',
  SO_AP_LABEL_LICENCECODE: 'SO_AP_LABEL_LICENCECODE',
  SO_AP_LABEL_EARNCHARGEBACK: 'SO_AP_LABEL_EARNCHARGEBACK',
  SO_AP_LABEL_ACCOUNTFORCOMM: 'SO_AP_LABEL_ACCOUNTFORCOMM',
  SO_AP_LABEL_WRITEOFALLOW: 'SO_AP_LABEL_WRITEOFALLOW',

  /**
   * Static Label Keys (MSG_ID) for Agent preference Page Contents - END
   */

  /**
   * Static Label Keys (MSG_ID) for Access Options  Page Contents - START
   */
  SO_TITLE_ACCESSOPTIONS: 'SO_TITLE_ACCESSOPTIONS',
  SO_AO_LABEL_ADDSYSSET: 'SO_AO_TITTLE_ADD',
  SO_AO_LABEL_EDITSYSSET: 'SO_AO_LABEL_EDITSYSSET',
  SO_AO_ADD_SUCCESS: 'SO_AO_ADD_SUCCESS',
  SO_AO_EDIT_SUCCESS: 'SO_AO_EDIT_SUCCESS',
  SO_AO_DELETE_SUCCESS: 'SO_AO_DELETE_SUCCESS',
  SO_AO_DELETE_SETTINGS: 'SO_AO_DELETE_SETTINGS',
  SO_AO_LABEL_SETTING: 'SO_AO_LABEL_SETTING',
  SO_AO_LABEL_DATATYPE: 'SO_AO_LABEL_DATATYPE',
  SO_AO_LABEL_MAXDATALEN: 'SO_AO_LABEL_MAXDATALEN',
  SO_AO_LABEL_VALUEREQ: 'SO_AO_LABEL_VALUEREQ',
  SO_AO_LABEL_LOOKUPTABLE: 'SO_AO_LABEL_LOOKUPTABLE',
  SO_AO_LABEL_VALUE: 'SO_AO_LABEL_VALUE',
  SO_AO_LABEL_FIELD: 'SO_AO_LABEL_FIELD',
  /**
     * Static Label Keys (MSG_ID) for Access Options Page Contents - END
     */
  /**
   * Static Label Keys (MSG_ID) for Notifications/Others Page Contents - START
   */
  SON_TITLE_NOTIFICATION: 'SON_TITLE_NOTIFICATION',
  SON_TAB_NOTIFICATION: 'SON_TAB_NOTIFICATION',
  SON_LABEL_ENABLE_NOTIFICATION: 'SON_LABEL_ENABLE_NOTIFICATION',
  SON_LABEL_MAIL_SYSYTEM: 'SON_LABEL_MAIL_SYSYTEM',
  SON_LABEL_SMTP_SERVER: 'SON_LABEL_SMTP_SERVER',
  SON_LABEL_EMAIL_ACCOUNT: 'SON_LABEL_EMAIL_ACCOUNT',
  SON_LABEL_DATA_FEED_FROM: 'SON_LABEL_DATA_FEED_FROM',
  SON_LABEL_MAX_ROWS: 'SON_LABEL_MAX_ROWS',
  SON_EDIT_SUCCESS: 'SON_EDIT_SUCCESS',
  /**
     * Static Label Keys (MSG_ID) for Notifications/Others Page Contents - END
     */

  /*
   *ARIA LABELS
   */
  ARIA_GET_BUTTON_ADD: 'ARIA_GET_BUTTON_ADD',
  ARIA_GEV_BUTTON_ADD: 'ARIA_GEV_BUTTON_ADD',
  ARIA_GERT_BUTTON_ADD: 'ARIA_GERT_BUTTON_ADD',
  ARIA_GERV_BUTTON_ADD: 'ARIA_GERV_BUTTON_ADD',
  ARIA_PTH_BUTTON_ADD: 'ARIA_PTH_BUTTON_ADD',
  ARIA_SYSPARM_BUTTON_ADD: 'ARIA_SYSPARM_BUTTON_ADD',
  ARIA_PLC_BUTTON_ADD: 'ARIA_PLC_BUTTON_ADD',
  ARIA_IM_BUTTON_ADD: 'ARIA_IM_BUTTON_ADD',
  ARIA_IEM_BUTTON_ADD: 'ARIA_IEM_BUTTON_ADD',
  ARIA_CHS_BUTTON_ADD: 'ARIA_CHS_BUTTON_ADD',
  ARIA_LIC_BUTTON_ADD: 'ARIA_LIC_BUTTON_ADD',
  ARIA_RM_BUTTON_ADD: 'ARIA_RM_BUTTON_ADD',

  /*
   *ARIA LABELS - END
   */
  MGA_EDIT_SUCCESS: 'MGA_EDIT_SUCCESS',
  MGA_TITLE_LIST: 'MGA_TITLE_LIST',
  MGA_LABEL_SCREENS: 'MGA_LABEL_SCREENS',
  MGA_LABEL_CONTROLS: 'MGA_LABEL_CONTROLS',
  MGA_LABEL_GROUPACCESS: 'MGA_LABEL_GROUPACCESS',

  /* Landing Screen */
  LS_LABEL_QUICKLINKS: 'LS_LABEL_QUICKLINKS',
  LS_NOQUICKLINK_MSG: 'LS_NOQUICKLINK_MSG',
  /* Manage application */
  MGA_LABEL_MANAGEAPPLICATION: 'MGA_LABEL_MANAGEAPPLICATION',
  MG_MANAGE_APPLICATION_HEADER_MESSAGE1: 'MG_MANAGE_APPLICATION_HEADER_MESSAGE1',
  MG_MANAGE_APPLICATION_HEADER_MESSAGE2: 'MG_MANAGE_APPLICATION_HEADER_MESSAGE2',
  MG_MANAGE_APPLICATION_HEADER_MESSAGE3: 'MG_MANAGE_APPLICATION_HEADER_MESSAGE3',

  PAT_MENU: 'RBAC_PAT_MENU',
  PAT_LABEL_CHANGEUSER: 'PAT_LABEL_CHANGEUSER',
  PAT_LABEL_CHANGEDATE: 'PAT_LABEL_CHANGEDATE',
  PAT_LABEL_CHANGETIME: 'PAT_LABEL_CHANGETIME',
  PAT_LABEL_APPLICATION: 'PAT_LABEL_APPLICATION',
  PAT_LABEL_COMPUTER: 'PAT_LABEL_COMPUTER',
  PAT_LABEL_AGENTID: 'PAT_LABEL_AGENTID',
  PAT_LABEL_CLIENTID: 'PAT_LABEL_CLIENTID',
  PAT_LABEL_BUSINESSNAME: 'PAT_LABEL_BUSINESSNAME',
  PAT_LABEL_DATEBEFORE: 'PAT_LABEL_DATEBEFORE',
  PAT_LABEL_DATEAFTER: 'PAT_LABEL_DATEAFTER',
  PAT_LABEL_VALUEBEFORE: 'PAT_LABEL_VALUEBEFORE',
  PAT_LABEL_VAUEAFTER: 'PAT_LABEL_VAUEAFTER',
  PAT_LABEL_NUMBEFORE: 'PAT_LABEL_NUMBEFORE',
  PAT_LABEL_NUMAFTER: 'PAT_LABEL_NUMAFTER',
  PAT_LABEL_EXTRACT: 'PAT_LABEL_EXTRACT',
  PAT_LABEL_DSSGUID: 'PAT_LABEL_DSSGUID',

  /* Payment Configuration */
  MG_PSC_SUCCESSMSG: 'MG_PSC_SUCCESSMSG',
  MG_PSC_FIELDFORCEPAYMENT: 'MG_PSC_FIELDFORCEPAYMENT',
  MG_PSC_CONFIELDFORCEPAY: 'MG_PSC_CONFIELDFORCEPAY',
  CONFIG_AUTH_AMOUNT: 'CONFIG_AUTH_AMOUNT',
  CONFIG_MEMO_LENGTH: 'CONFIG_MEMO_LENGTH',
  CONFIG_DESC_LINE_LENGTH: 'CONFIG_DESC_LINE_LENGTH',
  CONFIG_DESC_LINES: 'CONFIG_DESC_LINES',

  // Batch Report - StatementsAndXmlLifeFeeds
  BR_LABEL_TITLE: 'BR_LABEL_TITLE',
  BR_MSG_SUCCCESS: 'BR_MSG_SUCCCESS',
  BR_MSG_RUNNING: 'BR_MSG_RUNNING',
  BR_LABEL_SELECT_HEADING: 'BR_LABEL_SELECT_HEADING',
  BR_LABEL_SYSTEM_DATE: 'BR_LABEL_SYSTEM_DATE',
  BR_LABEL_RUN_DATE: 'BR_LABEL_RUN_DATE',
  BR_LABEL_PERIOD_START: 'BR_LABEL_PERIOD_START',
  BR_LABEL_PERIOD_END: 'BR_LABEL_PERIOD_END',
  BR_LABEL_LAST_PROCESSED_ADVISOR: 'BR_LABEL_LAST_PROCESSED_ADVISOR',
  BR_LABEL_DISPLAY_CHECKBOX_MSG: 'BR_LABEL_DISPLAY_CHECKBOX_MSG',
  BR_LABEL_STOP: 'BR_LABEL_STOP',
  BR_LABEL_START: 'BR_LABEL_START',
  BR_LABEL_FEED_RUN: 'BR_LABEL_FEED_RUN',
  BR_LABEL_START_TIME: 'BR_LABEL_START_TIME',
  BR_LABEL_END_TIME: 'BR_LABEL_END_TIME',
  BR_LABEL_ELAPSED_TIME: 'BR_LABEL_ELAPSED_TIME',
  BR_LABEL_FILES: 'BR_LABEL_FILES',
  BR_LABEL_PRINT: 'BR_LABEL_PRINT',
  BR_LABEL_REPORTS: 'BR_LABEL_REPORTS',
  BR_LABEL_TIME: 'BR_LABEL_TIME',
  BR_LABEL_STATUS: 'BR_LABEL_STATUS',
  BR_LABEL_RECORD_COUNT: 'BR_LABEL_RECORD_COUNT',
  BR_LABEL_SUCCESS: 'BR_LABEL_SUCCESS',
  BR_COMPLETE_STATUS: 'C',
  BR_MSG_CONCURRENCY: 'BR_MSG_CONCURRENCY',
  BR_LABEL_STOP_TITLE: 'BR_STOPMSG_STATUS',
  BR_MSG_FORCE_COMPLETE: 'BR_MSG_FILESTOPSUCCESS',
  BR_MSG_NOTRUNNING: 'BR_MSG_NOTRUNNING',
  BR_LABEL_DATEFORMAT: 'BR_LABEL_DATEFORMAT',

  // Auto Reports
  AR_TITLE_MOVEMENT: 'AR_TITLE_MOVEMENT',
  AR_LABEL_REPORT_NAME: 'AR_LABEL_REPORT_NAME',
  AR_LABEL_QUERY_NAME: 'AR_LABEL_QUERY_NAME',
  AR_LABEL_DEVELOPED_BY: 'AR_LABEL_DEVELOPED_BY',
  AR_LABEL_REQUESTOR: 'AR_LABEL_REQUESTOR',
  AR_LABEL_REPORT_FREQUENCY: 'AR_LABEL_REPORT_FREQUENCY',
  AR_LABEL_REPORT_STATUS: 'AR_LABEL_REPORT_STATUS',
  AR_LABEL_LAST_RUN_DATE: 'AR_LABEL_LAST_RUN_DATE',
  AR_LABEL_REPORT_FORMAT: 'AR_LABEL_REPORT_FORMAT',
  AR_LABEL_QUERY: 'AR_LABEL_QUERY',
  AR_LABEL_QUERY_ID: 'AR_LABEL_QUERY_ID',
  AR_TITLE_VIEW_AUTO_REPORT: 'AR_TITLE_VIEW_AUTO_REPORT',
  AR_LABEL_REPORT_OUTPUT_PATH: 'AR_LABEL_REPORT_OUTPUT_PATH',
  AR_LABEL_DEPT_OUTPUT_PATH: 'AR_LABEL_DEPT_OUTPUT_PATH',

  // Notifications Labels
  NC_TITLE_NOTIFICATION: 'NC_TITLE_NOTIFICATION',
  RBAC_NF_MENU: 'RBAC_NF_MENU',
  NC_LABEL_EVENTS: 'NC_LABEL_EVENTS',
  NC_LABEL_USERS: 'NC_LABEL_USERS',
  NC_LABEL_EVENT: 'NC_LABEL_EVENT',
  NC_LABEL_SUBJECT: 'NC_LABEL_SUBJECT',
  NC_LABEL_MESSAGE: 'NC_LABEL_MESSAGE',
  NC_LABEL_ACTIVE: 'NC_LABEL_ACTIVE',
  NC_LABEL_SEQ: 'NC_LABEL_SEQ',
  NC_LABEL_FIELDFORCEHIERAPPL: 'NC_LABEL_FIELDFORCEHIERAPPL',
  NC_LABEL_CC: 'NC_LABEL_CC',
  NC_LABEL_BCC: 'NC_LABEL_BCC',
  NC_LABEL_MSG_TYPE: 'NC_LABEL_MSG_TYPE',
  NC_LABEL_INCLUDEFIELDFORCEHIERARCY: 'NC_LABEL_INCLUDEFIELDFORCEHIERARCY',
  NC_LABEL_USER: 'NC_LABEL_USER',
  NC_LABEL_USERTYPE: 'NC_LABEL_USERTYPE',
  NC_LABEL_MESSAGETYPE: 'NC_LABEL_MESSAGETYPE',
  NC_LABEL_NAME: 'NC_LABEL_NAME',
  NC_LABEL_AVAILABLEEVENTS: 'NC_LABEL_AVAILABLEEVENTS',
  NC_LABEL_SELECTEDEVENTS: 'NC_LABEL_SELECTEDEVENTS',
  AVAILABLE_HEADER_EVENT: 'AVAILABLE_HEADER_EVENT',
  SELECTED_HEADER_EVENT: 'SELECTED_HEADER_EVENT',
  NC_BUTTON_ADD_REMOVE: 'NC_BUTTON_ADD_REMOVE',
  NC_LABEL_TSK: 'NC_LABEL_TSK',
  NC_LABEL_ADD_EVENT: 'NC_LABEL_ADD_EVENT',
  NC_LABEL_EDIT_EVENT: 'NC_LABEL_EDIT_EVENT',
  NC_SELECT_BUTTON_ACTIVE: 'NC_SELECT_BUTTON_ACTIVE',
  NC_SELECT_BUTTON_INACTIVE: 'NC_SELECT_BUTTON_INACTIVE',
  NC_SELECT_LABEL_TO: 'NC_SELECT_LABEL_TO',
  NC_SELECT_LABEL_CC: 'NC_SELECT_LABEL_CC',
  NC_SELECT_LABEL_BCC: 'NC_SELECT_LABEL_BCC',
  NTFY_DELHR_NC: 'NTFY_DELHR_NC',
  NL_LABEL_EDIT_USER: 'NL_LABEL_EDIT_USER',
  NC_DELETE_CONFIRM: 'NC_DELETE_CONFIRM',
  NC_DELETE_SUCCESS: 'NC_DELETE_SUCCESS',
  NL_DELETE_SUCCESS: 'NL_DELETE_SUCCESS',
  NL_DELETE_CONFIRM: 'NL_DELETE_CONFIRM',
  NL_UPDATE_SUCCESS: 'NL_UPDATE_SUCCESS',
  NC_ADD_SUCCESS: 'NC_ADD_SUCCESS',
  NC_UPDATE_SUCCESS: 'NC_UPDATE_SUCCESS',

  // ARIA LABELS
  ARIA_TO_BUTTON_ADD_REMOVE: 'ARIA_TO_BUTTON_ADD_REMOVE',
  ARIA_CC_BUTTON_ADD_REMOVE: 'ARIA_CC_BUTTON_ADD_REMOVE',
  ARIA_BCC_BUTTON_ADD_REMOVE: 'ARIA_BCC_BUTTON_ADD_REMOVE',
  ARIA_EVENT_BUTTON_ADD: 'ARIA_EVENT_BUTTON_ADD',
  ARIA_ADD_EVENT_DL_BUTTON: 'ARIA_ADD_EVENT_DL_BUTTON',
  ARIA_REMOVE_EVENT_DL_BUTTON: 'ARIA_REMOVE_EVENT_DL_BUTTON',
  ARIA_ADD_USER_DL_BUTTON: 'ARIA_ADD_USER_DL_BUTTON',
  ARIA_REMOVE_USER_DL_BUTTON: 'ARIA_REMOVE_USER_DL_BUTTON',
  ARIA_ADD_HIER_USER_DL_BUTTON: 'ARIA_ADD_HIER_USER_DL_BUTTON',
  ARIA_REMOVE_HIER_USER_DL_BUTTON: 'ARIA_REMOVE_HIER_USER_DL_BUTTON',

  // FF structure
  FFS_PAGE_TITLE: 'FFS_PAGE_TITLE',
  FFS_LABEL_MOVEMENT: 'FFS_LABEL_MOVEMENT',
  FFS_LABEL_HIERARCHY: 'FFS_LABEL_HIERARCHY',
  FFS_LABEL_DEBIT_REFINANCED: 'FFS_LABEL_DEBIT_REFINANCED',
  FFS_LABEL_MAXORGIDSIZE: 'FFS_LABEL_MAXORGIDSIZE',
  FFS_LABEL_MAXCLIENTIDSIZE: 'FFS_LABEL_MAXCLIENTIDSIZE',
  FFS_LABEL_IDNUMERIC: 'FFS_LABEL_IDNUMERIC',
  FFS_LABEL_ALTERPRODUCTION: 'FFS_LABEL_ALTERPRODUCTION',
  FFS_LABEL_LEVELHIERARCHY: 'FFS_LABEL_LEVELHIERARCHY',
  FFS_LABEL_MANU_SEQCODEREQ: 'FFS_LABEL_MANU_SEQCODEREQ',
  FFS_LABEL_MANU_SEQCODENUMERIC: 'FFS_LABEL_MANU_SEQCODENUMERIC',
  FFS_LABEL_MAX_MANU_SEQCODESIZE: 'FFS_LABEL_MAX_MANU_SEQCODESIZE',
  FFS_LABEL_INTER_REQ: 'FFS_LABEL_INTER_REQ',
  FFS_LABEL_OTHER_AGENT_ID_REQ: 'FFS_LABEL_OTHER_AGENT_ID_REQ',
  FFS_LABEL_DOC_REQ: 'FFS_LABEL_DOC_REQ',
  FFS_LABEL_COMP_STRUC_REQ: 'FFS_LABEL_COMP_STRUC_REQ',
  FFS_LABEL_LICENSE_REQ: 'FFS_LABEL_LICENSE_REQ',
  FFS_LABEL_COMP_REDIRECT: 'FFS_LABEL_COMP_REDIRECT',
  FFS_LABEL_PROPAY_PARTICIPATION: 'FFS_LABEL_PROPAY_PARTICIPATION',
  FFS_LABEL_INC_FIN_ARR_PROCESS: 'FFS_LABEL_INC_FIN_ARR_PROCESS',
  FFS_DELETE_CONFIRMATION: 'FFS_DELETE_CONFIRMATION',
  FFS_DELETE_SUCCESS: 'FFS_DELETE_SUCCESS',
  FFS_LABEL_ADD_SUCCESS_MSG: 'FFS_LABEL_ADD_SUCCESS_MSG',
  FFS_LABEL_UPDATE_SUCCESS_MSG: 'FFS_LABEL_UPDATE_SUCCESS_MSG',
  FFS_LABEL_ADD: 'FFS_LABEL_ADD',
  FFS_LABEL_EDIT: 'FFS_LABEL_EDIT',

};

export const BATCH_RPT_COL_DATAFIELDS = {
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  PRINT_FLAG: 'printFlag',
  FILE_NAME: 'fileName',
  RECORD_COUNT: 'recordCount',
  STATUS: 'status',
};
