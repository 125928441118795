/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { extRateActionTypes } from '../actions/actionTypes';
import { exportErrorMsg, formatResponse, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

const getResetUpsertValue = (responseData, err) => ({
  upsertRequest: null,
  upsertRequestError: err ? processError(err) : null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: responseData ? new Date() : null,
  upsertData: responseData,
});

export function* getExtRateList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(extRateActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(extRateActionTypes.LOG_EXT_RATE_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_RATE_LIST, action.data);
    yield all([
      put(getAction(extRateActionTypes.GET_EXT_RATE_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(extRateActionTypes.GET_EXT_RATE_LIST_ERROR, err)),
      put(getAction(extRateActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getExtRatebyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(extRateActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(extRateActionTypes.LOG_EXT_RATE_BY_ID_REQUEST, action.data)),
    put(getAction(extRateActionTypes.GET_UPLINE_RATE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_RATE_GET_BY_ID, action.data);
    const obj = {
      rateKey: responseData.rateKey,
      rdRateKey: responseData.rdRateKey,
      manulifeSellingCode: responseData.manulifeSellingCode,
      rdrCalcType: responseData.rdrCalcType,
      rdrRateType: responseData.rdrRateType,
      effectiveDate: responseData.effectiveDate,
    };
    const newData = yield call(invokeApi, moduleAPI.EXT_RATE_GET_BY_NEW_RATE, obj);
    yield all([
      put(getAction(extRateActionTypes.GET_EXT_RATE_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put((getAction(extRateActionTypes.GET_EXT_NEW_RATE_RESPONSE, newData))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(extRateActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(extRateActionTypes.GET_EXT_RATE_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
export function* getExtNewRate(action) {
  yield all([
    put(processingStarted()),
    put(getAction(extRateActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(extRateActionTypes.LOG_EXT_NEW_RATE_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_RATE_GET_BY_NEW_RATE, action.data);
    yield all([
      put(getAction(extRateActionTypes.GET_EXT_NEW_RATE_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(extRateActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(extRateActionTypes.GET_EXT_NEW_RATE_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
export function* rateSave(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_RATE_SAVE, action.data);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(extRateActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(extRateActionTypes.GET_EXT_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(extRateActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getNewRateDrop(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_RATE_GET_BY_NEW_RATE, action.data);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put((getAction(extRateActionTypes.GET_EXT_NEW_RATE_RESPONSE, responseData?.slice(0, -1)))),
      put((getAction(extRateActionTypes.GET_UPLINE_RATE, responseData.slice(-1)[0]))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(extRateActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(extRateActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  const payload = action.data.formatExtObject ? action.data.formatExtObject : action.data;
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_RATE_EXPORT, payload);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    if (action.data.popUpClose) {
      action.data.popUpClose();
    }
    yield put(processingCompleted());
  } catch (err) {
    if (action.data.popUpClose) {
      action.data.popUpClose();
    }
    if (
      err.response.status === globalConstants.errorCode403
      || err.response.status === globalConstants.errorCode402
    ) {
      const errorObject = exportErrorMsg(err.response.status, payload?.exportFileType);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(extRateActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
function* extRateModuleWatcher() {
  yield takeEvery(extRateActionTypes.GET_EXT_RATE_LIST_REQUEST, getExtRateList);
  yield takeEvery(extRateActionTypes.GET_EXT_RATE_BY_ID_REQUEST, getExtRatebyID);
  yield takeEvery(extRateActionTypes.GET_EXT_NEW_RATE_REQUEST, getExtNewRate);
  yield takeEvery(extRateActionTypes.GET_EXT_RATE_LIST_SAVE, rateSave);
  yield takeEvery(extRateActionTypes.GET_EXT_RATE_DROP, getNewRateDrop);
  yield takeEvery(extRateActionTypes.GET_EXT_RATE_LIST_EXPORT, getExportList);
}
export default extRateModuleWatcher;
