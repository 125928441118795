/**
 * @Description FasatColumnOrder List File
 * @FileName List.js
 * @Author ROHIT NEGI-negiroh
 * @CreatedOn 12 January, 2022 12:30:30
 * @IssueID 3505
 */
import PropTypes from 'prop-types';
import React from 'react';
import ListItem from './ListItem';

const KEY_CODES = {
  SPACEBAR: 32,
  ENTER: 13,
  END: 35,
  HOME: 36,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  A: 65,
};

const MOVE_KEY_CODES = [KEY_CODES.SPACEBAR, KEY_CODES.ENTER];

const focusedRingClass = 'focused-ring';
const ariaActiveDescendent = 'aria-activedescendant';

const removeClass = (className) => {
  const elements = document.getElementsByClassName(className);
  Array.from(elements).forEach((ele) => {
    ele.classList.remove(className);
  });
};

const scrollIntoViewIfNeeded = (container, element) => {
  const contRect = container.getBoundingClientRect();
  const eleRect = element.getBoundingClientRect();
  if (eleRect.top < contRect.top || eleRect.bottom > contRect.bottom) {
    const scrollHeight = element.dataset.index * element.scrollHeight;
    container.scroll({ top: scrollHeight });
  }
};

/**
 *
 * @param {HTMLElement} element
 * @param {number} keyCode
 * @returns {(HTMLElement|void)}
 */
function getSiblingElement(element, keyCode) {
  let nextEle;
  if (keyCode === KEY_CODES.UP_ARROW) {
    nextEle = element.previousElementSibling;
  }
  if (keyCode === KEY_CODES.DOWN_ARROW) {
    nextEle = element.nextElementSibling;
  }
  return nextEle;
}

class List extends React.Component {
  /**
   * @returns {void}
   */
  constructor(props) {
    super(props);
    this.lastSelectedValue = '';
    this.selectedValues = [];
    this.listBoxRef = React.createRef();
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.removeActivedescendant = this.removeActivedescendant.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  handleShiftKeyPressOption(keyCode, lastSelectedOption, ctrlKey) {
    const { onChange } = this.props;
    const listBox = this.listBoxRef.current;
    let selectedOption;
    if (keyCode === KEY_CODES.END) {
      this.selectToCurrentValue(Array.from(listBox.children).reverse(), lastSelectedOption.dataset.value);
      selectedOption = listBox.lastElementChild;
    } else
    if (keyCode === KEY_CODES.HOME) {
      this.selectToCurrentValue(Array.from(listBox.children), lastSelectedOption.dataset.value);
      selectedOption = listBox.firstElementChild;
    } else {
      selectedOption = getSiblingElement(lastSelectedOption, keyCode);
      if (selectedOption) {
        if (selectedOption.dataset.selected === 'true') {
          const index = this.selectedValues.indexOf(lastSelectedOption.dataset.value);
          this.selectedValues.splice(index, 1);
          onChange(this.selectedValues);
        } else {
          this.selectedValues.push(selectedOption.dataset.value);
          onChange(this.selectedValues);
        }
      }
    }
    this.setLastSelectedOption(selectedOption, ctrlKey);
  }

  /**
   * @param {Event} event
   *
   * @returns {void}
   */
  handleOptionKeyDown(event, lastSelectedOption) {
    event.stopPropagation();
    const { onChange, onKeyDown } = this.props;
    const { keyCode, ctrlKey, shiftKey } = event;
    const listBox = this.listBoxRef.current;

    // on shift + end/home/arrwup/down
    if (shiftKey && ([KEY_CODES.END, KEY_CODES.HOME, KEY_CODES.UP_ARROW, KEY_CODES.DOWN_ARROW].indexOf(keyCode) > -1)) {
      this.handleShiftKeyPressOption(keyCode, lastSelectedOption, ctrlKey);
      return;
    }

    // on pressing ctrl A
    if (ctrlKey && keyCode === KEY_CODES.A) {
      event.preventDefault();
      this.markAllOptionSelected();
      this.setLastSelectedOption(listBox.lastElementChild);
      return;
    }

    if (ctrlKey && !shiftKey) {
      this.handleCtrlKeyDownOption(keyCode, event, lastSelectedOption);
      return;
    }
    // on single key pressed
    const nextElement = this.getNextElementOnKeyOptionKeyPress(keyCode, lastSelectedOption);
    this.selectNextElement(nextElement, event, onChange, onKeyDown);
  }

  handleListKeyDown(event) {
    const { keyCode, ctrlKey, shiftKey } = event;
    const listBoxEle = this.listBoxRef.current;
    // ctrl + a
    if (keyCode === KEY_CODES.A && ctrlKey) {
      event.preventDefault();
      this.markAllOptionSelected();
      this.setLastSelectedOption(listBoxEle.lastElementChild);
      return;
    }
    // add focused ring
    if (ctrlKey && !shiftKey) {
      this.handleCtrlKeyDownList(keyCode, event);
      return;
    }
    // arrow down / home
    this.onListSingleKeyDown(keyCode, event);
  }

  handleOptionSingleClick(event) {
    const isMultiSelect = event.ctrlKey;
    const { value } = event.target.dataset;
    const isSelected = event.target.dataset.selected === 'true';
    const currentIndex = parseInt(event.target.dataset.index, 10);

    // check for shift click
    if (event.shiftKey) {
      const indexOfLastSelectedValue = this.getIndexOfLastSelectedValue();
      if (indexOfLastSelectedValue >= 0) {
        if (currentIndex < indexOfLastSelectedValue) {
          this.selectOptions(currentIndex, indexOfLastSelectedValue);
        } else {
          this.selectOptions(indexOfLastSelectedValue, currentIndex);
        }
        return;
      }
    }
    this.toggleValueSelection(value, !isSelected, isMultiSelect);
  }

  /**
   * @param {MouseEvent} event
   *
   * @returns {void}
   */
  handleOptionClick(event) {
    const { onDoubleClick } = this.props;
    const doubleClikDetail = 2;
    clearTimeout(this.timer);
    const { value } = event.target.dataset;
    this.listBoxRef.current.setAttribute(ariaActiveDescendent, event.target.id);
    if (event.detail === doubleClikDetail) {
      onDoubleClick(value);
      this.removeActivedescendant();
    } else {
      this.timer = setTimeout(() => {
        this.handleOptionSingleClick(event);
      }, 0);
    }
    removeClass(focusedRingClass);
  }

  handleCtrlKeyDownList(keyCode, event) {
    const listBox = this.listBoxRef.current;
    let selectedElement;
    if (keyCode === KEY_CODES.DOWN_ARROW || keyCode === KEY_CODES.HOME) {
      selectedElement = listBox.firstElementChild;
    }
    // arrow up /end
    if (keyCode === KEY_CODES.UP_ARROW || keyCode === KEY_CODES.END) {
      selectedElement = listBox.lastElementChild;
    }
    if (selectedElement) {
      event.preventDefault();
      removeClass(focusedRingClass);
      this.setLastSelectedOption(selectedElement);
      selectedElement.classList.add(focusedRingClass);
    }
  }

  handleCtrlKeyDownOption(keyCode, event, lastSelectedOption) {
    const selectedElement = this.getNextElementOnKeyOptionKeyPress(keyCode, lastSelectedOption);
    if (selectedElement) {
      event.preventDefault();
      removeClass(focusedRingClass);
      this.setLastSelectedOption(selectedElement);
      selectedElement.classList.add(focusedRingClass);
    }
  }

  onListSingleKeyDown(keyCode, event) {
    const listBox = this.listBoxRef.current;
    let selectedElement;
    if (keyCode === KEY_CODES.DOWN_ARROW || keyCode === KEY_CODES.HOME) {
      selectedElement = listBox.firstElementChild;
    }
    // arrow up /end
    if (keyCode === KEY_CODES.UP_ARROW || keyCode === KEY_CODES.END) {
      selectedElement = listBox.lastElementChild;
    }
    if (selectedElement) {
      event.preventDefault();
      this.selectSingleValue(selectedElement.dataset.value);
      this.setLastSelectedOption(selectedElement, event.ctrlKey);
    }
  }

  /**
   * @param {KeyboardEvent} event
   *
   * @returns {void}
   */
  onKeyDown(event) {
    const lastSelectedOption = this.getLastFocusedOption() || this.getLastSelectedOption();
    if (lastSelectedOption) {
      this.handleOptionKeyDown(event, lastSelectedOption);
    } else {
      this.handleListKeyDown(event);
    }
  }

  onFocus() {
    const lastSelectedOption = this.getLastFocusedOption() || this.getLastSelectedOption();
    if (lastSelectedOption) {
      this.listBoxRef.current.setAttribute(ariaActiveDescendent, lastSelectedOption.id);
    }
  }

  getNextElementOnKeyOptionKeyPress(keyCode, lastSelectedOption) {
    let element;
    const listBox = this.listBoxRef.current;
    switch (keyCode) {
      case KEY_CODES.END:
        element = listBox.lastElementChild;
        break;
      case KEY_CODES.HOME:
        element = listBox.firstElementChild;
        break;
      case KEY_CODES.UP_ARROW:
        element = lastSelectedOption.previousElementSibling;
        break;
      case KEY_CODES.DOWN_ARROW:
        element = lastSelectedOption.nextElementSibling;
        break;
      default:
    }
    return element;
  }

  setLastSelectedOption(element, ctrlKey) {
    if (element) {
      removeClass(focusedRingClass);
      this.lastSelectedValue = element.dataset.value;
      if (ctrlKey) {
        setTimeout(() => {
          element.classList.add(focusedRingClass);
        }, 0);
      }
      this.listBoxRef.current.setAttribute(ariaActiveDescendent, element.id);
      scrollIntoViewIfNeeded(this.listBoxRef.current, element);
    }
  }

  getLastSelectedOption() {
    if (!this.lastSelectedValue) {
      return null;
    }
    return Array.from(this.listBoxRef.current.children)
      .find((ele) => ((ele.dataset.value === this.lastSelectedValue) && ele.dataset.selected === 'true'));
  }

  getLastFocusedOption() {
    return Array.from(this.listBoxRef.current.children)
      .find((ele) => ele.classList.contains(focusedRingClass));
  }

  getSelectedOptionValues() {
    const { options } = this.props;
    const selected = [];
    options.forEach((opt) => {
      if (opt.selected) {
        selected.push(opt.value);
      }
    });
    return selected;
  }

  getIndexOfLastSelectedValue() {
    if (!this.lastSelectedValue) {
      return -1;
    }
    return Array.from(this.listBoxRef.current.children)
      .findIndex((ele) => (ele.dataset.value === this.lastSelectedValue) && ele.dataset.selected === 'true');
  }

  removeActivedescendant() {
    this.listBoxRef.current.removeAttribute(ariaActiveDescendent);
  }

  selectNextElement(element, event, onChange, onKeyDown) {
    if (element) {
      event.preventDefault();
      this.selectedValues = [element.dataset.value];
      onChange(this.selectedValues);
      this.setLastSelectedOption(element, event.ctrlKey);
    } else {
      if (MOVE_KEY_CODES.indexOf(event.keyCode) > -1) {
        this.selectLastFocusedElement();
        this.removeActivedescendant();
      }
      onKeyDown(event);
    }
  }

  selectLastFocusedElement() {
    const lastFocusedOptionElement = this.getLastFocusedOption();
    const { onChange } = this.props;
    if (lastFocusedOptionElement) {
      this.selectedValues.push(lastFocusedOptionElement.dataset.value);
      onChange(this.selectedValues);
    }
    removeClass(focusedRingClass);
  }

  /**
   * @param {Array<HTMLElement>} optionEleArr
   * @param {string} currentValue
   *
   * @returns {void}
   */
  selectToCurrentValue(optionEleArr, currentValue) {
    const { onChange } = this.props;
    this.selectedValues = [];
    // replaced for loop with every due to sonar issue
    optionEleArr.every((opt) => {
      this.selectedValues.push(opt.dataset.value);
      return currentValue !== opt.dataset.value;
    });
    onChange(this.selectedValues);
  }

  markAllOptionSelected() {
    const { options, onChange } = this.props;
    this.selectedValues = [];
    options.forEach((opt) => {
      this.selectedValues.push(opt.value);
    });
    onChange(this.selectedValues);
  }

  selectSingleValue(value) {
    const { onChange } = this.props;
    this.selectedValues = [value];
    onChange(this.selectedValues);
  }

  /**
   * @param {number} startIndex
   * @param {number} endIndex
   * @param {boolean} isMultiSelect
   * @returns {void}
   */
  selectOptions(startIndex, endIndex) {
    const { onChange } = this.props;
    this.selectedValues = [];
    const liArr = Array.from(this.listBoxRef.current.children);
    for (let i = startIndex; i <= endIndex; i += 1) {
      this.selectedValues.push(liArr[i].dataset.value);
    }
    onChange(this.selectedValues);
  }

  /**
   * @param {string} value
   * @param {boolean} isSelected
   * @param {boolean} isMultiSelect
   * @returns {void}
   */
  toggleValueSelection(value, isSelected, isMultiSelect) {
    const { onChange } = this.props;
    if (isMultiSelect) {
      if (isSelected) {
        this.selectedValues.push(value);
      } else {
        const index = this.selectedValues.findIndex((val) => val === value);
        this.selectedValues.splice(index, 1);
      }
    }
    if (!isMultiSelect && (isSelected || this.selectedValues.length > 0)) {
      this.selectedValues = [value];
    }
    this.lastSelectedValue = value;
    onChange(this.selectedValues);
  }

  /**
   * @returns {React.Component}
   */
  render() {
    const { options, id, controlKey } = this.props;
    this.selectedValues = this.getSelectedOptionValues();
    return (
      <div
        className="listbox-ul rdl-control"
        tabIndex="0"
        id={id}
        role="listbox"
        aria-multiselectable="true"
        ref={this.listBoxRef}
        aria-labelledby={`label-${id}`}
        onKeyDown={this.onKeyDown}
        removeActivedescendant={this.removeActivedescendant}
        onFocus={this.onFocus}
      >

        {options.map((option, i) => (
          <ListItem
            key={option.key}
            option={option}
            index={i}
            onClick={this.handleOptionClick}
            controlKey={controlKey}
          />
        ))}
      </div>
    );
  }
}

List.propTypes = {
  id: PropTypes.string.isRequired,
  controlKey: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

List.defaultProps = {
};
export default List;
