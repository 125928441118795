/**
 * @Description Fasat Expandable Panel component page
 * @FileName fasatExpandablePanel.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 13 May, 2021 10:15:58
 * @IssueID 950
 */
import React from 'react';
import PropTypes from 'prop-types';
import { ExpandablePanel } from '@manulife/mux';
import './fasatExpandablePanel.scss';

const FasatExpandablePanel = (props) => {
  const { children } = props;
  return (
    <div className="fasatExpandablePanel">
      <ExpandablePanel {...props}>{children}</ExpandablePanel>
    </div>
  );
};

FasatExpandablePanel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape()),
};

FasatExpandablePanel.defaultProps = {
  children: '',
};

export default FasatExpandablePanel;
