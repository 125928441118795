import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { statementsAndXMLActionTypes } from '../actions/actionTypes';

const initialState = {
  brGridData: [],
  brNonGridData: null,
  brJobDetails: null,
  batchRptJobStarted: null,
  brStartErr: false,
  brStopSucess: false,
  brStartSucess: false,
  brStopErr: null,
  serverErr: null,
  isJobReqestFirstTime: false,
};

const StatementsXMLlifeFeedsPartA = (state = { ...initialState }, action = null) => {
  switch (action.type) {
    case statementsAndXMLActionTypes.LOG_BATCH_REPORT_LIST_REQ:
      return {
        ...state,
        hideLoader: true,
      };
    case statementsAndXMLActionTypes.GET_BATCH_REPORT_LIST_RESPONSE:
      return {
        ...state,
        brGridData: action.data.RuDetails,
        brNonGridData: action.data.BrNonGrid,
      };
    case statementsAndXMLActionTypes.LOG_BATCH_REPORT_JOB_Detail:
      return {
        ...state,
        isJobReqestFirstTime: action.data,

      };
    case statementsAndXMLActionTypes.LOG_BATCH_START_REQ:
      return {
        ...state,
        brStopSucess: false,
        brStartErr: false,
      };
    case statementsAndXMLActionTypes.GET_BATCH_REPORT_START_RESPONSE:
      return {
        ...state,
        brStartSucess: action.data,
      };
    case statementsAndXMLActionTypes.GET_BATCH_REPORT_START_ERROR:
      return {
        ...state,
        hideLoader: true,
        brStartSucess: false,
        brStartErr: true,
      };
    case statementsAndXMLActionTypes.GET_BATCH_RPT_JOB_ERROR:
      return {
        ...state,
        batchRptJobDetailsError: action.data,
      };
    case statementsAndXMLActionTypes.GET_BATCH_REPORT_LIST_ERROR:
      return {
        ...state,
        batchRptListReqError: action.data,
      };
    default:
      return null;
  }
};

const StatementsXMLlifeFeedsPartB = (state = { ...initialState }, action = null) => {
  switch (action.type) {
    case statementsAndXMLActionTypes.RESET_SERVER_VALUE:
      return {
        ...state,
        serverErr: action?.data?.serverRequestError,
        hideLoader: false,
      };
    case statementsAndXMLActionTypes.RESET_STOP_START_ERRORS:
      return {
        ...state,
        serverErr: null,
        brStartSucess: false,
        brStopSucess: false,
        brStopErr: null,
      };

    case statementsAndXMLActionTypes.RESET_BATCH_RPT_DEFAULT_VALUES:
      return {
        ...initialState,
      };
    case statementsAndXMLActionTypes.GET_BATCH_RPT_JOB_RESPONSE:
      return {
        ...state,
        hideLoader: false,
        brJobDetails: action.data,
        brStartSucess: action.data.runStatus === 'RUNNING',
      };

    case statementsAndXMLActionTypes.BATCH_RPT_STOP_RES:
      return {
        ...state,
        hideLoader: false,
        brStartSucess: false,
        brStopSucess: action.data,
      };
    case statementsAndXMLActionTypes.BATCH_RPT_STOP_ERROR:
      return {
        ...state,
        hideLoader: true,
        brStopSucess: false,
        brStartSucess: false,
        brStopErr: action?.data?.serverRequestError,
      };

    case commonTypes.RESETSTORE:
      return initialState;
    default:
      return null;
  }
};

const StatementsXMLlifeFeeds = (state = { ...initialState }, action = null) => {
  const stateConfigPartA = StatementsXMLlifeFeedsPartA(state, action);
  const stateConfigPartB = StatementsXMLlifeFeedsPartB(state, action);
  if (stateConfigPartA !== null) {
    return stateConfigPartA;
  }
  if (stateConfigPartB !== null) {
    return stateConfigPartB;
  }
  return state;
};

export default StatementsXMLlifeFeeds;
