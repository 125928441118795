import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { codesAndHierarchyActionTypes, debitBalanceActionTypes, topProducersActionTypes } from '../actions/actionTypes';

const codeAndHierReducerHelper = (state, action) => {
  switch (action.type) {
    case codesAndHierarchyActionTypes.GET_ORG_SECURITY_DATA_RESPONSE:
      return {
        ...state,
        orgSecurity: action.data,
        loading: false,
      };
    case debitBalanceActionTypes.GET_CH_DEBIT_BALANCE_LIST_RESPONSE:
      return {
        ...state,
        chDebitBalanceListData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };

    case debitBalanceActionTypes.RESET_CH_DBEXPORT_SERVER_VALUE:
      return {
        ...state,
        chDBExportErr: action.data?.serverRequestError,
        loading: false,
      };

    case topProducersActionTypes.GET_EXT_TOP_PRODUCERS_LIST_RES:
      return {
        ...state,
        topProducersListData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };

    case topProducersActionTypes.RESET_EXT_TP_EXPORT_ERROS:
      return {
        ...state,
        tpExportErr: action.data?.serverRequestError,
        loading: false,
      };
    case codesAndHierarchyActionTypes.GET_CODE_NICKNAME_RESPONSE:
      return {
        ...state,
        editCodeNickName: action.data,
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

const CodeAndHierarchyReducer = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case codesAndHierarchyActionTypes.RESET_SERVER_VALUE:
      return {
        ...state,
        updateCodeNickNameError: action.data?.updateCodeNickNameError,
        exportRequestError: action.data?.exportRequestError,
        serverRequestError: action.data && action.data.serverRequestError,
        loading: false,
      };
    case codesAndHierarchyActionTypes.GET_CODES_AND_HIER_TREE_UPSTREAM_RESPONSE:
      return {
        ...state,
        upperHierArchy: action.data,
        loading: false,
      };
    case codesAndHierarchyActionTypes.GET_CODES_AND_HIER_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        loading: false,
      };
    case codesAndHierarchyActionTypes.GET_CODES_AND_HIER_MOVEMENT_RESPONSE:
      return {
        ...state,
        movementData: action.data,
        loading: false,
      };
    case codesAndHierarchyActionTypes.GET_LEVEL_RBAC_DATA_RESPONSE:
      return {
        ...state,
        levelRBAC: action.data,
        loading: false,
      };
    case codesAndHierarchyActionTypes.UPDATE_CODE_NICKNAME_RESPONSE:
      return {
        ...state,
        codeNickName: action.data,
        loading: false,
      };
    default:
      return codeAndHierReducerHelper(state, action);
  }
};

export default CodeAndHierarchyReducer;
