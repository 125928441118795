/* eslint-disable no-unused-vars */
/**
 * @Description This file contains all the Menu configuration for administration module
 * @FileName menuConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:41:03
 * @IssueID - 459, 311
 */
import { Chart } from '@manulife/mux-cds-icons';
import { t } from '../../../common/i18n';
import { navigate } from '../../../common/util';
import { aclKeysConstant, routeConstant, staticLabelKeys } from '../moduleConstants';

/**
 * Menu configuration for the module
 */
const desktopMenus = () => [
  {
    id: 'codeAndHierarchy',
    sequence: 1,
    icon: <Chart style={{ width: '20px', height: '20px' }} />,
    label: t(staticLabelKeys.CHC_TITLE_CODE_HIERACHY),
    aria: t(staticLabelKeys.CHC_TITLE_CODE_HIERACHY),
    urlPath: '#',
    url: '#',
    action: () => navigate('#'),
    aclKey: [aclKeysConstant.RBAC_EXT_CODES_AND_HIERARCHY],
    acl: true,
    showInBatchProcess: true,
    // external: true,
    subMenu: [{
      id: 'EXTCAH_MENU',
      label: t(staticLabelKeys.CHC_TITLE_CODE_HIERACHY),
      urlPath: routeConstant.CODE_HIERARCHY,
      url: '#',
      action: () => navigate(routeConstant.CODE_HIERARCHY),
      aclKey: [aclKeysConstant.RBAC_EXTCAH_MENU],
      acl: true,
      showInBatchProcess: true,
      // external: true,
    }],
  },
];
const mobileMenus = desktopMenus;
export { desktopMenus, mobileMenus };
