/**
 * @Description This file will have all the route information for administration module
 * @FileName routeConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:41:45
 * @IssueID - 318
 */
import { lazy } from 'react';
import { aclKeysConstant, routeConstant } from '../moduleConstants';

/**
 * Lazy load BusinessCardList
 */
export const CompStructureList = lazy(() => import(
  /* webpackChunkName:"AgencyBCR" */ '../containers/compStructureList/compStructureList'
));

export const AgencyHome = lazy(() => import(
  /* webpackChunkName:"AgencyHomeChunk" */ '../containers/home/home'
));
/**
 * All the module specific routes to be configured here
 */
export const AddCompStructure = lazy(() => import(
  /* webpackChunkName:"AgencyHomeChunk" */ '../components/addCompCalStructure/addCompCalStructure'
));
export const CompDetails = lazy(() => import(
  /* webpackChunkName:"AgencyHomeChunk" */ '../containers/compDetails/compDetails'
));
export const DefaultCompStructureList = lazy(() => import(
  /* webpackChunkName:"defaultCS" */ '../containers/defaultcompStructureList/defaultCompStructureList'
));
export const AddDefaultCompStructure = lazy(() => import(
  /* webpackChunkName:"AgencyHomeChunk" */ '../components/addDefaultCompCalStructure/addDefaultCompCalStructure'
));
export const LROEligibility = lazy(() => import(
  /* webpackChunkName:"AgencyLROEligibilityList" */ '../containers/lroEligiblityList/lroEligibilityList'
));
export const AddLROEligibility = lazy(() => import(
  /* webpackChunkName:"AgencyLROEligibilityList" */ '../components/addLROEligibility/addLROEligibility'
));

// #region Lazyload Rate master routes

export const RateMasterList = lazy(() => import(
  /* webpackChunkName:"RateMasterList" */ '../containers/rateMasterList/rateMasterList'
));

export const AddEditRateMaster = lazy(() => import(
  /* webpackChunkName:"RateMasterDetails" */ '../components/addEditRateMaster/addEditRateMaster'
));
export const RateMaster = lazy(() => import(
  /* webpackChunkName:"RateMasterDetails" */ '../containers/rateMaster/rateMaster'
));

// #endregion
export const SalesCreditSummary = lazy(() => import(
  /* webpackChunkName:"defaultCS" */ '../containers/salesCreditSummary/salesCreditSummary'
));
export const ExternalRateList = lazy(() => import(
  /* webpackChunkName:"defaultCS" */ '../containers/externalRate/externalRateList'
));
const routeConfig = [
  {
    path: routeConstant.COMPCAL_STRUCTURE_ROUTE,
    exact: true,
    component: CompStructureList,
    id: 'CompCalHome',
    title: 'FASAT - Comp Cal Home',
    pageName: 'FASAT - Comp Cal Home', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.CS_CARD_RULES],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.COMPCAL_STRUCTURE_ADD_ROUTE,
    exact: true,
    component: AddCompStructure,
    id: 'AdminBusinessCardList',
    title: 'FASAT - Compcal card rules',
    pageName: 'FASAT - Compcal card rules add', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.CS_ADD],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.COMPCAL_STRUCTURE_EDIT_ROUTE,
    exact: true,
    component: AddCompStructure,
    id: 'AdminBusinessCardList',
    title: 'FASAT - Compcal card rules edit',
    pageName: 'FASAT - Compcal card rules edit', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.CS_EDIT],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.COMPCAL_TREE_STRUCTURE_ROUTE,
    exact: true,
    component: CompDetails,
    id: 'AdminBusinessCardDetail',
    title: 'FASAT - Business card detail',
    pageName: 'FASAT - Business card detail', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_CS_TREEVIEW],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.DEFAULT_COMPCAL_STRUCTURE_ROUTE,
    exact: true,
    component: DefaultCompStructureList,
    id: 'DefaultCompCalHome',
    title: 'FASAT - Default CompCal Home',
    pageName: 'FASAT - Default CompCal Home', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.DCS_CARD_RULES],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.DEFAULT_COMPCAL_STRUCTURE_ADD_ROUTE,
    exact: true,
    component: AddDefaultCompStructure,
    id: 'DefaultCompCalAdd',
    title: 'FASAT - Default CompCal Add',
    pageName: 'FASAT - Default CompCal Add', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.DCS_ADD],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.DEFAULT_COMPCAL_STRUCTURE_EDIT_ROUTE,
    exact: true,
    component: AddDefaultCompStructure,
    id: 'DefaultCompCalEdit',
    title: 'FASAT - Default CompCal edit',
    pageName: 'FASAT - Default CompCal edit', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.DCS_EDIT],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.LRO_ROUTE,
    exact: true,
    component: LROEligibility,
    id: 'AdminLROEligibility',
    title: 'FASAT - LRO Eligibility',
    pageName: 'FASAT - LRO Eligibility', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_LRO_MENU],
    public: false,
    showInBatchProcess: false,
  },
  {
    path: routeConstant.LRO_ADD_ROUTE,
    exact: true,
    component: AddLROEligibility,
    id: 'AdminLROEligibilityAdd',
    title: 'FASAT - LRO Eligibility add',
    pageName: 'FASAT - LRO Eligibility add', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_LRO_ADD],
    public: false,
    showInBatchProcess: false,
  },
  {
    path: routeConstant.LRO_EDIT_ROUTE,
    exact: true,
    component: AddLROEligibility,
    id: 'AdminLROEligibilityEdit',
    title: 'FASAT - LRO Eligibility edit',
    pageName: 'FASAT - LRO Eligibility edit', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_LRO_EDIT],
    // acl: true,
    public: false,
    showInBatchProcess: false,
  },
  {
    path: routeConstant.RATE_MASTER_LIST_ROUTE,
    exact: true,
    component: RateMaster,
    id: 'rateMasterList',
    title: 'FASAT - Rate Master List',
    pageName: 'FASAT - Rate Master List', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_RM_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.RATE_MASTER_ADD,
    exact: true,
    component: AddEditRateMaster,
    id: 'rateMasterAdd',
    title: 'FASAT - Add Rate Master',
    pageName: 'FASAT - Add Rate Master', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_RM_ADD],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.RATE_MASTER_EDIT,
    exact: true,
    component: AddEditRateMaster,
    id: 'rateMasterEdit',
    title: 'FASAT - Edit Rate Master',
    pageName: 'FASAT - Edit Rate Master', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_RM_EDIT],
    acl: true,
    public: false,
  },
  // #endregion
  {
    path: routeConstant.SCS_ROUTE,
    exact: true,
    component: SalesCreditSummary,
    id: 'SalesCreditSummary',
    title: 'FASAT - SalesCreditSummary',
    pageName: 'FASAT - SalesCreditSummary', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_SCS_MENU],
    acl: true,
    public: false,
    showInBatchProcess: false,
  },
  {
    path: routeConstant.EXTERNAL_RATE_ROUTE,
    exact: true,
    component: ExternalRateList,
    id: 'AdminBusinessCardList',
    title: 'FASAT - Business card rules',
    pageName: 'FASAT - Business card rules', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: aclKeysConstant.RBAC_EXRATE_MENU,
    acl: false,
    showInBatchProcess: true,
    public: true,
    external: true,
    dbMapId: 'EXTERNAL_RATES_MENU',
  },
];

export default routeConfig;
