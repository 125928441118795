/* eslint-disable react/prop-types */
/**
 * @Description Container for Actionable Text Input
 * @FileName index.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 23 March, 2021 01:31:24
 * @IssueID 720
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './scss/style.scss';
import { FasatChip, FasatTextInput, FasatActionButton } from '../../../components';

const ActionableTextInput = ({
  inlineButtonText,
  placeholder,
  errors,
  changeValues,
  type,
  values,
  label,
  ariaLabel,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [arr, setArr] = useState([]);

  useEffect(() => {
    if (values) {
      const castingValues = typeof values === 'string' ? values.split(',') : values;
      setArr(castingValues);
    } else {
      setArr([]);
    }
  }, [values]);

  /**
  * Constant values for classNames, placeholders, types
  */
  const actionableTextInput = 'actionableTextInput';
  const actionableTextInputWithLabel = 'actionableTextInputWithLabel';
  const inputId = 'id';
  const inputName = 'name';
  const selectedContainer = 'selectedContainer';
  const addLinkContainer = 'addLinkContainer';

  /**
  * @param {String} item Removes the selected item
  */
  const deleteItem = (item) => {
    const filteredItem = arr.filter((value) => item !== value);
    setArr(filteredItem);
    changeValues(filteredItem);
  };

  /**
  * Adds the entered textbox value as a chip
  */
  const addItem = () => {
    // VALUE SPLIT WITH PIPE
    inputValue.split('|').forEach((initValue) => {
      const value = initValue.trim();
      if (value.length > 0 && arr.indexOf(value) < 0) {
        arr.push(value);
        setArr(arr);
        setInputValue('');
        changeValues(arr);
      }
    });
  };
  return (
    <div className={label ? actionableTextInputWithLabel : actionableTextInput}>
      <FasatTextInput
        errors={errors}
        id={inputId}
        name={inputName}
        value={inputValue}
        onChange={(newValue) => setInputValue(newValue.target.value)}
        placeholder={placeholder}
        label={label}
        aria-label={ariaLabel}
        onInputEnabled={type === 'number'}
        inputNumWithPipe={type === 'number'}
      />
      <div className={addLinkContainer}>
        <FasatActionButton onClick={addItem}>{inlineButtonText}</FasatActionButton>
      </div>
      <div className={selectedContainer}>
        {arr && arr.map((si) => (<FasatChip key={si} data={si} onClick={() => deleteItem(si)} />))}
      </div>
    </div>
  );
};

ActionableTextInput.propTypes = {
  inlineButtonText: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  changeValues: PropTypes.func,
  errors: [
    {
      id: PropTypes.string,
      isError: PropTypes.bool,
      msg: PropTypes.string,
    },
  ],
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
};

ActionableTextInput.defaultProps = {
  inlineButtonText: '',
  placeholder: '',
  type: 'text',
  changeValues: () => undefined,
  errors: [
    {
      id: '',
      isError: false,
      msg: '',
    },
  ],
  label: '',
  ariaLabel: '',
};

export default ActionableTextInput;
