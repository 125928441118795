import CodesAndHierarchyApiConfig from './codesAndHierarchyApiConfig';
import { environment } from '../../../common/util/utility';

const envData = environment();

const apiConfig = {
  EXT_ADVISOR_DETAILS: {
    endpoint: `${envData.apiHost}/api/externaladvisordetail/`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  EXT_ADVISOR_REQUIREMENT_LIST: {
    endpoint: `${envData.apiHost}/api/externaladvisordetail/requirementlist`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  EXT_ADVISOR_REQUIREMENT_EXPORT: {
    endpoint: `${envData.apiHost}/api/externaladvisordetail/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },

  EXT_CH_DETAIL_REQUEST_LIST: {
    endpoint: `${envData.apiHost}/api/detailedActivity/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  EXT_CH_MS_ACTIVITY_REQUEST_LIST: {
    endpoint: `${envData.apiHost}/api/detailedActivity/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_CH_MS_ACTIVITY_REQUEST_LIST_FIRST_LEVEL: {
    endpoint: `${envData.apiHost}/api/msactivity/getfirstleveldata`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_CH_MS_ACTIVITY_REQUEST_LIST_DETAIL_FIRST_LEVEL: {
    endpoint: `${envData.apiHost}/api/msactivity/getdetailfirstleveldata`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_CH_MS_ACTIVITY_REQUEST_LIST_ALL_LEVEL: {
    endpoint: `${envData.apiHost}/api/msactivity/getdetailleveldata`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_CH_MS_ACTIVITY_REQUEST_LIST_ALL_LEVEL_EXPORT: {
    endpoint: `${envData.apiHost}/api/msactivity/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  CH_ADVISOR_DETAILS_FLAG_REQ: {
    endpoint: `${envData.apiHost}/api/externaladvisordetail/advisordetail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  EXT_DETAIL_ACTIVTI_EXPORT: {
    endpoint: `${envData.apiHost}/api/detailedActivity/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },

  ...CodesAndHierarchyApiConfig,
};

export default apiConfig;
