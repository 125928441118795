import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { scsActionTypes } from '../actions/actionTypes';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const getListData = (data) => ({
  isDistinct: false,
  data: data.data,
  totalSalesCredit: data.totalSalesCredit,
});

const SalesCreditList = (state = { }, action = null) => {
  switch (action.type) {
    // log request
    case scsActionTypes.LOG_SCS_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case scsActionTypes.GET_SCS_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case scsActionTypes.GET_SCS_LIST_RESPONSE:
      return {
        ...state,
        listData: getListData(action.data),
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case scsActionTypes.RESET_SCS_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case scsActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return null;
    default:
      return null;
  }
};

const SalesCreditSummary = (state = { }, action = null) => {
  const stateReqRes = SalesCreditList(state, action);
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default SalesCreditSummary;
