import moment from 'moment';
import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { repReActionTypes } from '../actions/reportRepositoryActionTypes';
import { updateFilterReducer, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'ReportRepositoryFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: moment().format(),
  loading: true,
});

const ReportRepositoryFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case repReActionTypes.UPDATE_CSR_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case repReActionTypes.REMOVE_CSR_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case repReActionTypes.UPDATE_CSR_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const ReportRepositoryFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case repReActionTypes.LOG_CSR_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case repReActionTypes.GET_CSR_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: moment().format(),
        loading: false,
      };
    // store response
    case repReActionTypes.GET_CSR_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: moment().format(),
        loading: false,
      };
    case repReActionTypes.RESET_CSR_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case repReActionTypes.UPDATE_CSR_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const ReportRepositoryFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = ReportRepositoryFilterListConfig(state, action);
  const stateReqRes = ReportRepositoryFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ReportRepositoryFilterList;
