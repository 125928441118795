/* eslint-disable react/no-danger */
/**
 * @Description Save / Save as filter modal container
 * @FileName saveFilter.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 23 June, 2021 05:17:13
 * @IssueID Issue Detail
 */

import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { FasatButtonSelector,
  FasatCheckbox,
  FasatDropdown,
  FasatFormElement,
  FasatTextArea,
  FasatTextInput,
  FasatMessageCard,
} from '../..';
import { formatRoleList, getErrors } from '../../../util';
import { addRuleConfig, addRules, addValidationSchema } from './addEditFilterRuleConfig';
import showFasatNotification from '../../fasatQuickActionNotification/showFasatNotification';
import { checkDirtyData, resetUpsertError, upsertFilter } from '../../../redux/actions/commonActions';
import FasatModal from '../../fasatModal/fasatModal';
import { globalConstants, staticCommonLabelKeys } from '../../../moduleConstants';
import SaveAsDefault from './saveAsDefault';
import FasatLabel from '../../fasatLabel/fasatLabel';
import { configureReqParm, formatFilterCriteriaParams, formatOrder } from '../filterUtil';
import UserContext from '../../../../../userContext';

let formik;
const CREATE = 'CREATE';
const UPDATE = 'UPDATE';
const { formRow, formElement, colSize1, serverErrorMsgContainer, colSize2, notificationTime } = globalConstants;
const modalWidth = '560px';
const alterListPos = 'alterListPos roleList';
const roleListOptionsLength = 3;
const { external } = globalConstants;
const externalUser = external;

const checkUpsertDefaultFilter = (upsertData) => ((upsertData.isSystemFilter)
  || (!upsertData.isSystemFilter && upsertData.isDefault));

const checkPreUserDefaultFilter = (preUserDefaultFilter, upsertData) => (preUserDefaultFilter
   && (!upsertData.isSystemFilter && !upsertData.isDefault)
  && (preUserDefaultFilter === upsertData.name));

/**
   * Convert string to array
   * @param {boolean} isShared
   * @param {string} sharedWith
   * @returns
   */
export const formatShareWith = (isShared, sharedWith) => {
  if (isShared) {
    return sharedWith ? sharedWith.split(',') : [];
  }
  return [];
};

const formatDateTypPram = (tempPram) => tempPram.map((item) => configureReqParm(item));
/**
     * Initiate Action CALL
     */
const submitFilter = (updateFilterAction, dispatch) => {
  const { preUserDefaultFilter } = formik.values;
  const tempPram = formik.values.custFilCreteria && formatFilterCriteriaParams(formik.values.custFilCreteria);
  let parameter = [];
  if (tempPram) {
    parameter = formatDateTypPram(tempPram);
  }
  const formatObject = {
    action: formik.values.action,
    filter: {
      id: formik.values.action === UPDATE ? formik.values.id : '',
      owner: formik.values.owner,
      entityId: formik.values.entityId,
      name: formik.values.name,
      description: formik.values.description,
      isDefault: formik.values.isDefault || false,
      isShared: JSON.parse(formik.values.isShared),
      isSystemFilter: false,
      sharedWith: formik.values.isShared !== JSON.stringify(false)
        ? formatShareWith(formik.values.isShared, formik.values.sharedWith) : [],
      detail: {
        parameters: parameter,
        groupByParameters: formik.values.customColumnGroupBy
        && formik.values.customColumnGroupBy.map(({ key, operator }) => (
          { key, operator })),
        isDistinct: formik.values.isDistinct,
        sortOptions: formik.values.customColumnSorting
                && formik.values.customColumnSorting.map(({ key, asc }) => ({ key, asc })),
        columnOrders: formik.values.columnOrder && formatOrder(formik.values.columnOrder),
      },
    },
  };
  const requestPayload = {
    formatObject,
    preUserDefaultFilter,
    setAction: updateFilterAction,
  };
  dispatch(upsertFilter(requestPayload));
};
export const validateSubmit = (isSystemDefaultFilter, updateFilterAction, dispatch, userDefaultFilterID,
  setDisplayDefaultFilterModal) => {
  if (isSystemDefaultFilter) {
    submitFilter(updateFilterAction, dispatch);
  } else if (!userDefaultFilterID) {
    submitFilter(updateFilterAction, dispatch);
  } else if (userDefaultFilterID !== formik.values.id) {
    setDisplayDefaultFilterModal(true);
    if (document.getElementsByClassName('saveFilterModal') && document.querySelector('.saveFilterModal')) {
      document.querySelector('.saveFilterModal').parentElement.style.zIndex = '99996';
    }
  } else {
    submitFilter(updateFilterAction, dispatch);
  }
};

const fetchHandleSave = (dispatch, sharedFilterCount, validateAndSubmit, updateFilterAction, setValidateOnChange) => {
  setValidateOnChange(true);
  dispatch(resetUpsertError());
  formik.validateForm().then(() => {
    if ((formik.values.name && formik.values.description) && formik.isValid) {
      if (formik.values.isDefault && sharedFilterCount) {
        validateAndSubmit();
      } else {
        submitFilter(updateFilterAction, dispatch);
      }
    }
  });
};

const cancelData = (cancelEventData, flag) => {
  if (!flag && !formik.dirty && cancelEventData) {
    cancelEventData.close();
  }
};

const fetchRoleList = (roleList, setRoleListOptions) => {
  if (roleList) {
    setRoleListOptions(formatRoleList(roleList));
  }
};

export const fetchSaveError = (t, errItem, screenName) => (
  <div>
    {errItem.errorMessageId
      ? <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessageId, { screenName }) }} />
      : <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessage) }} />}
  </div>
);

const displayErrMsg = (t, serverErrors, screenName) => (
  <div className={serverErrorMsgContainer}>
    <FasatMessageCard
      titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
      variant="primary_alert"
    >
      {serverErrors.map((errItem) => (
        fetchSaveError(t, errItem, screenName)
      ))}
    </FasatMessageCard>
  </div>
);

const row1 = (t, serverErrors, formRuleIDs, formRuleConfig) => (
  <div className={formRow}>
    <div className={`${formElement} ${colSize1}`}>
      {formik.values.action === CREATE && (
      <FasatFormElement
        direction="bottom"
        id="nameForm"
        errors={getErrors('name', formik, serverErrors, formRuleIDs.name,
          formRuleConfig, false, t)}
      >
        <FasatTextInput
          id="name"
          name="name"
          ariaRequired
          label={t(staticCommonLabelKeys.FILTER_LABEL_NAME)}
          {...formik.getFieldProps('name')}
          errors={getErrors('name', formik, serverErrors, formRuleIDs.name, formRuleConfig, true, t)}
        />
      </FasatFormElement>
      )}
      {formik.values.action === UPDATE && (
      <>
        <FasatLabel>{t(staticCommonLabelKeys.FILTER_LABEL_NAME)}</FasatLabel>
        <FasatLabel isContent>{formik.values.name}</FasatLabel>
      </>
      )}
    </div>
  </div>
);

const row2 = (t, serverErrors, formRuleIDs, formRuleConfig) => (
  <div className={formRow}>
    <div className={`${formElement} ${colSize1}`}>
      <FasatFormElement
        direction="bottom"
        id="descriptionForm"
        errors={getErrors('description', formik, serverErrors, formRuleIDs.description, formRuleConfig, false, t)}
      >
        <FasatTextArea
          id="description"
          name="description"
          ariaRequired
          label={t(staticCommonLabelKeys.COMMON_LABEL_DESCRIPTION)}
          maxLength={100}
          {...formik.getFieldProps('description')}
          errors={getErrors('description', formik, serverErrors, formRuleIDs.description,
            formRuleConfig, true, t)}
        />
      </FasatFormElement>
    </div>
  </div>
);

const row3 = (t, roleListOptions, serverErrors, formRuleIDs, formRuleConfig) => (
  <div className={formRow}>
    <div className={`${formElement} ${colSize2}`}>
      <FasatButtonSelector
        values={[
          { label: t(staticCommonLabelKeys.COMMON_BUTTON_YES), value: 'true' },
          { label: t(staticCommonLabelKeys.COMMON_BUTTON_NO), value: 'false' },
        ]}
        onHandleChange={(val) => formik.setFieldValue('isShared', val)}
        id="isShared"
        label={t(staticCommonLabelKeys.FILTER_LABEL_SHARE_FILTER)}
        name="isShared"
        {...formik.getFieldProps('isShared')}
        value={formik.values && formik.values.isShared}
      />
    </div>
    {formik.values.isShared === 'true' && (
    <div className={`${formElement} ${colSize2} ${roleListOptions.length > roleListOptionsLength
            && alterListPos}`}
    >
      <FasatFormElement
        direction="bottom"
        id="sharedWithForm"
        errors={getErrors('sharedWith', formik, serverErrors, formRuleIDs.sharedWith, formRuleConfig, false, t)}
      >
        <FasatDropdown
          dropdownItems={roleListOptions}
          isMultiselect
          id="sharedWith"
          onHandleChange={(val) => formik.setFieldValue('sharedWith', val)}
          label={t(staticCommonLabelKeys.COMMON_LABEL_ROLE)}
          name="sharedWith"
          placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
          {...formik.getFieldProps('sharedWith')}
          selected={0}
          searchEnabled
          searchPlaceholderText={t(staticCommonLabelKeys.COMMON_BUTTON_SEARCH)}
          errors={getErrors('sharedWith', formik, serverErrors, formRuleIDs.sharedWith, formRuleConfig, true, t)}
        />
      </FasatFormElement>
    </div>
    )}
  </div>
);

const row4 = (t) => (
  <div className={formRow}>
    <div className={`${formElement}`}>
      <FasatFormElement direction="bottom" id="isDefaultForm">
        <FasatCheckbox
          id="isDefault"
          name="isDefault"
          labelText={t(staticCommonLabelKeys.FILTER_LABEL_MARK_USER_DEFAULT)}
          onHandleChange={(val) => formik.setFieldValue('isDefault', val)}
          defaultChecked={formik.values.isDefault}
        />
      </FasatFormElement>
    </div>
  </div>
);

const displayConfirmModal = (displayDefaultFilterModal, setDisplayDefaultFilterModal, t, confirmationHandler) => (
  <FasatModal
    display={displayDefaultFilterModal}
    modalWidth={modalWidth}
    primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_MAKE_DEFAULT)}
    secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_NO)}
    primaryTopic="CONFIRM_DEFALT"
    modalCosed={() => setDisplayDefaultFilterModal(false)}
    modalClass="modalWithoutHeading"
    staydiscard
    defaultCheck
  >
    <SaveAsDefault
      onConfirmationTopic="CONFIRM_DEFALT"
      onConfirmationClick={confirmationHandler}
      onCancelTopic={t(staticCommonLabelKeys.COMMON_BUTTON_NO)}
    />
  </FasatModal>
);
const saveCancelTopic = ({ onSaveTopic, saveSubscriptionIds,
  setSaveEventData,
  handleSaveClick,
  setSaveSubscriptionIds,
  onCancelTopic,
  setCancelEventData }) => {
  if (onSaveTopic && onSaveTopic !== '' && (saveSubscriptionIds || saveSubscriptionIds.length === 0)
  ) {
    const subId = PubSub.subscribe(onSaveTopic, (msg, data) => {
      formik.handleSubmit();
      setSaveEventData(data);
      handleSaveClick();
    });
    saveSubscriptionIds.push(subId);
    setSaveSubscriptionIds(saveSubscriptionIds);
  }
  if (onCancelTopic && onCancelTopic !== '') {
    const subId = PubSub.subscribe(onCancelTopic, (msg, data) => setCancelEventData(data));
    saveSubscriptionIds.push(subId);
    setSaveSubscriptionIds(saveSubscriptionIds);
  }
};

const SaveFilter = ({ onSaveTopic, onCancelTopic, formData, userDefaultFilterID, updateDefaultFilterID,
  isSystemDefaultFilter, updateFilterAction, screenName, sharedFilterCount, renderFilterList, resetNewFilterFlag, entityFilterConfig }) => {
  const selectedFilter = useSelector((state) => state[entityFilterConfig.REDUX_STATE].selectedFilter);
  const [t] = useTranslation();
  const userType = useContext(UserContext);
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);
  const [saveEventData, setSaveEventData] = useState(null);
  const [serverErrors, setServerErrors] = useState([]);
  const [roleListOptions, setRoleListOptions] = useState([]);
  const [displayDefaultFilterModal, setDisplayDefaultFilterModal] = useState(false);
  const formRuleConfig = addRuleConfig;
  const formRuleIDs = addRules;
  const dispatch = useDispatch();
  const { upsertRequestError, upsertData } = useSelector((state) => state.ApplicationState);
  const roleList = useSelector((state) => state.ApplicationStateRBAC.roleList);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [cancelEventData, setCancelEventData] = useState(null);
  const [flag, setFlag] = useState(false);
  formik = useFormik({
    validateOnChange,
    validateOnBlur: validateOnChange,
    initialValues: formData,
    validationSchema: () => addValidationSchema(t),
  });
  const confirmationHandler = () => submitFilter(updateFilterAction, dispatch);
  const validateAndSubmit = () => validateSubmit(isSystemDefaultFilter, updateFilterAction, dispatch, userDefaultFilterID, setDisplayDefaultFilterModal);
  const handleSaveClick = () => fetchHandleSave(dispatch, sharedFilterCount, validateAndSubmit, updateFilterAction, setValidateOnChange);
  useEffect(() => cancelData(cancelEventData, flag), [flag, cancelEventData]);
  useEffect(() => dispatch(checkDirtyData(formik.dirty)), [formik.dirty]);
  useEffect(() => {
    const paramsOpt = { onSaveTopic, saveSubscriptionIds, setSaveEventData, dispatch };
    saveCancelTopic({ ...paramsOpt, setSaveSubscriptionIds, onCancelTopic, setCancelEventData, handleSaveClick });
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);
  useEffect(() => {
    const { preUserDefaultFilter } = formData;
    if (upsertData) {
      const filterName = formik.values.name;
      saveEventData?.close();
      dispatch(checkDirtyData(false));
      if (!sharedFilterCount) {
        renderFilterList();
      }
      const successMsg = t(staticCommonLabelKeys.FILTER_SAVE_SUCCESS, { filterName, interpolation: { escapeValue: false } });
      showFasatNotification(successMsg, notificationTime);
      if (checkUpsertDefaultFilter(upsertData)) {
        updateDefaultFilterID(upsertData.id);
      }
      if (checkPreUserDefaultFilter(preUserDefaultFilter, upsertData)) {
        updateDefaultFilterID(null);
      }
      resetNewFilterFlag();
      window.scrollTo(0, 0);
      dispatch(resetUpsertError());
    }
    if (upsertRequestError) {
      setServerErrors(upsertRequestError);
    }
  }, [upsertRequestError, upsertData]);
  useEffect(() => fetchRoleList(roleList, setRoleListOptions), [roleList]);
  useEffect(() => {
    if (formData.action === UPDATE) {
      const test1 = JSON.stringify(formik?.values?.custFilCreteria.map(({ uuid, ...rest }) => ({ ...rest })));
      const cloneSelParam = selectedFilter.detail.parameters;
      selectedFilter.detail.parameters.map((item, index) => {
        cloneSelParam[index].values = item.values.toString();
        return item;
      });
      const test2 = JSON.stringify(cloneSelParam);
      const customColumnSorting = JSON.stringify(selectedFilter.detail.sortOptions);
      const columnOrder = JSON.stringify(selectedFilter.detail.columnOrders);
      const columnOrderTEST = JSON.stringify(formik?.values?.columnOrder);
      const paramstest = formik?.values?.customColumnSorting;
      const groupByParams = JSON.stringify(selectedFilter?.detail?.groupByParameters || []);
      const customColumnGroupBy = formik?.values?.customColumnGroupBy || [];
      const groupByCustomString = JSON.stringify(customColumnGroupBy.map(({ uuid, ...rest }) => ({ ...rest })));
      const isGroupByChanged = groupByParams !== groupByCustomString;
      const sortingresult = JSON.stringify(paramstest.map(({ uuid, ...rest }) => ({ ...rest })));
      if (test1 !== test2 || columnOrderTEST !== columnOrder || customColumnSorting !== sortingresult
        || isGroupByChanged) {
        dispatch(checkDirtyData(true));
        setFlag(true);
      }
    }
  }, [formik?.values?.custFilCreteria]);
  return (
    <>
      <form id="addEditFilter" onSubmit={(e) => e.preventDefault()}>
        {serverErrors.length > 0 && (displayErrMsg(t, serverErrors, screenName))}
        {row1(t, serverErrors, formRuleIDs, formRuleConfig)}
        {row2(t, serverErrors, formRuleIDs, formRuleConfig)}
        {externalUser !== userType && row3(t, roleListOptions, serverErrors, formRuleIDs, formRuleConfig)}
        {row4(t)}
      </form>
      {displayConfirmModal(displayDefaultFilterModal, setDisplayDefaultFilterModal, t, confirmationHandler)}
    </>
  );
};

SaveFilter.propTypes = {
  onSaveTopic: PropTypes.string.isRequired,
  userDefaultFilterID: PropTypes.string,
  updateDefaultFilterID: PropTypes.func,
  isSystemDefaultFilter: PropTypes.func.isRequired,
  onCancelTopic: PropTypes.string,
  updateFilterAction: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    action: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    isShared: PropTypes.string,
    // sharedWith: PropTypes.shape([]),
    owner: PropTypes.string,
    isDefault: PropTypes.bool,
    // customColumnSorting: PropTypes.shape({}),
    // customFilterCreteria: PropTypes.shape({}),
    // columnOrder: PropTypes.shape({}),
    entityId: PropTypes.string,
    preUserDefaultFilter: PropTypes.string.isRequired,
    sharedWith: PropTypes.arrayOf(PropTypes.shape(
      {
      },
    )),
    customColumnGroupBy: PropTypes.arrayOf(PropTypes.shape(
      {
      },
    )),
    customColumnSorting: PropTypes.arrayOf(PropTypes.shape(
      {
      },
    )),
    customFilterCreteria: PropTypes.arrayOf(PropTypes.shape(
      {
      },
    )),
    columnOrder: PropTypes.arrayOf(PropTypes.shape(
      {
      },
    )),
  }),
  screenName: PropTypes.string.isRequired,
  sharedFilterCount: PropTypes.number.isRequired,
  renderFilterList: PropTypes.func,
  resetNewFilterFlag: PropTypes.func,
  entityFilterConfig: PropTypes.shape({
    SEARCH_ACTION: PropTypes.string,
    UPDATE_CONFIGURATION: PropTypes.string,
    UPDATE_TABLE_CONFIGURATION: PropTypes.string,
    UPDATE_FILTER_CONFIG: PropTypes.string,
    REDUX_STATE: PropTypes.string,
    REMOVE_USER_DEFAULT_FILTER: PropTypes.string,
  }).isRequired,
};

SaveFilter.defaultProps = {
  onCancelTopic: '',
  userDefaultFilterID: '',
  updateDefaultFilterID: () => undefined,
  renderFilterList: () => undefined,
  resetNewFilterFlag: () => undefined,
  formData: {
    action: CREATE,
    id: '',
    name: '',
    description: '',
    isShared: 'false',
    // sharedWith: PropTypes.shape([]),
    owner: '',
    isDefault: false,
    // customColumnSorting: [],
    customFilterCreteria: null,
    columnOrder: null,
    entityId: null,
    onSaveTopic: '',
    sharedWith: [
      {
      },
    ],
    customColumnGroupBy: [
      {
      },
    ],
    customColumnSorting: [
      {
      },
    ],
    preUserDefaultFilter: ' ',
  },
};

export default SaveFilter;
