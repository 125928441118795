/**
 * @Description English reources for activity
 * @FileName english.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:13:37
 * @IssueID NA
 */

const activityResourcesEnglish = {
  'activity.menu': 'activity',
};

export default activityResourcesEnglish;
