/* eslint-disable no-debugger */
/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { csActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'CsFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const updateFilterConfigResetSelectedFilter = (state, action) => ({
  ...state,
  selectedFilter: null,
  systemDefaultFilter: action.data.systemDefault || null,
  userDefaultFilter: action.data.userDefault || null,
});

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});

const logBcrListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});
// Required for reducing cognitive complexity of main function
const csFilterListHelper = (state, action) => {
  switch (action.type) {
    case csActionTypes.RESET_CS_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        nextagentName: null,
        agentName: null,
        loading: false,
      };
    case csActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);

    case csActionTypes.GET_CS_TREE_RES:
      return {
        ...state,
        treeData: action.data,
      };

    case csActionTypes.RESET_TREE_DATA:
      return {
        ...state,
        treeData: null,
      };
    case csActionTypes.GET_RATE_TABLE_RES:
      return {
        ...state,
        rateTable: action.data,
      };

    case csActionTypes.RESET_RATE_TABLE_DATA:
      return {
        ...state,
        rateTable: null,
      };
    case csActionTypes.RESET_VALIDATE_DELETE_CS:
      return {
        ...state,
        validateDeleteCS: null,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return state;
  }
};

const CsFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case csActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case csActionTypes.UPDATE_FILTER_CONFIG_RESET_SELECTED:
      return updateFilterConfigResetSelectedFilter(state, action);
    case csActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case csActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case csActionTypes.LOG_COMPCAL_LIST_REQUEST:
      return logBcrListRequest(state, action);
    case csActionTypes.GET_CS_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case csActionTypes.GET_CS_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case csActionTypes.GET_COMP_STRUCTURE_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case csActionTypes.VALIDATE_DELETE_CS_RESPONSE:
      return {
        ...state,
        validateDeleteCS: action.data,
        loading: false,
      };
    default:
      return csFilterListHelper(state, action);
  }
};

export default CsFilterList;
