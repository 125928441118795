/* eslint-disable no-unused-vars */
/**
 * @Description This file will have all the route information for administration module
 * @FileName routeConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:41:45
 * @IssueID - 318
 */
import { lazy } from 'react';
import { aclKeysConstant, routeConstant } from '../moduleConstants';

/**
 *
 * Lazy load CodeAndHierArchy
 */
export const CodeAndHierArchy = lazy(() => import(
  /* webpackChunkName:"CodeAndHierArchy" */ '../containers/codesAndHierarchy/codesAndHierarchy'
));

export const AdvisorDetails = lazy(() => import(
  /* webpackChunkName:"CodeAndHierArchy" */ '../containers/advisorDetails/advisorDetails'
));

export const CodeAndHierArchyDetails = lazy(() => import(
  /* webpackChunkName:"CodeAndHierArchy" */ '../containers/codesAndHierarchyDetails/chDetails'
));

const routeConfig = [
  {
    path: routeConstant.CODE_HIERARCHY,
    exact: true,
    component: CodeAndHierArchy,
    id: 'CodeAndHierArchy',
    title: 'FASAT - Code and hierarchy',
    pageName: 'FASAT - Code and hierarchy', // page name to send in error message log service
    authenticationRequired: false,
    // aclKey: [aclKeysConstant.BUSINESS_CARD_RULES],
    acl: false,
    showInBatchProcess: true,
    public: true,
    external: true,
  },
  {
    path: routeConstant.CODE_HIERARCHY_DETAILS,
    exact: true,
    component: CodeAndHierArchyDetails,
    id: 'CodeAndHierArchyDetails',
    title: 'FASAT - Code and hierarchy Details',
    pageName: 'FASAT - Code and hierarchy Details', // page name to send in error message log service
    authenticationRequired: false,
    // aclKey: [aclKeysConstant.BUSINESS_CARD_RULES],
    acl: false,
    showInBatchProcess: true,
    public: true,
    external: true,
  },
];

export default routeConfig;
