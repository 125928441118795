import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { deInquiryActionTypes } from '../actions/actionTypes';
import { exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

// task inquiry
export function* getDeductionInquiryExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(deInquiryActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.DEDUCTION_INQUIRY_EXPORT,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(deInquiryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDeductionInquiryList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(deInquiryActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(deInquiryActionTypes.LOG_DEINQ_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.DEDUCTION_INQUIRY_LIST, action.data);
    yield all([
      put(getAction(deInquiryActionTypes.GET_DEINQ_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(deInquiryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(deInquiryActionTypes.GET_DEINQ_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
function* deductionInquiryWatcher() {
  yield takeEvery(deInquiryActionTypes.GET_DEINQ_LIST_REQUEST, getDeductionInquiryList);
  yield takeEvery(deInquiryActionTypes.GET_DEINQ_EXPORT_REQUEST, getDeductionInquiryExportList);
}

export default deductionInquiryWatcher;
