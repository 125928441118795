/**
 * @Description Container for Fasat Tab
 * @FileName fasatProgressBar.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 19 January, 2021 05:38:22
 * @IssueID
 */
import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '@manulife/mux';
import './fasatProgressBar.scss';

const FasatProgressBar = (props) => {
  const { currentStep, steps } = props;
  return (
    <div className="fasatProgressBar">
      <ProgressBar
        variant={ProgressBar.VARIANT.STEPS}
        steps={steps}
        currentStep={currentStep}
        stepWidth="350px"
        customStyle={{
          rootStyle: {
            marginBottom: '20px',
          },
        }}
      />
    </div>
  );
};

FasatProgressBar.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
  })),
};

FasatProgressBar.defaultProps = {
  currentStep: 0,
  steps: null,
};

export default FasatProgressBar;
