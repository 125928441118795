/**
 * @Description English reources for administration
 * @FileName adminResourceEnglish.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:13:37
 * @IssueID NA
 */

const adminResourceEnglish = {
  'administration.menu': 'Administration',
  // System configuration starts
  'administration.systemConfiguration.menu': 'System configuration',
  'administration.fieldforceStructure.menu': 'Field Force Structure',
  'administration.genericEdit.menu': 'Generic edit',
  'administration.periodDefinition.menu': 'Period Definition',
  'administration.systemParameters.menu': 'System parameters',
  'administration.systemOptions.menu': 'System Options',
  'administration.notification.menu': 'Notifications',
  // System configuration ends
  // Product configuration starts
  'administration.productConfiguration.menu': 'Product configuration',
  'administration.planCode.menu': 'Plan code',
  'administration.interfaceMapping.menu': 'Interface mapping',
  'administration.transactionDefinition.menu': 'Transaction Definition',
  // Product configuration ends
  // Payment configuration starts
  'administration.paymentConfiguration.menu': 'Payment configuration',
  'administration.paymentThreshold.menu': 'Payment threshold',
  'administration.chequeSystem.menu': 'Cheque System',
  // Payment configuration ends
  // Reporting configuration starts
  'administration.reporting.menu': 'Reporting',
  'administration.incomeExpenseMap.menu': 'Income Expense Map',
  'administration.genEditRelation.menu': 'Generic edit relationships',
  'administration.producerAuditTrail.menu': 'Producer Audit Trail',
  'administration.dataDictionary.menu': 'Data dictionary',
  // Reporting configuration ends
  // batchProcess configuration starts
  'administration.batchProcess.menu': 'Batch process',
  'administration.interfaceSetup.menu': 'Interface Setup',
  'administration.batchMonitoring.menu': 'Batch Monitoring',
  // batchProcess configuration ends
  // Security configuration starts
  'administration.security.menu': 'Security setup',
  'administration.chequeSecurity.menu': 'Cheque Security',
  'administration.securitySettings.menu': 'Manage group',
  // Security configuration ends
  'cmn.list.show.page': 'Show',
  'cmn.list.record.display': 'records per page',
  'cmn.list.reset': 'Reset filters',
  'cmn.placeholder.display': 'Select',
  /**
     * Contents for GenEdit Type Page
     */
  // 'get.add.save': 'Save',
  // 'get.add.cancel': 'Cancel',
  // 'get.page.title': 'Generic Edit',
  // 'get.modal.title': 'Add type',
  // 'get.edit.modal.title': 'Edit type',
  // 'get.form.dropdownplaceholder': 'Select',
  /**
     * Contents for GenEdit Type Page
     */
  'sys.param.page.title': 'System parameters',
  'sys.param.add.modal.title': 'Add system parameter',
  'sys.param.edit.modal.title': 'Edit system parameter',
  'sys.param.add.success': 'System Parameter add succesfully',
  'sys.param.edit.success': 'System Parameter update succesfully',

  /**
    * Contents for Generic Edit type Details Page
    */
  // 'get.list.column.type': 'Type',
  // 'get.list.column.description': 'Description',
  // 'get.list.column.case': 'Case',
  // 'get.list.column.parenttype': 'Parent type',
  // 'get.list.column.systemtype': 'System type',
  // 'get.list.column.maxvallength': 'Maximum value length',
  // 'get.list.column.action': 'Action',
  // 'get.list.show.page': 'Show',
  // 'get.list.record.display': 'records per page',
  // 'get.list.reset': 'Reset filters',
  // 'get.list.add': 'Add',
  // 'get.list.export': 'Export',

  /**
    * Contents for GET ADD/EDIT Details Page
    */
  // 'get.form.type': 'Type',
  // 'get.form.case': 'Case',
  // 'get.form.parenttype': 'Parent Type',
  // 'get.form.systemtype': 'System Type',
  // 'get.form.maxvallength': 'Maximum value length',
  'GENERIC.NOTIFICATION': 'Operation could not be completed due to an unexpected error.',
};

export default adminResourceEnglish;
