import { environment } from '../../../common/util';

const envData = environment();
/**
 * Module specific API endpoint configuration.
 */
const apiConfig = {
  ACCOUNTING_INQUIRY_LIST: {
    endpoint: `${envData.apiHost}/api/accountinginquiry/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ACCOUNTING_INQUIRY_EXPORT: {
    endpoint: `${envData.apiHost}/api/accountinginquiry/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default apiConfig;
