/**
 * @Description This file will have all the route information for administration module
 * @FileName routeConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:41:45
 * @IssueID - 318
 */
import { lazy } from 'react';
import { aclKeysConstant, routeConstant } from '../moduleConstants';

export const AccountingInquiry = lazy(() => import(
  '../containers/accountingInquiry/accountingInquiry'
));
const routeConfig = [
  {
    path: routeConstant.ACCOUNTING_INQUIRY_ROUTE,
    exact: true,
    component: AccountingInquiry,
    id: 'AccountingInquiry',
    title: 'FASAT - AccountingInquiry',
    pageName: 'FASAT - AccountingInquiry page',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_ACI_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
];

export default routeConfig;
