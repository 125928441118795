/**
 * @Description This is fasatSecuritySettings container page
 * @FileName fasatSecuritySettings.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 27 January, 2021 11:15:07
 * @IssueID CDTFASATMOD-502
 */
import React from 'react';
import '../../../../index.scss';
import './fasatStatementGrid.scss';
import PropTypes from 'prop-types';

const enNaN = '$NaN';
const frNaN = 'NaN $';
const FasatStatementGrid = (props) => {
  const { statementHeader, statementData } = props;
  return (
    <table className="fasatStatementGrid">
      <thead>
        <tr>
          {statementHeader.map((th) => (
            <th key={th}>{th}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {statementData.map((tr) => (
          <tr key={tr.key} className={tr.highlight ? 'stRowHighlight' : ''}>
            <td>{tr.label}</td>
            <td>{(tr.periodVal === enNaN || tr.periodVal === frNaN) ? '' : tr.periodVal}</td>
            <td>{(tr.ytdVal === enNaN || tr.ytdVal === frNaN) ? '' : tr.ytdVal}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

FasatStatementGrid.propTypes = {
  statementHeader: PropTypes.shape(),
  statementData: PropTypes.shape(),
};

FasatStatementGrid.defaultProps = {
  statementHeader: [],
  statementData: [],
};

export default FasatStatementGrid;
