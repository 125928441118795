/* eslint-disable import/prefer-default-export */
/**
 * @Description Action types for
 * @FileName actionTypes.js
 * @Author Jayanta Sarkhel
 * @CreatedOn 11 February, 2022 23:55:21
 * @IssueID - Issue IDs
 */
// BCR action types
export const csActionTypes = {
  LOG_COMCALSTRUCTURE_LIST_REQUEST: 'LOG_COMCALSTRUCTURE_LIST_REQUEST',
  GET_COMPCAL_LIST_REQUEST: 'GET_COMPCAL_LIST_REQUEST',
  GET_COMCALSTRUCTURE_LIST_RESPONSE: 'GET_COMCALSTRUCTURE_LIST_RESPONSE',
  GET_COMCALSTRUCTURE_LIST_ERROR: 'GET_COMCALSTRUCTURE_LIST_ERROR',
  GET_COMCALSTRUCTURE_LIST_LOG_REQ: 'GET_COMCALSTRUCTURE_LIST_LOG_REQ',
  LOG_COMPCAL_LIST_REQUEST: 'LOG_COMPCAL_LIST_REQUEST',
  // GET_BCR_LIST_REQUEST: 'GET_BCR_LIST_REQUEST',
  GET_COMP_STRUCTURE_LIST_RESPONSE: 'GET_COMP_STRUCTURE_LIST_RESPONSE',
  GET_CS_LIST_ERROR: 'GET_CS_LIST_ERROR',
  // GET_BCR_LIST_LOG_REQ: 'GET_BCR_LIST_LOG_REQ',
  // Get Edit Iten by Id
  // LOG_BCR_BY_ID_REQUEST: 'LOG_BCR_BY_ID_REQUEST',
  GET_CS_BY_ID_REQUEST: 'GET_CS_BY_ID_REQUEST',
  GET_CS_BY_ID_RESPONSE: 'GET_CS_BY_ID_RESPONSE',
  // GET_BCR_BY_ID_ERROR: 'GET_BCR_BY_ID_ERROR',
  // GET_BCR_BY_ID_LOG_REQ: 'GET_BCR_BY_ID_LOG_REQ',
  // Get business card rule detail
  // GET_BC_REQUEST: 'GET_BC_REQUEST',
  // GET_BC_RESPONSE: 'GET_BC_RESPONSE',
  // GET_BC_ERROR: 'GET_BC_ERROR',
  // Add/Update business card rule detail
  RESET_UPSERT_VALUE: 'RESET_COMP_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_CS_REQUEST: 'UPSERT_CS_REQUEST',
  INSERT_COMPSTRUCTURE_REQUEST: 'INSERT_COMPSTRUCTURE_REQUEST',
  // INSERT_BCR_RESPONSE: 'INSERT_BCR_RESPONSE',
  // INSERT_BCR_ERROR: 'INSERT_BCR_ERROR',
  UPDATE_COMPSTRUCTURE_REQUEST: 'UPDATE_COMPSTRUCTURE_REQUEST',
  // UPDATE_BCR_RESPONSE: 'UPDATE_BCR_RESPONSE',
  // UPDATE_BCR_ERROR: 'UPDATE_BCR_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_DELETE_ERROR: 'RESET_DELETE_ERROR',
  VALIDATE_DELETE_CS_REQUEST: 'VALIDATE_DELETE_CS_REQUEST',
  VALIDATE_DELETE_CS_RESPONSE: 'VALIDATE_DELETE_CS_RESPONSE',
  RESET_VALIDATE_DELETE_CS: 'RESET_VALIDATE_DELETE_CS',
  RESET_CS_DATE_CONFLICT_RECORDS: 'RESET_CS_DATE_CONFLICT_RECORDS',
  // Delete business card rule
  DELETE_CS_REQUEST: 'DELETE_CS_REQUEST',
  // DELETE_BCR_RESPONSE: 'DELETE_BCR_RESPONSE',
  // DELETE_BCR_ERROR: 'DELETE_BCR_ERROR',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'CS_UPDATE_FILTER_CONFIG',
  UPDATE_FILTER_CONFIG_RESET_SELECTED: 'CS_UPDATE_FILTER_CONFIG_RESET_SELECTED',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_COMP_TABLE_CONFIGURATION',
  // Detail business card rule
  SET_CS_DETAILS_RESPONSE: 'SET_CS_DETAILS_RESPONSE',
  // Export List for CS
  GET_CS_EXPORT_REQUEST: 'GET_CS_EXPORT_REQUEST',
  GET_CSRT_EXPORT_REQUEST: 'GET_CSRT_EXPORT_REQUEST',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Reset store
  RESET_CS_STORE: 'RESET_CS_STORE',
  // by agent code
  GET_AGENT_NAME_BY_AGENT_CODE: 'GET_AGENT_NAME_BY_AGENT_CODE',
  GET_NEXT_AGENT_NAME_BY_AGENT_CODE: 'GET_NEXT_AGENT_NAME_BY_AGENT_CODE',
  // Tree action types
  GET_CS_TREE: 'GET_CS_TREE',
  GET_CS_TREE_RES: 'GET_CS_TREE_RES',
  RESET_TREE_DATA: 'RESET_TREE_DATA',
  // Rate Table action types
  GET_RATE_TABLE_REQ: 'GET_RATE_TABLE_REQ',
  GET_RATE_TABLE_RES: 'GET_RATE_TABLE_RES',
  RESET_RATE_TABLE_DATA: 'RESET_RATE_TABLE_DATA',
};
export const defaultCsActionTypes = {
  LOG_COMCALSTRUCTURE_LIST_REQUEST: 'LOG_COMCALSTRUCTURE_LIST_REQUEST',
  GET_DEFAULT_COMPCAL_LIST_REQUEST: 'GET_DEFAULT_COMPCAL_LIST_REQUEST',
  GET_COMCALSTRUCTURE_LIST_RESPONSE: 'GET_COMCALSTRUCTURE_LIST_RESPONSE',
  GET_COMCALSTRUCTURE_LIST_ERROR: 'GET_COMCALSTRUCTURE_LIST_ERROR',
  GET_COMCALSTRUCTURE_LIST_LOG_REQ: 'GET_COMCALSTRUCTURE_LIST_LOG_REQ',
  LOG_COMPCAL_LIST_REQUEST: 'LOG_COMPCAL_LIST_REQUEST',
  // GET_BCR_LIST_REQUEST: 'GET_BCR_LIST_REQUEST',
  GET_COMP_STRUCTURE_LIST_RESPONSE: 'GET_COMP_STRUCTURE_LIST_RESPONSE',
  GET_CS_LIST_ERROR: 'GET_CS_LIST_ERROR',
  // GET_BCR_LIST_LOG_REQ: 'GET_BCR_LIST_LOG_REQ',
  // Get Edit Iten by Id
  LOG_CS_DETAIL_REQUEST: 'LOG_CS_DETAIL_REQUEST',
  GET_DEFAULT_CS_BY_ID_REQUEST: 'GET_DEFAULT_CS_BY_ID_REQUEST',
  GET_DEFAULT_CS_BY_ID_RESPONSE: 'GET_DEFAULT_CS_BY_ID_RESPONSE',
  // GET_BCR_BY_ID_ERROR: 'GET_BCR_BY_ID_ERROR',
  // GET_BCR_BY_ID_LOG_REQ: 'GET_BCR_BY_ID_LOG_REQ',
  // Get business card rule detail
  // GET_BC_REQUEST: 'GET_BC_REQUEST',
  // GET_BC_RESPONSE: 'GET_BC_RESPONSE',
  // GET_BC_ERROR: 'GET_BC_ERROR',
  // Add/Update business card rule detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_CS_REQUEST: 'UPSERT_CS_REQUEST',
  INSERT_DEFAULT_COMPSTRUCTURE_REQUEST: 'INSERT_DEFAULT_COMPSTRUCTURE_REQUEST',
  // INSERT_BCR_RESPONSE: 'INSERT_BCR_RESPONSE',
  // INSERT_BCR_ERROR: 'INSERT_BCR_ERROR',
  UPDATE_DEFAULT_COMPSTRUCTURE_REQUEST: 'UPDATE_DEFAULT_COMPSTRUCTURE_REQUEST',
  // UPDATE_BCR_RESPONSE: 'UPDATE_BCR_RESPONSE',
  // UPDATE_BCR_ERROR: 'UPDATE_BCR_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  // Delete business card rule
  DELETE_DEFAULT_CS_REQUEST: 'DELETE_DEFAULT_CS_REQUEST',
  GET_DEFAULT_CS_EXPORT_REQUEST: 'GET_DEFAULT_CS_EXPORT_REQUEST',
  // DELETE_BCR_RESPONSE: 'DELETE_BCR_RESPONSE',
  // DELETE_BCR_ERROR: 'DELETE_BCR_ERROR',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_DCOMP_TABLE_CONFIGURATION',
  // Detail business card rule
  SET_CS_DETAILS_RESPONSE: 'SET_CS_DETAILS_RESPONSE',
  // Export List for BCR
  GET_CS_EXPORT_REQUEST: 'GET_CS_EXPORT_REQUEST',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Reset store
  RESET_CS_STORE: 'RESET_CS_STORE',
  // by agent code
  GET_AGENT_NAME_BY_AGENT_CODE: 'GET_AGENT_NAME_BY_AGENT_CODE',
  GET_NEXT_AGENT_NAME_BY_AGENT_CODE: 'GET_NEXT_AGENT_NAME_BY_AGENT_CODE',
};

// BCR detail action types
export const csDetailActionTypes = {
  LOG_CS_DETAIL_REQUEST: 'LOG_CS_DETAIL_REQUEST',
  GET_CS_DETAIL_LIST_REQUEST: 'GET_CS_DETAIL_LIST_REQUEST',
  GET_CS_DETAIL_RESPONSE: 'GET_CS_DETAIL_RESPONSE',
  GET_CS_DETAIL_ERROR: 'GET_CS_DETAIL_ERROR',
  GET_CS_AGENT_NAME_RESPONSE: 'GET_CS_AGENT_NAME_RESPONSE',
  GET_NEXT_CS_AGENT_NAME_RESPONSE: 'GET_NEXT_CS_AGENT_NAME_RESPONSE',
  SET_CS_DATE_CONFLICT_RECORDS: 'SET_CS_DATE_CONFLICT_RECORDS',
  RESET_CS_DETAIL_STORE: 'RESET_CS_DETAIL_STORE',
  RESET_CS_DATE_CONFLICT_RECORDS: 'RESET_CS_DATE_CONFLICT_RECORDS',

  // Common header action types
  GET_CS_HEADER_REQUEST: 'GET_CS_HEADER_REQUEST',
  LOG_CS_HEADER_REQUEST: 'LOG_CS_HEADER_REQUEST',
  GET_CS_HEADER_DETAIL_RESPONSE: 'GET_CS_HEADER_DETAIL_RESPONSE',
  GET_CS_HEADER_DETAIL_ERROR: 'GET_CS_HEADER_DETAIL_ERROR',
  RESET_CS_HEADER_DATA: 'RESET_CS_HEADER_DATA',
};

export const defaultCsDetailActionTypes = {
  LOG_CS_DETAIL_REQUEST: 'LOG_CS_DETAIL_REQUEST',
  GET_CS_DETAIL_LIST_REQUEST: 'GET_CS_DETAIL_LIST_REQUEST',
  GET_CS_DETAIL_RESPONSE: 'GET_CS_DETAIL_RESPONSE',
  GET_CS_DETAIL_ERROR: 'GET_CS_DETAIL_ERROR',
  GET_CS_AGENT_NAME_RESPONSE: 'GET_CS_AGENT_NAME_RESPONSE',
  GET_NEXT_CS_AGENT_NAME_RESPONSE: 'GET_NEXT_CS_AGENT_NAME_RESPONSE',
};

// LRO- Action Types
export const lroEligibilityActionTypes = {
  LOG_LRO_LIST_REQUEST: 'LOG_LRO_LIST_REQUEST',
  GET_LRO_LIST_REQUEST: 'GET_LRO_LIST_REQUEST',
  GET_LRO_LIST_RESPONSE: 'GET_LRO_LIST_RESPONSE',
  GET_LRO_LIST_ERROR: 'GET_LRO_LIST_ERROR',
  GET_LRO_LIST_LOG_REQ: 'GET_LRO_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_LRO_FILTER_CONFIG: 'UPDATE_LRO_FILTER_CONFIG',
  GET_LRO_EXPORT_REQUEST: 'GET_LRO_EXPORT_REQUEST',
  REMOVE_LRO_USER_DEFAULT_FILTER: 'REMOVE_LRO_USER_DEFAULT_FILTER',

  // Get LRO by ID
  LOG_LRO_BY_ID_REQUEST: 'LOG_LRO_BY_ID_REQUEST',
  GET_LRO_BY_ID_REQUEST: 'GET_LRO_BY_ID_REQUEST',
  GET_LRO_BY_ID_RESPONSE: 'GET_LRO_BY_ID_RESPONSE',
  GET_LRO_BY_ID_ERROR: 'GET_LRO_BY_ID_ERROR',
  GET_LRO_BY_ID_LOG_REQ: 'GET_LRO_BY_ID_LOG_REQ',

  // Get LRO detail
  GET_LRO_REQUEST: 'GET_LRO_REQUEST',
  GET_LRO_RESPONSE: 'GET_LRO_RESPONSE',
  GET_LRO_ERROR: 'GET_LRO_ERROR',

  // Add/Update LRO
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_LRO_REQUEST: 'UPSERT_LRO_REQUEST',
  INSERT_LRO_REQUEST: 'INSERT_LRO_REQUEST',
  INSERT_LRO_ERROR: 'INSERT_LRO_ERROR',
  UPDATE_LRO_REQUEST: 'UPDATE_LRO_REQUEST',
  UPDATE_LRO_RESPONSE: 'UPDATE_LRO_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_LRO_RESPONSE: 'UPSERT_LRO_RESPONSE',

  // Delete LRO
  DELETE_LRO_REQUEST: 'DELETE_LRO_REQUEST',
  DELETE_LRO_RESPONSE: 'DELETE_LRO_RESPONSE',
  DELETE_LRO_ERROR: 'DELETE_LRO_ERROR',

  // Detail LRO
  SET_LRO_DETAILS_RESPONSE: 'SET_LRO_DETAILS_RESPONSE',

  // Reset store
  RESET_LRO_STORE: 'RESET_LRO_STORE',

  // Export LRO
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',

  // lookups
  RESET_LRO_LOOKUPDATA: 'RESET_LRO_LOOKUPDATA',
  GET_LRO_LOOKUP_REQUEST: 'GET_LRO_LOOKUP_REQUEST',
};

// #region Rate Master/Details action types
export const rateMasterActionTypes = {
  // CONCURRENCY
  GET_ADD_CONCURRENCY: 'GET_ADD_CONCURRENCY',
  // FILTER AND TABLE CONFIG
  UPDATE_CONFIGURATION: 'UPDATE_RATE_MASTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_RATE_MASTER_TABLE_CONFIG',
  UPDATE_FILTER_CONFIG: 'UPDATE_RATE_MASTER_FILTER_CONFIG',
  EXPORT_ACTION: 'RATE_MASTER_EXPORT_ACTION',
  REMOVE_USER_DEFAULT_FILTER: 'RM_REMOVE_USER_DEFAULT_FILTER',
  // Rate Master List
  LOG_LIST_REQUEST: 'LOG_RATE_MASTER_LIST_REQUEST',
  GET_LIST_REQUEST: 'GET_RATE_MASTER_LIST_REQUEST',
  GET_LIST_RESPONSE: 'GET_RATE_MASTER_LIST_RESPONSE',
  GET_LIST_ERROR: 'GET_RATE_MASTER_LIST_ERROR',
  // ADD EDIT RATE MASTER
  RESET_UPSERT_VALUE: 'RESET_RATE_MASTER_UPSERT_VALUE',
  GET_BY_ID_REQUEST: 'GET_RATE_MASTER_BY_ID_REQUEST',
  GET_BY_ID_RESPONSE: 'GET_RATE_MASTER_BY_ID_RESPONSE',
  INSERT_REQUEST: 'INSERT_RATE_MASTER_REQUEST',
  UPDATE_REQUEST: 'UPDATE_RATE_MASTER_REQUET',
  RESET_UPSERT_ERROR: 'RESET_RATE_MASTER_UPSERT_ERROR',
  // DELETE RATE MASTER
  DELETE_REQUEST: 'DELETE_RATE_MASTER',
  RESET_DELETE_VALUE: 'RESET_RATE_MASTER_DELETE_VALUE',
  RESET_STORE: 'RESET_RATE_MASTER_STORE',
  RESET_SERVER_VALUE: 'RESET_RATE_MASTER_SERVER_VALUE',
  GET_ONLOAD_RATEMASTER_HIRER: 'GET_ONLOAD_RATEMASTER_HIRER',
  GET_RATEMASTER_ONLOAD_RESPONSE: 'GET_RATEMASTER_ONLOAD_RESPONSE',
  GET_RATEMASTER_ONLOAD_EDIT_RESPONSE: 'GET_RATEMASTER_ONLOAD_EDIT_RESPONSE',
  // Check rate master in use
  CHECK_RM_INUSE_REQUEST: 'CHECK_RM_INUSE_REQUEST',
  CHECK_RM_INUSE_RESPONSE: 'CHECK_RM_INUSE_RESPONSE',
};
export const rateMasterDetailsActionTypes = {
  // FILTER AND TABLE CONFIG
  UPDATE_CONFIGURATION: 'UPDATE_RATE_MASTER_DETAIL_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_RATE_MASTER_DETAIL_TABLE_CONFIG',
  UPDATE_FILTER_CONFIG: 'UPDATE_RATE_MASTER_DETAIL_FILTER_CONFIG',
  EXPORT_ACTION: 'RATE_MASTER_DETAIL_EXPORT_ACTION',
  REMOVE_USER_DEFAULT_FILTER: 'RM_REMOVE_USER_DEFAULT_FILTER',
  UPDATE_FILTER_CONFIG_RESET_SELECTED: 'UPDATE_RMD_FILTER_CONFIG_RESET_SELECTED',
  // List
  LOG_LIST_REQUEST: 'LOG_RATE_MASTER_DETAIL_LIST_REQUEST',
  GET_LIST_REQUEST: 'GET_RATE_MASTER_DETAIL_LIST_REQUEST',
  GET_LIST_RESPONSE: 'GET_RATE_MASTER_DETAIL_LIST_RESPONSE',
  GET_LIST_ERROR: 'GET_RATE_MASTER_DETAIL_LIST_ERROR',
  // ADD EDIT
  RESET_UPSERT_VALUE: 'RESET_RATE_MASTER_DETAIL_UPSERT_VALUE',
  GET_BY_ID_REQUEST: 'GET_RATE_MASTER_DETAIL_BY_ID_REQUEST',
  GET_BY_ID_RESPONSE: 'GET_RATE_MASTER_DETAIL_BY_ID_RESPONSE',
  INSERT_REQUEST: 'INSERT_RATE_MASTER_DETAIL_REQUEST',
  UPDATE_REQUEST: 'UPDATE_RATE_MASTER_DETAIL_REQUET',
  RESET_UPSERT_ERROR: 'RESET_RATE_MASTER_DETAIL_UPSERT_ERROR',
  // DELETE
  DELETE_REQUEST: 'DELETE_RATE_MASTER_DETAIL',
  RESET_DELETE_VALUE: 'RESET_RATE_MASTER_DETAIL_DELETE_VALUE',
  RESET_STORE: 'RESET_RATE_MASTER_DETAIL_STORE',
  RESET_SERVER_VALUE: 'RESET_RATE_MASTER_DETAIL_SERVER_VALUE',
  //
  LOG_EXPORT_LIST: 'RATE_MASTER_DETAIL_LOG_EXPORT_LIST',
};
export const rateKeyActionTypes = {
  // FILTER AND TABLE CONFIG
  UPDATE_CONFIGURATION: 'UPDATE_RATE_KEY_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_RATE_KEY_TABLE_CONFIG',
  UPDATE_FILTER_CONFIG: 'UPDATE_RATE_KEY_FILTER_CONFIG',
  EXPORT_ACTION: 'RATE_KEY_EXPORT_ACTION',
  REMOVE_USER_DEFAULT_FILTER: 'RATE_KEY_REMOVE_USER_DEFAULT_FILTER',
  // Rate Master List
  LOG_LIST_REQUEST: 'LOG_RATE_KEY_LIST_REQUEST',
  GET_LIST_REQUEST: 'GET_RATE_KEY_LIST_REQUEST',
  GET_LIST_RESPONSE: 'GET_RATE_KEY_LIST_RESPONSE',
  GET_LIST_ERROR: 'GET_RATE_KEY_LIST_ERROR',
  // ADD EDIT RATE MASTER
  RESET_UPSERT_VALUE: 'RESET_RATE_KEY_UPSERT_VALUE',
  GET_BY_ID_REQUEST: 'GET_RATE_KEY_BY_ID_REQUEST',
  GET_BY_ID_RESPONSE: 'GET_RATE_KEY_BY_ID_RESPONSE',
  INSERT_REQUEST: 'INSERT_RATE_KEY_REQUEST',
  UPDATE_REQUEST: 'UPDATE_RATE_KEY_REQUET',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR', // TODO
  // DELETE RATE MASTER
  DELETE_REQUEST: 'DELETE_RATE_KEY',
  RESET_DELETE_VALUE: 'RESET_RATE_KEY_DELETE_VALUE',
  RESET_STORE: 'RESET_RATE_KEY_STORE',
  RESET_SERVER_VALUE: 'RESET_RATE_KEY_SERVER_VALUE',
  SET_RATE_KEY_DETAILS_RESPONSE: 'SET_RATE_KEY_DETAILS_RESPONSE',
  // ON LOAD API RATE MASTER
  GET_RATEMASTER_ONLOAD: 'GET_RATEMASTER_ONLOAD',
  GET_RATEMASTER_ONLOAD_EDIT: 'GET_RATEMASTER_ONLOAD_EDIT',
  GET_BY_ONLOAD_RESPONSE: 'GET_BY_ONLOAD_RESPONSE',
  GET_BY_ONLOAD_EDIT_RESPONSE: 'GET_BY_ONLOAD_EDIT_RESPONSE',
  // Check rate key in use
  CHECK_RATEKEY_INUSE_REQUEST: 'CHECK_RATEKEY_INUSE_REQUEST',
  CHECK_RATEKEY_INUSE_RESPONSE: 'CHECK_RATEKEY_INUSE_RESPONSE',
};
export const rateDetailsActionTypes = {
  // FILTER AND TABLE CONFIG
  UPDATE_CONFIGURATION: 'UPDATE_RATE_DETAILS_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_RATE_DETAILS_TABLE_CONFIG',
  UPDATE_FILTER_CONFIG: 'UPDATE_RATE_DETAILS_FILTER_CONFIG',
  EXPORT_ACTION: 'RATE_DETAILS_EXPORT_ACTION',
  REMOVE_USER_DEFAULT_FILTER: 'RM_REMOVE_USER_DEFAULT_FILTER',
  // Rate Master List
  LOG_LIST_REQUEST: 'LOG_RATE_DETAILS_LIST_REQUEST',
  GET_LIST_REQUEST: 'GET_RATE_DETAILS_LIST_REQUEST',
  GET_LIST_RESPONSE: 'GET_RATE_DETAILS_LIST_RESPONSE',
  GET_LIST_ERROR: 'GET_RATE_DETAILS_LIST_ERROR',
  // ADD EDIT RATE MASTER
  RESET_UPSERT_VALUE: 'RESET_RATE_DETAILS_UPSERT_VALUE',
  GET_BY_ID_REQUEST: 'GET_RATE_DETAILS_BY_ID_REQUEST',
  GET_BY_ID_RESPONSE: 'GET_RATE_DETAILS_BY_ID_RESPONSE',
  INSERT_REQUEST: 'INSERT_RATE_DETAILS_REQUEST',
  UPDATE_REQUEST: 'UPDATE_RATE_DETAILS_REQUET',
  RESET_UPSERT_ERROR: 'RESET_RATE_DETAILS_UPSERT_ERROR',
  // DELETE RATE MASTER
  DELETE_REQUEST: 'DELETE_RATE_DETAILS',
  RESET_DELETE_VALUE: 'RESET_RATE_DETAILS_DELETE_VALUE',
  RESET_STORE: 'RESET_RATE_DETAILS_STORE',
  RESET_SERVER_VALUE: 'RESET_RATE_DETAILS_SERVER_VALUE',
  GET_ONLOAD_RATEDETAILS_HIRER: 'GET_ONLOAD_RATEDETAILS_HIRER',
  GET_RATEDETAILS_ONLOAD_RESPONSE: 'GET_RATEDETAILS_ONLOAD_RESPONSE',
  GET_RATEDETAILS_ONLOAD_EDIT_RESPONSE: 'GET_RATEDETAILS_ONLOAD_EDIT_RESPONSE',
};
export const rateParticipantsActionTypes = {
  // FILTER AND TABLE CONFIG
  UPDATE_CONFIGURATION: 'UPDATE_RATE_PARTICIPANT_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_RATE_PARTICIPANT_TABLE_CONFIG',
  UPDATE_FILTER_CONFIG: 'UPDATE_RATE_PARTICIPANT_FILTER_CONFIG',
  EXPORT_ACTION: 'RATE_PARTICIPANT_EXPORT_ACTION',
  REMOVE_USER_DEFAULT_FILTER: 'RM_REMOVE_USER_DEFAULT_FILTER',
  // Rate Master List
  LOG_LIST_REQUEST: 'LOG_RATE_PARTICIPANT_LIST_REQUEST',
  GET_LIST_REQUEST: 'GET_RATE_PARTICIPANT_LIST_REQUEST',
  GET_LIST_RESPONSE: 'GET_RATE_PARTICIPANT_LIST_RESPONSE',
  GET_LIST_ERROR: 'GET_RATE_PARTICIPANT_LIST_ERROR',
  // ADD EDIT RATE MASTER
  RESET_UPSERT_VALUE: 'RESET_RATE_PARTICIPANT_UPSERT_VALUE',
  GET_BY_ID_REQUEST: 'GET_RATE_PARTICIPANT_BY_ID_REQUEST',
  GET_BY_ID_RESPONSE: 'GET_RATE_PARTICIPANT_BY_ID_RESPONSE',
  INSERT_REQUEST: 'INSERT_RATE_PARTICIPANT_REQUEST',
  UPDATE_REQUEST: 'UPDATE_RATE_PARTICIPANT_REQUET',
  RESET_UPSERT_ERROR: 'RESET_RATE_PARTICIPANT_UPSERT_ERROR',
  SET_ONLOAD_CALL: 'SET_ONLOAD_CALL',
  GET_RATEPARTICIPANT_ONLOAD_RESPONSE_ERROR: 'GET_RATEPARTICIPANT_ONLOAD_RESPONSE_ERROR',
  // DELETE RATE MASTER
  DELETE_REQUEST: 'DELETE_RATE_PARTICIPANT',
  RESET_DELETE_VALUE: 'RESET_RATE_PARTICIPANT_DELETE_VALUE',
  RESET_STORE: 'RESET_RATE_PARTICIPANT_STORE',
  RESET_SERVER_VALUE: 'RESET_RATE_PARTICIPANT_SERVER_VALUE',
  GET_ONLOAD_RATEPARTICIPANT_HIRER: 'GET_ONLOAD_RATEPARTICIPANT_HIRER',
  GET_RATEPARTICIPANT_ONLOAD_RESPONSE: 'GET_RATEPARTICIPANT_ONLOAD_RESPONSE',
  GET_RATEPARTICIPANT_ONLOAD_SYS_RESPONSE: 'GET_RATEPARTICIPANT_ONLOAD_SYS_RESPONSE',
  GET_RATEPARTICIPANT_ONLOADSYS_RESPONSE: 'GET_RATEPARTICIPANT_ONLOADSYS_RESPONSE',
};
// #endregion

export const scsActionTypes = {
  GET_SCS_LIST_REQUEST: 'GET_SCS_LIST_REQUEST',
  GET_SCS_EXPORT_REQUEST: 'GET_SCS_EXPORT_REQUEST',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  LOG_SCS_LIST_REQUEST: 'LOG_SCS_LIST_REQUEST',
  GET_SCS_LIST_ERROR: 'GET_SCS_LIST_ERROR',
  GET_SCS_LIST_RESPONSE: 'GET_SCS_LIST_RESPONSE',
  RESET_SCS_STORE: 'RESET_SCS_STORE',
  GET_SCS_SYSTEM_OPTIONS: 'GET_SCS_SYSTEM_OPTIONS',
};
export const rateSweepActionTypes = {
  // FILTER AND TABLE CONFIG
  UPDATE_SWEEP_CONFIGURATION: 'UPDATE_SWEEP_CONFIGURATION',
  UPDATE_SWEEP_TABLE_CONFIGURATION: 'UPDATE_SWEEP_TABLE_CONFIGURATION',
  UPDATE_SWEEP_FILTER_CONFIG: 'UPDATE_SWEEP_FILTER_CONFIG',
  EXPORT_SWEEP_ACTION: 'EXPORT_SWEEP_ACTION',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  SET_SWEEP_DETAILS_RESPONSE: 'SET_SWEEP_DETAILS_RESPONSE',
  GET_RS_LOOKUP_REQUEST: 'GET_RS_LOOKUP_REQUEST',
  RESET_RS_LOOKUPDATA: 'RESET_RS_LOOKUPDATA',
  // Rate SWEEPList
  LOG_LIST_REQUEST: 'LOG_SWEEP_LIST_REQUEST',
  LOG_CLONE_LIST_REQUEST: 'LOG_CLONE_LIST_REQUEST',
  GET_SWEEP_LIST_REQUEST: 'GET_SWEEP_LIST_REQUEST',
  GET_SWEEP_LIST_RESPONSE: 'GET_SWEEP_LIST_RESPONSE',
  GET_SWEEP_CLONE_LIST_RESPONSE: 'GET_SWEEP_CLONE_LIST_RESPONSE',
  GET_SWEEP_LIST_ERROR: 'GET_SWEEP_LIST_ERROR',
  GET_SWEEP_CLONE_LIST_ERROR: 'GET_SWEEP_CLONE_LIST_ERROR',
  GET_SWEEP_CLONE_LIST_REQUEST: 'GET_SWEEP_CLONE_LIST_REQUEST',
  // ADD EDIT RATE SWEEP
  RESET_UPSERT_VALUE: 'RESET_SWEEP_UPSERT_VALUE',
  GET_SWEEP_BY_ID_REQUEST: 'GET_SWEEP_BY_ID_REQUEST',
  GET_SWEEP_BY_ID_RESPONSE: 'GET_SWEEP_BY_ID_RESPONSE',
  INSERT_SWEEP_REQUEST: 'INSERT_SWEEP_REQUEST',
  UPDATE_SWEEP_REQUEST: 'UPDATE_SWEEP_REQUEST',
  RESET_UPSERT_ERROR: 'RESET_SWEEP_UPSERT_ERROR',
  GET_SWEEP_CLONE_LIST_RESPONSE_RESET: 'GET_SWEEP_CLONE_LIST_RESPONSE_RESET',
  GET_SWEEP_CLONE: 'GET_SWEEP_CLONE',
  // DELETE RATE SWEEP
  DELETE_SWEEP_REQUEST: 'DELETE_SWEEP_REQUEST',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_STORE: 'RESET_SWEEP_STORE',
  RESET_SERVER_VALUE: 'RESET_SWEEP_SERVER_VALUE',
};
export const extRateActionTypes = {
  UPDATE_EXT_RATE_COLUMN_CONFIG: 'UPDATE_EXT_RATE_COLUMN_CONFIG',
  LOG_EXT_RATE_LIST_REQUEST: 'LOG_EXT_RATE_LIST_REQUEST',
  GET_EXT_RATE_LIST_REQUEST: 'GET_EXT_RATE_LIST_REQUEST',
  GET_EXT_RATE_LIST_RESPONSE: 'GET_EXT_RATE_LIST_RESPONSE',
  GET_EXT_RATE_LIST_ERROR: 'GET_EXT_RATE_LIST_ERROR',
  GET_EXT_RATE_LIST_LOG_REQ: 'GET_EXT_RATE_LIST_LOG_REQ',
  GET_DEFAULT_FILTER_SETTING: 'GET_DEFAULT_FILTER_SETTING',
  GET_EXT_RATE_LIST_EXPORT: 'GET_EXT_RATE_LIST_EXPORT',
  // Get Edit Item by ID
  LOG_EXT_RATE_BY_ID_REQUEST: 'LOG_EXT_RATE_BY_ID_REQUEST',
  GET_EXT_RATE_BY_ID_REQUEST: 'GET_EXT_RATE_BY_ID_REQUEST',
  GET_EXT_RATE_BY_ID_RESPONSE: 'GET_EXT_RATE_BY_ID_RESPONSE',
  GET_EXT_RATE_BY_ID_ERROR: 'GET_EXT_RATE_BY_ID_ERROR',
  GET_EXT_RATE_BY_ID_LOG_REQ: 'GET_EXT_RATE_BY_ID_LOG_REQ',
  // Get New rate
  LOG_EXT_NEW_RATE_REQUEST: 'LOG_EXT_NEW_RATE_REQUEST',
  GET_EXT_NEW_RATE_REQUEST: 'GET_EXT_NEW_RATE_REQUEST',
  GET_EXT_NEW_RATE_RESPONSE: 'GET_EXT_NEW_RATE_RESPONSE',
  GET_EXT_NEW_RATE_ERROR: 'GET_EXT_NEW_RATE_ERROR',
  GET_EXT_NEW_RATE_LOG_REQ: 'GET_EXT_NEW_RATE_LOG_REQ',
  // Get External Rate detail
  GET_EXT_RATE_REQUEST: 'GET_EXT_RATE_REQUEST',
  GET_EXT_RATE_RESPONSE: 'GET_EXT_RATE_RESPONSE',
  GET_EXT_RATE_ERROR: 'GET_EXT_RATE_ERROR',
  // Add/Update External Rate detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_EXT_RATE_REQUEST: 'INSERT_EXT_RATE_REQUEST',
  INSERT_EXT_RATE_RESPONSE: 'UPSERT_EXT_RATE_RESPONSE',
  INSERT_EXT_RATE_ERROR: 'UPSERT_EXT_RATE_ERROR',
  UPDATE_EXT_RATE_REQUEST: 'UPDATE_EXT_RATE_REQUEST',
  UPDATE_EXT_RATE_RESPONSE: 'UPDATE_EXT_RATE_RESPONSE',
  UPDATE_EXT_RATE_ERROR: 'UPDATE_EXT_RATE_ERROR',
  // Delete External Rate
  DELETE_EXT_RATE_REQUEST: 'DELETE_EXT_RATE_REQUEST',
  DELETE_EXT_RATE_RESPONSE: 'DELETE_EXT_RATE_RESPONSE',
  DELETE_EXT_RATE_ERROR: 'DELETE_EXT_RATE_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Update Filter Settings
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_EXT_RATE_TABLE_CONFIGURATION',
  UPDATE_EXT_RATE_FILTER_CONFIG: 'UPDATE_EXT_RATE_FILTER_CONFIG',
  GET_EXT_RATE_EXPORT_REQUEST: 'GET_EXT_RATE_EXPORT_REQUEST',
  REMOVE_EXT_RATE_USER_DEFAULT_FILTER: 'REMOVE_EXT_RATE_USER_DEFAULT_FILTER',
  SET_EXT_RATE_DETAILS_RESPONSE: 'SET_EXT_RATE_DETAILS_RESPONSE',
  // Reset Store
  RESET_EXT_RATE_STORE: 'RESET_EXT_RATE_STORE',
  // Export SystemParameters
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  GET_EXT_RATE_LIST_SAVE: 'GET_EXT_RATE_LIST_SAVE',
  GET_EXT_LIST_REQUEST: 'GET_EXT_LIST_REQUEST',
  GET_EXT_RATE_DROP: 'GET_EXT_RATE_DROP',
  GET_UPLINE_RATE: 'GET_UPLINE_RATE',
};
