/**
 * @Description licence process list module saga/watcher file.
 * @FileName licenceModuleWatcher.js
 * @Author Meena Saini
 * @CreatedOn 3 March, 2023 10:00:00
 * @IssueID - -
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { fiedForceLetterActionTypes } from '../actions/actionTypes';

import { exportErrorMsg, processError,
  renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

export function* getFFLetterList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(fiedForceLetterActionTypes.RESET_SERVER_VALUE, resetServerValue)),
    put(getAction(fiedForceLetterActionTypes.LOG_FF_LETTER_LIST_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_LETTER_LIST,
      action.data);
    yield all([
      put(getAction(fiedForceLetterActionTypes.GET_FF_LETTER_LIST_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fiedForceLetterActionTypes.GET_FF_LETTER_LIST_ERROR, err)),
      put(getAction(fiedForceLetterActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFLetterExportList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(fiedForceLetterActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_LETTER_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(fiedForceLetterActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  // ff letter
  yield takeEvery(fiedForceLetterActionTypes.GET_FF_LETTER_LIST_REQUEST, getFFLetterList);
  yield takeEvery(fiedForceLetterActionTypes.GET_FF_LETTER_LIST_EXPORT_REQ, getFFLetterExportList);
}

export default moduleWatcher;
