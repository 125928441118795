/**
 * @Description This file contains all the API configuration for Compensation Structure module
 * @FileName apiConfig.js
 * @Author Jayanta Sarkhel - sarkhja
 * @CreatedOn 15 march, 2021 9.30
 * @IssueID
*/

import { environment } from '../../../common/util';

const envData = environment();
/**
 * Module specific API endpoint configuration.
 */
const apiConfig = {
  REPORT_LIST: {
    endpoint: `${envData.apiHost}/api/comphierarchy/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PROD_SUMMARY_LIST: {
    endpoint: `${envData.apiHost}/api/productionsummary/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PROD_SUMMARY_EXPORT: {
    endpoint: `${envData.apiHost}/api/productionsummary/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  BAL_SUMMARY_LIST: {
    endpoint: `${envData.apiHost}/api/balancesummary/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  BAL_SUMMARY_EXPORT: {
    endpoint: `${envData.apiHost}/api/balancesummary/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  CS_REPORT_LIST: {
    endpoint: `${envData.apiHost}/api/reportrepository/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CS_REPORT_EXPORT: {
    endpoint: `${envData.apiHost}/api/reportrepository/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // CSR_EXT
  CS_REPORT_LIST_EXT: {
    endpoint: `${envData.apiHost}/api/compensationstatementext/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // Compenstion statements
  CS_REPORT_LIST_API: {
    endpoint: `${envData.apiHost}/api/compensationStatement/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  SELF_SERVICE_CATEGORY: {
    endpoint: `${envData.apiHost}/api/selfservice/getCategory`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  IAR_ACTIVITY_PERIOD_LOOKUP: {
    endpoint: `${envData.apiHost}/api/interactive/getActivityPeriod`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  IAR_COMP_TYPE_LIST: {
    endpoint: `${envData.apiHost}/api/interactive/getCompensationType`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  IAR_MSCodes_LIST: {
    endpoint: `${envData.apiHost}/api/interactive/getManulifeSellingCode`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  IAR_COMP_TYPE_HIER_LIST: {
    endpoint: `${envData.apiHost}/api/interactive/getProduct`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_ALFESCO_DOCUMENT: {
    endpoint: `${envData.apiHost}/api/docmgmt/search?returnAsSingeldoc=false`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_ALFESCO_DOCUMENT_B4: {
    endpoint: `${envData.apiHost}/api/docmgmt/search?returnAsSingeldoc=true`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  COMMISSION_FEED_DATA: {
    endpoint: `${envData.apiHost}/api/commissionfeed/getCommssionFeedValues`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  COMMISSION_GENERATE_REQUEST: {
    endpoint: `${envData.apiHost}/api/commissionfeed/generateCommFeed`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  COMMISSION_GENERATE_REQUEST_BATCH: {
    endpoint: `${envData.apiHost}/api/reportbatch/generatecompfeed`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  COMPENSATION_STATEMENT_DOWNLOAD: {
    endpoint: `${envData.apiHost}/api/docmgmt/search`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  COMPENSATION_STATEMENT_DOWNLOAD_POST: {
    endpoint: `${envData.apiHost}/api/docmgmt/search`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_DB_SYSTEM_DATE: {
    endpoint: `${envData.apiHost}/api/docmgmt/getsystemdate`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  EXPORT_IAR_COMPTYPES: {
    endpoint: `${envData.apiHost}/api/interactive/getCompensationType/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  EXPORT_IAR_MSC: {
    endpoint: `${envData.apiHost}/api/interactive/getManulifeSellingCode/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  EXPORT_IAR_PRODUCT: {
    endpoint: `${envData.apiHost}/api/interactive/getProduct/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default apiConfig;
