/**
 * @Description Custom HTTP sink for structured-log
 * @FileName httpSink.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 14:59:29
 * @IssueID - 318
 */
import axios from 'axios';
import { getLogLevel, enrichLogEvent } from './utility';

/**
 * create axios instance to invoke REST API
 */
const axiosInstance = axios.create({
  timeout: 1000,
  headers: {
    'sink-type': 'http',
    'content-type': 'application/json',
  },
});

/**
 * Log level switch for the HttpSink.
 * This will control the logs which needs to be published.
 */
const HttpSinkLevelSwitch = {
  off: 0,
  fatal: 1,
  error: 3,
  warning: 7,
  information: 15,
  debug: 31,
  verbose: 63,
};

const DEFAULT_LOG_LEVEL = 'information';

/**
 * Main sink class
 */
class HttpSink {
  /**
   *
   * @param {*} option :
   * {
   *  url : null,
   *  restrictedToMinimumLevel: information,
   *  enableRetry: false,
   *  retryCount: 0, // not implemented yet
   *  batchMode: false,
   *  batchSize: 0,
   *  disable: false
   * }
   */
  constructor(options) {
    this.url = options.url;
    this.restrictedToMinimumLevel = options.restrictedToMinimumLevel
      ? getLogLevel(options.restrictedToMinimumLevel)
      : getLogLevel(DEFAULT_LOG_LEVEL);
    this.enableRetry = options.enableRetry;
    this.retryCount = options.retryCount;
    this.batchMode = options.batchMode;
    this.batchSize = options.batchSize;
    this.eventQueues = [];
    this.disable = true;

    this.internalEventThreshold = 1;
    if (this.batchMode && this.batchSize > 1) {
      this.internalEventThreshold = this.batchSize;
    }
  }

  /**
   * @param {*} events  - events which needs to be processed by the sink
   */
  emit(events) {
    // update the event queue as per the level switch
    this.eventQueues = [...this.eventQueues, ...events].filter(
      (e) => e.level <= this.restrictedToMinimumLevel,
    );
    this.processLogs();
  }

  /**
   * Log any pending events
   */
  flush() {
    // all the pending items needs to be pushed
    this.postEvent(this.eventQueues);
    this.eventQueues.length = 0;
    return true;
  }

  /**
   * this will process log depending on the batch size configured for the sink
   */
  processLogs() {
    const logMessages = [];
    let counter = this.internalEventThreshold;
    if (this.eventQueues.length >= this.internalEventThreshold) {
      while (counter > 0 && this.eventQueues.length > 0) {
        logMessages.push(enrichLogEvent(this.eventQueues.pop()));
        counter -= 1;
      }
    }
    this.postEvent(logMessages);
  }

  /**
   *@param {LogEvent} events - events which needs to be posted to the configured endpoint.
   */
  postEvent(events) {
    if (!this.url || this.url === '') {
      return null;
    }
    const logs = events.map((e) => e);
    if (logs.length > 0 && !this.disable) {
      return axiosInstance.post(this.url, logs);
    }
    return null;
  }
}

export { HttpSink, HttpSinkLevelSwitch };
