/**
 * @Description Container for the chip component
 * @FileName fasatChip.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 08 April, 2021 06:39:06
 * @IssueID 720
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '../../libs/fasatSharedComponent';

const FasatChip = ({ data, onClick, hasCloseBtn, isDefaultChip, ariaCloseBtnLabel }) => (
  <Chip
    key={data}
    chipText={data}
    onClick={() => onClick()}
    hasCloseBtn={hasCloseBtn}
    isDefaultChip={isDefaultChip}
    ariaCloseBtnLabel={ariaCloseBtnLabel}
  />
);

FasatChip.propTypes = {
  data: PropTypes.string,
  onClick: PropTypes.func,
  hasCloseBtn: PropTypes.bool,
  isDefaultChip: PropTypes.bool,
  ariaCloseBtnLabel: PropTypes.string,
};

FasatChip.defaultProps = {
  data: '',
  onClick: () => undefined,
  hasCloseBtn: true,
  isDefaultChip: false,
  ariaCloseBtnLabel: '',
};

export default FasatChip;
