/**
 * @Description This is fasatExpandableParagraph component page.
 * @FileName fasatExpandableParagraph.js
 * @Author UMA DUTTA-duttuma
 * @CreatedOn 29 January, 2021 6:00:00
 * @IssueID CDTFASATMOD- 502
 */

import React from 'react';
import { ExpandableParagraphExperimental } from '@manulife/mux';
import './fasatExpandableParagraph.scss';

const FasatExpandableParagraph = (props) => (
  <div className="fasatExpandableParagraph">
    <ExpandableParagraphExperimental
      {...props}
    />
  </div>
);
export default FasatExpandableParagraph;
