import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FasatModal, FasatToastContainer } from './app/common/components';
import { staticCommonLabelKeys } from './app/common/moduleConstants';
import { checkDirtyData, getUserPrefData, getUserQuicksLinks } from './app/common/redux/actions/commonActions';
import CancelPopup from './app/modules/admin/components/cancelPopup/cancelPopup';
import FasatPersonilizePopup from './fasatPersonilizePopup';
import { getCurrentLocale } from './app/common/i18n';

const numberThreeThousand = 3000;

const SAVELINKS = 'SAVE_QUICKS_LINKS';
const CANCEL_LINKS = 'QUICKS_LINKS_CANCEL';
const OPENQUICKSLINKSMODAL = 'OPEN_QUICKS_LINKS_MODAL';
const onClickDiscard = (setCloseAddEditModal, closeAddEditModal, dispatch) => {
  setCloseAddEditModal(!closeAddEditModal);
  dispatch(checkDirtyData(false));
};
const onCancelClickCancel = (setCancelDisplayModal, canceldisplayModal) => {
  setCancelDisplayModal(!canceldisplayModal);
};
const disCRModalLinks = (canceldisplayModal, setCancelDisplayModal,
  setDispPerModal, dispPerModal, history, t, dispatch) => (
    <FasatModal
      display={canceldisplayModal}
      modalWidth="560px"
      primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_RETURNTOPAGE)}
      primaryTopic="ON_SAVE_STAY"
      secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_LEAVE)}
      secondaryTopic="ON_CANCEL_LEAVE"
      openModal="OPEN_ADD_GET_MODAL"
      modalCosed={() => onCancelClickCancel(setCancelDisplayModal, canceldisplayModal)}
      staydiscard
      pathname="interfaceMapping"
      state="interfaceMapping"
      onClickDiscard={() => onClickDiscard(setDispPerModal, dispPerModal, dispatch)}
      modalClass="cancelPopup modalWithoutHeading"
    >
      <div><CancelPopup onDiscardTopic="ON_SAVE_STAY" /></div>
    </FasatModal>
);
const iterateOrInsert = (item, menuList) => {
  if (item.subMenu && item.subMenu.length > 0) {
    item.subMenu.map((subItem) => iterateOrInsert(subItem, menuList));
  } else {
    menuList.push(item.id);
  }
};
const FasatPersonilizedSec = ({ dispPerModal, setDispPerModal, menuItems }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const locale = getCurrentLocale();
  const [canceldisplayModal, setCancelDisplayModal] = useState(false);
  const [subMenuRBAC, setsubMenuRBAC] = useState([]);
  const history = useHistory();
  const upsertData = useSelector(({ ApplicationState }) => ApplicationState.upsertData);
  useEffect(() => {
    if (upsertData) {
      setTimeout(() => {
        dispatch(getUserPrefData(locale));
        dispatch(getUserQuicksLinks(locale));
      }, numberThreeThousand);
    }
  }, [upsertData]);
  useEffect(() => {
    if (menuItems && menuItems.length > 0) {
      const menuList = [];
      menuItems.map((item) => iterateOrInsert(item, menuList));
      setsubMenuRBAC(menuList);
    }
  }, [menuItems]);
  return (
    <div className="personilize_css">
      <FasatModal
        title={t(staticCommonLabelKeys.COMMON_PREFERENCE_LINKS_LABEL)}
        display={dispPerModal}
        modalWidth="810px"
        primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_SAVE)}
        primaryTopic={SAVELINKS}
        secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_CANCEL)}
        secondaryTopic={CANCEL_LINKS}
        openModal={OPENQUICKSLINKSMODAL}
        modalCosed={() => setDispPerModal(false)}
        isCheckDirty="yes"
        onCancelClickCancel={() => onCancelClickCancel(setCancelDisplayModal, canceldisplayModal)}
        modalClass="personalizeModal"
      >
        <FasatPersonilizePopup
          closeAddEditModal={dispPerModal}
          onSaveTopic={SAVELINKS}
          onCancelTopic={CANCEL_LINKS}
          subMenuRBAC={subMenuRBAC}
        />
      </FasatModal>
      {disCRModalLinks(canceldisplayModal, setCancelDisplayModal, setDispPerModal, dispPerModal, history, t, dispatch)}
      <FasatToastContainer />
    </div>
  );
};
FasatPersonilizedSec.propTypes = {
  dispPerModal: PropTypes.bool,
  setDispPerModal: PropTypes.func,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
};

FasatPersonilizedSec.defaultProps = {
  dispPerModal: false,
  setDispPerModal: () => undefined,
  menuItems: null,
};
export default FasatPersonilizedSec;
