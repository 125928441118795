export const updateFilterReducer = (action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        systemDefaultFilter: action.data.systemDefault || null,
        selectedFilter,
        userDefaultFilter: action.data.userDefault || null,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        selectedFilter,
        userDefaultFilter: action.data.userDefault,
      };
    }
  }

  return {
    selectedFilter,
  };
};

export const updateFilterConfig = (state, action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        ...state,
        systemDefaultFilter: action.data.systemDefault || null,
        selectedFilter,
        userDefaultFilter: action.data.userDefault || null,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        ...state,
        userDefaultFilter: action.data.userDefault,
        selectedFilter,
      };
    }
  }
  return {
    ...state,
    selectedFilter,
  };
};

export const updateTableConfig = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  selectedFilter: action.data ? action.data : null,
});
