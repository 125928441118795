/* eslint-disable max-classes-per-file */
const COLUMN_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  ACTION: 'action',
};

const ACTION_TYPE = {
  EDIT: 'editIcon',
  EDITHOVER: 'editIconHover',
  SPLIT: 'splitIcon',
  SPLITHOVER: 'splitIconHover',
  DUPLICATE: 'duplicateIcon',
  DUPLICATEHOVER: 'duplicateIconHover',
  DELETE: 'deleteIcon',
  DELETEHOVER: 'deleteIconHover',
  LOAD: 'loadIcon',
  LOADHOVER: 'loadIconHover',
  TREE: 'treeIcon',
  TREEHOVER: 'treeIconHover',
  HYPERLINK: '',
  VIEW: 'viewIcon',
  VIEWHOVER: 'viewIconHover',
  DOWNLOAD: 'downloadIcon',
  DOWNLOADHOVER: 'downloadIconHover',
  FORCECOMPLETE: 'forceCompleteIcon',
  FORCECOMPLETEHOVER: 'forceCompleteIconHover',
  COMMENT: 'commentIcon',
  COMMENTHOVER: 'commentIconHover',
};

const FILTER_TYPE = {
  INPUTFILTER: 'inputFilter',
  SELECTFILTER: 'selectFilter',
};

class TableDefinition {
  constructor(name, id) {
    this.id = id;
    this.name = name;
    // other properties
    this.columnConfig = [];
    this.pageSize = 10;
  }

  addColumn(columnConfig) {
    this.columnConfig.push(columnConfig);
  }

  setColumnResizable(isColumnResizable = true) {
    this.isColumnResizable = isColumnResizable;
  }

  getColumns() {
    return this.columnConfig ? this.columnConfig : [];
  }
}

class FilterDefination {
  constructor(enableFilter, filterType) {
    this.enableFilter = enableFilter;
    this.filterType = filterType;
  }
}

class ActionDefinition {
  constructor(id, name, icon, hoverIcon, className, actionHandler, order) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.hoverIcon = hoverIcon;
    this.className = className;
    this.actionHandler = actionHandler;
    this.order = order;
    switch (this.icon) {
      case ACTION_TYPE.EDIT:
      case ACTION_TYPE.DELETE:
      case ACTION_TYPE.EDITHOVER:
      case ACTION_TYPE.DELETEHOVER:
      case ACTION_TYPE.HYPERLINK:
      case ACTION_TYPE.DUPLICATE:
      case ACTION_TYPE.DUPLICATEHOVER:
      case ACTION_TYPE.FORCECOMPLETE:
      case ACTION_TYPE.FORCECOMPLETEHOVER:
        break;
      default:
        throw new Error(`Invalid action type ${this.icon}`);
    }
  }
}

export {
  TableDefinition,
  FilterDefination,
  ActionDefinition,
  COLUMN_TYPE,
  ACTION_TYPE,
  FILTER_TYPE,
};
