/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/prop-types */
/**
 * @Description Wrapper component for grid
 * @FileName fasatGrid.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 February, 2021 04:53:48
 * @IssueID - 323
 */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sumOfRecordCount } from '../../util/utility';
import { CustomGrid } from '../../libs/fasatSharedComponent';
import FasatGridFooterBar from '../fasatGridFooterBar/fasatGridFooterBar';
import { getAction } from '../../../../stateManagement/reduxUtil';
import { staticCommonLabelKeys } from '../../moduleConstants';

/**
 * This function would be used to get the best suitable filter component
 * for the given type. In future this should support operation also to render
 * more appropriate filter
 * @param {string} type - Type of the filter.
 * Depending on which the filter component would get rendered.
 */

const OPT_AVG = 'OPT_AVG';
const OPT_SUM = 'OPT_SUM';
const OPT_MIN = 'OPT_MIN';
const OPT_MAX = 'OPT_MAX';

/**
 * This method transforms from fasat grid metadata to underlying grid configuration
 * @param {TableDefination} gridMetaData -metadata for the grid which will be consulted to
 * create the under-lying grid configuration
 */
const getCustomColumns = (gridMetaData) =>
  /**
   * 1. Map grid metadata to columns of react table
   */
  gridMetaData.getColumns().map((item) => {
    let columnObj = null;
    columnObj = item.visibility ? {
      Header: item.header,
      isVisible: item.visibility,
      accessor: item.dataField,
      disableFilters: item.disableFilter,
      disableSortBy: item.disableSortBy,
      // ...(item.filterType && { Filter: generateFilter(item.filterType) }),
      ...(item.filterType && { Filter: item.filterType }),
      ...(item.isAction && { Cell: item.body }),
      ...item,
    } : null;
    return columnObj;
  }).filter((item) => item != null);

export const updateGridHeader = (col, operator, t) => {
  const column = col;
  if (operator === OPT_AVG) {
    column.header = `${t(staticCommonLabelKeys.COMMON_LABEL_AVG_OF)}${' '}${col.header.toLowerCase()}`;
  }
  if (operator === OPT_SUM) {
    column.header = `${t(staticCommonLabelKeys.COMMON_LABEL_SUM_OF)}${' '}${col.header.toLowerCase()}`;
  }
  if (operator === OPT_MIN) {
    column.header = `${t(staticCommonLabelKeys.COMMON_LABEL_MIN_OF)}${' '}${col.header.toLowerCase()}`;
  }
  if (operator === OPT_MAX) {
    column.header = `${t(staticCommonLabelKeys.COMMON_LABEL_MAX_OF)}${' '}${col.header.toLowerCase()}`;
  }
};

const updateGridHeaderForGroupBy = (groupByParameters, gridMetaData, t) => {
  gridMetaData.columnConfig.map((col) => groupByParameters.map((grp) => {
    if (col.dataField === grp.key) {
      updateGridHeader(col, grp.operator, t);
    }
    return col.header;
  }));
};

/**
   * update the Gridview header for Sum of records count based on the language selection
   */
const sumOfRecordCountColumn = (t) => {
  sumOfRecordCount.header = t(staticCommonLabelKeys.COMMON_LABEL_SUM_RECORD_COUNT);
  return sumOfRecordCount;
};
/**
 * This is the wrapper grid component.
 * Event handlers needs to be bounded properly
 * @param {object} props - react props object
 */
const FasatGrid = (props) => {
  /**
   * describing the functionality/purpose
   * */
  const { tableMetaData, paginationInfo: { currentPage, totalRows, rowCount, pageSize }, selectedRowIndex,
    onColumnResize, onSortBy, onFilter, onPageChangeHandler, isFilterable, isSortable, isSortIconClickable, hide,
    isColumnResizable, hasRowSelect, allowClientSideInteractions, columnOrder, showFooterBar, activateDataGrid,
    isPeriodDefinition, caption, data, reqObj, entityFilterConfig, customFilterList, fetchFilterList, getSelectedRows,
    onGridRowClick, isDependentGrid, isManualAdj, canClick, displayAll, intSelRows, checkboxAriaLabel, customNoDataMsg, isCompCheckbox } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const [columns, setColumns] = useState([]);
  const [isGroupByActive, setIsGroupByActive] = useState(null);
  const [isDistinctActive, setIsDistinctActive] = useState(null);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  /**
   * on change of table metadata underlying grid needs to be refreshed
   */
  useEffect(() => {
    const gridMetaData = tableMetaData;
    if (reqObj) {
      const isGroupBy = reqObj && reqObj.groupByParameters.length > 0;
      const isDistinct = reqObj ? reqObj.isDistinct : null;
      if (isGroupBy || isDistinct) {
        gridMetaData.columnConfig = tableMetaData.columnConfig.length > 0
       && tableMetaData.columnConfig.filter((x) => x.id !== 'action');
        if (isGroupBy) {
          gridMetaData.columnConfig.push(sumOfRecordCountColumn(t));
        }
        updateGridHeaderForGroupBy(reqObj.groupByParameters, gridMetaData, t);
      }
      setIsGroupByActive(isGroupBy);
      setIsDistinctActive(isDistinct);
    }
    setColumns(getCustomColumns(gridMetaData));
  }, [tableMetaData, reqObj]);

  useEffect(() => {
    if (currentPage > 1 && data.length === 0 && activateDataGrid) {
      const reqObject = reqObj;
      reqObject.page = 1;
      if (customFilterList) {
        fetchFilterList(reqObject);
      } else {
        dispatch(getAction(entityFilterConfig.SEARCH_ACTION, reqObject));
      }
    }
  }, [data]);
  /**
   * on change of table column filter underlying grid needs to be refreshed
   * @param {object} filters
   */
  const onFilterChangeHandler = (filters) => onFilter(filters);
  /**
   * on change of table column sort underlying grid needs to be refreshed
   * @param {object} sortBy - [{"id":{string},"desc":{boolean}}]
   */
  const onSortByChangeHandler = (sortBy) => onSortBy(sortBy);
  /**
   * on change of table column resize underlying grid needs to be refreshed
   * @param {object} columnResizing
   */
  const onColumnResizeChangeHandler = (columnResizing) => onColumnResize(columnResizing);
  /**
   * Current page numer
   * @param {number} currPage
   */
  const onPageChange = (currPage) => onPageChangeHandler(currPage);
  return (
    <>
      {hide !== '1' && (
      <CustomGrid
        {...props}
        columns={columns}
        onFilterChange={onFilterChangeHandler}
        onSortByChange={onSortByChangeHandler}
        onColumnResizeChange={onColumnResizeChangeHandler}
        isFilterable={isFilterable}
        isSortable={isSortable}
        isSortIconClickable={isSortIconClickable}
        isColumnResizable={isColumnResizable}
        hasRowSelect={activateDataGrid && hasRowSelect}
        allowClientSideInteractions={allowClientSideInteractions}
        columnOrder={columnOrder}
        activateDataGrid={activateDataGrid}
        isPeriodDefinition={isPeriodDefinition}
        caption={caption}
        currentPageIndex={currentPage}
        getSelectedRows={getSelectedRows}
        isGroupBy={isGroupByActive}
        hide={hide}
        selectedRowIndex={selectedRowIndex}
        onGridRowClick={onGridRowClick}
        isDependentGrid={isDependentGrid}
        isManualAdj={isManualAdj}
        canClick={canClick}
        isDistinct={isDistinctActive}
        intSelRows={intSelRows}
        displayAll={displayAll}
        checkboxAriaLabel={checkboxAriaLabel}
        customNoDataMsg={customNoDataMsg}
        isCompCheckbox={isCompCheckbox}
      />
      )}
      { showFooterBar && (
      <FasatGridFooterBar
        currentPageIndex={currentPage}
        currentItemAriaLabel=""
        navigationAriaLabel=""
        nextAltText=""
        previousAltText=""
        totalRowCount={totalRows}
        onPagination={(currPage) => onPageChange(currPage)}
        rowCount={rowCount || 0}
        pageSize={pageSize}
        activateDataGrid={activateDataGrid}
        showingLabel={t(staticCommonLabelKeys.COMMON_LABEL_SHOWING)}
        ofLabel={t(staticCommonLabelKeys.COMMON_LABEL_OF_IN_SHOWING_PAGE_RANGE)}
        hypenLabel={t(staticCommonLabelKeys.COMMON_LABEL_HYPHEN)}
      />
      )}
    </>
  );
};

FasatGrid.propTypes = {
  showFooterBar: PropTypes.bool,
  customFilterList: PropTypes.bool,
  selectedRowIndex: PropTypes.number,
  onGridRowClick: PropTypes.func,
  isDependentGrid: PropTypes.bool,
};

FasatGrid.defaultProps = {
  showFooterBar: true,
  customFilterList: false,
  selectedRowIndex: null,
  onGridRowClick: () => undefined,
  isDependentGrid: false,
};
export default FasatGrid;
