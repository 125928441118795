/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-debugger */
/**
 * @Description Base service for http invocation
 * @FileName httpService.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 28 January, 2021 05:53:29
 * @IssueID - 307
 */
import axios from 'axios';
import https from './http';
import {
  // authenticationInterceptor,
  // authorizationInterceptor,
  baseResponseInterceptor,
  rejectRequest,
  errorInterceptor,
  baseRequestInterceptor,
  cleanupRequestHeader,
} from './httpInterceptors';
import { Logger, getSessionId, getUniqueId } from '../util';

https.interceptors.request.use(baseRequestInterceptor, rejectRequest);
https.interceptors.request.use(cleanupRequestHeader, rejectRequest);
https.interceptors.response.use(baseResponseInterceptor, errorInterceptor);

/**
 * This method will update the endpoint depending on the path parameters and query parameters
 * @param {string} endpoint
 * @param {object} pathParameters
 * @param {object} queryParams
 * @returns
 */
const updateEndPoint = (endpoint, pathParameters, queryParams) => {
  // replace pathParameter
  // Logger.verbose(pathParameters, queryParams);
  // return endpoint;
  let modifiedEndpoint = endpoint;
  if (pathParameters) {
    for (const param in pathParameters) {
      if (param != null) {
        modifiedEndpoint = modifiedEndpoint.replace(`@@${param}`, encodeURI(pathParameters[param]));
      }
    }
  }
  // to do : replace queryParameter
  if (queryParams) {
    for (const param in queryParams) {
      if (param != null) {
        modifiedEndpoint = modifiedEndpoint.replace(
          `@@${param}`,
          `${param}=${encodeURI(queryParams[param])}`,
        );
      }
    }
  }
  return modifiedEndpoint;
};
// use interceptors
const invokeApi = async (
  apiOption,
  requestBody = null,
  pathParameters = null,
  queryParams = null,
  headers = {},
) => {
  const header = {
    // static header to protect against CSRF
    'X-CSRF': '1',
    requestid: getUniqueId(),
    // sessionid: loggedUser ? null : loggedUser.SessionId,
    sessionid: getSessionId(),
    ipAddress: localStorage.getItem('ipInfo'),
    logexceptiononly: true,
    logtimestats: false,
  };
  let config = { headers: { ...headers, ...header } };
  config = { ...config, ...apiOption };

  Logger.verbose(`API options passed : ${JSON.stringify(config)}`);
  let response = null;
  switch (apiOption.type) {
    case 'GET':
      response = https
        .get(updateEndPoint(apiOption.endpoint, pathParameters, queryParams), config)
        .then();
      break;
    case 'POST':
      response = https.post(
        updateEndPoint(apiOption.endpoint, pathParameters, queryParams),
        requestBody,
        config,
      );
      break;
    case 'PUT':
      response = https.put(
        updateEndPoint(apiOption.endpoint, pathParameters, queryParams),
        requestBody,
        config,
      );
      break;
    case 'DELETE':
      response = https.delete(updateEndPoint(apiOption.endpoint, pathParameters, queryParams), {
        ...config,
        data: requestBody,
      });
      break;
    case 'PATCH':
      response = https.patch(
        updateEndPoint(apiOption.endpoint, pathParameters, queryParams),
        requestBody,
        config,
      );
      break;
    case 'DOWNLOAD':
      {
        const instance = axios.create();
        const url = apiOption.endpoint;
        const data = requestBody;
        const options = {
          headers: header,
          responseType: 'blob',
        };
        response = instance.post(url, data, options);
      }
      break;
    default:
      throw new Error(`Invalid request type : ${apiOption.type}`);
  }
  return response
    .then((r) => r)
    .catch((e) => {
      Logger.error(e, 'Error occurred in API -{apiEndpoint}', apiOption.endpoint, apiOption);
      throw e;
    });
};

export default invokeApi;
