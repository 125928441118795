/**
 * @Description French reources for activity
 * @FileName french.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:13:37
 * @IssueID NA
 */

const activityResourcesFrench = {
  'activity.menu': 'F: activity',
};

export default activityResourcesFrench;
