/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { fieldForceActionTypes } from '../actions/actionTypes';
import { Logger, processError, renderFileName, exportErrorMsg } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';
import { getFFBalProdApiURLUtil } from './agencyutility';

export const getFFBalProdApiURL = getFFBalProdApiURLUtil;

// Relationship

export function* getFFRelList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_REL_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(fieldForceActionTypes.LOG_FF_LIST_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_REL_LIST, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_REL_LIST_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* insertFFRelationship(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_ADD_REL,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_REL_LIST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getAdvisorName(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.SET_FF_REL_ADVISOR_NAME, { data: 'NULL' })),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetUpsertValue = null;
  try {
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.FF_REL_ADVISOR_NAME, action.data);

    yield all([
      put(getAction(fieldForceActionTypes.SET_FF_REL_ADVISOR_NAME, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(fieldForceActionTypes.SET_FF_REL_ADVISOR_NAME, { data: 'NULL' })),
      put(processingCompleted()),
    ]);
  }
}

export function* updateFFRelationship(action) {
  Logger.verbose(`Action Detail For FF Relationship : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_EDIT_REL,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_REL_LIST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deleteFFRelationship(action) {
  Logger.verbose(`Action Detail For FF Relationship : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_DELETE_REL,
      action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_REL_LIST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for BCR Export Listing
   */
export function* getFFRelationshipExport(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_REL_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

// FF Balance Period list
export function* getFFBalPeriodList(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FFBAL_PERIOD_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALNCE_PERIOD_LIST, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FFBAL_PERIOD_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}
export function* getFFBalPeriodListExt(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FFBAL_PERIOD_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALNCE_PERIOD_LIST_EXTERNAL, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FFBAL_PERIOD_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}
// FF Balance Get Item by Period
export function* getFFBalItemByPeriod(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data.payload)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALANCE_ITEM_BY_PERIOD,
      action.data.payload);
    if (action.data.ytdRes) {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    } else {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_RESPONSE, null)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}
export function* getFFBalItemByPeriodExternal(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data.payload)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALANCE_ITEM_BY_PERIOD_EXTERNAL,
      action.data.payload);
    if (action.data.ytdRes) {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE_EXTERNAL, responseData.data)),
        put(processingCompleted()),
      ]);
    } else {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_RESPONSE, null)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}
// FF Prod Get Item by Month
export function* getFFProdItemByMonth(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data.payload)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PRD_ITEM_BY_MONTH,
      action.data.payload);
    if (action.data.ytdRes) {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_YTD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    } else {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_MONTH_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_MONTH_RESPONSE, null)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Period Get Item by YTD
export function* getFFProdItemByYTD(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data.payload)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PRD_ITEM_BY_YTD, action.data.payload);
    if (action.data.prdRes) {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_MONTH_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    } else {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_YTD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_YTD_RESPONSE, null)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// EXTERNAL - Prod Get Item by Month
export function* getFFProdItemByMonthExt(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data.payload)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PRD_ITEM_BY_MONTH_EXT, action.data.payload);
    if (action.data.ytdRes) {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_YTD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    } else {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_MONTH_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_MONTH_RESPONSE, null)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// EXTERNAL - Prod Get Item by YTD
export function* getFFProdItemByYTDExt(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data.payload)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PRD_ITEM_BY_YTD_EXT, action.data.payload);
    if (action.data.prdRes) {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_MONTH_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    } else {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_YTD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.GET_FFPROD_ITEM_BY_YTD_RESPONSE, null)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Balance Get Item by Period YTD
export function* getFFBalItemByPeriodYTD(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data.payload)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALANCE_ITEM_BY_PERIOD_YTD, action.data.payload);
    if (action.data.prdRes) {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    } else {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE, null)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFBalItemByPeriodYTDExternal(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data.payload)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALANCE_ITEM_BY_PERIOD_YTD_EXTERNAL, action.data.payload);
    if (action.data.prdRes) {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    } else {
      yield all([
        put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE, responseData.data)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE, null)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Balance Period list
export function* getFFBalMonthlyList(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FFBAL_MONTHLY_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALNCE_MONTHLY_LIST, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FFBAL_MONTHLY_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFBalMonthlyListExt(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FFBAL_MONTHLY_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALNCE_MONTHLY_LIST_EXTERNAL, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FFBAL_MONTHLY_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Balance Summary list
export function* getFFBalSummaryList(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FFBAL_SUMMARY_LIST_REQUEST, [])),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALNCE_SUMMARY_LIST, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FFBAL_SUMMARY_LIST_REQUEST, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Balance Hierarchy list
export function* getFFBalHierarchyList(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FFBAL_HIERARCHY_LIST_REQUEST, [])),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BALANCE_HIERARCHY_LIST,
      action.data.payload);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FFBAL_HIERARCHY_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFMatList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST)),
    put(getAction(fieldForceActionTypes.RESET_FF_MAT_LIST_RESPONSE)),
    put(getAction(fieldForceActionTypes.UPDATE_MAT_TABLE_CONFIGURATION, { detail: action.data })),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_GET_MAT_LIST,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_MAT_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE_ITEM, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* transferFFMatList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
  ]);

  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_TRANSFER_MAT_LIST,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.FF_TRANSFER_SUCCESS, responseData)),
      put(getAction(fieldForceActionTypes.CLEAR_MASS_TRANSFER_SUCCESS)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.FF_TRANSFER_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.CLEAR_MASS_TRANSFER_SUCCESS)),
      put(processingCompleted()),
    ]);
  }
}

export function* getLookUpsInfo(action) {
  const { reqObj, storeSetState } = action.data;
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_LOOKUPS_DATA, reqObj);
    storeSetState(responseData[0].values);
    yield put(processingCompleted());
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Prod Buis list
export function* getFFProdBuisList(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FFBAL_PERIOD_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PROD_BUIS_LIST, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FFPROD_BUIS_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFProdBuisListExt(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FFBAL_PERIOD_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PROD_BUIS_LIST_EXT, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FFPROD_BUIS_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Prod Hierachy list
export function* getFFProdHierList(action) {
  const { reqObj, storeSetState } = action.data;
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PROD_BUIS_LIST, reqObj);
    storeSetState(responseData);
    yield put(processingCompleted());
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      // put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFProdHierListExt(action) {
  const { reqObj, storeSetState } = action.data;
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PROD_BUIS_LIST_EXT, reqObj);
    storeSetState(responseData);
    yield put(processingCompleted());
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      // put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Prod Hierachy Lower list
export function* getFFProdHierLwrList(action) {
  const { reqObj, storeSetState } = action.data;
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PROD_HIER_LWR_LIST, reqObj);
    storeSetState(responseData);
    yield put(processingCompleted());
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    storeSetState([]);
    yield all([
      // put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Prod Prod list
export function* getFFProdProdList(action) {
  const { reqObj, storeSetState } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PROD_PROD_LIST, reqObj);
    storeSetState(responseData);
    yield put(processingCompleted());
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    storeSetState([]);
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Prod Prod list
export function* getFFProdProdListExt(action) {
  const { reqObj, storeSetState } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PROD_PROD_LIST_EXT, reqObj);
    storeSetState(responseData);
    yield put(processingCompleted());
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    storeSetState([]);
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF Prod Prod list
export function* getFFProdMonthCompList(action) {
  const { reqObj, storeSetState } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_PROD_MONTHCOMP_LIST, reqObj);
    storeSetState(responseData);
    yield put(processingCompleted());
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    storeSetState([]);
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

// FF System Options
export function* getSystemOptions(action) {
  const { data, storeSetState } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  const sysOptDetailsInitURL = moduleAPI.ADMINISTRATION_SYS_OPT_DETAILS.endpoint;
  try {
    const lang = data === 'en' ? 'E/false' : 'F/false';
    const sysOptDetailsUpdateURL = sysOptDetailsInitURL + lang;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_SYS_OPT_DETAILS };
    apiEndpoint.endpoint = sysOptDetailsUpdateURL;
    const responseData = yield call(invokeApi, apiEndpoint, lang);
    storeSetState(responseData);
    yield put(processingCompleted());
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    storeSetState([]);
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

/**
 * Set Relationship concurrency
 *
 * @export
 * @param {*} action metadata
 */
export function* setFFRelConcurrency(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_RELATIONSHIP_LIST_BY_ID,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_REL_BY_ID_RESPONSE, { ...responseData, comboLock: action.data.lock })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_REL_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* getLookUpsFFMnthYearInfo(action) {
  const { reqObj, storeSetState } = action.data;
  let resetServerValue = { serverRequestError: null };
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null))]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_LOOKUPS_DATA, reqObj);
    storeSetState(responseData);
    yield put(processingCompleted());
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* exportProdHier(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_PROD_HIER_EXPORT, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)), put(processingCompleted()),
    ]);
  }
}

export function* getFFBalanceExportList(action) {
  const apiURL = getFFBalProdApiURL(action);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  const payload = action.data.formatExtObject ? action.data.formatExtObject : action.data;
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, apiURL, payload);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    const fileExt = action.data.formatExtObject && (action.data.formatExtObject.exportFileType === 1) ? 'csv' : 'xlsx';
    FileSaver(urlData, `${filename}.${fileExt}`);
    if (action.data.popUpClose) {
      action.data.popUpClose();
    }
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFCORPLookUpsData(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(fieldForceActionTypes.HAS_CORP_ERROR, false)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_LOOKUPS_DATA, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_LOOKUPDATA, responseData)),
      put(getAction(fieldForceActionTypes.GET_FF_MAT_CORP_INFO, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_LOOKUPDATA, null)),
      put(getAction(fieldForceActionTypes.RESET_FF_MAT_CORP_INFO)),
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.HAS_CORP_ERROR, true)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcherFFM() {
  // MAT
  yield takeEvery(fieldForceActionTypes.GET_FF_MAT_LIST, getFFMatList);
  yield takeEvery(fieldForceActionTypes.GET_FF_MAT_TRANSFER_ITEMS, transferFFMatList);
  yield takeEvery(fieldForceActionTypes.GET_FF_MAT_CORP_ITEMS, getFFCORPLookUpsData);
  // FF Relationship
  yield takeEvery(fieldForceActionTypes.GET_FF_REL_LIST, getFFRelList);
  yield takeEvery(fieldForceActionTypes.INSERT_FF_REL_REQUEST, insertFFRelationship);
  yield takeEvery(fieldForceActionTypes.UPDATE_FF_REL_REQUEST, updateFFRelationship);
  yield takeEvery(fieldForceActionTypes.DELETE_FF_REL_REQUEST, deleteFFRelationship);
  yield takeEvery(fieldForceActionTypes.GET_FF_REL_ADVISOR_NAME, getAdvisorName);
  yield takeEvery(fieldForceActionTypes.GET_FF_REL_EXPORT_REQUEST, getFFRelationshipExport);
  yield takeEvery(fieldForceActionTypes.SET_FF_REL_CONCURRENCY, setFFRelConcurrency);

  // EXTERNAL - Prod
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_ITEM_BY_MONTH_EXT, getFFProdItemByMonthExt);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_ITEM_BY_YTD_EXT, getFFProdItemByYTDExt);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_BUIS_EXPORT_REQUEST_EXT, getFFBalanceExportList);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_PROD_EXPORT_REQUEST_EXT, getFFBalanceExportList);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_BUIS_LIST_REQUEST_EXT, getFFProdBuisListExt);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_PROD_LIST_REQUEST_EXT, getFFProdProdListExt);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_HIER_LIST_REQUEST_EXT, getFFProdHierListExt);

  // FF Balance
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_PERIOD_LIST_REQUEST, getFFBalPeriodList);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_PERIOD_LIST_REQUEST_EXTERNAL, getFFBalPeriodListExt);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD, getFFBalItemByPeriod);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_EXTERNAL, getFFBalItemByPeriodExternal);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_ITEM_BY_MONTH, getFFProdItemByMonth);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_YTD, getFFBalItemByPeriodYTD);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_ITEM_BY_PERIOD_YTD_EXTERNAL, getFFBalItemByPeriodYTDExternal);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_ITEM_BY_YTD, getFFProdItemByYTD);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_MONTHLY_LIST_REQUEST, getFFBalMonthlyList);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_MONTHLY_LIST_REQUEST_EXTERNAL, getFFBalMonthlyListExt);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_SUMMARY_REQUEST, getFFBalSummaryList);
  yield takeEvery(fieldForceActionTypes.GETLOOKUPS, getLookUpsInfo);
  yield takeEvery(fieldForceActionTypes.GETLKUPSMNTHYR, getLookUpsFFMnthYearInfo);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_BUIS_LIST_REQUEST, getFFProdBuisList);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_HIER_LIST_REQUEST, getFFProdHierList);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_PROD_LIST_REQUEST, getFFProdProdList);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_MONTHCOMP_LIST_REQUEST, getFFProdMonthCompList);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_HIER_LWR_LIST_REQUEST, getFFProdHierLwrList);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_HIERARCHY_LIST_REQUEST, getFFBalHierarchyList);
  yield takeEvery(fieldForceActionTypes.GET_SYSOPTS_REQUEST, getSystemOptions);

  yield takeEvery(fieldForceActionTypes.GET_FFBAL_PERIODMONTH_EXPORT_REQUEST, getFFBalanceExportList);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_PERIODMONTH_EXPORT_REQUEST_EXTERNAL, getFFBalanceExportList);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_SUMMARY_EXPORT_REQUEST, getFFBalanceExportList);
  yield takeEvery(fieldForceActionTypes.GET_FFBAL_HIERARCHY_EXPORT_REQUEST, getFFBalanceExportList);
  yield takeEvery(fieldForceActionTypes.EXPORT_PRODHIER, exportProdHier);

  yield takeEvery(fieldForceActionTypes.GET_FFPROD_BUIS_EXPORT_REQUEST, getFFBalanceExportList);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_HIER_EXPORT_REQUEST, getFFBalanceExportList);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_PROD_EXPORT_REQUEST, getFFBalanceExportList);
  yield takeEvery(fieldForceActionTypes.GET_FFPROD_MONTHCOMP_EXPORT_REQUEST, getFFBalanceExportList);
}

export default moduleWatcherFFM;
