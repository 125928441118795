/**
 * @Description This file contains all the Menu configuration for administration module
 * @FileName menuConfig.js
 * @Author Jayanta Sarkhel- sarkhja
 * @CreatedOn 15 february, 2022 16:41:03
 * @IssueID - 459, 311
 */
import { Write } from '@manulife/mux-cds-icons';
import { t } from '../../../common/i18n';
import { navigate } from '../../../common/util';
import { aclKeysConstant, routeConstant, staticLabelKeys } from '../moduleConstants';

/**
  * Menu configuration for the module
  */
const desktopMenus = () => [
  {
    id: 'accounting',
    sequence: 1,
    icon: <Write style={{ width: '20px', height: '20px' }} />,
    label: t(staticLabelKeys.ACCOUNTING_MENU),
    aria: t(staticLabelKeys.ACCOUNTING_MENU),
    urlPath: '#',
    url: '#',
    action: () => navigate('#'),
    aclKey: [aclKeysConstant.RBAC_ACCOUNTING],
    acl: true,
    subMenu: [
      {
        id: 'ACCOUNTING_INQUIRY_MENU',
        label: t(staticLabelKeys.ACCOUNTING_INQUIRY_MENU),
        urlPath: routeConstant.ACCOUNTING_INQUIRY_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.ACCOUNTING_INQUIRY_ROUTE),
        aclKey: [aclKeysConstant.RBAC_ACI_MENU],
        acl: true,
        showInBatchProcess: false,
      },
    ],
  },
];
const mobileMenus = desktopMenus;
export { desktopMenus, mobileMenus };
