/**
 * @Description FasatColumnOrder List File
 * @FileName List.js
 * @Author ROHIT NEGI-negiroh
 * @CreatedOn 13 January, 2022 12:30:30
 * @IssueID 3505
 */
import PropTypes from 'prop-types';
import React from 'react';

const ListItem = (props) => {
  const { option, index, onClick, controlKey, onKeyDown } = props;
  return (
    <li
      role="option"
      id={`${controlKey}-option-${index}`}
      className={`listbox-li ${option.selected ? 'selected' : ''}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      aria-selected={option.selected}
      data-value={option.value}
      data-selected={option.selected}
      data-index={index}
    >
      {option.label}
    </li>
  );
};

ListItem.propTypes = {
  controlKey: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onKeyDown: PropTypes.func,
};

ListItem.defaultProps = {
  onKeyDown: () => undefined,
};

export default ListItem;
