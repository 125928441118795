import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { reportActionTypes } from '../actions/actionTypes';

const ReportList = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case reportActionTypes.GET_REPORT_LIST_ERROR:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case reportActionTypes.GET_REPORT_LIST_RESPONSE:
      return {
        ...state,
        detailData: action.data,
      };
    case reportActionTypes.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: action.data && action.data.serverRequestError,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default ReportList;
