/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/**
 * @Description This is FasatButton component page.
 * This is a wrapper on "Button" component from MUX without adding any custom functionlities
 * @FileName fasatButton.js
 * @Author UMA DUTTA-duttuma
 * @CreatedOn 29 January, 2021 6:00:00
 * @IssueID CDTFASATMOD- 502
 */
import React from 'react';
import { Button } from '@manulife/mux';
import './fasatButton.scss';

const FasatButton = (props) => (
  <Button
    {...props}
    customStyle={{
      buttonStyle: {
        padding: '12px 4px',
        width: 'max-content',
      },
    }}
  >
    {props.children}
  </Button>
);

export default FasatButton;
