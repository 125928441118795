/**
 * @Description FasatColumnOrder Value Prototypes File
 * @FileName valueShape.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
import PropTypes from 'prop-types';

import optionShape from './optionShape';

export default PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    optionShape,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      options: PropTypes.arrayOf(optionShape),
    }),
  ]),
);
