import * as Yup from 'yup';

const exportRule = {
  fileType: ['BCR_ADD_FER001_LEVEL'],
  pageType: ['BCR_ADD_FER001_LEVEL'],
};

const ruleConfig = {
  BCR_ADD_FER001_LEVEL: 'REQFLD',
};

const exportValidationSchema = () => Yup.object().shape({
  fileType: Yup.string().nullable().required('BCR_ADD_FER001_LEVEL'),
  pageType: Yup.string().nullable().required('BCR_ADD_FER001_LEVEL'),
});
export { exportValidationSchema, exportRule, ruleConfig };
