/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { put, call, all, takeEvery } from 'redux-saga/effects';
import { dataTransferActionType } from '../actions/actionTypes';

import { Logger, processError } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';

export function* getDataTransferProcesStart(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DT_START.endpoint;
  Logger.verbose(`Action Detail For Get Period Definition List : ${JSON.stringify(action)}`);
  yield all([
    // put(processingStarted()),
    put(getAction(dataTransferActionType.RESET_SERVER_VALUE, null)),
    put(getAction(dataTransferActionType.LOG_DT_START_PROCESS, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_DT_START, action.data);
    const responseData1 = { ...responseData };
    yield all([
      // put(getAction(dataTransferActionType.GET_DT_START_PROCESS_RESPONSE, responseData1)),
      put(getAction(dataTransferActionType.GET_DT_START_PROCESS_TABLE_RES, responseData1)),
      // put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DT_START.endpoint = initApiUrl;
    yield all([
      put(getAction(dataTransferActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dataTransferActionType.GET_DT_START_PROCESS_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDTsProcesCancel(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DT_CANCEL.endpoint;
  Logger.verbose(`Action Detail For Get Period Definition List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(dataTransferActionType.RESET_SERVER_VALUE, null)),
    put(getAction(dataTransferActionType.LOG_DT_START_PROCESS, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_DT_CANCEL, action.data);
    yield all([
      put(getAction(dataTransferActionType.GET_DT_CANCEL_PROCESS_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DT_CANCEL.endpoint = initApiUrl;
    yield all([
      put(getAction(dataTransferActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dataTransferActionType.GET_DT_START_PROCESS_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* setDTsProcesSequence(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DT_SEQUENCE.endpoint;
  Logger.verbose(`Action Detail For Get Period Definition List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(dataTransferActionType.RESET_SERVER_VALUE, null)),
    put(getAction(dataTransferActionType.GET_DT_START_SEQUENCE_RESPONSE, null)),
    put(getAction(dataTransferActionType.LOG_DT_SET_SEQUENCE, action.data)),
  ]);
  let resetServerValue = { sequenceRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_DT_SEQUENCE, action.data);
    yield all([
      put(getAction(dataTransferActionType.GET_DT_START_SEQUENCE_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { sequenceRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DT_SEQUENCE.endpoint = initApiUrl;
    yield all([
      put(getAction(dataTransferActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dataTransferActionType.SET_DT_SEQUENCE_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDataTransferList(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DT_LIST.endpoint;
  Logger.verbose(`Action Detail For Get Period Definition List : ${JSON.stringify(action)}`);
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(dataTransferActionType.RESET_SERVER_VALUE, resetServerValue)),
    put(getAction(dataTransferActionType.LOG_DT_LIST_REQUEST, action.data)),
  ]);
  try {
    const locale = `/${action.data}`;
    const updatedApiUrl = initApiUrl + locale;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_DT_LIST };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    moduleAPI.ADMINISTRATION_DT_LIST.endpoint = initApiUrl;
    yield all([
      put(getAction(dataTransferActionType.GET_DT_LIST_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DT_LIST.endpoint = initApiUrl;
    yield all([
      put(getAction(dataTransferActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dataTransferActionType.GET_DT_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  // Date Transfer
  yield takeEvery(dataTransferActionType.GET_DT_LIST_REQUEST, getDataTransferList);
  yield takeEvery(dataTransferActionType.GET_DT_START_PROCESS, getDataTransferProcesStart);
  yield takeEvery(dataTransferActionType.SET_DT_SEQUENCE, setDTsProcesSequence);
  yield takeEvery(dataTransferActionType.GET_DT_CANCEL_PROCESS_REQ, getDTsProcesCancel);
}
export default moduleWatcher;
