import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { massAgentTransferInqActionTypes } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from '../../../report/redux/reducers/utilities/utility';
import { commonTypes } from '../../../../common/redux/actions/commonActions';

export const REDUCER_NAME = 'MassAgentTransferInqFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const massAgentTransferInqFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case massAgentTransferInqActionTypes.UPDATE_MATI_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case massAgentTransferInqActionTypes.REMOVE_MATI_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case massAgentTransferInqActionTypes.UPDATE_MATI_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const massAgentTransferInqFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case massAgentTransferInqActionTypes.LOG_MATI_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case massAgentTransferInqActionTypes.GET_MATI_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case massAgentTransferInqActionTypes.GET_MATI_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case massAgentTransferInqActionTypes.RESET_MATI_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case massAgentTransferInqActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case massAgentTransferInqActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const MassAgentTransferInqFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = massAgentTransferInqFilterListConfig(state, action);
  const stateReqRes = massAgentTransferInqFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default MassAgentTransferInqFilterList;
