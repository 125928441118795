/**
 * @Description Application Router - deals with public and protected routing
 * @FileName fasatRouter.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 25 January, 2021 07:56:35
 * @IssueID - 311
 */

import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { FasatProtectedRoute, FasatPublicRoute } from './app/common/components';
import appRouting from './config/appRouting';
import FasatHome from './fasatHome';

/**
 * Component which should be shown during the lazy component being loaded
 */
const FallbackComponent = () => 'Loading...';

/**
 * Fasat application route which dynamically create all the configured
 * routes. please refer /config/routeConfig.js file for route configuration
 */
const FasatRouter = ({ isBatchProcessing, menuItems }) => {
  const publicRoutes = appRouting.filter((route) => route.public);
  const protectedRoute = appRouting.filter((route) => !route.public);
  const userClaim = useSelector((state) => state.ApplicationStateRBAC.rbacData);
  const { isExternalUser } = useSelector((state) => state.ApplicationStateRBAC);

  return (
    <Suspense fallback={<FallbackComponent />}>
      <Switch>
        <Route path="/" exact>
          <FasatHome isExternalUser={isExternalUser} menuItems={menuItems} />
        </Route>
        <Route path="/signin-oidc" exact>
          <FasatHome />
        </Route>
        {/* this component can be wrapped as PublicRouteComponent. */}
        {publicRoutes.map((route) => (
          <FasatPublicRoute
            path={route.path}
            exact={route.exact}
            component={route.component}
            key={route.id}
            routeConfig={route}
          />
        ))}
        {userClaim && protectedRoute.map((route) => (
          <FasatProtectedRoute
            path={route.path}
            exact={route.exact}
            component={route.component}
            key={route.id}
            routeConfig={route}
            aclKey={route.aclKey[0]}
            userClaim={userClaim}
          />
        ))}
        <Route path="*">
          {isBatchProcessing ? <FasatHome /> : <div>404 Not found</div>}
        </Route>
      </Switch>
    </Suspense>
  );
};

FasatRouter.propTypes = {
  isBatchProcessing: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
};

FasatRouter.defaultProps = {
  isBatchProcessing: false,
  menuItems: [],
};

export default FasatRouter;
