/**
 * @Description Action types for activity
 * @FileName actionTypes.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:13:37
 * @IssueID NA
 */
// Activity Processed - Action Types
export const activityProcessedActionType = {
  LOG_AP_LIST_REQUEST: 'LOG_AP_LIST_REQUEST',
  GET_AP_LIST_REQUEST: 'GET_AP_LIST_REQUEST',
  GET_AP_LIST_RESPONSE: 'GET_AP_LIST_RESPONSE',
  GET_AP_LIST_ERROR: 'GET_AP_LIST_ERROR',
  GET_AP_LIST_LOG_REQ: 'GET_AP_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_AP_TABLE_CONFIGURATION',
  UPDATE_AP_FILTER_CONFIG: 'UPDATE_AP_FILTER_CONFIG',
  GET_AP_EXPORT_REQUEST: 'GET_AP_EXPORT_REQUEST',
  REMOVE_AP_USER_DEFAULT_FILTER: 'REMOVE_AP_USER_DEFAULT_FILTER',

  LOG_AP_BY_ID_REQUEST: 'LOG_AP_BY_ID_REQUEST',
  GET_AP_BY_ID_REQUEST: 'GET_AP_BY_ID_REQUEST',
  GET_AP_BY_ID_RESPONSE: 'GET_AP_BY_ID_RESPONSE',
  GET_AP_BY_ID_ERROR: 'GET_AP_BY_ID_ERROR',
  GET_AP_BY_ID_LOG_REQ: 'GET_AP_BY_ID_LOG_REQ',

  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_AP_REQUEST: 'UPSERT_AP_REQUEST',
  GET_AP_REVERSE_REQUEST: 'GET_AP_REVERSE_REQUEST',
  GET_AP_REV_REP_REQUEST: 'GET_AP_REV_REP_REQUEST',
  GET_REVERSE_STATUS_AP: 'GET_REVERSE_STATUS_AP',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',

  SET_AP_DETAILS_RESPONSE: 'SET_AP_DETAILS_RESPONSE',

  // bulk validation Count

  GET_BULK_VALID_COUNT_RESPONSE: 'GET_BULK_VALID_COUNT_RESPONSE',
  GET_BULK_VALID_COUNT: 'GET_BULK_VALID_COUNT',

  // Bulk Concurrency

  GET_BULK_CONCURRENCY_RESPONSE: 'GET_BULK_CONCURRENCY_RESPONSE',
  GET_BULK_CONCURRENCY: 'GET_BULK_CONCURRENCY',

  // Reset store
  RESET_AP_STORE: 'RESET_AP_STORE',

  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',

  // lookups
  RESET_AP_LOOKUPDATA: 'RESET_AP_LOOKUPDATA',
  GET_AP_LOOKUP_REQUEST: 'GET_AP_LOOKUP_REQUEST',

  GET_AP_AUP_CONCURRENCY: 'GET_AP_AUP_CONCURRENCY',
  LOG_AP_AUP_CONCURRENCY: 'LOG_AP_AUP_CONCURRENCY',
  GET_AP_AUP_CONCURRENCY_RESPONSE: 'GET_AP_AUP_CONCURRENCY_RESPONSE',
  GET_AP_AUP_CONCURRENCY_ERROR: 'GET_AP_AUP_CONCURRENCY_ERROR',
  GET_AP_AUP_CONCURRENCY_LOG_REQ: 'GET_AP_AUP_CONCURRENCY_LOG_REQ',
};
export const activityUnProcessedActionType = {
  LOG_AUP_LIST_REQUEST: 'LOG_AUP_LIST_REQUEST',
  GET_AUP_LIST_REQUEST: 'GET_AUP_LIST_REQUEST',
  GET_AUP_LIST_RESPONSE: 'GET_AUP_LIST_RESPONSE',
  GET_AUP_LIST_ERROR: 'GET_AUP_LIST_ERROR',
  GET_AUP_LIST_LOG_REQ: 'GET_AUP_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_AUP_TABLE_CONFIGURATION',
  UPDATE_AUP_FILTER_CONFIG: 'UPDATE_AUP_FILTER_CONFIG',
  GET_AUP_EXPORT_REQUEST: 'GET_AUP_EXPORT_REQUEST',
  REMOVE_AUP_USER_DEFAULT_FILTER: 'REMOVE_AUP_USER_DEFAULT_FILTER',

  GET_AUP_NEGATIVE_REQUEST: 'GET_AUP_NEGATIVE_REQUEST',
  LOG_AUP_NEGATIVE_REQUEST: 'LOG_AUP_NEGATIVE_REQUEST',
  GET_AUP_NEGATIVE_RESPONSE: 'GET_AUP_NEGATIVE_RESPONSE',
  GET_AUP_NEGATIVE_ERROR: 'GET_AUP_NEGATIVE_ERROR',

  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_AUP_REQUEST: 'UPSERT_AUP_REQUEST',
  GET_AUP_PROCESS_REQUEST: 'GET_AUP_PROCESS_REQUEST',
  GET_AUP_DELETE_REQUEST: 'GET_AUP_DELETE_REQUEST',
  GET_REVERSE_STATUS_AUP: 'GET_REVERSE_STATUS_AUP',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',

  UPDATE_AUP_RESPONSE: 'UPDATE_AUP_RESPONSE',
  UPSERT_AUP_RESPONSE: 'UPSERT_AUP_RESPONSE',
  DELETE_AUP_REQUEST: 'DELETE_AUP_REQUEST',
  DELETE_AUP_RESPONSE: 'DELETE_AUP_RESPONSE',
  DELETE_AUP_ERROR: 'DELETE_AUP_ERROR',

  SET_AUP_DETAILS_RESPONSE: 'SET_AUP_DETAILS_RESPONSE',

  RESET_AUP_STORE: 'RESET_AUP_STORE',

  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',

  RESET_AUP_LOOKUPDATA: 'RESET_AUP_LOOKUPDATA',
  GET_AUP_LOOKUP_REQUEST: 'GET_AUP_LOOKUP_REQUEST',

  AUP_DELETE_STATUS: 'AUP_DELETE_STATUS',
  AUP_DELETE_STATUS_RESPONSE: 'AUP_DELETE_STATUS_RESPONSE',
};

export const activityProcessedExtActionType = {
  RESET_PARAM_STOR: 'RESET_PARAM_STOR',
  LOG_AP_LIST_REQUEST: 'LOG_AP_LIST_REQUEST',
  GET_AP_LIST_EXT_REQUEST: 'GET_AP_LIST_EXT_REQUEST',
  GET_AP_LIST_EXT_RESPONSE: 'GET_AP_LIST_EXT_RESPONSE',
  GET_AP_LIST_ERROR: 'GET_AP_LIST_ERROR',
  GET_AP_LIST_LOG_REQ: 'GET_AP_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_AP_FILTER_CONFIG: 'UPDATE_AP_FILTER_CONFIG',
  GET_AP_EXPORT_EXT_REQUEST: 'GET_AP_EXPORT_EXT_REQUEST',
  REMOVE_AP_USER_DEFAULT_FILTER: 'REMOVE_AP_USER_DEFAULT_FILTER',

  LOG_AP_BY_ID_REQUEST: 'LOG_AP_BY_ID_REQUEST',
  GET_AP_BY_ID_REQUEST: 'GET_AP_BY_ID_REQUEST',
  GET_AP_BY_ID_RESPONSE: 'GET_AP_BY_ID_RESPONSE',
  GET_AP_BY_ID_ERROR: 'GET_AP_BY_ID_ERROR',
  GET_AP_BY_ID_LOG_REQ: 'GET_AP_BY_ID_LOG_REQ',

  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_AP_REQUEST: 'UPSERT_AP_REQUEST',
  GET_AP_REVERSE_REQUEST: 'GET_AP_REVERSE_REQUEST',
  GET_AP_REV_REP_REQUEST: 'GET_AP_REV_REP_REQUEST',
  GET_REVERSE_STATUS_AP: 'GET_REVERSE_STATUS_AP',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',

  SET_AP_DETAILS_RESPONSE: 'SET_AP_DETAILS_RESPONSE',

  // Reset store
  RESET_AP_STORE: 'RESET_AP_STORE',

  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',

  // lookups
  RESET_AP_LOOKUPDATA: 'RESET_AP_LOOKUPDATA',
  GET_AP_LOOKUP_REQUEST: 'GET_AP_LOOKUP_REQUEST',
};

export const fpaMonitoringAction = {

  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',

  LOG_FPA_LIST_REQUEST: 'LOG_FPA_LIST_REQUEST',
  GET_FPA_LIST_REQUEST: 'GET_FPA_LIST_REQUEST',
  GET_FPA_LIST_RESPONSE: 'GET_FPA_LIST_RESPONSE',
  GET_FPA_LIST_ERROR: 'GET_FPA_LIST_ERROR',
  GET_FPA_LIST_LOG_REQ: 'GET_FPA_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_FPA_TABLE_CONFIGURATION',
  UPDATE_FPA_FILTER_CONFIG: 'UPDATE_FPA_FILTER_CONFIG',
  REMOVE_FPA_USER_DEFAULT_FILTER: 'REMOVE_FPA_USER_DEFAULT_FILTER',

  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_FPA_REQUEST: 'UPSERT_FPA_REQUEST',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',

  UPDATE_FPA_RESPONSE: 'UPDATE_FPA_RESPONSE',
  UPSERT_FPA_RESPONSE: 'UPSERT_FPA_RESPONSE',

  SET_FPA_DETAILS_RESPONSE: 'SET_FPA_DETAILS_RESPONSE',

  RESET_FPA_STORE: 'RESET_FPA_STORE',

  RESET_FPA_LOOKUPDATA: 'RESET_FPA_LOOKUPDATA',
  GET_FPA_LOOKUP_REQUEST: 'GET_FPA_LOOKUP_REQUEST',

  // batch num

  LOG_FPA_BATCH_NUM_REQUEST: 'LOG_FPA_BATCH_NUM_REQUEST',
  GET_FPA_BATCH_NUM_REQUEST: 'GET_FPA_BATCH_NUM_REQUEST',
  GET_FPA_BATCH_NUM_RESPONSE: 'GET_FPA_BATCH_NUM_RESPONSE',
  GET_FPA_BATCH_NUM_ERROR: 'GET_FPA_BATCH_NUM_ERROR',
  GET_FPA_BATCH_NUM_LOG_REQ: 'GET_FPA_BATCH_NUM_LOG_REQ',
  GET_FPA_BATCH_NUM_ELIGIBLE: 'GET_FPA_BATCH_NUM_ELIGIBLE',
  GET_FPA_BATCH_NUM_NONELIGIBLE: 'GET_FPA_BATCH_NUM_NONELIGIBLE',

  // Adjustment Error Record

  LOG_FPA_ADJXERR_NUM_REQUEST: 'LOG_FPA_ADJXERR_NUM_REQUEST',
  GET_FPA_ADJXERR_NUM_REQUEST: 'GET_FPA_ADJXERR_NUM_REQUEST',
  GET_FPA_ADJXERR_NUM_RESPONSE: 'GET_FPA_ADJXERR_NUM_RESPONSE',
  GET_FPA_ADJXERR_NUM_ERROR: 'GET_FPA_ADJXERR_NUM_ERROR',
  GET_FPA_ADJXERR_NUM_LOG_REQ: 'GET_FPA_ADJXERR_NUM_LOG_REQ',
};
