/* eslint-disable import/prefer-default-export */
/**
 * @Description Action types for
 * @FileName repReActionTypes.js
 * @Author pgoutam
 * @CreatedOn 16 Jan 23
 * @IssueID 224
 */

export const repReActionTypes = {
  LOG_CSR_LIST_REQUEST: 'LOG_CSR_LIST_REQUEST',
  GET_CSR_LIST_REQUEST: 'GET_CSR_LIST_REQUEST',
  GET_CSR_LIST_RESPONSE: 'GET_CSR_LIST_RESPONSE',
  GET_CSR_LIST_ERROR: 'GET_CSR_LIST_ERROR',
  UPDATE_CSR_COLUMN_CONFIG: 'UPDATE_CSR_COLUMN_CONFIG',
  UPDATE_CSR_FILTER_CONFIG: 'UPDATE_CSR_FILTER_CONFIG',
  UPDATE_CSR_TABLE_CONFIGURATION: 'UPDATE_CSR_TABLE_CONFIGURATION',
  SET_CSR_DETAILS_RESPONSE: 'SET_CSR_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_CSR_USER_DEFAULT_FILTER: 'REMOVE_CSR_USER_DEFAULT_FILTER',
  // Reset store
  RESET_CSR_STORE: 'RESET_CSR_STORE',
  // Export List for BCR
  GET_CSR_EXPORT_REQUEST: 'GET_CSR_EXPORT_REQUEST',
  // Update Server Error Section
  COMP_RESET_SERVER_VALUE: 'COMP_RESET_SERVER_VALUE',
  UPDATE_CLM_SRT_ORD_SELF: 'UPDATE_CLM_SRT_ORD_SELF',

  CSR_PRINT_PDF: 'CSR_PRINT_PDF',
  CSR_PRINT_PDF_B4: 'CSR_PRINT_PDF_B4',

  COMP_STATEMENT_DOWNLOAD: 'COMP_STATEMENT_DOWNLOAD',
  COMP_STATEMENT_PRINT: 'COMP_STATEMENT_PRINT',
  COMP_STATEMENT_VIEW: 'COMP_STATEMENT_VIEW',
  GET_DB_SYSTEM_DATE: 'GET_DB_SYSTEM_DATE',

  GET_CSR_LIST_REQUEST_EXT: 'GET_CSR_LIST_REQUEST_EXT',
};
