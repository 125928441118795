/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/**
 * @Description This is FasatLink component page.
 * This is a wrapper on "TextLink" component from MUX without adding any custom functionlities
 * @FileName fasatLink.js
 * @Author Imroz Dawar- dawarim
 * @CreatedOn 2 February, 2021 20:08:00
 * @IssueID CDTFASATMOD- 325
 */
import React from 'react';
import { TextLink } from '@manulife/mux';
import './fasatLink.scss';

const FasatLink = (props) => <TextLink {...props}>{props.children}</TextLink>;

export default FasatLink;
