/* eslint-disable no-debugger */
/**
 * @Description Fasat Grid Footer Bar component
 * @FileName fasatGridFooterBar.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 22 March, 2021 04:53:07
 * @IssueID 671
 */
import React from 'react';
import PropTypes from 'prop-types';
import './fasatGridFooterBar.scss';
import { useTranslation } from 'react-i18next';
import FasatPagination from '../fasatPagination/fasatPagination';
import { staticCommonLabelKeys } from '../../moduleConstants';

const stringTrue = 'true';
const filterExpandablePanelHeaderID = 'filterExpandablePanel-header';

const fromTORecord = ({ rowCount, currentPageIndex, pageSize }) => {
  const fromRecord = rowCount > 0 ? (currentPageIndex - 1) * pageSize + 1 : 0;
  const toRecord = rowCount > 0 ? (currentPageIndex - 1) * pageSize + rowCount : 0;
  return { fromRecord, toRecord };
};

const recordSummaryContainer = ({ showingLabel,
  fromRecord,
  hypenLabel,
  toRecord,
  ofLabel,
  totalRowCount,
  activateDataGrid }) => {
  let showSummary;
  if (fromRecord > 0 && activateDataGrid) {
    showSummary = () => (
      <div className="recordSummarySection">
        <span>
          {showingLabel}
            &nbsp;
        </span>
        {fromRecord}
        <span>{hypenLabel}</span>
        {toRecord}
        <span>
            &nbsp;
          {ofLabel}
            &nbsp;
        </span>
        {totalRowCount > 0 ? totalRowCount : 0}
      </div>
    );
  } else if (fromRecord === 0 || !activateDataGrid) {
    showSummary = () => (
      <div className="recordSummarySection">
        <span>
          {showingLabel}
         &nbsp;
        </span>
        0
        <span>{hypenLabel}</span>
        0
        <span>
         &nbsp;
          {ofLabel}
         &nbsp;
        </span>
        0
      </div>
    );
  } else {
    showSummary = null;
  }

  return { showSummary };
};

const FasatGridFooterBar = (props) => {
  const [t] = useTranslation();
  const {
    showingLabel,
    ofLabel,
    hypenLabel,
    currentPageIndex,
    pageSize,
    rowCount,
    totalRowCount,
    onPagination,
    activateDataGrid,
  } = props;

  const { fromRecord, toRecord } = fromTORecord({ rowCount, currentPageIndex, pageSize });
  const { showSummary } = recordSummaryContainer({
    showingLabel,
    fromRecord,
    hypenLabel,
    toRecord,
    ofLabel,
    totalRowCount,
    activateDataGrid,
  });

  const onPageChange = (event) => {
    if (onPagination) {
      onPagination(event);
      if (document.getElementById(filterExpandablePanelHeaderID)
      && document.getElementById(filterExpandablePanelHeaderID).getAttribute('aria-expanded') === stringTrue) {
        document.getElementById(filterExpandablePanelHeaderID).click();
      }
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="footerBarWrapper" data-testid="FasatGridFooterBarComponent">
      {showSummary()}
      <FasatPagination
        {...props}
        onPageChange={onPageChange}
        activateDataGrid={activateDataGrid}
        previousAltText={t(staticCommonLabelKeys.ARIA_COMMON_PAGINATION_BUTTON_PREVIOUS)}
        nextAltText={t(staticCommonLabelKeys.ARIA_COMMON_PAGINATION_BUTTON_NEXT)}
        currentItemAriaLabel={t(staticCommonLabelKeys.ARIA_COMMON_PAGINATION_INPUT_LABEL)}
      />
    </div>
  );
};

FasatGridFooterBar.propTypes = {
  showingLabel: PropTypes.string,
  ofLabel: PropTypes.string,
  hypenLabel: PropTypes.string,
  activateDataGrid: PropTypes.bool,
  currentPageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  rowCount: PropTypes.string,
  onPagination: PropTypes.string,
  totalRowCount: PropTypes.number,
};

FasatGridFooterBar.defaultProps = {
  showingLabel: 'Showing',
  ofLabel: 'of',
  hypenLabel: '-',
  activateDataGrid: false,
  currentPageIndex: 0,
  pageSize: 0,
  totalRowCount: 0,
  rowCount: '0',
  onPagination: '0',
};

export default FasatGridFooterBar;
