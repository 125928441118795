const routeConstant = {
  REQ_INQUIRY_ROUTE: '/agency/reqinquiry',
  TASK_INQUIRY_ROUTE: '/agency/taskinquiry',
  EXCEPTIONS_ROUTE: '/agency/exceptionsInquiry',
  MASS_AGENT_TRANSFER_INQ_ROUTE: '/agency/massagenttrasferinquiry',
  PAYMENT_CONTROLLER_ROUTE: '/agency/paymentcontrollermovement',
  DEDUCTION_INQUIRY_ROUTE: '/agency/deductioninquiry',
};

/**
  * ACL keys for the module
  */
const aclKeysConstant = {
  // Requirement inquiry
  RBAC_RI_MENU: 'RBAC_RI_MENU',
  RBAC_RI_EXPORT: 'RBAC_RI_EXPORT',
  REQUIREMENT_INQUIRY_MENU: 'RBAC_RI_MENU',
  // task inquiry
  RBAC_TI_MENU: 'RBAC_TI_MENU',
  RBAC_TI_EXPORT: 'RBAC_TI_EXPORT',

  // mass agent transfer inquiry
  RBAC_MATI_MENU: 'RBAC_MATI_MENU',
  RBAC_MATI_EXPORT: 'RBAC_MATI_EXPORT',

  // payment controller movement
  RBAC_PCM_MENU: 'RBAC_PCM_MENU',
  RBAC_PCM_EXPORT: 'RBAC_PCM_EXPORT',

  // Exceptions
  RBAC_EXI_MENU: 'RBAC_EXI_MENU',
  RBAC_EXI_ADD: 'RBAC_EXI_ADD',
  RBAC_EXI_EDIT: 'RBAC_EXI_EDIT',
  RBAC_EXI_DELETE: 'RBAC_EXI_DELETE',
  RBAC_EXI_EXPORT: 'RBAC_EXI_EXPORT',

  // Deduction Inquiry

  RBAC_DI_MENU: 'RBAC_DI_MENU',
  RBAC_DI_EXPORT: 'RBAC_DI_EXPORT',

};

/**
  * i18n Keys for the module
  */
const staticLabelKeys = {
  /** Label for Exceptions
   * Actual label for Exceptions
   */
  EXCEPTIONS_MENU: 'RBAC_EXI_MENU',
  EXI_MODAL_TITLE: 'EXI_LABEL_ADD',
  EXI_EDIT_MODAL_TITLE: 'EXI_LABEL_EDIT',
  EXI_PAGE_TITLE: 'EXI_PAGE_TITLE',
  EXI_LABEL_ORGTYPE: 'EXI_LABEL_ORGTYPE',
  EXI_LABEL_EXCEPTION_TYPE: 'EXI_LABEL_EXCEPTION_TYPE',
  EXI_LABEL_REASON: 'EXI_LABEL_REASON',
  EXI_LABEL_BFN: 'EXI_LABEL_BUSFIRSTNAME',
  EXI_LABEL_LAST_MODIFIED_DATE: 'EXI_LABEL_LAST_MODIFIED_DATE',
  /**
    * Static Label Keys for Exceptions Modal
    */
  EXI_DEL_CONF_MSG: 'EXI_DEL_CONFIRM',
  EXI_DEL_SUCCESS_MSG: 'EXI_DELETE_SUCCESS',
  EXI_EDIT_SUCCESS: 'EXI_EDIT_SUCCESS',
  EXI_DUPLICATE: 'EXI_DUPLICATE',
  EXI_ADD_FAILED: 'EXI_ADD_FAILED',
  EXI_ADD_CONFIRM: 'EXI_ADD_CONFIRM',
  EXI_DEL_FAILED: 'EXI_DEL_FAILED',
  EXI_EDIT_FAILED: 'EXI_EDIT_FAILED',
  EXI_ADD_SUCCESS: 'EXI_ADD_SUCCESS',

  // Requirement inquiry
  REQ_PAGE_TITLE: 'REQ_PAGE_TITLE',
  REQ_LABEL_DOC: 'REQ_LABEL_DOC',
  REQ_LABEL_STATUS: 'REQ_LABEL_STATUS',
  REQ_LABEL_USERID: 'REQ_LABEL_USERID',
  REQ_LABEL_NOTIFYDT: 'REQ_LABEL_NOTIFYDT',
  REQ_LABEL_NOTIFYSTOP: 'REQ_LABEL_NOTIFYSTOP',
  // task inquiry
  TASK_INQUIRY_PAGE_TITLE: 'TASK_INQUIRY_PAGE_TITLE',
  TI_LABEL_FFID: 'TI_LABEL_FFID',
  TI_LABEL_FFLEVEL: 'TI_LABEL_FFLEVEL',
  TI_LABEL_SEQ: 'TI_LABEL_SEQ',
  TI_LABEL_TASK: 'TI_LABEL_TASK',
  TI_LABEL_LFFID: 'TI_LABEL_LFFID',
  TI_LABEL_LFFLEV: 'TI_LABEL_LFFLEV',
  TI_LABEL_DEFLEV: 'TI_LABEL_DEFLEV',
  TI_LABEL_CREATEDEF: 'TI_LABEL_CREATEDEF',
  TI_LABEL_CREATEDEFLEV: 'TI_LABEL_CREATEDEFLEV',
  TI_LABEL_DET1: 'TI_LABEL_DET1',
  TI_LABEL_DET2: 'TI_LABEL_DET2',
  TI_LABEL_DET3: 'TI_LABEL_DET3',
  TI_LABEL_DET4: 'TI_LABEL_DET4',
  TI_LABEL_DET5: 'TI_LABEL_DET5',
  TI_LABEL_DET6: 'TI_LABEL_DET6',
  TI_LABEL_DET7: 'TI_LABEL_DET7',
  TI_LABEL_DET8: 'TI_LABEL_DET8',
  TI_LABEL_DET9: 'TI_LABEL_DET9',
  TI_LABEL_DET10: 'TI_LABEL_DET10',
  TI_LABEL_OVRRTASK: 'TI_LABEL_OVRRTASK',
  // Mass Agent Transfer
  MASS_TRANSFER_INQUIRY_PAGE_TITLE: 'MASS_TRANSFER_INQUIRY_PAGE_TITLE',
  MATI_LABEL_TRANSFERFROM: 'MATI_LABEL_TRANSFERFROM',
  MATI_LABEL_TRANSFERFROMLEV: 'MATI_LABEL_TRANSFERFROMLEV',
  MATI_LABEL_TRANSFERTO: 'MATI_LABEL_TRANSFERTO',
  MATI_LABEL_TRANSFERTOLEV: 'MATI_LABEL_TRANSFERTOLEV',
  MATI_LABEL_TRANSSTAT: 'MATI_LABEL_TRANSSTAT',
  MATI_LABEL_AGENTTRANSFER: 'MATI_LABEL_AGENTTRANSFER',
  MATI_LABEL_AGENTTRANSFERLEV: 'MATI_LABEL_AGENTTRANSFERLEV',
  MATI_LABEL_TRANSFERRINGOPT: 'MATI_LABEL_TRANSFERRINGOPT',
  MATI_LABEL_BATCHNUM: 'MATI_LABEL_BATCHNUM',
  MATI_LABEL_TRANSFERDT: 'MATI_LABEL_TRANSFERDT',
  MATI_LABEL_CHANGEREASON: 'MATI_LABEL_CHANGEREASON',
  MATI_LABEL_REQBY: 'MATI_LABEL_REQBY',
  MATI_LABEL_EXECUTEDT: 'MATI_LABEL_EXECUTEDT',
  // payment controller movement
  PAYMENT_CONTROLLER_PAGE_TITLE: 'PAYMENT_CONTROLLER_PAGE_TITLE',
  PCM_LABEL_MSC: 'PCM_LABEL_MSC',
  PCM_LABEL_SURNAME: 'PCM_LABEL_SURNAME',
  PCM_LABEL_FIRSTNAME: 'PCM_LABEL_FIRSTNAME',
  PCM_LABEL_EFFDT: 'PCM_LABEL_EFFDT',
  PCM_LABEL_ENDDT: 'PCM_LABEL_ENDDT',
  PCM_LABEL_PAYEEID: 'PCM_LABEL_PAYEEID',
  PCM_LABEL_PAYEESURNAME: 'PCM_LABEL_PAYEESURNAME',
  PCM_LABEL_PAYEEFIRSTNAME: 'PCM_LABEL_PAYEEFIRSTNAME',

  // Deduction inquiry
  DEDUCTION_INQUIRY_MENU: 'RBAC_DI_MENU',
  DI_TITLE_DEDINQ: 'DI_TITLE_DEDINQ',
  DI_LABEL_BUSINESSLSTNAME: 'DI_LABEL_BUSINESSLSTNAME',
  DI_LABEL_LSTPRDDATE: 'DI_LABEL_LSTPRDDATE',
  DI_LABEL_DEDTYPE: 'DI_LABEL_DEDTYPE',
  DI_LABEL_TRANSAGT: 'DI_LABEL_TRANSAGT',
  DI_LABEL_PERCENTAGE: 'DI_LABEL_PERCENTAGE',
  DI_LABEL_TRANSAMT: 'DI_LABEL_TRANSAMT',
  DI_LABEL_CUMULAMT: 'DI_LABEL_CUMULAMT',
  DI_LABEL_SPECIALPAY: 'DI_LABEL_SPECIALPAY',
  DI_LABEL_PERBALANCE: 'DI_LABEL_PERBALANCE',
  DI_LABEL_PERFYC: 'DI_LABEL_PERFYC',
  DI_LABEL_PERRENEWAL: 'DI_LABEL_PERRENEWAL',
  DI_LABEL_PERFSTYR: 'DI_LABEL_PERFSTYR',
  DI_LABEL_PERRENOVRD: 'DI_LABEL_PERRENOVRD',
  DI_LABEL_COSTCNTR: 'DI_LABEL_COSTCNTR',
  DI_LABEL_MONTHTRG: 'DI_LABEL_MONTHTRG',
  DI_LABEL_MONTRGAMT: 'DI_LABEL_MONTRGAMT',
  DI_LABEL_MONCUMUL: 'DI_LABEL_MONCUMUL',
  DI_LABEL_CHQMEMOREMARK: 'DI_LABEL_CHQMEMOREMARK',

  // FF Balance - Production
  FFBALPROD_LABEL_YEAR_TO_DATE: 'FFBALPROD_LABEL_YEAR_TO_DATE',
  FFBALPROD_LABEL_FYC: 'FFBALPROD_LABEL_FYC',
  FFBALPROD_LABEL_DEDUCTIONS: 'TD_LABEL_DEDUCTIONS',
  FFBALPROD_LABEL_DEDUCTIONS_YTD: 'FFBALPROD_LABEL_DEDUCTIONS_YTD',
  FFBALPROD_LABEL_TRANS: 'BLS_LABEL_TRANS',
  FFBALPROD_LABEL_TRANS_YTD: 'FFBALPROD_LABEL_TRANS_YTD',
  FFBALPROD_LABEL_PYMT: 'BLS_LABEL_PYMT',
  FFBALPROD_LABEL_PYMT_YTD: 'FFBALPROD_LABEL_PYMT_YTD',
  FFBALPROD_LABEL_NEGOTIATEDDEBIT: 'FFBALPROD_LABEL_NEGOTIATEDDEBIT',
  FFBALPROD_LABEL_ML_OWE_BAL: 'FFBALPROD_LABEL_ML_OWE_BAL',
  FFBALPROD_HEADER_STMT_PRD: 'FFBALPROD_HEADER_STMT_PRD',
  FFBALPROD_LABEL_CURRENT: 'FFT_BUTTON_CURRENT',
  FFBALPROD_LABEL_BALANCE: 'PAY_LABEL_BALANCE',
  FFBALPROD_LABEL_TAXEARNINGS: 'TD_LABEL_TAXEARNINGS',
  FFBALPROD_LABEL_TAXEARNINGS_YTD: 'FFBALPROD_LABEL_TAXEARNINGS_YTD',
  FFBALPROD_LABEL_MDRT: 'BLS_LABEL_MDRT',
  FFBALPROD_LABEL_MDRT_YTD: 'FFBALPROD_LABEL_MDRT_YTD',
  FFBALPROD_LABEL_YEAR_TO_DATE_YTD: 'FFBALPROD_LABEL_YEAR_TO_DATE_YTD',
  FFBALPROD_HEADER_DETAILS_PRD: 'FFBALPROD_HEADER_DETAILS_PRD',
  FFBALPROD_LABEL_FYC_GROSS_COMM: 'PRODSUM_LABEL_FYC_GROSS',
  FFBALPROD_LABEL_SALES_CREDITS: 'PRODSUM_LABEL_SALES',
  FFBALPROD_LABEL_PERSISTENCY_CREDITS: 'PRODSUM_LABEL_PERSISTENCY',
  FFBALPROD_LABEL_PERSISTENCY_LAPSES: 'PRODSUM_LABEL_PERSISTENCY_LAPSES',
  FFBALPROD_LABEL_CURR_YEAR_YTD_AMT: 'FFBALPROD_LABEL_CURR_YEAR_YTD_AMT',
  FFBALPROD_LABEL_PREV_YEAR_MONTHLY_AMT: 'FFBALPROD_LABEL_PREV_YEAR_MONTHLY_AMT',
  FFBALPROD_LABEL_PREV_YEAR_YTD_AMT: 'FFBALPROD_LABEL_PREV_YEAR_YTD_AMT',
  FFBALPROD_LABEL_RENEWAL_OVERRIDES: 'PRODSUM_LABEL_RENEWAL',
  FFBALPROD_LABEL_CURRENTYEAR: 'PD_LABEL_CURRENTYEAR',
  FFBALPROD_LABEL_MONTHLY_COMP: 'FFBALPROD_LABEL_MONTHLY_COMP',
  FFBALPROD_LABEL_HIERARCHY_VIEW: 'FFBALPROD_LABEL_HIERARCHY_VIEW',
  FFBALPROD_LABEL_MONTH_YEAR: 'FFBALPROD_LABEL_MONTH_YEAR',
  FFBALPROD_LABEL_MONTH_YEAR_YTD: 'FFBALPROD_LABEL_MONTH_YEAR_YTD',
  FFBALPROD_LABEL_EXPORT_BUSSVIEW: 'FFBALPROD_LABEL_EXPORT_BUSSVIEW',
  FFBALPROD_LABEL_EXPORT_PRODUCT: 'FFBALPROD_LABEL_EXPORT_PRODUCT',
  FFBALPROD_LABEL_EXPORT_PERIOD: 'FFBALPROD_LABEL_EXPORT_PERIOD',
  FFBALPROD_LABEL_EXPORT_MONTHLY: 'FFBALPROD_LABEL_EXPORT_MONTHLY',
};

export const moduleInquiryConstants = {
  routeConstant,
  aclKeysConstant,
  staticLabelKeys,
};

export default moduleInquiryConstants;
