import { fieldForceActionTypes } from '../actions/actionTypes';
import moduleAPI from '../../config/apiConfig';

const getFFBalProdApiURLPart1 = (action) => {
  let returnType = null;
  switch (action.type) {
    case fieldForceActionTypes.GET_FFBAL_SUMMARY_EXPORT_REQUEST:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_BALANCE_SUMMARY;
      return returnType;
    case fieldForceActionTypes.GET_FFBAL_HIERARCHY_EXPORT_REQUEST:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_BALANCE_HIERARCHY;
      return returnType;
    case fieldForceActionTypes.GET_FFBAL_PERIODMONTH_EXPORT_REQUEST:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_BALANCE_PERIOD_MONTH;
      return returnType;
    case fieldForceActionTypes.GET_FFBAL_PERIODMONTH_EXPORT_REQUEST_EXTERNAL:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_BALANCE_PERIOD_MONTH_EXTERNAL;
      return returnType;
    case fieldForceActionTypes.GET_FFPROD_BUIS_EXPORT_REQUEST:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_PROD_BUIS;
      return returnType;
    case fieldForceActionTypes.GET_FFPROD_BUIS_EXPORT_REQUEST_EXT:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_PROD_BUIS_EXT;
      return returnType;
    case fieldForceActionTypes.GET_FFPROD_HIER_EXPORT_REQUEST:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_PROD_HIER;
      return returnType;
    default:
      return returnType;
  }
};

const getFFBalProdApiURLPart2 = (action) => {
  let returnType = null;
  switch (action.type) {
    case fieldForceActionTypes.GET_FFPROD_PROD_EXPORT_REQUEST:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_PROD_PROD;
      return returnType;
    case fieldForceActionTypes.GET_FFPROD_PROD_EXPORT_REQUEST_EXT:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_PROD_PROD_EXT;
      return returnType;
    case fieldForceActionTypes.GET_FFPROD_MONTHCOMP_EXPORT_REQUEST:
      returnType = moduleAPI.ADMINISTRATION_FF_EXPORT_PROD_MONTHCOMP;
      return returnType;
    default:
      return returnType;
  }
};

export const getFFBalProdApiURLUtil = (action) => {
  const returnType = null;
  const ffBalProdApiURLPart1 = getFFBalProdApiURLPart1(action);
  if (ffBalProdApiURLPart1 !== null) {
    return ffBalProdApiURLPart1;
  }
  const ffBalProdApiURLPart2 = getFFBalProdApiURLPart2(action);
  if (ffBalProdApiURLPart2 !== null) {
    return ffBalProdApiURLPart2;
  }
  return returnType;
};

export default getFFBalProdApiURLUtil;
