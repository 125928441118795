/**
 * @Description Field Force Movement List
 * @FileName fieldForceMovementList.js
 * @Author Shreya Dhara - dharshr
 * @CreatedOn 24 July - 2023
 * @IssueID - 190
 */
import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { fieldForceMovementActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'FieldForceMovementList';

const getFFMovementListResponse = (state, action) => ({
  ...state,
  listData: action.data,
  listRequestError: null,
  listRequestProcessedOn: new Date(),
  loading: false,
});
const getFFmovementListError = (state, action) => ({
  ...state,
  listRequestError: action.data,
  listRequestProcessedOn: new Date(),
  loading: false,
});
const logFFMovementListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});
const getResetFFMovementStore = (state) => ({
  ...state,
  listData: null,
  listRequestError: null,
  listRequestProcessedOn: null,
  serverRequestError: null,
  loading: false,
});
const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});
const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const FieldForceMovementListREQRES = (state, action = null) => {
  switch (action.type) {
    case fieldForceMovementActionTypes.GET_FIELDFORCEMOVEMENT_LIST_RESPONSE:
      return getFFMovementListResponse(state, action);
    case fieldForceMovementActionTypes.GET_FIELDFORCEMOVEMENTR_LIST_ERROR:
      return getFFmovementListError(state, action);
    case fieldForceMovementActionTypes.LOG_FIELDFORCEMOVEMENT_LIST_REQUEST:
      return logFFMovementListRequest(state, action);
    case fieldForceMovementActionTypes.RESET_FIELDFORCEMOVEMENT_STORE:
      return getResetFFMovementStore(state);
    case fieldForceMovementActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    default:
      return null;
  }
};
const FieldForceMovementListConfig = (state, action = null) => {
  switch (action.type) {
    case fieldForceMovementActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case fieldForceMovementActionTypes.SET_FFM_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case fieldForceMovementActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case fieldForceMovementActionTypes.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: action.data && action.data.serverRequestError,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const FieldForceMovementList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateReqRes = FieldForceMovementListREQRES(state, action);
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  const stateConfig = FieldForceMovementListConfig(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  return state;
};

export default FieldForceMovementList;
