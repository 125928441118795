/**
 * @Description This is FASAT Toast component
 * @FileName fasatQuickActionNotification.js
 * @Author Imroz Dawar-dawarim
 * @CreatedOn 9 February, 2021 15:57:14
 * @IssueID CDTFASATMOD-314
 */

import { Icon } from '@manulife/mux';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import './fasatQuickActionNotification.scss';
import qanCheckmark from '../../assets/images/fasatQANCheckmark.svg';

const notificationTimeOut = 500;
/**
 * This would close the existing fasatToast component
 * has not been exported.
 * @function useEnterAlerter : used to close toast if enter/esc/spacebar is pressed
 *
 */
const keyCodeTwentySeven = 27;
const keyCodeThirtyTwo = 32;
const useEnterAlerter = (ref) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        event.code === 'Enter'
        || event.code === 'NumpadEnter'
        || event.keyCode === keyCodeTwentySeven
        || event.keyCode === keyCodeThirtyTwo
      ) {
        toast.dismiss();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [ref]);
};

/**
 * This would close the existing fasatToast component
 * has not been exported.
 * @function useOutsideAlerter : used to close toast if mouse is clicked
 */

const useOutsideAlerter = (ref) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        toast.dismiss();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

/**
 * This would return the fasat specific toast message styling. Hence this component
 * has not been exported.
 * @param {String} message : message which would be shown in the toast
 *
 */

export const FasatToastContent = ({ message }) => {
  const toastRef = useRef(0);
  useOutsideAlerter(toastRef);
  useEnterAlerter(toastRef);
  return (
    <div className="messageContainer" ref={toastRef}>
      <span className="iconContainer">
        <Icon path={qanCheckmark} alt="checkmark" width="24px" height="24px" />
      </span>
      <div>
        {message && typeof message === 'object' && message.map((msg) => <div className="message" key={msg}>{msg}</div>)}
        {typeof message === 'string' && <div className="message">{message}</div>}
      </div>
    </div>
  );
};

FasatToastContent.propTypes = {
  message: PropTypes.string.isRequired,
};

/**
 * This would show the message via FasatToastContainer.
 * Please note that you have to include FasatToastContainer
 * to make this function work.
 * @param {String} message - message which needs to be shown as toast message
 * @param {*} option - options to configure the toast
 */

const showFasatNotification = (
  message,
  timeout,
  containerId,
  option = {
    position: 'top-right',
    autoClose: timeout,
    hideProgressBar: true,
    closeOnClick: true,
    closeButton: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: 'fasatToastNotification',
  },
) => {
  window.scrollTo(0, 0);
  const toastOptions = option;
  if (containerId) {
    toastOptions.containerId = containerId;
  }
  setTimeout(() => {
    toast(<FasatToastContent message={message} />, toastOptions);
  }, notificationTimeOut);
};

export default showFasatNotification;
