import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PubSub from 'pubsub-js';
import { FasatColumnOrder, FasatLabel, FasatMessageCard, showFasatNotification } from './app/common/components';
import { globalConstants, staticCommonLabelKeys } from './app/common/moduleConstants';
import { checkDirtyData, saveUserSelQuickLinks } from './app/common/redux/actions/commonActions';
import { fetchServerError } from './app/common/components/fasatPage/fasatPage';

const btnLbl = {
  button: { bottom: 'Bottom Arrow',
    down: 'Down Arrow',
    left: 'Left Arrow',
    right: 'Right Arrow',
    top: 'Top Arrow',
    up: 'Up Arrow' },
};
const { serverErrorMsgContainer, notificationTime } = globalConstants;
let usrSelQuicksLinks = [];
const handleSaveClick = (dispatch) => dispatch(saveUserSelQuickLinks({ entityId: usrSelQuicksLinks }));
export const errorMessage = (t, serverErrors) => (
  <div className={serverErrorMsgContainer}>
    <FasatMessageCard titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)} variant="primary_alert">
      {serverErrors.map((errItem) => (fetchServerError(t, errItem)))}
    </FasatMessageCard>
  </div>
);
const setServerErr = ({ upsertRequestError, setServerErrors }) => {
  if (upsertRequestError) {
    setServerErrors(upsertRequestError);
  }
};
const handleCancelEvent = ({ cancelEventData, formDirty }) => {
  if (cancelEventData && !formDirty) {
    cancelEventData.close();
  }
};
const initPersonalize = ({ onSaveTopic, saveSubscriptionIds, setSaveEventData, dispatch, setSaveSubscriptionIds,
  onCancelTopic, setCancelEventData }) => {
  if (onSaveTopic && onSaveTopic !== '' && (saveSubscriptionIds || saveSubscriptionIds.length === 0)) {
    const subId = PubSub.subscribe(onSaveTopic, (msg, data) => {
      setSaveEventData(data);
      handleSaveClick(dispatch);
    });
    saveSubscriptionIds.push(subId);
    setSaveSubscriptionIds(saveSubscriptionIds);
  }
  if (onCancelTopic && onCancelTopic !== '') {
    const subId = PubSub.subscribe(onCancelTopic, (msg, data) => {
      setCancelEventData(data);
    });
    saveSubscriptionIds.push(subId);
    setSaveSubscriptionIds(saveSubscriptionIds);
  }
};

const FasatPersonilizePopup = ({ closeAddEditModal, onSaveTopic, onCancelTopic, subMenuRBAC }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [selQuickLinks, setSelQuickLinks] = useState([]);
  const [tempSelLinks, setTempSelLinks] = useState([]);
  const [saveEventData, setSaveEventData] = useState(null);
  const [formDirty, setFormDirty] = useState(false);
  const [cancelEventData, setCancelEventData] = useState(null);
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);
  const [serverErrors, setServerErrors] = useState([]);
  const [allLinks, setAllLinks] = useState([]);
  const avlLinks = useSelector(({ ApplicationState }) => ApplicationState.allQuicksLinks);
  const userLinks = useSelector(({ ApplicationState }) => ApplicationState.usersQuicksLinks);
  const upsertData = useSelector(({ ApplicationState }) => ApplicationState.upsertData);
  const upsertRequestError = useSelector(({ ApplicationState }) => ApplicationState.upsertRequestError);
  useEffect(() => {
    if (JSON.stringify(selQuickLinks) === JSON.stringify(userLinks)) {
      setFormDirty(false);
      dispatch(checkDirtyData(false));
    } else {
      setFormDirty(true);
      dispatch(checkDirtyData(true));
    }
  }, [selQuickLinks]);
  useEffect(() => {
    if (usrSelQuicksLinks.length === 0) {
      setFormDirty(false);
      dispatch(checkDirtyData(false));
    }
  }, [usrSelQuicksLinks]);
  useEffect(() => setSelQuickLinks(userLinks), [userLinks]);
  useEffect(() => {
    if (userLinks.length === 0) {
      const temporarySel = [];
      allLinks.forEach(({ value }) => temporarySel.push(value));
      setTempSelLinks(temporarySel);
    } else {
      setTempSelLinks([]);
    }
  }, [allLinks]);
  const clmOrdHnd = (selectedOption) => {
    setSelQuickLinks(selectedOption);
    usrSelQuicksLinks = selectedOption;
  };
  useEffect(() => {
    initPersonalize({ onSaveTopic,
      saveSubscriptionIds,
      setSaveEventData,
      dispatch,
      setSaveSubscriptionIds,
      onCancelTopic,
      setCancelEventData });
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);
  useEffect(() => {
    if (upsertData && saveEventData) {
      saveEventData.close();
      setFormDirty(false);
      dispatch(checkDirtyData(false));
      showFasatNotification(t(staticCommonLabelKeys.COMMON_LABEL_PQL_US_CONTENT), notificationTime);
    }
  }, [upsertData]);
  useEffect(() => setServerErr({ upsertRequestError, setServerErrors }), [upsertRequestError]);
  useEffect(() => handleCancelEvent({ cancelEventData, formDirty }), [cancelEventData, closeAddEditModal]);
  useEffect(() => {
    if ((subMenuRBAC && subMenuRBAC.length > 0) && avlLinks) {
      const filterMenuList = avlLinks.filter(({ menuId }) => subMenuRBAC.includes(menuId));
      setAllLinks(filterMenuList);
    }
  }, [avlLinks, subMenuRBAC]);
  return (
    <div className="personilize_popup">
      {serverErrors.length > 0 && errorMessage(t, serverErrors)}
      <FasatLabel isContent>{t(staticCommonLabelKeys.COMMON_LABEL_PQL_CONTENT)}</FasatLabel>
      <FasatColumnOrder
        labels={btnLbl}
        options={allLinks}
        selectedOptions={userLinks.length === 0 ? tempSelLinks : userLinks}
        columnOrderChange={clmOrdHnd}
        headerLabel="SCREENS"
      />
    </div>
  );
};
FasatPersonilizePopup.propTypes = {
  onSaveTopic: PropTypes.string.isRequired,
  onCancelTopic: PropTypes.string.isRequired,
  closeAddEditModal: PropTypes.func,
  subMenuRBAC: PropTypes.arrayOf(PropTypes.shape({})),
};

FasatPersonilizePopup.defaultProps = {
  closeAddEditModal: () => undefined,
  subMenuRBAC: [],
};
export default FasatPersonilizePopup;
