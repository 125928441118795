/**
 * @Description Fasat Filter Utility Page
 * @FileName filter.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 24 May, 2021 21:40:00
 * @IssueID - 436
 */

import { v4 as uuidv4 } from 'uuid';
import { COLUMN_TYPE } from '../../util';
import { staticCommonLabelKeys as cmnLblKey, staticCommonLabelKeys } from '../../moduleConstants';
import FasatModal from '../fasatModal/fasatModal';
import CancelPopup from '../../../modules/admin/components/cancelPopup/cancelPopup';
import CustomFilterList from './customFilter/customFilterList';
import FasatButton from '../fasatButton/fasatButton';

export const SHAREDFILTER = 'sharedFilter';
export const USERDEFAULT = 'userDefault';
export const SYSDEFAULT = 'sysDefault';
export const notificationTime = 100;
const modalWidth = '560px';
const modalWidthCustomFilter = '80%';
const custFilterToastContainerID = `cus-f-toaster-container${uuidv4()}`;
const cusFilListModalId = `cust-filt-list-${uuidv4()}`;
export const UPDATE = 'UPDATE';
export const CREATE = 'CREATE';
const fitCrtPnlIDheader = 'filterCriteriaPanelID-header';
const sortOrdPnlIDheader = 'sortOrderPanelID-header';
const clmOrdPnlTDheader = 'columnOrderPanelID-header';
const ariaExpanded = 'aria-expanded';
const stringFalse = 'false';
const filterExpandablePanelHeaderID = 'filterExpandablePanel-header';
const RDL_CNTRL = 'rdl-control';

/**
 * get Filter Instance
 * @param {sting} entityId
 * @param {sting} entityName
 * @returns
 */
const getFilterInstance = ({
  entityId,
  id,
  name,
  isDefault,
  owner,
  description,
  createdOn,
  updatedOn,
  isShared,
  usedOn,
  sharedWith,
  isSystemFilter,
  isCustomizedSysDefault = false,
}, parameters, sortOptions, columnOrders, groupByParameters, isDistinct) => ({
  id,
  owner,
  entityId,
  name,
  description,
  sharedWith,
  isCustomizedSysDefault,
  isDefault: isDefault || null,
  isShared: isShared || null,
  isSystemFilter: isSystemFilter || null,
  createdOn: createdOn || '',
  updatedOn: updatedOn || '',
  usedOn: usedOn || '',
  detail: {
    parameters,
    sortOptions,
    columnOrders,
    groupByParameters,
    isDistinct,
  },
});
const pageSize = 10;
const lengthSizeTwo = 2;

/**
 * generate filter request struvture
 * @param {string} pageSize
 * @param {object} filterDetails
 * @param {string} locale
 * @returns
 */

const configureReqParm = (item) => {
  const cloneParams = item;
  if (item.type === COLUMN_TYPE.DATE) {
    item.values.map((val, index) => {
      if (val !== null && val !== undefined && val !== '') {
        const splitDate = val.split('-');
        if (splitDate[0].length === lengthSizeTwo) {
          cloneParams.values[index] = `${splitDate[(1 + 1)]}-${splitDate[0]}-${splitDate[1]}`;
        }
        return cloneParams;
      }
      cloneParams.values[index] = '';
      return cloneParams;
    });
  }
  return cloneParams;
};
const getListRequest = ({ customPageSize, filterDetails, locale, exportType, listActive, customCurrentPage }) => {
  filterDetails.parameters.map((item) => configureReqParm(item));

  return {
    locale,
    active: listActive,
    export: exportType,
    page: customCurrentPage || 1,
    lookUpInformation: [],
    pageSize: customPageSize || pageSize,
    parameters: [...filterDetails.parameters],
    ...(filterDetails.groupByActive && { groupByParameters: [...filterDetails.groupByParameters] }),
    ...(!filterDetails.groupByActive && { isDistinct: filterDetails.isDistinct }),
    sortOptions: [...filterDetails.sortOptions],
    columnOrders: filterDetails.columnOrder,
    ...(filterDetails.isExtSrceen
      && { userId: filterDetails?.isExtSrceen?.userId, isQuick: filterDetails?.isExtSrceen?.isQuick }),
    isCurrentRate: filterDetails.isCurrentRate,
    isNonBandRate: filterDetails.isNonBandRate,
  };
};

/**
 * Format Filter Paraments Structure as per Structure
 * @param {array} customFilterCreteriaList
 * @returns
 */
const formatFilterCriteriaParams = (customFilterCreteriaList) => customFilterCreteriaList
  .map(({ key, operator, type, values }) => {
    const valueArray = typeof values === 'string' ? values.split(',') : values;
    return {
      key,
      operator,
      type,
      values: valueArray === null ? [] : valueArray,
    };
  });
const formatParamsSearch = (customFilterCreteriaList) => formatFilterCriteriaParams(customFilterCreteriaList)
  .filter((item) => {
    const oprChck1 = item.operator === 'OPT_IS_NULL' || item.operator === 'OPT_IS_NOT_NULL';
    const oprChck2 = item.operator === 'OPT_IS_EMPTY' || item.operator === 'OPT_IS_NOT_EMPTY';
    if (oprChck1 || oprChck2) {
      return item;
    }
    return item.values[0];
  });

/**
 * Format Filter Column order Structure as per Structure
 * @param {array} sortedColumnList
 * @returns
 */
const formatOrder = (sortedColumnList) => sortedColumnList.map((item, index) => ({
  key: item,
  order: index + 1,
}));

const generateOptions = ({ t, userDefaultFilter, sharedFilterCount }) => {
  const dropdownItems = [
    {
      label: t(cmnLblKey.FILTER_LABEL_SYSTEM_DEFAULT),
      value: SYSDEFAULT,
    },
  ];
  if (userDefaultFilter && sharedFilterCount) {
    dropdownItems.push({
      label: t(cmnLblKey.FILTER_LABEL_USER_DEFAULT),
      value: USERDEFAULT,
    });
  }
  if (sharedFilterCount) {
    dropdownItems.push({
      label: t(cmnLblKey.FILTER_LABEL_CUSTOM_FILTER),
      value: SHAREDFILTER,
    });
  }
  return dropdownItems;
};

export const setDropdownItem = ({ setCreateNewFilterFlag, setshowCustFilModal, showCustFilModal, setDeselectColumnOrder,
  val, userDefaultFilter, setFilterData, systemDefaultFilter }) => {
  setCreateNewFilterFlag(false);
  if (val === SHAREDFILTER) {
    setshowCustFilModal(!showCustFilModal);
  } else {
    setDeselectColumnOrder(true);
    setTimeout(() => {
      if (val === USERDEFAULT && userDefaultFilter) {
        setFilterData(userDefaultFilter, true);
      }
      if (val === SYSDEFAULT && systemDefaultFilter) {
        setFilterData(systemDefaultFilter, true);
      }
    }, notificationTime);
  }
};
const chkFilGrpIsDflt = (selectedFilter, systemDefaultFilter) => selectedFilter && selectedFilter.detail
 && selectedFilter.detail.groupByParameters && systemDefaultFilter;
// systemdefaultFilter added in condition 190 and 197
export const selectedFilIn = ({ setDeselectColumnOrder, setFilterData, selectedFilter,
  systemDefaultFilter, sharedFilterCount }) => {
  setDeselectColumnOrder(true);
  setTimeout(() => {
    if (selectedFilter && (sharedFilterCount || selectedFilter.isCustomizedSysDefault)) {
      setFilterData(selectedFilter, true);
    } else {
      if (selectedFilter && selectedFilter.detail && systemDefaultFilter) {
        const sysDefaultFilter = systemDefaultFilter;
        sysDefaultFilter.detail.isDistinct = selectedFilter.detail.isDistinct;
        setFilterData(sysDefaultFilter);
      }
      if (chkFilGrpIsDflt(selectedFilter, systemDefaultFilter)) {
        const sysDefaultFilter = systemDefaultFilter;
        sysDefaultFilter.detail.groupByParameters = selectedFilter.detail.groupByParameters;
        setFilterData(sysDefaultFilter);
      }
    }
  }, notificationTime);
};

export const getCancelModal = ({ canceldisplayModal, onCancelClickCancel, onClickDiscard, t }) => (
  <FasatModal
    display={canceldisplayModal}
    modalWidth="560px"
    primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_RETURNTOPAGE)}
    primaryTopic="ON_SAVE_STAY"
    secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_LEAVE)}
    secondaryTopic="ON_CANCEL_LEAVE"
    openModal="OPEN_ADD_GET_MODAL"
    modalCosed={() => onCancelClickCancel()}
    onc
    staydiscard
    pathname="systemParameters"
    state="systemParameters"
    onClickDiscard={() => onClickDiscard()}
    modalClass="modalWithoutHeading"
  >
    <div><CancelPopup onDiscardTopic="ON_SAVE_STAY" /></div>
  </FasatModal>
);

export const modalDublicateSelection = ({ t, displayDublicateSel, setdisplayDublicateSel, dublicateSelection }) => {
  const { 0: dubSelPart1, 1: dubSelPart2 } = dublicateSelection;
  return (
    <FasatModal
      title={t(cmnLblKey.FILTER_DUPLICATE_COL_SELECTED_TITLE)}
      display={displayDublicateSel}
      modalWidth={modalWidth}
      secondaryButtonText={t(cmnLblKey.COMMON_BUTTON_OK)}
      modalCosed={() => setdisplayDublicateSel(false)}
      staydiscard
    >
      <div className="dupSelectionMsg">
        <div>{`${dubSelPart1}.`}</div>
        <div>{`${dubSelPart2}.`}</div>
      </div>
    </FasatModal>
  );
};

export const getDuplicateModal = ({ t, setDisplayWarningMessage, displayWarningMessage }) => (
  <FasatModal
    title={t(cmnLblKey.FILTER_VISIBILITY_NO_COLS_SELECTED_TITLE)}
    display={displayWarningMessage}
    modalWidth={modalWidth}
    secondaryButtonText={t(cmnLblKey.COMMON_BUTTON_OK)}
    staydiscard
    modalCosed={() => setDisplayWarningMessage(false)}
  >
    <div className="dupSelectionMsg">
      <div>{t(cmnLblKey.FILTER_VISIBILITY_NO_COLS_SELECTED_MSG)}</div>
    </div>
  </FasatModal>
);

export const custFilList = ({ t, showCustFilModal, setshowCustFilModal,
  loadSelectedFilter, triggerFilterListCount, entityId }) => (
    <FasatModal
      title={t(cmnLblKey.FILTER_LABEL_CUSTOM_FILTERS)}
      display={showCustFilModal}
      modalWidth={modalWidthCustomFilter}
      primaryTopic="FILTER_LIST_ACTION"
      modalClass="customFilterListModal"
      modalCosed={() => setshowCustFilModal(false)}
      staydiscard
      showNotificationInModal
      toasterContainerId={custFilterToastContainerID}
      id={cusFilListModalId}
    >
      <CustomFilterList
        loadSelectedFilter={(filterData) => loadSelectedFilter(filterData)}
        onActionTopic="FILTER_LIST_ACTION"
        entityId={entityId}
        triggerFilterListCount={triggerFilterListCount}
        toasterContainerId={custFilterToastContainerID}
        cusFilListModalId={cusFilListModalId}
      />
    </FasatModal>
);

/**
  * Validate and generate Save or Save As Button
  * @returns HTML Element Block
*/
export const returnSaveButton = ({ t, currentFilterObject, loggedInUser, openSaveFilterModal }) => {
  const owner = currentFilterObject && currentFilterObject.owner;
  return (
    <>
      {(owner === loggedInUser) && (
      <FasatButton variant="secondary" id="saveButton" onClick={() => openSaveFilterModal(UPDATE)}>
        {t(cmnLblKey.COMMON_BUTTON_SAVE)}
      </FasatButton>
      )}
      <FasatButton variant="secondary" id="saveAsButton" onClick={() => openSaveFilterModal(CREATE)}>
        {t(cmnLblKey.COMMON_BUTTON_SAVE_AS)}
      </FasatButton>
    </>
  );
};

/**
       * format value accourding to the selected operator
       * @param {string} operator
       * @param {array} values
       * @returns
       */
export const paramValue = (operator, values) => {
  if (operator === 'OPT_BETWEEN' || operator === 'OPT_NOT_BETWEEN') {
    return values;
  }
  return values && values.join();
};

export const configureCustFilCrt = ({ selectedParametOptions, setCustFilCreteria }) => {
  if (selectedParametOptions) {
    const custSelParamOption = selectedParametOptions.map(({ key, operator, type, values }) => ({
      key,
      operator,
      type,
      values: paramValue(operator, values),
      uuid: uuidv4(),
    }));
    setCustFilCreteria(custSelParamOption);
  } else {
    setCustFilCreteria([]);
  }
};

/**
* Function to set the initial visibility of accordions
*/
export const initialAccordionState = () => {
  if (document.getElementById(fitCrtPnlIDheader)
  && document.getElementById(fitCrtPnlIDheader).getAttribute(ariaExpanded) === 'false') {
    document.getElementById(fitCrtPnlIDheader).click();
  }
  if (document.getElementById(sortOrdPnlIDheader)
  && document.getElementById(sortOrdPnlIDheader).getAttribute(ariaExpanded) === 'true') {
    document.getElementById(sortOrdPnlIDheader).click();
  }
  if (document.getElementById(clmOrdPnlTDheader)
  && document.getElementById(clmOrdPnlTDheader).getAttribute(ariaExpanded) === 'true') {
    document.getElementById(clmOrdPnlTDheader).click();
  }
};

export const validateIsSharedFlag = ({ action, currentFilterObject }) => {
  if (currentFilterObject && action === UPDATE) {
    return currentFilterObject.isShared
      ? JSON.stringify(currentFilterObject.isShared) : stringFalse;
  }
  return stringFalse;
};

export const animateFilterComponent = (gridData, activateDataGrid) => {
  if (activateDataGrid) {
    const isListData = gridData ? gridData.totalRows > 0 : false;
    if ((isListData && document.getElementById(filterExpandablePanelHeaderID)
      .getAttribute(ariaExpanded) === 'true') || (!isListData && document.getElementById(filterExpandablePanelHeaderID)
      .getAttribute(ariaExpanded) === 'false')) {
      document.getElementById(filterExpandablePanelHeaderID).click();
    }
  }
};

export const configSelColumn = ({ isColumnOrderChange, setSelectedColumn, systemDefaultFilter }) => {
  if (!isColumnOrderChange) {
    setSelectedColumn([...systemDefaultFilter.detail.columnOrders]);
  }
};

export const configureGroupByColumn = ({ value, customColumnGroupBy, setCustomColumnGroupBy }) => {
  if (value && value.key !== null) {
    const cloneCustomColumnGroupBy = customColumnGroupBy;
    const foundIndex = customColumnGroupBy.findIndex((x) => x.uuid === value.uuid);
    if (foundIndex >= 0) {
      cloneCustomColumnGroupBy[foundIndex] = value;
      setCustomColumnGroupBy([...cloneCustomColumnGroupBy]);
    } else {
      setCustomColumnGroupBy([...customColumnGroupBy, value]);
    }
  }
};

export const setFilterType = ({ filterData, setSelectedFilterType }) => {
  if (filterData.isSystemFilter) {
    setSelectedFilterType(SYSDEFAULT);
  } else {
    setSelectedFilterType(USERDEFAULT);
  }
};

export const configureSortColumn = ({ value, customColumnSorting, setCustomColumnSorting }) => {
  if (value && value.key !== null) {
    const cloneCustomColumnOrder = customColumnSorting;
    const foundIndex = customColumnSorting.findIndex((x) => x.uuid === value.uuid);
    if (foundIndex >= 0) {
      cloneCustomColumnOrder[foundIndex] = value;
      setCustomColumnSorting(cloneCustomColumnOrder);
    } else {
      setCustomColumnSorting([...customColumnSorting, value]);
    }
  }
};

export const scrollListBoxToTopPosition = () => {
  if (document.getElementsByClassName(RDL_CNTRL).length > 0) {
    document.getElementsByClassName(RDL_CNTRL)[0].scrollTo(0, 0);
  }
  if (document.getElementsByClassName(RDL_CNTRL).length > 0) {
    document.getElementsByClassName(RDL_CNTRL)[1].scrollTo(0, 0);
  }
};

export const grpByIsActive = (val, setGroupByActive, setCustomColumnGroupBy,
  setCustomColumnSorting, setIsColumnOrderChange) => {
  if (val === 'false') {
    setCustomColumnGroupBy([]);
  }
  setGroupByActive(val === 'true');
  setCustomColumnSorting([]);
  setIsColumnOrderChange(false);
};

export const validateNull = ({ operator, values }) => (operator !== 'OPT_IS_NOT_NULL' && operator !== 'OPT_IS_NULL')
 && (values === null || values === '');

export const bridgeFilterMethod = (filterData) => getFilterInstance(filterData,
  filterData.detail.parameters || null,
  filterData.detail.sortOptions || [],
  filterData.detail.columnOrders || null,
  filterData.detail.groupByParameters || [],
  filterData.detail.isDistinct || false);

export const stringBlankModal = ({ t, setDisplayBetWarningMsg, displayBetWarningMsg }) => (
  <FasatModal
    display={displayBetWarningMsg}
    modalWidth={modalWidth}
    secondaryButtonText={t(cmnLblKey.COMMON_BUTTON_OK)}
    staydiscard
    modalCosed={() => setDisplayBetWarningMsg(false)}
  >
    <div className="dupSelectionMsg">
      <div>{t(cmnLblKey.FILTER_VISIBILITY_BETWEEN_OPS_SELECTED_MSG)}</div>
    </div>
  </FasatModal>
);

const isEmpty = (value) => (value === null || value === '' || value === undefined);
const checkValueExist = (values) => (!values ? true : (!(values.length > 0)));
export const operatorBtwnValidate = (custFilCreteria) => custFilCreteria.filter(({ values, operator }) => {
  const hasValue = checkValueExist(values);
  if (hasValue) {
    return false;
  }
  if ((operator === 'OPT_NOT_BETWEEN' || operator === 'OPT_BETWEEN')) {
    const fromVal = values && isEmpty(values[0]);
    const toVal = values && isEmpty(values[1]);
    const bothEmpty = (fromVal && toVal);
    const eitherEmpty = (fromVal || toVal);
    if (!bothEmpty && eitherEmpty) {
      return true;
    }
  }
  return false;
});

export { getFilterInstance, getListRequest, formatFilterCriteriaParams, formatOrder, generateOptions,
  formatParamsSearch, configureReqParm };
