/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/**
 * @Description File description.
 * @FileName fasatElement.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 23 March, 2021 03:01:09
 * @IssueID - Issue IDs
 */

import { useEffect, useState } from 'react';
import './fasatFormElement.scss';

const getSummary = (errors) => {
  if (errors && errors.length > 0) {
    return errors[0].msg;
  }
  return '';
};

const FasatElement = (props) => {
  const { errors, direction, children, id } = props;

  const [active, setActive] = useState(false);
  const [summary, setSummary] = useState('');

  const hideTip = () => {
    setActive(false);
  };

  const getContent = (errs) => errs.map((i) => <div key={i.msg}>{i.msg}</div>);

  useEffect(() => {
    if (errors && errors.length > 0) {
      setSummary(getSummary(errors));
    } else {
      setSummary('');
    }
  }, [errors]);

  /**
   * Position and errors should be prop
   * 1. if there is errors show summary or return hide error
   */
  return (
    <div
      className="fasat-error-popout-wrapper"
      // When to show the tooltip
      id={id}
    >
      {/* Wrapping */}
      {children}

      {/* <div onClick={showTip} className="truncated summary" aria-hidden="true" role="button"> */}
      <div className="truncated summary" aria-hidden="true" role="button">
        {summary}
        {}
        {}
      </div>

      {active && (
        <div className={`fasat-error-popout ${direction || 'top'}`}>
          {/* Content */}
          <div className="hideError" onClick={hideTip} aria-hidden="true">
            X
          </div>
          {getContent(errors)}
        </div>
      )}
    </div>
  );
};

export default FasatElement;
