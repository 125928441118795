/**
 * @Description File Description
 * @FileName moduleConstants.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 27 May, 2021 09:08:06
 * @IssueID Issue Detail
 */
import moment from 'moment';
import staticCommonLabel from './staticCommonLabel';

export const staticCommonLabelKeys = staticCommonLabel;

export const globalConstants = {
  pageSizeTen: '10',
  pageSizeTenInt: 10,
  number2: 2,
  number3: 3,
  number4: 4,
  number5: 5,
  number6: 6,
  number7: 7,
  number9: 9,
  number10: 10,
  number64: 64,
  number91: 91,
  number99: 99,
  number105: 105,
  number189: 189,
  dateFormatMMDDYYYY: 'MM/DD/YYYY',
  dateFormatYYYYMMDD: 'YYYY-MM-DDTHH:mm:ss',
  dateFormatDDMMYYYHHMM: 'DD MMMM YYYY HH:mm',
  dateFormatmmddyyyy: 'mm-dd-yyyy',
  dateFormatDDMMYYYY: 'DD-MM-YYYY',
  dateFormatMMDDYYYYHiphen: 'MM-DD-YYYY',
  minAllowedDate: '01/01/1900',
  GEV_PARENT_VALUE: 'GEV_PARENT_VALUE',
  RBAC_MGA_EDIT: 'RBAC_MGA_EDIT',
  RBAC_MGA_APP_VIEW: 'RBAC_MGA_APP_VIEW',
  RBAC_MGA_APP_EDIT: 'RBAC_MGA_APP_EDIT',
  PRIVACY_POLICY: 'Privacy Policy',
  Accessibility: 'Accessibility',
  Legal: 'Legal',
  COMMON_SYSTEM_SESSION_EXPIRED: 'COMMON_SYSTEM_SESSION_EXPIRED',
  COMMON_SYSTEM_SIGNOUT_SUCCESS: 'COMMON_SYSTEM_SIGNOUT_SUCCESS',
  COMMON_SYSTEM_SIGNIN_REQUEST: 'COMMON_SYSTEM_SIGNIN_REQUEST',
  COMMON_LABEL_SESSION_EXPIRY: 'COMMON_LABEL_SESSION_EXPIRY',
  COMMON_LABEL_SESSION_EXPIRE_MSG: 'COMMON_LABEL_SESSION_EXPIRE_MSG',
  COMMON_BUTTON_STAY_SIGNIN: 'COMMON_BUTTON_STAY_SIGNIN',

  // Export limit
  COMMON_EXCEPTION_EXPORT_MAX_LIMIT: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
  errorCode402: 402,
  errorCode403: 403,
  errorCode404: 404,
  errorCode400: 400,
  // business card rule
  formRow: 'form-row',
  formElement: 'form-element',
  formControl: 'formControl',
  serverErrorMsgContainer: 'serverError',
  colSize2: 'col-sm-6',
  w320: 'w320',
  alterListPos: 'alterListPos',
  fixHeight: 'fixHeight',
  valignBottom: 'valignBottom',
  elementSeparator: 'elementSeparator',
  USENAME_SECONDARY_VALUE: '2',
  USENAME_CLIENT_VALUE: '1',
  NAME_ADVISOR_VALUE: '9',
  BC_LEVEL: 'BC_LEVEL',
  BC_CLINAME: 'BC_CLINAME',
  ID_LEVEL: 'ID_LEVEL',
  ORG_LEVEL: 'ORG_LEVEL',
  AGENT_STATUS: 'Agent_Status',
  numberFour: 4,
  columnSize: 'column',
  // column size
  colSize: 'col-sm-8',
  colSize1: 'col-sm-12',
  colSize3: 'col-sm-4',
  colSize4: 'col-sm-3',
  colSize5: 'col-sm-2',
  commonFE: 'commonFE',
  // plan code
  Language: 'Language',
  CHB_PAR: 'CHB_par',
  RATE_SCALE: 'RATE_SCALE',
  PRODUCT_CODE: 'Product_Code',
  PROD_GRP: 'PROD_GRP',
  SEGMENT: 'SEGMENT',
  plcPlanCdKey: 'plcPlanCd',
  plcPlanDescKey: 'plcPlanDesc',
  plcPlanKey: 'plan',
  plcFundKey: 'fund',
  plcBasicPlanKey: 'basicPlan',
  languageKey: 'language',
  parCodeKey: 'parCode',
  plcRateScaleKey: 'rateScale',
  productIdKey: 'productId',
  productKey: 'product',
  lobKey: 'lob',
  divisionKey: 'division',
  accountingUnitKey: 'accountingUnit',
  numberThree: 3,
  // genedit rel type
  genEditTypeOptionsTwo: 2,
  RelationshipType: 'codeWithDesc',
  FromType: 'fromGetWithDesc',
  ToType: 'toGetWithDesc',
  Actions: 'Actions',
  codeDescriptionField: 'codeDescription',
  // gen edit rel val
  toValueOptionsTwo: 2,
  numberTwo: 2,
  fromGevWithDesc: 'fromGevWithDesc',
  toGevWithDesc: 'toGevWithDesc',
  fromGevParentValueWithDesc: 'fromGevParentValueWithDesc',
  // gen edit value
  gevType: 'gevType',
  gevVal: 'gevVal',
  gevDesc: 'gevDesc',
  language: 'language',
  gevParentValue: 'gevParentValue',
  parentValueOptionsTwo: 2,
  // genedit type
  getType: 'getType',
  description: 'description',
  getCase: 'case',
  getParentType: 'getParentType',
  systemType: 'systemType',
  getMaxLength: 'getMaxLength',
  // paymentthreshold
  level: 'level',
  pthId: 'pthId',
  currency: 'currency',
  status: 'status',
  paymentMethod: 'paymentMethod',
  pthThreshold: 'pthThreshold',
  pthThresholdAmount: 'thresholdAmount',
  frequency: 'frequency',
  pthKey: 'pthKey',
  // systemparameters
  parmName: 'parmName',
  parmDesc: 'parmDesc',
  parmVal: 'parmVal',
  // Access Options
  sysSetting: 'sysSetting',
  sysDescription: 'sysDescription',
  Value: 'Value',
  // replace variables
  replace: /&amp;/g,
  replaceWith: '&',
  notificationTime: 3000,
  delayTime: 200,
  // Delete filter item
  DELETE: 'DELETE',
  // data Dictionary
  tableNameKey: 'tableName',
  fieldKey: 'field',
  dataTypeKey: 'dataType',
  lengthKey: 'length',
  precisionKey: 'precision',
  scaleKey: 'scale',
  identityKey: 'identity',
  sequenceKey: 'sequence',
  exampleKey: 'example',
  descriptionKey: 'description',
  genEditTypeKey: 'genEditType',
  genEditParentTypeKey: 'genEditParentType',
  sourceFieldNameKey: 'sourceFieldName',
  sourceSystemKey: 'sourceSystem',
  isObsoleteKey: 'isObsolete',
  // interface mapping
  cardCodeKey: 'cardCode',
  compDate: 'compDate',
  levelKey: 'level',
  idKey: 'itmOrgId',
  agentStatusKey: 'agentStatus',
  elementKey: 'itmElement',
  durationKey: 'itmDuration',
  maxAmountKey: 'itmAmount',
  itmMaxAmountLabelKey: 'maxAmount',
  transactionIdKey: 'transactionID',
  GETLINKS: 'getlinks',
  // Transaction Definition
  transactionDefinitionIdKey: 'transactionId',
  // compcal
  CHIER_HIERTYPE: 'CHIER_HIERTYPE',
  PROD_DIV: 'PROD_DIV',
  CHIER_NEXTAGT: 'CHIER_NEXTAGT',
  // Income Expense Map
  productIEM: 'product',
  lobIEM: 'lob',
  divisionIEM: 'division',
  commTypeIEM: 'commType',
  commSubTypeIEM: 'commSubType',
  categoryIEM: 'category',
  commLabelTypeIEM: 'commLabelType',
  descriptionIEM: 'description',
  tcmCommTypeIEM: 'tcmCommType',
  tcmCommSubtypeIEM: 'tcmCommSubtype',
  tcmDivisionIEM: 'tcmDivision',
  tcmLobIEM: 'tcmLob',
  tcmProductIEM: 'tcmProduct',
  tcmCategory: 'tcmCategory',
  tcmMapCommtypeIEM: 'tcmMapCommtype',
  tcmMapCommsubtype: 'tcmMapCommsubtype',
  tcmIdIEM: 'tcmId',
  // Period Definition
  periodId: 'periodId',
  startDate: 'startDate',
  finishDate: 'finishDate',
  ame: 'ame',
  aqe: 'aqe',
  aye: 'aye',

  // LRO
  levelLRO: 'level',
  idLRO: 'lroOrgCd',
  typeLRO: 'type',
  divisionLRO: 'division',
  lobLRO: 'lob',
  productLRO: 'product',
  plcdLRO: 'lroPlanCd',
  elementLRO: 'lroElement',
  causecodeLRO: 'lroCauseCd',
  rtypeLRO: 'rateType',
  rkeyLRO: 'lroRatekey',
  policyLRO: 'lroPolicy',
  // effdateLRO: 'lroEffDt',
  // enddateLRO: 'lroEndDt',
  lroEffDt: 'lroEffDt',
  lroEndDt: 'lroEndDt',
  lroLastModifyDt: 'lroLastModifyDt',
  // moddateLRO: 'rateType',
  moduserLRO: 'lroLastModifyUser',
  identityLRO: 'lroId',
  // Client address list
  type: 'type',
  badAddress: 'badAddress',
  address: 'address',
  claWorkPh: 'claWorkPh',
  claFaxNum: 'claFaxNum',
  claInternetId: 'claInternetId',
  claLstChgdDt: 'claLstChgdDt',
  claLstChgdId: 'claLstChgdId',
  lockEdit: 'edit',
  FASAT_DEFAULT_SIGNOUT_LIMIT: 'FASAT_DEFAULT_SIGNOUT_LIMIT',
  FASAT_DEFAULT_DATEFORMAT: 'FASAT_DEFAULT_DATEFORMAT',
  FASAT_INTERNAL_MAX_SIGNOUT_LIMIT: 'FASAT_INTERNAL_MAX_SIGNOUT_LIMIT',
  DOWNLOAD_CENTRE_REFRESH_INTERVAL_ONLINE: 'DOWNLOAD_CENTRE_REFRESH_INTERVAL_ONLINE',
  FPA_THRESHOLD_TIME_LIMIT: 'FPA_THRESHOLD_TIME_LIMIT',
  FASAT_EXTERNAL_DEFAULT_SIGNOUT_LIMIT: 'FASAT_EXTERNAL_DEFAULT_SIGNOUT_LIMIT',
  FASAT_EXTERNAL_MAX_SIGNOUT_LIMIT: 'FASAT_EXTERNAL_MAX_SIGNOUT_LIMIT',
  FPA_PP_THRESHOLD_TIME_LIMIT: 'FPA_PP_THRESHOLD_TIME_LIMIT',
  // payments
  COMMON_LABEL_SYSTEMDATE: 'COMMON_LABEL_SYSTEMDATE',
  WEB_BATCH_EXPIRY_DAYS: 'WEB_BATCH_EXPIRY_DAYS',
  // Cheque Security
  //
  FASAT_INTERNAL_CALLING_TIME: 'FASAT_INTERNAL_CALLING_TIME',
  chsUserid: 'userId',
  chsGenSystem: 'genSystem',
  chsDept: 'dept',
  chsChqIdentifier: 'identifier',
  chsAction: 'actionType',
  chsMaxAmount: 'amount',
  // Configure Letters
  clDesc: 'frmDesc',
  clLstUpdated: 'frmLstUpdated',
  clProgram: 'program',
  clDocName: 'frmDocName',
  clImmed: 'autoPrint',
  clType: 'type',
  clLanguage: 'language',
  // Daily Process
  stepId: 'stepId',
  stepName: 'stepName',
  step: 'step',
  execStartTime: 'execStartTime',
  execEndTime: 'execEndTime',
  errorMsg: 'errorMsg',
  recsProcessedCount: 'recsProcessedCount',
  recsSuccessCount: 'recsSuccessCount',
  recsFailedCount: 'recsFailedCount',
  // batchStatus: 'status',
  // dataStatus: 'status',
  processTypeName: 'processTypeName',
  activeProcStep: 'activeProcStep',
  processStartDt: 'processStartDt',
  procStartTime: 'procStartTime',
  procLastTime: 'procLastTime',
  procId: 'procId',
  fileName: 'fileName',
  fileFormat: 'fileFormat',
  sourceSystemName: 'sourceSystemName',
  fileReceived: 'fileReceived',
  startTime: 'startTime',
  endTime: 'endTime',
  // status: 'Not Recieved',
  totalRecords: 'totalRecords',
  success: 'success',
  failure: 'failure',
  fcrTransId: 'fcrTransId',
  action: 'action',
  DAILYPROCESS_BATCH_LIVE_UPDATE_INTERVAL_ONLINE: 'DAILYPROCESS_BATCH_LIVE_UPDATE_INTERVAL_ONLINE',
  EXEC_TYPE: 'Excep_Type',
  EXCE_APPROVER: 'Excep_Approver',
  EXEC_REASON: 'Excep_Reason',
  ORG_TYPE: 'Org_Type',
  pcrErrorMsgCount: 'pcrErrorMsgCount',
  pcrFailedCount: 'pcrFailedCount',
  pcrProcessedCount: 'pcrProcessedCount',
  pcrSuccessCount: 'pcrSuccessCount',

  // field force
  Payee: 'Payee',
  AgtType: 'AgtType',
  FF_Status: 'Status',
  wealth: 'wealth',
  Id: 'Id',
  // Activiy Processed

  advisorCode: 'advisorCode',
  originalAdvisorCode: 'originalAdvisorCode',
  originalAdvisorName: 'originalAdvisorName',
  policyHolderName: 'policyHolderName',
  manulifeSecuritiesCode: 'manulifeSecuritiesCode',
  manulifeSellingCode: 'manulifeSellingCode',
  businessFirstName: 'businessFirstName',
  businessSurname: 'businessSurname',
  branchCode: 'branchCode',
  generationDate: 'generationDate',
  batchNumber: 'batchNumber',
  commType: 'commType',
  commSubType: 'commSubType',
  adjustmentType: 'adjustmentType',
  amount: 'amount',
  policy: 'policy',
  firstName: 'firstName',
  lastName: 'lastName',
  fasatDivision: 'fasatDivision',
  fasatLob: 'fasatLob',
  fasatProduct: 'fasatProduct',
  basicPlanCode: 'basicPlanCode',
  covPlanCode: 'covPlanCode',
  fasatElement: 'fasatElement',
  sharePercent: 'sharePercent',
  policyCount: 'policyCount',
  commissionRate: 'commissionRate',
  inforceDate: 'inforceDate',
  duration: 'duration',
  issueCountry: 'issueCountry',
  issueLocation: 'issueLocation',
  currentCountry: 'currentCountry',
  currentLocation: 'currentLocation',
  generationId: 'generationId',
  generationSystem: 'generationSystem',
  generationTransaction: 'generationTransaction',
  compensationDate: 'compensationDate',
  // currency: 'currency',
  processedDate: 'processedDate',
  sequence: 'sequence',
  overrideCommAmt: 'overrideCommAmt',
  mdrtEligible: 'mdrtEligible',
  grossCommission: 'grossCommission',
  durationMonths: 'durationMonths',
  dueDate: 'dueDate',
  mode: 'mode',
  commPremium: 'commPremium',
  ovrdAdvisorPtr: 'ovrdAdvisorPtr',
  commissionablePremium: 'commissionablePremium',
  fromToAdvisor: 'fromToAdvisor',
  fromToAdvisorName: 'fromToAdvisorName',
  ovAdvisorPtr: 'ovAdvisorPtr',
  userDeptOrBranchId: 'userDeptOrBranchId',
  createDate: 'createDate',
  statisticalAcct: 'statisticalAcct',
  coverage: 'coverage',
  stmtBasisAmnt: 'stmtBasisAmnt',
  cardCode: 'cardCode',
  originalAdvisor: 'originalAdvisor',
  parCode: 'parCode',
  effectiveDate: 'effectiveDate',
  transferred: 'transferred',
  chequeRefNum: 'chequeRefNum',
  companyCode: 'companyCode',
  indIdFromOrToAdvisor: 'indIdFromOrToAdvisor',
  faceAmount: 'faceAmount',
  coverageStatus: 'coverageStatus',
  taxableEarning: 'taxableEarning',
  chargebackRate: 'chargebackRate',
  rateMasterId: 'rateMasterId',
  rateDetailId: 'rateDetailId',
  fund: 'fund',
  appReceivedDate: 'appReceivedDate',
  cumulativeDeposits: 'cumulativeDeposits',
  rateDuration: 'rateDuration',
  rateTerm: 'rateTerm',
  rateBasis: 'rateBasis',
  compHierarchy: 'compHierarchy',
  planRateScale: 'planRateScale',
  covPlanrateScale: 'covPlanrateScale',
  licenceValidated: 'licenceValidated',
  compStructureLookup: 'compStructureLookup',
  rateLookup: 'rateLookup',
  fundCompany: 'fundCompany',
  fundNumber: 'fundNumber',
  splitRate: 'splitRate',
  originalCurrency: 'originalCurrency',
  originalAmount: 'originalAmount',
  subAccount: 'subAccount',
  premiumNumber: 'premiumNumber',
  salesCredits: 'salesCredits',
  variableCode: 'variableCode',
  clientNumber: 'clientNumber',
  remark: 'remark',
  jointIndicator: 'jointIndicator',
  basisScs: 'basisScs',
  sweepRate: 'sweepRate',
  sweepCalcSalesCredits: 'sweepCalcSalesCredits',
  sweepBonusPaid: 'sweepBonusPaid',
  sweepBonusEarned: 'sweepBonusEarned',
  expSeqNumber: 'expSeqNumber',
  tradeNumber: 'tradeNumber',
  tradeAmount: 'tradeAmount',
  securityNumber: 'securityNumber',
  pendingAccountFlag: 'pendingAccountFlag',
  assignmentIndicator: 'assignmentIndicator',
  assignmentDone: 'assignmentDone',
  affiliate: 'affiliate',
  corporation: 'corporation',
  tempLevel7: 'tempLevel7',
  location: 'location',
  alternateOffice: 'alternateOffice',
  distributor: 'distributor',
  region: 'region',
  channel: 'channel',
  company: 'company',
  commTypeLabel: 'commTypeLabel',
  commSubTypeLabel: 'commSubTypeLabel',
  fasatDivisionLabel: 'fasatDivisionLabel',
  fasatLobLabel: 'fasatLobLabel',
  fasatProductLabel: 'fasatProductLabel',
  fasatElementLabel: 'fasatElementLabel',
  issueCountryLabel: 'issueCountryLabel',
  issueLocationLabel: 'issueLocationLabel',
  currentCountryLabel: 'currentCountryLabel',
  currentCountryLocLabel: 'currentCountryLocLabel',
  generationSystemLabel: 'generationSystemLabel',
  generationTransactionLabel: 'generationTransactionLabel',
  currencyLabel: 'currencyLabel',
  mdrtFlagLabel: 'mdrtFlagLabel',
  modeLabel: 'modeLabel',
  statisticalAccountLabel: 'statisticalAccountLabel',
  parCodeLabel: 'parCodeLabel',
  transferredLabel: 'transferredLabel',
  companyCodeLabel: 'companyCodeLabel',
  coverageStatusLabel: 'coverageStatusLabel',
  taxableEarningLabel: 'taxableEarningLabel',
  rateTermLabel: 'rateTermLabel',
  rateBasisLabel: 'rateBasisLabel',
  compHeirarchyLabel: 'compHeirarchyLabel',
  rateScaleLabel: 'rateScaleLabel',
  coverageRateScaleLabel: 'coverageRateScaleLabel',
  licenceValidatedLabel: 'licenceValidatedLabel',
  compStructureLookupLabel: 'compStructureLookupLabel',
  rateLookupLabel: 'rateLookupLabel',
  originalCurrencyLabel: 'originalCurrencyLabel',
  jointIndicatorLabel: 'jointIndicatorLabel',
  pendingAccountFlagLabel: 'pendingAccountFlagLabel',
  assignmentIndicatorLabel: 'assignmentIndicatorLabel',
  assignmentDoneLabel: 'assignmentDoneLabel',
  adjustmentTypeLabel: 'adjustmentTypeLabel',
  basicPlanCodeLabel: 'basicPlanCodeLabel',
  covPlanCodeLabel: 'covPlanCodeLabel',
  // sumRecordCount: 'sumRecordCount',
  // activity Unprocessed
  jobRequestId: 'jobRequestId',
  gainOrLossAccount: 'gainOrLossAccount',
  covPlanRateScale: 'covPlanRateScale',
  salesCredit: 'salesCredit',
  apprReceivedDate: 'apprReceivedDate',
  ovrdCommAmount: 'ovrdCommAmount',
  cumulativeDeposit: 'cumulativeDeposit',
  recordStatus: 'recordStatus',
  processDate: 'processDate',
  errorFlag: 'errorFlag',
  share: 'share',
  grossCommision: 'grossCommision',
  rate: 'rate',
  commmisonablePremium: 'commmisonablePremium',
  currentLocationLabel: 'currentLocationLabel',
  stmtBasisAmount: 'stmtBasisAmount',
  cardCodeLabel: 'cardCodeLabel',
  compDt: 'compDt',
  durationPaid: 'durationPaid',
  cumalativeDeposit: 'cumalativeDeposit',
  compHierarchyLabel: 'compHierarchyLabel',
  pendingAccount: 'pendingAccount',
  basicScs: 'basicScs',
  tardeAmount: 'tardeAmount',
  tempLevel: 'tempLevel',
  aacInputPk: 'aacInputPk',

  // Data Transfer
  file: 'file',
  active: 'active',
  order: 'ifsOrder',

  // External or Internal user
  external: 'external',
  internal: 'internal',

  // Job Status screen

  jobId: 'jobId',
  recordCount: 'recordCount',
  processName: 'processName',
  requestedBy: 'requestedBy',
  requestedOn: 'requestedOn',
  updatedTime: 'updatedTime',
  batchNum: 'batchNum',
  reason: 'reason',
};

export const pageSizeOptions = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '500',
    value: '500',
  },
];

export const pageSizeAP = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '75',
    value: '75',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '150',
    value: '150',
  },
  {
    label: '200',
    value: '200',
  },
];

export const userLock = {
  entityKey: '',
  recordKey: '',
  source: '',
  lockId: 0,
  lockedBy: '',
  lockStartTime: moment().format(),
  sessionId: '',
  action: '',
};

export const exportFileType = {
  Excel: 0,
  CSV: 1,
};

export const exportPageSizeType = {
  All: 'ALL',
  Current: 'CRT',
};
