/* eslint-disable import/prefer-default-export */
/**
 * @Description Action types for
 * @FileName actionTypes.js
 * @Author pgoutam
 * @CreatedOn 03 Jan 23
 * @IssueID 220
 */

export const acInquiryActionTypes = {
  LOG_ACINQ_LIST_REQUEST: 'LOG_ACINQ_LIST_REQUEST',
  GET_ACINQ_LIST_REQUEST: 'GET_ACINQ_LIST_REQUEST',
  GET_ACINQ_LIST_RESPONSE: 'GET_ACINQ_LIST_RESPONSE',
  GET_ACINQ_LIST_ERROR: 'GET_ACINQ_LIST_ERROR',
  UPDATE_ACINQ_COLUMN_CONFIG: 'UPDATE_ACINQ_COLUMN_CONFIG',
  UPDATE_ACINQ_FILTER_CONFIG: 'UPDATE_ACINQ_FILTER_CONFIG',
  UPDATE_ACINQ_TABLE_CONFIGURATION: 'UPDATE_ACINQ_TABLE_CONFIGURATION',
  SET_ACINQ_DETAILS_RESPONSE: 'SET_ACINQ_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_ACINQ_USER_DEFAULT_FILTER: 'REMOVE_ACINQ_USER_DEFAULT_FILTER',
  // Reset store
  RESET_ACINQ_STORE: 'RESET_ACINQ_STORE',
  // Export List for BCR
  GET_ACINQ_EXPORT_REQUEST: 'GET_ACINQ_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
