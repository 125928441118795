/**
 * @Description Fasat Filet Operator
 * @FileName fasatOperatorInput.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 24 June, 2021 21:40:00
 * @IssueID - 1050
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { staticCommonLabelKeys } from '../../../moduleConstants';
import FasatDropdown from '../../fasatDropdown/fasatDropdown';
import { getCurrentLocale } from '../../../i18n';
import { COLUMN_TYPE } from '../../../util';

const number = 'number';
const text = 'text';
const OPT_EQUALS = 'OPT_EQUALS';

const FasatOperatorInput = ({ columnValue,
  operatorList,
  onOperatorChange,
  customFilterCreteriaElement,
  preSelectedIndex,
  inputConfiguration,
  uuid,
}) => {
  const [t] = useTranslation();
  const locale = getCurrentLocale();
  const [operatorValue, setOperatorValue] = useState(null);
  const [filterOperatorList, setFilterOpertorList] = useState([]);
  const dropdownPlaceholder = t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT);
  const fcOperatorID = 'fcOperator';

  /**
   * generate Operator List as per Column Value Selection and Set Opertor value to OPT_EQUALS
   */
  useEffect(() => {
    setOperatorValue(null);
    if (columnValue && operatorList) {
      let list = [];
      const formatOption = (operatorItem) => ({ ...operatorItem,
        label: t(operatorItem.operator),
        value: operatorItem.operator });
      if (columnValue.type === text) {
        list = operatorList.filter(({ forText }) => forText === true)
          .map((operatorItem) => (formatOption(operatorItem)));
      }
      if (columnValue.type === number) {
        list = operatorList.filter(({ forNumber }) => forNumber === true)
          .map((operatorItem) => (formatOption(operatorItem)));
      }
      if (columnValue.type === COLUMN_TYPE.DATE) {
        list = operatorList.filter(({ forDatetime }) => forDatetime === true)
          .map((operatorItem) => (formatOption(operatorItem)));
      }
      if (columnValue.type === COLUMN_TYPE.BOOLEAN) {
        list = operatorList.filter(({ forBoolean }) => forBoolean === true)
          .map((operatorItem) => (formatOption(operatorItem)));
      }
      setFilterOpertorList(list);
      setOperatorValue(OPT_EQUALS);
      onOperatorChange(OPT_EQUALS);
    } else {
      setFilterOpertorList([]);
    }
  }, [columnValue, locale]);

  /**
   * Set operatot Value while auto population of operator
   */
  useEffect(() => {
    if (inputConfiguration.autoPopulate && preSelectedIndex > -1
      && customFilterCreteriaElement[preSelectedIndex].operator) {
      setOperatorValue(customFilterCreteriaElement[preSelectedIndex].operator);
      onOperatorChange(customFilterCreteriaElement[preSelectedIndex].operator, false);
    }
  }, [filterOperatorList]);

  /**
   * Set oerator Value as per selection and trigger event onOperatorChange
   * @param {string} item
   */
  const handleOperatorChange = (item) => {
    if (item) {
      setOperatorValue(item);
      onOperatorChange(item, true);
    }
  };

  return (
    <>
      <FasatDropdown
        dropdownItems={filterOperatorList}
        selected={0}
        id={`${fcOperatorID}-${uuid}`}
        onHandleChange={(val) => handleOperatorChange(val)}
        value={operatorValue}
        placeholder={dropdownPlaceholder}
        ariaLabel={t(staticCommonLabelKeys.FILTER_LABEL_OPERATOR)}
      />
    </>
  );
};
// #region component
FasatOperatorInput.propTypes = {
  operatorList: PropTypes.arrayOf(PropTypes.shape()),
  columnValue: PropTypes.shape({
    value: PropTypes.string,
    type: PropTypes.string,
    lookupKey: PropTypes.string,
  }),
  onOperatorChange: PropTypes.func,
  preSelectedIndex: PropTypes.number,
  inputConfiguration: PropTypes.shape({
    isInputComponent: PropTypes.bool,
    isMultiselect: PropTypes.bool,
    isBetween: PropTypes.bool,
    isDatePickerComponent: PropTypes.bool,
    isDropdownComponent: PropTypes.bool,
    autoPopulate: PropTypes.bool,
  }),
  customFilterCreteriaElement: PropTypes.arrayOf(PropTypes.shape()),
  uuid: PropTypes.number,
};

FasatOperatorInput.defaultProps = {
  operatorList: [],
  columnValue: null,
  onOperatorChange: () => undefined,
  preSelectedIndex: -1,
  inputConfiguration: {
    isInputComponent: true,
    isMultiselect: false,
    isBetween: false,
    isDatePickerComponent: false,
    isDropdownComponent: false,
    autoPopulate: false,
  },
  customFilterCreteriaElement: null,
  uuid: null,
};
export default FasatOperatorInput;
