import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { systemParametersActionType } from '../actions/actionTypes';
import { updateFilterConfig, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'SystemParametersFilterList';

const logSysParamListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const SystemParametersFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case systemParametersActionType.UPDATE_SYSPARAM_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case systemParametersActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case systemParametersActionType.REMOVE_SYSPARAM_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    default:
      return null;
  }
};

const SystemParametersFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case systemParametersActionType.LOG_SYSPARAM_LIST_REQUEST:
      return logSysParamListReq(state, action);
    // log error
    case systemParametersActionType.GET_SYSPARAM_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case systemParametersActionType.GET_SYSPARAM_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case systemParametersActionType.GET_SYSPARAM_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case systemParametersActionType.RESET_SYSPARAM_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case systemParametersActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const SystemParametersFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = SystemParametersFilterListConfig(state, action);
  const stateReqRes = SystemParametersFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default SystemParametersFilterList;
