import { getAction } from '../../../../../stateManagement/reduxUtil';
import { FasatButton, FasatLabel, FasatMessageCard,
  FasatModal, showFasatNotification } from '../../../../common/components';
import { fetchServerError } from '../../../../common/components/fasatPage/fasatPage';
import { globalConstants, staticCommonLabelKeys } from '../../../../common/moduleConstants';
import { checkDirtyData } from '../../../../common/redux/actions/commonActions';
import { dateFieldValidator } from '../../../../common/util';
import CancelPopup from '../../../admin/components/cancelPopup/cancelPopup';
import { staticLabelKeys } from '../../moduleConstants';
import { fieldForceActionTypes } from '../../redux';

const { serverErrorMsgContainer, notificationTime, formElement, formRow, commonFE } = globalConstants;

const getReqPayLoad = (locale) => ([
  {
    type: 'Currency',
    depth: 0,
    value: '',
    locale,
  },
  {
    type: 'YES',
    depth: 0,
    value: '',
    locale,
  },
  {
    type: 'ADE_Frequency',
    depth: 0,
    value: '',
    locale,
  },
  {
    type: 'ADE_CalcMethod',
    depth: 0,
    value: '',
    locale,
  },
  {
    type: 'Transactions',
    depth: 0,
    value: '',
    locale,
  },
]);
const pathName = '/agency/fieldForceDeduction';

const dirtyCheckCancel = (isDirty, setCancelDisplayModal, history, location) => {
  if (isDirty) {
    setCancelDisplayModal(true);
  } else {
    history.push({
      pathname: pathName,
      state: {
        orgCode: location.state.orgCode,
        orgLevel: location.state.orgLevel,
        addEditDeduction: 'true',
      },
    });
  }
};
const getTranslatedLabels = (t) => {
  const successAddMsg = t(staticLabelKeys.DE_ADD_SUCCESS);
  const successEditMsg = t(staticLabelKeys.DE_EDIT_SUCCESS);
  const dropdownPlaceholder = t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT);
  return { successAddMsg, successEditMsg, dropdownPlaceholder };
};
const footerFunc = (t, onClickCancel, isDirty) => (
  <div className="addCompButtonSection">
    <FasatButton variant="secondary" type="button" onClick={() => onClickCancel()} id="compCancelButton">
      {t(staticCommonLabelKeys.COMMON_BUTTON_CANCEL)}
    </FasatButton>
    <FasatButton type="submit" disabled={!isDirty} id="compSaveButton">
      {t(staticCommonLabelKeys.COMMON_BUTTON_SAVE)}
    </FasatButton>
  </div>
);
const onEndDateCheck = (data) => {
  if (dateFieldValidator(data.adeEndDt) && data.adeEndDt !== null && data.adeEndDt.length > 0 && data.adeEndDt !== undefined) {
    return true;
  }
  return false;
};
const onDateChange = (dispatch, formik) => {
  if (onEndDateCheck(formik.values)) {
    const [mon, date, years] = formik.values.adeEndDt?.split('/');
    const resultenddate1 = [years, mon, date]?.join('-');
    dispatch(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_ACTIVE, resultenddate1));
  }
};
const transactionFormik = (transactionData, setPopup, formik) => {
  if (transactionData) {
    formik.setFieldValue('comType', transactionData.data.result.commtype);
    formik.setFieldValue('comSubType', transactionData.data.result.comSubType);
    formik.setFieldValue('type', transactionData.data.result.sTransType);
    formik.setFieldValue('adePriority', transactionData.data.result.priority > 0
    && transactionData.data.result.priority);
    formik.setFieldValue('flagTransfer', transactionData.data.enabledTransAgntCd);
    formik.setFieldValue('scharge', transactionData.data.result.scharge);
    formik.setFieldValue('transferAgntReq', transactionData.data.transferAgntReq);
    if (transactionData.data.result.isS015Error === true) {
      setPopup(true);
    }
  } else {
    formik.setFieldValue('comType', '');
    formik.setFieldValue('comSubType', '');
    formik.setFieldValue('type', '');
    formik.setFieldValue('adePriority', null);
    formik.setFieldValue('flagTransfer', false);
    formik.setFieldValue('scharge', null);
  }
};
const showServerMessage = (serverErrors, t) => {
  if (serverErrors && serverErrors.length > 0) {
    window.scrollTo(0, 0);
    return (
      <div className={`${serverErrorMsgContainer} tabServerError`}>
        <FasatMessageCard
          hasCloseButton
          titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
          variant="primary_alert"
        >
          {serverErrors.map((errItem) => fetchServerError(t, errItem))}
        </FasatMessageCard>
      </div>
    );
  }
  return null;
};
let flagError = [];
function dispatchUpsert({ upsertData,
  dispatch, addNewBCR, successAddMsg, successEditMsg, setServerErrors,
  upsertRequestError, setClientAdddEditActBtn, setContWithError, contWithError, setMsg, formik, history, location }) {
  if (upsertData) {
    setClientAdddEditActBtn(false);
    history.push({
      pathname: pathName,
      state: { orgCode: location.state.orgCode,
        orgLevel: location.state.orgLevel },
      addEditDeduction: 'true',
    });
  }

  if (upsertData) {
    dispatch(checkDirtyData(false));
    const successMsg = !addNewBCR ? successAddMsg : successEditMsg;
    showFasatNotification(successMsg, notificationTime);
  }
  if (upsertRequestError) {
    formik.setFieldValue('bflag', 0);
    const errorCollect = upsertRequestError.filter((item) => item.errorCode !== 'DE_ADD_FER016');
    flagError = upsertRequestError.filter((item) => item.errorCode === 'DE_ADD_FER016');
    if (flagError.length > 0 && !contWithError && errorCollect.length === 0) {
      setContWithError(true);
      setMsg(flagError);
    }
    setServerErrors(errorCollect);
  }
}
const tranSetCalcMethod = (dispatch, formik) => {
  if (formik.values.adeAmtCalcMethod === 'F') {
    formik.setFieldValue('flagTransfer', false);
  }
  if (formik.values.adeAmtCalcMethod !== 'F') {
    const reqnewObj = {
      transaction: formik.values.adeType,
      transactionMode: 'INSERT',
      priority: 0,
    };
    dispatch(getAction(fieldForceActionTypes.GET_FF_TRAN_DETAILS, reqnewObj));
  }
};
const adeCalcFunc = (data, dirty) => {
  if (dirty && data?.adeAmtCalcMethod !== '' && data?.adeType !== '') {
    return true;
  }
  return false;
};
const tranDetailsSet = (dispatch, formik) => {
  if (adeCalcFunc(formik.values, formik.dirty)) {
    if (formik.values.adeAmtCalcMethod !== 'M' || formik.values.adeAmtCalcMethod !== 'R') {
      formik.setFieldValue('adePercent', null);
    }
    if (formik.values.adeAmtCalcMethod !== 'P' || formik.values.adeAmtCalcMethod !== 'N') {
      formik.setFieldValue('adePctOfRenOvrde', null);
      formik.setFieldValue('adePctOfFyrComm', null);
      formik.setFieldValue('adePctOfRenComm', null);
      formik.setFieldValue('adePctOfFyrOvrde', null);
      formik.setFieldValue('adePctOfBal', null);
      formik.setFieldValue('adeAmt', null);
      formik.setFieldValue('adePercent', null);
    }
    tranSetCalcMethod(dispatch, formik);
  }
};
const getSuccessMsg = ({ upsertData, saveEventData, setSaveEventData, resetAddEdit, t, dispatch, formik }) => {
  if (upsertData && saveEventData) {
    setSaveEventData(false);
    formik.setSubmitting(false);
    const successEditMsg = t(staticLabelKeys.DE_ADD_SUCCESS);
    showFasatNotification(successEditMsg, notificationTime);
    resetAddEdit();
  }
  dispatch(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_BY_ID_RESPONSE, null));
};
const pathChange = (flag, history, location) => {
  if (flag === true) {
    history.push({
      pathname: pathName,
      state: {
        formData: location.state?.formData,
        orgCode: location.state?.orgCode,
        orgLevel: location.state.orgLevel,
        addEditDeduction: 'true',
      },
    });
  }
};
const addRowAuto = (t, formik) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <FasatLabel>{t(staticCommonLabelKeys.COMMON_LABEL_COMMTYPE)}</FasatLabel>
      <FasatLabel isContent>{formik.values.comType}</FasatLabel>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatLabel>{t(staticCommonLabelKeys.COMMON_LABEL_COMMSUBTYPE)}</FasatLabel>
      <FasatLabel isContent>{formik.values.comSubType}</FasatLabel>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatLabel>{t(staticCommonLabelKeys.COMMON_LABEL_TYPE)}</FasatLabel>
      <FasatLabel isContent>{formik.values.type}</FasatLabel>
    </div>
  </div>
);
const onCancelClickCancel = (setCancelDisplayModal, canceldisplayModal) => {
  setCancelDisplayModal(!canceldisplayModal);
};

const onClickDiscard = (dispatch, setClientAdddEditActBtn, resetAddEdit, setFlag, formik) => {
  setFlag(true);
  dispatch(checkDirtyData(false));
  formik.resetForm();
  setClientAdddEditActBtn(false);
  resetAddEdit();
};

const clientAddCancelCR = ({ canceldisplayModal, setCancelDisplayModal, t, dispatch,
  setClientAdddEditActBtn, resetAddEdit, setFlag, formik }) => (
    <FasatModal
      display={canceldisplayModal}
      modalWidth="560px"
      primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_RETURNTOPAGE)}
      primaryTopic="ON_SAVE_STAY"
      secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_LEAVE)}
      secondaryTopic="ON_CANCEL_LEAVE"
      openModal="OPEN_ADD_GET_MODAL"
      modalCosed={() => onCancelClickCancel(setCancelDisplayModal, canceldisplayModal)}
      staydiscard
      onClickDiscard={() => onClickDiscard(dispatch, setClientAdddEditActBtn, resetAddEdit, setFlag, formik)}
      modalClass="cancelPopup modalWithoutHeading"
    >
      <div><CancelPopup onDiscardTopic="ON_SAVE_STAY" /></div>
    </FasatModal>
);
const numberFour = 4;
const formatDecimal = (number) => {
  if (!number) {
    return number;
  }
  // Convert number to string
  const numberStr = number.toString();
  // Split the number into integer and decimal parts
  const parts = numberStr.split('.');
  // If there are no decimal places, add '.0000' to the end
  if (parts.length === 1) {
    return `${numberStr}.0000`;
  }
  // If there are less than 4 decimal places, pad zeros
  if (parts[1].length < numberFour) {
    parts[1] = parts[1].padEnd(numberFour, '0');
  } else {
    // If there are more than 4 decimal places, truncate
    parts[1] = parts[1].substring(0, numberFour);
  }
  // Join the parts back together and return
  return parts.join('.');
};

export { dirtyCheckCancel, getReqPayLoad, getTranslatedLabels, footerFunc, onDateChange,
  transactionFormik, showServerMessage, dispatchUpsert, tranSetCalcMethod, tranDetailsSet,
  getSuccessMsg, pathChange, addRowAuto, clientAddCancelCR, adeCalcFunc, onEndDateCheck, formatDecimal };
