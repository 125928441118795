import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { dataTransferActionType } from '../actions/actionTypes';

const DataTransferFilterListConfig = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case dataTransferActionType.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case dataTransferActionType.SET_DT_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case dataTransferActionType.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case dataTransferActionType.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case dataTransferActionType.GET_DT_HEADER_DETAIL_RESPONSE:
      return {
        ...state,
        headerData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};
const getServerReqErr = (action) => (action.data && action.data.serverRequestError);

const DataTransferFilterListREQRES = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case dataTransferActionType.GET_DT_DETAIL_RESPONSE:
      return {
        ...state,
        detailData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case dataTransferActionType.RESET_DT_TAB_DETAIL_VALUE:
      return {
        ...state,
        detailData: null,
        selectedTab: action.data.tab,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case dataTransferActionType.RESET_DT_SUB_TAB_DETAIL_VALUE:
      return {
        ...state,
        detailData: null,
        selectedSubTab: action.data.tab,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case dataTransferActionType.LOG_DT_DETAIL_REQUEST:
      return {
        ...state,
        detailRequestedOn: new Date(),
        loading: true,
      };
    case dataTransferActionType.GET_DT_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case dataTransferActionType.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: getServerReqErr(action),
      };
    case dataTransferActionType.SET_DT_SEQUENCE_ERROR:
      return {
        ...state,
        sequenceRequestError: action.data && action.data.serverRequestError,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

const DataTransfer = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  const stateConfig = DataTransferFilterListConfig(state, action);
  const stateReqRes = DataTransferFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default DataTransfer;
