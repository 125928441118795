/**
 * @Description Local Storage Service
 * @FileName localStorageService.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 21 January, 2021 08:29:15
 * @IssueID - 310
 */

/**
 * Commonly used keys for local-storage
 */
export const STORAGE_KEY = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  AUTHENTICATION_TOKEN: 'auth_token',
  USER_TYPE: 'user_type',
  SESSION_ID: 'session_id',
  LOGOUT_TYPE: 'logout_type',
  DATE_FORMAT: 'dateFormat',
  DEFAULT_LOGOUT_TIME: 'defaultTimeOut',
  DAILYPROCESS_BATCH_LIVE_UPDATE_INTERVAL_ONLINE: 'dailyProcessBatchInterval',
  WEB_BATCH_EXPIRY_DAYS: 'batchExpiryDays',
  DOWNLOAD_CENTRE_REFRESH_INTERVAL_ONLINE: 'downloadCenterRefreshInterval',
  FASAT_EXTERNAL_DEFAULT_SIGNOUT_LIMIT: 'FASAT_EXTERNAL_DEFAULT_SIGNOUT_LIMIT',
  FASAT_EXTERNAL_MAX_SIGNOUT_LIMIT: 'FASAT_EXTERNAL_MAX_SIGNOUT_LIMIT',
  FPA_PP_THRESHOLD_TIME_LIMIT: 'fpaPPThresholdTimeLimit',
  FASAT_INTERNAL_MAX_SIGNOUT_LIMIT: 'FASAT_INTERNAL_MAX_SIGNOUT_LIMIT',
  FASAT_INTERNAL_CALLING_TIME: 'FASAT_INTERNAL_CALLING_TIME',
};

/**
 * Store the value for a given key
 * @param {string} key - key against which value to be stored
 * @param {string} strValue - string value which needs to be stored
 */
export const addToLocalStorage = (key, strValue) => {
  localStorage.setItem(key, strValue);
  return true;
};

/**
 * Returns the value stored against the key in the local storage
 * @param {string} key
 */
export const getValueFromLocalStorage = (key) => localStorage.getItem(key);

/**
 * Remove values from local-storage
 * @param {sting} key
 */
export const clearValueFromLocalStorage = (key) => localStorage.removeItem(key);

/**
 * Get the access token from the local-storage
 */
export const getAccessToken = () => getValueFromLocalStorage(STORAGE_KEY.ACCESS_TOKEN);
/**
 * Get the access token from the local-storage
 */
export const getRefreshToken = () => getValueFromLocalStorage(STORAGE_KEY.REFRESH_TOKEN);
/**
 * Get session Id from the local-storage
 */
export const getSessionId = () => getValueFromLocalStorage(STORAGE_KEY.SESSION_ID);

/**
 * Method to store access token information in local-storage
 * @param {string} accessToken this is the authentication token either from AAD or CIAM
 * @param {string} refreshToken this is the refresh token from AAD or CIAM
 */
export const setToken = (accessToken, refreshToken) => {
  addToLocalStorage(STORAGE_KEY.ACCESS_TOKEN, accessToken);
  addToLocalStorage(STORAGE_KEY.REFRESH_TOKEN, refreshToken);
  return true;
};
/**
 * Method to store access token information in local-storage
 * @param {sessionid} sessionid this is the authentication token either from AAD or CIAM
 * */
export const setUserSession = (sessionid) => {
  addToLocalStorage(STORAGE_KEY.SESSION_ID, sessionid);
  return true;
};

/**
 * Clear access token information from local-storage
 */
export const clearToken = () => {
  clearValueFromLocalStorage(STORAGE_KEY.ACCESS_TOKEN);
  clearValueFromLocalStorage(STORAGE_KEY.REFRESH_TOKEN);
  clearValueFromLocalStorage(STORAGE_KEY.LOGOUT_TYPE);
};

/**
 * Clear access token information from local-storage
 */
export const clearUserDetails = () => {
  clearValueFromLocalStorage(STORAGE_KEY.SESSION_ID);
};

/**
 * add Autologout information in local-storage
 */
export const setAutoLogoutType = (logoutType) => {
  addToLocalStorage(STORAGE_KEY.LOGOUT_TYPE, logoutType);
};

/**
 * get Autologout information in local-storage
 */
export const getAutoLogoutType = () => getValueFromLocalStorage(STORAGE_KEY.LOGOUT_TYPE);
