/**
 * @Description FasatColumnOrder List Box File
 * @FileName ListBox.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import classNames from './util/classNames';
import Filter from './Filter';
import languageShape from './shapes/languageShape';
import capitalizeFirstLetter from './util/capitalizeFirstLetter';
import List from './List';

class ListBox extends React.Component {
  /**
   * @returns {React.Component}
   */
  renderFilter() {
    const {
      canFilter,
      controlKey,
      disabled,
      filterPlaceholder,
      filterValue,
      id,
      lang,
      onFilterChange,
    } = this.props;

    if (!canFilter) {
      return null;
    }

    return (
      <Filter
        controlKey={controlKey}
        disabled={disabled}
        filterPlaceholder={filterPlaceholder}
        filterValue={filterValue}
        id={id}
        lang={lang}
        onFilterChange={onFilterChange}
      />
    );
  }

  /**
   * @returns {React.Component}
   */
  renderList() {
    const {
      actions,
      disabled,
      controlKey,
      id,
      lang,
      showNoOptionsText,
      onDoubleClick,
      onKeyDown,
      value,
      options,
      onValueChange,
    } = this.props;

    if (showNoOptionsText && options.length === 0) {
      return (
        <div className="rdl-no-options">
          {lang[`no${capitalizeFirstLetter(controlKey)}Options`]}
        </div>
      );
    }

    return (
      <div className="rdl-control-container">
        {actions}
        {this.renderFilter()}
        <List
          className="rdl-control"
          disabled={disabled}
          id={`${id}-${controlKey}`}
          multiple
          value={value}
          onChange={onValueChange}
          onDoubleClick={onDoubleClick}
          onKeyDown={onKeyDown}
          options={options}
          controlKey={controlKey}
        />
      </div>
    );
  }

  render() {
    const { controlKey, id, lang, showHeaderLabels, t, headerLabel } = this.props;
    const labelClassName = classNames({
      'rdl-control-label': true,
      'rdl-sr-only': !showHeaderLabels,
    });

    return (
      <div className={`rdl-list-box rdl-${controlKey}`}>
        <label className={labelClassName} htmlFor={`${id}-${controlKey}`} id={`label-${id}-${controlKey}`}>
          {headerLabel !== '' && t(`${controlKey}_HEADER_${headerLabel}`.toString().toUpperCase())}
          {headerLabel === '' && t(lang[`${controlKey}Header`])}
        </label>
        {this.renderList()}

      </div>
    );
  }
}

ListBox.propTypes = {
  canFilter: PropTypes.bool.isRequired,
  controlKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  filterPlaceholder: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  lang: languageShape.isRequired,
  showHeaderLabels: PropTypes.bool.isRequired,
  showNoOptionsText: PropTypes.bool.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

  actions: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerLabel: PropTypes.string,
};

ListBox.defaultProps = {
  actions: null,
  value: [],
  headerLabel: '',
};

export default withTranslation()(ListBox);
