/* eslint-disable no-debugger */
/**
 * @Description Rate Master saga/watcher file.
 * @FileName rateMasterWatcher.js
 * @Author pgoutam
 * @CreatedOn 12/14/22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  rateDetailsActionTypes,
  rateKeyActionTypes, rateMasterDetailsActionTypes,
  rateParticipantsActionTypes, rateMasterActionTypes, rateSweepActionTypes,
} from '../actions/actionTypes';

import { Logger, processError, renderFileName, exportErrorMsg } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';
import { rdColOrder, rdrColOrder, rdoColOrder } from '../../config/rateDetailColumnConfig';
import { addRateKey, addRateMaster, deleteRateMaster, getRateMasterByID, getRateMasterList, updateRateMaster } from './rateMasterDetailsWatcher';

// #region Rate Master/Details api calls
const responseDataFunc = (responseData) => ({
  upsertRequest: null,
  upsertRequestError: null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: new Date(),
  upsertData: responseData,
});
export function* getRateMasterDetailList(action) {
  Logger.verbose(`Action Detail For Get RateMasterDetail List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterDetailsActionTypes.LOG_LIST_REQUEST, action.data)),
    put(getAction(rateMasterDetailsActionTypes.RESET_SERVER_VALUE, null)),

  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.RATE_MASTER_DETAIL_LIST,
      action.data);
    yield all([
      put(getAction(rateMasterDetailsActionTypes.GET_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* exportRateMasterDetail(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterDetailsActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(rateMasterDetailsActionTypes.LOG_EXPORT_LIST, { screenName: 'All results' })),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.RATE_MASTER_DETAILS_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, resetServerValue.serverRequestError)),
      put(processingCompleted()),
    ]);
  }
}

export const formatReqObjForRateDetailsSplitView = (orgReqObj, availableCols) => {
  const reqObj = JSON.parse(JSON.stringify(orgReqObj));
  reqObj.sortOptions = [];
  reqObj.columnOrders = [];
  orgReqObj.sortOptions.forEach((p) => {
    if (availableCols.includes(p.key)) {
      reqObj.sortOptions.push(p);
    }
  });

  orgReqObj.columnOrders.forEach((p) => {
    if (availableCols.includes(p)) {
      reqObj.columnOrders.push(p);
    }
  });

  return reqObj;
};
export function* getRateKeyList(action) {
  Logger.verbose(`Action Detail For Get Rate Key list action : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(rateKeyActionTypes.LOG_LIST_REQUEST, action.data)),
    put(getAction(rateMasterDetailsActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const reqObj = formatReqObjForRateDetailsSplitView(action.data, rdColOrder);
    const responseData = yield call(invokeApi, moduleAPI.RATE_KEY_LIST, reqObj);
    yield all([
      put(getAction(rateKeyActionTypes.GET_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}
export function* getRateDetailsList(action) {
  Logger.verbose(`Action Detail For Get Rate Details List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(rateDetailsActionTypes.LOG_LIST_REQUEST, action.data)),
    put(getAction(rateMasterDetailsActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const reqObj = formatReqObjForRateDetailsSplitView(action.data,
      [...rdrColOrder, 'rdrRateDetailId']);
    const responseData = yield call(invokeApi, moduleAPI.RATE_DETAIL_LIST, reqObj);
    yield all([
      put(getAction(rateDetailsActionTypes.GET_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}
export function* getRateParticipantsList(action) {
  Logger.verbose(`Action Detail For Get Participant List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(rateParticipantsActionTypes.LOG_LIST_REQUEST, action.data)),
    put(getAction(rateMasterDetailsActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const reqObj = formatReqObjForRateDetailsSplitView(action.data,
      [...rdoColOrder, 'rdoRateDetailId']);
    const responseData = yield call(invokeApi, moduleAPI.RATE_PARTICIPANT_LIST, reqObj);
    yield all([
      put(getAction(rateParticipantsActionTypes.GET_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}
export function* addRateDetail(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_DETAIL_ADD, action.data);
    if (responseData) {
      resetUpsertValue = responseDataFunc(responseData);
      yield all([
        put(getAction(rateDetailsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(getAction(rateDetailsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)), put(processingCompleted())]);
  }
}
export function* addRateParticipant(action) {
  let resetUpsertValue = {
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  try {
    yield all([
      put(processingStarted()),
      put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
    ]);
    const responseData = yield call(invokeApi, moduleAPI.RATE_PARTICIPANT_ADD, action.data);
    if (responseData) {
      resetUpsertValue = responseDataFunc(responseData);
      yield all([
        put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      ...resetUpsertValue,
      upsertRequestError: processError(err),
    };
    yield all([
      put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getRateKeyByID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateKeyActionTypes.GET_BY_ID_RESPONSE, null)),
    put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const reqObj = formatReqObjForRateDetailsSplitView(action.data, rdColOrder);
    const responseData = yield call(invokeApi, moduleAPI.RATE_KEY_GET_BY_ID, reqObj);
    if (responseData) {
      resetUpsertValue = responseDataFunc(responseData);
      yield all([
        put(getAction(rateKeyActionTypes.GET_BY_ID_RESPONSE, { ...responseData, comboLock: action.data.lock })),
        put(processingCompleted())]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateKeyActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}
export function* getRateDetailsByID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const reqObj = formatReqObjForRateDetailsSplitView(action.data, [...rdrColOrder, 'rdrRateDetailId']);
    const responseData = yield call(invokeApi,
      moduleAPI.RATE_DETAIL_GET_BY_ID,
      reqObj);
    if (responseData) {
      resetUpsertValue = responseDataFunc(responseData);
      yield all([
        put(getAction(rateDetailsActionTypes.GET_BY_ID_RESPONSE, { ...responseData, comboLock: action.data.lock })),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, processError(err))),
      put(getAction(rateDetailsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getRateParticipantByID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const reqObj = formatReqObjForRateDetailsSplitView(action.data,
      [...rdoColOrder, 'rdoRateDetailId']);
    const responseData = yield call(invokeApi, moduleAPI.RATE_PARTICIPANT_GET_BY_ID, reqObj);
    if (responseData) {
      resetUpsertValue = responseDataFunc(responseData);
      yield all([
        put(getAction(rateParticipantsActionTypes.GET_BY_ID_RESPONSE, { ...responseData, comboLock: action.data.lock })),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, processError(err))),
      put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* updateRateKey(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_KEY_UPDATE, action.data.formData);
    if (responseData) {
      resetUpsertValue = responseDataFunc(responseData);
      yield all([put(getAction(rateKeyActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)), put(processingCompleted())]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateKeyActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      // put(getAction(rateKeyActionTypes.GET_LIST_REQUEST, action.data.reqObj)),
      put(processingCompleted())]);
  }
}
export function* updateRateDetail(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_DETAIL_UPDATE, action.data.formData);
    if (responseData) {
      resetUpsertValue = responseDataFunc(responseData);
      yield all([
        put(getAction(rateDetailsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(rateDetailsActionTypes.GET_LIST_REQUEST, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(getAction(rateDetailsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)), put(processingCompleted())]);
  }
}
export function* updateRateParticipant(action) {
  let resetUpsertValue = {
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  try {
    yield all([
      put(processingStarted()),
      put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
    ]);
    const responseData = yield call(invokeApi, moduleAPI.RATE_PARTICIPANT_UPDATE, action.data);
    if (responseData) {
      resetUpsertValue = responseDataFunc(responseData);
      yield all([
        put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      ...resetUpsertValue,
      upsertRequestError: processError(err),
    };
    yield all([
      put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* deleteRateKey(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_KEY_DELETE, action.data.deleteItem);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      const listReq = action.data.reqObj;
      listReq.page = 1;
      yield all([
        put(getAction(rateKeyActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
        put(getAction(rateKeyActionTypes.GET_LIST_REQUEST, listReq)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([put(getAction(rateKeyActionTypes.RESET_DELETE_VALUE, resetDeleteValue)), put(processingCompleted())]);
  }
}
export function* deleteRateDetail(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_DETAIL_DELETE, action.data.formData);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      const listReq = action.data.reqObj;
      listReq.page = 1;
      yield all([
        put(getAction(rateDetailsActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
        put(getAction(rateDetailsActionTypes.GET_LIST_REQUEST, listReq)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([put(getAction(rateDetailsActionTypes.RESET_DELETE_VALUE, resetDeleteValue)), put(processingCompleted())]);
  }
}
export function* RateMasterExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_MASTER_EXPORT_LIST, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = exportMaxLimitErrorObj;
    } else {
      resetServerValue = processError(err);
    }
    yield all([put(getAction(rateMasterActionTypes.GET_LIST_ERROR, resetServerValue)), put(processingCompleted())]);
  }
}
export function* deleteRateParticipant(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_PARTICIPANT_DELETE, action.data.formData);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      const listReq = action.data.reqObj;
      listReq.page = 1;
      yield all([
        put(getAction(rateParticipantsActionTypes.GET_LIST_REQUEST, listReq)),
        put(getAction(rateParticipantsActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([put(getAction(rateParticipantsActionTypes.RESET_DELETE_VALUE, resetDeleteValue)), put(processingCompleted())]);
  }
}
export function* getRateSweepList(action) {
  Logger.verbose(`Action Detail For Get Participant List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(rateSweepActionTypes.LOG_LIST_REQUEST, action.data)),
    put(getAction(rateSweepActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_SWEEP_LIST, action.data);
    yield all([
      put(getAction(rateSweepActionTypes.GET_SWEEP_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(rateSweepActionTypes.GET_SWEEP_LIST_ERROR, err)), put(processingCompleted())]);
  }
}
export function* getLookUpsDataRS(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.RS_LOOKUPS_DATA, action.data);
    yield all([
      put(getAction(rateSweepActionTypes.RESET_RS_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(rateSweepActionTypes.RESET_RS_LOOKUPDATA, null)), put(processingCompleted())]);
  }
}
export function* getRateSweepCloneList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateSweepActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(rateSweepActionTypes.LOG_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_SWEEP_CLONE_LIST, action.data);
    yield all([
      put(getAction(rateSweepActionTypes.GET_SWEEP_CLONE_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(rateSweepActionTypes.GET_SWEEP_CLONE_LIST_ERROR, err)),
      put(getAction(rateSweepActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}
export function* getRateSweepCloneSave(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_SWEEP_CLONE_SAVE, action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(rateSweepActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateSweepActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* rateKeyListExport(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterDetailsActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(rateMasterDetailsActionTypes.LOG_EXPORT_LIST, { screenName: 'Rate key' })),

  ]);
  let resetServerValue = null;
  try {
    const reqObj = action.data;
    reqObj.data = formatReqObjForRateDetailsSplitView(action.data.data, rdColOrder);
    const responseData = yield call(invokeApi, moduleAPI.RATE_KEY_EXPORT_LIST, reqObj);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, resetServerValue.serverRequestError)),
      put(processingCompleted()),
    ]);
  }
}
export function* rateExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterDetailsActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(rateMasterDetailsActionTypes.LOG_EXPORT_LIST, { screenName: 'Rate details' })),
  ]);
  let resetServerValue = null;
  try {
    const reqObj = action.data;
    reqObj.data = formatReqObjForRateDetailsSplitView(action.data.data, [...rdrColOrder, 'rdrRateDetailId']);
    const responseData = yield call(invokeApi, moduleAPI.RATE_EXPORT_LIST, reqObj);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, resetServerValue.serverRequestError)),
      put(processingCompleted()),
    ]);
  }
}
export function* rateParticipantsExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterDetailsActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(rateMasterDetailsActionTypes.LOG_EXPORT_LIST, { screenName: 'Participants' })),
  ]);
  let resetServerValue = null;
  try {
    const reqObj = action.data;
    reqObj.data = formatReqObjForRateDetailsSplitView(action.data.data, [...rdoColOrder, 'rdoRateDetailId']);
    const responseData = yield call(invokeApi, moduleAPI.RATE_PARTICIPANTS_EXPORT_LIST, reqObj);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, resetServerValue.serverRequestError)),
      put(processingCompleted()),
    ]);
  }
}
export function* getRateMasterOnload(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_KEY_ONLOAD, action.data);
    if (responseData) {
      resetUpsertValue = responseDataFunc(responseData);
      yield all([put(getAction(rateKeyActionTypes.GET_BY_ONLOAD_RESPONSE, responseData)), put(processingCompleted())]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(getAction(rateKeyActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)), put(processingCompleted())]);
  }
}
export function* getRateMasterEditOnload(action) {
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_KEY_ONLOAD_EDIT, action.data);
    if (responseData === true || responseData === false) {
      yield all([
        put(getAction(rateKeyActionTypes.GET_BY_ONLOAD_EDIT_RESPONSE, responseData)),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateKeyActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
// #endregion
export function* getRateMasterHirerOnload(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_MASTER_ONLOAD, action.data);
    if (responseData) {
      resetUpsertValue = { onloadData: responseData };
      yield all([
        put(getAction(rateMasterActionTypes.GET_RATEMASTER_ONLOAD_RESPONSE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(getAction(rateMasterActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)), put(processingCompleted())]);
  }
}
export function* getRateDetailsHirerOnload(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_DETAILS_ONLOAD, action.data);
    if (responseData) {
      resetUpsertValue = { onloadData: responseData };
      yield all([
        put(getAction(rateDetailsActionTypes.GET_RATEDETAILS_ONLOAD_RESPONSE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateDetailsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getRateParticipantSysOnload() {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_PARTICIPANT_ONLOAD);
    if (responseData) {
      resetUpsertValue = { onloadData: responseData };
      yield all([
        put(getAction(rateParticipantsActionTypes.GET_RATEPARTICIPANT_ONLOADSYS_RESPONSE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getRateParticipantHirerOnload(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_DETAILS_ONLOAD, action.data);
    if (responseData) {
      resetUpsertValue = { onloadData: responseData };
      yield all([
        put(getAction(rateParticipantsActionTypes.GET_RATEPARTICIPANT_ONLOAD_RESPONSE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getOnloadCall(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_PARTICIPANTS_ONLOAD, action);
    if (responseData) {
      resetUpsertValue = { onParticipantsLoad: responseData };
      yield all([
        put(getAction(rateParticipantsActionTypes.GET_RATEPARTICIPANT_ONLOAD_RESPONSE_ERROR, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateParticipantsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* setFinArrangementsAddLock(action) {
  const { setStore, storeType } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(rateMasterActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(rateMasterActionTypes.GET_LIST_ERROR, null)),
    put(getAction(rateMasterDetailsActionTypes.GET_LIST_ERROR, null)),
  ]);
  try {
    yield call(invokeApi, moduleAPI.RATE_MASTER_ADD_LOCK);
    yield put(processingCompleted());
    setStore(true);
  } catch (err) {
    yield all([
      put(getAction(storeType, processError(err))),
      put(processingCompleted()),
    ]);
    setStore(false);
  }
}

export function* checkRateMasterInUse(action) {
  yield all([put(processingStarted()), put(getAction(rateMasterActionTypes.RESET_SERVER_VALUE, null))]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_MASTER_IN_USE, action.data);
    resetServerValue = { rmInUse: responseData };
    yield all([put(getAction(rateMasterActionTypes.RESET_SERVER_VALUE, resetServerValue)), put(processingCompleted())]);
  } catch (err) {
    yield all([put(getAction(rateMasterActionTypes.RESET_SERVER_VALUE,
      processError(err))),
    put(processingCompleted())]);
  }
}

export function* checkRateKeyInUse(action) {
  yield all([put(processingStarted()), put(getAction(rateKeyActionTypes.RESET_SERVER_VALUE, null))]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.RATE_KEY_IN_USE, action.data);
    resetServerValue = { rkInUse: responseData };
    yield all([put(getAction(rateKeyActionTypes.RESET_SERVER_VALUE, resetServerValue)), put(processingCompleted())]);
  } catch (err) {
    const resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([put(getAction(rateKeyActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted())]);
  }
}

export function* rateSweepExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.RATE_MASTER_SWEEP_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = exportMaxLimitErrorObj;
    } else {
      resetServerValue = processError(err);
    }
    yield all([
      put(getAction(rateSweepActionTypes.GET_SWEEP_LIST_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

function* rateMasterWatcher() {
  // #region Rate master module watcher
  yield takeEvery(rateMasterActionTypes.GET_ADD_CONCURRENCY, setFinArrangementsAddLock);
  yield takeEvery(rateMasterActionTypes.GET_LIST_REQUEST, getRateMasterList);
  yield takeEvery(rateMasterActionTypes.INSERT_REQUEST, addRateMaster);
  yield takeEvery(rateMasterActionTypes.GET_BY_ID_REQUEST, getRateMasterByID);
  yield takeEvery(rateMasterActionTypes.UPDATE_REQUEST, updateRateMaster);
  yield takeEvery(rateMasterActionTypes.DELETE_REQUEST, deleteRateMaster);
  yield takeEvery(rateMasterActionTypes.EXPORT_ACTION, RateMasterExportList);
  yield takeEvery(rateMasterDetailsActionTypes.GET_LIST_REQUEST, getRateMasterDetailList);
  yield takeEvery(rateMasterDetailsActionTypes.EXPORT_ACTION, exportRateMasterDetail);
  yield takeEvery(rateKeyActionTypes.GET_LIST_REQUEST, getRateKeyList);
  yield takeEvery(rateKeyActionTypes.INSERT_REQUEST, addRateKey);
  yield takeEvery(rateKeyActionTypes.GET_BY_ID_REQUEST, getRateKeyByID);
  yield takeEvery(rateKeyActionTypes.UPDATE_REQUEST, updateRateKey);
  yield takeEvery(rateKeyActionTypes.DELETE_REQUEST, deleteRateKey);
  yield takeEvery(rateDetailsActionTypes.GET_LIST_REQUEST, getRateDetailsList);
  yield takeEvery(rateDetailsActionTypes.INSERT_REQUEST, addRateDetail);
  yield takeEvery(rateDetailsActionTypes.GET_BY_ID_REQUEST, getRateDetailsByID);
  yield takeEvery(rateDetailsActionTypes.UPDATE_REQUEST, updateRateDetail);
  yield takeEvery(rateDetailsActionTypes.DELETE_REQUEST, deleteRateDetail);
  yield takeEvery(rateParticipantsActionTypes.GET_LIST_REQUEST, getRateParticipantsList);
  yield takeEvery(rateParticipantsActionTypes.INSERT_REQUEST, addRateParticipant);
  yield takeEvery(rateParticipantsActionTypes.GET_BY_ID_REQUEST, getRateParticipantByID);
  yield takeEvery(rateParticipantsActionTypes.UPDATE_REQUEST, updateRateParticipant);
  yield takeEvery(rateParticipantsActionTypes.DELETE_REQUEST, deleteRateParticipant);
  yield takeEvery(rateParticipantsActionTypes.EXPORT_ACTION, rateParticipantsExportList);
  yield takeEvery(rateParticipantsActionTypes.SET_ONLOAD_CALL, getOnloadCall);
  // Rate Sweep
  yield takeEvery(rateSweepActionTypes.GET_SWEEP_LIST_REQUEST, getRateSweepList);
  yield takeEvery(rateSweepActionTypes.GET_RS_LOOKUP_REQUEST, getLookUpsDataRS);
  yield takeEvery(rateSweepActionTypes.GET_SWEEP_CLONE_LIST_REQUEST, getRateSweepCloneList);
  yield takeEvery(rateSweepActionTypes.GET_SWEEP_CLONE, getRateSweepCloneSave);
  // #endregion
  yield takeEvery(rateKeyActionTypes.GET_RATEMASTER_ONLOAD, getRateMasterOnload);
  yield takeEvery(rateKeyActionTypes.GET_RATEMASTER_ONLOAD_EDIT, getRateMasterEditOnload);
  yield takeEvery(rateMasterActionTypes.GET_ONLOAD_RATEMASTER_HIRER, getRateMasterHirerOnload);
  yield takeEvery(rateDetailsActionTypes.GET_ONLOAD_RATEDETAILS_HIRER, getRateDetailsHirerOnload);
  yield takeEvery(rateParticipantsActionTypes.GET_ONLOAD_RATEPARTICIPANT_HIRER, getRateParticipantHirerOnload);
  yield takeEvery(rateParticipantsActionTypes.GET_RATEPARTICIPANT_ONLOAD_SYS_RESPONSE, getRateParticipantSysOnload);
  yield takeEvery(rateKeyActionTypes.EXPORT_ACTION, rateKeyListExport);
  yield takeEvery(rateDetailsActionTypes.EXPORT_ACTION, rateExportList);
  yield takeEvery(rateSweepActionTypes.EXPORT_SWEEP_ACTION, rateSweepExportList);
  yield takeEvery(rateKeyActionTypes.CHECK_RATEKEY_INUSE_REQUEST, checkRateKeyInUse);
  yield takeEvery(rateMasterActionTypes.CHECK_RM_INUSE_REQUEST, checkRateMasterInUse);
}

export default rateMasterWatcher;
