/**
 * @Description Modal content for custom filters
 * @FileName customFilter.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 25 June, 2021 01:05:29
 * @IssueID Issue Detail
 */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  FasatGridBar,
  FasatGrid,
  TableDefinition,
  actionBodyGenerator,
  FasatMessageCard,
} from '../..';
import { getCurrentLocale } from '../../../i18n';
import { formatRoleList, initialReqObject, Logger, resetDeletetValue } from '../../../util';
import { getCustomFilterList, resetDeleteValue } from '../../../redux/actions/commonActions';
import { FasatDateRangeFilter,
  FasatMultiSelectFilter,
  FasatTextFilter } from '../../fasatFilters';
import { globalConstants, staticCommonLabelKeys } from '../../../moduleConstants';
import FasatModal from '../../fasatModal/fasatModal';
import DeleteFilterItem from './DeleteFilterItem';
import { commaSeparatedBodyGenerator } from '../../fasatGrid';
import { fetchServerError } from '../../fasatPage/fasatPage';
import UserContext from '../../../../../userContext';

const RESET_FILTER = 'RESET_FILTER';
const DELETE_BCR_CANCEL = 'DELETE_BCR_CANCEL';
const DELETE_BCR = 'DELETE_BCR';
const privateFilter = 'private';
const modalWidth = '560px';
const sortOptions = [{ key: 'defaultFilter', asc: false }];
const locale = getCurrentLocale();
const { serverErrorMsgContainer, external } = globalConstants;
const externalUser = external;
/**
 * Dispatch Action to fetch List Data based on filter data
 * @param {object} filters
 */
export const onFilterHandler = ({ filters, privateValue, setSharedFilter,
  reqObj, parameters, setReqObj, fetchFilterList, setResetBtnAction }) => {
  const reqObjNew = reqObj;
  let finalFilters = null;
  if (filters.length > 0) {
    if (!privateValue) {
      finalFilters = filters.filter(({ key, value }) => key !== 'private' && value !== 'PRIVATE');
    } else {
      finalFilters = filters;
    }
    const isSharedWith = finalFilters.find(({ key }) => key === 'sharedWith');
    if (isSharedWith) {
      setSharedFilter(true);
    } else {
      setSharedFilter(false);
    }
    reqObjNew.parameters = [...parameters, ...finalFilters];
    setReqObj(reqObj);
    fetchFilterList(reqObj);
    setResetBtnAction(true);
  } else {
    reqObjNew.parameters = [...parameters];
    setReqObj(reqObj);
    fetchFilterList(reqObj);
    setResetBtnAction(false);
    setSharedFilter(false);
  }
};

/**
 * Private click check box handlet
 * @param {boolean} value
 */
export const privateClickHandler = (value, setPrivateValue, reqObj,
  setResetBtnAction, setReqObj, fetchFilterList) => {
  const reqObjNew = reqObj;
  setPrivateValue(value);
  if (!value) {
    const reqObjPrivate = reqObj.parameters.filter(({ key }) => key !== privateFilter);
    if (reqObjPrivate.length === 1) {
      setResetBtnAction(false);
    }
    reqObjNew.parameters = [...reqObjPrivate];
    setReqObj(reqObj);
    fetchFilterList(reqObj);
  }
};

const displayErrMsg = (t, serverErrors) => (
  <div className={serverErrorMsgContainer}>
    <FasatMessageCard
      titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
      variant="primary_alert"
    >
      {serverErrors.map((errItem) => (
        fetchServerError(t, errItem)
      ))}
    </FasatMessageCard>
  </div>
);

/**
 * function to create column headers for date fields
 * @param {String} headerText
 * @returns date header block
 */
const dateHeader = (headerText, dateFormat) => (
  <span>
    {headerText}
    <br />
    <span className="dateFormat">{dateFormat}</span>
  </span>
);

const handleReset = () => PubSub.publish(`${RESET_FILTER}`);

const fetchCurrentPage = (filterList) => filterList?.page;

const fetchTotalRows = (filterList) => filterList?.totalRows;

const fetchRowCount = (filterList) => (filterList && filterList.data ? filterList.data.length : 0);

const fetchPageSize = (filterList) => filterList?.pageSize;

const fetchRoleList = (roleList, setRoleListOptions) => {
  if (roleList) {
    setRoleListOptions([...formatRoleList(roleList)]);
  }
};

const nameCol = {
  actions: [],
  body: null,
  columnType: 'text',
  dataField: 'name',
  disableFilter: false,
  disableSortBy: true,
  filterType: FasatTextFilter,
  filterOperation: 'OPT_CONTAINS',
  id: 'name',
  isAction: false,
  visibility: true,
  width: 176,
  filterOptions: [],
  primaryTopic: RESET_FILTER,
};

const descCol = {
  actions: [],
  body: null,
  columnType: 'text',
  dataField: 'description',
  disableFilter: false,
  disableSortBy: true,
  filterType: FasatTextFilter,
  filterOperation: 'OPT_CONTAINS',
  id: 'description',
  isAction: false,
  visibility: true,
  width: 236,
  filterOptions: [],
  primaryTopic: RESET_FILTER,
};

const ownerCol = {
  actions: [],
  body: null,
  columnType: 'text',
  dataField: 'owner',
  disableFilter: false,
  disableSortBy: true,
  filterType: FasatTextFilter,
  filterOperation: 'OPT_CONTAINS',
  filterOptions: [],
  id: 'owner',
  isAction: true,
  visibility: true,
  width: 176,
  primaryTopic: RESET_FILTER,
};

const sharedWithCol = {
  columnType: 'action',
  dataField: 'sharedWith',
  disableFilter: false,
  disableSortBy: true,
  filterType: FasatMultiSelectFilter,
  filterOperation: 'OPT_ONE_OF',
  id: 'sharedWith',
  isAction: true,
  visibility: true,
  width: 200,
  primaryTopic: RESET_FILTER,
};

const createdOnCol = {
  actions: [],
  body: null,
  columnType: 'date',
  dataField: 'createdOn',
  accessor: (row) => moment(row.createdOn).format(globalConstants.dateFormatMMDDYYYY),
  disableFilter: false,
  disableSortBy: true,
  filterType: FasatDateRangeFilter,
  filterOperation: 'OPT_BETWEEN',
  id: 'createdOn',
  isAction: false,
  visibility: true,
  width: 148,
  primaryTopic: RESET_FILTER,
};

const updatedOnCol = {
  actions: [],
  body: null,
  columnType: 'date',
  dataField: 'updatedOn',
  accessor: (row) => moment(row.updatedOn).format(globalConstants.dateFormatMMDDYYYY),
  disableFilter: false,
  disableSortBy: true,
  filterType: FasatDateRangeFilter,
  filterOperation: 'OPT_BETWEEN',
  id: 'updatedOn',
  isAction: false,
  visibility: true,
  width: 158,
  primaryTopic: RESET_FILTER,
};

const usedOnCol = {
  actions: [],
  body: null,
  columnType: 'date',
  dataField: 'usedOn',
  accessor: (row) => moment(row.usedOn).format(globalConstants.dateFormatMMDDYYYY),
  disableFilter: false,
  disableSortBy: true,
  filterType: FasatDateRangeFilter,
  filterOperation: 'OPT_BETWEEN',
  id: 'usedOn',
  isAction: false,
  visibility: true,
  width: 152,
  primaryTopic: RESET_FILTER,
};

const generateTableDefination = (t, privateValue, roleListOptions, dateFormat, loadSelectedFilter,
  generateDeleteSection, userType) => {
  const tableDef = new TableDefinition('customFilterList', 'customFilterID');
  tableDef.addColumn({ ...nameCol, header: t(staticCommonLabelKeys.FILTER_LABEL_NAME) });
  tableDef.addColumn({ ...descCol, header: t(staticCommonLabelKeys.COMMON_LABEL_DESCRIPTION) });
  const ownerColumn = { ...ownerCol, header: t(staticCommonLabelKeys.COMMON_LABEL_OWNER) };
  ownerColumn.body = ({ cell }) => (<>{cell.row.original.owner.toLowerCase()}</>);
  if (userType !== externalUser) {
    tableDef.addColumn(ownerColumn);
  }
  const hyperLinkColumn = { ...sharedWithCol,
    header: t(staticCommonLabelKeys.COMMON_LABEL_SHARED_WITH),
    filterOptions: { key: 'shared_with', values: roleListOptions },
    privateValue,
  };
  hyperLinkColumn.body = commaSeparatedBodyGenerator(t(staticCommonLabelKeys.FILTER_LABEL_PRIVATE));
  if (userType !== externalUser) {
    tableDef.addColumn(hyperLinkColumn);
  }
  tableDef.addColumn({ ...createdOnCol,
    header: dateHeader(t(staticCommonLabelKeys.COMMON_LABEL_CREATED_ON), dateFormat) });
  tableDef.addColumn({ ...updatedOnCol,
    header: dateHeader(t(staticCommonLabelKeys.COMMON_LABEL_LAST_MODIFIED_ON), dateFormat),
  });
  tableDef.addColumn({ ...usedOnCol,
    header: dateHeader(t(staticCommonLabelKeys.COMMON_LABEL_LAST_USED_ON), dateFormat),
  });
  const actionColumn = {
    actions: [
      {
        actionHandler: (rowData, colData) => {
          Logger.info(`Load Handler Row Data, ${rowData}`);
          Logger.info(`Load Handler Column Data', ${JSON.stringify(colData)}`);
          loadSelectedFilter(rowData.row.original);
        },
        className: 'pi pi-user-edit',
        hoverIcon: 'loadIconHover',
        icon: 'loadIcon',
        id: 'load',
        name: 'Load',
        ariaLabel: t(staticCommonLabelKeys.ARIA_COMMON_GRID_BUTTON_LOAD),
        order: 1,
      },
      {
        actionHandler: (rowData, colData) => {
          Logger.info(`Delete Handler Row Data, ${rowData}`);
          Logger.info(`Delete Handler Column Data', ${colData}`);
          generateDeleteSection(rowData.row.original);
        },
        className: 'p-button-rounded p-button-warning',
        hoverIcon: 'deleteIconHover',
        icon: 'deleteIcon',
        id: 'delete',
        name: 'Deleted',
        ariaLabel: t(staticCommonLabelKeys.ARIA_COMMON_GRID_BUTTON_DELETE),
        order: 2,
        iconDisabled: true,
      },
    ],
    columnType: 'action',
    dataField: null,
    disableFilter: false,
    disableSortBy: true,
    filterOptions: [],
    filterType: null,
    header: t(staticCommonLabelKeys.COMMON_LABEL_ACTIONS),
    id: 'action',
    isAction: true,
    visibility: true,
    width: 70,
  };
  actionColumn.body = actionBodyGenerator(
    actionColumn.actions, (action, rowData, columnData) => action && rowData && columnData,
  );
  tableDef.addColumn(actionColumn);
  tableDef.setColumnResizable();
  return tableDef;
};

const displayFasatGridBar = ({ t, setPrivateValue, reqObj, setResetBtnAction, setReqObj, fetchFilterList,
  resetBtnAction, currentPage, onChangePageSize, pageSize, totalRows, sharedFilter }) => (
    <FasatGridBar
      addAction={false}
      exportAction={false}
      resetEnabled={resetBtnAction}
      currentPage={currentPage}
      pageSize={pageSize}
      onChangePageSize={(val) => onChangePageSize(val)}
      totalRows={totalRows || 0}
      showLabel={t(staticCommonLabelKeys.COMMON_LABEL_PAGESIZE_PART1)}
      recordDisplayLabel={t(staticCommonLabelKeys.COMMON_LABEL_PAGESIZE_PART2)}
      resetLabel={t(staticCommonLabelKeys.COMMON_BUTTON_RESET)}
      resetAriaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_RESET_FILTER)}
      addLabel={t(staticCommonLabelKeys.COMMON_BUTTON_ADD)}
      exportLabel={t(staticCommonLabelKeys.COMMON_BUTTON_EXPORT)}
      resetAction
      onClickReset={() => handleReset()}
      placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
      showDefaultChip
      defaultChipText={t(staticCommonLabelKeys.FILTER_LABEL_MY_DEFAULT)}
      showPrivateCheckbox
      privateHandler={(value) => privateClickHandler(value, setPrivateValue, reqObj,
        setResetBtnAction, setReqObj, fetchFilterList)}
      resetButtonClick={resetBtnAction}
      sharedFilter={sharedFilter}
    />
);

const displayFilterDeleteModal = (t, displayDeleteModal, setdisplayDeleteModal, formData, reqObj,
  triggerFilterListCount, { toasterContainerId, cusFilListModalId }) => (
    <FasatModal
      title={null}
      display={displayDeleteModal}
      modalWidth={modalWidth}
      primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_DELETE)}
      secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_CANCEL)}
      primaryTopic={DELETE_BCR}
      secondaryTopic={DELETE_BCR_CANCEL}
      modalClass="modalWithoutHeading"
      modalCosed={() => setdisplayDeleteModal(false)}
      staydiscard
      defaultCheck
    >
      <DeleteFilterItem
        onDeleteTopic={DELETE_BCR}
        formData={formData}
        reqObj={reqObj}
        triggerFilterListCount={triggerFilterListCount}
        toasterContainerId={toasterContainerId}
        cusFilListModalId={cusFilListModalId}
      />
    </FasatModal>
);

const CustomFilterList = ({ entityId, loadSelectedFilter, triggerFilterListCount, toasterContainerId,
  cusFilListModalId }) => {
  const parameters = [{ key: 'entityId', operator: 'OPT_EQUALS', type: 'number', values: [entityId] }];
  const [t] = useTranslation();
  const userType = useContext(UserContext);
  const dateFormat = t(staticCommonLabelKeys.COMMON_DATE_FORMAT_MM_DD_YYYY);
  const filterList = useSelector((state) => state.ApplicationStateFilterList.filterList);
  const upsertRequestError = useSelector((state) => state.ApplicationState.upsertRequestError);
  const [formData, setFormData] = useState([]);
  const [serverErrors, setServerErrors] = useState([]);
  const [resetBtnAction, setResetBtnAction] = useState(false);
  const [privateValue, setPrivateValue] = useState(false);
  const [sharedFilter, setSharedFilter] = useState(false);
  const dispatch = useDispatch();
  const roleList = useSelector((state) => state.ApplicationStateRBAC.roleList);
  const [roleListOptions, setRoleListOptions] = useState([]);
  const currentPage = fetchCurrentPage(filterList);
  const totalRows = fetchTotalRows(filterList);
  const rowCount = fetchRowCount(filterList);
  const pageSize = fetchPageSize(filterList);
  const [reqObj, setReqObj] = useState(initialReqObject({ locale, sortOptions }));
  const [displayDeleteModal, setdisplayDeleteModal] = useState(false);
  const fetchFilterList = (reqObjFilter) => dispatch(getCustomFilterList({ reqObjFilter, listCount: false }));
  useEffect(() => fetchRoleList(roleList, setRoleListOptions), [roleList]);
  useEffect(() => {
    if (upsertRequestError) {
      setServerErrors(upsertRequestError);
    } else { setServerErrors(null); }
  }, [upsertRequestError]);
  const paginationInfo = { currentPage, totalRows, rowCount, pageSize };
  const fasatValues = { setPrivateValue, reqObj, setResetBtnAction, setReqObj, fetchFilterList, resetBtnAction };
  const onColumnResizeHandler = (columnResizing) => Logger.info(`columnResizer -> , ${JSON.stringify(columnResizing)}`);
  const onSortByHandler = (sortBy) => Logger.info(`sortBy -> , ${JSON.stringify(sortBy)}`);
  const filterHandler = (filters) => onFilterHandler({ filters,
    privateValue,
    setSharedFilter,
    reqObj,
    parameters,
    setReqObj,
    fetchFilterList,
    setResetBtnAction });
  const onPageChangeHandler = (currPage) => {
    reqObj.page = currPage;
    setReqObj(reqObj);
    fetchFilterList(reqObj);
  };
  const onChangePageSize = (currentPageSize) => {
    reqObj.pageSize = currentPageSize;
    setReqObj(reqObj);
    fetchFilterList(reqObj);
  };
  const generateDeleteSection = (deleteItems) => {
    setFormData(deleteItems);
    if (deleteItems) {
      dispatch(resetDeleteValue(resetDeletetValue));
      setdisplayDeleteModal(!displayDeleteModal);
      const reqObjFilter = initialReqObject({ locale, sortOptions, parameters });
      dispatch(getCustomFilterList({ reqObjFilter, listCount: true }));
      if (document.getElementsByClassName('customFilterListModal')) {
        document.querySelector('.customFilterListModal').parentElement.style.zIndex = '99996';
      }
    }
  };
  return (
    <>
      {serverErrors && serverErrors.length > 0 && (displayErrMsg(t, serverErrors))}
      {displayFasatGridBar({ t, ...fasatValues, currentPage, onChangePageSize, pageSize, totalRows, sharedFilter })}
      <FasatGrid
        data={filterList && filterList.data ? filterList.data : []}
        tableMetaData={generateTableDefination(t, privateValue, roleListOptions, dateFormat, loadSelectedFilter,
          generateDeleteSection, userType)}
        paginationInfo={paginationInfo}
        onColumnResize={onColumnResizeHandler}
        onSortBy={onSortByHandler}
        onFilter={filterHandler}
        onPageChangeHandler={onPageChangeHandler}
        isFilterable
        isSortable="true"
        isSortIconClickable="false"
        isColumnResizable="true"
        hasRowSelect="false"
        allowClientSideInteractions="false"
        showingLabel={t(staticCommonLabelKeys.COMMON_LABEL_SHOWING)}
        ofLabel={t(staticCommonLabelKeys.COMMON_LABEL_OF)}
        hypenLabel={t(staticCommonLabelKeys.COMMON_LABEL_HYPHEN)}
        activateDataGrid
        reqObj={reqObj}
        customFilterList
        fetchFilterList={fetchFilterList}
      />
      {displayFilterDeleteModal(t, displayDeleteModal, setdisplayDeleteModal, formData, reqObj,
        triggerFilterListCount, { toasterContainerId, cusFilListModalId })}
    </>
  );
};

CustomFilterList.propTypes = {
  entityId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  loadSelectedFilter: PropTypes.func,
  triggerFilterListCount: PropTypes.func,
  toasterContainerId: PropTypes.string.isRequired,
  cusFilListModalId: PropTypes.string.isRequired,
};
CustomFilterList.defaultProps = {
  loadSelectedFilter: () => undefined,
  triggerFilterListCount: () => undefined,
};

export default CustomFilterList;
