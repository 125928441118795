import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { FasatButtonSelector, FasatDatePicker, FasatDropdown, FasatFormElement, FasatLabel,
  FasatModal, FasatTextInput, FasatConfirmationMsg } from '../../../../common/components';
import { globalConstants, staticCommonLabelKeys } from '../../../../common/moduleConstants';
import { currencyExchange, currencyFormatToDecimal, getErrorsPage, Logger } from '../../../../common/util';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import { checkDirtyData, lookUpsDataProcessing } from '../../../../common/redux/actions/commonActions';
import { getCurrentLocale } from '../../../../common/i18n';
import { fieldForceActionTypes } from '../../redux';
import { addValidationSchema, addRules, editRules, addRuleConfig, editRuleConfig } from './addEditDeductionRuleConfig';
import FasatCurrencyInput from '../../../../common/components/fasatCurrencyInput/fasatCurrencyInput';
import FasatPage, { fetchServerError } from '../../../../common/components/fasatPage/fasatPage';
import { staticLabelKeys } from '../../moduleConstants';
import { getReqPayLoad, dirtyCheckCancel, getTranslatedLabels, footerFunc, onDateChange, transactionFormik,
  showServerMessage, dispatchUpsert, tranDetailsSet, getSuccessMsg,
  pathChange, addRowAuto, clientAddCancelCR, formatDecimal } from './addEditDeductionHelper';

const { formElement, formRow, formControl, commonFE } = globalConstants;
let formik;
const decimalPlaces = 2;
const debouncingTimeout = 1000;
const fourdecimalPlaces = 4;
const three = 3;
let formRuleIDs = addRules;
let formRuleConfig = addRuleConfig;
const dateInvalidError = ' ';
const dateRangeError = ' ';
export const setRule = (addNewBCR) => {
  formRuleIDs = addNewBCR ? editRules : addRules;
  formRuleConfig = addNewBCR ? editRuleConfig : addRuleConfig;
};
const getProvinceLookUp = (dispatch, locale, val) => {
  const reqnewObj = { transaction: val, transactionMode: 'INSERT', priority: 0 };
  dispatch(getAction(fieldForceActionTypes.GET_FF_TRAN_DETAILS, reqnewObj));
  formik.setFieldValue('adeType', val);
  const tt = getReqPayLoad(locale);
  dispatch(lookUpsDataProcessing(tt));
  if (val !== 'BKRPT') {
    formik.setFieldValue('adeTransAgtCd', null);
    formik.setFieldValue('adeTransAgtName', '');
  }
};
/**
 * handle save and edit event
 */
export const findDisable = () => {
  if (formik.values.adeAmtCalcMethod === 'M' || formik.values.adeAmtCalcMethod === 'R') {
    return false;
  }
  return true;
};
export const findDisableRenewal = () => {
  if (formik.values.adeAmtCalcMethod === 'P' || formik.values.adeAmtCalcMethod === 'N') {
    return false;
  }
  return true;
};
export const checkNullValue = (val, place, locale) => {
  if (val === null) {
    return null;
  }
  if (val) {
    return locale === 'en' ? val : currencyExchange(val, 'en', place);
  }
  return val?.trim() === '' && null;
};
export const flagTransferFunc = (flagTransfer, adeTransAgtCd) => {
  if (flagTransfer) {
    return adeTransAgtCd;
  }
  return null;
};
const getAdeTargetAmtVal = (val, locale) => ((val !== '') ? currencyFormatToDecimal(
  val,
  locale,
) : null);
const handleSaveClick = ({ dispatch, clientId, locale, addNewBCR, contWithError,
  clientLevel, setValidateOnChange }) => {
  setValidateOnChange(true);
  dispatch(getAction(fieldForceActionTypes.RESET_UPSERT_ERROR, null));
  formik.validateForm().then(() => {
    let startDate;
    const fromdate = formik.values.adeEffDt;
    if (fromdate !== null) {
      const [month, day, year] = fromdate?.split('/');
      startDate = [year, month, day]?.join('-');
    }
    const enddate = formik.values.adeEndDt;
    let resultenddate = null;
    if (enddate) {
      const [mon, date, years] = enddate?.split('/');
      resultenddate = [years, mon, date]?.join('-');
    }
    if (formik.isValid && formik.dirty && formik.values) {
      const formatObjectEdit = {
        adeAgtCd: clientId,
        AgtLevel: clientLevel?.split('-')[0].trim(),
        adeType: formik.values?.adeType,
        adeCurr: formik.values.adeCurr,
        adeAmtCalcMethod: formik.values.adeAmtCalcMethod,
        adeAmt: getAdeTargetAmtVal(formik.values.adeAmt, locale),
        adePercent: formik.values.adePercent,
        adeFreq: formik.values.adeFreq,
        adeTargetAmt: getAdeTargetAmtVal(formik.values.adeTargetAmt, locale),
        adeCumulativeAmt: formik.values.adeCumulativeAmt,
        adeMonthlyCumulativeAmt: formik.values.adeMonthlyCumulativeAmt,
        adeMonthlyTargetAmt: getAdeTargetAmtVal(formik.values.adeMonthlyTargetAmt, locale),
        adeEffDt: startDate,
        adeCostCenter: formik.values.adeCostCenter,
        adeTransAgtCd: flagTransferFunc(formik.values.flagTransfer, formik.values.adeTransAgtCd),
        adeDedctAct: formik.values.adeDedctAct,
        adeSpecialPayProcess: 0,
        adePctOfFyrComm: getAdeTargetAmtVal(formik.values.adePctOfFyrComm, locale),
        adePctOfRenComm: getAdeTargetAmtVal(formik.values.adePctOfRenComm, locale),
        adePctOfFyrOvrde: getAdeTargetAmtVal(formik.values.adePctOfFyrOvrde, locale),
        adePctOfRenOvrde: getAdeTargetAmtVal(formik.values.adePctOfRenOvrde, locale),
        adePctOfBal: getAdeTargetAmtVal(formik.values.adePctOfBal, locale),
        adeMemo: formik.values.adeMemo,
        commType: formik.values.comType,
        commSubType: formik.values.comSubType,
        type: formik.values.type,
        adeEndDt: resultenddate,
        adeLstProcDt: null,
        adePriority: formik.values.adePriority,
        adeDays: formik.values.adeDays,
        CompenS016Response: !!contWithError,
        Scharge: formik.values.scharge,
        adeTransAgtReq: formik.values.transferAgntReq,
        adeSeq: formik.values.adeSeq,
        adeMonthlyTargetSw: formik.values.AdeMonthlyTargetSw,
        isEdit: !!addNewBCR,
      };
      if (addNewBCR) {
        dispatch(getAction(fieldForceActionTypes.UPDATE_FIELDFORCE_DEDUCTION,
          { formatObject: formatObjectEdit, reqObject: { language: locale, clientNumber: clientId } }));
      } else {
        dispatch(getAction(fieldForceActionTypes.INSERT_FIELDFORCE_DEDUCTION,
          { formatObject: formatObjectEdit, reqObject: { language: locale, clientNumber: clientId } }));
      }
    } else {
      Logger.verbose('Client Address Submit default');
    }
  });
};
export const checkDropDown = (freequencyOptions) => {
  if (formik.values.adeAmtCalcMethod === 'R') {
    return freequencyOptions.filter((index) => index.value === 'AM' || index.value === 'PE');
  }
  return freequencyOptions;
};
const currencyFunc = (lookupData, setCurrencyOptions) => {
  const requestType = lookupData.filter((lookUpItem) => lookUpItem.key === 'Currency');
  if (requestType && requestType.length > 0) {
    setCurrencyOptions(requestType[0].values.filter((val) => val.value === 'CA'));
  }
};
export const getLookUps = (lookupData, setFreequencyOptions, setCurrencyOptions,
  setMethodOptions, setStatusOptions) => {
  if (lookupData && lookupData.length > 0) {
    const freequencyType = lookupData.filter((lookUpItem) => lookUpItem.key === 'ADE_Frequency');
    if (freequencyType && freequencyType.length > 0) {
      setFreequencyOptions(freequencyType[0].values);
    }
    currencyFunc(lookupData, setCurrencyOptions);
    const renewalType = lookupData.filter((lookUpItem) => lookUpItem.key === 'LIC_Ren_Stat');
    if (renewalType && renewalType.length > 0) {
      setStatusOptions(renewalType[0].values);
    }
    const MethodType = lookupData.filter((lookUpItem) => lookUpItem.key === 'ADE_CalcMethod');
    if (MethodType && MethodType.length > 0) {
      setMethodOptions(MethodType[0].values);
    }
  }
};
const addRow1 = (t, dispatch, transactonOptions, locale, CurrencyOptions, serverErrors, clientId) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adeCurrFrm"
        name="adeCurr"
        errors={getErrorsPage('adeCurr', formik, serverErrors, formRuleIDs.adeCurr, formRuleConfig, false, t)}
      >
        <FasatLabel>{t(staticCommonLabelKeys.COMMON_LABEL_MLC_SELLING_CD)}</FasatLabel>
        <FasatLabel isContent>{clientId}</FasatLabel>
      </FasatFormElement>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adeTypeForm"
        errors={getErrorsPage('adeType', formik, serverErrors, formRuleIDs.adeType, formRuleConfig, false, t)}
      >
        <FasatDropdown
          dropdownItems={transactonOptions}
          onHandleChange={(val) => getProvinceLookUp(dispatch, locale, val)}
          id="adeType"
          label={t(staticCommonLabelKeys.COMMON_LABEL_TRANSACTION)}
          name="adeType"
          {...formik.getFieldProps('adeType')}
          selected={0}
          placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
          errors={getErrorsPage('adeType', formik, serverErrors, formRuleIDs.adeType, formRuleConfig, true, t)}
          ariaRequired
        />
      </FasatFormElement>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adeCurrForm"
        name="adeCurr"
        errors={getErrorsPage('adeCurr', formik, serverErrors, formRuleIDs.adeCurr, formRuleConfig, false, t)}
      >
        <FasatLabel>{t(staticCommonLabelKeys.COMMON_LABEL_CURRENCY)}</FasatLabel>
        <FasatLabel isContent>
          {CurrencyOptions && CurrencyOptions.filter((index) => index.value === 'CA')[0]?.label}
        </FasatLabel>
      </FasatFormElement>
    </div>
  </div>
);
const addRow4 = (t, serverErrors) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adePriorityForm"
        errors={getErrorsPage('adePriority', formik, serverErrors, formRuleIDs.adePriority, formRuleConfig, false, t)}
      >
        <FasatTextInput
          id="adePriority"
          value={formik.values.adePriority}
          name="adePriority"
          label={t(staticCommonLabelKeys.COMMON_LABEL_PRIORITY)}
          mask="999"
          ariaRequired
          maxLength={10}
          {...formik.getFieldProps('adePriority')}
          errors={getErrorsPage('adePriority', formik, serverErrors, formRuleIDs.adePriority, formRuleConfig, true, t)}
        />
      </FasatFormElement>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formControl} datePickerControl`}>
        <FasatFormElement direction="bottom" id="AdeMonthlyTargetSwForm" name="AdeMonthlyTargetSwForm">
          <FasatButtonSelector
            values={[{ label: t(staticCommonLabelKeys.COMMON_BUTTON_YES), value: 1 },
              { label: t(staticCommonLabelKeys.COMMON_BUTTON_NO), value: 0 }]}
            onHandleChange={(value) => formik.setFieldValue('AdeMonthlyTargetSw', Number(value))}
            id="AdeMonthlyTargetSw"
            label={t(staticLabelKeys.CM_DE_LABEL_MONTHTRG)}
            name="AdeMonthlyTargetSw"
            {...formik.getFieldProps('AdeMonthlyTargetSw')}
            value={formik.values.AdeMonthlyTargetSw}
          />
        </FasatFormElement>
      </div>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adeMonthlyTargetAmtForm"
        errors={getErrorsPage('adeMonthlyTargetAmt', formik, serverErrors, formRuleIDs.adeMonthlyTargetAmt,
          formRuleConfig, false, t)}
      >
        <FasatCurrencyInput
          id="adeMonthlyTargetAmt"
          name="adeMonthlyTargetAmt"
          adornmentEnabled
          placeholder="0.00"
          maxLength="13"
          decimalPlaces={decimalPlaces}
          disabled={formik.values.AdeMonthlyTargetSw === 0}
          label={t(staticLabelKeys.CM_DE_LABEL_MONTARGETAMT)}
          {...formik.getFieldProps('adeMonthlyTargetAmt')}
          handleOnBlur={(val) => formik.setFieldValue('adeMonthlyTargetAmt', val)}
          errors={getErrorsPage('adeMonthlyTargetAmt', formik, serverErrors,
            formRuleIDs.adeMonthlyTargetAmt, formRuleConfig, true, t)}
        />
      </FasatFormElement>
    </div>
  </div>
);
const addRow6 = (t, serverErrors) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adePercentForm"
        name="adePercentForm"
        errors={getErrorsPage('adePercent', formik, serverErrors, formRuleIDs.adePercent, formRuleConfig, false, t)}
      >
        <FasatTextInput
          id="adePercent"
          ariaRequired
          name="adePercent"
          label={t(staticLabelKeys.CM_DE_LABEL_PERCENT)}
          onInputEnabled
          onBlurEnabled
          isParcent
          beforeDc={three}
          decimalPlaces={fourdecimalPlaces}
          {...formik.getFieldProps('adePercent')}
          errors={getErrorsPage('adePercent', formik, serverErrors, formRuleIDs.adePercent, formRuleConfig, true, t)}
          disabled={findDisable()}
        />
      </FasatFormElement>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adeCumulativeAmtForm"
        name="adeCumulativeAmtForm"
        errors={getErrorsPage('adeCumulativeAmt', formik, serverErrors,
          formRuleIDs.adeCumulativeAmt, formRuleConfig, false, t)}
      >
        <FasatCurrencyInput
          id="adeCumulativeAmt"
          name="adeCumulativeAmt"
          adornmentEnabled
          placeholder="0.00"
          maxLength="13"
          decimalPlaces={decimalPlaces}
          label={t(staticCommonLabelKeys.CM_DE_LABEL_CUMULATIVE)}
          disabled
          {...formik.getFieldProps('adeCumulativeAmt')}
          handleOnBlur={(val) => formik.setFieldValue('adeCumulativeAmt', val)}
          errors={getErrorsPage('adeCumulativeAmt', formik, serverErrors,
            formRuleIDs.adeCumulativeAmt, formRuleConfig, true, t)}
        />
      </FasatFormElement>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formControl} datePickerControl`}>
        <FasatFormElement direction="bottom" id="adeMonthlyCumulativeAmtForm">
          <FasatCurrencyInput
            id="adeMonthlyCumulativeAmt"
            name="adeMonthlyCumulativeAmt"
            adornmentEnabled
            disabled
            placeholder="0.00"
            maxLength="25"
            decimalPlaces={decimalPlaces}
            label={t(staticLabelKeys.CM_DE_LABEL_MONTHLYCUMULATIVE)}
            {...formik.getFieldProps('adeMonthlyCumulativeAmt')}
            handleOnBlur={(val) => formik.setFieldValue('adeMonthlyCumulativeAmt', val)}
          />
        </FasatFormElement>
      </div>
    </div>
  </div>
);
const addRow2 = (t, methodOptions, freequencyOptions, serverErrors) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adeAmtCalcMethodForm"
        errors={getErrorsPage('adeAmtCalcMethod',
          formik, serverErrors, formRuleIDs.adeAmtCalcMethod, formRuleConfig, false, t)}
      >
        <FasatDropdown
          dropdownItems={methodOptions}
          onHandleChange={(val) => {
            formik.setFieldValue('adeAmtCalcMethod', val);
            formik.setFieldValue('adeFreq', null);
          }}
          id="adeAmtCalcMethod"
          label={t(staticCommonLabelKeys.CM_DE_LABEL_METHOD)}
          name="adeAmtCalcMethod"
          {...formik.getFieldProps('adeAmtCalcMethod')}
          selected={0}
          placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
          errors={getErrorsPage('adeAmtCalcMethod', formik, serverErrors,
            formRuleIDs.adeAmtCalcMethod, formRuleConfig, true, t)}
          ariaRequired
        />
      </FasatFormElement>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adeFreqForm"
        errors={getErrorsPage('adeFreq', formik, serverErrors, formRuleIDs.adeFreq, formRuleConfig, false, t)}
      >
        <FasatDropdown
          dropdownItems={checkDropDown(freequencyOptions)}
          onHandleChange={(val) => formik.setFieldValue('adeFreq', val)}
          id="adeFreq"
          label={t(staticCommonLabelKeys.COMMON_LABEL_FREQUENCY)}
          name="adeFreq"
          {...formik.getFieldProps('adeFreq')}
          selected={0}
          placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
          ariaRequired
          errors={getErrorsPage('adeFreq', formik, serverErrors, formRuleIDs.adeFreq, formRuleConfig, true, t)}
        />
      </FasatFormElement>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formControl} datePickerControl`}>
        <FasatFormElement
          direction="bottom"
          id="adeDaysForm"
          errors={getErrorsPage('adeDays', formik, serverErrors, formRuleIDs.adeDays, formRuleConfig, false, t)}
        >
          <FasatTextInput
            id="adeDays"
            name="adeDays"
            {...formik.getFieldProps('adeDays')}
            ariaRequired
            label={t(staticCommonLabelKeys.COMMON_LABEL_DAYS)}
            maxLength={6}
            errors={getErrorsPage('adeDays', formik, serverErrors, formRuleIDs.adeDays, formRuleConfig, true, t)}
            disabled={formik.values.adeFreq !== 'DY'}
          />
        </FasatFormElement>
      </div>
    </div>
  </div>
);
const datePickerFunc = (t, serverErrors) => (
  <div className={`${formElement} ${commonFE}`}>
    <div className={`${formControl} datePickerControl`}>
      <FasatFormElement
        direction="bottom"
        id="adeLstProcDtForm"
        name="adeLstProcDtForm"
        errors={getErrorsPage('adeLstProcDt', formik, serverErrors, formRuleIDs.adeLstProcDt, formRuleConfig, false, t)}
      >
        <FasatDatePicker
          id="adeLstProcDt"
          required={false}
          label={t(staticLabelKeys.CM_DE_LABEL_LASTPROCESSED)}
          dateFormat="MM/DD/YYYY"
          {...formik.getFieldProps('adeLstProcDt')}
          dateValue={formik.values.adeLstProcDt}
          onChange={(newValue) => formik.setFieldValue('adeLstProcDt', newValue)}
          errors={getErrorsPage('adeLstProcDt', formik, serverErrors,
            formRuleIDs.adeLstProcDt, formRuleConfig, true, t)}
          hideDefaultErrorBorder
          dateInvalidError={dateInvalidError}
          dateRangeError={dateRangeError}
          disabled
        />
      </FasatFormElement>
    </div>
  </div>
);
const addRow3 = (t, serverErrors) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formControl} datePickerControl`}>
        <FasatFormElement
          direction="bottom"
          id="adeEffDtForm"
          name="adeEffDtForm"
          errors={getErrorsPage('adeEffDt', formik, serverErrors, formRuleIDs.adeEffDt, formRuleConfig, false, t)}
        >
          <FasatDatePicker
            id="adeEffDt"
            required={false}
            dateFormat="MM/DD/YYYY"
            dateValue={formik.values.adeEffDt}
            label={t(staticCommonLabelKeys.COMMON_LABEL_STARTDATE)}
            onChange={(newValue) => { formik.setFieldValue('adeEffDt', newValue); }}
            errors={getErrorsPage('adeEffDt', formik, serverErrors, formRuleIDs.adeEffDt, formRuleConfig, true, t)}
            hideDefaultErrorBorder
            dateInvalidError={dateInvalidError}
            dateRangeError={dateRangeError}
          />
        </FasatFormElement>
      </div>
    </div>
    <div className={`${formElement} ${commonFE} noLabelDateFieldAODA`}>
      <div className={`${formControl} datePickerControl`}>
        <FasatFormElement
          direction="bottom"
          id="adeEndDtForm"
          name="adeEndDtForm"
          errors={getErrorsPage('adeEndDt', formik, serverErrors, formRuleIDs.adeEndDt, formRuleConfig, false, t)}
        >
          <FasatLabel
            additionalTag
            additionalText={t(staticCommonLabelKeys.COMMON_DATE_FORMAT_MM_DD_YYYY)}
            optionalTag
            optionalText={t(staticCommonLabelKeys.COMMON_LABEL_OPTIONAL)}
            htmlFor="adeEndDt-input"
          >
            {t(staticCommonLabelKeys.COMMON_LABEL_ENDDATE)}
          </FasatLabel>
          <FasatDatePicker
            id="adeEndDt"
            required={false}
            dateFormat="MM/DD/YYYY"
            labelFormat=" "
            {...formik.getFieldProps('adeEndDt')}
            dateValue={formik.values.adeEndDt}
            onChange={(newValue) => { formik.setFieldValue('adeEndDt', newValue); }}
            errors={getErrorsPage('adeEndDt', formik, serverErrors, formRuleIDs.adeEndDt, formRuleConfig, true, t)}
            hideDefaultErrorBorder
            dateInvalidError={dateInvalidError}
            dateRangeError={dateRangeError}
          />
        </FasatFormElement>
      </div>
    </div>
    {datePickerFunc(t, serverErrors)}
  </div>
);
const addRow5 = (t, serverErrors) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formControl} datePickerControl`}>
        <FasatFormElement direction="bottom" id="adeDedctActForm" name="adeDedctActForm">
          <FasatButtonSelector
            values={[{ label: t(staticCommonLabelKeys.COMMON_BUTTON_YES), value: 1 },
              { label: t(staticCommonLabelKeys.COMMON_BUTTON_NO), value: 0 }]}
            onHandleChange={(value) => formik.setFieldValue('adeDedctAct', Number(value))}
            id="adeDedctAct"
            label={t(staticCommonLabelKeys.COMMON_LABEL_ACTIVE)}
            name="adeDedctAct"
            {...formik.getFieldProps('adeDedctAct')}
            value={formik.values.adeDedctAct}
          />
        </FasatFormElement>
      </div>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adeAmtForm"
        name="adeAmtForm"
        errors={getErrorsPage('adeAmt', formik, serverErrors, formRuleIDs.adeAmt, formRuleConfig, false, t)}
      >
        <FasatCurrencyInput
          id="adeAmti"
          name="adeAmt"
          adornmentEnabled
          placeholder="0.00"
          maxLength="13"
          decimalPlaces={decimalPlaces}
          label={t(staticCommonLabelKeys.COMMON_LABEL_AMOUNT)}
          ariaRequired
          {...formik.getFieldProps('adeAmt')}
          handleOnBlur={(val) => formik.setFieldValue('adeAmt', val)}
          errors={getErrorsPage('adeAmt', formik, serverErrors, formRuleIDs.adeAmt, formRuleConfig, true, t)}
          disabled={formik.values.adeAmtCalcMethod === 'P' || formik.values.adeAmtCalcMethod === 'N'}

        />
      </FasatFormElement>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formControl} datePickerControl`}>
        <FasatFormElement
          direction="bottom"
          id="adeTargetAmtForm"
          errors={getErrorsPage('adeTargetAmt', formik, serverErrors,
            formRuleIDs.adeTargetAmt, formRuleConfig, false, t)}
        >
          <FasatCurrencyInput
            id="adeTargetAmt"
            name="adeTargetAmt"
            adornmentEnabled
            placeholder="0.00"
            decimalPlaces={decimalPlaces}
            maxLength="13"
            label={t(staticLabelKeys.CM_DE_LABLE_TARGET)}
            ariaRequired
            {...formik.getFieldProps('adeTargetAmt')}
            handleOnBlur={(val) => formik.setFieldValue('adeTargetAmt', val || null)}
            errors={getErrorsPage('adeTargetAmt', formik, serverErrors,
              formRuleIDs.adeTargetAmt, formRuleConfig, true, t)}
          />
        </FasatFormElement>
      </div>
    </div>
  </div>
);
const addrow7 = (t, onChangeData, serverErrors, costcenter) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formControl} datePickerControl`}>
        <FasatFormElement direction="bottom" id="adeCostCenterForm">
          <FasatTextInput
            id="adeCostCenter"
            name="adeCostCenter"
            label={t(staticLabelKeys.CM_DE_COSTCENTER)}
            ariaRequired
            maxLength={10}
            {...formik.getFieldProps('adeCostCenter')}
            disabled={costcenter !== 1}
          />
        </FasatFormElement>
      </div>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formControl} datePickerControl`}>
        <FasatFormElement
          direction="bottom"
          id="adeTransAgtCdForm"
          errors={getErrorsPage('adeTransAgtCd', formik, serverErrors,
            formRuleIDs.adeTransAgtCd, formRuleConfig, false, t)}
        >
          <FasatTextInput
            id="adeTransAgtCd"
            ariaRequired
            name="adeTransAgtCd"
            label={t(staticCommonLabelKeys.COMMON_LABEL_MSC_TRANSFER)}
            value={formik.values.adeTransAgtCd}
            onBlurEnabled
            disabled={!formik.values.flagTransfer}
            {...formik.getFieldProps('adeTransAgtCd')}
            onBlur={(e) => onChangeData(e, 'adeTransAgtCd')}
            maxLength={10}
            errors={getErrorsPage('adeTransAgtCd', formik, serverErrors, formRuleIDs.adeTransAgtCd,
              formRuleConfig, true, t)}
          />
        </FasatFormElement>
      </div>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatLabel>{t(staticCommonLabelKeys.COMMON_LABEL_TRANS_ADVISOR_NAME)}</FasatLabel>
      <FasatLabel isContent>{formik.values.adeTransAgtName}</FasatLabel>
    </div>
  </div>
);
const addrow9 = (t, serverErrors) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formElement} datePickerControl`}>
        <FasatFormElement
          direction="bottom"
          id="adePctOfFyrCommForm"
          name="adePctOfFyrCommForm"
          errors={getErrorsPage('adePctOfFyrComm', formik, serverErrors,
            formRuleIDs.adePctOfFyrComm, formRuleConfig, false, t)}
        >
          <FasatTextInput
            id="adePctOfFyrComm"
            ariaRequired
            name="adePctOfFyrComm"
            label={t(staticLabelKeys.CM_DE_PERFRSTYEARCOMM)}
            onInputEnabled
            onBlurEnabled
            isParcent
            decimalPlaces={decimalPlaces}
            beforeDc={three}
            {...formik.getFieldProps('adePctOfFyrComm')}
            errors={getErrorsPage('adePctOfFyrComm', formik, serverErrors,
              formRuleIDs.adePctOfFyrComm, formRuleConfig, true, t)}
            disabled={findDisableRenewal()}
          />
        </FasatFormElement>
      </div>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adePctOfRenCommingForm"
        name="adePctOfRenCommingForm"
        errors={getErrorsPage('adePctOfRenComming', formik, serverErrors,
          formRuleIDs.adePctOfRenComming, formRuleConfig, false, t)}
      >
        <FasatTextInput
          id="adePctOfRenComm"
          ariaRequired
          name="adePctOfRenComm"
          label={t(staticLabelKeys.CM_DE_PERRENCOMM)}
          onInputEnabled
          onBlurEnabled
          beforeDc={three}
          isParcent
          decimalPlaces={decimalPlaces}
          {...formik.getFieldProps('adePctOfRenComm')}
          errors={getErrorsPage('adePctOfRenComm', formik, serverErrors,
            formRuleIDs.adePctOfRenComm, formRuleConfig, true, t)}
          disabled={findDisableRenewal()}
        />
      </FasatFormElement>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formElement} datePickerControl`}>
        <FasatFormElement
          direction="bottom"
          id="adePctOfFyrOvrdeingForm"
          name="adePctOfFyrOvrdeingForm"
          errors={getErrorsPage('adePctOfFyrOvrde', formik, serverErrors,
            formRuleIDs.adePctOfFyrOvrde, formRuleConfig, false, t)}
        >
          <FasatTextInput
            id="adePctOfFyrOvrde"
            ariaRequired
            name="adePctOfFyrOvrde"
            label={t(staticLabelKeys.CM_DE_PERFRSTYEAROVERRIDE)}
            onInputEnabled
            onBlurEnabled
            beforeDc={three}
            isParcent
            decimalPlaces={decimalPlaces}
            {...formik.getFieldProps('adePctOfFyrOvrde')}
            errors={getErrorsPage('adePctOfFyrOvrde', formik, serverErrors,
              formRuleIDs.adePctOfFyrOvrde, formRuleConfig, true, t)}
            disabled={findDisableRenewal()}
          />
        </FasatFormElement>
      </div>
    </div>
  </div>
);
const addrow10 = (t, serverErrors) => (
  <div className={formRow}>
    <div className={`${formElement} ${commonFE}`}>
      <div className={`${formElement} datePickerControl`}>
        <FasatFormElement
          direction="bottom"
          id="adePctOfRenOvrdeForm"
          name="adePctOfRenOvrdeForm"
          errors={getErrorsPage('adePctOfRenOvrde', formik, serverErrors,
            formRuleIDs.adePctOfRenOvrde, formRuleConfig, false, t)}
        >
          <FasatTextInput
            id="adePctOfRenOvrde"
            ariaRequired
            name="adePctOfRenOvrde"
            label={t(staticLabelKeys.CM_DE_PERRENOVERRIDE)}
            onInputEnabled
            onBlurEnabled
            isParcent
            beforeDc={three}
            decimalPlaces={decimalPlaces}
            {...formik.getFieldProps('adePctOfRenOvrde')}
            errors={getErrorsPage('adePctOfRenOvrde', formik, serverErrors,
              formRuleIDs.adePctOfRenOvrde, formRuleConfig, true, t)}
            disabled={findDisableRenewal()}
          />
        </FasatFormElement>
      </div>
    </div>
    <div className={`${formElement} ${commonFE}`}>
      <FasatFormElement
        direction="bottom"
        id="adePctOfBalForm"
        name="adePctOfBalForm"
        errors={getErrorsPage('adePctOfBal', formik, serverErrors, formRuleIDs.adePctOfBal, formRuleConfig, false, t)}
      >
        <FasatTextInput
          id="adePctOfBal"
          ariaRequired
          name="adePctOfBal"
          label={t(staticLabelKeys.CM_DE_PERBALANCE)}
          onInputEnabled
          onBlurEnabled
          isParcent
          decimalPlaces={decimalPlaces}
          beforeDc={three}
          {...formik.getFieldProps('adePctOfBal')}
          errors={getErrorsPage('adePctOfBal', formik, serverErrors, formRuleIDs.adePctOfBal, formRuleConfig, true, t)}
          disabled={findDisableRenewal()}
        />
      </FasatFormElement>
    </div>
  </div>
);
const addrow11 = (t, serverErrors) => (
  <div className={formRow}>
    <div className={`${formElement} twoColumnRow`}>
      <FasatFormElement
        direction="bottom"
        id="adeMemoForm"
        errors={getErrorsPage('adeMemo', formik, serverErrors, formRuleIDs.adeMemo, formRuleConfig, false, t)}
      >
        <FasatLabel
          optionalTag
          optionalText={t(staticCommonLabelKeys.COMMON_LABEL_OPTIONAL)}
          htmlfor="adeMemo-input"
        >
          {t(staticLabelKeys.CM_DE_LABEL_CHQMEMOREMARK)}
        </FasatLabel>
        <FasatTextInput
          id="adeMemo"
          name="adeMemo"
          ariaLabel={t(staticLabelKeys.CM_DE_LABEL_CHQMEMOREMARK) + t(staticCommonLabelKeys.COMMON_LABEL_OPTIONAL)}
          ariaRequired
          maxLength={50}
          {...formik.getFieldProps('adeMemo')}
          errors={getErrorsPage('adeMemo', formik, serverErrors, formRuleIDs.adeMemo, formRuleConfig, true, t)}
          disabled={!formik.values.adeType}
        />
      </FasatFormElement>
    </div>
  </div>
);
const clientCntryConf = ({ contWithError, t, dispatch, clientId, locale, addNewBCR,
  setContWithError, msg, clientLevel, setValidateOnChange }) => (
    <FasatModal
      display={contWithError}
      modalWidth="560px"
      primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_NO)}
      primaryTopic="ON_SAVE_STAY"
      secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_YES)}
      secondaryTopic="ON_CANCEL_LEAVE"
      openModal="OPEN_ADD_GET_MODAL"
      modalCosed={() => setContWithError(false)}
      staydiscard
      onClickDiscard={async () => {
        setContWithError(false);
        handleSaveClick({ dispatch, clientId, locale, addNewBCR, contWithError, clientLevel, setValidateOnChange });
      }}
      modalClass="cancelPopup modalWithoutHeading"
    >
      <FasatConfirmationMsg msg={msg?.map((errItem) => fetchServerError(t, errItem))} />
    </FasatModal>
);
const showPopup = (popup, t, setPopup) => (
  <FasatModal
    display={popup}
    modalWidth="560px"
    secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_YES)}
    secondaryTopic="ON_CANCEL_LEAVE"
    openModal="OPEN_ADD_GET_MODAL"
    modalCosed={() => setPopup(false)}
    staydiscard
    modalClass="cancelPopup modalWithoutHeading"
  >
    {t(staticLabelKeys.COMPENS015)}
  </FasatModal>
);
export const getValidationFn = ({ t }) => addValidationSchema(t);
export const calcMethodCheck = (adeAmtCalcMethod) => {
  if (adeAmtCalcMethod) {
    return adeAmtCalcMethod;
  }
  return null;
};
export const handleAdvisorCodeChange = (e, dispatch, val, clientId, clientLevel) => {
  if (val === 'adeTransAgtCd') {
    formik.setFieldValue('adeTransAgtCd', e.target.value);
    dispatch(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_NAME, {
      transAgtCd: e.target.value,
      agentCd: clientId,
      level: clientLevel.split('-')[0].trim(),
      calcMethod: calcMethodCheck(formik.values.adeAmtCalcMethod),
    }));
  }
};
const onFreqChange = () => {
  if (formik.values.adeFreq !== 'DY') {
    formik.setFieldValue('adeDays', null);
  }
};
const transNameLang = (transferName, t) => {
  if (transferName === 'DE_INVALID_ADVISOR') {
    return t(staticLabelKeys.DE_INVALID_ADVISOR);
  }
  return transferName;
};
const transferNameFunc = (transferName, isDirty, t) => {
  if (transferName && isDirty) {
    formik.setFieldValue('adeTransAgtName', transNameLang(transferName, t));
  } else {
    formik.setFieldValue('adeTransAgtName', '');
  }
};
const endDateChaneFunc = (activeStatus) => {
  if (formik.values.adeEndDt && activeStatus === false && formik.values.adeDedctAct === 1) {
    formik.setFieldValue('adeDedctAct', 0);
  }
};
const monthlyTargetFunc = () => {
  if (formik.values.AdeMonthlyTargetSw === 0) {
    formik.setFieldValue('adeMonthlyTargetAmt', null);
  }
};
const percentChangeUseEffect = () => {
  if (formik.values.adeAmtCalcMethod === 'M' || formik.values.adeAmtCalcMethod === 'S') {
    formik.setFieldValue('adePctOfBal', null);
  } else {
    formik.setFieldValue('adePctOfBal', formatDecimal(formik.values.adePercent));
  }
};
const AddEditDeductionStructure = ({ resetAddEdit, formData, setClientAdddEditActBtn }) => {
  const location = useLocation();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const locale = getCurrentLocale();
  const isDirty = useSelector((state) => state.ApplicationState.isDirty);
  const transactionData = useSelector((state) => state.FieldForceFilterList.ddTranDetails);
  const upsertData = useSelector((state) => state.ClientTabs.upsertData);
  const upsertRequestError = useSelector((state) => state?.FieldForce?.upsertRequestError);
  const transactionType = useSelector((state) => state?.FieldForce?.transaction);
  const lookupData = useSelector((state) => state.ApplicationState.lookupData);
  const activeStatus = useSelector((state) => state.FieldForce.activeStatus);
  const transferName = useSelector((state) => state?.FieldForce?.transferName);
  const clientId = location.state !== undefined && location.state.deductionOrgCode;
  const clientLevel = location.state !== undefined && location.state.orgLevel;
  const addNewBCR = !location.state.action;
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [canceldisplayModal, setCancelDisplayModal] = useState(false);
  const [saveEventData, setSaveEventData] = useState(false);
  const [transactonOptions, settransactonOptions] = useState([]);
  const [methodOptions, setMethodOptions] = useState([]);
  const [freequencyOptions, setFreequencyOptions] = useState([]);
  const [CurrencyOptions, setCurrencyOptions] = useState([]);
  const [contWithError, setContWithError] = useState(false);
  const [msg, setMsg] = useState([]);
  const [flag, setFlag] = useState(false);
  const [popup, setPopup] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const { successAddMsg, successEditMsg } = getTranslatedLabels(t);
  const history = useHistory();
  formik = useFormik({
    validateOnChange,
    validateOnBlur: validateOnChange,
    initialValues: location.state.formData,
    validationSchema: getValidationFn({ t }),
    enableReinitialize: true,
  });
  setRule(addNewBCR);
  useEffect(() => percentChangeUseEffect(), [formik.values?.adePercent]);
  useEffect(() => dispatch(getAction(fieldForceActionTypes.GET_FF_TRANSACTION_LIST,
    { orgCode: clientId, orgLevel: clientLevel?.split('-')[0].trim() })), []);
  const callTransaction = () => settransactonOptions(transactionType);
  useEffect(() => monthlyTargetFunc(), [formik.values?.AdeMonthlyTargetSw]);
  useEffect(() => callTransaction(), [transactionType]);
  useEffect(() => endDateChaneFunc(activeStatus), [formik.values.adeEndDt, activeStatus]);
  useEffect(() => tranDetailsSet(dispatch, formik), [formik.values.adeAmtCalcMethod]);
  useEffect(() => onFreqChange(), [formik.values.adeFreq]);
  useEffect(() => transferNameFunc(transferName, isDirty, t), [transferName]);
  useEffect(() => transactionFormik(transactionData, setPopup, formik), [transactionData]);
  useEffect(() => getLookUps(lookupData, setFreequencyOptions, setCurrencyOptions, setMethodOptions), [lookupData]);
  useEffect(() => {
    setServerErrors([]);
    dispatch(getAction(fieldForceActionTypes.RESET_FF_DEDUCTION_BY_ID_NAME));
  }, [locale]);
  useEffect(() => {
    const paramDis = { upsertData, dispatch, addNewBCR, successAddMsg, successEditMsg, setServerErrors };
    dispatchUpsert({ ...paramDis, upsertRequestError, setClientAdddEditActBtn, setContWithError, setMsg, formik, history, location });
  }, [upsertRequestError, upsertData]);
  useEffect(() => {
    formik.resetForm(formData);
    dispatch(lookUpsDataProcessing(getReqPayLoad(locale)));
  }, [locale]);
  useEffect(() => dispatch(checkDirtyData(formik.dirty)), [formik.dirty]);
  useEffect(() => getSuccessMsg({ upsertData, saveEventData, setSaveEventData, resetAddEdit, t, dispatch, formik }), [upsertData]);
  useEffect(() => onDateChange(dispatch, formik), [formik.values.adeEndDt]);
  const onChangeData = useAsyncDebounce((changeValue, code) => {
    handleAdvisorCodeChange(changeValue, dispatch, code, clientId, clientLevel);
  }, debouncingTimeout);
  useEffect(() => pathChange(flag, history, location), [canceldisplayModal]);
  const onClickCancel = () => dirtyCheckCancel(isDirty, setCancelDisplayModal, history, location);
  return (
    <FasatPage onClickBack={() => onClickCancel()} title={location.state.formData.adeType.length === 0 ? t(staticLabelKeys.CM_DE_TITLE_ADD) : t(staticLabelKeys.CM_DE_TITLE_EDIT)}>
      {clientCntryConf({ contWithError, t, dispatch, clientId, locale, addNewBCR, setContWithError, msg, clientLevel, setValidateOnChange })}
      {showPopup(popup, t, setPopup)}
      {showServerMessage(serverErrors, t)}
      <form
        id="addEditDeductions"
        onSubmit={(e) => {
          e.preventDefault();
          handleSaveClick({ dispatch, clientId, locale, addNewBCR, contWithError, clientLevel, setValidateOnChange });
        }}
        className="addEditDeductions"
      >
        {addRow1(t, dispatch, transactonOptions, locale, CurrencyOptions, serverErrors, clientId)}
        {addRowAuto(t, formik)}
        {addRow2(t, methodOptions, freequencyOptions, serverErrors)}
        {addRow3(t, serverErrors)}
        {addRow5(t, serverErrors)}
        {addRow4(t, serverErrors)}
        {addRow6(t, serverErrors)}
        {addrow7(t, onChangeData, serverErrors, transactionData?.data?.result.costCenter)}
        {addrow11(t, serverErrors)}
        {addrow9(t, serverErrors)}
        {addrow10(t)}
        {footerFunc(t, onClickCancel, isDirty)}
        {clientAddCancelCR({ canceldisplayModal, setCancelDisplayModal, t, dispatch, resetAddEdit, setClientAdddEditActBtn, setFlag, formik })}
      </form>
    </FasatPage>
  );
};
export default AddEditDeductionStructure;
AddEditDeductionStructure.propTypes = {
  resetAddEdit: PropTypes.func,
  setClientAdddEditActBtn: PropTypes.func,
  formData: PropTypes.shape({ adeType: PropTypes.string }),
};
AddEditDeductionStructure.defaultProps = {
  resetAddEdit: () => undefined,
  setClientAdddEditActBtn: () => undefined,
  formData: { adeType: '',
    adeEffDt: null },
};
