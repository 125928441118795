/* eslint-disable import/named */
/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Jayanta Sarkhel - sarkhja
 * @CreatedOn 21 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { csActionTypes, csDetailActionTypes } from '../actions/actionTypes';

const csCardRuleHelper = (state, action) => {
  switch (action.type) {
    case csDetailActionTypes.GET_CS_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case csDetailActionTypes.SET_CS_DATE_CONFLICT_RECORDS:
      return {
        ...state,
        csDateConflictRecords: action.data,
      };
    case csDetailActionTypes.RESET_CS_DETAIL_STORE:
      return {
        ...state,
        serverRequestError: null,
        nextagentName: null,
        agentName: null,
        loading: false,
        csDateConflictRecords: null,
      };
    case csDetailActionTypes.RESET_CS_DATE_CONFLICT_RECORDS:
      return {
        ...state,
        csDateConflictRecords: null,
      };
    case csDetailActionTypes.RESET_CS_HEADER_DATA:
      return {
        ...state,
        headerData: null,
      };
    case csDetailActionTypes.GET_CS_HEADER_DETAIL_RESPONSE:
      return {
        ...state,
        headerData: action.data,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

const CsCardRule = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case csActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case csActionTypes.SET_CS_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case csActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        serverRequestError: action.data.upsertRequestError,
        loading: false,
      };
    case csActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case csActionTypes.RESET_DELETE_ERROR:
      return {
        ...state,
        deleteRequestError: null,
      };
    case csActionTypes.UPSERT_CS_REQUEST:
      return {
        ...state,
        upsertRequestedOn: new Date(),
        upsertData: action.data.upsertData,
        loading: true,
      };
    case csDetailActionTypes.GET_CS_DETAIL_RESPONSE:
      return {
        ...state,
        detailData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case csDetailActionTypes.GET_CS_AGENT_NAME_RESPONSE:
      return {
        ...state,
        agentName: action.data,
      };
    case csDetailActionTypes.GET_NEXT_CS_AGENT_NAME_RESPONSE:
      return {
        ...state,
        nextagentName: action.data,
      };
    default:
      return csCardRuleHelper(state, action);
  }
};

export default CsCardRule;
