import { takeEvery, put, call, all } from 'redux-saga/effects';
import { manageGroupsActionType, paymentThresholdActionType } from '../actions/actionTypes';

import { formatResponse, processError, Logger } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';

const getResetUpsertValue = (responseData, err) => ({
  upsertRequest: null,
  upsertRequestError: err ? processError(err) : null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: responseData ? new Date() : null,
  upsertData: responseData,
});

// security setup
export function* getMgList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, null)),
    put(getAction(manageGroupsActionType.LOG_MG_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const lang = action.data === 'en' ? 'E' : 'F';
    const manageGroupsInitURL = moduleAPI.ADMINISTRATION_MG_LIST.endpoint;
    const manageGroupsUpdateURL = moduleAPI.ADMINISTRATION_MG_LIST.endpoint + lang;
    moduleAPI.ADMINISTRATION_MG_LIST.endpoint = manageGroupsUpdateURL;
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MG_LIST, lang);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_MG_LIST.endpoint = manageGroupsInitURL;
    yield all([
      put(getAction(manageGroupsActionType.GET_MG_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(manageGroupsActionType.GET_MG_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getSgList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, null)),
    put(getAction(manageGroupsActionType.LOG_SG_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_SECURITY_GROUPS, action.data);
    yield all([
      put(getAction(manageGroupsActionType.GET_SG_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(manageGroupsActionType.GET_SG_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
export function* updateSgList(action) {
  yield all([put(processingStarted()), put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, null))]);
  let resetUpsertValue = null;
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_SG_UPDATE, action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
      };
    }
    yield all([
      put(getAction(manageGroupsActionType.RESET_REQ_PROSS_ON, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageGroupsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* concurrencyCheck(action) {
  const reqAppObjRef = {
    resourceIdMenu: -1,
    groupName: action.data.sgrName,
    language: 'E',
    isScreen: true,
    groupOrLevel: action?.data.location === 'manageLevel' ? 'L' : 'G',
  };
  yield all([put(processingStarted()), put(getAction(manageGroupsActionType.RESET_CONCURRENCY_FLAG, false))]);
  try {
    yield call(invokeApi, moduleAPI.ADMINISTRATION_SECURITY_GROUPS, reqAppObjRef);
    yield all([
      put(getAction(manageGroupsActionType.RESET_CONCURRENCY_FLAG, action.data)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(paymentThresholdActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(manageGroupsActionType.RESET_CONCURRENCY_FLAG, false)),
      put(processingCompleted()),
    ]);
  }
}
export function* updatefieldforce(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.SECURITY_PAYMENTSEC_UPDATE, action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(manageGroupsActionType.PAYMENT_UPSERT_DATA, responseData));
      yield all([
        put(getAction(manageGroupsActionType.RESET_SECURITY_PAYMENT_INFO, responseData)),
        put(processingCompleted())]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      // upsertRequestError: 'processError(err, action.data.genErrMsg)',
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getPaymentInfo(action) {
  yield all([put(processingStarted()), put(getAction(manageGroupsActionType.RESET_SECURITY_PAYMENT_INFO, null))]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.SECURITY_PAYMENT_CONFIG_INFO, action.data);
    yield all([
      put(getAction(manageGroupsActionType.RESET_SECURITY_PAYMENT_INFO, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageGroupsActionType.RESET_SECURITY_PAYMENT_INFO, null)),
      put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getMngGrpDesc(action) {
  yield all([put(processingStarted())]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_GRP_DESC, action.data);
    yield all([
      put(getAction(manageGroupsActionType.SET_MANAGE_GRP_DESC_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
// Manage Application
export function* getMAList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, null)),
    put(getAction(manageGroupsActionType.LOG_MA_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MA_LIST, action.data);
    yield all([put(getAction(manageGroupsActionType.GET_MA_RESPONSE, responseData)), put(processingCompleted())]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(manageGroupsActionType.GET_MA_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* updateMA(action) {
  yield all([put(processingStarted()), put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, null))]);
  let resetUpsertValue = null;
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MA_UPDATE, action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
      };
    }
    yield all([
      put(getAction(manageGroupsActionType.UPDATE_MA_RESPONSE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageGroupsActionType.UPDATE_MA_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
// Manage Group
export function* mngGrpInsert(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_GRP_INSERT, action.data.formatObject);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(manageGroupsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(manageGroupsActionType.GET_MG_LIST_REQUEST, action.data.locale)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(manageGroupsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
        * This generator function will invoke the api
        * @param {object} action - action which will get by id for manage group code
        */
export function* getMngGroupbyID(action) {
  Logger.verbose(`Action Detail For Get ADMINISTRATION_MNG_GRP_BY_ID by id : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, null)),
    put(getAction(manageGroupsActionType.LOG_MNG_GRP_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_GRP_BY_ID, action.data);
    yield all([
      put(getAction(manageGroupsActionType.GET_MNG_GRP_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(manageGroupsActionType.GET_MNG_GRP_BY_ID_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

// update security group description
export function* updateMngGrp(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_GRP_UPDATE, action.data.formatObjectEdit);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(manageGroupsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(manageGroupsActionType.GET_MG_LIST_REQUEST, action.data.locale)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(manageGroupsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
        * This generator function will invoke the api
        * @param {object} action - action which will delete by sgrName for manage group code
        */
export function* deleteMngGrpBySGRName(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_GRP_DELETE, action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
    }
    yield all([
      put(getAction(manageGroupsActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(manageGroupsActionType.GET_MG_LIST_REQUEST, action.data.locale)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(manageGroupsActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* checkRelationalTableForMngGrp(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = {
    relationalTable: action.data,
    relationalTableError: null,
  };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_MNG_GRP_USER_PRESENT_RELATION,
      action.data);
    resetServerValue = {
      relationalTable: responseData.isBelongingUserExists,
    };
    yield all([
      put(getAction(manageGroupsActionType.GET_MNG_GRP_USER_EXSISTS_RESPONSE, resetServerValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = {
      relationalTableError: processError(err),
    };
    yield all([
      put(getAction(manageGroupsActionType.GET_MNG_GRP_USER_EXSISTS_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
function* moduleWatcher() {
// security setup
  yield takeEvery(manageGroupsActionType.GET_MG_LIST_REQUEST, getMgList);
  yield takeEvery(manageGroupsActionType.GET_SG_LIST_REQUEST, getSgList);
  yield takeEvery(manageGroupsActionType.UPDATE_SG_REQUEST, updateSgList);
  yield takeEvery(manageGroupsActionType.CONCURRENCY_CHK, concurrencyCheck);
  yield takeEvery(manageGroupsActionType.GET_PAYMENT_INFO, getPaymentInfo);
  yield takeEvery(manageGroupsActionType.UPDATE_FIELD_FORCE_SETTINGS, updatefieldforce);
  yield takeEvery(manageGroupsActionType.GET_MANAGE_GRP_DESC, getMngGrpDesc);
  // Manage Aapplication
  yield takeEvery(manageGroupsActionType.GET_MA_LIST_REQUEST, getMAList);
  yield takeEvery(manageGroupsActionType.UPDATE_MA_REQUEST, updateMA);
  // Manage Group
  yield takeEvery(manageGroupsActionType.INSERT_MNG_GRP_REQUEST, mngGrpInsert);
  yield takeEvery(manageGroupsActionType.GET_MNG_GRP_BY_ID_REQUEST, getMngGroupbyID);
  yield takeEvery(manageGroupsActionType.UPDATE_MNG_GRP_REQUEST, updateMngGrp);
  yield takeEvery(manageGroupsActionType.DELETE_SECURITY_GROUP_REQUEST, deleteMngGrpBySGRName);
  yield takeEvery(manageGroupsActionType.GET_MNG_GRP_USER_RELATIONAL_TABLE, checkRelationalTableForMngGrp);
}
export default moduleWatcher;
