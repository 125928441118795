/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { transactionDefinitionActionType } from '../actions/actionTypes';
import { exportErrorMsg, formatResponse, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted, setLock,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

// Transaction Definition
export function* getTDList(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.RESET_SERVER_VALUE, null)),
    put(getAction(transactionDefinitionActionType.LOG_TD_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_TD_LIST, action.data);
    yield all([
      put(getAction(transactionDefinitionActionType.GET_TD_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(transactionDefinitionActionType.GET_TD_LIST_ERROR, err)),
      put(getAction(transactionDefinitionActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for TD Detail Listing
 */
export function* getTDDetailList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.LOG_TD_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TD_DETAIL_LIST,
      action.data);
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_TD_DETAIL_ERROR)),
      put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getTDItmDetailList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.LOG_TD_DETAIL_REQUEST)),
    put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_TD_ITM_DETAIL_LIST, action.data);
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_TD_DETAIL_ERROR)),
      put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_RESPONSE,
        formatResponse({ action, responseData, listToDetail: true }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* validateTDItmEdit(action) {
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.LOG_TD_DETAIL_REQUEST)),
    put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_ERROR, null)),
    put(getAction(transactionDefinitionActionType.RESET_EDIT_STATUS, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_TD_ITM_DETAIL_LIST, action.data);
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_TD_DETAIL_ERROR)),
      put(getAction(transactionDefinitionActionType.RESET_EDIT_STATUS, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* updateTd(action) {
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.RESET_SERVER_VALUE, null)),
  ]);
  try {
    let responseData;
    if (action.data.addNewTD) {
      responseData = yield call(invokeApi,
        moduleAPI.ADMINISTRATION_TD_ADD,
        action.data.formatObject);
    } else {
      responseData = yield call(invokeApi,
        moduleAPI.ADMINISTRATION_TD_EDIT,
        action.data.formatObject);
    }
    const resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([put(getAction(transactionDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deleteTd(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_TD_DELETE,
      action.data.formatObject,
    );
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([put(getAction(transactionDefinitionActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(transactionDefinitionActionType.GET_TD_ASSOCTRANS_DETAIL_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const errObj = processError(err);
    if ((errObj[0].errorMessageId === 'TRANSD009')) {
      resetUpsertValue = {
        serverRequestError: processError(err),
      };
      yield put(getAction(transactionDefinitionActionType.RESET_SERVER_VALUE, resetUpsertValue));
    } else {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: processError(err),
        deleteRequestedOn: new Date(),
        deleteRequestProcessedOn: null,
        deleteData: null,
      };
      yield put(getAction(transactionDefinitionActionType.RESET_DELETE_VALUE, resetDeleteValue));
    }

    yield all([
      put(processingCompleted()),
    ]);
  }
}

export function* getTDSecurityDetailList(action) {
  Logger.verbose(`Action Detail For Get GERV description : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.LOG_TD_DETAIL_REQUEST)),
  ]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_TD_SECURITY_DETAIL_LIST.endpoint;
  try {
    const langData = `/${action.data.lang}`;
    const transIdData = `${action.data.id}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_TD_SECURITY_DETAIL_LIST.endpoint + transIdData + langData;
    moduleAPI.ADMINISTRATION_TD_SECURITY_DETAIL_LIST.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_TD_SECURITY_DETAIL_LIST);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_TD_SECURITY_DETAIL_LIST.endpoint = initApiUrl;
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_TD_DETAIL_ERROR)),
      put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_TD_SECURITY_DETAIL_LIST.endpoint = initApiUrl;
    yield all([put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getTDSecurityItmDetailList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.LOG_TD_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TD_SECURITY_ITM_DETAIL_LIST,
      action.data);
    const formatData = formatResponse({ action, responseData });
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_TD_DETAIL_ERROR)),
      put(setLock({ lockCombo: formatData.comboLock, lockedBy: formatData.comboLock.lockedBy || '' })),
      put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_RESPONSE, formatData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* updateTdSecDel(action) {
  Logger.verbose(`Action Detail For Get Sys Param : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_TD_SECURITY_DETAIL_UPDATE,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getTDAssocTransDetailList(action) {
  Logger.verbose(`Action Detail For Get GERV description : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.LOG_TD_DETAIL_REQUEST)),
  ]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_DETAIL_LIST.endpoint;
  try {
    const langData = `/${action.data.lang}`;
    const transIdData = `${action.data.id}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_DETAIL_LIST.endpoint + transIdData + langData;
    moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_DETAIL_LIST.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_DETAIL_LIST);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_DETAIL_LIST.endpoint = initApiUrl;
    const listData = { data: responseData };
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_TD_DETAIL_ERROR)),
      put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_RESPONSE, listData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_DETAIL_LIST.endpoint = initApiUrl;
    yield all([put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getTDAssocTransItmDetailList(action) {
  Logger.verbose(`Action Detail For Get TD List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.LOG_TD_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_ITM_DETAIL_LIST,
      action.data);
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_TD_DETAIL_ERROR)),
      put(getAction(transactionDefinitionActionType.SET_ASSO_TD_DETAIL, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(transactionDefinitionActionType.GET_TD_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getLookUpsDataTd(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.TD_LOOKUPS_DATA, action.data);
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_TD_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_TD_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}

// TD Assoc Transaction ADD
export function* insertTdAssocTrans(action) {
  Logger.verbose(`Action Detail For Get Sys Param : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_INSERT,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(transactionDefinitionActionType.GET_TD_ASSOCTRANS_DETAIL_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

// TD Assoc Transaction EDIT
export function* updateTdAssocTrans(action) {
  Logger.verbose(`Action Detail For Get Sys Param : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_UPDATE,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(transactionDefinitionActionType.GET_TD_ASSOCTRANS_DETAIL_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deleteTdAssocTrans(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_TD_ASSOCTRANS_DELETE,
      action.data.formatObject,
    );
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([put(getAction(transactionDefinitionActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(transactionDefinitionActionType.GET_TD_ASSOCTRANS_DETAIL_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      // upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for TD Export Listing
 */
export function* getTdExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(transactionDefinitionActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_TD_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(transactionDefinitionActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
function* moduleWatcher() {
  // Transaction Definition
  yield takeEvery(transactionDefinitionActionType.GET_TD_LIST_REQUEST, getTDList);
  yield takeEvery(transactionDefinitionActionType.GET_TD_DETAIL_LIST_REQUEST, getTDDetailList);
  yield takeEvery(transactionDefinitionActionType.GET_TD_ITM_DETAIL_LIST_REQUEST, getTDItmDetailList);
  yield takeEvery(transactionDefinitionActionType.VALIDATE_TD_ITM_EDIT, validateTDItmEdit);
  yield takeEvery(transactionDefinitionActionType.UPDATE_TD_REQUEST, updateTd);
  yield takeEvery(transactionDefinitionActionType.DELETE_TD_REQUEST, deleteTd);
  yield takeEvery(transactionDefinitionActionType.GET_TD_SECURITY_DETAIL_LIST_REQUEST, getTDSecurityDetailList);
  yield takeEvery(transactionDefinitionActionType.GET_TD_SECURITY_ITM_DETAIL_LIST_REQUEST, getTDSecurityItmDetailList);
  yield takeEvery(transactionDefinitionActionType.UPDATE_TD_SECURITY_DETAIL_REQUEST, updateTdSecDel);
  yield takeEvery(transactionDefinitionActionType.GET_TD_ASSOCTRANS_DETAIL_LIST_REQUEST, getTDAssocTransDetailList);
  yield takeEvery(transactionDefinitionActionType.GET_TD_ASSOCTS_ITM_DETAIL_LIST_REQ, getTDAssocTransItmDetailList);
  yield takeEvery(transactionDefinitionActionType.INSERT_TD_ASSOCTRANS_REQUEST, insertTdAssocTrans);
  yield takeEvery(transactionDefinitionActionType.UPDATE_TD_ASSOCTRANS_REQUEST, updateTdAssocTrans);
  yield takeEvery(transactionDefinitionActionType.DELETE_TD_ASSOCTRANS_REQUEST, deleteTdAssocTrans);
  yield takeEvery(transactionDefinitionActionType.GET_TD_EXPORT_REQUEST, getTdExportList);
  yield takeEvery(transactionDefinitionActionType.GET_TD_LOOKUP_REQUEST, getLookUpsDataTd);
}
export default moduleWatcher;
