/* eslint-disable no-unused-vars */
/**
 * @Description This is FasatButton component page.
 * This is a wrapper on "CheckboxGroup" component from MUX without adding any custom functionlities
 * @FileName fasatCheckboxGroup.js
 * @Author UMA DUTTA-duttuma
 * @CreatedOn 29 January, 2021 6:00:00
 * @IssueID CDTFASATMOD- 502
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup } from '@manulife/mux';
import './fasatCheckboxGroup.scss';

const FasatCheckboxGroup = (props) => {
  const { selected, values, handleChange } = props;
  const [select, setSelect] = useState(null);
  const changeHandler = (val) => {
    handleChange(val);
    setSelect(val);
  };
  return (
    <div className="fasatCheckboxGroup">
      <CheckboxGroup
        {...props}
        values={values}
        selected={select || selected}
         // onHandleChange
         // onChange={(value) => setSelect(value)}
        onChange={changeHandler}
      />
    </div>
  );
};

FasatCheckboxGroup.propTypes = {
  handleChange: PropTypes.func,
  selected: PropTypes.shape({}),
  values: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

FasatCheckboxGroup.defaultProps = {
  selected: {},
  values: PropTypes.arrayOf(PropTypes.shape({
    label: '',
    value: '',
  })),
  handleChange: () => undefined,
};

export default FasatCheckboxGroup;
