/**
 * @Description File description.
 * @FileName moduleConstants.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 25 January, 2021 08:39:44
 * @IssueID - 311
 */
/**
   * All the route constants for the module
   */
const ROUTE_HOME = '/reports';
export const routeConstant = {
  REP_HOME_ROUTE: `${ROUTE_HOME}/home`,
  PAYMENT_PROCESS_ROUTE: `${ROUTE_HOME}/paymentProcess`,
};

/**
   * ACL keys for the module
   */
export const aclKeysConstant = {
  ADMIN_MENU: 'RBAC_ADMINISTRATION',
  RBAC_REPORTS: 'RBAC_REPORTS',
  RBAC_REP_PRODUCTION_SUMMARY: 'RBAC_REP_PRODUCTION_SUMMARY',
  RBAC_REP_BALANCE_SUMMARY: 'RBAC_REP_BALANCE_SUMMARY',
  RBAC_PP_MENU: 'RBAC_PP_MENU',
  RBAC_PP_EXPORT: 'RBAC_PP_EXPORT',
  RBAC_BLS_MENU: 'RBAC_BLS_MENU',
  RBAC_BLS_EXPORT: 'RBAC_BLS_EXPORT',
  PRODUCTION_SUMMARY_MENU: 'RBAC_PSU_MENU',
  BALANCE_SUMMARY_MENU: 'RBAC_BLS_MENU',
  REP_REPOSITORY_MENU: 'RBAC_BLS_MENU',
  RBAC_PAYMENTS: 'RBAC_PAYMENTS',
  RBAC_PP_APPROVE: 'RBAC_PP_APPROVE',
  RBAC_PP_REMITUNREMIT: 'RBAC_PP_REMIT/UNREMIT',
  RBAC_PP_CANCEL: 'RBAC_PP_CANCEL',
};

/**
   * i18n Keys for the module
   */
export const staticLabelKeys = {
  PP_TITLE_LIST: 'PP_TITLE_LIST',
  REPORT_MENU: 'RBAC_REPORTS',
  REP_PRODUCTION_SUMMARY: 'REP_PRODUCTION_SUMMARY',
  REP_BALANCE_SUMMARY: 'REP_BALANCE_SUMMARY',
  PRODSUM_PAGE_TITLE: 'PRODSUM_PAGE_TITLE',
  PRODSUM_LABEL_MSC: 'PRODSUM_LABEL_MSC',
  PRODSUM_LABEL_BFN: 'PRODSUM_LABEL_BFN',
  PRODSUM_LABEL_BLN: 'PRODSUM_LABEL_BLN',
  PRODSUM_LABEL_PAYEE: 'PRODSUM_LABEL_PAYEE',
  PRODSUM_LABEL_FFT: 'PRODSUM_LABEL_FFT',
  PRODSUM_LABEL_COMP: 'PRODSUM_LABEL_COMP',
  PRODSUM_LABEL_FFS: 'PRODSUM_LABEL_FFS',
  PRODSUM_LABEL_STATUSDT: 'PRODSUM_LABEL_STATUSDT',
  PRODSUM_LABEL_CONTRACTDT: 'PRODSUM_LABEL_CONTRACTDT',
  PRODSUM_LABEL_TERMINATIONDT: 'PRODSUM_LABEL_TERMINATIONDT',
  PRODSUM_LABEL_ACTIVEREC: 'PRODSUM_LABEL_ACTIVEREC',
  PRODSUM_LABEL_AFFILIATE: 'PRODSUM_LABEL_AFFILIATE',
  PRODSUM_LABEL_CORPORATION: 'PRODSUM_LABEL_CORPORATION',
  PRODSUM_LABEL_TEMPLEV: 'PRODSUM_LABEL_TEMPLEV',
  PRODSUM_LABEL_ALTERNATE: 'PRODSUM_LABEL_ALTERNATE',
  PRODSUM_LABEL_EFFECTIVEDT: 'PRODSUM_LABEL_EFFECTIVEDT',
  PRODSUM_LABEL_MOVEMENTDT: 'PRODSUM_LABEL_MOVEMENTDT',
  PRODSUM_LABEL_FYC_GROSS: 'PRODSUM_LABEL_FYC_GROSS',
  PRODSUM_LABEL_FYC_GROSS_YTD: 'PRODSUM_LABEL_FYC_GROSS_YTD',
  PRODSUM_LABEL_FYC: 'PRODSUM_LABEL_FYC',
  PRODSUM_LABEL_FYCYTD: 'PRODSUM_LABEL_FYCYTD',
  PRODSUM_LABEL_FYO: 'PRODSUM_LABEL_FYO',
  PRODSUM_LABEL_FYOYTD: 'PRODSUM_LABEL_FYOYTD',
  PRODSUM_LABEL_FYC_CHARGE: 'PRODSUM_LABEL_FYC_CHARGE',
  PRODSUM_LABEL_FYC_CHARGE_YTD: 'PRODSUM_LABEL_FYC_CHARGE_YTD',
  PRODSUM_LABEL_FYO_CHARGE: 'PRODSUM_LABEL_FYO_CHARGE',
  PRODSUM_LABEL_FYO_CHARGE_YTD: 'PRODSUM_LABEL_FYO_CHARGE_YTD',
  PRODSUM_LABEL_FYC_COMMISSION: 'PRODSUM_LABEL_FYC_COMMISSION',
  PRODSUM_LABEL_FYC_COMMISSION_YTD: 'PRODSUM_LABEL_FYC_COMMISSION_YTD',
  PRODSUM_LABEL_POLICY: 'PRODSUM_LABEL_POLICY',
  PRODSUM_LABEL_POLICY_YTD: 'PRODSUM_LABEL_POLICY_YTD',
  PRODSUM_LABEL_REN: 'PRODSUM_LABEL_REN',
  PRODSUM_LABEL_REN_YTD: 'PRODSUM_LABEL_REN_YTD',
  PRODSUM_LABEL_RENEWAL_COMM: 'PRODSUM_LABEL_RENEWAL_COMM',
  PRODSUM_LABEL_RENEWAL_COMM_YTD: 'PRODSUM_LABEL_RENEWAL_COMM_YTD',
  PRODSUM_LABEL_RENEWAL: 'PRODSUM_LABEL_RENEWAL',
  PRODSUM_LABEL_RENEWAL_YTD: 'PRODSUM_LABEL_RENEWAL_YTD',
  PRODSUM_LABEL_RENEWAL_PREM: 'PRODSUM_LABEL_RENEWAL_PREM',
  PRODSUM_LABEL_SALES: 'PRODSUM_LABEL_SALES',
  PRODSUM_LABEL_SALES_YTD: 'PRODSUM_LABEL_SALES_YTD',
  PRODSUM_LABEL_PERSISTENCY: 'PRODSUM_LABEL_PERSISTENCY',
  PRODSUM_LABEL_REFERRAL: 'PRODSUM_LABEL_REFERRAL',
  PRODSUM_LABEL_REFERRAL_YTD: 'PRODSUM_LABEL_REFERRAL_YTD',
  PRODSUM_LABEL_REFERRAL_GROSS: 'PRODSUM_LABEL_REFERRAL_GROSS',
  PRODSUM_LABEL_REFERRAL_GROSS_YTD: 'PRODSUM_LABEL_REFERRAL_GROSS_YTD',
  PRODSUM_LABEL_EQUALIZED: 'PRODSUM_LABEL_EQUALIZED',
  PRODSUM_LABEL_EQUALIZED_YTD: 'PRODSUM_LABEL_EQUALIZED_YTD',
  PRODSUM_LABEL_EQUALIZED_OVERRIDE: 'PRODSUM_LABEL_EQUALIZED_OVERRIDE',
  PRODSUM_LABEL_EQUALIZED_OVERRIDE_YTD: 'PRODSUM_LABEL_EQUALIZED_OVERRIDE_YTD',
  PRODSUM_LABEL_PERSISTENCY_LAPSES: 'PRODSUM_LABEL_PERSISTENCY_LAPSES',
  BLS_PAGE_TITLE: 'BLS_PAGE_TITLE',
  BLS_LABEL_COMP_SCH: 'BLS_LABEL_COMP_SCH',
  BLS_LABEL_FF_STATUS: 'BLS_LABEL_FF_STATUS',
  BLS_LABEL_TYPE: 'BLS_LABEL_TYPE',
  BLS_LABEL_BAL_FORWARD: 'BLS_LABEL_BAL_FORWARD',
  BLS_LABEL_CURR_BAL: 'BLS_LABEL_CURR_BAL',
  BLS_LABEL_WRITEOFF: 'BLS_LABEL_WRITEOFF',
  BLS_LABEL_WRITEOFF_OV: 'BLS_LABEL_WRITEOFF_OV',
  BLS_LABEL_WRITEOFF_AMT: 'BLS_LABEL_WRITEOFF_AMT',
  BLS_LABEL_WRITEOFF_AMT_OV: 'BLS_LABEL_WRITEOFF_AMT_OV',
  BLS_LABEL_RESCAP_ACCFW: 'BLS_LABEL_RESCAP_ACCFW',
  BLS_LABEL_RESCAP_ACCYTD: 'BLS_LABEL_RESCAP_ACCYTD',
  BLS_LABEL_ROLL_DEB_FW: 'BLS_LABEL_ROLL_DEB_FW',
  BLS_LABEL_ROLL_DEB_BAL: 'BLS_LABEL_ROLL_DEB_BAL',
  BLS_LABEL_MAX_DEB_ALLW: 'BLS_LABEL_MAX_DEB_ALLW',
  BLS_LABEL_MAXDEBALLW_EXP_DT: 'BLS_LABEL_MAXDEBALLW_EXP_DT',
  BLS_LABEL_REN_OV: 'BLS_LABEL_REN_OV',
  BLS_LABEL_DEDC: 'BLS_LABEL_DEDC',
  BLS_LABEL_MSC_ADJ: 'BLS_LABEL_MSC_ADJ',
  BLS_LABEL_TRANS: 'BLS_LABEL_TRANS',
  BLS_LABEL_PYMT: 'BLS_LABEL_PYMT',
  BLS_LABEL_RES_CAP: 'BLS_LABEL_RES_CAP',
  BLS_LABEL_ROLL_DEB_TRANS: 'BLS_LABEL_ROLL_DEB_TRANS',
  BLS_LABEL_TAX_EAR: 'BLS_LABEL_TAX_EAR',
  BLS_LABEL_TAX_BEN: 'BLS_LABEL_TAX_BEN',
  BLS_LABEL_MDRT: 'BLS_LABEL_MDRT',
  BLS_LABEL_REN_OV_YTD: 'BLS_LABEL_REN_OV_YTD',
  BLS_LABEL_DEDC_YTD: 'BLS_LABEL_DEDC_YTD',
  BLS_LABEL_MSC_ADJ_YTD: 'BLS_LABEL_MSC_ADJ_YTD',
  BLS_LABEL_TRANS_YTD: 'BLS_LABEL_TRANS_YTD',
  BLS_LABEL_PYMT_YTD: 'BLS_LABEL_PYMT_YTD',
  BLS_LABEL_RES_CAP_ACC_BAL: 'BLS_LABEL_RES_CAP_ACC_BAL',
  BLS_LABEL_DEB_TRANS_YTD: 'BLS_LABEL_DEB_TRANS_YTD',
  BLS_LABEL_TAX_BEN_YTD: 'BLS_LABEL_TAX_BEN_YTD',
  BLS_LABEL_TAX_EAR_YTD: 'BLS_LABEL_TAX_EAR_YTD',
  BLS_LABEL_MDRT_YTD: 'BLS_LABEL_MDRT_YTD',
  BLS_LABEL_DET_REC_CNT: 'BLS_LABEL_DET_REC_CNT',
  BLS_LABEL_PER_ID: 'BLS_LABEL_PER_ID',
  BLS_LABEL_PYMT_MTH: 'BLS_LABEL_PYMT_MTH',
  BLS_LABEL_BANK_NO: 'BLS_LABEL_BANK_NO',
  BLS_LABEL_BRCH_NO: 'BLS_LABEL_BRCH_NO',
  BLS_LABEL_ACCNO: 'BLS_LABEL_ACCNO',
  BLS_LABEL_TRANS_TO: 'BLS_LABEL_TRANS_TO',
  BLS_LABEL_PYMT_STAT: 'BLS_LABEL_PYMT_STAT',
  BLS_LABEL_TAXFRM: 'BLS_LABEL_TAXFRM',
  BLS_LABEL_INS_ADV: 'BLS_LABEL_INS_ADV',
  BLS_LABEL_INS_AFF: 'BLS_LABEL_INS_AFF',
  BLS_LABEL_INS_CORP: 'BLS_LABEL_INS_CORP',
  BLS_LABEL_INS_TEMP: 'BLS_LABEL_INS_TEMP',
  BLS_LABEL_INS_LOC: 'BLS_LABEL_INS_LOC',
  BLS_LABEL_INS_ALT: 'BLS_LABEL_INS_ALT',
  BLS_LABEL_INS_DIST: 'BLS_LABEL_INS_DIST',
  BLS_LABEL_INS_REG: 'BLS_LABEL_INS_REG',
  BLS_LABEL_INS_CHA: 'BLS_LABEL_INS_CHA',
  BLS_LABEL_INS_COM: 'BLS_LABEL_INS_COM',
  BLS_LABEL_NEG_DEB: 'BLS_LABEL_NEG_DEB',
  BLS_LABEL_NEG_DEB_FWD: 'BLS_LABEL_NEG_DEB_FWD',
  BLS_LABEL_NEG_DEB_TRANS: 'BLS_LABEL_NEG_DEB_TRANS',
  BLS_LABEL_GRP_PORBAL: 'BLS_LABEL_GRP_PORBAL',
  BLS_LABEL_CB_DEB_STRTDT: 'BLS_LABEL_CB_DEB_STRTDT',
  BLS_LABEL_NEG_DEB_STRTDT: 'BLS_LABEL_NEG_DEB_STRTDT',

  REPR_PAGE_TITLE: 'REPR_PAGE_TITLE',
  REP_LABEL_LOCATIONLEVEL: 'REP_LABEL_LOCATIONLEVEL',
  REP_LABEL_LOCATIONID: 'REP_LABEL_LOCATIONID',
  REP_LABEL_REPSTARTDT: 'REP_LABEL_REPSTARTDT',
  REP_LABEL_REPENDDT: 'REP_LABEL_REPENDDT',
  REP_LABEL_TASK: 'REP_LABEL_TASK',
  REP_LABEL_REPTITLE: 'REP_LABEL_REPTITLE',
  REP_LABEL_PHYFILEPATH: 'REP_LABEL_PHYFILEPATH',
  REP_LABEL_FILESIZE: 'REP_LABEL_FILESIZE',
  // Payment Processing
  PP_LABEL_REF_NUM: 'PP_LABEL_REF_NUM',
  PP_LABEL_REQ_USER_ID: 'PP_LABEL_REQ_USER_ID',
  PP_LABEL_REQ_DEPT: 'PP_LABEL_REQ_DEPT',
  PP_LABEL_REQ_DATE: 'PP_LABEL_REQ_DATE',
  PP_LABEL_DISTRIBUTION_CODE: 'PP_LABEL_DISTRIBUTION_CODE',
  PP_LABEL_SHORT_PAYEE: 'PP_LABEL_SHORT_PAYEE',
  PP_LABEL_RUSH_INDICATOR: 'PP_LABEL_RUSH_INDICATOR',
  PP_LABEL_MANUAL_INDICATOR: 'PP_LABEL_MANUAL_INDICATOR',
  PP_LABEL_NAME_OVERRIDE: 'PP_LABEL_NAME_OVERRIDE',
  PP_LABEL_ADDR_OVERRIDE: 'PP_LABEL_ADDR_OVERRIDE',
  PP_LABEL_CHQ_AMT: 'PP_LABEL_CHQ_AMT',
  PP_LABEL_PRINT_BATCH: 'PP_LABEL_PRINT_BATCH',
  PP_LABEL_PRE_AUTHORIZED: 'PP_LABEL_PRE_AUTHORIZED',
  PP_LABEL_MICR_NUM: 'PP_LABEL_MICR_NUM',
  PP_LABEL_BANK_ACCOUNT: 'PP_LABEL_BANK_ACCOUNT',
  PP_LABEL_APPROVE_ID: 'PP_LABEL_APPROVE_ID',
  PP_LABEL_APPROVE_DATE: 'PP_LABEL_APPROVE_DATE',
  PP_LABEL_PRINT_USER_ID: 'PP_LABEL_PRINT_USER_ID',
  PP_LABEL_PRINT_DATE: 'PP_LABEL_PRINT_DATE',
  PP_LABEL_REPRINT_ID: 'PP_LABEL_REPRINT_ID',
  PP_LABEL_REPRINT_DATE: 'PP_LABEL_REPRINT_DATE',
  PP_LABEL_REPRINT_TIMES: 'PP_LABEL_REPRINT_TIMES',
  PP_LABEL_CANCEL_ID: 'PP_LABEL_CANCEL_ID',
  PP_LABEL_CANCEL_DATE: 'PP_LABEL_CANCEL_DATE',
  PP_LABEL_CHQ_DATE: 'PP_LABEL_CHQ_DATE',
  PP_LABEL_PAYEE1: 'PP_LABEL_PAYEE1',
  PP_LABEL_PAYEE2: 'PP_LABEL_PAYEE2',
  PP_LABEL_PAYEE3: 'PP_LABEL_PAYEE3',
  PP_LABEL_PAYEE4: 'PP_LABEL_PAYEE4',
  PP_LABEL_PAYEE5: 'PP_LABEL_PAYEE5',
  PP_LABEL_PAYEE6: 'PP_LABEL_PAYEE6',
  PP_LABEL_PAY_TYPE: 'PP_LABEL_PAY_TYPE',
  PP_LABEL_PAY_BANK: 'PP_LABEL_PAY_BANK',
  PP_LABEL_PAY_BRANCH: 'PP_LABEL_PAY_BRANCH',
  PP_LABEL_ACCT_TYPE: 'PP_LABEL_ACCT_TYPE',
  PP_LABEL_ACCT_NUM: 'PP_LABEL_ACCT_NUM',
  PP_LABEL_JOINT_OWNER_INDICATOR: 'PP_LABEL_JOINT_OWNER_INDICATOR',
  PP_LABEL_BANK_ID_APPL: 'PP_LABEL_BANK_ID_APPL',
  PP_LABEL_BANK_ID_CO: 'PP_LABEL_BANK_ID_CO',
  PP_LABEL_SORT: 'PP_LABEL_SORT',
  PP_LABEL_DATA_TRANS_BATCH: 'PP_LABEL_DATA_TRANS_BATCH',
  PP_APPROVE_SUCCESS_MSG: 'Are you sure you want to Approve selected records?',
  PP_REMIT_SUCCESS_MSG: 'Are you sure you want to remit/unremit selected records?',
  PP_MSG08_CHEQUEREMITTED: 'PP_MSG08_CHEQUEREMITTED',
  PP_MSG08_CHEQUEREMITTED_HEADER: 'PP_MSG08_CHEQUEREMITTED_HEADER',
  PP_MSG09_CHEQUEAPPROVED_HEADER: 'PP_MSG09_CHEQUEAPPROVED_HEADER',
  PP_MSG014_CHEQUECANCELLED_HEADER: 'PP_MSG014_CHEQUECANCELLED_HEADER',
  PP_MSG10_CHEQUEAPPROVED_FAILED_HEADER: 'PP_MSG10_CHEQUEAPPROVED_FAILED_HEADER',
  PP_MSG11_CHEQUEAPPROVED_PARTIAL_HEADER: 'PP_MSG11_CHEQUEAPPROVED_PARTIAL_HEADER',
  PP_BUTTON_APPROVE_TEXT: 'PP_BUTTON_APPROVE_TEXT',
  PP_BUTTON_REMIT_TEXT: 'PP_BUTTON_REMIT_TEXT',
  PP_BUTTON_CANCEL_TEXT: 'PP_BUTTON_CANCEL_TEXT',
  PP_MSG12_TOTAL_PAYMENT_SELECTED: 'PP_MSG12_TOTAL_PAYMENT_SELECTED',
  PP_MSG13_TOTAL_PAYMENT_SELECTED: 'PP_MSG13_TOTAL_PAYMENT_SELECTED',
  PP_BUTTON_REMIT: 'PP_BUTTON_REMIT',
  PAY_TITLE_LIST: 'PAY_TITLE_LIST',
  CP_THRESHOLD_MSG: 'CP_THRESHOLD_MSG',
  JS_PAGE_TITLE: 'JS_PAGE_TITLE',
  CP_CANCEL_COMPLETE_MSG: 'CP_CANCEL_COMPLETE_MSG',
  PP_MENU: 'PP_MENU',
};
