/* eslint-disable no-debugger */
/**
 * @Description Reducer for manual adjustments
 * @FileName businessCardRulesReducer.js
 * @Author negiroh
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { manualAdjustmentsActionTypes } from '../actions/actionTypes';

const ManualAdj1 = (state, action = null) => {
  switch (action.type) {
    case manualAdjustmentsActionTypes.GET_ON_LOAD_RESPONSE:
      return {
        ...state,
        onLoadData: action?.data?.data,
        loading: false,
      };
    case manualAdjustmentsActionTypes.RESET_STATE:
      return {
        ...state,
        onLoadData: null,
        loading: false,
      };
    case manualAdjustmentsActionTypes.RESET_STORE:
      return {
        ...state,
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: null,
        transferAgentName: null,
        policyInfo: null,
        productInfo: null,
        upsertData: null,
        headerInfo: null,
        loading: false,
        onLoadData: null,
        jobRequestResponse: null,
      };
    case manualAdjustmentsActionTypes.GET_HEADER_INFO_RESPONSE:
      return {
        ...state,
        headerInfo: action.data,
        loading: false,
      };
    case manualAdjustmentsActionTypes.RESET_HEADER_INFO:
      return {
        ...state,
        headerInfo: null,
        loading: false,
      };
    case manualAdjustmentsActionTypes.GET_MA_TA_NAME_RESPONSE:
      return {
        ...state,
        transferAgentName: action.data,
        loading: false,
      };
    case manualAdjustmentsActionTypes.RESET_MA_TA_NAME:
      return {
        ...state,
        transferAgentName: null,
        loading: false,
      };
    default:
      return null;
  }
};

const ManualAdj2 = (state, action = null) => {
  switch (action.type) {
    case manualAdjustmentsActionTypes.GET_POLICY_INFO_RESPONSE:
      return {
        ...state,
        policyInfo: action.data,
        loading: false,
      };
    case manualAdjustmentsActionTypes.RESET_POLICY_INFO:
      return {
        ...state,
        policyInfo: null,
        loading: false,
      };
    case manualAdjustmentsActionTypes.GET_PRODUCT_INFO_RESPONSE:
      return {
        ...state,
        productInfo: action.data,
        loading: false,
      };
    case manualAdjustmentsActionTypes.RESET_PRODUCT_INFO:
      return {
        ...state,
        productInfo: null,
        loading: false,
      };
    case manualAdjustmentsActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case manualAdjustmentsActionTypes.MADJ_JOB_REQUEST_RESPONSE: {
      return {
        ...state,
        jobRequestResponse: action.data,
      };
    }
    case manualAdjustmentsActionTypes.MADJ_JOB_REQUEST_ERROR: {
      return {
        ...state,
        upsertRequestError: action.data,
      };
    }
    case manualAdjustmentsActionTypes.PROCESS_VALUES: {
      return {
        ...state,
        processValue: action.data,
      };
    }
    case commonTypes.RESETSTORE:
      return null;
    default:
      return null;
  }
};

const ManualAdjustments = (state = null, action = null) => {
  const stateConfig = ManualAdj1(state, action);
  const stateReqRes = ManualAdj2(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ManualAdjustments;
