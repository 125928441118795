/**
 * @Description This is FasatRadioButton component page.
 * This is a wrapper on "RadioButton" component from MUX without adding any custom functionlities
 * @FileName fasatRadioButton.js
 * @Author UMA DUTTA-duttuma
 * @CreatedOn 29 January, 2021 6:00:00
 * @IssueID CDTFASATMOD- 502
 */
import React from 'react';
import { RadioButton } from '@manulife/mux';
import PropTypes from 'prop-types';
import FasatLabel from '../fasatLabel/fasatLabel';

const FasatRadioButton = (props) => {
  const { labelText, id } = props;
  return (
    <>
      {labelText && (
      <FasatLabel htmlFor={id}>
        {labelText}
      </FasatLabel>
      )}
      <RadioButton {...props} />
    </>
  );
};

FasatRadioButton.propTypes = {
  labelText: PropTypes.string,
  id: PropTypes.string,
};

FasatRadioButton.defaultProps = {
  labelText: '',
  id: '',
};

export default FasatRadioButton;
