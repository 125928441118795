/**
 * @Description FasatColumnOrder Options Prototypes File
 * @FileName optionsShape.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
import PropTypes from 'prop-types';

import optionShape from './optionShape';

export default PropTypes.arrayOf(
  PropTypes.oneOfType([
    optionShape,
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      options: PropTypes.arrayOf(optionShape).isRequired,
    }),
  ]),
);
