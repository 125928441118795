/**
 * @Description Http service base
 * @FileName http.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 28 January, 2021 05:45:48
 * @IssueID - 307
 */
import axios from 'axios';
import { environment } from '../util';
/**
 * In case we need to send some default static header,
 * that should be configured here.
 * Currently we configure it to app-name only.
 */
const apiHeaders = {};
apiHeaders['app-name'] = 'FASAT-ONLINE';
apiHeaders['Content-Type'] = 'application/json';
apiHeaders.responseType = 'arraybuffer';

/**
 * Creation of axios instance.
 * In future if we want to use a different provider
 * we will change here only.
 */
export default axios.create({
  timeout: environment().timeout,
  headers: apiHeaders,
});
