import { typography } from '@manulife/mux';
import styled from 'styled-components';
import checkMark from './assets/images/checkmark.svg';

export const Container = styled.div`
    text-align: left;
    font-family: ${typography.fontFamily};
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #34384b;
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
    position: relative;    
    }

    .errorText {
      color: #db1f00;
      font-size: 16px;
      line-height: 1.5;
      margin: 4px 0 0;
    }
    .noErrorText {
      display: none;
    }

    &.error {
      button {
        border: solid 2px #db1f00;
      }
    }
`;

export const DropdownLabel = styled.label`
  font-family: ${typography.fontFamily};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
`;

export const Button = styled.button`
  width: inherit;
  height: 46px;
  color: #34384b;
  background-color:#ffffff;
  border: solid 1px #d5d6da;
  padding: 4px;
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
  font-family: ${typography.fontFamily};
  margin-top: 8px;
  
  .selectPlaceholderText {
    padding-left: 8px;
  }

  &:disabled {
    color: #8e90a2;
    border-color: #c2c3c9;
    background-color: #fafafa;
    cursor: not-allowed;
    &:hover {
      border-color: #c2c3c9;
    }
  }

  &:hover {
    border: solid 1px #424559;
  }

  &.selectOpen {
    border: 1px solid #00a758;
  }

  p {
    font-family: ${typography.fontFamily} !important;
    display: inline;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 600;
    color: #34384b;
    margin-right: 7px;
    padding: 13px 8px 11px 8px;
    line-height: 18px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      max-width: calc(100% - 74px);
      vertical-align: -5px;
    }

    img {
      &.closeSelection {
        float: none;
        cursor: pointer;
        vertical-align: -5px;
        margin-left: 7px;
      }

      &.selectedOption {
        margin-right: 4px;
      }
    }
  }

  img {
    &.upDownArrow {      
    float: right;
    }
  }

`;

export const MultiSelect = styled.ul`
  padding: 6px 0;
  width:-webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  max-height:252px;
  border:1px solid #c2c3c9;
  overflow:auto;
  position: absolute;
  z-index: 9999;
  background-color: #ffffff;
  margin-top: 46px;
  &.f-margin-t-0 {
    margin-top: 0;
  }
  &.sortedOptionTray {    
    margin-top: 47px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    transform: rotate(-180deg);
    border-radius: 6px;
    background-color: #e5e5e8;
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 8px;
    transform: rotate(-180deg);
    border-radius: 6px;
    height: 44px;
    background-color: #8e90a2;
  }
  li {
    list-style: none;
  }
`;

export const Label = styled.label`
  display:flex;
  padding: 6px 12px;
  font-size: 16px;  
  line-height: 1.6;
  font-weight: 300;
  word-break: break-all;
  &:first-child {
    padding-top: 12px;
  }
  &:last-child {
    padding-bottom: 12px;
  }
  &:hover {
    font-weight: 400;
  }
  &.selected{
    font-weight: 400;
    border-left: 4px solid #00a758;
  }
  &.false {
    span {
      font-weight: 400;
      margin-top: 2px;
    }
  }
`;

export const Input = styled.input`
  appearance: none;
  background: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color: #5e6073;
  width: 28px;
  height: 28px;
  min-width: 28px;
  vertical-align: bottom;
  margin: 0px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    border-width: 3px;
  }

  &:checked {
    border: solid 3px #00a758;
    background: url( ${checkMark}) no-repeat 4px;
  }
`;

export const SelectWrapper = styled.div`
  display: ${(props) => props.display};
  position: absolute;
  width: 100%;
`;

export const SearchSection = styled.div`
    height: 44px;
    position: absolute;
    width: 100%;
    img {
      position: absolute;
      z-index: 9999;
      top: 15px;
      right: 10px;
    }
  `;
export const SearchBox = styled.input`
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
    padding: 12px;
    border: solid 1px #d5d6da;
    font-size: 16px;
    line-height: 1.5;
    color: #34384B;
    font-family: ${typography.fontFamily};
    position: absolute;
    z-index: 9999;
  `;

export const ButtonRemove = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const DropdownCheckbox = styled.div`
  display:flex;
  padding: 6px 12px;
  font-size: 16px;  
  line-height: 1.6;
  font-weight: 300;
  word-break: break-all;
  &:hover {
    font-weight: 400;
  }
  &.selected{
    font-weight: 400;
    border-left: 4px solid #00a758;
  }
  &.false {
    span {
      font-weight: 400;
      margin-top: 2px;
    }
  }
`;

export const SearchResultDisplay = styled.div`
    padding: 2px 2px 0 10px;
    position: absolute;
`;
