import moment from 'moment';
import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { deInquiryActionTypes } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from '../../../report/redux/reducers/utilities/utility';
import { commonTypes } from '../../../../common/redux/actions/commonActions';

export const REDUCER_NAME = 'DeductionInquiryFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: moment().format(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const DeductionInqFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case deInquiryActionTypes.UPDATE_DEINQ_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case deInquiryActionTypes.REMOVE_DEINQ_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case deInquiryActionTypes.UPDATE_DEINQ_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const DeductionInqFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case deInquiryActionTypes.LOG_DEINQ_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case deInquiryActionTypes.GET_DEINQ_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: moment().format(),
        loading: false,
      };
    // store response
    case deInquiryActionTypes.GET_DEINQ_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: moment().format(),
        loading: false,
      };
    case deInquiryActionTypes.RESET_DEINQ_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case deInquiryActionTypes.UPDATE_DEINQ_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case deInquiryActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const DeductionInquiryFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = DeductionInqFilterListConfig(state, action);
  const stateReqRes = DeductionInqFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default DeductionInquiryFilterList;
