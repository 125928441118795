/* eslint-disable react/prop-types */
/**
 * @Description Container for delete business card rule list
 * @FileName deleteBusinessCardRule.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 20 April, 2021 08:49:34
 * @IssueID 774
 */

import React, { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FasatConfirmationMsg } from '../index';
import { staticCommonLabelKeys } from '../../moduleConstants';

const one = 1;
const two = 2;
const zero = 0;
let timeOutMsg1;
let timeOutMsg2;
let timeOutMsg3;

export const timeOutMsg = ({ session, extWarnType, t }) => (
  <>
    {!session
    && (
    <>
      <span style={{ fontWeight: 300 }}>{timeOutMsg1}</span>
      <span>
        {' '}
        {timeOutMsg2}
      </span>
      <span style={{ fontWeight: 300 }}>.</span>
    </>
    )}
    {extWarnType === one && (
    <div>
      <>
        <span style={{ fontWeight: 300 }}>{timeOutMsg}</span>
        {/* <span>
            {' '}
            {timeOutMsg2}
          </span> */}
        <span style={{ fontWeight: 300 }}>
          {t(staticCommonLabelKeys.CIAM_LOGOUT_TWELVE_HOUR_EXPIRE)}
          {' '}
          {timeOutMsg2}
          .
        </span>
        <br />
        <span style={{ fontWeight: 300 }}>
          {t(staticCommonLabelKeys.CIAM_LOGOUT_DEFAULT_WARNING_SAVEWORK)}
        </span>
      </>
    </div>
    )}
    {extWarnType === two && (
    <div>
      <>
        {/* <span style={{ fontWeight: 300 }}>{timeOutMsg1}</span> */}
        {/* <span>
            {' '}
            {timeOutMsg2}
          </span> */}
        <span style={{ fontWeight: 300 }}>
          {t(staticCommonLabelKeys.CIAM_LOGOUT_TWELVE_HOUR_EXPIRE)}
          {timeOutMsg3}
          .
        </span>
        <br />
        <span style={{ fontWeight: 300 }}>
          {t(staticCommonLabelKeys.CIAM_LOGOUT_DEFAULT_WARNING_AUTOSIGNOUT)}
        </span>
        <br />
      </>
    </div>
    )}

    {session && extWarnType !== one && extWarnType !== two
      && (
        <>
          <span style={{ fontWeight: 300 }}>{timeOutMsg1}</span>
          <span>
            {' '}
            {timeOutMsg2}
          </span>
          <span style={{ fontWeight: 300 }}>.</span>
          <br />
          <span style={{ fontWeight: 300 }}>
            {t(staticCommonLabelKeys.CIAM_LOGOUT_DEFAULT_WARNING)}

          </span>
        </>
      )}
  </>
);

export const timeOutMsgExternal = ({ extWarnType, session, t }) => (
  <>
    {extWarnType === one && (
    <div>
      <>
        <span style={{ fontWeight: 300 }}>{timeOutMsg}</span>
        {/* <span>
            {' '}
            {timeOutMsg2}
          </span> */}
        <span style={{ fontWeight: 300 }}>
          {t(staticCommonLabelKeys.CIAM_LOGOUT_TWELVE_HOUR_EXPIRE)}
          {' '}
          {timeOutMsg2}
          .
        </span>
        <br />
        <span style={{ fontWeight: 300 }}>
          {t(staticCommonLabelKeys.CIAM_LOGOUT_DEFAULT_WARNING_SAVEWORK)}
        </span>
      </>
    </div>
    )}
    {extWarnType === two && (
    <div>
      <>
        {/* <span style={{ fontWeight: 300 }}>{timeOutMsg1}</span> */}
        {/* <span>
            {' '}
            {timeOutMsg2}
          </span> */}
        <span style={{ fontWeight: 300 }}>
          {t(staticCommonLabelKeys.CIAM_LOGOUT_TWELVE_HOUR_EXPIRE)}
          {timeOutMsg3}
          .
        </span>
        <br />
        <span style={{ fontWeight: 300 }}>
          {t(staticCommonLabelKeys.CIAM_LOGOUT_DEFAULT_WARNING_AUTOSIGNOUT)}
        </span>
        <br />
      </>
    </div>
    )}

    {session && extWarnType !== one && extWarnType !== two
      && (
        <>
          <span style={{ fontWeight: 300 }}>{timeOutMsg1}</span>
          <span>
            {' '}
            {timeOutMsg2}
          </span>
          <span style={{ fontWeight: 300 }}>.</span>
          <br />
          <span style={{ fontWeight: 300 }}>
            {t(staticCommonLabelKeys.CIAM_LOGOUT_DEFAULT_WARNING)}

          </span>
        </>
      )}
    {!session && (
    <>
      <span style={{ fontWeight: 300 }}>{timeOutMsg1}</span>
      <span>
        {' '}
        {timeOutMsg2}
      </span>
      <span style={{ fontWeight: 300 }}>.</span>
      <br />
      <span style={{ fontWeight: 300 }}>
        {t(staticCommonLabelKeys.CIAM_LOGOUT_SESSION_INACTIVITY)}

      </span>
    </>
    )}
  </>
);

const onStayTopicCheck = (onStayTopic) => {
  if (onStayTopic && onStayTopic !== '') {
    return true;
  }
  return false;
};
const saveSubscriptionIdsCheck = (saveSubscriptionIds) => {
  if (saveSubscriptionIds || saveSubscriptionIds.length === zero) {
    return true;
  }
  return false;
};
const onLogoutTopicCheck = (onLogoutTopic) => {
  if (onLogoutTopic && onLogoutTopic !== '') {
    return true;
  }
  return false;
};
export const externalFunc = ({ session, loggedUser, setLogoutEventData, data }) => {
  if (session) {
    localStorage.setItem('logInTime', '');
    window.location = `${loggedUser.LogOutUrl}&&logOutType=session`;
    setLogoutEventData(data);
  } else {
    localStorage.setItem('logInTime', '');
    window.location = `${loggedUser.LogOutUrl}&&logOutType=inactive`;
    setLogoutEventData(data);
  }
};
export const saveEventFunc = (saveEventData) => {
  if (saveEventData != null) {
    saveEventData.close();
  }
};
export const logoutEventFunc = (logoutEventData) => {
  if (logoutEventData != null) {
    logoutEventData.close();
  }
};
const timeOutMessage = ({ loggedUser, extWarnType, session, t }) => (loggedUser?.UserType === 'external' ? timeOutMsgExternal({ extWarnType, session, t }) : timeOutMsg({ session, extWarnType, t }));

const FasatAutoLogout = ({ onStayTopic, onLogoutTopic, minutes, seconds, i18n, session, extWarnType }) => {
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);
  const loggedUser = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.loggedUser);
  const userType = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC?.loggedUser?.UserType);
  const [saveEventData, setSaveEventData] = useState(null);
  const [logoutEventData, setLogoutEventData] = useState(null);
  const [t] = useTranslation();

  if (i18n) {
    timeOutMsg1 = t(staticCommonLabelKeys.COMMON_SESSION_EXPIRE_MSG);
    timeOutMsg2 = `${minutes}:${seconds}`;
    timeOutMsg3 = `${minutes}:${seconds}`;
  }

  /**
    * Subscribe button Click Event from Modal
    */
  useEffect(() => {
    if (onStayTopicCheck(onStayTopic) && saveSubscriptionIdsCheck(saveSubscriptionIds)) {
      const subId = PubSub.subscribe(onStayTopic, (msg, data) => {
        setSaveEventData(data);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }

    if (onLogoutTopicCheck(onLogoutTopic)) {
      const subId = PubSub.subscribe(onLogoutTopic, (msg, data) => {
        if (userType === 'external') {
          externalFunc({ session, loggedUser, setLogoutEventData, data });
        } else {
          localStorage.setItem('logInTime', '');
          window.location = loggedUser.LogOutUrl;
          setLogoutEventData(data);
        }
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);
  useEffect(() => saveEventFunc(saveEventData), [saveEventData]);
  useEffect(() => {
    logoutEventFunc(logoutEventData);
  }, [logoutEventData]);
  return (
    <div className="fasatAutoLogout">
      <FasatConfirmationMsg msg={timeOutMessage({ loggedUser, extWarnType, session, t })} />
    </div>
  );
};

export default FasatAutoLogout;
