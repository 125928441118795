/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { staticCommonLabelKeys, userLock } from '../../moduleConstants';
import { getValueFromLocalStorage, Logger, setAutoLogoutType, STORAGE_KEY } from '../../util';
import { FasatModal } from '../index';
import FasatAutoLogout from './fasatAutoLogout';
import { getBatchProcessingState, releaseLockCombo } from '../../redux/actions/commonActions';

const defaultDateFormat = 'YYYY-MM-DD hh:mm:ss a';
const modalWidth = '584px';
const sixty = 60;
const two = 2;
const thousand = 1000;
const TEN = 10;
const five = 5;
const one = 1;
const three = 3;
const autoLogoutTime = parseInt(getValueFromLocalStorage(STORAGE_KEY.DEFAULT_LOGOUT_TIME), TEN) || TEN;
const autoLogoutExternalTime = parseInt(localStorage.getItem('FASAT_EXTERNAL_DEFAULT_SIGNOUT_LIMIT'), TEN) || TEN;
const autologSessin = parseInt(localStorage.getItem('FASAT_EXTERNAL_MAX_SIGNOUT_LIMIT'), TEN) || TEN;
const autologInternalSessin = parseInt(localStorage.getItem('FASAT_INTERNAL_MAX_SIGNOUT_LIMIT'), TEN) || TEN;

// Prepend `0` for one digit numbers. For that the number has to be
export const userTypeCheck = (userType) => {
  if (userType === 'external') {
    return autoLogoutExternalTime;
  }
  return autoLogoutTime;
};
// converted to string, as numbers don't have length method
export const padTime = (time) => (String(time).length === 1 ? `0${time}` : `${time}`);

const format = (time) => {
  // Convert seconds into minutes and take the whole part
  const minutes = padTime(Math.floor(time / sixty));

  // Get the seconds left after converting minutes
  const seconds = time % sixty;
  const paddedTime = padTime(seconds);
  // Return combined values as string in format mm:ss
  return { minutes, paddedTime };
};

const timmer = (setCounter) => {
  setTimeout(() => setCounter((c) => c - 1), thousand);
};
const extTimmer = (setExtCounter) => {
  setTimeout(() => setExtCounter((c) => c - 1), thousand);
};

export const getCountTimmer = ({ counter, displayLogoutModal, loggedUser, setCounter, dispatch }) => {
  if (counter > 0 && displayLogoutModal) {
    timmer(setCounter);
  }
  if (counter === 0 && displayLogoutModal) {
    setAutoLogoutType('auto-logout');
    localStorage.setItem('logInTime', '');
    dispatch(releaseLockCombo({ ...userLock, lockedBy: loggedUser.UserId }));
    window.location = loggedUser.LogOutUrl;
  }
};
export const getExtCountTimmer = ({ extCounter, displayExtLogoutModal, loggedUser, setExtCounter, dispatch, displayLogoutModal }) => {
  if (extCounter > 0 && displayExtLogoutModal) {
    extTimmer(setExtCounter);
  }
  if (extCounter === 0 && displayExtLogoutModal) {
    setAutoLogoutType('auto-logout');
    dispatch(releaseLockCombo({ ...userLock, lockedBy: loggedUser.UserId }));
    window.location = loggedUser.LogOutUrl;
  }
  if (extCounter > 0 && displayLogoutModal) {
    extTimmer(setExtCounter);
  }
};

export const setingTime = ({ loggedUser, extPopUp1, extPopUp2, setextWarnType, setSession, setDisplayExtLogoutModal, setExtPopUp1, setExtPopUp2, setMaxIdleExpirationTimeExt }) => {
  const now = moment().format(defaultDateFormat);
  let userWiseTime;
  if (loggedUser?.UserType === 'external') {
    userWiseTime = autologSessin;
  } else {
    userWiseTime = autologInternalSessin;
  }
  const futureTime1 = moment(localStorage?.getItem('logInTime')).add((userWiseTime - five), 'minutes').format(defaultDateFormat).toString();
  const futureTime2 = moment(localStorage?.getItem('logInTime')).add((userWiseTime - one), 'minutes').format(defaultDateFormat).toString();
  const futureTime3 = moment(localStorage?.getItem('logInTime')).add(userWiseTime, 'minutes').format(defaultDateFormat).toString();
  const maxIdleExpirationTime = localStorage?.getItem('maxIdleExpirationTime');
  if (maxIdleExpirationTime) {
    setMaxIdleExpirationTimeExt(maxIdleExpirationTime);
  }
  if (now.toString() === futureTime1 && extPopUp1 === one) {
    setextWarnType(one);
    setSession(true);
    setDisplayExtLogoutModal(true);
    setExtPopUp1(null);
  }
  if (now.toString() === futureTime2 && extPopUp2 === one) {
    setextWarnType(two);
    setSession(true);
    setExtPopUp2(two);
    setDisplayExtLogoutModal(true);
  }
  if (now.toString() === futureTime3) {
    if (loggedUser.UserType === 'external') {
      localStorage.setItem('logInTime', '');
      window.location = `${loggedUser.LogOutUrl}&&logOutType=session`;
    } else {
      localStorage.setItem('logInTime', '');
      window.location = loggedUser.LogOutUrl;
    }
  }
  // }
};

const sessionModal = ({ displayExtLogoutModal, t, closeAutoTimeOutExtModal, minutes, paddedTime, seesion, extWarnType }) => (
  <FasatModal
    title={t(staticCommonLabelKeys.COMMON_LABEL_SESSION_EXPIRY)}
    display={displayExtLogoutModal}
    modalWidth={modalWidth}
    primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_CONTINUE)}
    secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_SIGNOUT)}
    primaryTopic="SHOW_STAY_SIGN_IN"
    secondaryTopic="SHOW_SIGN_OUT"
    modalClass="modalWithHeading"
    modalCosed={closeAutoTimeOutExtModal}
    staydiscard
    isLogout
  >
    <FasatAutoLogout
      onStayTopic="SHOW_STAY_SIGN_IN"
      onLogoutTopic="SHOW_SIGN_OUT"
      minutes={minutes}
      seconds={paddedTime}
      session={seesion}
      extWarnType={extWarnType}
      i18n
    />
  </FasatModal>
);
const initialCounter = sixty * two;
const initialExtCounter = sixty * five;

export const closeLogOutFunc = ({ extPopUp2,
  setDisplayExtLogoutModal, setExtCounter, setextWarnType, paddedTime, setExtPopUp1, setSession }) => {
  if (extPopUp2 === two) {
    setDisplayExtLogoutModal(false);
    document.body.style.overflowY = 'auto';
    setExtCounter(paddedTime);
  } else {
    setextWarnType(null);
    setExtPopUp1(null);
    setSession(false);
    setDisplayExtLogoutModal(false);
    document.body.style.overflowY = 'auto';
    clearTimeout(extTimmer);
    setExtCounter(initialExtCounter);
  }
};

export const closeAutoTimeModalFunc = ({ setDisplayLogoutModal, setCounter, setExtCounter }) => {
  setDisplayLogoutModal(false);
  document.body.style.overflowY = 'auto';
  clearTimeout(timmer);
  setCounter(initialCounter);
  setExtCounter(initialCounter);
};

const seesionExtWarnType = ({ seesion, extWarnType, setExtCounter }) => {
  if (seesion && extWarnType !== two) {
    setExtCounter(initialExtCounter);
  } else if (seesion && extWarnType === two) {
    setExtCounter(sixty * one);
  } else {
    setExtCounter(initialCounter);
  }
};

const checkLoggedUserType = ({ loggedUser, extCounter, counter }) => (loggedUser?.UserType === 'external' ? extCounter : counter);
const refreshSession = (dispatch) => {
  dispatch(getBatchProcessingState());
};
const checkAndRefreshUserSession = (maxIdleExpirationTimeExt, loggedUser, dispatch, isLogoutModalOpen) => {
  if (!moment(maxIdleExpirationTimeExt).isValid() || loggedUser?.UserType !== 'external' || isLogoutModalOpen) {
    return;
  }
  const thresholdTime = moment.utc().add(three, 'minutes');
  if (moment.utc(maxIdleExpirationTimeExt).isSameOrBefore(moment.utc(thresholdTime))) {
    refreshSession(dispatch);
  }
};
const setExtSessionRefreshTimer = (externalSessionRefreshTimer, maxIdleExpirationTimeExt, loggedUser, dispatch, isLogoutModalOpen) => {
  clearInterval(externalSessionRefreshTimer);
  return setInterval(() => {
    checkAndRefreshUserSession(maxIdleExpirationTimeExt, loggedUser, dispatch, isLogoutModalOpen);
  }, sixty * thousand);
};
const clearTimmerTimeout = (extPopUp2) => {
  if (extPopUp2 !== two) {
    clearTimeout(timmer);
  }
};
const checkLogoutModalOpen = (displayLogoutModal, displayExtLogoutModal) => displayLogoutModal || displayExtLogoutModal;
const FasatIdleTimer = ({ children }) => {
  const [displayLogoutModal, setDisplayLogoutModal] = useState(false);
  const [displayExtLogoutModal, setDisplayExtLogoutModal] = useState(false);
  const [counter, setCounter] = useState(initialCounter);
  const [extCounter, setExtCounter] = useState(initialCounter);
  const [extWarnType, setextWarnType] = useState(null);
  const [extPopUp1, setExtPopUp1] = useState(one);
  const [extPopUp2, setExtPopUp2] = useState(one);
  const [seesion, setSession] = useState(false);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const loggedUser = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.loggedUser);
  const [maxIdleExpirationTimeExt, setMaxIdleExpirationTimeExt] = useState(null);
  useEffect(() => {
    getCountTimmer({ counter, displayLogoutModal, loggedUser, setCounter, dispatch });
    return () => {
      clearTimeout(timmer);
    };
  }, [counter, displayLogoutModal]);
  useEffect(() => {
    getExtCountTimmer({ extCounter, displayExtLogoutModal, loggedUser, setExtCounter, dispatch, displayLogoutModal });
    return () => {
      clearTimmerTimeout(extPopUp2);
    };
  }, [extCounter, displayExtLogoutModal, displayLogoutModal]);
  useEffect(() => {
    seesionExtWarnType({ seesion, extWarnType, setExtCounter });
  }, [seesion, extWarnType]);
  setInterval(() => {
    setingTime({ loggedUser, extPopUp1, extPopUp2, setextWarnType, setSession, setDisplayExtLogoutModal, setExtPopUp1, setExtPopUp2, setMaxIdleExpirationTimeExt });
  }, thousand);
  useEffect(() => {
    if (extWarnType === two) {
      setExtCounter(sixty * one);
    }
  }, [extWarnType]);
  const { minutes, paddedTime } = seesion ? format(extCounter) : format(checkLoggedUserType({ loggedUser, extCounter, counter }));
  const handleOnIdle = (event) => {
    Logger.verbose('user is idle', event);
    if (loggedUser) {
      setDisplayLogoutModal(true);
    }
  };
  let externalSessionRefreshTimer;
  useEffect(() => {
    externalSessionRefreshTimer = setExtSessionRefreshTimer(externalSessionRefreshTimer, maxIdleExpirationTimeExt, loggedUser, dispatch, checkLogoutModalOpen(displayLogoutModal, displayExtLogoutModal));
    return () => clearInterval(externalSessionRefreshTimer);
  }, [maxIdleExpirationTimeExt, displayLogoutModal, displayExtLogoutModal]);
  const handleOnActive = (event) => Logger.verbose('user is active', event);
  const handleOnAction = (event) => Logger.verbose('user did something', event);
  const timeChek = userTypeCheck(loggedUser?.UserType);
  const { getLastActiveTime } = useIdleTimer({
    timeout: timeChek * sixty * thousand,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  Logger.verbose('user last active time', getLastActiveTime());
  const closeAutoTimeOutModal = () => {
    closeAutoTimeModalFunc({ setDisplayLogoutModal, setCounter, setExtCounter });
  };
  const closeAutoTimeOutExtModal = () => closeLogOutFunc({ extPopUp2, setDisplayExtLogoutModal, setExtCounter, setextWarnType, paddedTime, setExtPopUp1, setSession });
  return (
    <>
      {children}
      {seesion
        ? (sessionModal({ displayExtLogoutModal, t, closeAutoTimeOutExtModal, minutes, paddedTime, seesion, extWarnType }))
        : (
          <FasatModal
            title={t(staticCommonLabelKeys.COMMON_LABEL_SESSION_EXPIRY)}
            display={displayLogoutModal}
            modalWidth={modalWidth}
            primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_STAY_SIGNIN)}
            secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_SIGNOUT)}
            primaryTopic="SHOW_STAY_SIGN_IN"
            secondaryTopic="SHOW_SIGN_OUT"
            modalClass="modalWithHeading"
            modalCosed={(isPrimaryButtonClicked) => {
              if (isPrimaryButtonClicked) {
                refreshSession(dispatch);
              }
              closeAutoTimeOutModal();
            }}
            staydiscard
            isLogout
          >
            <FasatAutoLogout
              onStayTopic="SHOW_STAY_SIGN_IN"
              onLogoutTopic="SHOW_SIGN_OUT"
              minutes={minutes}
              seconds={paddedTime}
              session={seesion}
              i18n
            />
          </FasatModal>
        )}
    </>
  );
};

FasatIdleTimer.propTypes = {
  children: propTypes.isRequired,
};
export default FasatIdleTimer;
