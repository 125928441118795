/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { fieldForcePaymentsActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'FieldForcePayments';

const getEditItemPayDetailsResponse = (state, action) => ({
  ...state,
  ffPaymentsOnLoadData: action.data,
  ffPaymentsOnLoadData1: action.data,
});

const updatePaymentsDefaultData = (state, action) => ({
  ...state,
  updatedDefaultData: action.data,
});

const FieldForcePaymentsHelper = (state, action) => {
  switch (action.type) {
    case fieldForcePaymentsActionTypes.PAYMENTS_JOB_REQUEST_RESPONSE: {
      return {
        ...state,
        jobRequestResponse: action.data,
      };
    }
    case fieldForcePaymentsActionTypes.PAYMENTS_JOB_REQUEST_ERROR: {
      return {
        ...state,
        upsertRequestError: action.data,
      };
    }
    case fieldForcePaymentsActionTypes.PROCESS_VALUE: {
      return {
        ...state,
        processValue: action.data,
      };
    }
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return state;
  }
};

const FieldForcePayments = (state = { ...basicFilterInitialState, validateLocation: false }, action = null) => {
  switch (action.type) {
    case fieldForcePaymentsActionTypes.PAYMENTS_ADDRESS_DETAILS_RESPONSE:
      return {
        ...state,
        addressDetail: action.data,
        loading: false,
      };
    case fieldForcePaymentsActionTypes.PAYMENTS_ON_LOAD_RESPONSE:
      return getEditItemPayDetailsResponse(state, action);
    case fieldForcePaymentsActionTypes.PAYMENTS_UPDATE_DEFAULT_DATA:
      return updatePaymentsDefaultData(state, action);
    case fieldForcePaymentsActionTypes.PAYMENTS_PROCESS_RESPONSE:
      return { ...state,
        upsertData: action.data,
        loading: false };
    case fieldForcePaymentsActionTypes.RESET_SERVER_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case fieldForcePaymentsActionTypes.RESET_STORE: {
      return {
        ...state,
        ffPaymentsOnLoadData: null,
        upsertData: null,
        addressDetail: null,
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: null,
      };
    }
    default:
      return FieldForcePaymentsHelper(state, action);
  }
};

export default FieldForcePayments;
