import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { manageUsersActionType } from '../actions/actionTypes';

export const REDUCER_NAME = 'ManageUsersFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const logAccOptsListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const updateTableConfig = (state, action) => ({
  ...state,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  selectedFilter: action.data ? action.data : null,
});

const ManageUsersFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case manageUsersActionType.UPDATE_MNG_USER_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case manageUsersActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case manageUsersActionType.REMOVE_MNG_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    default:
      return null;
  }
};

const ManageUsersFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case manageUsersActionType.LOG_MNG_USER_LIST_REQUEST:
      return logAccOptsListReq(state, action);
    // log error
    case manageUsersActionType.GET_MNG_USER_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case manageUsersActionType.GET_MNG_USER_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageUsersActionType.GET_MNG_USER_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case manageUsersActionType.RESET_MNG_USER_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const ManageUsersFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = ManageUsersFilterListConfig(state, action);
  const stateReqRes = ManageUsersFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ManageUsersFilterList;
