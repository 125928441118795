/**
 * @Description Admin module saga/watcher file.
 * @FileName adminModuleWatcher.js
 * @Author Goutam Pal- pgoutam
 */
import { put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { dataDictActionType, paymentThresholdActionType } from '../actions/actionTypes';

import { formatResponse, exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

const getResetUpsertValue = (responseData, err) => ({
  upsertRequest: null,
  upsertRequestError: err ? processError(err) : null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: responseData ? new Date() : null,
  upsertData: responseData,
});

// Data dictonary
/**
   * This generator function will invoke the api
   * @param {object} action - action which will get list data for Data Dictionary
   */
export function* getDDList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(dataDictActionType.RESET_SERVER_VALUE, null)),
    put(getAction(dataDictActionType.LOG_DD_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_DD_LIST, action.data);
    yield all([
      put(getAction(dataDictActionType.GET_DD_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(dataDictActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dataDictActionType.GET_DD_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
      * This generator function will invoke the api
      * @param {object} action - action which will get by id for Data Dictionary
      */
export function* getDDbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(dataDictActionType.RESET_SERVER_VALUE, null)),
    put(getAction(dataDictActionType.LOG_DD_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_DD_BY_ID, action.data);
    yield all([
      put(getAction(dataDictActionType.GET_DD_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(dataDictActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dataDictActionType.GET_DD_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
      * This generator function will invoke the api
      * @param {object} action - action which will update for existing Data Dictionary
      */
export function* updateDataDict(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_DD_UPDATE, action.data.formatObject);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([put(getAction(dataDictActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(dataDictActionType.GET_DD_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(dataDictActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for GEV Export Listing
   */
export function* getDDExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(dataDictActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_DD_EXPORT_LIST, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(dataDictActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

// Payment threshold
/**
    * This generator function will invoke the api
    * @param {object} action - action which will get list data for Plan code
    */
export function* getPtList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(paymentThresholdActionType.RESET_SERVER_VALUE, null)),
    put(getAction(paymentThresholdActionType.LOG_PT_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PT_LIST, action.data);
    yield all([
      put(getAction(paymentThresholdActionType.GET_PT_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(paymentThresholdActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(paymentThresholdActionType.GET_PT_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
      * This generator function will invoke the api
      * @param {object} action - action which will get by id for Plan code
      */
export function* getPtbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(paymentThresholdActionType.RESET_SERVER_VALUE, null)),
    put(getAction(paymentThresholdActionType.LOG_PT_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PT_BY_ID, action.data);
    yield all([
      put(getAction(paymentThresholdActionType.GET_PT_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(paymentThresholdActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(paymentThresholdActionType.GET_PT_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
      * This generator function will invoke the api
      * @param {object} action - action which will insert data for new Plan code
      */
export function* insertPt(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PT_INSERT, action.data.formatObject);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(paymentThresholdActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(paymentThresholdActionType.GET_PT_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(paymentThresholdActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
      * This generator function will invoke the api
      * @param {object} action - action which will update data for existing Plan code
      */
export function* updatePt(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PT_UPDATE, action.data.formatObject);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([put(getAction(paymentThresholdActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(paymentThresholdActionType.GET_PT_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(paymentThresholdActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
      * This generator function will invoke the api
      * @param {object} action - action which will delete data for existing Plan code
      */
export function* deletePt(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PT_DELETE, action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
    }
    yield all([
      put(getAction(paymentThresholdActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(paymentThresholdActionType.GET_PT_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(paymentThresholdActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(paymentThresholdActionType.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
     * This generator function will invoke the api
     * @param {object} action - action which will request for PT Export Listing
     */
export function* getPTHExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(paymentThresholdActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PT_EXPORT_LIST, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(paymentThresholdActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
