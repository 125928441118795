/* eslint-disable no-debugger */
/**
 * @Description File description.
 * @FileName fasatErrorBoundary.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 10 February, 2021 02:55:27
 * @IssueID - Issue IDs
 */
import React from 'react';
import PropTypes from 'prop-types';
import logger from '../../util/appLogger';

class FasatErrorBoundary extends React.Component {
  /**
   *
   * @param {*} props  - fallback is a required prop
   */
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * This is a lifecycle method which provide access to the rendering error.
   * This needs to be implemented for an ErrorBoundary component
   * @param {*} error - rendering error detail
   */
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return error ? { hasError: true } : { hasError: false };
  }

  /**
   * This is a life cycle method which needs to be implemented for ErrorBoundary component.
   * In case of error we can perform generic tasks like logging the error
   * @param {*} error - error which has been thrown from the rendering tree
   * @param {*} errorInfo - information regarding the error
   */
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logger.error(error, 'Error Reported From Boundary', errorInfo);
  }

  /**
   * React render method. If there is any error render
   * the fall-back UI otherwise child node
   */
  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;
    // In case of any error render the fallback component
    if (hasError) {
      // You can render any custom fallback UI
      return fallback;
    }

    return children;
  }
}
FasatErrorBoundary.propTypes = {
  fallback: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
export default FasatErrorBoundary;
