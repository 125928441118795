import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { fieldForceDeductionActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'FFDeduction';

const updateFilterConfig = (state, action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        ...state,
        systemDefaultFilter: action.data.systemDefault || null,
        userDefaultFilter: action.data.userDefault || null,
        selectedFilter,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        ...state,
        selectedFilter,
        userDefaultFilter: action.data.userDefault,
      };
    }
  }
  return {
    ...state,
    selectedFilter,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  selectedFilter: action.data ? action.data : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
});

const updateMATFilterOption = (state, action) => ({
  ...state,
  listMATColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listMATParamOptions: action.data ? action.data.detail.parameters : null,
  listMATGroupBy: action.data ? action.data.detail.groupByParameters : null,
  listMATSortOptions: action.data ? action.data.detail.sortOptions : null,
  selectedMATFilter: action.data ? action.data : null,
});

const logBcrListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const FieldForceFilterList1 = (state, action = null) => {
  switch (action.type) {
    case fieldForceDeductionActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case fieldForceDeductionActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case fieldForceDeductionActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case fieldForceDeductionActionTypes.UPDATE_MAT_TABLE_CONFIGURATION:
      return updateMATFilterOption(state, action);
    case fieldForceDeductionActionTypes.LOG_FF_LIST_REQUEST:
      return logBcrListRequest(state, action);

    case fieldForceDeductionActionTypes.RESET_DEDUCTION_PARAMS:
      return {
        ...state,
        listParamOptions: action.data,
      };
    case fieldForceDeductionActionTypes.RESET_DEDUCTION_PARAMS_COL:
      return {
        ...state,
        listColumnOrder: action.data,
      };
    case fieldForceDeductionActionTypes.RESET_DEDUCTION_PARAMS_SORT:
      return {
        ...state,
        listSortOptions: action.data,
      };
    default:
      return null;
  }
};

const FieldForceFilterList2 = (state, action = null) => {
  switch (action.type) {
    case fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST_RESPONSE:
      return {
        ...state,
        deductionlistData: action.data,
        listData: action?.data?.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FF_TASK_LIST_RESPONSE:
      return {
        ...state,
        tasklistData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.REDEFAULT_FF_TASK_RESPONSE:
      return {
        ...state,
        redefaultResData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST_RESPONSE:
      return {
        ...state,
        taskDetailsDDlistData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE:
      return {
        ...state,
        taskLocationDetailsResponseData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.SET_VALIDATE_LOCATION_ERR:
      return {
        ...state,
        validateLocation: action.data,
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FF_FIN_ARRANGMENT_LIST_RESPONSE:
      return {
        ...state,
        finArrangmentlistData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };

    default:
      return null;
  }
};

const FieldForceFilterList3 = (state, action = null) => {
  switch (action.type) {
    case fieldForceDeductionActionTypes.GET_FF_MAT_LIST_RESPONSE:
      return {
        ...state,
        matlistData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.RESET_FF_MAT_LIST_RESPONSE:
      return {
        ...state,
        matlistData: null,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FF_TRAN_DETAILS_RESPONSE:
      return {
        ...state,
        ddTranDetails: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FF_TRAN_DETAIL_BY_ID_RESPONSE_RESET:
      return {
        ...state,
        ddTranDetails: null,
        detailDataById: null,
        detailByIdRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.RESET_FF_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case fieldForceDeductionActionTypes.FF_UPSERT_VALUE:
      return {
        ...state,
        upsertFlag: true,
      };
    case fieldForceDeductionActionTypes.FF_UPSERT_VALUE_RESET:
      return {
        ...state,
        upsertFlag: false,
      };
    case fieldForceDeductionActionTypes.GET_FF_REL_LIST_RESPONSE:
      return {
        ...state,
        ffRellistData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FFBAL_PERIOD_LIST_RESPONSE:
      return {
        ...state,
        balPeriodlistData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };

    default:
      return null;
  }
};

const FieldForceFilterList4 = (state, action = null) => {
  switch (action.type) {
    case fieldForceDeductionActionTypes.GET_FFPROD_BUIS_LIST_RESPONSE:
      return {
        ...state,
        prodBuisList: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE:
      return {
        ...state,
        balPeriodYTDData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FFBAL_MONTHLY_LIST_RESPONSE:
      return {
        ...state,
        balMntlyLstData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FFBAL_SUMMARY_LIST_REQUEST:
      return {
        ...state,
        balSummaryList: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FFBAL_HIERARCHY_LIST_RESPONSE:
      return {
        ...state,
        balHierarchyList: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FFBAL_ITEM_BY_PERIOD_RESPONSE:
      return {
        ...state,
        balItemByPeriod: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FFPROD_ITEM_BY_MONTH_RESPONSE:
      return {
        ...state,
        prodItemByMonth: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FFPROD_ITEM_BY_YTD_RESPONSE:
      return {
        ...state,
        prodItemYTDData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.RESET_SERVER_VALUE_ITEM:
      return resetServerValue(state, action);

    default:
      return null;
  }
};

const FieldForceFilterList5 = (state, action = null) => {
  switch (action.type) {
    case fieldForceDeductionActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case fieldForceDeductionActionTypes.FF_TRANSFER_SUCCESS:
      return {
        ...state,
        status: 'Success',
      };
    case fieldForceDeductionActionTypes.FF_TRANSFER_ERROR:
      return {
        ...state,
        status: 'Faliure',
      };
    case fieldForceDeductionActionTypes.CLEAR_MASS_TRANSFER_SUCCESS:
      return {
        ...state,
        status: null,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const FieldForceFilterList6 = (state, action = null) => {
  switch (action.type) {
    case fieldForceDeductionActionTypes.GET_FF_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case fieldForceDeductionActionTypes.GET_FF_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceDeductionActionTypes.GET_FF_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

const FieldForceDeduction = (state = { ...basicFilterInitialState,
  validateLocation: false,
  balMntlyLstData: null,
  prodBuisList: null },
action = null) => {
  const ff2 = FieldForceFilterList2(state, action);
  if (ff2 !== null) {
    return ff2;
  }
  const ff1 = FieldForceFilterList1(state, action);
  if (ff1 !== null) {
    return ff1;
  }
  const ff3 = FieldForceFilterList3(state, action);
  if (ff3 !== null) {
    return ff3;
  }
  const ff5 = FieldForceFilterList5(state, action);
  if (ff5 !== null) {
    return ff5;
  }
  const ff4 = FieldForceFilterList4(state, action);
  if (ff4 !== null) {
    return ff4;
  }
  const ff6 = FieldForceFilterList6(state, action);
  if (ff6 !== null) {
    return ff6;
  }
  return state;
};

export default FieldForceDeduction;
