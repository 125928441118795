// Interface Setup Action types
export const interfaceSetupActionTypeValue = {
  UPDATE_IS_COLUMN_CONFIG: 'UPDATE_IS_COLUMN_CONFIG',
  LOG_IS_LIST_REQUEST: 'LOG_IS_LIST_REQUEST',
  GET_IS_LIST_REQUEST: 'GET_IS_LIST_REQUEST',
  GET_IS_LIST_RESPONSE: 'GET_IS_LIST_RESPONSE',
  GET_IS_LIST_ERROR: 'GET_IS_LIST_ERROR',
  GET_IS_LIST_LOG_REQ: 'GET_IS_LIST_LOG_REQ',
  SET_IS_DEFAULT_REQ: 'SET_IS_DEFAULT_REQ',
  GET_DEFAULT_FILTER_SETTING: 'GET_DEFAULT_FILTER_SETTING',
  // Get edit item by ID
  LOG_IS_BY_ID_REQUEST: 'LOG_IS_BY_ID_REQUEST',
  GET_IS_BY_ID_REQUEST: 'GET_IS_BY_ID_REQUEST',
  GET_IS_BY_ID_RESPONSE: 'GET_IS_BY_ID_RESPONSE',
  GET_IS_BY_ID_ERROR: 'GET_IS_BY_ID_ERROR',
  GET_IS_BY_ID_LOG_REQ: 'GET_IS_BY_ID_LOG_REQ',
  // Get Interface Setup detail
  GET_IS_REQUEST: 'GET_IS_REQUEST',
  GET_IS_RESPONSE: 'GET_IS_RESPONSE',
  GET_IS_ERROR: 'GET_IS_ERROR',
  // Add/Update PLAN CODE detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_IS_REQUEST: 'INSERT_IS_REQUEST',
  INSERT_IS_RESPONSE: 'UPSERT_IS_RESPONSE',
  INSERT_IS_ERROR: 'UPSERT_IS_ERROR',
  UPDATE_IS_REQUEST: 'UPDATE_IS_REQUEST',
  UPDATE_IS_RESPONSE: 'UPDATE_IS_RESPONSE',
  UPDATE_IS_ERROR: 'UPDATE_IS_ERROR',
  // Delete PLAN CODE
  DELETE_IS_REQUEST: 'DELETE_IS_REQUEST',
  DELETE_IS_RESPONSE: 'DELETE_IS_RESPONSE',
  DELETE_IS_ERROR: 'DELETE_IS_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Update Filter Settings
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_IM_TABLE_CONFIGURATION',
  UPDATE_IS_FILTER_CONFIG: 'UPDATE_IS_FILTER_CONFIG',
  GET_IS_EXPORT_REQUEST: 'GET_IS_EXPORT_REQUEST',
  REMOVE_IS_USER_DEFAULT_FILTER: 'REMOVE_IS_USER_DEFAULT_FILTER',
  SET_IS_DETAILS_RESPONSE: 'SET_IS_DETAILS_RESPONSE',
  // Reset Store
  RESET_IS_STORE: 'RESET_IS_STORE',
  // Lookup
  GET_IS_LOOKUP_REQUEST: 'GET_IS_LOOKUP_REQUEST',
  RESET_IS_LOOKUPDATA: 'RESET_IS_LOOKUPDATA',
  // Export InterfaceMapping
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
export const ffStructureActionTypesValue = {
  GET_STRUCTURE_LIST_REQUEST: 'GET_STRUCTURE_LIST_REQUEST',
  UPDATE_FF_STRUCTURE_COLUMN_CONFIG: 'UPDATE_FF_STRUCTURE_COLUMN_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_FF_STRUCTURE_TABLE_CONFIGURATION',
  UPDATE_FF_STRUCTURE_FILTER_CONFIG: 'UPDATE_FF_STRUCTURE_FILTER_CONFIG',
  GET_CS_EXPORT_REQUEST: 'GET_CS_EXPORT_REQUEST',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  GET_FF_STRUCTURE_LIST_RESPONSE: 'GET_FF_STRUCTURE_LIST_RESPONSE',
  UPDATE_FF_STRUCTURE: 'UPDATE_FF_STRUCTURE',
  DELETE_FF_STRUCTURE_REQUEST: 'DELETE_FF_STRUCTURE_REQUEST',
  GET_FF_STRUCTURE_EXPORT: 'GET_FF_STRUCTURE_EXPORT',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  RESET_FF_STRUCTURE_INQUIRY_STORE: 'RESET_FF_STRUCTURE_INQUIRY_STORE',
  GET_FF_SUBMISSION_ERROR: 'GET_FF_SUBMISSION_ERROR',
  RESET_FF_STRUCTURE_SUBMISSION_ERROR: 'RESET_FF_STRUCTURE_SUBMISSION_ERROR',
  GET_FF_STRUCTURE_LIST_BY_ID: 'GET_FF_STRUCTURE_LIST_BY_ID',
  GET_FF_STRUCTURE_BY_ID_RESPONSE: 'GET_FF_STRUCTURE_BY_ID_RESPONSE',
};

// manage user action types
export const manageUsersActionTypeValue = {
  LOG_MNG_USER_LIST_REQUEST: 'LOG_MNG_USER_LIST_REQUEST',
  GET_MNG_USER_LIST_REQUEST: 'GET_MNG_USER_LIST_REQUEST',
  GET_NOTIF_USERS_LIST_REQUEST: 'GET_NOTIF_USERS_LIST_REQUEST',
  GET_MNG_USER_LIST_RESPONSE: 'GET_MNG_USER_LIST_RESPONSE',
  GET_MNG_USER_LIST_ERROR: 'GET_MNG_USER_LIST_ERROR',
  GET_MNG_USER_LIST_LOG_REQ: 'GET_MNG_USER_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_MNG_USER_FILTER_CONFIG: 'UPDATE_MNG_USER_FILTER_CONFIG',
  GET_MNG_USER_EXPORT_REQUEST: 'GET_MNG_USER_EXPORT_REQUEST',
  REMOVE_MNG_USER_DEFAULT_FILTER: 'REMOVE_MNG_USER_DEFAULT_FILTER',
  // Get Edit Item by ID
  LOG_MNG_USER_BY_ID_REQUEST: 'LOG_MNG_USER_BY_ID_REQUEST',
  GET_MNG_USER_BY_ID_REQUEST: 'GET_MNG_USER_BY_ID_REQUEST',
  GET_MNG_USER_BY_ID_RESPONSE: 'GET_MNG_USER_BY_ID_RESPONSE',
  GET_MNG_USER_BY_ID_ERROR: 'GET_MNG_USER_BY_ID_ERROR',
  GET_MNG_USER_BY_ID_LOG_REQ: 'GET_MNG_USER_BY_ID_LOG_REQ',
  GET_MNG_USER_SECURITY_GRP_REQUEST: 'GET_MNG_USER_SECURITY_GRP_REQUEST',
  GET_MNG_USER_SECURITY_GRP_RESPONSE: 'GET_MNG_USER_SECURITY_GRP_RESPONSE',
  // Get system parameters detail
  GET_MNG_USER_REQUEST: 'GET_MNG_USER_REQUEST',
  GET_MNG_USER_RESPONSE: 'GET_MNG_USER_RESPONSE',
  GET_MNG_USER_ERROR: 'GET_MNG_USER_ERROR',
  // Add/Update system parameter
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_MNG_USER_REQUEST: 'UPSERT_MNG_USER_REQUEST',
  INSERT_MNG_USER_REQUEST: 'INSERT_MNG_USER_REQUEST',
  INSERT_MNG_USER_ERROR: 'INSERT_MNG_USER_ERROR',
  UPDATE_MNG_USER_REQUEST: 'UPDATE_MNG_USER_REQUEST',
  UPDATE_MNG_USER_RESPONSE: 'UPDATE_MNG_USER_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_MNG_USER_RESPONSE: 'UPSERT_MNG_USER_RESPONSE',

  // Delete system parameter
  DELETE_MNG_USER_REQUEST: 'DELETE_MNG_USER_REQUEST',
  DELETE_MNG_USER_RESPONSE: 'DELETE_MNG_USER_RESPONSE',
  DELETE_MNG_USER_ERROR: 'DELETE_MNG_USER_ERROR',
  // Detail system parameter
  SET_MNG_USER_DETAILS_RESPONSE: 'SET_MNG_USER_DETAILS_RESPONSE',
  // Reset store
  RESET_MNG_USER_STORE: 'RESET_MNG_USER_STORE',
  // Export SystemParameters
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// manage level action types
export const manageLevelActionTypeValue = {
  LOG_MNG_LEVEL_LIST_REQUEST: 'LOG_MNG_LEVEL_LIST_REQUEST',
  GET_MNG_LEVEL_LIST_RESPONSE: 'GET_MNG_LEVEL_LIST_RESPONSE',
  GET_MNG_LEVEL_LIST_ERROR: 'GET_MNG_LEVEL_LIST_ERROR',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  GET_MNG_LEVEL_LIST_REQUEST: 'GET_MNG_LEVEL_LIST_REQUEST',
  // Add/Update system parameter
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Reset store
  RESET_MNG_LEVEL_STORE: 'RESET_MNG_LEVEL_STORE',
  RESET_REQ_PROSS_ON: 'RESET_REQ_PROSS_ON',
  // Export SystemParameters
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// fieldForceSecurity
export const fieldForceSecurityActionTypesValue = {
  UPDATE_FF_SECURITY_COLUMN_CONFIG: 'UPDATE_FF_SECURITY_COLUMN_CONFIG',
  LOG_FF_SECURITY_LIST_REQUEST: 'LOG_FF_SECURITY_LIST_REQUEST',
  GET_FF_SECURITY_LIST_REQUEST: 'GET_FF_SECURITY_LIST_REQUEST',
  GET_FF_SECURITY_LIST_RESPONSE: 'GET_FF_SECURITY_LIST_RESPONSE',
  GET_FF_SECURITY_LIST_ERROR: 'GET_FF_SECURITY_LIST_ERROR',
  GET_FF_SECURITY_LIST_LOG_REQ: 'GET_FF_SECURITY_LIST_LOG_REQ',
  GET_DEFAULT_FILTER_SETTING: 'GET_DEFAULT_FILTER_SETTING',
  GET_FF_SECURITY_ONLOAD_RESPONSE: 'GET_FF_SECURITY_ONLOAD_RESPONSE',
  GET_FF_SECURITY_ASSIGNED: 'GET_FF_SECURITY_ASSIGNED',
  GET_FF_SECURITY_GET_NAME_RESPONSE: 'GET_FF_SECURITY_GET_NAME_RESPONSE',
  GET_FF_SECURITY_GET_NAME_REQUEST: 'GET_FF_SECURITY_GET_NAME_REQUEST',
  // Get fieldForceSecurity detail
  GET_FF_SECURITY_REQUEST: 'GET_FF_SECURITY_REQUEST',
  GET_FF_SECURITY_RESPONSE: 'GET_FF_SECURITY_RESPONSE',
  GET_FF_SECURITY_ERROR: 'GET_FF_SECURITY_ERROR',
  // Add/Update fieldForceSecurity detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_FF_SECURITY_REQUEST: 'INSERT_FF_SECURITY_REQUEST',
  INSERT_FF_SECURITY_RESPONSE: 'UPSERT_FF_SECURITY_RESPONSE',
  INSERT_FF_SECURITY_ERROR: 'UPSERT_FF_SECURITY_ERROR',
  UPDATE_FF_SECURITY_REQUEST: 'UPDATE_FF_SECURITY_REQUEST',
  UPDATE_FF_SECURITY_RESPONSE: 'UPDATE_FF_SECURITY_RESPONSE',
  UPDATE_FF_SECURITY_ERROR: 'UPDATE_FF_SECURITY_ERROR',
  // Delete fieldForceSecurity
  DELETE_FF_SECURITY_REQUEST: 'DELETE_FF_SECURITY_REQUEST',
  DELETE_FF_SECURITY_RESPONSE: 'DELETE_FF_SECURITY_RESPONSE',
  DELETE_FF_SECURITY_ERROR: 'DELETE_FF_SECURITY_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Update Filter Settings
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_FF_SECURITY_TABLE_CONFIGURATION',
  UPDATE_FF_SECURITY_FILTER_CONFIG: 'UPDATE_FF_SECURITY_FILTER_CONFIG',
  GET_FF_SECURITY_EXPORT_REQUEST: 'GET_FF_SECURITY_EXPORT_REQUEST',
  REMOVE_FF_SECURITY_USER_DEFAULT_FILTER: 'REMOVE_FF_SECURITY_USER_DEFAULT_FILTER',
  SET_FF_SECURITY_DETAILS_RESPONSE: 'SET_FF_SECURITY_DETAILS_RESPONSE',
  // Reset Store
  RESET_FF_SECURITY_STORE: 'RESET_FF_SECURITY_STORE',
  // Export SystemParameters
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
