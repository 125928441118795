/**
 * @Description File description.
 * @FileName english.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 19 February, 2021 00:36:44
 * @IssueID - Issue IDs
*/

const agencyResourceEnglish = {
  'agency.bcr.menu': 'Business card rules',
  'agency.menu': 'Agency',
  'distributor.menu': 'Distributor',
};

export default agencyResourceEnglish;
