import { basicInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { manageGroupsActionType } from '../actions/actionTypes';

export const REDUCER_NAME = 'ManageGroups';

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const logMGListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});
const setIfExsists = (state, action) => ({
  ...state,
  relationalTable: action.data,
});
const setIfExsistsErr = (state, action) => ({
  ...state,
  relationalTableError: action.data,
});
const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  grpDetailDataById: action.data,
});
const getMngGrpDescResponse = (state, action) => ({
  ...state,
  manageGrpDesc: action.data[0],
});

const manageApplicationReducer = (state = { ...basicInitialState }, action = null) => {
  switch (action.type) {
    case manageGroupsActionType.GET_MA_RESPONSE:
      return {
        ...state,
        maData: action.data.data,
        maDataRequestError: null,
        maDataRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.RESET_MA_DATA:
      return {
        ...state,
        maData: null,
        maUpdateData: null,
        maUpdateRequestError: null,
        maDataRequestError: null,
        maDataRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.UPDATE_MA_RESPONSE:
      return {
        ...state,
        maUpdateData: action.data,
        maUpdateRequestError: null,
        maUpdateRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.UPDATE_MA_ERROR:
      return {
        ...state,
        maUpdateRequestError: action.data.serverRequestError,
        maUpdateRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.RESET_CONCURRENCY_FLAG:
      return {
        ...state,
        concurrencyFlag: action.data,
      };
    case manageGroupsActionType.RESET_SECURITY_PAYMENT_INFO:
      return {
        ...state,
        paymentSeqInfo: action.data,
      };
    case manageGroupsActionType.PAYMENT_UPSERT_DATA:
      return {
        ...state,
        paymentFlag: action.data,
      };
    case commonTypes.RESETSTORE:
      return basicInitialState;
    default:
      return state;
  }
};
const manageGroupListConfig = (state = { ...basicInitialState }, action = null) => {
  switch (action.type) {
    case manageGroupsActionType.GET_MNG_GRP_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case manageGroupsActionType.SET_MANAGE_GRP_DESC_RESPONSE:
      return getMngGrpDescResponse(state, action);
    case manageGroupsActionType.GET_MNG_GRP_USER_EXSISTS_RESPONSE:
      return setIfExsists(state, action);
    case manageGroupsActionType.GET_MNG_GRP_USER_EXSISTS_ERROR:
      return setIfExsistsErr(state, action);
    case manageGroupsActionType.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    // reset Upset Value
    case manageGroupsActionType.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
      };
    default:
      return null;
  }
};
const manageGroupRequestConfig = (state = { ...basicInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case manageGroupsActionType.LOG_MG_LIST_REQUEST:
      return logMGListReq(state, action);
    // log error
    case manageGroupsActionType.GET_MG_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case manageGroupsActionType.GET_MG_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.RESET_MG_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case manageGroupsActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case manageGroupsActionType.GET_SG_LIST_RESPONSE:
      return {
        ...state,
        upsertData: action.data,
        upsertRequestError: null,
        upsertRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.RESET_REQ_PROSS_ON:
      return {
        ...state,
        upsertRequestProcessedOn: new Date(),
      };
    case manageGroupsActionType.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    default:
      return null;
  }
};
const ManageGroups = (state = {
  ...basicInitialState, concurrencyFlag: false, paymentSeqInfo: null, paymentFlag: null,
}, action = null) => {
  const stateConfig = manageGroupListConfig(state, action);
  const stateReqRes = manageGroupRequestConfig(state, action);
  const stateApp = manageApplicationReducer(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  if (stateApp !== null) {
    return stateApp;
  }
  return state;
};
export default ManageGroups;
