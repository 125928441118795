/* eslint-disable no-unused-vars */

import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { fpaMonitoringAction } from '../actions/actionTypes';

import { Logger, exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

export function* getFPAList(action) {
  Logger.verbose(`Action Detail For Get Activity Unprocessed : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fpaMonitoringAction.RESET_SERVER_VALUE, null)),
    put(getAction(fpaMonitoringAction.LOG_FPA_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.FPA_MONITORING_LIST, action.data);
    yield all([
      put(getAction(fpaMonitoringAction.GET_FPA_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fpaMonitoringAction.GET_FPA_LIST_ERROR, err)),
      put(getAction(fpaMonitoringAction.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getFPABatchNum(action) {
  Logger.verbose(`Action Detail For batch numbers : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fpaMonitoringAction.RESET_SERVER_VALUE, null)),
    put(getAction(fpaMonitoringAction.LOG_FPA_BATCH_NUM_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  const initApiUrl = moduleAPI.FPA_MONITORING_JOBSTATUS_LIST.endpoint;
  try {
    const jobId = `?jobRequestId=${action.data}`;
    const updatedApiUrl = initApiUrl + jobId;
    const apiEndpoint = { ...moduleAPI.FPA_MONITORING_JOBSTATUS_LIST };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    const responseDataEligible = {
      data: responseData.eligible,
    };
    const responseDataNonEligible = {
      data: responseData.nonEligible,
    };
    yield all([
      put(getAction(fpaMonitoringAction.GET_FPA_BATCH_NUM_RESPONSE, responseData)),
      put(getAction(fpaMonitoringAction.GET_FPA_BATCH_NUM_ELIGIBLE, responseDataEligible)),
      put(getAction(fpaMonitoringAction.GET_FPA_BATCH_NUM_NONELIGIBLE, responseDataNonEligible)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fpaMonitoringAction.GET_FPA_BATCH_NUM_ERROR, err)),
      put(getAction(fpaMonitoringAction.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getAdjErrorXRecord(action) {
  Logger.verbose(`Action Detail For batch numbers : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fpaMonitoringAction.RESET_SERVER_VALUE, null)),
    put(getAction(fpaMonitoringAction.LOG_FPA_ADJXERR_NUM_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.FPA_MONITORING_ADJXRECORDLIST_LIST, action.data);
    yield all([
      put(getAction(fpaMonitoringAction.GET_FPA_ADJXERR_NUM_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fpaMonitoringAction.GET_FPA_ADJXERR_NUM_ERROR, err)),
      put(getAction(fpaMonitoringAction.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  yield takeEvery(fpaMonitoringAction.GET_FPA_LIST_REQUEST, getFPAList);
  yield takeEvery(fpaMonitoringAction.GET_FPA_BATCH_NUM_REQUEST, getFPABatchNum);
  yield takeEvery(fpaMonitoringAction.GET_FPA_ADJXERR_NUM_REQUEST, getAdjErrorXRecord);
}

export default moduleWatcher;
