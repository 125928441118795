/**
 * @Description This is FasatDetailSection container page
 * @FileName fasatDetailSection.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 27 January, 2021 11:15:07
 * @IssueID CDTFASATMOD-502
 */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../../index.scss';
import './fasatDetailSection.scss';

const FasatDetailSection = ({ information }) => (
  <div className="detailSection f-align-left" data-testid="FasatDetailSectionComponent">
    {information.map((info) => (
      <div className="f-padding-b-12" key={info.type}>
        <span className="f-demi-bold">{info.type}</span>
        <span className="f-demi-bold">
          : &nbsp;
        </span>
        <span>
          {info.value}
        </span>
      </div>
    ))}
  </div>
);

FasatDetailSection.propTypes = {
  information: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  })),
};

FasatDetailSection.defaultProps = {
  information: [],
};

export default FasatDetailSection;
