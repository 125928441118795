import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { dailyProcessActionType } from '../actions/actionTypes';

export const REDUCER_NAME = 'DailyProcessFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const logDPListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const logDPStartProcesstReq = (state, action) => ({
  ...state,
  startProcessRequest: action.data ? action.data : state.listRequest,
  startProcessRequestedOn: new Date(),
  loading: true,
});

const logDPBatchStatusReq = (state, action) => ({
  ...state,
  batchStatusRequest: action.data ? action.data : state.listRequest,
  batchStatusRequestedOn: new Date(),
  loading: true,
});

const updateTableConfig = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  selectedFilter: action.data ? action.data : null,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const resetResumeValue = (state, action) => ({
  ...state,
  resumeData: null,
  resumeRequestError: action.data && action.data.resumeRequestError,
  loading: false,
});

const logHistoryListReq = (state, action) => ({
  ...state,
  historyRequest: action.data ? action.data : state.historyRequest,
  historyRequestedOn: new Date(),
  loading: true,
});

const dailyProcessFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case dailyProcessActionType.UPDATE_DP_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case dailyProcessActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case dailyProcessActionType.REMOVE_DP_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case dailyProcessActionType.RESET_DP_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case dailyProcessActionType.RESET_DP_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    case dailyProcessActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case dailyProcessActionType.GET_HISTORY_DATA_RESPONSE:
      return {
        ...state,
        historyFieldData: action.data,
        historyFieldRequestError: null,
        historyFieldRequestProcessedOn: new Date(),
        loading: false,
      };
    case dailyProcessActionType.LOG_HISTORY_LIST_REQUEST:
      return logHistoryListReq(state, action);
    case dailyProcessActionType.GET_HISTORY_LIST_RESPONSE:
      return {
        ...state,
        historyData: action.data,
        historyRequestError: null,
        historyRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

const dailyProcessListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case dailyProcessActionType.LOG_DP_LIST_REQUEST:
      return logDPListReq(state, action);
    // log error
    case dailyProcessActionType.GET_DP_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case dailyProcessActionType.GET_DP_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case dailyProcessActionType.LOG_DP_START_PROCESS:
      return logDPStartProcesstReq(state, action);
    // log error
    case dailyProcessActionType.GET_DP_START_PROCESS_ERROR:
      return {
        ...state,
        startProcessRequestError: action.data,
        startProcessRequestProcessedOn: new Date(),
        loading: false,
      };
    case dailyProcessActionType.LOG_DP_BATCH_STATUS:
      return logDPBatchStatusReq(state, action);
      // log error
    case dailyProcessActionType.GET_DP_BATCH_STATUS_ERROR:
      return {
        ...state,
        batchStatusRequestError: action.data,
        batchStatusRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};
const dailyProcessListREQResponse = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // store response
    case dailyProcessActionType.GET_DP_START_PROCESS_RESPONSE:
      return {
        ...state,
        startProcessData: action.data,
        startProcessRequestError: null,
        startProcessRequestProcessedOn: new Date(),
        loading: false,
      };
    case dailyProcessActionType.GET_DP_BATCH_STATUS_RESPONSE:
      return {
        ...state,
        batchStatusData: action.data,
        batchStatusRequestError: null,
        batchStatusRequestProcessedOn: new Date(),
        loading: false,
      };
    case dailyProcessActionType.GET_DP_START_PROCESS_TABLE_RES:
      return {
        ...state,
        startProcessTableData: action.data,
        startProcessTableError: null,
        startProcessTableProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const dailyProcessResumeResponse = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // store response
    case dailyProcessActionType.RESET_RESUME_VALUE:
      return resetResumeValue(state, action);
    case dailyProcessActionType.GET_DP_RESUME_RESPONSE:
      return {
        ...state,
        resumeData: action.data,
        resumeRequestError: null,
        resumeRequestProcessedOn: new Date(),
        loading: false,
      };
    case dailyProcessActionType.GET_DP_RESUME_ERROR:
      return {
        ...state,
        resumeRequestError: action.data,
        resumeRequestProcessedOn: new Date(),
        loading: false,
      };
    case dailyProcessActionType.SET_DP_SEQUENCE_RESPONSE:
      return {
        ...state,
        sequenceResponse: action.data,
      };
    default:
      return null;
  }
};

const DailyProcessFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = dailyProcessFilterListConfig(state, action);
  const stateReqRes = dailyProcessListREQRES(state, action);
  const stateReqResponse = dailyProcessListREQResponse(state, action);
  const stateResumeResponse = dailyProcessResumeResponse(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  if (stateReqResponse !== null) {
    return stateReqResponse;
  }
  if (stateResumeResponse !== null) {
    return stateResumeResponse;
  }
  return state;
};

export default DailyProcessFilterList;
