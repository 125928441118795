import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { manageUsersActionType } from '../actions/actionTypes';
import { commonTypes } from '../../../../common/redux/actions/commonActions';

const ManageUsersList = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    // reset Upset Value
    case manageUsersActionType.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
      };
    // reset Delete Value
    case manageUsersActionType.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case manageUsersActionType.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case manageUsersActionType.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: action.data && action.data.serverRequestError,
      };
    case manageUsersActionType.GET_MNG_USER_SECURITY_GRP_RESPONSE:
      return {
        isInternal: action.data,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default ManageUsersList;
