/* eslint-disable react/prop-types */
/**
 * @Description Container for delete business card rule list
 * @FileName deleteSystemParameter.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 27 April, 2021 03:26:34
 * @IssueID 1069
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FasatConfirmationMsg, showFasatNotification, FasatMessageCard } from '../../../../common/components';
import { replaceString } from '../../../../common/util';
import { globalConstants, staticCommonLabelKeys } from '../../../../common/moduleConstants';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import { fieldForceActionTypes } from '../../redux';
import { staticLabelKeys } from '../../moduleConstants';
import { fetchServerError } from '../../../../common/components/fasatPage/fasatPage';

const { serverErrorMsgContainer, notificationTime, replaceWith, replace } = globalConstants;

/**
   * @param {Array} options
   * Validate all form data and lift event handleDelete
   * @returns {Array}
   */
// eslint-disable-next-line no-unused-vars
export const handleDeleteClick = ({ formData, reqObj, dispatch }) => {
  if (formData) {
    dispatch(
      getAction(fieldForceActionTypes.DELETE_DEDUCTION_REQUEST,
        { formatObject: formData, reqObject: reqObj }),
    );
  }
};

const closeIfCancelEventData = (cancelEventData) => {
  if (cancelEventData) {
    cancelEventData.close();
  }
};

const DeleteDeduction = ({ formData, onDeleteTopic, onCancelTopic, reqObj, showSuccessMsg }) => {
  const [serverErrors, setServerErrors] = useState([]);
  const [t] = useTranslation();
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);
  const [cancelEventData, setCancelEventData] = useState(null);
  const [saveEventData, setSaveEventData] = useState(null);
  const dispatch = useDispatch();
  const cliAddDeletConfirmationMsg = t(staticLabelKeys.DE_DEL_CONFIRM);
  const cliAddDeleteSuccessMsg = t(staticLabelKeys.DE_DELETE_SUCCESS);
  const deleteRequestError = useSelector((state) => state.FieldForce.deleteRequestError);
  const deleteData = useSelector((state) => state.FieldForce.deleteData);
  useEffect(() => {
    if (deleteData) {
      saveEventData.close();
      const successMsg = cliAddDeleteSuccessMsg;
      showFasatNotification(successMsg, notificationTime);
      showSuccessMsg();
    }
    if (deleteRequestError) {
      setServerErrors(deleteRequestError);
    }
  }, [deleteRequestError, deleteData]);
  useEffect(() => {
    if (onDeleteTopic && onDeleteTopic !== '' && (saveSubscriptionIds || saveSubscriptionIds.length === 0)) {
      const subId = PubSub.subscribe(onDeleteTopic, (msg, data) => {
        setSaveEventData(data);
        handleDeleteClick({ formData, reqObj, dispatch });
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    if (onCancelTopic && onCancelTopic !== '') {
      const subId = PubSub.subscribe(onCancelTopic, (msg, data) => {
        setCancelEventData(data);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);
  useEffect(() => closeIfCancelEventData(cancelEventData), [cancelEventData]);
  return (
    <div>
      {serverErrors.length > 0 && (
      <div className={serverErrorMsgContainer}>
        <FasatMessageCard
          data-testid="deleteClientAdd"
          titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
          variant="primary_alert"
        >
          {serverErrors.map((errItem) => (
            fetchServerError(t, errItem)
          ))}
        </FasatMessageCard>
      </div>
      )}
      <FasatConfirmationMsg msg={replaceString(cliAddDeletConfirmationMsg, replaceWith, replace)} />
    </div>
  );
};

DeleteDeduction.propTypes = {
  onDeleteTopic: PropTypes.string,
  onCancelTopic: PropTypes.string,
  showSuccessMsg: PropTypes.func,
  reqObj: PropTypes.shape({}),
  formData: PropTypes.arrayOf(PropTypes.shape({
    // licLoc: PropTypes.string,
    // licClsOfBus: PropTypes.string,
    // licApprovalExp: PropTypes.string,
    // licEffDt: PropTypes.string,
    // licExpDt: PropTypes.string,
    // licOthReqt: PropTypes.string,
    // licOthReqtType: PropTypes.string,
    // licOthReqtExp: PropTypes.string,
    // licRenStat: PropTypes.string,
    // licStatusChangeDate: PropTypes.string,
    // licNum: PropTypes.string,
    // licCompSponsor: PropTypes.string,
    // licLev: PropTypes.string,
    // licNotes: PropTypes.string,
    // licCountry: PropTypes.string,
  })),
};

DeleteDeduction.defaultProps = {
  onCancelTopic: '',
  onDeleteTopic: '',
  showSuccessMsg: () => undefined,
  reqObj: {},
  formData: {
    // licLoc: '',
    // licClsOfBus: '',
    // licApprovalExp: '',
    // licEffDt: '',
    // licExpDt: '',
    // licOthReqt: '',
    // licOthReqtType: '',
    // licOthReqtExp: '',
    // licRenStat: '',
    // licStatusChangeDate: '',
    // licNum: '',
    // licCompSponsor: '',
    // licLev: '',
    // licNotes: '',
    // licCountry: '',
  },
};

export default DeleteDeduction;
