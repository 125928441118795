/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { fieldForcePaymentsActionTypes } from '../actions/actionTypes';
import { Logger, processError } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';

export function* paymentsOnLoad(action) {
  Logger.verbose(`Action Detail For Get COMPCAL List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_PAYMENTS_ONLOAD,
      action.data);
    const updateAction = action.data.isUpdate ? fieldForcePaymentsActionTypes.PAYMENTS_UPDATE_DEFAULT_DATA
      : fieldForcePaymentsActionTypes.PAYMENTS_ON_LOAD_RESPONSE;
    yield all([
      put(getAction(updateAction, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForcePaymentsActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getPaymnetsAddress(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_PAYMENTS_ADDRESS,
      action.data);
    yield all([
      put(getAction(fieldForcePaymentsActionTypes.PAYMENTS_ADDRESS_DETAILS_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForcePaymentsActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* processPayments(action) {
  const resetValues = {
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(fieldForcePaymentsActionTypes.RESET_SERVER_VALUE, resetValues)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_PAYMENTS_PROCESS,
      action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(fieldForcePaymentsActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
        put(getAction(fieldForcePaymentsActionTypes.PROCESS_VALUE, responseData)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForcePaymentsActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
function* moduleWatcher() {
  yield takeEvery(fieldForcePaymentsActionTypes.PAYMENTS_ON_LOAD_REQUEST, paymentsOnLoad);
  yield takeEvery(fieldForcePaymentsActionTypes.PAYMENTS_ADDRESS_DETAILS_REQUEST, getPaymnetsAddress);
  yield takeEvery(fieldForcePaymentsActionTypes.PAYMENTS_PROCESS_REQUEST, processPayments);
}

export default moduleWatcher;
