/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { cliLicActionTypes, fieldForceActionTypes } from '../actions/actionTypes';
import { Logger, processError, renderFileName, exportErrorMsg } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';
import logger from '../../../../common/util/appLogger';

// notes
export function* getFFNotesList(action) {
  Logger.verbose(`Action Detail For Get FF Notes List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_NOTES_LIST, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFNotesByID(action) {
  Logger.verbose(`Action Detail For Get FF Notes List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_GET_NOTES_BY_ID,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* insertFFNotes(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_ADD_NOTES,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_NOTES_DETAIL_REQUEST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* updateFFNotes(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_EDIT_NOTES,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_NOTES_DETAIL_REQUEST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deleteFFNotes(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_DELETE_NOTES,
      action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_NOTES_DETAIL_REQUEST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* exportFFNotes(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_EXPORT_NOTES,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * Set Notes concurrency
 *
 * @export
 * @param {*} action metadata
 */
export function* setFFNotesConcurrency(action) {
  Logger.verbose(`Action Detail For Get FF concurrency Option : ${JSON.stringify(action)}`);
  yield put(processingStarted());
  try {
    yield call(invokeApi, moduleAPI.FF_NOTES_LIST_BY_ID, action.data);
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(processingCompleted()),
    ]);
  }
}

// administration
export function* getFFAdmin(action) {
  Logger.verbose(`Action Detail For Get FF Admin description : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST)),
  ]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADMIN.endpoint;
  try {
    const queryParam = `?orgLevel=${action.data.orgLevel}&orgCode=${action.data.orgCode}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADMIN.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_ADMIN.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_ADMIN);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_ADMIN.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_ADMIN.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* updateFFAdmin(action) {
  Logger.verbose(`Action Detail For FF Administration : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    let responseData;
    if (action.data.addNewFF) {
      responseData = yield call(invokeApi,
        moduleAPI.ADMINISTRATION_FF_ADD_ADMIN,
        action.data.formatObject);
    } else {
      responseData = yield call(invokeApi,
        moduleAPI.ADMINISTRATION_FF_EDIT_ADMIN,
        action.data.formatObject);
    }
    // const responseData = yield call(invokeApi,
    //   moduleAPI.ADMINISTRATION_FF_EDIT_ADMIN,
    //   action.data.formatObject);
    if (responseData) {
      const resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      // yield put(getAction(fieldForceActionTypes.GET_FF_ADDRESS_DETAIL_REQUEST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* insertFFDeduction(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_DEDUCTION_ADD,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        // put(getAction(clientActionsTypes.GET_BCR_LIST_REQUEST, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

export function* getFFAdminDebtRef(action) {
  Logger.verbose(`Action Detail For Get FF admin description : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST)),
  ]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADMIN_DEBTREF.endpoint;
  try {
    const queryParam = `?locale=${action.data.locale}&orgType=${action.data.orgType}`;// UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADMIN_DEBTREF.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_ADMIN_DEBTREF.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_GET_ADMIN_DEBTREF,
      action.data.formatObject);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_ADMIN_DEBTREF.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_ADMIN_DEBTREF_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_ADMIN_DEBTREF.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

/**
 * Set Administration concurrency
 *
 * @export
 * @param {*} action metadata
 */
export function* setFFAdminConcurrency(action) {
  Logger.verbose(`Action Detail For Get FF concurrency Option : ${JSON.stringify(action)}`);
  yield put(processingStarted());
  try {
    yield call(invokeApi, moduleAPI.FF_ADMIN_LIST_BY_ID, action.data);
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_CONCURRENCY_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFDeductionItem(action) {
  const lockInfo = action.data.reqObjForId.lock;
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(clientAddressActionTypes.LOG_TEMPADJ_BY_ID_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_DEDUCTION_ITEM_BY_ID,
      action.data.reqObjForId);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_BY_ID_RESPONSE, { ...responseData, comboLock: lockInfo })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
export function* updateFFDeduction(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_DEDUCTION_UPDATE,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        // put(getAction(clientActionsTypes.GET_BCR_LIST_REQUEST, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getDeductionFFName(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(clientAddressActionTypes.LOG_TEMPADJ_BY_ID_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_DEDUCTION_AGENT_NAME,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_BY_ID_NAME, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
export function* getTransactionLookList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(clientAddressActionTypes.LOG_TEMPADJ_BY_ID_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_DEDUCTION_TRANSACTION_LIST,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_TRANSACTION_LOOKUP, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
export function* deleteDeduction(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_DEDUCTION_TRANSACTION_DELETE,
      action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_LIST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deductionActiveCheck(action) {
  yield all([
    put(getAction(fieldForceActionTypes.DEDUCTION_ACTIVE_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FINARRANGEMENT_DEDUCTION_ID, action.data);

    yield put(getAction(fieldForceActionTypes.DEDUCTION_ACTIVE_VALUE, responseData));
  } catch (err) {
    logger.verbose('err', err);
  }
}

export function* getDeductionFFExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_DEDUCTION_FF_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFTaskList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_TASK_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(fieldForceActionTypes.LOG_FF_LIST_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_TASK_LIST, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_TASK_LIST_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(cliLicActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}
export function* getFFTaskItem(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.SET_FF_TASK_BY_ID_RESPONSE, null)),
    // put(getAction(clientAddressActionTypes.LOG_TEMPADJ_BY_ID_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_TASK_ITEM_BY_ID,
      action.data.reqObjForId);
    yield all([
      put(getAction(fieldForceActionTypes.SET_FF_TASK_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_TASK_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
export function* getFFTaskDetailsDDList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.FF_TASK_DETAILS_DD_LIST, action.data);

    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(cliLicActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* deleteFFTask(action) {
  Logger.verbose(`Action Detail For FF TASK : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_DELETE_TASK,
      action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_TASK_LIST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
  // put request failed action
  // const resetServerValue = { serverRequestError: processError(err) };
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFTaskLocDetails(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(fieldForceActionTypes.LOG_FF_LIST_REQUEST, action.data)),
  ]);

  try {
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.FF_TASK_LOC_DETAILS, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(cliLicActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* validateFFTaskLocDetails(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.VAL_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.SET_VALIDATE_LOCATION_ERR, null)),
    // put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(fieldForceActionTypes.LOG_FF_LIST_REQUEST, action.data)),
  ]);
  try {
    // API CALL
    yield call(invokeApi, moduleAPI.FF_TASK_VAL_LOC_DETAILS, action.data);

    yield all([
      put(getAction(fieldForceActionTypes.SET_VALIDATE_LOCATION_ERR, false)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      // put(getAction(cliLicActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(fieldForceActionTypes.SET_VALIDATE_LOCATION_ERR, resetServerValue.serverRequestError)),
      put(processingCompleted())]);
  }
}

export function* redefaultFFTask(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_TASK_REDEFAULT, action.data);
    const test = { ortDDLabelVal: [
      { ortDetail: 'A1', keyIndex: '1' },
      { ortDetail: 'A1', keyIndex: 2 },
    ] };
    yield all([
      put(getAction(fieldForceActionTypes.REDEFAULT_FF_TASK_RESPONSE, { data: { ...responseData, ...test } })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(cliLicActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* validateDetailsChange(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.VALIDATE_DETAILS_CHANGE_RESPONSE, false)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(fieldForceActionTypes.LOG_FF_LIST_REQUEST, action.data)),
  ]);

  try {
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.FF_VAL_DETAILS_CHANGE, action.data);

    yield all([
      put(getAction(fieldForceActionTypes.VALIDATE_DETAILS_CHANGE_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(cliLicActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(fieldForceActionTypes.VALIDATE_DETAILS_CHANGE_RESPONSE, 0)),
      put(processingCompleted())]);
  }
}

export function* getAssocTaskCount(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_ASSOC_TASK_CNT_RES, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(fieldForceActionTypes.LOG_FF_LIST_REQUEST, action.data)),
  ]);

  try {
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.FF_GET_ASSOC_TASK_CNT_RES, action.data);

    yield all([
      put(getAction(fieldForceActionTypes.GET_ASSOC_TASK_CNT_RES, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(cliLicActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* insertFFTask(action) {
  Logger.verbose(`Action Detail For FF Task : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_ADD_TASK,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_TASK_LIST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
  // put request failed action
  // const resetServerValue = { serverRequestError: processError(err) };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* updateFFTask(action) {
  Logger.verbose(`Action Detail For FF Task : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_UPDATE_TASK,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_TASK_LIST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for BCR Export Listing
   */
export function* getFFTaskExport(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_TASK_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * Set Task concurrency
 *
 * @export
 * @param {*} action metadata
 */
export function* setFFTaskConcurrency(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_TASK_LIST_BY_ID,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_TASK_CONCURRENCY_RES,
        { ...responseData, comboLock: action.data.lock })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_TASK_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* getSysOptHierarchy() {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_ASSOC_TASK_CNT_RES, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(fieldForceActionTypes.LOG_FF_LIST_REQUEST, action.data)),
  ]);

  try {
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.FF_GET_SYSOPT_HIER_RES);

    yield all([
      put(getAction(fieldForceActionTypes.GET_SYSOPT_HIER_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(cliLicActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

function* moduleWatcherN() {
  // Notes
  yield takeEvery(fieldForceActionTypes.GET_FF_NOTES_DETAIL_REQUEST, getFFNotesList);
  yield takeEvery(fieldForceActionTypes.GET_FF_NOTES_DETAIL_BY_ID_REQUEST, getFFNotesByID);
  yield takeEvery(fieldForceActionTypes.INSERT_FF_NOTES_REQUEST, insertFFNotes);
  yield takeEvery(fieldForceActionTypes.UPDATE_FF_NOTES_REQUEST, updateFFNotes);
  yield takeEvery(fieldForceActionTypes.DELETE_FF_NOTES_REQUEST, deleteFFNotes);
  yield takeEvery(fieldForceActionTypes.EXPORT_FF_NOTES_REQUEST, exportFFNotes);
  yield takeEvery(fieldForceActionTypes.SET_FF_NOTES_CONCURRENCY, setFFNotesConcurrency);

  // Administration
  yield takeEvery(fieldForceActionTypes.GET_FF_ADMIN_DETAIL_REQUEST, getFFAdmin);
  yield takeEvery(fieldForceActionTypes.UPDATE_FF_ADMIN_REQUEST, updateFFAdmin);
  yield takeEvery(fieldForceActionTypes.GET_FF_ADMIN_DEBTREF_REQUEST, getFFAdminDebtRef);
  yield takeEvery(fieldForceActionTypes.SET_FF_ADMIN_CONCURRENCY, setFFAdminConcurrency);

  // deduction
  yield takeEvery(fieldForceActionTypes.INSERT_FIELDFORCE_DEDUCTION, insertFFDeduction);
  yield takeEvery(fieldForceActionTypes.GET_FF_DEDUCTION_ITEM_BY_ID, getFFDeductionItem);
  yield takeEvery(fieldForceActionTypes.UPDATE_FIELDFORCE_DEDUCTION, updateFFDeduction);

  // Task
  yield takeEvery(fieldForceActionTypes.GET_FF_TASK_LIST, getFFTaskList);
  yield takeEvery(fieldForceActionTypes.GET_FF_TASK_ITEM_BY_ID, getFFTaskItem);
  yield takeEvery(fieldForceActionTypes.GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST, getFFTaskDetailsDDList);
  yield takeEvery(fieldForceActionTypes.DELETE_FF_TASK_REQUEST, deleteFFTask);
  yield takeEvery(fieldForceActionTypes.GET_FF_ADD_EDIT_TASK_LOC_DETAILS, getFFTaskLocDetails);
  yield takeEvery(fieldForceActionTypes.VAL_FF_ADD_EDIT_TASK_LOC_DETAILS, validateFFTaskLocDetails);
  yield takeEvery(fieldForceActionTypes.REDEFAULT_FF_TASK, redefaultFFTask);
  yield takeEvery(fieldForceActionTypes.VALIDATE_DETAILS_CHANGE, validateDetailsChange);
  yield takeEvery(fieldForceActionTypes.GET_ASSOC_TASK_CNT, getAssocTaskCount);
  yield takeEvery(fieldForceActionTypes.INSERT_FF_TASK, insertFFTask);
  yield takeEvery(fieldForceActionTypes.UPDATE_FF_TASK, updateFFTask);
  yield takeEvery(fieldForceActionTypes.GET_FF_TASK_EXPORT_REQUEST, getFFTaskExport);
  yield takeEvery(fieldForceActionTypes.SET_FF_TASK_CONCURRENCY, setFFTaskConcurrency);
  yield takeEvery(fieldForceActionTypes.GET_SYSOPT_HIER, getSysOptHierarchy);
  // deduction name
  yield takeEvery(fieldForceActionTypes.GET_FF_DEDUCTION_NAME, getDeductionFFName);
  yield takeEvery(fieldForceActionTypes.GET_FF_TRANSACTION_LIST, getTransactionLookList);
  yield takeEvery(fieldForceActionTypes.DELETE_DEDUCTION_REQUEST, deleteDeduction);
  yield takeEvery(fieldForceActionTypes.GET_FF_DEDUCTION_ACTIVE, deductionActiveCheck);

  // FF Deduction
  yield takeEvery(fieldForceActionTypes.GET_FF_DEDUCTION_EXPORT_REQUEST, getDeductionFFExportList);
}

export default moduleWatcherN;
