/**
 * @Description This is FasatLabel component page.
 * This is a wrapper on "Label" component from MUX without adding any custom functionlities
 * @FileName fasatLabel.js
 * @Author UMA DUTTA-duttuma
 * @CreatedOn 31 January, 2021 12:45:00
 * @IssueID CDTFASATMOD- 502
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@manulife/mux';
import './fasatLabel.scss';

const FasatLabel = (props) => {
  const { children, isContent, optionalTag, optionalText, additionalTag, additionalText } = props;
  const labelContent = 'labelContent';
  return (
    <div className={`${isContent ? labelContent : ''} ${optionalTag ? 'optionalLabel' : ''} 
    ${additionalTag ? 'additionalLabel' : ''}`}
    >
      <Label {...props}>
        {children}
        {additionalTag
      && (
      <>
        &nbsp;
        <span className="additionalText">
          {additionalText}
        </span>
      </>
      )}
        {optionalTag
      && (
      <>
        &nbsp;
        <span className="optionalText">
          {optionalText}
        </span>
      </>
      )}
      </Label>
    </div>
  );
};

FasatLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]),
  isContent: PropTypes.bool,
  optionalTag: PropTypes.bool,
  optionalText: PropTypes.string,
  additionalTag: PropTypes.bool,
  additionalText: PropTypes.string,
};

FasatLabel.defaultProps = {
  children: '',
  optionalTag: false,
  isContent: false,
  optionalText: '',
  additionalTag: false,
  additionalText: '',
};

export default FasatLabel;
