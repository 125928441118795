/**
 * @Description Watcher file for the module activity
 * @FileName moduleWatcher.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:22:16
 * @IssueID NA
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  activityProcessedActionType, activityUnProcessedActionType,
  activityProcessedExtActionType,
} from '../actions/actionTypes';

import { Logger, exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

export const getResetUpsertValue = (responseData, err) => ({
  upsertRequest: null,
  upsertRequestError: err ? processError(err) : null,
  dataRequestError: err ? processError(err) : null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: responseData ? new Date() : null,
  upsertData: responseData,
});

export function* getAPList(action) {
  Logger.verbose(`Action Detail For Get Income Expense Map List : ${JSON.stringify(action)}`);
  const resetUpsertValue = {
    upsertRequest: null,
    upsertRequestError: null,
    dataRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityProcessedActionType.LOG_AP_LIST_REQUEST, action.data)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AP_LIST, action.data);
    yield all([
      put(getAction(activityProcessedActionType.GET_AP_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(activityProcessedActionType.GET_AP_LIST_ERROR, err)),
      put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

// Reverse
export function* reverseAP(action) {
  let resetUpsertValue = {
    upsertRequest: null,
    upsertRequestError: null,
    dataRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AP_REVERSE, action.data.reverseReqPayload);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestProcessedOn: null,
      dataRequestError: upsertesultData,
      upsertRequestedOn: null,
      upsertData: err,
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* revrepAP(action) {
  let resetUpsertValue = {
    dataRequestError: null,
    upsertRequestedOn: null,
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AP_REV_REP, action.data.reverseReqPayload);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      upsertRequestError: processError(err),
      upsertRequest: null,
      dataRequestError: upsertesultData,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: err,
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getAPReverseStatus(action) {
  let resetUpsertValue = {
    upsertRequest: null,
    upsertRequestProcessedOn: null,
    upsertRequestError: null,
    dataRequestError: null,
    upsertRequestedOn: null,
    upsertData: null,
  };
  yield all([
    put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  const initApiUrl = moduleAPI.ADMINISTRATION_REVERSE_STATUS_AP.endpoint;
  try {
    const statusData = `?requestedOn=${action.data.responseData.requestedOn}`;
    const updatedApiUrl = initApiUrl + statusData;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_REVERSE_STATUS_AP };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: action.data,
        upsertRequestError: null,
        dataRequestError: null,
        upsertRequestProcessedOn: new Date(),
        upsertRequestedOn: null,
        jobStatusAP: responseData,
        upsertData: action.data,
      };
      yield put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue));
    }
    if (responseData.jobStatus === 'C') {
      yield all([
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      dataRequestError: upsertesultData,
      upsertRequestProcessedOn: null,
      upsertData: err,
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

// Activity Unprocessed
export function* getAUPList(action) {
  Logger.verbose(`Action Detail For Get Activity Unprocessed : ${JSON.stringify(action)}`);
  const resetUpsertValue = {
    upsertRequestProcessedOn: null,
    dataRequestError: null,
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityUnProcessedActionType.LOG_AUP_LIST_REQUEST, action.data)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AUP_LIST, action.data);
    yield all([
      put(getAction(activityUnProcessedActionType.GET_AUP_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(activityUnProcessedActionType.GET_AUP_LIST_ERROR, err)),
      put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getLookUpsDataAP(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_AP_LOOKUP_REQUEST, action.data);
    yield all([
      put(getAction(activityProcessedActionType.RESET_AP_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(activityProcessedActionType.RESET_AP_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for SP Export Listing
 */
export function* getAPExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_AP_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* processAUP(action) {
  let resetUpsertValue = {
    upsertRequestProcessedOn: null,
    upsertData: null,
    upsertRequest: null,
    upsertRequestError: null,
    dataRequestError: null,
    upsertRequestedOn: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AUP_PROCESS, action.data.aupReqPayload);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      dataRequestError: upsertesultData,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: err,
      upsertRequest: null,
      upsertRequestError: processError(err),
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getAUPProcessStatus(action) {
  let resetUpsertValue = {
    dataRequestError: null,
    upsertRequestedOn: null,
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  const initApiUrl = moduleAPI.ADMINISTRATION_REVERSE_STATUS_AUP.endpoint;
  try {
    const statusData = `?requestedOn=${action.data.responseData.requestedOn}`;
    const updatedApiUrl = initApiUrl + statusData;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_REVERSE_STATUS_AUP };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    if (responseData) {
      resetUpsertValue = {
        upsertRequestError: null,
        dataRequestError: null,
        upsertRequestedOn: null,
        upsertRequest: action.data,
        upsertRequestProcessedOn: new Date(),
        jobStatusAUP: responseData,
        upsertData: action.data,
      };
      yield put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue));
    }
    if (responseData.jobStatus === 'C') {
      yield all([
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      dataRequestError: upsertesultData,
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: err,
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deleteAUP(action) {
  let resetUpsertValue = {
    upsertData: null,
    upsertRequest: null,
    dataRequestError: null,
    upsertRequestedOn: null,
    upsertRequestError: null,
    upsertRequestProcessedOn: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AUP_DELETE, action.data.aupReqPayload);
    if (responseData) {
      if (responseData.successCount === 0) {
        resetUpsertValue = getResetUpsertValue(responseData, null);
        yield all([
          put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
          put(processingCompleted()),
        ]);
      } else {
        resetUpsertValue = {
          upsertRequest: action.data,
          upsertRequestError: null,
          dataRequestError: null,
          upsertRequestedOn: null,
          upsertRequestProcessedOn: new Date(),
        };
        yield all([
          put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
          put(processingCompleted()),
        ]);
      }
    }
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      dataRequestError: upsertesultData,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: err,
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deleteStatusAUP(action) {
  let resetUpsertValue = {
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
    upsertRequest: null,
    upsertRequestError: null,
    dataRequestError: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityUnProcessedActionType.LOG_AUP_LIST_REQUEST, action.data)),
    put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AUP_DELETE_CHECK, action.data);
    if (responseData) {
      resetUpsertValue = getResetUpsertValue(responseData, null);
      yield all([
        put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: err,
      dataRequestError: upsertesultData,
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityUnProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getAUPExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_AUP_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getAUPNegative(action) {
  Logger.verbose(`Action Detail For Get Activity Unprocessed : ${JSON.stringify(action)}`);
  const resetUpsertValue = {
    upsertRequestedOn: null,
    upsertRequestError: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
    upsertRequest: null,
    dataRequestError: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityUnProcessedActionType.LOG_AUP_NEGATIVE_REQUEST, action.data)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AUP_NEGATIVE_RATE, action.data);
    yield all([
      put(getAction(activityUnProcessedActionType.GET_AUP_NEGATIVE_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(activityUnProcessedActionType.GET_AUP_NEGATIVE_ERROR, err)),
      put(getAction(activityUnProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getConcurrency(action) {
  let resetUpsertValue = {
    upsertRequest: null,
    upsertRequestError: null,
    dataRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AP_AUP_CONCURRENCY,
      action.data.reqPayload);
    if (responseData) {
      yield all([
        put(getAction(activityProcessedActionType.GET_AP_AUP_CONCURRENCY_RESPONSE, responseData)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestProcessedOn: null,
      upsertData: err,
      upsertRequestError: processError(err),
      dataRequestError: upsertesultData,
      upsertRequestedOn: null,
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityProcessedActionType.GET_AP_AUP_CONCURRENCY_ERROR, err)),
      put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getBulkValidCount() {
  let resetUpsertValue = {
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertRequest: null,
    upsertRequestError: null,
    dataRequestError: null,
    upsertData: null,
  };

  yield all([
    put(processingStarted()),
    put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_BULK_VALID_COUNT,
      {});
    if (responseData) {
      yield all([
        put(getAction(activityProcessedActionType.GET_BULK_VALID_COUNT_RESPONSE, responseData)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      upsertRequestProcessedOn: null,
      upsertData: err,
      upsertRequest: null,
      upsertRequestError: processError(err),
      dataRequestError: upsertesultData,
      upsertRequestedOn: null,
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getBulkConcurrency() {
  let resetUpsertValue = {
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertRequest: null,
    upsertRequestError: null,
    dataRequestError: null,
    upsertData: null,
  };

  yield all([
    put(processingStarted()),
    put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_BULK_CONCURRENCY,
      {});
    yield put(processingCompleted());
    if (responseData === 0 || responseData === 1) {
      yield all([
        put(getAction(activityProcessedActionType.GET_BULK_CONCURRENCY_RESPONSE, responseData)),
      ]);
    }
  } catch (err) {
    const responseDataOne = err.response.data;
    let upsertesultData = null;
    if (!(typeof (responseDataOne) === 'string' && responseDataOne.includes('-999'))) {
      upsertesultData = responseDataOne;
    }
    resetUpsertValue = {
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: err,
      upsertRequest: null,
      upsertRequestError: processError(err),
      dataRequestError: upsertesultData,
    };
    const resetServerValue = { serverRequestError: upsertesultData === null ? processError(err) : null };
    yield all([
      put(getAction(activityProcessedActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(activityProcessedActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getAPListExt(action) {
  Logger.verbose(`Action Detail For Get Income Expense Map List : ${JSON.stringify(action)}`);
  const resetUpsertValue = {
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(activityProcessedExtActionType.RESET_SERVER_VALUE, null)),
    put(getAction(activityProcessedExtActionType.LOG_AP_LIST_REQUEST, action.data)),
    put(getAction(activityProcessedExtActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_ACTIVITY_PROCESS_LIST, action.data);
    yield all([
      put(getAction(activityProcessedExtActionType.GET_AP_LIST_EXT_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(activityProcessedExtActionType.GET_AP_LIST_ERROR, err)),
      put(getAction(activityProcessedExtActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getAPExportListExt(action) {
  yield all([
    put(processingStarted()),
    put(getAction(activityProcessedExtActionType.RESET_SERVER_VALUE, null)),
  ]);
  const payload = action.data.formatExtObject ? action.data.formatExtObject : action.data;
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.EXT_AP_EXPORT_LIST,
      payload,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    if (action.data.popUpClose) {
      action.data.popUpClose();
    }
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status, payload.exportFileType);
      resetServerValue = { serverErrorsExport: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverErrorsExport: processError(err) };
    }
    yield all([
      put(getAction(activityProcessedExtActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  yield takeEvery(activityProcessedActionType.GET_AP_LIST_REQUEST, getAPList);
  yield takeEvery(activityProcessedActionType.GET_AP_REVERSE_REQUEST, reverseAP);
  yield takeEvery(activityProcessedActionType.GET_AP_REV_REP_REQUEST, revrepAP);
  yield takeEvery(activityProcessedActionType.GET_REVERSE_STATUS_AP, getAPReverseStatus);
  yield takeEvery(activityProcessedActionType.GET_AP_EXPORT_REQUEST, getAPExportList);
  yield takeEvery(activityProcessedActionType.GET_AP_LOOKUP_REQUEST, getLookUpsDataAP);
  yield takeEvery(activityUnProcessedActionType.GET_AUP_LIST_REQUEST, getAUPList);
  yield takeEvery(activityUnProcessedActionType.GET_AUP_PROCESS_REQUEST, processAUP);
  yield takeEvery(activityUnProcessedActionType.GET_AUP_DELETE_REQUEST, deleteAUP);
  yield takeEvery(activityUnProcessedActionType.AUP_DELETE_STATUS, deleteStatusAUP);
  yield takeEvery(activityUnProcessedActionType.GET_REVERSE_STATUS_AUP, getAUPProcessStatus);
  yield takeEvery(activityUnProcessedActionType.GET_AUP_EXPORT_REQUEST, getAUPExportList);
  yield takeEvery(activityUnProcessedActionType.GET_AUP_NEGATIVE_REQUEST, getAUPNegative);
  yield takeEvery(activityProcessedActionType.GET_AP_AUP_CONCURRENCY, getConcurrency);
  yield takeEvery(activityProcessedActionType.GET_BULK_VALID_COUNT, getBulkValidCount);
  yield takeEvery(activityProcessedExtActionType.GET_AP_LIST_EXT_REQUEST, getAPListExt);
  yield takeEvery(activityProcessedExtActionType.GET_AP_EXPORT_EXT_REQUEST, getAPExportListExt);
  yield takeEvery(activityProcessedActionType.GET_BULK_CONCURRENCY, getBulkConcurrency);
}

export default moduleWatcher;
