/**
 * @Description File description.
 * @FileName moduleConstants.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 25 January, 2021 08:39:44
 * @IssueID - 311
 */
import { moduleInquiryConstants } from './moduleInquiryConstants';

const LOCAL_RESOURCE_NS = 'static:';
/**
  * All the route constants for the module
  */
export const routeConstant = {
  AGENCY_HOME: '/agency/home',
  BUSINESS_CARD_RULES: '/agency/bcr',
  BUSINESS_CARD_DETAIL: '/agency/bcrdetail',
  TEMPLATE_ADJUSTMENTS: '/agency/templateadjustments',
  MANUAL_COMMISSION_RECORDS: '/agency/manualcommissionrecords',
  MANUAL_COMMISSION_RECORD_DETAIL: '/agency/mcrdetail',
  CLIENT: '/agency/client',
  CLIENT_CARD_DETAIL: '/agency/clientdetail',
  CLIENT_ADD: '/agency/add',
  FIELD_FORCE: '/agency/fieldForce',
  FIELD_FORCE_DETAIL_ROUTE: '/agency/fieldForceDetail',
  FIELD_FORCE_LEVEL_STEP_ROUTE: '/agency/fieldForceLevelStep',
  FIELD_FORCE_ADDRESS_STEP_ROUTE: '/agency/fieldForceAddressStep',
  FIELD_FORCE_MOVEMENT: '/agency/fieldForceMovement',
  MANUAL_ADJUSTMENT_ROUTE: '/agency/manualadjustment',
  FIN_ARRANGEMENT: '/agency/fieldForceComp/finArrangement',
  RELATIONSHIPS: '/agency/relationship',
  LICENCE_ROUTE: '/agency/licence',
  SELECT_LETTER_ROUTE_FFL: '/agency/selectLetter/ffl',
  SELECT_LETTER_ROUTE_LIP: '/agency/selectLetter/lip',
  FIELD_FORCE_LETTER_ROUTE: '/agency/fieldForceLetter',
  FIELD_FORCE_DEDUCTION: '/agency/fieldForceDeduction',
  FIELD_FORCE_DEDUCTION_ADD: '/agency/fieldForceDeduction/add',
  FIELD_FORCE_FIN_ARRANGEMENT: '/agency/fieldForceFinArrangement',
  FIELD_FORCE_FIN_ARRANGEMENT_ADD: '/agency/fieldForceFinArrangement/add',
  FIELD_FORCE_FIN_ARRANGEMENT_EDIT: '/agency/fieldForceFinArrangement/edit',
  FIELD_FORCE_FIN_ARRANGEMENT_CLONE: '/agency/fieldForceFinArrangement/clone',
  // all inquiry screen
  ...moduleInquiryConstants.routeConstant,
};

/**
  * ACL keys for the module
  */
export const aclKeysConstant = {
  // Menu
  AGENCY: 'RBAC_DISTRIBUTORS',
  ACTIVITY: 'Activity',
  // SubMenu
  BUSINESS_CARD_RULES: 'RBAC_BCR_MENU',
  // BCR Feature
  BCR_ADD: 'RBAC_BCR_ADD',
  BCR_EDIT: 'RBAC_BCR_EDIT',
  BCR_DELETE: 'RBAC_BCR_DELETE',
  BCR_EXPORT: 'RBAC_BCR_EXPORT',
  BCR_DETAIL: 'RBAC_BCR_DETAIL',
  RBAC_ADJUSTMENTS_MENU: 'RBAC_ADJUSTMENTS_MENU',
  RBAC_TA_MENU: 'RBAC_TA_MENU',
  RBAC_TA_EDIT: 'RBAC_TA_EDIT',
  RBAC_TA_EXPORT: 'RBAC_TA_EXPORT',
  RBAC_TA_DELETE: 'RBAC_TA_DELETE',
  RBAC_TA_APPROVE: 'RBAC_TA_APPROVE',
  RBAC_TA_IMPORT: 'RBAC_TA_IMPORT',

  // cleint Feature
  RBAC_CLN_ADD: 'RBAC_CLN_ADD',
  RBAC_CLN_EDIT: 'RBAC_CLN_EDIT',
  RBAC_CLN_EXPORT: 'RBAC_CLN_EXPORT',
  RBAC_CLN_DELETE: 'RBAC_CLN_DELETE',
  RBAC_CLN_MENU: 'RBAC_CLN_MENU',

  RBAC_LIC_ADD: 'RBAC_LIC_ADD',
  RBAC_LIC_EDIT: 'RBAC_LIC_EDIT',
  RBAC_LIC_EXPORT: 'RBAC_LIC_EXPORT',
  RBAC_LIC_DELETE: 'RBAC_LIC_DELETE',
  RBAC_LIC_MENU: 'RBAC_LIC_MENU',

  RBAC_CLA_ADD: 'RBAC_CLA_ADD',
  RBAC_CLA_EDIT: 'RBAC_CLA_EDIT',
  RBAC_CLA_EXPORT: 'RBAC_CLA_EXPORT',
  RBAC_CLA_DELETE: 'RBAC_CLA_DELETE',
  RBAC_CLA_VIEW: 'RBAC_CLA_VIEW',
  RBAC_CLA_MENU: 'RBAC_CLA_MENU',

  RBAC_CLB_ADD: 'RBAC_CLB_ADD',
  RBAC_CLB_EDIT: 'RBAC_CLB_EDIT',
  RBAC_CLB_EXPORT: 'RBAC_CLB_EXPORT',
  RBAC_CLB_DELETE: 'RBAC_CLB_DELETE',
  RBAC_CLB_VIEW: 'RBAC_CLB_VIEW',
  RBAC_CLB_MENU: 'RBAC_CLB_MENU',

  RBAC_CLI_VIEW_PERSONAL: 'RBAC_CLI_VIEW_PERSONAL',
  RBAC_CLI_EXPORT: 'RBAC_CLI_EXPORT',
  RBAC_CLI_ADD: 'RBAC_CLI_ADD',
  RBAC_CLI_VIEW: 'RBAC_CLI_VIEW',
  RBAC_CLI_DELETE: 'RBAC_CLI_DELETE',
  RBAC_CLI_EDIT: 'RBAC_CLI_EDIT',
  RBAC_CL_MENU: 'RBAC_CL_MENU',
  // Field Force
  RBAC_FF_MENU: 'RBAC_FF_MENU',
  RBAC_FF_ADD: 'RBAC_FF_ADD',
  RBAC_FF_DELETE: 'RBAC_FF_DELETE',
  RBAC_FF_EXPORT: 'RBAC_FF_EXPORT',
  RBAC_FFG_EDIT: 'RBAC_FFG_EDIT',
  RBAC_FFG_GST_UPDATE: 'RBAC_FFG_GST_UPDATE',
  RBAC_FFG_5ALPHA_OVERRIDE: 'RBAC_FFG_5ALPHA_OVERRIDE',
  RBAC_FFG_4ALPHA_OVERRIDE: 'RBAC_FFG_4ALPHA_OVERRIDE',
  RBAC_FFG_MENU: 'RBAC_FFG_MENU',
  RBAC_FFADD_EDIT: 'RBAC_FFADD_EDIT',
  RBAC_FFADD_MENU: 'RBAC_FFADD_MENU',
  RBAC_FFB_EDIT: 'RBAC_FFB_EDIT',
  RBAC_FFB_MENU: 'RBAC_FFB_MENU',
  RBAC_FFN_MENU: 'RBAC_FFN_MENU',
  RBAC_FFN_ADD: 'RBAC_FFN_ADD',
  RBAC_FFN_EDIT: 'RBAC_FFN_EDIT',
  RBAC_FFN_DELETE: 'RBAC_FFN_DELETE',
  RBAC_FFN_EXPORT: 'RBAC_FFN_EXPORT',
  RBAC_FFREL_MENU: 'RBAC_FFREL_MENU',
  RBAC_FFREL_ADD: 'RBAC_FFREL_ADD',
  RBAC_FFREL_EDIT: 'RBAC_FFREL_EDIT',
  RBAC_FFREL_DELETE: 'RBAC_FFREL_DELETE',
  RBAC_FFREL_EXPORT: 'RBAC_FFREL_EXPORT',
  RBAC_FFADM_MENU: 'RBAC_FFADM_MENU',
  RBAC_FFADM_EDIT: 'RBAC_FFADM_EDIT',
  RBAC_FFT_MENU: 'RBAC_FFT_MENU',
  RBAC_FFT_ADD: 'RBAC_FFT_ADD',
  RBAC_FFT_EDIT: 'RBAC_FFT_EDIT',
  RBAC_FFT_EXPORT: 'RBAC_FFT_EXPORT',
  RBAC_FFT_DELETE: 'RBAC_FFT_DELETE',
  RBAC_FFREQ_MENU: 'RBAC_FFREQ_MENU',

  // Field Force Movement
  RBAC_FFM_MENU: 'RBAC_FFM_MENU',
  RBAC_FFM_EXPORT: 'RBAC_FFM_EXPORT',

  // Fin Arrangement

  RBAC_FA_MENU: 'RBAC_FA_MENU',
  RBAC_FA_ADD: 'RBAC_FA_ADD',
  RBAC_FA_EDIT: 'RBAC_FA_EDIT',
  RBAC_FA_CLONE: 'RBAC_FA_CLONE',
  RBAC_FA_DELETE: 'RBAC_FA_DELETE',
  RBAC_FA_EXPORT: 'RBAC_FA_EXPORT',

  // DEDUCTION
  RBAC_DED_MENU: 'RBAC_DED_MENU',
  RBAC_DED_ADD: 'RBAC_DED_ADD',
  RBAC_DED_EDIT: 'RBAC_DED_EDIT',
  RBAC_DED_DELETE: 'RBAC_DED_DELETE',
  RBAC_DED_EXPORT: 'RBAC_DED_EXPORT',

  // Mass agent Transfer
  RBAC_FFMAT_MENU: 'RBAC_FFMAT_MENU',
  RBAC_FFMAT_TRANSFER: 'RBAC_FFMAT_TRANSFER',
  RBAC_FFMAT_ADD: 'RBAC_FFMAT_ADD',
  RBAC_FFMAT_EDIT: 'RBAC_FFMAT_EDIT',
  RBAC_FFMAT_DELETE: 'RBAC_FFMAT_DELETE',

  // FF requirement
  RBAC_FFREQ_ADD: 'RBAC_FFREQ_ADD',
  RBAC_FFREQ_EDIT: 'RBAC_FFREQ_EDIT',
  RBAC_FFREQ_DELETE: 'RBAC_FFREQ_DELETE',

  // all inquiry screen
  ...moduleInquiryConstants.aclKeysConstant,

  // Manual Commision records screen RBAC
  RBAC_MCR_MENU: 'RBAC_MCR_MENU',
  RBAC_MCR_EDIT: 'RBAC_MCR_EDIT',
  RBAC_MCR_IMPORT: 'RBAC_MCR_IMPORT',
  RBAC_MCR_DELETE: 'RBAC_MCR_DELETE',
  RBAC_MCR_DELETE_ALL: 'RBAC_MCR_DELETE_USER_RECENT_IMPORT',
  RBAC_MCR_EXPORT: 'RBAC_MCR_EXPORT',

  // Manual Adjustments records screen RBAC
  RBAC_MADJ_MENU: 'RBAC_MADJ_MENU',
  RBAC_MADJ_EDIT: 'RBAC_MADJ_EDIT',
  RBAC_MADJ_ADV_SELECTION: 'RBAC_MADJ_ADV_SELECTION',

  // Client Relationship screen
  RBAC_RLI_MENU: 'RBAC_RLI_MENU',
  RBAC_RLI_DELETE: 'RBAC_RLI_DELETE',
  RBAC_RLI_EXPORT: 'RBAC_RLI_EXPORT',

  // FF Balance Product Report
  RBAC_FFBLC_EXPORT: 'RBAC_FFBLC_EXPORT',
  RBAC_EXTBAL_EXPORT: 'RBAC_EXTBAL_EXPORT',
  RBAC_FFBLC_MENU: 'RBAC_FFBLC_MENU',
  RBAC_EXTBAL_MENU: 'RBAC_EXTBAL_MENU',
  RBAC_FFPRD_MENU: 'RBAC_FFPRD_MENU',
  RBAC_FFPRD_EXPORT: 'RBAC_FFPRD_EXPORT',
  RBAC_EXT_PRODUCTION_EXPORT: 'RBAC_EXT_PRODUCTION_EXPORT',
  RBAC_EXTPRB_EXPORT: 'RBAC_EXTPRB_EXPORT',

  // licence process
  RBAC_LIP_MENU: 'RBAC_LIP_MENU',
  RBAC_LIP_CHANGE_STATUS: 'RBAC_LIP_CHANGE_STATUS',
  RBAC_LIP_SELECT_LETTER: 'RBAC_LIP_SELECT_LETTER',
  RBAC_LIP_EXPORT: 'RBAC_LIP_EXPORT',

  // ff letter
  RBAC_FFL_MENU: 'RBAC_FFL_MENU',
  RBAC_FFL_SELECT_LETTER: 'RBAC_FFL_SELECT_LETTER',
  RBAC_FFL_EXPORT: 'RBAC_FFL_EXPORT',

  // Payments
  RBAC_PAYMENTS: 'RBAC_PAYMENTS',
  RBAC_PAYMENT_MENU: 'RBAC_PAYMENT_MENU',
  RBAC_PAYMENT_NAMEOVERRIDE: 'RBAC_PAYMENT_NAMEOVERRIDE',
  RBAC_PAYMENT_ADDOVERRIDE: 'RBAC_PAYMENT_ADDOVERRIDE',
  RBAC_PAYMENT_CHNGCHQOPTIONS: 'RBAC_PAYMENT_CHNGCHQOPTIONS',
  RBAC_PAYMENT_BALOVERRIDE: 'RBAC_PAYMENT_BALOVERRIDE',
  RBAC_PAYMENT_DESCENABLED: 'RBAC_PAYMENT_DESCENABLED',
  RBAC_PAYMENT_RUSHCHQ: 'RBAC_PAYMENT_RUSHCHQ',
  RBAC_PAYMENT_MANUALCHQ: 'RBAC_PAYMENT_MANUALCHQ',
  RBAC_PAYMENT_MEMOENABLED: 'RBAC_PAYMENT_MEMOENABLED',
};

/**
  * i18n Keys for the module
  */
export const staticLabelKeys = {
  AGENCY: `${LOCAL_RESOURCE_NS}agency.menu`,
  DISTRIBUTOR: 'RBAC_DISTRIBUTORS',

  CL_MENU: 'RBAC_CL_MENU',
  FF_MENU: 'RBAC_FF_MENU',
  REQUIREMENT_INQUIRY_MENU: 'RBAC_RI_MENU',

  BCR_MODAL_TITLE: 'BCR_TITLE_ADD',
  BCR_EDIT_MODAL_TITLE: 'BCR_TITLE_EDIT',
  BCR_PAGE_TITLE: 'BCR_TITLE_LIST',
  BCR_MENU: 'RBAC_BCR_MENU',
  CL_LABEL_CLIENT: 'CL_LABEL_CLIENT',
  /**
   * Static Label Keys for Business List Page Contents
   */
  BCR_LIST_COLUMN_LEVEL: 'BCR_LABEL_LEVEL',
  BCR_LIST_COLUMN_ID: 'BCR_LABEL_ID',
  BCR_LIST_COLUMN_NAME: 'BCR_LABEL_NAME',
  BCR_LIST_COLUMN_PHONE: 'BCR_LABEL_PHONE',
  BCR_LIST_COLUMN_ADDRESS: 'BCR_LABEL_ADDRESS',
  BCR_LIST_COLUMN_FAX: 'BCR_LABEL_FAX',
  BCR_LIST_COLUMN_USENAME: 'BCR_LABEL_USENAME',
  BCR_LIST_COLUMN_EMAIL: 'BCR_LABEL_EMAIL',

  /**
   * Static Label Keys for Business Card Details ADD/EDIT Page Contents
   */
  BCR_LABEL_LEVEL: 'BCR_LABEL_LEVEL',
  BCR_LABEL_ID: 'BCR_LABEL_ID',
  BCR_LABEL_NAME: 'BCR_LABEL_NAME',
  BCR_LABEL_USENAME: 'BCR_LABEL_USENAME',
  BCR_LABEL_NONE: 'BCR_LABEL_NONE',
  BCR_LABEL_CLIENT: 'BCR_LABEL_CLIENT',
  BCR_LABEL_SECONDARY: 'BCR_LABEL_SECONDARY',
  BCR_LABEL_ADDRESS: 'BCR_LABEL_ADDRESS',
  BCR_LABEL_PHONE: 'BCR_LABEL_PHONE',
  BCR_LABEL_FAX: 'BCR_LABEL_FAX',
  BCR_LABEL_EMAIL: 'BCR_LABEL_EMAIL',
  BCR_ADD_SUCCESS: 'BCR_ADD_SUCCESS',
  BCR_EDIT_SUCCESS: 'BCR_EDIT_SUCCESS',

  /**
   * Static Label Keys for Business Card Details Page Contents
   */
  BCR_DETAILS_PAGE_TITLE: 'BCR_TITLE_DETAIL',
  BCR_DETAILS_LEVEL_LABEL: 'BCR_LABEL_LEVEL',
  BCR_DETAILS_LEVEL_CONTENT: `${LOCAL_RESOURCE_NS}bcr.details.level.content`,
  BCR_DETAILS_ID_LABEL: 'BCR_LABEL_ID',
  BCR_DETAILS_ID_CONTENT: `${LOCAL_RESOURCE_NS}bcr.details.id.content`,
  BCR_DETAILS_NAME_CONTENT: `${LOCAL_RESOURCE_NS}bcr.details.name.content`,
  BCR_DETAILS_ADDRESS_LABEL: 'BCR_LABEL_ADDRESS',
  BCR_DETAILS_ADDRESS_LINE1_CONTENT: `${LOCAL_RESOURCE_NS}bcr.details.address.line1.content`,
  BCR_DETAILS_ADDRESS_LINE2_CONTENT: `${LOCAL_RESOURCE_NS}bcr.details.address.line2.content`,
  BCR_DETAILS_ADDRESS_LINE3_CONTENT: `${LOCAL_RESOURCE_NS}bcr.details.address.line3.content`,
  BCR_DETAILS_PHONE_LABEL: 'BCR_LABEL_PHONE',
  BCR_DETAILS_PHONE_CONTENT: `${LOCAL_RESOURCE_NS}bcr.details.phone.contents`,
  BCR_DETAILS_FAX_LABEL: 'BCR_LABEL_FAX',
  BCR_DETAILS_FAX_CONTENT: `${LOCAL_RESOURCE_NS}bcr.details.fax.content`,
  BCR_DETAILS_EMAIL_LABEL: 'BCR_LABEL_EMAIL',
  BCR_DETAILS_EMAIL_CONTENT: `${LOCAL_RESOURCE_NS}bcr.details.email.content`,

  /**
   * Static Label Keys for BCR Delete Modal
   */
  BCR_DEL_CONF_MSG: 'BCR_DELETE_CONFIRMATION',
  BCR_DEL_SUCCESS_MSG: 'BCR_DELETE_SUCCESS',

  /*
   *ARIA LABELS FOR BCR PAGE - START
   */
  ARIA_BCR_BUTTON_ADD: 'ARIA_BCR_BUTTON_ADD',
  /*
   *ARIA LABELS FOR BCR PAGE - END
   */
  ARIA_DE_BUTTON_ADD: 'ARIA_DE_BUTTON_ADD',
  /**
   * // -----------Start-Manual commission records-------------------
   * Static label keys for Manual commission records menu
   */
  // RBAC_ADJUSTMENTS_MENU: 'RBAC_ADJUSTMENTS_MENU',
  MCR_MENU_TITLE: 'MCR_MENU_TITLE',
  RBAC_MADJ_MENU: 'RBAC_MADJ_MENU',
  MADJ_PROCESS_SUCCESS: 'ADJPROCOK',
  MCR_SUBMIT_SUCCESS: 'MCR_SUBMIT_SUCCESS',
  MADJ_POLICY_NOT_FOUND: 'MADJ_POLICY_NOT_FOUND',
  MADJ_POLICY_NOT_FOR_AGT: 'MADJ_POLICY_NOT_FOR_AGT',
  MAJ_DELAY_PROCESSING: 'MAJ_DELAY_PROCESSING',
  MAJ_SUBMIT_MSG: 'MAJ_SUBMIT_MSG',
  MAJ_FPA_ERROR_TITLE: 'MAJ_FPA_ERROR_TITLE',
  MAJ_ERROR_PROCESSING: 'MAJ_ERROR_PROCESSING',
  MAJ_ERROR_REASON: 'MAJ_ERROR_REASON',
  /**
   * Static label keys for Template Adjustments list page
   */
  MCR_TITLE_LIST: 'MCR_TITLE_LIST',
  MCR_LABEL_REMARK: 'MCR_LABEL_REMARK',
  MCR_LABEL_PLANCODE: 'MCR_LABEL_PLANCODE',
  MCR_LABEL_ADJTYPE: 'MCR_LABEL_ADJTYPE',
  MCR_LABEL_ADVNUM: 'MCR_LABEL_ADVNUM',
  MCR_LABEL_AMOUNT: 'MCR_LABEL_AMOUNT',
  MCR_LABEL_COMPREM: 'MCR_LABEL_COMPREM',
  MCR_LABEL_SALESCREDIT: 'MCR_LABEL_SALESCREDIT',
  MCR_LABEL_COMEARN: 'MCR_LABEL_COMEARN',
  MCR_LABEL_TRANSACTION: 'MCR_LABEL_TRANSACTION',
  MCR_LABEL_PENDING: 'MCR_LABEL_PENDING',
  MCR_LABEL_MDRT: 'MCR_LABEL_MDRT',
  MCR_LABEL_APLYCOMPSTR: 'MCR_LABEL_APLYCOMPSTR',
  MCR_LABEL_LOOKUPRATE: 'MCR_LABEL_LOOKUPRATE',
  MCR_LABEL_REMARKS: 'MCR_LABEL_REMARKS',
  MCR_LABEL_TRANSFERADVNUM: 'MCR_LABEL_TRANSFERADVNUM',
  MCR_LABEL_POLICYNUM: 'MCR_LABEL_POLICYNUM',
  MCR_LABEL_COVNUM: 'MCR_LABEL_COVNUM',
  MCR_LABEL_FUND: 'MCR_LABEL_FUND',
  MCR_LABEL_LASTNAME: 'MCR_LABEL_LASTNAME',
  MCR_LABEL_FRSTNAME: 'MCR_LABEL_FRSTNAME',
  MCR_LABEL_BASICPLNCODE: 'MCR_LABEL_BASICPLNCODE',
  MCR_LABEL_BASICRTSCALE: 'MCR_LABEL_BASICRTSCALE',
  MCR_LABEL_COVPLNCODE: 'MCR_LABEL_COVPLNCODE',
  MCR_LABEL_COVRTSCALE: 'MCR_LABEL_COVRTSCALE',
  MCR_LABEL_PARCODE: 'MCR_LABEL_PARCODE',
  MCR_LABEL_COVSTAT: 'MCR_LABEL_COVSTAT',
  MCR_LABEL_GICTERM: 'MCR_LABEL_GICTERM',
  MCR_LABEL_FACEAMT: 'MCR_LABEL_FACEAMT',
  MCR_LABEL_CUMMDEPAMT: 'MCR_LABEL_CUMMDEPAMT',
  MCR_LABEL_COMPDATE: 'MCR_LABEL_COMPDATE',
  MCR_LABEL_DURATION: 'MCR_LABEL_DURATION',
  MCR_LABEL_DUEDATE: 'MCR_LABEL_DUEDATE',
  MCR_LABEL_MODE: 'MCR_LABEL_MODE',
  MCR_LABEL_SHARE: 'MCR_LABEL_SHARE',
  MCR_LABEL_ISSUECNTRY: 'MCR_LABEL_ISSUECNTRY',
  MCR_LABEL_ISSUELOC: 'MCR_LABEL_ISSUELOC',
  MCR_LABEL_CURRENTCNTRY: 'MCR_LABEL_CURRENTCNTRY',
  MCR_LABEL_CURRENTLOC: 'MCR_LABEL_CURRENTLOC',
  MCR_LABEL_CHARGEBCKRATE: 'MCR_LABEL_CHARGEBCKRATE',
  MCR_LABEL_COSTCENTRE: 'MCR_LABEL_COSTCENTRE',
  MCR_LABEL_ISSUEDATE: 'MCR_LABEL_ISSUEDATE',
  MCR_LABEL_APPRECDATE: 'MCR_LABEL_APPRECDATE',
  MCR_TITLE_IMPORTADJ: 'MCR_TITLE_IMPORTADJ',
  MCR_RECORD_PROCESSING: 'MCR_RECORD_PROCESSING',
  MAJ_LABEL_RATE_PERCENTAGE: 'MAJ_LABEL_RATE_PERCENTAGE',
  MAJ_LABEL_BASIC_PLAN_RATESCALE: 'MAJ_LABEL_BASIC_PLAN_RATESCALE',
  MAJ_LABEL_COV_PLAN: 'MAJ_LABEL_COV_PLAN',
  MAJ_LABEL_CUM_DEP: 'MAJ_LABEL_CUM_DEP',
  MAJ_LABEL_CURRENT: 'MAJ_LABEL_CURRENT',
  MAJ_LABEL_BUSINESS: 'MAJ_LABEL_BUSINESS',
  MAJ_LABEL_TRANSFER_INFORMATION: 'MAJ_LABEL_TRANSFER_INFORMATION',
  MAJ_TITLE_MANUAL_ADJUSTMENTS: 'MAJ_TITLE_MANUAL_ADJUSTMENTS',
  MAJ_LABEL_PENDING: 'MAJ_LABEL_PENDING',
  MAJ_LABEL_APPLY_COMP_STRUCT: 'MAJ_LABEL_APPLY_COMP_STRUCT',
  MAJ_LABEL_LOOK_UP_RATE: 'MAJ_LABEL_LOOK_UP_RATE',
  MAJ_LABEL_FROM_AGENT: 'MAJ_LABEL_FROM_AGENT',
  MAJ_LABEL_ISSUE: 'MAJ_LABEL_ISSUE',
  MCR_LABEL_JOB_ID: 'MCR_LABEL_JOB_ID',
  MCR_LABEL_POLICYCOMPANY: 'MCR_LABEL_POLICYCOMPANY',
  MCR_LABEL_IMPORTED_BY: 'MCR_LABEL_IMPORTED_BY',
  MCR_IMPORT_COLUMN_COUNT_MSG: 'MCR_IMPORT_COLUMN_COUNT_MSG',
  MCR_IMPORT_MSG: 'MCR_IMPORT_MSG',
  MCR_IMPORT_MSG1: 'MCR_IMPORT_MSG1',
  MCR_IMPORT_MSG2: 'MCR_IMPORT_MSG2',
  MCR_IMPORT_IMPSTATUS: 'MCR_IMPORT_IMPSTATUS',
  MCR_IMPORT_REJSTATUS: 'MCR_IMPORT_REJSTATUS',
  MCR_IMPORT_REJCOUNT: 'MCR_IMPORT_REJCOUNT',
  MCR_ALL_RECORDS_SUCCESS_MSG: 'MCR_ALL_RECORDS_SUCCESS_MSG',
  MCR_PARTIAL_SUCCESS_MSG: 'MCR_PARTIAL_SUCCESS_MSG',
  MCR_TOTAL_RECORDS_IMPORTED_MSG: 'MCR_TOTAL_RECORDS_IMPORTED_MSG',
  MCR_TOTAL_AMOUNT_IMPORTED_MSG: 'MCR_TOTAL_AMOUNT_IMPORTED_MSG',
  JS_PAGE_TITLE: 'JS_PAGE_TITLE',
  /**
   * Static Label Keys for BCR Delete Modal
   */
  MCR_DEL_CONF_MSG: 'MCR_DELETE_CONFIRMATION',
  MCR_DEL_SUCCESS_MSG: 'MCR_DELETE_SUCCESS',
  MCR_DEL_ALL_LABEL: 'MCR_DEL_ALL_LABEL',
  MCR_DELETE_BY_JOBID_CONFIRMATION: 'MCR_DELETE_BY_JOBID_CONFIRMATION',
  MCR_DEL_BY_JOBID_SUCCESS_MSG: 'MCR_DEL_BY_JOBID_SUCCESS_MSG',
  MCR_GRID_NO_DATA: 'MCR_GRID_NO_DATA',

  // -----------End-Manual commission records-------------------

  /**
   * Static label keys for Template Adjustments menu
   */
  RBAC_ADJUSTMENTS_MENU: 'RBAC_ADJUSTMENTS_MENU',
  RBAC_TA_MENU: 'RBAC_TA_MENU',

  /**
   * Static label keys for Template Adjustments list page
   */
  TA_TITLE_LIST: 'TA_TITLE_LIST',
  TA_LABEL_SELECT_OPTION: 'TA_LABEL_SELECT_OPTION',
  TA_BUTTON_ACTIVE: 'TA_BUTTON_ACTIVE',
  TA_BUTTON_HISTORY: 'TA_BUTTON_HISTORY',
  TA_BUTTON_APPROVE: 'TA_BUTTON_APPROVE',
  TA_BUTTON_IMPORT: 'TA_BUTTON_IMPORT',
  TA_LABEL_TRASACTION: 'TA_LABEL_TRASACTION',
  TA_LABEL_SELLING_CODE: 'TA_LABEL_SELLING_CODE',
  TA_LABEL_PAYMENT_CTRL_CODE: 'TA_LABEL_PAYMENT_CTRL_CODE',
  TA_LABEL_PAYMENT_CTRL_NAME: 'TA_LABEL_PAYMENT_CTRL_NAME',
  TA_LABEL_CREDIT: 'TA_LABEL_CREDIT',
  TA_LABEL_AMOUNT: 'TA_LABEL_AMOUNT',
  TA_LABEL_PROCESS_DT: 'TA_LABEL_PROCESS_DT',
  TA_LABEL_COMMENT: 'TA_LABEL_COMMENT',
  TA_LABEL_CREATED_BY: 'TA_LABEL_CREATED_BY',
  TA_LABEL_STATUS_REASON: 'TA_LABEL_STATUS_REASON',
  TA_LABEL_CHANGE_DT: 'TA_LABEL_CHANGE_DT',
  TA_LABEL_CHANGE_ID: 'TA_LABEL_CHANGE_ID',
  TA_LABEL_APPROVED_DT: 'TA_LABEL_APPROVED_DT',
  TA_LABEL_APPROVER_ID: 'TA_LABEL_APPROVER_ID',
  TA_MESSAGE_INELIGIBLE_RECORDS: 'TA_MESSAGE_INELIGIBLE_RECORDS',
  TA_APPROVE_SUCCESS_MESSAGE: 'TA_APPROVE_SUCCESS_MESSAGE',
  TA_APPROVE_APPROVED_TOTAL: 'TA_APPROVE_APPROVED_TOTAL',
  TA_APPROVE_AMOUNT_APPROVED: 'TA_APPROVE_AMOUNT_APPROVED',
  TA_APPROVE_SELECTED: 'TA_APPROVE_SELECTED',
  TA_LABEL_SELLING_CODE_NAME: 'TA_LABEL_SELLING_CODE_NAME',
  TA_IMPORT_CONFIRMATION_MESSAGE: 'TA_IMPORT_CONFIRMATION_MESSAGE',
  MCR_LABEL_UPLD_MSG: 'MCR_LABEL_UPLD_MSG',

  /**
   * Static label keys for Import transactions in Template adjustments
   */
  TA_TITLE_IMPORT_TRANSACTIONS: 'TA_TITLE_IMPORT_TRANSACTIONS',
  TA_LABEL_TEMPLATE: 'TA_LABEL_TEMPLATE',
  TA_MESSAGE_IMPORT_SUCCESS: 'TA_MESSAGE_IMPORT_SUCCESS',
  TA_IMPORT_TOTAL_RECORDS: 'TA_IMPORT_TOTAL_RECORDS',
  TA_IMPORT_SUBMITTED_LOADED: 'TA_IMPORT_SUBMITTED_LOADED',
  TA_IMPORT_REJECTED_LOADED: 'TA_IMPORT_REJECTED_LOADED',
  TA_IMPORT_INVALID_LOADED: 'TA_IMPORT_INVALID_LOADED',
  TA_IMPORT_SUBMITTED_AMOUNT: 'TA_IMPORT_SUBMITTED_AMOUNT',
  TA_MESSAGE_IMPORT_NOT_ALL_SUCCESS: 'TA_MESSAGE_IMPORT_NOT_ALL_SUCCESS',

  /**
   * Static label keys for Approve transactions in Template adjustments
   */
  TA_APPROVE_APPROVAL_TOTAL: 'TA_APPROVE_APPROVAL_TOTAL',
  TA_APPROVE_AMOUNT_TOTAL: 'TA_APPROVE_AMOUNT_TOTAL',
  TA_APPROVE_CONFIRMATION: 'TA_APPROVE_CONFIRMATION',

  /**
   * Static label keys for Delete transactions in Template adjustments
   */
  TA_DELETE_SELECTED_DELETE: 'TA_DELETE_SELECTED_DELETE',
  TA_DELETE_AMOUNT: 'TA_DELETE_AMOUNT',
  TA_DELETE_PROCEED_CONFIRMATION: 'TA_DELETE_PROCEED_CONFIRMATION',
  TA_DELETE_SUCCESS: 'TA_DELETE_SUCCESS',
  TA_DELETE_SUCCESSFUL: 'TA_DELETE_SUCCESSFUL',
  TA_DELETE_TOTAL: 'TA_DELETE_TOTAL',
  TA_DELTE_AMOUNT: 'TA_DELTE_AMOUNT',

  /**
   * Static label keys for Edit transactions in Template adjustments
   */
  TA_TITLE_EDIT_BULK_ADJUSTMENTS: 'TA_TITLE_EDIT_BULK_ADJUSTMENTS',
  TA_EDIT_SUCCESS: 'TA_EDIT_SUCCESS',

  /**
   * Static Label Keys (MSG_ID) for Field Force Page Contents - START
   */

  /** ARIA LABEL FOR FF */
  ARIA_FFI_VIEW_BUTTON: 'ARIA_FFI_VIEW_BUTTON',

  FF_PAGE_TITLE: 'Field Force',
  FFG_ADD_TITLE: 'FFG_ADD_TITLE',
  FF_LABEL_REPORTINGCONTROLLER: 'FF_LABEL_REPORTINGCONTROLLER',
  FF_LABEL_PAYMENTCONTROLLER: 'FF_LABEL_PAYMENTCONTROLLER',

  FF_LABEL_MLC_SELLING_CODE: 'FF_LABEL_MLC_SELLING_CODE',
  FF_LABEL_MLC_SECCODE: 'FF_LABEL_MLC_SECCODE',
  FF_LABEL_BUSINESS_FIRSTNAME: 'FF_LABEL_BUSINESS_FIRSTNAME',
  FF_LABEL_BUSINESS_LASTNAME: 'FF_LABEL_BUSINESS_LASTNAME',
  FF_LABEL_ADVISOR_TYPE: 'FF_LABEL_ADVISOR_TYPE',
  FF_LABEL_PAYEE: 'FF_LABEL_PAYEE',
  FF_LABEL_PAYEE_CHGREASON: 'FF_LABEL_PAYEE_CHGREASON',
  FF_LABEL_COMPSCHEDULE: 'FF_LABEL_COMPSCHEDULE',
  FF_LABEL_CONTRACT_DATE: 'FF_LABEL_CONTRACT_DATE',
  FF_LABEL_TERMINATION_DATE: 'FF_LABEL_TERMINATION_DATE',
  FF_LABEL_STATUS_REASON: 'FF_LABEL_STATUS_REASON',
  FF_LABEL_SERVICE_ANNIVERSARY: 'FF_LABEL_SERVICE_ANNIVERSARY',
  FF_LABEL_TRANSFER_ID: 'FF_LABEL_TRANSFER_ID',
  FF_LABEL_JURISDICTION: 'FF_LABEL_JURISDICTION',
  FF_LABEL_PERSONEL_CLIENT_ID: 'FF_LABEL_PERSONEL_CLIENT_ID',
  FF_LABEL_STMT_CLIENT_ID: 'FF_LABEL_STMT_CLIENT_ID',
  FF_LABEL_STMT_FIRSTNAME: 'FF_LABEL_STMT_FIRSTNAME',
  FF_LABEL_STMT_LASTNAME: 'FF_LABEL_STMT_LASTNAME',
  FF_LABEL_BUSINESS_PHONE_NUM: 'FF_LABEL_BUSINESS_PHONE_NUM',
  FF_LABEL_CONTEST_ADJUSTMENT: 'FF_LABEL_CONTEST_ADJUSTMENT',
  FF_LABEL_CONTEST_ADJCREDIT: 'FF_LABEL_CONTEST_ADJCREDIT',
  FF_LABEL_NUM_OF_PRINCIPALS: 'FF_LABEL_NUM_OF_PRINCIPALS',
  FF_LABEL_LEVEL_ADVISOR_CODE: 'FF_LABEL_LEVEL_ADVISOR_CODE',
  FF_LABEL_INDID_ADVISOR: 'FF_LABEL_INDID_ADVISOR',
  FF_LABEL_INDID_AFFILIATE: 'FF_LABEL_INDID_AFFILIATE',
  FF_LABEL_INDID_CORPORATION: 'FF_LABEL_INDID_CORPORATION',
  FF_LABEL_INDID_TEMPLEVEL: 'FF_LABEL_INDID_TEMPLEVEL',
  FF_LABEL_INDID_LOCATION: 'FF_LABEL_INDID_LOCATION',
  FF_LABEL_INDID_ALTOFFICE: 'FF_LABEL_INDID_ALTOFFICE',
  FF_LABEL_INDID_DISTRIBUTOR: 'FF_LABEL_INDID_DISTRIBUTOR',
  FF_LABEL_INDID_REGION: 'FF_LABEL_INDID_REGION',
  FF_LABEL_INDID_CHANNEL: 'FF_LABEL_INDID_CHANNEL',
  FF_LABEL_INDID_COMPANY: 'FF_LABEL_INDID_COMPANY',
  FF_LABEL_REQNOTIFY_DATE: 'FF_LABEL_REQNOTIFY_DATE',
  FF_LABEL_REQNOTIFY_OVERRIDE: 'FF_LABEL_REQNOTIFY_OVERRIDE',
  FF_LABEL_PAYSTATUS: 'FF_LABEL_PAYSTATUS',
  FF_LABEL_PAYMETHOD_REASON: 'FF_LABEL_PAYMETHOD_REASON',
  FF_LABEL_CHGREASON: 'FF_LABEL_CHGREASON',
  FF_LABEL_ACTIVE_ON_EXTRACT: 'FF_LABEL_ACTIVE_ON_EXTRACT',
  FF_LABEL_PRERENEWAL_NOTICE: 'FF_LABEL_PRERENEWAL_NOTICE',
  FF_LABEL_BUSINESS_SECNAME: 'FF_LABEL_BUSINESS_SECNAME',
  FF_LABEL_ADDR_LINE1: 'FF_LABEL_ADDR_LINE1',
  FF_LABEL_ADDR_LINE2: 'FF_LABEL_ADDR_LINE2',
  FF_LABEL_BADADDR_IND: 'FF_LABEL_BADADDR_IND',
  FF_LABEL_PROPAY_PARTICIPATION: 'FF_LABEL_PROPAY_PARTICIPATION',
  FF_LABEL_INTERNET_ID: 'FF_LABEL_INTERNET_ID',
  FF_LABEL_DEBIT_NON_COLLECTABLE: 'FF_LABEL_DEBIT_NON_COLLECTABLE',
  FF_LABEL_DEBIT_COLLSTATUS: 'FF_LABEL_DEBIT_COLLSTATUS',
  FF_LABEL_WEALTH_CODE: 'FF_LABEL_WEALTH_CODE',
  FF_LABEL_GST_FLAG: 'FF_LABEL_GST_FLAG',
  FFG_LABEL_EXCLUDE_FROM_EXTRACT: 'FFG_LABEL_EXCLUDE_FROM_EXTRACT',
  FFG_LABEL_GST_REGISTRANT: 'FFG_LABEL_GST_REGISTRANT',
  FFG_LABEL_LEVEL_ADVISOR_NUM: 'FFG_LABEL_LEVEL_ADVISOR_NUM',
  FF_LABEL_CHECKBOXOPTIONS: 'FF_LABEL_CHECKBOXOPTIONS',
  FFG_LABEL_PAYEE_NAME: 'FFG_LABEL_PAYEE_NAME',
  FFG_LABEL_REPORTING_CONTROLLER: 'FFG_LABEL_REPORTING_CONTROLLER',
  FFG_LABEL_PAYMENT_CONTROLLER: 'FFG_LABEL_PAYMENT_CONTROLLER',

  FF_LABEL_TYPE: 'Type',

  // Field force - Address
  FFA_LABEL_CLIENTID: 'FFA_LABEL_CLIENTID',
  FFA_LABEL_PERSONAL: 'FFA_LABEL_PERSONAL',
  FFA_LABEL_STATEMENT: 'FFA_LABEL_STATEMENT',
  FFA_LABEL_TYPE: 'FFA_LABEL_TYPE',
  FFA_LABEL_ADDRESS: 'FFA_LABEL_ADDRESS',
  FFA_LABEL_BUSINESS_CARD: 'FFA_LABEL_BUSINESS_CARD',
  FFA_ADD_SUCCESS: 'FFA_ADD_SUCCESS',
  FFA_EDIT_SUCCESS: 'FFA_EDIT_SUCCESS',
  // Field Force Movement
  FFM_TITLE_MOVEMENT: 'FFM_TITLE_MOVEMENT',
  FFM_LABEL_ENDDT_NOT_INC: 'FFM_LABEL_ENDDT_NOT_INC',
  FFM_LABEL_IND_ADVISOR: 'FFM_LABEL_IND_ADVISOR',
  FFM_LABEL_IND_AFFILIATE: 'FFM_LABEL_IND_AFFILIATE',
  FFM_LABEL_IND_CORPORATION: 'FFM_LABEL_IND_CORPORATION',
  FFM_LABEL_IND_TEMP: 'FFM_LABEL_IND_TEMP',
  FFM_LABEL_IND_LOCATION: 'FFM_LABEL_IND_LOCATION',
  FFM_LABEL_IND_ALTOFFICE: 'FFM_LABEL_IND_ALTOFFICE',
  FFM_LABEL_IND_DISTRIBUTOR: 'FFM_LABEL_IND_DISTRIBUTOR',
  FFM_LABEL_IND_REGION: 'FFM_LABEL_IND_REGION',
  FFM_LABEL_IND_CHANNEL: 'FFM_LABEL_IND_CHANNEL',
  FFM_LABEL_IND_COMPANY: 'FFM_LABEL_IND_COMPANY',
  // Financial (Banking)
  FFB_LABEL_PAY_METHOD: 'FFB_LABEL_PAY_METHOD',
  FFB_LABEL_PAY_METHOD_REASON: 'FFB_LABEL_PAY_METHOD_REASON',
  FFB_LABEL_EFT_ACCOUNT: 'FFB_LABEL_EFT_ACCOUNT',
  FFB_LABEL_BRANCH_NUMBER: 'FFB_LABEL_BRANCH_NUMBER',
  FFB_LABEL_TRANSFER_ID: 'FFB_LABEL_TRANSFER_ID',
  FFB_LABEL_TRANSFER_NAME: 'FFB_LABEL_TRANSFER_NAME',
  FFB_LABEL_CURRENCY: 'FFB_LABEL_CURRENCY',
  FFB_LABEL_TAX_FORM: 'FFB_LABEL_TAX_FORM',
  FFB_LABEL_DEBIT_NON_COLLECTIBLE: 'FFB_LABEL_DEBIT_NON_COLLECTIBLE',
  FFB_LABEL_REASON: 'FFB_LABEL_REASON',
  FFB_LABEL_PAY_SWITCHES: 'FFB_LABEL_PAY_SWITCHES',
  FFB_LABEL_FYC_ADVANCED: 'FFB_LABEL_FYC_ADVANCED',
  FFB_LABEL_FYC_MODAL: 'FFB_LABEL_FYC_MODAL',
  FFB_LABEL_REN_ADVANCED: 'FFB_LABEL_REN_ADVANCED',
  FFB_LABEL_REN_MODAL: 'FFB_LABEL_REN_MODAL',
  FFB_LABEL_SETTLE_UP: 'FFB_LABEL_SETTLE_UP',
  FFB_LABEL_EARNED_CHARGEBACK: 'FFB_LABEL_EARNED_CHARGEBACK',
  FFB_LABEL_LICENCE_CODE: 'FFB_LABEL_LICENCE_CODE',
  FFB_LABEL_LAST_MODIFY_DATE: 'FFB_LABEL_LAST_MODIFY_DATE',
  FFB_LABEL_LAST_MODIFIED_BY: 'FFB_LABEL_LAST_MODIFIED_BY',
  FFB_ADD_SUCCESS: 'FFB_ADD_SUCCESS',
  FFB_EDIT_SUCCESS: 'FFB_EDIT_SUCCESS',
  FF_LABEL_DATE: 'Date',
  FFN_LABEL_USER: 'FFN_LABEL_USER',
  FF_LABEL_DESCRIPTION: 'Description',
  FFN_LABEL_DETAILS: 'FFN_LABEL_DETAILS',
  FFN_LABEL_ADD_NOTES: 'FFN_LABEL_ADD_NOTES',
  FFN_LABEL_EDIT_NOTES: 'FFN_LABEL_EDIT_NOTES',
  FFN_ADD_SUCCESS: 'FFN_ADD_SUCCESS',
  FFN_EDIT_SUCCESS: 'FFN_EDIT_SUCCESS',
  FFN_DELETE_SUCCESS: 'FFN_DELETE_SUCCESS',
  FFN_DELETE_FER001: 'FFN_DELETE_FER001',
  // FF_LABEL_RELATEDID_OR_ADVISOR: 'Related ID/Advisor',
  FF_LABEL_ADVISORNAME: 'Advisor Name',
  // FF_LABEL_RELATEDINFO_OR_BRANCH: 'Related information/Branch',
  FFADMIN_LABEL_CON_ADJ_CREDITS: 'FFADMIN_LABEL_CON_ADJ_CREDITS',
  FFADMIN_LABEL_PRINCIPLES: 'FFADMIN_LABEL_PRINCIPLES',
  FFADMIN_LABEL_WRITEOFF: 'FFADMIN_LABEL_WRITEOFF',
  FFADMIN_LABEL_PCT_OVERRIDE: 'FFADMIN_LABEL_PCT_OVERRIDE',
  FFADMIN_LABEL_PCT_OVERRIDE_PCT: 'FFADMIN_LABEL_PCT_OVERRIDE_PCT',
  FFADMIN_LABEL_MIN_OVERRIDE_USED: 'FFADMIN_LABEL_MIN_OVERRIDE_USED',
  FFADMIN_LABEL_MIN_OVERRIDE: 'FFADMIN_LABEL_MIN_OVERRIDE',
  FFADMIN_LABEL_DEBT_REFINANCING: 'FFADMIN_LABEL_DEBT_REFINANCING',
  FFADMIN_LABEL_MAX_DEBT_ALLOWED: 'FFADMIN_LABEL_MAX_DEBT_ALLOWED',
  FFADMIN_LABEL_MAX_DEBT: 'FFADMIN_LABEL_MAX_DEBT',
  FFADMIN_ADD_SUCCESS: 'FFADMIN_ADD_SUCCESS',
  FFADMIN_EDIT_SUCCESS: 'FFADMIN_EDIT_SUCCESS',
  FFR_LABEL_NOTIFY_NOW: 'FFR_LABEL_NOTIFY_NOW',
  FFR_LABEL_NOTIFY_LATER: 'FFR_LABEL_NOTIFY_LATER',
  FFR_LABEL_NOTIFY: 'FFR_LABEL_NOTIFY',
  FFR_LABEL_NOTIFY_DATE: 'FFR_LABEL_NOTIFY_DATE',
  FFR_LABEL_NOTIFY_STOP: 'FFR_LABEL_NOTIFY_STOP',
  FFR_LABEL_REQ_STATUS: 'FFR_LABEL_REQ_STATUS',
  FFR_LABEL_REQUIREMENT: 'FFR_LABEL_REQUIREMENT',
  FF_LABEL_STATUS_CHANGE_DATE: 'FF_LABEL_STATUS_CHANGE_DATE',
  FFR_LABEL_COMMENTS: 'FFR_LABEL_COMMENTS',
  FFR_LABEL_ADD_REQ: 'FFR_LABEL_ADD_REQ',
  FFR_LABEL_EDIT_REQ: 'FFR_LABEL_EDIT_REQ',
  FFR_ADD_SUCCESS: 'FFR_ADD_SUCCESS',
  FFR_EDIT_SUCCESS: 'FFR_EDIT_SUCCESS',
  FFR_DELETE_SUCCESS: 'FFR_DELETE_SUCCESS',
  FFR_DELETE_CONFIRM_MSG: 'DELREC',
  FFR_REQUIREMENT_COMMENT_ADDED_SUCCESSFULLY: 'FFR_REQUIREMENT_COMMENT_ADDED_SUCCESSFULLY',
  FFR_REQUIREMENT_COMMENT_ADDED_FALIURE: 'FFR_REQUIREMENT_COMMENT_ADDED_FALIURE',
  FFR_MSG_NOTIFY_UPDATE: 'FFR_MSG_NOTIFY_UPDATE',
  FFR_MSG_NOTIFY_UPDATE_FALIURE: 'FFR_MSG_NOTIFY_UPDATE_FALIURE',
  FFR_MSG_NOTIFY_SENT: 'FFR_MSG_NOTIFY_SENT',
  FFR_MSG_NOTIFY_SENT_FALIURE: 'FFR_MSG_NOTIFY_SENT_FALIURE',
  FF_LABEL_SEQUENCE_NO: 'Sequence#',
  FF_LABEL_ACTIVE: 'Active',
  FF_LABEL_DEFAULT: 'Default',
  FF_LABEL_DEFAULT_LEVEL: 'Default Level',
  FF_TITLE_OVERRIDE_OR_ACTIVE: 'Override/Active',
  FF_LABEL_FREQUENCY: 'Frequency',
  FF_LABEL_CURRENT_BALANCE: 'Current Balance',
  FF_LABEL_CREATE_AS_DEFAULT: 'Create as default',
  FFR_LABEL_SAVE_COMMENTS: 'FFR_LABEL_SAVE_COMMENTS',
  FFR_LABEL_REQUIREMENTS: 'FFR_LABEL_REQUIREMENTS',
  FFR_LABEL_REQS_STATUS: 'FFR_LABEL_REQS_STATUS',
  FFR_MSG_DATE1753: 'FFR_MSG_DATE1753',

  FFG_ADD_SUCCESS: 'FFG_ADD_SUCCESS',
  FFG_EDIT_SUCCESS: 'FFG_EDIT_SUCCESS',

  /**
   * Static label keys for Field Force Task
   */
  FFT_LABEL_SEQ_NUM: 'FFT_LABEL_SEQ_NUM', // Sequence Number
  FFT_LABEL_TASK: 'FFT_LABEL_TASK', // Task
  FFT_LABEL_LOCATION_LEVEL: 'FFT_LABEL_LOCATION_LEVEL', // Location level
  FFT_LABEL_DETAIL1: 'FFT_LABEL_DETAIL1', // Detail 1
  FFT_LABEL_DETAIL2: 'FFT_LABEL_DETAIL2', // Detail 2
  FFT_LABEL_DETAIL3: 'FFT_LABEL_DETAIL3', // Detail 3
  FFT_LABEL_DETAIL4: 'FFT_LABEL_DETAIL4', // Detail 4
  FFT_LABEL_DETAIL5: 'FFT_LABEL_DETAIL5', // Detail 5
  FFT_LABEL_DETAIL6: 'FFT_LABEL_DETAIL6', // Detail 6
  FFT_LABEL_DETAIL7: 'FFT_LABEL_DETAIL7', // Detail 7
  FFT_LABEL_DETAIL8: 'FFT_LABEL_DETAIL8', // Detail 8
  FFT_LABEL_DETAIL9: 'FFT_LABEL_DETAIL9', // Detail 9
  FFT_LABEL_DETAIL10: 'FFT_LABEL_DETAIL10', // Detail 10
  FFT_LABEL_CREATE_AS_DEFAULT: 'FFT_LABEL_CREATE_AS_DEFAULT', // Create as default
  FFT_LABEL_CREATE_DEFAULT_LEVEL: 'FFT_LABEL_CREATE_DEFAULT_LEVEL', // Create default level
  FFT_LABEL_OVERRIDE: 'FFT_LABEL_OVERRIDE', // Override
  FFT_TITLE_ADD_TASK: 'FFT_TITLE_ADD_TASK', // Add task
  FFT_TITLE_EDIT_TASK: 'FFT_TITLE_EDIT_TASK', // Edit task
  FFT_TITLE_SET_TASK_AS_DEFAULT: 'FFT_TITLE_SET_TASK_AS_DEFAULT', // Set task as default
  FFT_TITLE_OVERRIDE_ACTIVE: 'FFT_TITLE_OVERRIDE_ACTIVE', // Override/Active
  FFT_LABEL_OVERRIDE_TASK: 'FFT_LABEL_OVERRIDE_TASK', // Override task
  FFT_TITLE_TASK_DETAILS: 'FFT_TITLE_TASK_DETAILS', // Task Details
  FFT_ADD_SUCCESS_MSG: 'FFT_ADD_SUCCESS_MSG', // Task has been added successfully.
  FFT_EDIT_SUCCESS_MSG: 'FFT_EDIT_SUCCESS_MSG',
  FFT_DELETE_SUCCESS_MSG: 'FFT_DELETE_SUCCESS_MSG', // Task has been deleted successfully.
  FFT_DELETE_CONFIRMATION_MSG: 'FFT_DELETE_CONFIRMATION_MSG', // Are you sure you wish to delete this ^ record?
  FFT_LOCATION_NOT_EXISTS: 'FFT_LOCATION_NOT_EXISTS',
  FFT_ADD_DEFTTASK_EDITOR_CONFIRM: 'FFT_ADD_DEFTTASK_EDITOR_CONFIRM',
  FFT_LOCATION_DETAIL_MODIFICATION_CONFIRM: 'FFT_LOCATION_DETAIL_MODIFICATION_CONFIRM',
  FFT_TYPE_MODIFICATION_CONFIRM: 'FFT_TYPE_MODIFICATION_CONFIRM',
  FFT_EDIT_REDEFAULT_TASK_CONFIRM: 'FFT_EDIT_REDEFAULT_TASK_CONFIRM',
  FFTSK_DET2: 'FFTSK_DET2',
  FFT_LABEL_DEFAULT_LEVEL: 'FFT_LABEL_DEFAULT_LEVEL',
  FFT_TYPE_OR_DETAIL_MODIFIED_INFO: 'FFT_TYPE_OR_DETAIL_MODIFIED_INFO',
  FFT_BUTTON_CURRENT: 'FFT_BUTTON_CURRENT',
  FFT_BUTTON_COMPLETE: 'FFT_BUTTON_COMPLETE',
  FFT_BUTTON_PARTIAL: 'FFT_BUTTON_PARTIAL',

  /**
   * Static label keys for FF Relationships
   */
  FFREL_PAGE_TITLE: 'FFREL_PAGE_TITLE',
  FFREL_ADD_SUCCESS: 'FFREL_ADD_SUCCESS',
  FFREL_EDIT_SUCCESS: 'FFREL_EDIT_SUCCESS',
  FFREL_DELETE_SUCCESS: 'FFREL_DELETE_SUCCESS',
  FFREL_LABEL_RELTYPE: 'FFREL_LABEL_RELTYPE',
  FFREL_LABEL_RELLEVEL: 'FFREL_LABEL_RELLEVEL',
  FFREL_LABEL_RELID: 'FFREL_LABEL_RELID',
  FFREL_LABEL_RELINFO: 'FFREL_LABEL_RELINFO',
  FFREL_LABEL_DIRECTION: 'FFREL_LABEL_DIRECTION',
  FFREL_ADD_TITLE: 'FFREL_ADD_TITLE',
  FFREL_EDIT_TITLE: 'FFREL_EDIT_TITLE',
  FFREL_LABEL_RELID_ADVISOR: 'FFREL_LABEL_RELID_ADVISOR',
  FFREL_LABEL_RELINFO_BRANCH: 'FFREL_LABEL_RELINFO_BRANCH',
  FFREL_DELETE_CONFIRMATION_MSG: 'FFREL_DELETE_CONFIRMATION_MSG',
  /**
   * Static label keys for Field Force PopUp Label
   */
  FFG_PAYCTRL_STAT_PEND_CONFIRM: 'FFG_PAYCTRL_STAT_PEND_CONFIRM',
  FFG_GST_CHANGE_CONFIRM: 'FFG_GST_CHANGE_CONFIRM',
  FFA_EDIT_FER001_REQD: 'FFA_EDIT_FER001_REQD',
  FFA_ADDRESS_NOT_FOUND: 'FFA_ADDRESS_NOT_FOUND',
  FFB_EFT_NOT_FOUND: 'FFB_EFT_NOT_FOUND',
  FFB_MSG_PMTRSNCHG: 'FFB_MSG_PMTRSNCHG',

  /**
   * Static label keys for Field Force Tab
   */
  FF_LABEL_ADVISOR: 'Advisor details',
  FF_LABEL_DISTRIBUTOR: 'Distributor details',
  FF_LABEL_COMPENSATION: 'Compensation',
  FF_LABEL_COMPENSATION_STRUCT: 'Compensation structure',
  FF_LABEL_PAYMENT: 'Payments',
  FF_LABEL_ADJUSTMENT: 'MAJ_LABEL_ADJUSTMENTS',
  FF_LABEL_PRODUCTION: 'Production',
  FF_LABEL_BALANCE: 'Balance',

  FF_LABEL_MASS_AGENT_TRANSFER: 'Mass agent transfer',
  FFM_PAGE_TITLE: 'FFM_PAGE_TITLE',

  /**
   * Static label keys for Field Force Sub Tab
   */
  FF_LABEL_GENERAL: 'General',
  FF_LABEL_ADDRESS: 'Address',
  FF_LABEL_BANKING: 'Banking',
  FF_LABEL_NOTES: 'Notes',
  FF_LABEL_RELATIONSHIPS: 'FFREL_PAGE_TITLE',
  FF_LABEL_ADMINISTRATION: 'Administration',
  FF_LABEL_TASK: 'FFT_LABEL_TASK',
  FF_LABEL_REQUIREMENTS: 'Requirements',
  FF_LABEL_DEDUCTION: 'CM_DE_TITLE_LIST',
  FF_LABEL_ERR_OMISSION: 'Error and Omissions',
  FF_LABEL_FIN_ARRANGEMENT: 'Financial arrangements',
  FFI_DEL_FF_OTHERS_REPTCTRL: 'FFI_DEL_FF_OTHERS_REPTCTRL',
  FFI_DEL_FF_NOT_ELIGIBLE: 'FFI_DEL_FF_NOT_ELIGIBLE',
  FF_DELETE_SUCCESS: 'FF_DELETE_SUCCESS',
  FF_DEL_CONF_MSG: 'FFI_DEL_CONFIRM',
  FF_BUTTON_BACKTOGENERAL: 'Back to advisor details',
  FF_BUTTON_SAVE_AND_CLOSE: 'Save and close',
  FF_TITLE_ADDRESS: 'FF_TITLE_ADDRESS',
  FF_TITLE_NOTES: 'FF_TITLE_NOTES',
  FF_TITLE_BANKING: 'FF_TITLE_BANKING',
  FF_TITLE_ADMINISTRATION: 'FF_TITLE_ADMINISTRATION',
  FF_TITLE_REQUIREMENTS: 'FF_TITLE_REQUIREMENTS',

  /**
   * Static label keys for FF MAT
   */
  FFM_LABEL_TRANSLEVEL: 'FFM_LABEL_TRANSLEVEL',
  FFM_LABEL_TRANSTO: 'FFM_LABEL_TRANSTO',
  FFM_SELECT_ADVISOR_TO_TRANSFER: 'FFM_SELECT_ADVISOR_TO_TRANSFER',
  FFM_SELECT_ALL_ADVISOR: 'FFM_SELECT_ALL_ADVISOR',
  FFM_SUCCESS_TEXT: 'Success',
  FFM_FALIURE_TEXT: 'Faliure',
  FFM_TRANSFER_SUCCESS: 'FFM_TRANSFER_SUCCESS',
  FFM_TRANSFER_FAILED: 'FFM_TRANSFER_FAILED',

  /**
   * Static label keys for Fin Arrangements Tab
   */
  FA_ADD_SUCCESS: 'FA_ADD_SUCCESS',
  FA_EDIT_SUCCESS: 'FA_EDIT_SUCCESS',
  FA_DELETE_CONFIRM: 'FA_DELETE_CONFIRM',
  FA_DELETE_SUCCESS: 'FA_DELETE_SUCCESS',
  FA_LABEL_MANULIFE_CODE: 'FA_LABEL_MANULIFE_CODE',
  FINSHR100: 'FINSHR100',
  FA_LABEL_FINARR: 'FA_LABEL_FINARR',
  FA_FINARDTEND: 'FA_FINARDTEND',
  FA_FINARLT100: 'FA_FINARLT100',
  // FA_LABEL_BUSINESS_LAST_NAME: 'FA_LABEL_BUSINESS_LAST_NAME',
  FA_LABEL_STATUS: 'FA_LABEL_STATUS',
  FA_LABEL_SHAREPERC: 'FA_LABEL_SHAREPERC',
  FA_LABEL_FIRST_NAME: 'FA_LABEL_FIRST_NAME',
  FA_LABEL_LAST_NAME: 'FA_LABEL_LAST_NAME',
  FA_LABEL_TYPE: 'FA_LABEL_TYPE',
  FA_LABEL_ARR_METHOD: 'FA_LABEL_ARR_METHOD',
  FA_LABEL_GROUPING_NUM: 'FA_LABEL_GROUPING_NUM',
  FA_LABEL_START_DATE: 'FA_LABEL_START_DATE',
  FA_LABEL_END_DATE: 'FA_LABEL_END_DATE',
  FA_LABEL_TRANSFER_ID: 'FA_LABEL_TRANSFER_ID',
  FA_LABEL_POLICY_NUM: 'FA_LABEL_POLICY_NUM',
  FA_LABEL_ELEMENT: 'FA_LABEL_ELEMENT',
  FA_LABEL_COMM_SUB_TYPE: 'FA_LABEL_COMM_SUB_TYPE',
  FA_LABEL_ADD_FINARR: 'FA_LABEL_ADD_FINARR',
  FA_LABEL_TRANSFER_NAME: 'FA_LABEL_TRANSFER_NAME',
  FA_LABEL_SELECT_OPT: 'FA_LABEL_SELECT_OPT',
  FA_LABEL_POLICY: 'FA_LABEL_POLICY',
  FA_LABEL_COMMISSION: 'FA_LABEL_COMMISSION',
  FA_LABEL_ELEMENTS: 'FA_LABEL_ELEMENTS',
  FA_LABEL_EDIT_FINARR: 'FA_LABEL_EDIT_FINARR',
  FA_LABEL_COPY_FINARR: 'FA_LABEL_COPY_FINARR',

  // Aria labels Fin Arrangments
  ARIA_FIN_ARR_BUTTON_ADD: 'ARIA_FIN_ARR_BUTTON_ADD',

  // for client list
  CL_CLI_LABEL_DESIGNATIONS: 'CL_CLI_LABEL_DESIGNATIONS',
  CL_CLI_LABEL_SSN: 'CL_CLI_LABEL_SSN',
  CL_CLI_LABEL_SIN_SSN: 'CL_CLI_LABEL_SIN_SSN',
  CL_CLI_LABEL_NIN: 'CL_CLI_LABEL_NIN',
  CL_CLI_ADD_SUCCESS: 'CL_CLI_ADD_SUCCESS',
  CL_CLI_EDIT_SUCCESS: 'CL_CLI_EDIT_SUCCESS',
  CL_CLI_DELETE_SUCCESS: 'CL_CLI_DELETE_SUCCESS',
  CL_CLI_CLIDEL: 'CL_CLI_CLIDEL',
  CL_CLI_NAMETRUNC: 'CL_CLI_NAMETRUNC',
  CL_CLI_LABEL_ADDCLIENT: 'CL_CLI_LABEL_ADDCLIENT',
  CL_CLI_DELETE_CONFIRMATION: 'CL_CLI_DELETE_CONFIRMATION',
  CL_CLI_LABEL_CLIENTDETAILS: 'CL_CLI_LABEL_CLIENTDETAILS',
  CL_CLI_LABEL_SIN: 'CL_CLI_LABEL_SIN',
  CL_CLI_LABEL_FIRSTNAME: 'CL_CLI_LABEL_FIRSTNAME',
  CL_CLI_LABEL_JOINTNAME: 'CL_CLI_LABEL_JOINTNAME',
  CL_CLI_LABEL_INCLUDE_PARTYNAME: 'CL_CLI_LABEL_INCLUDE_PARTYNAME',
  CL_CLI_LABEL_COMPANYNAME: 'CL_CLI_LABEL_COMPANYNAME',
  CL_CLI_LABEL_SURNAME: 'CL_CLI_LABEL_SURNAME',
  CL_CLI_LABEL_LASTNAME: 'CL_CLI_LABEL_LASTNAME',
  CL_CLI_LABEL_GENDER: 'CL_CLI_LABEL_GENDER',
  CL_CLI_LABEL_TITLE: 'CL_CLI_LABEL_TITLE',
  CL_CLI_LABEL_DOB: 'CL_CLI_LABEL_DOB',
  CL_CLI_LABEL_PROVINCE: 'CL_CLI_LABEL_PROVINCE',
  CL_CLA_LABEL_PROVINCE_STATE: 'CL_CLA_LABEL_PROVINCE_STATE',
  CL_CLA_LABEL_STATE: 'CL_CLA_LABEL_STATE',
  CL_CLA_LABEL_POSTAL_ZIP_CODE: 'CL_CLA_LABEL_POSTAL_ZIP_CODE',
  CL_CLA_LABEL_ZIP_CODE: 'CL_CLA_LABEL_ZIP_CODE',
  CL_CLI_LABEL_BUSINESS: 'CL_CLI_LABEL_BUSINESS',
  CL_CLI_LABEL_BADADDRESS: 'CL_CLI_LABEL_BADADDRESS',
  CL_CLI_LABEL_PUBLISH: 'CL_CLI_LABEL_PUBLISH',
  CL_CLI_LABEL_EMAIL: 'CL_CLI_LABEL_EMAIL',
  CL_CLI_LABEL_CLIENTTYPE: 'CL_CLI_LABEL_CLIENTTYPE',
  CL_CLI_LABEL_CLIENTNUMBER: 'CL_CLI_LABEL_CLIENTNUMBER',
  CL_CLI_LABEL_PRIMARYLICPROVINCE: 'CL_CLI_LABEL_PRIMARYLICPROVINCE',
  CL_CLI_LABEL_MIDDLENAME: 'CL_CLI_LABEL_MIDDLENAME',

  // Client Address
  CL_CLA_LABEL_TYPE: 'CL_CLA_LABEL_TYPE',
  CL_CLA_LABEL_ADDRESS: 'CL_CLA_LABEL_ADDRESS',
  CL_CLA_LABEL_LAST_CHANGE_ON: 'CL_CLA_LABEL_LAST_CHANGE_ON',
  CL_CLA_LABEL_LAST_CHANGE_BY: 'CL_CLA_LABEL_LAST_CHANGE_BY',
  CL_CLA_LABEL_ADD_ADDRESS: 'CL_CLA_LABEL_ADD_ADDRESS',
  CL_CLA_LABEL_EDIT_ADDRESS: 'CL_CLA_LABEL_EDIT_ADDRESS',
  CL_CLA_LABEL_INC_CL_NAME: 'CL_CLA_LABEL_INC_CL_NAME',
  CL_CLA_LABEL_CL_NAME: 'CL_CLA_LABEL_CL_NAME',
  CL_CLA_LABEL_SEC_NAME: 'CL_CLA_LABEL_SEC_NAME',
  CL_CLA_LABEL_ADDRESS_1: 'CL_CLA_LABEL_ADDRESS_1',
  CL_CLA_LABEL_ADDRESS_2: 'CL_CLA_LABEL_ADDRESS_2',
  CL_CLA_LABEL_EXTENSION: 'CL_CLA_LABEL_EXTENSION',
  CL_CLA_LABEL_ADDSUCCESS: 'CL_CLA_LABEL_ADDSUCCESS',
  CL_CLA_LABEL_EDITSUCCESS: 'CL_CLA_LABEL_EDITSUCCESS',
  CL_CLA_LABEL_DELETESUCCESS: 'CL_CLA_LABEL_DELETESUCCESS',
  CL_CLA_LABEL_DELETECONFIRM: 'CL_CLA_LABEL_DELETECONFIRM',
  CL_CLA_PRIMARYADDR_CHANGE: 'CL_CLA_PRIMARYADDR_CHANGE',
  CL_CLA_PRIMARYLIC_DUP: 'CL_CLA_PRIMARYLIC_DUP',
  CL_CLA_PRIMARYLIC_CONFIRM: 'CL_CLA_PRIMARYLIC_CONFIRM',
  CL_CLA_DELERR: 'CL_CLA_DELERR',

  // client licence
  CL_LIC_LABEL_ADVISORCODE: 'CL_LIC_LABEL_ADVISORCODE',
  CL_LIC_LABEL_ADDLIC: 'CL_LIC_LABEL_ADDLIC',
  CL_LIC_LABEL_EDITLIC: 'CL_LIC_LABEL_EDITLIC',
  CL_LIC_LICENCE: 'CL_LIC_LICENCE',
  CL_LIC_LABEL_LOCATION: 'CL_LIC_LABEL_LOCATION',
  CL_LIC_LABEL_CLASS: 'CL_LIC_LABEL_CLASS',
  CL_LIC_LABEL_APPROVALEXPIRY: 'CL_LIC_LABEL_APPROVALEXPIRY',
  CL_LIC_LABEL_EFFECTIVEDATE: 'CL_LIC_LABEL_EFFECTIVEDATE',
  CL_LIC_LABEL_EXPIRYDATE: 'CL_LIC_LABEL_EXPIRYDATE',
  CL_LIC_LABEL_OTHER_REQ: 'CL_LIC_LABEL_OTHER_REQ',
  CL_LIC_LABEL_LICENCESTATUS: 'CL_LIC_LABEL_LICENCESTATUS',
  CL_LIC_LABEL_NUMBER: 'CL_LIC_LABEL_NUMBER',
  CL_LIC_LABEL_COMPANYSPONSOR: 'CL_LIC_LABEL_COMPANYSPONSOR',
  CL_LIC_LABEL_GRACEEXPIRYDATE: 'CL_LIC_LABEL_GRACEEXPIRYDATE',
  CL_LIC_LABEL_LICID: 'CL_LIC_LABEL_LICID',
  CL_LIC_LABEL_OTHER_REQ_TYPE: 'CL_LIC_LABEL_OTHER_REQ_TYPE',
  CL_LIC_LABEL_OTHER_REQ_EXPIRY_DATE: 'CL_LIC_LABEL_OTHER_REQ_EXPIRY_DATE',
  CL_LIC_LABEL_OTHER_REQ_EXPIRY: 'CL_LIC_LABEL_OTHER_REQ_EXPIRY',
  CL_LIC_LABEL_LASTACTDATE: 'CL_LIC_LABEL_LASTACTDATE',
  CL_LIC_LABEL_CLSFBUS: 'CL_LIC_LABEL_CLSFBUS',
  CL_LIC_LABEL_GRACEEXPIRY: 'CL_LIC_LABEL_GRACEEXPIRY',
  CL_LIC_LABEL_LICNUM: 'CL_LIC_LABEL_LICNUM',
  CL_LIC_LABEL_LICLEVEL: 'CL_LIC_LABEL_LICLEVEL',
  CL_LIC_LABEL_COMMENTS: 'CL_LIC_LABEL_COMMENTS',
  CL_LIC_LABEL_DELETECONFIRM: 'CL_LIC_LABEL_DELETECONFIRM',
  CL_LIC_LABEL_DELETESUCCESS: 'CL_LIC_LABEL_DELETESUCCESS',
  CL_LIC_LABEL_ADDSUCCESS: 'CL_LIC_LABEL_ADDSUCCESS',
  CL_LIC_LABEL_EDITSUCCESS: 'CL_LIC_LABEL_EDITSUCCESS',

  // client banking
  CL_CLB_LABEL_ACNTHOLDER: 'CL_CLB_LABEL_ACNTHOLDER',
  CL_CLB_LABEL_BRANCHNUM: 'CL_CLB_LABEL_BRANCHNUM',
  CL_CLB_LABEL_BRANCH: 'CL_CLB_LABEL_BRANCH',
  CL_CLB_LABEL_BANK: 'CL_CLB_LABEL_BANK',
  CL_CLB_LABEL_ACNTTYPE: 'CL_CLB_LABEL_ACNTTYPE',
  CL_CLB_LABEL_ACNTNUM: 'CL_CLB_LABEL_ACNTNUM',
  CL_CLB_LABEL_BANKING: 'CL_CLB_LABEL_BANKING',
  CL_CLB_LABEL_BANKREC: 'CL_CLB_LABEL_BANKREC',
  CL_CLB_LABEL_ADDBANKING: 'CL_CLB_LABEL_ADDBANKING',
  CL_CLB_LABEL_EDITBANKING: 'CL_CLB_LABEL_EDITBANKING',
  CL_CLB_LABEL_ADDSUCCESS: 'CL_CLB_LABEL_ADDSUCCESS',
  CL_CLB_LABEL_EDITSUCCESS: 'CL_CLB_LABEL_EDITSUCCESS',
  CL_CLB_LABEL_DELETESUCCESS: 'CL_CLB_LABEL_DELETESUCCESS',
  CL_CLB_LABEL_DELCNFMSG: 'CL_CLB_LABEL_DELCNFMSG',
  CL_CLB_REL_EXISTS: 'CL_CLB_REL_EXISTS',

  // client notes
  CL_CLN_LABEL_NOTES: 'CL_CLN_LABEL_NOTES',
  CL_CLN_LABEL_DATE: 'CL_CLN_LABEL_DATE',
  CL_CLN_LABEL_USER: 'CL_CLN_LABEL_USER',
  CL_CLN_LABEL_DESCRIPTION: 'CL_CLN_LABEL_DESCRIPTION',
  CL_CLN_LABEL_DETAILS: 'CL_CLN_LABEL_DETAILS',
  CL_CLN_LABEL_ADDNOTES: 'CL_CLN_LABEL_ADDNOTES',
  CL_CLN_LABEL_EDITNOTES: 'CL_CLN_LABEL_EDITNOTES',
  CL_CLN_LABEL_DELCNFMSG: 'CL_CLN_LABEL_DELCNFMSG',
  CL_CLN_LABEL_ADDSUCCESS: 'CL_CLN_LABEL_ADDSUCCESS',
  CL_CLN_LABEL_EDITSUCCESS: 'CL_CLN_LABEL_EDITSUCCESS',
  CL_CLN_LABEL_DELETESUCCESS: 'CL_CLN_LABEL_DELETESUCCESS',
  PAY_LABEL_PAYCONTROLLERID: 'PAY_LABEL_PAYCONTROLLERID',
  PAY_LABEL_ADVISORNAMEOV: 'PAY_LABEL_ADVISORNAMEOV',
  PAY_LABEL_ADDRESSOV: 'PAY_LABEL_ADDRESSOV',
  PAY_LABEL_BALANCE: 'PAY_LABEL_BALANCE',
  PAY_TITLE_LIST: 'PAY_TITLE_LIST',
  PAYMENTS_SUCCESS_MSG: 'PAYMENTS_SUCCESS_MSG',
  PAY_LABEL_NEGOTIATEDDEBIT: 'PAY_LABEL_NEGOTIATEDDEBIT',
  PAY_LABEL_AMTOFPAYMENT: 'PAY_LABEL_AMTOFPAYMENT',
  PAY_LABEL_PAYMENTREASON: 'PAY_LABEL_PAYMENTREASON',
  PAY_LABEL_MEMO: 'PAY_LABEL_MEMO',
  PAY_LABEL_PAYMENTMODE: 'PAY_LABEL_PAYMENTMODE',
  PAY_LABEL_VIEWBANKDETAILS: 'PAY_LABEL_VIEWBANKDETAILS',
  PAY_LABEL_BANKINGDETAILS: 'PAY_LABEL_BANKINGDETAILS',
  PAY_THRESHOLD_MSG: 'PAY_THRESHOLD_MSG',
  NOPAYT1: 'NOPAYT1',
  PAYNOTDIR: 'PAYNOTDIR',
  PAYNOTPC: 'PAYNOTPC',
  PAYAGTPEND: 'PAYAGTPEND',
  CM_DE_CURRENCY: 'CM_DE_CURRENCY ',
  CM_DE_PRIORITY: 'CM_DE_PRIORITY',
  CM_DE_MONTHTRG: 'CM_DE_MONTHTRG',
  CM_DE_COSTCENTER: 'CM_DE_COSTCENTER',
  CM_DE_SPECIALPAY: 'CM_DE_SPECIALPAY',
  CM_DE_CUMAMT: 'CM_DE_CUMAMT',
  CM_DE_MONTHCUML: 'CM_DE_MONTHCUML',
  CM_DE_CALCMTHD: 'CM_DE_CALCMTHD',
  CM_DE_FREQ: 'CM_DE_FREQ',
  CM_DE_PERCDAYS: 'CM_DE_PERCDAYS',
  CM_DE_ENDDATE: 'CM_DE_ENDDATE',
  CM_DE_ACTIVE: 'CM_DE_ACTIVE',
  CM_DE_AMOUNT: 'CM_DE_AMOUNT',
  CM_DE_TARGETAMT: 'CM_DE_TARGETAMT',
  CM_DE_MONTHTRGAMT: 'CM_DE_MONTHTRGAMT',
  CM_DE_PERFRSTYEARCOMM: 'CM_DE_PERFRSTYEARCOMM',
  CM_DE_PERRENCOMM: 'CM_DE_PERRENCOMM',
  CM_DE_PERFRSTYEAROVERRIDE: 'CM_DE_PERFRSTYEAROVERRIDE',
  CM_DE_PERRENOVERRIDE: 'CM_DE_PERRENOVERRIDE',
  CM_DE_TRANSACTION: 'CM_DE_TRANSACTION',
  CM_DE_COMMTYPE: 'CM_DE_COMMTYPE',
  CM_DE_PERBALANCE: 'CM_DE_PERBALANCE',
  CM_DE_TRANSFERADV: 'CM_DE_TRANSFERADV',
  CM_DE_CHEQMEMO: 'CM_DE_CHEQMEMO',
  CM_DE_LSTPROCESSED: 'CM_DE_LSTPROCESSED',
  CM_DE_COMMSUBTYPE: 'CM_DE_COMMSUBTYPE',
  CM_DE_TYPE: 'CM_DE_TYPE',
  CM_DE_SEQ: 'CM_DE_SEQ',
  CM_DE_FER001_TRANSACTIONONCLICK: 'DE_ADD_FER001_TRANSACTIONONCLICK',
  DE_ADD_SUCCESS: 'DE_ADD_SUCCESS',
  DE_EDIT_SUCCESS: 'DE_EDIT_SUCCESS',
  CM_DE_LABEL_CALCMETHOD: 'CM_DE_LABEL_CALCMETHOD',
  CM_DE_LABEL_LASTPROCESSED: 'CM_DE_LABEL_LASTPROCESSED',
  CM_DE_LABEL_ADVISORREQ: 'CM_DE_LABEL_ADVISORREQ',
  CM_DE_LABEL_TRANSFERID: 'CM_DE_LABEL_TRANSFERID',
  CM_DE_LABEL_MONTHTRGAMT: 'CM_DE_LABEL_MONTHTRGAMT',
  CM_DE_LABEL_MONTHTRG: 'CM_DE_LABEL_MONTHTRG',
  CM_DE_LABEL_MONTHLYCUMULATIVE: 'CM_DE_LABEL_MONTHLYCUMULATIVE',
  CM_DE_LABEL_TARGETAMT: 'CM_DE_LABEL_TARGETAMT',
  CM_DE_LABEL_PERCDAYS: 'CM_DE_LABEL_PERCDAYS',
  CM_DE_LABEL_MONTARGETAMT: 'CM_DE_LABEL_MONTARGETAMT',
  CM_DE_LABEL_PERCNTOFBALANCE: 'CM_DE_LABEL_PERCNTOFBALANCE',
  CM_DE_LABEL_PERCNTOFFYC: 'CM_DE_LABEL_PERCNTOFFYC',
  CM_DE_LABEL_PERCOFRENCOMM: 'CM_DE_LABEL_PERCOFRENCOMM',
  CM_DE_LABEL_PERCOFFYROVRDE: 'CM_DE_LABEL_PERCOFFYROVRDE',
  CM_DE_LABEL_PERCOFRENOVRDE: 'CM_DE_LABEL_PERCOFRENOVRDE',
  CM_DE_LABEL_CHQMEMOREMARK: 'CM_DE_LABEL_CHQMEMOREMARK',
  CM_DE_LABEL_CUMULATIVEAMT: 'CM_DE_LABEL_CUMULATIVEAMT',
  CM_DE_LABEL_SPECIALPAY: 'CM_DE_LABEL_SPECIALPAY',
  CM_DE_LABEL_MLC_SELLING_CODE: 'CM_DE_LABEL_MLC_SELLING_CODE',
  CM_DE_TRANSFERADVNAME: 'CM_DE_TRANSFERADVNAME',
  CM_DE_LABEL_DAYS: 'CM_DE_LABEL_DAYS',
  CM_DE_LABEL_PERC_SYMBOL: 'CM_DE_LABEL_PERC_SYMBOL',
  PAY_LABEL_CHQPROCESSOPTIONS: 'PAY_LABEL_CHQPROCESSOPTIONS',
  DE_DELETE_SUCCESS: 'DE_DELETE_SUCCESS',
  DE_DEL_CONFIRM: 'DE_DEL_CONFIRM',
  CM_DE_LABEL_PERCENT: 'CM_DE_LABEL_PERCENT',
  CM_DE_TITLE_ADD: 'CM_DE_TITLE_ADD',
  CM_DE_TITLE_EDIT: 'CM_DE_TITLE_EDIT',
  CM_DE_LABLE_TARGET: 'CM_DE_LABLE_TARGET',
  CM_DE_TITLE_LIST: 'CM_DE_TITLE_LIST',
  COMPENS015: 'COMPENS015',
  DE_INVALID_ADVISOR: 'CM_DE_LABEL_INVALIDAGT',
  PAY_THRESHOLD_MSG_LABEL: 'PAY_THRESHOLD_MSG_LABEL',
  /*
   *ARIA LABELS FOR BCR PAGE - START
   */
  ARIA_CLI_BUTTON_ADD: 'ARIA_CLI_BUTTON_ADD',
  ARIA_CLA_BUTTON_ADD: 'ARIA_CLA_BUTTON_ADD',
  ARIA_LIC_BUTTON_ADD: 'ARIA_LIC_BUTTON_ADD',
  ARIA_CLB_BUTTON_ADD: 'ARIA_CLB_BUTTON_ADD',
  ARIA_CLN_BUTTON_ADD: 'ARIA_CLN_BUTTON_ADD',
  ARIA_CLA_BUTTON_VIEW: 'ARIA_CLA_BUTTON_VIEW',
  ARIA_CLD_BUTTON_VIEW: 'ARIA_CLD_BUTTON_VIEW',
  ARIA_CLA_BUS_PH_EXT_LABEL: 'ARIA_CLA_BUS_PH_EXT_LABEL',
  ARIA_CLA_FAX_NO_EXT_LABEL: 'ARIA_CLA_FAX_NO_EXT_LABEL',
  /*
   *ARIA LABELS FOR BCR PAGE - END
   */

  /**
   * Static label keys for Relationship
   */
  RLI_PAGE_TITLE: 'RLI_PAGE_TITLE',
  COMMON_LABEL_RELTYPE: 'COMMON_LABEL_RELTYPE',
  RLI_LABEL_RELLEVEL: 'RLI_LABEL_RELLEVEL',
  RLI_LABEL_RELID_ADVISOR: 'RLI_LABEL_RELID_ADVISOR',
  RLI_LABEL_RELINFO_BRANCH: 'RLI_LABEL_RELINFO_BRANCH',
  RLI_TYPE_KEY: 'relType',
  RLI_TYPE: 'PROPAY',
  RLI_DELETE_CONFIRMATION: 'RLI_DELETE_CONFIRMATION',
  RLI_DELETE_SUCCESS: 'RLI_DELETE_SUCCESS',

  // label keys for Licence
  LIP_NO_RECORD_SELECTED: 'LIP_NO_RECORD_SELECTED',
  LIP_CHGSTATPRO_FAILED: 'LIP_CHGSTATPRO_FAILED',
  LIP_CHANGE_STATUS_SUCCESS: 'LIP_CHANGE_STATUS_SUCCESS',
  LIP_PAGE_TITLE: 'LIP_PAGE_TITLE',
  LIP_LABEL_CLIENT_NUM: 'LIP_LABEL_CLIENT_NUM',
  LIP_LABEL_LICENCE_STATUS: 'LIP_LABEL_LICENCE_STATUS',
  LIP_LABEL_LICENCE_LOCATION: 'LIP_LABEL_LICENCE_LOCATION',
  LIP_LABEL_LICENCE_CLASS: 'LIP_LABEL_LICENCE_CLASS',
  LIP_LABEL_ADVISOR_STATUS: 'LIP_LABEL_ADVISOR_STATUS',
  LIP_LABEL_APPT_EXPIRY: 'LIP_LABEL_APPT_EXPIRY',
  LIP_LABEL_LICENCE_STATUS_CHGDATE: 'LIP_LABEL_LICENCE_STATUS_CHGDATE',
  LIP_LABEL_COMPANY_SPONSER: 'LIP_LABEL_COMPANY_SPONSER',
  LIP_LABEL_LICENCE_EFFDATE: 'LIP_LABEL_LICENCE_EFFDATE',
  LIP_LABEL_LICENCE_EXPDATE: 'LIP_LABEL_LICENCE_EXPDATE',
  LIP_LABEL_LICENCE_NUM: 'LIP_LABEL_LICENCE_NUM',
  LIP_LABEL_LICENCE_OTHER_REQ: 'LIP_LABEL_LICENCE_OTHER_REQ',
  LIP_LABEL_LICENCE_OTHER_REQTYPE: 'LIP_LABEL_LICENCE_OTHER_REQTYPE',
  LIP_LABEL_LICENCE_OTHER_REQEXP: 'LIP_LABEL_LICENCE_OTHER_REQEXP',
  LIP_LABEL_LICENCE_LEVEL: 'LIP_LABEL_LICENCE_LEVEL',
  LIP_LABEL_LICENCE_GRACE_EXPDATE: 'LIP_LABEL_LICENCE_GRACE_EXPDATE',
  LIP_LABEL_BUSADDR_JURISDICTION: 'LIP_LABEL_BUSADDR_JURISDICTION',
  LIP_LABEL_CHANGE_STATUS: 'LIP_LABEL_CHANGE_STATUS',
  LIP_LABEL_SELECT_LETTER: 'LIP_LABEL_SELECT_LETTER',
  LIP_LABEL_ORIGINAL_STATUS: 'LIP_LABEL_ORIGINAL_STATUS',
  LIP_LABEL_CHANGE_TO_STATUS: 'LIP_LABEL_CHANGE_TO_STATUS',

  // label keys for letter
  LTR_PAGE_TITLE: 'LTR_PAGE_TITLE',
  LTR_LABEL_ADDRESS: 'LTR_LABEL_ADDRESS',
  LTR_BUTTON_BUSINESS: 'LTR_BUTTON_BUSINESS',
  LTR_BUTTON_MAILING: 'LTR_BUTTON_MAILING',
  LTR_LABEL_DESCRIPTION: 'LTR_LABEL_DESCRIPTION',
  LTR_LABEL_LASTUPDATE: 'LTR_LABEL_LASTUPDATE',
  LTR_LABEL_DOCUMENT_TYPE: 'LTR_LABEL_DOCUMENT_TYPE',
  LTR_LABEL_TYPE: 'LTR_LABEL_TYPE',
  LTR_LABEL_PATH: 'LTR_LABEL_PATH',
  LTR_LABEL_AUTOPRINT: 'LTR_LABEL_AUTOPRINT',
  LTR_BUTTON_DOWNLOAD: 'LTR_BUTTON_DOWNLOAD',
  NO_TEMPLATES_VIEW: 'No templates to view',

  COMMON_LABEL_ALTOFFICE: 'COMMON_LABEL_ALTOFFICE',
  COMMON_LABEL_LOCATION: 'COMMON_LABEL_LOCATION',
  COMMON_LABEL_TEMPLEVEL: 'COMMON_LABEL_TEMPLEVEL',
  COMMON_LABEL_CORPORATION: 'COMMON_LABEL_CORPORATION',
  COMMON_LABEL_AFFILIATE: 'COMMON_LABEL_AFFILIATE',
  COMMON_LABEL_LEVEL: 'COMMON_LABEL_LEVEL',
  COMMON_LABEL_BACK: 'COMMON_LABEL_BACK',
  COMMON_LABEL_ADVISOR_CODE: 'COMMON_LABEL_ADVISOR_CODE',
  COMMON_LABEL_BUSINESS_FIRSTNAME: 'COMMON_LABEL_BUSINESS_FIRSTNAME',
  COMMON_LABEL_BUSINESS_LASTNAME: 'COMMON_LABEL_BUSINESS_LASTNAME',
  COMMON_LABEL_COUNTRY: 'COMMON_LABEL_COUNTRY',
  COMMON_LABEL_PAYMETHOD: 'COMMON_LABEL_PAYMETHOD',
  COMMON_LABEL_PAYSTATUS: 'COMMON_LABEL_PAYSTATUS',
  COMMON_LABEL_DISTRIBUTOR: 'COMMON_LABEL_DISTRIBUTOR',
  COMMON_LABEL_REGION: 'COMMON_LABEL_REGION',
  COMMON_LABEL_CHANNEL: 'COMMON_LABEL_CHANNEL',
  COMMON_LABEL_COMPANY: 'COMMON_LABEL_COMPANY',
  COMMON_LABEL_STATUS: 'COMMON_LABEL_STATUS',
  COMMON_LABEL_STATUS_CHGDATE: 'COMMON_LABEL_STATUS_CHGDATE',

  // label keys for Field Force letter
  FFL_PAGE_TITLE: 'FFL_PAGE_TITLE',
  FFL_LABEL_MLC_SELLING_CODE: 'FFL_LABEL_MLC_SELLING_CODE',
  FFL_LABEL_MLC_SECCODE: 'FFL_LABEL_MLC_SECCODE',
  FFL_LABEL_TYPE: 'FFL_LABEL_TYPE',
  FFL_LABEL_NAME: 'FFL_LABEL_NAME',
  FFL_LABEL_DESIGNATION: 'FFL_LABEL_DESIGNATION',
  FFL_LABEL_CONTACTNAME: 'FFL_LABEL_CONTACTNAME',
  FFL_LABEL_COMPSCHEDULE: 'FFL_LABEL_COMPSCHEDULE',
  FFL_LABEL_PAYMENT_STATUS: 'FFL_LABEL_PAYMENT_STATUS',
  FFL_LABEL_CONTRACT_DATE: 'FFL_LABEL_CONTRACT_DATE',
  FFL_LABEL_TERMINATION_DATE: 'FFL_LABEL_TERMINATION_DATE',
  FFL_LABEL_VESTED_PERIOD: 'FFL_LABEL_VESTED_PERIOD',
  FFL_LABEL_CONTACTDATE: 'FFL_LABEL_CONTACTDATE',
  FFL_LABEL_AFFILIATE: 'FFL_LABEL_AFFILIATE',
  FFL_LABEL_CORPORATION: 'FFL_LABEL_CORPORATION',
  FFL_LABEL_MAXIMUM_DEBT_ALLOWED: 'FFL_LABEL_MAXIMUM_DEBT_ALLOWED',
  FFL_LABEL_MAXIMUM_DEBT_EXPIRE_DATE: 'FFL_LABEL_MAXIMUM_DEBT_EXPIRE_DATE',
  FFL_LABEL_PAYMENT_METHOD: 'FFL_LABEL_PAYMENT_METHOD',
  FFL_LABEL_CLIENT_ADDRESS: 'FFL_LABEL_CLIENT_ADDRESS',
  FFL_LABEL_CITY: 'FFL_LABEL_CITY',
  FFL_LABEL_JURISDICTION: 'FFL_LABEL_JURISDICTION',
  FFL_LABEL_BUSINESS_SECONDARY_NAME: 'FFL_LABEL_BUSINESS_SECONDARY_NAME',
  FFL_LABEL_PAYMENT_CONTROLLER: 'FFL_LABEL_PAYMENT_CONTROLLER',
  FFL_LABEL_INDID_AFFILIATE: 'FFL_LABEL_INDID_AFFILIATE',
  FFL_LABEL_INDID_CORPORATION: 'FFL_LABEL_INDID_CORPORATION',
  FFL_LABEL_INDID_TEMPLEVEL: 'FFL_LABEL_INDID_TEMPLEVEL',
  FFL_LABEL_INDID_LOCATION: 'FFL_LABEL_INDID_LOCATION',
  FFL_LABEL_INDID_ALTOFFICE: 'FFL_LABEL_INDID_ALTOFFICE',
  FFL_LABEL_INDID_DISTRIBUTOR: 'FFL_LABEL_INDID_DISTRIBUTOR',
  FFL_LABEL_INDID_REGION: 'FFL_LABEL_INDID_REGION',
  FFL_LABEL_INDID_CHANNEL: 'FFL_LABEL_INDID_CHANNEL',
  FFL_LABEL_INDID_COMPANY: 'FFL_LABEL_INDID_COMPANY',
  FFL_LABEL_INDID_ADVISOR: 'FFL_LABEL_INDID_ADVISOR',
  FFL_LABEL_FIRST_NAME: 'FFL_LABEL_FIRSTNAME',
  FFL_LABEL_MIDDLE_NAME: 'FFL_LABEL_MIDDLE_NAME',
  FFL_LABEL_SUR_NAME: 'FFL_LABEL_SURNAME',
  FFL_LABEL_TITLE: 'FFL_LABEL_TITLE',
  PAY_ACTIVE_DED_MSG: 'PAY_ACTIVE_DED_MSG',
  // all inquiry screen
  ...moduleInquiryConstants.staticLabelKeys,
};
