/**
 * @Description Fasat Group By Row Page
 * @FileName groupByRow.js
 * @Author Karthick K
 * @CreatedOn 07 July, 2022 21:40:00
 * @IssueID - Poc
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonPlusFilled, ButtonMinusOutlined } from '@manulife/mux-cds-icons';
import { FasatDropdown, FasatLabel } from '../..';
import FasatOperatorInput from './fasatOperatorInput';
import { staticCommonLabelKeys } from '../../../moduleConstants';

const propTypes = {
  totalRows: PropTypes.arrayOf(PropTypes.shape()),
  index: PropTypes.number,
  uuid: PropTypes.string,
  item: PropTypes.string,
  operator: PropTypes.string,
  columnConfig: PropTypes.arrayOf(PropTypes.shape()),
  addRow: PropTypes.func,
  removeRow: PropTypes.func,
  removeGroupByColumn: PropTypes.func,
  configColumn: PropTypes.func,
  showDuplicateMessage: PropTypes.func,
  operatorList: PropTypes.arrayOf(PropTypes.shape()),
  customColumnGroupBy: PropTypes.arrayOf(PropTypes.shape()),
  groupByActive: PropTypes.bool,
};

const defaultProps = {
  totalRows: [],
  index: 0,
  uuid: '',
  item: '',
  operator: '',
  columnConfig: [],
  addRow: () => undefined,
  removeRow: () => undefined,
  removeGroupByColumn: () => undefined,
  configColumn: () => undefined,
  showDuplicateMessage: () => undefined,
  operatorList: [],
  customColumnGroupBy: [],
  groupByActive: true,
};

const soFilterRowClass = 'so-filterRow';
const soFilterCellClass = 'so-filterCell';
const soSortOrderCellClass = 'so-sortOrderCell';
const soActionCellClass = 'so-actionCell';
const soBlankCellClass = 'so-blankCell';
const grpColumnID = 'grpColumn';

const getButton = ({ t, totalRows, removeSection, addRow, index, groupByActive, uuid }) => (
  <div className={soActionCellClass} id={`soAction${uuid}`}>
    {' '}
    {totalRows.length > 1 && (
    <button
      type="button"
      onClick={() => removeSection()}
      aria-label={t(staticCommonLabelKeys.ARIA_FILTER_BUTTON_DELETE_GROUP_BY)}
      disabled={!groupByActive && 'disabled'}
    >
      {' '}
      <ButtonMinusOutlined />
      {' '}
    </button>
    )}
    {' '}
    {totalRows.length === index + 1 && (
    <button
      type="button"
      onClick={addRow}
      disabled={!groupByActive && 'disabled'}
      aria-label={t(staticCommonLabelKeys.ARIA_FILTER_BUTTON_ADD_GROUP_BY)}
    >
      {' '}
      <ButtonPlusFilled />
      {' '}
    </button>
    )}
    {' '}
  </div>
);

const GroupByRow = ({
  totalRows,
  index,
  uuid,
  columnConfig,
  addRow,
  removeRow,
  removeGroupByColumn,
  configColumn,
  showDuplicateMessage,
  customColumnGroupBy,
  item,
  operator,
  operatorList,
  groupByActive,
}) => {
  const [columnValue, setColumnValue] = useState(null);
  const [operatorValue, setOpertorValue] = useState(null);
  const [preSelectedIndex] = useState(-1);
  const [t] = useTranslation();
  const dropdownPlaceholder = t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT);

  /**
   * Set column value and operator value
   */
  useEffect(() => {
    if (item) {
      const colValue = columnConfig.filter((colItem) => colItem.value === item);
      if (colValue && colValue.length > 0) {
        setColumnValue(colValue[0]);
      }
    }
    if (operator) {
      const optValue = operatorList.filter((optItem) => optItem.operator === operator);
      if (optValue && optValue.length > 0) {
        setOpertorValue(optValue[0]);
      }
    }
  }, [item, operator]);

  /**
   * Set per selected Column Value and Operator Value
   */
  useEffect(() => {
    configColumn({ key: columnValue && columnValue.value, operator: operatorValue && operatorValue.operator, uuid });
  }, [columnValue, operatorValue]);

  /**
   * Remove Selection and reconfigure group by column object
   */
  const removeSection = () => {
    removeRow(uuid);
    if (operatorValue !== null) {
      removeGroupByColumn({ key: columnValue, operator: operatorValue, uuid });
    }
  };

  /**
   * set column values
   * @param {any} id
   */
  const handleColumnChange = (id) => {
    const selColumnItem = columnConfig.filter((colItem) => colItem.value === id);
    const isColumnExist = customColumnGroupBy.filter((colItem) => colItem.key === id);
    if (selColumnItem && selColumnItem.length > 0 && isColumnExist.length === 0) {
      setColumnValue(selColumnItem[0]);
      setOpertorValue(null);
    } else {
      showDuplicateMessage();
      if (columnValue === null) {
        setOpertorValue(null);
        setColumnValue(null);
      }
    }
  };

  const onOperatorChange = (val) => {
    if (val) {
      const selOperatorItem = operatorList.filter((operatorItem) => operatorItem.operator === val);
      if (selOperatorItem) {
        setOpertorValue(selOperatorItem[0]);
      }
    } else {
      setOpertorValue(null);
    }
  };

  return (
    <div className={soFilterRowClass}>
      <div className={soFilterCellClass}>
        <div className="noDeskDisp"><FasatLabel>Column</FasatLabel></div>
        <FasatDropdown
          dropdownItems={columnConfig}
          placeholder={dropdownPlaceholder}
          id={grpColumnID}
          onHandleChange={(id) => handleColumnChange(id)}
          value={columnValue && columnValue.value}
          ariaLabel={t(staticCommonLabelKeys.FILTER_LABEL_COLUMN)}
          disabled={!groupByActive && 'disabled'}
        />
      </div>
      <div className={`${soFilterCellClass} ${soSortOrderCellClass}`}>
        <div className="noDeskDisp"><FasatLabel>Operator 1</FasatLabel></div>
        <FasatOperatorInput
          columnValue={columnValue}
          operatorList={operatorList}
          onOperatorChange={(val) => onOperatorChange(val)}
          customFilterCreteriaElement={[columnValue]}
          preSelectedIndex={preSelectedIndex}
          groupByActive={groupByActive}
          optValue={operatorValue && operatorValue.operator}
          uuid={uuid}
        />
      </div>
      {getButton({ t, totalRows, uuid, removeSection, addRow, index, groupByActive })}
      <div className={soBlankCellClass} />
    </div>
  );
};

GroupByRow.propTypes = propTypes;
GroupByRow.defaultProps = defaultProps;

export default GroupByRow;
