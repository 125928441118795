import { environment } from '../../../common/util/utility';

const envData = environment();

export const apiConfigExtension = {

  // Configure Letters
  ADMINISTRATION_CL_LIST: {
    endpoint: `${envData.apiHost}/api/forms/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CL_BY_ID: {
    endpoint: `${envData.apiHost}/api/forms/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CL_INSERT: {
    endpoint: `${envData.apiHost}/api/forms/add`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CL_UPDATE: {
    endpoint: `${envData.apiHost}/api/forms/edit`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CL_DELETE: {
    endpoint: `${envData.apiHost}/api/forms/delete`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CL_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/forms/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_CL_LOOKUP_REQUEST: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  VALIDATE_CL_FILENAME: {
    endpoint: `${envData.apiHost}/api/ffletters/validatefile`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  // Daily Process
  ADMINISTRATION_DP_PROCESS_LIST: {
    endpoint: `${envData.apiHost}/api/dailyprocess/list/process`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DP_SCHEDULE_LIST: {
    endpoint: `${envData.apiHost}/api/dailyprocess/list/schedule`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DP_START: {
    endpoint: `${envData.apiHost}/api/dailyprocess/process`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  SECURITY_PAYMENTSEC_UPDATE: {
    endpoint: `${envData.apiHost}/api/security/paymentsecurity/edit`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DP_SEQUENCE: {
    endpoint: `${envData.apiHost}/api/dailyprocess/sequence`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FCR_GET: {
    endpoint: `${envData.apiHost}/api/dailyprocess/fcr/process`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },

  ADMINISTRATION_FCR_HISTORY: {
    endpoint: `${envData.apiHost}/api/dailyprocess/fcr/history`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FCR_INSERT: {
    endpoint: `${envData.apiHost}/api/dailyprocess/fcr/add`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FCR_DELETE: {
    endpoint: `${envData.apiHost}/api/dailyprocess/fcr/mark-complete`,
    type: 'PATCH',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DP_HISTORY: {
    endpoint: `${envData.apiHost}/api/dailyprocess/history`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DP_BATCHSTATUS: {
    endpoint: `${envData.apiHost}/api/dailyprocess/dailyprocessjobstatus`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DP_RESUME: {
    endpoint: `${envData.apiHost}/api/dailyprocess/resume`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  BATCH_REPORT_GET_LIST: {
    endpoint: `${envData.apiHost}/api/batchreport/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  BATCH_REPORT_JOB_DETAILS: {
    endpoint: `${envData.apiHost}/api/batchreport/jobrequestdetail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  BATCH_REPORT_START_DETAILS: {
    endpoint: `${envData.apiHost}/api/batchreport/start`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  BATCH_REPORT_STOP: {
    endpoint: `${envData.apiHost}/api/batchreport/stop`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PAT_INQUIRY_LIST: {
    endpoint: `${envData.apiHost}/api/produceraudittrail/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PAT_INQUIRY_EXPORT: {
    endpoint: `${envData.apiHost}/api/produceraudittrail/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_STRUCTURE_LIST_RESPONSE: {
    endpoint: `${envData.apiHost}/api/fieldforcestructure/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_STRUCTURE_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcestructure/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_STRUCTURE_ADD: {
    endpoint: `${envData.apiHost}/api/fieldforcestructure`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_STRUCTURE_EDIT: {
    endpoint: `${envData.apiHost}/api/fieldforcestructure`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_STRUCTURE_DELETE: {
    endpoint: `${envData.apiHost}/api/fieldforcestructure`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_STRUCTURE_GET_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforcestructure/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // Manage Users
  ADMINISTRATION_MNG_USER_BY_ID: {
    endpoint: `${envData.apiHost}/api/SecurityUser/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_DELETE: {
    endpoint: `${envData.apiHost}/api/SecurityUser/Delete`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/SecurityUser/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_INSERT: {
    endpoint: `${envData.apiHost}/api/SecurityUser/Add`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_LIST: {
    endpoint: `${envData.apiHost}/api/SecurityUser/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_SECURITY_GROUP: {
    endpoint: `${envData.apiHost}/api/lookups/groupType`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_UPDATE: {
    endpoint: `${envData.apiHost}/api/SecurityUser/Edit`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default apiConfigExtension;
