/**
 * @Description Container for Fasat Loader
 * @FileName fasatLoader.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 04 March, 2021 02:25:06
 * @IssueID 623
 */
import React from 'react';
import { Progress } from '@manulife/mux';
import styled from 'styled-components';

const Overlay = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  display: block;
  background: rgba(0, 0, 0, 0);
  z-index: 100001;
  cursor: not-allowed;
`;

const FasatLoader = () => (
  <Overlay>
    <Progress />
  </Overlay>
);

export default FasatLoader;
