import React from 'react';
import './fasatBatchUIConcurrency.scss';
import { UpdateRecycle } from '@manulife/mux-cds-icons';
import { colors } from '@manulife/mux';
import { t } from '../../i18n';
import { staticCommonLabelKeys } from '../../moduleConstants';

const FasatBatchUIConcurrency = () => (
  <div className="fasatBatchUIConcurrencySection">
    <UpdateRecycle style={{ color: colors.m_green, height: '60px', width: '60px' }} />
    <h1>{ t(staticCommonLabelKeys.BATCH_STATUS_INPROGRESS)}</h1>
    <p>{ t(staticCommonLabelKeys.BATCH_INPROGRESS_WEB_STATUS_MSG)}</p>
  </div>
);
export default FasatBatchUIConcurrency;
