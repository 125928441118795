/**
 * @Description This is fasatSecuritySettings container page
 * @FileName fasatSecuritySettings.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 27 January, 2021 11:15:07
 * @IssueID CDTFASATMOD-502
 */
import React from 'react';
import '../../../../index.scss';
import './fasatSecuritySettings.scss';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import FasatCheckbox from '../fasatCheckbox/fasatCheckbox';

const number146 = 146;
const FasatSecuritySettings = (props) => {
  const { dataObj, screenValue, onControlChange, isReadOnly, settingModal } = props;
  const [t] = useTranslation();
  const uniqueId = `-${uuidv4()}`;
  const displayChildRows = (item, cItem) => (
    <tr role="row">
      <td />
      <td>
        <FasatCheckbox
          id={cItem.resourceId}
          key={cItem.resourceId + uniqueId}
          name={cItem.msgText}
          defaultChecked={parseInt(cItem.permissionId, 10)}
          onHandleChange={(e) => onControlChange(e, item, cItem)}
          disabled={isReadOnly}
          aria-label={cItem.msgText}
          labelText={cItem.msgText}
        />
      </td>
      <td />
    </tr>
  );
  if (screenValue === '*') {
    return (
      <>
        <tr role="row">
          <td value={dataObj.msgText}>
            <FasatCheckbox
              id={dataObj.resourceId}
              key={dataObj.resourceId + uniqueId}
              name={dataObj.msgText}
              labelText={dataObj.msgText}
              defaultChecked={dataObj.permissionId}
              onHandleChange={(e) => onControlChange(e, dataObj, null)}
              disabled={isReadOnly}
            />
          </td>
          <td />
          <td>
            {dataObj.resourceId === number146
            && (
            <button className="btnAsLink" type="button" onClick={() => settingModal()}>
              {t('MG_PSC_CONFIELDFORCEPAY')}
              {' '}
            </button>
            )}
          </td>
        </tr>
        {dataObj.controls.map((cItem) => displayChildRows(dataObj, cItem))}
      </>
    );
  }
  if (screenValue === String(dataObj.resourceId)) {
    return (
      <>
        <tr role="row">
          <td value={dataObj.msgText}>
            <FasatCheckbox
              id={dataObj.resourceId}
              key={dataObj.resourceId + uniqueId}
              name={dataObj.msgText}
              labelText={dataObj.msgText}
              defaultChecked={dataObj.permissionId}
              disabled={isReadOnly}
              onHandleChange={(e) => onControlChange(e, dataObj, null)}
            />
          </td>
          <td />
          <td>
            {dataObj.resourceId === number146
            && (
            <button className="btnAsLink" type="button" onClick={() => settingModal()}>
              {t('MG_PSC_CONFIELDFORCEPAY')}
              {' '}
            </button>
            )}
          </td>
          <td />
        </tr>
        {dataObj.controls.map((cItem) => displayChildRows(dataObj, cItem))}
      </>
    );
  }

  return null;
};

FasatSecuritySettings.propTypes = {
  dataObj: PropTypes.shape({
    msgText: PropTypes.string,
    resourceId: PropTypes.string,
    permissionId: PropTypes.number,
    controls: [] }),
  onControlChange: PropTypes.func,
  settingModal: PropTypes.func,
  screenValue: PropTypes.string,
  isReadOnly: PropTypes.bool,
  formik: PropTypes.shape({
    getFieldProps: '',
  }),
};

FasatSecuritySettings.defaultProps = {
  dataObj: { data: [] },
  onControlChange: () => undefined,
  settingModal: () => undefined,
  screenValue: 'All',
  isReadOnly: false,
  formik: {
    getFieldProps: {},
  },
};

export default FasatSecuritySettings;
