import * as Yup from 'yup';

const addRules = {
  name: ['FILTER_ADD_FER001_NAME', 'FILTER_ADD_BER002', 'FILTER_DUPLICATE'],
  description: ['FILTER_ADD_FER001_DESCRIPTION'],
  sharedWith: ['FILTER_ADD_FER001_SHARED_WITH'],
};

const addRuleConfig = {
  FILTER_ADD_FER001_NAME: 'REQFLD',
  FILTER_ADD_FER001_DESCRIPTION: 'REQFLD',
  FILTER_ADD_FER001_SHARED_WITH: 'REQFLD',
  FILTER_DUPLICATE: 'FILTER_DUPLICATE',
};

const addValidationSchema = () => Yup.object().shape({
  name: Yup.string().nullable().required('FILTER_ADD_FER001_NAME'),
  description: Yup.string().nullable().required('FILTER_ADD_FER001_DESCRIPTION'),
  sharedWith: Yup.string().nullable().when('isShared', {
    is: (val) => val === 'true',
    then: Yup.string().nullable().required('FILTER_ADD_FER001_SHARED_WITH'),
    otherwise: Yup.string().nullable().notRequired(),
  }),
});

export { addValidationSchema, addRules, addRuleConfig };
