import { basicInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { manageGroupsActionType, manageLevelActionType } from '../actions/actionTypes';

export const REDUCER_NAME = 'ManageLevel';

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const logMGListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const manageApplicationReducer = (state = { ...basicInitialState }, action = null) => {
  switch (action.type) {
    case manageGroupsActionType.GET_MA_RESPONSE:
      return {
        ...state,
        maData: action.data.data,
        maDataRequestError: null,
        maDataRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.RESET_MA_DATA:
      return {
        ...state,
        maData: null,
        maUpdateData: null,
        maUpdateRequestError: null,
        maDataRequestError: null,
        maDataRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.UPDATE_MA_RESPONSE:
      return {
        ...state,
        maUpdateData: action.data,
        maUpdateRequestError: null,
        maUpdateRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.UPDATE_MA_ERROR:
      return {
        ...state,
        maUpdateRequestError: action.data.serverRequestError,
        maUpdateRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageGroupsActionType.RESET_CONCURRENCY_FLAG:
      return {
        ...state,
        concurrencyFlag: action.data,
      };
    case manageGroupsActionType.RESET_SECURITY_PAYMENT_INFO:
      return {
        ...state,
        paymentSeqInfo: action.data,
      };
    case manageGroupsActionType.PAYMENT_UPSERT_DATA:
      return {
        ...state,
        paymentFlag: action.data,
      };
    case commonTypes.RESETSTORE:
      return basicInitialState;
    default:
      return state;
  }
};
const manageLevelListConfig = (state = { ...basicInitialState }, action = null) => {
  switch (action.type) {
    case manageLevelActionType.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    // reset Upset Value
    case manageLevelActionType.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
      };
    default:
      return null;
  }
};
const manageLevelRequestConfig = (state = { ...basicInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case manageLevelActionType.LOG_MNG_LEVEL_LIST_REQUEST:
      return logMGListReq(state, action);
    // log error
    case manageLevelActionType.GET_MNG_LEVEL_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case manageLevelActionType.GET_MNG_LEVEL_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case manageLevelActionType.RESET_MNG_LEVEL_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case manageLevelActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case manageLevelActionType.RESET_REQ_PROSS_ON:
      return {
        ...state,
        upsertRequestProcessedOn: new Date(),
      };
    default:
      return null;
  }
};
const ManageLevel = (state = {
  ...basicInitialState, concurrencyFlag: false, paymentSeqInfo: null, paymentFlag: null,
}, action = null) => {
  const stateConfig = manageLevelListConfig(state, action);
  const stateReqRes = manageLevelRequestConfig(state, action);
  const stateApp = manageApplicationReducer(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  if (stateApp !== null) {
    return stateApp;
  }
  return state;
};
export default ManageLevel;
