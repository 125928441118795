/**
 * @Description This is FasatActionBtn component page.
 * This is a wrapper on "ActionButton" component from MUX without adding any custom functionlities
 * @FileName fasatActionBtn.js
 * @Author Imroz Dawar- dawarim
 * @CreatedOn 2 February, 2021 20:45:42
 * @IssueID CDTFASATMOD- 325
 */
import React from 'react';
import { ActionButton } from '@manulife/mux';

const FasatActionBtn = (props) => <ActionButton {...props} />;

export default FasatActionBtn;
