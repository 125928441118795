/* eslint-disable max-len */
import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { transactionDefinitionActionType } from '../actions/actionTypes';

export const REDUCER_NAME = 'TransactionDefinitionFilterList';

/**
 * update filter config
 */
const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const updateTableConfig = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});

const logTdListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const getUpdatedStateFirst = (state, action = null) => {
  switch (action.type) {
    // config list column configuration
    case transactionDefinitionActionType.UPDATE_TD_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case transactionDefinitionActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case transactionDefinitionActionType.REMOVE_TD_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case transactionDefinitionActionType.UPDATE_TD_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const getUpdatedStateSecond = (state, action = null) => {
  switch (action.type) {
    // log request
    case transactionDefinitionActionType.LOG_TD_LIST_REQUEST:
      return logTdListReq(state, action);
    // log error
    case transactionDefinitionActionType.GET_TD_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case transactionDefinitionActionType.GET_TD_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case transactionDefinitionActionType.RESET_TD_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case transactionDefinitionActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return state;
  }
};

const getUpdatedState = (action, state) => {
  const firstUpdatedState = getUpdatedStateFirst(state, action);
  const secondUpdatedState = getUpdatedStateSecond(state, action);
  if (firstUpdatedState !== null) {
    return firstUpdatedState;
  }
  if (secondUpdatedState !== null) {
    return secondUpdatedState;
  }

  return state;
};

const TransactionDefinitionFilterList = (state = { ...basicFilterInitialState }, action = null) => getUpdatedState(action, state);

export default TransactionDefinitionFilterList;
