/**
 * @Description FasatColumnOrder container page
 * @FileName fasatColumnOrder.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 671
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DualListBox } from '../../libs/fasatSharedComponent';
import { staticCommonLabelKeys } from '../../moduleConstants';

const FasatColumnOrder = ({
  options,
  selectedOptions,
  columnOrderChange,
  deselectColumnOrder,
  headerLabel,
}) => {
  const [selected, setSelected] = useState([]);
  const [t] = useTranslation();

  const labels = {
    button: {
      left: t(staticCommonLabelKeys.ARIA_COMMON_COLUMN_ORDER_BUTTON_RIGHT),
      right: t(staticCommonLabelKeys.ARIA_COMMON_COLUMN_ORDER_BUTTON_LEFT),
      top: t(staticCommonLabelKeys.ARIA_COMMON_COLUMN_ORDER_BUTTON_TOP),
      up: t(staticCommonLabelKeys.ARIA_COMMON_COLUMN_ORDER_BUTTON_UP),
      down: t(staticCommonLabelKeys.ARIA_COMMON_COLUMN_ORDER_BUTTON_DOWN),
      bottom: t(staticCommonLabelKeys.ARIA_COMMON_COLUMN_ORDER_BUTTON_BOTTOM),
    },
  };

  /**
    * @param {Array} sel
    * Selection made on changing position
    * @returns {void}
    */
  const onChange = (sel) => {
    setSelected(sel);
    columnOrderChange(sel);
  };

  /**
    * Set selected options
    */
  useEffect(() => {
    setSelected(selectedOptions);
  }, [selectedOptions]);

  return (
    <div data-testid="FasatColumnOrderComponent">
      <DualListBox
        options={options}
        selected={selected}
        onChange={onChange}
        preserveSelectOrder
        showOrderButtons
        deselectColumnOrder={deselectColumnOrder}
        canFilter
        labels={labels}
        headerLabel={headerLabel}
        actionBtnOdrChge
      />
    </div>
  );
};

FasatColumnOrder.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  columnOrderChange: PropTypes.func,
  deselectColumnOrder: PropTypes.bool,
  labels: PropTypes.shape({
    button: PropTypes.shape({
      left: PropTypes.string,
      right: PropTypes.string,
      top: PropTypes.string,
      up: PropTypes.string,
      down: PropTypes.string,
      bottom: PropTypes.string,
    }),
  }),
  headerLabel: PropTypes.string,
};

FasatColumnOrder.defaultProps = {
  options: [],
  selectedOptions: [],
  columnOrderChange: () => undefined,
  deselectColumnOrder: false,
  labels: {
    button: {
      left: 'Left Arrow Button',
      right: 'Right Arrow Button',
      top: 'Top Arrow Button',
      up: 'Up Arrow Button',
      down: 'Down Arrow Button',
      bottom: 'Bottom Arrow Button',
    },
  },
  headerLabel: '',
};

export default FasatColumnOrder;
