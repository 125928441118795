import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { interactiveActivityReportActionTypes } from '../actions/actionTypes';

export const totalRowUpdate = (action, state) => (action.data?.totalRows
  ? [...state.totalRows, action.data.totalRows] : [...state.totalRows]);

export const InteractiveActivityReportOne = (state = { ...basicFilterInitialState, MSCListData: null }, action = null) => {
  switch (action.type) {
    // Configure Page Details
    case interactiveActivityReportActionTypes.SET_COMP_LIST_REQ_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case interactiveActivityReportActionTypes.SET_COMP_TYPE_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case interactiveActivityReportActionTypes.SET_COMP_TYPE_HIER_LIST_RESPONSE:
      return {
        ...state,
        listDataHier: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

export const InteractiveActivityReportTwo = (state = { ...basicFilterInitialState, MSCListData: null }, action = null) => {
  switch (action.type) {
    case interactiveActivityReportActionTypes.SET_MANULIFE_SELLING_CODES_LIST_RESPONSE:
      return {
        ...state,
        totalRows: totalRowUpdate(action, state),
        MSCListData: action.data?.data || null,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case interactiveActivityReportActionTypes.SET_MANULIFE_SELLING_CODES_LIST_RESPONSE_EMPTY:
      return {
        ...state,
        MSCListData: [],
      };
    case interactiveActivityReportActionTypes.SET_MANULIFE_SELLING_CODES_LIST_TOTALROWS:
      return {
        ...state,
        totalRows: [],
      };
    case interactiveActivityReportActionTypes.SET_AP_LOOKUP_VALUES:
      return {
        ...state,
        activityPeriodLookup: action.data.data,
      };
    case interactiveActivityReportActionTypes.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: action.data && action.data.serverRequestError,
        exportRequestError: action.data && action.data.exportRequestError,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return state;
  }
};

export const InteractiveActivityReport = (state = { ...basicFilterInitialState, MSCListData: null }, action = null) => {
  const stateConfig = InteractiveActivityReportOne(state, action);
  const stateReqRes = InteractiveActivityReportTwo(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default InteractiveActivityReport;
