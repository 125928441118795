/* eslint-disable no-debugger */
/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

const PaymentProcessingFilterList = () => true;

export default PaymentProcessingFilterList;
