/**
 * @Description This file contains all the API configuration for administration module
 * @FileName apiConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:40:00
 * @IssueID - 459
*/

import { environment } from '../../../common/util';
import FFApiConfig from './ffApiConfig';
import ClientRelationshipApiConfig from './clientRelationshipApiConfig';
import InquiryApiConfig from './inquiryApiConfig';

const envData = environment();
/**
 * Module specific API endpoint configuration.
 */
const apiConfig = {
  ADMINISTRATION_BCR_LIST: {
    endpoint: `${envData.apiHost}/api/businesscardrules/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_BCR_BY_ID: {
    endpoint: `${envData.apiHost}/api/businesscardrules/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_BCR_INSERT: {
    endpoint: `${envData.apiHost}/api/businesscardrules`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_BCR_UPDATE: {
    endpoint: `${envData.apiHost}/api/businesscardrules`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_BCR_DELETE: {
    endpoint: `${envData.apiHost}/api/businesscardrules`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_BCR_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/businesscardrules/businesscardruledetail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_BCR_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/businesscardrules/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TEMPADJ_LIST: {
    endpoint: `${envData.apiHost}/api/webadjustments/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TEMPADJ_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/webadjustments/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TEMPADJ_DELETE: {
    endpoint: `${envData.apiHost}/api/webadjustments`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TEMPADJ_APPROVE: {
    endpoint: `${envData.apiHost}/api/webadjustments/approve`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TEMPADJ_UPDATE: {
    endpoint: `${envData.apiHost}/api/webadjustments`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TEMPADJ_BY_ID: {
    endpoint: `${envData.apiHost}/api/webadjustments/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TEMPADJ_IMPORT: {
    endpoint: `${envData.apiHost}/api/webadjustments/import`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // ---- Manual commission records - Start - API -----
  ADMINISTRATION_MCR_LIST: {
    endpoint: `${envData.apiHost}/api/manualcomrecords/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CHCK_STAT_USR: {
    endpoint: `${envData.apiHost}/api/manualcomrecords/checkRejStatForLoggedInUser`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MCRALL_DELETE: {
    endpoint: `${envData.apiHost}/api/manualcomrecords/deleteByJobId`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MCR_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/manualcomrecords/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MCR_DELETE: {
    endpoint: `${envData.apiHost}/api/manualcomrecords`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MCR_APPROVE: {
    endpoint: `${envData.apiHost}/api/manualcomrecords/approve`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MCR_UPDATE: {
    endpoint: `${envData.apiHost}/api/manualcomrecords`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MCR_BY_ID: {
    endpoint: `${envData.apiHost}/api/manualcomrecords/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MCR_IMPORT: {
    endpoint: `${envData.apiHost}/api/manualcomrecords/import`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // ---- Manual commission records - End - API -----
  CLIENT_LIST: {
    endpoint: `${envData.apiHost}/api/client/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CLIENT_BY_ID: {
    endpoint: `${envData.apiHost}/api/client/view`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CLIENT_LENGTH: {
    endpoint: `${envData.apiHost}/api/client/getDefaultValues`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CLIENT_BANKING_PRESENT_RELATION: {
    endpoint: `${envData.apiHost}/api/clientbanking/ifRelationshipExists`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TEMPADJ_LAST_IMPORT: {
    endpoint: `${envData.apiHost}/api/webadjustments/lastimport`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CLIENT_INSERT: {
    endpoint: `${envData.apiHost}/api/client`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CLIENT_UPDATE: {
    endpoint: `${envData.apiHost}/api/client`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  CLIENT_DELETE: {
    endpoint: `${envData.apiHost}/api/client`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_LIC_INSERT: {
    endpoint: `${envData.apiHost}/api/licence`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_LIC_LIST: {
    endpoint: `${envData.apiHost}/api/licence/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_LICENCE_BY_ID: {
    endpoint: `${envData.apiHost}/api/licence/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_LICENCE_UPDATE: {
    endpoint: `${envData.apiHost}/api/licence`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_LICENCE_DEFAULT: {
    endpoint: `${envData.apiHost}/api/licence/getDefaultLicenceValues`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_LICENCE_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/licence/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_LIC_DELETE: {
    endpoint: `${envData.apiHost}/api/licence`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_ADDRESS_LIST: {
    endpoint: `${envData.apiHost}/api/clientaddress/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_ADDRESS_PRI_LICENCE: {
    endpoint: `${envData.apiHost}/api/clientaddress/getDefaultAddressValues`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_ADDRESS_INSERT: {
    endpoint: `${envData.apiHost}/api/clientaddress`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_ADDRESS_BY_ID: {
    endpoint: `${envData.apiHost}/api/clientaddress/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_ADDRESS_EXIST_BY_FF: {
    endpoint: `${envData.apiHost}/api/clientaddress/ifFieldForceEntryExists`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_ADDRESS_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/clientaddress/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_ADDRESS_VIEW_BY_ID: {
    endpoint: `${envData.apiHost}/api/clientaddress/clientname`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_ADDRESS_UPDATE: {
    endpoint: `${envData.apiHost}/api/clientaddress`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_ADDRESS_DELETE: {
    endpoint: `${envData.apiHost}/api/clientaddress`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_TASK_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  GLOBAL_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforce/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_STATUS: {
    endpoint: `${envData.apiHost}/api/fieldforce/deletestatus`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_DELETE: {
    endpoint: `${envData.apiHost}/api/fieldforce`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforce/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_GENERAL_DETAIL: {
    endpoint: `${envData.apiHost}/api/fieldforcegeneral/detail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_SEC_WEALTH_BY_CODE: {
    endpoint: `${envData.apiHost}/api/systemsetting/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_VALUE_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforcegeneral/getvalue`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_VALIDATE_STATUS: {
    endpoint: `${envData.apiHost}/api/fieldforcegeneral/validate`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_ORG_STRUCT_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforce/orgstruct`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GET_FF_SYS_OPTIONS: {
    endpoint: `${envData.apiHost}/api/lookups/systemoptions`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GET_FF_SERVER_DATE: {
    endpoint: `${envData.apiHost}/api/lookups/serverdate`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_ADD: {
    endpoint: `${envData.apiHost}/api/fieldforcegeneral`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EDIT: {
    endpoint: `${envData.apiHost}/api/fieldforcegeneral`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_JOBSTATUS: {
    endpoint: `${envData.apiHost}/api/fieldforcegeneral/getjobstatus`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: false,
  },
  ADMINISTRATION_FF_HEADER: {
    endpoint: `${envData.apiHost}/api/hierarchy/header`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_ADDRESS: {
    endpoint: `${envData.apiHost}/api/fieldforceaddress`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_ADD_ADDRESS: {
    endpoint: `${envData.apiHost}/api/fieldforceaddress`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EDIT_ADDRESS: {
    endpoint: `${envData.apiHost}/api/fieldforceaddress`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_ADDRESS_BY_CLIENTID: {
    endpoint: `${envData.apiHost}/api/fieldforceaddress/address`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BANKING: {
    endpoint: `${envData.apiHost}/api/fieldforcebanking/detail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EDIT_BANKING: {
    endpoint: `${envData.apiHost}/api/fieldforcebanking`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BANKING_GET_ORS_PROPAY: {
    endpoint: `${envData.apiHost}/api/fieldforcebanking/getvalue`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BANKING_GET_IS_PAYEE: {
    endpoint: `${envData.apiHost}/api/fieldforcebanking/getvalue`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_CODE: {
    endpoint: `${envData.apiHost}/api/fieldforcebanking/getvalue`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_NAME: {
    endpoint: `${envData.apiHost}/api/fieldforcebanking/getvalue`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BANKING_GET_BRANCH_NAME: {
    endpoint: `${envData.apiHost}/api/fieldforcebanking/getvalue`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_MAT_LIST: {
    endpoint: `${envData.apiHost}/api/massagenttransfer/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_NOTES_LIST: {
    endpoint: `${envData.apiHost}/api/agentnotes/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_ADD_NOTES: {
    endpoint: `${envData.apiHost}/api/agentnotes`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_ADD_TASK: {
    endpoint: `${envData.apiHost}/api/fieldforcetask`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_UPDATE_TASK: {
    endpoint: `${envData.apiHost}/api/fieldforcetask`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EDIT_NOTES: {
    endpoint: `${envData.apiHost}/api/agentnotes`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_DELETE_NOTES: {
    endpoint: `${envData.apiHost}/api/agentnotes`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_NOTES: {
    endpoint: `${envData.apiHost}/api/agentnotes/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_DELETE_TASK: {
    endpoint: `${envData.apiHost}/api/fieldforcetask`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_NOTES_BY_ID: {
    endpoint: `${envData.apiHost}/api/agentnotes/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_ADMIN: {
    endpoint: `${envData.apiHost}/api/fieldforceadministration/detail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_ADD_ADMIN: {
    endpoint: `${envData.apiHost}/api/fieldforceadministration`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EDIT_ADMIN: {
    endpoint: `${envData.apiHost}/api/fieldforceadministration`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_ADMIN_DEBTREF: {
    endpoint: `${envData.apiHost}/api/fieldforceadministration/hierarchy`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_BANKING_LIST: {
    endpoint: `${envData.apiHost}/api/clientbanking/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_BANKING_DEFAULT: {
    endpoint: `${envData.apiHost}/api/clientbanking/getDefaultValues`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_BANKING_INSERT: {
    endpoint: `${envData.apiHost}/api/clientbanking`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_BANKING_BY_ID: {
    endpoint: `${envData.apiHost}/api/clientbanking/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_BANKING_UPDATE: {
    endpoint: `${envData.apiHost}/api/clientbanking`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_BANKING_DELETE: {
    endpoint: `${envData.apiHost}/api/clientbanking`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_BANKING_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/clientbanking/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_DEDUCTION_LIST: {
    endpoint: `${envData.apiHost}/api/deduction/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_DEDUCTION_TRAN_LIST: {
    endpoint: `${envData.apiHost}/api/deduction/details`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_DEDUCTION_ADD: {
    endpoint: `${envData.apiHost}/api/deduction`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_FINE_ARRANGEMENT_ITEM_BY_ID: {
    endpoint: `${envData.apiHost}/api/finarrangement/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_DEDUCTION_ITEM_BY_ID: {
    endpoint: `${envData.apiHost}/api/deduction/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_DEDUCTION_UPDATE: {
    endpoint: `${envData.apiHost}/api/deduction`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_TASK_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_TASK_DETAILS_DD_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/getlabel`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_TASK_LOC_DETAILS: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/getlocationdetail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_TASK_VAL_LOC_DETAILS: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/islocationexists`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_TASK_ITEM_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforcetask`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_VAL_DETAILS_CHANGE: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/isdetailchanged`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_GET_ASSOC_TASK_CNT_RES: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/gettaskcount`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_GET_SYSOPT_HIER_RES: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/sysopthier/agent`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_TASK_REDEFAULT: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/redefault`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTES_LIST: {
    endpoint: `${envData.apiHost}/api/clientnotes/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_NOTES_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/clientnotes/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_NOTES_INSERT: {
    endpoint: `${envData.apiHost}/api/clientnotes`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_NOTES_BY_ID: {
    endpoint: `${envData.apiHost}/api/clientnotes/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_NOTES_UPDATE: {
    endpoint: `${envData.apiHost}/api/clientnotes`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CLIENT_NOTES_DELETE: {
    endpoint: `${envData.apiHost}/api/clientnotes`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_LIST: {
    endpoint: `${envData.apiHost}/api/finarrangement/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_TRANSFER_ID: {
    endpoint: `${envData.apiHost}/api/finarrangement/validateTransferId`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_ADD_LOCK: {
    endpoint: `${envData.apiHost}/api/finarrangement/checkandapplylockonadd`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_INSERT: {
    endpoint: `${envData.apiHost}/api/finarrangement`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_CLONE: {
    endpoint: `${envData.apiHost}/api/finarrangement/clone`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_BY_ID: {
    endpoint: `${envData.apiHost}/api/finarrangement/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_UPDATE: {
    endpoint: `${envData.apiHost}/api/finarrangement`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_UPDATE_ENDDATE: {
    endpoint: `${envData.apiHost}/api/finarrangement/updateEndDate`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_DELETE: {
    endpoint: `${envData.apiHost}/api/finarrangement`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/finarrangement/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_TAB_VISIBILITY: {
    endpoint: `${envData.apiHost}/api/finarrangement/tabVisibility`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  MANUAL_ADJUSTMENTS_ON_LOAD: {
    endpoint: `${envData.apiHost}/api/adjustments/onload`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  MANUAL_ADJUSTMENTS_POLICY_INFO: {
    endpoint: `${envData.apiHost}/api/adjustments/getPolicyInfo`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  MANUAL_ADJUSTMENTS_PRODUCT_INFO: {
    endpoint: `${envData.apiHost}/api/adjustments/getProductInfo`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PROCESS_MANUAL_ADJUSTMENT: {
    endpoint: `${envData.apiHost}/api/adjustments`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CLIENT_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/client/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // #region  FF Payments
  FF_PAYMENTS_PROCESS: {
    endpoint: `${envData.apiHost}/api/payments`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_PAYMENTS_ONLOAD: {
    endpoint: `${envData.apiHost}/api/payments/paymentOnLoad`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_PAYMENTS_ADDRESS: {
    endpoint: `${envData.apiHost}/api/payments/address`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // Field Force Movement
  FIELD_FORCE_MOVEMENT_LIST: {
    endpoint: `${envData.apiHost}/api/movement/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FIELD_FORCE_MOVEMENT_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/movement/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_GENERAL_LIST_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforcegeneral/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_ADDRESS_LIST_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforceaddress/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_BANKING_LIST_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforcebanking/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // #endregion  FF Payments
  ...FFApiConfig,
  ...InquiryApiConfig,
  ...ClientRelationshipApiConfig,
};

export default apiConfig;
