/**
 * @Description This file contains all the API configuration for Compensation Structure module
 * @FileName apiConfig.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 24 February, 2023 16:42:34
 * @IssueID 8376
*/

import { environment } from '../../../common/util';

const envData = environment();
/**
 * Module specific API endpoint configuration.
 */
const apiConfig = {
  ADMINISTRATION_PP_LIST: {
    endpoint: `${envData.apiHost}/api/comphierarchy/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PAYMENTS_PP_LIST: {
    endpoint: `${envData.apiHost}/api/paymentprocessing/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PAYMENTS_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/paymentprocessing/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  PAYMENTS_APPROVE: {
    endpoint: `${envData.apiHost}/api/paymentprocessing/approve`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PAYMENTS_REMIT: {
    endpoint: `${envData.apiHost}/api/paymentprocessing/remit`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PAYMENTS_CANCEL: {
    endpoint: `${envData.apiHost}/api/paymentprocessing/cancel`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default apiConfig;
