import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { manageUsersActionType } from '../actions/actionTypes';

import { formatResponse, processError, renderFileName, exportErrorMsg } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

const getResetUpsertValue = (responseData, err) => ({
  upsertRequest: null,
  upsertRequestError: err ? processError(err) : null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: responseData ? new Date() : null,
  upsertData: responseData,
});
export function* getMngUsrList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(manageUsersActionType.RESET_SERVER_VALUE, null)),
    put(getAction(manageUsersActionType.LOG_MNG_USER_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_USER_LIST, action.data);
    yield all([
      put(getAction(manageUsersActionType.GET_MNG_USER_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageUsersActionType.GET_MNG_USER_LIST_ERROR, err)),
      put(getAction(manageUsersActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* insertMngUsr(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_USER_INSERT, action.data.formatObject);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(manageUsersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(manageUsersActionType.GET_MNG_USER_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(manageUsersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getMngUsrbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(manageUsersActionType.RESET_SERVER_VALUE, null)),
    put(getAction(manageUsersActionType.LOG_MNG_USER_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_USER_BY_ID, action.data);
    yield all([
      put(getAction(manageUsersActionType.GET_MNG_USER_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageUsersActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(manageUsersActionType.GET_MNG_USER_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
        * This generator function will invoke the api
        * @param {object} action - action which will update data for existing manage user
        */
export function* updateMngUsr(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_USER_UPDATE, action.data.formatObject);
    if (responseData) {
      resetUpsertValue = getResetUpsertValue(responseData, null);
      yield put(getAction(manageUsersActionType.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(manageUsersActionType.GET_MNG_USER_LIST_REQUEST, action.data.reqObject));
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(manageUsersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
       * This generator function will invoke the api
        * @param {object} action - action which will delete data for existing Plan code
        */
export function* deleteMngUsr(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_USER_DELETE, action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(manageUsersActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(manageUsersActionType.GET_MNG_USER_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(manageUsersActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(manageUsersActionType.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getMngUsrSecurityGrp(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_MNG_USER_SECURITY_GROUP,
      action.data);
    yield all([
      put(getAction(manageUsersActionType.GET_MNG_USER_SECURITY_GRP_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manageUsersActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getMngUsrExportList(action) {
  yield all([put(processingStarted()), put(getAction(manageUsersActionType.RESET_SERVER_VALUE, null))]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MNG_USER_EXPORT_LIST, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response?.status === globalConstants.errorCode402
      || err.response?.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([put(getAction(manageUsersActionType.RESET_SERVER_VALUE, resetServerValue)), put(processingCompleted())]);
  }
}
function* moduleWatcher() {
  // Manage Users
  yield takeEvery(manageUsersActionType.GET_MNG_USER_LIST_REQUEST, getMngUsrList);
  yield takeEvery(manageUsersActionType.GET_MNG_USER_BY_ID_REQUEST, getMngUsrbyID);
  yield takeEvery(manageUsersActionType.INSERT_MNG_USER_REQUEST, insertMngUsr);
  yield takeEvery(manageUsersActionType.UPDATE_MNG_USER_REQUEST, updateMngUsr);
  yield takeEvery(manageUsersActionType.DELETE_MNG_USER_REQUEST, deleteMngUsr);
  yield takeEvery(manageUsersActionType.GET_MNG_USER_SECURITY_GRP_REQUEST, getMngUsrSecurityGrp);
  yield takeEvery(manageUsersActionType.GET_MNG_USER_EXPORT_REQUEST, getMngUsrExportList);
}
export default moduleWatcher;
