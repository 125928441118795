import React, { useEffect } from 'react';
import { Icon, colors } from '@manulife/mux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAutoLogoutType } from '../../util';
import signOutIcon from './signedoutIcon.svg';
import sessionExpireIcon from './expiredIcon.svg';
import './fasatSignOut.scss';
import { globalConstants, staticCommonLabelKeys } from '../../moduleConstants';
import { getCurrentLocale } from '../../i18n';
import { getUserPrefData } from '../../redux/actions/commonActions';

const SignOutMessage = () => {
  const locale = getCurrentLocale();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserPrefData());
  }, [locale]);
  const [t] = useTranslation();
  return (
    <>
      <div className="signOutSection">
        <Icon path={signOutIcon} alt="icon" height="auto" width="auto" color={colors.m_green} />
        <h1>{t(staticCommonLabelKeys.COMMON_BUTTON_SIGNOUT)}</h1>
        <p>{t(staticCommonLabelKeys.COMMON_SYSTEM_SIGNOUT_SUCCESS)}</p>
      </div>
    </>
  );
};

export const SessionExpireMessage = () => {
  const locale = getCurrentLocale();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserPrefData(globalConstants.GETLINKS));
  }, [locale]);
  const [t] = useTranslation();
  return (
    <>
      <div className="signOutSection">
        <Icon path={sessionExpireIcon} alt="icon" height="auto" width="auto" color={colors.m_green} />
        <h1>{t(staticCommonLabelKeys.COMMON_SYSTEM_SESSION_EXPIRED)}</h1>
        <p>{t(staticCommonLabelKeys.COMMON_SYSTEM_SIGNIN_REQUEST)}</p>

      </div>
    </>
  );
};

export const getLogoutMessage = ({ logoutStatus, autologoutKey }) => (logoutStatus
=== autologoutKey ? <SessionExpireMessage /> : <SignOutMessage />);

const FasatSignOut = () => {
  const autologoutKey = 'auto-logout';
  const logoutStatus = getAutoLogoutType();
  return (
    <div>
      {getLogoutMessage({ logoutStatus, autologoutKey })}
    </div>
  );
};

export default FasatSignOut;
