/**
 * @Description File description.
 * @FileName english.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 19 February, 2021 00:36:44
 * @IssueID - Issue IDs
*/

const english = {
  /**
   * Contents for Grid Footer Bar
   */
  'showing.label': 'Showing',
  'of.label': 'of',

  /**
   * Content for FILTER SETTINGS
   */
  'manage.custom.filter.label': 'Manage custom filters',
  'filter.settings.title': 'Filter setting',
  'system.default.filter.title': 'System default',
  'new.filter.title': 'New filter',
  'create.new.filter.label': 'Create new filter',
  'load.label': 'Manage filter',
  'filter.criteria.panel.title': 'Filter criteria',
  'filter.criteria.panel.text':
  'Select the columns and define the operators to enter the values you want to search',
  'sort.order.panel.title': 'Sort order',
  'sort.order.panel.text': 'Select the columns you want to apply the sorting order',
  'group.by.panel.title': 'Group by',
  'group.by.panel.text': 'Group by allows to define the operators to group the values you want to search',
  'column.order.panel.title': 'Column order and visibility',
  'column.order.panel.text': 'Choose and arrange the columns as desired',
  'search.label': 'Search',
  'column.label': 'Column',
  'operator.label': 'Operator',
  'value.label': 'Value',
  'sort.order.label': 'Sort order',
  'group.by.label': 'Group by',
  'information.title': 'Duplicate selection',
  'save.label': 'Save',
  'save.as.label': 'Save as',
  'save.as.default.button.label': 'Yes, make default',
  'no.button.label': 'No',
  'GENERIC.NOTIFICATION': 'A system error occurred. Please try again later. If the problem persists,'
  + ' email us at <span> <a style="color: green" href="mailto:DTSC@manulife.ca">DTSC@manulife.ca</a></span> or call'
  + ' contact support at 1-800-667-4266.',
  'ERROR.LABEL': 'Important information',
  'LABEL.LEGAL.ERR': 'Legal',
  'LABEL.ACCESSIBILITY.ERR': 'Accessibility',
  'LABEL.PRIVACY.POLICY.ERR': 'Privacy Policy',
  'LS.FOOTER.TEXT.ERROR': '© 1999-2022 The Manufacturers Life Insurance Company',
  'PD.ADD.ERROR': 'Errrrrr displayed',
};

export default english;
