/* eslint-disable react/prop-types */
/**
 * @Description File description.
 * @FileName fasatMultiSelectFilter.js
 * @Author Copied from custom grid select column filter
 * @CreatedOn 07 July, 2021 03:22:23
 * @IssueID - Issue IDs
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import FasatDropdown from '../../fasatDropdown/fasatDropdown';
import { staticCommonLabelKeys } from '../../../moduleConstants';

const PRIVATE = 'PRIVATE';

const FasatMultiSelectFilter = ({
  column: { filterValue, setFilter, filterOptions, id, primaryTopic, privateValue },
}) => {
  const [t] = useTranslation();
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);
  const [value, setValue] = useState(filterValue);

  /**
   * Subscribe button Clicl Event from Modal
   */
  useEffect(() => {
    if (primaryTopic && primaryTopic !== '') {
      const subId = PubSub.subscribe(primaryTopic, () => {
        setValue(null);
        setFilter(null);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);

  /**
   * Subscribe button Click Event from Modal
   */
  useEffect(() => {
    if (privateValue) {
      setValue(null);
      setFilter(PRIVATE);
    }
  }, [privateValue]);
  /**
   * column onChange filter
   */
  const onChange = (changeValue) => {
    setValue(changeValue);
    setFilter(changeValue && changeValue.split(','));
  };

  /**
   * Subscribe button Clicl Event from Modal
   */
  useEffect(() => {
    if (primaryTopic && primaryTopic !== '') {
      const subId = PubSub.subscribe(primaryTopic, () => {
        setFilter(null);
        setValue(null);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const optionsSet = new Set();
    if (JSON.stringify(filterOptions) !== '{}' && filterOptions.values.length > 0) {
      filterOptions.values.forEach((row) => {
        optionsSet.add({ label: row.label, value: row.value });
      });
    }
    return [...optionsSet.values()];
  }, [filterOptions]);
  return (
    <FasatDropdown
      dropdownItems={options}
      errors={[]}
      id={id}
      label=""
      isMultiselect
      onHandleChange={(e) => {
        onChange(e || undefined);
      }}
      searchPlaceholderText={t(staticCommonLabelKeys.COMMON_BUTTON_SEARCH)}
      selected={0}
      searchEnabled
      value={value}
      placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
      variant="basic"
      searchAriaLabel={t(staticCommonLabelKeys.ARIA_COMMON_DROPDOWN_SEARCH_LABEL)}
      hideLabel
    />
  );
};

FasatMultiSelectFilter.propTypes = {
  column: PropTypes.arrayOf(
    PropTypes.shape({
      filterValue: PropTypes.string,
      setFilter: PropTypes.func,
      preFilteredRows: PropTypes.arrayOf(PropTypes.shape({})),
      primaryTopic: PropTypes.string,
    }),
  ).isRequired,
};

export default FasatMultiSelectFilter;
