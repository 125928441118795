/* eslint-disable import/named */
/**
 * @Description Reducer for business card rules
 * @FileName DefaultCsCardRule.js
 * @Author Jayanta Sarkhel - sarkhja
 * @CreatedOn 21 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { defaultCsActionTypes, defaultCsDetailActionTypes } from '../actions/actionTypes';

const DefaultCsCardRule1 = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case defaultCsActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case defaultCsActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case defaultCsActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case defaultCsActionTypes.UPSERT_CS_REQUEST:
      return {
        ...state,
        upsertRequestedOn: new Date(),
        upsertData: action.data.upsertData,
        loading: true,
      };
    case defaultCsDetailActionTypes.GET_CS_DETAIL_RESPONSE:
      return {
        ...state,
        detailData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case defaultCsDetailActionTypes.GET_CS_AGENT_NAME_RESPONSE:
      return {
        ...state,
        agentName: action.data,
      };
    case defaultCsDetailActionTypes.GET_NEXT_CS_AGENT_NAME_RESPONSE:
      return {
        ...state,
        nextagentName: action.data,
      };
    case defaultCsDetailActionTypes.GET_CS_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

const DefaultCsCardRule2 = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case defaultCsActionTypes.LOG_CS_DETAIL_REQUEST:
      return {
        ...state,
        detailRequestedOn: new Date(),
        loading: true,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return null;
  }
};

const DefaultCsCardRule = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  const cond1 = DefaultCsCardRule1(state, action);
  const cond2 = DefaultCsCardRule2(state, action);
  if (cond1 !== null) {
    return cond1;
  }
  if (cond2 !== null) {
    return cond2;
  }
  return state;
};

export default DefaultCsCardRule;
