/* eslint-disable no-unused-vars */

/* eslint-disable no-debugger */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  advisorDetailsActions, chDetailsActionTypes, msActivityActionTypes,
} from '../actions/actionTypes';
import { processError, renderFileName, exportErrorMsg } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { exportFileType, globalConstants } from '../../../../common/moduleConstants';
import { fieldForceActionTypes } from '../../../agency/redux';

export function* getExtAdvisorDetails(action) {
  let resetServerValue = { serverRequestError: null };
  const initApiUrl = moduleAPI.EXT_ADVISOR_DETAILS.endpoint;
  yield all([
    put(processingStarted()),
    put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const { param, type } = action.data;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.EXT_ADVISOR_DETAILS.endpoint + param;
    moduleAPI.EXT_ADVISOR_DETAILS.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.EXT_ADVISOR_DETAILS);
    moduleAPI.EXT_ADVISOR_DETAILS.endpoint = initApiUrl;
    const responseAction = type === 'general' ? advisorDetailsActions.GET_GENERAL_DETAIL_RESPONSE
      : advisorDetailsActions.GET_BANKING_DETAIL_RESPONSE;
    yield all([
      put(getAction(responseAction, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    moduleAPI.EXT_ADVISOR_DETAILS.endpoint = initApiUrl;
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(advisorDetailsActions.GET_ADVISOR_DETAILS_ERROR, processError(err))),
      put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getExtAdvisorRequirementList(action) {
  let resetServerValue = { serverRequestError: null };

  yield all([
    put(processingStarted()),
    put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_ADVISOR_REQUIREMENT_LIST, action.data);
    const responseDataGrid = { ...responseData };
    responseDataGrid.data = responseData.data[0].requirementsList;
    responseDataGrid.comment = responseData.data[0].ffRequirement.orgComment;
    responseDataGrid.orgReqNotifyDt = responseData.data[0].ffRequirement.orgReqNotifyDt;
    yield all([
      put(getAction(advisorDetailsActions.GET_FF_REQUIREMENT_TABLE_RES, responseDataGrid)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(advisorDetailsActions.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* exportExtRequirementList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(advisorDetailsActions.GET_EXT_REQUIREMENT_EXPORT_ERROR, resetServerValue)),
  ]);
  let fileType = exportFileType.Excel;
  try {
    const payload = action.data?.formatExtObject;
    fileType = payload?.exportFileType;
    const responseData = yield call(invokeApi,
      moduleAPI.EXT_ADVISOR_REQUIREMENT_EXPORT,
      action.data?.formatExtObject);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    const filename = renderFileName(responseData);
    yield put(processingCompleted());
    FileSaver(urlData, filename);
    action.data?.popUpClose();
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status, fileType);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(advisorDetailsActions.GET_EXT_REQUIREMENT_EXPORT_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* exportDetailActivityList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(chDetailsActionTypes.GET_EXT_REQUIREMENT_EXPORT_ERROR, resetServerValue)),
  ]);
  let fileType = exportFileType.Excel;
  try {
    const payload = action.data?.formatExtObject;
    fileType = payload?.exportFileType;
    const responseData = yield call(invokeApi,
      moduleAPI.EXT_DETAIL_ACTIVTI_EXPORT,
      action.data?.formatExtObject);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    const filename = renderFileName(responseData);
    yield put(processingCompleted());
    FileSaver(urlData, filename);
    action.data?.popUpClose();
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status, fileType);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(processingCompleted()),
      put(getAction(chDetailsActionTypes.GET_EXT_REQUIREMENT_EXPORT_ERROR, resetServerValue)),
    ]);
  }
}

export function* getDetailActivityList(action) {
  let resetServerValue = { serverRequestError: null };

  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_CH_DETAIL_REQUEST_LIST, action.data);
    yield all([
      put(getAction(chDetailsActionTypes.SET_EXTERNAL_CH_DETAIL_ACTIVITY_LIST, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getMSActivityList(action) {
  let resetServerValue = { serverRequestError: null };

  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_CH_MS_ACTIVITY_REQUEST_LIST, action.data);
    yield all([
      put(getAction(msActivityActionTypes.SET_EXTERNAL_MS_ACTIVITY_LIST, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getMSActivityFirstLevelList(action) {
  let resetServerValue = { serverRequestError: null };

  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
    put(getAction(msActivityActionTypes.RESET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY_LIST, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_CH_MS_ACTIVITY_REQUEST_LIST_FIRST_LEVEL, action.data);
    yield all([
      put(getAction(msActivityActionTypes.SET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY_LIST, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, resetServerValue)),
      put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getChAdvisorDetailsFlag(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  const initApiUrl = moduleAPI.CH_ADVISOR_DETAILS_FLAG_REQ.endpoint;
  try {
    const params = `?orgLevel=${action.data.orgLevel}&orgCode=${action.data.orgCode}`;
    const updatedApiUrl = initApiUrl + params;
    const apiEndpoint = { ...moduleAPI.CH_ADVISOR_DETAILS_FLAG_REQ };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    yield all([
      put(getAction(chDetailsActionTypes.GET_CH_ADVISOR_DETAILS_FLAG_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(chDetailsActionTypes.GET_CH_ADVISOR_DETAILS_FLAG_ERROR, err)),
      put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getMSActivityAllLevelList(action) {
  let resetServerValue = { serverRequestError: null };

  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, null)),
    put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.EXT_CH_MS_ACTIVITY_REQUEST_LIST_ALL_LEVEL, action.data);
    yield all([
      put(getAction(msActivityActionTypes.SET_EXTERNAL_ALL_LEVEL_MS_ACTIVITY_LIST, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getMSActivityExport(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  let fileType = exportFileType.Excel;
  try {
    const payload = action.data?.formatExtObject;
    if (payload.view === 'tree') {
      payload.data.pageSize = 10;
    }
    fileType = payload?.exportFileType;
    const responseData = yield call(
      invokeApi,
      moduleAPI.EXT_CH_MS_ACTIVITY_REQUEST_LIST_ALL_LEVEL_EXPORT,
      payload,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    action?.data?.popUpClose();
    yield put(processingCompleted());
  } catch (err) {
    if (err.response?.status === globalConstants.errorCode402 || err.response?.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status, fileType);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
      // close export modal and show message on list page
      action.data.popUpClose();
    } else {
      // DOn't close modal show error in modal window
      resetServerValue = { exportRequestError: processError(err) };
    }
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, resetServerValue)),
      // put(getAction(advisorDetailsActions.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
function* moduleWatcher() {
  yield takeEvery(advisorDetailsActions.GET_EXT_ADVISOR_DETAILS, getExtAdvisorDetails);
  yield takeEvery(advisorDetailsActions.GET_EXT_ADVISOR_REQUIREMENT_LIST, getExtAdvisorRequirementList);
  yield takeEvery(advisorDetailsActions.EXT_REQUIREMENT_LIST_EXPORT, exportExtRequirementList);
  yield takeEvery(chDetailsActionTypes.EXT_DETAIL_ACTIVITY_EXPORT, exportDetailActivityList);
  yield takeEvery(chDetailsActionTypes.GET_EXTERNAL_CH_DETAIL_ACTIVITY, getDetailActivityList);
  yield takeEvery(msActivityActionTypes.GET_EXTERNAL_MS_ACTIVITY, getMSActivityList);
  yield takeEvery(msActivityActionTypes.GET_EXTERNAL_MS_ACTIVITY, getMSActivityList);
  yield takeEvery(chDetailsActionTypes.GET_CH_ADVISOR_DETAILS_FLAG, getChAdvisorDetailsFlag);
  yield takeEvery(msActivityActionTypes.GET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY, getMSActivityFirstLevelList);
  yield takeEvery(msActivityActionTypes.GET_EXTERNAL_ALL_LEVEL_MS_ACTIVITY, getMSActivityAllLevelList);
  yield takeEvery(msActivityActionTypes.EXPORT_MS_ACTIVITY_REQUEST, getMSActivityExport);
}

export default moduleWatcher;
