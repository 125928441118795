import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { activityProcessedActionType } from '../actions/actionTypes';

const APListConfig = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    // reset Upset Value
    case activityProcessedActionType.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        dataRequestError: action.data.dataRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        jobStatusAP: action.data.jobStatusAP,
        loading: false,
      };
    // reset Delete Value
    case activityProcessedActionType.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    // Configure Page Details
    case activityProcessedActionType.SET_AP_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case activityProcessedActionType.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case activityProcessedActionType.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: action.data && action.data.serverRequestError,
      };
    case activityProcessedActionType.GET_BULK_VALID_COUNT_RESPONSE:
      return {
        ...state,
        bulkVC: action.data,
      };
    case activityProcessedActionType.GET_BULK_CONCURRENCY_RESPONSE:
      return {
        ...state,
        bulkConc: action.data,
      };
    default:
      return null;
  }
};

const APListREQRES = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case activityProcessedActionType.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: action.data && action.data.serverRequestError,
      };
    case activityProcessedActionType.GET_BULK_VALID_COUNT_RESPONSE:
      return {
        ...state,
        bulkVC: action.data,
      };
    case activityProcessedActionType.GET_BULK_CONCURRENCY_RESPONSE:
      return {
        ...state,
        bulkConc: action.data,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return null;
  }
};

const ActivityProcessed = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  const stateConfig = APListConfig(state, action);
  const stateReqRes = APListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ActivityProcessed;
