import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { rateMasterDetailsActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'RateMasterDetailList';
export const FILTER_REDUCER_NAME_DETAILS = 'RateMasterDetailList';

const updateFilterConfig = (state, action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        ...state,
        selectedFilter,
        systemDefaultFilter: action.data.systemDefault || null,
        userDefaultFilter: action.data.userDefault || null,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        ...state,
        selectedFilter,
        userDefaultFilter: action.data.userDefault,
      };
    }
  }
  return {
    ...state,
    selectedFilter,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  ratelistParamOptions: action.data?.detail?.parameters
    ? JSON.parse(JSON.stringify(action.data.detail.parameters)) : null,
  rateParticipantlistParamOptions: action.data?.detail?.parameters
    ? JSON.parse(JSON.stringify(action.data.detail.parameters)) : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  selectedFilter: action.data ? action.data : null,
});

const logListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  listRequestError: action.data?.listRequestError || null,
  loading: false,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const updateFilterConfigResetSelectedFilter = (state, action) => ({
  ...state,
  selectedFilter: null,
  systemDefaultFilter: action.data.systemDefault || null,
  userDefaultFilter: action.data.userDefault || null,
});
const RateMasterDetailList1 = (state, action = null) => {
  switch (action.type) {
    case rateMasterDetailsActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case rateMasterDetailsActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case rateMasterDetailsActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case rateMasterDetailsActionTypes.LOG_LIST_REQUEST:
      return logListRequest(state, action);
    case rateMasterDetailsActionTypes.GET_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case rateMasterDetailsActionTypes.GET_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case rateMasterDetailsActionTypes.GET_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};
const RateMasterDetailList2 = (state, action = null) => {
  switch (action.type) {
    case rateMasterDetailsActionTypes.RESET_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case rateMasterDetailsActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case rateMasterDetailsActionTypes.UPDATE_FILTER_CONFIG_RESET_SELECTED:
      return updateFilterConfigResetSelectedFilter(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    case rateMasterDetailsActionTypes.LOG_EXPORT_LIST:
      return {
        ...state,
        screenName: action.data.screenName,
      };
    default:
      return null;
  }
};
const RateMasterDetailList = (state = { ...basicFilterInitialState }, action = null) => {
  const rateMasterDetail1 = RateMasterDetailList1(state, action);
  if (rateMasterDetail1 !== null) {
    return rateMasterDetail1;
  }
  const rateMasterDetail2 = RateMasterDetailList2(state, action);
  if (rateMasterDetail2 !== null) {
    return rateMasterDetail2;
  }
  return state;
};

export default RateMasterDetailList;
