/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import { dateValidator } from '../../../../common/util';

export const testAmt = (val) => {
  if (val === null || val === '' || val === undefined) {
    return false;
  }
  return true;
};
export const testDays = (val) => {
  if (val === null) {
    return false;
  }
  return true;
};
export const check = (val) => {
  if (val === 'P') {
    return false;
  }
  if (val === 'N') {
    return false;
  }
  return true;
};
const addRules = {
  adeAmt: ['DE_ADD_FER001_CALCMETHOD', 'DE_ADD_FER001_AMOUNT', 'DE_ADD_FER004', 'DE_ADD_FER003'],
  adeTargetAmt: ['DE_ADD_FER007', 'DE_ADD_FER008', 'DE_ADD_FER001_TARGETAMOUNT', 'DE_ADD_FER006', 'DE_ADD_FER003'],
  adePercent: ['DE_ADD_FER011', 'DE_ADD_FER015_PERCENT'],
  adeMonthlyTargetAmt: ['DE_ADD_FER008', 'DE_ADD_FER009'],
  adeCumulativeAmt: ['DE_ADD_FER010'],
  adeEndDt: ['DE_ADD_FER013'],
  adePriority: ['DE_ADD_FER014'],
  adePctOfFyrComm: ['DE_ADD_FER015_FYRCOMM'],
  adePctOfRenComm: ['DE_ADD_FER015_RENCOMM'],
  adePctOfFyrOvrde: ['DE_ADD_FER015_FYROVR'],
  adePctOfRenOvrde: ['DE_ADD_FER015_RENOVR'],
  adePctOfBal: ['DE_ADD_FER015_PCTBAL'],
  adeTransAgtCd: ['DE_FER001_TRANSAGENTCD', 'DE_ADD_FER002', 'DE_ADD_FER005'],
};

const editRules = {
  adeAmt: ['DE_ADD_FER001_CALCMETHOD', 'DE_ADD_FER001_AMOUNT', 'DE_ADD_FER004', 'DE_ADD_FER003'],
  adeTargetAmt: ['DE_ADD_FER007', 'DE_ADD_FER008', 'DE_ADD_FER001_TARGETAMOUNT', 'DE_ADD_FER006', 'DE_ADD_FER003'],
  adePercent: ['DE_ADD_FER011', 'DE_ADD_FER015_PERCENT'],
  adeMonthlyTargetAmt: ['DE_ADD_FER008', 'DE_ADD_FER009'],
  adeCumulativeAmt: ['DE_ADD_FER010'],
  adeEndDt: ['DE_ADD_FER013'],
  adePriority: ['DE_ADD_FER014'],
  adePctOfFyrComm: ['DE_ADD_FER015_FYRCOMM'],
  adePctOfRenComm: ['DE_ADD_FER015_RENCOMM'],
  adePctOfFyrOvrde: ['DE_ADD_FER015_FYROVR'],
  adePctOfRenOvrde: ['DE_ADD_FER015_RENOVR'],
  adePctOfBal: ['DE_ADD_FER015_PCTBAL'],
  adeTransAgtCd: ['DE_FER001_TRANSAGENTCD', 'DE_ADD_FER002', 'DE_ADD_FER005'],
};

const addRuleConfig = {
  CS_ADD_FER003_AGENTCODE: 'REQFLD',
  CS_DEDUCTION_FER003_METHOD: 'REQFLD',
  CS_DEDUCTION_FER003_FREQ: 'REQFLD',
  CS_DEDUCTION_FER003_TARGET: 'REQFLD',
  DE_ADD_FER001_PERCENT: 'REQFLD',
  CS_DEDUCTION_FER003_AMT: 'REQFLD',
  CS_DEDUCTION_FER003_EFFDT: 'REQFLD',
  CS_STRUCTURE_EDIT_COMMON: 'COMMON_DATE_INVALID_FORMAT',
  DE_ADD_FER003_AMTTARGET: 'REQFLD',
  DE_ADD_FER001_MONTHLYTARGETAMT: 'REQFLD',
  DE_ADD_FER001_DAYS: 'REQFLD',
  DE_ADD_FER001_PRIORITY: 'REQFLD',
};

const editRuleConfig = {
  CS_ADD_FER003_AGENTCODE: 'REQFLD',
  CS_DEDUCTION_FER003_METHOD: 'REQFLD',
  CS_DEDUCTION_FER003_FREQ: 'REQFLD',
  CS_DEDUCTION_FER003_TARGET: 'REQFLD',
  DE_ADD_FER001_PERCENT: 'REQFLD',
  CS_DEDUCTION_FER003_AMT: 'REQFLD',
  CS_DEDUCTION_FER003_EFFDT: 'REQFLD',
  CS_STRUCTURE_EDIT_COMMON: 'COMMON_DATE_INVALID_FORMAT',
  DE_ADD_FER003_AMTTARGET: 'REQFLD',
  DE_ADD_FER001_MONTHLYTARGETAMT: 'REQFLD',
  DE_ADD_FER001_DAYS: 'REQFLD',
  DE_ADD_FER001_PRIORITY: 'REQFLD',
};

const addValidationSchema = () => Yup.object().shape({
  adeType: Yup.string().nullable().required('CS_ADD_FER003_AGENTCODE'),
  adeFreq: Yup.string().nullable().required('CS_DEDUCTION_FER003_FREQ'),
  adeAmtCalcMethod: Yup.string().nullable().required('CS_DEDUCTION_FER003_METHOD'),
  adePercent: Yup.number().nullable().when('adeAmtCalcMethod', {
    is: (val) => ((val === 'M') || (val === 'R')),
    then: Yup.number().nullable().test('adePercent', 'DE_ADD_FER001_PERCENT', testAmt),
    otherwise: Yup.number().nullable().notRequired(),
  }),
  adeAmt: Yup.string().nullable().when('adeAmtCalcMethod', {
    is: (val) => ((val === 'A') || (val === 'S') || (val === 'F')),
    then: Yup.string().nullable().test('adeAmt', 'CS_DEDUCTION_FER003_AMT', testAmt),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  adeDays: Yup.string().nullable().when('adeFreq', {
    is: (val) => ((val) !== 'DY'),
    then: Yup.string().nullable().notRequired(),
    otherwise: Yup.string().nullable().test('adeDays', 'CS_DEDUCTION_FER003_AMT', testDays),
  }),
  adeTargetAmt: Yup.string().nullable().required('DE_ADD_FER003_AMTTARGET'),
  adeTransAgtCd: Yup.string().nullable().when('flagTransfer', {
    is: (val) => ((val) === false),
    then: Yup.string().nullable().notRequired(),
    otherwise: Yup.string().nullable().required('DE_ADD_FER001_DAYS'),
  }),
  adeMonthlyTargetAmt: Yup.string().nullable().when('AdeMonthlyTargetSw', {
    is: (val) => val === 1,
    then: Yup.string().nullable().required('DE_ADD_FER001_MONTHLYTARGETAMT'),
    otherWise: Yup.number().nullable().notRequired(),
  }),
  adeEffDt: Yup.string().nullable().required('CS_DEDUCTION_FER003_EFFDT').test('adeEffDt', 'CS_STRUCTURE_EDIT_COMMON',
    dateValidator),
  adeEndDt: Yup.string().nullable().notRequired().test('adeEndDt', 'CS_STRUCTURE_EDIT_COMMON',
    dateValidator),
  adePriority: Yup.string().nullable().required('DE_ADD_FER001_PRIORITY'),
});

export { addValidationSchema, addRules, editRules,
  addRuleConfig, editRuleConfig };
