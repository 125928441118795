/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { bcrActionTypes, clientActionsTypes,
  cliLicActionTypes, clientAddressActionTypes, clientBankingActionTypes,
  ClientCommonActionTypes } from '../actions/actionTypes';
import { exportErrorMsg, formatResponse, Logger, processError,
  renderFileName, resetDeletetValue } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
  setUserPrefError,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

const ERROR_MSG_LENGTH_EXPORT = 'The number of records has exceeded the maximum limit.';
const errorCode = 402;
const exportMaxLimitErrorObj = [
  {
    errorCode: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
    errorMessage: ERROR_MSG_LENGTH_EXPORT,
    errorMessageId: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
    type: 'RULE',
  },
];
export function* getClientStructureList(action) {
  Logger.verbose(`Action Detail For Get COMPCAL List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(clientActionsTypes.LOG_CLIENT_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.CLIENT_LIST,
      action.data);
    yield all([
      put(getAction(clientActionsTypes.GET_CLIENT_STRUCTURE_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(clientActionsTypes.GET_CS_LIST_ERROR, err)),
      put(getAction(clientActionsTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getClientLength(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.CLIENT_LENGTH,
      action.data);
    yield all([
      put(getAction(clientActionsTypes.GET_CLIENT_LENGTH_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(bcrActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* checkRelationalTableForBanking(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = {
    relationalTable: action.data,
    relationalTableError: null,
  };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.CLIENT_BANKING_PRESENT_RELATION,
      action.data);
    resetServerValue = {
      relationalTable: responseData.isRelationExists,
    };
    // eslint-disable-next-line no-debugger
    // debugger;
    yield all([
      put(getAction(clientActionsTypes.GET_CLIENT_EXSISTS_RESPONSE, resetServerValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = {
      relationalTableError: processError(err),
    };
    yield all([
      put(getAction(clientActionsTypes.GET_CLIENT_EXSISTS_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* insertClientStructure(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.CLIENT_INSERT,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(clientActionsTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(clientActionsTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* updateClientStructure(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.CLIENT_UPDATE,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(clientActionsTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(clientActionsTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
//  * This generator function will invoke the api

//  * @param {object} action - action which will request for Client details by ID

export function* getClientbyID(action) {
  const resetUpsertValue = {
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  let resetServerValue = { serverRequestError: null };
  const isEdit = action.data && action.data.isEdit;
  yield all([
    put(processingStarted()),
    put(getAction(clientActionsTypes.GET_CS_LIST_ERROR, null)),
    put(getAction(clientActionsTypes.RESET_SERVER_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.CLIENT_BY_ID, action.data);
    yield all([
      put(getAction(clientActionsTypes.GET_CLIENT_BY_ID_RESPONSE, isEdit
        ? formatResponse({ action, responseData }) : responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(clientActionsTypes.GET_CS_LIST_ERROR, err)),
      put(getAction(clientActionsTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
    // }
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing CLIENT
  */
export function* deleteClient(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.CLIENT_DELETE,
      action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(clientActionsTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(clientActionsTypes.GET_CLIENT_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(clientActionsTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

/* @param {object} action - action which will request for Licence Listing
  */
export function* getLicenceList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, null)),
    put(getAction(clientAddressActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(clientAddressActionTypes.LOG_CLIENT_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_LIC_LIST, action.data);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}
/* @param {object} action - action which will request for Licence Listing
  */
export function* getLicenceDefults(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, null)),
    put(getAction(clientAddressActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(clientAddressActionTypes.LOG_CLIENT_DETAIL_REQUEST)),
  ]);
  try {
    const clientID = action.data;
    // initial END POINT URL
    const initailURL = moduleAPI.ADMINISTRATION_CLIENT_LICENCE_DEFAULT.endpoint;
    // UPDATED END POINT URL
    const updatedApiUrl = `${initailURL}?clientNumber=${clientID}`;
    moduleAPI.ADMINISTRATION_CLIENT_LICENCE_DEFAULT.endpoint = updatedApiUrl;
    // API call to get Default Values
    const defaultValuesResponse = yield call(invokeApi, moduleAPI.ADMINISTRATION_CLIENT_LICENCE_DEFAULT);
    moduleAPI.ADMINISTRATION_CLIENT_LICENCE_DEFAULT.endpoint = initailURL;
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_DEFAULT_RESPONSE, { data: defaultValuesResponse })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert for new BCR
  */
export function* insertLic(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_LIC_INSERT,
      action.data.formatObject);
    resetUpsertValue = {
      upsertRequestedOn: null,
      upsertRequest: null,
      upsertRequestError: null,
      upsertData: responseData,
      upsertRequestProcessedOn: new Date(),
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(cliLicActionTypes.GET_LIC_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestProcessedOn: null,
      upsertRequestError: processError(err),
      upsertData: null,
      upsertRequestedOn: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

export function* getClientLicById(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
    put(getAction(clientAddressActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_LICENCE_BY_ID,
      action.data.reqObjForId);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_BY_ID_RESPONSE,
        formatResponse({ action: { data: action.data.reqObjForId }, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* updateClientLicence(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_LICENCE_UPDATE,
      action.data.formatObject);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(cliLicActionTypes.GET_LIC_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing Client Address
  */
export function* deleteClientLicence(action) {
  yield all([
    put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeletetValue)),
    put(processingStarted()),
    put(setUserPrefError(null)),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_LIC_DELETE,
      action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(cliLicActionTypes.GET_LIC_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted())]);
  }
}

/* @param {object} action - action which will request for Client Address Listing
  */
export function* getClientAddressList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, null)),
    put(getAction(clientAddressActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(clientAddressActionTypes.LOG_CLIENT_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_CLIENT_ADDRESS_LIST, action.data);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}
/* @param {object} action - action which will request for Client Address Default Values
  */
export function* getClientAddressDefault(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, null)),
    put(getAction(clientAddressActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(clientAddressActionTypes.LOG_CLIENT_DETAIL_REQUEST)),
  ]);
  try {
    // API call to get Primary Licence exist or not
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_CLIENT_ADDRESS_PRI_LICENCE,
      action.data);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_DEFAULT_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert for new insetClientAddress
  */
export function* insetClientAddress(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_ADDRESS_INSERT,
      action.data.formatObject);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(clientAddressActionTypes.GET_CLIENT_ADDRESS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: new Date(),
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert for new updateClientAddress
  */
export function* updateClientAddress(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_ADDRESS_UPDATE,
      action.data.formatObject);
    resetUpsertValue = {
      upsertRequestProcessedOn: new Date(),
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertData: responseData,
      upsertRequest: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(clientAddressActionTypes.GET_CLIENT_ADDRESS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertRequest: null,
      upsertData: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing Client Address
  */
export function* deleteClientAddress(action) {
  yield all([
    put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeletetValue)),
    put(processingStarted()),
    put(setUserPrefError(null)),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_ADDRESS_DELETE,
      action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    // API call to get Primary Licence exist or not
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(clientAddressActionTypes.GET_CLIENT_ADDRESS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted())]);
  }
}

export function* getBankingList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, null)),
    put(getAction(clientAddressActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(clientAddressActionTypes.LOG_CLIENT_DETAIL_REQUEST)),
  ]);
  try {
    // API call to get Default Values
    const defaultValuesResponse = yield call(invokeApi, moduleAPI.ADMINISTRATION_CLIENT_BANKING_DEFAULT);
    const response = yield call(invokeApi, moduleAPI.ADMINISTRATION_BANKING_LIST, action.data);
    const responseData = { ...response, ...{ defaultValues: { defaultCountry: defaultValuesResponse } } };
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all(
      [
        put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
        put(processingCompleted()),
      ],
    );
  }
}
// /**

//  * This generator function will invoke the api

//  * @param {object} action - action which will request for Client Address DEPENDS ON ff

//  */

export function* getCliAddrExistInFF(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_ADDRESS_EXIST_BY_FF,
      action.data.reqObjForId);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
//  * This generator function will invoke the api

//  * @param {object} action - action which will request for Client Address details by ID

//  */

export function* getClientAddressById(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
  ]);
  try {
    // eslint-disable-next-line no-unused-vars
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_ADDRESS_BY_ID,
      action.data.reqObjForId);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_BY_ID_RESPONSE,
        formatResponse({ action: { data: action.data.reqObjForId }, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
// /**

//  * This generator function will invoke the api

//  * @param {object} action - action which will request for Client Address details by ID

//  */

export function* getClientAddresViewsById(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_ADDRESS_VIEW_BY_ID,
      action.data.reqObjForId);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert for new BCR
  */
export function* insertClientBank(action) {
  const resetValues = {
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetValues)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_BANKING_INSERT,
      action.data.getUpSertObj.addEditData);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(clientBankingActionTypes.GET_CLIENT_BANKING_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

export function* getBankingbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_BANKING_BY_ID,
      action.data);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_BY_ID_RESPONSE,
        formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will update for existing Temp Adj
  */
export function* updateBanking(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_BANKING_UPDATE,
      action.data.getUpSertObj.addEditData);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(clientBankingActionTypes.GET_CLIENT_BANKING_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing BCR
  */
export function* deleteBanking(action) {
  yield all([
    put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeletetValue)),
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_BANKING_DELETE,
      action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(clientBankingActionTypes.GET_CLIENT_BANKING_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for Adddress Export Listing
 */
export function* getCliAddressExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_ADDRESS_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === errorCode) {
      const errorObject = exportMaxLimitErrorObj();
      resetServerValue = errorObject;
    } else {
      resetServerValue = processError(err);
    }
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for Licence Export Listing
 */
export function* getCliLicenceExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_LICENCE_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === errorCode) {
      const errorObject = exportMaxLimitErrorObj();
      resetServerValue = errorObject;
    } else {
      resetServerValue = processError(err);
    }
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for Template Adjustments Export Listing
 */
export function* getCliBankingExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_BANKING_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === errorCode) {
      const errorObject = exportMaxLimitErrorObj();
      resetServerValue = errorObject;
    } else {
      resetServerValue = processError(err);
    }
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getClientExportList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(clientActionsTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.CLIENT_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(clientActionsTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
function* moduleWatcher() {
  // client
  // client INSERT_CLIENT_REQUEST
  yield takeEvery(clientActionsTypes.GET_CLIENT_LIST_REQUEST, getClientStructureList);
  yield takeEvery(clientActionsTypes.INSERT_CLIENT_REQUEST, insertClientStructure);
  yield takeEvery(clientActionsTypes.UPDATE_CLIENT_REQUEST, updateClientStructure);
  yield takeEvery(clientActionsTypes.GET_CLIENT_BY_ID_REQUEST, getClientbyID);
  yield takeEvery(clientActionsTypes.GET_CLIENT_LENGTH, getClientLength);
  yield takeEvery(clientActionsTypes.DELETE_CLIENT_REQUEST, deleteClient);
  yield takeEvery(clientActionsTypes.GET_CS_EXPORT_REQUEST, getClientExportList);

  // client Licence
  yield takeEvery(cliLicActionTypes.INSERT_LIC_REQUEST, insertLic);
  yield takeEvery(cliLicActionTypes.GET_LIC_LIST_REQUEST, getLicenceList);
  yield takeEvery(cliLicActionTypes.GET_CLIENT_LIC_DEFAULT_REQUEST, getLicenceDefults);
  yield takeEvery(cliLicActionTypes.UPDATE_LIC_REQUEST, updateClientLicence);
  yield takeEvery(cliLicActionTypes.DELETE_LIC_REQUEST, deleteClientLicence);
  yield takeEvery(cliLicActionTypes.GET_CLIENT_LICENCE_BY_ID_REQUEST, getClientLicById);
  yield takeEvery(cliLicActionTypes.GET_CLIENT_LICENCE_EXPORT_REQUEST, getCliLicenceExportList);

  // Client Address
  yield takeEvery(clientAddressActionTypes.GET_CLIENT_ADDRESS_LIST_REQUEST, getClientAddressList);
  yield takeEvery(clientAddressActionTypes.GET_CLIENT_ADDRESS_DEFAULT_REQUEST, getClientAddressDefault);
  yield takeEvery(clientAddressActionTypes.ADD_CLIENT_ADDRESS_REQUEST, insetClientAddress);
  yield takeEvery(clientAddressActionTypes.UPDATE_CLIENT_ADDRESS_REQUEST, updateClientAddress);
  yield takeEvery(clientAddressActionTypes.DELETE_CLIENT_ADDRESS_REQUEST, deleteClientAddress);
  yield takeEvery(clientAddressActionTypes.GET_CLIENT_ADDRESS_BY_ID_REQUEST, getClientAddressById);
  yield takeEvery(clientAddressActionTypes.GET_CLIENT_ADDRESS_EXIST_IN_REQUEST, getCliAddrExistInFF);
  yield takeEvery(clientAddressActionTypes.GET_CLIENT_ADDRESS_VIEW_BY_ID_REQUEST, getClientAddresViewsById);
  yield takeEvery(clientAddressActionTypes.GET_CLIENT_ADDRESS_EXPORT_REQUEST, getCliAddressExportList);

  // BANKING
  yield takeEvery(clientBankingActionTypes.GET_CLIENT_BANKING_LIST_REQUEST, getBankingList);
  yield takeEvery(clientBankingActionTypes.INSERT_CLIENT_BANKING_REQUEST, insertClientBank);
  yield takeEvery(clientBankingActionTypes.GET_CLB_BY_ID_REQUEST, getBankingbyID);
  yield takeEvery(clientBankingActionTypes.GET_CLIENT_BANKING_RELATIONAL_TABLE, checkRelationalTableForBanking);
  yield takeEvery(clientBankingActionTypes.UPDATE_CLIENT_BANKING__REQUEST, updateBanking);
  yield takeEvery(clientBankingActionTypes.DELETE_CLIENT_BANKING_REQUEST, deleteBanking);
  yield takeEvery(clientBankingActionTypes.GET_CLIENT_BANKING_EXPORT_REQUEST, getCliBankingExportList);
}

export default moduleWatcher;
