import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { selectLetterActionTypes } from '../actions/actionTypes';

const initialState = {
  isSelectLetters: null,
  serverRequestError: null,
};
const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  downloadError: null,
  loading: false,
});

const selectLetter = (state = { ...initialState }, action = null) => {
  switch (action.type) {
    case selectLetterActionTypes.SELECT_LETTERS_INSERT_RES:
      return {
        ...state,
        isSelectLetterSuccess: action.data,
      };
    case selectLetterActionTypes.GET_LETTERS_LIST_RES:
      return {
        ...state,
        letterList: action.data,
      };

    case selectLetterActionTypes.DOWNLOAD_LETTERS_ERROR:
      return {
        ...state,
        downloadError: action.data,
      };
    case selectLetterActionTypes.DOWNLOAD_LETTERS_API_ERROR:
      return {
        ...state,
        downloadApiError: action.data,
      };
    case selectLetterActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);

    case commonTypes.RESETSTORE:
      return initialState;
    default:
      return state;
  }
};

export default selectLetter;
