import { environment } from '../../../common/util';

const envData = environment();
const InquiryApiConfig = {
  ADMINISTRATION_EXI_LIST: {
    endpoint: `${envData.apiHost}/api/exceptioninquiry/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_EXI_BY_ID: {
    endpoint: `${envData.apiHost}/api/exceptioninquiry/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_EXI_INSERT: {
    endpoint: `${envData.apiHost}/api/exceptioninquiry`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_EXI_UPDATE: {
    endpoint: `${envData.apiHost}/api/exceptioninquiry`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_EXI_DELETE: {
    endpoint: `${envData.apiHost}/api/exceptioninquiry`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_EXI_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/exceptioninquiry`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_EXI_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/exceptioninquiry/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  REQ_INQUIRY_LIST: {
    endpoint: `${envData.apiHost}/api/requirementinquiry/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  REQ_INQUIRY_EXPORT: {
    endpoint: `${envData.apiHost}/api/requirementinquiry/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  TASK_INQUIRY_LIST: {
    endpoint: `${envData.apiHost}/api/taskinquiry/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  TASK_INQUIRY_EXPORT: {
    endpoint: `${envData.apiHost}/api/taskinquiry/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  MASS_AGENT_TRANSFER_INQUIRY_LIST: {
    endpoint: `${envData.apiHost}/api/massagenttransferinq/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  MASS_AGENT_TRANSFER_INQUIRY_EXPORT: {
    endpoint: `${envData.apiHost}/api/massagenttransferinq/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  PAYMENT_CONTROLLER_LIST: {
    endpoint: `${envData.apiHost}/api/paymentmovement/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  PAYMENT_CONTROLLER_EXPORT: {
    endpoint: `${envData.apiHost}/api/paymentmovement/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  DEDUCTION_INQUIRY_LIST: {
    endpoint: `${envData.apiHost}/api/deductioninquiry/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  DEDUCTION_INQUIRY_EXPORT: {
    endpoint: `${envData.apiHost}/api/deductioninquiry/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default InquiryApiConfig;
