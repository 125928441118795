import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { handle, noop, uniqueId, useLang, useTheme,
  INPUT_VARIANT, TextInput, TEXT_ALIGN, Icon, i18n } from '@manulife/mux';
import { useTranslation } from 'react-i18next';
import { staticCommonLabelKeys } from '../../../moduleConstants';

import { Nav, ButtonControl, ScreenReaderOnly, TextInputControl } from './styledComponents';

const PX_REM_RATIO = 0.0625;
const rem = (px) => `${px * PX_REM_RATIO}rem`;
const remTwelve = 12;

/**
 * Display left arrow icon
 */
const displayLeftArrowIcon = (leftAltText, item, theme) => {
  const isDisabled = (item === 1 || item === 0);
  return (
    <Icon
      alt={leftAltText}
      manulifeName="arrowleft"
      fill={
      isDisabled
        ? theme.colorPalette.neutralColors.light.shade_dark_3
        : theme.colorPalette.neutralColors.dark.shade_light_1
      }
      height={rem(remTwelve)}
    />
  );
};

/**
 * Display right arrow icon
 */
const displayRightArrowIcon = (rightAltText, item, theme, totalItems) => (
  <Icon
    alt={rightAltText}
    manulifeName="arrowright"
    fill={
            item === totalItems
              ? theme.colorPalette.neutralColors.light.shade_dark_3
              : theme.colorPalette.neutralColors.dark.shade_light_1
          }
    height={rem(remTwelve)}
  />
);

const Pagination = ({
  id = uniqueId('pagination-id-'),
  currentItem,
  totalItems,
  previousAltText,
  nextAltText,
  onChange,
  navigationAriaLabel,
  currentItemAriaLabel,
}) => {
  const lang = useLang();
  const theme = useTheme();
  const [t] = useTranslation();
  const ofLabel = t(staticCommonLabelKeys.COMMON_LABEL_OF);
  const [item, setItem] = useState(currentItem);
  const [text, setText] = useState(`${currentItem}`);
  const wrappedOnChange = handle(onChange);

  useEffect(() => {
    setText(`${currentItem}`);
    setItem(currentItem);
  }, [currentItem]);

  const textChangeHandler = useCallback(
    (value, event) => {
      const parsedValue = Number.parseInt(value, 10);
      if (parsedValue && parsedValue >= 1 && parsedValue <= totalItems) {
        setItem(parsedValue);
        setText(`${parsedValue}`);
        wrappedOnChange(event, parsedValue);
      } else {
        setText(`${item}`);
      }
    },
    [totalItems, item, setItem, wrappedOnChange, setText],
  );

  // memoized text
  const leftAltText = useMemo(() => previousAltText || i18n[lang].pagination.previousAltText, [
    previousAltText,
    lang,
  ]);
  const rightAltText = useMemo(() => nextAltText || i18n[lang].pagination.nextAltText, [
    nextAltText,
    lang,
  ]);

  /**
   * Controller for chevron toggles
   * @param {number} shift - +1 move forward by one item, -1 move back by one
   */
  const pageControlHandler = (event, shift) => {
    const newItem = shift + item;
    if (newItem >= 1 && newItem <= totalItems) {
      setItem(newItem);
      setText(`${newItem}`);
      wrappedOnChange(event, newItem);
    }
  };
  return (
    <Nav id={id} aria-label={navigationAriaLabel}>
      <ButtonControl
        disabled={item === 1 || item === 0}
        aria-disabled={item === 1 || item === 0}
        onClick={(event) => pageControlHandler(event, -1)}
        data-testid="previous"
      >
        {displayLeftArrowIcon(leftAltText, item, theme)}
        <ScreenReaderOnly>{leftAltText}</ScreenReaderOnly>
      </ButtonControl>
      <TextInputControl aria-live="polite" aria-atomic>
        <TextInput
          value={text}
          onChange={(value) => setText(value)}
          onBlur={textChangeHandler}
          variant={INPUT_VARIANT.BASIC_LINE}
          textAlign={TEXT_ALIGN.CENTER}
          customStyle={{ rootStyle: { maxWidth: '55px' } }}
          ariaLabel={currentItemAriaLabel}
          data-testid="current-item-input"
        />
        {`${ofLabel} ${totalItems}`}
      </TextInputControl>
      <ButtonControl
        disabled={item === totalItems}
        aria-disabled={item === totalItems}
        onClick={(event) => pageControlHandler(event, +1)}
        data-testid="next"
      >
        {displayRightArrowIcon(rightAltText, item, theme, totalItems)}
        <ScreenReaderOnly>{rightAltText}</ScreenReaderOnly>
      </ButtonControl>
    </Nav>
  );
};

Pagination.propTypes = {
  /** DOM element ID to give the pagination control */
  id: PropTypes.string,

  /** The currently selected item */
  currentItem: PropTypes.number,

  /** Total number of items */
  totalItems: PropTypes.number,

  /** previous alt text */
  previousAltText: PropTypes.string,

  /** next alt text */
  nextAltText: PropTypes.string,

  /** onChange function that gets called back with new item */
  onChange: PropTypes.func,

  /** aria-label for pagination control */
  navigationAriaLabel: PropTypes.string.isRequired,

  /** aria-label for input that has current item */
  currentItemAriaLabel: PropTypes.string.isRequired,
};

Pagination.defaultProps = {
  id: undefined,
  currentItem: 1,
  totalItems: 1,
  previousAltText: '',
  nextAltText: '',
  onChange: noop,
};

Pagination.displayName = 'Pagination';

export default Pagination;
