/**
 * @Description This will create instance of global history
 * @FileName appHistory.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 14:39:46
 * @IssueID - 459
*/
import { createBrowserHistory } from 'history';

/**
 * Create the instance of the Browser History;
 * which needs to be referred where ever history needs to be used
 */
const appHistory = createBrowserHistory({
  forceRefresh: true,
});
export default appHistory;
