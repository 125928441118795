/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { planCodeActionTypes, dataDictActionType, paymentThresholdActionType,
  genEditRelationshipValActionType, manageGroupsActionType, systemOptionsActionType,
  periodDefinitionActionType, autoReportsActionTypes } from '../actions/actionTypes';

import { formatResponse, exportErrorMsg, processError, renderFileName,
  setConSysLangParams, setConLangParams } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import { getDDList, getDDbyID, updateDataDict, getDDExportList,
  getPtList, getPtbyID, insertPt, updatePt,
  deletePt, getPTHExportList } from './adminModuleWatcher';
import {
  processingStarted, processingCompleted, setUserPrefError,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

const getResetUpsertValue = (responseData, err) => ({
  upsertRequest: null,
  upsertRequestError: err ? processError(err) : null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: responseData ? new Date() : null,
  upsertData: responseData,
});

/**
    * Get Period Definition List
    * @param {any} action
    */
export function* getPdList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(periodDefinitionActionType.RESET_SERVER_VALUE, null)),
    put(getAction(periodDefinitionActionType.LOG_PD_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  const initApiUrl = moduleAPI.ADMINISTRATION_PD_LIST.endpoint;
  try {
    const yearData = `?year=${action.data.year}`;
    const updatedApiUrl = initApiUrl + yearData;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_PD_LIST };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    yield all([
      put(getAction(periodDefinitionActionType.GET_PD_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(periodDefinitionActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(periodDefinitionActionType.GET_PD_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
    * Get Period Definition by id
    * @param {object} action
    */
export function* getPdbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(periodDefinitionActionType.RESET_SERVER_VALUE, null)),
    put(getAction(periodDefinitionActionType.LOG_PD_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PD_BY_ID, action.data);
    yield all([
      put(getAction(periodDefinitionActionType.GET_PD_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(periodDefinitionActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(periodDefinitionActionType.GET_PD_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* insertPd(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PD_INSERT, action.data.formatObject);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(periodDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(periodDefinitionActionType.GET_PD_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(periodDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
// updatePd
export function* updatePd(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PD_UPDATE, action.data.formatObject);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield put(getAction(periodDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue));
    yield put(
      getAction(periodDefinitionActionType.GET_PD_LIST_REQUEST, action.data.reqObject),
    );
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(periodDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* splitPd(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PD_SPLIT, action.data.formatObject);
    if (responseData) {
      resetUpsertValue = getResetUpsertValue(responseData, null);
      yield put(getAction(periodDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(
        getAction(periodDefinitionActionType.GET_PD_LIST_REQUEST, action.data.reqObject),
      );
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(periodDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
    * This generator function will invoke the api
    * @param {object} action - action which will delete existing System Parameter
    */
export function* deletePd(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PD_DELETE, action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([put(getAction(periodDefinitionActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(periodDefinitionActionType.GET_PD_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(periodDefinitionActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(periodDefinitionActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for SP Export Listing
   */
export function* getPdExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(periodDefinitionActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PD_EXPORT_LIST, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(periodDefinitionActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

// PlanCode
/**
    * This generator function will invoke the api
    * @param {object} action - action which will get list data for Plan code
    */
export function* getPlcList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(planCodeActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(planCodeActionTypes.LOG_PLC_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PLC_LIST, action.data);
    yield all([
      put(getAction(planCodeActionTypes.GET_PLC_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(planCodeActionTypes.GET_PLC_LIST_ERROR, err)),
      put(getAction(planCodeActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
    * This generator function will invoke the api
    * @param {object} action - action which will get PLC by Id
    */
export function* getPlcbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(planCodeActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(planCodeActionTypes.LOG_PLC_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PLC_BY_ID, action.data);
    yield all([
      put(getAction(planCodeActionTypes.GET_PLC_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(planCodeActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(planCodeActionTypes.GET_PLC_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
export function* insertPLC(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PLC_INSERT, action.data.formatObject);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(planCodeActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(planCodeActionTypes.GET_PLC_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(planCodeActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
    * This generator function will invoke the api
    * @param {object} action - action which will update data for existing Plan code
    */
export function* updatePlc(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PLC_UPDATE, action.data.formatObject);
    if (responseData) {
      resetUpsertValue = getResetUpsertValue(responseData, null);
      yield put(getAction(planCodeActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(planCodeActionTypes.GET_PLC_LIST_REQUEST, action.data.reqObject));
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(planCodeActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
    * @param {object} action - action which will delete data for existing Plan code
    */
export function* deletePlc(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PLC_DELETE, action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(planCodeActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(planCodeActionTypes.GET_PLC_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(planCodeActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(planCodeActionTypes.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for SP Export Listing
   */
export function* getPLCExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(planCodeActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_PLC_EXPORT_LIST, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(planCodeActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
    * This generator function will invoke the api
    * @param {object} action - action which will request for LookUps Data
    */
export function* getLookUpsData(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.GERV_LOOKUPS_DATA, action.data);
    yield all([
      put(getAction(genEditRelationshipValActionType.RESET_GERV_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(genEditRelationshipValActionType.RESET_GERV_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will get details of system options
   */
export function* getSysOptDetails(action) {
  const { locale, storeSetState } = action.data;
  yield all([put(processingStarted())]);
  try {
    const lang = setConLangParams({ storeSetState, locale });
    const sysOptDetailsInitURL = JSON.parse(JSON.stringify(moduleAPI.ADMINISTRATION_SYS_OPT_DETAILS));
    sysOptDetailsInitURL.endpoint += lang;
    const responseData = yield call(invokeApi, sysOptDetailsInitURL, lang);
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
    if (storeSetState) {
      storeSetState(!!responseData);
    }
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will Update details of system options
   */
export function* updateSysOptDetails(action) {
  let resetUpsertValue = null;
  yield all([
    put(processingStarted()),
    put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMIN_SYS_OPT_DETAILS_UPDATE, action.data.formatObject);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(systemOptionsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(systemOptionsActionType.GET_SYS_OPT_REQUEST, { locale: action.data.reqObject })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
/* @param {object} action - action which will get details of system options
   */
export function* getGenPref(action) {
  const { locale, storeSetState } = action.data;
  yield all([put(processingStarted())]);
  try {
    const lang = setConLangParams({ storeSetState, locale });
    const genPrefInitURL = JSON.parse(JSON.stringify(moduleAPI.ADMINISTRATION_GENPREF_LIST));
    genPrefInitURL.endpoint += lang;
    const responseData = yield call(invokeApi, genPrefInitURL, lang);
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
    if (storeSetState) {
      storeSetState(!!responseData);
    }
  } catch (err) {
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will Update details of Agent Preference
   */
export function* updateGenPref(action) {
  let resetUpsertValue = null;
  yield all([
    put(processingStarted()),
    put(getAction(systemOptionsActionType.UPDATE_SYS_OPT_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GENPREF_UPDATE, action.data.formatObject);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(systemOptionsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(systemOptionsActionType.GET_SYS_OPT_GEN_PREF_REQUEST, { locale: action.data.reqObject })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

/* @param {object} action - action which will get details of Agent Preference
   */
export function* getAgentPref(action) {
  const { locale, storeSetState } = action.data;
  yield all([put(processingStarted())]);
  try {
    const lang = setConLangParams({ storeSetState, locale });
    const agentPrefInitURL = JSON.parse(JSON.stringify(moduleAPI.ADMINISTRATION_AGENT_LIST));
    agentPrefInitURL.endpoint += lang;
    const responseData = yield call(invokeApi, agentPrefInitURL, lang);
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
    if (storeSetState) {
      storeSetState(!!responseData);
    }
  } catch (err) {
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will Update details of Agent Preference
   */
export function* updateAgentPref(action) {
  yield all([
    put(processingStarted()),
    put(getAction(systemOptionsActionType.UPDATE_SYS_OPT_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AGENT_UPDATE, action.data.formatObject);
    let resetUpsertValue;
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
    }
    yield all([
      put(getAction(systemOptionsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(systemOptionsActionType.UPDATE_SYS_OPT_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will concurrency check for Manage Groups CURD
  */
export function* concurrencyCheck(action) {
  const reqAppObjRef = {
    resourceIdMenu: -1,
    groupName: action.data.sgrName,
    language: 'E',
    isScreen: true,
  };
  yield all([
    put(processingStarted()),
    put(getAction(manageGroupsActionType.RESET_CONCURRENCY_FLAG, false)),
  ]);
  try {
    yield call(invokeApi, moduleAPI.ADMINISTRATION_SECURITY_GROUPS, reqAppObjRef);
    yield all([
      put(getAction(manageGroupsActionType.RESET_CONCURRENCY_FLAG, action.data)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(paymentThresholdActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(manageGroupsActionType.RESET_CONCURRENCY_FLAG, false)),
      put(processingCompleted()),
    ]);
  }
}
export function* updatefieldforce(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.SECURITY_PAYMENTSEC_UPDATE,
      action.data,
    );
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(
        getAction(manageGroupsActionType.PAYMENT_UPSERT_DATA, responseData),
      );
      yield all([
        put(getAction(manageGroupsActionType.RESET_SECURITY_PAYMENT_INFO, responseData)),
        put(processingCompleted())]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(manageGroupsActionType.RESET_SERVER_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
// Notifications/others

export function* getNotifOther(action) {
  const { locale, storeSetState } = action.data;
  yield all([put(processingStarted()), put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, null))]);
  try {
    const lang = setConSysLangParams({ storeSetState, locale });
    const notifOtherInitURL = JSON.parse(JSON.stringify(moduleAPI.ADMINISTRATION_NOTIFOTHER_LIST));
    notifOtherInitURL.endpoint += lang;
    const responseData = yield call(invokeApi, notifOtherInitURL, lang);
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
    if (storeSetState) {
      storeSetState(!!responseData);
    }
  } catch (err) {
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
export function* updateNotifOther(action) {
  let resetUpsertValue = null;
  yield all([
    put(processingStarted()),
    put(getAction(systemOptionsActionType.UPDATE_SYS_OPT_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_NOTIFOTHER_UPDATE, action.data.formatObject);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(systemOptionsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(systemOptionsActionType.GET_SYS_OPT_NOTIF_OTHER_REQUEST, { locale: action.data.reqObject })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
// Auto Reports
export function* getAutoRepList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(autoReportsActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(autoReportsActionTypes.LOG_AUTOREPORTS_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AUTO_REPORTS_LIST, action.data);
    yield all([
      put(getAction(autoReportsActionTypes.GET_AUTOREPORTS_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(autoReportsActionTypes.GET_AUTOREPORTS_LIST_ERROR, err)),
      put(getAction(autoReportsActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getAutoRepByItem(action) {
  let resetServerValue = null;
  yield all([
    put(processingStarted()),
    put(setUserPrefError(resetServerValue)),
    put(getAction(autoReportsActionTypes.LOG_AUTOREPORTS_ITEM_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_AUTO_REPORTS_BY_ITEM,
      action.data);
    yield all([
      put(getAction(autoReportsActionTypes.GET_AUTOREPORTS_ITEM_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted()),
      put(getAction(autoReportsActionTypes.GET_AUTOREPORTS_ITEM_ERROR, err)),
      put(processingCompleted())]);
  }
}
export function* getAutoRepExportList(action) {
  yield all([put(processingStarted()), put(getAction(autoReportsActionTypes.RESET_SERVER_VALUE, null))]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_AUTO_REPORTS_EXPORT_LIST, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([put(getAction(autoReportsActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

function* moduleWatcher() {
  // period definition
  yield takeEvery(periodDefinitionActionType.DELETE_PD_REQUEST, deletePd);
  yield takeEvery(periodDefinitionActionType.INSERT_PD_REQUEST, insertPd);
  yield takeEvery(periodDefinitionActionType.GET_PD_LIST_REQUEST, getPdList);
  yield takeEvery(periodDefinitionActionType.GET_PD_BY_ID_REQUEST, getPdbyID);
  yield takeEvery(periodDefinitionActionType.UPDATE_PD_REQUEST, updatePd);
  yield takeEvery(periodDefinitionActionType.SPLIT_PD_REQUEST, splitPd);
  // plan code
  yield takeEvery(planCodeActionTypes.GET_PLC_LIST_REQUEST, getPlcList);
  yield takeEvery(planCodeActionTypes.GET_PLC_BY_ID_REQUEST, getPlcbyID);
  yield takeEvery(planCodeActionTypes.INSERT_PLC_REQUEST, insertPLC);
  yield takeEvery(planCodeActionTypes.UPDATE_PLC_REQUEST, updatePlc);
  yield takeEvery(planCodeActionTypes.DELETE_PLC_REQUEST, deletePlc);
  yield takeEvery(planCodeActionTypes.GET_PLC_EXPORT_REQUEST, getPLCExportList);
  // data dictionary
  yield takeEvery(dataDictActionType.GET_DD_LIST_REQUEST, getDDList);
  yield takeEvery(dataDictActionType.GET_DD_BY_ID_REQUEST, getDDbyID);
  yield takeEvery(dataDictActionType.UPDATE_DD_REQUEST, updateDataDict);
  yield takeEvery(dataDictActionType.GET_DD_EXPORT_REQUEST, getDDExportList);
  // Payment threshold
  yield takeEvery(paymentThresholdActionType.GET_PT_LIST_REQUEST, getPtList);
  yield takeEvery(paymentThresholdActionType.GET_PT_BY_ID_REQUEST, getPtbyID);
  yield takeEvery(paymentThresholdActionType.INSERT_PT_REQUEST, insertPt);
  yield takeEvery(paymentThresholdActionType.UPDATE_PT_REQUEST, updatePt);
  yield takeEvery(paymentThresholdActionType.DELETE_PT_REQUEST, deletePt);
  yield takeEvery(paymentThresholdActionType.GET_PT_EXPORT_REQUEST, getPTHExportList);
  // System Options
  yield takeEvery(systemOptionsActionType.GET_SYS_OPT_REQUEST, getSysOptDetails);
  yield takeEvery(systemOptionsActionType.UPDATE_SYS_OPT_REQUEST, updateSysOptDetails);
  // General preferences
  yield takeEvery(systemOptionsActionType.GET_SYS_OPT_GEN_PREF_REQUEST, getGenPref);
  yield takeEvery(systemOptionsActionType.UPDATE_SYS_OPT_GEN_PREF_REQUEST, updateGenPref);
  // Agent preferences

  yield takeEvery(systemOptionsActionType.GET_SYS_OPT_AGENT_PREF_REQUEST, getAgentPref);
  yield takeEvery(systemOptionsActionType.UPDATE_SYS_OPT_AGENT_PREF_REQUEST, updateAgentPref);
  // Notifications/Other
  yield takeEvery(systemOptionsActionType.GET_SYS_OPT_NOTIF_OTHER_REQUEST, getNotifOther);
  yield takeEvery(systemOptionsActionType.UPDATE_SYS_OPT_NOTIF_OTHER_REQUEST, updateNotifOther);
  // Auto Reports
  yield takeEvery(autoReportsActionTypes.GET_AUTOREPORTS_LIST_REQUEST, getAutoRepList);
  yield takeEvery(autoReportsActionTypes.GET_AUTOREPORTS_ITEM_REQUEST, getAutoRepByItem);
  yield takeEvery(autoReportsActionTypes.GET_AUTOREPORTS_EXPORT_REQUEST, getAutoRepExportList);
}
export default moduleWatcher;
