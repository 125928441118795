/* eslint-disable no-unused-vars */
/**
 * @Description This is FasatModal component page.
 * This is a wrapper on "Modal" component from MUX without adding any custom functionlities
 * @FileName fasatModal.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 04 February, 2021 12:31:22
 * @IssueID CDTFASATMOD- 316
 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { Modal, uniqueId } from '@manulife/mux';
import './fasatModal.scss';
import FasatButton from '../fasatButton/fasatButton';
import { Logger } from '../../util';
import FasatToastContainer from '../fasatToast/fasatToastContainer';
import { checkDirtyData, releaseLockCombo, setLock } from '../../redux/actions/commonActions';
/**
*
* This should be a generic component which can be used for any kind of overlay
* This should have following feature:
* 1. Should be able to hide buttons
* 2. Should be able to disable primary button
* 3. Should be able to add children component
* 4. Should be able to raise an event for primary and secondary button clikc
* 5. Children should be able to handle those events
*/
const displayModal = (setModalOpen, display) => {
  setModalOpen(display);
  if (display) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'auto';
  }
};
const disableButton = (isDirty, SetDisableSave) => {
  if (isDirty) {
    SetDisableSave(false);
  } else {
    SetDisableSave(true);
  }
};
const secondaryButton = (onSecondaryButtonClick, secondaryTopic) => {
  onSecondaryButtonClick(secondaryTopic);
};

const modalCloseFunction = (setModalOpen, modalCosed, isPrimaryButtonClicked) => {
  setModalOpen(false);
  modalCosed(isPrimaryButtonClicked);
  const highlightedRow = document.querySelector('.highlightedRow');
  if (highlightedRow) {
    highlightedRow.classList.remove('highlightedRow');
  }
  document.body.style.overflowY = 'auto';
};

const onPrimaryClickFunction = (pt, modalClose) => {
  if (pt && pt !== '') {
    PubSub.publish(`${pt}`, { close: modalClose });
  }
};

const onSecondaryButtonClickFunction = (isDirty, modalClose, onCancelClickCancel, st, dispatch) => {
  if (isDirty) {
    onCancelClickCancel();
    PubSub.publish(`${st}`, { close: modalClose });
  } else if (!isDirty) {
    if (st && st !== '') {
      dispatch(checkDirtyData(false));
      PubSub.publish(`${st}`, { close: modalClose });
    }
  } else {
    Logger.verbose('error');
  }
};
const isDisabled = (isCheckDirty, DisableSave) => (isCheckDirty === 'yes' ? DisableSave : false);
const checkNotStayDiscard = (primaryButtonText, staydiscard) => (!!(primaryButtonText && !staydiscard));
const checkStayDiscardCon = (primaryButtonText, staydiscard) => (primaryButtonText && staydiscard);
const renderFasatToastContainer = (showNotificationInModal, toasterContainerId) => (
  showNotificationInModal && (
    <FasatToastContainer
      containerId={toasterContainerId}
      enableMultiContainer
    />
  ));
const renderBtnCombo = ({ staydiscard, isLogout, secondaryTopic, onSecondaryButtonClick, secondaryButtonText,
  onClickDiscard, modalClose, secondaryBtnClickHandler }) => (
    <div className="left">
      {(!staydiscard || isLogout) && (
      <FasatButton
        variant="secondary"
        type="button"
        onClick={() => (secondaryBtnClickHandler ? secondaryBtnClickHandler() : onSecondaryButtonClick(secondaryTopic))}
        id="modalSecondaryButton"
      >
        {secondaryButtonText}
      </FasatButton>
      )}
      {(staydiscard && !isLogout) && (
      <FasatButton
        variant="secondary"
        type="button"
        id="modalSecondaryButton"
        onClick={() => {
          onClickDiscard();
          modalClose();
        }}
      >
        {secondaryButtonText}
      </FasatButton>
      )}
    </div>
);

const getTertiaryBtn = (tertiaryBtn, onClickTertiaryButton, tertiaryButtonText) => (tertiaryBtn && (
  <div className="left tertiaryBtn">
    <FasatButton
      variant="tertiary_back"
      type="button"
      id="modalTertiaryButton"
      onClick={() => {
        onClickTertiaryButton();
      }}
    >
      {tertiaryButtonText}
    </FasatButton>
  </div>
));
const getThirdBtn = (thirdBtn, onClickThirdBtn, modalClose, thirdButtonText) => (thirdBtn && (
  <div className="left">
    <FasatButton
      variant="secondary"
      type="button"
      id="modalThirdButton"
      onClick={() => {
        onClickThirdBtn();
        modalClose();
      }}
    >
      {thirdButtonText}
    </FasatButton>
  </div>
));
const renderModal = ({ modalOpen, staydiscard, modalClose, onSecondaryButtonClick, secondaryTopic,
  title, showCloseButton, modalWidth, modalClass, children, showActionButton, secondaryButtonText, thirdButtonText,
  isLogout, onClickDiscard, onClickThirdBtn, primaryButtonText, isCheckDirty, DisableSave, onPrimaryButtonClick,
  primaryTopic, defaultCheck, onClickStay, showNotificationInModal, toasterContainerId, id, thirdBtn, footerText,
  primaryBtnClickHandler, secondaryBtnClickHandler, tertiaryBtn, tertiaryButtonText, onClickTertiaryButton }) => {
  const unique = uniqueId();
  const headingId = `heading-${unique}`;

  const checkSecondaryHandler = () => {
    if (secondaryBtnClickHandler) {
      secondaryBtnClickHandler();
    } else {
      onSecondaryButtonClick(secondaryTopic);
    }
  };
  return (
    <div>
      <Modal
        id={id}
        isOpen={modalOpen}
        onClose={() => (staydiscard ? modalClose() : checkSecondaryHandler())}
        ariaLabel={title}
        ariaLabelledBy={headingId}
        ariaDescribedBy="content"
        shouldCloseOnBackdropClick={false}
        shouldCloseWithEscapeKey={false}
        showCloseButton={showCloseButton}
        customStyle={{ modalStyle: { width: modalWidth } }}
        toasterContainerId={toasterContainerId}
      >
        <div className={modalClass || ''}>
          <h2 id={headingId} className="modalHeading">{title}</h2>
          <div className="formContent">{children}</div>
          {showActionButton && (
          <div className="buttonContainer modalBtnContainer">
            {getTertiaryBtn(tertiaryBtn, onClickTertiaryButton, tertiaryButtonText)}
            {getThirdBtn(thirdBtn, onClickThirdBtn, modalClose, thirdButtonText)}
            {secondaryButtonText && renderBtnCombo({ staydiscard,
              isLogout,
              secondaryTopic,
              onSecondaryButtonClick,
              secondaryButtonText,
              onClickDiscard,
              modalClose,
              secondaryBtnClickHandler })}
            {checkNotStayDiscard(primaryButtonText, staydiscard) && (
            <div className="right">
              <FasatButton
                type="submit"
                disabled={isDisabled(isCheckDirty, DisableSave)}
                onClick={() => (primaryBtnClickHandler ? primaryBtnClickHandler() : onPrimaryButtonClick(primaryTopic))}
                id="modalPrimaryButton"
              >
                {primaryButtonText}
              </FasatButton>
            </div>
            )}
            {checkStayDiscardCon(primaryButtonText, staydiscard) && (
            <div className="right">
              <FasatButton
                type="submit"
                id="modalPrimaryButton"
                disabled={isDisabled(isCheckDirty, DisableSave)}
                onClick={() => {
                  if (defaultCheck) {
                    onPrimaryButtonClick(primaryTopic);
                  } else {
                    onClickStay();
                    modalClose(true);
                  }
                }}
              >
                {primaryButtonText}
              </FasatButton>
            </div>
            )}
          </div>
          )}
          {footerText && <div className="left footer-text">{footerText}</div>}
        </div>
        {renderFasatToastContainer(showNotificationInModal, toasterContainerId)}
      </Modal>
    </div>
  );
};

const FasatModal = ({ title, secondaryButtonText, thirdButtonText, primaryButtonText, showActionButton,
  display, children, modalWidth, primaryTopic, secondaryTopic, modalClass, showCloseButton, defaultCheck,
  modalCosed, staydiscard, onCancelClickCancel, onClickDiscard, onClickThirdBtn, isCheckDirty, onClickStay, isLogout,
  showNotificationInModal, toasterContainerId, id, lockCombo, thirdBtn, tertiaryBtn, footerText, primaryBtnClickHandler,
  secondaryBtnClickHandler, tertiaryButtonText, onClickTertiaryButton,
}) => {
  const [modalOpen, setModalOpen] = useState(display);
  const [DisableSave, SetDisableSave] = useState(true);
  const [comboLock, SetComboLock] = useState(true);
  const isDirty = useSelector((state) => state.ApplicationState.isDirty);
  const loggedUser = useSelector(
    ({ ApplicationStateRBAC }) => ApplicationStateRBAC && ApplicationStateRBAC.loggedUser,
  );
  const dispatch = useDispatch();
  useEffect(() => displayModal(setModalOpen, display), [display]);
  useEffect(() => {
    if (lockCombo) {
      SetComboLock({ ...lockCombo, lockedBy: loggedUser.UserId });
      dispatch(setLock({ ...lockCombo, lockedBy: loggedUser.UserId }));
    }
  },
  [lockCombo]);
  const modalClose = (isPrimaryButtonClicked) => {
    modalCloseFunction(setModalOpen, modalCosed, isPrimaryButtonClicked);
    if (lockCombo) {
      dispatch(releaseLockCombo(comboLock));
    }
  };
  useEffect(() => disableButton(isDirty, SetDisableSave), [isDirty]);
  const processingInProgress = useSelector(
    ({ ApplicationStateFilterList }) => ApplicationStateFilterList.processingInProgress,
  );
  const onPrimaryButtonClick = (pt) => {
    if (!processingInProgress) {
      onPrimaryClickFunction(pt, modalClose);
    }
  };
  const onSecondaryButtonClick = (st) => {
    onSecondaryButtonClickFunction(isDirty, modalClose, onCancelClickCancel, st, dispatch);
  };
  const modalId = id || `modal-id-${uniqueId()}`;
  return (
    renderModal({ modalOpen,
      staydiscard,
      modalClose,
      onSecondaryButtonClick,
      secondaryTopic,
      title,
      showCloseButton,
      modalWidth,
      modalClass,
      children,
      showActionButton,
      secondaryButtonText,
      thirdButtonText,
      tertiaryButtonText,
      isLogout,
      onClickDiscard,
      onClickThirdBtn,
      onClickTertiaryButton,
      primaryButtonText,
      isCheckDirty,
      DisableSave,
      onPrimaryButtonClick,
      primaryTopic,
      defaultCheck,
      modalCosed,
      onClickStay,
      showNotificationInModal,
      toasterContainerId,
      footerText,
      id: modalId,
      thirdBtn,
      tertiaryBtn,
      primaryBtnClickHandler,
      secondaryBtnClickHandler })
  );
};

FasatModal.propTypes = {
  title: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  thirdButtonText: PropTypes.string,
  tertiaryButtonText: PropTypes.string,
  primaryButtonText: PropTypes.string,
  showActionButton: PropTypes.bool,
  display: PropTypes.bool,
  children: PropTypes.shape(),
  modalWidth: PropTypes.string,
  primaryTopic: PropTypes.string,
  secondaryTopic: PropTypes.string,
  modalClass: PropTypes.string,
  showCloseButton: PropTypes.bool,
  modalCosed: PropTypes.func,
  onCancelClickCancel: PropTypes.func,
  onClickDiscard: PropTypes.func,
  onClickThirdBtn: PropTypes.func,
  onClickTertiaryButton: PropTypes.func,
  staydiscard: PropTypes.bool,
  isCheckDirty: PropTypes.string,
  onClickStay: PropTypes.func,
  isLogout: PropTypes.bool,
  defaultCheck: PropTypes.bool,
  showNotificationInModal: PropTypes.bool,
  toasterContainerId: PropTypes.string,
  id: PropTypes.string,
  lockCombo: PropTypes.string,
  thirdBtn: PropTypes.bool,
  tertiaryBtn: PropTypes.bool,
  footerText: PropTypes.string,
  primaryBtnClickHandler: PropTypes.func,
  secondaryBtnClickHandler: PropTypes.func,
};

FasatModal.defaultProps = {
  title: '',
  secondaryButtonText: '',
  thirdButtonText: '',
  tertiaryButtonText: '',
  primaryButtonText: '',
  showActionButton: true,
  display: false,
  children: {},
  modalWidth: '',
  primaryTopic: '',
  secondaryTopic: '',
  modalClass: '',
  showCloseButton: true,
  modalCosed: () => undefined,
  onClickStay: () => undefined,
  staydiscard: false,
  onCancelClickCancel: () => undefined,
  onClickDiscard: () => undefined,
  onClickThirdBtn: () => undefined,
  onClickTertiaryButton: () => undefined,
  isCheckDirty: '',
  isLogout: undefined,
  defaultCheck: false,
  showNotificationInModal: false,
  toasterContainerId: '',
  id: undefined,
  lockCombo: null,
  thirdBtn: false,
  tertiaryBtn: false,
  footerText: null,
};

export default FasatModal;
