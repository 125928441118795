import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getCurrentLocale } from '../../../../common/i18n';
import { getDateFormat, initialReqObject, pageChngRequestObj, resetUpsertValue } from '../../../../common/util';
import { aclKeysConstant, staticLabelKeys } from '../../moduleConstants';
import { globalConstants, staticCommonLabelKeys, userLock } from '../../../../common/moduleConstants';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import { fieldForceDeductionActionTypes } from '../../redux';
import FieldForceDeductionList from './fieldforceDeductionList';
import { FasatMessageCard, FasatToastContainer } from '../../../../common/components';
import FasatPage, { fetchServerError } from '../../../../common/components/fasatPage/fasatPage';
import { releaseLockCombo, setLock } from '../../../../common/redux/actions/commonActions';

const { serverErrorMsgContainer } = globalConstants;

export const getTableData = ({ listData }) => {
  const currentPage = listData?.page || 1;
  const totalRows = listData?.totalRows || 0;
  const rowCount = listData && listData.data ? listData.data.length : 0;
  const GridPageSize = listData?.pageSize || globalConstants.pageSizeTen;
  return { currentPage, totalRows, rowCount, GridPageSize };
};
const handleReqObj = (transId, locale) => ({
  locale: locale === 'en' ? 'E' : 'F',
  export: 'NONE',
  page: 1,
  pageSize: 10,
  lookUpInformation: [],
  parameters: [
    {
      key: 'adeAgtCd',
      operator: 'OPT_EQUALS',
      type: 'text',
      values: [transId],
    },
  ],
  sortOptions: [],
  listSortOptions: [],
});

const adeAgtCd = 'adeAgtCd';
const adeSeq = 'adeSeq';
const type = 'type';
const active = 'active';
const adeAmt = 'amount';
const calcMethod = 'calcMethod';
const frequency = 'frequency';
const adeEffDt = 'adeEffDt';
const adeLstProcDt = 'adeLstProcDt';
const adeEndDt = 'adeEndDt';
const agentReq = 'agentReq';
const adeTransAgtCd = 'adeTransAgtCd';
const adePercent = 'percent';
const adeDays = 'adeDays';
const adeTargetAmt = 'targetAmt';
const adeCumulativeAmt = 'cumulativeAmt';
const monTarget = 'monTarget';
const adeMonthlyTargetAmt = 'monthlyTargetAmt';
const adeMonthlyCumulativeAmt = 'monthlyCumulativeAmt';
const splPayProc = 'splPayProc';
const adePctOfBal = 'pctOfBal';
const adePctOfFyrComm = 'pctOfFyrComm';
const adePctOfRenComm = 'pctOfRenComm';
const adePctOfFyrOvrde = 'pctOfFyrOvrde';
const adePctOfRenOvrde = 'pctOfRenOvrde';
const adeCostCenter = 'adeCostCenter';
const currency = 'currency';
const adePriority = 'adePriority';
const adeMemo = 'adeMemo';

const defaultColumnOrder = [adeAgtCd,
  adeSeq,
  type,
  active,
  adeAmt,
  calcMethod,
  frequency,
  adeEffDt,
  adeLstProcDt,
  adeEndDt,
  agentReq,
  adeTransAgtCd,
  adePercent,
  adeDays,
  adeTargetAmt,
  adeCumulativeAmt,
  monTarget,
  adeMonthlyTargetAmt,
  adeMonthlyCumulativeAmt,
  splPayProc,
  adePctOfBal,
  adePctOfFyrComm,
  adePctOfRenComm,
  adePctOfFyrOvrde,
  adePctOfRenOvrde,
  adeCostCenter,
  currency,
  adePriority,
  adeMemo,
];
const getDefaltAndFilterConfig = (ParamOptions, listColumnOrder, locale) => {
  const defaultEntityFilterConfig = {
    locale: locale === 'en' ? 'E' : 'F',
    export: 'NONE',
    page: 1,
    pageSize: 10,
    lookUpInformation: [],
    listParamOptions: ParamOptions || [],
    sortOptions: [
      {
        key: 'adeSeq',
        asc: true,
      },
    ],
    listSortOptions: [],
    listColumnOrder: listColumnOrder || defaultColumnOrder,
    listGroupBy: [],
    selectedFilter: {},
    isDistinctActive: true,
  };
  const filterConfig = {
    SEARCH_ACTION: fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST,
    EXPORT_ACTION: fieldForceDeductionActionTypes.GET_FF_DEDUCTION_EXPORT_REQUEST,
    UPDATE_CONFIGURATION: fieldForceDeductionActionTypes.UPDATE_FF_COLUMN_CONFIG,
    UPDATE_TABLE_CONFIGURATION: fieldForceDeductionActionTypes.UPDATE_TABLE_CONFIGURATION,
    UPDATE_FILTER_CONFIG: fieldForceDeductionActionTypes.UPDATE_FILTER_CONFIG,
    REMOVE_USER_DEFAULT_FILTER: fieldForceDeductionActionTypes.REMOVE_USER_DEFAULT_FILTER,
  };
  return { defaultEntityFilterConfig, filterConfig };
};
export const setActiveExport = (setactivateExportButton, listData, setActivateDataGrid) => {
  setActivateDataGrid(true);
  if (listData.data.length > 0) {
    setactivateExportButton(true);
  } else {
    setactivateExportButton(false);
  }
};
const dispatchFunc = (dispatch, setClientAdddEditActBtn, setFormData) => {
  dispatch(getAction(fieldForceDeductionActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
  setClientAdddEditActBtn(true);
  setFormData(null);
};
const exportClick = (setactivateExportButton, listData, setActivateDataGrid) => {
  if (listData) {
    setActiveExport(setactivateExportButton, listData, setActivateDataGrid);
  }
};
export const setFormDataFunc = (getEditItemDetails, setFormData, setClientAdddEditActBtn, dispatch) => {
  if (getEditItemDetails?.adeType?.length > 0) {
    dispatch(setLock({ ...getEditItemDetails.comboLock, lockedBy: getEditItemDetails.comboLock.lockedBy }));
    setFormData(getEditItemDetails);
    setClientAdddEditActBtn(true);
  }
};
const id = 123;
const two = 2;
const hundread = 100;
const four = 4;
const zeroFormat = (cumtamt) => (cumtamt === 0 ? '0.00' : cumtamt);
export const adeTypeFunc = (formData) => (formData ? formData.adeType : '');
export const adeCurrFunc = (formData) => (formData ? formData.adeCurr : 'CA');
export const adeAmtCalcFunc = (formData) => (formData ? formData.adeAmtCalcMethod : '');
export const adeAmtFunc = (formData) => (formData ? formData.adeAmt?.toFixed(two) : null);
export const adePercentFunc = (formData) => (formData ? (formData.adePercent * hundread).toFixed(four) : null);
export const adeFreqFunc = (formData) => (formData ? formData.adeFreq : '');
export const adeTargetAmtFunc = (formData) => (formData ? formData.adeTargetAmt?.toFixed(two) : null);
export const adeCumulativeAmtFunc = (formData) => (formData ? zeroFormat(formData.adeCumulativeAmt) : '0.00');
export const adeMonthlyCumulativeAmtFunc = (formData) => (formData
  ? zeroFormat(formData.adeMonthlyCumulativeAmt) : '0.00');
export const adeCostCenterFunc = (formData) => (formData ? formData.adeCostCenter : '');
export const adeTransAgtCdFunc = (formData) => (formData ? formData.adeTransAgtCd : null);
const adeDedctActFunc = (formData) => (formData ? formData.adeDedctAct : 0);
const adeSpecialPayProcessFunc = (formData) => (formData ? formData.adeSpecialPayProcess : null);
const adePctOfFyrCommFunc = (formData) => (formData ? (formData.adePctOfFyrComm * hundread).toFixed(two) : null);
const adePctOfRenCommFunc = (formData) => (formData ? (formData.adePctOfRenComm * hundread).toFixed(two) : null);
const adePctOfFyrOvrdeFunc = (formData) => (formData ? (formData.adePctOfFyrOvrde * hundread).toFixed(two) : null);
const adePctOfRenOvrdeFunc = (formData) => (formData ? (formData.adePctOfRenOvrde * hundread).toFixed(two) : null);
const adePctOfBalFunc = (formData) => (formData ? (formData.adePctOfBal * hundread).toFixed(four) : null);
const adeMemoFunc = (formData) => (formData ? formData.adeMemo : null);
const comTypeFunc = (formData) => (formData ? formData.result.commtype : '');
const comSubTypeFunc = (formData) => (formData ? formData.result.comSubType : '');
const typeFunc = (formData) => (formData ? formData.result.sTransType : '');
const adeDaysFunc = (formData) => (formData ? formData.adeDays : null);
const adeBflagFunc = (formData) => (formData ? formData.bflag : 0);
const adeAdeseqFunc = (formData) => (formData ? formData.adeSeq : 0);
const flagTransferFunc = (formData) => {
  if (formData && formData.adeTransAgtCd) {
    return true;
  }

  return false;
};
const assignFormData = (formData) => ({
  adeType: adeTypeFunc(formData),
  adeCurr: adeCurrFunc(formData),
  adeAmtCalcMethod: adeAmtCalcFunc(formData),
  adeAmt: adeAmtFunc(formData),
  adePercent: adePercentFunc(formData),
  adeFreq: adeFreqFunc(formData),
  adeTargetAmt: adeTargetAmtFunc(formData),
  adeCumulativeAmt: adeCumulativeAmtFunc(formData),
  adeMonthlyCumulativeAmt: adeMonthlyCumulativeAmtFunc(formData),
  adeEffDt: formData ? getDateFormat(formData.adeEffDt, globalConstants.dateFormatMMDDYYYY) : null,
  adeCostCenter: adeCostCenterFunc(formData),
  adeTransAgtCd: adeTransAgtCdFunc(formData),
  adeDedctAct: adeDedctActFunc(formData),
  adeSpecialPayProcess: adeSpecialPayProcessFunc(formData),
  adePctOfFyrComm: adePctOfFyrCommFunc(formData),
  adePctOfRenComm: adePctOfRenCommFunc(formData),
  adePctOfFyrOvrde: adePctOfFyrOvrdeFunc(formData),
  adePctOfRenOvrde: adePctOfRenOvrdeFunc(formData),
  adePctOfBal: adePctOfBalFunc(formData),
  adeMemo: adeMemoFunc(formData),
  comType: comTypeFunc(formData),
  comSubType: comSubTypeFunc(formData),
  type: typeFunc(formData),
  adeEndDt: formData ? getDateFormat(formData.adeEndDt, globalConstants.dateFormatMMDDYYYY) : '',
  adeLstProcDt: formData ? getDateFormat(formData.adeLstProcDt, globalConstants.dateFormatMMDDYYYY) : null,
  adeDays: adeDaysFunc(formData),
  adeMonthlyTargetAmt: formData ? formData.adeMonthlyTargetAmt : null,
  adeId: formData ? formData.adeId : null,
  adeTransAgtName: formData ? formData.agentName : '',
  adePriority: formData ? formData.result.priority : null,
  AdeMonthlyTargetSw: formData ? formData.adeMonthlyTargetSw : 0,
  bflag: adeBflagFunc(formData),
  adeSeq: adeAdeseqFunc(formData),
  flagTransfer: flagTransferFunc(formData),
  scharge: null,
  adeAgtCd: formData?.adeAgtCd,
});

export const showServerMessage = (serverErrors, t) => {
  if (serverErrors && serverErrors.length > 0) {
    const batchMsg = serverErrors.some((item) => item.errorCode === 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT');
    const screenName = t(staticLabelKeys.CM_DE_TITLE_LIST);
    return (
      <div className={`${serverErrorMsgContainer} tabServerError`}>
        <FasatMessageCard
          titleText={batchMsg ? t(staticCommonLabelKeys.COMMON_LABEL_BATCH_PROCESSING)
            : t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
          variant={batchMsg ? 'primary_complete' : 'primary_alert'}
          hasCloseButton
        >
          {serverErrors.map((errItem) => (
            fetchServerError(t, errItem, screenName)
          ))}
        </FasatMessageCard>
      </div>
    );
  }
  return null;
};
export const handleOnBackClick = (history, location) => {
  history.push(
    { pathname: '/agency/fieldForceDetail',
      state: {
        orgCode: location.state?.orgCode,
        orgLevel: location.state?.orgLevel,
        detailPage: 'ffDetail' },
    },
  );
};
const clientAddEditUseEffectHelper = ({ clientAddEditAct, lockDetails, dispatch, history, configFormData, location, getEditItemDetails }) => {
  if (!clientAddEditAct && lockDetails) {
    dispatch(releaseLockCombo({
      ...userLock,
      lockedBy: lockDetails.lockedBy,
      recordKey: lockDetails.recordKey,
      entityKey: lockDetails.entityKey,
      action: 'edit',
    }));
  }
  if (clientAddEditAct) {
    history.push({
      pathname: 'fieldForceDeduction/add',
      state: {
        formData: configFormData,
        deductionOrgCode: configFormData.adeAgtCd || location.state?.orgCode,
        orgCode: location.state?.orgCode,
        orgLevel: location.state?.orgLevel,
        action: !getEditItemDetails,
        path: 'editCompCal',
      },
    });
  }
};
const getReqObjForPageSizeChange = ({ sortOptions, reqObject, currentPageSize, listData, reqObjForId, groupByParameters, isDistinct, listColumnOrder }) => {
  const updatedReqObj = { ...reqObject };
  updatedReqObj.pageSize = currentPageSize;
  updatedReqObj.page = listData.page;
  updatedReqObj.parameters = reqObjForId.parameters;
  updatedReqObj.groupByParameters = groupByParameters;
  updatedReqObj.isDistinct = isDistinct;
  updatedReqObj.columnOrders = listColumnOrder;
  updatedReqObj.sortOptions = sortOptions;

  return updatedReqObj;
};
const FieldForceDeductionLand = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const locale = getCurrentLocale();
  const dispatch = useDispatch();
  const history = useHistory();
  const transId = location.state !== undefined ? location.state.orgCode : '';
  const { listData, listParamOptions } = useSelector((state) => state.FFDeduction);
  const listRequestError = useSelector((state) => state.FieldForce.listRequestError);
  const upsertRequestError = useSelector((state) => state?.DeductionInquiryFilterList?.serverRequestError);
  const groupByParameters = useSelector((state) => state.FFDeduction?.listGroupBy);
  const isDistinct = useSelector((state) => state.FFDeduction?.isDistinctActive);
  const listColumnOrder = useSelector((state) => state.FFDeduction?.listColumnOrder);
  const sortOptions = useSelector((state) => state.FFDeduction?.listSortOptions);
  const parameters = useSelector((state) => state.FFDeduction.listParamOptions);
  const lockDetails = useSelector((state) => state?.ApplicationState?.lockDetails);
  const getEditItemDetails = useSelector((state) => state.FieldForce.detailDataById);
  const [clientAddEditAct, setClientAdddEditActBtn] = useState(false);
  const [activateExportButton, setactivateExportButton] = useState(true);
  const { currentPage, totalRows, rowCount, GridPageSize } = getTableData({ listData });
  // activateDataGrid is not getting used but setActivateDataGrid called multiple time
  const setActivateDataGrid = () => false;
  const [formData, setFormData] = useState(null);
  const handleAddActionBtn = () => dispatchFunc(dispatch, setClientAdddEditActBtn, setFormData);
  const [serverErrors, setServerErrors] = useState([]);
  useEffect(() => exportClick(setactivateExportButton, listData, setActivateDataGrid), [listData]);
  useEffect(() => {
    setFormDataFunc(getEditItemDetails, setFormData, setClientAdddEditActBtn, dispatch);
  }, [getEditItemDetails]);
  const configFormData = assignFormData(formData);
  useEffect(() => {
    const args = { clientAddEditAct, lockDetails, dispatch, history, configFormData, location, getEditItemDetails };
    clientAddEditUseEffectHelper(args);
  }, [clientAddEditAct]);
  const paginationInfo = { currentPage, totalRows, rowCount, pageSize: GridPageSize };
  const reqObjForId = initialReqObject({ locale, sortOptions, parameters });
  reqObjForId.id = id;
  const reqObject = handleReqObj(transId, locale);
  useEffect(() => {
    if (locale && reqObject && listData) {
      const args = { sortOptions, reqObject, currentPageSize: reqObject.pageSize, listData, reqObjForId, groupByParameters, isDistinct, listColumnOrder };
      const updatedReqObj = getReqObjForPageSizeChange({ ...args });
      dispatch(getAction(fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST, updatedReqObj));
    }
  }, [locale]);
  const [pageSize, setGridPageSize] = useState(GridPageSize);
  const onChangePageSize = (currentPageSize) => {
    setGridPageSize(currentPageSize);
    const args = { sortOptions, reqObject, currentPageSize, listData, reqObjForId, groupByParameters, isDistinct, listColumnOrder };
    const updatedReqObj = getReqObjForPageSizeChange({ ...args });
    dispatch(getAction(fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST, updatedReqObj));
    pageChngRequestObj({ reqObj: updatedReqObj, currentPageSize });
  };
  const onPageChangeHandler = (currPage) => {
    reqObject.page = currPage;
    reqObject.pageSize = pageSize;
    reqObject.parameters = parameters;
    reqObject.groupByParameters = groupByParameters;
    reqObject.isDistinct = isDistinct;
    reqObject.columnOrders = listColumnOrder;
    reqObject.sortOptions = sortOptions;
    dispatch(getAction(fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST, reqObject));
  };
  useEffect(() => dispatch(getAction(fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST, reqObject)),
    [clientAddEditAct]);
  useEffect(() => {
    setServerErrors(upsertRequestError || listRequestError);
  }, [upsertRequestError, listRequestError]);
  const { defaultEntityFilterConfig, filterConfig } = getDefaltAndFilterConfig(listParamOptions, listColumnOrder, locale);
  const gb1 = { currentPage, onChangePageSize, onPageChangeHandler, totalRows, activateExportButton, dispatch, paginationInfo };
  const gb2 = { addBtnAriaLabel: t(staticLabelKeys.ARIA_DE_BUTTON_ADD), exportAction: true, entityId: 38 };
  const gb3 = { exportAclKey: aclKeysConstant.RBAC_DED_EXPORT, addAclKey: aclKeysConstant.RBAC_DED_ADD, showPageSize: true };
  const gb4 = { pageSize, generateAddEditSection: handleAddActionBtn };
  const gb5 = { filterConfig, defaultEntityFilterConfig };
  const FASAT_GRID_BAR = { ...gb1, ...gb2, ...gb3, ...gb5, ...gb4, addAction: true };
  return (
    <FasatPage title={!clientAddEditAct && t(staticLabelKeys.FF_LABEL_DEDUCTION)} onClickBack={() => handleOnBackClick(history, location)}>
      <div className="FieldForceDeductionLand">
        {showServerMessage(serverErrors, t)}
        {!clientAddEditAct && (
          <>
            <FieldForceDeductionList
              listData={listData}
              setClientAdddEditActBtn={setClientAdddEditActBtn}
              reqObj={reqObject}
              onPageChangeHandler={onPageChangeHandler}
              fasatGrid={FASAT_GRID_BAR}
            />
          </>
        )}
        <FasatToastContainer />
      </div>
    </FasatPage>
  );
};
FieldForceDeductionLand.propTypes = {
  reqObj: PropTypes.shape({}),
};
FieldForceDeductionLand.defaultProps = {
  reqObj: {},
};
export default FieldForceDeductionLand;
