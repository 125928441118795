/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import downArrow from './assets/images/down.svg';
import upArrow from './assets/images/up.svg';
import close from './assets/images/close.svg';
import closeDisabled from './assets/images/close_disabled.svg';
import search from './assets/images/searchIcon.svg';
import {
  Container,
  Button,
  SearchSection,
  SearchBox,
  MultiSelect,
  Label,
  Input,
  ButtonRemove,
  DropdownLabel,
  SelectWrapper,
  DropdownCheckbox,
  SearchResultDisplay,
} from './styleComponent';
import { staticCommonLabelKeys } from '../../../moduleConstants';
import logger from '../../../util/appLogger';

const ALL_SELECTED = 'All selected';
let option = -1;
const AdvancedDropdownComponent = 'AdvancedDropdownComponent';
const sortedOptionTray = 'sortedOptionTray';
const error = 'error';
const selectOpen = 'selectOpen';
const selectClose = 'selectClose';
const selectedOption = 'selectedOption';
const closeSelection = 'closeSelection';
const All = 'All';
const upDownArrow = 'upDownArrow';
const searchKeyId = 'searchKeyId';
const searchIcon = 'searchIcon';
const removeSelection = 'remove selection';
const fMarginTop0 = 'f-margin-t-0';
const optionText = 'option';
const errorText = 'errorText';
const noErrorText = 'noErrorText';
const block = 'block';
const none = 'none';
const searchPlaceholder = 'Search';
const dropdownPlaceholder = 'Select';
const selectPlaceholderTextClass = 'selectPlaceholderText';
const caseNoTwentySeven = 27;
const caseNoThirteen = 13;
const caseNoThirtyTwo = 32;
const caseNoThirtySeven = 37;
const caseNoThirtyEight = 38;
const caseNoThirtyNine = 39;
const caseNoForty = 40;

const searchResultLabel = staticCommonLabelKeys.ARIA_COMMON_DROPDOWN_SEARCH_RESULT;

/**
 * check and set show hide class
 * @param {bool} open
 * @returns string
 */
const validateDisplay = (open) => (open ? block : none);
/**
 * set Palceholder Class
 * @param {object} optSlt
 * @param {string} sltPlcCls
 * @returns string
 */
const slcPlcHldCls = (optSlt, sltPlcCls) => (optSlt.length === 0 && sltPlcCls);
/**
 * set Palceholder Class for multi select
 * @param {bool} srcEnb
 * @param {string} fMarT0
 * @param {bool} srcKy
 * @param {string} srtOptTry
 * @returns string
 */
export const setMtSelCls = (srcEnb, fMarT0, srcKy, srtOptTry) => (!srcEnb ? fMarT0 : !!srcKy && srtOptTry);
/**
 * set Up and Down Arrow
 * @param {bool} open
 * @param {string} upArw
 * @param {string} downArw
 * @returns string
 */
const setUpDwnImg = (open, upArw, downArw) => (open ? upArw : downArw);
/**
 * Set Error Class
 * @param {object} errors
 * @returns string
 */
export const setErCls = (errors) => (errors && errors.length && errors[0].isError > 0 ? error : '');

/**
 * function for collapsing option tray in dropdown
 */
const collapseOptionsTray = (setOpen) => {
  option = -1;
  setOpen(false);
};

/**
 * function to remove all selected options
 */
const removeAll = (event, disabled, setItemSelected,
  setOptionSelected, onChange) => {
  if (!disabled) {
    event.stopPropagation();
    setItemSelected('');
    setOptionSelected([]);
    onChange('');
  }
  return false;
};

/**
 * function to check whether option is selected or not
 */
const isSelected = (selValue, itemSelected) => {
  const res = itemSelected.split(',');
  if (res[0].toString() === 'all') {
    return true;
  }
  const staticObjFilter = res.filter((item) => item === selValue && selValue.toString());
  return staticObjFilter.length > 0 || false;
};

/**
 * function to display selected option(s)
 */
export const displaySelecttion = (isMultiselect, dropdownItems, optionSelected, SELECTED) => {
  if (isMultiselect && dropdownItems.length > 0) {
    if (optionSelected[0] === undefined) {
      optionSelected.splice(0, 1);
    }
    if (optionSelected[0]) {
      if (optionSelected[0].value.toString() === 'all') {
        return ALL_SELECTED;
      }
      return `${optionSelected.length} ${SELECTED}`;
    }
    return false;
  }
  if (!isMultiselect) {
    return optionSelected[0] && optionSelected[0].label.toString();
  }
  return false;
};

const caseNoFortyMethod = (dropdownItems, open, id) => {
  if (option < dropdownItems.length - 1 && open) {
    option += 1;
  }
  if (option > -1 && option < dropdownItems.length && open) {
    document.getElementById(`${id}-option-${option}`).focus();
  }
};
const caseNoTwentySevenMethod = (open, id, setOpen) => {
  if (open) {
    document.querySelectorAll(`.${id}-button`).focus();
    collapseOptionsTray(setOpen);
  }
};
const caseNoThirtyTwoMethod = (id) => {
  if (option > -1) {
    document.getElementById(`${id}-option-${option}`).click();
  }
};
const caseNoThirtyEightMethod = (id, buttonRef) => {
  if (option !== -1) {
    option -= 1;
  }
  if (option > -1) {
    document.getElementById(`${id}-option-${option}`).focus();
  } else {
    buttonRef.current.focus();
  }
};
const displayRemoveSelectionImg = (disabled, setItemSelected,
  setOptionSelected, onChange) => (
    <img
      className={closeSelection}
      alt={removeSelection}
      role="presentation"
      src={disabled ? closeDisabled : close}
      onClick={(event) => removeAll(event, disabled, setItemSelected,
        setOptionSelected, onChange)}
    />
);

const displaySearchBox = (handleKeyUp, searchPlaceholderText, searchKey,
  setSearchKey, searchAriaLabel) => (
    <SearchBox
      onKeyUp={(e) => handleKeyUp(e)}
      placeholder={searchPlaceholderText}
      id={searchKeyId}
      value={searchKey}
      onChange={(e) => setSearchKey(e.target.value)}
      aria-label={searchAriaLabel}
    />
);

const displayButtonRemove = (setSearchKey) => (
  <ButtonRemove
    type="button"
    onClick={() => setSearchKey('')}
    onKeyDown={() => setSearchKey('')}
  >
    <img alt={removeSelection} src={close} />
  </ButtonRemove>
);

const displayMultiselectOption = (id, idx, el, filterType, itemSelected, handleKeyUp) => (
  <DropdownCheckbox>
    <Input
      type="checkbox"
      className={`option-chk-${idx}`}
      name={optionText}
      id={`${id}-option-${idx}`}
      value={el.value}
      onChange={(event) => filterType(event, el)}
      checked={isSelected(el.value, itemSelected)}
      onKeyUp={(e) => handleKeyUp(e)}
    />
    <label htmlFor={`${id}-option-${idx}`}>{el.label}</label>
  </DropdownCheckbox>
);

const displayErrorParagraph = (errors) => (
  <p className={errors[0].isError ? errorText : noErrorText}>
    {errors[0].msg}
  </p>
);
const handleUpFunc = ({
  buttonRef,
  dropdownItems,
  open,
  id,
  setOpen,
  keyCode,
}) => {
  switch (keyCode) {
    case caseNoTwentySeven: // esc
      caseNoTwentySevenMethod(open, id, setOpen);
      break;
    case caseNoThirteen: // enter
    case caseNoThirtyTwo: // space
      caseNoThirtyTwoMethod(id);
      break;
    case caseNoThirtySeven: // left arrow
      setOpen(true);
      break;
    case caseNoThirtyEight: // up arrow
      caseNoThirtyEightMethod(id, buttonRef);
      break;
    case caseNoThirtyNine: // right arrow
      collapseOptionsTray(setOpen);
      break;
    case caseNoForty: // down arrow
      caseNoFortyMethod(dropdownItems, open, id);
      break;
    default:
  }
};

const fetchButtonClass = (open, id) => (
  open ? `${id}-button ${selectOpen}` : `${id}-button ${selectClose}`
);

const setItemVal = (value) => (value || '');

const hasErrorLength = (errors) => (errors && errors.length > 0);

const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
  if (relatedTarget === null) {
    return false;
  }
  let node = relatedTarget.parentNode;
  while (node !== null) {
    if (node === currentTarget) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};
const multiSelect = ({ setOptionSelected, setItemSelected }) => {
  setOptionSelected([]);
  setItemSelected('');
};
const operatorInit = ({ operator, setOptionSelected, setItemSelected }) => {
  if (operator) {
    setOptionSelected([]);
    setItemSelected('');
  }
};
const initFunc = ({ masterDisplayList, value, dropdownItems, setOptionSelected, setItemSelected }) => {
  if (masterDisplayList) {
    if (value) {
      const itemArray = value.split(',');
      const preSelectedOption = [];
      itemArray.forEach((item) => {
        preSelectedOption.push(
          dropdownItems.filter((listItem) => listItem.value.toString() === item.toString())[0],
        );
      });
      setOptionSelected(preSelectedOption.length > 0 ? preSelectedOption : []);
      setItemSelected(value);
    } else {
      setOptionSelected([]);
      setItemSelected('');
    }
  }
};
const setSearchKeyFunc = ({ open, onChange, itemSelected, searchEnabled, setSearchKey }) => {
  if (!open) {
    logger.info(`itemSelected: ${itemSelected}`);
    onChange(itemSelected);
  } else {
    document.getElementById('multiSeltID').scrollTop = 0;
    if (searchEnabled) {
      setSearchKey('');
    }
  }
};
/**
   * function to filter options in option tray
   */
const setFilterType = ({ event,
  element, settingIndividualItem, optionSelected, setItemSelected, setOptionSelected, dropdownItems }) => {
  event.stopPropagation();
  if (element) {
    if (element.value.toString() !== 'all') {
      settingIndividualItem(element);
    } else {
      const isAll = optionSelected.find(
        (item) => item.value.toString() === element.value.toString(),
      );
      if (isAll) {
        setItemSelected('');
        setOptionSelected([]);
      } else {
        setItemSelected(dropdownItems[0].value.toString());
        setOptionSelected([{ value: 0, label: All }]);
      }
    }
  } else {
    setItemSelected('');
    setOptionSelected([]);
  }
};
const individualItemfunc = ({ itemSelected, element, setItemForAllSelection, setItemForIndividualSelection }) => {
  const res = itemSelected.split(',');
  const isAllSelected = res.filter((item) => item.toString() === 'all');
  if (isAllSelected.length > 0) {
    setItemForAllSelection(element);
  } else {
    setItemForIndividualSelection(element, res);
  }
};
const individualSelectFunc = ({ res, element, optionSelected, setOptionSelected,
  setItemSelected, isMultiselect, itemSelected, onChange }) => {
  let selectedString = '';
  let labelString = '';
  const staticObjFilter = res.filter(
    (item) => item.toString() === element.value.toString(),
  );
  if (staticObjFilter.length > 0) {
    const objFilter = res.filter((item) => item.toString() !== element.value.toString());
    labelString = optionSelected.filter(
      (item) => item.value.toString() !== element.value.toString(),
    );
    if (objFilter.length > 0) {
      objFilter.map((item) => {
        selectedString = !selectedString ? `${item}` : `${selectedString},${item}`;
        return item;
      });
      setOptionSelected(labelString);
    } else {
      setItemSelected('');
      setOptionSelected([]);
    }
    if (selectedString.charAt(0) === ',') {
      selectedString = selectedString.substring(1);
    }
    setItemSelected(selectedString);
  } else if (isMultiselect) {
    selectedString = `${itemSelected},${element.value}`;
    if (selectedString.charAt(0) === ',') {
      selectedString = selectedString.substring(1);
    }
    setOptionSelected([...optionSelected, element]);
    setItemSelected(selectedString);
  } else {
    selectedString = `${element.value}`;
    setOptionSelected([element]);
    setItemSelected(selectedString);
    onChange(selectedString);
  }
};
const selectedForAll = ({ element, selectedString, dropdownItems, setOptionSelected, setItemSelected }) => {
  let selString = '';
  const objFilterExceptAll = dropdownItems.filter(
    (item) => item.value.toString() !== element.value.toString() && item.value.toString() !== 'all',
  );
  objFilterExceptAll.map((item) => {
    selString = !selectedString ? `${item.value}` : `${selectedString},${item.value}`;
    return item;
  });
  setOptionSelected(objFilterExceptAll);
  setItemSelected(selString);
};
const funBlur = ({ setOpen, e }) => {
  if (!focusInCurrentTarget(e)) {
    collapseOptionsTray(setOpen);
  }
};
const getButtonFunc = ({ buttonRef,
  id,
  handleKeyUp,
  disabled,
  open,
  toggleMultiSelect,
  optionSelected,
  isMultiselect,
  setOptionSelected,
  setItemSelected,
  dropdownItems,
  SELECTED,
  onChange,
  placeholder,
}) => (
  <Button
    ref={buttonRef}
    id={id}
    onKeyUp={(e) => handleKeyUp(e)}
    disabled={disabled}
    className={fetchButtonClass(open, id)}
    onClick={toggleMultiSelect}
    aria-haspopup="true"
    aria-expanded={open}
    aria-labelledby={`aria-labelledby-${id} ${id}`}
  >
    {optionSelected.length > 0 && (
    <p>
      <span
        className={selectedOption}
        aria-label={displaySelecttion(isMultiselect, dropdownItems, optionSelected, SELECTED)}
      >
        {displaySelecttion(isMultiselect, dropdownItems, optionSelected, SELECTED)}
      </span>
      {displayRemoveSelectionImg(disabled, setItemSelected, setOptionSelected, onChange)}
    </p>
    )}
    <span className={slcPlcHldCls(optionSelected, selectPlaceholderTextClass)}>
      {optionSelected.length === 0 && placeholder}
    </span>
    <img className={upDownArrow} src={setUpDwnImg(open, upArrow, downArrow)} alt="" />
  </Button>
);
const dropFunc = ({ isMultiselect, dropdownItems, allOption, setMasterDisplayList, setDisplayList }) => {
  if (isMultiselect && dropdownItems && allOption && dropdownItems[0].label !== 'All') {
    dropdownItems.unshift({ value: 'all', label: All });
  }
  const copyList = dropdownItems;
  setMasterDisplayList(copyList);
  setDisplayList(copyList);
};
const getSearch = ({ handleKeyUp, searchPlaceholderText, searchKey, setSearchKey, searchAriaLabel }) => (
  <SearchSection>
    {displaySearchBox(handleKeyUp, searchPlaceholderText, searchKey,
      setSearchKey, searchAriaLabel)}
    {!searchKey && <img src={search} alt={searchIcon} />}
    {!!searchKey && (
      displayButtonRemove(setSearchKey)
    )}
  </SearchSection>
);

const AdvancedDropdown = ({ dropdownItems, value, searchEnabled, disabled, errors, id, placeholder, isMultiselect,
  searchPlaceholderText, label, onChange, allOption, operator, searchAriaLabel,
}) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(setItemVal(value));
  const [searchKey, setSearchKey] = useState('');
  const [optionSelected, setOptionSelected] = useState([]);
  const [displayList, setDisplayList] = useState('');
  const [masterDisplayList, setMasterDisplayList] = useState('');
  const toggleMultiSelect = () => setOpen(!open);
  const buttonRef = useRef(0);
  const wrapperRef = useRef(null);
  const SELECTED = t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECTED);
  useEffect(() => multiSelect({ setOptionSelected, setItemSelected }), [isMultiselect]);
  useEffect(() => operatorInit({ operator, setOptionSelected, setItemSelected }), [operator]);
  useEffect(() => initFunc({ masterDisplayList, value, dropdownItems, setOptionSelected, setItemSelected }), [masterDisplayList, value]);
  useEffect(() => setSearchKeyFunc({ open, onChange, itemSelected, searchEnabled, setSearchKey }), [open]);
  useEffect(() => {
    dropFunc({ isMultiselect, dropdownItems, allOption, setMasterDisplayList, setDisplayList });
  }, [dropdownItems, isMultiselect, value]);
  useEffect(() => {
    if (dropdownItems && searchKey) {
      const filterList = dropdownItems.filter(
        (item) => item.label.substr(0, searchKey.length).toUpperCase() === searchKey.toUpperCase(),
      );
      setDisplayList(filterList);
    } else {
      setDisplayList(dropdownItems);
    }
  }, [searchKey]);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          collapseOptionsTray(setOpen);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keyup', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keyup', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);
  const setItemForAllSelection = (element, selectedString) => selectedForAll({ element, selectedString, dropdownItems, setOptionSelected, setItemSelected });
  const setItemForIndividualSelection = (element, res) => {
    const inPara = { res, element, optionSelected, setOptionSelected, setItemSelected, isMultiselect };
    const inPara2 = { itemSelected, onChange, ...inPara };
    individualSelectFunc({ ...inPara2 });
  };
  const settingIndividualItem = (element) => individualItemfunc({ element, itemSelected, setItemForAllSelection, setItemForIndividualSelection });
  const filterType = (event, element) => {
    const params = { event, element, optionSelected };
    setFilterType({ settingIndividualItem, setItemSelected, setOptionSelected, dropdownItems, ...params });
  };
  const handleKeyUp = ({ keyCode }) => handleUpFunc({ buttonRef, dropdownItems, open, id, setOpen, keyCode });
  const opsn1 = { buttonRef, id, handleKeyUp, disabled, open, toggleMultiSelect, optionSelected, isMultiselect };
  const opsn2 = { setOptionSelected, setItemSelected, dropdownItems, SELECTED, onChange, placeholder, ...opsn1 };
  return (
    <Container id={`container-${id}`} data-testid={AdvancedDropdownComponent} className={setErCls(errors)} ref={wrapperRef}>
      {label && <DropdownLabel id={`aria-labelledby-${id}`} htmlFor={id}>{label}</DropdownLabel>}
      {getButtonFunc({ ...opsn2 })}
      <SelectWrapper display={validateDisplay(open)}>
        {searchEnabled && getSearch({ handleKeyUp, searchPlaceholderText, searchKey, setSearchKey, searchAriaLabel })}
        {searchEnabled && (
        <SearchResultDisplay aria-live="polite">{t(searchResultLabel, { record: displayList.length })}</SearchResultDisplay>
        )}
        <MultiSelect className={setMtSelCls(searchEnabled, fMarginTop0, searchKey, sortedOptionTray)} id="multiSeltID">
          {displayList && displayList.map((el, idx) => (
            <li
              key={el.value}
              className={`${isSelected(el.value, itemSelected) && !isMultiselect && SELECTED}`}
              id={`${id}-option-div-${idx}`}
              role="option"
              aria-selected={isSelected(el.value, itemSelected)}
              onKeyUp={(e) => handleKeyUp(e)}
            >
              {isMultiselect && displayMultiselectOption(id, idx, el, filterType, itemSelected, handleKeyUp)}
              {!isMultiselect && (
                <Label
                  className={`${isSelected(el.value, itemSelected) && !isMultiselect && SELECTED}`}
                  onClick={!isMultiselect ? (event) => filterType(event, el) : () => false}
                  tabIndex={0}
                  id={`${id}-option-${idx}`}
                  onKeyUp={(e) => handleKeyUp(e)}
                >
                  {el.label}
                </Label>
              )}
            </li>
          ))}
        </MultiSelect>
      </SelectWrapper>
      {hasErrorLength(errors) && (displayErrorParagraph(errors))}
    </Container>
  );
};

AdvancedDropdown.propTypes = {
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]),
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  searchEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  allOption: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      msg: PropTypes.number,
      isError: PropTypes.bool,
    }),
  ),
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isMultiselect: PropTypes.bool,
  searchPlaceholderText: PropTypes.string,
  label: PropTypes.string,
  // hideLabel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  // onBlur: PropTypes.func.isRequired,
  searchAriaLabel: PropTypes.string,
};

AdvancedDropdown.defaultProps = {
  dropdownItems: [],
  value: '',
  allOption: false,
  searchEnabled: false,
  disabled: false,
  errors: [],
  placeholder: dropdownPlaceholder,
  isMultiselect: null,
  searchPlaceholderText: searchPlaceholder,
  label: null,
  // hideLabel: true,
  searchAriaLabel: '',
};

export default AdvancedDropdown;
