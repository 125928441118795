/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/**
 * @Description This is fasatRBAC component page.
 * This is a wrapper on any  Fasat component where RBAC logic has to be implemented
 * @FileName fasatRBAC.js
 * @Author Abhisek Kundu- kundabh
 * @CreatedOn 21 May, 2021 11:57:00
 * @IssueID CDTFASATMOD- <>
 */
import React from 'react';
import './fasatRBAC.scss';

const FasatRBAC = (props) => {
  const { aclKey, userClaim, children } = props;
  // depending on acl key implement rendering logic
  if (!aclKey || aclKey === null || aclKey === '') {
    return <>{children}</>;
  }
  const divStyle = 'divStyle';
  // eslint-disable-next-line no-debugger
  const aclObject = userClaim?.access.find((i) => i.resource === aclKey);
  if (aclObject) {
    if (aclObject.permission === 'ENABLED') {
      return <>{children}</>;
    }
    return <div className={divStyle}>{children}</div>;
  }
  return null;
};

export default FasatRBAC;
