/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { configureLettersActionType } from '../actions/actionTypes';

import { formatResponse, exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

// Configure Letters

/**
   * Get Configure Letters List
   * @param {any} action
   */
export function* getCLList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(configureLettersActionType.RESET_SERVER_VALUE, null)),
    put(getAction(configureLettersActionType.LOG_CL_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_CL_LIST, action.data);
    yield all([
      put(getAction(configureLettersActionType.GET_CL_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(configureLettersActionType.GET_CL_LIST_ERROR, err)),
      put(getAction(configureLettersActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * Get ConfigureLetters by id
   * @param {object} action
   */
export function* getCLbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(configureLettersActionType.RESET_SERVER_VALUE, null)),
    put(getAction(configureLettersActionType.LOG_CL_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_CL_BY_ID, action.data);
    yield all([
      put(getAction(configureLettersActionType.GET_CL_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(configureLettersActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(configureLettersActionType.GET_CL_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will insert for Configure Letters
   */
export function* insertCL(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_CL_INSERT,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(configureLettersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(configureLettersActionType.GET_CL_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(configureLettersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will update existing Configure Letters
   */
export function* updateCL(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_CL_UPDATE,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(configureLettersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(configureLettersActionType.GET_CL_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(configureLettersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will delete existing Configure Letters
   */
export function* deleteCL(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_CL_DELETE,
      action.data.formatObject,
    );
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(configureLettersActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(configureLettersActionType.GET_CL_LIST_REQUEST, action.data.reqObject)),
      put(getAction(configureLettersActionType.RESET_UPSERT_ERROR, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(configureLettersActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(configureLettersActionType.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getLookUpsDataCL(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_CL_LOOKUP_REQUEST, action.data);
    yield all([
      put(getAction(configureLettersActionType.RESET_CL_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(configureLettersActionType.RESET_CL_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for Configure Letters Export Listing
  */
export function* getCLExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(configureLettersActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_CL_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(configureLettersActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* validateCLFileName(action) {
  yield all([
    put(processingStarted()),
    put(getAction(configureLettersActionType.RESET_SERVER_VALUE, null)),
    put(getAction(configureLettersActionType.LOG_CL_LIST_REQUEST, action.data)),
    put(getAction(configureLettersActionType.VALIDATE_CL_FILENAME_RESPONSE, undefined)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.VALIDATE_CL_FILENAME,
      action.data.filename,
    );
    yield all([
      put(getAction(configureLettersActionType.VALIDATE_CL_FILENAME_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(configureLettersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  // Configure Letters
  yield takeEvery(configureLettersActionType.GET_CL_LIST_REQUEST, getCLList);
  yield takeEvery(configureLettersActionType.GET_CL_BY_ID_REQUEST, getCLbyID);
  yield takeEvery(configureLettersActionType.INSERT_CL_REQUEST, insertCL);
  yield takeEvery(configureLettersActionType.UPDATE_CL_REQUEST, updateCL);
  yield takeEvery(configureLettersActionType.DELETE_CL_REQUEST, deleteCL);
  yield takeEvery(configureLettersActionType.GET_CL_EXPORT_REQUEST, getCLExportList);
  yield takeEvery(configureLettersActionType.GET_CL_LOOKUP_REQUEST, getLookUpsDataCL);
  yield takeEvery(configureLettersActionType.VALIDATE_CL_FILENAME_REQUEST, validateCLFileName);
}
export default moduleWatcher;
