/* eslint-disable no-debugger */
/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { bcrActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'BusinessCardRuleFilterList';

const updateFilterConfig = (state, action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        ...state,
        selectedFilter,
        systemDefaultFilter: action.data.systemDefault || null,
        userDefaultFilter: action.data.userDefault || null,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        ...state,
        userDefaultFilter: action.data.userDefault,
        selectedFilter,
      };
    }
  }
  return {
    ...state,
    selectedFilter,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
});

const logBcrListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  loading: true,
  listRequestedOn: new Date(),
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const BusinessCardRuleFilterListPartA = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case bcrActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case bcrActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case bcrActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case bcrActionTypes.LOG_BCR_LIST_REQUEST:
      return logBcrListRequest(state, action);
    case bcrActionTypes.GET_BCR_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case bcrActionTypes.GET_BCR_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case bcrActionTypes.GET_BCR_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

const BusinessCardRuleFilterListPartB = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case bcrActionTypes.RESET_BCR_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case bcrActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const BusinessCardRuleFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = BusinessCardRuleFilterListPartA(state, action);
  const stateReqRes = BusinessCardRuleFilterListPartB(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default BusinessCardRuleFilterList;
