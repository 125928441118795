/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  genEditActionTypes,
  genEditValActionTypes,
  genEditRelationshipActionType,
  genEditValParentActionTypes,
  genEditRelationshipValActionType,
} from '../actions/actionTypes';

import { formatResponse, exportErrorMsg, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

// Gen Edit Type
/**
   * This generator function will invoke the api
   * @param {object} action - action which will Get list for Gen Edit Type
   */
export function* getGetList(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(genEditActionTypes.LOG_GET_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GET_LIST, action.data);
    yield all([
      put(getAction(genEditActionTypes.GET_GET_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditActionTypes.GET_GET_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will Get list for Gen Edit Type by id
   */
export function* getGetbyID(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type by id : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(genEditActionTypes.LOG_GET_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GET_BY_ID, action.data);
    yield all([
      put(getAction(genEditActionTypes.GET_GET_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditActionTypes.GET_GET_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

// hasRelation
/**
   * This generator function will invoke the api
   * @param {object} action - action which check relationship for new Gen Edit Type
   */
export function* getRelationship(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditActionTypes.RESET_SERVER_VALUE, null)),
    // put(getAction(genEditActionTypes.GET_DEPENDENCY_EXIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const adminRelGel = moduleAPI.ADMINISTRATION_GET_REL.endpoint + action.data;
    moduleAPI.ADMINISTRATION_GET_REL.endpoint = adminRelGel;
    Logger.verbose(`API data : ${adminRelGel}`);
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GET_REL, action.data);
    Logger.verbose(`API data Response : ${responseData}`);
    yield all([
      put(getAction(genEditActionTypes.GET_DEPENDENCY_EXIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditActionTypes.GET_DEPENDENCY_EXIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
   * @param {object} action - action which will insert for new Gen Edit Type
   */
export function* insertGet(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GET_INSERT,
      action.data.getUpSertObj.addEditData,
    );
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(genEditActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(genEditActionTypes.GET_GET_LIST_REQUEST, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err, action.data.genErrMsg),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(genEditActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will update for existing Gen Edit Type
   */
export function* updateGet(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GET_UPDATE,
      action.data.getUpSertObj.addEditData,
    );
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(genEditActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(genEditActionTypes.GET_GET_LIST_REQUEST, action.data.reqObject));
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err, action.data.genErrMsg),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(genEditActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will delete existing Gen Edit Type
   */
export function* deleteGet(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GET_DELETE,
      action.data.formatObject,
    );
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(genEditActionTypes.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(getAction(genEditActionTypes.GET_GET_LIST_REQUEST, action.data.reqObject));
    }
    yield all([
      // put(getAction(genEditActionTypes.GET_GET_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(genEditActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(genEditActionTypes.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * Gen Edit Value
   */
/**
   * This generator function will invoke the api
   * @param {object} action - action which will get list Gen Edit Value
   */
export function* getGevList(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Value List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditValActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(genEditValActionTypes.LOG_GEV_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GEV_LIST, action.data);
    yield all([
      put(getAction(genEditValActionTypes.GET_GEV_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    //  put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditValActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditValActionTypes.GET_GEV_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will get list Gen Edit Value by id
   */
export function* getGevbyID(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Value by id : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditValActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(genEditValActionTypes.LOG_GEV_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GEV_BY_ID, action.data);
    yield all([
      put(getAction(genEditValActionTypes.GET_GEV_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    //  put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditValActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditValActionTypes.GET_GEV_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will insert for new Gen Edit Value
   */
export function* insertGev(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GEV_INSERT,
      action.data.getUpSertObj.addEditData,
    );
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(genEditValActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(genEditValActionTypes.GET_GEV_LIST_REQUEST, action.data.reqObject));
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err, action.data.genErrMsg),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(genEditValActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
  // * This generator function will invoke the api
  // * @param {object} action - action which will update for existing Gen Edit Value
  // */
export function* updateGev(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GEV_UPDATE,
      action.data.getUpSertObj.addEditData,
    );
    if (responseData) {
      resetUpsertValue = {
        uupsertRequest: null,
        upsertRequestProcessedOn: new Date(),
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertData: responseData,
      };
      yield put(getAction(genEditValActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(genEditValActionTypes.GET_GEV_LIST_REQUEST, action.data.reqObject));
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequestedOn: null,
      upsertRequest: null,
      upsertRequestError: processError(err, action.data.genErrMsg),
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(genEditValActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
// /**
// * This generator function will invoke the api
// * @param {object} action - action which will delete existing Gen Edit Value
// */
export function* deleteGev(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GEV_DELETE,
      action.data.formatObject,
    );
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(genEditValActionTypes.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(getAction(genEditValActionTypes.GET_GEV_LIST_REQUEST, action.data.reqObject));
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(genEditActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(genEditActionTypes.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getGertList(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditRelationshipActionType.RESET_SERVER_VALUE, null)),
    put(getAction(genEditRelationshipActionType.LOG_GER_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GER_LIST, action.data);
    yield all([
      put(getAction(genEditRelationshipActionType.GET_GER_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditRelationshipActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditRelationshipActionType.GET_GER_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getGertbyID(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type by id : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditRelationshipActionType.RESET_SERVER_VALUE, null)),
    put(getAction(genEditRelationshipActionType.LOG_GER_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GER_BY_ID, action.data);
    yield all([
      put(getAction(genEditRelationshipActionType.GET_GER_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditRelationshipActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditRelationshipActionType.GET_GER_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will insert for new Gen Edit Relationship Type
   */
export function* insertGert(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GER_INSERT,
      action.data.getUpSertObj.addEditData,
    );
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(genEditRelationshipActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(genEditRelationshipActionType.GET_GER_LIST_REQUEST, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err, action.data.genErrMsg),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(genEditRelationshipActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
   * @param {object} action - action which will update for existing Gen Edit Relationship Type
   */
export function* updateGert(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GER_UPDATE,
      action.data.getUpSertObj.addEditData,
    );
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(genEditRelationshipActionType.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(
        getAction(genEditRelationshipActionType.GET_GER_LIST_REQUEST, action.data.reqObject),
      );
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err, action.data.genErrMsg),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(genEditRelationshipActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
   * @param {object} action - action which will delete existing Gen Edit Relationship Type
   */
export function* deleteGert(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GER_DELETE,
      action.data.formatObject,
    );
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(genEditRelationshipActionType.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(
        getAction(genEditRelationshipActionType.GET_GER_LIST_REQUEST, action.data.reqObject),
      );
    }
    yield all([
      put(getAction(genEditRelationshipActionType.GET_GER_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(genEditRelationshipActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(genEditRelationshipActionType.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

// Gen Edit Relationship Values
/**
   * This generator function will invoke the api
   * @param {object} action - action which will Get list for Gen Edit Relationship Value
   */
export function* getGervList(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditRelationshipValActionType.RESET_SERVER_VALUE, null)),
    put(getAction(genEditRelationshipValActionType.LOG_GERV_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GERV_LIST, action.data);
    yield all([
      put(getAction(genEditRelationshipValActionType.GET_GERV_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditRelationshipValActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditRelationshipValActionType.GET_GERV_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will Get list for Gen Edit Relationship Value by id
   */
export function* getGervbyID(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type by id : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditRelationshipValActionType.RESET_SERVER_VALUE, null)),
    put(getAction(genEditRelationshipValActionType.LOG_GERV_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GERV_BY_ID, action.data);
    yield all([
      put(getAction(genEditRelationshipValActionType.GET_GERV_BY_ID_RESPONSE,
        formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditRelationshipValActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditRelationshipValActionType.GET_GERV_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getGervDesc(action) {
  Logger.verbose(`Action Detail For Get GERV description : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditRelationshipValActionType.RESET_SERVER_VALUE, null)),
    put(getAction(genEditRelationshipValActionType.GET_GERV_DESC_REQUEST)),
  ]);
  let resetServerValue = { serverRequestError: null };
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_GERV_DESC.endpoint;
  try {
    const langData = `${action.data.lang}`;
    const idData = `/${action.data.id}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_GERV_DESC.endpoint + langData + idData;
    moduleAPI.ADMINISTRATION_GERV_DESC.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GERV_DESC);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_GERV_DESC.endpoint = initApiUrl;
    yield all([
      put(getAction(genEditRelationshipValActionType.GET_GERV_DESC_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_GERV_DESC.endpoint = initApiUrl;
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(genEditRelationshipValActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(genEditRelationshipValActionType.RESET_UPSERT_VALUE)),
      put(getAction(genEditRelationshipValActionType.GET_GERV_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
   * @param {object} action - action which will insert for new Gen Edit Relationship Value
   */
export function* insertGerv(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GERV_INSERT,
      action.data.getUpSertObj.addEditData,
    );
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(genEditRelationshipValActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(
          getAction(genEditRelationshipValActionType.GET_GERV_LIST_REQUEST, action.data.reqObject),
        ),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err, action.data.genErrMsg),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(genEditRelationshipValActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will update for existing Gen Edit Relationship Value
   */
export function* updateGerv(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GERV_UPDATE,
      action.data.getUpSertObj.addEditData,
    );
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(genEditRelationshipValActionType.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(
        getAction(genEditRelationshipValActionType.GET_GERV_LIST_REQUEST, action.data.reqObject),
      );
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err, action.data.genErrMsg),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(genEditRelationshipValActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
   * @param {object} action - action which will delete existing Gen Edit Relationship Type
   */
export function* deleteGerv(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GERV_DELETE,
      action.data.formatObject,
    );
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(genEditRelationshipValActionType.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(
        getAction(genEditRelationshipValActionType.GET_GERV_LIST_REQUEST, action.data.reqObject),
      );
    }
    yield all([
      put(getAction(genEditRelationshipValActionType.GET_GERV_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(genEditRelationshipValActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(genEditRelationshipValActionType.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getGETExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(genEditActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GET_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode403 || err.response.status === globalConstants.errorCode402) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(genEditActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getGEVExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(genEditValActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GEV_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode403 || err.response.status === globalConstants.errorCode402) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(genEditValActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getGERTExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(genEditRelationshipActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GERT_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode403 || err.response.status === globalConstants.errorCode402) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(genEditRelationshipActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getGERVExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(genEditRelationshipValActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_GERV_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode403 || err.response.status === globalConstants.errorCode402) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(genEditRelationshipValActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for BCR Detail Listing
  */
export function* getGenParentList(action) {
  Logger.verbose(`Action Detail For Get BCR List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(genEditValParentActionTypes.LOG_GEV_PARENT_REQUEST)),
  ]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_GEV_PARENT_LIST.endpoint;
  try {
    const langData = `${action.data.lang}`;
    const parentValueData = `/${action.data.parentValue}`;
    const parentTypeData = `/${action.data.parentType}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_GEV_PARENT_LIST.endpoint
         + langData
         + parentValueData
         + parentTypeData;
    moduleAPI.ADMINISTRATION_GEV_PARENT_LIST.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GEV_PARENT_LIST);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_GEV_PARENT_LIST.endpoint = initApiUrl;
    yield all([
      put(getAction(genEditValParentActionTypes.GET_GEV_PARENT_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_GEV_PARENT_LIST.endpoint = initApiUrl;
    yield all([
      put(getAction(genEditValParentActionTypes.GET_GEV_RESET_UPSERT_VALUE)),
      put(getAction(genEditValParentActionTypes.GET_GEV_PARENT_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for LookUps Data
  */
export function* getLookUpsData(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.GERV_LOOKUPS_DATA, action.data);
    yield all([
      // put(setGERVLookUpsData(responseData)),
      put(getAction(genEditRelationshipValActionType.RESET_GERV_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      // put(setGERVLookUpsData(null)),
      put(getAction(genEditRelationshipValActionType.RESET_GERV_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  // Gen Edit Type
  yield takeEvery(genEditActionTypes.GET_GET_LIST_REQUEST, getGetList);
  yield takeEvery(genEditActionTypes.GET_GET_BY_ID_REQUEST, getGetbyID);
  yield takeEvery(genEditActionTypes.INSERT_GET_REQUEST, insertGet);
  yield takeEvery(genEditActionTypes.UPDATE_GET_REQUEST, updateGet);
  yield takeEvery(genEditActionTypes.DELETE_GET_REQUEST, deleteGet);
  yield takeEvery(genEditActionTypes.GET_DEPENDENCY_EXIST_REQUEST, getRelationship);
  yield takeEvery(genEditActionTypes.GET_GET_EXPORT_REQUEST, getGETExportList);
  // Gen Edit Value
  yield takeEvery(genEditValActionTypes.GET_GEV_LIST_REQUEST, getGevList);
  yield takeEvery(genEditValActionTypes.GET_GEV_BY_ID_REQUEST, getGevbyID);
  yield takeEvery(genEditValActionTypes.INSERT_GEV_REQUEST, insertGev);
  yield takeEvery(genEditValActionTypes.UPDATE_GEV_REQUEST, updateGev);
  yield takeEvery(genEditValActionTypes.DELETE_GEV_REQUEST, deleteGev);
  yield takeEvery(genEditValActionTypes.GET_GEV_EXPORT_REQUEST, getGEVExportList);
  // Gen Parent Value
  yield takeEvery(genEditValParentActionTypes.GET_GEV_PARENT_LIST_REQUEST, getGenParentList);
  // Gen edit relationship type
  yield takeEvery(genEditRelationshipActionType.GET_GER_LIST_REQUEST, getGertList);
  yield takeEvery(genEditRelationshipActionType.GET_GER_BY_ID_REQUEST, getGertbyID);
  yield takeEvery(genEditRelationshipActionType.INSERT_GER_REQUEST, insertGert);
  yield takeEvery(genEditRelationshipActionType.UPDATE_GER_REQUEST, updateGert);
  yield takeEvery(genEditRelationshipActionType.DELETE_GER_REQUEST, deleteGert);
  yield takeEvery(genEditRelationshipActionType.GET_GERT_EXPORT_REQUEST, getGERTExportList);
  // Gen edit relationship value
  yield takeEvery(genEditRelationshipValActionType.GET_GERV_DESC_REQUEST, getGervDesc);
  yield takeEvery(genEditRelationshipValActionType.GET_GERV_LIST_REQUEST, getGervList);
  yield takeEvery(genEditRelationshipValActionType.GET_GERV_BY_ID_REQUEST, getGervbyID);
  yield takeEvery(genEditRelationshipValActionType.INSERT_GERV_REQUEST, insertGerv);
  yield takeEvery(genEditRelationshipValActionType.UPDATE_GERV_REQUEST, updateGerv);
  yield takeEvery(genEditRelationshipValActionType.DELETE_GERV_REQUEST, deleteGerv);
  yield takeEvery(genEditRelationshipValActionType.GET_GERV_EXPORT_REQUEST, getGERVExportList);
  yield takeEvery(genEditRelationshipValActionType.GET_GERV_LOOKUP_REQUEST, getLookUpsData);
}
export default moduleWatcher;
