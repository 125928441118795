/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  codesAndHierarchyActionTypes, debitBalanceActionTypes, topProducersActionTypes,
} from '../actions/actionTypes';
import { processError, renderFileName, exportErrorMsg } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { exportFileType, globalConstants } from '../../../../common/moduleConstants';

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for Code and hierarchy Listing
   */
export function* getCodeAndHierarchyTreeViewUpstream(action) {
  yield all([
    put(getAction(codesAndHierarchyActionTypes.GET_CODES_AND_HIER_TREE_UPSTREAM_RESPONSE, null)),
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CODES_AND_HIER_UPSTREAM, action.data);
    yield all([
      put(getAction(codesAndHierarchyActionTypes.GET_CODES_AND_HIER_TREE_UPSTREAM_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getCodeAndHierarchyList(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CODES_AND_HIER_LIST, action.data);
    yield all([
      put(getAction(codesAndHierarchyActionTypes.GET_CODES_AND_HIER_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getCodeAndHierarchyMovementData(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CODES_AND_HIER_MOVEMENT, action.data);
    yield all([
      put(getAction(codesAndHierarchyActionTypes.GET_CODES_AND_HIER_MOVEMENT_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for SP Export Listing
 */
export function* exportCodesAndHier(action) {
  yield all([
    put(processingStarted()),
    put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  let fileType = exportFileType.Excel;
  try {
    const payload = action.data?.formatExtObject;
    if (payload.view === 'tree') {
      payload.data.pageSize = 10;
    }
    fileType = payload?.exportFileType;
    const responseData = yield call(
      invokeApi,
      moduleAPI.CODES_AND_HIER_EXPORT,
      payload,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    action?.data?.popUpClose();
    yield put(processingCompleted());
  } catch (err) {
    if (err.response?.status === globalConstants.errorCode402 || err.response?.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status, fileType);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
      // close export modal and show message on list page
      action.data.popUpClose();
    } else {
      // DOn't close modal show error in modal window
      resetServerValue = { exportRequestError: processError(err) };
    }
    yield all([
      put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getExternalLevelRBACData(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CODES_AND_HIER_LEVEL_RBAC_DATA, null, action.data);
    yield all([
      put(getAction(codesAndHierarchyActionTypes.GET_LEVEL_RBAC_DATA_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getCodeNickname(action) {
  yield all([
    put(processingStarted()),
    put(getAction(codesAndHierarchyActionTypes.GET_CODE_NICKNAME_RESPONSE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CODES_AND_HIER_LEVEL_NICKNAME, null, null,
      action.data);
    yield all([
      put(getAction(codesAndHierarchyActionTypes.GET_CODE_NICKNAME_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    resetServerValue.serverRequestError.forEach((errorObject) => {
      const errorObj = errorObject;
      if (errorObj.errorCode === 'ERR_9949') {
        errorObj.errorMessageId = 'CHC_NICKNAME_UPDATE_CONCURRENCY_MSG';
      }
    });
    yield all([
      put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* updateCodeNickname(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CODES_AND_HIER_LEVEL_NICKNAME_UPDATE, action.data);
    yield all([
      put(getAction(codesAndHierarchyActionTypes.UPDATE_CODE_NICKNAME_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { updateCodeNickNameError: processError(err) };
    yield all([
      put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getOrgSecurityData(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CODES_AND_HIER_ORG_SECURITY_LIST, action.data);
    yield all([
      put(getAction(codesAndHierarchyActionTypes.GET_ORG_SECURITY_DATA_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getChDebitBalanceList(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(debitBalanceActionTypes.GET_CH_DEBIT_BALANCE_LIST_RESPONSE, null)),
    put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.CH_DEBIT_BALANCE_LIST, action.data);
    yield all([
      put(getAction(debitBalanceActionTypes.GET_CH_DEBIT_BALANCE_LIST_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(codesAndHierarchyActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* exportCHdBList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(debitBalanceActionTypes.RESET_CH_DBEXPORT_SERVER_VALUE, resetServerValue)),
  ]);
  let fileType = exportFileType.Excel;
  try {
    const payload = action.data?.formatExtObject;
    fileType = payload?.exportFileType;
    const responseData = yield call(invokeApi,
      moduleAPI.CH_DEBIT_BALANCE_LIST_EXPORT,
      action.data?.formatExtObject);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
    action.data?.popUpClose();
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status, fileType);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(debitBalanceActionTypes.RESET_CH_DBEXPORT_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getTopProducersList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(topProducersActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.TOP_PRODUCERS_LIST, action.data);
    yield all([
      put(getAction(topProducersActionTypes.GET_EXT_TOP_PRODUCERS_LIST_RES, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(topProducersActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* exportTopProducersList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(topProducersActionTypes.RESET_EXT_TP_EXPORT_ERROS, resetServerValue)),
  ]);
  let fileType = exportFileType.Excel;
  try {
    const payload = action.data?.formatExtObject;
    fileType = payload?.exportFileType;
    const responseData = yield call(invokeApi,
      moduleAPI.TOP_PRODUCERS_LIST_EXPORT,
      action.data?.formatExtObject);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
    action.data?.popUpClose();
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status, fileType);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(topProducersActionTypes.RESET_EXT_TP_EXPORT_ERROS, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  yield takeEvery(codesAndHierarchyActionTypes.GET_CODES_AND_HIER_TREE_UPSTREAM_REQUEST,
    getCodeAndHierarchyTreeViewUpstream);
  yield takeEvery(codesAndHierarchyActionTypes.GET_CODES_AND_HIER_LIST_REQUEST, getCodeAndHierarchyList);
  yield takeEvery(codesAndHierarchyActionTypes.GET_CODES_AND_HIER_MOVEMENT_REQUEST, getCodeAndHierarchyMovementData);
  yield takeEvery(codesAndHierarchyActionTypes.EXPORT_CODES_AND_HIER_REQUEST, exportCodesAndHier);

  yield takeEvery(codesAndHierarchyActionTypes.GET_LEVEL_RBAC_DATA_REQUEST, getExternalLevelRBACData);
  yield takeEvery(codesAndHierarchyActionTypes.GET_CODE_NICKNAME_REQUEST, getCodeNickname);
  yield takeEvery(codesAndHierarchyActionTypes.UPDATE_CODE_NICKNAME_REQUEST, updateCodeNickname);
  yield takeEvery(codesAndHierarchyActionTypes.GET_ORG_SECURITY_DATA_REQUEST, getOrgSecurityData);

  // C&H Debit Balance
  yield takeEvery(debitBalanceActionTypes.GET_CH_DEBIT_BALANCE_LIST, getChDebitBalanceList);
  yield takeEvery(debitBalanceActionTypes.EXORT_CH_DEBIT_BALANCE_LIST, exportCHdBList);
  yield takeEvery(topProducersActionTypes.GET_EXT_TOP_PRODUCERS_LIST, getTopProducersList);
  yield takeEvery(topProducersActionTypes.EXORT_EXT_TOP_PRODUCERS, exportTopProducersList);
}

export default moduleWatcher;
