import styled from 'styled-components';

export const ButtonClose = styled.button`
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 12px;
`;

export default ButtonClose;
