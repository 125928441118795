/* eslint-disable react/prop-types */
/**
 * @Description Fasat Currency Text Input container page
 * @FileName fasatCurrencyInput.js
 * @Author MANGARAM B-bmangab
 * @CreatedOn 24 March, 2021 06:04:16
 * @IssueID 1184
 */
import React from 'react';
import PropTypes from 'prop-types';
import CurrencyTextInput from '../../libs/fasatSharedComponent/currencyTextInput';

const FasatCurrencyInput = ({
  inlineButtonText,
  errors,
  changeValues,
  type,
  value,
  label,
  maxLength,
  decimalPlaces,
  handleOnBlur,
  adornmentEnabled,
  ariaRequired,
  ariaLabel,
  disabled,
  id,
  name,
  numDec,
  integralPartLength,
  negativeAllowed,
}) => (
  <div data-testid="FasatActionableTextInputComponent">
    <CurrencyTextInput
      inlineButtonText={inlineButtonText}
      errors={errors}
      changeValues={changeValues}
      handleOnBlur={handleOnBlur}
      type={type}
      maxLength={maxLength}
      decimalPlaces={decimalPlaces}
      values={value}
      label={label}
      disabled={disabled}
      adornmentEnabled={adornmentEnabled}
      ariaRequired={ariaRequired}
      ariaLabel={ariaLabel}
      id={id}
      name={name}
      numDec={numDec}
      integralPartLength={integralPartLength}
      negativeAllowed={negativeAllowed}
    />
  </div>
);

FasatCurrencyInput.propTypes = {
  inlineButtonText: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  changeValues: PropTypes.func,
  maxLength: PropTypes.number,
  handleOnBlur: PropTypes.func,
  adornmentEnabled: PropTypes.bool,
  ariaRequired: PropTypes.bool,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  decimalPlaces: PropTypes.number,
  integralPartLength: PropTypes.number,
};

FasatCurrencyInput.defaultProps = {
  inlineButtonText: '',
  type: 'text',
  label: '',
  value: '',
  adornmentEnabled: false,
  maxLength: null,
  changeValues: () => undefined,
  handleOnBlur: () => null,
  ariaRequired: false,
  ariaLabel: null,
  disabled: false,
  decimalPlaces: 0,
  integralPartLength: null,
};

export default FasatCurrencyInput;
