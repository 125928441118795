/**
 * @Description File description.
 * @FileName reduxUtil.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 29 January, 2021 08:51:27
 * @IssueID -
 */
/**
 * Create a Redux action depending on the actionType and actionData
 * @param {string} actionType - string identifier for the action
 * @param {object} actionData - data required for the action
 */
export const getAction = (actionType, actionData) => {
  if (!actionType || actionType === '') {
    throw new Error('action type not provided');
  }
  return {
    type: actionType,
    data: actionData,
  };
};

/**
 * Initial state for the application.
 * This state would be maintained by the ApplicationReducer
 */
export const initialApplicationState = {
  loaded: true,
  applicationName: 'React Sample Project Setup',
  lookupData: null,
  detailRequest: null,
  detailRequestError: null,
  detailRequestedOn: null,
  detailRequestProcessedOn: null,
  detailData: null,
  upsertRequest: null,
  upsertRequestError: null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: null,
  upsertData: null,
  deleteRequest: null,
  deleteRequestError: null,
  deleteRequestedOn: null,
  deleteRequestProcessedOn: null,
  deleteData: null,
  isDirty: false,
  lockDetails: null,
  batchRequestError: null,
  batchRequestedOn: null,
  usersQuicksLinks: [],
  allQuicksLinks: [],
  iraData: [],
};
export const initialApplicationStateRBAC = {
  roleList: null,
  loggedUser: null,
  rbacData: null,
  userPrefData: null,
  userPrefError: null,
  notLogin: false,
  userlang: 'E',
  rbacApiCalled: false,
};

export const initialApplicationFilterListState = {
  processingInProgress: 0,
  operatorList: null,
  listSortOptions: null,
  listColumnOrder: null,
  listGroupBy: null,
  isDistinctActive: false,
  filterListCount: 0,
};

/**
 * Most of the unique module functional group has similar kind of store
 * structure. So to be on the consistent side; we have created this basic
 * state object which can be leveraged in future reducers for different module
 */
export const basicInitialState = {
  listSortOptions: null, //
  listGroupBy: null,
  listParamOptions: null, //
  listColumnOrder: null, //
  isDistinctActive: false,
  selectedFilter: null, //
  systemDefaultFilter: null, //
  userDefaultFilter: null, //
  listRequest: null,
  listRequestError: null,
  listRequestedOn: null,
  listRequestProcessedOn: null,
  listData: null,
  lookupData: [],
  detailRequest: null,
  detailRequestError: null,
  detailRequestedOn: null,
  detailRequestProcessedOn: null,
  detailData: null,
  upsertRequest: null,
  upsertRequestError: null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: null,
  upsertData: null,
  deleteRequest: null,
  deleteRequestError: null,
  deleteRequestedOn: null,
  deleteRequestProcessedOn: null,
  deleteData: null,
  filter: null,
  forcedFilterLoad: false,
  subModuledetails: null,
  serverRequestError: null,
  pageLevelLookupData: null,
};

export const basicFilterInitialState = {
  listSortOptions: null,
  listParamOptions: null,
  listColumnOrder: null,
  listGroupBy: null,
  isDistinctActive: false,
  selectedFilter: null,
  systemDefaultFilter: null,
  userDefaultFilter: null,
  listRequest: null,
  listRequestError: null,
  listRequestedOn: null,
  listRequestProcessedOn: null,
  listData: null,
  serverRequestError: null,
  DependencyExist: null,
  getEditItemDetails: null,
  datasources: [],
  totalRows: [],
};

export const basicListAddEditDltInitialState = {
  detailRequest: null,
  detailRequestError: null,
  detailRequestedOn: null,
  detailRequestProcessedOn: null,
  detailData: null,
  headerData: null,
  upsertRequest: null,
  upsertRequestError: null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: null,
  upsertData: null,
  deleteRequest: null,
  deleteRequestError: null,
  deleteRequestedOn: null,
  deleteRequestProcessedOn: null,
  deleteData: null,
  filter: null,
  forcedFilterLoad: false,
  subModuledetails: null,
  pageLevelLookupData: null,
  serverRequestError: null,
  selectedSubTab: null,
  selectedTab: null,
  datasourceFiled: [],
};

export const basicManualAdjFilterInitialState = {
  listSortOptions: null,
  listParamOptions: null,
  listColumnOrder: [],
  listGroupBy: null,
  isDistinctActive: false,
  selectedFilter: null,
  systemDefaultFilter: null,
  userDefaultFilter: null,
  listRequest: null,
  listRequestError: null,
  listRequestedOn: null,
  listRequestProcessedOn: null,
  listData: null,
  serverRequestError: null,
  DependencyExist: null,
  getEditItemDetails: null,
};
