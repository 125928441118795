/**
 * @Description File description.
 * @FileName moduleConstants.js
 * @Author pgoutam
 * @CreatedOn 03 Jan 23
 * @IssueID 220
 */
/**
   * All the route constants for the module
   */

export const routeConstant = {
  ACCOUNTING_INQUIRY_ROUTE: '/agency/accountinginquiry',
};

/**
   * ACL keys for the module
   */
export const aclKeysConstant = {
  RBAC_ACI_MENU: 'RBAC_ACI_MENU',
  RBAC_ACI_EXPORT: 'RBAC_ACI_EXPORT',
  RBAC_ACCOUNTING: 'RBAC_ACCOUNTING',
};

/**
   * i18n Keys for the module
   */
export const staticLabelKeys = {
  ACCOUNTING_MENU: 'RBAC_ACCOUNTING',
  ACCOUNTING_INQUIRY_MENU: 'RBAC_ACI_MENU',

  // Accounting inquiry
  ACI_TITLE_ACINQ: 'RBAC_ACI_MENU',

  ACI_LABEL_ACTYR: 'ACI_LABEL_ACTYR',
  ACI_LABEL_ACTPR: 'ACI_LABEL_ACTPR',
  ACI_LABEL_PROCESSEDDATE: 'ACI_LABEL_PROCESSEDDATE',
  ACI_LABEL_ACCOUNT: 'ACI_LABEL_ACCOUNT',
  ACI_LABEL_BALANCETAG: 'ACI_LABEL_BALANCETAG',
  ACI_LABEL_ACTUNIT: 'ACI_LABEL_ACTUNIT',
  ACI_LABEL_BRNCHDEPT: 'ACI_LABEL_BRNCHDEPT',
  ACI_LABEL_AGENTCODE: 'ACI_LABEL_AGENTCODE',
  ACI_LABEL_COMMTYPE: 'ACI_LABEL_COMMTYPE',
  ACI_LABEL_COMMSUBTYPE: 'ACI_LABEL_COMMSUBTYPE',
  ACI_LABEL_ADJTYPE: 'ACI_LABEL_ADJTYPE',
  ACI_LABEL_STATACCOUNT: 'ACI_LABEL_STATACCOUNT',
  ACI_LABEL_GENDATE: 'ACI_LABEL_GENDATE',
  ACI_LABEL_PLANID: 'ACI_LABEL_PLANID',
  ACI_LABEL_ACCTCOMPANY: 'ACI_LABEL_ACCTCOMPANY',
  ACI_LABEL_POLICYNUM: 'ACI_LABEL_POLICYNUM',
  ACI_LABEL_COVERAGE: 'ACI_LABEL_COVERAGE',
  ACI_LABEL_ISSDT: 'ACI_LABEL_ISSDT',
  ACI_LABEL_ISSCNTRY: 'ACI_LABEL_ISSCNTRY',
  ACI_LABEL_ISSLOC: 'ACI_LABEL_ISSLOC',
  ACI_LABEL_CRNTCNTRY: 'ACI_LABEL_CRNTCNTRY',
  ACI_LABEL_CRNTLOC: 'ACI_LABEL_CRNTLOC',
  ACI_LABEL_USERID: 'ACI_LABEL_USERID',
  ACI_LABEL_GENTASK: 'ACI_LABEL_GENTASK',
  ACI_LABEL_GENSYS: 'ACI_LABEL_GENSYS',
  ACI_LABEL_BATCHNUM: 'ACI_LABEL_BATCHNUM',
  ACI_LABEL_INTERFACEBATCHNUM: 'ACI_LABEL_INTERFACEBATCHNUM',
  ACI_LABEL_CHQREF: 'ACI_LABEL_CHQREF',
  ACI_LABEL_REFNUM: 'ACI_LABEL_REFNUM',
  ACI_LABEL_MICR: 'ACI_LABEL_MICR',
  ACI_LABEL_DUEDATE: 'ACI_LABEL_DUEDATE',
  ACI_LABEL_BLOCK: 'ACI_LABEL_BLOCK',
  ACI_LABEL_ADMINBRCH: 'ACI_LABEL_ADMINBRCH',
  ACI_LABEL_FASATELEMENT: 'ACI_LABEL_FASATELEMENT',
  ACI_LABEL_WIRETRADE: 'ACI_LABEL_WIRETRADE',
  ACI_LABEL_FUND: 'ACI_LABEL_FUND',
  ACI_LABEL_APPRECEIVEDT: 'ACI_LABEL_APPRECEIVEDT',
  ACI_LABEL_CLNTNUM: 'ACI_LABEL_CLNTNUM',
  ACI_LABEL_REPORTNUM: 'ACI_LABEL_REPORTNUM',
  ACI_LABEL_COSTCENTER: 'ACI_LABEL_COSTCENTER',
  ACI_LABEL_PAR: 'ACI_LABEL_PAR',
  ACI_LABEL_GENTRANS: 'ACI_LABEL_GENTRANS',
  ACI_LABEL_SEQ: 'ACI_LABEL_SEQ',

};
