/**
 * @Description This file contains all the Menu configuration for administration module
 * @FileName menuConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:41:03
 * @IssueID - 459, 311
 */
import { People } from '@manulife/mux-cds-icons';
import { t } from '../../../common/i18n';
import { navigate } from '../../../common/util';
import { aclKeysConstant, routeConstant, staticLabelKeys } from '../moduleConstants';

const getProductConfigSubMenu = () => [
  {
    id: 'TEMPLATE_ADJUSTMENT_MENU',
    label: t(staticLabelKeys.RBAC_TA_MENU),
    urlPath: routeConstant.TEMPLATE_ADJUSTMENTS,
    url: '#',
    action: () => navigate(routeConstant.TEMPLATE_ADJUSTMENTS),
    aclKey: [aclKeysConstant.RBAC_TA_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'MANUAL_ADJUSTMENT_MENU',
    label: t(staticLabelKeys.RBAC_MADJ_MENU),
    urlPath: routeConstant.MANUAL_ADJUSTMENT_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.MANUAL_ADJUSTMENT_ROUTE),
    aclKey: [aclKeysConstant.RBAC_MADJ_EDIT],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'MANUAL_COMMISSION_RECORDS_MENU',
    label: t(staticLabelKeys.MCR_MENU_TITLE),
    urlPath: routeConstant.MANUAL_COMMISSION_RECORDS,
    url: '#',
    action: () => navigate(routeConstant.MANUAL_COMMISSION_RECORDS),
    aclKey: [aclKeysConstant.RBAC_MCR_MENU],
    acl: true,
  },
];

const inquiryMenus = () => [
  {
    id: 'PAYMENT_CONTROLLER_MOVEMENT_MENU',
    label: t(staticLabelKeys.PAYMENT_CONTROLLER_PAGE_TITLE),
    urlPath: routeConstant.PAYMENT_CONTROLLER_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.PAYMENT_CONTROLLER_ROUTE),
    aclKey: [aclKeysConstant.RBAC_PCM_MENU],
    acl: true,
  },
  {
    id: 'EXCEPTIONS_MENU',
    label: t(staticLabelKeys.EXCEPTIONS_MENU),
    urlPath: routeConstant.EXCEPTIONS_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.EXCEPTIONS_ROUTE),
    aclKey: [aclKeysConstant.RBAC_EXI_MENU],
    acl: true,
  },
  {
    id: 'RELATIONSHIP_MENU',
    label: t(staticLabelKeys.RLI_PAGE_TITLE),
    urlPath: routeConstant.RELATIONSHIPS,
    url: '#',
    action: () => navigate(routeConstant.RELATIONSHIPS),
    aclKey: [aclKeysConstant.RBAC_RLI_MENU],
    acl: true,
  },
  {
    id: 'DEDUCTION_INQUIRY_MENU',
    label: t(staticLabelKeys.DEDUCTION_INQUIRY_MENU),
    urlPath: routeConstant.DEDUCTION_INQUIRY_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.DEDUCTION_INQUIRY_ROUTE),
    aclKey: [aclKeysConstant.RBAC_DI_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'REQUIREMENT_INQUIRY_MENU',
    label: t(staticLabelKeys.REQ_PAGE_TITLE),
    urlPath: routeConstant.REQ_INQUIRY_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.REQ_INQUIRY_ROUTE),
    aclKey: [aclKeysConstant.RBAC_RI_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'TASK_INQUIRY_MENU',
    label: t(staticLabelKeys.TASK_INQUIRY_PAGE_TITLE),
    urlPath: routeConstant.TASK_INQUIRY_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.TASK_INQUIRY_ROUTE),
    aclKey: [aclKeysConstant.RBAC_TI_MENU],
    acl: true,
  },
  {
    id: 'MASS_TRANSFER_INQUIRY_MENU',
    label: t(staticLabelKeys.MASS_TRANSFER_INQUIRY_PAGE_TITLE),
    urlPath: routeConstant.MASS_AGENT_TRANSFER_INQ_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.MASS_AGENT_TRANSFER_INQ_ROUTE),
    aclKey: [aclKeysConstant.RBAC_MATI_MENU],
    acl: true,
  },

];

/**
 * Menu configuration for the module
 */
const desktopMenus = () => [
  {
    id: 'agency',
    sequence: 1,
    icon: <People style={{ width: '20px', height: '20px' }} />,
    label: t(staticLabelKeys.DISTRIBUTOR),
    aria: t(staticLabelKeys.DISTRIBUTOR),
    urlPath: '#',
    url: '#',
    action: () => navigate('#'),
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    subMenu: [
      {
        id: 'BUSINESS_CARD_RULE_MENU',
        label: t(staticLabelKeys.BCR_MENU),
        urlPath: routeConstant.BUSINESS_CARD_RULES,
        url: '#',
        action: () => navigate(routeConstant.BUSINESS_CARD_RULES),
        aclKey: [aclKeysConstant.BUSINESS_CARD_RULES],
        acl: true,
        showInBatchProcess: false,
      },
      {
        id: 'CLIENT_MENU',
        label: t(staticLabelKeys.CL_MENU),
        urlPath: routeConstant.CLIENT,
        url: '#',
        action: () => navigate(routeConstant.CLIENT),
        aclKey: [aclKeysConstant.RBAC_CL_MENU],
        acl: true,
      },
      {
        id: 'FIELD_FORCE_MENU',
        label: t(staticLabelKeys.FF_MENU),
        urlPath: routeConstant.FIELD_FORCE,
        url: '#',
        action: () => navigate(routeConstant.FIELD_FORCE),
        aclKey: [aclKeysConstant.RBAC_FF_MENU],
        acl: true,
      },

      {
        id: 'LICENCE_MENU',
        label: t(staticLabelKeys.LIP_PAGE_TITLE),
        urlPath: routeConstant.LICENCE_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.LICENCE_ROUTE),
        aclKey: [aclKeysConstant.RBAC_LIP_MENU],
        acl: true,
        showInBatchProcess: false,
        // subMenu: getProductConfigSubMenu(),
      },
      {
        id: 'ADJUSTMENTS',
        label: t(staticLabelKeys.RBAC_ADJUSTMENTS_MENU),
        urlPath: '#',
        url: '#',
        action: () => navigate('#'),
        aclKey: [aclKeysConstant.RBAC_ADJUSTMENTS_MENU],
        acl: true,
        showInBatchProcess: false,
        subMenu: getProductConfigSubMenu(),
      },
      { id: 'FIELD_FORCE_MOVEMENT_MENU',
        label: t(staticLabelKeys.FFM_TITLE_MOVEMENT),
        urlPath: routeConstant.FIELD_FORCE_MOVEMENT,
        url: '#',
        action: () => navigate(routeConstant.FIELD_FORCE_MOVEMENT),
        aclKey: [aclKeysConstant.RBAC_FFM_MENU],
        acl: true,
      },
      {
        id: 'FIELD_FORCE_LETTERS_MENU',
        label: t(staticLabelKeys.FFL_PAGE_TITLE),
        urlPath: routeConstant.FIELD_FORCE_LETTER_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.FIELD_FORCE_LETTER_ROUTE),
        aclKey: [aclKeysConstant.RBAC_FFL_MENU],
        acl: true,
        showInBatchProcess: false,
      },
      ...inquiryMenus(),
    ],
  },
];
const mobileMenus = desktopMenus;
export { desktopMenus, mobileMenus };
