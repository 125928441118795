/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName agencyRequirementWatcher.js
 * @Author Prasenjit Chakraborty - chakpra
 * @CreatedOn 2nd November, 2022 05:46:22
 * @IssueID - 6798
 */

import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { fieldForceActionTypes } from '../actions/actionTypes';
import { formatResponse, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';

const ERROR_MSG_LENGTH_EXPORT = 'The number of records has exceeded the maximum limit.';
const errorCode = 402;
const exportMaxLimitErrorObj = [
  {
    errorCode: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
    errorMessage: ERROR_MSG_LENGTH_EXPORT,
    errorMessageId: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
    type: 'RULE',
  },
];

export function* getFFRequirementList(action) {
  Logger.verbose(`Action Detail For Get FF Notes List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.GET_FF_DETAIL_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_REQUIREMENT_LIST, action.data);
    const responseDataGrid = { ...responseData };
    responseDataGrid.data = responseData.data[0].requirementsList;
    responseDataGrid.comment = responseData.data[0].ffRequirement.orgComment;
    responseDataGrid.orgReqNotifyDt = responseData.data[0].ffRequirement.orgReqNotifyDt;
    yield all([
      // put(getAction(fieldForceActionTypes.GET_FF_DETAIL_RESPONSE, responseData)),
      put(getAction(fieldForceActionTypes.GET_FF_REQUIREMENT_TABLE_RES, responseDataGrid)),
      put(getAction(fieldForceActionTypes.GET_FF_REQUIREMENT_TABLE_COMMENT, responseDataGrid)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFRequirementByID(action) {
  Logger.verbose(`Action Detail For Get FF Notes List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_REQUIREMENT_LIST_BY_ID,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

/**
 * Get Requirement look up
 *
 * @export
 * @param {*} action metadata
 */
export function* getFFRequirementLookUp(action) {
  Logger.verbose(`Action Detail For Get FF Sys Option : ${JSON.stringify(action)}`);
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_LOOKUPS_DATA, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_REQUIREMENT_LOOK_UP_DATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(processingCompleted()),
    ]);
  }
}

/**
 * Insert Requirement
 *
 * @export
 * @param {*} action
 */
export function* insertFFRequirement(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_REQUIREMENT_INSERT,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_REQUIREMENT_LIST_REQUEST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 *
 *
 * @export
 * @param {*} action
 */
export function* updateFFRequirement(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_REQUIREMENT_UPDATE,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_REQUIREMENT_LIST_REQUEST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deleteFFRequirement(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_REQUIREMENT_DELETE,
      action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue));
      // yield put(getAction(fieldForceActionTypes.GET_FF_REQUIREMENT_LIST_REQUEST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // const resetServerValue = { serverRequestError: processError(err) };
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* notifyNowFFRequirement(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  let notiFication = { isNotifyNow: action.data.isNotifyNow };
  yield all([
    put(processingStarted()),
  ]);
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.FF_REQUIREMENT_NOTIFY_NOW,
      action.data,
    );
    if (responseData) {
      notiFication = { ...notiFication, ...{ status: true, ...responseData } };
      yield put(getAction(fieldForceActionTypes.NOTIFY_SUCCESS_STATUS, notiFication));
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.FF_CLEAR_NOTIFY_STATUS, null)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    notiFication.status = false;
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.NOTIFY_SUCCESS_STATUS, notiFication)),
      put(getAction(fieldForceActionTypes.FF_CLEAR_NOTIFY_STATUS, null)),
      put(processingCompleted()),
    ]);
  }
}

export function* notifyLaterFFRequirement(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  let notiFication = { isNotifyNow: action.data.isNotifyNow };
  let resetUpsertValue = null;
  yield all([
    put(processingStarted()),
  ]);
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.FF_REQUIREMENT_NOTIFY_NOW,
      action.data,
    );
    if (responseData) {
      notiFication = { ...notiFication, ...{ status: true, ...responseData } };
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.NOTIFY_SUCCESS_STATUS, notiFication));
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
    }
    yield all([
      put(getAction(fieldForceActionTypes.FF_CLEAR_NOTIFY_STATUS, null)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    notiFication.status = false;
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(fieldForceActionTypes.NOTIFY_SUCCESS_STATUS, notiFication)),
      put(getAction(fieldForceActionTypes.FF_CLEAR_NOTIFY_STATUS, null)),
      put(processingCompleted()),
    ]);
  }
}

export function* saveFFRequirement(action) {
  Logger.verbose(`Action Detail For FF Notes : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
  ]);
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.FF_REQUIREMENT_EDIT_COMMENT,
      action.data,
    );
    if (responseData) {
      yield put(getAction(fieldForceActionTypes.FF_SUCCESS_SAVE_COMMENT, {
        status: true, comment: responseData.orgComment }));
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.FF_CLEAR_SAVE_COMMENT)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.FF_SUCCESS_SAVE_COMMENT, {
        status: false, comment: null })),
      put(getAction(fieldForceActionTypes.FF_CLEAR_SAVE_COMMENT)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * Set Requirement concurrency
 *
 * @export
 * @param {*} action metadata
 */
export function* setFFRequirementConcurrency(action) {
  Logger.verbose(`Action Detail For Get FF concurrency Option : ${JSON.stringify(action)}`);
  yield put(processingStarted());
  try {
    yield call(invokeApi, moduleAPI.FF_REQUIREMENT_LIST_BY_ID, action.data);
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  // Requirement
  yield takeEvery(fieldForceActionTypes.GET_FF_REQUIREMENT_LIST_REQUEST, getFFRequirementList);
  yield takeEvery(fieldForceActionTypes.GET_FF_REQUIREMENT_LIST_BY_ID_REQUEST, getFFRequirementByID);
  yield takeEvery(fieldForceActionTypes.GET_FF_REQUIREMENT_LOOK_UP, getFFRequirementLookUp);
  yield takeEvery(fieldForceActionTypes.INSERT_FF_REQUIREMENT_REQUEST, insertFFRequirement);
  yield takeEvery(fieldForceActionTypes.UPDATE_FF_REQUIREMENT_REQUEST, updateFFRequirement);
  yield takeEvery(fieldForceActionTypes.DELETE_FF_REQUIREMENT_REQUEST, deleteFFRequirement);
  yield takeEvery(fieldForceActionTypes.NOTIFY_NOW_REQUIREMENT_REQUEST, notifyNowFFRequirement);
  yield takeEvery(fieldForceActionTypes.NOTIFY_LATER_REQUIREMENT_REQUEST, notifyLaterFFRequirement);
  yield takeEvery(fieldForceActionTypes.FF_REQUIREMENT_SAVE_COMMENT, saveFFRequirement);
  yield takeEvery(fieldForceActionTypes.SET_FF_REQUIREMENT_CONCURRENCY, setFFRequirementConcurrency);
}

export default moduleWatcher;
