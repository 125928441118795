/* eslint-disable react/prop-types */
/**
 * @Description Container for Currency Text Input
 * @FileName index.js
 * @Author MANGARAM-bmangab
 * @CreatedOn 16 July, 2021 01:31:24
 * @IssueID 1184
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FasatTextInput } from '../../../components';
import { getCurrentLocale } from '../../../i18n';
import { Logger, currencyFormat } from '../../../util';

const twoNumber = 2;
const fourNumber = 4;
const currencyConvertion = (locale, inputValue, values, decimalPlaces) => {
  let splitedValue;
  if (locale === 'en' && values) {
    splitedValue = inputValue.toString().replace(',', '.').split(/\s/g).join('');
  } else if (locale === 'fr' && values) {
    splitedValue = inputValue.toString().split(',').join('');
  } else {
    splitedValue = inputValue;
  }
  return currencyFormat(splitedValue, locale, decimalPlaces);
};

/**
      * Validate Integral part in the currency
      */
const validateIntegralPart = (locale, v, integralPartLength) => {
  let value = v;
  let decimalSeprator;
  if (locale === 'en') {
    decimalSeprator = '.';
    value = value.replace(',', '');
  } else {
    decimalSeprator = ',';
    value = value.replace(' ', '');
  }
  const decimalPosition = value.indexOf(decimalSeprator) >= 0 ? value.indexOf(decimalSeprator) : value.length;
  const inegralPart = value.substring(0, decimalPosition);
  if (inegralPart.length > integralPartLength) {
    let newValue = value.substring(0, integralPartLength);
    newValue += value.substring(decimalPosition, value.length);
    value = newValue;
  }
  return value;
};

const handleNegativeInputEnabled = (v, negativeAllowed, isNegativeP) => {
  let value = v;
  let isNegative = isNegativeP;
  if (value && negativeAllowed) {
    const firstChar = value.substring(0, 1);
    isNegative = firstChar === '-';
    value = isNegative ? value.substring(0, value.length) : value;
  }
  return { value, isNegative };
};
/**
      * Remove comma(,) space(\s) and period(.) symbols
      * Note for en
      * 1.replace(allow only num and .,) 2.replace(allow only one period)
      * 3.replace(restrict integer after 2 decimal)
      * 4.replace(restrict integer after 2 decimal with comma)
      */
const decimalCurrentFormat = (val, locale, decimalPlaces, integralPartLength, negativeAllowed) => {
  let valueResult;
  let value = val;
  let isNegative = false;
  ({ value, isNegative } = handleNegativeInputEnabled(value, negativeAllowed, isNegative));
  // Check for restrication on Integral part of amount
  if (integralPartLength && value) {
    value = validateIntegralPart(locale, value, integralPartLength);
  }

  if (locale === 'en') {
    if (decimalPlaces === twoNumber) {
      valueResult = value.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1')
        .replace(/(\.\d{2})\d+/g, '$1').replace(/(\.\d{0}),+/g, '$1')
        .replace(/(\.\d{1}),+/g, '$1')
        .replace(/(\.\d{2}),+/g, '$1');
    } else if (decimalPlaces === fourNumber) {
      valueResult = value.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1')
        .replace(/(\.\d{4})\d+/g, '$1').replace(/(\.\d{0}),+/g, '$1')
        .replace(/(\.\d{1}),+/g, '$1')
        .replace(/(\.\d{2}),+/g, '$1')
        .replace(/(\.\d{3}),+/g, '$1')
        .replace(/(\.\d{4}),+/g, '$1');
    } else {
      valueResult = value.replace(/[^0-9,]/g, '');
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (decimalPlaces === twoNumber) {
      valueResult = value.replace(/[^0-9,\s]/g, '').replace(/(,.*?),(.*,)?/, '$1')
        .replace(/(,\d{2})\d+/g, '$1').replace(/(,\d{0})\s+/g, '$1')
        .replace(/(,\d{1})\s+/g, '$1')
        .replace(/(,\d{2})\s+/g, '$1');
    } else if (decimalPlaces === fourNumber) {
      valueResult = value.replace(/[^0-9,\s]/g, '').replace(/(,.*?),(.*,)?/, '$1')
        .replace(/(,\d{4})\d+/g, '$1').replace(/(,\d{0})\s+/g, '$1')
        .replace(/(,\d{1})\s+/g, '$1')
        .replace(/(,\d{2})\s+/g, '$1')
        .replace(/(,\d{2})\s+/g, '$1')
        .replace(/(,\d{2})\s+/g, '$1');
    } else {
      valueResult = value.replace(/[^0-9\s]/g, '');
    }
  }

  if (isNegative) {
    valueResult = `-${valueResult}`;
  }
  return valueResult;
};

const currencyFormatValidation = ({ e, maxLength, locale, decimalPlaces, setInputValue,
  numDec, integralPartLength, negativeAllowed }) => {
  /* GET WHOLE TEXT LENGTH WITHOUT CONSIDERING COMMA(,) & SPACE(/\s/g) */
  const getTextLength = e.target.value.split(',').join('').split(/\s/g).join('').length;
  /* MAX LENGTH LESSER THAN TEXT LENGTH */
  if (getTextLength <= maxLength || maxLength === null) {
    e.target.value = decimalCurrentFormat(e.target.value, locale, decimalPlaces, integralPartLength, negativeAllowed);
  } else if (e.target.value.split(',').join('').split(/\s/g).join('').length > maxLength) {
    /* RESTRICT TEXT MORE THAN MAXLENGTH */
    const exceededNumber = e.target.value.split(',').join('').split(/\s/g).join('').length - maxLength;
    e.target.value = e.target.value.slice(0, -exceededNumber);
  } else {
    Logger.verbose('Invalid path');
  }
  if (numDec) {
    setInputValue(e.target.value);
  }
};

const CurrencyTextInput = ({ onBlurAction, onFocusAction, onInputAction, handleOnBlur, placeholder, decimalPlaces,
  defaultLang, errors, type, label, values, maxLength, adornmentEnabled, ariaRequired, ariaLabel, disabled,
  id, name, numDec, integralPartLength, negativeAllowed }) => {
  const getLangData = getCurrentLocale();
  const locale = (getLangData === undefined) ? defaultLang : getLangData;
  const [inputValue, setInputValue] = useState(values);
  let alignSymbol;

  if (locale === 'en') {
    alignSymbol = 'left';
  } else {
    alignSymbol = 'right';
  }

  /* Currency to decimal number */
  useEffect(() => {
    if (values !== '' && values !== null) {
      const dataResult = currencyConvertion(locale, inputValue, values, decimalPlaces);
      setInputValue(dataResult);
    }
  }, [locale]);

  useEffect(() => {
    setInputValue(values);
  }, [values]);
  /**
      * Constant values for classNames, placeholders, types
      */
  const customTextInput = 'customTextInput';
  const inputId = id || 'currencyTextId';
  const inputName = name || 'name';

  /**
      * @param {String} item Trim space and remove 0.00 value on focus
      */
  const formatValueOnFocus = (num) => {
    setInputValue(num.trimEnd());
  };

  const formatValueOnInput = (e) => currencyFormatValidation({ e,
    maxLength,
    locale,
    decimalPlaces,
    setInputValue,
    numDec,
    integralPartLength,
    negativeAllowed });

  return (
    <div className={customTextInput}>
      <FasatTextInput
        errors={errors}
        disabled={disabled}
        id={inputId}
        name={inputName}
        value={inputValue}
        onChange={() => undefined}
        onBlurEnabled={onBlurAction}
        onBlur={(value) => handleOnBlur(value)}
        onFocusEnabled={onFocusAction}
        onFocus={(newValue) => formatValueOnFocus(newValue.target.value)}
        onInputEnabled={onInputAction}
        onInput={(e) => formatValueOnInput(e)}
        currencyInput
        locale={locale}
        placeholder={placeholder}
        decimalPlaces={decimalPlaces}
        type={type}
        adornment={adornmentEnabled ? '$' : ''}
        label={label}
        adornmentPosition={alignSymbol}
        min={0}
        ariaRequired={ariaRequired}
        ariaLabel={ariaLabel}
        numDec={numDec}
      />
    </div>
  );
};

CurrencyTextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onBlurAction: PropTypes.bool,
  onFocusAction: PropTypes.bool,
  onInputAction: PropTypes.bool,
  maxLength: PropTypes.number,
  defaultLang: PropTypes.string,
  ariaRequired: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

CurrencyTextInput.defaultProps = {
  placeholder: '',
  type: 'text',
  label: '',
  maxLength: null,
  onBlurAction: true,
  onFocusAction: true,
  onInputAction: true,
  defaultLang: 'en',
  ariaRequired: false,
  ariaLabel: null,
};

export default CurrencyTextInput;
