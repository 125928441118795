/* eslint-disable import/named */
/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Jayanta Sarkhel - sarkhja
 * @CreatedOn 21 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { clientActionsTypes, clientDetailActionTypes } from '../actions/actionTypes';

const ClientCardRule1 = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case clientActionsTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case clientActionsTypes.SET_CLIENT_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case clientActionsTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case clientActionsTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case clientActionsTypes.UPSERT_CS_REQUEST:
      return {
        ...state,
        upsertRequestedOn: new Date(),
        upsertData: action.data.upsertData,
        loading: true,
      };
    default:
      return null;
  }
};
const ClientCardRule2 = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case clientDetailActionTypes.GET_CS_DETAIL_RESPONSE:
      return {
        ...state,
        detailData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case clientDetailActionTypes.GET_CS_AGENT_NAME_RESPONSE:
      return {
        ...state,
        agentName: action.data,
      };
    case clientDetailActionTypes.GET_NEXT_CS_AGENT_NAME_RESPONSE:
      return {
        ...state,
        nextagentName: action.data,
      };
    case clientDetailActionTypes.GET_CS_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return null;
  }
};
const ClientCardRule = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  const stateReqRes = ClientCardRule1(state, action);
  const stateConfig = ClientCardRule2(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ClientCardRule;
