import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, typography } from '@manulife/mux';

// #region constants

// #endregion

// #region styled-components
const ErrMsg = styled.div`
  font-family: ${typography.fontFamily};
  color: ${colors.dark_3_coral};
  margin-top: 0.5em;
  line-height: 1em;
  font-size: 1em;
`;
const notificationTime = 200;
// #endregion

// #region functions
const msgExcerpt = (msg, len) => {
  if (msg.length > len) {
    return `${msg.substring(0, len)}...`;
  }
  return msg;
};
// #endregion

// #region component
const propTypes = {
  name: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.shape()),
};

const defaultProps = {
  name: '',
  errors: [],
};

/**
 *
 */
const FasatFormErrors = ({ name, errors }) => (
  <div
    id={`${name}-errors-custom`}
    aria-atomic="true"
    aria-live="off"
    role="alert"
    data-testid="inline-errors-root"
  >
    {errors.map(({ id, msg }) => (
      <ErrMsg key={id} title={msg}>
        {msgExcerpt(msg, notificationTime)}
      </ErrMsg>
    ))}
  </div>
);

FasatFormErrors.propTypes = propTypes;
FasatFormErrors.defaultProps = defaultProps;
// #endregion

export default FasatFormErrors;
