/**
 * @Description This file will have all the route information for administration module
 * @FileName routeConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:41:45
 * @IssueID - 318
 */
import { lazy } from 'react';
import { aclKeysConstant, routeConstant } from '../moduleConstants';

export const ProdSummary = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/prodSummaryList/prodSummaryList'
));
export const BalSummary = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/BalSummaryList/balSummaryList'
));
export const ReportRepository = lazy(() => import(
  '../containers/reportRepository/reportRepository'
));
export const ReportRepositoryExt = lazy(() => import(
  '../containers/reportRepositoryExt/reportRepository'
));
export const CompStatement = lazy(() => import(
  '../containers/compensationStatementList/compensationStatementList'
));
export const CommissionFeed = lazy(() => import(
  '../containers/commissionFeed/commissionFeedList'
));
export const InteractiveAcitvityReport = lazy(() => import(
  '../containers/interactiveActivityReport/interactiveActivityReportList'
));

const routeConfig = [
  {
    path: routeConstant.PROD_SUMMARY_ROUTE,
    exact: true,
    component: ProdSummary,
    id: 'ReportProdSummary',
    title: 'FASAT - ProductionSummary',
    pageName: 'FASAT - Production Summary', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_PS_MENU],
    acl: true,
    showInBatchProcess: true,
    public: false,
  },
  {
    path: routeConstant.BAL_SUMMARY_ROUTE,
    exact: true,
    component: BalSummary,
    id: 'ReportBalSummary',
    title: 'FASAT - BalanceSummary',
    pageName: 'FASAT - Balance Summary', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_BLS_MENU],
    acl: true,
    showInBatchProcess: true,
    public: false,
  },
  {
    path: routeConstant.REP_REPOSITORY_ROUTE,
    exact: true,
    component: ReportRepository,
    id: 'ReportRepository',
    title: 'FASAT - ReportRepository',
    pageName: 'FASAT - ReportRepository',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_CSR_MENU],
    acl: true,
    showInBatchProcess: true,
    public: false,
  },
  {
    path: routeConstant.COMP_STATEMENT_ROUTE,
    exact: true,
    component: CompStatement,
    id: 'compstatement',
    title: 'FASAT - CompStatement',
    pageName: 'FASAT - CompStatement',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_CMS_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.REP_REPOSITORY_EXT_ROUTE,
    exact: true,
    component: ReportRepositoryExt,
    id: 'ReportRepositoryExt',
    title: 'FASAT - ReportRepositoryExt',
    pageName: 'FASAT - ReportRepositoryExt',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_ECS_MENU],
    acl: true,
    showInBatchProcess: true,
    public: false,
  },
  {
    path: routeConstant.COMMISSION_FEED_ROUTE,
    exact: true,
    component: CommissionFeed,
    id: 'commissionfeed',
    title: 'FASAT - CommissionFeed',
    pageName: 'FASAT - CommissionFeed',
    authenticationRequired: false,
    aclKey: [aclKeysConstant.RBAC_CF_MENU],
    acl: true,
    public: false,
    showInBatchProcess: true,
  },
  {
    path: routeConstant.INTERACTIVE_ACTIVITY_REPORT_ROUTE,
    exact: true,
    component: InteractiveAcitvityReport,
    id: 'InteractiveAcitvityReport',
    title: 'FASAT - InteractiveAcitvityReport',
    pageName: 'FASAT - InteractiveAcitvityReport',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_IAR_MENU],
    acl: true,
    showInBatchProcess: true,
    public: false,
    // dbMapId: 'INTERACTIVE_REPORT_MENU',
  },
];

export default routeConfig;
