/* eslint-disable import/prefer-default-export */
/**
 * @Description Action types for
 * @FileName actionTypes.js
 * @Author pgoutam
 * @CreatedOn 03 Jan 23
 * @IssueID 220
 */

export const patInqActionTypes = {
  LOG_PATINQ_LIST_REQUEST: 'LOG_PATINQ_LIST_REQUEST',
  GET_PATINQ_LIST_REQUEST: 'GET_PATINQ_LIST_REQUEST',
  GET_PATINQ_LIST_RESPONSE: 'GET_PATINQ_LIST_RESPONSE',
  GET_PATINQ_LIST_ERROR: 'GET_PATINQ_LIST_ERROR',
  UPDATE_PATINQ_COLUMN_CONFIG: 'UPDATE_PATINQ_COLUMN_CONFIG',
  UPDATE_PATINQ_FILTER_CONFIG: 'UPDATE_PATINQ_FILTER_CONFIG',
  UPDATE_PATINQ_TABLE_CONFIGURATION: 'UPDATE_PATINQ_TABLE_CONFIGURATION',
  SET_PATINQ_DETAILS_RESPONSE: 'SET_PATINQ_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_PATINQ_USER_DEFAULT_FILTER: 'REMOVE_PATINQ_USER_DEFAULT_FILTER',
  // Reset store
  RESET_PATINQ_STORE: 'RESET_PATINQ_STORE',
  // Export List for BCR
  GET_PATINQ_EXPORT_REQUEST: 'GET_PATINQ_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE_PATINQ: 'RESET_SERVER_VALUE_PATINQ',
};
