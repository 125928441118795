/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { clientActionsTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'ClientFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});
const getLengthResponse = (state, action) => ({
  ...state,
  getLength: action.data,
});
const setIfExsists = (state, action) => ({
  ...state,
  relationalTable: action.data,
});
const setIfExsistsErr = (state, action) => ({
  ...state,
  relationalTableError: action.data,
});
const getCSListError = (state, action) => ({
  ...state,
  listRequestError: action.data,
  listRequestProcessedOn: new Date(),
  loading: false,
});

const getCliStrucListResponse = (state, action) => ({
  ...state,
  listData: action.data,
  listRequestError: null,
  listRequestProcessedOn: new Date(),
  loading: false,
});
const getResetCliStore = (state) => ({
  ...state,
  listData: null,
  listRequestError: null,
  listRequestProcessedOn: null,
  serverRequestError: null,
  loading: false,
});

const clientFilterListReq = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case clientActionsTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case clientActionsTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case clientActionsTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case clientActionsTypes.GET_CLIENT_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case clientActionsTypes.GET_CLIENT_EXSISTS_RESPONSE:
      return setIfExsists(state, action);
    case clientActionsTypes.GET_CLIENT_EXSISTS_ERROR:
      return setIfExsistsErr(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const clientFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case clientActionsTypes.GET_CLIENT_LENGTH_RESPONSE:
      return getLengthResponse(state, action);
    case clientActionsTypes.GET_CS_LIST_ERROR:
      return getCSListError(state, action);
    case clientActionsTypes.GET_CLIENT_STRUCTURE_LIST_RESPONSE:
      return getCliStrucListResponse(state, action);
    case clientActionsTypes.RESET_CLIENT_STORE:
      return getResetCliStore(state);
    case clientActionsTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    default:
      return null;
  }
};
const ClientFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateReqRes = clientFilterListReq(state, action);
  const stateConfig = clientFilterListConfig(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ClientFilterList;
