/**
 * @Description Fasat Sort Order Row Page
 * @FileName sortOrderRow.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 14 May, 2021 21:40:00
 * @IssueID - 436
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonPlusFilled, ButtonMinusOutlined } from '@manulife/mux-cds-icons';
import { FasatDropdown, FasatLabel } from '../..';
import { staticCommonLabelKeys } from '../../../moduleConstants';

const propTypes = {
  totalRows: PropTypes.arrayOf(PropTypes.shape()),
  customColumnOrder: PropTypes.arrayOf(PropTypes.shape()),
  index: PropTypes.number,
  uuid: PropTypes.string,
  item: PropTypes.string,
  asc: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  columnConfig: PropTypes.arrayOf(PropTypes.shape()),
  addRow: PropTypes.func,
  removeRow: PropTypes.func,
  removeSortedColumn: PropTypes.func,
  configColumn: PropTypes.func,
  showDuplicateMessage: PropTypes.func,
};

const defaultProps = {
  totalRows: [],
  customColumnOrder: [],
  index: 0,
  uuid: '',
  item: '',
  asc: '',
  columnConfig: [],
  addRow: () => undefined,
  removeRow: () => undefined,
  removeSortedColumn: () => undefined,
  configColumn: () => undefined,
  showDuplicateMessage: () => undefined,
};

const soFilterRowClass = 'so-filterRow';
const soFilterCellClass = 'so-filterCell';
const soSortOrderCellClass = 'so-sortOrderCell';
const soActionCellClass = 'so-actionCell';
const soBlankCellClass = 'so-blankCell';
const soColumnID = 'soColumn';
const soOperatorID = 'soOperator';

/**
 * set operator values
 * @param {any} val
 */
const handleOptionChange = (val, setOpertorValue) => {
  setOpertorValue(val);
};

const orderOptions = (t) => ([
  {
    label: t(staticCommonLabelKeys.FILTER_LABEL_ASCENDING),
    value: 'true',
  },
  {
    label: t(staticCommonLabelKeys.FILTER_LABEL_DESCENDING),
    value: 'false',
  },
]);
const getButton = ({ t, totalRows, removeSection, addRow, index, uuid }) => (
  <div className={soActionCellClass} id={`soAction${uuid}`}>
    {' '}
    {totalRows.length > 1 && (
    <button
      type="button"
      onClick={() => removeSection()}
      aria-label={t(staticCommonLabelKeys.ARIA_FILTER_BUTTON_DELETE_SORT_ORDER)}
    >
      {' '}
      <ButtonMinusOutlined />
      {' '}
    </button>
    )}
    {' '}
    {totalRows.length === index + 1 && (
    <button type="button" onClick={addRow} aria-label={t(staticCommonLabelKeys.ARIA_FILTER_BUTTON_ADD_SORT_ORDER)}>
      {' '}
      <ButtonPlusFilled />
      {' '}
    </button>
    )}
    {' '}
  </div>
);

/**
   * set column values
   * @param {any} id
   */
const handleColumnChange = ({ id, customColumnOrder, setColumnValue, operatorValue,
  showDuplicateMessage, setOpertorValue, columnValue }) => {
  const foundIndex = customColumnOrder.findIndex((x) => x.key === id);
  if (foundIndex < 0) {
    setColumnValue(id);
    if (operatorValue === null) {
      setOpertorValue('true');
    }
  } else {
    showDuplicateMessage();
    if (columnValue === null) {
      setOpertorValue(null);
      setColumnValue(null);
    }
  }
};

const SortOrderRow = ({
  totalRows,
  index,
  uuid,
  columnConfig,
  addRow,
  removeRow,
  removeSortedColumn,
  customColumnOrder,
  configColumn,
  showDuplicateMessage,
  item,
  asc,
}) => {
  const [columnValue, setColumnValue] = useState(null);
  const [operatorValue, setOpertorValue] = useState(null);
  const [t] = useTranslation();
  const dropdownPlaceholder = t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT);
  const sortOrderDropdownItems = orderOptions(t);

  /**
   * Set column value and order value as per selection
   */
  useEffect(() => {
    if (item) {
      setColumnValue(item);
      setOpertorValue(item ? JSON.stringify(asc) : null);
    }
  }, [item, asc]);

  /**
   * Set per selected Column Value and Order Value
   */
  useEffect(() => {
    configColumn({ key: columnValue, asc: JSON.parse(operatorValue), uuid });
  }, [columnValue, operatorValue]);

  /**
   * Remove Selection and reconfigure sorted column object
   */
  const removeSection = () => {
    removeRow(uuid);
    if (operatorValue !== null) {
      removeSortedColumn({ key: columnValue, asc: JSON.parse(operatorValue), uuid });
    }
  };

  return (
    <div className={soFilterRowClass}>
      <div className={soFilterCellClass}>
        <div className="noDeskDisp"><FasatLabel>Column</FasatLabel></div>
        <FasatDropdown
          dropdownItems={columnConfig}
          placeholder={dropdownPlaceholder}
          id={`${soColumnID}-${index}`}
          onHandleChange={(id) => handleColumnChange({ id,
            customColumnOrder,
            setColumnValue,
            operatorValue,
            showDuplicateMessage,
            setOpertorValue,
            columnValue })}
          value={columnValue}
          ariaLabel={t(staticCommonLabelKeys.FILTER_LABEL_COLUMN)}
        />
      </div>
      <div className={`${soFilterCellClass} ${soSortOrderCellClass}`}>
        <div className="noDeskDisp"><FasatLabel>Sort order</FasatLabel></div>
        <FasatDropdown
          dropdownItems={columnConfig.length > 0 ? sortOrderDropdownItems : []}
          placeholder={dropdownPlaceholder}
          id={`${soOperatorID}-${index}`}
          onHandleChange={(val) => handleOptionChange(val, setOpertorValue)}
          value={operatorValue}
          ariaLabel={t(staticCommonLabelKeys.FILTER_LABEL_SORT_ORDER)}
        />
      </div>
      {getButton({ t, totalRows, uuid, removeSection, addRow, index })}
      <div className={soBlankCellClass} />
    </div>
  );
};

SortOrderRow.propTypes = propTypes;
SortOrderRow.defaultProps = defaultProps;

export default SortOrderRow;
