/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { tempAdjActionTypes } from '../actions/actionTypes';
import { formatResponse, exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
  setUserPrefError,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for Temp Adj Listing
 */
export function* getTempAdjList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(tempAdjActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(tempAdjActionTypes.LOG_TEMPADJ_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TEMPADJ_LIST,
      action.data);
    yield all([
      put(getAction(tempAdjActionTypes.GET_TEMPADJ_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(tempAdjActionTypes.GET_TEMPADJ_LIST_ERROR, err)),
      put(getAction(tempAdjActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for Temp Adj details by ID
 */

export function* getTempAdjbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(tempAdjActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(tempAdjActionTypes.LOG_TEMPADJ_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TEMPADJ_BY_ID,
      action.data);
    yield all([
      put(getAction(tempAdjActionTypes.GET_TEMPADJ_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(tempAdjActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(tempAdjActionTypes.GET_TEMPADJ_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will update for existing Temp Adj
 */
export function* updateTempAdj(action) {
  yield all([
    put(processingStarted()),
    put(getAction(tempAdjActionTypes.UPSERT_TEMPADJ_REQUEST, action.data.formatObject)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TEMPADJ_UPDATE,
      action.data.formatObject);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(tempAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(tempAdjActionTypes.GET_TEMPADJ_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(tempAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will delete existing Template Adjustments
 */
export function* deleteTempAdj(action) {
  yield all([
    put(processingStarted()),
    put(setUserPrefError(null)),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TEMPADJ_DELETE,
      action.data.formData);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: { formData: action.data.formData,
          count: action.data.count,
          amount: action.data.amount },
      };
    }
    yield all([
      put(getAction(tempAdjActionTypes.RESET_DELETE_TEMPADJ_VALUE, resetDeleteValue)),
      put(getAction(tempAdjActionTypes.GET_TEMPADJ_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([put(getAction(tempAdjActionTypes.RESET_DELETE_TEMPADJ_VALUE, resetDeleteValue)),
      put(processingCompleted())]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will delete existing Template Adjustments
 */
export function* approveTempAdj(action) {
  yield all([
    put(processingStarted()),
    put(setUserPrefError(null)),
  ]);
  let resetApproveValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TEMPADJ_APPROVE,
      action.data.formData);
    if (responseData) {
      resetApproveValue = {
        approveRequest: null,
        approveRequestError: null,
        approveRequestedOn: null,
        approveRequestProcessedOn: new Date(),
        approveData: { formData: action.data.formData,
          count: action.data.count,
          amount: action.data.amount },
      };
    }
    yield all([
      put(getAction(tempAdjActionTypes.RESET_APPROVE_TEMPADJ_VALUE, resetApproveValue)),
      put(getAction(tempAdjActionTypes.GET_TEMPADJ_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetApproveValue = {
      approveRequest: null,
      approveRequestError: processError(err),
      approveRequestedOn: new Date(),
      approveRequestProcessedOn: null,
      approveData: null,
    };
    yield all([put(getAction(tempAdjActionTypes.RESET_APPROVE_TEMPADJ_VALUE, resetApproveValue)),
      put(processingCompleted())]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for Template Adjustments Export Listing
 */
export function* getTempAdjExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(tempAdjActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TEMPADJ_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(tempAdjActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
 * This generator function will invoke the api
 * @param {object} action - action which will delete existing Template Adjustments
 */
export function* importTempAdj(action) {
  yield all([
    put(processingStarted()),
    put(setUserPrefError(null)),
    // put(getAction(tempAdjActionTypes.RESET_DETAILDATA)),
  ]);
  let resetImportValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TEMPADJ_IMPORT,
      action.data.data);
    if (responseData) {
      resetImportValue = {
        detailRequest: null,
        detailRequestError: null,
        detailRequestedOn: null,
        detailRequestProcessedOn: new Date(),
        detailData: responseData,
      };
    }
    yield all([
      put(getAction(tempAdjActionTypes.IMPORT_TEMPADJ_RESPONSE, resetImportValue)),
      put(getAction(tempAdjActionTypes.GET_TEMPADJ_LIST_REQUEST, action.data.reqObj)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetImportValue = {
      detailRequest: null,
      detailRequestError: processError(err),
      detailRequestedOn: new Date(),
      detailRequestProcessedOn: null,
      detailData: null,
    };
    yield all([put(getAction(tempAdjActionTypes.RESET_IMPORT_TEMPADJ, resetImportValue)),
      put(processingCompleted())]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will last Import Template Adjustments
 */
export function* lastImport(action) {
  yield all([
    put(processingStarted()),
    put(setUserPrefError(null)),
  ]);
  let resetImportValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_TEMPADJ_LAST_IMPORT,
      action.data.reqImpObj);
    if (responseData) {
      if (responseData.lastModifyUser !== '') {
        resetImportValue = {
          detailData: null,
          detailRequest: null,
          detailRequestError: null,
          detailRequestedOn: null,
          detailRequestProcessedOn: new Date(),
          detailLastImpData: responseData,
        };
        yield all([
          put(getAction(tempAdjActionTypes.RESET_IMPORT_TEMPADJ, resetImportValue)),
          put(processingCompleted()),
        ]);
      } else {
        const { data, reqObj } = action.data;
        yield all([
          put(getAction(tempAdjActionTypes.IMPORT_TEMPADJ_REQUEST, { data, reqObj })),
          put(processingCompleted()),
        ]);
      }
    }
  } catch (err) {
    resetImportValue = {
      detailRequest: null,
      detailRequestError: processError(err),
      detailRequestedOn: new Date(),
      detailRequestProcessedOn: null,
      detailData: null,
      detailLastImpData: null,
    };
    yield all([put(getAction(tempAdjActionTypes.RESET_IMPORT_TEMPADJ, resetImportValue)),
      put(processingCompleted())]);
  }
}

function* moduleWatcher() {
  // template adjustments
  yield takeEvery(tempAdjActionTypes.GET_TEMPADJ_LIST_REQUEST, getTempAdjList);
  yield takeEvery(tempAdjActionTypes.UPDATE_TEMPADJ_REQUEST, updateTempAdj);
  yield takeEvery(tempAdjActionTypes.DELETE_TEMPADJ_REQUEST, deleteTempAdj);
  yield takeEvery(tempAdjActionTypes.APPROVE_TEMPADJ_REQUEST, approveTempAdj);
  yield takeEvery(tempAdjActionTypes.GET_TEMPADJ_EXPORT_REQUEST, getTempAdjExportList);
  // template adjustments itembyid
  yield takeEvery(tempAdjActionTypes.GET_TEMPADJ_BY_ID_REQUEST, getTempAdjbyID);
  // template adjustment import
  yield takeEvery(tempAdjActionTypes.IMPORT_TEMPADJ_REQUEST, importTempAdj);
  yield takeEvery(tempAdjActionTypes.LAST_IMPORT, lastImport);
}

export default moduleWatcher;
