import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { fieldForceActionTypes } from '../actions/actionTypes';

const cond1 = (rowData) => (rowData.data && rowData.data.length > 0 && rowData.data[0].status === null);
const cond2 = (rowData) => (rowData.data[0].document === null && rowData.data[0].oreStatusChageDt === null && rowData.data[0].oreUserid === null);

const FieldForceRequirement1 = (state, action = null) => {
  const rowData = action.data;
  switch (action.type) {
    case fieldForceActionTypes.GET_FF_REQUIREMENT_TABLE_RES:
      if (
        rowData
        && cond1(rowData)
        && cond2(rowData)
      ) {
        rowData.data.shift();
      }
      return {
        ...state,
        listData: action.data,
        requirementTableError: null,
        requirementTableProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_REQUIREMENT_TABLE_COMMENT:
      return {
        ...state,
        comment: action.data.comment,
        orgReqNotifyDt: action.data.orgReqNotifyDt,
        loading: false,
      };
    default:
      return null;
  }
};

const FieldForceRequirement2 = (state, action = null) => {
  switch (action.type) {
    case fieldForceActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };

    case fieldForceActionTypes.GET_FF_REQUIREMENT_LOOK_UP_DATA:
      return {
        ...state,
        pageLookup: action.data,
      };

    case fieldForceActionTypes.NOTIFY_SUCCESS_STATUS:
    case fieldForceActionTypes.FF_CLEAR_NOTIFY_STATUS:
      return {
        ...state,
        notifyStatus: action.data,
      };

    case fieldForceActionTypes.FF_SUCCESS_SAVE_COMMENT:
    case fieldForceActionTypes.FF_CLEAR_SAVE_COMMENT:
      return {
        ...state,
        saveComment: action.data,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};
const FieldForceRequirement = (
  state = { ...basicListAddEditDltInitialState, ...{ notifyStatus: null } },
  action = null,
) => {
  const ffr1 = FieldForceRequirement1(state, action);
  if (ffr1 !== null) {
    return ffr1;
  }
  const ffr2 = FieldForceRequirement2(state, action);
  if (ffr2 !== null) {
    return ffr2;
  }
  return state;
};

export default FieldForceRequirement;
