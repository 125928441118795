/**
 * @Description This file contains all the Menu configuration for administration module
 * @FileName menuConfig.js
 * @Author Jayanta Sarkhel- sarkhja
 * @CreatedOn 15 february, 2022 16:41:03
 * @IssueID - 459, 311
 */
import { Icon } from '@manulife/mux';
import { t } from '../../../common/i18n';
import { navigate } from '../../../common/util';
import { aclKeysConstant, routeConstant, staticLabelKeys } from '../moduleConstants';
import earningBar from '../../../common/assets/images/earningBar.svg';

/**
  * Menu configuration for the module
  */
// aclKey: [aclKeysConstant.REPORT],
// aclKey: [aclKeysConstant.RBAC_REP_PRODUCTION_SUMMARY],
// aclKey: [aclKeysConstant.RBAC_REP_BALANCE_SUMMARY],
const desktopMenus = () => [
  {
    id: 'report',
    sequence: 1,
    icon: <Icon path={earningBar} alt="icon" style={{ width: '20px', height: '20px' }} />,
    label: t(staticLabelKeys.REPORT_MENU),
    aria: t(staticLabelKeys.REPORT_MENU),
    urlPath: '#',
    url: '#',
    action: () => navigate('#'),
    aclKey: [aclKeysConstant.RBAC_REPORTS],
    acl: true,
    showInBatchProcess: true,
    subMenu: [
      {
        id: 'PRODUCTION_SUMMARY_MENU',
        label: t(staticLabelKeys.PRODSUM_PAGE_TITLE),
        urlPath: routeConstant.PROD_SUMMARY_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.PROD_SUMMARY_ROUTE),
        aclKey: [aclKeysConstant.RBAC_PS_MENU],
        acl: true,
        showInBatchProcess: true,
      },
      {
        id: 'BALANCE_SUMMARY_MENU',
        label: t(staticLabelKeys.BLS_PAGE_TITLE),
        urlPath: routeConstant.BAL_SUMMARY_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.BAL_SUMMARY_ROUTE),
        aclKey: [aclKeysConstant.RBAC_BLS_MENU],
        acl: true,
        showInBatchProcess: true,
      },
      {
        id: 'REPORT_REPOSITORY_MENU',
        label: t(staticLabelKeys.CSR_PAGE_TITLE),
        urlPath: routeConstant.REP_REPOSITORY_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.REP_REPOSITORY_ROUTE),
        aclKey: [aclKeysConstant.RBAC_CSR_MENU],
        acl: true,
        showInBatchProcess: true,
      },
      {
        id: 'COMPANSATION_STATEMENT_MENU',
        label: t(staticLabelKeys.CSR_PAGE_TITLE),
        urlPath: routeConstant.REP_REPOSITORY_EXT_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.REP_REPOSITORY_EXT_ROUTE),
        aclKey: [aclKeysConstant.RBAC_ECS_MENU],
        acl: true,
        showInBatchProcess: true,
      },
      {
        id: 'COMMISSION_FEED_MENU',
        label: t(staticLabelKeys.CMFD_TITLE_COMM_FEED),
        urlPath: routeConstant.COMMISSION_FEED_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.COMMISSION_FEED_ROUTE),
        aclKey: [aclKeysConstant.RBAC_CF_MENU],
        showInBatchProcess: true,
        acl: true,
      },
      {
        id: 'INTERACTIVE_REPORT_MENU',
        // label: t(staticLabelKeys.REPR_PAGE_TITLE),
        label: t(staticLabelKeys.IAR_LABEL_IAR_TITLE),
        urlPath: routeConstant.INTERACTIVE_ACTIVITY_REPORT_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.INTERACTIVE_ACTIVITY_REPORT_ROUTE),
        aclKey: [aclKeysConstant.RBAC_IAR_MENU],
        showInBatchProcess: true,
        acl: true,
        // external: true,
      },
    ],
  },
];
const mobileMenus = desktopMenus;
export { desktopMenus, mobileMenus };
