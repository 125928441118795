/* eslint-disable no-unused-vars */
/**
 * @Description Fasat distinct
 * @FileName distinct.js
 * @Author Karthick. K - kkarthk
 * @CreatedOn 29 Aug, 2022 21:40:00
 * @IssueID - 3628
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FasatButtonSelector from '../../fasatButtonSelector/fasatButtonSelector';
import { staticCommonLabelKeys } from '../../../moduleConstants';

const Distinct = ({ isGroupByActive, isDistinct, setIsDistinct, setCustomColumnSorting }) => {
  const [t] = useTranslation();
  const handleDistinct = () => {
    setIsDistinct(!isDistinct);
    setCustomColumnSorting([]);
  };

  return (
    <>
      <FasatButtonSelector
        values={[{ label: t(staticCommonLabelKeys.COMMON_BUTTON_YES), value: true }, {
          label: t(staticCommonLabelKeys.COMMON_BUTTON_NO),
          value: false,
        }]}
        customStyle={{ rootStyle: { width: '240px', paddingTop: '0px', paddingBottom: '12px' } }}
        onHandleChange={handleDistinct}
        id="distinct_option"
        name="distinct-option"
        value={isDistinct}
        disabled={isGroupByActive && 'disabled'}
      />
    </>
  );
};
// #region component
Distinct.propTypes = {
  isGroupByActive: PropTypes.bool,
  isDistinct: PropTypes.bool,
  setIsDistinct: PropTypes.func,
  setCustomColumnSorting: PropTypes.func,
};

Distinct.defaultProps = {
  isGroupByActive: false,
  isDistinct: false,
  setIsDistinct: () => undefined,
  setCustomColumnSorting: () => undefined,
};
export default Distinct;
