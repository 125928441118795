import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actionBodyGenerator, FasatModal, TableDefinition } from '../../../../common/components';
import { globalConstants, staticCommonLabelKeys } from '../../../../common/moduleConstants';
import { generateTableTextColumn, getFasatFilterSetting, getFasatGrid, getFasatGridBar } from '../../../moduleUtility';
import { COLUMN_TYPE, currencyFormat, getCurrentLocaleFormListData, initialReqObject, Logger,
  notEmpty, resetUpsertValue, onColumnResizeHandler, onSortByHandler, onFilterHandler, formatToPercentage,
} from '../../../../common/util';
import { staticLabelKeys, aclKeysConstant } from '../../moduleConstants';
import { getCurrentLocale } from '../../../../common/i18n';
import { ClientCommonActionTypes, fieldForceActionTypes, fieldForceDeductionActionTypes } from '../../redux';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import DeleteDeduction from '../deleteDeduction/deleteDeduction';
import { filterConfig } from './fieldForceDeductionConfig';
import { lookUpsDataProcessing } from '../../../../common/redux/actions/commonActions';

const numberTwo = 2;
const numberFour = 4;

const adeAgtCd = 'adeAgtCd';
const adeSeq = 'adeSeq';
const type = 'type';
const active = 'active';
const adeAmt = 'amount';
const calcMethod = 'calcMethod';
const frequency = 'frequency';
const adeEffDt = 'adeEffDt';
const adeLstProcDt = 'adeLstProcDt';
const adeEndDt = 'adeEndDt';
const agentReq = 'agentReq';
const adeTransAgtCd = 'adeTransAgtCd';
const adePercent = 'percent';
const adeDays = 'adeDays';
const adeTargetAmt = 'targetAmt';
const adeCumulativeAmt = 'cumulativeAmt';
const monTarget = 'monTarget';
const adeMonthlyTargetAmt = 'monthlyTargetAmt';
const adeMonthlyCumulativeAmt = 'monthlyCumulativeAmt';
const splPayProc = 'splPayProc';
const adePctOfBal = 'pctOfBal';
const adePctOfFyrComm = 'pctOfFyrComm';
const adePctOfRenComm = 'pctOfRenComm';
const adePctOfFyrOvrde = 'pctOfFyrOvrde';
const adePctOfRenOvrde = 'pctOfRenOvrde';
const adeCostCenter = 'adeCostCenter';
const currency = 'currency';
const adePriority = 'adePriority';
const adeMemo = 'adeMemo';
const AdeMonthlyTargetSw = 'AdeMonthlyTargetSw';
const checkListDataFunc = (lstData) => (lstData && lstData.length > 0);
const listDataFunc = (listData) => (listData && listData.data ? listData.data : []);
const langCheck = (currentLocale) => (currentLocale === 'en');
const dateCond = ({ dataFieldKey }) => (dataFieldKey === adeEffDt
  || dataFieldKey === adeLstProcDt || dataFieldKey === adeEndDt);
const monthCumCheck = ({ dataFieldKey, cell }) => (dataFieldKey === adeMonthlyTargetAmt
   || dataFieldKey === adeMonthlyCumulativeAmt) && notEmpty(cell.row.original[dataFieldKey]);
const amtCurrencyCondCheck = ({ dataFieldKey, cell }) => (dataFieldKey === adeAmt || dataFieldKey === adeTargetAmt
  || dataFieldKey === adeCumulativeAmt) && notEmpty(cell.row.original[dataFieldKey]);
const defaultColumnOrder = [adeAgtCd,
  adeSeq,
  type,
  active,
  adeAmt,
  calcMethod,
  frequency,
  adeEffDt,
  adeLstProcDt,
  adeEndDt,
  agentReq,
  adeTransAgtCd,
  adePercent,
  adeDays,
  adeTargetAmt,
  adeCumulativeAmt,
  monTarget,
  AdeMonthlyTargetSw,
  adeMonthlyTargetAmt,
  adeMonthlyCumulativeAmt,
  splPayProc,
  adePctOfBal,
  adePctOfFyrComm,
  adePctOfRenComm,
  adePctOfFyrOvrde,
  adePctOfRenOvrde,
  adeCostCenter,
  currency,
  adePriority,
  adeMemo,
];
const percentageFields = [adePercent, adePctOfBal, adePctOfFyrComm, adePctOfRenComm, adePctOfFyrOvrde, adePctOfRenOvrde];
const two = 2;

const formatPercentageValues = (cell, dataFieldKey) => {
  let formattedValue = cell.row.original[dataFieldKey];
  if (percentageFields.includes(dataFieldKey)) {
    const precision = [adePercent, adePctOfBal].includes(dataFieldKey) ? numberFour : numberTwo;
    formattedValue = formatToPercentage(cell.row.original[dataFieldKey], precision);
  }
  return formattedValue;
};

const dateFormatFunc = (cell, dataFieldKey) => (cell.row.original[dataFieldKey]
  ? moment(cell.row.original[dataFieldKey]).format('MM/DD/YYYY') : '');
export const linkBodyGeneratorType = (action, dataFieldKey, listData) => (rowData) => {
  const { cell } = rowData;
  const lstData = listDataFunc(listData);
  if (checkListDataFunc(lstData)) {
    if (amtCurrencyCondCheck({ dataFieldKey, cell })) {
      const currentLocale = getCurrentLocaleFormListData(listData);
      if (langCheck(currentLocale)) {
        return `$${currencyFormat(cell.row.original[dataFieldKey]?.toFixed(two), currentLocale)}`;
      }
      return `${currencyFormat(cell.row.original[dataFieldKey]?.toFixed(two).toString(), currentLocale)}$`;
    }
    if (monthCumCheck({ dataFieldKey, cell })) {
      const currentLocale = getCurrentLocaleFormListData(listData);
      if (langCheck(currentLocale)) {
        return `$${currencyFormat(cell.row.original[dataFieldKey].toString(), currentLocale)}`;
      }
      return `${currencyFormat(cell.row.original[dataFieldKey].toString(), currentLocale)}$`;
    }
    if (dateCond({ dataFieldKey })) {
      return dateFormatFunc(cell, dataFieldKey);
    }
    return formatPercentageValues(cell, dataFieldKey);
  }
  return null;
};

const defaultColumnOrderTable = [
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: adeAgtCd,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_MLC_SELLING_CD,
    id: adeAgtCd,
    isAction: false,
    visibility: adeAgtCd,
    width: 200,
    sortAscending: adeAgtCd,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: adeSeq,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_SEQUENCE,
    id: adeSeq,
    isAction: false,
    visibility: adeSeq,
    width: 200,
    sortAscending: adeSeq,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: type,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_TYPE,
    id: type,
    isAction: false,
    visibility: type,
    width: 200,
    sortAscending: type,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: 'Transactions',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: active,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_ACTIVE,
    id: active,
    isAction: false,
    visibility: active,
    width: 200,
    sortAscending: active,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.BOOLEAN,
    lookupKey: 'YES',
  },
  {
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adeAmt,
    disableFilter: false,
    disableSortBy: false,
    header: staticCommonLabelKeys.COMMON_LABEL_AMOUNT,
    id: adeAmt,
    isAction: false,
    visibility: adeAmt,
    width: 200,
    sortAscending: adeAmt,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: null,
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: calcMethod,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_CALCMETHOD,
    id: calcMethod,
    isAction: false,
    visibility: calcMethod,
    width: 200,
    sortAscending: calcMethod,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: 'ADE_CalcMethod',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: frequency,
    disableFilter: false,
    disableSortBy: false,
    header: staticCommonLabelKeys.COMMON_LABEL_FREQUENCY,
    id: frequency,
    isAction: false,
    visibility: frequency,
    sortAscending: frequency,
    width: 160,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: 'ADE_Frequency',
  },
  {
    actions: [],
    body: null,
    columnType: 'date',
    dataField: adeEffDt,
    disableFilter: false,
    disableSortBy: false,
    header: staticCommonLabelKeys.COMMON_LABEL_STARTDATE,
    id: adeEffDt,
    isAction: false,
    visibility: adeEffDt,
    width: 160,
    sortAscending: adeEffDt,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.DATE,
    lookupKey: null,
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'date',
    dataField: adeLstProcDt,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_LASTPROCESSED,
    id: adeLstProcDt,
    isAction: false,
    visibility: adeLstProcDt,
    width: 200,
    sortAscending: adeLstProcDt,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.DATE,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'date',
    dataField: adeEndDt,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_ENDDATE,
    id: adeEndDt,
    isAction: false,
    visibility: adeEndDt,
    width: 200,
    sortAscending: adeEndDt,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.DATE,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: agentReq,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_ADVISORREQ,
    id: agentReq,
    isAction: false,
    visibility: agentReq,
    width: 200,
    sortAscending: agentReq,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.BOOLEAN,
    lookupKey: 'YES',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: adeTransAgtCd,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_MSC_TRANSFER,
    id: adeTransAgtCd,
    isAction: false,
    visibility: adeTransAgtCd,
    width: 200,
    sortAscending: adeTransAgtCd,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adePercent,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_PERC_SYMBOL,
    id: adePercent,
    isAction: false,
    visibility: adePercent,
    width: 200,
    sortAscending: adePercent,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: adeDays,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_DAYS,
    id: adeDays,
    isAction: false,
    visibility: adeDays,
    width: 200,
    sortAscending: adeDays,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adeTargetAmt,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_TARGETAMT,
    id: adeTargetAmt,
    isAction: false,
    visibility: adeTargetAmt,
    width: 200,
    sortAscending: adeTargetAmt,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adeCumulativeAmt,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_CUMULATIVEAMT,
    id: adeCumulativeAmt,
    isAction: false,
    visibility: adeCumulativeAmt,
    width: 200,
    sortAscending: adeCumulativeAmt,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: monTarget,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_MONTHTRG,
    id: monTarget,
    isAction: false,
    visibility: monTarget,
    width: 200,
    sortAscending: monTarget,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.BOOLEAN,
    lookupKey: 'YES',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adeMonthlyTargetAmt,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_MONTARGETAMT,
    id: adeMonthlyTargetAmt,
    isAction: false,
    visibility: adeMonthlyTargetAmt,
    width: 200,
    sortAscending: adeMonthlyTargetAmt,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: '',
    dataField: adeMonthlyCumulativeAmt,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_MONTHLYCUMULATIVE,
    id: adeMonthlyCumulativeAmt,
    isAction: false,
    visibility: adeMonthlyCumulativeAmt,
    width: 200,
    sortAscending: adeMonthlyCumulativeAmt,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: splPayProc,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_SPECIALPAY,
    id: splPayProc,
    isAction: false,
    visibility: splPayProc,
    width: 200,
    sortAscending: splPayProc,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.BOOLEAN,
    lookupKey: 'YES',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adePctOfBal,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_PERCNTOFBALANCE,
    id: adePctOfBal,
    isAction: false,
    visibility: adePctOfBal,
    width: 200,
    sortAscending: adePctOfBal,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adePctOfFyrComm,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_PERCNTOFFYC,
    id: adePctOfFyrComm,
    isAction: false,
    visibility: adePctOfFyrComm,
    width: 200,
    sortAscending: adePctOfFyrComm,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adePctOfRenComm,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_PERCOFRENCOMM,
    id: adePctOfRenComm,
    isAction: false,
    visibility: adePctOfRenComm,
    width: 200,
    sortAscending: adePctOfRenComm,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adePctOfFyrOvrde,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_PERCOFFYROVRDE,
    id: adePctOfFyrOvrde,
    isAction: false,
    visibility: adePctOfFyrOvrde,
    width: 200,
    sortAscending: adePctOfFyrOvrde,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'action',
    dataField: adePctOfRenOvrde,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_PERCOFRENOVRDE,
    id: adePctOfRenOvrde,
    isAction: false,
    visibility: adePctOfRenOvrde,
    width: 200,
    sortAscending: adePctOfRenOvrde,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: adeCostCenter,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_COSTCENTER,
    id: adeCostCenter,
    isAction: false,
    visibility: adeCostCenter,
    width: 200,
    sortAscending: adeCostCenter,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: currency,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_CURRENCY,
    id: currency,
    isAction: false,
    visibility: currency,
    width: 200,
    sortAscending: currency,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: 'Currency',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: adePriority,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_PRIORITY,
    id: adePriority,
    isAction: false,
    visibility: adePriority,
    width: 200,
    sortAscending: adePriority,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.NUMBER,
    lookupKey: '',
  },
  {
    FilterDefination: null,
    actions: [],
    body: null,
    columnType: 'text',
    dataField: adeMemo,
    disableFilter: false,
    disableSortBy: true,
    header: staticLabelKeys.CM_DE_LABEL_CHQMEMOREMARK,
    id: adeMemo,
    isAction: false,
    visibility: adeMemo,
    width: 200,
    sortAscending: adeMemo,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: '',
  },
];
const modalWidth = '560px';

const getDelClientAddModal = ({ displayDeleteModal, t, setdisplayDeleteModal, formData, reqObj }) => (
  <FasatModal
    title={null}
    display={displayDeleteModal}
    modalWidth={modalWidth}
    primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_DELETE)}
    secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_CANCEL)}
    primaryTopic="DELETE_FF_DEDUCTION"
    secondaryTopic="DELETE_FF_DEDUCTION_CANCEL"
    modalClass="modalWithoutHeading"
    modalCosed={() => setdisplayDeleteModal(false)}
  >
    <DeleteDeduction
      onCancelTopic="DELETE_FF_DEDUCTION_CANCEL"
      onDeleteTopic="DELETE_FF_DEDUCTION"
      formData={formData}
      reqObj={reqObj}
    />
  </FasatModal>
);

const getConfigDelData = (deleteItems) => ({
  adeAgtCd: deleteItems.adeAgtCd,
  adeSeq: deleteItems.adeSeq,
});
/**
  * @param {Array} options
  * Set item for Delete Client Licence
  * @returns {Array}
  */
const generateDeleteSection = ({ dispatch, setFormData,
  displayDeleteModal, setdisplayDeleteModal }, deleteItems) => {
  const resetDeletetValue = {
    deleteRequest: null,
    deleteRequestError: null,
    deleteRequestedOn: null,
    deleteRequestProcessedOn: null,
    deleteData: null,
  };
  dispatch(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeletetValue));
  const configDelData = getConfigDelData(deleteItems);
  setFormData(configDelData);
  setdisplayDeleteModal(!displayDeleteModal);
};

const generateTableDefination = ({
  t,
  dispatch,
  listData,
  columnOrder,
  sortOptions,
  setFormData,
  setdisplayDeleteModal,
  displayDeleteModal,
  setCloseAddEditModal,
  setAdddEditActBtn,
  loggedUser,
  groupByParameters,
  reqObj,
}) => {
  const tableDef = new TableDefinition('clientLicenceList', 'clientLicenceListID');
  generateTableTextColumn({ defaultColumnOrderTable,
    tableDef,
    listData,
    columnOrder,
    sortOptions,
    setCloseAddEditModal,
    setAdddEditActBtn,
    groupByParameters,
    t,
    reqObj }, linkBodyGeneratorType, listData);
  const actionColumn = {
    FilterDefination: null,
    actions: [
      {
        actionHandler: (rowData) => {
          const locale = getCurrentLocale();
          const reqObjForId = initialReqObject({ locale, loggedUser, lockType: globalConstants.lockEdit });
          delete reqObjForId.active;
          delete reqObjForId.entityId;
          delete reqObjForId.groupByParameters;
          reqObjForId.lock.entityKey = '38';
          reqObjForId.lock.recordKey = rowData.row.original.adeAgtCd;
          reqObjForId.parameters = [{
            key: 'adeId',
            operator: 'OPT_EQUALS',
            type: 'text',
            values: [rowData.row.original.adeId],
          },
          ];
          dispatch(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
          dispatch(getAction(fieldForceActionTypes.GET_FF_DEDUCTION_ITEM_BY_ID,
            { reqObjForId }));
        },
        className: 'pi pi-user-edit',
        hoverIcon: 'editIconHover',
        icon: 'editIcon',
        id: 'edit',
        name: 'Edit',
        ariaLabel: t(staticCommonLabelKeys.ARIA_COMMON_GRID_BUTTON_EDIT),
        order: 1,
        aclkey: aclKeysConstant.RBAC_DED_EDIT,
      },
      {
        actionHandler: (rowData, colData) => {
          Logger.info(`Delete Handler Row Data, ${rowData}`);
          Logger.info(`Delete Handler Column Data', ${colData}`);
          generateDeleteSection({ dispatch,
            setFormData,
            displayDeleteModal,
            setdisplayDeleteModal }, rowData.row.original);
        },
        className: 'p-button-rounded p-button-warning',
        hoverIcon: 'deleteIconHover',
        icon: 'deleteIcon',
        id: 'delete',
        name: 'Deleted',
        ariaLabel: t(staticCommonLabelKeys.ARIA_COMMON_GRID_BUTTON_DELETE),
        order: 1,
        aclkey: aclKeysConstant.RBAC_DED_DELETE,
      },
    ],
    columnType: 'action',
    dataField: null,
    id: 'action',
    isAction: true,
    visibility: true,
    disableSortBy: true,
    disableFilter: false,
    filterOptions: [],
    filterType: null,
    header: t(staticCommonLabelKeys.COMMON_LABEL_ACTIONS),
    width: 85,
  };
  actionColumn.body = actionBodyGenerator(
    actionColumn.actions,
    (action, rowData, columnData) => action && rowData && columnData,
  );
  tableDef.addColumn(actionColumn);
  tableDef.setColumnResizable();
  return tableDef;
};
const getLookUpInfoPayload = (locale) => [
  {
    type: 'Currency',
    depth: 0,
    value: '1',
    locale,
  },
  {
    type: 'YES',
    depth: 0,
    value: '',
    locale,
  },
  {
    type: 'ADE_Frequency',
    depth: 0,
    value: '',
    locale,
  },
  {
    type: 'ADE_CalcMethod',
    depth: 0,
    value: '',
    locale,
  },
  {
    type: 'Transactions',
    depth: 0,
    value: '',
    locale,
  },
];
const getTableData = ({ listData }) => {
  const currentPage = listData?.page || 1;
  const totalRows = listData?.totalRows || 0;
  const rowCount = listData && listData.data ? listData.data.length : 0;
  const GridPageSize = listData?.pageSize || globalConstants.pageSizeTen;
  return { currentPage, totalRows, rowCount, GridPageSize };
};
export const getDefaultNF = ({ parameters = null, currentPage, pageSize, locale, location }) => {
  const defaultNewCustomFilter = initialReqObject({ parameters, currentPage, pageSize, locale, sortOptions: [] });
  defaultNewCustomFilter.parameters = [
    { key: 'adeAgtCd', operator: 'OPT_EQUALS', type: 'text', values: [location.state?.orgCode] },
  ];
  defaultNewCustomFilter.columnOrder = defaultColumnOrder;
  return defaultNewCustomFilter;
};

const setDefaultFilter = ({ defaultNewCustomFilter, dispatch, sysDefaultFilter }) => {
  const newFilterObject = JSON.parse(JSON.stringify(sysDefaultFilter));
  newFilterObject.detail.parameters = defaultNewCustomFilter.parameters;
  newFilterObject.isCustomizedSysDefault = true;
  dispatch(getAction(filterConfig.UPDATE_TABLE_CONFIGURATION, newFilterObject));
};
export const columnSettings = (listColumnOrder, setColumnOrder) => {
  if (listColumnOrder) {
    setColumnOrder(listColumnOrder);
  }
};
const defaultList = (dispatch, listParamOptions, location, sortOptions) => {
  const parameters = [
    {
      key: 'adeAgtCd',
      operator: 'OPT_EQUALS',
      type: 'text',
      values: [location.state?.orgCode],
    },
  ];
  const columnOrder = defaultColumnOrder;
  if (listParamOptions === null) {
    dispatch(getAction(fieldForceDeductionActionTypes.RESET_DEDUCTION_PARAMS, parameters));
    dispatch(getAction(fieldForceDeductionActionTypes.RESET_DEDUCTION_PARAMS_COL, columnOrder));
    dispatch(getAction(fieldForceDeductionActionTypes.RESET_DEDUCTION_PARAMS_SORT, sortOptions));
  }
};
const initializeReqObj = ({ checkTempConfig, currentLocale, listParamOptions, currentPage, pageSize,
  listGroupBy, listSortOptions, selectedFilter, isDistinct, listColumnOrder }) => {
  let reqObj = {};
  if (checkTempConfig) {
    reqObj = initialReqObject({
      locale: currentLocale,
      parameters: listParamOptions,
      groupByParameters: listGroupBy,
      sortOptions: listSortOptions,
      currentPage,
      pageSize,
      isDistinct,
      listColumnOrder,
    });
  } else {
    reqObj = initialReqObject({
      locale: currentLocale,
      parameters: selectedFilter?.detail.parameters || [],
      sortOptions: selectedFilter?.detail.sortOptions || [],
      groupByParameters: selectedFilter?.detail.groupByParameters || [],
      currentPage,
      pageSize,
      isDistinct,
      listColumnOrder,
    });
  }
  return reqObj;
};
const FieldForceDeductionList = ({ setClientAdddEditActBtn, reqObj, onPageChangeHandler, fasatGrid }) => {
  const [listData, setListData] = useState([]);
  const locale = getCurrentLocale();
  const [activateDataGrid, setActivateDataGrid] = useState(true);
  const listDatas = useSelector((state) => state.FFDeduction.listData);
  const lookupData = useSelector((state) => state.ApplicationState.lookupData);
  const { selectedFilter, listGroupBy: groupByParameters, isDistinctActive: isDistinct,
    listColumnOrder: listColumnOrders, listParamOptions, listGroupBy, listSortOptions } = useSelector((state) => state.FFDeduction);
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [displayDeleteModal, setdisplayDeleteModal] = useState(false);
  const [formData, setFormData] = useState({});
  const location = useLocation();
  const sortOptions = [{
    key: adeAgtCd,
    asc: true,
  }];
  const [temporaryFilterConfig, setPreSelectedFilterConfig] = useState(false);
  const { currentPage, totalRows, rowCount, GridPageSize, checkTempConfig } = getTableData({ listData });
  const defaultNewCustomFilter = getDefaultNF({ currentPage, locale, location, pageSize: GridPageSize });
  const sysDefaultFilter = useSelector((state) => state[filterConfig.REDUX_STATE].systemDefaultFilter);
  useEffect(() => {
    if (!checkTempConfig && sysDefaultFilter) {
      setDefaultFilter({ defaultNewCustomFilter, dispatch, sysDefaultFilter });
    }
  }, [sysDefaultFilter]);
  useEffect(() => {
    defaultList(dispatch, listParamOptions, location, sortOptions);
  }, [listParamOptions]);
  const lookUpInfoPayload = getLookUpInfoPayload(locale);
  useEffect(() => dispatch(lookUpsDataProcessing(lookUpInfoPayload)), [locale]);
  const listColumnOrder = [adeAgtCd, adeSeq, type, active, adeAmt, calcMethod, frequency, adeEffDt,
    adeLstProcDt, adeEndDt, agentReq, adeTransAgtCd, adePercent, adeDays, adeTargetAmt, adeCumulativeAmt, monTarget];
  const [columnOrder, setColumnOrder] = useState(defaultColumnOrder);
  useEffect(() => columnSettings(listColumnOrders, setColumnOrder), [listColumnOrders]);
  const triggerActivateDataGrid = () => setActivateDataGrid(true);
  const paginationInfo = { currentPage, totalRows, rowCount, pageSize: GridPageSize };
  const resetPreSelectedFilterConfig = () => setPreSelectedFilterConfig(false);
  const iroParams = { locale, currentPage, listColumnOrders, sortOptions, groupByParameters, isDistinct, parameters: listParamOptions, pageSize: GridPageSize };
  const reqObjectId = initialReqObject(iroParams);
  useEffect(() => {
    setListData(listDatas);
  }, [listDatas]);
  const f1 = { t, dispatch, listData, columnOrder, sortOptions, setFormData, lookupData, pageSize: GridPageSize };
  const f2 = { setdisplayDeleteModal, displayDeleteModal, triggerActivateDataGrid, entityId: 38 };
  const f3 = { generateTableDefination, filterConfig, staticLabelKeys, reqObj, setAddNew: () => undefined };
  const f4 = { screenName: staticLabelKeys.CS_TITLE_LIST, activateDataGrid, groupByParameters };
  const FASAT_FILTER_SETTING = { ...f1, ...f2, ...f3, ...f4 };
  let reqObjArgs = { checkTempConfig, currentLocale: locale, listParamOptions, currentPage, pageSize: GridPageSize };
  reqObjArgs = { ...reqObjArgs, listGroupBy, listSortOptions, selectedFilter, isDistinct, listColumnOrder };
  const reqObjForDelete = initializeReqObj(reqObjArgs);
  return (
    <div className="clientListContainer">
      {getFasatFilterSetting(FASAT_FILTER_SETTING, resetPreSelectedFilterConfig, temporaryFilterConfig)}
      {getFasatGridBar(fasatGrid)}
      {getFasatGrid({ generateTableDefination,
        t,
        listData,
        columnOrder,
        listColumnOrder,
        sortOptions,
        paginationInfo,
        displayDeleteModal,
        dispatch,
        setFormData,
        onSortByHandler,
        onPageChangeHandler,
        onFilterHandler,
        onColumnResizeHandler,
        setdisplayDeleteModal,
        groupByParameters,
        activateDataGrid: true,
        setAdddEditActBtn: setClientAdddEditActBtn,
        reqObj: reqObjectId,
        filterConfig: fasatGrid.filterConfig,
      }, t(staticLabelKeys.CM_DE_TITLE_LIST))}
      {getDelClientAddModal({ displayDeleteModal, t, setdisplayDeleteModal, formData, reqObj: reqObjForDelete })}
    </div>
  );
};
FieldForceDeductionList.propTypes = {
  reqObj: PropTypes.shape({}),
  fasatGrid: PropTypes.shape({
    filterConfig: PropTypes.shape({}),
  }),
  setClientAdddEditActBtn: PropTypes.func,
  onPageChangeHandler: PropTypes.func,
};

FieldForceDeductionList.defaultProps = {
  reqObj: {},
  fasatGrid: {},
  setClientAdddEditActBtn: () => undefined,
  onPageChangeHandler: () => undefined,
};

export default FieldForceDeductionList;
