/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { actionBodyGenerator, FasatExpandablePanel, FasatLink, FasatRouterLink, TableDefinition } from '../../../../common/components';
import { generateTableTextColumn, getFasatGrid, getFasatGridBar } from '../../../moduleUtility';
import { globalConstants, staticCommonLabelKeys } from '../../../../common/moduleConstants';
import { getCurrentLocale } from '../../../../common/i18n';
import { currencyFormat, defaultColumnValueText, getDateFormat,
  initialReqObject, Logger, negetiveValueFormat,
  onColumnResizeHandler,
  onSortByHandler,
  onFilterHandler } from '../../../../common/util';
import { staticLabelKeys } from '../../../report/moduleConstants';
import { getCompStateList } from '../../../../common/redux/actions/commonActions';
import { repReActionTypes } from '../../../report/redux';
import { getAction } from '../../../../../stateManagement/reduxUtil';

const rateType = 'type';
const manulifeSellingCode = 'manulifeSellingCd';
const level = 'level';
const amount = 'amount';
const externalName = 'name';
const externalRate = 'rate';
const fromDate = 'fromDate';
const toDate = 'date';
const listPayload = {
  export: 'NONE',
  page: 1,
  pageSize: 10,
  locale: 'en',
  lookUpInformation: [],
  parameters: [],
  groupByParameters: [],
  sortOptions: [],
};
let rowSelected = [];

export const defaultColumnOrder = [externalName, manulifeSellingCode, level, toDate, amount, rateType];

const defaultColumnOrderTable = [
  {
    ...defaultColumnValueText({ field: externalName, label: staticCommonLabelKeys.COMMON_LABEL_NAME }),
    width: 260,
  }, {
    ...defaultColumnValueText({ field: manulifeSellingCode, label: staticCommonLabelKeys.COMMON_LABEL_MLC_SELLING_CD }),
    width: 260,
  }, {
    ...defaultColumnValueText({ field: level, label: staticCommonLabelKeys.COMMON_LABEL_LEVEL, action: 'action' }),
    width: 160,
  }, {
    ...defaultColumnValueText({ field: toDate, label: staticCommonLabelKeys.COMMON_LABEL_DATE, action: 'action' }),
    width: 320,
  }, {
    ...defaultColumnValueText({ field: amount, label: staticCommonLabelKeys.COMMON_LABEL_AMOUNT, action: 'action' }),
    width: 100,
  }, {
    ...defaultColumnValueText({ field: rateType, label: staticCommonLabelKeys.COMMON_LABEL_TYPE }),
    width: 320,
  },
];

export const getTableData = ({ listData, location, sortOptions }) => {
  const currentPage = listData?.page || 1;
  const totalRows = listData?.totalRows || 0;
  const rowCount = listData && listData.data ? listData.data.length : 0;
  const checkTempConfig = !!(location.state === 'bcrDetail' && sortOptions);
  const gridPageSize = listData?.pageSize || globalConstants.pageSizeTen;
  return { currentPage, totalRows, rowCount, checkTempConfig, gridPageSize };
};
export const getCurrentLocaleForListData = (listData) => ((listData.locale === 'en' || listData.locale === 'E')
  ? 'en' : 'fr');
export const dateCurrencyCol = (actions, dataFieldKey, listData) => (rowData) => {
  const currentLocale = listData ? getCurrentLocaleForListData(listData) : 'en';
  const decimalPlace = '2';
  const { cell } = rowData;
  if (dataFieldKey === 'amount') {
    const amt = cell.row.original[dataFieldKey] || 0;
    if (currentLocale === 'en') {
      return `$${negetiveValueFormat(amt.toString(), currentLocale, decimalPlace)}`;
    }
    return `${negetiveValueFormat(amt.toString(), currentLocale, decimalPlace)}$`;
  }
  if (dataFieldKey === 'date') {
    return cell.row.original[dataFieldKey].toString();
  }
  if (dataFieldKey === 'level' && cell.row.original[dataFieldKey]) {
    return cell.row.original[dataFieldKey].split('-')[1];
  }
  return null;
};
export const generateTableDefination = ({ t, listData, columnOrder, sortOptions }) => {
  const tableDef = new TableDefinition('rateMasterList', 'rateMasterListID');
  generateTableTextColumn(
    { defaultColumnOrderTable, tableDef, listData, columnOrder, sortOptions, t },
    dateCurrencyCol,
    listData,
  );
  tableDef.setColumnResizable();
  return tableDef;
};

export const setRowsSel = ({ rows, setEnableButton }) => {
  if (rows.length > 0) {
    let configRow = [];
    rows.map((item) => {
      configRow = [...configRow, item.original];
      return item;
    });
    if (configRow.length > 0) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
    rowSelected = configRow;
  } else {
    setEnableButton(false);
  }
};

export const getPayload = (rows, download) => {
  const payload = {
    searchType: 0,
    repIDs: [],
    isSingleDoc: true,
  };
  if (rows.length > 1 && download) {
    payload.isSingleDoc = false;
  }
  rows.forEach((item) => {
    payload.repIDs.push(item.repId);
  });
  return payload;
};

const downloadPDF = (dispatch) => {
  dispatch(getAction(repReActionTypes.COMP_STATEMENT_DOWNLOAD, { payload: getPayload(rowSelected, true) }));
};

export default function fasatCompStatement() {
  const activateDataGrid = true;
  const [expand, setExpand] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const locale = getCurrentLocale();
  const [displayModal, setDisplayModal] = useState(false);
  const [displayDeleteModal, setdisplayDeleteModal] = useState(false);
  const [listData, setListData] = useState(null);
  const listColumnOrder = defaultColumnOrder;
  const columnOrder = defaultColumnOrder;
  const sortOptions = useSelector((state) => state.RateMasterDetailList.listSortOptions);
  const parameters = useSelector((state) => state.RateMasterDetailList.listParamOptions);
  const { currentPage, totalRows, rowCount, gridPageSize } = getTableData({ listData, location, sortOptions });
  const [pageSize, setGridPageSize] = useState(gridPageSize);
  const paginationInfo = { currentPage, totalRows, rowCount, pageSize };
  const defaultEntityFilterConfig = {
    listSortOptions: sortOptions || [],
    listParamOptions: parameters || [],
    listColumnOrder: defaultColumnOrder,
    selectedFilter: {},
    listGroupBy: [],
    isDistinctActive: true,
  };
  const listActive = true;
  const [enableButton, setEnableButton] = useState(false);
  const [t] = useTranslation();
  const reqObj = initialReqObject({ locale, parameters, currentPage, pageSize, sortOptions, listColumnOrder });
  useEffect(() => {
    if (locale) {
      const payload = { ...reqObj, locale, isDashBoard: true };
      dispatch(getCompStateList({ payload, setListData }));
    }
  }, [locale]);
  const onPageChangeHandler = (currPage) => {
    reqObj.page = currPage;
    reqObj.pageSize = pageSize;
    reqObj.active = listActive;
    const payload = { ...reqObj, locale, isDashBoard: true };
    dispatch(getCompStateList({ payload, setListData }));
  };
  const onChangePageSize = (currentPageSize) => {
    setGridPageSize(currentPageSize);
    reqObj.pageSize = currentPageSize;
    reqObj.active = listActive;
    const payload = { ...reqObj, locale, isDashBoard: true };
    dispatch(getCompStateList({ payload, setListData }));
  };
  const getSelectedRows = (rows) => setRowsSel({ rows, setEnableButton });
  const onClickDownload = () => downloadPDF(dispatch);
  const gb1 = { currentPage, pageSize, onChangePageSize, totalRows, dispatch, pageSizeID: 'compStmtDashboardPageSize' };
  const gb2 = { setDisplayModal, displayModal, defaultEntityFilterConfig, exportAction: false, addAction: false };
  const gb3 = { downloadBtn: { downloadAction: listActive, onClickDownload, downloadLabel: t(staticLabelKeys.CSR_LABEL_DOWNLOAD_SELECTED), enableDownload: enableButton } };
  const gb4 = { addBtnAriaLabel: t(staticLabelKeys.ARIA_RATE_KEY_BUTTON_ADD) };
  const FASAT_GRID_BAR = { ...gb1, ...gb2, ...gb3, ...gb4, listActive };
  const isCompCheckbox = (listData?.data?.length === 0 || listData === null) ? 1 : 0;
  const g1 = { t, dispatch, listData, columnOrder, sortOptions, setDisplayModal, displayModal };
  const g2 = { setdisplayDeleteModal, displayDeleteModal, listColumnOrder, paginationInfo, activateDataGrid };
  const g3 = { onColumnResizeHandler, onSortByHandler, onFilterHandler, onPageChangeHandler, staticLabelKeys };
  const g4 = { generateTableDefination, reqObj, isPeriodDefinition: 'Comp' };
  const FASAT_GRID = { ...g1, ...g2, ...g3, ...g4, isCompCheckbox, hasRowSelect: listActive, getSelectedRows };
  return (
    <FasatExpandablePanel
      expandedByDefault={!expand}
      onToggle={() => setExpand(false)}
      title={t(staticLabelKeys.CSR_PAGE_TITLE_DASHBOARD)}
      className="fasatExpandablePanel"
    >
      <div className="">
        {t(staticLabelKeys.CSR_LABEL_SHOW_RECENT)}
        {' '}
        <FasatRouterLink
          to={{
            pathname: 'reports/reportRepositoryExt',
          }}
        >
          {t(staticLabelKeys.CSR_LABEL_VIEW_ALL)}
        </FasatRouterLink>
      </div>
      { getFasatGridBar(FASAT_GRID_BAR)}
      <div className="compstatementlist">{ getFasatGrid(FASAT_GRID, `${t(staticLabelKeys.CSR_PAGE_TITLE_DASHBOARD)}`)}</div>
    </FasatExpandablePanel>
  );
}
