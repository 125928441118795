/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { systemOptionsActionType, accessOptionsActionType } from '../actions/actionTypes';

import { exportErrorMsg, formatResponse, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

/**
   * Get Access Options List
   * @param {any} action
   */
export function* getAccessOptsList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, null)),
    put(getAction(accessOptionsActionType.RESET_SERVER_VALUE, null)),
    put(getAction(accessOptionsActionType.LOG_ACCESSOPTS_LIST_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_ACCESSOPTS_LIST, action.data);
    yield all([
      put(getAction(accessOptionsActionType.GET_ACCESSOPTS_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, processError(err))),
      put(getAction(accessOptionsActionType.GET_ACCESSOPTS_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * Get Access Options by id
   * @param {object} action
   */
export function* getAccessOptsbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, null)),
    put(getAction(accessOptionsActionType.LOG_ACCESSOPTS_BY_ID_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_ACCESSOPTS_BY_ID, action.data);
    yield all([
      put(getAction(accessOptionsActionType.GET_ACCESSOPTS_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
   * @param {object} action - action which will insert for Access Options
   */
export function* insertAccessOpts(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_ACCESSOPTS_INSERT,
      action.data.formatAddObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(accessOptionsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(accessOptionsActionType.GET_ACCESSOPTS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(accessOptionsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
// update Access Options
export function* updateAccessOpts(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_ACCESSOPTS_UPDATE,
      action.data.formatEditObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([put(getAction(accessOptionsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(accessOptionsActionType.GET_ACCESSOPTS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(accessOptionsActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for SP Export Listing
  */
export function* getAOExportList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(accessOptionsActionType.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_ACCESSOPTS_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode403 || err.response.status === globalConstants.errorCode402) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = exportMaxLimitErrorObj;
    } else {
      resetServerValue = processError(err);
    }
    yield all([
      put(getAction(systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will delete existing Access Options
   */
export function* deleteAO(action) {
  let resetDeleteValue = null;
  yield all([
    put(processingStarted())]);
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_ACCESSOPTS_DELETE,
      action.data.formatObject,
    );
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(accessOptionsActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(accessOptionsActionType.GET_ACCESSOPTS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(accessOptionsActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  // Access Options
  yield takeEvery(accessOptionsActionType.DELETE_ACCESSOPTS_REQUEST, deleteAO);
  yield takeEvery(accessOptionsActionType.INSERT_ACCESSOPTS_REQUEST, insertAccessOpts);
  yield takeEvery(accessOptionsActionType.GET_ACCESSOPTS_LIST_REQUEST, getAccessOptsList);
  yield takeEvery(accessOptionsActionType.GET_ACCESSOPTS_BY_ID_REQUEST, getAccessOptsbyID);
  yield takeEvery(accessOptionsActionType.UPDATE_ACCESSOPTS_REQUEST, updateAccessOpts);
  yield takeEvery(accessOptionsActionType.GET_ACCESSOPTS_EXPORT_REQUEST, getAOExportList);
}
export default moduleWatcher;
