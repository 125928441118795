/**
 * @Description Fasat Filet Criteria
 * @FileName filterCriteria.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 14 May, 2021 21:40:00
 * @IssueID - 436
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import FilterCriteriaRow from './filterCriteriaRow';
import './filterCriteria.scss';
import { staticCommonLabelKeys } from '../../../moduleConstants';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

const filterCriteria = ({
  columns,
  operatorList,
  lookupData,
  onValueChange,
  removeFilterCriteriaElement,
  customFilterCreteriaElement,
  showDuplicateMessage,
  freezeColumn,
  validateFilterCriteria,
}) => {
  const [t] = useTranslation();
  const [totalRows, setTotalRows] = useState([{ uuid: uuidv4() }]);
  const [availableColumns, setAvailableColumns] = useState([]);

  /**
   * Reset pre selected rows
   */
  useEffect(() => {
    if (customFilterCreteriaElement && customFilterCreteriaElement.length > 0) {
      if (freezeColumn) {
        setAvailableColumns(columns.filter(({ value }) => !freezeColumn.includes(value)));
      } else {
        setTotalRows(customFilterCreteriaElement);
      }
      setTotalRows(customFilterCreteriaElement);
    } else {
      setTotalRows([{ uuid: uuidv4() }]);
    }
  }, [customFilterCreteriaElement]);
  /**
   * Filter out column if required
   */
  useEffect(() => {
    if (columns && freezeColumn) {
      setAvailableColumns(columns.filter(({ value }) => !freezeColumn.includes(value)));
    } else {
      setAvailableColumns(columns);
    }
  }, [columns]);
  /**
   * Adding a row
   */
  const addRow = () => {
    setTotalRows((arr) => [...arr, { uuid: uuidv4() }]);
  };

  /**
   * Removing a row
   * @param {any} val
   */
  const removeRow = (val) => {
    const hasIndex = customFilterCreteriaElement.find(({ uuid }) => uuid === val);
    if (hasIndex) {
      removeFilterCriteriaElement(val);
    } else {
      const filteredCol = totalRows.filter(({ uuid }) => uuid !== val);
      setTotalRows(filteredCol);
    }
  };

  /**
   * Class names
   */
  const fcFilterTableClass = 'fc-filterTable';
  const fcFilterRowClass = 'fc-filterRow';
  const fcFilterCellClass = 'fc-filterCell';
  const fcValueCellClass = 'fc-valueCell';
  const fcActionCellClass = 'fc-actionCell';
  return (
    <div className={fcFilterTableClass}>
      <div className={`${fcFilterRowClass} deskDisp filterR`}>
        <div className={fcFilterCellClass}>{t(staticCommonLabelKeys.FILTER_LABEL_COLUMN)}</div>
        <div className={fcFilterCellClass}>{t(staticCommonLabelKeys.FILTER_LABEL_OPERATOR)}</div>
        <div className={fcValueCellClass}>{t(staticCommonLabelKeys.FILTER_LABEL_VALUE)}</div>
        <div className={fcActionCellClass} />
      </div>
      {totalRows.map(({ uuid }, idx) => (
        <FilterCriteriaRow
          key={uuid}
          addRow={addRow}
          removeRow={removeRow}
          uuid={uuid}
          index={idx}
          totalRows={totalRows}
          columnConfig={availableColumns}
          operatorList={operatorList}
          lookupData={lookupData}
          onValueChange={onValueChange}
          customFilterCreteriaElement={customFilterCreteriaElement}
          showDuplicateMessage={showDuplicateMessage}
          validateFilterCriteria={validateFilterCriteria}
        />
      ))}
    </div>
  );
};

filterCriteria.propTypes = propTypes;
filterCriteria.defaultProps = defaultProps;
// #endregion

export default filterCriteria;
