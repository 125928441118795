/**
 * @Description Reducer for Auto Reports
 * @FileName autoReports.js
 * @Author Shreya Dhara - dharshr
 * @CreatedOn 14 September - 2023
 * @IssueID - 209
 */
import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { autoReportsActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'AutoReportsList';

const getAutoReportListResponse = (state, action) => ({
  ...state,
  listData: action.data,
  listRequestError: null,
  listRequestProcessedOn: new Date(),
  loading: false,
});
const getAutoReportListError = (state, action) => ({
  ...state,
  listRequestError: action.data,
  listRequestProcessedOn: new Date(),
  loading: false,
});
const logAutoReportListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});
const getResetAutoReportStore = (state) => ({
  ...state,
  listData: null,
  listRequestError: null,
  listRequestProcessedOn: null,
  serverRequestError: null,
  loading: false,
});
const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});
const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};
const getAutoReportsDetails = (state, action) => ({
  ...state,
  detailData: action.data,
  detailRequestProcessedOn: new Date(),
  loading: false,
});
const AutoReportsListREQRES = (state, action = null) => {
  switch (action.type) {
    case autoReportsActionTypes.GET_AUTOREPORTS_LIST_RESPONSE:
      return getAutoReportListResponse(state, action);
    case autoReportsActionTypes.GET_AUTOREPORTS_LIST_ERROR:
      return getAutoReportListError(state, action);
    case autoReportsActionTypes.LOG_AUTOREPORTS_LIST_REQUEST:
      return logAutoReportListRequest(state, action);
    case autoReportsActionTypes.RESET_AUTOREPORTS_STORE:
      return getResetAutoReportStore(state);
    case autoReportsActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case autoReportsActionTypes.GET_AUTOREPORTS_ITEM_RESPONSE:
      return getAutoReportsDetails(state, action);
    default:
      return null;
  }
};
const AutoReportsListConfig = (state, action = null) => {
  switch (action.type) {
    case autoReportsActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case autoReportsActionTypes.SET_AR_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case autoReportsActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case autoReportsActionTypes.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: action.data && action.data.serverRequestError,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const AutoReportsTabList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateReqRes = AutoReportsListREQRES(state, action);
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  const stateConfig = AutoReportsListConfig(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  return state;
};

export default AutoReportsTabList;
