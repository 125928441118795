/* eslint-disable no-debugger */
/**
 * @Description Base reducer for the application
 * @FileName ApplicationBaseReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:48:53
 * @IssueID - 308
 */
import { commonTypes } from '../actions/commonActions';
import { initialApplicationState, initialApplicationStateRBAC } from '../../../../stateManagement/reduxUtil';

const groupLevelRBACDataByLevel = (levelRBACdata) => Object.groupBy(levelRBACdata?.access, ({ role }) => role);

const applicationStatePartOne = (state = { ...initialApplicationState }, action = null) => {
  switch (action.type) {
    // When application get loaded
    case commonTypes.APPLICATION_LOADED:
      return {
        ...state,
        loaded: action.data.isLoaded,
      };
    case commonTypes.SET_LOOKUPS_DATA:
      return {
        ...state,
        lookupData: action.data,
      };
    case commonTypes.SET_SYSOPT_DATA:
      return {
        ...state,
        sysOptData: action.data,
      };
    case commonTypes.SET_SYS_RUN_DATE:
      return {
        ...state,
        sysRunDate: action.data,
      };
    case commonTypes.RESET_SYS_RUN_DATE:
      return {
        ...state,
        sysRunDate: null,
      };
      // reset Upset Value
    case commonTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case commonTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
        upsertData: null,
      };
      // reset Delete Value
    case commonTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    default:
      return null;
  }
};

const applicationStatePartTwo = (state = { ...initialApplicationState }, action = null) => {
  switch (action.type) {
    // When application get loaded
    case commonTypes.RESET_BATCH_VALUE:
      return {
        ...state,
        batchRequestError: action.data.batchRequestError,
        batchRequestedOn: action.data.batchRequestedOn,
      };
    case commonTypes.SET_DIRTY_DATA:
      return {
        ...state,
        isDirty: action.data,
      };
    case commonTypes.SET_BATCH_PROCESSING_STATUS:
      return {
        ...state,
        isBatchProcessing: action.data,
      };
    case commonTypes.SET_LOCK:
      return {
        ...state,
        lockDetails: action.data,
      };
    case commonTypes.SETUSERQUICKSLINKS:
      return {
        ...state,
        usersQuicksLinks: action.data,
      };
    case commonTypes.SETALLQUICKSLINKS:
      return {
        ...state,
        allQuicksLinks: action.data,
      };
    case commonTypes.SETIARDATA:
      return {
        ...state,
        iraData: action.data,
      };
    case commonTypes.REMOVE_LOCK:
      return {
        ...state,
        lockDetails: null,
      };
    default:
      return null;
  }
};
/**
    * Base reducer to manage application default state
    * @param {object} state
    * @param {object} action
    */
export const ApplicationState = (state = initialApplicationState, action = null) => {
  const statePartOne = applicationStatePartOne(state, action);
  const statePartTwo = applicationStatePartTwo(state, action);
  if (statePartOne !== null) {
    return statePartOne;
  }
  if (statePartTwo !== null) {
    return statePartTwo;
  }
  return state;
};

const applicationRBACStatePartOne = (state = { ...initialApplicationStateRBAC }, action = null) => {
  switch (action.type) {
    // When application get loaded
    case commonTypes.SET_ROLE_LIST:
      return {
        ...state,
        roleList: action.data,
      };
      /** Authentication And Authorization */
    case commonTypes.SET_USER_DATA:
      return {
        ...state,
        loggedUser: action.data,
      };
    case commonTypes.SET_RBAC_DATA:
      return {
        ...state,
        rbacData: action.data,
        userlang: action.data.userLang ? action.data.userLang : 'E',
        rbacApiCalled: true,
      };
    case commonTypes.SET_RBAC_API_CALLED:
      return {
        ...state,
        rbacApiCalled: true,
      };
    case commonTypes.SET_USER_PREF_DATA:
      return {
        ...state,
        userPrefData: action.data,
      };
    case commonTypes.SET_USER_PREF_ERROR:
      return {
        ...state,
        userPrefError: action.data,
      };
    case commonTypes.SET_USER_CONTACT_INFO:
      return {
        ...state,
        userContactInfo: action.data,
      };
    case commonTypes.SET_USER_LANG:
      return {
        ...state,
        userlang: action.data,
      };
    default:
      return null;
  }
};

const applicationRBACStatePartTwo = (state = { ...initialApplicationStateRBAC }, action = null) => {
  switch (action.type) {
    // When application get loaded
    case commonTypes.SET_BATCH_EXPORT_DATA:
      return {
        ...state,
        batchExportData: action.data,
      };
    case commonTypes.SET_NOT_LOGIN:
      return {
        ...state,
        notLogin: action.data,
      };
    case commonTypes.STORE_SCREEN_TIME:
      return {
        ...state,
        screenTime: action.data,
      };
    case commonTypes.SET_EXTERNAL_USER:
      return {
        ...state,
        isExternalUser: action.data,
      };
    case commonTypes.SET_LEVEL_RBAC_DATA:
      return {
        ...state,
        levelRBACData: groupLevelRBACDataByLevel(action.data),
      };
    default:
      return null;
  }
};

export const ApplicationStateRBAC = (state = initialApplicationStateRBAC, action = null) => {
  const stateRBACPartOne = applicationRBACStatePartOne(state, action);
  const stateRBACPartTwo = applicationRBACStatePartTwo(state, action);
  if (stateRBACPartOne !== null) {
    return stateRBACPartOne;
  }
  if (stateRBACPartTwo !== null) {
    return stateRBACPartTwo;
  }
  return state;
};
