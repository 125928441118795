/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { clientAddressActionTypes,
  clientNotesActionTypes, ClientCommonActionTypes } from '../actions/actionTypes';
import { formatResponse, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';

const ERROR_MSG_LENGTH_EXPORT = 'The number of records has exceeded the maximum limit.';
const errorCode = 402;
const exportMaxLimitErrorObj = [
  {
    errorCode: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
    errorMessage: ERROR_MSG_LENGTH_EXPORT,
    errorMessageId: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
    type: 'RULE',
  },
];

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for Notes Export Listing
 */
export function* getCliNotesExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_NOTES_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === errorCode) {
      const errorObject = exportMaxLimitErrorObj;
      resetServerValue = errorObject;
    } else {
      resetServerValue = processError(err);
    }
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getNotesList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, null)),
    put(getAction(clientAddressActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(clientAddressActionTypes.LOG_CLIENT_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_NOTES_LIST, action.data);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert for new BCR
  */
export function* insertClientNotes(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_NOTES_INSERT,
      action.data.getUpSertObj.addEditData);
    resetUpsertValue = {
      upsertRequest: null,
      upsertData: responseData,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(clientNotesActionTypes.GET_CLIENT_NOTES_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestProcessedOn: null,
      upsertRequestedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

export function* getNotesbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(clientAddressActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_NOTES_BY_ID,
      action.data);
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_BY_ID_RESPONSE,
        formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will update for existing Temp Adj
  */
export function* updateNotes(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(clientBankingActionTypes.UPDATE_CLIENT_BANKING__REQUEST, action.data.formatObject)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_NOTES_UPDATE,
      action.data.getUpSertObj.addEditData);
    resetUpsertValue = {
      upsertRequestError: null,
      upsertRequest: null,
      upsertRequestedOn: null,
      upsertData: responseData,
      upsertRequestProcessedOn: new Date(),
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(clientNotesActionTypes.GET_CLIENT_NOTES_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertRequestError: processError(err),
      upsertData: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing BCR
  */
export function* deleteNotes(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CLIENT_NOTES_DELETE,
      action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(clientNotesActionTypes.GET_CLIENT_NOTES_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(ClientCommonActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  // NOTES
  yield takeEvery(clientNotesActionTypes.GET_CLIENT_NOTES_LIST_REQUEST, getNotesList);
  yield takeEvery(clientNotesActionTypes.INSERT_CLIENT_NOTES_REQUEST, insertClientNotes);
  yield takeEvery(clientNotesActionTypes.GET_CLN_BY_ID_REQUEST, getNotesbyID);
  yield takeEvery(clientNotesActionTypes.UPDATE_CLIENT_NOTES__REQUEST, updateNotes);
  yield takeEvery(clientNotesActionTypes.DELETE_CLIENT_NOTES_REQUEST, deleteNotes);
  yield takeEvery(clientNotesActionTypes.GET_CLIENT_NOTES_EXPORT_REQUEST, getCliNotesExportList);
}

export default moduleWatcher;
