/**
 * @Description File description.
 * @FileName fasatTextFilter.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 07 July, 2021 03:11:13
 * @IssueID - Issue IDs
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { Icon } from '@manulife/mux';
import { useTranslation } from 'react-i18next';
import FasatTextInput from '../../fasatTextInput/fasatTextInput';
import searchIcon from '../../../assets/images/search-icon.svg';
import { staticCommonLabelKeys } from '../../../moduleConstants';

const FasatTextInputContainer = styled.div`
  .fasatTextInput {
    margin-top: 8px;
  }
`;
const changeValueTwo = 2;
const FasatTextFilter = ({ column }) => {
  const [t] = useTranslation();
  const { filterValue, setFilter, primaryTopic } = column;
  const [value, setValue] = useState(filterValue);
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);

  /**
   * Subscribe button Clicl Event from Modal
   */
  useEffect(() => {
    if (primaryTopic && primaryTopic !== '') {
      const subId = PubSub.subscribe(primaryTopic, () => {
        setValue(null);
        setFilter(null);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);

  /**
   * column onChange filter
   */
  const onChange = (changeValue) => {
    setValue(changeValue.target.value);
    if (changeValue.target.value.length > changeValueTwo || changeValue.target.value.length < 1) {
      setFilter(changeValue.target.value || undefined);
    }
  };

  return (
    <FasatTextInputContainer>
      <FasatTextInput
        value={value || ''}
        onChange={(val) => onChange(val)}
        placeholder={t(staticCommonLabelKeys.FILTER_LABEL_SEARCH_HELP)}
        icon={<Icon path={searchIcon} alt="icon" width="20px" height="20px" />}
      />
    </FasatTextInputContainer>
  );
};

FasatTextFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func,
    primaryTopic: PropTypes.string,
  }).isRequired,
};

export default FasatTextFilter;
