/* eslint-disable react/prop-types */
/**
 * @Description Fasat Actionable Text Input container page
 * @FileName fasatActionableTextInput.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 24 March, 2021 06:04:16
 * @IssueID 671
 */
import React from 'react';
import PropTypes from 'prop-types';
import { ActionableTextInput } from '../../libs/fasatSharedComponent';

const FasatActionableTextInput = ({
  inlineButtonText,
  placeholder,
  errors,
  changeValues,
  type,
  value,
  label,
  ariaLabel,
}) => (
  <div data-testid="FasatActionableTextInputComponent">
    <ActionableTextInput
      inlineButtonText={inlineButtonText}
      placeholder={placeholder}
      errors={errors}
      changeValues={changeValues}
      type={type}
      values={value}
      label={label}
      ariaLabel={ariaLabel}
    />
  </div>
);

FasatActionableTextInput.propTypes = {
  inlineButtonText: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  changeValues: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.string,
      isError: PropTypes.bool,
      msg: PropTypes.string,
    },
  )),
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
};

FasatActionableTextInput.defaultProps = {
  inlineButtonText: '',
  placeholder: '',
  type: 'text',
  changeValues: () => undefined,
  errors:
     [{
       id: '',
       isError: false,
       msg: '',
     }],
  label: '',
  ariaLabel: '',
};

export default FasatActionableTextInput;
