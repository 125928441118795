import React from 'react';
import { useTranslation } from 'react-i18next';
import { FasatMessageCard } from './app/common/components';
import { staticLabelKeys } from './app/modules/activity/moduleConstants';
import { globalConstants, staticCommonLabelKeys } from './app/common/moduleConstants';

const { serverErrorMsgContainer } = globalConstants;

export default function FasatHomeMessageAlert() {
  const [t] = useTranslation();
  const url = t(staticLabelKeys.FASAT_HOME_MESSAGE);
  const splittedUrl = url.split(' ');
  const check = (link) => (
    <>
      <br />
      <a href={link}>{t(staticLabelKeys.FASAT_HOME_MESSAGE_CLICK)}</a>
      <br />
    </>
  );
  return (
    <div className={`${serverErrorMsgContainer}`}>
      <FasatMessageCard
        hasCloseButton
        titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
        variant="primary_information"
      >
        {splittedUrl.map((idx, key) => (splittedUrl[key].match(/\bhttps?:\/\/\S+/gi) ? check(splittedUrl[key]) : ` ${splittedUrl[key]}`))}
      </FasatMessageCard>
    </div>
  );
}
