/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/**
 * @Description This is a wrapper for "Checkbox" component
 * @FileName fasatCheckbox.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 18 February, 2021 05:49:30
 * @IssueID CDTFASATMOD- 570
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@manulife/mux';
import FasatLabel from '../fasatLabel/fasatLabel';
import './fasatCheckbox.scss';
import FasatFormErrors from '../fasatFormErrors/fasatFormErrors';

const renderSec = ({ extRenderCheckbox, id, labelText, val1, val2 }) => (
  <FasatLabel htmlFor={id} isContent>
    {extRenderCheckbox ? (
      <>
        {labelText}
        {val1}
      </>
    ) : (
      <>
        {labelText}
        {val2}
      </>
    ) }
  </FasatLabel>
);

const FasatCheckbox = (props) => {
  const { labelText, name, errors, id, className, defaultChecked, isCustom, extRenderCheckbox, cItem } = props;
  const { onHandleChange, ...rest } = props;
  const inputClass = `fasatCheckbox ${className}`;
  const fasatInputErrorClass = 'withError';
  const [checkValue, setCheckValue] = useState(defaultChecked);
  const val1 = (
    <>
      <span className="indicator"> *</span>
    </>
  );
  const val2 = (
    <>
      <span className="indicator"> #</span>
    </>
  );

  const changeHandler = (val) => {
    onHandleChange(val);
  };
  useEffect(() => setCheckValue(defaultChecked), [defaultChecked]);

  const renderCheckBox = () => (
    <div
      className={`${inputClass} ${
        errors.length > 0 && errors[0].isError ? fasatInputErrorClass : ''
      }`}
    >
      {!isCustom && (
      <Checkbox
        {...rest}
        onChange={changeHandler}
      />
      )}

      {isCustom && (
      <input
        {...rest}
        type="checkbox"
        onChange={(e) => onHandleChange(e.target.checked)}
        checked={checkValue}
        className="sdCheckbox"
      />
      )}
      {!extRenderCheckbox && (cItem.resourceName !== 'RBAC_REPORTS')
        ? (
          <FasatLabel htmlFor={id} isContent>
            {labelText}
          </FasatLabel>
        )
        : renderSec(({ extRenderCheckbox, id, labelText, val1, val2 }))}
      <FasatFormErrors name={name} errors={errors} />
    </div>
  );
  return (
    <>
      {renderCheckBox()}
    </>
  );
};

FasatCheckbox.propTypes = {
  labelText: PropTypes.string,
  onHandleChange: PropTypes.func,
  errors: PropTypes.shape(),
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  isCustom: PropTypes.bool,
  extRenderCheckbox: PropTypes.bool,
  cItem: PropTypes.shape(),
};

FasatCheckbox.defaultProps = {
  labelText: '',
  onHandleChange: () => undefined,
  errors: [],
  name: '',
  id: '',
  className: '',
  defaultChecked: 0,
  isCustom: false,
  extRenderCheckbox: false,
  cItem: {},
};

export default FasatCheckbox;
