/**
 * @Description licence process list module saga/watcher file.
 * @FileName licenceModuleWatcher.js
 * @Author Meena Saini
 * @CreatedOn 3 March, 2023 10:00:00
 * @IssueID - -
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { licencesActionTypes, selectLetterActionTypes } from '../actions/actionTypes';
import { downloadErrorMessage, exportErrorMsg, Logger, processError,
  renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

export function* getLicenceList(action) {
  Logger.verbose(`Action Detail For Get Licence List : ${JSON.stringify(action)}`);
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(licencesActionTypes.RESET_SERVER_VALUE, resetServerValue)),
    put(getAction(licencesActionTypes.LOG_LICENCE_LIST_REQUEST, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.LICENCE_LIST,
      action.data);
    yield all([
      put(getAction(licencesActionTypes.GET_LICENCE_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(licencesActionTypes.GET_LICENCE_LIST_ERROR, err)),
      put(getAction(licencesActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getLicenceExportList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(licencesActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.LICENCE_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData).trim();
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(licencesActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* licenceChangeStatusReq(action) {
  Logger.verbose(`Action Detail to change status of selected licence : ${JSON.stringify(action)}`);
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(licencesActionTypes.RESET_SERVER_VALUE, resetServerValue)),
    put(getAction(licencesActionTypes.LOG_LICENCE_CHANGE_STATUS_REQ, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.LICENCE_CHANGE_STATUS,
      action.data);
    yield all([
      put(getAction(licencesActionTypes.LICENCE_CHANGE_STATUS_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(licencesActionTypes.LICENCE_CHANGE_STATUS_ERROR, err)),
      put(getAction(licencesActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* sendLettersInsertReq(action) {
  Logger.verbose(`Action Detail to insert selected FF or licence letters  : ${JSON.stringify(action)}`);
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(selectLetterActionTypes.RESET_SERVER_VALUE, resetServerValue)),
    put(getAction(selectLetterActionTypes.LOG_SELECT_LETTERS_INSERT_REQ, action.data)),
  ]);
  const initApiUrl = moduleAPI.LETTERS_INSERT_API.endpoint;
  try {
    const updatedApiUrl = `${initApiUrl}/${action.data.type}`;
    const apiEndpoint = { ...moduleAPI.LETTERS_INSERT_API };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi,
      apiEndpoint,
      action.data.selectedLettersInfo);
    yield all([
      put(getAction(selectLetterActionTypes.SELECT_LETTERS_INSERT_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(selectLetterActionTypes.SELECT_LETTERS_INSERT_ERROR, err)),
      put(getAction(licencesActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}
export function* getLettersList(action) {
  Logger.verbose(`Action Detail to get licence or FF letters list : ${JSON.stringify(action)}`);
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(selectLetterActionTypes.RESET_SERVER_VALUE, resetServerValue)),
    put(getAction(selectLetterActionTypes.LOG_GET_LETTERS_LIST_REQ, action.data)),
  ]);
  const initApiUrl = `${moduleAPI.LETTERS_LIST.endpoint}/${action.data.type}`;
  try {
    const params = `?letterType=${action.data.letterType}&locale=${action.data.locale}`;
    const updatedApiUrl = initApiUrl + params;
    const apiEndpoint = { ...moduleAPI.LETTERS_LIST };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    yield all([
      put(getAction(selectLetterActionTypes.GET_LETTERS_LIST_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(selectLetterActionTypes.GET_LETTERS_LIST_ERROR, err)),
      put(getAction(licencesActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* sendLockReq4ChangeStatus(action) {
  Logger.verbose(`Action Detail to change status concurrency : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(licencesActionTypes.LOG_LOCK_CHANGE_STATUS_REQ, action.data)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.LOCK_CHANGE_STATUS_API,
      action.data);
    yield all([
      put(getAction(licencesActionTypes.LOCK_CHANGE_STATUS_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(licencesActionTypes.RELEASE_CHANGE_STATUS)),
      put(getAction(licencesActionTypes.LOCK_CHANGE_STATUS_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}
export const errorFunc = (err) => {
  if (err.response.status === globalConstants.errorCode402) {
    const exportMaxLimitErrorObject = downloadErrorMessage(err.response.status);
    const resetServerValueOne = { serverRequestError: exportMaxLimitErrorObject };
    return resetServerValueOne;
  }
  const resetServerValue = { serverRequestError: processError(err) };
  return resetServerValue;
};
export function* downloadLettersTemplate(action) {
  let resetServerValue = { serverRequestError: null };
  Logger.verbose('Action to download letters template ');
  yield all([
    put(processingStarted()),
    put(getAction(selectLetterActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const apiEndpoint = { ...moduleAPI.FF_LETTERS_TEMPLATE_DOWNLOAD };
    const apiEndpointLicence = { ...moduleAPI.FF_LETTERS_LICENCE_TEMPLATE_DOWNLOAD };
    const responseData = yield call(invokeApi, action.data.reqObj4Download.letterType === 'A' ? apiEndpoint : apiEndpointLicence, action.data.reqObj4Download);
    const resetServerValueOne = responseData?.errors.length > 0 && { serverRequestError: processError({ response: { data: { errors: responseData?.errors } } }) };
    yield put(getAction(selectLetterActionTypes.DOWNLOAD_LETTERS_API_ERROR, responseData?.data));
    yield put(getAction(selectLetterActionTypes.RESET_SERVER_VALUE, responseData?.errors ? resetServerValueOne : null));
    const apiSend = responseData.downloadLettersResultToApilst?.filter((index) => index.fF_ID !== null);
    if (responseData.data === 'FORM_NODATAAGT') {
      yield put(processingCompleted());
    }
    if (apiSend.length > 0) {
      try {
        const responseDocx = yield call(invokeApi, moduleAPI.FF_LETTERS_MULTIPLE_TEMPLATE_DOWNLOAD, apiSend);
        const filename = renderFileName(responseDocx);
        const urlData = window.URL.createObjectURL(new Blob([responseDocx.data]));
        FileSaver(urlData, filename.replace(/^"(.*)"$/, '$1'));
        yield put(processingCompleted());
      } catch (err) {
        yield put(getAction(selectLetterActionTypes.RESET_SERVER_VALUE, errorFunc(err)));

        yield put(processingCompleted());
      }
    }
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(selectLetterActionTypes.DOWNLOAD_LETTERS_ERROR, resetServerValue)),
      put(getAction(selectLetterActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

function* moduleWatcher() {
  // licence
  yield takeEvery(licencesActionTypes.LOCK_CHANGE_STATUS, sendLockReq4ChangeStatus);
  yield takeEvery(licencesActionTypes.GET_LICENCE_LIST_REQUEST, getLicenceList);
  yield takeEvery(licencesActionTypes.GET_LICENCE_EXPORT_REQUEST, getLicenceExportList);
  yield takeEvery(licencesActionTypes.LICENCE_CHANGE_STATUS_REQ, licenceChangeStatusReq);
  yield takeEvery(selectLetterActionTypes.SELECT_LETTERS_INSERT_REQ, sendLettersInsertReq);
  yield takeEvery(selectLetterActionTypes.GET_LETTERS_LIST_REQ, getLettersList);
  yield takeEvery(selectLetterActionTypes.DOWNLOAD_LETTERS_TEMPLATE_REQ, downloadLettersTemplate);
}

export default moduleWatcher;
