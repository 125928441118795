import React, { useRef, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const IndeterminateCheckbox = forwardRef(({ indeterminate, row, checkboxAriaLabel, ...rest }, ref) => {
  const { original } = row;
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  let { title } = { ...rest };
  title = title || 'Select All';

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  return (
    <div className="gridCheckboxDiv">
      <input
        type="checkbox"
        disabled={original && original.isDefaultCheck}
        className="gridCheckbox"
        ref={resolvedRef}
        aria-label={checkboxAriaLabel}
        {...rest}
        title={title}
      />
    </div>
  );
});

IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.arrayOf([]),
  row: PropTypes.arrayOf(PropTypes.shape({
    isDefaultCheck: PropTypes.bool,
  })),
};

IndeterminateCheckbox.defaultProps = {
  indeterminate: [],
  row: [{ isDefaultCheck: false }],
};

export default IndeterminateCheckbox;
