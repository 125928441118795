import { ActionButton, colors } from '@manulife/mux';
import { Export } from '@manulife/mux-cds-icons';
import PropTypes from 'prop-types';
import FasatRBAC from '../fasatRBAC/fasatRBAC';

const icnExtCls = (activExBtn, color) => (!activExBtn ? color.dark_3_light_grey : color.m_green);
const icnHvExtCls = (actExBtn, color) => (!actExBtn ? color.dark_3_light_grey : color.dark_2_green);
const FasatExportButton = ({
  exportAclKey,
  rbacData,
  activateExportButton,
  onclickExport,
  ariaLabel,
  label }) => (
    <FasatRBAC aclKey={exportAclKey} userClaim={rbacData}>
      <ActionButton
        id="exportListButton"
        disabled={!activateExportButton}
        ariaLabel={ariaLabel}
        icon={<Export color={icnExtCls(activateExportButton, colors)} style={{ width: '20px', height: '20px' }} />}
        hoverIcon={(
          <Export
            color={icnHvExtCls(activateExportButton, colors)}
            style={{ width: '20px', height: '20px' }}
          />
            )}
        onClick={onclickExport}
      >
        {label}
      </ActionButton>
    </FasatRBAC>
);

FasatExportButton.propTypes = {
  exportAclKey: PropTypes.string,
  rbacData: PropTypes.shape(),
  activateExportButton: PropTypes.bool,
  onclickExport: PropTypes.func,
  ariaLabel: PropTypes.string,
  label: PropTypes.string,
};

FasatExportButton.defaultProps = {
  exportAclKey: false,
  rbacData: [],
  activateExportButton: false,
  onclickExport: () => undefined,
  ariaLabel: '',
  label: '',
};

export default FasatExportButton;
