import { typography } from '@manulife/mux';
import styled from 'styled-components';

const PX_REM_RATIO = 0.0625;
const rem = (px) => `${px * PX_REM_RATIO}rem`;
const remTwelve = 44;

export const Nav = styled.nav`
  display: flex;
  align-items: top;
`;
Nav.displayName = 'Nav';

export const ButtonControl = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
`;
ButtonControl.displayName = 'ButtonControl';

export const ScreenReaderOnly = styled.span`
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  border-width: 0px;
  margin: -1px;
  overflow: hidden;
  padding: 0px;
`;
ScreenReaderOnly.displayName = 'ScreenReaderOnly';

export const TextInputControl = styled.div`
  font-family: ${typography.fontFamily};
  font-size: ${typography.fontSize};
  font-weight: 600;
  display: flex;
  align-items: top;
  line-height: ${rem(remTwelve)};
`;
TextInputControl.displayName = 'TextInputControl';
