import moment from 'moment';
import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { patInqActionTypes } from '../actions/patInqActionTypes';
import { updateFilterReducer, updateTableConfig } from '../../../report/redux/reducers/utilities/utility';
import { commonTypes } from '../../../../common/redux/actions/commonActions';

export const REDUCER_NAME = 'ProducerAuditTrailFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: moment().format(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const ProducerAuditTrailFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case patInqActionTypes.UPDATE_PATINQ_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case patInqActionTypes.REMOVE_PATINQ_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case patInqActionTypes.UPDATE_PATINQ_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const ProducerAuditTrailFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case patInqActionTypes.LOG_PATINQ_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case patInqActionTypes.GET_PATINQ_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: moment().format(),
        loading: false,
      };
    // store response
    case patInqActionTypes.GET_PATINQ_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: moment().format(),
        loading: false,
      };
    case patInqActionTypes.RESET_PATINQ_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case patInqActionTypes.UPDATE_PATINQ_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case patInqActionTypes.RESET_SERVER_VALUE_PATINQ:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const ProducerAuditTrailFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = ProducerAuditTrailFilterListConfig(state, action);
  const stateReqRes = ProducerAuditTrailFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ProducerAuditTrailFilterList;
