/* eslint-disable no-unused-vars */
/**
 * @Description FasatColumnOrder Dual List File
 * @FileName DualListBox.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { escapeRegExp } from 'lodash';
import { nanoid } from 'nanoid';
import { ButtonDownOutlined, ButtonLeftOutlined, ButtonRightOutlined, ButtonUpOutlined } from '@manulife/mux-cds-icons';
import { colors } from '@manulife/mux';
import Action from './Action';
import ListBox from './ListBox';
import defaultLang from './lang/default';
import iconsShape from './shapes/iconsShape';
import languageShape from './shapes/languageShape';
import optionsShape from './shapes/optionsShape';
import valueShape from './shapes/valueShape';
import indexesOf from './util/indexesOf';
import arrowBottom from '../assets/images/arrow-bottom.svg';
import arrowBottomHover from '../assets/images/arrow-bottom_hover.svg';
import arrowBottomActive from '../assets/images/arrow-bottom_active.svg';
import arrowTop from '../assets/images/arrow-top.svg';
import arrowTopHover from '../assets/images/arrow-top_hover.svg';
import arrowTopActive from '../assets/images/arrow-top_active.svg';
import '../scss/columnFilter.scss';
import { staticCommonLabelKeys } from '../../../../moduleConstants';
import { Logger } from '../../../../util';

const ActionContainer = styled.div`
  .rdl-actions {
    button {
      svg {
        &:hover {
          color: ${colors.m_green};
        }
        &:active {
          color: ${colors.dark_2_green};
        }
      }
    }
  }
  .rdl-actions.actionUpDown {
    button {
      width: 32px;
      height: 32px;
      &:first-child {
        background: url( ${arrowTop}) no-repeat 0 0;
        background-size: 32px 29px;
        &:hover {
          background-image: url( ${arrowTopHover});
        }
        &:active {
          background-image: url( ${arrowTopActive});
        }
      }
      &:nth-child(4) {
        background: url( ${arrowBottom}) no-repeat 0 0;
        background-size: 32px 29px;
        &:hover {
          background-image: url( ${arrowBottomHover});
        }
        &:active {
          background-image: url( ${arrowBottomActive});
        }
      }
    }
  }
`;
const columnFilterContainer = 'columnFilterContainer';
const rdlActions = 'rdl-actions';
const actionLeftRight = 'actionLeftRight';
const actionUpDown = 'actionUpDown';
const KEY_CODES = {
  SPACEBAR: 32,
  ENTER: 13,
};
const ALIGNMENTS = {
  MIDDLE: 'middle',
  TOP: 'top',
};
export const defaultFilter = (option, filterInput) => {
  if (filterInput === '') {
    return true;
  }
  return new RegExp(escapeRegExp(filterInput), 'i').test(option.label);
};
const defaultIcons = {
  moveLeft: <ButtonLeftOutlined color={colors.light_1_dark_navy} style={{ width: '32px', height: '32px' }} />,
  moveAllLeft: [
    <span key={0} className="fa fa-chevron-left" />,
    <span key={1} className="fa fa-chevron-left" />,
  ],
  moveRight: <ButtonRightOutlined color={colors.light_1_dark_navy} style={{ width: '32px', height: '32px' }} />,
  moveAllRight: [
    <span key={0} className="fa fa-chevron-right" />,
    <span key={1} className="fa fa-chevron-right" />,
  ],
  moveUp: <ButtonUpOutlined color={colors.light_1_dark_navy} style={{ width: '32px', height: '32px' }} />,
  moveDown: <ButtonDownOutlined color={colors.light_1_dark_navy} style={{ width: '32px', height: '32px' }} />,
};

/**
   * @param {Array} options
   *
   * @returns {Array}
   */

export const sortBy = (field, reverse, primer) => {
  const key = primer ? (x) => primer(x[field]) : (x) => x[field];
  reverse = !reverse ? 1 : -1;
  return (a, b) => {
    const c = key(a).toString();
    const d = key(b).toString();
    return reverse * ((c.localeCompare(d)) - (d.localeCompare(c)));
  };
};

export const arrayMove = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k) {
      arr.push(undefined);
      k -= 1;
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

// Return a function to swap positions of the given indexes in an ordering
const swap = (index1, index2) => (options) => {
  const newOptions = [...options];
  [newOptions[index1], newOptions[index2]] = [newOptions[index2], newOptions[index1]];
  return newOptions;
};

const directionUp = ({ markedOptions, newOrder }) => {
  // If all of the marked options are already as high as they can get, ignore the
  // re-arrangement request because they will end of swapping their order amongst
  // themselves.
  if (markedOptions[markedOptions.length - 1].index > markedOptions.length - 1) {
    markedOptions.forEach(({ index }) => {
      if (index > 0) {
        newOrder = swap(index, index - 1)(newOrder);
      }
    });
  }
  return newOrder;
};

const directionDown = ({ markedOptions, selected, newOrder }) => {
  // Similar to the above, if all of the marked options are already as low as they can
  // get, ignore the re-arrangement request.
  if (markedOptions[0].index < selected.length - markedOptions.length) {
    [...markedOptions].reverse().forEach(({ index }) => {
      if (index < selected.length - 1) {
        newOrder = swap(index, index + 1)(newOrder);
      }
    });
  }
  return newOrder;
};
const directionTop = ({ markedOptions, newOrder }) => {
  if (markedOptions[markedOptions.length - 1].index > markedOptions.length - 1) {
    let counter = 0;
    markedOptions.forEach(({ index }) => {
      if (index > 0) {
        newOrder = arrayMove(newOrder, index, counter);
      }
      counter += 1;
    });
  }
  return newOrder;
};
const directionBottom = ({ markedOptions, selected, newOrder }) => {
  if (markedOptions[0].index < selected.length - markedOptions.length) {
    let counter = selected.length - 1;
    [...markedOptions].reverse().forEach(({ index }) => {
      if (index < selected.length - 1) {
        newOrder = arrayMove(newOrder, index, counter);
      }
      counter -= 1;
    });
  }
  return newOrder;
};

export const configureSelected = ({ markedOptions, direction, selected }) => {
  let newOrder = [...selected];
  if (markedOptions.length === 0) {
    return newOrder;
  }
  if (direction === 'up') {
    newOrder = directionUp({ markedOptions, newOrder });
  } else if (direction === 'down') {
    newOrder = directionDown({ markedOptions, selected, newOrder });
  } else if (direction === 'top') {
    newOrder = directionTop({ markedOptions, newOrder });
  } else if (direction === 'bottom') {
    newOrder = directionBottom({ markedOptions, selected, newOrder });
  } else {
    Logger.verbose('Invalid Direction');
  }
  return newOrder;
};

export const handleOnChangeRefactor = (option, selected, complexSelected) => {
  const subSelected = [];
  option.options.forEach((subOption) => {
    if (selected.indexOf(subOption.value) > -1) {
      subSelected.push(subOption);
    }
  });
  if (subSelected.length > 0) {
    complexSelected.push({
      label: option.label,
      options: subSelected,
    });
  }
  return complexSelected;
};

export const handleGetFlatOptions = (options, simpleValue) => {
  if (simpleValue) {
    return options;
  }
  const flattened = [];
  options.forEach((option) => {
    if (option.value) {
      // Flatten single-level options
      flattened.push(option.value);
    } else {
      // Flatten optgroup options
      option.options.forEach((subOption) => {
        flattened.push(subOption.value);
      });
    }
  });
  return flattened;
};
class DualListBox extends React.Component {
  /**
   * @param {Object} props
   *
   * @returns {void}
   */
  constructor(props) {
    super(props);
    this.state = {
      filter: props.filter
        ? props.filter
        : {
          available: '',
          selected: '',
        },
      id: props.id || `rdl-${nanoid()}`,
      rightSelValue: [],
      leftSelValue: [],
    };
    this.onActionClick = this.onActionClick.bind(this);
    this.onOptionDoubleClick = this.onOptionDoubleClick.bind(this);
    this.onOptionKeyDown = this.onOptionKeyDown.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  /**
   * @param {Object} filter
   * @param {string} id
   * @param {Object} prevState
   *
   * @returns {Object}
   */
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const { deselectColumnOrder } = this.props;
    if (prevProps.deselectColumnOrder !== deselectColumnOrder) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ rightSelValue: [], leftSelValue: [] });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState((prevState) => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          available: '',
        },
      }));
    }
  }

  static getDerivedStateFromProps({ filter, id }, prevState) {
    let newState = { ...prevState };
    if (filter !== null) {
      newState = { ...newState, filter };
    }
    if (id !== null) {
      newState = { ...newState, id };
    }
    return newState;
  }

  /**
   * @param {Array} selected
   *
   * @returns {void}
   */
  onChange(selected, resetSelected) {
    const { options, onChange } = this.props;
    onChange(selected);
    if (resetSelected) {
      options.forEach((opt) => {
        opt.selected = false;
      });
    }
  }

  /**
   * @param {string} direction
   * @param {boolean} isMoveAll
   *
   * @returns {void}
   */
  onActionClick({ direction, isMoveAll }) {
    const { options } = this.props;
    const directionIsRight = direction === 'right';
    const sourceListBox = directionIsRight ? this.availableOptions : this.selectedOptions;
    let selected = [];
    let resetSelected = true;
    let markedOptions = [];
    if (['up', 'down', 'top', 'bottom'].indexOf(direction) > -1) {
      markedOptions = this.getSelectedOptions(sourceListBox);
      selected = this.rearrangeSelected(markedOptions, direction);
      resetSelected = false;
    } else if (isMoveAll) {
      selected = directionIsRight ? this.makeOptionsSelected(options) : [];
    } else {
      markedOptions = this.getSelectedOptions(sourceListBox);
      if (markedOptions.length === 0) {
        return;
      }
      selected = this.toggleSelected(
        markedOptions,
        directionIsRight ? 'available' : 'selected',
      );
    }
    if (['up', 'down', 'top', 'bottom'].indexOf(direction) < -1) {
      this.setState({ leftSelValue: [] });
      this.setState({ rightSelValue: [] });
    }
    this.onChange(selected, resetSelected);
  }

  /**
   * @param {Object} event
   * @param {string} controlKey
   *
   * @returns {void}
   */
  onOptionDoubleClick(value, controlKey) {
    const selected = this.toggleSelected([{ value }], controlKey);
    this.onChange(selected, true);
  }

  /**
   * @param {Event} event
   * @param {string} controlKey
   *
   * @returns {void}
   */
  onOptionKeyDown(event, controlKey) {
    const { keyCode } = event;
    const { moveKeyCodes } = this.props;
    if (moveKeyCodes.indexOf(keyCode) > -1) {
      event.preventDefault();
      const options = (controlKey === 'available') ? this.availableOptions : this.selectedOptions;
      const selected = this.toggleSelected(this.getSelectedOptions(options), controlKey);
      this.onChange(selected, true);
    }
  }

  /**
   * @param {Event} event
   *
   * @returns {void}
   */
  onFilterChange(event) {
    const { filter } = this.state;
    const newFilter = {
      ...filter,
      [event.target.dataset.key]: event.target.value,
    };

    this.setState({ filter: newFilter });
  }

  /**
   *
   * @param {Event} options
   * @returns {array}
   */
  onValueChange(value, side) {
    if (side === 'available') {
      this.markOptionsSelected(this.availableOptions, value);
      this.toggleOptionSelection(this.selectedOptions, false);
      this.setState({ leftSelValue: value, rightSelValue: [] });
    } else {
      this.markOptionsSelected(this.selectedOptions, value);
      this.toggleOptionSelection(this.availableOptions, false);
      this.setState({ rightSelValue: value, leftSelValue: [] });
    }
  }

  /**
   * Converts a flat array to a key/value mapping.
   *
   * @param {Array} options
   *
   * @returns {Object}
   */
  getLabelMap(options) {
    const labelMap = {};
    options.forEach((option) => {
      labelMap[option.value] = option.label;
    });
    return labelMap;
  }

  /**
   * Returns the selected options from a given element.
   *
   * @param {Object} element
   *
   * @returns {Array}
   */
  getSelectedOptions(options) {
    if (options === null) {
      return [];
    }
    return options
      .map((option, index) => ({
        index: parseInt(index, 10),
        value: option.value,
        selected: option.selected,
      }))
      .filter((option) => option.selected);
  }

  markOptionsSelected(options, selected) {
    options.forEach((option) => {
      if (selected.indexOf(option.value) > -1) {
        option.selected = true;
      } else {
        option.selected = false;
      }
    });
  }

  toggleOptionSelection(optionList, selection) {
    optionList.forEach((option) => {
      option.selected = selection;
    });
  }

  /**
   * Re-arrange the marked options to move up or down in the selected list.
   *
   * @param {Array} markedOptions
   * @param {string} direction
   *
   * @returns {Array}
   */
  rearrangeSelected(markedOptions, direction) {
    const { selected } = this.props;
    return configureSelected({ markedOptions, direction, selected });
  }

  /**
   * Make all the given options selected, appending them after the existing selections.
   *
   * @param {Array} options
   *
   * @returns {Array}
   */
  makeOptionsSelected(options) {
    const { selected: previousSelected } = this.props;
    const availableOptions = this.filterAvailable(options);
    return [
      ...previousSelected,
      ...this.makeOptionsSelectedRecursive(availableOptions),
    ];
  }

  /**
   * Recursively make the given set of options selected.
   *
   * @param {Array} options
   *
   * @returns {Array}
   */
  makeOptionsSelectedRecursive(options) {
    let newSelected = [];
    options.forEach((option) => {
      if (option.options !== undefined) {
        newSelected = [...newSelected, ...this.makeOptionsSelectedRecursive(option.options)];
      } else {
        newSelected.push(option.value);
      }
    });
    return newSelected;
  }

  /**
   * Toggle a new set of selected elements.
   *
   * @param {Array} toggleItems
   * @param {string} controlKey
   *
   * @returns {Array}
   */
  toggleSelected(toggleItems, controlKey) {
    const { allowDuplicates, selected } = this.props;
    const selectedItems = selected.slice(0);
    const toggleItemsMap = { ...selectedItems };
    // Add/remove the individual items based on previous state
    toggleItems.forEach(({ value }) => {
      const inSelectedOptions = selectedItems.indexOf(value) > -1;
      if (inSelectedOptions && (!allowDuplicates || controlKey === 'selected')) {
        // Toggled items that were previously selected are removed unless `allowDuplicates`
        // is set to true or the option was sourced from the selected ListBox. We use an
        // object mapping such that we can remove the exact index of the selected items
        // without the array re-arranging itself.
        delete toggleItemsMap[selectedItems.indexOf(value)];
      } else {
        selectedItems.push(value);
      }
    });
    // Convert object mapping back to an array
    if (controlKey === 'selected') {
      return Object.keys(toggleItemsMap).map((key) => toggleItemsMap[key]);
    }
    return selectedItems;
  }

  /**
   * Filter the given options by a ListBox filtering function and the user search string.
   *
   * @param {Array} options
   * @param {Function} filterer
   * @param {string} filterInput
   *
   * @returns {Array}
   */
  filterOptions(options, filterer, filterInput) {
    const { canFilter, filterCallback } = this.props;
    const filtered = [];
    options.forEach((option) => {
      const subFiltered = [];
      // Run the main filter function against the given item
      const filterResult = filterer(option);
      if (Array.isArray(filterResult)) {
        // The selected list box will be filtered by whether the given options have a
        // selected index. This index will later be used when removing user selections.
        // This index is particularly relevant for duplicate selections, as we want to
        // preserve the removal order properly when `preserveSelectOrder` is set to
        // true, rather than simply removing the first value encountered.
        filterResult.forEach((index) => {
          subFiltered.push({
            ...option,
            selectedIndex: index,
          });
        });
      } else if (filterResult) {
        // Available options are much simpler and are merely filtered by a boolean
        subFiltered.push(option);
      } else {
        Logger.verbose('Undefined Filter Result');
      }
      // If any matched options go through, optionally apply user filtering and then add
      // these options to the filtered list. The text search filtering is applied AFTER
      // the main filtering to prevent unnecessary calls to the filterCallback function.
      if (subFiltered.length > 0) {
        if (canFilter && !filterCallback(option, filterInput)) {
          return;
        }
        subFiltered.forEach((subItem) => {
          filtered.push(subItem);
        });
      }
    });
    return filtered;
  }

  /**
   * Filter the available options.
   *
   * @param {Array} options
   *
   * @returns {Array}
   */
  filterAvailable(options) {
    const { allowDuplicates, available, selected } = this.props;
    const {
      filter: { available: availableFilter },
    } = this.state;
    // The default is to only show available options when they are not selected
    let filterer = (option) => selected.indexOf(option.value) < 0;
    if (allowDuplicates) {
      // If we allow duplicates, all options will always be available
      filterer = () => true;
    } else if (available !== undefined) {
      // If the caller is restricting the available options, combine that with the default
      filterer = (option) => available.indexOf(option.value) >= 0
        && selected.indexOf(option.value) < 0;
    } else {
      Logger.verbose('Unavailable Filter');
    }
    return this.filterOptions(options, filterer, availableFilter);
  }

  /**
   * Filter the selected options.
   *
   * @param {Array} options
   *
   * @returns {Array}
   */
  filterSelected(options) {
    const { preserveSelectOrder, selected } = this.props;
    const {
      filter: { selected: selectedFilter },
    } = this.state;
    if (preserveSelectOrder) {
      return this.filterSelectedByOrder(options);
    }
    // Order the selections by the default order
    return this.filterOptions(
      options,
      (option) => indexesOf(selected, option.value),
      selectedFilter,
    );
  }

  /**
   * Preserve the selection order. This drops the opt-group associations.
   *
   * @param {Array} options
   *
   * @returns {Array}
   */
  filterSelectedByOrder(options) {
    const { selected } = this.props;
    const labelMap = this.getLabelMap(options);
    const selectedOptions = [];
    selected.forEach((value, index) => {
      const selectedOption = options[options.findIndex((opt) => opt.value === value)];
      if (selectedOption) {
        selectedOption.label = labelMap[value];
        selectedOption.selectedIndex = index;
        selectedOptions.push(selectedOption);
      }
    });
    return selectedOptions;
  }

  /**
   * @param {string} controlKey
   * @param {Array} options
   * @param {function} ref
   * @param {React.Component} actions
   *
   * @returns {React.Component}
   */

  renderListBox(controlKey, options, ref, actions) {
    const {
      alignActions,
      disabled,
      filterPlaceholder,
      lang,
      showHeaderLabels,
      showNoOptionsText,
      headerLabel,
    } = this.props;
    const { filter, id } = this.state;
    // Wrap event handlers with a controlKey reference
    const wrapHandler = (handler) => (event) => handler(event, controlKey);
    return (
      <ListBox
        actions={alignActions === ALIGNMENTS.TOP ? actions : null}
        canFilter={controlKey === 'available'}
        controlKey={controlKey}
        disabled={disabled}
        filterPlaceholder={filterPlaceholder}
        filterValue={filter[controlKey]}
        id={id}
        inputRef={(c) => {
          this[controlKey] = c;
          if (ref) {
            ref(c);
          }
        }}
        lang={lang}
        showHeaderLabels={showHeaderLabels}
        showNoOptionsText={showNoOptionsText}
        onDoubleClick={wrapHandler(this.onOptionDoubleClick)}
        onFilterChange={wrapHandler(this.onFilterChange)}
        onKeyDown={wrapHandler(this.onOptionKeyDown)}
        onValueChange={wrapHandler(this.onValueChange)}
        options={options}
        headerLabel={headerLabel}
      />
    );
  }

  /**
   * @returns {React.Component}
   */
  render() {
    const {
      alignActions,
      availableRef,
      disabled,
      icons,
      lang,
      options,
      preserveSelectOrder,
      selectedRef,
      showOrderButtons,
      labels,
    } = this.props;
    options.sort(sortBy('label', false, (a) => a.toUpperCase()));
    const { id } = this.state;
    this.availableOptions = this.filterAvailable(options);
    this.selectedOptions = this.filterSelected(options);

    const showRightLeft = (btnOrder, btnRight, btnLeft) => (
      <div className={`${rdlActions} ${actionLeftRight}`}>
        { btnOrder ? (
          <div>
            {btnRight}
            {btnLeft}
          </div>
        ) : (
          <div>
            {btnLeft}
            {btnRight}
          </div>
        )}
      </div>
    );
    const makeAction = (direction, isMoveAll = false) => (
      <Action
        direction={direction}
        disabled={disabled}
        icons={icons}
        id={id}
        isMoveAll={isMoveAll}
        lang={lang}
        onClick={this.onActionClick}
        labels={labels}
      />
    );
    const actionsRight = makeAction('right');
    const actionsLeft = makeAction('left');
    const { actionBtnOdrChge } = this.props;
    return (
      <ActionContainer className={columnFilterContainer} id={id}>
        {this.renderListBox('available', this.availableOptions, availableRef, actionsRight)}
        {alignActions === ALIGNMENTS.MIDDLE ? showRightLeft(actionBtnOdrChge, actionsRight, actionsLeft) : null}
        {this.renderListBox('selected', this.selectedOptions, selectedRef, actionsLeft)}
        {preserveSelectOrder && showOrderButtons ? (
          <div className={`${rdlActions} ${actionUpDown}`}>
            <div>
              {makeAction('top')}
              {makeAction('up')}
              {makeAction('down')}
              {makeAction('bottom')}
            </div>
          </div>
        ) : null}
      </ActionContainer>
    );
  }
}

DualListBox.propTypes = {
  options: optionsShape.isRequired,
  onChange: PropTypes.func.isRequired,
  alignActions: PropTypes.oneOf([ALIGNMENTS.MIDDLE, ALIGNMENTS.TOP]),
  allowDuplicates: PropTypes.bool,
  available: valueShape,
  availableRef: PropTypes.func,
  canFilter: PropTypes.bool,
  disabled: PropTypes.bool,
  filter: PropTypes.shape({
    available: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
  }),
  filterCallback: PropTypes.func,
  filterPlaceholder: PropTypes.string,
  icons: iconsShape,
  id: PropTypes.string,
  lang: languageShape,
  moveKeyCodes: PropTypes.arrayOf(PropTypes.number),
  preserveSelectOrder: PropTypes.bool,
  selected: valueShape,
  selectedRef: PropTypes.func,
  showHeaderLabels: PropTypes.bool,
  showNoOptionsText: PropTypes.bool,
  showOrderButtons: PropTypes.bool,
  deselectColumnOrder: PropTypes.bool,
  actionBtnOdrChge: PropTypes.bool,
  labels: PropTypes.shape({
    button: PropTypes.shape({
      left: PropTypes.string,
      right: PropTypes.string,
      top: PropTypes.string,
      up: PropTypes.string,
      down: PropTypes.string,
      bottom: PropTypes.string,
    }),
  }),
  headerLabel: PropTypes.string,
};

DualListBox.defaultProps = {
  alignActions: ALIGNMENTS.MIDDLE,
  allowDuplicates: false,
  available: undefined,
  availableRef: null,
  canFilter: false,
  disabled: false,
  filter: null,
  filterPlaceholder: staticCommonLabelKeys.COMMON_BUTTON_SEARCH,
  filterCallback: defaultFilter,
  icons: defaultIcons,
  id: null,
  lang: defaultLang,
  moveKeyCodes: [KEY_CODES.SPACEBAR, KEY_CODES.ENTER],
  preserveSelectOrder: null,
  selected: [],
  selectedRef: null,
  showHeaderLabels: false,
  showNoOptionsText: false,
  showOrderButtons: false,
  deselectColumnOrder: false,
  actionBtnOdrChge: false,
  labels: {},
  headerLabel: '',
};

export default DualListBox;
