/* eslint-disable no-unused-vars */
/**
 * @Description File generated by awesome-react-template
 * @FileName App.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 14:23:08
 * @IssueID - 459, 305, 311
 */
import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LANG, MuxProvider } from '@manulife/mux';
import i18n, { getCurrentLocale } from './app/common/i18n';
import { Layout } from './app/common/components/masterPage';
import FasatLoader from './app/common/components/fasatLoader/fasatLoader';
import getMenuItems from './config/menuConfig';
import FasatRouter from './fasatRouter';
import { IsACLEnabled } from './app/common/util/utility';
import { getAllQuicksLinks } from './app/common/redux/actions/commonActions';

function App() {
  /**
    * The below line for translation has been added to
    * kick in the i18next. Otherwise we have to face localization
    * issue for the menu labels
    */
  // eslint-disable-next-line no-unused-vars
  // const [t] = useTranslation(); // DO NOT DELETE
  // const xmenuItems = getMenuItems().sort((i1, i2) => i1.sequence - i2.sequence);
  const [menuItems, setMenuItems] = useState([]);

  window.addEventListener('popstate', (event) => {
    window.history.pushState(null, '', window.location.origin);
  });

  // // detect initialPage
  // useEffect(() => {
  //   let { currentPage } = pageTrack;
  //   const currentDate = new Date();

  //   setPageTrack({ ...pageTrack, currentPage });
  // }, []);

  // // detect page change
  // useEffect(
  //   () => history.listen((historyUpdate) => {
  //     const currentLocation = historyUpdate.location;
  //     const currentPath = currentLocation.pathname;

  //       currentPage = {
  //         page: currentPath,
  //         accessedOn: currentDate,
  //       };
  //       logTimeTracking(previous);
  //       setPageTrack({ ...pageTrack, currentPage, previous });
  //     }
  //   }),
  //   [history, pageTrack],
  // );

  /**
    * Since menu items have been distributed in different modules and combined
    * in config/menuConfig; there has a potential to have distorted menu order.
    * To make the menu order consistent we need to sort the same
    */
  // const isAuthenticated = false;
  // const menuItems = getMenuItems().filter.sort((i1, i2) => i1.sequence - i2.sequence);
  // const menuItems = getMenuItems().filter((item) => arrValues.indexOf('Sam') > -1);
  const dispatch = useDispatch();
  const processingInProgress = useSelector(
    ({ ApplicationStateFilterList }) => ApplicationStateFilterList.processingInProgress,
  );
  const rbacData = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.rbacData);
  const loggedUser = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.loggedUser);
  const isBatchProcessing = useSelector(({ ApplicationState }) => ApplicationState.isBatchProcessing);
  const locale = getCurrentLocale();
  useEffect(() => {
    if (loggedUser && rbacData && rbacData.access && rbacData.access.length > 0) {
      dispatch(getAllQuicksLinks(loggedUser));
      const rbacMenus = getMenuItems().filter((item) => IsACLEnabled(rbacData, item, loggedUser));
      setMenuItems(rbacMenus);
    } else {
      setMenuItems([]);
    }
  }, [rbacData, loggedUser, locale, isBatchProcessing]);
  return (
    <div className="App" data-testid="FASAT_APP">
      {processingInProgress > 0 && <FasatLoader />}
      <div>
        {/* <FasatHome /> */}
        <I18nextProvider i18n={i18n}>
          <MuxProvider langProvider={{ lang: locale === 'en' ? LANG.EN_CA : LANG.FR_CA }}>
            <Router>
              <Layout menuItems={menuItems} mobileMenuTabs={[]} selectedTab={null}>
                <FasatRouter isBatchProcessing={isBatchProcessing} loggedUser={loggedUser} menuItems={menuItems} />
              </Layout>
            </Router>
          </MuxProvider>
        </I18nextProvider>
      </div>
    </div>
  );
}
export default App;
