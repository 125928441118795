/**
 * @Description This file will have all the route information for administration module
 * @FileName routeConfig.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 08 January, 2021 16:41:45
 * @IssueID - 8376
 */
import { lazy } from 'react';
import { aclKeysConstant, routeConstant } from '../moduleConstants';

/**
 * Lazy load BusinessCardList
 */
export const paymentList = lazy(() => import(
  /* webpackChunkName:"PaymentProcess" */ '../containers/paymentProccessingList/paymentProcessingList'
));

const routeConfig = [
  {
    path: routeConstant.PAYMENT_PROCESS_ROUTE,
    exact: true,
    component: paymentList,
    id: 'paymentList',
    title: 'FASAT - Payment Processing',
    pageName: 'FASAT - Payment Processing', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_REPORTS], // need to update
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
];

export default routeConfig;
