/**
  * All the route constants for the module
  */
export const routeConstant = {
  CODE_HIERARCHY: '/codeAndHierarchy',
  CODE_HIERARCHY_DETAILS: '/codeAndHierarchyDetails',
};

/**
  * ACL keys for the module
  */
export const aclKeysConstant = {
  RBAC_FFG_MENU: 'RBAC_FFG_MENU',
  RBAC_FFB_MENU: 'RBAC_FFB_MENU',
  RBAC_FFREQ_MENU: 'RBAC_FFREQ_MENU',
  RBAC_DA_EXPORT: 'RBAC_DA_EXPORT',
  RBAC_EXTCAH_MENU: 'RBAC_EXTCAH_MENU',
  RBAC_EXT_CODES_AND_HIERARCHY: 'RBAC_EXT_CODES_AND_HIERARCHY',
  // RBAC_EXT_PRODUCTION: 'RBAC_EXT_PRODUCTION',
  RBAC_EXTPRB_MENU: 'RBAC_EXTPRB_MENU',
  RBAC_FFPRD_MENU: 'RBAC_FFPRD_MENU',
  RBAC_EXT_BALANCE: 'RBAC_EXT_BALANCE',
  RBAC_EXTBAL_MENU: 'RBAC_EXTBAL_MENU',
  RBAC_EXTMSA_MENU: 'RBAC_EXTMSA_MENU',
  RBAC_DB_MENU: 'RBAC_DB_MENU',
  RBAC_EXT_TOP_PRODUCERS: 'RBAC_EXT_TOP_PRODUCERS',
  RBAC_TP_MENU: 'RBAC_TP_MENU',
  RBAC_EXT_CODES_AND_HIERARCHY_EDIT_NICKNAME: 'RBAC_EXT_CODES_AND_HIERARCHY_EDIT_NICKNAME',
  RBAC_EXT_CODES_AND_HIERARCHY_EXPORT: 'RBAC_EXT_CODES_AND_HIERARCHY_EXPORT',

  RBAC_EXT_LEVEL_DETAILS_GENERAL: 'RBAC_EXT_LEVEL_DETAILS_GENERAL',
  RBAC_EXT_LEVEL_DETAILS_BANKING: 'RBAC_EXT_LEVEL_DETAILS_BANKING',
  RBAC_EXT_LEVEL_DETAILS_REQUIREMENTS: 'RBAC_EXT_LEVEL_DETAILS_REQUIREMENTS',
  RBAC_EXT_LEVEL_DETAILS_REQ_EXPORT: 'RBAC_EXT_LEVEL_DETAILS_REQ_EXPORT',
  RBAC_EFFR_MENU: 'RBAC_EFFR_MENU',
  RBAC_EFFR_EXPORT: 'RBAC_EFFR_EXPORT',
  RBAC_DTAC_MENU: 'RBAC_DTAC_MENU',
  RBAC_DTAC_EXPORT: 'RBAC_DTAC_EXPORT',
  RBAC_DB_EXPORT: 'RBAC_DB_EXPORT',
  RBAC_EXT_TOP_PRODUCERS_EXPORT: 'RBAC_EXT_TOP_PRODUCERS_EXPORT',
  RBAC_TP_EXPORT: 'RBAC_TP_EXPORT',
  RBAC_MSA_MENU: 'RBAC_MSA_MENU',
  RBAC_MSA_EXPORT: 'RBAC_MSA_EXPORT',
};

/**
  * i18n Keys for the module
  */
export const staticLabelKeys = {
  CHC_TITLE_CODE_HIERACHY: 'CHC_TITLE_CODE_HIERACHY',
  CHC_LABEL_CODE_NICKNAME: 'CHC_LABEL_CODE_NICKNAME',
  CHC_LABEL_ORG_LEVEL: 'CHC_LABEL_ORG_LEVEL',
  CHC_LABEL_ACTION: 'CHC_LABEL_ACTION',
  CHC_LABEL_SEARCH_FOR: 'CHC_LABEL_SEARCH_FOR',
  CHC_LABEL_HIDE_RECORDS: 'CHC_LABEL_HIDE_RECORDS',
  CHC_LABEL_SHOW_RECORDS: 'CHC_LABEL_SHOW_RECORDS',
  CHC_LABEL_NICKNAME: 'CHC_LABEL_NICKNAME',
  CHC_LABEL_BACK_TO_SEARCH: 'CHC_LABEL_BACK_TO_SEARCH',
  CHC_NICKNAME_UPDATE_SUCCESS: 'CHC_NICKNAME_UPDATE_SUCCESS',
  CHC_LABEL_EXPORT: 'CHC_LABEL_EXPORT',
  // advisor details
  ADVISOR_DETAILS: 'Advisor details',
  ADG_LABEL_STATUS_CHANGE_REASON: 'ADG_LABEL_STATUS_CHANGE_REASON',
  ADG_LABEL_EXPORT_REQUIREMENTS: 'ADG_LABEL_EXPORT_REQUIREMENTS',
  FF_TITLE_BANKING: 'FF_TITLE_BANKING',
  FF_LABEL_REQUIREMENTS: 'FFR_LABEL_REQUIREMENTS',
  FF_LABEL_MLC_SELLING_CODE: 'FF_LABEL_MLC_SELLING_CODE',
  ADVISOR_FUND_SERVE_CODE: 'ADG_LABEL_FUNDSERV_CODE',
  FF_LABEL_WEALTH_CODE: 'FF_LABEL_WEALTH_CODE',
  FF_LABEL_ADVISOR_TYPE: 'FF_LABEL_ADVISOR_TYPE',
  FF_LABEL_STATUS_REASON: 'FF_LABEL_STATUS_REASON',
  FF_LABEL_TERMINATION_DATE: 'FF_LABEL_TERMINATION_DATE',
  BUSINESS_PHONE: 'ADG_LABEL_BUSINESS_PHONE',
  BUSINESS_ADDRESS: 'ADG_LABEL_BUSINESS_ADDRESS',
  FF_LABEL_CONTRACT_DATE: 'FF_LABEL_CONTRACT_DATE',
  FFB_LABEL_PAY_METHOD: 'FFB_LABEL_PAY_METHOD',
  FFB_LABEL_CHEQUE_NAME: 'COMMON_LABEL_CHEQUE_NAME',
  FFB_LABEL_CURRENCY: 'FFB_LABEL_CURRENCY',
  BANKING_LABEL_BANK: 'COMMON_LABEL_BANK',
  FFB_LABEL_BRANCH_NUMBER: 'FFB_LABEL_BRANCH_NUMBER',
  BANKING_ACCOUNT_NO: 'COMMON_LABEL_ACNTNUM',
  LTR_LABEL_LASTUPDATE: 'ADG_LABEL_LAST_UPDATE_DATE',

  CODE_AND_HIERARCHY_DETAILS: 'Code and hierarchy details',

  // Debit Balance
  DB_LABEL_DEBIT_START_DATE: 'DB_LABEL_DEBIT_START_DATE',
  DB_LABEL_DEBIT: 'DB_LABEL_DEBIT',
  DB_LABEL_CURRENT: 'DB_LABEL_CURRENT',
  CH_DB_EXORT_MODAL_TITLE: 'CH_DB_EXORT_MODAL_TITLE',

  COMMON_LABEL_TYPE: 'COMMON_LABEL_TYPE',

  // Detailed Activity
  DETAILED_ACTIVITY_TITLE: 'DETAILED_ACTIVITY_TITLE',
  DTAC_LABEL_COMMTYPE_DESC: 'DTAC_LABEL_COMMTYPE_DESC',
  DTAC_LABEL_BAS_AMNT: 'DTAC_LABEL_BAS_AMNT',
  DETAILED_COMPENSATION_AMOUNT: 'Compensation amount',
  DTAC_LABEL_COMP_RATE: 'DTAC_LABEL_COMP_RATE',
  DTAC_EXPORT_TITLE: 'DTAC_EXPORT_TITLE',

  // top producers
  TP_LABEL_YTD_FYF_EFY_PRODUCTION: 'TP_LABEL_YTD_PRODUCTION',
  TP_LABEL_MTD_FYC_EFY_PRODUCTION: 'TP_LABEL_MTD_PRODUCTION',
  TP_LABEL_RANK: 'TP_LABEL_RANK',
  TP_EXPORT_MODAL_TITLE: 'TP_EXPORT_MODAL_TITLE',
  TP_PAGE_TITLE: 'TP_PAGE_TITLE',

  // ms activity
  MAC_TITLE_MC_ACT: 'MAC_TITLE_MC_ACT',
  MAC_LABEL_ACTIVITY_PERIOD: 'MAC_LABEL_ACTIVITY_PERIOD',
  MAC_LABEL_YEAR_TO_DATE: 'MAC_LABEL_YEAR_TO_DATE',
  MAC_LABEL_PREV_YEAR_DATE: 'MAC_LABEL_PREV_YEAR_DATE',
  blank_header: '   ',
  COMMON_LABEL_NO_RECORD_FOUND: 'COMMON_LABEL_NO_RECORD_FOUND',
  MAC_EXPORT_TITLE: 'MAC_EXPORT_TITLE',
};
