/**
 * @Description Application logger for frontend logging. Implemented structured-log package
 * @FileName appLogger.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 14:51:44
 * @IssueID - 318
 */
import { configure, ConsoleSink } from 'structured-log';
import { HttpSink } from './httpSink';
import { getLogLevel, environment } from './utility';

const envData = environment(window);
const httpLogLevel = envData && envData.httpLogLevel ? envData.httpLogLevel : 'verbose';
const minimumLogLevel = envData && envData.minimumLogLevel ? envData.minimumLogLevel : 'verbose';
/**
 * Configure the application logger
 */
const logger = configure()
  .writeTo(
    new ConsoleSink({
      includeTimestamps: true,
      restrictedToMinimumLevel: getLogLevel(envData.consoleLogLevel),
    }),
  )
  .writeTo(
    new HttpSink({
      url: envData && envData.logEndpoint ? envData.logEndpoint : '',
      restrictedToMinimumLevel: httpLogLevel,
    }),
  )
  .minLevel(minimumLogLevel)
  .enrich(envData || {})
  .create();

export default logger;

/**
 * Logs user's time spend in a specific page
 * @param {object} pageTrack
 */
export const logTimeTracking = (pageTrack) => {
  logger.info(
    '{screen} has been accessed for {time} milliseconds by {user}',
    pageTrack.page,
    pageTrack.timeSpend,
    'DUMMY USER',
  );
};
