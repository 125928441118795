/* eslint-disable no-unused-vars */
/**
 * @Description This will contain all the configuration for different module
 * @FileName routeConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:48:51
 * @IssueID - 459
 */
import { adminRoutes } from '../app/modules/admin';
import { agencyRoutes } from '../app/modules/agency';
import { activityRoutes } from '../app/modules/activity';
import { FasatSignOut } from '../app/common/components';
import { compcalRoutes } from '../app/modules/compcal';
import { reportRoutes } from '../app/modules/report';
import { accountingRoutes } from '../app/modules/accounting';
import { paymentRoutes } from '../app/modules/payments';
import { codeAndHierarchyRoutes } from '../app/modules/codesAndHierarchyExternal';

/**
 * All the module specific routing needs to be combined here
 */

const signOutRoute = {
  path: '/sign-out',
  exact: true,
  component: FasatSignOut,
  id: 'Sign Out',
  title: 'FASAT - Sign Out',
  pageName: 'FASAT - Sign Out', // page name to send in error message log service
  authenticationRequired: false,
  aclKey: [],
  acl: true,
  public: true,
};

const appRouting = [
  ...adminRoutes,
  ...agencyRoutes,
  ...activityRoutes,
  ...compcalRoutes,
  ...reportRoutes,
  ...accountingRoutes,
  ...paymentRoutes,
  ...codeAndHierarchyRoutes,
  signOutRoute,
];
export default appRouting;
