import { commonTypes } from '../actions/commonActions';
import { initialApplicationFilterListState } from '../../../../stateManagement/reduxUtil';

/**
 * Base reducer to manage application default state
 * @param {object} state
 * @param {object} action
 */
const ApplicationStateFilterList = (
  state = initialApplicationFilterListState, action = null,
) => {
  switch (action.type) {
    /**
     * For any activity for which processing wait cursor needs to be shown;
     * just before the processing start, this action needs to be dispatched
     */
    case commonTypes.PROCESSING_INITIATED: // need to check the best way to increment
      return {
        ...state,
        processingInProgress: state.processingInProgress + 1,
      };
    case commonTypes.PROCESSING_COMPLETED: // need to check the best way to decrement
      return {
        ...state,
        processingInProgress: state.processingInProgress - 1,
      };
    case commonTypes.PROCESSING_COMPLETED_FORCE: // need to check the best way to decrement
      return {
        ...state,
        processingInProgress: 0,
      };
    case commonTypes.SET_OPERATOR_LIST:
      return {
        ...state,
        operatorList: action.data,
      };
    case commonTypes.SET_FILTER_LIST:
      return {
        ...state,
        filterList: action.data,
      };
    case commonTypes.SET_FILTER_LIST_COUNT:
      return {
        ...state,
        filterListCount: action.data ? action.data.data.length : null,
      };
    case commonTypes.SET_FILTER_DATA:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return state;
  }
};

export default ApplicationStateFilterList;
