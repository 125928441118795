/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Karthick - kkarthk
 * @CreatedOn 04 Oct, 2022 08:51:22
 * @IssueID - Id
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { formatResponse, exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import { mcrDetailActionTypes } from '../actions/actionTypes';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import { processingStarted, processingCompleted,
  setUserPrefError } from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for Temp Adj Listing
  */
export function* getMCRList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(mcrDetailActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(mcrDetailActionTypes.LOG_MCR_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MCR_LIST, action.data);
    if (action.data.importedJobId) {
      responseData.importedJobId = action.data.importedJobId;
    }
    yield all([
      put(getAction(mcrDetailActionTypes.CHECK_REJ_USER)),
      put(getAction(mcrDetailActionTypes.GET_MCR_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(mcrDetailActionTypes.GET_MCR_LIST_ERROR, err)),
      put(getAction(mcrDetailActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* checkRejStatForUser() {
  yield all([put(processingStarted()),
    put(getAction(mcrDetailActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_CHCK_STAT_USR);
    const { jobNum } = responseData;
    yield all([put(getAction(mcrDetailActionTypes.SET_CHCK_STAT_USR, jobNum)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(mcrDetailActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for Temp Adj details by ID
  */

export function* getMCRbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(mcrDetailActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(mcrDetailActionTypes.LOG_MCR_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_MCR_BY_ID,
      action.data);
    yield all([
      put(getAction(mcrDetailActionTypes.GET_MCR_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(mcrDetailActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(mcrDetailActionTypes.GET_MCR_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will update for existing Temp Adj
  */
export function* updateMCR(action) {
  yield all([
    put(processingStarted()),
    put(getAction(mcrDetailActionTypes.UPSERT_MCR_REQUEST, action.data.formatObject)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_MCR_UPDATE,
      action.data.formatObject);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(mcrDetailActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(mcrDetailActionTypes.GET_MCR_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(mcrDetailActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing Template Adjustments
  */

export function* deleteMCR(action) {
  const { formatObject, reqObject } = action.data;
  const { jobReqId } = formatObject;
  yield all([put(processingStarted()), put(setUserPrefError(null))]);
  let resetDeleteValue = null;
  const mngMCRAllInitURL = moduleAPI.ADMINISTRATION_MCRALL_DELETE.endpoint;
  try {
    let responseData = null;
    if (formatObject.jobOutput === 'delete') {
      responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MCR_DELETE, formatObject);
      localStorage.setItem('jobReqId', formatObject.jobReqId);
    } else {
      const mngMCRAllUpdateURL = `${moduleAPI.ADMINISTRATION_MCRALL_DELETE.endpoint}/${jobReqId}`;
      moduleAPI.ADMINISTRATION_MCRALL_DELETE.endpoint = mngMCRAllUpdateURL;
      responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MCRALL_DELETE, jobReqId);
      localStorage.setItem('jobReqId', jobReqId);
      // RESET END POINT URL
      moduleAPI.ADMINISTRATION_MCRALL_DELETE.endpoint = mngMCRAllInitURL;
    }
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: { ...responseData, jobReqId },
      };
    }
    yield all([
      put(getAction(mcrDetailActionTypes.RESET_DELETE_MCR_VALUE, resetDeleteValue)),
      put(getAction(mcrDetailActionTypes.GET_MCR_LIST_REQUEST, reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    moduleAPI.ADMINISTRATION_MCRALL_DELETE.endpoint = mngMCRAllInitURL;
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([put(getAction(mcrDetailActionTypes.RESET_DELETE_MCR_VALUE, resetDeleteValue)),
      put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing Template Adjustments
  */
export function* approveMCR(action) {
  yield all([put(processingStarted()), put(setUserPrefError(null))]);
  let resetApproveValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_MCR_APPROVE,
      action.data.formData);
    if (responseData) {
      resetApproveValue = {
        approveRequest: null,
        approveRequestError: null,
        approveRequestedOn: null,
        approveRequestProcessedOn: new Date(),
        approveData: { formData: action.data.formData,
          count: action.data.count,
          amount: action.data.amount },
      };
    }
    yield all([
      put(getAction(mcrDetailActionTypes.RESET_APPROVE_MCR_VALUE, resetApproveValue)),
      put(getAction(mcrDetailActionTypes.GET_MCR_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetApproveValue = {
      approveRequest: null,
      approveRequestError: processError(err),
      approveRequestedOn: new Date(),
      approveRequestProcessedOn: null,
      approveData: null,
    };
    yield all([put(getAction(mcrDetailActionTypes.RESET_APPROVE_MCR_VALUE, resetApproveValue)),
      put(processingCompleted())]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for Template Adjustments Export Listing
  */
export function* getMCRExportList(action) {
  yield all([put(processingStarted()), put(getAction(mcrDetailActionTypes.RESET_SERVER_VALUE, null))]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_MCR_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(mcrDetailActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing Template Adjustments
  */
export function* importMCR(action) {
  yield all([put(processingStarted()), put(setUserPrefError(null))]);
  let resetImportValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_MCR_IMPORT, action.data.fileContentList);
    localStorage.setItem('jobReqId', responseData?.jobNum);
    if (responseData) {
      resetImportValue = {
        detailRequest: null,
        detailRequestError: null,
        detailRequestedOn: null,
        detailRequestProcessedOn: new Date(),
        detailData: responseData,
        importedJobId: null,
      };
    }
    if (responseData && responseData.totalRejectedRecords > 0) {
      const listReqObj = action.data.reqObj;
      listReqObj.importedJobId = responseData?.jobNum;
      yield put(getAction(mcrDetailActionTypes.GET_MCR_LIST_REQUEST, listReqObj));
    }
    yield all([put(getAction(mcrDetailActionTypes.IMPORT_MCR_RESPONSE, resetImportValue)), put(processingCompleted())]);
  } catch (err) {
    resetImportValue = {
      detailRequest: null,
      detailRequestError: processError(err),
      detailRequestedOn: new Date(),
      detailRequestProcessedOn: null,
      detailData: null,
    };
    yield all([put(getAction(mcrDetailActionTypes.RESET_IMPORT_MCR, resetImportValue)),
      put(processingCompleted())]);
  }
}

function* moduleWatcher() {
  // template adjustments
  yield takeEvery(mcrDetailActionTypes.GET_MCR_LIST_REQUEST, getMCRList);
  yield takeEvery(mcrDetailActionTypes.UPDATE_MCR_REQUEST, updateMCR);
  yield takeEvery(mcrDetailActionTypes.DELETE_MCR_REQUEST, deleteMCR);
  yield takeEvery(mcrDetailActionTypes.APPROVE_MCR_REQUEST, approveMCR);
  yield takeEvery(mcrDetailActionTypes.GET_MCR_EXPORT_REQUEST, getMCRExportList);
  // template adjustments itembyid
  yield takeEvery(mcrDetailActionTypes.GET_MCR_BY_ID_REQUEST, getMCRbyID);
  // template adjustment import
  yield takeEvery(mcrDetailActionTypes.IMPORT_MCR_REQUEST, importMCR);
  yield takeEvery(mcrDetailActionTypes.CHECK_REJ_USER, checkRejStatForUser);
}

export default moduleWatcher;
