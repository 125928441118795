/* eslint-disable no-debugger */
/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { bcrActionTypes, bcrDetailActionTypes } from '../actions/actionTypes';

const BusinessCardRule = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case bcrActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case bcrActionTypes.SET_BCR_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case bcrActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case bcrActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case bcrActionTypes.UPSERT_BCR_REQUEST:
      return {
        ...state,
        upsertRequestedOn: new Date(),
        upsertData: action.data.upsertData,
        loading: true,
      };
    case bcrDetailActionTypes.GET_BCR_DETAIL_RESPONSE:
      return {
        ...state,
        detailData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case bcrActionTypes.LOG_BCR_DETAIL_REQUEST:
      return {
        ...state,
        detailRequestedOn: new Date(),
        loading: true,
      };
    case bcrDetailActionTypes.GET_BCR_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default BusinessCardRule;
