/* eslint-disable no-undef */
/* eslint-disable react/forbid-prop-types */
/**
 * @Description File description.
 * @FileName dateRangeFilter.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 07 July, 2021 02:30:33
 * @IssueID - Issue IDs
*/

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import FasatDropdown from '../../fasatDropdown/fasatDropdown';

const TODAY = 'DATE_RANGE_TODAY';
const THIS_WEEK = 'DATE_RANGE_THIS_WEEK';
const PAST_WEEK = 'DATE_RANGE_PAST_WEEK';
const PAST_MONTH = 'DATE_RANGE_PAST_MONTH';
const PAST_THREE_MONTHS = 'DATE_RANGE_PAST_THREE_MONTHS';
const PAST_SIX_MONTHS = 'DATE_RANGE_PAST_SIX_MONTHS';
const PAST_YEAR = 'DATE_RANGE_PAST_YEAR';
const PAST_TWO_YEARS = 'DATE_RANGE_PAST_TWO_YEARS';
const MORE_THAN_TWO_YEARS = 'DATE_RANGE_MORE_THAN_TWO_YEARS';
const COMMON_PLACEHOLDER_SELECT = 'COMMON_PLACEHOLDER_SELECT';
const valuesSeven = 7;
const valuesThree = 3;
const valuesOne = 1;
const valuesTwo = 2;
const valuesSix = 6;
const valuesYear = 1753;

const FasatDateRangeContainer = styled.div`
  .fasatDropdown {
    margin-top: 8px;
  }
`;

const setFilterValue = (values) => (values || undefined);

const onRangeChangeAction = (changeValue, setValue, setFilter) => {
  const values = [];
  switch (changeValue) {
    case TODAY:
      values.push(moment().startOf('day').toISOString().split('T')[0]);
      values.push(moment().endOf('day').toISOString().split('T')[0]);
      break;
    case THIS_WEEK:
      values.push(moment().startOf('week').toISOString().split('T')[0]);
      values.push(moment().endOf('day').toISOString().split('T')[0]);
      break;
    case PAST_WEEK:
      values.push(moment().subtract(valuesSeven, 'days').startOf('day').toISOString()
        .split('T')[0]);
      values.push(moment().endOf('day').toISOString().split('T')[0]);
      break;
    case PAST_MONTH:
      values.push(moment().subtract(valuesOne, 'months').startOf('day').toISOString()
        .split('T')[0]);
      values.push(moment().endOf('day').toISOString().split('T')[0]);
      break;
    case PAST_THREE_MONTHS:
      values.push(moment().subtract(valuesThree, 'months').startOf('day').toISOString()
        .split('T')[0]);
      values.push(moment().endOf('day').toISOString().split('T')[0]);
      break;
    case PAST_SIX_MONTHS:
      values.push(moment().subtract(valuesSix, 'months').startOf('day').toISOString()
        .split('T')[0]);
      values.push(moment().endOf('day').toISOString().split('T')[0]);
      break;
    case PAST_YEAR:
      values.push(moment().subtract(valuesOne, 'years').startOf('day').toISOString()
        .split('T')[0]);
      values.push(moment().endOf('day').toISOString().split('T')[0]);
      break;
    case PAST_TWO_YEARS:
      values.push(moment().subtract(valuesTwo, 'years').startOf('day').toISOString()
        .split('T')[0]);
      values.push(moment().endOf('day').toISOString().split('T')[0]);
      break;
    case MORE_THAN_TWO_YEARS:
      values.push(moment()
        .year(valuesYear)
        .month(0)
        .date(1)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .toISOString());
      values.push(moment().subtract(valuesTwo, 'years').endOf('day').toISOString()
        .split('T')[0]);
      break;
    default:
      throw new Error(`unkmoment()n value : ${changeValue.target.value}`);
  }
  setValue(changeValue);
  setFilter(setFilterValue(values));
};

const returnFasatDateRangeContainer = (options, id, value, t, onRangeChange) => (
  <FasatDateRangeContainer>
    <FasatDropdown
      dropdownItems={options}
      errors={[]}
      id={id}
      label=""
      onHandleChange={(e) => onRangeChange(e)}
      selected={0}
      value={value}
      placeholder={t(COMMON_PLACEHOLDER_SELECT)}
      hideLabel
      isMultiselect={false}
    />
  </FasatDateRangeContainer>
);

const FasatDateRangeFilter = ({ column }) => {
  const { filterValue, setFilter, id, primaryTopic } = column;
  const [value, setValue] = useState(filterValue);
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);
  const [t] = useTranslation();

  const onRangeChange = (changeValue) => {
    onRangeChangeAction(changeValue, setValue, setFilter);
  };

  /**
   * Subscribe button Clicl Event from Modal
   */
  useEffect(() => {
    if (primaryTopic && primaryTopic !== '') {
      const subId = PubSub.subscribe(primaryTopic, () => {
        setFilter(null);
        setValue(null);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);
  const options = [
    {
      label: t(TODAY),
      value: TODAY,
    },
    {
      label: t(THIS_WEEK),
      value: THIS_WEEK,
    },
    {
      label: t(PAST_WEEK),
      value: PAST_WEEK,
    },
    {
      label: t(PAST_MONTH),
      value: PAST_MONTH,
    },
    {
      label: t(PAST_THREE_MONTHS),
      value: PAST_THREE_MONTHS,
    },
    {
      label: t(PAST_SIX_MONTHS),
      value: PAST_SIX_MONTHS,
    },
    {
      label: t(PAST_YEAR),
      value: PAST_YEAR,
    },
    {
      label: t(PAST_TWO_YEARS),
      value: PAST_TWO_YEARS,
    },
    {
      label: t(MORE_THAN_TWO_YEARS),
      value: MORE_THAN_TWO_YEARS,
    },
  ];
  return (
    returnFasatDateRangeContainer(options, id, value, t, onRangeChange)
  );
};

FasatDateRangeFilter.propTypes = {
  column:
    PropTypes.shape({
      filterValue: PropTypes.any,
      setFilter: PropTypes.func,
      preFilteredRows: PropTypes.arrayOf(PropTypes.shape({})),
      id: PropTypes.string,
      primaryTopic: PropTypes.string,
    }).isRequired,

};

export default FasatDateRangeFilter;
