/**
 * @Description Save as default filter modal container
 * @FileName saveFilter.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 23 June, 2021 05:17:13
 * @IssueID Issue Detail
 */

import React, { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FasatConfirmationMsg } from '../..';
import { staticCommonLabelKeys } from '../../../moduleConstants';
import { Logger } from '../../../util';

const SaveAsDefault = ({
  onConfirmationTopic,
  onConfirmationClick,
  onCancelTopic,
}) => {
  const [t] = useTranslation();
  const message = t(staticCommonLabelKeys.FILTER_DEFAULT_CONFIRMATION);
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);

  /**
    * @param {Array} options
   * Confirm Click Handler
   * @returns {Array}
   */
  const handleConfirmClick = (data) => {
    data.close();
    onConfirmationClick();
  };

  /**
   * Subscribe button Clicl Event from Modal
   */
  useEffect(() => {
    if (onConfirmationTopic && onConfirmationTopic !== ''
    && (saveSubscriptionIds || saveSubscriptionIds.length === 0)) {
      const subId = PubSub.subscribe(onConfirmationTopic, (msg, data) => {
        handleConfirmClick(data);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    if (onCancelTopic && onCancelTopic !== '') {
      PubSub.subscribe(onCancelTopic, () => Logger.verbose(`Cancel click handler
       ${onCancelTopic}`));
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);

  return (
    <div>
      <FasatConfirmationMsg msg={message} />
    </div>
  );
};

SaveAsDefault.propTypes = {
  onConfirmationTopic: PropTypes.string.isRequired,
  onCancelTopic: PropTypes.string,
  onConfirmationClick: PropTypes.func.isRequired,
};

SaveAsDefault.defaultProps = {
  onCancelTopic: '',
};
export default SaveAsDefault;
