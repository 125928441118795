/**
 * @Description FasatColumnOrder icon Shape Prototypes File
 * @FileName iconShape.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
import PropTypes from 'prop-types';

export default PropTypes.shape({
  // moveLeft: PropTypes.node.isRequired,
  // moveAllLeft: PropTypes.node.isRequired,
  // moveRight: PropTypes.node.isRequired,
  // moveAllRight: PropTypes.node.isRequired,

  // Optional properties
  // moveDown: PropTypes.node,
  // moveUp: PropTypes.node,
});
