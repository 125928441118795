import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { ffStructureActionTypes } from '../actions/actionTypes';
import {
  exportErrorMsg,
  processError,
  renderFileName,
} from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

export function* getStructreList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(ffStructureActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_STRUCTURE_LIST_RESPONSE, action.data);
    yield all([
      put(getAction(ffStructureActionTypes.GET_FF_STRUCTURE_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(ffStructureActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* updateffStructure(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ffStructureActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(ffStructureActionTypes.RESET_FF_STRUCTURE_SUBMISSION_ERROR)),
  ]);
  try {
    const moduleAPIStruct = action.data.addNewFFStructure ? moduleAPI.FF_STRUCTURE_ADD : moduleAPI.FF_STRUCTURE_EDIT;
    const responseData = yield call(invokeApi, moduleAPIStruct, action.data.formatObject);
    if (responseData) {
      const resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(ffStructureActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield all([
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    yield all([
      put(getAction(ffStructureActionTypes.GET_FF_SUBMISSION_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* delFFStructureList(action) {
  yield all([
    put(getAction(ffStructureActionTypes.RESET_SERVER_VALUE, null)),
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_STRUCTURE_DELETE, action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(ffStructureActionTypes.RESET_DELETE_VALUE, resetDeleteValue));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(ffStructureActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getffStructureExportList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(ffStructureActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_STRUCTURE_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(ffStructureActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getffStructureById(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ffStructureActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_STRUCTURE_GET_BY_ID,
      action.data);
    yield all([
      put(getAction(ffStructureActionTypes.GET_FF_STRUCTURE_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // TODO: Need to work on it
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(ffStructureActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  yield takeEvery(ffStructureActionTypes.GET_STRUCTURE_LIST_REQUEST, getStructreList);
  yield takeEvery(ffStructureActionTypes.DELETE_FF_STRUCTURE_REQUEST, delFFStructureList);
  yield takeEvery(ffStructureActionTypes.GET_FF_STRUCTURE_EXPORT, getffStructureExportList);
  yield takeEvery(ffStructureActionTypes.UPDATE_FF_STRUCTURE, updateffStructure);
  yield takeEvery(ffStructureActionTypes.GET_FF_STRUCTURE_LIST_BY_ID, getffStructureById);
}

export default moduleWatcher;
