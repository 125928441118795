/**
 * @Description FasatPagination component page
 * @FileName fasatPagination.js
 * @Author UMA DUTTA-duttuma
 * @CreatedOn 29 January, 2021 6:00:00
 * @IssueID CDTFASATMOD- 502
 */
import PropTypes from 'prop-types';
import { Pagination } from '../../libs/fasatSharedComponent';

const FasatPagination = (props) => {
  const { currentPageIndex, pageSize, totalRowCount, onPageChange, activateDataGrid } = props;
  const totalItems = activateDataGrid ? Math.ceil(totalRowCount / pageSize) : 0;
  /**
   * Method to set current page index
   * @param {number} pageIndex
   * @returns number
   */
  const setCurrentPageIndex = (pageIndex) => (totalRowCount ? pageIndex : totalRowCount);
  const currentPage = activateDataGrid ? setCurrentPageIndex(currentPageIndex) : 0;
  return (
    <Pagination
      {...props}
      totalItems={Number.isNaN(totalItems) ? 0 : totalItems}
      currentItem={currentPage}
      onChange={onPageChange}
    />
  );
};

FasatPagination.propTypes = {
  currentPageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  totalRowCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  activateDataGrid: PropTypes.bool,
};

FasatPagination.defaultProps = {
  activateDataGrid: false,
  currentPageIndex: 0,
  pageSize: 0,
  totalRowCount: 0,
};

export default FasatPagination;
