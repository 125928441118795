/**
 * @Description Container for delete business card rule list
 * @FileName deleteBusinessCardRule.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 20 April, 2021 08:49:34
 * @IssueID 774
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { globalConstants, staticCommonLabelKeys } from '../../../moduleConstants';
import FasatConfirmationMsg from '../../fasatConfirmationMsg/fasatConfirmationMsg';
import showFasatNotification from '../../fasatQuickActionNotification/showFasatNotification';
import { deleteFilterRequest } from '../../../redux/actions/commonActions';
import { FasatMessageCard } from '../..';
import { Logger } from '../../../util';
import { fetchServerError } from '../../fasatPage/fasatPage';

const { serverErrorMsgContainer, DELETE } = globalConstants;

const DeleteFilterItem = ({
  formData,
  onDeleteTopic,
  onCancelTopic,
  reqObj,
  triggerFilterListCount,
  toasterContainerId,
  cusFilListModalId,
}) => {
  const [serverErrors, setServerErrors] = useState([]);
  const [t] = useTranslation();
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);
  const [saveEventData, setSaveEventData] = useState(null);
  const dispatch = useDispatch();
  const filterName = formData.name;
  const FilterDeleteSuccessMsg = t(staticCommonLabelKeys.FILTER_DELETE_SUCCESS,
    { filterName, interpolation: { escapeValue: false } });
  const deleteRequestError = useSelector((state) => state.ApplicationState.deleteRequestError);
  const deleteData = useSelector((state) => state.ApplicationState.deleteData);

  /**
   * Function to dynamically return Delete confirmation message
   * @returns string
   */
  const filterDeleteConfirmationMsg = () => {
    if (formData.isShared) {
      return t(staticCommonLabelKeys.FILTER_SHARED_DELETE_CONFIRMATION,
        { filterName, interpolation: { escapeValue: false } });
    }
    return t(staticCommonLabelKeys.FILTER_DELETE_CONFIRMATION,
      { filterName, interpolation: { escapeValue: false } });
  };

  /**
   * @param {Array} options
   * Validate all form data and lift event handleDelete
   * @returns {Array}
   */
  const handleDeleteClick = (data) => {
    if (data) {
      const formatObject = {
        action: DELETE,
        filter: data,
      };
      const reqObjFilter = reqObj;
      reqObjFilter.page = 1;
      const reqObject = { reqObjFilter, listCount: false };
      dispatch(deleteFilterRequest({ formatObject, reqObject }));
    }
  };

  /**
   * Validate and Display Server Error Message
   */
  useEffect(() => {
    if (deleteRequestError) {
      setServerErrors(deleteRequestError);
    }
    if (deleteData && saveEventData) {
      saveEventData.close();
      const successMsg = FilterDeleteSuccessMsg;
      const notificationTime = 3000;
      const dialogEle = document.getElementById(cusFilListModalId);
      if (dialogEle) {
        dialogEle.scroll(0, 0);
        showFasatNotification(successMsg, notificationTime, toasterContainerId);
        dialogEle.focus();
      }
      triggerFilterListCount(formData.id);
    }
  }, [deleteRequestError, deleteData]);
  /**
   * Subscribe button Clicl Event from Modal
   */
  useEffect(() => {
    if (
      onDeleteTopic
      && onDeleteTopic !== ''
      && (saveSubscriptionIds || saveSubscriptionIds.length === 0)
    ) {
      const subId = PubSub.subscribe(onDeleteTopic, (msg, data) => {
        setSaveEventData(data);
        handleDeleteClick(formData);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    if (onCancelTopic && onCancelTopic !== '') {
      PubSub.subscribe(onCancelTopic, () => Logger.verbose(`Cancel click handler ${onCancelTopic}`));
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);

  return (
    <>
      {serverErrors.length > 0 && (
      <div className={serverErrorMsgContainer}>
        <FasatMessageCard
          data-testid="deleteFilterItem"
          titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
          variant="primary_alert"
        >
          {serverErrors.map((errItem) => (
            fetchServerError(t, errItem)
          ))}
        </FasatMessageCard>
      </div>
      )}
      <FasatConfirmationMsg msg={filterDeleteConfirmationMsg()} />
    </>
  );
};

DeleteFilterItem.propTypes = {
  onDeleteTopic: PropTypes.string.isRequired,
  onCancelTopic: PropTypes.string,
  reqObj: PropTypes.shape({}).isRequired,
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      isShared: PropTypes.bool,
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  triggerFilterListCount: PropTypes.func,
  toasterContainerId: PropTypes.string.isRequired,
  cusFilListModalId: PropTypes.string.isRequired,
};

DeleteFilterItem.defaultProps = {
  onCancelTopic: '',
  triggerFilterListCount: () => undefined,
};

export default DeleteFilterItem;
