/**
 * @Description Root reducer for the application
 * @FileName appRootReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:46:46
 * @IssueID - 308
 */
import { combineReducers } from 'redux';
import { ApplicationState, ApplicationStateRBAC } from '../app/common/redux/reducers/applicationState';
import ApplicationStateFilterList from '../app/common/redux/reducers/applicationStateFilterList';
import { BusinessCardRule, BusinessCardRuleFilterList,
  ClientCardRule,
  ClientFilterList,
  TemplateAdjustments, ManualCommissionRecords, ManualCommissionRecordsFilterList,
  TemplateAdjustmentsFilterList, ClientTabs, FieldForce, FieldForceFilterList,
  Exceptions, ExceptionsFilterList,
  FinArrangements, ManualAdjustments, ReqInquiry, ReqInquiryFilterList,
  TaskInquiry, TaskInquiryFilterList, MassAgentTrasnferInq, MassAgentTransferInqFilterList,
  PaymentController, PaymentControllerFilterList, FieldForceRequirement,
  DeductionInquiry, DeductionInquiryFilterList, FieldForcePayments, RelationshipFilterList, FieldForceMovementList,
  ClientRelationshipRule, LicenceFilterList, LicenceFilter, selectLetter, FFLetterFilterList, FFLetterReducer,
  FFDeduction, FinArrangementFilterList,
} from '../app/modules/agency/redux';
import { CsCardRule, CsFilterList, DefaultCompStructFilterList, DefaultCsCardRule,
  LROEligibility, LROEligibilityFilterList, RateMasterList, RateMasterDetailList,
  RateKeyList, RateDetailsList, RateParticipantList, SalesCreditSummary,
  RateParticipantsList, RateSweepList,
  ExternalRateList,
  ExternalRateFilterList,
} from '../app/modules/compcal/redux';
import { PpFilterList, DefaultPpFilterList } from '../app/modules/payments/redux';
import { DataDictionary, DataDictionaryFilterList, GenEditType, GenEditTypeFilterList,
  GenEditRelationshipType, GenEditRelationshipTypeFilterList,
  GenEditRelationshipVal, GenEditRelationshipValFilterList,
  GenEditValues, GenEditValuesFilterList,
  PlanCode, PlanCodeFilterList,
  PaymentThreshold, PaymentThresholdFilterList,
  InterfaceMapping, InterfaceMappingFilterList,
  TransactionDefinition, TransactionDefinitionFilterList,
  SystemParameters, SystemParametersFilterList,
  SystemOptionsDetails,
  EventsList,
  EventsFilterList,
  UsersList,
  UsersFilterList,
  IncomeExpenseMap, IncomeExpenseMapFilterList,
  PeriodDefinition, PeriodDefinitionFilterList, DailyProcess,
  DailyProcessFilterList,
  DailyActivityFCR,
  DailyActivityFCRFilterList,
  AccessOptions,
  AccessOptionsFilterList,
  InterfaceSetup, InterfaceSetupFilterList,
  ChequeSecurity,
  ChequeSecurityFilterList,
  ConfigureLetters,
  ConfigureLettersFilterList,
  ProducerAuditTrail,
  ProducerAuditTrailFilterList,
  // ManageUsersFilterList,
  // ManageUsersList,
  StatementsXMLlifeFeeds,
  // ManageUsersListFilterList,
  AutoReportsList,
  DataTransfer,
  DataTransferFilterList,
  StructureFilterList,
  StructureFilterRule,
  ManageUsersFilterList,
  ManageUsersList,
  ManageLevel,
  FieldforceSecurity,
  FieldForceSecurityFilterList,
} from '../app/modules/admin/redux';
import { APFilterList, ActivityProcessed, ActivityUnProcessedFilterList,
  ActivityUnProcessed, FpaMonitoringFilterList, FpaMonitoring, ActivityProcessedExt, ActivityProcessedFilterListExt } from '../app/modules/activity/redux';
import ManageGroups from '../app/modules/admin/redux/reducers/manageGroups';

import { CommissionFeed, ReportList, ProdSummaryFilterList, ProdSummary,
  BalSummary,
  BalSummaryFilterList, ReportRepository, ReportRepositoryFilterList,
  InteractiveActivityReport,
} from '../app/modules/report/redux';

import { AccountingInquiry, AccountingInquiryFilterList } from '../app/modules/accounting/redux';
import { CodeAndHierarchy, AdvisorDetailsReducer,
  DetailsActivityReducer } from '../app/modules/codesAndHierarchyExternal/redux';
/**
  * All the reducers for different modules needs to be included here
  */
const AppRootReducer = combineReducers({
  ApplicationState,
  ApplicationStateRBAC,
  ApplicationStateFilterList,
  BusinessCardRule,
  BusinessCardRuleFilterList,
  GenEditRelationshipType,
  GenEditRelationshipTypeFilterList,
  DataDictionary,
  DataDictionaryFilterList,
  GenEditType,
  GenEditTypeFilterList,
  GenEditValues,
  GenEditValuesFilterList,
  SystemParameters,
  SystemParametersFilterList,
  PlanCode,
  PlanCodeFilterList,
  PaymentThreshold,
  PaymentThresholdFilterList,
  GenEditRelationshipVal,
  GenEditRelationshipValFilterList,
  InterfaceMapping,
  InterfaceMappingFilterList,
  TransactionDefinition,
  TransactionDefinitionFilterList,
  ManageGroups,
  TemplateAdjustments,
  TemplateAdjustmentsFilterList,
  ManualCommissionRecords,
  ManualCommissionRecordsFilterList,
  SystemOptionsDetails,
  AccessOptions,
  AccessOptionsFilterList,
  EventsList,
  EventsFilterList,
  UsersList,
  UsersFilterList,
  CsFilterList,
  CsCardRule,
  IncomeExpenseMap,
  IncomeExpenseMapFilterList,
  PeriodDefinition,
  PeriodDefinitionFilterList,
  DefaultCompStructFilterList,
  DefaultCsCardRule,
  LROEligibility,
  LROEligibilityFilterList,
  ClientCardRule,
  ClientFilterList,
  FieldForce,
  ClientTabs,
  FieldForceFilterList,
  ChequeSecurity,
  ChequeSecurityFilterList,
  ConfigureLetters,
  ConfigureLettersFilterList,
  DailyProcess,
  DailyProcessFilterList,
  DailyActivityFCR,
  DailyActivityFCRFilterList,
  FinArrangements,
  ReportList,
  ProdSummary,
  ProdSummaryFilterList,
  BalSummary,
  BalSummaryFilterList,
  ManualAdjustments,
  ReqInquiry,
  ReqInquiryFilterList,
  TaskInquiry,
  TaskInquiryFilterList,
  MassAgentTrasnferInq,
  MassAgentTransferInqFilterList,
  Exceptions,
  ExceptionsFilterList,
  RateMasterList,
  RateMasterDetailList,
  RateKeyList,
  RateDetailsList,
  RateParticipantList,
  RateParticipantsList,
  PaymentController,
  PaymentControllerFilterList,
  FieldForceRequirement,
  SalesCreditSummary,
  RateSweepList,
  InterfaceSetup,
  InterfaceSetupFilterList,
  DeductionInquiry,
  DeductionInquiryFilterList,
  FieldForcePayments,
  RelationshipFilterList,
  ClientRelationshipRule,
  AccountingInquiry,
  AccountingInquiryFilterList,
  FieldForceMovementList,
  ProducerAuditTrail,
  ProducerAuditTrailFilterList,
  StatementsXMLlifeFeeds,
  ReportRepository,
  ReportRepositoryFilterList,
  DataTransfer,
  DataTransferFilterList,
  StructureFilterList,
  StructureFilterRule,
  AutoReportsList,
  LicenceFilterList,
  LicenceFilter,
  selectLetter,
  FFLetterFilterList,
  FFLetterReducer,
  CommissionFeed,
  ActivityProcessed,
  APFilterList,
  ActivityUnProcessed,
  ActivityUnProcessedFilterList,
  ActivityProcessedExt,
  ActivityProcessedFilterListExt,
  FpaMonitoringFilterList,
  FpaMonitoring,
  PpFilterList,
  DefaultPpFilterList,
  FFDeduction,
  ManageUsersFilterList,
  ManageUsersList,
  ManageLevel,
  FieldforceSecurity,
  FieldForceSecurityFilterList,
  CodeAndHierarchy,
  AdvisorDetailsReducer,
  DetailsActivityReducer,
  ExternalRateList,
  InteractiveActivityReport,
  ExternalRateFilterList,
  FinArrangementFilterList,
});

export default AppRootReducer;
