import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { activityUnProcessedActionType } from '../actions/actionTypes';

export const REDUCER_NAME = 'ActivityUnProcessedFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
      selectedFilter: action.data.selectedItem || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const logAPListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const logAUPNegativeReq = (state, action) => ({
  ...state,
  negativeRequest: action.data ? action.data : state.listRequest,
  negativeRequestedOn: new Date(),
  loading: true,
});

const updateTableConfig = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const ActivityUnProcessedFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case activityUnProcessedActionType.UPDATE_AUP_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case activityUnProcessedActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case activityUnProcessedActionType.REMOVE_AUP_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case activityUnProcessedActionType.RESET_AUP_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case activityUnProcessedActionType.RESET_AUP_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    default:
      return null;
  }
};

const ActivityUnProcessedFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case activityUnProcessedActionType.LOG_AUP_LIST_REQUEST:
      return logAPListReq(state, action);
    // log error
    case activityUnProcessedActionType.GET_AUP_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case activityUnProcessedActionType.GET_AUP_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case activityUnProcessedActionType.LOG_AUP_NEGATIVE_REQUEST:
      return logAUPNegativeReq(state, action);
    // log error
    case activityUnProcessedActionType.GET_AUP_NEGATIVE_ERROR:
      return {
        ...state,
        negativeRequestError: action.data,
        negativeRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case activityUnProcessedActionType.GET_AUP_NEGATIVE_RESPONSE:
      return {
        ...state,
        negativeData: action.data,
        negativeRequestError: null,
        negativeRequestProcessedOn: new Date(),
        loading: false,
      };
    case activityUnProcessedActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const ActivityUnProcessedFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = ActivityUnProcessedFilterListConfig(state, action);
  const stateReqRes = ActivityUnProcessedFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ActivityUnProcessedFilterList;
