import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { advisorDetailsActions, msActivityActionTypes, chDetailsActionTypes } from '../actions/actionTypes';

const initialState = {
  generalDetails: null,
  bankingDetails: null,
  msactivityDetailsList: null,
};
const checkServeCode = (data) => data.fundServCode !== null;

const getFFServeCode = (generalDetails) => {
  let fundServCode = '';
  const fundServCodeList = generalDetails.filter(checkServeCode);
  if (fundServCodeList.length > 0) {
    fundServCode = fundServCodeList.map((code) => code.fundServCode).join();
  }
  return fundServCode;
};

const AdvisorDetailsReducerOne = (state = { ...initialState }, action = null) => {
  switch (action.type) {
    case advisorDetailsActions.GET_GENERAL_DETAIL_RESPONSE:
      return {
        ...state,
        generalDetails: action?.data.length > 0 ? action?.data[0] : null,
        generalFFserveCode: action?.data.length > 0 && getFFServeCode(action.data),
        loading: false,
      };

    case advisorDetailsActions.GET_BANKING_DETAIL_RESPONSE:
      return {
        ...state,
        bankingDetails: action?.data,
        loading: false,
      };

    case advisorDetailsActions.GET_EXT_REQUIREMENT_EXPORT_ERROR:
      return {
        ...state,
        extReqExportErr: action.data?.serverRequestError,
        loading: false,
      };

    case msActivityActionTypes.SET_EXTERNAL_MS_ACTIVITY_LIST:
      return {
        ...state,
        msactivityDetailsList: action?.data.data,
        loading: false,
      };

    default:
      return null;
  }
};

const AdvisorDetailsReducerTwo = (state = { ...initialState }, action = null) => {
  switch (action.type) {
    case msActivityActionTypes.SET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY_LIST:
      return {
        ...state,
        msactivityFirstLevelDetailsList: action?.data,
        loading: false,
      };
    case msActivityActionTypes.RESET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY_LIST:
      return {
        ...state,
        msactivityFirstLevelDetailsList: null,
        loading: false,
      };
    case msActivityActionTypes.SET_EXTERNAL_ALL_LEVEL_MS_ACTIVITY_LIST:
      return {
        ...state,
        msactivityAllLevel: action?.data,
        loading: false,
      };

    case advisorDetailsActions.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: action.data?.serverRequestError,
        loading: false,
      };

    case chDetailsActionTypes.GET_CH_ADVISOR_DETAILS_FLAG_RES:
      return {
        ...state,
        showAdvisorDetailsTab: action.data > 0,
      };

    case commonTypes.RESETSTORE:
      return initialState;

    default:
      return null;
  }
};

const AdvisorDetailsReducer = (state = { ...initialState }, action = null) => {
  const stateConfig = AdvisorDetailsReducerOne(state, action);
  const stateReqRes = AdvisorDetailsReducerTwo(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default AdvisorDetailsReducer;
