import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { rateKeyActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'RateKeyList';

const updateFilterConfig = (state, action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        ...state,
        selectedFilter,
        systemDefaultFilter: action.data.systemDefault || null,
        userDefaultFilter: action.data.userDefault || null,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        ...state,
        selectedFilter,
        userDefaultFilter: action.data.userDefault,
      };
    }
  }
  return {
    ...state,
    selectedFilter,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  selectedFilter: action.data ? action.data : null,
});

const logListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
  rkInUse: action.data?.rkInUse,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});
const getEditItemOnloadResponse = (state, action) => ({
  ...state,
  getOnloadDetails: action.data,
});
const getEditItemOnloadEditResponse = (state, action) => ({
  ...state,
  getOnloadEditDetails: action.data,
});

const RateKeyList1 = (state, action = null) => {
  switch (action.type) {
    case rateKeyActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case rateKeyActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case rateKeyActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case rateKeyActionTypes.LOG_LIST_REQUEST:
      return logListRequest(state, action);
    case rateKeyActionTypes.GET_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case rateKeyActionTypes.GET_BY_ONLOAD_RESPONSE:
      return getEditItemOnloadResponse(state, action);
    case rateKeyActionTypes.GET_BY_ONLOAD_EDIT_RESPONSE:
      return getEditItemOnloadEditResponse(state, action);
    case rateKeyActionTypes.GET_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case rateKeyActionTypes.GET_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };

    default:
      return null;
  }
};

const RateKeyList2 = (state, action = null) => {
  switch (action.type) {
    case rateKeyActionTypes.RESET_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        getEditItemDetails: null,
        loading: false,
      };
    case rateKeyActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case rateKeyActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case rateKeyActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case rateKeyActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data?.deleteRequest,
        deleteRequestError: action.data?.deleteRequestError,
        deleteRequestedOn: action.data?.deleteRequestedOn,
        deleteRequestProcessedOn: action.data?.deleteRequestProcessedOn,
        deleteData: action.data?.deleteData,
      };
    case rateKeyActionTypes.SET_RATE_KEY_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const RateKeyList = (state = { ...basicFilterInitialState }, action = null) => {
  const rateKey1 = RateKeyList1(state, action);
  if (rateKey1 !== null) {
    return rateKey1;
  }
  const rateKey2 = RateKeyList2(state, action);
  if (rateKey2 !== null) {
    return rateKey2;
  }
  return state;
};

export default RateKeyList;
