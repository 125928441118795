/* eslint-disable import/named */
/**
 * @Description Reducer for business card rules
 * @FileName clientRelationship.js
 * @Author Prasenjit Chakraborty - chakpra
 * @CreatedOn 13 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { ffStructureActionTypes } from '../actions/actionTypes';

const StructureFilterRule = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case ffStructureActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case ffStructureActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case ffStructureActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case ffStructureActionTypes.GET_FF_SUBMISSION_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case ffStructureActionTypes.RESET_FF_STRUCTURE_SUBMISSION_ERROR:
      return {
        ...state,
        detailRequestError: null,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case ffStructureActionTypes.GET_FF_STRUCTURE_BY_ID_RESPONSE:
      return {
        ...state,
        detailDataById: action.data,
        detailByIdRequestProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default StructureFilterRule;
