import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { extRateActionTypes } from '../actions/actionTypes';

export const REDUCER_NAME = 'ExternalRateFilterList';

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const updateTableConfig = (state, action) => ({
  ...state,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  selectedFilter: action.data ? action.data : null,
});

const ExternalRateFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case extRateActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    default:
      return null;
  }
};

const ExternalRateFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // store response
    case extRateActionTypes.GET_EXT_RATE_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case extRateActionTypes.GET_EXT_RATE_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case extRateActionTypes.GET_EXT_NEW_RATE_RESPONSE:
      return {
        ...state,
        getNewRate: action.data,
      };
    case extRateActionTypes.GET_UPLINE_RATE:
      return {
        ...state,
        uplineData: action?.data?.uplineRate,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const ExternalRateFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = ExternalRateFilterListConfig(state, action);
  const stateReqRes = ExternalRateFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ExternalRateFilterList;
