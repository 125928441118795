/**
 * @Description Fasat Group By Page
 * @FileName groupBy.js
 * @Author Karthick K
 * @CreatedOn 07 July, 2022 21:40:00
 * @IssueID - Poc
 */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import GroupByRow from './groupByRow';
import './groupBy.scss';
import { staticCommonLabelKeys } from '../../../moduleConstants';

// #region constants
// #endregion
// #region styled-components
// #endregion
// #region functions
// #endregion
// #region component
const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()),
  removeGroupByColumn: PropTypes.func,
  groupByColumn: PropTypes.func,
  showDuplicateMessage: PropTypes.func,
  customColumnGroupBy: PropTypes.arrayOf(PropTypes.shape()),
  operatorList: PropTypes.arrayOf(PropTypes.shape()),
  groupByActive: PropTypes.bool,
};

const defaultProps = {
  columns: [],
  removeGroupByColumn: () => undefined,
  customColumnGroupBy: [],
  groupByColumn: () => undefined,
  showDuplicateMessage: () => undefined,
  operatorList: [],
  groupByActive: true,
};

const GroupBy = ({
  columns,
  groupByColumn,
  groupByActive,
  removeGroupByColumn,
  customColumnGroupBy,
  showDuplicateMessage,
  operatorList,
}) => {
  const [t] = useTranslation();
  const [totalRows, setTotalRows] = useState([{ uuid: uuidv4() }]);
  const KeyValue = null;

  /**
   * Initiate total row as per selected column order
   */
  useEffect(() => {
    if (customColumnGroupBy && customColumnGroupBy.length > 0) {
      setTotalRows(customColumnGroupBy);
    } else {
      setTotalRows([{ uuid: uuidv4(), key: KeyValue, operator: null }]);
    }
  }, [customColumnGroupBy]);

  /**
   * Removing a row
   * @param {any} val
   */
  const removeRow = (val) => {
    const filteredCol = totalRows.filter(({ uuid }) => uuid !== val);
    setTotalRows(filteredCol);
  };

  /**
   * Adding a row
   */
  const addRow = () => {
    setTotalRows((arr) => [...arr, { uuid: uuidv4(), key: KeyValue, asc: null }]);
  };

  /**
   * Class names
   */
  const soFilterTableClass = 'so-filterTable';
  const soFilterRowClass = 'so-filterRow';
  const soActionCellClass = 'so-actionCell';
  const soBlankCellClass = 'so-blankCell';
  const soFilterCellClass = 'so-filterCell';
  const soSortOrderCellClass = 'so-sortOrderCell';

  return (
    <div className={soFilterTableClass}>
      <div className={`${soFilterRowClass} deskDisp filterR`}>
        <div className={soFilterCellClass}>{t(staticCommonLabelKeys.FILTER_LABEL_COLUMN)}</div>
        <div className={`${soFilterCellClass} ${soSortOrderCellClass}`}>
          {t(staticCommonLabelKeys.FILTER_LABEL_OPERATOR)}
        </div>
        <div className={soActionCellClass} />
        <div className={soBlankCellClass} />
      </div>
      {totalRows.map(({ uuid, key, operator }, idx) => (
        <GroupByRow
          key={uuid}
          item={key}
          operator={operator}
          addRow={addRow}
          removeRow={removeRow}
          configColumn={groupByColumn}
          uuid={uuid}
          index={idx}
          totalRows={totalRows}
          columnConfig={columns}
          removeGroupByColumn={removeGroupByColumn}
          customColumnGroupBy={customColumnGroupBy}
          showDuplicateMessage={showDuplicateMessage}
          operatorList={operatorList}
          groupByActive={groupByActive}
        />
      ))}
    </div>
  );
};

GroupBy.propTypes = propTypes;
GroupBy.defaultProps = defaultProps;
// #endregion

export default GroupBy;
