/**
 * @Description This is FasatMasterInformation container page
 * @FileName fasatMasterInformation.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 27 January, 2021 11:15:07
 * @IssueID CDTFASATMOD-502
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../../../../index.scss';
import './fasatMasterInformation.scss';
import { ActionButton } from '@manulife/mux';
import { Chart } from '@manulife/mux-cds-icons';
import FasatLabel from '../fasatLabel/fasatLabel';
import FasatH2 from '../fasatH2/fasatH2';
import FasatLink from '../fasatLink/fasatLink';
import { getAction } from '../../../../stateManagement/reduxUtil';
import { codesAndHierarchyActionTypes } from '../../../modules/codesAndHierarchyExternal/redux';

const FasatMasterInformation = ({ information, infoTitle, showInfoTitle, chDetails, orgLevel }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="masterInfoSection" data-testid="FasatMasterInformationComponent">
      {showInfoTitle && <FasatH2>{infoTitle}</FasatH2>}
      { information.map((info) => (
        <div className={`infoCell ${chDetails ? 'hideMasterHeader' : ''}`} key={info.key}>
          <FasatLabel>{info.key}</FasatLabel>
          { !info.isCodeAndHierHyperlink && <FasatLabel isContent>{info.value}</FasatLabel>}
          { info.isCodeAndHierHyperlink && (
          <FasatLink
            class="hyperlink"
            tabIndex="0"
            aria-label={info.value}
            onClick={() => {
              dispatch(getAction(codesAndHierarchyActionTypes.GET_CODES_AND_HIER_MOVEMENT_REQUEST,
                { level: orgLevel, movOrgCd: info.value }));
              history.push({ pathname: '/codeAndHierarchy',
                state: {
                  status: 'grid',
                } });
            }}
          >
            {info.value}
            <ActionButton
              icon={(
                <Chart
                  style={{ width: '20px', height: '30px' }}
                />
)}
              ariaLabel={info.value}
            />

          </FasatLink>
          )}
        </div>
      ))}

      {chDetails && (
      <div className="infoCell viewChMasterHeader">
        <FasatLabel>{chDetails.key}</FasatLabel>
        <FasatLabel isContent>{chDetails.value}</FasatLabel>
      </div>
      )}

    </div>
  );
};

FasatMasterInformation.propTypes = {
  information: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  infoTitle: PropTypes.string,
  showInfoTitle: PropTypes.bool,
  chDetails: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  orgLevel: PropTypes.string,
};

FasatMasterInformation.defaultProps = {
  information: [],
  infoTitle: '',
  showInfoTitle: true,
  chDetails: null,
  orgLevel: null,
};

export default FasatMasterInformation;
