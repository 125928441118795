/* eslint-disable import/prefer-default-export */

import { fieldForceDeductionActionTypes } from '../../redux';
import { FILTER_REDUCER_NAME } from '../../redux/reducers/fieldForceDeduction';

/**
 * @Description This is the container page for buisness Crad Rule Config
 * @FileName businessCardRuleConfig.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 June, 2021 12:03:55
 * @IssueID Issue Detail
 */
const filterConfig = {
  IS_GROUPBY: true,
  IS_DISTINCT: true,
  REDUX_STATE: FILTER_REDUCER_NAME,
  // SEARCH_ACTION: bcrActionTypes.GET_BCR_LIST_REQUEST,
  SEARCH_ACTION: fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST,
  UPDATE_CONFIGURATION: fieldForceDeductionActionTypes.UPDATE_FF_COLUMN_CONFIG,
  UPDATE_TABLE_CONFIGURATION: fieldForceDeductionActionTypes.UPDATE_TABLE_CONFIGURATION,
  UPDATE_FILTER_CONFIG: fieldForceDeductionActionTypes.UPDATE_FILTER_CONFIG,
  EXPORT_ACTION: fieldForceDeductionActionTypes.GET_FF_EXPORT_REQUEST,
  REMOVE_USER_DEFAULT_FILTER: fieldForceDeductionActionTypes.REMOVE_USER_DEFAULT_FILTER,
};

export { filterConfig };
