import { environment } from '../../../common/util';

const envData = environment();
const CodesAndHierarchyApiConfig = {
  CODES_AND_HIER_UPSTREAM: {
    endpoint: `${envData.apiHost}/api/codeAndHier/upstream`,
    type: 'POST',
    authorizationRequired: true,
    showLoading: true,
  },
  CODES_AND_HIER_LIST_DOWNSTREAM: {
    endpoint: `${envData.apiHost}/api/codeAndHier/downstream`,
    type: 'POST',
    authorizationRequired: true,
    showLoading: true,
  },
  CODES_AND_HIER_LIST: {
    endpoint: `${envData.apiHost}/api/codeAndHier/list`,
    type: 'POST',
    authorizationRequired: true,
    showLoading: true,
  },
  CODES_AND_HIER_MOVEMENT: {
    endpoint: `${envData.apiHost}/api/codeAndHier/movement`,
    type: 'POST',
    authorizationRequired: true,
    showLoading: true,
  },
  CODES_AND_HIER_EXPORT: {
    endpoint: `${envData.apiHost}/api/codeAndHier/export`,
    type: 'DOWNLOAD',
    authorizationRequired: true,
    showLoading: true,
  },
  CODES_AND_HIER_LEVEL_RBAC_DATA: {
    endpoint: `${envData.apiHost}/api/security/securedresources?level=@@level`,
    type: 'GET',
    authorizationRequired: true,
    showLoading: true,
  },
  CODES_AND_HIER_LEVEL_NICKNAME: {
    endpoint: `${envData.apiHost}/api/codeAndHier/nickname?@@orgCode&@@orgLevel`,
    type: 'GET',
    authorizationRequired: true,
    showLoading: true,
  },
  CODES_AND_HIER_LEVEL_NICKNAME_UPDATE: {
    endpoint: `${envData.apiHost}/api/codeAndHier/nickname`,
    type: 'POST',
    authorizationRequired: true,
    showLoading: true,
  },
  CODES_AND_HIER_ORG_SECURITY_LIST: {
    endpoint: `${envData.apiHost}/api/codeAndHier/orgSecurity`,
    type: 'GET',
    authorizationRequired: true,
    showLoading: true,
  },
  CH_DEBIT_BALANCE_LIST: {
    endpoint: `${envData.apiHost}/api/debitbalance/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CH_DEBIT_BALANCE_LIST_EXPORT: {
    endpoint: `${envData.apiHost}/api/debitbalance/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },

  TOP_PRODUCERS_LIST: {
    endpoint: `${envData.apiHost}/api/topproducer/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  TOP_PRODUCERS_LIST_EXPORT: {
    endpoint: `${envData.apiHost}/api/topproducer/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default CodesAndHierarchyApiConfig;
