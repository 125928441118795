import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { dataDictActionType } from '../actions/actionTypes';

const DataDictionary = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  const { data, type } = action;
  switch (type) {
    case dataDictActionType.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    // reset Upset Value
    case dataDictActionType.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: data.upsertRequest,
        upsertRequestError: data.upsertRequestError,
        upsertRequestedOn: data.upsertRequestedOn,
        upsertRequestProcessedOn: data.upsertRequestProcessedOn,
        upsertData: data.upsertData,
      };
    // Configure Page Details
    case dataDictActionType.SET_DD_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: data,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default DataDictionary;
