/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { fieldForceSecurityActionTypes } from '../actions/actionTypes';

import { processError } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { getResetUpsertValue } from '../../../activity/redux/effects/moduleWatcher';

/**
   * Get Access Options List
   * @param {any} action
   */
export function* getFFSecurityList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceSecurityActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceSecurityActionTypes.LOG_FF_SECURITY_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_SECURITY_LIST, action.data);
    yield all([
      put(getAction(fieldForceSecurityActionTypes.GET_FF_SECURITY_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForceSecurityActionTypes.GET_FF_SECURITY_LIST_ERROR, err)),
      put(getAction(fieldForceSecurityActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getAssignedFFSecurity(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_SECURITY_ASSIGNED_LIST, action.data);
    if (responseData) {
      resetUpsertValue = { onloadData: responseData };
      yield all([
        put(getAction(fieldForceSecurityActionTypes.GET_FF_SECURITY_ONLOAD_RESPONSE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceSecurityActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getFFSecurityName(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_SECURITY_GET_NAME,
      action.data);
    yield all([
      put(getAction(fieldForceSecurityActionTypes.GET_FF_SECURITY_GET_NAME_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForceSecurityActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* insertAssignedFFSecurity(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_ASSIGNED_FF_SECURITY_INSERT,
      action.data.formatObject);
    resetUpsertValue = getResetUpsertValue(responseData, null);
    yield all([
      put(getAction(fieldForceSecurityActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(fieldForceSecurityActionTypes.GET_FF_SECURITY_ASSIGNED, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(fieldForceSecurityActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* deleteAssignedFFSecurity(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_ASSIGNED_FF_SECURITY_DELETE,
      action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(fieldForceSecurityActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(fieldForceSecurityActionTypes.GET_FF_SECURITY_ASSIGNED, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(fieldForceSecurityActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(fieldForceSecurityActionTypes.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}
function* fieldforceSecurityWatcher() {
  // FieldForceSecurity
  yield takeEvery(fieldForceSecurityActionTypes.GET_FF_SECURITY_LIST_REQUEST, getFFSecurityList);
  yield takeEvery(fieldForceSecurityActionTypes.GET_FF_SECURITY_ASSIGNED, getAssignedFFSecurity);
  yield takeEvery(fieldForceSecurityActionTypes.GET_FF_SECURITY_GET_NAME_REQUEST, getFFSecurityName);
  yield takeEvery(fieldForceSecurityActionTypes.INSERT_FF_SECURITY_REQUEST, insertAssignedFFSecurity);
  yield takeEvery(fieldForceSecurityActionTypes.DELETE_FF_SECURITY_REQUEST, deleteAssignedFFSecurity);
}
export default fieldforceSecurityWatcher;
