/**
 * @Description Fasat Filet Column
 * @FileName fasatFilterColumn.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 24 June, 2021 21:40:00
 * @IssueID - 1050
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { staticCommonLabelKeys } from '../../../moduleConstants';
import FasatDropdown from '../../fasatDropdown/fasatDropdown';

const FasatFilterColumn = ({
  availableList,
  onColumnValueChange,
  showDuplicateMessage,
  customFilterCreteriaElement,
  onPreSelectedIndexChange,
  uuid,
}) => {
  const [t] = useTranslation();
  const [columnvalue, setColumnValue] = useState(null);
  const dropdownPlaceholder = t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT);
  const fcColumnID = 'fcColumn';

  /**
   * Initialize column Value as per row
   */
  useEffect(() => {
    if (uuid && customFilterCreteriaElement.length > 0) {
      const foundIndex = customFilterCreteriaElement.findIndex((x) => x.uuid === uuid);
      onPreSelectedIndexChange(foundIndex);
      if (foundIndex > -1) {
        setColumnValue(customFilterCreteriaElement[foundIndex].key);
        onColumnValueChange(customFilterCreteriaElement[foundIndex].key, true);
      }
    }
  }, [uuid]);

  /**
   * Validate dublicate selection
   * @param {object} item
   */
  const handleColumnChange = (item) => {
    if (item) {
      const foundIndex = customFilterCreteriaElement.findIndex((x) => x.key === item);
      if (foundIndex < 0) {
        setColumnValue(item);
        onColumnValueChange(item, false);
      } else {
        showDuplicateMessage();
      }
    }
  };
  return (
    <>
      <FasatDropdown
        dropdownItems={availableList}
        selected={0}
        onHandleChange={(val) => handleColumnChange(val)}
        value={columnvalue}
        id={`${fcColumnID}-${uuid}`}
        placeholder={dropdownPlaceholder}
        ariaLabel={t(staticCommonLabelKeys.FILTER_LABEL_COLUMN)}
      />
    </>
  );
};
// #region component
FasatFilterColumn.propTypes = {
  availableList: PropTypes.arrayOf(PropTypes.shape()),
  customFilterCreteriaElement: PropTypes.arrayOf(PropTypes.shape()),
  onColumnValueChange: PropTypes.func,
  showDuplicateMessage: PropTypes.func,
  onPreSelectedIndexChange: PropTypes.func,
  uuid: PropTypes.string,
};

FasatFilterColumn.defaultProps = {
  availableList: [],
  customFilterCreteriaElement: null,
  onColumnValueChange: () => undefined,
  showDuplicateMessage: () => undefined,
  onPreSelectedIndexChange: () => undefined,
  uuid: null,
};

export default FasatFilterColumn;
