import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { deInquiryActionTypes } from '../actions/actionTypes';

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
});

const DeductionInquiry = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    // Configure Page Details
    case deInquiryActionTypes.SET_DEINQ_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case deInquiryActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default DeductionInquiry;
