/**
 * @Description This file will have all the route information for administration module
 * @FileName routeConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:41:45
 * @IssueID - 318
 */
import { lazy } from 'react';
import { aclKeysConstant, routeConstant } from '../moduleConstants';
import FieldForceDeductionLand from '../components/fieldForceDeduction/fieldForceDeductionLand';
import AddEditDeductionStructure from '../components/addEditDeductionRule/addEditDeductionStructure';

const fasatFieldForceList = 'FASAT - FieldForce List';

/**
 * Lazy load BusinessCardList
 */
export const BusinessCardList = lazy(() => import(
  /* webpackChunkName:"AgencyBCR" */ '../containers/businessCardRuleList/businessCardRuleList'
));

export const BusinessCardDetail = lazy(() => import(
  /* webpackChunkName:"AgencyBCRDetail" */ '../containers/businessCardDetail/businessCardDetail'
));

export const AgencyHome = lazy(() => import(
  /* webpackChunkName:"AgencyHomeChunk" */ '../containers/home/home'
));

export const TemplateAdjustments = lazy(() => import(
  '../containers/templateAdjustmentsList/templateAdjustmentsList'
));
/**
 * All the module specific routes to be configured here
 */
export const Client = lazy(() => import(
  '../containers/clientStructureList/clientStructureList'
));
/**
 * All the module specific routes to be configured here
 */
export const ClientDetails = lazy(() => import(
  '../containers/clientStructureDetail/clientStructureDetail'
));
export const ClientAdd = lazy(() => import(
  '../components/addEditClientStructure/addClientStructure'
));
export const FieldForceList = lazy(() => import(
  '../containers/fieldForceList/fieldForceList'
));
export const FieldForceTab = lazy(() => import(
  '../containers/fieldForceTab/fieldForceTab'
));
export const FieldForceLevelStep = lazy(() => import(
  '../containers/fieldForceStep/fieldForceLevelStep'
));
export const FieldForceAddressStep = lazy(() => import(
  '../containers/fieldForceStep/fieldForceAddressStep'
));
export const Exceptions = lazy(() => import(
  '../containers/exceptionsList/exceptionsList'
));

export const ReqInquiry = lazy(() => import(
  '../containers/reqInquiryList/reqInquiryList'
));
export const TaskInquiry = lazy(() => import(
  '../containers/taskInquiryList/taskInquiryList'
));
export const massAgentTransferInq = lazy(() => import(
  '../containers/massAgentTransferInqList/massAgentTransferInqList'
));
export const paymentController = lazy(() => import(
  '../containers/paymentControllerList/paymentControllerList'
));

export const DeductionInquiry = lazy(() => import(
  '../containers/deductionInquiry/deductionInquiry'
));

export const Relationship = lazy(() => import(
  '../containers/clientRelationship/relationshipList'
));
export const FieldForceMovementList = lazy(() => import(
  '../containers/fieldForceMovement/fieldForceMovementList'
));

export const Licence = lazy(() => import(
  '../containers/licenceProcess/licenceProcessList'
));

export const SelectLetter = lazy(() => import(
  '../containers/licenceProcess/selectLetter'
));

export const FieldForceLetter = lazy(() => import(
  '../containers/fieldForceLetter/fieldForceLetterList'
));

export const ManualCommissionRecords = lazy(() => import(
  '../containers/manualCommissionRecordsList/manualCommissionRecordsList'
));

export const ManualCommissionRecordDetail = lazy(() => import(
  '../containers/manualCommissionRecordDetail/manualCommissionRecordDetail'
));

export const ManualAdjustments = lazy(() => import(
  '../components/manualAdjustments/manualAdjustments'
));
export const FinArrangementsList = lazy(() => import(
  '../containers/finArrangements/finArrangementsList'
));
export const FinArrangementsListAddEditClone = lazy(() => import(
  '../containers/finArrangements/finArrangmentContainer'
));

const selectLetterTitle = 'FASAT - SelectLetter';
const routeConfig = [
  {
    path: routeConstant.AGENCY_HOME,
    exact: true,
    component: AgencyHome,
    id: 'AgencyHome',
    title: 'FASAT - Agency Home',
    pageName: 'FASAT - Agency Home', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.BUSINESS_CARD_RULES],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.BUSINESS_CARD_RULES,
    exact: true,
    component: BusinessCardList,
    id: 'AdminBusinessCardList',
    title: 'FASAT - Business card rules',
    pageName: 'FASAT - Business card rules', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.BUSINESS_CARD_RULES],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_MOVEMENT,
    exact: true,
    component: FieldForceMovementList,
    id: 'AdminFieldForceList',
    title: fasatFieldForceList,
    pageName: fasatFieldForceList, // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_FFM_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.BUSINESS_CARD_DETAIL,
    exact: true,
    component: BusinessCardDetail,
    id: 'AdminBusinessCardDetail',
    title: 'FASAT - Business card detail',
    pageName: 'FASAT - Business card detail', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.BCR_DETAIL],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.TEMPLATE_ADJUSTMENTS,
    exact: true,
    component: TemplateAdjustments,
    id: 'AdminTemplateAdjustments',
    title: 'FASAT - Template Adjustments',
    pageName: 'FASAT - Template Adjustments', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_TA_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.MANUAL_COMMISSION_RECORDS,
    exact: true,
    component: ManualCommissionRecords,
    id: 'AdminManualCommissionRecords',
    title: 'FASAT - Manual Commission Records',
    pageName: 'FASAT - Manual Commission Records', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.BUSINESS_CARD_RULES],
    acl: false,
    public: false,
  },
  {
    path: routeConstant.MANUAL_COMMISSION_RECORD_DETAIL,
    exact: true,
    component: ManualCommissionRecordDetail,
    id: 'AdminManualCommissionRecordDetail',
    title: 'FASAT - Manual Commission Record Detail',
    pageName: 'FASAT - Manual Commission Record Detail', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_TA_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.CLIENT,
    exact: true,
    component: Client,
    id: 'DistributerClient',
    title: 'FASAT - Client',
    pageName: 'FASAT - Client', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.CLIENT_CARD_DETAIL,
    exact: true,
    component: ClientDetails,
    id: 'DistributerClient',
    title: 'FASAT - ClientDetails',
    pageName: 'FASAT - ClientDetails', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.CLIENT_ADD,
    exact: true,
    component: ClientAdd,
    id: 'DistributerClient',
    title: 'FASAT - ClientAdd',
    pageName: 'FASAT - ClientAdd', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE,
    exact: true,
    component: FieldForceList,
    id: 'AdminFieldForceList',
    title: fasatFieldForceList,
    pageName: fasatFieldForceList, // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_FF_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_DETAIL_ROUTE,
    exact: true,
    component: FieldForceTab,
    id: 'AdminFieldForce',
    title: 'FASAT - FieldForce Tab',
    pageName: 'FASAT - FieldForce Tab', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_LEVEL_STEP_ROUTE,
    exact: true,
    component: FieldForceLevelStep,
    id: 'AdminFieldForceLevelStep',
    title: 'FASAT - FieldForceLevelStep Tab',
    pageName: 'FASAT - FieldForceLevelStep Tab', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_ADDRESS_STEP_ROUTE,
    exact: true,
    component: FieldForceAddressStep,
    id: 'AdminFieldForceAddressStep',
    title: 'FASAT - FieldForceAddressStep Tab',
    pageName: 'FASAT - FieldForceAddressStep Tab', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.MANUAL_ADJUSTMENT_ROUTE,
    exact: true,
    component: ManualAdjustments,
    id: 'ManualAdjustmentRoutID',
    title: 'FASAT - Manual Adjustments',
    pageName: 'FASAT - Manual Adjustments',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_MADJ_EDIT], // TODO - needs to be update once RBAC is implemented
    acl: true,
    public: false,
  },
  {
    path: routeConstant.EXCEPTIONS_ROUTE,
    exact: true,
    component: Exceptions,
    id: 'Exceptions',
    title: 'FASAT - Exceptions',
    pageName: 'FASAT - Exceptions', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_EXI_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.REQ_INQUIRY_ROUTE,
    exact: true,
    component: ReqInquiry,
    id: 'ReportReqInquiry',
    title: 'FASAT - RequirementInquiry',
    pageName: 'FASAT - Requirement Inquiry', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_RI_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.TASK_INQUIRY_ROUTE,
    exact: true,
    component: TaskInquiry,
    id: 'ReportTaskInquiry',
    title: 'FASAT - TaskInquiry',
    pageName: 'FASAT - Task Inquiry', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_TI_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.MASS_AGENT_TRANSFER_INQ_ROUTE,
    exact: true,
    component: massAgentTransferInq,
    id: 'ReportMassAgentTransferInq',
    title: 'FASAT - MassAgentTrasnferInq',
    pageName: 'FASAT - Mass Agent Transfer Inquiry', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_MATI_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.PAYMENT_CONTROLLER_ROUTE,
    exact: true,
    component: paymentController,
    id: 'ReportPaymentController',
    title: 'FASAT - PaymentController',
    pageName: 'FASAT - Payment Controller Movement',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_PCM_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.DEDUCTION_INQUIRY_ROUTE,
    exact: true,
    component: DeductionInquiry,
    id: 'ReportDeductionInquiry',
    title: 'FASAT - Deduction Inquiry',
    pageName: 'FASAT - DeductionInquiry',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_DI_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.RELATIONSHIPS,
    exact: true,
    component: Relationship,
    id: 'Relationship',
    title: 'FASAT - Relationship',
    pageName: 'FASAT - Relationship', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.LICENCE_ROUTE,
    exact: true,
    component: Licence,
    id: 'License',
    title: 'FASAT - License',
    pageName: 'FASAT - license', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_LIP_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.SELECT_LETTER_ROUTE_FFL,
    exact: true,
    component: SelectLetter,
    id: 'SelectLetter',
    title: selectLetterTitle,
    pageName: selectLetterTitle, // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_FFL_SELECT_LETTER],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.SELECT_LETTER_ROUTE_LIP,
    exact: true,
    component: SelectLetter,
    id: 'SelectLetter',
    title: selectLetterTitle,
    pageName: selectLetterTitle, // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_LIP_SELECT_LETTER],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_LETTER_ROUTE,
    exact: true,
    component: FieldForceLetter,
    id: 'FieldForceLetter',
    title: 'FASAT - FieldForceLetter',
    pageName: 'FASAT - FieldForceLetter', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_FFL_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_DEDUCTION,
    exact: true,
    component: FieldForceDeductionLand,
    id: 'FieldForceDeductionLand',
    title: 'FieldForceDeductionLand',
    pageName: 'FieldForceDeductionLand', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_DEDUCTION_ADD,
    exact: true,
    component: AddEditDeductionStructure,
    id: 'FieldForceDeductionLand',
    title: 'FieldForceDeductionLand',
    pageName: 'FieldForceDeductionLand', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_FIN_ARRANGEMENT,
    exact: true,
    component: FinArrangementsList,
    id: 'FinArrangementsList',
    title: 'FinArrangementsList',
    pageName: 'FinArrangementsList', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_FIN_ARRANGEMENT_ADD,
    exact: true,
    component: FinArrangementsListAddEditClone,
    id: 'FinArrangementsLisAdd',
    title: 'FinArrangementsListAdd',
    pageName: 'FinArrangementsListAdd', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_FIN_ARRANGEMENT_EDIT,
    exact: true,
    component: FinArrangementsListAddEditClone,
    id: 'FinArrangementsLisEdit',
    title: 'FinArrangementsListEdit',
    pageName: 'FinArrangementsListEdit', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_FIN_ARRANGEMENT_CLONE,
    exact: true,
    component: FinArrangementsListAddEditClone,
    id: 'FinArrangementsListClone',
    title: 'FinArrangementsListClone',
    pageName: 'FinArrangementsListClone', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.AGENCY],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
];

export default routeConfig;
