/* eslint-disable no-debugger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/**
 * @Description Interceptors for the project
 * @FileName httpInterceptors.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 28 January, 2021 05:46:45
 * @IssueID - 307
 */
import _ from 'lodash-es';
import {
  appHistory,
  // getValueFromLocalStorage,
  /// STORAGE_KEY,
  // getAccessToken,
  // getRefreshToken,
  // setToken,
  // appHistory,
  Logger,
} from '../util';

/**
 *
 */
export const REQUEST_HEADER = {
  APP_NAME: 'app-name',
  AUTHENTICATION: 'Authentication',
  AUTHORIZATION: 'Authorization',
  API_OPTION: 'api-option',
};

/**
 * This interceptor will check whether the api require any
 * authentication check and also check for authentication detail
 * @param {*} config  - request configuration
 */

/**
 * This checks whether the request requires authorization;
 * and if authorization is required add the authorization token
 * @param {*} config  - request configuration
 */

/**
 * This is the base interceptor for both authorized and public API invocation.
 * We can use this for generic request processing and logging activity
 * @param {*} config  - request config
 */
const baseRequestInterceptor = (config) => {
  Logger.verbose(`Config options received : ${config}`);
  return config;
};

/**
 * This must be the last interceptor in the chain.
 * We may pass additional information in header; so that
 * other interceptor works properly; but not required for actual
 * API invocation. e.g. apiOption detail
 * We should cleanup such headers here.
 * @param {*} config - request config
 */
const cleanupRequestHeader = (config) => {
  // get the api key from resp.config
  // get the API KEY
  // If wait cursor is required dispatch action
  if (!_.isEmpty(config.apiConfig)) {
    delete config.apiConfig;
  }

  return config;
};

/**
 * This interceptor will look into the response and only data section would be returned;
 * so that developers do not need to bother about generic data processing.
 * Depending on the response code we can take decision in future
 * @param {*} resp - response from the REST endpoint
 */
const baseResponseInterceptor = (resp) => {
  // get the api key from resp.config
  // get the API KEY
  // If wait cursor is required dispatch action
  // need to check how we can get the request headers
  // const apiConfig = getApiDetail(resp.config.apiConfig);
  // read the Max-Idle-Expiration-Time header
  const maxIdleExpirationTime = resp.headers?.['max-idle-expiration-time'];
  if (maxIdleExpirationTime) {
    localStorage.setItem('maxIdleExpirationTime', maxIdleExpirationTime);
  }
  Logger.verbose('Basic Response Interceptor');
  return resp.data;
};

/**
 * This is the base reject interceptor which has been used in case
 * any error occurred in the pipeline
 * @param {*} error - error detail
 */
const rejectRequest = (error) => Promise.reject(error);

/**
 * This interceptor would try to refresh the access token in case the token get invalidated.
 * @param {*} error - error detail
 */
const errorInterceptor = (error) => {
  // const originalRequest = error.config;
  // log error
  // handle error
  // if (error.message === USER_NOT_AUTHENTICATED) {
  //   appHistory.push('/login');
  //   return Promise.reject(error);
  // }

  /**
   * Check the status and type of user. Accordingly update the access token
   * using refresh token
   */
  // if (error.response && error.response.status === 401 && !originalRequest._retry) {
  //   originalRequest._retry = true;
  // eslint-disable-next-line no-console
  const httpError = 401;
  if (error.response && error.response.status === httpError) {
    appHistory.push('/');
    return Promise.reject(error);
  }

  // return Error object with Promise
  return Promise.reject(error);
};

export {
  // authenticationInterceptor,
  // authorizationInterceptor,
  baseRequestInterceptor,
  cleanupRequestHeader,
  baseResponseInterceptor,
  errorInterceptor,
  rejectRequest,
};
