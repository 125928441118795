import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { paymentControllerActionTypes } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from '../../../report/redux/reducers/utilities/utility';
import { commonTypes } from '../../../../common/redux/actions/commonActions';

export const REDUCER_NAME = 'PaymentControllerFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const paymentControllerFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case paymentControllerActionTypes.UPDATE_PCM_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case paymentControllerActionTypes.REMOVE_PCM_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case paymentControllerActionTypes.UPDATE_PCM_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const paymentControllerFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case paymentControllerActionTypes.LOG_PCM_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case paymentControllerActionTypes.GET_PCM_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case paymentControllerActionTypes.GET_PCM_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case paymentControllerActionTypes.RESET_PCM_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case paymentControllerActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case paymentControllerActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const PaymentControllerFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = paymentControllerFilterListConfig(state, action);
  const stateReqRes = paymentControllerFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default PaymentControllerFilterList;
