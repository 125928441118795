/**
 * @Description Action types for
 * @FileName actionTypes.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 28 January, 2021 23:55:21
 * @IssueID - Issue IDs
 */
import { interfaceSetupActionTypeValue, ffStructureActionTypesValue, manageUsersActionTypeValue, manageLevelActionTypeValue, fieldForceSecurityActionTypesValue } from './adminActionTypes';

export const interfaceSetupActionType = interfaceSetupActionTypeValue;
export const ffStructureActionTypes = ffStructureActionTypesValue;
export const manageUsersActionType = manageUsersActionTypeValue;
export const manageLevelActionType = manageLevelActionTypeValue;
export const fieldForceSecurityActionTypes = fieldForceSecurityActionTypesValue;
// Gen Edit Type action types
export const genEditActionTypes = {
  LOG_GET_LIST_REQUEST: 'LOG_GET_LIST_REQUEST',
  GET_GET_LIST_REQUEST: 'GET_GET_LIST_REQUEST',
  GET_GET_LIST_RESPONSE: 'GET_GET_LIST_RESPONSE',
  GET_GET_LIST_ERROR: 'GET_GET_LIST_ERROR',
  GET_GET_LIST_LOG_REQ: 'GET_GET_LIST_LOG_REQ',
  UPDATE_GET_COLUMN_CONFIG: 'UPDATE_GET_COLUMN_CONFIG',
  // Get Edit Item by Id
  LOG_GET_BY_ID_REQUEST: 'LOG_GET_BY_ID_REQUEST',
  GET_GET_BY_ID_REQUEST: 'GET_GET_BY_ID_REQUEST',
  GET_GET_BY_ID_RESPONSE: 'GET_GET_BY_ID_RESPONSE',
  GET_GET_BY_ID_ERROR: 'GET_GET_BY_ID_ERROR',
  GET_GET_BY_ID_LOG_REQ: 'GET_GET_BY_ID_LOG_REQ',
  // Get GEN EDIT TYPE detail
  GET_GET_REQUEST: 'GET_GET_REQUEST',
  GET_GET_RESPONSE: 'GET_GET_RESPONSE',
  GET_GET_ERROR: 'GET_GET_ERROR',
  // Add/Update GEN EDIT TYPE detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_GET_REQUEST: 'UPSERT_GET_REQUEST',
  INSERT_GET_RESPONSE: 'UPSERT_GET_RESPONSE',
  INSERT_GET_ERROR: 'UPSERT_GET_ERROR',
  UPDATE_GET_REQUEST: 'UPDATE_GET_REQUEST',
  UPDATE_GET_RESPONSE: 'UPDATE_GET_RESPONSE',
  UPDATE_GET_ERROR: 'UPDATE_GET_ERROR',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Delete GEN EDIT TYPE
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  DELETE_GET_REQUEST: 'DELETE_GET_REQUEST',
  DELETE_GET_RESPONSE: 'DELETE_GET_RESPONSE',
  DELETE_GET_ERROR: 'DELETE_GET_ERROR',
  // Lookup GEN EDIT TYPE
  GET_DEPENDENCY_EXIST_REQUEST: 'GET_DEPENDENCY_EXIST_REQUEST',
  GET_DEPENDENCY_EXIST_RESPONSE: 'GET_DEPENDENCY_EXIST_RESPONSE',
  GET_DEPENDENCY_EXIST_ERROR: 'GET_DEPENDENCY_EXIST_ERROR',
  // Update Filter Setting
  UPDATE_GET_FILTER_CONFIG: 'UPDATE_GET_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_GEN_EDIT_TABLE_CONFIGURATION',
  SET_GET_DETAILS_RESPONSE: 'SET_GET_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_GET_USER_DEFAULT_FILTER: 'REMOVE_GET_USER_DEFAULT_FILTER',
  // Reset store
  RESET_GET_STORE: 'RESET_GET_STORE',
  // Export List for BCR
  GET_GET_EXPORT_REQUEST: 'GET_GET_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// Gen Edit Value types
export const genEditValActionTypes = {
  LOG_GEV_LIST_REQUEST: 'LOG_GEV_LIST_REQUEST',
  GET_GEV_LIST_REQUEST: 'GET_GEV_LIST_REQUEST',
  GET_GEV_LIST_RESPONSE: 'GET_GEV_LIST_RESPONSE',
  GET_GEV_LIST_ERROR: 'GET_GEV_LIST_ERROR',
  GET_GEV_LIST_LOG_REQ: 'GET_GEV_LIST_LOG_REQ',
  UPDATE_GEV_COLUMN_CONFIG: 'UPDATE_GEV_COLUMN_CONFIG',
  // Get Edit item By ID
  LOG_GEV_BY_ID_REQUEST: 'LOG_GEV_BY_ID_REQUEST',
  GET_GEV_BY_ID_REQUEST: 'GET_GEV_BY_ID_REQUEST',
  GET_GEV_BY_ID_RESPONSE: 'GET_GEV_BY_ID_RESPONSE',
  GET_GEV_BY_ID_ERROR: 'GET_GEV_BY_ID_ERROR',
  GET_GEV_BY_ID_LOG_REQ: 'GET_GEV_BY_ID_LOG_REQ',
  // Get GEN EDIT VALUES detail
  GET_GEV_REQUEST: 'GET_GEV_REQUEST',
  GET_GEV_RESPONSE: 'GET_GEV_RESPONSE',
  GET_GEV_ERROR: 'GET_GEV_ERROR',
  // Add/Update GEN EDIT VALUES detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_GEV_REQUEST: 'INSERT_GEV_REQUEST',
  INSERT_GEV_RESPONSE: 'INSERT_GEV_RESPONSE',
  INSERT_GEV_ERROR: 'INSERT_GEV_ERROR',
  UPDATE_GEV_REQUEST: 'UPDATE_GEV_REQUEST',
  UPDATE_GEV_RESPONSE: 'UPDATE_GEV_RESPONSE',
  UPDATE_GEV_ERROR: 'UPDATE_GEV_ERROR',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Delete GEN EDIT VALUES
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  DELETE_GEV_REQUEST: 'DELETE_GEV_REQUEST',
  DELETE_GEV_RESPONSE: 'DELETE_GEV_RESPONSE',
  DELETE_GEV_ERROR: 'DELETE_GEV_ERROR',
  // Update Filter Setting
  UPDATE_GEV_FILTER_CONFIG: 'UPDATE_GEV_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'GEN_EDIT_TYPE_TABLE_CONFIGURATION',
  SET_GEV_DETAILS_RESPONSE: 'SET_GEV_DETAILS_RESPONSE',
  REMOVE_GEV_USER_DEFAULT_FILTER: 'REMOVE_GEV_USER_DEFAULT_FILTER',
  // Reset Server value
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Export List for GEV
  GET_GEV_EXPORT_REQUEST: 'GET_GEV_EXPORT_REQUEST',
};
// Gen Edit Value Parent types
export const genEditValParentActionTypes = {
  GET_GEV_PARENT_LIST_REQUEST: 'GET_GEV_PARENT_LIST_REQUEST',
  LOG_GEV_PARENT_REQUEST: 'LOG_GEV_PARENT_REQUEST',
  GET_GEV_PARENT_RESPONSE: 'GET_GEV_PARENT_RESPONSE',
  GET_GEV_RESET_UPSERT_VALUE: 'GET_GEV_RESET_UPSERT_VALUE',
  GET_GEV_PARENT_ERROR: 'GET_GEV_PARENT_ERROR',
};
export const planCodeActionTypes = {
  UPDATE_PLC_COLUMN_CONFIG: 'UPDATE_PLC_COLUMN_CONFIG',
  LOG_PLC_LIST_REQUEST: 'LOG_PLC_LIST_REQUEST',
  GET_PLC_LIST_REQUEST: 'GET_PLC_LIST_REQUEST',
  GET_PLC_LIST_RESPONSE: 'GET_PLC_LIST_RESPONSE',
  GET_PLC_LIST_ERROR: 'GET_PLC_LIST_ERROR',
  GET_PLC_LIST_LOG_REQ: 'GET_PLC_LIST_LOG_REQ',
  GET_DEFAULT_FILTER_SETTING: 'GET_DEFAULT_FILTER_SETTING',
  // Get Edit Item by ID
  LOG_PLC_BY_ID_REQUEST: 'LOG_PLC_BY_ID_REQUEST',
  GET_PLC_BY_ID_REQUEST: 'GET_PLC_BY_ID_REQUEST',
  GET_PLC_BY_ID_RESPONSE: 'GET_PLC_BY_ID_RESPONSE',
  GET_PLC_BY_ID_ERROR: 'GET_PLC_BY_ID_ERROR',
  GET_PLC_BY_ID_LOG_REQ: 'GET_PLC_BY_ID_LOG_REQ',
  // Get PLAN CODE detail
  GET_PLC_REQUEST: 'GET_PLC_REQUEST',
  GET_PLC_RESPONSE: 'GET_PLC_RESPONSE',
  GET_PLC_ERROR: 'GET_PLC_ERROR',
  // Add/Update PLAN CODE detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_PLC_REQUEST: 'INSERT_PLC_REQUEST',
  INSERT_PLC_RESPONSE: 'UPSERT_PLC_RESPONSE',
  INSERT_PLC_ERROR: 'UPSERT_PLC_ERROR',
  UPDATE_PLC_REQUEST: 'UPDATE_PLC_REQUEST',
  UPDATE_PLC_RESPONSE: 'UPDATE_PLC_RESPONSE',
  UPDATE_PLC_ERROR: 'UPDATE_PLC_ERROR',
  // Delete PLAN CODE
  DELETE_PLC_REQUEST: 'DELETE_PLC_REQUEST',
  DELETE_PLC_RESPONSE: 'DELETE_PLC_RESPONSE',
  DELETE_PLC_ERROR: 'DELETE_PLC_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Update Filter Settings
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_PLC_TABLE_CONFIGURATION',
  UPDATE_PLC_FILTER_CONFIG: 'UPDATE_PLC_FILTER_CONFIG',
  GET_PLC_EXPORT_REQUEST: 'GET_PLC_EXPORT_REQUEST',
  REMOVE_PLC_USER_DEFAULT_FILTER: 'REMOVE_PLC_USER_DEFAULT_FILTER',
  SET_PLC_DETAILS_RESPONSE: 'SET_PLC_DETAILS_RESPONSE',
  // Reset Store
  RESET_PLC_STORE: 'RESET_PLC_STORE',
  // Export SystemParameters
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// System Parameters Action Types
export const systemParametersActionType = {
  LOG_SYSPARAM_LIST_REQUEST: 'LOG_SYSPARAM_LIST_REQUEST',
  GET_SYSPARAM_LIST_REQUEST: 'GET_SYSPARAM_LIST_REQUEST',
  GET_SYSPARAM_LIST_RESPONSE: 'GET_SYSPARAM_LIST_RESPONSE',
  GET_SYSPARAM_LIST_ERROR: 'GET_SYSPARAM_LIST_ERROR',
  GET_SYSPARAM_LIST_LOG_REQ: 'GET_SYSPARAM_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_SYSPARAM_FILTER_CONFIG: 'UPDATE_SYSPARAM_FILTER_CONFIG',
  GET_SYSPARAM_EXPORT_REQUEST: 'GET_SYSPARAM_EXPORT_REQUEST',
  REMOVE_SYSPARAM_USER_DEFAULT_FILTER: 'REMOVE_SYSPARAM_USER_DEFAULT_FILTER',
  // Get Edit Item by ID
  LOG_SYSPARAM_BY_ID_REQUEST: 'LOG_SYSPARAM_BY_ID_REQUEST',
  GET_SYSPARAM_BY_ID_REQUEST: 'GET_SYSPARAM_BY_ID_REQUEST',
  GET_SYSPARAM_BY_ID_RESPONSE: 'GET_SYSPARAM_BY_ID_RESPONSE',
  GET_SYSPARAM_BY_ID_ERROR: 'GET_SYSPARAM_BY_ID_ERROR',
  GET_SYSPARAM_BY_ID_LOG_REQ: 'GET_SYSPARAM_BY_ID_LOG_REQ',
  // Get system parameters detail
  GET_SYSPARAM_REQUEST: 'GET_SYSPARAM_REQUEST',
  GET_SYSPARAM_RESPONSE: 'GET_SYSPARAM_RESPONSE',
  GET_SYSPARAM_ERROR: 'GET_SYSPARAM_ERROR',
  // Add/Update system parameter
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_SYSPARAM_REQUEST: 'UPSERT_SYSPARAM_REQUEST',
  INSERT_SYSPARAM_REQUEST: 'INSERT_SYSPARAM_REQUEST',
  INSERT_SYSPARAM_ERROR: 'INSERT_SYSPARAM_ERROR',
  UPDATE_SYSPARAM_REQUEST: 'UPDATE_SYSPARAM_REQUEST',
  UPDATE_SYSPARAM_RESPONSE: 'UPDATE_SYSPARAM_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_SYSPARAM_RESPONSE: 'UPSERT_SYSPARAM_RESPONSE',
  // Delete system parameter
  DELETE_SYSPARAM_REQUEST: 'DELETE_SYSPARAM_REQUEST',
  DELETE_SYSPARAM_RESPONSE: 'DELETE_SYSPARAM_RESPONSE',
  DELETE_SYSPARAM_ERROR: 'DELETE_SYSPARAM_ERROR',
  // Detail system parameter
  SET_SYSPARAM_DETAILS_RESPONSE: 'SET_SYSPARAM_DETAILS_RESPONSE',
  // Reset store
  RESET_SYSPARAM_STORE: 'RESET_SYSPARAM_STORE',
  // Export SystemParameters
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// Period Definition Action Types
export const periodDefinitionActionType = {
  LOG_PD_LIST_REQUEST: 'LOG_PD_LIST_REQUEST',
  GET_PD_LIST_REQUEST: 'GET_PD_LIST_REQUEST',
  GET_PD_LIST_RESPONSE: 'GET_PD_LIST_RESPONSE',
  GET_PD_LIST_ERROR: 'GET_PD_LIST_ERROR',
  GET_PD_LIST_LOG_REQ: 'GET_PD_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_PD_FILTER_CONFIG: 'UPDATE_PD_FILTER_CONFIG',
  GET_PD_EXPORT_REQUEST: 'GET_PD_EXPORT_REQUEST',
  REMOVE_PD_USER_DEFAULT_FILTER: 'REMOVE_PD_USER_DEFAULT_FILTER',
  // Get Edit Item by ID
  LOG_PD_BY_ID_REQUEST: 'LOG_PD_BY_ID_REQUEST',
  GET_PD_BY_ID_REQUEST: 'GET_PD_BY_ID_REQUEST',
  GET_PD_BY_ID_RESPONSE: 'GET_PD_BY_ID_RESPONSE',
  GET_PD_BY_ID_ERROR: 'GET_PD_BY_ID_ERROR',
  GET_PD_BY_ID_LOG_REQ: 'GET_PD_BY_ID_LOG_REQ',
  // Get period definition detail
  GET_PD_REQUEST: 'GET_PD_REQUEST',
  GET_PD_RESPONSE: 'GET_PD_RESPONSE',
  GET_PD_ERROR: 'GET_PD_ERROR',
  // Add/Update period definition
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_PD_REQUEST: 'UPSERT_PD_REQUEST',
  INSERT_PD_REQUEST: 'INSERT_PD_REQUEST',
  INSERT_PD_ERROR: 'INSERT_PD_ERROR',
  UPDATE_PD_REQUEST: 'UPDATE_PD_REQUEST',
  UPDATE_PD_RESPONSE: 'UPDATE_PD_RESPONSE',
  SPLIT_PD_REQUEST: 'SPLIT_PD_REQUEST',
  SPLIT_PD_RESPONSE: 'SPLIT_PD_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_PD_RESPONSE: 'UPSERT_PD_RESPONSE',
  // Delete period definition
  DELETE_PD_REQUEST: 'DELETE_PD_REQUEST',
  DELETE_PD_RESPONSE: 'DELETE_PD_RESPONSE',
  DELETE_PD_ERROR: 'DELETE_PD_ERROR',
  // Detail period definition
  SET_PD_DETAILS_RESPONSE: 'SET_PD_DETAILS_RESPONSE',
  // Reset store
  RESET_PD_STORE: 'RESET_PD_STORE',
  // Export period definition
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
export const genEditRelationshipActionType = {
  UPDATE_GER_COLUMN_CONFIG: 'UPDATE_GER_COLUMN_CONFIG',
  LOG_GER_LIST_REQUEST: 'LOG_GER_LIST_REQUEST',
  GET_GER_LIST_REQUEST: 'GET_GER_LIST_REQUEST',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_GER_TABLE_CONFIGURATION',
  GET_GER_LIST_RESPONSE: 'GET_GER_LIST_RESPONSE',
  GET_GER_LIST_ERROR: 'GET_GER_LIST_ERROR',
  GET_GER_LIST_LOG_REQ: 'GET_GER_LIST_LOG_REQ',
  GET_DEFAULT_FILTER_SETTING: 'GET_DEFAULT_FILTER_SETTING',
  // Get Edit item by ID
  LOG_GER_BY_ID_REQUEST: 'LOG_GER_BY_ID_REQUEST',
  GET_GER_BY_ID_REQUEST: 'GET_GER_BY_ID_REQUEST',
  GET_GER_BY_ID_RESPONSE: 'GET_GER_BY_ID_RESPONSE',
  GET_GER_BY_ID_ERROR: 'GET_GER_BY_ID_ERROR',
  GET_GER_BY_ID_LOG_REQ: 'GET_GER_BY_ID_LOG_REQ',
  // Get GEN EDIT RELATIONSHIP detail
  GET_GER_REQUEST: 'GET_GER_REQUEST',
  GET_GER_RESPONSE: 'GET_GER_RESPONSE',
  GET_GER_ERROR: 'GET_GER_ERROR',
  // Add/Update GEN EDIT RELATIONSHIP detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_GER_REQUEST: 'INSERT_GER_REQUEST',
  INSERT_GER_RESPONSE: 'UPSERT_GER_RESPONSE',
  INSERT_GER_ERROR: 'UPSERT_GER_ERROR',
  UPDATE_GER_REQUEST: 'UPDATE_GER_REQUEST',
  UPDATE_GER_RESPONSE: 'UPDATE_GER_RESPONSE',
  UPDATE_GER_ERROR: 'UPDATE_GER_ERROR',
  // Delete GEN EDIT RELATIONSHIP
  DELETE_GER_REQUEST: 'DELETE_GER_REQUEST',
  DELETE_GER_RESPONSE: 'DELETE_GER_RESPONSE',
  DELETE_GER_ERROR: 'DELETE_GER_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPDATE_GER_FILTER_CONFIG: 'UPDATE_GER_FILTER_CONFIG',
  REMOVE_GER_USER_DEFAULT_FILTER: 'REMOVE_GER_USER_DEFAULT_FILTER',
  RESET_GER_STORE: 'RESET_GER_STORE',
  SET_GER_DETAILS_RESPONSE: 'SET_GER_DETAILS_RESPONSE',
  // Export GenEditRelationshipType
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Export List for BCR
  GET_GERT_EXPORT_REQUEST: 'GET_GERT_EXPORT_REQUEST',
};
export const genEditRelationshipValActionType = {
  UPDATE_GERV_COLUMN_CONFIG: 'UPDATE_GERV_COLUMN_CONFIG',
  LOG_GERV_LIST_REQUEST: 'LOG_GERV_LIST_REQUEST',
  GET_GERV_LIST_REQUEST: 'GET_GERV_LIST_REQUEST',
  GET_GERV_LIST_RESPONSE: 'GET_GERV_LIST_RESPONSE',
  GET_GERV_LIST_ERROR: 'GET_GERV_LIST_ERROR',
  GET_GERV_LIST_LOG_REQ: 'GET_GERV_LIST_LOG_REQ',
  GET_DEFAULT_FILTER_SETTING: 'GET_DEFAULT_FILTER_SETTING',
  // Get Edit Item by ID
  LOG_GERV_BY_ID_REQUEST: 'LOG_GERV_BY_ID_REQUEST',
  GET_GERV_BY_ID_REQUEST: 'GET_GERV_BY_ID_REQUEST',
  GET_GERV_BY_ID_RESPONSE: 'GET_GERV_BY_ID_RESPONSE',
  GET_GERV_BY_ID_ERROR: 'GET_GERV_BY_ID_ERROR',
  GET_GERV_BY_ID_LOG_REQ: 'GET_GERV_BY_ID_LOG_REQ',
  // Get GEN EDIT RELATIONSHIP detail
  GET_GERV_REQUEST: 'GET_GERV_REQUEST',
  GET_GERV_RESPONSE: 'GET_GERV_RESPONSE',
  GET_GERV_ERROR: 'GET_GERV_ERROR',
  GET_GERV_DESC_REQUEST: 'GET_GERV_DESC_REQUEST',
  GET_GERV_DESC_RESPONSE: 'GET_GERV_DESC_RESPONSE',
  // Add/Update GEN EDIT RELATIONSHIP detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_GERV_REQUEST: 'INSERT_GERV_REQUEST',
  INSERT_GERV_RESPONSE: 'UPSERT_GERV_RESPONSE',
  INSERT_GERV_ERROR: 'UPSERT_GERV_ERROR',
  UPDATE_GERV_REQUEST: 'UPDATE_GERV_REQUEST',
  UPDATE_GERV_RESPONSE: 'UPDATE_GERV_RESPONSE',
  UPDATE_GERV_ERROR: 'UPDATE_GERV_ERROR',
  // Delete GEN EDIT RELATIONSHIP
  DELETE_GERV_REQUEST: 'DELETE_GERV_REQUEST',
  DELETE_GERV_RESPONSE: 'DELETE_GERV_RESPONSE',
  DELETE_GERV_ERROR: 'DELETE_GERV_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_GERV_TABLE_CONFIGURATION',
  UPDATE_GERV_FILTER_CONFIG: 'UPDATE_GERV_FILTER_CONFIG',
  REMOVE_GERV_USER_DEFAULT_FILTER: 'REMOVE_GERV_USER_DEFAULT_FILTER',
  GET_GERV_EXPORT_REQUEST: 'GET_GERV_EXPORT_REQUEST',
  SET_GERV_DETAILS_RESPONSE: 'SET_GERV_DETAILS_RESPONSE',
  // Export GenEditRelationshipValue
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Lookup actions
  GET_GERV_LOOKUP_REQUEST: 'GET_GERV_LOOKUP_REQUEST',
  RESET_GERV_LOOKUPDATA: 'RESET_GERV_LOOKUPDATA',
};
// DataDictionary Action Types
export const dataDictActionType = {
  LOG_DD_LIST_REQUEST: 'LOG_DD_LIST_REQUEST',
  GET_DD_LIST_REQUEST: 'GET_DD_LIST_REQUEST',
  GET_DD_LIST_RESPONSE: 'GET_DD_LIST_RESPONSE',
  GET_DD_LIST_ERROR: 'GET_DD_LIST_ERROR',
  GET_DD_LIST_LOG_REQ: 'GET_DD_LIST_LOG_REQ',
  UPDATE_DD_COLUMN_CONFIG: 'UPDATE_DD_COLUMN_CONFIG',
  // Get Edit Item by ID
  LOG_DD_BY_ID_REQUEST: 'LOG_DD_BY_ID_REQUEST',
  GET_DD_BY_ID_REQUEST: 'GET_DD_BY_ID_REQUEST',
  GET_DD_BY_ID_RESPONSE: 'GET_DD_BY_ID_RESPONSE',
  GET_DD_BY_ID_ERROR: 'GET_DD_BY_ID_ERROR',
  GET_DD_BY_ID_LOG_REQ: 'GET_DD_BY_ID_LOG_REQ',
  // Get DD EDIT TYPE detail
  GET_DD_REQUEST: 'GET_DD_REQUEST',
  GET_DD_RESPONSE: 'GET_DD_RESPONSE',
  GET_DD_ERROR: 'GET_DD_ERROR',
  // Add/Update DD EDIT TYPE detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_DD_REQUEST: 'INSERT_DD_REQUEST',
  INSERT_DD_RESPONSE: 'INSERT_DD_RESPONSE',
  INSERT_DD_ERROR: 'INSERT_DD_ERROR',
  UPDATE_DD_REQUEST: 'UPDATE_DD_REQUEST',
  UPDATE_DD_RESPONSE: 'UPDATE_DD_RESPONSE',
  UPDATE_DD_ERROR: 'UPDATE_DD_ERROR',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Filter settings
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  UPDATE_DD_FILTER_CONFIG: 'UPDATE_DD_FILTER_CONFIG',
  REMOVE_DD_USER_DEFAULT_FILTER: 'REMOVE_DD_USER_DEFAULT_FILTER',
  // Export functionality
  GET_DD_EXPORT_REQUEST: 'GET_DD_EXPORT_REQUEST',
  // Reset store
  RESET_DD_STORE: 'RESET_DD_STORE',
  // Set response
  SET_DD_DETAILS_RESPONSE: 'SET_DD_DETAILS_RESPONSE',
  // Export Datadictionary
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE_DD',
};
export const paymentThresholdActionType = {
  UPDATE_PT_COLUMN_CONFIG: 'UPDATE_PT_COLUMN_CONFIG',
  LOG_PT_LIST_REQUEST: 'LOG_PT_LIST_REQUEST',
  GET_PT_LIST_REQUEST: 'GET_PT_LIST_REQUEST',
  GET_PT_LIST_RESPONSE: 'GET_PT_LIST_RESPONSE',
  GET_PT_LIST_ERROR: 'GET_PT_LIST_ERROR',
  GET_PT_LIST_LOG_REQ: 'GET_PT_LIST_LOG_REQ',
  GET_DEFAULT_FILTER_SETTING: 'GET_DEFAULT_FILTER_SETTING',
  // Get Edit Item by ID
  LOG_PT_BY_ID_REQUEST: 'LOG_PT_BY_ID_REQUEST',
  GET_PT_BY_ID_REQUEST: 'GET_PT_BY_ID_REQUEST',
  GET_PT_BY_ID_RESPONSE: 'GET_PT_BY_ID_RESPONSE',
  GET_PT_BY_ID_ERROR: 'GET_PT_BY_ID_ERROR',
  GET_PT_BY_ID_LOG_REQ: 'GET_PT_BY_ID_LOG_REQ',
  // Get PAYMENT THRESHOLD detail
  GET_PT_REQUEST: 'GET_PT_REQUEST',
  GET_PT_RESPONSE: 'GET_PT_RESPONSE',
  GET_PT_ERROR: 'GET_PT_ERROR',
  // Add/Update PAYMENT THRESHOLD detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_PT_REQUEST: 'INSERT_PT_REQUEST',
  INSERT_PT_RESPONSE: 'UPSERT_PT_RESPONSE',
  INSERT_PT_ERROR: 'UPSERT_PT_ERROR',
  UPDATE_PT_REQUEST: 'UPDATE_PT_REQUEST',
  UPDATE_PT_RESPONSE: 'UPDATE_PT_RESPONSE',
  UPDATE_PT_ERROR: 'UPDATE_PT_ERROR',
  // Delete PAYMENT THRESHOLD
  DELETE_PT_REQUEST: 'DELETE_PT_REQUEST',
  DELETE_PT_RESPONSE: 'DELETE_PT_RESPONSE',
  DELETE_PT_ERROR: 'DELETE_PT_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Filter Setting
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_PT_TABLE_CONFIGURATION',
  UPDATE_PT_FILTER_CONFIG: 'UPDATE_PT_FILTER_CONFIG',
  GET_PT_EXPORT_REQUEST: 'GET_PT_EXPORT_REQUEST',
  REMOVE_PT_USER_DEFAULT_FILTER: 'REMOVE_PT_USER_DEFAULT_FILTER',
  // Detail Payment Thresold
  SET_PT_DETAILS_RESPONSE: 'SET_PT_DETAILS_RESPONSE',
  // Reset store
  RESET_PT_STORE: 'RESET_PT_STORE',
  // Export Datadictionary
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// Interface mapping action types
export const interfaceMappingActionType = {
  UPDATE_IM_COLUMN_CONFIG: 'UPDATE_IM_COLUMN_CONFIG',
  LOG_IM_LIST_REQUEST: 'LOG_IM_LIST_REQUEST',
  GET_IM_LIST_REQUEST: 'GET_IM_LIST_REQUEST',
  GET_IM_LIST_RESPONSE: 'GET_IM_LIST_RESPONSE',
  GET_IM_LIST_ERROR: 'GET_IM_LIST_ERROR',
  GET_IM_LIST_LOG_REQ: 'GET_IM_LIST_LOG_REQ',
  GET_DEFAULT_FILTER_SETTING: 'GET_DEFAULT_FILTER_SETTING',
  // Get edit item by ID
  LOG_IM_BY_ID_REQUEST: 'LOG_IM_BY_ID_REQUEST',
  GET_IM_BY_ID_REQUEST: 'GET_IM_BY_ID_REQUEST',
  GET_IM_BY_ID_RESPONSE: 'GET_IM_BY_ID_RESPONSE',
  GET_IM_BY_ID_ERROR: 'GET_IM_BY_ID_ERROR',
  GET_IM_BY_ID_LOG_REQ: 'GET_IM_BY_ID_LOG_REQ',
  // Get Interface Mapping detail
  GET_IM_REQUEST: 'GET_IM_REQUEST',
  GET_IM_RESPONSE: 'GET_IM_RESPONSE',
  GET_IM_ERROR: 'GET_IM_ERROR',
  // Add/Update PLAN CODE detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_IM_REQUEST: 'INSERT_IM_REQUEST',
  INSERT_IM_RESPONSE: 'UPSERT_IM_RESPONSE',
  INSERT_IM_ERROR: 'UPSERT_IM_ERROR',
  UPDATE_IM_REQUEST: 'UPDATE_IM_REQUEST',
  UPDATE_IM_RESPONSE: 'UPDATE_IM_RESPONSE',
  UPDATE_IM_ERROR: 'UPDATE_IM_ERROR',
  // Delete PLAN CODE
  DELETE_IM_REQUEST: 'DELETE_IM_REQUEST',
  DELETE_IM_RESPONSE: 'DELETE_IM_RESPONSE',
  DELETE_IM_ERROR: 'DELETE_IM_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Update Filter Settings
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_IM_TABLE_CONFIGURATION',
  UPDATE_IM_FILTER_CONFIG: 'UPDATE_IM_FILTER_CONFIG',
  GET_IM_EXPORT_REQUEST: 'GET_IM_EXPORT_REQUEST',
  REMOVE_IM_USER_DEFAULT_FILTER: 'REMOVE_IM_USER_DEFAULT_FILTER',
  SET_IM_DETAILS_RESPONSE: 'SET_IM_DETAILS_RESPONSE',
  // Reset Store
  RESET_IM_STORE: 'RESET_IM_STORE',
  // Lookup
  GET_IM_LOOKUP_REQUEST: 'GET_IM_LOOKUP_REQUEST',
  RESET_IM_LOOKUPDATA: 'RESET_IM_LOOKUPDATA',
  // Export InterfaceMapping
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// Transaction Definition action types
export const transactionDefinitionActionType = {
  UPDATE_TD_COLUMN_CONFIG: 'UPDATE_TD_COLUMN_CONFIG',
  LOG_TD_LIST_REQUEST: 'LOG_TD_LIST_REQUEST',
  GET_TD_LIST_REQUEST: 'GET_TD_LIST_REQUEST',
  GET_TD_LIST_RESPONSE: 'GET_TD_LIST_RESPONSE',
  GET_TD_LIST_ERROR: 'GET_TD_LIST_ERROR',
  GET_TD_LIST_LOG_REQ: 'GET_TD_LIST_LOG_REQ',
  GET_DEFAULT_FILTER_SETTING: 'GET_DEFAULT_FILTER_SETTING',
  // Get Transaction Definition
  GET_TD_REQUEST: 'GET_TD_REQUEST',
  GET_TD_RESPONSE: 'GET_TD_RESPONSE',
  GET_TD_ERROR: 'GET_TD_ERROR',
  // Transaction Definition Detail
  LOG_TD_DETAIL_REQUEST: 'LOG_TD_DETAIL_REQUEST',
  GET_TD_DETAIL_LIST_REQUEST: 'GET_TD_DETAIL_LIST_REQUEST',
  RESET_TD_DETAIL_VALUE: 'RESET_TD_DETAIL_VALUE',
  DELETE_TD_DETAIL_REQUEST: 'DELETE_TD_DETAIL_REQUEST',
  GET_TD_SECURITY_DETAIL_LIST_REQUEST: 'GET_TD_SECURITY_DETAIL_LIST_REQUEST',
  GET_TD_SECURITY_ITM_DETAIL_LIST_REQUEST: 'GET_TD_SECURITY_ITM_DETAIL_LIST_REQUEST',
  GET_TD_ASSOCTRANS_DETAIL_LIST_REQUEST: 'GET_TD_ASSOCTRANS_DETAIL_LIST_REQUEST',
  GET_TD_ASSOCTS_ITM_DETAIL_LIST_REQ: 'GET_TD_ASSOCTS_ITM_DETAIL_LIST_REQ',
  GET_TD_DETAIL_RESPONSE: 'GET_TD_DETAIL_RESPONSE',
  GET_TD_DETAIL_ERROR: 'GET_TD_DETAIL_ERROR',
  RESET_TD_DETAIL_ERROR: 'RESET_TD_DETAIL_ERROR',
  SET_ASSO_TD_DETAIL: 'SET_ASSO_TD_DETAIL',
  // add/edit TD ASSOCIATE TRANSACTION
  INSERT_TD_ASSOCTRANS_REQUEST: 'INSERT_TD_ASSOCTRANS_REQUEST',
  UPDATE_TD_ASSOCTRANS_REQUEST: 'UPDATE_TD_ASSOCTRANS_REQUEST',
  DELETE_TD_ASSOCTRANS_REQUEST: 'DELETE_TD_ASSOCTRANS_REQUEST',
  // add/edit TD SECURITY DETAILS
  INSERT_TD_SECURITY_DETAIL_REQUEST: 'INSERT_TD_SECURITY_DETAIL_REQUEST',
  UPDATE_TD_SECURITY_DETAIL_REQUEST: 'UPDATE_TD_SECURITY_DETAIL_REQUEST',
  GET_TD_ITM_DETAIL_LIST_REQUEST: 'GET_TD_ITM_DETAIL_LIST_REQUEST',
  VALIDATE_TD_ITM_EDIT: 'VALIDATE_TD_ITM_EDIT',
  RESET_EDIT_STATUS: 'RESET_EDIT_STATUS',
  // Add/Update Transaction Definition detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  INSERT_TD_REQUEST: 'INSERT_TD_REQUEST',
  INSERT_TD_RESPONSE: 'UPSERT_TD_RESPONSE',
  INSERT_TD_ERROR: 'UPSERT_TD_ERROR',
  UPDATE_TD_REQUEST: 'UPDATE_TD_REQUEST',
  UPDATE_TD_RESPONSE: 'UPDATE_TD_RESPONSE',
  UPDATE_TD_ERROR: 'UPDATE_TD_ERROR',
  // Delete Transaction Definition
  DELETE_TD_REQUEST: 'DELETE_TD_REQUEST',
  DELETE_TD_RESPONSE: 'DELETE_TD_RESPONSE',
  DELETE_TD_ERROR: 'DELETE_TD_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Update Filter Settings
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TD_TABLE_CONFIGURATION',
  UPDATE_TD_FILTER_CONFIG: 'UPDATE_TD_FILTER_CONFIG',
  GET_TD_EXPORT_REQUEST: 'GET_TD_EXPORT_REQUEST',
  REMOVE_TD_USER_DEFAULT_FILTER: 'REMOVE_TD_USER_DEFAULT_FILTER',
  SET_TD_DETAILS_RESPONSE: 'SET_TD_DETAILS_RESPONSE',
  // Reset Store
  RESET_TD_STORE: 'RESET_TD_STORE',
  // Lookup
  GET_TD_LOOKUP_REQUEST: 'GET_TD_LOOKUP_REQUEST',
  RESET_TD_LOOKUPDATA: 'RESET_TD_LOOKUPDATA',
  // Export
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// Manage groups action types
export const manageGroupsActionType = {
  LOG_MG_LIST_REQUEST: 'LOG_MG_LIST_REQUEST',
  GET_MG_LIST_REQUEST: 'GET_MG_LIST_REQUEST',
  GET_MG_LIST_RESPONSE: 'GET_MG_LIST_RESPONSE',
  GET_MG_LIST_ERROR: 'GET_MG_LIST_ERROR',
  GET_MG_LIST_LOG_REQ: 'GET_MG_LIST_LOG_REQ',
  // Update manage groups
  UPDATE_MG_RESPONSE: 'UPDATE_MG_RESPONSE',
  UPDATE_MG_ERROR: 'UPDATE_MG_ERROR',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  // Reset store
  RESET_MG_STORE: 'RESET_MG_STORE',
  // Security groups
  LOG_SG_LIST_REQUEST: 'LOG_SG_LIST_REQUEST',
  GET_SG_LIST_REQUEST: 'GET_SG_LIST_REQUEST',
  GET_SG_LIST_RESPONSE: 'GET_SG_LIST_RESPONSE',
  GET_SG_LIST_ERROR: 'GET_SG_LIST_ERROR',
  GET_SG_LIST_LOG_REQ: 'GET_SG_LIST_LOG_REQ',
  UPDATE_SG_COLUMN_CONFIG: 'UPDATE_SG_COLUMN_CONFIG',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  // Update security groups
  UPDATE_SG_REQUEST: 'UPDATE_SG_REQUEST',
  UPDATE_SG_RESPONSE: 'UPDATE_SG_RESPONSE',
  UPDATE_SG_ERROR: 'UPDATE_SG_ERROR',
  // Reset store
  RESET_SG_STORE: 'RESET_SG_STORE',
  // NEW ADD
  RESET_REQ_PROSS_ON: 'RESET_REQ_PROSS_ON',
  // Manage Application
  LOG_MA_LIST_REQUEST: 'LOG_MA_LIST_REQUEST',
  GET_MA_LIST_REQUEST: 'GET_MA_LIST_REQUEST',
  GET_MA_RESPONSE: 'GET_MA_RESPONSE',
  GET_MA_LIST_ERROR: 'GET_MA_LIST_ERROR',
  GET_MA_LIST_LOG_REQ: 'GET_MA_LIST_LOG_REQ',
  RESET_MA_DATA: 'RESET_MA_DATA',
  UPDATE_MA_REQUEST: 'UPDATE_MA_REQUEST',
  UPDATE_MA_RESPONSE: 'UPDATE_MA_RESPONSE',
  UPDATE_MA_ERROR: 'UPDATE_MA_ERROR',
  CONCURRENCY_CHK: 'CONCURRENCY_CHK',
  RESET_CONCURRENCY_FLAG: 'RESET_CONCURRENCY_FLAG',
  GET_PAYMENT_INFO: 'GET_PAYMENT_INFO',
  RESET_SECURITY_PAYMENT_INFO: 'RESET_SECURITY_PAYMENT_INFO',
  UPDATE_FIELD_FORCE_SETTINGS: 'UPDATE_FIELD_FORCE_SETTINGS',
  GET_MANAGE_GRP_DESC: 'GET_MANAGE_GRP_DESC',
  SET_MANAGE_GRP_DESC_RESPONSE: 'SET_MANAGE_GRP_DESC_RESPONSE',
  PAYMENT_UPSERT_DATA: 'PAYMENT_UPSERT_DATA',
  // Add/Update Manage Group
  INSERT_MNG_GRP_REQUEST: 'INSERT_MNG_GRP_REQUEST',
  INSERT_MNG_GRP_RESPONSE: 'INSERT_MNG_GRP_RESPONSE',
  INSERT_MNG_GRP_ERROR: 'INSERT_MNG_GRP_ERROR',
  UPDATE_MNG_GRP_REQUEST: 'UPDATE_MNG_GRP_REQUEST',
  UPDATE_MNG_GRP_RESPONSE: 'UPDATE_MNG_GRP_RESPONSE',
  UPDATE_MNG_GRP_ERROR: 'UPDATE_MNG_GRP_ERROR',
  // Get Edit Item by Id
  LOG_MNG_GRP_BY_ID_REQUEST: 'LOG_MNG_GRP_BY_ID_REQUEST',
  GET_MNG_GRP_BY_ID_REQUEST: 'GET_MNG_GRP_BY_ID_REQUEST',
  GET_MNG_GRP_BY_ID_RESPONSE: 'GET_MNG_GRP_BY_ID_RESPONSE',
  GET_MNG_GRP_BY_ID_ERROR: 'GET_MNG_GRP_BY_ID_ERROR',
  GET_MNG_GRP_BY_ID_LOG_REQ: 'GET_MNG_GRP_BY_ID_LOG_REQ',
  // Delete security groups
  DELETE_SECURITY_GROUP_REQUEST: 'DELETE_SECURITY_GROUP_REQUEST',
  // relation between security group with user
  GET_MNG_GRP_USER_EXSISTS_RESPONSE: 'GET_MNG_GRP_USER_EXSISTS_RESPONSE',
  GET_MNG_GRP_USER_EXSISTS_ERROR: 'GET_MNG_GRP_USER_EXSISTS_ERROR',
  GET_MNG_GRP_USER_RELATIONAL_TABLE: 'GET_MNG_GRP_USER_RELATIONAL_TABLE',
};
export const systemOptionsActionType = {
  // Update System Options groups
  UPDATE_SYS_OPT_REQUEST: 'UPDATE_SYS_OPT_REQUEST',
  UPDATE_SYS_OPT_GEN_PREF_REQUEST: 'UPDATE_SYS_OPT_GEN_PREF_REQUEST',
  UPDATE_SYS_OPT_AGENT_PREF_REQUEST: 'UPDATE_SYS_OPT_AGENT_PREF_REQUEST',
  UPDATE_SYS_OPT_NOTIF_OTHER_REQUEST: 'UPDATE_SYS_OPT_NOTIF_OTHER_REQUEST',
  UPDATE_SYS_OPT_RESPONSE: 'UPDATE_SYS_OPT_RESPONSE',
  UPDATE_SYS_OPT_ERROR: 'UPDATE_SYS_OPT_ERROR',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_SYS_OPT_REQUEST: 'UPSERT_SYS_OPT_REQUEST',
  UPSERT_SYS_OPT_RESPONSE: 'UPSERT_SYS_OPT_RESPONSE',
  LOG_SYS_OPT_DETAIL_REQUEST: 'LOG_SYS_OPT_DETAIL_REQUEST',
  RESET_SYS_OPT_DETAIL_VALUE: 'RESET_SYS_OPT_DETAIL_VALUE',
  GET_SYS_OPT_REQUEST: 'GET_SYS_OPT_REQUEST',
  GET_SYS_OPT_GEN_PREF_REQUEST: 'GET_SYS_OPT_GEN_PREF_REQUEST',
  GET_SYS_OPT_AGENT_PREF_REQUEST: 'GET_SYS_OPT_AGENT_PREF_REQUEST',
  GET_SYS_OPT_NOTIF_OTHER_REQUEST: 'GET_SYS_OPT_NOTIF_OTHER_REQUEST',
  GET_SYS_OPT_DETAIL_RESPONSE: 'GET_SYS_OPT_DETAIL_RESPONSE',
  GET_SYS_OPT_DETAIL_ERROR: 'GET_SYS_OPT_DETAIL_ERROR',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// Income Expense Map (IEM)- Action Types
export const incomeExpenseMapActionType = {
  LOG_IEM_LIST_REQUEST: 'LOG_IEM_LIST_REQUEST',
  GET_IEM_LIST_REQUEST: 'GET_IEM_LIST_REQUEST',
  GET_IEM_LIST_RESPONSE: 'GET_IEM_LIST_RESPONSE',
  GET_IEM_LIST_ERROR: 'GET_IEM_LIST_ERROR',
  GET_IEM_LIST_LOG_REQ: 'GET_IEM_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_IEM_FILTER_CONFIG: 'UPDATE_IEM_FILTER_CONFIG',
  GET_IEM_EXPORT_REQUEST: 'GET_IEM_EXPORT_REQUEST',
  REMOVE_IEM_USER_DEFAULT_FILTER: 'REMOVE_IEM_USER_DEFAULT_FILTER',
  // Get Income Expense by ID
  LOG_IEM_BY_ID_REQUEST: 'LOG_IEM_BY_ID_REQUEST',
  GET_IEM_BY_ID_REQUEST: 'GET_IEM_BY_ID_REQUEST',
  GET_IEM_BY_ID_RESPONSE: 'GET_IEM_BY_ID_RESPONSE',
  GET_IEM_BY_ID_ERROR: 'GET_IEM_BY_ID_ERROR',
  GET_IEM_BY_ID_LOG_REQ: 'GET_IEM_BY_ID_LOG_REQ',
  // Get Income Expense Map detail
  GET_IEM_REQUEST: 'GET_IEM_REQUEST',
  GET_IEM_RESPONSE: 'GET_IEM_RESPONSE',
  GET_IEM_ERROR: 'GET_IEM_ERROR',
  // Add/Update Income Expense
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_IEM_REQUEST: 'UPSERT_IEM_REQUEST',
  INSERT_IEM_REQUEST: 'INSERT_IEM_REQUEST',
  INSERT_IEM_ERROR: 'INSERT_IEM_ERROR',
  UPDATE_IEM_REQUEST: 'UPDATE_IEM_REQUEST',
  UPDATE_IEM_RESPONSE: 'UPDATE_IEM_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_IEM_RESPONSE: 'UPSERT_IEM_RESPONSE',
  // Delete Income Expense
  DELETE_IEM_REQUEST: 'DELETE_IEM_REQUEST',
  DELETE_IEM_RESPONSE: 'DELETE_IEM_RESPONSE',
  DELETE_IEM_ERROR: 'DELETE_IEM_ERROR',
  // Detail Income Expense Map
  SET_IEM_DETAILS_RESPONSE: 'SET_IEM_DETAILS_RESPONSE',
  // Reset store
  RESET_IEM_STORE: 'RESET_IEM_STORE',
  // Export Income Expense Map
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // lookups
  RESET_IEM_LOOKUPDATA: 'RESET_IEM_LOOKUPDATA',
  GET_IEM_LOOKUP_REQUEST: 'GET_IEM_LOOKUP_REQUEST',
};
// Cheque Security - Action Types
export const chequeSecurityActionType = {
  LOG_CHS_LIST_REQUEST: 'LOG_CHS_LIST_REQUEST',
  GET_CHS_LIST_REQUEST: 'GET_CHS_LIST_REQUEST',
  GET_CHS_LIST_RESPONSE: 'GET_CHS_LIST_RESPONSE',
  GET_CHS_LIST_ERROR: 'GET_CHS_LIST_ERROR',
  GET_CHS_LIST_LOG_REQ: 'GET_CHS_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_CHS_FILTER_CONFIG: 'UPDATE_CHS_FILTER_CONFIG',
  GET_CHS_EXPORT_REQUEST: 'GET_CHS_EXPORT_REQUEST',
  REMOVE_CHS_USER_DEFAULT_FILTER: 'REMOVE_CHS_USER_DEFAULT_FILTER',
  // Get by ID
  LOG_CHS_BY_ID_REQUEST: 'LOG_CHS_BY_ID_REQUEST',
  GET_CHS_BY_ID_REQUEST: 'GET_CHS_BY_ID_REQUEST',
  GET_CHS_BY_ID_RESPONSE: 'GET_CHS_BY_ID_RESPONSE',
  GET_CHS_BY_ID_ERROR: 'GET_CHS_BY_ID_ERROR',
  GET_CHS_BY_ID_LOG_REQ: 'GET_CHS_BY_ID_LOG_REQ',
  // Get Detaill
  GET_CHS_REQUEST: 'GET_CHS_REQUEST',
  GET_CHS_RESPONSE: 'GET_CHS_RESPONSE',
  GET_CHS_ERROR: 'GET_CHS_ERROR',
  // Add/Update
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_CHS_REQUEST: 'UPSERT_CHS_REQUEST',
  INSERT_CHS_REQUEST: 'INSERT_CHS_REQUEST',
  INSERT_CHS_ERROR: 'INSERT_CHS_ERROR',
  UPDATE_CHS_REQUEST: 'UPDATE_CHS_REQUEST',
  UPDATE_CHS_RESPONSE: 'UPDATE_CHS_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_CHS_RESPONSE: 'UPSERT_CHS_RESPONSE',
  // Delete
  DELETE_CHS_REQUEST: 'DELETE_CHS_REQUEST',
  DELETE_CHS_RESPONSE: 'DELETE_CHS_RESPONSE',
  DELETE_CHS_ERROR: 'DELETE_CHS_ERROR',
  // Detail
  SET_CHS_DETAILS_RESPONSE: 'SET_CHS_DETAILS_RESPONSE',
  // Reset store
  RESET_CHS_STORE: 'RESET_CHS_STORE',
  // Export
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // LookUps
  RESET_CHS_LOOKUPDATA: 'RESET_CHS_LOOKUPDATA',
  GET_CHS_LOOKUP_REQUEST: 'GET_CHS_LOOKUP_REQUEST',
};
// Configure Letters - Action Types
export const configureLettersActionType = {
  LOG_CL_LIST_REQUEST: 'LOG_CL_LIST_REQUEST',
  GET_CL_LIST_REQUEST: 'GET_CL_LIST_REQUEST',
  GET_CL_LIST_RESPONSE: 'GET_CL_LIST_RESPONSE',
  GET_CL_LIST_ERROR: 'GET_CL_LIST_ERROR',
  GET_CL_LIST_LOG_REQ: 'GET_CL_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_CL_FILTER_CONFIG: 'UPDATE_CL_FILTER_CONFIG',
  GET_CL_EXPORT_REQUEST: 'GET_CL_EXPORT_REQUEST',
  REMOVE_CL_USER_DEFAULT_FILTER: 'REMOVE_CL_USER_DEFAULT_FILTER',
  // Get by ID
  LOG_CL_BY_ID_REQUEST: 'LOG_CL_BY_ID_REQUEST',
  GET_CL_BY_ID_REQUEST: 'GET_CL_BY_ID_REQUEST',
  GET_CL_BY_ID_RESPONSE: 'GET_CL_BY_ID_RESPONSE',
  GET_CL_BY_ID_ERROR: 'GET_CL_BY_ID_ERROR',
  GET_CL_BY_ID_LOG_REQ: 'GET_CL_BY_ID_LOG_REQ',
  // Get Detaill
  GET_CL_REQUEST: 'GET_CL_REQUEST',
  GET_CL_RESPONSE: 'GET_CL_RESPONSE',
  GET_CL_ERROR: 'GET_CL_ERROR',
  // Add/Update
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_CL_REQUEST: 'UPSERT_CL_REQUEST',
  INSERT_CL_REQUEST: 'INSERT_CL_REQUEST',
  INSERT_CL_ERROR: 'INSERT_CL_ERROR',
  UPDATE_CL_REQUEST: 'UPDATE_CL_REQUEST',
  UPDATE_CL_RESPONSE: 'UPDATE_CL_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_CL_RESPONSE: 'UPSERT_CL_RESPONSE',
  // Delete
  DELETE_CL_REQUEST: 'DELETE_CL_REQUEST',
  DELETE_CL_RESPONSE: 'DELETE_CL_RESPONSE',
  DELETE_CL_ERROR: 'DELETE_CL_ERROR',
  // Detail
  SET_CL_DETAILS_RESPONSE: 'SET_CL_DETAILS_RESPONSE',
  // Reset store
  RESET_CL_STORE: 'RESET_CL_STORE',
  // Export
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // LookUps
  RESET_CL_LOOKUPDATA: 'RESET_CL_LOOKUPDATA',
  GET_CL_LOOKUP_REQUEST: 'GET_CL_LOOKUP_REQUEST',
  // Validate File Name
  VALIDATE_CL_FILENAME_REQUEST: 'VALIDATE_CL_FILENAME_REQUEST',
  VALIDATE_CL_FILENAME_RESPONSE: 'VALIDATE_CL_FILENAME_RESPONSE',
};
// DailyProcess
export const dailyProcessActionType = {
  LOG_DP_LIST_REQUEST: 'LOG_DP_LIST_REQUEST',
  GET_DP_LIST_REQUEST: 'GET_DP_LIST_REQUEST',
  GET_DP_LIST_SCHEDULE_REQUEST: 'GET_DP_LIST_SCHEDULE_REQUEST',
  GET_DP_LIST_RESPONSE: 'GET_DP_LIST_RESPONSE',
  GET_DP_LIST_ERROR: 'GET_DP_LIST_ERROR',
  GET_DP_LIST_LOG_REQ: 'GET_DP_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_DP_FILTER_CONFIG: 'UPDATE_DP_FILTER_CONFIG',
  GET_DP_EXPORT_REQUEST: 'GET_DP_EXPORT_REQUEST',
  REMOVE_DP_USER_DEFAULT_FILTER: 'REMOVE_DP_USER_DEFAULT_FILTER',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // DailyProcessStartProcess
  LOG_DP_START_PROCESS: 'LOG_DP_START_PROCESS',
  GET_DP_START_PROCESS: 'GET_DP_START_PROCESS',
  GET_DP_START_PROCESS_RESPONSE: 'GET_DP_START_PROCESS_RESPONSE',
  GET_DP_START_PROCESS_ERROR: 'GET_DP_START_PROCESS_ERROR',
  GET_DP_START_PROCESS_LOG_REQ: 'GET_DP_START_PROCESS_LOG_REQ',
  GET_DP_START_PROCESS_TABLE_RES: 'GET_DP_START_PROCESS_TABLE_RES',
  // DailyProcessBatchStatus
  LOG_DP_BATCH_STATUS: 'LOG_DP_BATCH_STATUS',
  GET_DP_BATCH_STATUS: 'GET_DP_BATCH_STATUS',
  GET_DP_BATCH_STATUS_RESPONSE: 'GET_DP_BATCH_STATUS_RESPONSE',
  GET_DP_BATCH_STATUS_ERROR: 'GET_DP_BATCH_STATUS_ERROR',
  // daily Process Hisory
  LOG_HISTORY_LIST_REQUEST: 'LOG_HISTORY_LIST_REQUEST',
  GET_HISTORY_LIST_REQUEST: 'GET_HISTORY_LIST_REQUEST',
  GET_HISTORY_LIST_RESPONSE: 'GET_HISTORY_LIST_RESPONSE',
  GET_HISTORY_DATA_RESPONSE: 'GET_HISTORYR_DATA_RESPONSE',
  GET_DP_HISTORY_ERROR: 'GET_DP_HISTORY_ERROR',
  GET_DP_HISTORY_LOG_REQ: 'GET_DP_HISTORY_LOG_REQ',
  // Resume
  RESET_RESUME_VALUE: 'RESET_RESUME_VALUE',
  // LOG_DP_RESUME_REQUEST: 'LOG_DP_RESUME_REQUEST',
  GET_DP_RESUME_REQUEST: 'GET_DP_RESUME_REQUEST',
  GET_DP_RESUME_RESPONSE: 'GET_DP_RESUME_RESPONSE',
  GET_DP_RESUME_ERROR: 'GET_DP_RESUME_ERROR',
  // Daily process Sequence
  LOG_DP_SET_SEQUENCE: 'LOG_DP_SET_SEQUENCE',
  SET_DP_SEQUENCE: 'SET_DP_SEQUENCE',
  SET_DP_SEQUENCE_RESPONSE: 'SET_DP_SEQUENCE_RESPONSE',
  SET_DP_SEQUENCE_ERROR: 'SET_DP_SEQUENCE_ERROR',
  SET_DP_SEQUENCE_LOG_REQ: 'SET_DP_SEQUENCE_LOG_REQ',
  SET_DP_SEQUENCE_TABLE_RES: 'SET_DP_SEQUENCE_TABLE_RES',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_DP_REQUEST: 'UPSERT_DP_REQUEST',
  // Detail
  SET_DP_DETAILS_RESPONSE: 'SET_DP_DETAILS_RESPONSE',
  // Reset store
  RESET_DP_STORE: 'RESET_DP_STORE',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // lookups
  RESET_DP_LOOKUPDATA: 'RESET_DP_LOOKUPDATA',
  GET_DP_LOOKUP_REQUEST: 'GET_DP_LOOKUP_REQUEST',
  GET_DP_DETAIL_RESPONSE: 'GET_DP_DETAIL_RESPONSE',
  RESET_DP_TAB_DETAIL_VALUE: 'RESET_DP_TAB_DETAIL_VALUE',
  RESET_DP_SUB_TAB_DETAIL_VALUE: 'RESET_DP_SUB_TAB_DETAIL_VALUE',
  LOG_DP_DETAIL_REQUEST: 'LOG_DP_DETAIL_REQUEST',
  GET_DP_DETAIL_ERROR: 'GET_DP_DETAIL_ERROR',
  RESET_DP_DETAIL_ERROR: 'RESET_DP_DETAIL_ERROR',
  GET_DP_HEADER_REQUEST: 'GET_DP_HEADER_REQUEST',
  UPDATE_DP_COLUMN_CONFIG: 'UPDATE_DP_COLUMN_CONFIG',
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  GET_DP_DEDUCTION_LIST: 'GET_DP_DEDUCTION_LIST',
  RESET_DP_DETAIL_VALUE: 'RESET_DP_DETAIL_VALUE',
  GET_DP_HEADER_DETAIL_RESPONSE: 'GET_DP_HEADER_DETAIL_RESPONSE',
};
export const dailyActivityFCRActionType = {
  // FCR GET
  LOG_FCR_LIST_REQUEST: 'LOG_FCR_LIST_REQUEST',
  GET_FCR_LIST_REQUEST: 'GET_FCR_LIST_REQUEST',
  GET_FCR_LIST_RESPONSE: 'GET_FCR_LIST_RESPONSE',
  GET_FCR_DATA_RESPONSE: 'GET_FCR_DATA_RESPONSE',
  GET_FCR_LIST_ERROR: 'GET_FCR_LIST_ERROR',
  GET_FCR_LIST_LOG_REQ: 'GET_FCR_LIST_LOG_REQ',
  // FCR HISTORY
  LOG_FCR_HISTORY_REQUEST: 'LOG_FCR_HISTORY_REQUEST',
  GET_FCR_HISTORY_REQUEST: 'GET_FCR_HISTORY_REQUEST',
  GET_FCR_HISTORY_RESPONSE: 'GET_FCR_HISTORY_RESPONSE',
  GET_FCR_HISTORY_ERROR: 'GET_FCR_HISTORY_ERROR',
  GET_FCR_HISTORY_LOG_REQ: 'GET_FCR_HISTORY_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  UPDATE_FCR_FILTER_CONFIG: 'UPDATE_FCR_FILTER_CONFIG',
  // FCR ADD
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_FCR_REQUEST: 'UPSERT_FCR_REQUEST',
  INSERT_FCR_REQUEST: 'INSERT_FCR_REQUEST',
  INSERT_FCR_ERROR: 'INSERT_FCR_ERROR',
  UPDATE_FCR_REQUEST: 'UPDATE_FCR_REQUEST',
  UPDATE_FCR_RESPONSE: 'UPDATE_FCR_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_FCR_RESPONSE: 'UPSERT_FCR_RESPONSE',
  // Reset store
  RESET_FCR_STORE: 'RESET_FCR_STORE',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // FCR DELETE
  DELETE_FCR_REQUEST: 'DELETE_FCR_REQUEST',
  DELETE_FCR_RESPONSE: 'DELETE_FCR_RESPONSE',
  DELETE_FCR_ERROR: 'DELETE_FCR_ERROR',
};
// Access Options Action Types
export const accessOptionsActionType = {
  LOG_ACCESSOPTS_LIST_REQUEST: 'LOG_ACCESSOPTS_LIST_REQUEST',
  GET_ACCESSOPTS_LIST_REQUEST: 'GET_ACCESSOPTS_LIST_REQUEST',
  GET_ACCESSOPTS_LIST_RESPONSE: 'GET_ACCESSOPTS_LIST_RESPONSE',
  GET_ACCESSOPTS_LIST_ERROR: 'GET_ACCESSOPTS_LIST_ERROR',
  GET_ACCESSOPTS_LIST_LOG_REQ: 'GET_ACCESSOPTS_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_ACCESSOPTS_FILTER_CONFIG: 'UPDATE_ACCESSOPTS_FILTER_CONFIG',
  GET_ACCESSOPTS_EXPORT_REQUEST: 'GET_ACCESSOPTS_EXPORT_REQUEST',
  REMOVE_ACCESSOPTS_USER_DEFAULT_FILTER: 'REMOVE_ACCESSOPTS_USER_DEFAULT_FILTER',
  // Get Edit Item by ID
  LOG_ACCESSOPTS_BY_ID_REQUEST: 'LOG_ACCESSOPTS_BY_ID_REQUEST',
  GET_ACCESSOPTS_BY_ID_REQUEST: 'GET_ACCESSOPTS_BY_ID_REQUEST',
  GET_ACCESSOPTS_BY_ID_RESPONSE: 'GET_ACCESSOPTS_BY_ID_RESPONSE',
  GET_ACCESSOPTS_BY_ID_ERROR: 'GET_ACCESSOPTS_BY_ID_ERROR',
  GET_ACCESSOPTS_BY_ID_LOG_REQ: 'GET_ACCESSOPTS_BY_ID_LOG_REQ',
  // Get system parameters detail
  GET_ACCESSOPTS_REQUEST: 'GET_ACCESSOPTS_REQUEST',
  GET_ACCESSOPTS_RESPONSE: 'GET_ACCESSOPTS_RESPONSE',
  GET_ACCESSOPTS_ERROR: 'GET_ACCESSOPTS_ERROR',
  // Add/Update system parameter
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_ACCESSOPTS_REQUEST: 'UPSERT_ACCESSOPTS_REQUEST',
  INSERT_ACCESSOPTS_REQUEST: 'INSERT_ACCESSOPTS_REQUEST',
  INSERT_ACCESSOPTS_ERROR: 'INSERT_ACCESSOPTS_ERROR',
  UPDATE_ACCESSOPTS_REQUEST: 'UPDATE_ACCESSOPTS_REQUEST',
  UPDATE_ACCESSOPTS_RESPONSE: 'UPDATE_ACCESSOPTS_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_ACCESSOPTS_RESPONSE: 'UPSERT_ACCESSOPTS_RESPONSE',
  // Delete system parameter
  DELETE_ACCESSOPTS_REQUEST: 'DELETE_ACCESSOPTS_REQUEST',
  DELETE_ACCESSOPTS_RESPONSE: 'DELETE_ACCESSOPTS_RESPONSE',
  DELETE_ACCESSOPTS_ERROR: 'DELETE_ACCESSOPTS_ERROR',
  // Detail system parameter
  SET_ACCESSOPTS_DETAILS_RESPONSE: 'SET_ACCESSOPTS_DETAILS_RESPONSE',
  // Reset store
  RESET_ACCESSOPTS_STORE: 'RESET_ACCESSOPTS_STORE',
  // Export SystemParameters
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// Notification Events Action Types
export const notificationEventsActionType = {
  LOG_NOTIF_EVENTS_LIST_REQUEST: 'LOG_NOTIF_EVENTS_LIST_REQUEST',
  GET_NOTIF_EVENTS_LIST_REQUEST: 'GET_NOTIF_EVENTS_LIST_REQUEST',
  GET_NOTIF_USERS_LIST_REQUEST: 'GET_NOTIF_USERS_LIST_REQUEST',
  GET_NOTIF_EVENTS_LIST_RESPONSE: 'GET_NOTIF_EVENTS_LIST_RESPONSE',
  GET_NOTIF_EVENTS_LIST_ERROR: 'GET_NOTIF_EVENTS_LIST_ERROR',
  GET_NOTIF_EVENTS_LIST_LOG_REQ: 'GET_NOTIF_EVENTS_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_NOTIF_EVENTS_FILTER_CONFIG: 'UPDATE_NOTIF_EVENTS_FILTER_CONFIG',
  GET_NOTIF_EVENTS_EXPORT_REQUEST: 'GET_NOTIF_EVENTS_EXPORT_REQUEST',
  REMOVE_NOTIF_EVENTS_USER_DEFAULT_FILTER: 'REMOVE_NOTIF_EVENTS_USER_DEFAULT_FILTER',
  GET_NOTIFICATION_ADDCONC: 'GET_NOTIFICATION_ADDCONC',
  GET_NOTIFICATION_ADDCONC_ERROR: 'GET_NOTIFICATION_ADDCONC_ERROR',
  GET_NOTIFICATION_LIST_RESPONSE: 'GET_NOTIFICATION_LIST_RESPONSE',
  // Get Edit Item by ID
  LOG_NOTIF_EVENTS_BY_ID_REQUEST: 'LOG_NOTIF_EVENTS_BY_ID_REQUEST',
  GET_NOTIF_EVENTS_BY_ID_REQUEST: 'GET_NOTIF_EVENTS_BY_ID_REQUEST',
  GET_NOTIF_EVENTS_BY_ID_RESPONSE: 'GET_NOTIF_EVENTS_BY_ID_RESPONSE',
  GET_NOTIF_EVENTS_BY_ID_ERROR: 'GET_NOTIF_EVENTS_BY_ID_ERROR',
  GET_NOTIF_EVENTS_BY_ID_LOG_REQ: 'GET_NOTIF_EVENTS_BY_ID_LOG_REQ',
  // Get system parameters detail
  GET_NOTIF_EVENTS_REQUEST: 'GET_NOTIF_EVENTS_REQUEST',
  GET_NOTIF_EVENTS_RESPONSE: 'GET_NOTIF_EVENTS_RESPONSE',
  GET_NOTIF_EVENTS_ERROR: 'GET_NOTIF_EVENTS_ERROR',
  // Add/Update system parameter
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_NOTIF_EVENTS_REQUEST: 'UPSERT_NOTIF_EVENTS_REQUEST',
  INSERT_NOTIF_EVENTS_REQUEST: 'INSERT_NOTIF_EVENTS_REQUEST',
  INSERT_NOTIF_EVENTS_ERROR: 'INSERT_NOTIF_EVENTS_ERROR',
  UPDATE_NOTIF_EVENTS_REQUEST: 'UPDATE_NOTIF_EVENTS_REQUEST',
  UPDATE_NOTIF_EVENTS_RESPONSE: 'UPDATE_NOTIF_EVENTS_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_NOTIF_EVENTS_RESPONSE: 'UPSERT_NOTIF_EVENTS_RESPONSE',
  // Delete system parameter
  DELETE_NOTIF_EVENTS_REQUEST: 'DELETE_NOTIF_EVENTS_REQUEST',
  DELETE_NOTIF_EVENTS_RESPONSE: 'DELETE_NOTIF_EVENTS_RESPONSE',
  DELETE_NOTIF_EVENTS_ERROR: 'DELETE_NOTIF_EVENTS_ERROR',
  // Detail system parameter
  SET_NOTIF_EVENTS_DETAILS_RESPONSE: 'SET_NOTIF_EVENTS_DETAILS_RESPONSE',
  // Reset store
  RESET_NOTIF_EVENTS_STORE: 'RESET_NOTIF_EVENTS_STORE',
  // Export SystemParameters
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// Notification Users Action Types
export const notificationUsersActionType = {
  LOG_NOTIF_USERS_LIST_REQUEST: 'LOG_NOTIF_USERS_LIST_REQUEST',
  GET_NOTIF_USERS_LIST_REQUEST: 'GET_NOTIF_USERS_LIST_REQUEST',
  GET_NOTIF_USERS_LIST_RESPONSE: 'GET_NOTIF_USERS_LIST_RESPONSE',
  GET_NOTIF_USERS_LIST_ERROR: 'GET_NOTIF_USERS_LIST_ERROR',
  GET_NOTIF_USERS_LIST_LOG_REQ: 'GET_NOTIF_USERS_LIST_LOG_REQ',
  UPDATE_USER_TABLE_CONFIGURATION: 'UPDATE_USER_TABLE_CONFIGURATION',
  UPDATE_NOTIF_USERS_FILTER_CONFIG: 'UPDATE_NOTIF_USERS_FILTER_CONFIG',
  GET_NOTIF_USERS_EXPORT_REQUEST: 'GET_NOTIF_USERS_EXPORT_REQUEST',
  REMOVE_NOTIF_USERS_USER_DEFAULT_FILTER: 'REMOVE_NOTIF_USERS_USER_DEFAULT_FILTER',
  // Get Edit Item by ID
  LOG_NOTIF_USERS_BY_ID_REQUEST: 'LOG_NOTIF_USERS_BY_ID_REQUEST',
  GET_NOTIF_USERS_BY_ID_REQUEST: 'GET_NOTIF_USERS_BY_ID_REQUEST',
  GET_NOTIF_USERS_BY_ID_RESPONSE: 'GET_NOTIF_USERS_BY_ID_RESPONSE',
  GET_NOTIF_USERS_BY_ID_ERROR: 'GET_NOTIF_USERS_BY_ID_ERROR',
  GET_NOTIF_USERS_BY_ID_LOG_REQ: 'GET_NOTIF_USERS_BY_ID_LOG_REQ',
  // Get system parameters detail
  GET_NOTIF_USERS_REQUEST: 'GET_NOTIF_USERS_REQUEST',
  GET_NOTIF_USERS_RESPONSE: 'GET_NOTIF_USERS_RESPONSE',
  GET_NOTIF_USERS_ERROR: 'GET_NOTIF_USERS_ERROR',
  // Add/Update system parameter
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_NOTIF_USERS_REQUEST: 'UPSERT_NOTIF_USERS_REQUEST',
  INSERT_NOTIF_USERS_REQUEST: 'INSERT_NOTIF_USERS_REQUEST',
  INSERT_NOTIF_USERS_ERROR: 'INSERT_NOTIF_USERS_ERROR',
  UPDATE_NOTIF_USERS_REQUEST: 'UPDATE_NOTIF_USERS_REQUEST',
  UPDATE_NOTIF_USERS_RESPONSE: 'UPDATE_NOTIF_USERS_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_NOTIF_USERS_RESPONSE: 'UPSERT_NOTIF_USERS_RESPONSE',
  // Delete system parameter
  DELETE_NOTIF_USERS_REQUEST: 'DELETE_NOTIF_USERS_REQUEST',
  DELETE_NOTIF_USERS_RESPONSE: 'DELETE_NOTIF_USERS_RESPONSE',
  DELETE_NOTIF_USERS_ERROR: 'DELETE_NOTIF_USERS_ERROR',
  // Detail system parameter
  SET_NOTIF_USERS_DETAILS_RESPONSE: 'SET_NOTIF_USERS_DETAILS_RESPONSE',
  // Reset store
  RESET_NOTIF_USERS_STORE: 'RESET_NOTIF_USERS_STORE',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
// Export SystemParameters
export const statementsAndXMLActionTypes = {
  GET_BATCH_REPORT_LIST_REQ: 'GET_BATCH_REPORT_LIST_REQ',
  LOG_BATCH_REPORT_LIST_REQ: 'LOG_BATCH_REPORT_LIST_REQ',
  GET_BATCH_REPORT_LIST_RESPONSE: 'GET_BATCH_REPORT_LIST_RESPONSE',
  GET_BATCH_REPORT_LIST_ERROR: 'GET_BATCH_REPORT_LIST_ERROR',
  GET_BATCH_RPT_JOB_REQ_DETAILS: 'GET_BATCH_RPT_JOB_REQ_DETAILS',
  GET_BATCH_RPT_JOB_RESPONSE: 'GET_BATCH_RPT_JOB_RESPONSE',
  GET_BATCH_RPT_JOB_ERROR: 'GET_BATCH_RPT_JOB_ERROR',
  LOG_BATCH_REPORT_JOB_Detail: 'LOG_BATCH_REPORT_JOB_Detail',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  GET_BATCH_REPORT_START_REQ: 'GET_BATCH_REPORT_START_REQ',
  GET_BATCH_REPORT_START_RESPONSE: 'GET_BATCH_REPORT_START_RESPONSE',
  GET_BATCH_REPORT_START_ERROR: 'GET_BATCH_REPORT_START_ERROR',
  RESET_BATCH_RPT_DEFAULT_VALUES: 'RESET_BATCH_RPT_DEFAULT_VALUES',
  RESET_BATCH_RPT_DEFAULT_VALUE: 'RESET_BATCH_RPT_DEFAULT_VALUE',
  LOG_BATCH_RPT_STOP_REQ: 'LOG_BATCH_RPT_STOP_REQ',
  BATCH_RPT_STOP_RES: 'BATCH_RPT_STOP_RES',
  BATCH_RPT_STOP_ERROR: 'BATCH_RPT_STOP_ERROR',
  BATCH_RPT_STOP_REQ: 'BATCH_RPT_STOP_REQ',
  SET_DROPDOWN_SELECTED_VAL: 'SET_DROPDOWN_SELECTED_VAL',
  LOG_BATCH_START_REQ: 'LOG_BATCH_START_REQ',
  BATCH_RPT_STOP_LOADING: 'BATCH_RPT_STOP_LOADING',
  LOG_BATCH_RPT_STOP_LOADING: 'LOG_BATCH_RPT_STOP_LOADING',
  RESET_STOP_START_ERRORS: 'RESET_STOP_START_ERRORS',
};
// auto reports
export const autoReportsActionTypes = {
  LOG_AUTOREPORTS_LIST_REQUEST: 'LOG_AUTOREPORTS_LIST_REQUEST',
  GET_AUTOREPORTS_LIST_REQUEST: 'GET_AUTOREPORTS_LIST_REQUEST',
  GET_AUTOREPORTS_LIST_RESPONSE: 'GET_AUTOREPORTS_LIST_RESPONSE',
  GET_AUTOREPORTS_LIST_ERROR: 'GET_AUTOREPORTS_LIST_ERROR',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  RESET_AUTOREPORTS_STORE: 'RESET_AUTOREPORTS_STORE',
  UPDATE_BCR_COLUMN_CONFIG: 'UPDATE_BCR_COLUMN_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  GET_AUTOREPORTS_EXPORT_REQUEST: 'GET_AUTOREPORTS_EXPORT_REQUEST',
  SET_AR_DETAILS_RESPONSE: 'SET_AR_DETAILS_RESPONSE',
  GET_AUTOREPORTS_ITEM_REQUEST: 'GET_AUTOREPORTS_ITEM_REQUEST',
  LOG_AUTOREPORTS_ITEM_REQUEST: 'LOG_AUTOREPORTS_ITEM_REQUEST',
  GET_AUTOREPORTS_ITEM_RESPONSE: 'GET_AUTOREPORTS_ITEM_RESPONSE',
  GET_AUTOREPORTS_ITEM_ERROR: 'GET_AUTOREPORTS_ITEM_ERROR',
};
// Data Transfer ActionTypes
export const dataTransferActionType = {
  LOG_DT_LIST_REQUEST: 'LOG_DT_LIST_REQUEST',
  GET_DT_LIST_REQUEST: 'GET_DT_LIST_REQUEST',
  GET_DT_LIST_SCHEDULE_REQUEST: 'GET_DT_LIST_SCHEDULE_REQUEST',
  GET_DT_LIST_RESPONSE: 'GET_DT_LIST_RESPONSE',
  GET_DT_LIST_ERROR: 'GET_DT_LIST_ERROR',
  GET_DT_LIST_LOG_REQ: 'GET_DT_LIST_LOG_REQ',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_SYSTEM_TABLE_CONFIGURATION',
  UPDATE_DT_FILTER_CONFIG: 'UPDATE_DT_FILTER_CONFIG',
  GET_DT_EXPORT_REQUEST: 'GET_DT_EXPORT_REQUEST',
  REMOVE_DT_USER_DEFAULT_FILTER: 'REMOVE_DT_USER_DEFAULT_FILTER',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  // Data Transfer StartProcess
  LOG_DT_START_PROCESS: 'LOG_DT_START_PROCESS',
  GET_DT_START_PROCESS: 'GET_DT_START_PROCESS',
  GET_DT_START_SEQUENCE_RESPONSE: 'GET_DT_START_SEQUENCE_RESPONSE',
  GET_DT_START_PROCESS_ERROR: 'GET_DT_START_PROCESS_ERROR',
  GET_DT_START_PROCESS_LOG_REQ: 'GET_DT_START_PROCESS_LOG_REQ',
  GET_DT_START_PROCESS_TABLE_RES: 'GET_DT_START_PROCESS_TABLE_RES',
  GET_DT_CANCEL_PROCESS_REQ: 'GET_DT_CANCEL_PROCESS_REQ',
  GET_DT_CANCEL_PROCESS_RES: 'GET_DT_CANCEL_PROCESS_RES',
  // Data Tansfer Sequence
  LOG_DT_SET_SEQUENCE: 'LOG_DT_SET_SEQUENCE',
  SET_DT_SEQUENCE: 'SET_DT_SEQUENCE',
  SET_DT_SEQUENCE_RESPONSE: 'SET_DT_SEQUENCE_RESPONSE',
  SET_DT_SEQUENCE_ERROR: 'GET_DT_START_PROCESS_ERROR',
  SET_DT_SEQUENCE_LOG_REQ: 'GET_DT_START_PROCESS_LOG_REQ',
  SET_DT_SEQUENCE_TABLE_RES: 'SET_DT_SEQUENCE_TABLE_RES',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPSERT_DT_REQUEST: 'UPSERT_DT_REQUEST',
  // Detail
  SET_DT_DETAILS_RESPONSE: 'SET_DT_DETAILS_RESPONSE',
  // Reset store
  RESET_DT_STORE: 'RESET_DT_STORE',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // lookups
  RESET_DT_LOOKUPDATA: 'RESET_DT_LOOKUPDATA',
  GET_DT_LOOKUP_REQUEST: 'GET_DT_LOOKUP_REQUEST',
  GET_DT_DETAIL_RESPONSE: 'GET_DT_DETAIL_RESPONSE',
  RESET_DT_TAB_DETAIL_VALUE: 'RESET_DT_TAB_DETAIL_VALUE',
  RESET_DT_SUB_TAB_DETAIL_VALUE: 'RESET_DT_SUB_TAB_DETAIL_VALUE',
  LOG_DT_DETAIL_REQUEST: 'LOG_DT_DETAIL_REQUEST',
  GET_DT_DETAIL_ERROR: 'GET_DT_DETAIL_ERROR',
  RESET_DT_DETAIL_ERROR: 'RESET_DT_DETAIL_ERROR',
  GET_DT_HEADER_REQUEST: 'GET_DT_HEADER_REQUEST',
  UPDATE_DT_COLUMN_CONFIG: 'UPDATE_DT_COLUMN_CONFIG',
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  GET_DT_DEDUCTION_LIST: 'GET_DT_DEDUCTION_LIST',
  RESET_DT_DETAIL_VALUE: 'RESET_DT_DETAIL_VALUE',
  GET_DT_HEADER_DETAIL_RESPONSE: 'GET_DT_HEADER_DETAIL_RESPONSE',
};
