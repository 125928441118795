import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { systemOptionsActionType } from '../actions/actionTypes';

const SystemOptionsDetails = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case systemOptionsActionType.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case systemOptionsActionType.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
      };
    case systemOptionsActionType.UPSERT_SYS_OPT_REQUEST:
      return {
        ...state,
        upsertRequestedOn: new Date(),
        upsertData: action.data.upsertData,
        loading: true,
      };
    case systemOptionsActionType.GET_SYS_OPT_DETAIL_RESPONSE:
      return {
        ...state,
        detailData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case systemOptionsActionType.RESET_SYS_OPT_DETAIL_VALUE:
      return {
        ...state,
        detailData: null,
        selectedTab: action.data.tab,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case systemOptionsActionType.LOG_SYS_OPT_DETAIL_REQUEST:
      return {
        ...state,
        detailRequestedOn: new Date(),
        loading: true,
      };
    case systemOptionsActionType.RESET_SYS_OPT_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    case systemOptionsActionType.GET_SYS_OPT_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default SystemOptionsDetails;
