import { put, call, all } from 'redux-saga/effects';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { processingCompleted, processingStarted } from '../../../../common/redux/actions/commonActions';
import { Logger, processError } from '../../../../common/util';
import { rateKeyActionTypes, rateMasterActionTypes } from '../actions/actionTypes';
import moduleAPI from '../../config/apiConfig';

export function* getRateMasterList(action) {
  Logger.verbose(`Action Detail For Get Rate Master List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterActionTypes.LOG_LIST_REQUEST, action.data)),
    put(getAction(rateMasterActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.RATE_MASTER_LIST,
      action.data);
    yield all([
      put(getAction(rateMasterActionTypes.GET_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(rateMasterActionTypes.GET_LIST_ERROR, processError(err))), put(processingCompleted())]);
  }
}

export function* addRateMaster(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.RATE_MASTER_ADD,
      action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(rateMasterActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateMasterActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getRateMasterByID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(rateMasterActionTypes.GET_LIST_ERROR, null)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.RATE_MASTER_GET_BY_ID,
      action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(rateMasterActionTypes.GET_BY_ID_RESPONSE, responseData)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateMasterActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(rateMasterActionTypes.GET_LIST_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}
export function* updateRateMaster(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.RATE_MASTER_UPDATE,
      action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(rateMasterActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(rateMasterActionTypes.GET_BY_ID_RESPONSE, null)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateMasterActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* deleteRateMaster(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.RATE_MASTER_DELETE,
      action.data.deleteItem);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield all([
        put(getAction(rateMasterActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
        put(getAction(rateMasterActionTypes.GET_LIST_REQUEST, action.data.reqObj)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };

    yield all([
      put(getAction(rateMasterActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* addRateKey(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.RATE_KEY_ADD,
      action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(rateKeyActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(rateKeyActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
