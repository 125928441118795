export const fieldForceDeductionActions = {
  GET_FF_LOOKUP_REQUEST: 'GET_FF_LOOKUP_REQUEST',
  GET_FF_GENERAL_DETAIL_REQUEST: 'GET_FF_GENERAL_DETAIL_REQUEST',
  GET_FF_BY_ID_REQUEST: 'GET_FF_BY_ID_REQUEST',
  GET_FF_BY_ID_RESPONSE: 'GET_FF_BY_ID_RESPONSE',
  GET_FF_PAYEE_REQUEST: 'GET_FF_PAYEE_REQUEST',
  RESET_FF_PAYEE_NAME_RESPONSE: 'RESET_FF_PAYEE_NAME_RESPONSE',
  GET_FF_SEC_WEALTH_CODE: 'GET_FF_SEC_WEALTH_CODE',
  GET_FF_SEC_JOB_STATUS: 'GET_FF_SEC_JOB_STATUS',
  GET_FF_GEN_SEC_WEALTH_CODE_REQ: 'GET_FF_GEN_SEC_WEALTH_CODE_REQ',
  GET_FF_GEN_SEC_WEALTH_CODE_RES: 'GET_FF_GEN_SEC_WEALTH_CODE_RES',
  GET_FF_VALIDATE_STATUS_REQUEST: 'GET_FF_VALIDATE_STATUS_REQUEST',
  GET_FF_VALIDATE_STATUS_RESPONSE: 'GET_FF_VALIDATE_STATUS_RESPONSE',
  GET_FF_GST_STATUS_REQUEST: 'GET_FF_GST_STATUS_REQUEST',
  GET_FF_GST_STATUS_RESPONSE: 'GET_FF_GST_STATUS_RESPONSE',
  GET_FF_SYS_OPTION_REQUEST: 'GET_FF_SYS_OPTION_REQUEST',
  GET_FF_SYS_OPTION_RESPONSE: 'GET_FF_SYS_OPTION_RESPONSE',
  RESET_FF_VALIDATE_STATUS_RESPONSE: 'RESET_FF_VALIDATE_STATUS_RESPONSE',
  UPDATE_FF_REQUEST: 'UPDATE_FF_REQUEST',
  GET_JOB_STATUS: 'GET_JOB_STATUS',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  SET_FF_DETAILS_RESPONSE: 'SET_FF_DETAILS_RESPONSE',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_DEDUCTION_PARAMS: 'RESET_DEDUCTION_PARAMS',
  RESET_DEDUCTION_PARAMS_COL: 'RESET_DEDUCTION_PARAMS_COL',
  RESET_DEDUCTION_PARAMS_SORT: 'RESET_DEDUCTION_PARAMS_SORT',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  GET_FF_DETAIL_RESPONSE: 'GET_FF_DETAIL_RESPONSE',
  RESET_FF_DETAIL_RESPONSE: 'RESET_FF_DETAIL_RESPONSE',
  GET_FF_PAYEE_RESPONSE: 'GET_FF_PAYEE_RESPONSE',
  RESET_FF_PAYEE_RESPONSE: 'RESET_FF_PAYEE_RESPONSE',
  GET_FF_ORG_STRUCT_DETAIL: 'GET_FF_ORG_STRUCT_DETAIL',
  RESET_FF_ORG_STRUCT_DETAIL: 'RESET_FF_ORG_STRUCT_DETAIL',
  GET_FF_ORG_STRUCT_RESPONSE: 'GET_FF_ORG_STRUCT_RESPONSE',
  GET_FF_SEC_WEALTH_RESPONSE: 'GET_FF_SEC_WEALTH_RESPONSE',
  GET_FF_SEC_JOB_STATUS_RESPONSE: 'GET_FF_SEC_JOB_STATUS_RESPONSE',
  RESET_FF_TAB_DETAIL_VALUE: 'RESET_FF_TAB_DETAIL_VALUE',
  RESET_FF_SUB_TAB_DETAIL_VALUE: 'RESET_FF_SUB_TAB_DETAIL_VALUE',
  LOG_FF_DETAIL_REQUEST: 'LOG_FF_DETAIL_REQUEST',
  RESET_FF_LOOKUPDATA: 'RESET_FF_LOOKUPDATA',
  GET_FF_DETAIL_ERROR: 'GET_FF_DETAIL_ERROR',
  GET_FF_CONCURRENCY_ERROR: 'GET_FF_CONCURRENCY_ERROR',
  RESET_FF_CONCURRENCY_ERROR: 'RESET_FF_CONCURRENCY_ERROR',
  GET_FF_SERVER_DATE_REQUEST: 'GET_FF_SERVER_DATE_REQUEST',
  GET_FF_SERVER_DATE_RESPONSE: 'GET_FF_SERVER_DATE_RESPONSE',
  RESET_FF_SERVER_DATE_RESPONSE: 'RESET_FF_SERVER_DATE_RESPONSE',
  RESET_FF_DETAIL_ERROR: 'RESET_FF_DETAIL_ERROR',
  GET_FF_LIST_REQUEST: 'GET_FF_LIST_REQUEST',
  GET_FF_HEADER_REQUEST: 'GET_FF_HEADER_REQUEST',
  RESET_SERVER_VALUE: 'RESET_DEDUCTION_SERVER_VALUE',
  RESET_SERVER_VALUE_ITEM: 'RESET_SERVER_VALUE_ITEM',
  LOG_FF_LIST_REQUEST: 'LOG_FF_LIST_REQUEST',
  GET_FF_LIST_RESPONSE: 'GET_FF_LIST_RESPONSE',
  GET_FF_LIST_ERROR: 'GET_FF_LIST_ERROR',
  RESET_FF_STORE: 'RESET_FF_STORE',
  GET_FF_DEDUCTION_LIST: 'GET_FF_DEDUCTION_LIST',
  GET_TASK_LOOKUP: 'GET_TASK_LOOKUP',
  RESET_FF_TASK_LOOKUPDATA: 'RESET_FF_TASK_LOOKUPDATA',
  VALIDATE_DETAILS_CHANGE: 'VALIDATE_DETAILS_CHANGE',
  VALIDATE_DETAILS_CHANGE_RESPONSE: 'VALIDATE_DETAILS_CHANGE_RESPONSE',
  UPDATE_FF_COLUMN_CONFIG: 'UPDATE_FF_DEDUCTION_COLUMN_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_FF_DEDUCTION_TABLE_CONFIGURATION',
  UPDATE_FILTER_CONFIG: 'UPDATE_DEDUCTION_FILTER_CONFIG',
  GET_FF_EXPORT_REQUEST: 'GET_FF_EXPORT_REQUEST',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  GET_ASSOC_TASK_CNT: 'GET_ASSOC_TASK_CNT',
  GET_ASSOC_TASK_CNT_RES: 'GET_ASSOC_TASK_CNT_RES',
  INSERT_FF_TASK: 'INSERT_FF_TASK',
  UPDATE_FF_TASK: 'UPDATE_FF_TASK',
  GET_FF_DEDUCTION_EXPORT_REQUEST: 'GET_FF_DEDUCTION_EXPORT_REQUEST',
  SET_FF_GENERAL_CONCURRENCY: 'SET_FF_GENERAL_CONCURRENCY',

  GET_FF_TASK_LIST: 'GET_FF_TASK_LIST',
  GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST: 'GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST',
  GET_FF_ADD_EDIT_TASK_LOC_DETAILS: 'GET_FF_ADD_EDIT_TASK_LOC_DETAILS',
  VAL_FF_ADD_EDIT_TASK_LOC_DETAILS: 'VAL_FF_ADD_EDIT_TASK_LOC_DETAILS',
  VAL_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE: 'VAL_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE',
  GET_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE: 'GET_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE',
  GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST_RESPONSE: 'GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST_RESPONSE',
  REDEFAULT_FF_TASK: 'REDEFAULT_FF_TASK',
  SET_VALIDATE_LOCATION_ERR: 'SET_VALIDATE_LOCATION_ERR',
  REDEFAULT_FF_TASK_RESPONSE: 'REDEFAULT_FF_TASK_RESPONSE',
  GET_SYSOPT_HIER: 'GET_SYSOPT_HIER',
  GET_SYSOPT_HIER_RES: 'GET_SYSOPT_HIER_RES',

  GET_FF_HEADER_DETAIL_RESPONSE: 'GET_FF_HEADER_DETAIL_RESPONSE',
  GET_FF_DEDUCTION_LIST_RESPONSE: 'GET_FF_DEDUCTION_LIST_RESPONSE',
  GET_FF_FIN_ARRANGMENT_LIST_RESPONSE: 'GET_FF_FIN_ARRANGMENT_LIST_RESPONSE',
  GET_FF_DEDUCTION_DETAILS_RESPONSE: 'GET_FF_DEDUCTION_DETAILS_RESPONSE',
  RESET_FF_DETAIL_VALUE: 'RESET_FF_DETAIL_VALUE',

  GET_FF_TASK_LIST_RESPONSE: 'GET_FF_TASK_LIST_RESPONSE',
  GET_FF_TASK_DETAILS_RESPONSE: 'GET_FF_TASK_DETAILS_RESPONSE',

  RESET_FF_ADDRESS_BY_CLIENTID: 'RESET_FF_ADDRESS_BY_CLIENTID',
  SET_FF_ADDRESS_CONCURRENCY: 'SET_FF_ADDRESS_CONCURRENCY',
  GET_FF_TAB_DETAIL_ERROR: 'GET_FF_TAB_DETAIL_ERROR',
  UPDATE_FF_ADDRESS_REQUEST: 'UPDATE_FF_ADDRESS_REQUEST',
  GET_FF_HEADER_DETAIL_ERROR: 'GET_FF_HEADER_DETAIL_ERROR',
  GET_FF_TRAN_DETAILS: 'GET_FF_TRAN_DETAILS',
  GET_FF_TRAN_DETAILS_RESPONSE: 'GET_FF_TRAN_DETAILS_RESPONSE',
  GET_FF_ADDRESS_DETAIL_REQUEST: 'GET_FF_ADDRESS_DETAIL_REQUEST',
  GET_FF_ADDRESS_DETAIL_RESPONSE: 'GET_FF_ADDRESS_DETAIL_RESPONSE',
  GET_FF_ADDRESS_BY_CLIENTID_REQUEST: 'GET_FF_ADDRESS_BY_CLIENTID_REQUEST',
  GET_FF_ADDRESS_BY_CLIENTID_RESPONSE: 'GET_FF_ADDRESS_BY_CLIENTID_RESPONSE',
  INSERT_FIELDFORCE_DEDUCTION: 'INSERT_FIELDFORCE_DEDUCTION',
  UPDATE_FIELDFORCE_DEDUCTION: 'UPDATE_FIELDFORCE_DEDUCTION',
  GET_FF_DEDUCTION_ITEM_BY_ID: 'GET_FF_DEDUCTION_ITEM_BY_ID',
  GET_FF_DEDUCTION_BY_ID_RESPONSE: 'GET_FF_DEDUCTION_BY_ID_RESPONSE',
  GET_FF_DEDUCTION_DETAIL_TAB_ERROR: 'GET_FF_DEDUCTION_DETAIL_TAB_ERROR',
  RESET_FF_DEDUCTION_BY_ID_NAME: 'RESET_FF_DEDUCTION_BY_ID_NAME',
  GET_FF_DEDUCTION_BY_ID_RESPONSE_RESET: 'GET_FF_DEDUCTION_BY_ID_RESPONSE_RESET',
  GET_FF_DEDUCTION_BY_ID_NAME_RESET: 'GET_FF_DEDUCTION_BY_ID_NAME_RESET',
  DELETE_DEDUCTION_REQUEST: 'DELETE_DEDUCTION_REQUEST',
  GET_FF_TRAN_DETAIL_BY_ID_RESPONSE_RESET: 'GET_FF_TRAN_DETAIL_BY_ID_RESPONSE_RESET',
  GET_FF_DEDUCTION_ACTIVE: 'GET_FF_DEDUCTION_ACTIVE',
  GET_FF_DEDUCTION_NAME: 'GET_FF_DEDUCTION_NAME',
  GET_FF_DEDUCTION_BY_ID_NAME: 'GET_FF_DEDUCTION_BY_ID_NAME',
  GET_FF_TRANSACTION_LIST: 'GET_FF_TRANSACTION_LIST',
  GET_FF_TRANSACTION_LOOKUP: 'GET_FF_TRANSACTION_LOOKUP',
  FF_UPSERT_VALUE: 'FF_UPSERT_VALUE',
  FF_UPSERT_VALUE_RESET: 'FF_UPSERT_VALUE_RESET',
  DEDUCTION_ACTIVE_VALUE: 'DEDUCTION_ACTIVE_VALUE',

  INSERT_FIELDFORCE_TASK: 'INSERT_FIELDFORCE_TASK',
  UPDATE_FIELDFORCE_TASK: 'UPDATE_FIELDFORCE_TASK',
  GET_FF_TASK_ITEM_BY_ID: 'GET_FF_TASK_ITEM_BY_ID',
  SET_FF_TASK_BY_ID_RESPONSE: 'SET_FF_TASK_BY_ID_RESPONSE',
  GET_FF_TASK_DETAIL_TAB_ERROR: 'GET_FF_TASK_DETAIL_TAB_ERROR',
  DELETE_FF_TASK_REQUEST: 'DELETE_FF_TASK_REQUEST',
  GET_FF_TASK_EXPORT_REQUEST: 'GET_FF_TASK_EXPORT_REQUEST',
  SET_FF_TASK_CONCURRENCY: 'SET_FF_TASK_CONCURRENCY',
  GET_FF_TASK_CONCURRENCY_RES: 'GET_FF_TASK_CONCURRENCY_RES',

  GET_FF_DETAIL_BY_ID_REQUEST: 'GET_FF_DETAIL_BY_ID_REQUEST',
  GET_FF_DETAIL_BY_ID_RESPONSE: 'GET_FF_DETAIL_BY_ID_RESPONSE',
  GET_FF_ADDRESS_LOOKUP_P_REQUEST: 'GET_FF_ADDRESS_LOOKUP_P_REQUEST',
  GET_FF_ADDRESS_LOOKUP_P_RESPONSE: 'GET_FF_ADDRESS_LOOKUP_P_RESPONSE',
  GET_FF_ADDRESS_LOOKUP_S_REQUEST: 'GET_FF_ADDRESS_LOOKUP_S_REQUEST',
  GET_FF_ADDRESS_LOOKUP_S_RESPONSE: 'GET_FF_ADDRESS_LOOKUP_S_RESPONSE',
  GET_FF_DETAIL_BY_ID_RESPONSE_RESET: 'GET_FF_DETAIL_BY_ID_RESPONSE_RESET',
  // Financial (Banking)
  GET_FF_BANKING_DETAIL_REQUEST: 'GET_FF_BANKING_DETAIL_REQUEST',
  GET_FF_BANKING_DETAIL_RESPONSE: 'GET_FF_BANKING_DETAIL_RESPONSE',
  UPDATE_FF_BANKING_REQUEST: 'UPDATE_FF_BANKING_REQUEST',
  UPDATE_FF_BANKING_RESPONSE: 'UPDATE_FF_BANKING_RESPONSE',
  UPDATE_FF_BANKING_ERROR: 'UPDATE_FF_BANKING_ERROR',
  GET_FF_BANKING_ORS_PROPAY_REQUEST: 'GET_FF_BANKING_ORS_PROPAY_REQUEST',
  GET_FF_BANKING_ORS_PROPAY_RESPONSE: 'GET_FF_BANKING_ORS_PROPAY_RESPONSE',
  GET_FF_BANKING_PAYEE_STATUS_REQUEST: 'GET_FF_BANKING_PAYEE_STATUS_REQUEST',
  GET_FF_BANKING_PAYEE_STATUS_RESPONSE: 'GET_FF_BANKING_PAYEE_STATUS_RESPONSE',
  GET_FF_BANKING_PAYEE_CODE_REQUEST: 'GET_FF_BANKING_PAYEE_CODE_REQUEST',
  GET_FF_BANKING_PAYEE_CODE_RESPONSE: 'GET_FF_BANKING_PAYEE_CODE_RESPONSE',
  RESET_FF_BANKING_PAYEE_CODE: 'RESET_FF_BANKING_PAYEE_CODE',
  GET_FF_BANKING_PAYEE_NAME_REQUEST: 'GET_FF_BANKING_PAYEE_NAME_REQUEST',
  GET_FF_BANKING_PAYEE_NAME_RESPONSE: 'GET_FF_BANKING_PAYEE_NAME_RESPONSE',
  RESET_FF_BANKING_PAYEE_NAME: 'RESET_FF_BANKING_PAYEE_NAME',
  GET_FF_BANKING_BRANCH_NAME_REQUEST: 'GET_FF_BANKING_BRANCH_NAME_REQUEST',
  GET_FF_BANKING_BRANCH_NAME_RESPONSE: 'GET_FF_BANKING_BRANCH_NAME_RESPONSE',
  RESET_FF_BANKING_BRANCH_NAME: 'RESET_FF_BANKING_BRANCH_NAME',
  GET_FF_BANKING_SYSTEM_OPTIONS_REQUEST: 'GET_FF_BANKING_SYSTEM_OPTIONS_REQUEST',
  GET_FF_BANKING_SYSTEM_OPTIONS_RESPONSE: 'GET_FF_BANKING_SYSTEM_OPTIONS_RESPONSE',
  GET_FF_BANKING_ADDRESS_REQUEST: 'GET_FF_BANKING_ADDRESS_REQUEST',
  GET_FF_BANKING_ADDRESS_RESPONSE: 'GET_FF_BANKING_ADDRESS_RESPONSE',
  SET_FF_BANKING_CONCURRENCY: 'SET_FF_BANKING_CONCURRENCY',
  // FF MAT
  GET_FF_MAT_LIST: 'GET_FF_MAT_LIST',
  GET_FF_MAT_LIST_RESPONSE: 'GET_FF_MAT_LIST_RESPONSE',
  RESET_FF_MAT_LIST_RESPONSE: 'RESET_FF_MAT_LIST_RESPONSE',
  GET_FF_MAT_TRANSFER_ITEMS: 'GET_FF_MAT_TRANSFER_ITEMS',
  FF_TRANSFER_SUCCESS: 'FF_TRANSFER_SUCCESS',
  FF_TRANSFER_ERROR: 'FF_TRANSFER_ERROR',
  CLEAR_MASS_TRANSFER_SUCCESS: 'CLEAR_MASS_TRANSFER_SUCCESS',
  UPDATE_MAT_TABLE_CONFIGURATION: 'UPDATE_MAT_TABLE_CONFIGURATION',
  SET_FF_MAT_CONCURRENCY: 'SET_FF_MAT_CONCURRENCY',

  // Notes
  GET_FF_NOTES_DETAIL_REQUEST: 'GET_FF_NOTES_DETAIL_REQUEST',
  GET_FF_NOTES_DETAIL_RESPONSE: 'GET_FF_NOTES_DETAIL_RESPONSE',
  GET_FF_NOTES_DETAIL_BY_ID_REQUEST: 'GET_FF_NOTES_DETAIL_BY_ID_REQUEST',
  GET_FF_NOTES_DETAIL_BY_ID_RESPONSE: 'GET_FF_NOTES_DETAIL_BY_ID_RESPONSE',
  INSERT_FF_NOTES_REQUEST: 'INSERT_FF_NOTES_REQUEST',
  INSERT_FF_NOTES_RESPONSE: 'INSERT_FF_NOTES_RESPONSE',
  INSERT_FF_NOTES_ERROR: 'INSERT_FF_NOTES_ERROR',
  UPDATE_FF_NOTES_REQUEST: 'UPDATE_FF_NOTES_REQUEST',
  UPDATE_FF_NOTES_RESPONSE: 'UPDATE_FF_NOTES_RESPONSE',
  UPDATE_FF_NOTES_ERROR: 'UPDATE_FF_NOTES_ERROR',
  DELETE_FF_NOTES_REQUEST: 'DELETE_FF_NOTES_REQUEST',
  DELETE_FF_NOTES_RESPONSE: 'DELETE_FF_NOTES_RESPONSE',
  DELETE_FF_NOTES_ERROR: 'DELETE_FF_NOTES_ERROR',
  EXPORT_FF_NOTES_REQUEST: 'EXPORT_FF_NOTES_REQUEST',
  SET_FF_NOTES_CONCURRENCY: 'SET_FF_NOTES_CONCURRENCY',

  // FF Balance Period

  GET_FFBAL_ITEM_BY_PERIOD_RESPONSE: 'GET_FFBAL_ITEM_BY_PERIOD_RESPONSE',
  GET_FFPROD_ITEM_BY_MONTH_RESPONSE: 'GET_FFPROD_ITEM_BY_MONTH_RESPONSE',
  GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE: 'GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE',
  GET_FFPROD_ITEM_BY_YTD_RESPONSE: 'GET_FFPROD_ITEM_BY_YTD_RESPONSE',
  GET_FFBAL_MONTHLY_LIST_REQUEST: 'GET_FFBAL_MONTHLY_LIST_REQUEST',
  GET_FFBAL_MONTHLY_LIST_RESPONSE: 'GET_FFBAL_MONTHLY_LIST_RESPONSE',
  GET_FFBAL_SUMMARY_REQUEST: 'GET_FFBAL_SUMMARY_REQUEST',
  GET_FFBAL_SUMMARY_LIST_RESPONSE: 'GET_FFBAL_SUMMARY_LIST_RESPONSE',
  GET_FFBAL_SUMMARY_LIST_REQUEST: 'GET_FFBAL_SUMMARY_LIST_REQUEST',
  GET_FFBAL_HIERARCHY_REQUEST: 'GET_FFBAL_HIERARCHY_REQUEST',
  GET_FFBAL_PERIOD_LIST_REQUEST: 'GET_FFBAL_PERIOD_LIST_REQUEST',
  GET_FFBAL_PERIOD_LIST_RESPONSE: 'GET_FFBAL_PERIOD_LIST_RESPONSE',
  GET_FFBAL_ITEM_BY_PERIOD: 'GET_FFBAL_ITEM_BY_PERIOD',
  GET_FFPROD_ITEM_BY_MONTH: 'GET_FFPROD_ITEM_BY_MONTH',
  GET_FFBAL_ITEM_BY_PERIOD_YTD: 'GET_FFBAL_ITEM_BY_PERIOD_YTD',
  GET_FFPROD_ITEM_BY_YTD: 'GET_FFPROD_ITEM_BY_YTD',
  GETLOOKUPS: 'GETLOOKUPS',
  GET_FFBAL_HIERARCHY_LIST_RESPONSE: 'GET_FFBAL_HIERARCHY_LIST_RESPONSE',
  GET_FFBAL_HIERARCHY_LIST_REQUEST: 'GET_FFBAL_HIERARCHY_LIST_REQUEST',
  GET_FFPROD_BUIS_LIST_REQUEST: 'GET_FFPROD_BUIS_LIST_REQUEST',
  GET_FFPROD_BUIS_LIST_RESPONSE: 'GET_FFPROD_BUIS_LIST_RESPONSE',
  GET_FFPROD_HIER_LIST_REQUEST: 'GET_FFPROD_HIER_LIST_REQUEST',
  GET_FFPROD_HIER_LWR_LIST_REQUEST: 'GET_FFPROD_HIER_LWR_LIST_REQUEST',
  GET_FFPROD_PROD_LIST_REQUEST: 'GET_FFPROD_PROD_LIST_REQUEST',
  GET_FFPROD_MONTHCOMP_LIST_REQUEST: 'GET_FFPROD_MONTHCOMP_LIST_REQUEST',
  GET_SYSOPTS_REQUEST: 'GET_SYSOPTS_REQUEST',
  GETLKUPSMNTHYR: 'GETLKUPSMNTHYR',
  GET_FFPROD_BUIS_EXPORT_REQUEST: 'GET_FFPROD_BUIS_EXPORT_REQUEST',
  GET_FFPROD_HIER_EXPORT_REQUEST: 'GET_FFPROD_HIER_EXPORT_REQUEST',
  GET_FFPROD_PROD_EXPORT_REQUEST: 'GET_FFPROD_PROD_EXPORT_REQUEST',
  GET_FFPROD_MONTHCOMP_EXPORT_REQUEST: 'GET_FFPROD_MONTHCOMP_EXPORT_REQUEST',
  GET_FFBAL_PERIODMONTH_EXPORT_REQUEST: 'GET_FFBAL_PERIODMONTH_EXPORT_REQUEST',
  GET_FFBAL_SUMMARY_EXPORT_REQUEST: 'GET_FFBAL_SUMMARY_EXPORT_REQUEST',
  GET_FFBAL_HIERARCHY_EXPORT_REQUEST: 'GET_FFBAL_HIERARCHY_EXPORT_REQUEST',
  EXPORT_PRODHIER: 'EXPORT_PRODHIER',

  // Administration
  GET_FF_ADMIN_DEBTREF_REQUEST: 'GET_FF_ADMIN_DEBTREF_REQUEST',
  GET_FF_ADMIN_DEBTREF_RESPONSE: 'GET_FF_ADMIN_DEBTREF_RESPONSE',
  SET_FF_ADMIN_CONCURRENCY: 'SET_FF_ADMIN_CONCURRENCY',
  GET_FF_ADMIN_DETAIL_REQUEST: 'GET_FF_ADMIN_DETAIL_REQUEST',
  GET_FF_ADMIN_DETAIL_RESPONSE: 'GET_FF_ADMIN_DETAIL_RESPONSE',
  UPDATE_FF_ADMIN_REQUEST: 'UPDATE_FF_ADMIN_REQUEST',
  UPDATE_FF_ADMIN_RESPONSE: 'UPDATE_FF_ADMIN_RESPONSE',
  UPDATE_FF_ADMIN_ERROR: 'UPDATE_FF_ADMIN_ERROR',
  // Delete GEN EDIT TYPE
  DELETE_CLIENT_NOTES__REQUEST: 'DELETE_CLIENT_NOTES__REQUEST',
  DELETE_CLIENT_NOTES__RESPONSE: 'DELETE_CLIENT_NOTES__RESPONSE',
  DELETE_CLIENT_NOTES__ERROR: 'DELETE_CLIENT_NOTES__ERROR',
  // Delete client license
  STATUS_FF_REQUEST: 'STATUS_FF_REQUEST',
  STATUS_FF_RESPONSE: 'STATUS_FF_RESPONSE',
  STATUS_FF_ERROR: 'STATUS_FF_ERROR',
  DELETE_FF_REQUEST: 'DELETE_FF_REQUEST',
  DELETE_FF_RESPONSE: 'DELETE_FF_RESPONSE',
  DELETE_FF_ERROR: 'DELETE_FF_ERROR',

  // REQUIREMENT

  INSERT_FF_REQUIREMENT_REQUEST: 'INSERT_FF_REQUIREMENT_REQUEST',
  UPDATE_FF_REQUIREMENT_REQUEST: 'UPDATE_FF_REQUIREMENT_REQUEST',
  DELETE_FF_REQUIREMENT_REQUEST: 'DELETE_FF_REQUIREMENT_REQUEST',
  GET_FF_REQUIREMENT_LIST_REQUEST: 'GET_FF_REQUIREMENT_LIST_REQUEST',
  GET_FF_REQUIREMENT_LIST_BY_ID_REQUEST: 'GET_FF_REQUIREMENT_LIST_BY_ID_REQUEST',
  GET_FF_REQUIREMENT_TABLE_RES: 'GET_FF_REQUIREMENT_TABLE_RES',
  GET_FF_REQUIREMENT_TABLE_COMMENT: 'GET_FF_REQUIREMENT_TABLE_COMMENT',
  GET_FF_REQUIREMENT_LOOK_UP: 'GET_FF_REQUIREMENT_LOOK_UP',
  GET_FF_REQUIREMENT_LOOK_UP_DATA: 'GET_FF_REQUIREMENT_LOOK_UP_DATA',
  FF_REQUIREMENT_SAVE_COMMENT: 'FF_REQUIREMENT_SAVE_COMMENT',
  FF_SUCCESS_SAVE_COMMENT: 'FF_SUCCESS_SAVE_COMMENT',
  FF_CLEAR_SAVE_COMMENT: 'FF_CLEAR_SAVE_COMMENT',
  FF_CLEAR_NOTIFY_STATUS: 'FF_CLEAR_NOTIFY_STATUS',
  NOTIFY_NOW_REQUIREMENT_REQUEST: 'NOTIFY_NOW_REQUIREMENT_REQUEST',
  NOTIFY_LATER_REQUIREMENT_REQUEST: 'NOTIFY_LATER_REQUIREMENT_REQUEST',
  NOTIFY_SUCCESS_STATUS: 'NOTIFY_SUCCESS_STATUS',
  SET_FF_REQUIREMENT_CONCURRENCY: 'SET_FF_REQUIREMENT_CONCURRENCY',
};

export const fieldForceRelationshipActions = {
  // FF Relationship
  GET_FF_REL_LIST: 'GET_FF_REL_LIST',
  GET_FF_REL_LIST_RESPONSE: 'GET_FF_REL_LIST_RESPONSE',
  INSERT_FF_REL_REQUEST: 'INSERT_FF_REL_REQUEST',
  UPDATE_FF_REL_REQUEST: 'UPDATE_FF_REL_REQUEST',
  DELETE_FF_REL_REQUEST: 'DELETE_FF_REL_REQUEST',
  GET_FF_REL_ADVISOR_NAME: 'GET_FF_REL_ADVISOR_NAME',
  SET_FF_REL_ADVISOR_NAME: 'SET_FF_REL_ADVISOR_NAME',
  GET_FF_REL_EXPORT_REQUEST: 'GET_FF_REL_EXPORT_REQUEST',
  SET_FF_REL_CONCURRENCY: 'SET_FF_REL_CONCURRENCY',
  GET_FF_REL_BY_ID_RESPONSE: 'GET_FF_REL_BY_ID_RESPONSE',
  GET_FF_REL_DETAIL_TAB_ERROR: 'GET_FF_REL_DETAIL_TAB_ERROR',
};
