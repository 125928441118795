import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '@manulife/mux';
import './fasatTextArea.scss';
import FasatFormErrors from '../fasatFormErrors/fasatFormErrors';

/**
   * Changes the value of the current element
   * @param {String} newValue value
   * @param {String} e current event
   */
const setChangeValue = ({ newValue, e, inLineLength, maxLine, setValues, onHandleChange, onChange }) => {
  if (inLineLength) {
    const lines = newValue.split('\n');
    for (let i = 0; i < lines.length; i += 1) {
      if (lines[i].length > inLineLength) {
        lines[i] = lines[i].substring(0, inLineLength);
      }
    }
    if (maxLine) {
      while (lines.length > maxLine) {
        lines.pop();
      }
    }
    setValues(lines.join('\n'));
    onHandleChange(lines.join('\n'));
  } else {
    setValues(newValue);
    onChange(e);
  }
};

const FasatTextArea = (props) => {
  const { value, onHandleChange, errors, inLineLength, maxLine, onChange, name, label, ...rest } = props;
  const [values, setValues] = useState(value);
  useEffect(() => {
    setValues(value);
  }, [value]);

  /**
   * Handles blur event
   * @param {String} _val value
   * @param {String} ev event
   */

  const textInputClass = 'fasatTextArea';
  const fasatTextInputErrorClass = 'withError';

  /**
   * Updates error response
   * @returns error response
   */
  const updateError = () => {
    const errorsResponse = [];
    if (errors.length > 0 && errors[0].isError) {
      errorsResponse.push({ isError: true });
    }
    return errorsResponse;
  };
  return (
    <div
      className={`${textInputClass} ${
        errors.length > 0 && errors[0].isError ? fasatTextInputErrorClass : ''
      }`}
    >

      <TextArea
        {...rest}
        // id="textarea-playground"
        label={label}
        // maxLength={null}
        // placeholder="placeholder"
        // required
        // role="main"
        aria-multiline="true"
        tooltip=""
        value={values}
        name={name}
        onChange={(newValue, e) => setChangeValue({ newValue,
          e,
          inLineLength,
          maxLine,
          setValues,
          onHandleChange,
          onChange })}
        // onBlur={(val, ev) => onBlur && handleBlur(val, ev)}
        errors={updateError()}
        customStyle={{
          inputAreaStyle: {},
          maxLengthStyle: {},
          rootStyle: {
            width: '100%',
          },
        }}
      />
      {updateError().length > 0 && <FasatFormErrors name={name} errors={errors} />}
    </div>
  );
};

FasatTextArea.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  inLineLength: PropTypes.number,
  maxLine: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.string,
      isError: PropTypes.bool,
      msg: PropTypes.string,
    },
  )),
};

FasatTextArea.defaultProps = {
  value: '',
  label: '',
  name: '',
  inLineLength: 0,
  maxLine: 0,
  errors: [
    {
      id: '',
      isError: false,
      msg: '',
    },
  ],
};

export default FasatTextArea;
