/**
 * @Description Route configuartion for module activity
 * @FileName routeConfig.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:39:31
 * @IssueID NA
 */
import { lazy } from 'react';
import { routeConstant, aclKeysConstant } from '../moduleConstants';

export const ActivityHome = lazy(() => import(
  /* webpackChunkName:"ActivityHomeChunk" */ '../containers/home/home'
));
export const ActivityProcess = lazy(() => import(
  /* webpackChunkName:"ActivityDailyProcessList" */ '../containers/activityProcessedList/activityProcessedList'
));

export const ActivityProcessExt = lazy(() => import(
  /* webpackChunkName:"AgencyDailyProcessList" */ '../containers/activityProcessedList/activityProcessedListExt'
));
export const ActivityUnProcess = lazy(() => import(
  /* webpackChunkName:"ActivityDailyProcessList" */ '../containers/activityUnProcessedList/activityUnProcessedList'
));
export const FPAMonitoring = lazy(() => import(
  /* webpackChunkName:"ActivityDailyProcessList" */ '../containers/fpaMonitoring/fpaMonitoringList'
));

export const BatchNumberDetail = lazy(() => import(
  /* webpackChunkName:"ActivityBatchNumberDetail" */ '../containers/BatchNumberDetail/batchNumberDetail'
));

export const AdjXErrorRecordDetail = lazy(() => import(
  /* webpackChunkName:"ActivityAdjXREcordDEtail" */ '../containers/AdjustmentXError/adjXErrorDetail'
));

export const routeConfig = [
  {
    path: routeConstant.HOME,
    exact: true,
    component: ActivityHome,
    id: 'ActivityHome',
    title: 'FASAT - Activity Home',
    pageName: 'FASAT - Activity Home', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.ACTIVITY_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.ACTIVITY_PROCESS_ROUTE,
    exact: true,
    component: ActivityProcess,
    id: 'ActivityProcess',
    title: 'FASAT - Activity Process',
    pageName: 'FASAT - Activity Process', // page name to send in error message log service
    authenticationRequired: false,
    aclKey: [aclKeysConstant.RBAC_ACTVTP_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.ACTIVITY_PROCESS_EXT_ROUTE,
    exact: true,
    component: ActivityProcessExt,
    id: 'ActivityProcessExt',
    title: 'FASAT - Activity Process Ext',
    pageName: 'FASAT - Activity Process Ext', // page name to send in error message log service
    authenticationRequired: false,
    aclKey: [aclKeysConstant.RBAC_EAP_MENU],
    acl: true,
    showInBatchProcess: true,
    public: false,
  },
  {
    path: routeConstant.ACTIVITY_UNPROCESS_ROUTE,
    exact: true,
    component: ActivityUnProcess,
    id: 'ActivityUnProcess',
    title: 'FASAT - Activity UnProcess',
    pageName: 'FASAT - Activity UnProcess', // page name to send in error message log service
    authenticationRequired: false,
    aclKey: [aclKeysConstant.RBAC_AUP_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FPA_MONITORING_ROUTE,
    exact: true,
    component: FPAMonitoring,
    id: 'FPAMonitoring',
    title: 'FASAT - FPA Monitorings',
    pageName: 'FASAT - FPA Monitoring', // page name to send in error message log service
    authenticationRequired: false,
    aclKey: [aclKeysConstant.RBAC_AUP_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.BATCH_NUMBER_ROUTE,
    exact: true,
    component: BatchNumberDetail,
    id: 'AactivityBatchNumberDetail',
    title: 'FASAT - Business card detail',
    pageName: 'FASAT - Business card detail', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_AUP_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.ADJUSTMENT_XERROR_ROUTE,
    exact: true,
    component: AdjXErrorRecordDetail,
    id: 'AactivityAdjBatchNoDetail',
    title: 'FASAT - Activity Batch No Detail',
    pageName: 'FASAT - Activity Batch No Detail', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_AUP_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
];
export default routeConfig;
