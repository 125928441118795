/* eslint-disable no-unused-vars */
/**
 * @Description Container for batch export component
 * @FileName index.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 16 June, 2021 02:47:30
 * @IssueID 4777, 5033
 */

import { H2 } from '@manulife/mux';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import { generateTableTextColumn, getFasatGrid, getFasatGridBar } from '../../../../modules/moduleUtility';
import { actionBodyGenerator, FasatMessageCard, TableDefinition } from '../../../components';
import { getCurrentLocale } from '../../../i18n';
import { globalConstants, staticCommonLabelKeys } from '../../../moduleConstants';
import { commonTypes } from '../../../redux/actions/commonActions';
import { COLUMN_TYPE, getValueFromLocalStorage, initialReqObject,
  Logger, pageChngRequestObj, STORAGE_KEY,
  onColumnResizeHandler, onSortByHandler, onFilterHandler } from '../../../util';
import './scss/style.scss';
import { check } from '../../../../modules/agency/components/addEditDeductionRule/addEditDeductionRuleConfig';

const fileName = 'jobOutputFileName';
const reqDateTime = 'requestedOnDt';
const fileType = 'fileType';
const COMPLETED = 'COMPLETED';
const SIXT = 60;
const THOUSAND = 1000;
const intervalVal = parseInt(getValueFromLocalStorage(STORAGE_KEY.DOWNLOAD_CENTRE_REFRESH_INTERVAL_ONLINE), 10) || SIXT;
const defaultColumnOrder = [fileName, fileType, reqDateTime];

export const getTableData = ({ listData }) => {
  const currentPage = listData?.page || 1;
  const totalRows = listData?.totalRows || 0;
  const rowCount = listData && listData.data ? listData.data.length : 0;
  const GridPageSize = globalConstants.pageSizeTen;
  return { currentPage, totalRows, rowCount, GridPageSize };
};

const defaultColumnOrderTable = [
  {
    actions: [],
    body: null,
    columnType: 'name',
    dataField: fileName,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_FILENAME,
    id: fileName,
    isAction: false,
    visibility: fileName,
    width: 300,
    sortAscending: fileName,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: '',
    isSearchable: false,
  },
  {
    actions: [],
    body: null,
    columnType: 'date',
    dataField: reqDateTime,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_REQUESTDATE,
    headerUnbold: staticCommonLabelKeys.COMMON_DATE_FORMAT_MM_DD_YYYY,
    id: reqDateTime,
    isAction: false,
    visibility: reqDateTime,
    width: 300,
    sortAscending: reqDateTime,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: '',
    isSearchable: false,
  },
  {
    actions: [],
    body: null,
    columnType: 'type',
    dataField: fileType,
    disableFilter: false,
    disableSortBy: true,
    header: staticCommonLabelKeys.COMMON_LABEL_FILE_FORMAT,
    id: fileType,
    isAction: false,
    visibility: fileType,
    width: 300,
    sortAscending: fileType,
    aclKey: 'aclKey',
    type: COLUMN_TYPE.TEXT,
    lookupKey: '',
    isSearchable: false,
  },
];
/**
  *
  * @param {*} actions
  * @param {*} dataFieldKey
  * @param {*} dataField
  * @returns pointing towards the new respective page
  */

export const dateFormatColumn = (_dataFieldKey, dataField, listData, t) => (rowData) => {
  const { cell } = rowData;
  const lstData = listData && listData.data ? listData.data : [];
  if (lstData && lstData.length > 0) {
    if (dataField === fileType) {
      const checkFileType = cell.row.original[dataField] === 0 ? `${t(staticCommonLabelKeys.COMMON_LABEL_EXCEL_XLS)}` : `${t(staticCommonLabelKeys.COMMON_LABEL_EXCEL_CSV)}`;
      return checkFileType;
    }
    if (cell.row.original.lastUpdatedStatusList !== COMPLETED && dataField === fileName) {
      return (
        <span className="textBold">
          {`${t(staticCommonLabelKeys.COMMON_LABEL_INPROGRESS)}... ${cell.row.original[dataField]}`}
        </span>
      );
    }
    if (cell.row.original.lastUpdatedStatusList !== COMPLETED) {
      return <span className="textBold">{cell.row.original[dataField]}</span>;
    }
    return cell.row.original[dataField];
  }
  return '';
};

/**
  * @param {Array} options
  * Set item for Delete BCR
  * @returns {Array}
  */
const generateDeleteSection = ({ dispatch }, deleteItems, reqObj) => {
  const reqOb = reqObj;
  reqOb.page = 1;
  const configDelData = {
    jobRequestId: deleteItems.jobRequestId,
    reqObj: reqOb,
  };
  dispatch(getAction(commonTypes.DELETE_BATCH_EXPORT_JOB_REQUEST, configDelData));
};

/**
  * Generate grid
  */
const generateTableDefination = ({
  dispatch,
  listData,
  columnOrder,
  sortOptions,
  reqObj,
  t,
}) => {
  const tableDef = new TableDefinition('batchExportList', 'batchExportListsID');
  generateTableTextColumn({ defaultColumnOrderTable,
    dispatch,
    tableDef,
    listData,
    columnOrder,
    sortOptions,
    t }, dateFormatColumn, listData);
  const actionColumn = {
    actions: [
      {
        actionHandler: (rowData) => {
          const configDelData = {
            jobRequestId: rowData.row.original.jobRequestId,
          };
          dispatch(getAction(commonTypes.DOWNLOAD_BATCH_EXPORT_JOB_REQ, configDelData));
        },
        className: 'pi pi-user-download',
        hoverIcon: 'downloadIconHover',
        icon: 'downloadIcon',
        id: 'download',
        name: 'Download',
        ariaLabel: t(staticCommonLabelKeys.ARIA_COMMON_BUTTON_DOWNLOAD),
        order: 1,
        batchDisable: true,
        batchlastUpdatedStatus: COMPLETED,
      },
      {
        actionHandler: (rowData, colData) => {
          Logger.info(`Delete Handler Column Data', ${colData}`);
          generateDeleteSection({ dispatch }, rowData.row.original, reqObj);
        },
        className: 'p-button-rounded p-button-warning',
        hoverIcon: 'deleteIconHover',
        icon: 'deleteIcon',
        id: 'delete',
        name: 'Deleted',
        ariaLabel: t(staticCommonLabelKeys.ARIA_COMMON_GRID_BUTTON_DELETE),
        order: 2,
        batchDisable: true,
        batchlastUpdatedStatus: COMPLETED,
      },
    ],
    columnType: 'action',
    dataField: null,
    id: 'action',
    isAction: true,
    visibility: true,
    disableSortBy: true,
    disableFilter: false,
    filterOptions: [],
    filterType: null,
    header: t(staticCommonLabelKeys.COMMON_LABEL_ACTIONS),
    width: 40,
  };
  actionColumn.body = actionBodyGenerator(
    actionColumn.actions,
    (action, rowData, columnData) => action && rowData && columnData,
  );
  tableDef.addColumn(actionColumn);
  tableDef.setColumnResizable();
  return tableDef;
};

const dispatchFetchList = (dispatch, reqObj) => {
  dispatch(getAction(commonTypes.GET_BATCH_EXPORT_DATA, reqObj));
};

const pageGridSize = (details, listData, setGridPageSize) => {
  if (details && !listData) {
    setGridPageSize(details.defaultPageSize);
  }
};

export const padTime = (time) => (String(time).length === 1 ? `0${time}` : `${time}`);

const fetchCurrentDateTime = (locale, refreshedDateTime) => {
  const currentDate = new Date(refreshedDateTime).getDate();
  const currentMonth = new Date(refreshedDateTime).toLocaleString(locale, { month: 'long' });
  const currentYear = new Date(refreshedDateTime).getFullYear();
  const refreshedHour = new Date(refreshedDateTime)
    .toLocaleString(locale, { hour: 'numeric', minute: 'numeric', hour12: false });
  const currentHour = new Date(refreshedDateTime).getHours(refreshedDateTime);
  const currentMinute = padTime(new Date(refreshedDateTime).getMinutes());
  if (locale === 'en') {
    return { refreshedDate: `${currentMonth} ${currentDate}, ${currentYear}`, refreshedHour };
  }
  return { refreshedDate: `${currentDate} ${currentMonth} ${currentYear}`,
    refreshedHour: `${currentHour}h${currentMinute}` };
};

export const fetchServerError = (trans, errItem) => (
  <div>
    {errItem.errorMessageId
      ? <div dangerouslySetInnerHTML={{ __html: trans(errItem.errorMessageId) }} />
      : <div dangerouslySetInnerHTML={{ __html: trans(errItem.errorMessage) }} />}
  </div>
);

const BatchExport = () => {
  const listData = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.batchExportData);
  const batchRequestErr = useSelector(({ ApplicationState }) => ApplicationState.batchRequestError);
  const [t] = useTranslation();
  const locale = getCurrentLocale();
  const { currentPage, totalRows, rowCount, GridPageSize } = getTableData({ listData });
  const [pageSize, setGridPageSize] = useState(GridPageSize);
  const dispatch = useDispatch();
  const columnOrder = defaultColumnOrder;
  const parameters = null;
  const sortOptions = [{ key: reqDateTime, asc: false }];
  const reqObj = initialReqObject({ locale, parameters, currentPage, pageSize, sortOptions });
  useEffect(() => dispatchFetchList(dispatch, reqObj), [locale]);
  useEffect(() => {
    if (batchRequestErr) {
      const errMsg = document.getElementById('msgCrdErr');
      errMsg.scrollIntoView();
    }
  }, [batchRequestErr]);
  useEffect(() => {
    pageGridSize(listData, setGridPageSize);
  }, [listData]);
  const paginationInfo = { currentPage, totalRows, rowCount, pageSize };
  const onPageChangeHandler = (currPage) => {
    reqObj.page = currPage;
    reqObj.pageSize = pageSize;
    dispatch(getAction(commonTypes.GET_BATCH_EXPORT_DATA, reqObj));
  };
  const onChangePageSize = (currentPageSize) => {
    setGridPageSize(currentPageSize);
    reqObj.pageSize = currentPageSize;
    dispatch(getAction(commonTypes.GET_BATCH_EXPORT_DATA, reqObj), pageChngRequestObj({ reqObj, currentPageSize }));
  };
  useEffect(() => {
    const intervalCall = setInterval(() => {
      reqObj.page = 1;
      dispatch(getAction(commonTypes.GET_BATCH_EXPORT_DATA, reqObj));
    }, (intervalVal * THOUSAND));
    return () => {
      clearInterval(intervalCall);
    };
  }, []);
  const listColumnOrder = [fileName, fileType, reqDateTime];
  const gb = { addAction: false, exportAction: false, resetAction: false };
  const g1 = { t, dispatch, columnOrder, listColumnOrder, sortOptions, generateTableDefination, reqObj, listData };
  const g2 = { ...g1, paginationInfo, onSortByHandler, onFilterHandler, activateDataGrid: true, onPageChangeHandler };
  const noOfDays = getValueFromLocalStorage(STORAGE_KEY.WEB_BATCH_EXPIRY_DAYS);
  return (
    <div className="fasatBatchExport f-align-left" data-testid="BatchExportComponent">
      <div className="beHeader">
        <div className="headerLabel">
          <H2>{ t(staticCommonLabelKeys.COMMON_HEADER_DOWNLOAD_CENTRE)}</H2>
        </div>
      </div>
      <div className="beSection">
        <FasatMessageCard
          titleText={t(staticCommonLabelKeys.COMMON_FILE_AUTODELETE_MSG, { noOfDays })}
          variant="primary_information"
        />
        {batchRequestErr && (
        <FasatMessageCard
          titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
          variant="primary_alert"
          id="msgCrdErr"
          hasCloseButton
        >
          {batchRequestErr && batchRequestErr.map((errItem) => (fetchServerError(t, errItem)))}
        </FasatMessageCard>
        )}
        {getFasatGridBar({ currentPage, onChangePageSize, totalRows, dispatch, pageSize, resetAction: true, ...gb })}
        <div className="batchExportGrid">
          {getFasatGrid({ ...g2, reqObj, onColumnResizeHandler },
            t(staticCommonLabelKeys.COMMON_HEADER_DOWNLOAD_CENTRE))}
        </div>
      </div>
    </div>
  );
};
BatchExport.propTypes = {};

BatchExport.defaultProps = {};

export default BatchExport;
