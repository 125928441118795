import adminResourceEnglish from '../../../modules/admin/i18n/adminResourceEnglish';
import agencyResourceEnglish from '../../../modules/agency/i18n/agencyResourceEnglish';
import activityResourcesEnglish from '../../../modules/activity/i18n/activityResourcesEnglish';
import english from '../english';

/**
 * @Description Resource file for english
 * @FileName enLang.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 15 January, 2021 05:06:13
 * @IssueID - 305
*/
const defaultMessage = {
  'default.message': 'Welcome...',
};

const enLang = {
  static: {
    ...defaultMessage,
    ...adminResourceEnglish,
    ...agencyResourceEnglish,
    ...activityResourcesEnglish,
    ...english,
  },
};
export default enLang;
