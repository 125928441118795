/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { systemParametersActionType } from '../actions/actionTypes';

import { formatResponse, exportErrorMsg, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

const getResetUpsertValue = (responseData, err) => ({
  upsertRequest: null,
  upsertRequestError: err ? processError(err) : null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: responseData ? new Date() : null,
  upsertData: responseData,
});

/**
  * Get System Parameters List
  * @param {any} action
  */
export function* getSysParamList(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(systemParametersActionType.RESET_SERVER_VALUE, null)),
    put(getAction(systemParametersActionType.LOG_SYSPARAM_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_SYSPARAM_LIST, action.data);
    yield all([
      put(getAction(systemParametersActionType.GET_SYSPARAM_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(systemParametersActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(systemParametersActionType.GET_SYSPARAM_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
  * Get System Parameters by id
  * @param {object} action
  */
export function* getSysParambyID(action) {
  Logger.verbose(`Action Detail For Get Sysparam by id : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(systemParametersActionType.RESET_SERVER_VALUE, null)),
    put(getAction(systemParametersActionType.LOG_SYSPARAM_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_SYSPARAM_BY_ID, action.data);
    yield all([
      put(getAction(systemParametersActionType.GET_SYSPARAM_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(systemParametersActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(systemParametersActionType.GET_SYSPARAM_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert for System Parameters
  */
export function* insertSysParam(action) {
  Logger.verbose(`Action Detail For Get Sys Param : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_SYSPARAM_INSERT,
      action.data.formatObject,
    );
    if (responseData) {
      resetUpsertValue = getResetUpsertValue(responseData, null);
      yield all([
        put(getAction(systemParametersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(systemParametersActionType.GET_SYSPARAM_LIST_REQUEST, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(systemParametersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
// updateSysParam
export function* updateSysParam(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_SYSPARAM_UPDATE,
      action.data.formatObject,
    );
    if (responseData) {
      resetUpsertValue = getResetUpsertValue(responseData, null);
      yield put(getAction(systemParametersActionType.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(
        getAction(systemParametersActionType.GET_SYSPARAM_LIST_REQUEST, action.data.reqObject),
      );
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(systemParametersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing System Parameter
  */
export function* deleteSysParam(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_SYSPARAM_DELETE,
      action.data.formatObject,
    );
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(systemParametersActionType.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(
        getAction(systemParametersActionType.GET_SYSPARAM_LIST_REQUEST, action.data.reqObject),
      );
    }
    yield all([put(processingCompleted())]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: err.response.data.errors,
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(systemParametersActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(systemParametersActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for SP Export Listing
 */
export function* getSPExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(systemParametersActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_SYSPARAM_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(systemParametersActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
function* moduleWatcher() {
  // system parameter
  yield takeEvery(systemParametersActionType.DELETE_SYSPARAM_REQUEST, deleteSysParam);
  yield takeEvery(systemParametersActionType.INSERT_SYSPARAM_REQUEST, insertSysParam);
  yield takeEvery(systemParametersActionType.GET_SYSPARAM_LIST_REQUEST, getSysParamList);
  yield takeEvery(systemParametersActionType.GET_SYSPARAM_BY_ID_REQUEST, getSysParambyID);
  yield takeEvery(systemParametersActionType.UPDATE_SYSPARAM_REQUEST, updateSysParam);
  yield takeEvery(systemParametersActionType.GET_SYSPARAM_EXPORT_REQUEST, getSPExportList);
}
export default moduleWatcher;
