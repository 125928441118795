/* eslint-disable import/named */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
// eslint-disable-line no-debugger
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FasatGrid, FasatGridBar } from '../common/components';
import FasatFilterSetting from '../common/components/fasatFilterSetting';
import { getCurrentLocale } from '../common/i18n';
import { getColumns, hasUserClaim, setColumnSettings, Logger } from '../common/util';
import { aclKeysConstant } from './agency/moduleConstants';

export const getFasatGridBar = ({ currentPage, pageSize, onChangePageSize, totalRows, activateExportButton, dispatch, setAddNew, setFormData, setDisplayModal, displayModal, filterConfig, defaultEntityFilterConfig, exportAclKey, generateAddEditSection, editAction, onClickEdit, addAction, addAclKey, addBtnAriaLabel, setCloseAddEditModal, approveAction, approveLabel, deleteAction, deleteLabel, importAction, resetAction, resetLabel, resetAriaLabel,
  onClickReset, importLabel, importEnabled, onClickApprove, onClickDelete, onClickImport, approveEnabled, deleteEnabled, showPageSize, listActive, exportAction, cpStore, deleteAclKey, approveAclKey, remitAclKey, cancelAclKey, importAclKey, reqObj, editAclKey, deleteAllAction, reverseAction, reprocessAction, reverseAclKey, onClickReverse, exportOpen, reprocessAclKey, onClickReprocess, activateReprocessButton, ffExport, processNowAction, onClickProcessNow, pageSizeID,
  processNowAclKey, activateAUPButton, changeStatusAction, changeStatusAclKey, externalExport, emitExport, cloneAction, onClickChangeStatus, changeStatusLabel, changeStatusEnabled, selectLetterAclKey, onClickSelectLetter, pageSizeOptions, selectLetterLabel, selectLetterEnabled, selectLetterAction, onClickRemit, onClickCancel, remitLabel, approvePayment, downloadBtn, printBtn, viewBtn, quickSearchParam, isQuickSearchFilter, pdDisableAdd, terminatedRecordBtnParams,
  isExtrnalUser, backBtnParams, exportBtnID, formRequest, onClickBulk, bulkAction, activateBulkButton }) => {
  const history = useHistory();
  const locale = getCurrentLocale();
  return (
    <>
      <FasatGridBar
        onClickAdd={() => generateAddEditSection({ dispatch, reqObj, setAddNew, setFormData, setDisplayModal, displayModal, history, setCloseAddEditModal, locale })}
        currentPage={currentPage}
        pageSize={pageSize}
        showPageSize={showPageSize}
        onChangePageSize={(val) => onChangePageSize(val)}
        totalRows={totalRows || 0}
        resetAction={resetAction}
        resetLabel={resetLabel}
        resetAriaLabel={resetAriaLabel}
        onClickReset={onClickReset}
        activateExportButton={activateExportButton}
        entityFilterConfig={filterConfig}
        defaultEntityFilterConfig={defaultEntityFilterConfig}
        addAclKey={addAclKey}
        exportAclKey={exportAclKey}
        editAction={editAction}
        onClickEdit={onClickEdit}
        addAction={addAction}
        pageSizeID={pageSizeID}
        addBtnAriaLabel={addBtnAriaLabel}
        approveAction={approveAction}
        approvePayment={approvePayment}
        approveLabel={approveLabel}
        approveEnabled={approveEnabled}
        deleteAction={deleteAction}
        deleteLabel={deleteLabel}
        deleteEnabled={deleteEnabled}
        importAction={importAction}
        importLabel={importLabel}
        importEnabled={importEnabled}
        onClickApprove={onClickApprove}
        onClickDelete={onClickDelete}
        onClickImport={onClickImport}
        exportAction={exportAction}
        listActive={listActive}
        cpStore={cpStore}
        editAclKey={editAclKey}
        deleteAclKey={deleteAclKey}
        approveAclKey={approveAclKey}
        remitAclKey={remitAclKey}
        cancelAclKey={cancelAclKey}
        importAclKey={importAclKey}
        pageSizeOptions={pageSizeOptions}
        deleteAllAction={deleteAllAction}
        reverseAction={reverseAction}
        reprocessAction={reprocessAction}
        reverseAclKey={reverseAclKey}
        onClickReverse={onClickReverse}
        reprocessAclKey={reprocessAclKey}
        onClickReprocess={onClickReprocess}
        activateReprocessButton={activateReprocessButton}
        processNowAction={processNowAction}
        onClickProcessNow={onClickProcessNow}
        processNowAclKey={processNowAclKey}
        activateAUPButton={activateAUPButton}
        ffExport={ffExport}
        changeStatusAction={changeStatusAction}
        changeStatusAclKey={changeStatusAclKey}
        onClickChangeStatus={onClickChangeStatus}
        changeStatusLabel={changeStatusLabel}
        changeStatusEnabled={changeStatusEnabled}
        selectLetterAclKey={selectLetterAclKey}
        onClickSelectLetter={onClickSelectLetter}
        selectLetterLabel={selectLetterLabel}
        selectLetterEnabled={selectLetterEnabled}
        selectLetterAction={selectLetterAction}
        onClickRemit={onClickRemit}
        onClickCancel={onClickCancel}
        remitLabel={remitLabel}
        downloadBtn={downloadBtn}
        printBtn={printBtn}
        externalExport={externalExport}
        emitExport={emitExport}
        exportOpen={exportOpen}
        viewBtn={viewBtn}
        quickSearchParam={quickSearchParam}
        isQuickSearchFilter={isQuickSearchFilter}
        pdDisableAdd={pdDisableAdd}
        terminatedRecordBtnParams={terminatedRecordBtnParams}
        isExtrnalUser={isExtrnalUser}
        backBtnParams={backBtnParams}
        exportBtnID={exportBtnID}
        formRequest={formRequest}
        onClickBulk={onClickBulk}
        bulkAction={bulkAction}
        activateBulkButton={activateBulkButton}
        cloneAction={cloneAction}
      />
    </>
  );
};

/**
 * FASAT GRID RELATION BAR
 * @param {*} params
 * @returns
 */
export const getFasatGridRelBar = ({
  // parentType,
  currentPage,
  pageSize,
  getObj,
  onChangePageSize,
  totalRows,
  activateExportButton,
  dispatch,
  setAddNew,
  setFormData,
  setDisplayModal,
  displayModal,
  filterConfig,
  exportFileName,
  relationshipParameter,
  generateAddEditSection,
  exportAclKey,
  addAclKey,
  addAction,
  pageSizeID,
  addBtnAriaLabel,
  setCloseAddEditModal,
  approveAction,
  approvePayment,
  approveLabel,
  approveEnabled,
  deleteAction,
  resetAction, resetLabel, resetAriaLabel, onClickReset,
  deleteLabel,
  deleteEnabled,
  importAction,
  importLabel,
  importEnabled,
  onClickApprove,
  onClickEdit,
  onClickDelete,
  listActive,
  pageSizeOptions,
  onClickRemit,
}) => (
  <>
    <FasatGridBar
      onClickAdd={() => generateAddEditSection({ dispatch,
        setAddNew,
        setFormData,
        setDisplayModal,
        displayModal,
        getObj,
        setCloseAddEditModal })}
      onClickEdit={onClickEdit}
      currentPage={currentPage}
      pageSize={pageSize}
      onChangePageSize={(val) => onChangePageSize(val)}
      totalRows={totalRows || 0}
      resetAction={resetAction}
      resetLabel={resetLabel}
      resetAriaLabel={resetAriaLabel}
      onClickReset={onClickReset}
      activateExportButton={activateExportButton}
      entityFilterConfig={filterConfig}
      addAclKey={addAclKey}
      exportAclKey={exportAclKey}
      deleteAclKey={aclKeysConstant.RBAC_TA_DELETE}
      addAction={addAction}
      pageSizeID={pageSizeID}
      relationshipParameter={relationshipParameter}
      exportFileName={exportFileName}
      addBtnAriaLabel={addBtnAriaLabel}
      approveAction={approveAction}
      approvePayment={approvePayment}
      approveLabel={approveLabel}
      approveEnabled={approveEnabled}
      deleteAction={deleteAction}
      deleteLabel={deleteLabel}
      deleteEnabled={deleteEnabled}
      importAction={importAction}
      importLabel={importLabel}
      importEnabled={importEnabled}
      onClickApprove={onClickApprove}
      onClickDelete={onClickDelete}
      onClickRemit={onClickRemit}
      listActive={listActive}
      pageSizeOptions={pageSizeOptions}
    />
  </>
);

/**
 * FILTER SETTING FOR RELATIONSHIP
 * @param {*} params
 * @returns
 */
export const getPreFasatFilterSetting = ({
  t,
  setAddNewBCR,
  parentType,
  dispatch,
  listData,
  getObj,
  columnOrder,
  sortOptions,
  setFormData,
  setDisplayModal,
  displayModal,
  setdisplayDeleteModal,
  displayDeleteModal,
  pageSize,
  lookupData,
  triggerActivateDataGrid,
  generateTableDefination,
  setpVTitle,
  setformDateGevParent,
  setdisplayParentModal,
  displayParentModal,
  filterConfig,
  screenName,
  entityId,
  setHasRelationships,
  freezeColumn,
  paramForFreezeColumn,
  setCloseAddEditModal,
  listActive,
  activateDataGrid,
  formRequest,
},
temporaryFilterConfig = false) => (
  <FasatFilterSetting
    availableColumns={getColumns(
      generateTableDefination({
        t,
        setAddNewBCR,
        parentType,
        dispatch,
        listData,
        getObj,
        columnOrder,
        sortOptions,
        setFormData,
        setDisplayModal,
        setpVTitle,
        setformDateGevParent,
        setdisplayParentModal,
        displayParentModal,
        displayModal,
        setdisplayDeleteModal,
        displayDeleteModal,
        setHasRelationships,
        setCloseAddEditModal,
        formRequest,
      }),
    )}
    entityId={entityId}
    customPageSize={pageSize}
    entityFilterConfig={filterConfig}
    lookupData={lookupData}
    triggerActivateDataGrid={() => triggerActivateDataGrid()}
    screenName={t(screenName)}
    loadPreSelectedFilterConfig={temporaryFilterConfig}
    freezeColumn={freezeColumn}
    paramForFreezeColumn={paramForFreezeColumn}
    listActive={listActive}
    activateDataGrid={activateDataGrid}
  />
);

export const getFasatFilterSetting = ({
  t,
  setAddNew,
  parentType,
  dispatch,
  listData,
  getObj,
  columnOrder,
  sortOptions,
  isExtSrceen,
  groupByParameters,
  setFormData,
  setDisplayModal,
  displayModal,
  setdisplayDeleteModal,
  displayDeleteModal,
  pageSize,
  lookupData,
  freezeColumn,
  triggerActivateDataGrid,
  generateTableDefination,
  filterConfig,
  screenName,
  entityId,
  paramForFreezeColumn,
  setHasRelationships,
  listActive,
  activateDataGrid,
  defaultNewCustomFilter = null,
  onSearchClick = () => undefined,
  defaultParameter,
}, resetPreSelectedFilterConfig = null,
temporaryFilterConfig = false) => (
  <>
    <FasatFilterSetting
      availableColumns={getColumns(
        generateTableDefination({
          t,
          setAddNew,
          parentType,
          dispatch,
          listData,
          getObj,
          columnOrder,
          sortOptions,
          groupByParameters,
          setFormData,
          setDisplayModal,
          displayModal,
          setdisplayDeleteModal,
          displayDeleteModal,
          setHasRelationships,
        }),
      )}
      entityId={entityId}
      customPageSize={pageSize}
      entityFilterConfig={filterConfig}
      lookupData={lookupData}
      triggerActivateDataGrid={() => triggerActivateDataGrid()}
      screenName={t(screenName)}
      loadPreSelectedFilterConfig={temporaryFilterConfig}
      resetPreSelectedFilterConfig={() => resetPreSelectedFilterConfig()}
      freezeColumn={freezeColumn}
      paramForFreezeColumn={paramForFreezeColumn}
      listActive={listActive}
      activateDataGrid={activateDataGrid}
      defaultNewCustomFilter={defaultNewCustomFilter}
      onSearchClick={onSearchClick}
      defaultParameter={defaultParameter}
      isExtSrceen={isExtSrceen}
    />
  </>
);

export const getFasatGrid = ({ t, setAddNew, dispatch, columnOrder, sortOptions, groupByParameters, setFormData,
  setDisplayModal, displayModal, setdisplayDeleteModal, displayDeleteModal, activateDataGrid, listColumnOrder,
  paginationInfo, onColumnResizeHandler, onSortByHandler, onFilterHandler, onPageChangeHandler, deleteData,
  generateTableDefination, isPeriodDefinition, reqObj, filterConfig, listData, hasRowSelect, setCloseAddEditModal,
  getSelectedRows, listingYear, systemDate, setAdddEditActBtn, displayViewModal, setDisplayViewModal, hide, intSelRows,
  setProcessPayload, processPayload, mnthVal, yrVal, opt1Val, opt2Val, locale, hideChekAll = false, mnthText,
  selectedRowIndex = null, isManualAdj, canClick, checkboxAriaLabel, customNoDataMsg, rateKeyData, selectedUserDetailsRow, formRequest, addressType, location,
  onGridRowClick = () => undefined, displayAll = false,
  isDependentGrid = false,
  setEditItem = () => undefined,
  serverErrors,
  isCompCheckbox,
}, caption = null, showFooterBar = true, handleAddEdit = null) => {
  const history = useHistory();
  const loggedUser = useSelector(
    ({ ApplicationStateRBAC }) => ApplicationStateRBAC && ApplicationStateRBAC.loggedUser,
  );
  const rbacData = useSelector(
    ({ ApplicationStateRBAC }) => ApplicationStateRBAC && ApplicationStateRBAC.rbacData,
  );
  return (
    <>
      <FasatGrid
        data={activateDataGrid && listData && listColumnOrder ? listData.data : []}
        lookup={listData}
        canClick={canClick}
        tableMetaData={generateTableDefination({ t,
          locale,
          setAddNew,
          deleteData,
          listingYear,
          systemDate,
          dispatch,
          listData,
          columnOrder,
          sortOptions,
          groupByParameters,
          setFormData,
          setDisplayModal,
          displayModal,
          setdisplayDeleteModal,
          displayDeleteModal,
          displayViewModal,
          setDisplayViewModal,
          setCloseAddEditModal,
          hasRowSelect,
          history,
          setAdddEditActBtn,
          loggedUser,
          reqObj,
          handleAddEdit,
          setProcessPayload,
          processPayload,
          setEditItem,
          isManualAdj,
          mnthVal,
          yrVal,
          opt1Val,
          opt2Val,
          mnthText,
          serverErrors,
          rateKeyData,
          // rbacData,
          selectedUserDetailsRow,
          formRequest,
          rbacData,
          addressType,
          location,
        })}
        paginationInfo={paginationInfo}
        onColumnResize={onColumnResizeHandler}
        onSortBy={onSortByHandler}
        onFilter={onFilterHandler}
        onPageChangeHandler={onPageChangeHandler}
        isColumnResizable
        isPeriodDefinition={isPeriodDefinition}
        allowClientSideInteractions
        columnOrder={columnOrder}
        activateDataGrid={activateDataGrid}
        caption={caption}
        reqObj={reqObj}
        entityFilterConfig={filterConfig}
        hasRowSelect={hasRowSelect}
        getSelectedRows={getSelectedRows}
        showFooterBar={showFooterBar}
        hide={hide}
        selectedRowIndex={selectedRowIndex}
        onGridRowClick={onGridRowClick}
        isDependentGrid={isDependentGrid}
        hideChekAll={hideChekAll}
        isManualAdj={isManualAdj}
        displayAll={displayAll}
        intSelRows={intSelRows}
        checkboxAriaLabel={checkboxAriaLabel}
        customNoDataMsg={customNoDataMsg}
        isCompCheckbox={isCompCheckbox}
      />
    </>
  );
};
/**
 * FASAT GRID FOR RELATIONS
 * @param {*} params
 * @returns
 */
export const getFasatGridRel = ({
  t,
  getObj,
  setHasRelationships,
  setAddNew,
  dispatch,
  columnOrder,
  sortOptions,
  groupByParameters,
  setFormData,
  setDisplayModal,
  displayModal,
  setdisplayDeleteModal,
  displayDeleteModal,
  activateDataGrid,
  hypenLabel,
  paginationInfo,
  onColumnResizeHandler,
  onSortByHandler,
  onFilterHandler,
  onPageChangeHandler,
  generateTableDefination,
  setpVTitle,
  setformDateGevParent,
  setdisplayParentModal,
  displayParentModal,
  reqObj,
  filterConfig,
  deleteData,
  listData,
  setCloseAddEditModal,
}, caption = null) => (
  <>
    <FasatGrid
      data={activateDataGrid && listData ? listData.data : []}
      lookup={listData}
      tableMetaData={generateTableDefination({
        t,
        setHasRelationships,
        setAddNew,
        deleteData,
        getObj,
        setpVTitle,
        setformDateGevParent,
        setdisplayParentModal,
        displayParentModal,
        dispatch,
        listData,
        columnOrder,
        sortOptions,
        groupByParameters,
        setFormData,
        setDisplayModal,
        displayModal,
        setdisplayDeleteModal,
        displayDeleteModal,
        setCloseAddEditModal,
      })}
      paginationInfo={paginationInfo}
      onColumnResize={onColumnResizeHandler}
      onSortBy={onSortByHandler}
      onFilter={onFilterHandler}
      onPageChangeHandler={onPageChangeHandler}
      isColumnResizable
      allowClientSideInteractions
      columnOrder={columnOrder}
      activateDataGrid={activateDataGrid}
      hypenLabel={hypenLabel}
      caption={caption}
      reqObj={reqObj}
      entityFilterConfig={filterConfig}
    />
  </>
);

// Table generation function
export const generateTableTextColumn = (
  { defaultColumnOrderTable,
    tableDef,
    columnOrder,
    sortOptions,
    groupByParameters,
    setProcessPayload,
    processPayload,
    t,
    dispatch,
    reqObj,
    rbacData },
  linkBodyGenerator, listData = null,
) => {
  const isGroupBy = groupByParameters && groupByParameters.length > 0;
  const isDistinct = reqObj ? reqObj.isDistinct : null;
  if (defaultColumnOrderTable) {
    defaultColumnOrderTable.map((item) => {
      if (item.columnType === 'text') {
        return tableDef.addColumn({
          actions: item.actions || [],
          body: item.body || null,
          columnType: item.columnType,
          dataField: item.dataField,
          disableFilter: item.disableFilter,
          disableSortBy: item.disableSortBy,
          header: t(item.header),
          headerUnbold: t(item.headerUnbold) || null,
          id: item.id,
          isAction: item.isAction,
          visibility: columnOrder.includes(item.visibility),
          width: item.width,
          sortAscending: setColumnSettings(sortOptions, item.sortAscending),
          aclKey: item.aclKey,
          type: item.type,
          lookupKey: item.lookupKey,
          isSearchable: item.isSearchable,
          gridClmClass: item.gridClmClass || null,
        });
      }
      const hyperLinkColumn = {
        actions: [
          {
            actionHandler: (rowData, colData) => {
              Logger.info(`Edit Handler Row Data, ${rowData}`);
              Logger.info(`Edit Handler Column Data', ${colData}`);
            },
            className: null,
            hoverIcon: null,
            icon: '',
            id: 'id',
            name: 'ID',
            order: 1,
          },
        ],
        columnType: item.columnType,
        dataField: item.dataField,
        disableFilter: item.false,
        disableSortBy: item.disableSortBy || item.false,
        header: t(item.header),
        headerUnbold: t(item.headerUnbold) || null,
        id: item.id,
        isAction: hasUserClaim(item.isAction),
        visibility: columnOrder.includes(item.visibility),
        width: item.width,
        sortAscending: setColumnSettings(sortOptions, item.sortAscending),
        aclKey: item.aclKey,
        type: item.type,
        lookupKey: item.lookupKey,
        gridClmClass: item.gridClmClass || null,
      };
      hyperLinkColumn.body = linkBodyGenerator(
        hyperLinkColumn.actions,
        hyperLinkColumn.dataField,
        listData,
        t,
        dispatch,
        isGroupBy,
        { setProcessPayload, processPayload, isDistinct, rbacData },
      );
      return tableDef.addColumn(hyperLinkColumn);
    });
  }

  return tableDef;
};
