/* eslint-disable react/no-danger */
/**
 * @Description This is the Fasat page container page
 * @FileName fasatPage.js
 * @Author Uma Dutta - duttuma
 * @CreatedOn 28 January, 2021 08:25:00
 * @IssueID CDTFASATMOD-315
 */

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import FasatPageTitle from '../fasatPageTitle/fasatPageTitle';
import FasatButton from '../fasatButton/fasatButton';
import { staticCommonLabelKeys, globalConstants } from '../../moduleConstants';
import FasatMessageCard from '../fasatMessageCard/fasatMessageCard';
import '../../../../index.scss';
import './fasatPage.scss';
import FasatIdleTimer from '../fasatSignOut/fasatIdleTimer';
import FasatRouterLink from '../fasatRouterLink/fasatRouterLink';
import { staticLabelKeys } from '../../../modules/activity/moduleConstants';
import { getDateFormat } from '../../util';
import UserContext from '../../../../userContext';

const { dateFormatMMDDYYYY, COMMON_EXCEPTION_EXPORT_MAX_LIMIT, external } = globalConstants;
const timeOut = 500;
const externalUser = external;

export const getLabelErr = ({ userPrefError, tr, labelErr, label, localServerErrors, batchLabel, revRepErr,
  jobSuccess }) => {
  if (userPrefError) {
    return tr(labelErr);
  }
  if (revRepErr) {
    if (revRepErr?.selectedCount || revRepErr?.lockCount) {
      return null;
    }
    return tr(labelErr);
  }

  if (localServerErrors && localServerErrors[0].errorMessageId === COMMON_EXCEPTION_EXPORT_MAX_LIMIT) {
    return tr(batchLabel);
  }
  if (jobSuccess && jobSuccess.jobRequestId !== null) {
    if (jobSuccess.selectedCount === jobSuccess.successCount) {
      return tr(staticCommonLabelKeys.COMMON_ACTIVITY_PROCESS_SUCCESS);
    }
    return tr(staticLabelKeys.AP_AUP_ACTIVITIES_NOT_SUBMITTED);
  }
  return tr(label);
};

export const getVariant = (localServerErrors, jobSuccess) => {
  if (COMMON_EXCEPTION_EXPORT_MAX_LIMIT === (Array.isArray(localServerErrors)
  && localServerErrors[0].errorMessageId)) {
    return 'primary_complete';
  }
  if (jobSuccess && jobSuccess?.jobRequestId !== null) {
    if (jobSuccess.selectedCount === jobSuccess.successCount) {
      return 'primary_complete';
    }
    return 'primary_warning';
  }
  return 'primary_alert';
};

export const fetchServerError = (t, errItem, screenName) => {
  if (COMMON_EXCEPTION_EXPORT_MAX_LIMIT === errItem.errorMessageId) {
    return (
      <pre className="beMsgCard msgcard-text">
        <span dangerouslySetInnerHTML={{ __html: t(errItem.errorMessageId, { screenName }) }} />
        <FasatRouterLink to="/">{t(staticCommonLabelKeys.COMMON_LABEL_HOMEPAGE)}</FasatRouterLink>
        <span>.</span>
      </pre>
    );
  }
  return (
    <div>
      {errItem.errorMessageId
        ? <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessageId, errItem.alterObj) }} />
        : <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessage, errItem.alterObj) }} />}
    </div>
  );
};

export const fetchServerErrorAddEdit = (t, errItem, recordId) => (
  <div>
    {errItem.errorMessageId
      ? <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessageId, { recordId }) }} />
      : <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessage) }} />}
  </div>
);

export const fetchServerErrorMsg = (t, errItem, screenName) => {
  const obj = {
    Key1: errItem.alterObj ? errItem.alterObj.Key2 : '',
    Key2: errItem.alterObj ? errItem.alterObj.Key1 : '',
  };
  if (COMMON_EXCEPTION_EXPORT_MAX_LIMIT === errItem.errorMessageId) {
    return (
      <pre className="beMsgCard msgcard-text">
        <span dangerouslySetInnerHTML={{ __html: t(errItem.errorMessageId, { screenName }) }} />
        <FasatRouterLink to="/">{t(staticCommonLabelKeys.COMMON_LABEL_HOMEPAGE)}</FasatRouterLink>
        <span>.</span>
      </pre>
    );
  }
  return (
    <div>
      {errItem.errorMessageId
        ? <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessageId, obj) }} />
        : <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessage, obj) }} />}
    </div>
  );
};

const aodaFocus = () => {
  setTimeout(() => {
    const msgContainer = document.getElementById('messageCrdExport');
    if (msgContainer) {
      const btnCross = msgContainer.getElementsByTagName('button');
      if (btnCross.length > 0) {
        btnCross[0].focus();
      }
    }
  }, timeOut);
};

const commonErrAP = ({ item, t }) => {
  const { batchNum } = item;
  const genDateFormat = (item && item.msgParams !== null)
    ? getDateFormat(item.msgParams[0], dateFormatMMDDYYYY) : '';
  return (
    <div>
      {`${t(staticCommonLabelKeys.COMMON_BATCHNUM_AND_REASON)}`}
      {' '}
      <span className="demiboldText">
        {item.batchNum}
        {item?.batchNum && ','}
        {' '}
        {item && item.msgId !== null ? t(item.msgId,
          { genDate: genDateFormat, batchNum, interpolation: { escapeValue: false } })
          : t(item.msgText)}
      </span>
    </div>
  );
};
export const activityProcessedErr = (revRepErr, t) => {
  const apCondition = revRepErr?.eligibleCount ?? -1;
  const activityErrs = revRepErr.errors && revRepErr.errors.map((item) => commonErrAP({ item, t }));
  return (
    <>
      <div>
        <span className="demiboldText">
          {revRepErr.action && revRepErr.action === 'DELETE'
            ? `${t(staticCommonLabelKeys.COMMON_ACTIVITY_DELETE_ERROR)}`
            : `${t(staticCommonLabelKeys.COMMON_ACTIVITY_PROCESS_ERROR)}`}
        </span>
      </div>
      <div>
        {`${t(staticCommonLabelKeys.COMMON_TOTAL_RECORDS_SELECTED)}`}
        {' '}
        <span className="demiboldText">{revRepErr.selectedCount}</span>
      </div>
      {(revRepErr.action && revRepErr.action === 'DELETE') && (
        <div>
          {`${t(staticCommonLabelKeys.COMMON_TOTAL_RECORDS_DELETED)}`}
          {' '}
          <span className="demiboldText">{revRepErr.successCount}</span>
        </div>
      ) }
      {((revRepErr.action && revRepErr.action !== 'DELETE')) && apCondition >= 0 && (
      <div>
        {`${t(staticCommonLabelKeys.COMMON_TOTAL_RECORDS_ELIGIBLE)}`}
        {' '}
        <span className="demiboldText">{revRepErr.eligibleCount}</span>
      </div>
      ) }
      {activityErrs}
      <div />
    </>
  );
};

export const concurrencyAP = (revRepErr, t) => {
  const activityErrs = revRepErr.errors && revRepErr.errors.map((item) => commonErrAP({ item, t }));
  return (
    <>
      <div>
        <span className="demiboldText">
          {revRepErr.action && revRepErr.action === 'DELETE'
            ? `${t(staticCommonLabelKeys.COMMON_ACTIVITY_DELETE_ERROR)}`
            : `${t(staticCommonLabelKeys.COMMON_ACTIVITY_PROCESS_ERROR)}`}
        </span>
      </div>
      <div>
        {`${t(staticCommonLabelKeys.COMMON_TOTAL_RECORDS_SELECTED)}`}
        {' '}
        <span className="demiboldText">{revRepErr.lockCount}</span>
      </div>

      <div>
        {revRepErr.action && (revRepErr.action === 'DELETE' || revRepErr.action === 'PROCESS_REALTIME')
          ? `${t(staticLabelKeys.AUP_REC_UNAVAILABLE_LOCKED)}`
          : `${t(staticLabelKeys.AP_AUP_REC_LOCKED)}`}

        {' '}
        <span className="demiboldText">{revRepErr.lockCount}</span>
      </div>
      {activityErrs}
      <div />
    </>
  );
};

export const activityProcessedSuccess = (jobSuccess, t, link) => (
  <div>
    <>
      <div>
        {`${t(staticCommonLabelKeys.COMMON_TOTAL_RECORDS_SELECTED)}`}
        {' '}
        <span className="demiboldText">{jobSuccess.selectedCount}</span>
      </div>

      <div>
        {`${t(staticLabelKeys.AP_AUP_ACTIVITIES_SUBMITTED)}`}
        {' '}
        <span className="demiboldText">{jobSuccess.successCount}</span>
      </div>
      {`${t(staticLabelKeys.AP_AUP_LABEL_JOB_ID)}`}
      {' '}
      <span className="demiboldText">{jobSuccess.jobRequestId}</span>
      {' '}
      {`${t(staticLabelKeys.AP_AUP_PROCESS_SUCCESS)}`}
      {' '}
      <FasatRouterLink
        to={{ pathname: link }}
      >
        {`${t(staticLabelKeys.JS_PAGE_TITLE)}`}
      </FasatRouterLink>
      .
      <div />
    </>
  </div>
);

export const activityProcessedErrorMsg = (t, errItem) => {
  const genDateFormat = (errItem && errItem.addlDetails)
    ? getDateFormat(errItem.addlDetails, dateFormatMMDDYYYY) : '';
  const Key1 = errItem && errItem.addlDetails ? errItem.addlDetails : '';
  return (
    <div>
      {errItem.errorMessageId
        ? (
          <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessageId, { genDate: genDateFormat, Key1 }) }} />
        )
        : <div dangerouslySetInnerHTML={{ __html: t(errItem.errorMessage, { genDate: genDateFormat, Key1 }) }} />}
    </div>
  );
};

export const getErrorsAP = ({ revRepErr, t }) => {
  let val;
  if (revRepErr && !revRepErr.selectedCount) {
    val = revRepErr.errors?.map((errItem) => (
      activityProcessedErrorMsg(t, errItem)
    ));
  }
  return val;
};

export const getSuccessAP = ({ revRepErr, t }) => {
  let val;
  if (revRepErr && revRepErr?.errors
   && revRepErr?.selectedCount) {
    val = activityProcessedErr(revRepErr, t);
  }
  return val;
};

export const getJobSuccessActivity = ({ jobSuccess, t }) => {
  let val;
  const link = 'fpaMonitoring';
  if (jobSuccess && jobSuccess.jobRequestId !== null) {
    val = activityProcessedSuccess(jobSuccess, t, link);
  }
  return val;
};

export const getConcurrencyAP = ({ revRepErr, t }) => {
  let val;
  if (revRepErr && revRepErr?.errors
   && revRepErr?.lockCount) {
    val = concurrencyAP(revRepErr, t);
  }
  return val;
};

export const getHasErrors = ({ localUserPrefError, localServerErrors, revRepErr, jobSuccess }) => localUserPrefError
 || localServerErrors || revRepErr || jobSuccess;

export const getServerMessageCard = (serverErrors, userPrefError, t, title, revRepErr, jobSuccess) => {
  let localUserPrefError = userPrefError;
  const localServerErrors = serverErrors;
  if (localUserPrefError && localServerErrors) {
    localUserPrefError = null;
  }
  const hasErrors = getHasErrors({ localUserPrefError, localServerErrors, revRepErr, jobSuccess });
  if (hasErrors) {
    aodaFocus();
  }
  const screenName = title;
  return (
    <>
      {(hasErrors) && (
      <FasatMessageCard
        id="messageCrdExport"
        titleText={getLabelErr({ userPrefError,
          localServerErrors,
          revRepErr,
          jobSuccess,
          tr: t,
          labelErr: staticCommonLabelKeys.ERROR_LABEL,
          label: staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO,
          batchLabel: staticCommonLabelKeys.COMMON_LABEL_BATCH_PROCESSING })}
        variant={getVariant(localServerErrors, jobSuccess)}
        hasCloseButton
      >
        {localServerErrors && localServerErrors.map((errItem) => (
          fetchServerError(t, errItem, screenName)
        ))}
        {localUserPrefError && localUserPrefError.serverRequestError.map((errItem) => (
          fetchServerError(t, errItem, screenName)
        ))}
        {getSuccessAP({ revRepErr, t })}
        {getErrorsAP({ revRepErr, t })}
        {getConcurrencyAP({ revRepErr, t })}
        {getJobSuccessActivity({ jobSuccess, t })}
      </FasatMessageCard>
      )}
    </>
  );
};
const FasatPage = ({ title, titleInItalics, children, pageHeaderBtnSection, backBtnEnabled, pageName, onClickBack,
  serverErrors, backButtonId, ptAdd, ptEdit, ptExport, ptDelete, handleActionBtn, editAclKey, revRepErr, jobSuccess,
  extScreen }) => {
  const [t] = useTranslation();
  const userType = useContext(UserContext);
  const backBtnText = t(staticCommonLabelKeys.COMMON_BUTTON_BACK);
  const { userPrefError, rbacData } = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC);
  const isBatchProcessing = useSelector(({ ApplicationState }) => ApplicationState.isBatchProcessing);
  const backButtonProps = {};
  if (backButtonId) {
    backButtonProps.id = backButtonId;
  }
  return (
    <FasatIdleTimer>
      <div className={`fasatPage ${pageName} ${extScreen && 'extFasatPage'}`} data-testid="FasatPageComponent">
        {pageHeaderBtnSection && (
        <div className="pageHeaderBtnSection">
          {backBtnEnabled && (
          <div>
            <FasatButton variant="tertiary_back" onClick={() => onClickBack()} {...backButtonProps}>
              {backBtnText}
            </FasatButton>
          </div>
          )}
        </div>
        )}
        <Helmet><title>{`Fasat - ${title}`}</title></Helmet>
        <FasatPageTitle
          title={title}
          ptAdd={ptAdd}
          ptEdit={ptEdit}
          ptDelete={ptDelete}
          ptExport={ptExport}
          titleInItalics={titleInItalics}
          handleActionBtn={handleActionBtn}
          editAclKey={editAclKey}
          rbacData={rbacData}
        />
        {((userType === externalUser) && isBatchProcessing) && (
        <FasatMessageCard
          id="message24X7EXT"
          titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
          variant="primary_information"
        >
          {t(staticCommonLabelKeys.COMMON_LABEL_BATCH_PROCESSING_MSG)}
        </FasatMessageCard>
        )}
        {serverErrors?.fvTotalChequesProcessed ? ''
          : getServerMessageCard(serverErrors, userPrefError, t, title, revRepErr, jobSuccess)}
        {children}
      </div>
    </FasatIdleTimer>
  );
};

FasatPage.propTypes = {
  title: PropTypes.string,
  titleInItalics: PropTypes.string,
  children: PropTypes.node,
  pageHeaderBtnSection: PropTypes.bool,
  backBtnEnabled: PropTypes.bool,
  displayModal: PropTypes.bool,
  displayDeleteModal: PropTypes.bool,
  showExportBtn: PropTypes.bool,
  exportBtnText: PropTypes.string,
  showEditBtn: PropTypes.bool,
  editBtnText: PropTypes.string,
  showDeleteBtn: PropTypes.bool,
  deleteBtnText: PropTypes.string,
  showAddBtn: PropTypes.bool,
  addBtnText: PropTypes.string,
  pageName: PropTypes.string,
  onClickBack: PropTypes.func,
  serverErrors: PropTypes.shape([]),
  handleActionBtn: PropTypes.func,
  backButtonId: PropTypes.string,
  ptAdd: PropTypes.bool,
  ptEdit: PropTypes.bool,
  ptDelete: PropTypes.bool,
  ptExport: PropTypes.bool,
  editAclKey: PropTypes.string,
  revRepErr: PropTypes.shape({}),
  jobSuccess: PropTypes.shape({}),
  extScreen: PropTypes.bool,
};

FasatPage.defaultProps = {
  title: '',
  titleInItalics: '',
  children: '',
  pageHeaderBtnSection: true,
  backBtnEnabled: true,
  showExportBtn: true,
  exportBtnText: 'Export',
  showAddBtn: false,
  addBtnText: 'Add',
  showEditBtn: false,
  editBtnText: 'Edit',
  showDeleteBtn: false,
  deleteBtnText: 'Delete',
  pageName: '',
  onClickBack: undefined,
  serverErrors: null,
  handleActionBtn: undefined,
  displayModal: null,
  displayDeleteModal: null,
  backButtonId: null,
  ptAdd: false,
  ptEdit: false,
  ptDelete: false,
  ptExport: false,
  editAclKey: '',
  revRepErr: null,
  jobSuccess: null,
  extScreen: false,
};

export default FasatPage;
