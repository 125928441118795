/* eslint-disable max-len */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { exportRule, exportValidationSchema, ruleConfig } from './exportConfig';
import { exportFileType, exportPageSizeType, globalConstants, staticCommonLabelKeys } from '../../moduleConstants';
import { FasatDropdown, FasatFormElement, FasatMessageCard, FasatModal } from '..';
import { getErrors } from '../../util';
import { getAction } from '../../../../stateManagement/reduxUtil';
import { fetchServerError } from '../fasatPage/fasatPage';
import { checkDirtyData } from '../../redux/actions/commonActions';

const errorCode = 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT';

const { formElement, colSize1, serverErrorMsgContainer } = globalConstants;
let formik;
const onSaveTopic = 'ADD_EXPOT_SAVE';
const onCancelTopic = 'ADD_EXPOT_CANCEL';
export const dropDown = ({ options, label, id, t, serverErrors }) => (
  <div className={`${formElement} ${colSize1}`}>
    <FasatFormElement
      direction="bottom"
      id={`formElm_${id}`}
      errors={getErrors(id, formik, serverErrors, exportRule.fileType, ruleConfig, false, t)}
    >
      <FasatDropdown
        dropdownItems={options}
        id={id}
        label={label}
        name={id}
        placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
        ariaRequired
        {...formik.getFieldProps(id)}
        selected={0}
        onHandleChange={(val) => formik.setFieldValue(id, val)}
        errors={getErrors(id, formik, serverErrors, exportRule.fileType, ruleConfig, true, t)}
      />
    </FasatFormElement>
  </div>
);

/**
 * @param {Array} options
 * Validate all form validation and lift event sunmitHandler
 * @returns {Array}
 */
export const validateAndSubmit = (data, { exportPayload, dispatch, exportAction, popUpClose,
  totalRows, formData }) => {
  if (formData.values) {
    const formatExtObject = { ...exportPayload,
      data: { ...exportPayload.data,
        export: formData.values.pageType,
        pageSize: formData.values.pageType === exportPageSizeType.All ? totalRows : exportPayload.data?.pageSize,
      },
      exportFileType: formik.values.fileType === exportFileType.CSV.toString() ? exportFileType.CSV : exportFileType.Excel,
    };
    dispatch(getAction(exportAction, { formatExtObject, popUpClose }));
  }
};

export const handleSaveClick = (data, { dispatch, exportPayload, exportAction,
  resetAction, popUpClose, totalRows }) => {
  dispatch(getAction(resetAction, null));
  formik.validateForm().then(() => {
    if (formik.isValid) {
      validateAndSubmit(data, { exportPayload, dispatch, exportAction, popUpClose, totalRows, formData: formik });
    }
  });
};

export const errorMessage = (t, serverErrors, modalTitle) => (
  <div className={serverErrorMsgContainer}>
    <FasatMessageCard
      titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)}
      variant={serverErrors[0].errorCode === errorCode ? 'primary_complete' : 'primary_alert'}
    >
      {serverErrors.map((errItem) => (
        fetchServerError(t, errItem, modalTitle)
      ))}
    </FasatMessageCard>
  </div>
);
export const checkDirtyFunc = ({ formData, cancelEventData, exportOpen }) => {
  if ((!formData?.dirty && cancelEventData) || !exportOpen) {
    cancelEventData.close();
  }
};
export const popUpCloseFunc = ({ dispatch, setExportOpen, resetAction }) => {
  dispatch(getAction(resetAction, null));
  setExportOpen(false);
  document.body.style.overflowY = 'auto';
};
const ExportPopUp = ({ exportOpen, setExportOpen, modalTitle, exportAction, exportPayload,
  serverErrors, resetAction, totalRows, subHeading }) => {
  const dispatch = useDispatch();
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);
  const [cancelEventData, setCancelEventData] = useState(null);
  const popUpClose = () => popUpCloseFunc({ dispatch, setExportOpen, resetAction });
  useEffect(() => dispatch(checkDirtyData(formik?.dirty)), [formik?.dirty]);
  useEffect(() => {
    if (onSaveTopic && onSaveTopic !== '' && (saveSubscriptionIds || saveSubscriptionIds.length === 0)) {
      const subId = PubSub.subscribe(onSaveTopic, (msg, data) => {
        formik.handleSubmit();
        handleSaveClick(data, { dispatch,
          exportPayload,
          exportAction,
          resetAction,
          popUpClose,
          totalRows,
        });
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    if (onCancelTopic && onCancelTopic !== '') {
      const subId = PubSub.subscribe(onCancelTopic, (msg, data) => {
        setCancelEventData(data);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
    };
  }, []);
  useEffect(() => {
    checkDirtyFunc({ formData: formik, cancelEventData, exportOpen });
  }, [cancelEventData, exportOpen]);
  const [t] = useTranslation();
  formik = useFormik({
    initialValues: { fileType: exportFileType.Excel.toString(), pageType: exportPageSizeType.All },
    validationSchema: () => exportValidationSchema(t),
  });
  const exportType = [
    { label: `${t(staticCommonLabelKeys.COMMON_LABEL_EXCEL_XLS)}`, value: exportFileType.Excel.toString() },
    { label: `${t(staticCommonLabelKeys.COMMON_LABEL_EXCEL_CSV)}`, value: exportFileType.CSV.toString() },
  ];
  const pageType = [
    { label: `${t(staticCommonLabelKeys.COMMON_LABEL_ALL_ROWS)}`, value: exportPageSizeType.All },
    { label: `${t(staticCommonLabelKeys.COMMON_LABEL_CURRENT_SCREEN)}`, value: exportPageSizeType.Current },
  ];
  return (
    <FasatModal
      title={modalTitle}
      display={exportOpen}
      modalWidth="560px"
      primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_EXPORT)}
      primaryTopic={onSaveTopic}
      secondaryTopic={onCancelTopic}
      openModal="OPEN_ADD_EXPOT_MODAL"
      secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_CANCEL)}
      modalCosed={() => popUpClose()}
      onCancelClickCancel={() => popUpClose()}
      modalClass="addEditEXPOTModal"
    >
      {subHeading && <h3 className="exportSubHeading">{subHeading}</h3>}
      <form id="exportExternal" onSubmit={(e) => e.preventDefault()}>
        {(serverErrors && serverErrors.length > 0) && errorMessage(t, serverErrors, modalTitle)}
        {dropDown({ options: exportType, label: `${t(staticCommonLabelKeys.COMMON_LABEL_FILE_FORMAT)}`, id: 'fileType', t, serverErrors })}
        {dropDown({ options: pageType, label: `${t(staticCommonLabelKeys.COMMON_LABEL_NUM_OF_ROWS)}`, id: 'pageType', t, serverErrors })}
      </form>
    </FasatModal>
  );
};

ExportPopUp.propTypes = {
  setExportOpen: PropTypes.func,
  exportOpen: PropTypes.bool,
  modalTitle: PropTypes.isRequired,
  totalRows: PropTypes.isRequired,
  resetAction: PropTypes.isRequired,
  exportAction: PropTypes.isRequired,
  exportPayload: PropTypes.isRequired,
  serverErrors: PropTypes.shape({ length: 0 }),
  subHeading: PropTypes.string,
};

ExportPopUp.defaultProps = {
  exportOpen: false,
  setExportOpen: () => undefined,
  serverErrors: null,
  subHeading: null,
};

export default ExportPopUp;
