/* eslint-disable react/prop-types */
/**
 * @Description Fasat Filet Input
 * @FileName fasatFilterInput.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 24 June, 2021 21:40:00
 * @IssueID - 1050
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { globalConstants, staticCommonLabelKeys } from '../../../moduleConstants';
import FasatActionableTextInput from '../../fasatActionableTextInput/fasatActionableTextInput';
import FasatDatePicker from '../../fasatDatePicker/fasatDatePicker';
import FasatDropdown from '../../fasatDropdown/fasatDropdown';
import FasatTextInput from '../../fasatTextInput/fasatTextInput';
import { getCurrentLocale } from '../../../i18n';
import { getDateFormat } from '../../../util';

const OPT_IS_NULL_ID = 13;
const OPT_IS_NOT_NULL_ID = 14;
const OPT_IS_EMPTY_ID = 20;
const OPT_IS_NOT_EMPTY_ID = 21;
const { dateFormatMMDDYYYY } = globalConstants;

const generateInputComponent = (
  isFieldDisable, changeInputValue, type, values, showRequired, t,
) => (
  <FasatTextInput
    disabled={isFieldDisable}
    onChange={changeInputValue}
    value={values}
    errors={showRequired}
    aria-label={t(staticCommonLabelKeys.FILTER_LABEL_VALUE)}
    onInputEnabled={type === 'number'}
    inputNum={type === 'number'}
  />
);

/**
* Return Fasat Actionable Text Input Component
* @returns Component
*/
const grtFasatActionTxtInp = (
  isFieldDisable, changeInputValue, type, values, showRequired, t,
) => (
  <FasatActionableTextInput
    disabled={isFieldDisable}
    changeValues={changeInputValue}
    type={type}
    inlineButtonText={t(staticCommonLabelKeys.ARIA_COMMON_ACTION_BUTTON_ADD)}
    value={values || null}
    errors={showRequired}
    ariaLabel={t(staticCommonLabelKeys.FILTER_LABEL_VALUE)}
  />
);

/**
* Return Fasat Text Range Input Component
* @returns Component
*/
const generateFasatTextInputRange = (isFieldDisable, type, setRangeValueFrom, setRangeValueTo, t, values) => (
  <div clasName="inputRange">
    <FasatTextInput
      value={values ? values[0] : null}
      disabled={isFieldDisable}
      onChange={(event) => setRangeValueFrom(event.target.value)}
      aria-label={t(staticCommonLabelKeys.FILTER_LABEL_VALUE)}
      onInputEnabled={type === 'number'}
      inputNum={type === 'number'}
    />
    <span className="hyphen">-</span>
    <FasatTextInput
      value={values ? values[1] : null}
      disabled={isFieldDisable}
      onChange={(event) => setRangeValueTo(event.target.value)}
      aria-label={t(staticCommonLabelKeys.FILTER_LABEL_VALUE)}
      onInputEnabled={type === 'number'}
      inputNum={type === 'number'}
    />
  </div>
);

export const validateDateFormat = (values) => values && getDateFormat(values, dateFormatMMDDYYYY);

/**
* Return Fasat Date Picker Input Component
* @returns Component
*/
const generateFasatDateTimePicker = (isFieldDisable, setValues, values, showRequired, t) => (
  <FasatDatePicker
    dateValue={values}
    dateFormat={dateFormatMMDDYYYY}
    disabled={isFieldDisable}
    errors={showRequired}
    textBoxAriaLabel={t(staticCommonLabelKeys.FILTER_LABEL_VALUE)}
    onChange={(newValue) => setValues(newValue)}
  />
);

/**
* Return Fasat Date Picker Range Input Component
* @returns Component
*/
const generateFasatDateTimePickerRange = (isFieldDisable, setRangeValueFrom,
  setRangeValueTo, values, showRequired, t, uuid) => (
    <div className="dateRange">
      <FasatDatePicker
        id={`${uuid}_to`}
        dateValue={values !== null ? values[0] : values}
        dateFormat={dateFormatMMDDYYYY}
        disabled={isFieldDisable}
        errors={showRequired}
        onChange={(newValue) => setRangeValueFrom(newValue)}
        textBoxAriaLabel={t(staticCommonLabelKeys.FILTER_LABEL_VALUE)}
      />
      <span className="hyphen">-</span>
      <FasatDatePicker
        id={`${uuid}_from`}
        dateValue={values !== null ? values[1] : values}
        dateFormat={dateFormatMMDDYYYY}
        disabled={isFieldDisable}
        errors={showRequired}
        onChange={(newValue) => setRangeValueTo(newValue)}
        textBoxAriaLabel={t(staticCommonLabelKeys.FILTER_LABEL_VALUE)}
      />
    </div>
);

/**
  * Return Fasat Dropdown Input Component
  * @returns Component
  */
const generateDropdownComponent = (
  getDropdownOptions, setValues,
  { isFieldDisable, uuid, inputConfiguration, values, t, operator, showRequired },
) => (
  <FasatDropdown
    dropdownItems={getDropdownOptions()}
    disabled={isFieldDisable}
    onHandleChange={(val) => setValues(val)}
    id={uuid}
    isMultiselect={inputConfiguration.isMultiselect}
    value={values}
    placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
    filterCriteria
    operator={operator}
    errors={showRequired}
    ariaLabel={t(staticCommonLabelKeys.FILTER_LABEL_VALUE)}
    searchEnabled={inputConfiguration.isSearchable}
    searchPlaceholderText={t(staticCommonLabelKeys.COMMON_BUTTON_SEARCH)}
  />
);

const validateInputComponent = (inputConfiguration) => (
  inputConfiguration.isInputComponent && (!inputConfiguration.isMultiselect
    && !inputConfiguration.isBetween)
);

const validateActionAbleCOmponent = (inputConfiguration) => (
  inputConfiguration.isInputComponent && (inputConfiguration.isMultiselect
    && !inputConfiguration.isBetween)
);

const validateMultiSelect = (inputConfiguration) => (
  inputConfiguration.isInputComponent && (!inputConfiguration.isMultiselect
    && inputConfiguration.isBetween)
);

const validateDateTimePicker = (inputConfiguration) => (
  inputConfiguration.isDatePickerComponent && !inputConfiguration.isBetween
);

const validateDateTimeRange = (inputConfiguration) => (
  inputConfiguration.isDatePickerComponent && inputConfiguration.isBetween
);

const getRequiredMsg = ({ uuid, t }) => [{ id: uuid,
  msg: t(staticCommonLabelKeys.REQFLD),
  isError: true }];

const setOnValueChange = ({ columnValue, operator, values, uuid, onValueChange }) => {
  const onValueChangeEvent = { key: columnValue.value,
    operator: operator.operator,
    type: columnValue.type,
    values,
    uuid,
  };
  onValueChange(onValueChangeEvent);
};

const FasatFilterInput = ({ columnValue, operator, onValueChange, lookupData, type, uuid, inputConfiguration,
  preSelectedIndex, customFilterCreteriaElement, showInputRequired }) => {
  const [values, setValues] = useState(null);
  const locale = getCurrentLocale();
  const [showRequired, setShowRequired] = useState([]);
  const [t] = useTranslation();
  const [isFieldDisable, setIsFieldDisable] = useState(true);

  /**
  * trigger onValueChange for pre populated Values
  */
  useEffect(() => {
    if (showInputRequired) {
      if (values && showRequired.length > 0) {
        setShowRequired([]);
      }
      if (!values && showRequired.length === 0) {
        setShowRequired(getRequiredMsg({ uuid, t }));
      }
    }
    if (columnValue && columnValue.value && operator) {
      setOnValueChange({ columnValue, operator, values, uuid, onValueChange });
    }
  }, [values]);
  useEffect(() => {
    if (showInputRequired && !values) {
      setShowRequired(getRequiredMsg({ uuid, t }));
    } else {
      setShowRequired([]);
    }
  }, [showInputRequired, locale]);
  /**
  * Input component Disable Enable as per Operator Value
  */
  useEffect(() => {
    if (operator) {
      const oprChk1 = operator.id === OPT_IS_NULL_ID || operator.id === OPT_IS_NOT_NULL_ID;
      const oprChk2 = operator.id === OPT_IS_EMPTY_ID || operator.id === OPT_IS_NOT_EMPTY_ID;
      if (oprChk1 || oprChk2) {
        setIsFieldDisable(true);
      } else {
        setIsFieldDisable(false);
      }
    } else {
      setIsFieldDisable(true);
    }
    setValues(null);
  }, [operator]);
  /**
  * Set Value for pre populated Value
  */
  useEffect(() => {
    if (preSelectedIndex > -1
      && customFilterCreteriaElement[preSelectedIndex]?.values && operator) {
      setValues(customFilterCreteriaElement[preSelectedIndex].values);
    }
  }, [operator]);
  /**
  * Lookup dropdown options
  * @returns lookup dropdown options
  */
  const getDropdownOptions = () => {
    if (lookupData && columnValue) {
      const levelItems = lookupData
        .filter((lookUpItem) => lookUpItem.key === columnValue.lookupKey);
      if (levelItems && levelItems.length > 0) {
        return levelItems[0].values;
      }
    }
    return [];
  };
  /**
  * set user select value from text input component
  * @param {string} value
  */
  const changeInputValue = (value) => {
    let changeVal = null;
    if (inputConfiguration.isMultiselect) {
      changeVal = value;
    } else {
      changeVal = value.target.value;
    }
    setValues(changeVal);
  };
  /**
  * set user select value from range input component
  * @param {object} value
  */
  const setRangeValueFrom = (value) => {
    const formatObject = [value, values ? values[1] : null];
    setValues(formatObject);
  };
  /**
  * set user select value to range input component
  * @param {object} value
  */
  const setRangeValueTo = (value) => {
    const formatObject = [values ? values[0] : null, value];
    setValues(formatObject);
  };
  return (
    <>
      {validateInputComponent(inputConfiguration)
        && generateInputComponent(isFieldDisable, changeInputValue, type, values, showRequired, t)}

      {validateActionAbleCOmponent(inputConfiguration)
        && grtFasatActionTxtInp(isFieldDisable, changeInputValue, type, values, showRequired, t)}

      {validateMultiSelect(inputConfiguration) && generateFasatTextInputRange(
        isFieldDisable, type, setRangeValueFrom, setRangeValueTo, t, values,
      )}
      {validateDateTimePicker(inputConfiguration)
       && generateFasatDateTimePicker(isFieldDisable, setValues, values, showRequired, t)}
      {validateDateTimeRange(inputConfiguration)
        && generateFasatDateTimePickerRange(isFieldDisable, setRangeValueFrom,
          setRangeValueTo, values, showRequired, t, uuid)}
      {inputConfiguration.isDropdownComponent && generateDropdownComponent(
        getDropdownOptions, setValues,
        { isFieldDisable, uuid, inputConfiguration, values, t, operator, showRequired },
      )}
    </>
  );
};

FasatFilterInput.propTypes = {
  columnValue: PropTypes.shape({
    value: PropTypes.string,
    type: PropTypes.string,
    lookupKey: PropTypes.string,
  }),
  operator: PropTypes.shape(),
  onValueChange: PropTypes.func,
  lookupData: PropTypes.arrayOf(PropTypes.shape()),
  type: PropTypes.string,
  uuid: PropTypes.string,
  inputConfiguration: PropTypes.shape({
    isInputComponent: PropTypes.bool,
    isMultiselect: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isBetween: PropTypes.bool,
    isDatePickerComponent: PropTypes.bool,
    isDropdownComponent: PropTypes.bool,
    autoPopulate: PropTypes.bool,
  }),
  customFilterCreteriaElement: PropTypes.arrayOf(PropTypes.shape()),
  preSelectedIndex: PropTypes.number,
};

FasatFilterInput.defaultProps = {
  columnValue: {
    value: null,
    type: null,
    lookupKey: null,
  },
  operator: null,
  onValueChange: () => undefined,
  lookupData: null,
  type: 'text',
  uuid: null,
  customFilterCreteriaElement: null,
  preSelectedIndex: -1,
  inputConfiguration: {
    isInputComponent: true,
    isMultiselect: false,
    isBetween: false,
    isDatePickerComponent: false,
    isDropdownComponent: false,
    autoPopulate: false,
    isSearchable: false,
  },
};

export default FasatFilterInput;
