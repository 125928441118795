/* eslint-disable no-unused-vars */
/**
 * @Description Menu configuartion for module activity
 * @FileName menuConfig.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:39:31
 * @IssueID NA
 */
import { TransferOfExistingAssets } from '@manulife/mux-cds-icons';
import { t } from '../../../common/i18n';
import { navigate } from '../../../common/util';
import { aclKeysConstant, staticLabelKeys, routeConstant } from '../moduleConstants';

const menus = () => [{
  id: 'ACTIVITY_MENU',
  sequence: 1,
  icon: <TransferOfExistingAssets style={{ width: '20px', height: '20px' }} />,
  label: t(staticLabelKeys.ACTIVITY_MENU),
  aria: t(staticLabelKeys.ACTIVITY_MENU),
  urlPath: '#',
  url: '#',
  action: () => navigate('#'),
  aclKey: [aclKeysConstant.ACTIVITY_MENU],
  acl: true,
  showInBatchProcess: false,
  subMenu: [
    {
      id: 'ACTIVITY_PROCESSED_MENU',
      label: t(staticLabelKeys.RBAC_ACTVTP_MENU),
      urlPath: routeConstant.ACTIVITY_PROCESS_ROUTE,
      url: '#',
      action: () => navigate(routeConstant.ACTIVITY_PROCESS_ROUTE),
      aclKey: [aclKeysConstant.RBAC_ACTVTP_MENU],
      acl: true,
      showInBatchProcess: false,
    },
    {
      id: 'ACTIVITY_UNPROCESSED_MENU',
      label: t(staticLabelKeys.RBAC_AUP_MENU),
      urlPath: routeConstant.ACTIVITY_UNPROCESS_ROUTE,
      url: '#',
      action: () => navigate(routeConstant.ACTIVITY_UNPROCESS_ROUTE),
      aclKey: [aclKeysConstant.RBAC_AUP_MENU],
      acl: true,
      showInBatchProcess: false,
    },
    {
      id: 'FPA_NOTIFICATION_MENU',
      label: t(staticLabelKeys.RBAC_NOTIFICATION_MENU),
      urlPath: routeConstant.FPA_MONITORING_ROUTE,
      url: '#',
      action: () => navigate(routeConstant.FPA_MONITORING_ROUTE),
      aclKey: [aclKeysConstant.RBAC_NOTIFICATION_MENU],
      acl: true,
      showInBatchProcess: false,
    },
  ],
}, {
  id: 'ACTIVITY_PROCESSED_EXT',
  sequence: 1,
  icon: <TransferOfExistingAssets style={{ width: '20px', height: '20px' }} />,
  label: t(staticLabelKeys.RBAC_EXT_ACTIVITY_PROCESS_MENU),
  aria: t(staticLabelKeys.RBAC_EXT_ACTIVITY_PROCESS_MENU),
  urlPath: '#',
  url: '#',
  action: () => navigate('#'),
  aclKey: [aclKeysConstant.RBAC_EXT_ACTIVITY_PROCESS_MENU],
  acl: true,
  showInBatchProcess: true,
  // external: true,
  subMenu: [
    {
      id: 'EXTERNAL_ACTIVITY_PROCESS_MENU',
      label: t(staticLabelKeys.RBAC_EXT_ACTIVITY_PROCESS_MENU),
      urlPath: routeConstant.ACTIVITY_PROCESS_EXT_ROUTE,
      url: '#',
      action: () => navigate(routeConstant.ACTIVITY_PROCESS_EXT_ROUTE),
      aclKey: [aclKeysConstant.RBAC_EAP_MENU],
      acl: true,
      showInBatchProcess: true,
    // external: true,
    },
  ],
}];
const mobileMenus = menus;
export { menus, mobileMenus };
