/**
 * @Description This is FasatPageTitle container page
 * @FileName fasatPageTitle.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 27 January, 2021 14:15:00
 * @IssueID CDTFASATMOD- 298, 502
 */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../../index.scss';
import './fasatPageTitle.scss';
import { ActionButton, colors, Icon } from '@manulife/mux';
import { useTranslation } from 'react-i18next';
import { Delete, Edit, Export } from '@manulife/mux-cds-icons';
import { staticCommonLabelKeys } from '../../moduleConstants';
import FasatRBAC from '../fasatRBAC/fasatRBAC';

const FasatPageTitle = ({ title, titleInItalics, ptAdd, ptEdit, ptDelete,
  handleActionBtn, addBtnText, editBtnText, deleteBtnText, editAclKey, rbacData,
  ptExport, exportBtnText }) => {
  const [t] = useTranslation();
  return (
    <div className="titleSection">
      <h1
        id="fasatPgeTitleId"
        className={`${(ptAdd || ptEdit || ptDelete) ? '' : 'ptNoAction'} fasatPageTitle`}
        data-testid="FasatPageTitleComponent"
      >
        {title}
        {titleInItalics && (
        <span>
          {' '}
          {titleInItalics}
        </span>
        )}
      </h1>
      <div className="ptAction">
        { ptAdd && (
        <ActionButton
          ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_ADD)}
          icon={<Icon manulifeName="plus" fill={colors.m_green} />}
          hoverIcon={<Icon manulifeName="plus" fill={colors.dark_2_green} />}
          onClick={() => handleActionBtn(addBtnText)}
          id="ptAddButton"
        >
          {t(staticCommonLabelKeys.COMMON_BUTTON_ADD)}
        </ActionButton>
        )}
        {ptEdit && (
          <FasatRBAC aclKey={editAclKey} userClaim={rbacData}>
            <ActionButton
              id="ptEditBtn"
              ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_EDIT)}
              hoverIcon={<Edit color={colors.dark_2_green} />}
              icon={<Edit color={colors.m_green} />}
              onClick={() => handleActionBtn(editBtnText)}
            >
              {t(staticCommonLabelKeys.COMMON_BUTTON_EDIT)}
            </ActionButton>
          </FasatRBAC>
        )}
        {ptDelete && (
        <ActionButton
          id="ptDeleteBtn"
          hoverIcon={<Delete color={colors.dark_2_green} />}
          icon={<Delete color={colors.m_green} />}
          onClick={() => handleActionBtn(deleteBtnText)}
          ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_DELETE)}
        >
          {t(staticCommonLabelKeys.COMMON_BUTTON_DELETE)}
        </ActionButton>
        )}
        {ptExport && (
        <ActionButton
          id="ptExportBtn"
          hoverIcon={<Export color={colors.dark_2_green} />}
          icon={<Export color={colors.m_green} />}
          onClick={() => handleActionBtn(exportBtnText)}
          ariaLabel={t(staticCommonLabelKeys.COMMON_BUTTON_EXPORT)}
        >
          {t(staticCommonLabelKeys.COMMON_BUTTON_EXPORT)}
        </ActionButton>
        )}
      </div>
    </div>
  );
};

FasatPageTitle.propTypes = {
  title: PropTypes.string,
  titleInItalics: PropTypes.string,
  handleActionBtn: PropTypes.func,
  ptAdd: PropTypes.bool,
  addBtnText: PropTypes.string,
  ptEdit: PropTypes.bool,
  editBtnText: PropTypes.string,
  ptDelete: PropTypes.bool,
  deleteBtnText: PropTypes.string,
  editAclKey: PropTypes.string,
  rbacData: PropTypes.shape({}),
  ptExport: PropTypes.bool,
  exportBtnText: PropTypes.string,
};

FasatPageTitle.defaultProps = {
  title: 'Page title',
  titleInItalics: '',
  handleActionBtn: undefined,
  ptAdd: false,
  addBtnText: 'Add',
  ptEdit: false,
  editBtnText: 'Edit',
  ptDelete: false,
  deleteBtnText: 'Delete',
  ptExport: false,
  exportBtnText: 'Export',
  editAclKey: '',
  rbacData: {},
};

export default FasatPageTitle;
