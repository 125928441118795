/**
 * @Description The file to configure i18n
 * @FileName index.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 18 January, 2021 12:50:27
 * @IssueID - 305, 311
*/
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { enLang, frLang } from './languages';
import { Logger, setNumberLocale, environment } from '../util';

const setupI18N = (resourcePath) => i18n
  .use(Backend) // using the Backend backed localization
  .use(initReactI18next)
  .init(
    {
      // resources,
      lng: 'en', // default language settings
      fallbackLng: 'en', // fall back language
      preload: ['en', 'fr'], // configured language
      ns: ['translation'], // initial namespace config
      defaultNS: 'translation', // default namespace
      // crossDomain: true, // Remove this if not required
      backend: {
        loadPath: resourcePath, // url pattern for resource files
        customHeaders: {
          'X-CSRF': '1',
        },
      },
    },
    /**
     * Initialization CallBack - we are loading additional resources
     * (local resources) in the handler. In ca
     */
    (err) => {
      if (err) {
        Logger.error(err, 'Failed to load re-sources for i18n initialization', err);
      }
      /**
       * Loading additional local resources under namespace static
       */
      i18n.addResources('en', 'static', enLang.static);
      i18n.addResources('fr', 'static', frLang.static);
    },
  );

/**
 * Setup i18n with the resource path
 */
setupI18N(environment().i18nResourcePath);

/**
 * This will change the locale and also change the
 * numeral locale for thousand and decimal separator
 * @param {string} locale
 */
const setCurrentLocale = (locale) => {
  setNumberLocale(locale);
  i18n.changeLanguage(locale);
};

/**
 * Get the current selected locale
 */
const getCurrentLocale = () => i18n.language;

/**
 * This i the wrapper method of i18n translation method.
 * Developers should use this if the translation to be used
 * from outside of a react component
 * @param {string} key
 */
const t = (key) => i18n.t(key);

/**
 * Export section for the localization infra
 */
export default i18n;
export { setupI18N, setCurrentLocale, getCurrentLocale, t };
