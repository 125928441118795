import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { repReActionTypes } from '../actions/reportRepositoryActionTypes';
import { exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

const notifyTime = 1000;
export const pdfErrorMsg = (status) => {
  if (status === globalConstants.errorCode400) {
    return [{
      errorCode: 'ERR_0201',
      errorMessage: 'SELLING_CODE_NOT_FOUND',
      errorMessageId: 'SELLING_CODE_NOT_FOUND',
      type: 'RULE',
    }];
  }
  return [{
    errorCode: 'ERR_0202',
    errorMessage: 'FILE_NOT_FOUND',
    errorMessageId: 'FILE_NOT_FOUND',
    type: 'RULE',
  }];
};

export function* getComStatementReportExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.CS_REPORT_EXPORT,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export const printpdf = () => {
  const id = 'printpdf';
  try {
    const iframe = document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();
  } catch (err) {
    // no change
  }
};

export function* getComStatementReportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, null)),
    put(getAction(repReActionTypes.LOG_CSR_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CS_REPORT_LIST, action.data);
    yield all([
      put(getAction(repReActionTypes.GET_CSR_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(repReActionTypes.GET_CSR_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getComStatementReportListExt(action) {
  yield all([
    put(processingStarted()),
    put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, null)),
    put(getAction(repReActionTypes.LOG_CSR_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CS_REPORT_LIST_EXT, action.data);
    yield all([
      put(getAction(repReActionTypes.GET_CSR_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(repReActionTypes.GET_CSR_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getCompensationStatementPDFDownload(action) {
  const { payload } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.COMPENSATION_STATEMENT_DOWNLOAD,
      payload,
    );
    const filename = renderFileName(responseData);
    const reader = new FileReader();
    reader.readAsDataURL(responseData.data);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    try {
      yield call(
        invokeApi,
        moduleAPI.COMPENSATION_STATEMENT_DOWNLOAD_POST,
        payload,
      );
    } catch (error) {
      resetServerValue = { serverRequestError: processError(error) };
      yield all([
        put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, resetServerValue)),
        put(processingCompleted()),
      ]);
    }
  }
}

export function* getCompensationStatementPDFPrint(action) {
  const { payload } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.COMPENSATION_STATEMENT_DOWNLOAD,
      payload,
    );

    const file = window.URL.createObjectURL(responseData.data);
    const id = 'printpdf';
    const iframe = document.getElementById(id);
    if (iframe?.src) {
      iframe.src = file;
    }
    setTimeout(() => printpdf(), notifyTime);
    yield put(processingCompleted());
  } catch (err) {
    try {
      yield call(
        invokeApi,
        moduleAPI.COMPENSATION_STATEMENT_DOWNLOAD_POST,
        payload,
      );
    } catch (error) {
      resetServerValue = { serverRequestError: processError(error) };
      yield all([
        put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, resetServerValue)),
        put(processingCompleted()),
      ]);
    }
  }
}

export function* getCompensationStatementPDFView(action) {
  const { payload, setModalOpen } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.COMPENSATION_STATEMENT_DOWNLOAD,
      payload,
    );
    const file = window.URL.createObjectURL(responseData.data);
    const id = 'printmodalpdf';
    const iframe = document.getElementById(id);
    if (iframe?.src) {
      iframe.src = file;
    }
    yield put(processingCompleted());
  } catch (err) {
    setModalOpen(false);
    try {
      yield call(
        invokeApi,
        moduleAPI.COMPENSATION_STATEMENT_DOWNLOAD_POST,
        payload,
      );
    } catch (error) {
      resetServerValue = { serverRequestError: processError(error) };
      yield all([
        put(getAction(repReActionTypes.COMP_RESET_SERVER_VALUE, resetServerValue)),
        put(processingCompleted()),
      ]);
    }
  }
}

export function* getDBSystemDate(action) {
  const { setDbSystemDate } = action.data;
  yield all([
    put(processingStarted()),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_DB_SYSTEM_DATE, action.data);
    setDbSystemDate(responseData);
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(repReActionTypes.GET_CSR_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

function* reportRepositoryWatcher() {
  yield takeEvery(repReActionTypes.GET_CSR_LIST_REQUEST, getComStatementReportList);
  yield takeEvery(repReActionTypes.GET_CSR_EXPORT_REQUEST, getComStatementReportExportList);

  yield takeEvery(repReActionTypes.GET_CSR_LIST_REQUEST_EXT, getComStatementReportListExt);

  yield takeEvery(repReActionTypes.COMP_STATEMENT_DOWNLOAD, getCompensationStatementPDFDownload);
  yield takeEvery(repReActionTypes.COMP_STATEMENT_PRINT, getCompensationStatementPDFPrint);
  yield takeEvery(repReActionTypes.COMP_STATEMENT_VIEW, getCompensationStatementPDFView);

  yield takeEvery(repReActionTypes.GET_DB_SYSTEM_DATE, getDBSystemDate);
}

export default reportRepositoryWatcher;
