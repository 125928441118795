/**
 * @Description Menu configuartion for module agency
 * @FileName menuConfig.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:39:31
 * @IssueID NA
 */
import { colors } from '@manulife/mux';
import { Gear } from '@manulife/mux-cds-icons';
import { t } from '../../../common/i18n';
import { navigate } from '../../../common/util';
import { aclKeysConstant, routeConstant, staticLabelKeys } from '../moduleConstants';

const getProductConfigSubMenu = () => [
  {
    id: 'PLAN_CODE_MENU',
    label: t(staticLabelKeys.PLAN_CODE_MENU),
    urlPath: routeConstant.PLAN_CODE_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.PLAN_CODE_ROUTE),
    aclKey: [aclKeysConstant.PLC_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'INTERFACE_MAPPING_MENU',
    label: t(staticLabelKeys.INTERFACE_MAPPING_MENU),
    urlPath: routeConstant.INTERFACE_MAPPING_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.INTERFACE_MAPPING_ROUTE),
    aclKey: [aclKeysConstant.RBAC_IM_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'TRANSACTION_DEFINITION_MENU',
    label: t(staticLabelKeys.TRANSACTION_DEFINITION_MENU),
    urlPath: routeConstant.TRANSACTION_DEFINITION_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.TRANSACTION_DEFINITION_ROUTE),
    aclKey: [aclKeysConstant.RBAC_TD_MENU],
    acl: true,
    showInBatchProcess: false,
  },
];

const getSystemConfigSubMenu = () => [
  {
    id: 'GENERIC_EDIT_MENU',
    label: t(staticLabelKeys.GEN_EDIT_MENU),
    urlPath: routeConstant.GEN_EDIT_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.GEN_EDIT_ROUTE),
    aclKey: [aclKeysConstant.GEN_EDIT],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'PERIOD_DEFINITION_MENU',
    label: t(staticLabelKeys.PERIOD_DEFINITION_MENU),
    urlPath: routeConstant.PERIOD_DEFINITION_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.PERIOD_DEFINITION_ROUTE),
    aclKey: [aclKeysConstant.RBAC_PD_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'SYSTEM_PARAMETER_MENU',
    label: t(staticLabelKeys.SYSTEM_PARAMETER_MENU),
    urlPath: routeConstant.SYSTEM_PARAMETER_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.SYSTEM_PARAMETER_ROUTE),
    aclKey: [aclKeysConstant.SYSPARMS_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'SYSTEM_OPTIONS_MENU',
    label: t(staticLabelKeys.RBAC_SO_MENU),
    urlPath: routeConstant.SYSTEM_OPTIONS_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.SYSTEM_OPTIONS_ROUTE),
    aclKey: [aclKeysConstant.RBAC_SO_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'NOTIFICATION_MENU',
    label: t(staticLabelKeys.RBAC_NF_MENU),
    urlPath: routeConstant.NOTIFICATIONS_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.NOTIFICATIONS_ROUTE),
    aclKey: [aclKeysConstant.RBAC_NF_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'FIELD_FORCE_STRUCTURE_MENU',
    label: t(staticLabelKeys.FFS_PAGE_TITLE),
    urlPath: routeConstant.FIELD_FORCE_STRUCTURE_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.FIELD_FORCE_STRUCTURE_ROUTE),
    aclKey: [aclKeysConstant.RBAC_FFS_MENU],
    acl: true,
    showInBatchProcess: false,
  },
];

const getReportingConfigSubMenu = () => [
  {
    id: 'INCOME_EXPENSE_MAP_MENU',
    label: t(staticLabelKeys.INCOME_EXPENSE_MAP_MENU),
    urlPath: routeConstant.INCOME_EXPENSE_MAP_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.INCOME_EXPENSE_MAP_ROUTE),
    aclKey: [aclKeysConstant.RBAC_IEM_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'GENERIC_EDIT_RELATIONSHIP_MENU',
    label: t(staticLabelKeys.GENERIC_EDIT_RELATIONSHIP_MENU),
    urlPath: routeConstant.GENERIC_EDIT_RELATIONSHIP_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.GENERIC_EDIT_RELATIONSHIP_ROUTE),
    aclKey: [aclKeysConstant.RBAC_GER_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'PRODUCER_AUDIT_TRAIL_MENU',
    label: t(staticLabelKeys.PAT_MENU),
    urlPath: routeConstant.PRODUCER_AUDIT_TRAIL_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.PRODUCER_AUDIT_TRAIL_ROUTE),
    aclKey: [aclKeysConstant.RBAC_PAT_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'DATA_DICTIONARY_MENU',
    label: t(staticLabelKeys.DATA_DICTIONARY_MENU),
    urlPath: routeConstant.DATA_DICTIONARY_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.DATA_DICTIONARY_ROUTE),
    aclKey: [aclKeysConstant.DD_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'CONFIGURE_LETTERS_MENU',
    label: t(staticLabelKeys.CONFIGURE_LETTERS_MENU),
    urlPath: routeConstant.CONFIGURE_LETTERS_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.CONFIGURE_LETTERS_ROUTE),
    aclKey: [aclKeysConstant.RBAC_CLTR_MENU],
    acl: true,
    showInBatchProcess: false,
  },
];

const getDailyProcessConfigSubMenu = () => [
  {
    id: 'DAILY_PROCESS_MENU',
    label: t(staticLabelKeys.DP_MENU),
    urlPath: routeConstant.BATCH_PROCESSING_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.BATCH_PROCESSING_ROUTE),
    aclKey: [aclKeysConstant.RBAC_DP_MENU],
    acl: true,
    showInBatchProcess: true,
  },
  {
    id: 'INTERFACE_SETUP_MENU',
    label: t(staticLabelKeys.IFS_TITLE_INTERFACESETUP),
    urlPath: routeConstant.INTERFACE_SETUP_ROUTE,
    url: '#',
    action: () => navigate('#'),
    aclKey: [aclKeysConstant.RBAC_IS_MENU],
    acl: true,
  },
];

const getPaymentConfigSubMenu = () => [
  {
    id: 'PAYMENT_THRESHOLD_MENU',
    label: t(staticLabelKeys.PAYMENT_THRESHOLD_MENU),
    urlPath: routeConstant.PAYMENT_THRESHOLD_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.PAYMENT_THRESHOLD_ROUTE),
    aclKey: [aclKeysConstant.RBAC_PTH_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  // {
  //   id: 'CHEQUE_SYSTEM_MENU',
  //   label: t(staticLabelKeys.CHEQUE_SYSTEM_MENU),
  //   url: '#',
  //   action: () => navigate(routeConstant.CHEQUE_SYSTEM_ROUTE),
  //   aclKey: [],
  //   acl: true,
  // },
];

const getSecurityMenu = () => [
  {
    id: 'CHEQUE_SECURITY_MENU',
    label: t(staticLabelKeys.CHEQUE_SECURITY_MENU),
    urlPath: routeConstant.CHEQUE_SECURITY_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.CHEQUE_SECURITY_ROUTE),
    aclKey: [aclKeysConstant.RBAC_CHS_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'MANAGE_GROUP_MENU',
    label: t(staticLabelKeys.MG_TITLE_LIST),
    urlPath: routeConstant.SECURITY_SETTINGS_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.SECURITY_SETTINGS_ROUTE),
    aclKey: [aclKeysConstant.RBAC_MG_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'SECURITY_USER_MENU',
    label: t(staticLabelKeys.SSU_TITLE_MANAGE_USER),
    urlPath: routeConstant.MANAGE_USER_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.MANAGE_USER_ROUTE),
    aclKey: [aclKeysConstant.RBAC_SSU_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'MANAGE_LEVEL_MENU',
    label: t(staticLabelKeys.MGL_TITLE_MANAGE_LEVEL),
    urlPath: routeConstant.MANAGE_LEVEL_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.MANAGE_LEVEL_ROUTE),
    aclKey: [aclKeysConstant.RBAC_MG_MENU],
    acl: true,
    showInBatchProcess: false,
  },
  {
    id: 'FIELD_FORCE_SECURITY_MENU',
    label: t(staticLabelKeys.FFSE_LABEL_FIELD_FORCE_SECURITY),
    urlPath: routeConstant.FIELD_FORCE_SECURITY_ROUTE,
    url: '#',
    action: () => navigate(routeConstant.FIELD_FORCE_SECURITY_ROUTE),
    aclKey: [aclKeysConstant.RBAC_FFSE_MENU],
    acl: true,
    showInBatchProcess: false,
  },
];

const menus = () => [
  {
    id: 'ADMIN_MENU',
    sequence: 99,
    icon: <Gear style={{ width: '20px', height: '20px' }} color={colors.light_1_gold} />,
    label: t(staticLabelKeys.ADMIN_MENU),
    aria: t(staticLabelKeys.ADMIN_MENU),
    urlPath: '#',
    url: '#',
    action: () => navigate('#'),
    aclKey: [aclKeysConstant.ADMIN_MENU],
    acl: true,
    showInBatchProcess: true,
    subMenu: [
      {
        id: 'SYS_CONFIG_MENU',
        label: t(staticLabelKeys.SYS_CONFIG_MENU),
        urlPath: '#',
        url: '#',
        action: () => navigate('#'),
        aclKey: [aclKeysConstant.RBAC_SYS_CONFIG_MENU],
        acl: true,
        showInBatchProcess: false,
        subMenu: getSystemConfigSubMenu(),
      }, {
        id: 'PRODUCT_CONFIG_MENU',
        label: t(staticLabelKeys.PRODUCT_CONFIG_MENU),
        urlPath: '#',
        url: '#',
        action: () => navigate('#'),
        aclKey: [aclKeysConstant.RBAC_PROD_CONFIG_MENU],
        acl: true,
        showInBatchProcess: false,
        subMenu: getProductConfigSubMenu(),
      }, {
        id: 'PAYMENT_CONFIGURATION_MENU',
        label: t(staticLabelKeys.PAYMENT_CONFIGURATION_MENU),
        urlPath: '#',
        url: '#',
        action: () => navigate('#'),
        aclKey: [aclKeysConstant.RBAC_PAY_CONFIG_MENU],
        acl: true,
        showInBatchProcess: false,
        subMenu: getPaymentConfigSubMenu(),
      }, {
        id: 'REPORTING_MENU',
        label: t(staticLabelKeys.REPORTING_MENU),
        urlPath: '#',
        url: '#',
        action: () => navigate('#'),
        aclKey: [aclKeysConstant.REPORTING_MENU],
        acl: true,
        showInBatchProcess: false,
        subMenu: getReportingConfigSubMenu(),
      }, {
        id: 'DAILY_PROCESS_MAIN_MENU',
        label: t(staticLabelKeys.DP_MAIN_MENU),
        urlPath: '#',
        url: '#',
        action: () => navigate('#'),
        aclKey: [aclKeysConstant.RBAC_AUTO_PROCESSING_MENU],
        acl: true,
        showInBatchProcess: true,
        subMenu: getDailyProcessConfigSubMenu(),
      },
      {
        id: 'SECURITY_MENU',
        label: t(staticLabelKeys.SECURITY_MENU),
        urlPath: '#',
        url: '#',
        action: () => navigate('#'),
        aclKey: [aclKeysConstant.RBAC_SECURITY_SETUP_MENU],
        acl: true,
        showInBatchProcess: false,
        subMenu: getSecurityMenu(),
      }],
  },
];
const mobileMenus = menus;
export { menus, mobileMenus };
