/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { finArrangementsAdjActionTypes } from '../actions/actionTypes';

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
});
const updateFilterConfigResetSelectedFilter = (state, action) => ({
  ...state,
  selectedFilter: null,
  systemDefaultFilter: action.data.systemDefault || null,
  userDefaultFilter: action.data.userDefault || null,
});
const FinArrangements1 = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case finArrangementsAdjActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case finArrangementsAdjActionTypes.SET_FINARRANGEMENTS_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case finArrangementsAdjActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        upsertDefaultData: action.data.upsertDefaultData,
        loading: false,
      };
    case finArrangementsAdjActionTypes.SET_TRANSFER_AGENT_NAME:
      return {
        ...state,
        transferAdvisorName: action.data.transferAdvisorName,
      };
    case finArrangementsAdjActionTypes.SET_FINARRANGEMENTS_TAB_VISIBILITY_REQUEST:
      return {
        ...state,
        isFinArrangementTabVisible: action.data.tabVisibility,
      };
    case finArrangementsAdjActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    default:
      return null;
  }
};
const FinArrangements2 = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case finArrangementsAdjActionTypes.UPSERT_FINARRANGEMENTS_REQUEST:
      return {
        ...state,
        upsertRequestedOn: new Date(),
        upsertData: action.data.upsertData,
        loading: true,
      };
    case finArrangementsAdjActionTypes.LOG_FINARRANGEMENTS_DETAIL_REQUEST:
      return {
        ...state,
        detailRequestedOn: new Date(),
        loading: true,
      };
    case finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_BY_ID_RESPONSE:
      return {
        ...state,
        detailDataById: action?.data || null,
        detailByIdRequestProcessedOn: new Date(),
        loading: false,
      };
    case finArrangementsAdjActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    case finArrangementsAdjActionTypes.UPDATE_FILTER_CONFIG_RESET_SELECTED:
      return updateFilterConfigResetSelectedFilter(state, action);
    default:
      return null;
  }
};
const FinArrangements = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  const stateReqRes = FinArrangements1(state, action);
  const stateConfig = FinArrangements2(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default FinArrangements;
