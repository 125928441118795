/**
 * @Description field force module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { cliLicActionTypes, fieldForceActionTypes, fieldForceDeductionActionTypes, fieldForceMovementActionTypes } from '../actions/actionTypes';
import { processError, renderFileName, exportErrorMsg } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';
import { getCurrentLocale } from '../../../../common/i18n';

export function* getFFDeductionList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.GET_FF_TASK_DETAIL_TAB_ERROR, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_DEDUCTION_LIST, action.data);
    yield all([
      put(getAction(fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(cliLicActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFTaskLookUp(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.RESET_FF_TASK_LOOKUPDATA, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_TASK_LOOKUPS_DATA, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_TASK_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_TASK_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFLookUpsData(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_LOOKUPS_DATA, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for BCR Listing
  */
export function* getFFList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_LIST_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceActionTypes.LOG_FF_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_LIST,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceActionTypes.GET_FF_LIST_ERROR, err)),
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getFFGeneral(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_GENERAL_DETAIL.endpoint;
  try {
    const { param } = action.data;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_GENERAL_DETAIL.endpoint + param;
    moduleAPI.ADMINISTRATION_FF_GET_GENERAL_DETAIL.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_GENERAL_DETAIL);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_GENERAL_DETAIL.endpoint = initApiUrl;

    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_GENERAL_DETAIL.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* updateFF(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null))]);
  try {
    let responseData;
    if (action.data.addNewFF) {
      responseData = yield call(invokeApi,
        moduleAPI.ADMINISTRATION_FF_ADD,
        action.data.formatObject);
    } else {
      responseData = yield call(invokeApi,
        moduleAPI.ADMINISTRATION_FF_EDIT,
        action.data.formatObject);
    }
    if (responseData && responseData.isReptCtrlChanged === true) {
      yield put(getAction(fieldForceActionTypes.GET_JOB_STATUS, responseData));
    } else {
      const resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(fieldForceActionTypes.GET_FF_DETAIL_RESPONSE, action.data.reqObject));
      yield all([
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* getJobStatusFF(action) {
  yield all([put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null))]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_JOBSTATUS,
      action.data.changeInfo);
    if (responseData) {
      const resetUpsertValue = {
        upsertRequest: action.data,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        jobStatusFF: responseData,
        upsertData: action.data,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
    }
    if (responseData.status === 'COMPLETED') {
      const reqPayLoad = {
        level: action.data.orgLevel,
        sellingCode: action.data.orgCode,
        language: getCurrentLocale(),
      };
      yield all([
        put(processingCompleted()),
        put(getAction(fieldForceActionTypes.GET_FF_HEADER_REQUEST, reqPayLoad)),
      ]);
    }
  } catch (err) {
    const resetUpsertValue = {
      upsertRequest: action.data,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* generateSecWealthCode(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint;
  try {
    const { param, type } = action.data;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint + param;
    moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID);
    const resObj = { responseData, type };
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_GEN_SEC_WEALTH_CODE_RES, resObj)),
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFSecWealthCode(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_SEC_WEALTH_BY_CODE,
      action.data);
    const resDataWithPayload = { responseData, payload: action.data };
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_SEC_WEALTH_RESPONSE, resDataWithPayload)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFGetJobStatusTimer(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_SEC_WEALTH_BY_CODE,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_SEC_JOB_STATUS_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFOrgStruct(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_ORG_STRUCT_BY_ID,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_ORG_STRUCT_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getPayeeData(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint;
  try {
    const { param, type } = action.data;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint + param;
    moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID);
    const resObj = { responseData, type };
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_PAYEE_RESPONSE, resObj)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_FF_PAYEE_RESPONSE)),
      put(getAction(fieldForceActionTypes.RESET_FF_PAYEE_NAME_RESPONSE)),
      put(processingCompleted())]);
  }
}

export function* getGstStatus(action) {
  const { param, type } = action.data;
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint;
  try {
    const updatedApiUrlDt = moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint + param;
    moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint = updatedApiUrlDt;
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID);
    const resObject = { responseData, type };
    moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_GST_STATUS_RESPONSE, resObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    moduleAPI.ADMINISTRATION_FF_GET_VALUE_BY_ID.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(getAction(fieldForceActionTypes.RESET_FF_PAYEE_RESPONSE)),
      put(processingCompleted())]);
  }
}

export function* getFFValidateStatus(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_VALIDATE_STATUS.endpoint;
  const { param, type, payload } = action.data;
  try {
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_VALIDATE_STATUS.endpoint + param;
    moduleAPI.ADMINISTRATION_FF_VALIDATE_STATUS.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_VALIDATE_STATUS,
      payload);
    const resObj = { responseData, type };
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_VALIDATE_STATUS.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_VALIDATE_STATUS_RESPONSE, resObj)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_VALIDATE_STATUS.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_VALIDATE_STATUS_RESPONSE, { type })),
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFSysOption(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_GET_FF_SYS_OPTIONS,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_SYS_OPTION_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFServerDate() {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_GET_FF_SERVER_DATE);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_SERVER_DATE_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFHeader(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_HEADER,
      action.data);
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_HEADER_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* deleteFFList(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_DELETE, action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequestedOn: null,
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(fieldForceActionTypes.RESET_UPSERT_ERROR, resetUpsertValue)),
      put(getAction(fieldForceActionTypes.GET_FF_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequestedOn: new Date(),
      deleteRequestError: processError(err),
      deleteRequestProcessedOn: null,
      deleteRequest: null,
      deleteData: null,
    };
    resetUpsertValue = {
      upsertRequestError: processError(err),
      upsertRequestProcessedOn: null,
      upsertRequestedOn: null,
      upsertRequest: null,
      upsertData: null,
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deletetatusFF(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_STATUS, action.data);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteStatus: responseData,
        deleteRequestProcessedOn: new Date(),
        deleteData: null,
      };
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(fieldForceActionTypes.RESET_UPSERT_ERROR, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteData: null,
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
    };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
      upsertRequestError: processError(err),
    };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFExportList(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null))]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

// address
export function* getFFAddress(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint;
  try {
    const queryParam = `?orgLevel=${action.data.orgLevel}&orgCode=${action.data.orgCode}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_ADDRESS);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* updateFFAddress(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null))]);
  try {
    let responseData;
    if (action.data.addNewFF) {
      responseData = yield call(invokeApi,
        moduleAPI.ADMINISTRATION_FF_ADD_ADDRESS,
        action.data.formatObject);
    } else {
      responseData = yield call(invokeApi,
        moduleAPI.ADMINISTRATION_FF_EDIT_ADDRESS,
        action.data.formatObject);
    }
    if (responseData) {
      const resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFAddressByClientID(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADDRESS_BY_CLIENTID.endpoint;
  try {
    const queryParam1 = `?clientNum=${action.data.clientNum}&addrType=${action.data.addrType}`;
    const queryParam2 = `&addrSeq=${action.data.addrSeq}&addrSubType=${action.data.addrSubType}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADDRESS_BY_CLIENTID.endpoint + queryParam1 + queryParam2;
    moduleAPI.ADMINISTRATION_FF_GET_ADDRESS_BY_CLIENTID.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_ADDRESS_BY_CLIENTID);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_ADDRESS_BY_CLIENTID.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_ADDRESS_BY_CLIENTID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_HEADER.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFAddressLookUpsDataP(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_LOOKUPS_DATA, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_ADDRESS_LOOKUP_P_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_ADDRESS_LOOKUP_P_RESPONSE, null)),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFAddressLookUpsDataS(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_LOOKUPS_DATA, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_ADDRESS_LOOKUP_S_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_ADDRESS_LOOKUP_S_RESPONSE, null)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * Set Address concurrency
 *
 * @export
 * @param {*} action metadata
 */
export function* setFFAddressConcurrency(action) {
  yield put(processingStarted());
  try {
    yield call(invokeApi, moduleAPI.FF_ADDRESS_LIST_BY_ID, action.data);
    yield all([put(processingCompleted())]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_CONCURRENCY_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFDeductionDetails(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceActionTypes.GET_FF_TRAN_DETAILS_RESPONSE, null)),
    put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE_ITEM, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.FF_DEDUCTION_TRAN_LIST, action.data);
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_TRAN_DETAILS_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE_ITEM, resetServerValue)),
      put(processingCompleted())]);
  }
}

// banking
export function* getFFBanking(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING.endpoint;
  try {
    const queryParam = `?orgLevel=${action.data.orgLevel}&orgCode=${action.data.orgCode}&lang=${action.data.locale}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_BANKING.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BANKING);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* updateFFBanking(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.RESET_SERVER_VALUE, null))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_EDIT_BANKING.endpoint;
  try {
    const queryParam = `?lang=${action.data.locale}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_EDIT_BANKING.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_EDIT_BANKING.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_EDIT_BANKING,
      action.data.formatObject);
      // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_EDIT_BANKING.endpoint = initApiUrl;
    if (responseData) {
      const resetUpsertValue = {
        upsertRequest: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
        upsertRequestError: null,
      };
      yield put(getAction(fieldForceActionTypes.RESET_UPSERT_VALUE, resetUpsertValue));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    moduleAPI.ADMINISTRATION_FF_EDIT_BANKING.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

export function* getFFBankingOrsPropay(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_ORS_PROPAY.endpoint;
  try {
    const queryParam = `?field=${action.data.field}&inParams=${action.data.inParams}`;// UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_ORS_PROPAY.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_ORS_PROPAY.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_ORS_PROPAY);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_ORS_PROPAY.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_BANKING_ORS_PROPAY_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_ORS_PROPAY.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFBankingPayeeStatus(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_IS_PAYEE.endpoint;
  try {
    const queryParam = `?field=${action.data.field}&inParams=${action.data.inParams}`;// UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_IS_PAYEE.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_IS_PAYEE.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_IS_PAYEE);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_IS_PAYEE.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_BANKING_PAYEE_STATUS_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_IS_PAYEE.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFBankingPayeeCode(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_CODE.endpoint;
  try {
    const queryParam = `?field=${action.data.field}&inParams=${action.data.inParams}`;// UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_CODE.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_CODE.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_CODE);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_CODE.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_BANKING_PAYEE_CODE_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_CODE.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFBankingPayeeName(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_NAME.endpoint;
  try {
    const queryParam = `?field=${action.data.field}&inParams=${action.data.inParams}`;// UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_NAME.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_NAME.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_NAME);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_NAME.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_BANKING_PAYEE_NAME_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_PAYEE_NAME.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFBankingBranchName(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_BRANCH_NAME.endpoint;
  try {
    const queryParam = `?field=${action.data.field}&inParams=${action.data.inParams}`;// UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_BRANCH_NAME.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_BRANCH_NAME.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_BRANCH_NAME);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_BRANCH_NAME.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(fieldForceActionTypes.GET_FF_BANKING_BRANCH_NAME_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_BANKING_GET_BRANCH_NAME.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFBankingAddress(action) {
  yield all([put(processingStarted()), put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST))]);
  // INIT END POINT URL
  const initApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint;
  try {
    const queryParam = `?orgLevel=${action.data.orgLevel}&orgCode=${action.data.orgCode}`;
    // UPDATED END POINT URL
    const updatedApiUrl = moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint + queryParam;
    moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint = updatedApiUrl;
    // API CALL
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FF_GET_ADDRESS);
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint = initApiUrl;
    yield all([
      put(getAction(fieldForceActionTypes.RESET_FF_DETAIL_ERROR)),
      put(getAction(
        fieldForceActionTypes.GET_FF_BANKING_ADDRESS_RESPONSE,
        responseData.clientAddress?.personalId,
      )),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // RESET END POINT URL
    moduleAPI.ADMINISTRATION_FF_GET_ADDRESS.endpoint = initApiUrl;
    yield all([put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

export function* getFFBankingSystemOptions(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_GET_FF_SYS_OPTIONS, action.data);
    const sodDftPaytMthd = responseData.filter((lookUpItem) => lookUpItem.key === 'sod_dft_payt_mthd');
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_BANKING_SYSTEM_OPTIONS_RESPONSE, sodDftPaytMthd[0].value)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_BANKING_SYSTEM_OPTIONS_RESPONSE, null)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * Set Banking concurrency
 *
 * @export
 * @param {*} action metadata
 */
export function* setFFBankingConcurrency(action) {
  yield put(processingStarted());
  try {
    yield call(invokeApi, moduleAPI.FF_BANKING_LIST_BY_ID, action.data);
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_CONCURRENCY_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}
// Field Force movement
export function* getFieldForceMovementList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceMovementActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(fieldForceMovementActionTypes.LOG_FIELDFORCEMOVEMENT_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FIELD_FORCE_MOVEMENT_LIST,
      action.data);
    yield all([
      put(getAction(fieldForceMovementActionTypes.GET_FIELDFORCEMOVEMENT_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(fieldForceMovementActionTypes.GET_FIELDFORCEMOVEMENTR_LIST_ERROR, err)),
      put(getAction(fieldForceMovementActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}
export function* getFieldForceMovementExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(fieldForceMovementActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.FIELD_FORCE_MOVEMENT_EXPORT_LIST, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(fieldForceMovementActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
 * Set General concurrency
 *
 * @export
 * @param {*} action metadata
 */
export function* setFFGenralConcurrency(action) {
  yield put(processingStarted());
  try {
    yield call(invokeApi, moduleAPI.FF_GENERAL_LIST_BY_ID, action.data);
    yield all([put(processingCompleted())]);
  } catch (err) {
    yield all([
      put(getAction(fieldForceActionTypes.GET_FF_CONCURRENCY_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcherFF() {
  // Field force
  yield takeEvery(fieldForceActionTypes.GET_FF_LOOKUP_REQUEST, getFFLookUpsData);
  yield takeEvery(fieldForceActionTypes.GET_FF_LIST_REQUEST, getFFList);
  yield takeEvery(fieldForceActionTypes.DELETE_FF_REQUEST, deleteFFList);
  yield takeEvery(fieldForceActionTypes.GET_FF_EXPORT_REQUEST, getFFExportList);
  yield takeEvery(fieldForceActionTypes.GET_FF_HEADER_REQUEST, getFFHeader);
  yield takeEvery(fieldForceActionTypes.GET_FF_GENERAL_DETAIL_REQUEST, getFFGeneral);
  yield takeEvery(fieldForceActionTypes.GET_FF_SEC_WEALTH_CODE, getFFSecWealthCode);
  yield takeEvery(fieldForceActionTypes.GET_FF_SEC_JOB_STATUS, getFFGetJobStatusTimer);
  yield takeEvery(fieldForceActionTypes.GET_JOB_STATUS, getJobStatusFF);
  yield takeEvery(fieldForceActionTypes.GET_FF_GEN_SEC_WEALTH_CODE_REQ, generateSecWealthCode);
  yield takeEvery(fieldForceActionTypes.GET_FF_ORG_STRUCT_DETAIL, getFFOrgStruct);
  yield takeEvery(fieldForceActionTypes.GET_FF_PAYEE_REQUEST, getPayeeData);
  yield takeEvery(fieldForceActionTypes.GET_FF_VALIDATE_STATUS_REQUEST, getFFValidateStatus);
  yield takeEvery(fieldForceActionTypes.GET_FF_GST_STATUS_REQUEST, getGstStatus);
  yield takeEvery(fieldForceActionTypes.GET_FF_SYS_OPTION_REQUEST, getFFSysOption);
  yield takeEvery(fieldForceActionTypes.GET_FF_SERVER_DATE_REQUEST, getFFServerDate);
  yield takeEvery(fieldForceActionTypes.UPDATE_FF_REQUEST, updateFF);
  yield takeEvery(fieldForceActionTypes.GET_FF_ADDRESS_DETAIL_REQUEST, getFFAddress);
  yield takeEvery(fieldForceActionTypes.UPDATE_FF_ADDRESS_REQUEST, updateFFAddress);
  yield takeEvery(fieldForceActionTypes.GET_FF_ADDRESS_BY_CLIENTID_REQUEST, getFFAddressByClientID);
  yield takeEvery(fieldForceActionTypes.GET_FF_ADDRESS_LOOKUP_P_REQUEST, getFFAddressLookUpsDataP);
  yield takeEvery(fieldForceActionTypes.GET_FF_ADDRESS_LOOKUP_S_REQUEST, getFFAddressLookUpsDataS);
  yield takeEvery(fieldForceActionTypes.SET_FF_ADDRESS_CONCURRENCY, setFFAddressConcurrency);
  yield takeEvery(fieldForceActionTypes.STATUS_FF_REQUEST, deletetatusFF);
  yield takeEvery(fieldForceDeductionActionTypes.GET_FF_DEDUCTION_LIST, getFFDeductionList);
  yield takeEvery(fieldForceActionTypes.GET_TASK_LOOKUP, getFFTaskLookUp);
  yield takeEvery(fieldForceActionTypes.GET_FF_TRAN_DETAILS, getFFDeductionDetails);
  yield takeEvery(fieldForceActionTypes.SET_FF_GENERAL_CONCURRENCY, setFFGenralConcurrency);

  // Banking
  yield takeEvery(fieldForceActionTypes.GET_FF_BANKING_DETAIL_REQUEST, getFFBanking);
  yield takeEvery(fieldForceActionTypes.UPDATE_FF_BANKING_REQUEST, updateFFBanking);
  yield takeEvery(fieldForceActionTypes.GET_FF_BANKING_ORS_PROPAY_REQUEST, getFFBankingOrsPropay);
  yield takeEvery(fieldForceActionTypes.GET_FF_BANKING_PAYEE_STATUS_REQUEST, getFFBankingPayeeStatus);
  yield takeEvery(fieldForceActionTypes.GET_FF_BANKING_PAYEE_CODE_REQUEST, getFFBankingPayeeCode);
  yield takeEvery(fieldForceActionTypes.GET_FF_BANKING_PAYEE_NAME_REQUEST, getFFBankingPayeeName);
  yield takeEvery(fieldForceActionTypes.GET_FF_BANKING_BRANCH_NAME_REQUEST, getFFBankingBranchName);
  yield takeEvery(fieldForceActionTypes.GET_FF_BANKING_ADDRESS_REQUEST, getFFBankingAddress);
  yield takeEvery(fieldForceActionTypes.GET_FF_BANKING_SYSTEM_OPTIONS_REQUEST, getFFBankingSystemOptions);
  yield takeEvery(fieldForceActionTypes.SET_FF_BANKING_CONCURRENCY, setFFBankingConcurrency);
  // Field Force Movement
  yield takeEvery(fieldForceMovementActionTypes.GET_FIELDFORCEMOVEMENT_LIST_REQUEST, getFieldForceMovementList);
  yield takeEvery(fieldForceMovementActionTypes.GET_FIELDFORCEMOVEMENT_EXPORT_REQUEST, getFieldForceMovementExportList);
}

export default moduleWatcherFF;
