import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { chDetailsActionTypes } from '../actions/actionTypes';

const initialState = {
  activityDetailsList: null,
};

const DetailsActivityReducer = (state = { ...initialState }, action = null) => {
  switch (action.type) {
    case chDetailsActionTypes.SET_EXTERNAL_CH_DETAIL_ACTIVITY_LIST:
      return {
        ...state,
        activityDetailsList: action?.data.data,
        loading: false,
      };

    case chDetailsActionTypes.GET_EXT_REQUIREMENT_EXPORT_ERROR:
      return {
        ...state,
        extReqExportErr: action.data?.serverRequestError,
        loading: false,
      };

    case commonTypes.RESETSTORE:
      return initialState;

    default:
      return state;
  }
};

export default DetailsActivityReducer;
