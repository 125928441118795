import { environment } from '../../../common/util';

const envData = environment();

const FFApiConfig = {
  FF_NOTES_LIST_BY_ID: {
    endpoint: `${envData.apiHost}/api/agentnotes/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_ADMIN_LIST_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforceadministration/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_DEDUCTION_AGENT_NAME: {
    endpoint: `${envData.apiHost}/api/deduction/transferagt`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_DEDUCTION_TRANSACTION_LIST: {
    endpoint: `${envData.apiHost}/api/deduction/TransactionLookup`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_DEDUCTION_TRANSACTION_DELETE: {
    endpoint: `${envData.apiHost}/api/deduction`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REQUIREMENT_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcerequirements/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REQUIREMENT_DELETE: {
    endpoint: `${envData.apiHost}/api/fieldforcerequirements`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REQUIREMENT_INSERT: {
    endpoint: `${envData.apiHost}/api/fieldforcerequirements`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REQUIREMENT_UPDATE: {
    endpoint: `${envData.apiHost}/api/fieldforcerequirements`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REQUIREMENT_LIST_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforcerequirements/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REQUIREMENT_EDIT_NOTIFY: {
    endpoint: `${envData.apiHost}/api/fieldforcerequirements/EditNotify`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REQUIREMENT_EDIT_COMMENT: {
    endpoint: `${envData.apiHost}/api/fieldforcerequirements/EditComment`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REQUIREMENT_NOTIFY_NOW: {
    endpoint: `${envData.apiHost}/api/fieldforcerequirements/SaveNotify`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REL_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcerelationship/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_ADD_REL: {
    endpoint: `${envData.apiHost}/api/fieldforcerelationship`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EDIT_REL: {
    endpoint: `${envData.apiHost}/api/fieldforcerelationship`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_DELETE_REL: {
    endpoint: `${envData.apiHost}/api/fieldforcerelationship`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_REL_ADVISOR_NAME: {
    endpoint: `${envData.apiHost}/api/fieldforcerelationship/getclientname`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FINARRANGEMENT_DEDUCTION_ID: {
    endpoint: `${envData.apiHost}/api/deduction/checkEndDate`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DEDUCTION_FF_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/deduction/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_TRANSFER_MAT_LIST: {
    endpoint: `${envData.apiHost}/api/massagenttransfer/transfer`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALNCE_PERIOD_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcebalance/list/ffBalancePeriod`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALNCE_PERIOD_LIST_EXTERNAL: {
    endpoint: `${envData.apiHost}/api/fieldforcebalancetab/list/ffBalancePeriod`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALNCE_MONTHLY_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcebalance/list/ffbalancemonth`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALNCE_MONTHLY_LIST_EXTERNAL: {
    endpoint: `${envData.apiHost}/api/fieldforcebalancetab/list/ffbalancemonth`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALANCE_ITEM_BY_PERIOD: {
    endpoint: `${envData.apiHost}/api/fieldforcebalance/item/ffbalanceperiodmonth`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALANCE_ITEM_BY_PERIOD_EXTERNAL: {
    endpoint: `${envData.apiHost}/api/fieldforcebalancetab/item/ffbalanceperiodmonth`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALANCE_ITEM_BY_PERIOD_YTD: {
    endpoint: `${envData.apiHost}/api/fieldforcebalance/item/ffbalanceperiodmonthytd`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALANCE_ITEM_BY_PERIOD_YTD_EXTERNAL: {
    endpoint: `${envData.apiHost}/api/fieldforcebalancetab/item/ffbalanceperiodmonthytd`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALNCE_SUMMARY_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcebalance/list/ffbalancesummary`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_BALANCE_HIERARCHY_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcebalance/list/ffbalancehierarchy`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_BALANCE_PERIOD_MONTH: {
    endpoint: `${envData.apiHost}/api/fieldforcebalance/list/ffbalanceexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_BALANCE_PERIOD_MONTH_EXTERNAL: {
    endpoint: `${envData.apiHost}/api/fieldforcebalancetab/list/ffbalanceexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_BALANCE_SUMMARY: {
    endpoint: `${envData.apiHost}/api/fieldforcebalance/list/ffbalancesummaryexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_BALANCE_HIERARCHY: {
    endpoint: `${envData.apiHost}/api/fieldforcebalance/list/ffbalancehierarchyexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_PROD_BUIS: {
    endpoint: `${envData.apiHost}/api/ffproduction/item/ffproductionbusinessviewexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_PROD_BUIS_EXT: {
    endpoint: `${envData.apiHost}/api/ffproductiontab/item/ffproductionbusinessviewexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_PROD_HIER: {
    endpoint: `${envData.apiHost}/api/ffproduction/item/ffproductionhierarcyexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_PROD_PROD: {
    endpoint: `${envData.apiHost} /api/ffproduction/item/ffproductionproductexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_PROD_PROD_EXT: {
    endpoint: `${envData.apiHost} /api/ffproductiontab/item/ffproductionproductexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_EXPORT_PROD_MONTHCOMP: {
    endpoint: `${envData.apiHost}/api/ffproduction/item/ffproductionmonthlycompexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_REL_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcerelationship/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_TASK_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PROD_BUIS_LIST: {
    endpoint: `${envData.apiHost}/api/ffproduction/list/ffproductionbusinessview`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PRD_ITEM_BY_MONTH: {
    endpoint: `${envData.apiHost}/api/ffproduction/item/ffproductionbusinessviewmonth`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PRD_ITEM_BY_YTD: {
    endpoint: `${envData.apiHost}/api/ffproduction/item/ffproductionbusinessviewytd`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PROD_BUIS_LIST_EXT: {
    endpoint: `${envData.apiHost}/api/ffproductiontab/list/ffproductionbusinessview`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PRD_ITEM_BY_MONTH_EXT: {
    endpoint: `${envData.apiHost}/api/ffproductiontab/item/ffproductionbusinessviewmonth`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PRD_ITEM_BY_YTD_EXT: {
    endpoint: `${envData.apiHost}/api/ffproductiontab/item/ffproductionbusinessviewytd`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PROD_HIER_LWR_LIST: {
    endpoint: `${envData.apiHost}/api/ffproduction/item/ffproductionhierarchyview`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PROD_PROD_LIST: {
    endpoint: `${envData.apiHost}/api/ffproduction/item/ffproductionproduct`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PROD_PROD_LIST_EXT: {
    endpoint: `${envData.apiHost}/api/ffproductiontab/item/ffproductionproduct`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_GET_PROD_MONTHCOMP_LIST: {
    endpoint: `${envData.apiHost}/api/ffproduction/item/ffproductionmonthlycomparison`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_SYS_OPT_DETAILS: {
    endpoint: `${envData.apiHost}/api/systemoptions/view/`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_TASK_LIST_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforcetask/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_RELATIONSHIP_LIST_BY_ID: {
    endpoint: `${envData.apiHost}/api/fieldforcerelationship/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_PROD_HIER_EXPORT: {
    endpoint: `${envData.apiHost}/api/ffproduction/item/ffproductionhierarcyexportexcel`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  LICENCE_LIST: {
    endpoint: `${envData.apiHost}/api/licenceprocess/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  LICENCE_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/licenceprocess/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  LICENCE_CHANGE_STATUS: {
    endpoint: `${envData.apiHost}/api/licenceprocess/changestatus`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  LETTERS_INSERT_API: {
    endpoint: `${envData.apiHost}/api/letters/selectletter`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  LETTERS_LIST: {
    endpoint: `${envData.apiHost}/api/letters/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_LETTER_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforceletters/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_LETTER_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforceletters/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  LOCK_CHANGE_STATUS_API: {
    endpoint: `${envData.apiHost}/api/licenceprocess/items`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  DOWNLOAD_LETTERS_TEMPLATE: {
    endpoint: `${envData.apiHost}/api/letters/download`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_LETTERS_TEMPLATE_DOWNLOAD: {
    endpoint: `${envData.apiHost}/api/ffletters/downloadletters/fieldforce`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_LETTERS_LICENCE_TEMPLATE_DOWNLOAD: {
    endpoint: `${envData.apiHost}/api/ffletters/downloadletters/licence`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FF_LETTERS_MULTIPLE_TEMPLATE_DOWNLOAD: {
    endpoint: `${envData.apiHost}/api/ffletters/download`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default FFApiConfig;
