/* eslint-disable import/prefer-default-export */
/**
 * @Description Action types for
 * @FileName actionTypes.js
 * @Author Jayanta Sarkhel
 * @CreatedOn 11 February, 2022 23:55:21
 * @IssueID - Issue IDs
 */

export const reportActionTypes = {
  GET_REPORT_LIST_REQUEST: 'GET_REPORT_LIST_REQUEST',
  GET_REPORT_LIST_RESPONSE: 'GET_REPORT_LIST_RESPONSE',
  GET_REPORT_LIST_ERROR: 'GET_REPORT_LIST_ERROR',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
export const prodSummaryActionTypes = {
  LOG_PRODSUM_LIST_REQUEST: 'LOG_PRODSUM_LIST_REQUEST',
  GET_PRODSUM_LIST_REQUEST: 'GET_PRODSUM_LIST_REQUEST',
  GET_PRODSUM_LIST_RESPONSE: 'GET_PRODSUM_LIST_RESPONSE',
  GET_PRODSUM_LIST_ERROR: 'GET_PRODSUM_LIST_ERROR',
  GET_PRODSUM_LIST_LOG_REQ: 'GET_PRODSUM_LIST_LOG_REQ',
  UPDATE_PRODSUM_COLUMN_CONFIG: 'UPDATE_PRODSUM_COLUMN_CONFIG',
  UPDATE_PRODSUM_FILTER_CONFIG: 'UPDATE_PRODSUM_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_PRODSUM_TABLE_CONFIGURATION',
  SET_PRODSUM_DETAILS_RESPONSE: 'SET_PRODSUM_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_PRODSUM_USER_DEFAULT_FILTER: 'REMOVE_PRODSUM_USER_DEFAULT_FILTER',
  // Reset store
  RESET_PRODSUM_STORE: 'RESET_PRODSUM_STORE',
  // Export List for BCR
  GET_PRODSUM_EXPORT_REQUEST: 'GET_PRODSUM_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  GET_EXTERNAL_PRODSUM_LIST_REQUEST: 'GET_EXTERNAL_PRODSUM_LIST_REQUEST',
};
export const balSummaryActionTypes = {
  LOG_BALSUM_LIST_REQUEST: 'LOG_BALSUM_LIST_REQUEST',
  GET_BALSUM_LIST_REQUEST: 'GET_BALSUM_LIST_REQUEST',
  GET_BALSUM_LIST_RESPONSE: 'GET_BALSUM_LIST_RESPONSE',
  GET_BALSUM_LIST_ERROR: 'GET_BALSUM_LIST_ERROR',
  GET_BALSUM_LIST_LOG_REQ: 'GET_BALSUM_LIST_LOG_REQ',
  UPDATE_BALSUM_COLUMN_CONFIG: 'UPDATE_BALSUM_COLUMN_CONFIG',
  UPDATE_BALSUM_FILTER_CONFIG: 'UPDATE_BALSUM_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_BALSUM_TABLE_CONFIGURATION',
  SET_BALSUM_DETAILS_RESPONSE: 'SET_BALSUM_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_BALSUM_USER_DEFAULT_FILTER: 'REMOVE_BALSUM_USER_DEFAULT_FILTER',
  // Reset store
  RESET_BALSUM_STORE: 'RESET_BALSUM_STORE',
  // Export List for BCR
  GET_BALSUM_EXPORT_REQUEST: 'GET_BALSUM_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
export const reportTemplateActionTypes = {
  LOG_CS_DETAIL_REQUEST: 'LOG_CS_DETAIL_REQUEST',
  GET_CS_DETAIL_LIST_REQUEST: 'GET_CS_DETAIL_LIST_REQUEST',
  GET_CS_DETAIL_RESPONSE: 'GET_CS_DETAIL_RESPONSE',
  GET_CS_DETAIL_ERROR: 'GET_CS_DETAIL_ERROR',
  GET_CS_AGENT_NAME_RESPONSE: 'GET_CS_AGENT_NAME_RESPONSE',
  GET_NEXT_CS_AGENT_NAME_RESPONSE: 'GET_NEXT_CS_AGENT_NAME_RESPONSE',
  SET_CS_DATE_CONFLICT_RECORDS: 'SET_CS_DATE_CONFLICT_RECORDS',
  RESET_CS_DETAIL_STORE: 'RESET_CS_DETAIL_STORE',
  RESET_CS_DATE_CONFLICT_RECORDS: 'RESET_CS_DATE_CONFLICT_RECORDS',

  // Common header action types
  GET_CS_HEADER_REQUEST: 'GET_CS_HEADER_REQUEST',
  LOG_CS_HEADER_REQUEST: 'LOG_CS_HEADER_REQUEST',
  GET_CS_HEADER_DETAIL_RESPONSE: 'GET_CS_HEADER_DETAIL_RESPONSE',
  GET_CS_HEADER_DETAIL_ERROR: 'GET_CS_HEADER_DETAIL_ERROR',
  RESET_CS_HEADER_DATA: 'RESET_CS_HEADER_DATA',
};
export const commissionFeedActionTypes = {
  GET_ONCHANGE_DETAILS_REQUEST: 'GET_ONCHANGE_DETAILS_REQUEST',
  GET_ONCHANGE_DETAILS_RESPONSE: 'GET_ONCHANGE_DETAILS_RESPONSE',
  GET_GENERATE_REQUEST: 'GET_GENERATE_REQUEST',
  GET_GENERATE_RESPONSE: 'GET_GENERATE_RESPONSE',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  RESET_STORE: 'RESET_STORE',
};
export const interactiveActivityReportActionTypes = {
  GET_IAR_ACTIVITY_PERIOD_LOOKUP: 'GET_IAR_ACTIVITY_PERIOD_LOOKUP',
  SET_COMP_LIST_REQ_ERROR: 'SET_COMP_LIST_REQ_ERROR',
  SET_COMP_TYPE_LIST_RESPONSE: 'SET_COMP_TYPE_LIST_RESPONSE',
  SET_COMP_TYPE_HIER_LIST_RESPONSE: 'SET_COMP_TYPE_HIER_LIST_RESPONSE',
  SET_MANULIFE_SELLING_CODES_LIST_RESPONSE: 'SET_MANULIFE_SELLING_CODES_LIST_RESPONSE',
  SET_MANULIFE_SELLING_CODES_LIST_TOTALROWS: 'SET_MANULIFE_SELLING_CODES_LIST_TOTALROWS',
  SET_MANULIFE_SELLING_CODES_LIST_RESPONSE_EMPTY: 'SET_MANULIFE_SELLING_CODES_LIST_RESPONSE_EMPTY',
  GET_COMP_TYPE_LIST: 'GET_COMP_TYPE_LIST',
  GET_MSCodes_List: 'GET_MSCodes_List',
  SET_AP_LOOKUP_VALUES: 'SET_AP_LOOKUP_VALUES',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  GET_COMP_TYPE_HIER_LIST: 'GET_COMP_TYPE_HIER_LIST',

  EXT_IAR_COMP_EXPORT: 'EXT_IAR_COMP_EXPORT',
  EXT_IAR_COMP_EXPORT_ERROR: 'EXT_IAR_COMP_EXPORT_ERROR',
  EXT_IAR_MSC_EXPORT: 'EXT_IAR_MSC_EXPORT',
  EXT_IAR_PRODUCT_EXPORT: 'EXT_IAR_PRODUCT_EXPORT',

};
