import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { periodDefinitionActionType } from '../actions/actionTypes';
import { updateFilterConfig, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'PeriodDefinitionFilterList';

const logPdListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const PeriodDefinitionFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case periodDefinitionActionType.UPDATE_PD_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case periodDefinitionActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case periodDefinitionActionType.REMOVE_PD_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    default:
      return null;
  }
};

const PeriodDefinitionFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case periodDefinitionActionType.LOG_PD_LIST_REQUEST:
      return logPdListReq(state, action);
    // log error
    case periodDefinitionActionType.GET_PD_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case periodDefinitionActionType.GET_PD_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case periodDefinitionActionType.GET_PD_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case periodDefinitionActionType.RESET_PD_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case periodDefinitionActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const PeriodDefinitionFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = PeriodDefinitionFilterListConfig(state, action);
  const stateReqRes = PeriodDefinitionFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default PeriodDefinitionFilterList;
