/**
 * @Description French reources for administration
 * @FileName french.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:13:37
 * @IssueID NA
 */

const adminResourceFrench = {
  'administration.menu': 'F:Administration',
  // System configuration starts
  'administration.systemConfiguration.menu': 'F:System configuration',
  'administration.fieldforceStructure.menu': 'F:Field Force Structure',
  'administration.genericEdit.menu': 'F:Generic edit',
  'administration.periodDefinition.menu': 'F:Period Definition',
  'administration.systemParameters.menu': 'F:System parameters',
  'administration.systemOptions.menu': 'F:System Options',
  'administration.notification.menu': 'F:Notifications',
  // System configuration ends
  // Product configuration starts
  'administration.productConfiguration.menu': 'F:F:Product configuration',
  'administration.planCode.menu': 'F:Plan code',
  'administration.interfaceMapping.menu': 'F:Interface mapping',
  'administration.transactionDefinition.menu': 'F:Transaction definition',
  // Product configuration ends
  // Payment configuration starts
  'administration.paymentConfiguration.menu': 'F:Payment configuration',
  'administration.paymentThreshold.menu': 'F:Payment threshold',
  'administration.chequeSystem.menu': 'F:Cheque System',
  // Payment configuration ends
  // Reporting configuration starts
  'administration.reporting.menu': 'F:Reporting',
  'administration.incomeExpenseMap.menu': 'F:Income Expense Map',
  'administration.genEditRelation.menu': 'F:Generic edit relationships',
  'administration.producerAuditTrail.menu': 'F:Producer Audit Trail',
  'administration.dataDictionary.menu': 'F:Data dictionary',
  // Reporting configuration ends
  // batchProcess configuration starts
  'administration.batchProcess.menu': 'F:Batch process',
  'administration.interfaceSetup.menu': 'F:Interface Setup',
  'administration.batchMonitoring.menu': 'F:Batch Monitoring',
  // batchProcess configuration ends
  // Security configuration starts
  'administration.security.menu': 'F:Security setup',
  'administration.chequeSecurity.menu': 'F:Cheque Security',
  'administration.securitySettings.menu': 'F:Manage group',
  // Security configuration ends
  'cmn.list.show.page': 'Montrer',
  'cmn.list.record.display': 'résultats par page',
  'cmn.list.reset': 'Réinitialiser les filtres',
  'cmn.placeholder.display': 'F: Select',
  /**
     * Contents for GenEdit Type Page
     */
  'sys.param.page.title': 'F: System parameters',
  'sys.param.add.modal.title': 'F: Add system parameter',
  'sys.param.edit.modal.title': 'F: Edit system parameter',
  'sys.param.add.success': 'F: System Parameter add succesfully',
  'sys.param.edit.success': 'F: System Parameter update succesfully',
  'get.form.dropdownplaceholder': 'F: Select',
};

export default adminResourceFrench;
