/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-debugger */
/**
 * @Description Payment module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 01 March, 2023 08:51:22
 * @IssueID - 8376
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { ppDetailActionTypes } from '../actions/actionTypes';

import { exportErrorMsg, formatResponse, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

/**
   * This generator function will invoke the api
   * @param {object} action - action which will delete existing Access Options
   */
export function* deleteAccessOpts(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_ACCESSOPTS_DELETE,
      action.data.formatObject,
    );
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([put(getAction(ppDetailActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(ppDetailActionTypes.GET_ACCESSOPTS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(ppDetailActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getPaymentsProcessing(action) {
  Logger.verbose(`Action Detail For Get Rate Master List : ${JSON.stringify(action)}`);
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.PAYMENTS_PP_LIST,
      action.data);
    yield all([
      put(getAction(ppDetailActionTypes.GET_PP_STRUCTURE_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all(
      [
        put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, resetServerValue)),
        put(processingCompleted())],
    );
  }
}
export function* getPaymentsProcessingSearch(action) {
  Logger.verbose(`Action Detail For Get Rate Master List : ${JSON.stringify(action)}`);
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.PAYMENTS_PP_LIST,
      action.data);
    yield all([
      put(getAction(ppDetailActionTypes.GET_PP_STRUCTURE_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all(
      [
        put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, resetServerValue)),
        put(processingCompleted())],
    );
  }
}
export function* getPaymentsExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.PAYMENTS_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getPaymentsApprove(action) {
  yield all([
    put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, null)),
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.PAYMENTS_APPROVE,
      action.data);
    if (responseData) {
      resetUpsertValue = { serverRequestError: responseData };
      yield all([
        put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getPaymentsRemit(action) {
  yield all([
    put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, null)),
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.PAYMENTS_REMIT,
      action.data);
    if (responseData) {
      resetUpsertValue = { serverRequestError: responseData };
      yield all([
        put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* getPaymentsCancel(action) {
  yield all([
    put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, null)),
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.PAYMENTS_CANCEL,
      action.data);
    if (responseData) {
      resetUpsertValue = { serverRequestError: responseData };
      yield all([
        put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(ppDetailActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
function* moduleWatcher() {
  yield takeEvery(ppDetailActionTypes.GET_PP_LIST_REQUEST, getPaymentsProcessing);
  yield takeEvery(ppDetailActionTypes.GET_PP_SEARCH_LIST_REQUEST, getPaymentsProcessingSearch);
  yield takeEvery(ppDetailActionTypes.GET_PP_EXPORT_REQUEST, getPaymentsExportList);
  yield takeEvery(ppDetailActionTypes.GET_PP_APPROVE, getPaymentsApprove);
  yield takeEvery(ppDetailActionTypes.GET_PP_REMIT, getPaymentsRemit);
  yield takeEvery(ppDetailActionTypes.GET_PP_CANCEL, getPaymentsCancel);
}
export default moduleWatcher;
