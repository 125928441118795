import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { dataTransferActionType } from '../actions/actionTypes';

export const REDUCER_NAME = 'DataTransferFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const logDPListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const logDPStartProcesstReq = (state, action) => ({
  ...state,
  startProcessRequest: action.data ? action.data : state.listRequest,
  startProcessRequestedOn: new Date(),
  loading: true,
});

const getDtsCancelProcessResponse = (state, action) => ({
  ...state,
  getDtsCancelProcess: action.data,
});

const updateTableConfig = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  selectedFilter: action.data ? action.data : null,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const logHistoryListReq = (state, action) => ({
  ...state,
  historyRequest: action.data ? action.data : state.historyRequest,
  historyRequestedOn: new Date(),
  loading: true,
});

const dailyProcessFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case dataTransferActionType.UPDATE_DT_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case dataTransferActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case dataTransferActionType.REMOVE_DT_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case dataTransferActionType.RESET_DT_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case dataTransferActionType.RESET_DT_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    case dataTransferActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case dataTransferActionType.GET_HISTORY_DATA_RESPONSE:
      return {
        ...state,
        historyFieldData: action.data,
        historyFieldRequestError: null,
        historyFieldRequestProcessedOn: new Date(),
        loading: false,
      };
    case dataTransferActionType.LOG_HISTORY_LIST_REQUEST:
      return logHistoryListReq(state, action);
    case dataTransferActionType.GET_HISTORY_LIST_RESPONSE:
      return {
        ...state,
        historyData: action.data,
        historyRequestError: null,
        historyRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

const dailyProcessListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case dataTransferActionType.LOG_DT_LIST_REQUEST:
      return logDPListReq(state, action);
    // log error
    case dataTransferActionType.GET_DT_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case dataTransferActionType.GET_DT_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case dataTransferActionType.LOG_DT_START_PROCESS:
      return logDPStartProcesstReq(state, action);
    // log error
    case dataTransferActionType.GET_DT_START_PROCESS_ERROR:
      return {
        ...state,
        startProcessRequestError: action.data,
        startProcessRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};
const dailyProcessListREQResponse = (state = { ...basicFilterInitialState }, action = null) => {
  // eslint-disable-next-line no-debugger
  // debugger;
  switch (action.type) {
    // store response
    case dataTransferActionType.GET_DT_START_SEQUENCE_RESPONSE:
      return {
        ...state,
        startProcessData: action.data,
        startProcessRequestError: null,
        startProcessRequestProcessedOn: new Date(),
        loading: false,
      };
    case dataTransferActionType.GET_DT_START_PROCESS_TABLE_RES:
      return {
        ...state,
        startProcessTableData: action.data,
        startProcessTableError: null,
        startProcessTableProcessedOn: new Date(),
        loading: false,
      };
    case dataTransferActionType.GET_DT_CANCEL_PROCESS_RES:
      return getDtsCancelProcessResponse(state, action);
    case dataTransferActionType.GET_HISTORY_LIST_ERROR:
      return {
        ...state,
        historyRequestError: action.data,
        historyRequestProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const DataTransferFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = dailyProcessFilterListConfig(state, action);
  const stateReqRes = dailyProcessListREQRES(state, action);
  const stateReqResponse = dailyProcessListREQResponse(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  if (stateReqResponse !== null) {
    return stateReqResponse;
  }
  return state;
};

export default DataTransferFilterList;
