/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { interfaceSetupActionType } from '../actions/actionTypes';

import { formatResponse, exportErrorMsg, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

const getResetUpsertValue = (responseData, err) => ({
  upsertRequest: null,
  upsertRequestError: err ? processError(err) : null,
  upsertRequestedOn: null,
  upsertRequestProcessedOn: responseData ? new Date() : null,
  upsertData: responseData,
});

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for IM Export Listing
   */
export function* getIsExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(interfaceSetupActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_IS_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(interfaceSetupActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getIsList(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(interfaceSetupActionType.RESET_SERVER_VALUE, null)),
    put(getAction(interfaceSetupActionType.LOG_IS_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_IS_LIST, action.data);
    yield all([
      put(getAction(interfaceSetupActionType.GET_IS_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(interfaceSetupActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(interfaceSetupActionType.GET_IS_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert data for New IM by ID
  */
export function* getIsbyID(action) {
  Logger.verbose(`Action Detail For IM by ID : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(interfaceSetupActionType.RESET_SERVER_VALUE, null)),
    put(getAction(interfaceSetupActionType.LOG_IS_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_IS_BY_ID, action.data);
    yield all([
      put(getAction(interfaceSetupActionType.GET_IS_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(interfaceSetupActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert data for new Plan code
  */
export function* insertIs(action) {
  Logger.verbose(`Action Detail For Interface Setup List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_IS_INSERT,
      action.data.formatObjectAdd);
    if (responseData) {
      resetUpsertValue = getResetUpsertValue(responseData, null);
      yield all([
        put(getAction(interfaceSetupActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(interfaceSetupActionType.GET_IS_LIST_REQUEST, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = getResetUpsertValue(null, err);
    yield all([
      put(getAction(interfaceSetupActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
/**
  * This generator function will invoke the api
  * @param {object} action - action which will update data for existing Plan code
  */
export function* updateIs(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_IS_UPDATE,
      action.data.formatObjectEdit);
    if (responseData) {
      resetUpsertValue = getResetUpsertValue(responseData, null);
      yield put(getAction(interfaceSetupActionType.RESET_UPSERT_VALUE, resetUpsertValue));
      yield put(getAction(interfaceSetupActionType.GET_IS_LIST_REQUEST, action.data.reqObject));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(interfaceSetupActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* deleteIs(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_IS_DELETE,
      action.data.formatObject,
    );
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
    }
    yield all([
      put(getAction(interfaceSetupActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(interfaceSetupActionType.RESET_UPSERT_ERROR, resetUpsertValue)),
      put(getAction(interfaceSetupActionType.GET_IS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(interfaceSetupActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(interfaceSetupActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}
function* moduleWatcher() {
  // Interface Setup
  yield takeEvery(interfaceSetupActionType.GET_IS_LIST_REQUEST, getIsList);
  yield takeEvery(interfaceSetupActionType.GET_IS_BY_ID_REQUEST, getIsbyID);
  yield takeEvery(interfaceSetupActionType.INSERT_IS_REQUEST, insertIs);
  yield takeEvery(interfaceSetupActionType.UPDATE_IS_REQUEST, updateIs);
  yield takeEvery(interfaceSetupActionType.DELETE_IS_REQUEST, deleteIs);
  yield takeEvery(interfaceSetupActionType.GET_IS_EXPORT_REQUEST, getIsExportList);
}
export default moduleWatcher;
