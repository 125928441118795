import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { transactionDefinitionActionType } from '../actions/actionTypes';

const TransactionDefinitionDetailError = (state = { ...basicListAddEditDltInitialState,
  assoDetailData: null,
  editStatus: null }, action = null) => {
  switch (action.type) {
    case transactionDefinitionActionType.GET_TD_DETAIL_RESPONSE:
      return {
        ...state,
        detailData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case transactionDefinitionActionType.RESET_EDIT_STATUS:
      return {
        ...state,
        editStatus: action.data,
        loading: false,
      };
    case transactionDefinitionActionType.SET_ASSO_TD_DETAIL:
      return {
        ...state,
        assoDetailData: action.data,
        loading: false,
      };
    case transactionDefinitionActionType.RESET_TD_DETAIL_VALUE:
      return {
        ...state,
        ...action.data.tab === '' && { detailData: null },
        selectedTab: action.data.tab,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case transactionDefinitionActionType.LOG_TD_DETAIL_REQUEST:
      return {
        ...state,
        detailRequestedOn: new Date(),
        loading: true,
      };
    case transactionDefinitionActionType.GET_TD_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        serverRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case transactionDefinitionActionType.RESET_TD_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: null,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

const TransactionDefinitionUpersetDelete = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case transactionDefinitionActionType.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case transactionDefinitionActionType.SET_TD_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case transactionDefinitionActionType.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case transactionDefinitionActionType.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case transactionDefinitionActionType.RESET_TD_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

const TransactionDefinition = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  const stateReqRes = TransactionDefinitionUpersetDelete(state, action);
  const stateConfig = TransactionDefinitionDetailError(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default TransactionDefinition;
