/**
 * @Description FasatColumnOrder indexesOf File
 * @FileName indexesOf.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
/**
 * Return an array of indexes for all instances of the given value.
 *
 * @param {Array} list
 * @param {*} value
 *
 * @returns {Array}
 */
const indexesOf = (list, value) => {
  const indexes = [];
  list.forEach((listItem, index) => {
    if (listItem === value) {
      indexes.push(index);
    }
  });
  return indexes;
};

export default indexesOf;
