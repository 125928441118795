/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/**
* @Description Master page layout for the application.
* Initial file has been taken from mux-template.
* NOTE - COMPLETE DOCUMENTATION IS NOT AVAILABLE
* @FileName layout.js
* @Author  Arnab Sutar - sutaarn
* @CreatedOn 08 January, 2021 14:28:52
* @IssueID - 459
*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import {
  SideNav,
  Footer,
  MainNavItem,
  SubNavItem,
  Modal,
  SubNavDropdown,
  UtilityHeader,
  useSkipLinks,
  colors,
} from '@manulife/mux';
import { useDispatch, useSelector } from 'react-redux';
import { Email, Phone, Login1 } from '@manulife/mux-cds-icons';
import { checkDirtyData, getRBACData, releaseLockCombo, getSystemConfig, usersDataProcessing,
  getBatchProcessingState, resetStore, getLevelRBACData, getUserPrefData, getUserContactInfo } from '../../redux/actions/commonActions';
import {
  HeaderWrapper,
  LayoutContainer,
  LayoutContent,
  LayoutWrapper,
  SideNavWrapper,
} from './styledComponents';
import { setCurrentLocale, t } from '../../i18n';
import { staticCommonLabelKeys, userLock, globalConstants } from '../../moduleConstants';
import { FasatActionBtn, FasatButton, FasatModal } from '..';
import { clearToken, getLabelErr as getLabelErr1 } from '../../util';
import logger from '../../util/appLogger';
import UserContext from '../../../../userContext';
import staticCommonLabel from '../../staticCommonLabel';
import UserPrefLang from './userPrefLang';
import CancelPopup from '../../../modules/admin/components/cancelPopup/cancelPopup';

const sysConfigPayload = [globalConstants.FASAT_DEFAULT_SIGNOUT_LIMIT, globalConstants.WEB_BATCH_EXPIRY_DAYS,
  globalConstants.FASAT_DEFAULT_DATEFORMAT, globalConstants.DOWNLOAD_CENTRE_REFRESH_INTERVAL_ONLINE,
  globalConstants.FPA_THRESHOLD_TIME_LIMIT, globalConstants.FPA_PP_THRESHOLD_TIME_LIMIT,
  globalConstants.FASAT_EXTERNAL_DEFAULT_SIGNOUT_LIMIT, globalConstants.FASAT_EXTERNAL_MAX_SIGNOUT_LIMIT, globalConstants.FASAT_INTERNAL_MAX_SIGNOUT_LIMIT, globalConstants.FASAT_INTERNAL_CALLING_TIME];

const USER_LAN_SAVE = 'USER_LAN_SAVE';
const USER_LAN_CANCEL = 'USER_LAN_CANCEL';

const getLabelErr = (userPrefError, tr, labelErr, label) => {
  if (userPrefError) {
    return tr(labelErr);
  }
  return tr(label);
};
const { external } = globalConstants;
const curYear = new Date().getFullYear();
const getFooter = ({ userPrefError: err, t: tr }) => (
  <Footer
    items={[{
      ariaLabel: getLabelErr(err, tr, staticCommonLabelKeys.LABEL_LEGAL_ERR, staticCommonLabelKeys.COMMON_LABEL_LEGAL),
      href: 'https://www.manulife.com/en/legal.html',
      id: 'legal',
      isExternal: true,
      label: getLabelErr(err, tr, staticCommonLabelKeys.LABEL_LEGAL_ERR, staticCommonLabelKeys.COMMON_LABEL_LEGAL),
      title: getLabelErr(err, tr, staticCommonLabelKeys.LABEL_LEGAL_ERR, staticCommonLabelKeys.COMMON_LABEL_LEGAL),
    },
    {
      ariaLabel: getLabelErr(err, tr,
        staticCommonLabelKeys.LABEL_ACCESSIBILITY_ERR, staticCommonLabelKeys.COMMON_LABEL_ACCESSIBILITY),
      href: 'https://www.manulife.ca/about-us/accessibility.html',
      id: 'accessibility',
      isExternal: true,
      label: getLabelErr(err, tr,
        staticCommonLabelKeys.LABEL_ACCESSIBILITY_ERR, staticCommonLabelKeys.COMMON_LABEL_ACCESSIBILITY),
      title: getLabelErr(err, tr,
        staticCommonLabelKeys.LABEL_ACCESSIBILITY_ERR, staticCommonLabelKeys.COMMON_LABEL_ACCESSIBILITY),
    },
    {
      ariaLabel: getLabelErr(err, tr,
        staticCommonLabelKeys.LABEL_PRIVACY_POLICY_ERR, staticCommonLabelKeys.COMMON_LABEL_PRIVACY_POLICY),
      href: 'https://www.manulife.ca/privacy-policies.html',
      id: 'privacyPolicy',
      isExternal: true,
      label: getLabelErr(err, tr,
        staticCommonLabelKeys.LABEL_PRIVACY_POLICY_ERR, staticCommonLabelKeys.COMMON_LABEL_PRIVACY_POLICY),
      title: getLabelErr(err, tr,
        staticCommonLabelKeys.LABEL_PRIVACY_POLICY_ERR, staticCommonLabelKeys.COMMON_LABEL_PRIVACY_POLICY),
    }]}
    copyrightYear=""
    copyrightYearAria=""
    copyrightText={getLabelErr1(err, tr, curYear).replace('1999-', `1999-${curYear}`)}
  />
);
const subNavItem = (item, redirectPath, availMenus) => {
  if (availMenus.includes(item.id)) {
    return (
      <SubNavItem
        as="button"
        id={item.id}
        itemKey={item.id}
        label={item.label}
        onClick={() => redirectPath(item)}
      />
    );
  }
  return (<></>);
};
const modalSection = ({ modalOpen, modalClose, handleConfirm }) => (
  <Modal
    isOpen={modalOpen}
    onClose={modalClose}
    ariaLabel="Confirmation"
    ariaLabelledBy="heading"
    ariaDescribedBy="content"
    shouldCloseOnBackdropClick={false}
    shouldCloseWithEscapeKey={false}
    showCloseButton
  >
    <div>
      <div className="fasatConfirmMsg f-align-left" data-testid="FasatConfirmationMsgComponent">
        {t(staticCommonLabelKeys.COMMON_UNSAVED_WARNING)}
      </div>
      <div className="buttonContainer">
        <div className="left">
          <FasatButton variant="secondary" id="modalSecondaryButton" type="button" onClick={() => handleConfirm()}>
            {t(staticCommonLabelKeys.COMMON_BUTTON_LEAVE)}
          </FasatButton>
        </div>
        <div className="right">
          <FasatButton type="submit" id="modalPrimaryButton" onClick={() => modalClose()}>
            {t(staticCommonLabelKeys.COMMON_BUTTON_RETURNTOPAGE)}
          </FasatButton>
        </div>
      </div>

    </div>
  </Modal>
);
const modalWidth = '660px';
const modallogoutSection = ({ signOutmodal, modalCloselog, clickSignInOut, setSignOutModal }) => (
  <Modal
    isOpen={signOutmodal}
    onClose={modalCloselog}
    ariaLabel="Confirmation"
    ariaLabelledBy="heading"
    ariaDescribedBy="content"
    shouldCloseOnBackdropClick={false}
    shouldCloseWithEscapeKey={false}
    customStyle={{ modalStyle: { width: modalWidth } }}
    showCloseButton
  >
    <div>
      <span style={{ fontWeight: 900 }}>
        {t(staticCommonLabelKeys.CIAM_LOGOUT_DEFAULT)}
      </span>
      <p>{t(staticCommonLabelKeys.CIAM_LOGOUT_DEFAULT_WARNING)}</p>
      <div className="buttonContainer">
        <div className="left">
          <FasatButton
            type="submit"
            variant="secondary"
            id="modalPrimaryButton"
            onClick={() => setSignOutModal(false)}
          >
            {t(staticCommonLabel.CIAM_SIGN_IN)}
          </FasatButton>
        </div>
        <div className="right">
          <FasatButton
            // variant="secondary"
            id="modalSecondaryButton"
            type="button"
            onClick={() => {
              clickSignInOut();
              localStorage.setItem('logInTime', '');
              setSignOutModal(false);
            }}
          >
            {t(staticCommonLabel.CIAM_SIGN_OUT)}
          </FasatButton>
        </div>
      </div>

    </div>
  </Modal>
);
const modalSectionSignout = ({ signmodalOpen, signmodalClose, handleConfirmSignOut }) => (
  <Modal
    isOpen={signmodalOpen}
    onClose={signmodalClose}
    ariaLabel="Confirmation"
    ariaLabelledBy="heading"
    ariaDescribedBy="content"
    shouldCloseOnBackdropClick={false}
    shouldCloseWithEscapeKey={false}
    showCloseButton
  >
    <div>
      <div className="fasatConfirmMsg f-align-left" data-testid="FasatConfirmationMsgComponent">
        {t(staticCommonLabelKeys.COMMON_UNSAVED_WARNING)}
      </div>
      <div className="buttonContainer">
        <div className="left">
          <FasatButton
            variant="secondary"
            id="modalSecondaryButton"
            type="button"
            onClick={() => handleConfirmSignOut()}
          >
            {t(staticCommonLabelKeys.COMMON_BUTTON_LEAVE)}
          </FasatButton>
        </div>
        <div className="right">
          <FasatButton type="submit" id="modalPrimaryButton" onClick={() => signmodalClose()}>
            {t(staticCommonLabelKeys.COMMON_BUTTON_RETURNTOPAGE)}
          </FasatButton>
        </div>
      </div>
    </div>
  </Modal>
);
const modalLanChange = ({ lanModalOpen, langModalClose, handleLangChange }) => (
  <Modal
    isOpen={lanModalOpen}
    onClose={langModalClose}
    ariaLabel="Confirmation"
    ariaLabelledBy="heading"
    ariaDescribedBy="content"
    shouldCloseOnBackdropClick={false}
    shouldCloseWithEscapeKey={false}
    showCloseButton
    modalWidth="560px"
  >
    <div>
      <div className="fasatConfirmMsg f-align-left" data-testid="FasatConfirmationMsgComponent">
        {t(staticCommonLabelKeys.COMMON_UNSAVED_WARNING)}
      </div>
      <div className="buttonContainer">
        <div className="left">
          <FasatButton variant="secondary" id="modalSecondaryButton" type="button" onClick={() => handleLangChange()}>
            {t(staticCommonLabelKeys.COMMON_BUTTON_LEAVE)}
          </FasatButton>
        </div>
        <div className="right">
          <FasatButton type="submit" id="modalPrimaryButton" onClick={() => langModalClose()}>
            {t(staticCommonLabelKeys.COMMON_BUTTON_RETURNTOPAGE)}
          </FasatButton>
        </div>
      </div>

    </div>
  </Modal>
);
const onClickDiscard = (setCloseAddEditModal, closeAddEditModal, _history, dispatch) => {
  setCloseAddEditModal(!closeAddEditModal);
  dispatch(checkDirtyData(false));
};
const onCancelClickCancel = (setCancelDisplayModal, canceldisplayModal) => {
  setCancelDisplayModal(!canceldisplayModal);
  const saveFilterModal = document.getElementsByClassName('saveFilterModal');
  if (saveFilterModal.length > 0) {
    document.querySelector('.saveFilterModal').parentElement.style.zIndex = '99996';
  }
};
const displayCRModal = (canceldisplayModal, setCancelDisplayModal, setCloseAddEditModal,
  closeAddEditModal, history, dispatch, setLvClq) => (
    <FasatModal
      display={canceldisplayModal}
      modalWidth="560px"
      primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_RETURNTOPAGE)}
      primaryTopic="ON_SAVE_STAY"
      secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_LEAVE)}
      secondaryTopic="ON_CANCEL_LEAVE"
      openModal="OPEN_ADD_GET_MODAL"
      modalCosed={() => {
        onCancelClickCancel(setCancelDisplayModal, canceldisplayModal);
      }}
      staydiscard
      onClickDiscard={() => {
        setLvClq(true);
        onClickDiscard(setCloseAddEditModal, closeAddEditModal, history, dispatch);
      }}
      modalClass="cancelPopup modalWithoutHeading"
    >
      <div><CancelPopup onDiscardTopic="ON_SAVE_STAY" /></div>
    </FasatModal>
);
const modalLangPref = ({ langPrefModalOpen, setlangPrefModalOpen, closeAddEditModal, userlang,
  setCancelDisplayModal, canceldisplayModal }) => (
    <FasatModal
      title={t(staticCommonLabelKeys.COMMON_LABEL_USER_PREF_LANG_TITLE)}
      display={langPrefModalOpen}
      modalWidth="560px"
      primaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_SAVE)}
      secondaryButtonText={t(staticCommonLabelKeys.COMMON_BUTTON_CANCEL)}
      primaryTopic={USER_LAN_SAVE}
      secondaryTopic={USER_LAN_CANCEL}
      modalClass="saveFilterModal"
      modalCosed={() => setlangPrefModalOpen(false)}
      isCheckDirty="yes"
      onCancelClickCancel={() => onCancelClickCancel(setCancelDisplayModal, canceldisplayModal)}
    >
      <UserPrefLang
        onSaveTopic={USER_LAN_SAVE}
        onCancelTopic={USER_LAN_CANCEL}
        formData={{ userLang: userlang }}
        closeAddEditModal={closeAddEditModal}
      />
    </FasatModal>
);
export const langChangeFunc = ({ isDirtyCheck, setLabel, setlanModalOpen, setLocalLanguage, lang }) => {
  if (isDirtyCheck) {
    setLabel(lang);
    setlanModalOpen(true);
  } else {
    setLocalLanguage(lang);
  }
};
export const loggedUserCheck = ({ loggedUser, rbacData, setIsSignedInValue }) => {
  if (loggedUser && loggedUser.UserName && rbacData && rbacData.role) {
    setIsSignedInValue(true);
  }
};
export const clickSignInOutFunc = ({ isSignedInValue, loggedUser, dispatch, userType }) => {
  if (isSignedInValue) {
    if (userType === 'external') {
      dispatch(releaseLockCombo({ ...userLock, lockedBy: loggedUser.UserId }));
      window.location = `${loggedUser.LogOutUrl}&&logOutType=self`;
    } else {
      dispatch(releaseLockCombo({ ...userLock, lockedBy: loggedUser.UserId }));
      window.location = loggedUser.LogOutUrl;
    }
  } else {
    window.location = 'bff/login';
    clearToken();
  }
};
export const handleSignIn = () => {
  window.location.pathname = '/';
  clearToken();
};
export const redirectPathFunc = ({ location, isDirtyCheck, item, setModalOpen, setHasPath, history }) => {
  if (location.pathname !== '/' && isDirtyCheck) {
    if (item.urlPath !== '#' && (location.pathname !== item.urlPath)) {
      setModalOpen(true);
      setHasPath(item.urlPath);
    }
  } else {
    history.push(item.urlPath);
  }
};
export const signInDirtyFunc = ({ isDirtyCheck, setsignmodalOpen, setSignOutModal, loggedUser }) => {
  if (isDirtyCheck) {
    setsignmodalOpen(true);
  } else if (loggedUser.UserType === 'external') {
    setSignOutModal(true);
  } else {
    localStorage.setItem('logInTime', '');
    window.location = loggedUser.LogOutUrl;
    clearToken();
  }
};
export const handleConfirmfunc = ({ history, setModalOpen, dispatch, hasPath }) => {
  history.push(hasPath);
  setModalOpen(false);
  dispatch(checkDirtyData(false));
};
export const modalCloseFunc = ({ setHasPath, setModalOpen }) => {
  setHasPath(null);
  setModalOpen(false);
};
// Need to remove after User Pref Lang inplementation
export const langCheckFunction = ({ lang }) => setCurrentLocale(lang.split('-')[0]);
export const langChangeFuncSecond = ({ setLocalLanguage, setlanModalOpen, dispatch, label }) => {
  setLocalLanguage(label);
  setlanModalOpen(false);
  dispatch(checkDirtyData(false));
};
export const subChildMenuExit = (item, availMenus) => {
  let response = false;
  for (let i = 0; i < item.length; i += 1) {
    if (availMenus.includes(item[i].id)) {
      response = true;
      break;
    }
  }
  return response;
};
export const subMenuExit = (item, availMenus) => {
  let response = false;
  for (let i = 0; i < item.subMenu.length; i += 1) {
    if (item.subMenu[i].subMenu) {
      response = subChildMenuExit(item.subMenu[i].subMenu, availMenus);
    } else if (availMenus.includes(item.subMenu[i].id)) {
      response = true;
    } else {
      logger.verbose('fix sonar issue');
    }
    if (response) {
      break;
    }
  }
  return response;
};
export const getMenuItems = ({ menuItems, redirectPath, availMenus }) => menuItems.map((item, index) => {
  const hasSubMenuExist = subMenuExit(item, availMenus);
  let menu = '';
  if (item.subMenu.length > 0 && hasSubMenuExist) {
    menu = (
      <MainNavItem
        as="button"
        icon={item.icon}
        id={item.id}
        itemKey={index}
        label={t(item.label)}
        key={item.id}
      >
        {item.subMenu.map((submenu) => {
          if (submenu.subMenu) {
            const hasSubSMenuExist = subMenuExit(submenu, availMenus);
            if (hasSubSMenuExist) {
              return (
                <SubNavDropdown
                  as="button"
                  id={submenu.id}
                  itemKey={submenu.id}
                  label={submenu.label}
                  onClick={() => !submenu.subMenu && redirectPath(submenu)}
                  key={item.id}
                >
                  {submenu.subMenu && submenu.subMenu.length > 0
               && submenu.subMenu.map((dropItem) => (subNavItem(dropItem, redirectPath, availMenus)))}
                </SubNavDropdown>
              );
            }
            return (<></>);
          }
          return (subNavItem(submenu, redirectPath, availMenus));
        })}
      </MainNavItem>
    );
  } else if (item.subMenu.length <= 0) {
    menu = (
      <MainNavItem
        as="button"
        icon={item.icon}
        id={item.id}
        itemKey={index}
        label={t(item.label)}
        onClick={() => redirectPath(item)}
      />
    );
  } else {
    menu = (<></>);
  }
  return menu;
});
const pathLocation = (location) => {
  if (location && location.pathname !== '/') {
    return true;
  }
  return false;
};
const validateLock = ({ lockDetails, loggedUser }) => lockDetails && loggedUser && !lockDetails.listToDetail;
export const getSideNavWrapperClass = (loggedUser, rbacData) => (loggedUser && !rbacData ? 'noAccessNav' : '');
const stateCheck = (location) => (typeof location.state === 'object' && !('transPolBal' in location.state))
|| typeof location.state === 'string';
const releaseLock = ({ lockDetails, loggedUser, dispatch, location }) => {
  if (validateLock({ lockDetails, loggedUser })) {
    let rcdKey = lockDetails.recordKey;
    let entKey = lockDetails.entityKey;
    const action = lockDetails.action || 'edit';
    if (lockDetails.lockCombo && lockDetails.lockCombo.recordKey) {
      rcdKey = lockDetails.lockCombo.recordKey;
      entKey = lockDetails.lockCombo.entityKey;
    }
    if ((!location.state || stateCheck(location)) && location?.state?.path !== 'editCompCal') {
      dispatch(releaseLockCombo({ ...userLock,
        lockedBy: loggedUser.UserId,
        recordKey: rcdKey,
        entityKey: entKey,
        action: rcdKey ? action : '',
      }));
    }
  }
  if (pathLocation(location)) {
    dispatch(getBatchProcessingState());
  }
};
const sendEmail = () => {
  window.location = 'mailto:comp_mfc@manulife.ca';
};
const dialNum = () => {
  window.location = 'tel:1-800-838-7770';
};

const headerSection = ({ loggedUser, rbacData, isDirtyCheck, setLabel, setsignmodalOpen,
  setlangPrefModalOpen, setlanModalOpen, setSignOutModal, setLocalLanguage }) => (
    <HeaderWrapper>
      <UtilityHeader>
        {!(loggedUser && rbacData && rbacData.role) && (<UtilityHeader.SignIn onClick={handleSignIn} />)}
        {(loggedUser && rbacData && rbacData.role) && (
        <UtilityHeader.HeaderButton label="Profile" id="login-icon" icon={<Login1 />} showMenuArrow={false}>
          <UtilityHeader.MenuItem label="Language preference" onClick={() => setlangPrefModalOpen(true)} />
          <UtilityHeader.MenuItem
            label={t(staticCommonLabelKeys.COMMON_BUTTON_SIGNOUT)}
            onClick={() => signInDirtyFunc({ isDirtyCheck, setsignmodalOpen, setSignOutModal, loggedUser })}
          />
          <UtilityHeader.MenuItem label="" />
        </UtilityHeader.HeaderButton>
        )}
        <UtilityHeader.Lang
          id="langbtn"
          onClick={(lang) => langChangeFunc({ isDirtyCheck, setLabel, setlanModalOpen, setLocalLanguage, lang })}
        />
      </UtilityHeader>
    </HeaderWrapper>
);

const initLogedUser = ({ loggedUser, dispatch, location }) => {
  if (loggedUser && loggedUser.UserName && location.pathname !== '/') {
    dispatch(getRBACData());
    dispatch(getLevelRBACData());
  }
  if (loggedUser && loggedUser.UserName) {
    dispatch(getUserPrefData());
    dispatch(getSystemConfig(sysConfigPayload));
    dispatch(getUserContactInfo());
  }
};

const resetUsrLanMdl = ({ canceldisplayModal, setlangPrefModalOpen, lvClq, setLvClq }) => {
  if (!canceldisplayModal && lvClq) {
    setlangPrefModalOpen(false);
    setLvClq(false);
  }
};

const renderMenuArr = ({ allQuicksLinks, isBatchProcessing }) => {
  const arr = [];
  if (allQuicksLinks && allQuicksLinks.length > 0) {
    allQuicksLinks.forEach((item) => {
      if (isBatchProcessing === 1 && (item.batch_Online_Concurrency_Flag === 1)) {
        arr.push(item.menuId);
      }
      if (!isBatchProcessing) {
        arr.push(item.menuId);
      }
    });
  }
  return arr;
};

const initialValidation = (dispatch) => {
  if (window.location.pathname !== '/sign-out') {
    dispatch(usersDataProcessing());
  }
};
// mobile friendly header

const checkSignin = ({ loggedUser, rbacData, setlangPrefModalOpen, isDirtyCheck, setsignmodalOpen, setSignOutModal }) => {
  if (loggedUser && rbacData && rbacData.role) {
    return (
      <SideNav.HeaderButton label="Profile" id="login-icon" icon={<Login1 />} showMenuArrow={false}>
        <SideNav.MenuItem label="Language preference" onClick={() => setlangPrefModalOpen(true)} />
        <SideNav.MenuItem
          label={t(staticCommonLabelKeys.COMMON_BUTTON_SIGNOUT)}
          onClick={() => signInDirtyFunc({ isDirtyCheck, setsignmodalOpen, setSignOutModal, loggedUser })}
        />
        <SideNav.MenuItem label="" />
      </SideNav.HeaderButton>
    );
  }
  return (<SideNav.SignIn onClick={handleSignIn} />);
};
const Layout = ({ menuItems, children }) => {
  const [label, setLabel] = useState('');
  const [isSignedInValue, setIsSignedInValue] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [signmodalOpen, setsignmodalOpen] = useState(false);
  const [lanModalOpen, setlanModalOpen] = useState(false);
  const [langPrefModalOpen, setlangPrefModalOpen] = useState(false);
  const [signOutmodal, setSignOutModal] = useState(false);
  const [availMenus, setAvailMenus] = useState([]);
  const [hasPath, setHasPath] = useState(null);
  const [closeAddEditModal, setCloseAddEditModal] = useState(false);
  const [canceldisplayModal, setCancelDisplayModal] = useState(false);
  const [lvClq, setLvClq] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleConfirm = () => handleConfirmfunc({ history, setModalOpen, dispatch, hasPath });
  const modalClose = () => modalCloseFunc({ setHasPath, setModalOpen });
  const modalCloselog = () => setSignOutModal(false);
  const setLocalLanguage = (lang) => langCheckFunction({ lang });
  const isDirtyCheck = useSelector((state) => state.ApplicationState.isDirty);
  const { lockDetails, isBatchProcessing, allQuicksLinks } = useSelector((state) => state.ApplicationState);
  const { userPrefError, rbacData, loggedUser, userlang, userContactInfo } = useSelector(({
    ApplicationStateRBAC }) => ApplicationStateRBAC);
  const userType = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC?.loggedUser?.UserType);
  const signmodalClose = () => setsignmodalOpen(false);
  const langModalClose = () => setlanModalOpen(false);
  useEffect(() => loggedUserCheck({ loggedUser, rbacData, setIsSignedInValue }), [loggedUser, rbacData]);
  useEffect(() => setCurrentLocale(userlang === 'E' ? 'en' : 'fr'), [userlang]);
  useEffect(() => initialValidation(dispatch), []);
  useEffect(() => releaseLock({ lockDetails, loggedUser, dispatch, location }), [location, loggedUser]);
  const clickSignInOut = () => clickSignInOutFunc({ isSignedInValue, loggedUser, dispatch, userType });
  const handleConfirmSignOut = () => {
    clickSignInOut();
    dispatch(checkDirtyData(false));
  };
  const handleLangChange = () => langChangeFuncSecond({ setLocalLanguage, setlanModalOpen, dispatch, label });
  const redirectPath = (item) => {
    dispatch(resetStore());
    redirectPathFunc({ location, isDirtyCheck, item, setModalOpen, setHasPath, history });
  };
  useEffect(() => initLogedUser({ loggedUser, dispatch, location }), [loggedUser]);
  useEffect(() => resetUsrLanMdl({ canceldisplayModal, setlangPrefModalOpen, lvClq, setLvClq }), [canceldisplayModal]);
  useEffect(() => {
    if (allQuicksLinks) {
      setAvailMenus(renderMenuArr({ allQuicksLinks, isBatchProcessing }));
    }
  }, [allQuicksLinks]);
  const sideNavWrapperClass = getSideNavWrapperClass(loggedUser, rbacData);
  const { mainContentRef } = useSkipLinks();
  return (
    <UserContext.Provider value={userType}>
      <LayoutWrapper>
        {headerSection({ loggedUser, rbacData, isDirtyCheck, setLabel, setsignmodalOpen, setlangPrefModalOpen, setlanModalOpen, setSignOutModal, setLocalLanguage })}
        <SideNavWrapper className={sideNavWrapperClass}>
          <SideNav
            brandLogoProps={{ onClick: () => redirectPath({ urlPath: '/' }) }}
            keepOpenOnSelect={false}
            mobileFooterItems={[checkSignin({ loggedUser, rbacData, setlangPrefModalOpen, isDirtyCheck, setsignmodalOpen, setSignOutModal }), <SideNav.Lang key="lang" onClick={(lang) => langChangeFunc({ isDirtyCheck, setLabel, setlanModalOpen, setLocalLanguage, lang })} />]}
          >
            {availMenus.length > 0 && getMenuItems({ menuItems, redirectPath, availMenus })}
            <MainNavItem as="button" id="sideNavblankBtn" itemKey="1" label="" />
          </SideNav>
          {modallogoutSection({ signOutmodal, modalCloselog, clickSignInOut, setSignOutModal })}
          {modalSection({ modalOpen, modalClose, handleConfirm })}
          {modalSectionSignout({ signmodalOpen, signmodalClose, handleConfirmSignOut })}
          {modalLanChange({ lanModalOpen, langModalClose, handleLangChange })}
          {modalLangPref({ langPrefModalOpen, setlangPrefModalOpen, closeAddEditModal, setCancelDisplayModal, userlang, canceldisplayModal })}
          {displayCRModal(canceldisplayModal, setCancelDisplayModal,
            setCloseAddEditModal, closeAddEditModal, history, dispatch, setLvClq)}
        </SideNavWrapper>
        <LayoutContainer ref={mainContentRef} className="mainLayoutContainer" tabIndex="-1">
          <LayoutContent>{children}</LayoutContent>
          <div className="fasatFooter">
            {(userType === external && userContactInfo) ? (
              <div className="fasatContactUsFooter">
                <div className="contactUsFooter">
                  <FasatActionBtn icon={(<Phone color={colors.white} width="20px" height="20px" />)} onClick={() => dialNum()}> 1-800-838-7770 </FasatActionBtn>
                </div>
                <div className="contactUsFooter">
                  <FasatActionBtn
                    icon={(<Email color={colors.white} width="20px" height="20px" />)}
                    onClick={() => sendEmail()}
                  >
                    comp_mfc@manulife.ca
                  </FasatActionBtn>
                </div>
              </div>
            ) : ' '}
            {getFooter({ userPrefError, t })}
          </div>
        </LayoutContainer>
      </LayoutWrapper>
    </UserContext.Provider>
  );
};
Layout.propTypes = {
  menuItems: PropTypes.array.isRequired,
  children: PropTypes.object.isRequired,
};
export default Layout;
