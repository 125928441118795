/**
 * @Description This is FasatPlusMinus component page.
 * @FileName fasatDatePicker.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 17 February, 2021 07:23:35
 * @IssueID CDTFASATMOD- 570
 */
import React from 'react';
import PropTypes from 'prop-types';
import { PlusMinus } from '@manulife/mux';
import './fasatPlusMinus.scss';

const FasatPlusMinus = (props) => {
  const { value, onChange } = props;
  return (
    <div className="fasatPlusMinus">
      <PlusMinus
        {...props}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

FasatPlusMinus.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

FasatPlusMinus.defaultProps = {
  value: 10,
  onChange: () => undefined,
};

export default FasatPlusMinus;
