/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { eiActionTypes } from '../actions/actionTypes';
import { formatResponse, exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for EI Listing
   */
export function* getExceptionsList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(eiActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(eiActionTypes.LOG_EXI_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_EXI_LIST,
      action.data);
    yield all([
      put(getAction(eiActionTypes.GET_EXI_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(eiActionTypes.GET_EXI_LIST_ERROR, err)),
      put(getAction(eiActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

/**

     * This generator function will invoke the api

     * @param {object} action - action which will request for Exceptions Listing

     */

export function* getExceptionsbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(eiActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(eiActionTypes.LOG_EXI_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_EXI_BY_ID,
      action.data);
    yield all([
      put(getAction(eiActionTypes.GET_EXI_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(eiActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(eiActionTypes.GET_EXI_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
     * This generator function will invoke the api
     * @param {object} action - action which will insert for new Exceptions
     */
export function* insertExceptions(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_EXI_INSERT,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(eiActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(eiActionTypes.GET_EXI_LIST_REQUEST, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(eiActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

/**
     * This generator function will invoke the api
     * @param {object} action - action which will update for existing EI
     */
export function* updateExceptions(action) {
  yield all([
    put(processingStarted()),
    put(getAction(eiActionTypes.UPSERT_EXI_REQUEST, action.data.formatObject)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_EXI_UPDATE,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
    }
    yield all([
      put(getAction(eiActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(eiActionTypes.GET_EXI_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(eiActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
     * This generator function will invoke the api
     * @param {object} action - action which will delete existing Exceptions
     */
export function* deleteExceptions(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_EXI_DELETE,
      action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
    }
    yield all([
      put(getAction(eiActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(eiActionTypes.RESET_UPSERT_ERROR, resetUpsertValue)),
      put(getAction(eiActionTypes.GET_EXI_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(eiActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(eiActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
     * This generator function will invoke the api
     * @param {object} action - action which will request for BCR Export Listing
     */
export function* getExceptionsExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(eiActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_EXI_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(eiActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  yield takeEvery(eiActionTypes.GET_EXI_LIST_REQUEST, getExceptionsList);
  yield takeEvery(eiActionTypes.INSERT_EXI_REQUEST, insertExceptions);
  yield takeEvery(eiActionTypes.UPDATE_EXI_REQUEST, updateExceptions);
  yield takeEvery(eiActionTypes.DELETE_EXI_REQUEST, deleteExceptions);
  yield takeEvery(eiActionTypes.GET_EXI_EXPORT_REQUEST, getExceptionsExportList);
  // exception item by id
  yield takeEvery(eiActionTypes.GET_EXI_BY_ID_REQUEST, getExceptionsbyID);
}

export default moduleWatcher;
