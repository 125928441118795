/**
 * @Description API configuartion for module agency
 * @FileName apiConfig.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:39:31
 * @IssueID NA
 */

import { environment } from '../../../common/util/utility';
import { apiConfigExtension } from './apiConfigExtension';

const envData = environment();
const apiConfig = {
  ADMINISTRATION_GET_LIST: {
    endpoint: `${envData.apiHost}/api/genedittypes/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GET_BY_ID: {
    endpoint: `${envData.apiHost}/api/genedittypes/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GET_INSERT: {
    endpoint: `${envData.apiHost}/api/genedittypes`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GET_UPDATE: {
    endpoint: `${envData.apiHost}/api/genedittypes`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GET_DELETE: {
    endpoint: `${envData.apiHost}/api/genedittypes`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GET_REL: {
    endpoint: `${envData.apiHost}/api/genedittypes/`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GET_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/genedittypes/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Gen Edit Value
  ADMINISTRATION_GEV_LIST: {
    endpoint: `${envData.apiHost}/api/geneditvalues/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GEV_BY_ID: {
    endpoint: `${envData.apiHost}/api/geneditvalues/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GEV_INSERT: {
    endpoint: `${envData.apiHost}/api/geneditvalues`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GEV_UPDATE: {
    endpoint: `${envData.apiHost}/api/geneditvalues`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GEV_DELETE: {
    endpoint: `${envData.apiHost}/api/geneditvalues`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  // Gen Value Parent
  ADMINISTRATION_GEV_PARENT_LIST: {
    endpoint: `${envData.apiHost}/api/geneditvalues/parentvaluedetail/`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GEV_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/geneditvalues/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // system parameters
  ADMINISTRATION_SYSPARAM_LIST: {
    endpoint: `${envData.apiHost}/api/chequeparms/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_SYSPARAM_BY_ID: {
    endpoint: `${envData.apiHost}/api/chequeparms/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_SYSPARAM_INSERT: {
    endpoint: `${envData.apiHost}/api/chequeparms`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_SYSPARAM_UPDATE: {
    endpoint: `${envData.apiHost}/api/chequeparms`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_SYSPARAM_DELETE: {
    endpoint: `${envData.apiHost}/api/chequeparms`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_SYSPARAM_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/chequeparms/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Period Definition
  ADMINISTRATION_PD_LIST: {
    endpoint: `${envData.apiHost}/api/sysperiod/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PD_BY_ID: {
    endpoint: `${envData.apiHost}/api/sysperiod/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PD_INSERT: {
    endpoint: `${envData.apiHost}/api/sysperiod/add`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PD_UPDATE: {
    endpoint: `${envData.apiHost}/api/sysperiod`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PD_DELETE: {
    endpoint: `${envData.apiHost}/api/sysperiod`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PD_SPLIT: {
    endpoint: `${envData.apiHost}/api/sysperiod`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // Income Expense Map
  ADMINISTRATION_IEM_LIST: {
    endpoint: `${envData.apiHost}/api/transactioncategorymapping/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IEM_BY_ID: {
    endpoint: `${envData.apiHost}/api/transactioncategorymapping/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IEM_INSERT: {
    endpoint: `${envData.apiHost}/api/transactioncategorymapping`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IEM_UPDATE: {
    endpoint: `${envData.apiHost}/api/transactioncategorymapping`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IEM_DELETE: {
    endpoint: `${envData.apiHost}/api/transactioncategorymapping`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IEM_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/transactioncategorymapping/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_IEM_LOOKUP_REQUEST: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  // Plan code
  ADMINISTRATION_PLC_LIST: {
    endpoint: `${envData.apiHost}/api/plancodes/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PLC_BY_ID: {
    endpoint: `${envData.apiHost}/api/plancodes/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PLC_INSERT: {
    endpoint: `${envData.apiHost}/api/plancodes`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PLC_UPDATE: {
    endpoint: `${envData.apiHost}/api/plancodes`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PLC_DELETE: {
    endpoint: `${envData.apiHost}/api/plancodes`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PLC_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/plancodes/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Gen Edit Relationship type
  ADMINISTRATION_GER_LIST: {
    endpoint: `${envData.apiHost}/api/geneditreltypes/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GER_BY_ID: {
    endpoint: `${envData.apiHost}/api/geneditreltypes/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GER_INSERT: {
    endpoint: `${envData.apiHost}/api/geneditreltypes`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GER_UPDATE: {
    endpoint: `${envData.apiHost}/api/geneditreltypes`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GER_DELETE: {
    endpoint: `${envData.apiHost}/api/geneditreltypes`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GERV_DESC: {
    endpoint: `${envData.apiHost}/api/geneditreltypes/geneditreltype/`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GERT_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/geneditreltypes/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Gen Edit Relationship values
  ADMINISTRATION_GERV_LIST: {
    endpoint: `${envData.apiHost}/api/geneditrelvalues/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GERV_BY_ID: {
    endpoint: `${envData.apiHost}/api/geneditrelvalues/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GERV_INSERT: {
    endpoint: `${envData.apiHost}/api/geneditrelvalues`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GERV_UPDATE: {
    endpoint: `${envData.apiHost}/api/geneditrelvalues`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GERV_DELETE: {
    endpoint: `${envData.apiHost}/api/geneditrelvalues`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_GERV_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/geneditrelvalues/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  GERV_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // payment threshold
  ADMINISTRATION_PT_LIST: {
    endpoint: `${envData.apiHost}/api/paymentthresholds/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PT_BY_ID: {
    endpoint: `${envData.apiHost}/api/paymentthresholds/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PT_INSERT: {
    endpoint: `${envData.apiHost}/api/paymentthresholds`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PT_UPDATE: {
    endpoint: `${envData.apiHost}/api/paymentthresholds`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PT_DELETE: {
    endpoint: `${envData.apiHost}/api/paymentthresholds`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_PT_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/paymentthresholds/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Data Dictionary Type
  ADMINISTRATION_DD_LIST: {
    endpoint: `${envData.apiHost}/api/datadictionaries/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DD_BY_ID: {
    endpoint: `${envData.apiHost}/api/datadictionaries/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DD_UPDATE: {
    endpoint: `${envData.apiHost}/api/datadictionaries`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DD_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/datadictionaries/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Interface Mapping
  ADMINISTRATION_IM_LIST: {
    endpoint: `${envData.apiHost}/api/interfacemappings/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IM_BY_ID: {
    endpoint: `${envData.apiHost}/api/interfacemappings/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IM_INSERT: {
    endpoint: `${envData.apiHost}/api/interfacemappings`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IM_UPDATE: {
    endpoint: `${envData.apiHost}/api/interfacemappings`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IM_DELETE: {
    endpoint: `${envData.apiHost}/api/interfacemappings`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  IM_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IM_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/interfacemappings/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Transaction Definition
  ADMINISTRATION_TD_LIST: {
    endpoint: `${envData.apiHost}/api/transactiondefinition/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/transactiondefinition/detail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_ITM_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/transactiondefinition/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_SECURITY_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/securitytransaction/groups/`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_SECURITY_ITM_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/securitytransaction/items`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_SECURITY_DETAIL_UPDATE: {
    endpoint: `${envData.apiHost}/api/securitytransaction`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_ASSOCTRANS_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/associatedtransaction/list/`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_ASSOCTRANS_ITM_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/associatedtransaction/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_ASSOCTRANS_INSERT: {
    endpoint: `${envData.apiHost}/api/associatedtransaction`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_ASSOCTRANS_UPDATE: {
    endpoint: `${envData.apiHost}/api/associatedtransaction`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_ASSOCTRANS_DELETE: {
    endpoint: `${envData.apiHost}/api/associatedtransaction`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  TD_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_ADD: {
    endpoint: `${envData.apiHost}/api/transactiondefinition`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_EDIT: {
    endpoint: `${envData.apiHost}/api/transactiondefinition`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_DELETE: {
    endpoint: `${envData.apiHost}/api/transactiondefinition`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_TD_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/transactiondefinition/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Manage group
  ADMINISTRATION_MG_LIST: {
    endpoint: `${envData.apiHost}/api/manageSecurity/groups/`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_SECURITY_GROUPS: {
    endpoint: `${envData.apiHost}/api/manageSecurity/managegroups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_SG_UPDATE: {
    endpoint: `${envData.apiHost}/api/manageSecurity/managegroups`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_GRP_INSERT: {
    endpoint: `${envData.apiHost}/api/manageSecurity/add`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_GRP_BY_ID: {
    endpoint: `${envData.apiHost}/api/manageSecurity/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_GRP_DELETE: {
    endpoint: `${envData.apiHost}/api/manageSecurity/delete`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_GRP_UPDATE: {
    endpoint: `${envData.apiHost}/api/manageSecurity/update`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_GRP_USER_PRESENT_RELATION: {
    endpoint: `${envData.apiHost}/api/manageSecurity/ifBelongingUserExists`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_GRP_DESC: {
    endpoint: `${envData.apiHost}/api/manageSecurity/grpdescription`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  SECURITY_PAYMENT_CONFIG_INFO: {
    endpoint: `${envData.apiHost}/api/security/paymentsecurity/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // System Options
  ADMINISTRATION_SYS_OPT_DETAILS: {
    endpoint: `${envData.apiHost}/api/systemoptions/view/`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMIN_SYS_OPT_DETAILS_UPDATE: {
    endpoint: `${envData.apiHost}/api/systemoptions`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  // General preference
  ADMINISTRATION_GENPREF_LIST: {
    endpoint: `${envData.apiHost}/api/generalpreferences/view/`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },

  ADMINISTRATION_GENPREF_UPDATE: {
    endpoint: `${envData.apiHost}/api/generalpreferences`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  // Agent preference
  ADMINISTRATION_AGENT_LIST: {
    endpoint: `${envData.apiHost}/api/agentpreferences/View/`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AGENT_UPDATE: {
    endpoint: `${envData.apiHost}/api/agentpreferences`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  // Access Options
  ADMINISTRATION_ACCESSOPTS_LIST: {
    endpoint: `${envData.apiHost}/api/systemsettings/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_ACCESSOPTS_BY_ID: {
    endpoint: `${envData.apiHost}/api/systemsettings/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_ACCESSOPTS_INSERT: {
    endpoint: `${envData.apiHost}/api/systemsettings`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_ACCESSOPTS_UPDATE: {
    endpoint: `${envData.apiHost}/api/systemsettings`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_ACCESSOPTS_DELETE: {
    endpoint: `${envData.apiHost}/api/systemsettings`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_ACCESSOPTS_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/systemsettings/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Notifications/Other
  ADMINISTRATION_NOTIFOTHER_LIST: {
    endpoint: `${envData.apiHost}/api/SystemOptionsNotification/view/`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIFOTHER_UPDATE: {
    endpoint: `${envData.apiHost}/api/SystemOptionsNotification`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MA_LIST: {
    endpoint: `${envData.apiHost}/api/manageSecurity/manageapplication/list`,
    type: 'POST',
    authorizationRequired: true,
    showLoading: true,
  },
  ADMINISTRATION_MA_UPDATE: {
    endpoint: `${envData.apiHost}/api/manageSecurity/manageapplication`,
    type: 'PUT',
    authorizationRequired: true,
    showLoading: true,
  },
  // Check Security
  ADMINISTRATION_CHS_LIST: {
    endpoint: `${envData.apiHost}/api/chequesecurity/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CHS_BY_ID: {
    endpoint: `${envData.apiHost}/api/chequesecurity/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CHS_INSERT: {
    endpoint: `${envData.apiHost}/api/chequesecurity`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CHS_UPDATE: {
    endpoint: `${envData.apiHost}/api/chequesecurity`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CHS_DELETE: {
    endpoint: `${envData.apiHost}/api/chequesecurity`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CHS_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/chequesecurity/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_CHS_LOOKUP_REQUEST: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  SECURITY_PAYMENTSEC_UPDATE: {
    endpoint: `${envData.apiHost}/api/security/paymentsecurity/edit`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IS_LIST: {
    endpoint: `${envData.apiHost}/api/interfacesetup/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IS_BY_ID: {
    endpoint: `${envData.apiHost}/api/interfacesetup/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IS_INSERT: {
    endpoint: `${envData.apiHost}/api/interfacesetup`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IS_UPDATE: {
    endpoint: `${envData.apiHost}/api/interfacesetup`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IS_DELETE: {
    endpoint: `${envData.apiHost}/api/interfacesetup`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_IS_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/interfacesetup/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  IS_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // Notifications Events
  ADMINISTRATION_NOTIF_EVENTS_LIST: {
    endpoint: `${envData.apiHost}/api/notification/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIF_EVENTS_INSERT: {
    endpoint: `${envData.apiHost}/api/notification`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIF_EVENTS_UPDATE: {
    endpoint: `${envData.apiHost}/api/notification`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIF_EVENTS_BY_ID: {
    endpoint: `${envData.apiHost}/api/notification/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIF_EVENTS_DELETE: {
    endpoint: `${envData.apiHost}/api/notification`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIFY_EVENT_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/notification/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIFICATION_CONCURRENCY: {
    endpoint: `${envData.apiHost}/api/notification/checklockonadd`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  // Notifications Users
  ADMINISTRATION_NOTIF_USERS_LIST: {
    endpoint: `${envData.apiHost}/api/notificationList/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIF_USERS_BY_ID: {
    endpoint: `${envData.apiHost}/api/notificationList/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIFY_USERS_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/notificationList/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIF_USERS_DELETE: {
    endpoint: `${envData.apiHost}/api/notificationList`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_NOTIF_USERS_UPDATE: {
    endpoint: `${envData.apiHost}/api/notificationList`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  // Data Transfer
  ADMINISTRATION_DT_LIST: {
    endpoint: `${envData.apiHost}/api/datatransfer/datatransferlist`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DT_SEQUENCE: {
    endpoint: `${envData.apiHost}/api/datatransfer/sequence`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DT_START: {
    endpoint: `${envData.apiHost}/api/datatransfer/process`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_DT_CANCEL: {
    endpoint: `${envData.apiHost}/api/datatransfer/cancelprocess`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // Auto Reports
  ADMINISTRATION_AUTO_REPORTS_LIST: {
    endpoint: `${envData.apiHost}/api/autoReport/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AUTO_REPORTS_BY_ITEM: {
    endpoint: `${envData.apiHost}/api/autoReport/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AUTO_REPORTS_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/autoReport/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Manage Users
  ADMINISTRATION_MNG_USER_LIST: {
    endpoint: `${envData.apiHost}/api/SecurityUser/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_BY_ID: {
    endpoint: `${envData.apiHost}/api/SecurityUser/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_INSERT: {
    endpoint: `${envData.apiHost}/api/SecurityUser/Add`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_UPDATE: {
    endpoint: `${envData.apiHost}/api/SecurityUser/Edit`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_DELETE: {
    endpoint: `${envData.apiHost}/api/SecurityUser/Delete`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_SECURITY_GROUP: {
    endpoint: `${envData.apiHost}/api/lookups/groupType`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_MNG_USER_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/SecurityUser/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  // Manage Level
  ADMINISTRATION_MNG_LEVEL_LIST: {
    endpoint: `${envData.apiHost}/api/manageLevel/view/`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  // FieldForce Security
  ADMINISTRATION_FF_SECURITY_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcesecurity/list/securityuser`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_SECURITY_ASSIGNED_LIST: {
    endpoint: `${envData.apiHost}/api/fieldforcesecurity/list/assignedfield`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_FF_SECURITY_GET_NAME: {
    endpoint: `${envData.apiHost}/api/fieldforcesecurity/getname`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_ASSIGNED_FF_SECURITY_INSERT: {
    endpoint: `${envData.apiHost}/api/fieldforcesecurity/add`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_ASSIGNED_FF_SECURITY_DELETE: {
    endpoint: `${envData.apiHost}/api/fieldforcesecurity/delete`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ...apiConfigExtension,
};
export default apiConfig;
