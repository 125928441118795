const rateKey = 'rdRatekey';
const effectiveDate = 'rdFromDt';
const endDate = 'rdToDt';
const sequenceNo = 'rdRatekeyId';
const identity = 'rdId';
const includeStructure = 'includeStructure';
const basisType = 'basisType';
const gicTermBanding = 'rdrGicterm';
const upperBandAmout = 'rdrBandAmt';
const maxDurationYear = 'rdrDurYear';
const maxDurationMonth = 'rdrDurMonth';
const maxDurationDate = 'rdrDurDay';
const rateMethod = 'rdrRateType';
const rateAmount = 'rateAmount';
const rateIdentity = 'rdrId';
const level = 'level';
const type = 'type';
const code = 'rdoOrgCode';
const participationEffective = 'rdoFromDt';
const participationEndDate = 'rdoToDt';
const participationIndentity = 'rdoId';

export const rdoColOrder = [level, type, code, participationEffective, participationEndDate,
  participationIndentity];
export const rdrColOrder = [basisType, gicTermBanding, upperBandAmout, maxDurationYear, maxDurationMonth,
  maxDurationDate, rateMethod, rateAmount, rateIdentity];
export const rdColOrder = [rateKey, effectiveDate, endDate, sequenceNo, identity, includeStructure];
