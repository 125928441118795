/**
 * @Description API configuartion for module activity
 * @FileName apiConfig.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:39:31
 * @IssueID NA
 */

import { environment } from '../../../common/util/utility';

const envData = environment();
const apiConfig = {
  // adminAPI: {
  //   apiKey: 'adminAPI',
  //   endpoint: `${window.SERVER_DATA.apiHost}/adminAPI`,
  //   type: 'GET',
  //   authenticated: true,
  //   showLoading: true,
  // },
  // Activity Processed
  ADMINISTRATION_AP_LIST: {
    endpoint: `${envData.apiHost}/api/activity/processed/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AP_REVERSE: {
    endpoint: `${envData.apiHost}/api/activity/reverse`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AP_REV_REP: {
    endpoint: `${envData.apiHost}/api/activity/reverse-reprocess`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_REVERSE_STATUS_AP: {
    endpoint: `${envData.apiHost}/api/activity/jobstatus`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: false,
  },
  ADMINISTRATION_REVERSE_STATUS_AUP: {
    endpoint: `${envData.apiHost}/api/activity/jobstatus`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: false,
  },
  ADMINISTRATION_AP_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/activity/processed/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_AP_LOOKUP_REQUEST: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // Activity UnProcessed
  ADMINISTRATION_AUP_LIST: {
    endpoint: `${envData.apiHost}/api/activity/unprocessed/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AUP_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/activity/unprocessed/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AUP_PROCESS: {
    endpoint: `${envData.apiHost}/api/activity/unprocessed/process`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AUP_DELETE_CHECK: {
    endpoint: `${envData.apiHost}/api/activity/unprocessed/delete-eligibility`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AUP_DELETE: {
    endpoint: `${envData.apiHost}/api/activity/unprocessed`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AUP_NEGATIVE_RATE: {
    endpoint: `${envData.apiHost}/api/activity/unprocessed/negativerate`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_AP_AUP_CONCURRENCY: {
    endpoint: `${envData.apiHost}/api/activity/concurrency-check`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_BULK_VALID_COUNT: {
    endpoint: `${envData.apiHost}/api/activity/activityprocessedcount`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },

  ADMINISTRATION_BULK_CONCURRENCY: {
    endpoint: `${envData.apiHost}/api/activity/bulkcountvalidation`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },

  // job status
  FPA_MONITORING_LIST: {
    endpoint: `${envData.apiHost}/api/onlinefpainquiry/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  FPA_MONITORING_JOBSTATUS_LIST: {
    endpoint: `${envData.apiHost}/api/onlinefpainquiry/batchNum/list`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  FPA_MONITORING_ADJXRECORDLIST_LIST: {
    endpoint: `${envData.apiHost}/api/onlinefpainquiry/AdjustmentErrorX/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_ACTIVITY_PROCESS_LIST: {
    endpoint: `${envData.apiHost}/api/activityprocessedext/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_AP_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/activityprocessedext/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
};
export default apiConfig;
