/* eslint-disable no-debugger */
/**
 * @Description Fasat Filet Row
 * @FileName filterCriteriaRow.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 14 May, 2021 21:40:00
 * @IssueID - 1050
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonPlusFilled, ButtonMinusOutlined } from '@manulife/mux-cds-icons';
import { useTranslation } from 'react-i18next';
import FasatOperatorInput from './fasatOperatorInput';
import FasatFilterColumn from './fasatFilterColumn';
import FasatFilterInput from './fasatFilterInput';
import { staticCommonLabelKeys } from '../../../moduleConstants';
import { COLUMN_TYPE } from '../../../util';
import FasatLabel from '../../fasatLabel/fasatLabel';

const fcFilterRowClass = 'fc-filterRow';
const fcFilterCellClass = 'fc-filterCell';
const fcValueCellClass = 'fc-valueCell';
const fcActionCellClass = 'fc-actionCell';
const text = 'text';
const number = 'number';
const OPT_EQUALS = 'OPT_EQUALS';
const OPT_BETWEEN = 'OPT_BETWEEN';
const OPT_NOT_BETWEEN = 'OPT_NOT_BETWEEN';
const selOperatorItemThirteen = 13;
const selOperatorItemFourteen = 14;
const selOperatorItemNine = 9;
const selOperatorItemTen = 10;
const selOperatorItemEleven = 11;
const selOperatorItemTwelve = 12;
const propTypes = {
  totalRows: PropTypes.arrayOf(PropTypes.shape()),
  index: PropTypes.number,
  uuid: PropTypes.string,
  validateFilterCriteria: PropTypes.number.isRequired,
  columnConfig: PropTypes.arrayOf(PropTypes.shape()),
  operatorList: PropTypes.arrayOf(PropTypes.shape()),
  lookupData: PropTypes.arrayOf(PropTypes.shape()),
  customFilterCreteriaElement: PropTypes.arrayOf(PropTypes.shape()),
  addRow: PropTypes.func,
  removeRow: PropTypes.func,
  onValueChange: PropTypes.func,
  showDuplicateMessage: PropTypes.func,
};

const defaultProps = {
  totalRows: [],
  operatorList: [],
  index: 0,
  uuid: '',
  columnConfig: [],
  lookupData: [],
  customFilterCreteriaElement: [],
  addRow: () => undefined,
  removeRow: () => undefined,
  onValueChange: () => undefined,
  showDuplicateMessage: () => undefined,
};

const validateColumnType = (clmnVal) => (clmnVal.type === text || clmnVal.type === number
   || clmnVal.type === COLUMN_TYPE.BOOLEAN);

/**
 * Function to find whether the dropdown is searchable or not
 * @param {object} settingConfig
 */
export const findIsSearchable = (settingConfig, columnValue) => {
  const settingConfiguration = settingConfig;
  if (columnValue.isSearchable) {
    settingConfiguration.isSearchable = true;
  } else {
    settingConfiguration.isSearchable = false;
  }
};
export const operatorSettingConfiguration = (selOperatorItem, settingObj, columnValue) => {
  const settingConfig = settingObj;
  if (columnValue && (selOperatorItem[0].id !== selOperatorItemThirteen
          && selOperatorItem[0].id !== selOperatorItemFourteen)) {
    if (columnValue.lookupKey) {
      settingConfig.isDropdownComponent = true;
      findIsSearchable(settingConfig, columnValue);
    } else if (validateColumnType(columnValue)) {
      settingConfig.isInputComponent = true;
    } else {
      settingConfig.isDatePickerComponent = true;
    }
  } else {
    settingConfig.isInputComponent = true;
  }
  if (selOperatorItem[0].id === selOperatorItemNine
          || selOperatorItem[0].id === selOperatorItemTen) {
    settingConfig.isMultiselect = true;
  }
  if (selOperatorItem[0].id === selOperatorItemEleven
          || selOperatorItem[0].id === selOperatorItemTwelve) {
    settingConfig.isBetween = true;
  }
  return settingConfig;
};

const operatorNullCheck = (item) => item.operator !== 'OPT_IS_NOT_NULL' && item.operator !== 'OPT_IS_NULL';
const operatorEmptyCheck = (item) => item.operator !== 'OPT_IS_EMPTY' && item.operator !== 'OPT_IS_NOT_EMPTY';

export const valiadateOperator = (item) => {
  if ((item.operator === OPT_NOT_BETWEEN || item.operator === OPT_BETWEEN)) {
    return !((item.values && item.values[0]) && (item.values && item.values[1]));
  }
  const isNullOrBlank = item.values === null || item.values === '';
  const oprChk1 = operatorNullCheck(item);
  const oprChk2 = operatorEmptyCheck(item);
  return (oprChk1 && oprChk2) && (isNullOrBlank);
};

const fasatCompo = ({ autoPopulate }) => {
  const settingConfig = {
    isInputComponent: false,
    isDropdownComponent: false,
    isDatePickerComponent: false,
    isBetween: false,
    isMultiselect: false,
    isSearchable: false,
    autoPopulate: false,
  };
  settingConfig.isInputComponent = true;
  settingConfig.autoPopulate = autoPopulate || false;
  return settingConfig;
};
const colChangeFunc = ({ columnConfig, setColumnValue, setType, generateInputComponent, item, autoPopulate,
  setOpertorValue, onValueChange, uuid }) => {
  if (item) {
    const selCoulmnItem = columnConfig.filter((colItem) => colItem.value === item);
    if (selCoulmnItem && selCoulmnItem.length > 0) {
      setColumnValue(selCoulmnItem[0]);
      setType(selCoulmnItem[0].type);
      generateInputComponent(selCoulmnItem[0], autoPopulate);
      if (!autoPopulate) {
        const onValueChangeEvent = { key: selCoulmnItem[0].value,
          operator: OPT_EQUALS,
          type: selCoulmnItem[0].type,
          values: null,
          uuid,
        };
        setOpertorValue(null);
        onValueChange(onValueChangeEvent);
      }
    }
  }
};

const inputReqFun = ({ validateFilterCriteria,
  customFilterCreteriaElement, uuid, setShowInputRequired }) => {
  if (validateFilterCriteria) {
    const validateRow = customFilterCreteriaElement
      .filter((item) => (item.uuid === uuid && valiadateOperator(item)));
    if (validateRow.length > 0) {
      setShowInputRequired(true);
    } else {
      setShowInputRequired(false);
    }
  } else {
    setShowInputRequired(false);
  }
};

const getButton = ({ t, totalRows, uuid, removeRow, addRow, index }) => (
  <div className={fcActionCellClass} id={`fcAction${index}`}>
    {' '}
    {totalRows.length > 1 && (
    <button
      type="button"
      onClick={() => removeRow(uuid)}
      aria-label={t(staticCommonLabelKeys.ARIA_FILTER_BUTTON_DELETE_FILTER_CRITERIA)}
    >
      {' '}
      <ButtonMinusOutlined />
      {' '}
    </button>
    )}
    {' '}
    {totalRows.length === index + 1 && (
    <button type="button" onClick={addRow} aria-label={t(staticCommonLabelKeys.ARIA_FILTER_BUTTON_ADD_FILTER_CRITERIA)}>
      {' '}
      <ButtonPlusFilled />
      {' '}
    </button>
    )}
    {' '}
  </div>
);

const getFilterInfunc = ({ customFilterCreteriaElement, columnValue, uuid,
  operatorValue, lookupData, onValueChange, type, inputConfiguration, preSelectedIndex, showInputRequired }) => (
    <div className={fcValueCellClass}>
      <div className="noDeskDisp"><FasatLabel>Value</FasatLabel></div>
      <FasatFilterInput
        columnValue={columnValue}
        operator={operatorValue}
        onValueChange={(val) => onValueChange(val)}
        lookupData={lookupData}
        type={type}
        uuid={uuid}
        inputConfiguration={inputConfiguration}
        preSelectedIndex={preSelectedIndex}
        customFilterCreteriaElement={customFilterCreteriaElement}
        showInputRequired={showInputRequired}
      />
    </div>
);
const FilterCriteriaRow = ({
  totalRows,
  index,
  uuid,
  columnConfig,
  addRow,
  removeRow,
  operatorList,
  lookupData,
  onValueChange,
  customFilterCreteriaElement,
  showDuplicateMessage,
  validateFilterCriteria,
}) => {
  const [t] = useTranslation();
  const [showInputRequired, setShowInputRequired] = useState(false);
  useEffect(() => {
    inputReqFun({ validateFilterCriteria, customFilterCreteriaElement, uuid, setShowInputRequired });
  }, [validateFilterCriteria]);
  // input configuration
  const setInputComponetVisible = (autoPopulate) => fasatCompo({ autoPopulate });
  const [columnValue, setColumnValue] = useState(null);
  const [operatorValue, setOpertorValue] = useState(null);
  const [preSelectedIndex, setPreSelectedIndex] = useState(-1);
  const [inputConfiguration, setInputConfiguration] = useState(setInputComponetVisible());
  const [type, setType] = useState(text);
  // pre selected Index
  const onPreSelectedIndexChange = (selIndex) => setPreSelectedIndex(selIndex);
  // cnfigure component by selected coloumn
  const generateInputComponent = (columnVal, auto) => {
    const settingConfig = setInputComponetVisible(auto);
    setInputConfiguration(settingConfig);
  };
  // set column value
  const handleColumnChange = (item, autoPopulate) => {
    const params1 = { columnConfig, setColumnValue, setType, generateInputComponent, item, autoPopulate };
    const params2 = { setOpertorValue, onValueChange, uuid };
    colChangeFunc({ ...params1, ...params2 });
  };
  // set opearator value
  const onOperatorChange = (val, customChange) => {
    if (val) {
      const selOperatorItem = operatorList.filter((operatorItem) => operatorItem.operator === val);
      if (selOperatorItem) {
        setOpertorValue(selOperatorItem[0]);
        let settingConfig = inputConfiguration;
        if (customChange) {
          const onValueChangeEvent = { key: columnValue.value,
            operator: selOperatorItem[0].operator,
            type: columnValue.type,
            values: null,
            uuid,
          };
          onValueChange(onValueChangeEvent);
        }
        settingConfig.isInputComponent = false;
        settingConfig.isDropdownComponent = false;
        settingConfig.isDatePickerComponent = false;
        settingConfig.isBetween = false;
        settingConfig.isMultiselect = false;
        settingConfig.isSearchable = false;
        settingConfig = operatorSettingConfiguration(selOperatorItem, settingConfig, columnValue);
        setInputConfiguration(settingConfig);
      }
    } else {
      setOpertorValue(null);
    }
  };
  const paramsFil = { customFilterCreteriaElement, columnValue, operatorValue, lookupData, onValueChange, type };
  const paramsFil1 = { inputConfiguration, preSelectedIndex, showInputRequired, uuid };
  return (
    <div className={fcFilterRowClass}>
      <div className={fcFilterCellClass}>
        <div className="noDeskDisp"><FasatLabel>Column</FasatLabel></div>
        <FasatFilterColumn
          availableList={columnConfig}
          customFilterCreteriaElement={customFilterCreteriaElement}
          onColumnValueChange={(val, autoPopulate) => handleColumnChange(val, autoPopulate)}
          showDuplicateMessage={showDuplicateMessage}
          uuid={uuid}
          onPreSelectedIndexChange={
             (selIndex) => onPreSelectedIndexChange(selIndex)
           }
        />
      </div>
      <div className={fcFilterCellClass}>
        <div className="noDeskDisp"><FasatLabel>Operator</FasatLabel></div>
        <FasatOperatorInput
          columnValue={columnValue}
          operatorList={operatorList}
          onOperatorChange={(val, customChange) => onOperatorChange(val, customChange)}
          customFilterCreteriaElement={customFilterCreteriaElement}
          preSelectedIndex={preSelectedIndex}
          inputConfiguration={inputConfiguration}
          uuid={uuid}
        />
      </div>
      {getFilterInfunc({ ...paramsFil, ...paramsFil1 })}
      {getButton({ t, totalRows, uuid, removeRow, addRow, index })}
    </div>
  );
};

FilterCriteriaRow.propTypes = propTypes;
FilterCriteriaRow.defaultProps = defaultProps;
// #endregion

export default FilterCriteriaRow;
