/* eslint-disable import/prefer-default-export */
/**
 * @Description Action types for
 * @FileName actionTypes.js
 * @Author Abhisek Kundu
 * @CreatedOn 26 February, 2023 23:55:21
 * @IssueID - 8376
 */
// Payment action types
export const ppActionTypes = {
  LOG_PAYMENTPROCESSING_LIST_REQUEST: 'LOG_PAYMENTPROCESSING_LIST_REQUEST',
};
export const defaultPpActionTypes = {
  LOG_PAYMENTPROCESSING_LIST_REQUEST: 'LOG_PAYMENTPROCESSING_LIST_REQUEST',
};

export const defaultPpDetailActionTypes = {
  LOG_CS_DETAIL_REQUEST: 'LOG_CS_DETAIL_REQUEST',
  GET_CS_DETAIL_LIST_REQUEST: 'GET_CS_DETAIL_LIST_REQUEST',
  GET_CS_DETAIL_RESPONSE: 'GET_CS_DETAIL_RESPONSE',
  GET_CS_DETAIL_ERROR: 'GET_CS_DETAIL_ERROR',
  GET_CS_AGENT_NAME_RESPONSE: 'GET_CS_AGENT_NAME_RESPONSE',
  GET_NEXT_CS_AGENT_NAME_RESPONSE: 'GET_NEXT_CS_AGENT_NAME_RESPONSE',
};

// Payment detail action types
export const ppDetailActionTypes = {
  RESET_PP_STORE: 'RESET_PP_STORE',
  LOG_PP_DETAIL_REQUEST: 'LOG_PP_DETAIL_REQUEST',
  GET_PP_DETAIL_LIST_REQUEST: 'GET_PP_DETAIL_LIST_REQUEST',
  GET_PP_DETAIL_RESPONSE: 'GET_PP_DETAIL_RESPONSE',
  GET_PP_DETAIL_ERROR: 'GET_PP_DETAIL_ERROR',
  DELETE_ACCESSOPTS_REQUEST: 'DELETE_ACCESSOPTS_REQUEST',
  SET_DD_DETAILS_RESPONSE: 'SET_DD_DETAILS_RESPONSE',
  GET_PP_LIST_REQUEST: 'GET_PP_LIST_REQUEST',
  SET_PP_DETAILS_RESPONSE: 'SET_PP_DETAILS_RESPONSE',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  GET_BCR_BY_ID_REQUEST: 'GET_BCR_BY_ID_REQUEST',
  UPDATE_PP_FILTER_CONFIG: 'UPDATE_PP_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  GET_DD_EXPORT_REQUEST: 'GET_DD_EXPORT_REQUEST',
  REMOVE_DD_USER_DEFAULT_FILTER: 'REMOVE_DD_USER_DEFAULT_FILTER',
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  GET_PP_STRUCTURE_LIST_RESPONSE: 'GET_PP_STRUCTURE_LIST_RESPONSE',
  TOGGLE_LIST_APPROVE_BUTTON: 'TOGGLE_LIST_APPROVE_BUTTON',
  GET_PP_EXPORT_REQUEST: 'GET_PP_EXPORT_REQUEST',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  GET_PP_APPROVE: 'GET_PP_APPROVE',
  GET_PP_REMIT: 'GET_PP_REMIT',
  GET_PP_CANCEL: 'GET_PP_CANCEL',
  ENABLE_PP_APPROVE: 'ENABLE_PP_APPROVE',
  GET_PP_SEARCH_LIST_REQUEST: 'GET_PP_SEARCH_LIST_REQUEST',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  LOG_COMPCAL_LIST_REQUEST: 'LOG_COMPCAL_LIST_REQUEST',
  GET_DEFAULT_CS_BY_ID_RESPONSE: 'GET_DEFAULT_CS_BY_ID_RESPONSE',
  GET_CS_LIST_ERROR: 'GET_CS_LIST_ERROR',
  GET_PP_SEARCH_LIST_REQUEST_DATA: 'GET_PP_SEARCH_LIST_REQUEST_DATA',
  GET_PPD_SEARCH_LIST_REQUEST: 'GET_PPD_SEARCH_LIST_REQUEST',
  PP_JOB_REQUEST_RESPONSE: 'PP_JOB_REQUEST_RESPONSE',
  PP_JOB_REQUEST_ERROR: 'PP_JOB_REQUEST_ERROR',
  PP_JOB_RESET_REQUEST_RESPONSE: 'PP_JOB_RESET_REQUEST_RESPONSE',
};
