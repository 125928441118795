/**
 * @Description File description.
 * @FileName moduleConstants.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 25 January, 2021 08:39:44
 * @IssueID - 311
 */
const LOCAL_RESOURCE_NS = 'static:';
/**
   * All the route constants for the module
   */
export const routeConstant = {
  // AGENCY_HOME: '/agency/home',
  // BUSINESS_CARD_RULES: '/agency/bcr',
  // BUSINESS_CARD_DETAIL: '/agency/bcrdetail',
  COMPCAL_STRUCTURE_ROUTE: '/compcal/compensationstructure',
  COMPCAL_STRUCTURE_ADD_ROUTE: '/compcal/add',
  COMPCAL_STRUCTURE_EDIT_ROUTE: '/compcal/edit',
  COMPCAL_TREE_STRUCTURE_ROUTE: '/compcal/tree',
  DEFAULT_COMPCAL_STRUCTURE_ROUTE: '/defaultcompcal/defaultcompensationsstructure',
  DEFAULT_COMPCAL_STRUCTURE_ADD_ROUTE: '/defaultcompcal/add',
  DEFAULT_COMPCAL_STRUCTURE_EDIT_ROUTE: '/defaultcompcal/edit',
  LRO_ROUTE: '/lroeligibility/lro',
  LRO_ADD_ROUTE: '/lroeligibility/add',
  LRO_EDIT_ROUTE: '/lroeligibility/edit',

  RATE_MASTER_LIST_ROUTE: '/compcal/ratemaster',
  RATE_MASTER_ADD: '/compcal/ratemaster/add',
  RATE_MASTER_EDIT: '/compcal/ratemaster/edit',
  SCS_ROUTE: '/compcal/salescreditsummary',
  EXTERNAL_RATE_ROUTE: '/compcal/externalRate',
};

/**
   * ACL keys for the module
   */
export const aclKeysConstant = {
  // Menu
  AGENCY: 'RBAC_DISTRIBUTORS',
  ACTIVITY: 'Activity',
  COMPCAL: 'RBAC_COMPCALC',
  // SubMenu
  DCS_CARD_RULES: 'RBAC_DCS_MENU',
  // BCR Feature

  DCS_ADD: 'RBAC_DCS_ADD',
  DCS_EDIT: 'RBAC_DCS_EDIT',
  DCS_DELETE: 'RBAC_DCS_DELETE',
  DCS_EXPORT: 'RBAC_DCS_EXPORT',
  DCS_DETAIL: 'RBAC_DCS_DETAIL',

  CS_CARD_RULES: 'RBAC_CS_MENU',
  COMPENSATION_STRUCTURE_MENU: 'RBAC_CS_MENU',
  // BCR Feature

  CS_ADD: 'RBAC_CS_ADD',
  CS_EDIT: 'RBAC_CS_EDIT',
  CS_DELETE: 'RBAC_CS_DELETE',
  CS_EXPORT: 'RBAC_CS_EXPORT',
  CS_DETAIL: 'RBAC_CS_DETAIL',
  RBAC_CS_TREEVIEW: 'RBAC_CS_TREEVIEW',
  RBAC_CS_RATETABLE: 'RBAC_CS_RATETABLE',
  RBAC_CS_EXPORT_RATETABLE: 'RBAC_CS_EXPORT_RATETABLE',

  BCR_ADD: 'RBAC_BCR_ADD',
  BCR_EDIT: 'RBAC_BCR_EDIT',
  BCR_DELETE: 'RBAC_BCR_DELETE',
  BCR_EXPORT: 'RBAC_BCR_EXPORT',
  BCR_DETAIL: 'RBAC_BCR_DETAIL',

  // LRO Eligibility
  RBAC_LRO_MENU: 'RBAC_LRO_MENU',
  RBAC_LRO_ADD: 'RBAC_LRO_ADD',
  RBAC_LRO_EDIT: 'RBAC_LRO_EDIT',
  RBAC_LRO_DELETE: 'RBAC_LRO_DELETE',
  RBAC_LRO_EXPORT: 'RBAC_LRO_EXPORT',
  // RATE MASTER/RATE DETAILS
  RBAC_RM_MENU: 'RBAC_RM_MENU',
  RBAC_RM_ADD: 'RBAC_RM_ADD',
  RBAC_RM_EDIT: 'RBAC_RM_EDIT',
  RBAC_RM_DELETE: 'RBAC_RM_DELETE',
  RBAC_RM_VIEW: 'RBAC_RM_VIEW',
  RBAC_RM_EXPORT: 'RBAC_RM_EXPORT',
  RBAC_SB_MENU: 'RBAC_SB_MENU',
  RBAC_SB_EXPORT: 'RBAC_SB_EXPORT',
  RBAC_RD_MENU: 'RBAC_RD_MENU',
  RBAC_RD_EXPORT: 'RBAC_RD_EXPORT',
  RBAC_RKF_MENU: 'RBAC_RKF_MENU',
  RBAC_RKF_ADD: 'RBAC_RKF_ADD',
  RBAC_RKF_EDIT: 'RBAC_RKF_EDIT',
  RBAC_RKF_DELETE: 'RBAC_RKF_DELETE',
  RBAC_RKF_EXPORT: 'RBAC_RKF_EXPORT',
  RBAC_RF_MENU: 'RBAC_RF_MENU',
  RBAC_RF_ADD: 'RBAC_RF_ADD',
  RBAC_RF_EDIT: 'RBAC_RF_EDIT',
  RBAC_RF_DELETE: 'RBAC_RF_DELETE',
  RBAC_RF_EXPORT: 'RBAC_RF_EXPORT',
  RBAC_RDO_MENU: 'RBAC_RDO_MENU',
  RBAC_RDO_ADD: 'RBAC_RDO_ADD',
  RBAC_RDO_EDIT: 'RBAC_RDO_EDIT',
  RBAC_RDO_DELETE: 'RBAC_RDO_DELETE',
  RBAC_RDO_EXPORT: 'RBAC_RDO_EXPORT',
  // Sales  Credit Summary
  RBAC_SCS_MENU: 'RBAC_SCS_MENU',
  RBAC_SCS_EXPORT: 'RBAC_SCS_EXPORT',
  // External rates
  RBAC_EXRATE_MENU: 'RBAC_EXRATE_MENU',
  RBAC_EXRATE: 'RBAC_EXRATE',
  RBAC_EXRATE_EXPORT: 'RBAC_EXRATE_EXPORT',

};

/**
   * i18n Keys for the module
   */
export const staticLabelKeys = {
  DCS_LABEL_DEFAGENTIDENTIFIER: 'DCS_LABEL_DEFAGENTIDENTIFIER',
  DCS_LABEL_FIELDFORCELEVEL: 'DCS_LABEL_FIELDFORCELEVEL',
  DCS_LABEL_FIELDFORCETYPE: 'DCS_LABEL_FIELDFORCETYPE',
  DCS_LABEL_DEFROMDATE: 'DCS_LABEL_DEFROMDATE',
  DCS_LABEL_DEFIXEDDATE: 'DCS_LABEL_DEFIXEDDATE',
  DCS_LABEL_FIELDFORCEID: 'DCS_LABEL_FIELDFORCEID',
  DCS_TITLE_LIST: 'DCS_TITLE_LIST',
  DCS_TITLE_ADD: 'DCS_TITLE_ADD',
  DCS_TITLE_EDIT: 'DCS_TITLE_EDIT',
  DCS_LABEL_CHAIN: 'DCS_LABEL_CHAIN',
  DCS_LABEL_ADJSHARE: 'DCS_LABEL_ADJSHARE',
  DCS_ADD_SUCCESS: 'DCS_ADD_SUCCESS',
  DCS_EDIT_SUCCESS: 'DCS_EDIT_SUCCESS',

  AGENCY: `${LOCAL_RESOURCE_NS}agency.menu`,
  DISTRIBUTOR: 'RBAC_DISTRIBUTORS',
  COMCALC: 'RBAC_COMPCALC',

  BCR_MODAL_TITLE: 'BCR_TITLE_ADD',
  BCR_EDIT_MODAL_TITLE: 'BCR_TITLE_EDIT',
  BCR_PAGE_TITLE: 'BCR_TITLE_LIST',
  BCR_MENU: 'RBAC_BCR_MENU',

  LRO_ELIGIBILITY_MENU: 'RBAC_LRO_MENU',
  CS_TITLE_LIST: 'CS_TITLE_LIST',
  CS_MENU: 'RBAC_CS_MENU',
  DCS_MENU: 'RBAC_DCS_MENU',

  /**
    * Static Label Keys for BCR Delete Modal
    */
  DEFCOMPDEL: 'DEFCOMPDEL',
  DCS_DELETE_SUCCESS: 'DCS_DELETE_SUCCESS',

  /**
    * Static Label Keys (MSG_ID) for Income Expense Map Page Contents - START
    */
  LRO_PAGE_TITLE: 'LRO_PAGE_TITLE',
  LRO_TITLE_ADD: 'LRO_TITLE_ADD',
  LRO_TITLE_EDIT: 'LRO_TITLE_EDIT',
  LRO_ADD_SUCCESS: 'LRO_ADD_SUCCESS',
  LRO_EDIT_SUCCESS: 'LRO_EDIT_SUCCESS',
  LRO_DELETE_SUCCESS: 'LRO_DELETE_SUCCESS',
  LRO_DEL_CONF_MSG: 'LRO_DELETE_IER',
  // SYSPARAM_DEL_SUCCESS_MSG: 'SYSPARAM_DEL_SUCCESS_MSG',
  LRO_LABEL_RATETYPE: 'LRO_LABEL_RATETYPE',
  LRO_LABEL_LROID: 'LRO_LABEL_LROID',
  LRO_LABEL_TYPE: 'LRO_LABEL_TYPE',
  COMMON_LABEL_IDENTITY: 'COMMON_LABEL_IDENTITY',
  LRO_LABEL_LEVEL: 'LRO_LABEL_LEVEL',
  LRO_LABEL_PLANCODE: 'LRO_LABEL_PLANCODE',
  LRO_LABEL_CODE: 'LRO_LABEL_CODE',
  LRO_LABEL_ELEMENT: 'LRO_LABEL_ELEMENT',
  LRO_LABEL_CAUSECODE: 'LRO_LABEL_CAUSECODE',
  LRO_LABEL_RATEKEY: 'LRO_LABEL_RATEKEY',
  LRO_LABEL_POLICY: 'LRO_LABEL_POLICY',
  LRO_LABEL_EFFDATE: 'LRO_LABEL_EFFDATE',
  LRO_LABEL_ENDDATE: 'LRO_LABEL_ENDDATE',
  LRO_LABEL_LASTMODUSER: 'LRO_LABEL_LASTMODUSER',
  LRO_LABEL_LASTMODDATE: 'LRO_LABEL_LASTMODDATE',
  /*
   *ARIA LABELS FOR BCR PAGE - START
   */
  ARIA_BCR_BUTTON_ADD: 'ARIA_BCR_BUTTON_ADD',
  ARIA_CS_BUTTON_ADD: 'ARIA_CS_BUTTON_ADD',
  ARIA_DCS_BUTTON_ADD: 'ARIA_DCS_BUTTON_ADD',
  ARIA_LRO_BUTTON_ADD: 'ARIA_LRO_BUTTON_ADD',
  /*
  *ARIA LABELS FOR BCR PAGE - END
  */

  CS_LABEL_EFFDATE: 'CS_LABEL_EFFDATE',
  CS_LABEL_ADVISOR: 'CS_LABEL_ADVISOR',
  CS_LABEL_TODATE: 'CS_LABEL_TODATE',
  CS_LABEL_SHAREPERCENT: 'CS_LABEL_SHAREPERCENT',
  CS_LABEL_NEXTAGENTCODE: 'CS_LABEL_NEXTAGENTCODE',
  CS_LABEL_AFFILIATE: 'CS_LABEL_AFFILIATE',
  CS_LABEL_CORPORATION: 'CS_LABEL_CORPORATION',
  CS_LABEL_TEMPLEVEL: 'CS_LABEL_TEMPLEVEL',
  CS_LABEL_LOCATION: 'CS_LABEL_LOCATION',
  CS_LABEL_ALTOOFFICE: 'CS_LABEL_ALTOOFFICE',
  CS_LABEL_DISTRIBUTOR: 'CS_LABEL_DISTRIBUTOR',
  CS_LABEL_REGION: 'CS_LABEL_REGION',
  CS_LABEL_CHANNEL: 'CS_LABEL_CHANNEL',
  CS_LABEL_COMPANY: 'CS_LABEL_COMPANY',
  CS_DELETE_SUCCESS: 'CS_DELETE_SUCCESS',
  CNFDELETE: 'CNFDELETE',
  CS_LABEL_AGENTCODE: 'CS_LABEL_AGENTCODE',
  CS_LABEL_AGENTNAME: 'CS_LABEL_AGENTNAME',
  CS_LABEL_NEXTADVISOR: 'CS_LABEL_NEXTADVISOR',
  CS_EDIT_SUCCESS: 'CS_EDIT_SUCCESS',
  CS_ADD_SUCCESS: 'CS_ADD_SUCCESS',
  CS_LABEL_NEXTADVISORCODE: 'CS_LABEL_NEXTADVISORCODE',
  CS_LABEL_NEXTADVISORNAME: 'CS_LABEL_NEXTADVISORNAME',
  CS_TITLE_ADD: 'CS_TITLE_ADD',
  CS_TITLE_EDIT: 'CS_TITLE_EDIT',
  CS_LABEL_RATEFILTER: 'CS_LABEL_RATEFILTER',
  CS_LABEL_RATETABLE: 'CS_LABEL_RATETABLE',
  CS_LABEL_TREEVIEW: 'CS_LABEL_TREEVIEW',
  CS_LABEL_RATECATEGORY: 'CS_LABEL_RATECATEGORY',
  CS_LABEL_RATEKEY: 'CS_LABEL_RATEKEY',
  CS_LABEL_ACTIVEHIST: 'CS_LABEL_ACTIVEHIST',
  CS_LABEL_STRUCTTYPENEXTSTRUCTTYPE: 'CS_LABEL_STRUCTTYPENEXTSTRUCTTYPE',
  CS_DATE_CONFLICT_MSG: 'CS_DATE_CONFLICT_MSG',
  CS_DATE_UPDATE_MSG: 'CS_DATE_UPDATE_MSG',
  CS_DATE_DELETE_MSG: 'CS_DATE_DELETE_MSG',
  CS_DATE_UPDATE_CONFIRM_MSG: 'CS_DATE_UPDATE_CONFIRM_MSG',
  CS_DATE_CONFLICT_ERR_MSG: 'CS_DATE_CONFLICT_ERR_MSG',
  /*
  * Static LABELS For Rate Master List Page -START
  */
  RATE_MASTER_TITLE: 'Rate master',
  RATE_MASTER_DETAILS_TITLE: 'Rate details',
  RDO_RD_DELETE_CONFIRMATION: 'RDO_RD_DELETE_CONFIRMATION',
  RDO_RD_DELETE_SUCCESS: 'RDO_RD_DELETE_SUCCESS',
  RMD_PAGE_TITLE: 'RMD_PAGE_TITLE',
  RD_TITLE_RATEDETAIL: 'RD_TITLE_RATEDETAIL',
  RKF_RD_LABEL_SEQNUM: 'RKF_RD_LABEL_SEQNUM',
  RF_RD_LABEL_BASISTYPE: 'RF_RD_LABEL_BASISTYPE',
  RF_RD_LABEL_UPPERBANDAMT: 'RF_RD_LABEL_UPPERBANDAMT',
  RF_RD_LABEL_DURYEARS: 'RF_RD_LABEL_DURYEARS',
  RF_RD_LABEL_DURMONTHS: 'RF_RD_LABEL_DURMONTHS',
  RF_RD_LABEL_DURDAYS: 'RF_RD_LABEL_DURDAYS',
  RF_RD_LABEL_RATEMETHOD: 'RF_RD_LABEL_RATEMETHOD',
  RF_RD_LABEL_TORATEKEY: 'RF_RD_LABEL_TORATEKEY',
  RF_RD_LABEL_YEARS: 'RF_RD_LABEL_YEARS',
  RF_RD_LABEL_MONTHS: 'RF_RD_LABEL_MONTHS',
  RF_RD_LABEL_FROMDATE: 'RF_RD_LABEL_FROMDATE',
  PF_RD_LABEL_PARTICIPANT: 'PF_RD_LABEL_PARTICIPANT',
  PF_RD_LABEL_PARTEFFDATE: 'PF_RD_LABEL_PARTEFFDATE',
  PF_RD_LABEL_PARTENDDATE: 'PF_RD_LABEL_PARTENDDATE',
  RF_ADD_SUCCESS: 'RF_ADD_SUCCESS',
  RF_EDIT_SUCCESS: 'RF_EDIT_SUCCESS',
  RWD_RD_LABEL_RATEORAMT: 'RWD_RD_LABEL_RATEORAMT',
  RF_RD_TITLE_ADD: 'RF_RD_TITLE_ADD',
  RF_RD_TITLE_EDIT: 'RF_RD_TITLE_EDIT',
  RF_RMRD_TITLE: 'RF_RMRD_TITLE',
  PF_ADD_SUCCESS: 'PF_ADD_SUCCESS',
  PF_EDIT_SUCCESS: 'PF_EDIT_SUCCESS',
  PF_RD_TITLE_ADD: 'PF_RD_TITLE_ADD',
  PF_RD_TITLE_EDIT: 'PF_RD_TITLE_EDIT',
  RF_RD_LABEL_RATE: 'RF_RD_LABEL_RATE',
  UPDATE_RKF: 'UPDATE_RKF',
  OPSUCCESS_RKF: 'OPSUCCESS_RKF',
  RM_TITLE_RATEMASTER: 'RM_TITLE_RATEMASTER',
  RM_LABEL_COVERAGE: 'RM_LABEL_COVERAGE',
  RM_LABEL_GIC_TERM: 'RM_LABEL_GIC_TERM',
  RM_LABEL_BANDING: 'RM_LABEL_BANDING',
  RM_LABEL_BANDING_TYPE: 'RM_LABEL_BANDING_TYPE',
  RM_LABEL_DATE_TYPE: 'RM_LABEL_DATE_TYPE',
  RM_LABEL_DURATION_TYPE: 'RM_LABEL_DURATION_TYPE',
  RM_LABEL_MAXDURATION: 'RM_LABEL_MAXDURATION',
  RM_LABEL_DURATION_MONTH: 'RM_LABEL_DURATION_MONTH',
  RM_LABEL_DURATION_DATE: 'RM_LABEL_DURATION_DATE',
  RM_LABEL_MAX_DURATION: 'RM_LABEL_MAX_DURATION',
  RM_LABEL_ADD_RM: 'RM_LABEL_ADD_RM',
  RM_LABEL_EDIT_RM: 'RM_LABEL_EDIT_RM',
  RM_EDIT_SUCCESS: 'RM_EDIT_SUCCESS',
  RM_ADD_SUCCESS: 'RM_ADD_SUCCESS',
  RM_DELETE_SUCCESS: 'RM_DELETE_SUCCESS',
  RM_DELETE_CONFIRMATION: 'RM_DELETE_CONFIRMATION',
  RM_IN_USE: 'RMINUSE',
  RATE_IN_USE: 'RATEINUSE',
  RM_LABEL_YEAR: 'RM_LABEL_YEAR',
  SWB_LABEL_BANDING_LEVEL: 'SWB_LABEL_BANDING_LEVEL',
  SWB_LABEL_GURANTEED_RATE: 'SWB_LABEL_GURANTEED_RATE',
  SWB_LABEL_GURRATEEND_END_DATE: 'SWB_LABEL_GURRATEEND_END_DATE',
  SWB_LABEL_LAST_MODIFY_DATE: 'SWB_LABEL_LAST_MODIFY_DATE',
  SWB_LABEL_LAST_MODIFY_USER: 'SWB_LABEL_LAST_MODIFY_USER',
  SWB_CLONE: 'SWB_CLONE',
  SWB_HEADER: 'SWB_HEADER',

  RKF_ADD_SUCCESS: 'RKF_ADD_SUCCESS',
  RKF_EDIT_SUCCESS: 'RKF_EDIT_SUCCESS',
  RDR_RD_RATE_IDENTITY: 'RDR_RD_RATE_IDENTITY',
  RDR_RD_PART_IDENTITY: 'RDR_RD_PART_IDENTITY',
  VARIOG_RD_TITLE_ALL_RESULTS: 'VARIOG_RD_TITLE_ALL_RESULTS',
  VARIOG_RD_LABEL_VARIAG: 'VARIOG_RD_LABEL_VARIAG',
  VARIOG_RD_LABEL_DAYS: 'VARIOG_RD_LABEL_DAYS',
  VARIOG_RD_LABEL_YEARS: 'VARIOG_RD_LABEL_YEARS',
  VARIOG_RD_LABEL_MONTHS: 'VARIOG_RD_LABEL_MONTHS',
  VARIOG_RD_LABEL_INCLUDE_STRUCT: 'VARIOG_RD_LABEL_INCLUDE_STRUCT',
  RF_DELETE_CONFIRMATION: 'RF_DELETE_CONFIRMATION',
  RF_DELETE_SUCCESS: 'RF_DELETE_SUCCESS',
  RKF_RD_TITLE_EDIT: 'RKF_RD_TITLE_EDIT',
  RKF_RD_TITLE_ADD: 'RKF_RD_TITLE_ADD',
  RKF_DELETE_SUCCESS: 'RKF_DELETE_SUCCESS',
  RKF_DELETE_CONFIRMATION: 'RKF_DELETE_CONFIRMATION',
  RM_PAGE_TITLE: 'RM_PAGE_TITLE',
  // sweep banding title
  SWB_TITLE_LIST: 'SWB_TITLE_LIST',
  /*
  * Static LABELS For Rate Master List Page -END
  */

  // Sales Credit Summary
  SCS_MENU: 'RBAC_SCS_MENU',
  SCS_TITLE: 'SCS_TITLE',
  SCS_END_DATE: 'SCS_END_DATE',
  SCS_CLEAR_RESULTS: 'SCS_CLEAR_RESULTS',
  SCS_TOTAL_SALES_CREDIT: 'SCS_TOTAL_SALES_CREDIT',
  SCS_LABEL_SALESCREDITS: 'SCS_LABEL_SALESCREDITS',
  SCS_LABEL_TASKTYPE: 'SCS_LABEL_TASKTYPE',
  // External Rate
  EXRATE_EDIT_FER001_EFFECTIVEDT: 'EXRATE_EDIT_FER001_EFFECTIVEDT',
  EXRATE_LABEL_RATE_SUBMIT: 'EXRATE_LABEL_RATE_SUBMIT',
  EXRATE_EDIT_BER004_LABEL_SUBMIT: 'EXRATE_EDIT_BER004_LABEL_SUBMIT',
  EXRATE_EDIT_LABEL: 'EXRATE_EDIT_LABEL',
  EXRATE_EDIT_LABEL_CURRENT_RATE: 'EXRATE_EDIT_LABEL_CURRENT_RATE',
  EXRATE_EDIT_LABEL_PREVIEW_RATE: 'EXRATE_EDIT_LABEL_PREVIEW_RATE',
  EXRATE_EDIT_LABEL_NEW_RATE: 'EXRATE_EDIT_LABEL_NEW_RATE',
  EXRATE_EDIT_LABEL_UPLINE_RATE: 'EXRATE_EDIT_LABEL_UPLINE_RATE',
  EXRATE_EDIT_CHECKBOX_CONFIRM_MSG: 'EXRATE_EDIT_CHECKBOX_CONFIRM_MSG',
  EXRATE_EDIT_LABEL_CONTINUE: 'EXRATE_EDIT_LABEL_CONTINUE',
  EXRATE_EDIT_LABEL_SUBMIT: 'EXRATE_EDIT_LABEL_SUBMIT',
  EXRATE_EDIT_CHECKBOX_CONFIRM: 'EXRATE_EDIT_CHECKBOX_CONFIRM',
  EXRATE_TITLE_RATES: 'EXRATE_TITLE_RATES',
  EXRATE_LABEL_ORG_LEVEL: 'EXRATE_LABEL_ORG_LEVEL',
  EXRATE_LABEL_CURRENT_RATE: 'EXRATE_LABEL_CURRENT_RATE',
  EXTRATE_LABEL_BANDED_RATE: 'EXTRATE_LABEL_BANDED_RATE',
  EXTRATE_LABEL_NON_BANDED_RATE: 'EXTRATE_LABEL_NON_BANDED_RATE',
  EXRATE_LABEL_ALL_RATES: 'EXRATE_LABEL_ALL_RATES',
  EXRATE_LABEL_RATE_PERCENTAGE: 'EXRATE_LABEL_RATE_PERCENTAGE',
  EXRATE_LABEL_FROM_DATE: 'EXRATE_LABEL_FROM_DATE',
  EXRATE_LABEL_TO_DATE: 'EXRATE_LABEL_TO_DATE',
  EXRATE_HEADER_EXPORT: 'EXRATE_HEADER_EXPORT',
};
