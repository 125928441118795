/* eslint-disable react/prop-types */
/**
 * @Description Container for delete Plan Code
 * @FileName cancelPopup.js
 * @Author Imroz Dawar-dawarim
 * @CreatedOn 29th Nov, 2021 08:49:34
 * @IssueID
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FasatConfirmationMsg } from '../../../../common/components';
import { replaceString } from '../../../../common/util';
import { staticCommonLabelKeys, globalConstants } from '../../../../common/moduleConstants';

const CancelPopup = () => {
  const [t] = useTranslation();
  const getDiscardMessage = t(staticCommonLabelKeys.COMMON_UNSAVED_WARNING);
  const { replace, replaceWith } = globalConstants;
  /**
   * @param {Array} options
   * Validate all form data and lift event handleDelete
   * @returns {Array}
   */
  /**  COMMON_UNSAVED_WARNING
   * Subscribe button Clicl Event from Modal
   */
  return (
    <div>
      <FasatConfirmationMsg msg={replaceString(getDiscardMessage, replaceWith, replace)} />
    </div>
  );
};

CancelPopup.propTypes = {};

CancelPopup.defaultProps = {};
export default CancelPopup;
