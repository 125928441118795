/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { incomeExpenseMapActionType } from '../actions/actionTypes';

import { formatResponse, exportErrorMsg, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

// Income Expense Map
/**
  * Get Income Expense Map List
  * @param {any} action
  */
export function* getIEMList(action) {
  Logger.verbose(`Action Detail For Get Income Expense Map List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(incomeExpenseMapActionType.RESET_SERVER_VALUE, null)),
    put(getAction(incomeExpenseMapActionType.LOG_IEM_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_IEM_LIST, action.data);
    yield all([
      put(getAction(incomeExpenseMapActionType.GET_IEM_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(incomeExpenseMapActionType.GET_IEM_LIST_ERROR, err)),
      put(getAction(incomeExpenseMapActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * Get Income Expense by id
  * @param {object} action
  */
export function* getIEMbyID(action) {
  Logger.verbose(`Action Detail For Get IEM by id : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(incomeExpenseMapActionType.RESET_SERVER_VALUE, null)),
    put(getAction(incomeExpenseMapActionType.LOG_IEM_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_IEM_BY_ID, action.data);
    yield all([
      put(getAction(incomeExpenseMapActionType.GET_IEM_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(incomeExpenseMapActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(incomeExpenseMapActionType.GET_IEM_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert for Income Expense Map
  */
export function* insertIEM(action) {
  Logger.verbose(`Action Detail For Get Income Expese Map : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_IEM_INSERT,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(incomeExpenseMapActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(incomeExpenseMapActionType.GET_IEM_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(incomeExpenseMapActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will update existing Income Expense Map
  */
export function* updateIEM(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_IEM_UPDATE,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([put(getAction(incomeExpenseMapActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(incomeExpenseMapActionType.GET_IEM_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(incomeExpenseMapActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will delete existing Income Expense Map
  */
export function* deleteIEM(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_IEM_DELETE,
      action.data.formatObject,
    );
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(incomeExpenseMapActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(incomeExpenseMapActionType.GET_IEM_LIST_REQUEST, action.data.reqObject)),
      put(getAction(incomeExpenseMapActionType.RESET_UPSERT_ERROR, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(incomeExpenseMapActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(incomeExpenseMapActionType.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getLookUpsDataIEM(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_IEM_LOOKUP_REQUEST, action.data);
    yield all([
      put(getAction(incomeExpenseMapActionType.RESET_IEM_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(incomeExpenseMapActionType.RESET_IEM_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for SP Export Listing
 */
export function* getIEMExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(incomeExpenseMapActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_IEM_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(incomeExpenseMapActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
function* moduleWatcher() {
  // Income Expense Map
  yield takeEvery(incomeExpenseMapActionType.GET_IEM_LIST_REQUEST, getIEMList);
  yield takeEvery(incomeExpenseMapActionType.GET_IEM_BY_ID_REQUEST, getIEMbyID);
  yield takeEvery(incomeExpenseMapActionType.INSERT_IEM_REQUEST, insertIEM);
  yield takeEvery(incomeExpenseMapActionType.UPDATE_IEM_REQUEST, updateIEM);
  yield takeEvery(incomeExpenseMapActionType.DELETE_IEM_REQUEST, deleteIEM);
  yield takeEvery(incomeExpenseMapActionType.GET_IEM_EXPORT_REQUEST, getIEMExportList);
  yield takeEvery(incomeExpenseMapActionType.GET_IEM_LOOKUP_REQUEST, getLookUpsDataIEM);
}
export default moduleWatcher;
