/**
 * @Description FasatColumnOrder Filter File
 * @FileName Filter.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon } from '@manulife/mux';
import searchIcon from '../assets/images/search.svg';

class Filter extends React.PureComponent {
  render() {
    const {
      controlKey,
      disabled,
      filterPlaceholder,
      filterValue,
      id,
      // lang,
      onFilterChange,
      t,
    } = this.props;

    return (
      <div className="rdl-filter-container">
        {/* <label className="rdl-control-label rdl-sr-only" htmlFor={`${id}-filter-${controlKey}`}>
          {lang[`${controlKey}FilterHeader`]}
        </label> */}
        <input
          className="rdl-filter"
          data-key={controlKey}
          disabled={disabled}
          id={`${id}-filter-${controlKey}`}
          placeholder={t(filterPlaceholder)}
          type="text"
          value={filterValue}
          onChange={onFilterChange}
        />
        <Icon path={searchIcon} alt={t(filterPlaceholder)} />
      </div>
    );
  }
}

Filter.propTypes = {
  controlKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  filterPlaceholder: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  // lang: languageShape.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Filter);
