/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { dailyProcessActionType,
  dailyActivityFCRActionType,
  statementsAndXMLActionTypes } from '../actions/actionTypes';

import { processError } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';

// Daily Process

export function* getDailyProcessList(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DP_PROCESS_LIST.endpoint;
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, resetServerValue)),
    put(getAction(dailyProcessActionType.LOG_DP_LIST_REQUEST, action.data)),
  ]);
  try {
    const processDate = `?processDate=${action.data.processDate}`;
    const updatedApiUrl = initApiUrl + processDate;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_DP_PROCESS_LIST };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    moduleAPI.ADMINISTRATION_DP_PROCESS_LIST.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyProcessActionType.GET_DP_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DP_PROCESS_LIST.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dailyProcessActionType.GET_DP_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDailyProcessScheduleList(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DP_SCHEDULE_LIST.endpoint;
  yield all([
    put(processingStarted()),
    put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, null)),
    put(getAction(dailyProcessActionType.LOG_DP_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const processDate = `?processDate=${action.data.processDate}`;
    const updatedApiUrl = initApiUrl + processDate;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_DP_SCHEDULE_LIST };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    moduleAPI.ADMINISTRATION_DP_SCHEDULE_LIST.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyProcessActionType.GET_DP_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DP_SCHEDULE_LIST.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dailyProcessActionType.GET_DP_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
export function* getDailyProcesStart(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DP_START.endpoint;
  yield all([
    put(processingStarted()),
    put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, { serverRequestError: null })),
    put(getAction(dailyProcessActionType.LOG_DP_START_PROCESS, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_DP_START, {});
    const responseData1 = { ...responseData };
    responseData1.data = responseData.data[0].gridData;
    yield all([
      put(getAction(dailyProcessActionType.GET_DP_START_PROCESS_RESPONSE, responseData)),
      put(getAction(dailyProcessActionType.GET_DP_START_PROCESS_TABLE_RES, responseData1)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DP_START.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dailyProcessActionType.GET_DP_START_PROCESS_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* setDailyProcesSequence(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DP_SEQUENCE.endpoint;
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(dailyProcessActionType.LOG_DP_SET_SEQUENCE, action.data)),
    put(getAction(dailyProcessActionType.SET_DP_SEQUENCE_RESPONSE, undefined)),
    put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_DP_SEQUENCE, action.data);
    yield all([
      put(getAction(dailyProcessActionType.SET_DP_SEQUENCE_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DP_SEQUENCE.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

// FCR

export function* getDailyActivityFCRList(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_FCR_GET.endpoint;
  yield all([
    put(processingStarted()),
    put(getAction(dailyActivityFCRActionType.RESET_SERVER_VALUE, null)),
    put(getAction(dailyActivityFCRActionType.LOG_FCR_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const processDate = `?processDate=${action.data.processDate}`;
    const updatedApiUrl = initApiUrl + processDate;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_FCR_GET };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    moduleAPI.ADMINISTRATION_FCR_GET.endpoint = initApiUrl;
    const responseData1 = { ...responseData };
    responseData1.data = responseData.data[0].gridData;
    yield all([
      put(getAction(dailyActivityFCRActionType.GET_FCR_DATA_RESPONSE, responseData)),
      put(getAction(dailyActivityFCRActionType.GET_FCR_LIST_RESPONSE, responseData1)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_FCR_GET.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyActivityFCRActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dailyActivityFCRActionType.GET_FCR_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDailyActivityFCRHistory(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_FCR_HISTORY.endpoint;
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(dailyActivityFCRActionType.RESET_SERVER_VALUE, resetServerValue)),
    put(getAction(dailyActivityFCRActionType.LOG_FCR_HISTORY_REQUEST, action.data)),
  ]);
  try {
    const processDate = `?processDate=${action.data.processDate}`;
    const updatedApiUrl = initApiUrl + processDate;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_FCR_HISTORY };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    moduleAPI.ADMINISTRATION_FCR_HISTORY.endpoint = initApiUrl;
    const responseData1 = { ...responseData };
    responseData1.data = responseData.data[0].gridData;
    yield all([
      put(getAction(dailyActivityFCRActionType.GET_FCR_DATA_RESPONSE, responseData)),
      put(getAction(dailyActivityFCRActionType.GET_FCR_HISTORY_RESPONSE, responseData1)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_FCR_HISTORY.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyActivityFCRActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dailyActivityFCRActionType.GET_FCR_HISTORY_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDailyActivityFCRInsert(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_FCR_INSERT.endpoint;
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const processDate = `?fileName=${action.data.reqObject.fileName}`;
    const updatedApiUrl = initApiUrl + processDate;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_FCR_INSERT };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    moduleAPI.ADMINISTRATION_FCR_INSERT.endpoint = initApiUrl;
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(dailyActivityFCRActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(dailyActivityFCRActionType.GET_FCR_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(dailyActivityFCRActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deleteFCR(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_FCR_DELETE.endpoint;
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const processDate = `?transId=${action.data.reqObject.fcrTransId}`;
    const updatedApiUrl = initApiUrl + processDate;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_FCR_DELETE };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    moduleAPI.ADMINISTRATION_FCR_DELETE.endpoint = initApiUrl;
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(dailyActivityFCRActionType.RESET_DELETE_VALUE, resetDeleteValue));
      yield put(
        getAction(dailyActivityFCRActionType.GET_FCR_LIST_REQUEST, action.data.formatObject),
      );
    }
    yield all([
      put(getAction(dailyActivityFCRActionType.GET_FCR_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(dailyActivityFCRActionType.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(getAction(dailyActivityFCRActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDailyActivityHistory(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DP_HISTORY.endpoint;
  yield all([
    put(processingStarted()),
    put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, null)),
    put(getAction(dailyProcessActionType.LOG_HISTORY_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const processDate = `?processDate=${action.data.processDate}`;
    let batchExecId = '';
    if (action.data.batchExecId) {
      batchExecId = `&batchExecId=${action.data.batchExecId}`;
    }
    const updatedApiUrl = initApiUrl + processDate + batchExecId;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_DP_HISTORY };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    moduleAPI.ADMINISTRATION_DP_HISTORY.endpoint = initApiUrl;
    const responseData1 = { ...responseData };
    responseData1.data = responseData.data[0].gridData;
    yield all([
      put(getAction(dailyProcessActionType.GET_HISTORY_DATA_RESPONSE, responseData)),
      put(getAction(dailyProcessActionType.GET_HISTORY_LIST_RESPONSE, responseData1)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DP_HISTORY.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dailyProcessActionType.GET_DP_HISTORY_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDailyActivityResume(action) {
  const initApiUrl = moduleAPI.ADMINISTRATION_DP_RESUME.endpoint;
  yield all([
    put(processingStarted()),
    put(getAction(dailyProcessActionType.RESET_RESUME_VALUE, null)),
  ]);
  try {
    const JobRequestId = `?JobRequestId=${action.data}`;
    const updatedApiUrl = initApiUrl + JobRequestId;
    const apiEndpoint = { ...moduleAPI.ADMINISTRATION_DP_RESUME };
    apiEndpoint.endpoint = updatedApiUrl;
    const responseData = yield call(invokeApi, apiEndpoint);
    moduleAPI.ADMINISTRATION_DP_RESUME.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyProcessActionType.GET_DP_RESUME_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    moduleAPI.ADMINISTRATION_DP_RESUME.endpoint = initApiUrl;
    yield all([
      put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dailyProcessActionType.GET_DP_RESUME_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDailyGetBatchStatus(action) {
  yield all([
    put(processingStarted()),
    put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, { serverRequestError: null })),
    put(getAction(dailyProcessActionType.LOG_DP_BATCH_STATUS, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_DP_BATCHSTATUS, {});
    yield all([
      put(getAction(dailyProcessActionType.GET_DP_BATCH_STATUS_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(dailyProcessActionType.GET_DP_BATCH_STATUS_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getBatchReportListDetails(action) {
  yield all([
    put(processingStarted()),
    put(getAction(dailyProcessActionType.RESET_SERVER_VALUE, null)),
    put(getAction(statementsAndXMLActionTypes.LOG_BATCH_REPORT_LIST_REQ, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.BATCH_REPORT_GET_LIST,
      action.data);
    yield all([
      put(getAction(statementsAndXMLActionTypes.GET_BATCH_REPORT_LIST_RESPONSE, responseData)),
      put(processingCompleted()),

    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(statementsAndXMLActionTypes.GET_BATCH_REPORT_LIST_ERROR, err)),
      put(getAction(statementsAndXMLActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getBatchRptJobReqDetails(action) {
  const { batchExecId, isJobReqestFirstTime } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(statementsAndXMLActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(statementsAndXMLActionTypes.LOG_BATCH_REPORT_JOB_Detail, isJobReqestFirstTime)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const payload = {
      batchExecId,
    };
    const responseData = yield call(invokeApi, moduleAPI.BATCH_REPORT_JOB_DETAILS, payload);
    yield all([
      put(getAction(statementsAndXMLActionTypes.GET_BATCH_RPT_JOB_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(statementsAndXMLActionTypes.GET_BATCH_RPT_JOB_ERROR, err)),
      put(getAction(statementsAndXMLActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getBatchRptStartDetails(action) {
  yield all([
    put(processingStarted()),
    put(getAction(statementsAndXMLActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(statementsAndXMLActionTypes.LOG_BATCH_START_REQ, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.BATCH_REPORT_START_DETAILS,
      action.data);
    yield all([
      put(getAction(statementsAndXMLActionTypes.GET_BATCH_REPORT_START_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(statementsAndXMLActionTypes.GET_BATCH_REPORT_START_ERROR, err)),
      put(getAction(statementsAndXMLActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* batchRptForcesStopRequest(action) {
  yield all([
    put(processingStarted()),
    put(getAction(statementsAndXMLActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(statementsAndXMLActionTypes.LOG_BATCH_RPT_STOP_REQ, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.BATCH_REPORT_STOP,
      action.data);
    yield all([
      put(getAction(statementsAndXMLActionTypes.BATCH_RPT_STOP_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all(
      [put(processingCompleted()),
        put(getAction(statementsAndXMLActionTypes.RESET_SERVER_VALUE, resetServerValue)),
        put(getAction(statementsAndXMLActionTypes.BATCH_RPT_STOP_ERROR, resetServerValue)),
      ],
    );
  }
}

function* moduleWatcher() {
  // Daily Process
  yield takeEvery(dailyProcessActionType.GET_DP_LIST_REQUEST, getDailyProcessList);
  yield takeEvery(dailyProcessActionType.GET_DP_LIST_SCHEDULE_REQUEST, getDailyProcessScheduleList);
  yield takeEvery(dailyProcessActionType.GET_DP_START_PROCESS, getDailyProcesStart);
  yield takeEvery(dailyProcessActionType.SET_DP_SEQUENCE, setDailyProcesSequence);
  yield takeEvery(dailyProcessActionType.GET_HISTORY_LIST_REQUEST, getDailyActivityHistory);
  yield takeEvery(dailyProcessActionType.GET_DP_RESUME_REQUEST, getDailyActivityResume);
  yield takeEvery(dailyProcessActionType.GET_DP_BATCH_STATUS, getDailyGetBatchStatus);

  // FCR
  yield takeEvery(dailyActivityFCRActionType.GET_FCR_LIST_REQUEST, getDailyActivityFCRList);
  yield takeEvery(dailyActivityFCRActionType.GET_FCR_HISTORY_REQUEST, getDailyActivityFCRHistory);
  yield takeEvery(dailyActivityFCRActionType.INSERT_FCR_REQUEST, getDailyActivityFCRInsert);
  yield takeEvery(dailyActivityFCRActionType.DELETE_FCR_REQUEST, deleteFCR);

  // Statements and XMllife Feeds
  yield takeEvery(statementsAndXMLActionTypes.GET_BATCH_REPORT_LIST_REQ, getBatchReportListDetails);
  yield takeEvery(statementsAndXMLActionTypes.GET_BATCH_RPT_JOB_REQ_DETAILS, getBatchRptJobReqDetails);
  yield takeEvery(statementsAndXMLActionTypes.GET_BATCH_REPORT_START_REQ, getBatchRptStartDetails);
  yield takeEvery(statementsAndXMLActionTypes.BATCH_RPT_STOP_REQ, batchRptForcesStopRequest);
}
export default moduleWatcher;
