/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
/**
* @Description This is FasatDropdown component page.
* This is a wrapper on "Dropdown" component from MUX without adding any custom functionlities
* @FileName fasatDropdown.js
* @Author UMA DUTTA-duttuma
* @CreatedOn 29 January, 2021 6:00:00
* @IssueID CDTFASATMOD- 502
*/
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { bool } from 'prop-types';
import { Dropdown } from '@manulife/mux';
import { Dropdown as CustomDropdown } from '../../libs/fasatSharedComponent';
import './fasatDropdown.scss';
import FasatFormErrors from '../fasatFormErrors/fasatFormErrors';
import { staticCommonLabelKeys } from '../../moduleConstants';

const setError = (name, id) => (name || id);
const validateDropdownType = ({
  isMultiselect, searchEnabled, forcible }) => (isMultiselect || searchEnabled || forcible);
const setComponentClassName = ({ inputClass, errors, fasatInputErrorClass }) => (`${inputClass} ${
  errors.length > 0 && errors[0].isError ? fasatInputErrorClass : ''
}`);
const FasatDropdown = (props) => {
  const { isMultiselect, forcible, searchEnabled, name, onHandleChange, placeholder,
    errors, dropdownItems, filterCriteria, selectLabelEnabled, ...rest } = props;
  const { id } = props;
  const errorName = setError(name, id);
  const inputClass = 'fasatDropdown';
  const fasatInputErrorClass = 'withError';
  const [t] = useTranslation();
  const selectLabel = t(staticCommonLabelKeys.SELECT_DROPDOWN_LABEL);
  // eslint-disable-next-line no-unused-vars
  const selectLabelOption = {
    label: selectLabel,
    value: '',
  };
  const generateFasatList = () => {
    if (filterCriteria) {
      return dropdownItems.filter((item) => item.label !== 'All');
    }
    if (selectLabelEnabled) {
      if (!dropdownItems.find((item) => item.label === selectLabel)) {
        dropdownItems.splice(0, 0, selectLabelOption);
      }
    } else {
      return dropdownItems?.filter((item) => item.label !== selectLabel);
    }
    return dropdownItems;
  };
  useEffect(() => {
    const svgTitles = document.querySelectorAll('[data-testid="dropdown-native-icon"] svg title');
    Array.from(svgTitles).forEach((title) => {
      if (title.innerHTML === '') {
        title.remove();
      }
    });
  });
  if (validateDropdownType({ isMultiselect, searchEnabled, forcible })) {
    return (
      <div
        className={setComponentClassName({ inputClass, errors, fasatInputErrorClass })}
      >
        <CustomDropdown
          {...rest}
          onChange={onHandleChange}
          isMultiselect={isMultiselect}
          placeholder={placeholder}
          searchEnabled={searchEnabled}
          dropdownItems={dropdownItems}
        />
        <FasatFormErrors name={errorName} errors={errors} />
      </div>
    );
  }
  return (
    <>
      <div
        className={`${inputClass} ${
          errors.length > 0 && errors[0].isError ? fasatInputErrorClass : ''
        }`}
      >
        <Dropdown
          {...rest}
          onChange={onHandleChange}
          // onBlur={(e) => onBlur(e)}
          customStyle={{
            rootStyle: { width: '100%' },
          }}
          placeholder={!selectLabelEnabled ? placeholder : ''}
          dropdownItems={generateFasatList()}
        />
        <FasatFormErrors name={errorName} errors={errors} />
      </div>
    </>
  );
};

FasatDropdown.propTypes = {
  isMultiselect: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  forcible: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  selectLabelEnabled: bool,
  placeholder: PropTypes.string,
  onHandleChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.string,
      isError: PropTypes.bool,
      msg: PropTypes.string,
    },
  )),
};

FasatDropdown.defaultProps = {
  isMultiselect: false,
  searchEnabled: false,
  selectLabelEnabled: false,
  forcible: false,
  onHandleChange: () => undefined,
  placeholder: 'Select',
  name: '',
  id: '',
  errors:
     [{
       id: '',
       isError: false,
       msg: '',
     }],
};

export default FasatDropdown;
