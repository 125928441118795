import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { fieldForceActionTypes } from '../actions/actionTypes';
import { globalConstants } from '../../../../common/moduleConstants';
import { commonTypes } from '../../../../common/redux/actions/commonActions';

export const getSecWealthCode = (state, action) => {
  const resultObj = [];
  if (action.data) {
    const { payload, responseData } = action.data;
    Object.entries(responseData)
      .filter(([key], idx) => key !== payload[idx])
      // eslint-disable-next-line no-unused-vars
      .forEach(([_key, value]) => {
        resultObj.push({ [value.key]: value.value.sysValue });
      });
  }
  return {
    ...state,
    secWealthCode: { ...resultObj[0], ...resultObj[1] },
    loading: false,
  };
};
const genSecWealthObj = { sec: '', wealth: '' };
const genSecWealthCode = (state, action) => {
  if (action.data.type === globalConstants.wealth) {
    genSecWealthObj.wealth = action.data.responseData;
  } else {
    genSecWealthObj.sec = action.data.responseData;
  }
  return { ...state,
    genCodeSecWealth: genSecWealthObj,
    loading: false,
  };
};

const getPayee = (state, action) => {
  if (action.data.type === globalConstants.Id) {
    return { ...state,
      detailRequestError: [],
      payeeId: action.data.responseData,
      loading: false,
    };
  }
  return { ...state,
    detailRequestError: [],
    payeeName: action.data.responseData,
    loading: false,
  };
};

const getValidateStatus = (state, action) => {
  if (action.data.type === globalConstants.Payee) {
    return { ...state,
      detailRequestError: [],
      payeeStatus: action.data.responseData,
      loading: false,
    };
  }
  if (action.data.type === globalConstants.AgtType) {
    return { ...state,
      detailRequestError: [],
      agtTypeStatus: action.data.responseData,
      loading: false,
    };
  }
  if (action.data.type === globalConstants.FF_Status) {
    return { ...state,
      detailRequestError: [],
      statusType: action.data.responseData,
      loading: false,
    };
  }
  return {
    ...state,
  };
};

const resetValidateStatus = (state, action) => {
  if (action.data.type === globalConstants.Payee) {
    return { ...state,
      detailRequestError: [],
      payeeStatus: '',
      loading: false,
    };
  }
  if (action.data.type === globalConstants.AgtType) {
    return { ...state,
      detailRequestError: [],
      agtTypeStatus: '',
      loading: false,
    };
  }
  if (action.data.type === globalConstants.FF_Status) {
    return { ...state,
      detailRequestError: [],
      statusType: '',
      loading: false,
    };
  }
  return {
    ...state,
  };
};

const FieldForce1 = (state, action = null) => {
  switch (action.type) {
    case fieldForceActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case fieldForceActionTypes.SET_FF_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case fieldForceActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        jobStatusFF: action.data.jobStatusFF,
        loading: false,
      };
    case fieldForceActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
        deleteStatus: action.data.deleteStatus,
      };
    case fieldForceActionTypes.GET_FF_HEADER_DETAIL_RESPONSE:
      return {
        ...state,
        headerData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_SEC_WEALTH_RESPONSE:
      return getSecWealthCode(state, action);
    default:
      return null;
  }
};

const FieldForce2 = (state, action = null) => {
  switch (action.type) {
    case fieldForceActionTypes.GET_FF_SEC_JOB_STATUS_RESPONSE:
      return {
        ...state,
        jobStatusTimerVal: action.data,
      };
    case fieldForceActionTypes.GET_FF_GEN_SEC_WEALTH_CODE_RES:
      return genSecWealthCode(state, action);
    case fieldForceActionTypes.GET_FF_PAYEE_RESPONSE:
      return getPayee(state, action);
    case fieldForceActionTypes.GET_FF_GST_STATUS_RESPONSE:
      return {
        ...state,
        gstStatus: action.data.responseData,
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_PAYEE_RESPONSE:
      return {
        ...state,
        payeeId: '',
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_PAYEE_NAME_RESPONSE:
      return {
        ...state,
        payeeName: undefined,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_VALIDATE_STATUS_RESPONSE:
      return getValidateStatus(state, action);
    default:
      return null;
  }
};

const FieldForce3 = (state, action = null) => {
  switch (action.type) {
    case fieldForceActionTypes.RESET_FF_VALIDATE_STATUS_RESPONSE:
      return resetValidateStatus(state, action);
    case fieldForceActionTypes.GET_FF_ORG_STRUCT_RESPONSE:
      return {
        ...state,
        orgDetailData: action.data,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_SYS_OPTION_RESPONSE:
      return {
        ...state,
        sysOptionData: action.data,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_SERVER_DATE_RESPONSE:
      return {
        ...state,
        serverDate: action.data,
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_ORG_STRUCT_DETAIL:
      return {
        ...state,
        orgDetailData: null,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_DETAIL_RESPONSE:
      return {
        ...state,
        detailData: action.data,
        paymentMethod: action.data?.orgPaytMthd,
        paymentControllerId: action.data?.payee,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_DETAIL_BY_ID_RESPONSE:
      return {
        ...state,
        detailDataById: action.data,
        detailByIdRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_ADDRESS_BY_CLIENTID_RESPONSE:
      return {
        ...state,
        addressData: action.data,
        addressRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

const FieldForce4 = (state, action = null) => {
  switch (action.type) {
    case fieldForceActionTypes.RESET_FF_ADDRESS_BY_CLIENTID:
      return {
        ...state,
        addressData: null,
        addressRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_BANKING_ADDRESS_RESPONSE:
      return {
        ...state,
        addressDetailData: action.data,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_BANKING_ORS_PROPAY_RESPONSE:
      return {
        ...state,
        orsPropay: action.data,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_BANKING_PAYEE_STATUS_RESPONSE:
      return {
        ...state,
        payeeStatus: action.data,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_BANKING_PAYEE_CODE_RESPONSE:
      return {
        ...state,
        payeeCode: action.data,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_BANKING_PAYEE_NAME_RESPONSE:
      return {
        ...state,
        payeeName: action.data,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_BANKING_BRANCH_NAME_RESPONSE:
      return {
        ...state,
        branchName: action.data,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_BANKING_SYSTEM_OPTIONS_RESPONSE:
      return {
        ...state,
        systemOptionsData: action.data,
        loading: false,
      };
    default:
      return null;
  }
};

const FieldForce5 = (state, action = null) => {
  switch (action.type) {
    case fieldForceActionTypes.RESET_FF_BANKING_PAYEE_CODE:
      return {
        ...state,
        payeeCode: null,
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_BANKING_PAYEE_NAME:
      return {
        ...state,
        payeeName: null,
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_BANKING_BRANCH_NAME:
      return {
        ...state,
        branchName: null,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_ADMIN_DEBTREF_RESPONSE:
      return {
        ...state,
        isDebtRef: action.data,
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_DETAIL_RESPONSE:
      return {
        ...state,
        detailData: null,
        detailRequestProcessedOn: new Date(),
        detailtaskConccDataById: null,
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_TAB_DETAIL_VALUE:
      return {
        ...state,
        detailData: null,
        selectedTab: action.data.tab,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_SUB_TAB_DETAIL_VALUE:
      return {
        ...state,
        detailData: null,
        selectedSubTab: action.data.tab,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.LOG_FF_DETAIL_REQUEST:
      return {
        ...state,
        detailRequestedOn: new Date(),
        loading: true,
      };
    case fieldForceActionTypes.RESET_FF_TASK_LOOKUPDATA:
      return {
        ...state,
        taskDetailData: action.data,
      };
    default:
      return null;
  }
};

const serverResponseError = (action) => action.data && action.data.serverRequestError;

const FieldForce6 = (state, action = null) => {
  switch (action.type) {
    case fieldForceActionTypes.GET_FF_TAB_DETAIL_ERROR:
      return {
        ...state,
        tabDetailRequestError: action.data,
        tabDetailRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        exportRequestError: action.data?.exportRequestError,
        serverRequestError: serverResponseError(action),
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_CONCURRENCY_ERROR:
      return {
        ...state,
        concurrencyRequestError: action.data,
        concurrencyRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_CONCURRENCY_ERROR:
      return {
        ...state,
        concurrencyRequestError: null,
        concurrencyRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.RESET_FF_DETAIL_ERROR:
      return {
        ...state,
        detailRequestError: null,
        detailRequestProcessedOn: new Date(),
        exportRequestError: null,
        serverRequestError: null,
        loading: false,
      };
    case fieldForceActionTypes.GET_FF_DEDUCTION_BY_ID_RESPONSE:
      return {
        ...state,
        detailDataById: action.data,
      };
    case fieldForceActionTypes.GET_FF_REL_BY_ID_RESPONSE:
      return {
        ...state,
        detailRelDataById: action.data,
      };
    case fieldForceActionTypes.GET_FF_TASK_CONCURRENCY_RES:
      return {
        ...state,
        detailtaskConccDataById: action.data,
      };
    case fieldForceActionTypes.RESET_FF_DEDUCTION_BY_ID_NAME:
      return {
        ...state,
        transferName: null,
        transferError: null,
      };
    default:
      return null;
  }
};

const FieldForce7 = (state, action = null) => {
  switch (action.type) {
    case fieldForceActionTypes.GET_FF_DEDUCTION_DETAIL_TAB_ERROR:
    case fieldForceActionTypes.GET_FF_REL_DETAIL_TAB_ERROR:
    case fieldForceActionTypes.GET_FF_TASK_DETAIL_TAB_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.SET_FF_TASK_BY_ID_RESPONSE:
      return {
        ...state,
        detailTaskDataById: action.data,
      };
    case fieldForceActionTypes.VALIDATE_DETAILS_CHANGE_RESPONSE:
      return {
        ...state,
        hasDetailsChanged: action.data,
      };
    case fieldForceActionTypes.GET_ASSOC_TASK_CNT_RES:
      return {
        ...state,
        assocTaskCount: action.data,
      };
    case fieldForceActionTypes.GET_SYSOPT_HIER_RES:
      return {
        ...state,
        sysOptHierResponse: action.data,
      };
    case fieldForceActionTypes.GET_FF_DEDUCTION_BY_ID_NAME:
      return {
        ...state,
        transferName: action.data.clientName,
        transferError: action.data.errorCode,
      };
    case fieldForceActionTypes.GET_FF_TRANSACTION_LOOKUP:
      return {
        ...state,
        transaction: action.data,
      };
    default:
      return null;
  }
};

const FieldForce8 = (state, action = null) => {
  switch (action.type) {
    case fieldForceActionTypes.GET_FF_DEDUCTION_BY_ID_RESPONSE_RESET:
      return {
        ...state,
        detailDataById: null,
      };
    case fieldForceActionTypes.GET_FF_DETAIL_BY_ID_RESPONSE_RESET:
      return {
        ...state,
        detailDataById: null,
        detailByIdRequestProcessedOn: new Date(),
        loading: false,
      };
    case fieldForceActionTypes.DEDUCTION_ACTIVE_VALUE:
      return {
        ...state,
        activeStatus: action.data,
      };
    case fieldForceActionTypes.SET_FF_REL_ADVISOR_NAME:
      return {
        ...state,
        relAdvisorName: action.data,
      };
    case fieldForceActionTypes.RESET_FF_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    case fieldForceActionTypes.GET_FF_ADDRESS_LOOKUP_P_RESPONSE:
      return {
        ...state,
        addressLookUpDataP: action.data,
      };
    case fieldForceActionTypes.GET_FF_ADDRESS_LOOKUP_S_RESPONSE:
      return {
        ...state,
        addressLookUpDataS: action.data,
      };
    case fieldForceActionTypes.GET_FF_HEADER_DETAIL_ERROR:
      return {
        ...state,
        headerDetailRequestError: action.data,
        headerDetailRequestProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return null;
  }
};

const FieldForce = (state = { ...basicListAddEditDltInitialState,
  taskDetailData: null,
  hasDetailsChanged: 0,
  assocTaskCount: null,
  payeeName: null }, action = null) => {
  const ff1 = FieldForce1(state, action);
  if (ff1 !== null) {
    return ff1;
  }
  const ff2 = FieldForce2(state, action);
  if (ff2 !== null) {
    return ff2;
  }
  const ff3 = FieldForce3(state, action);
  if (ff3 !== null) {
    return ff3;
  }
  const ff4 = FieldForce4(state, action);
  if (ff4 !== null) {
    return ff4;
  }
  const ff5 = FieldForce5(state, action);
  if (ff5 !== null) {
    return ff5;
  }
  const ff6 = FieldForce6(state, action);
  if (ff6 !== null) {
    return ff6;
  }
  const ff7 = FieldForce7(state, action);
  if (ff7 !== null) {
    return ff7;
  }
  const ff8 = FieldForce8(state, action);
  if (ff8 !== null) {
    return ff8;
  }

  return state;
};

export default FieldForce;
