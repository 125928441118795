/* eslint-disable no-unused-vars */
/**
 * @Description This will include all the modules MENU configuration.
 * @FileName menuConfig.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 08 January, 2021 16:46:10
 * @IssueID - 459
 */
import { adminMenus } from '../app/modules/admin';
import { agencyMenus } from '../app/modules/agency';
import { activityMenus } from '../app/modules/activity';
import { compcalMenus, rateMenus } from '../app/modules/compcal';
import { reportMenus } from '../app/modules/report';
import { accountingMenus } from '../app/modules/accounting';
import { paymentMenus } from '../app/modules/payments';
import { codeAndHierarchyMenus } from '../app/modules/codesAndHierarchyExternal';

/**
 * All the module specific menus needs to be configured here
 */
const menuConfig = () => [
  ...agencyMenus(),
  ...codeAndHierarchyMenus(),
  ...activityMenus(),
  ...accountingMenus(),
  ...compcalMenus(),
  ...rateMenus(),
  ...reportMenus(),
  ...adminMenus(),
  ...paymentMenus(),
];
export default menuConfig;
