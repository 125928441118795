/**
 * @Description FasatColumnOrder ClassNames File
 * @FileName classNames.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */

const classNames = () => [].join(' ');

export default classNames;
