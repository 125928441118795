/**
 * @Description Reducer for business card rules
 * @FileName relationshipFilter.js
 * @Author Prasenjit Chakraborty - chakpra
 * @CreatedOn 13 December, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { relationshipActionsTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'RelationshipFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
});

const getRelationshipListResponse = (state, action) => ({
  ...state,
  listData: action.data,
  listRequestError: null,
  listRequestProcessedOn: new Date(),
  loading: false,
});
const getResetRelStore = (state) => ({
  ...state,
  listData: null,
  listRequestError: null,
  listRequestProcessedOn: null,
  serverRequestError: null,
  loading: false,
});

const relationshipFilterListReq = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case relationshipActionsTypes.UPDATE_RELATIONSHIP_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case relationshipActionsTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    default:
      return null;
  }
};

const relationshipFilterConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case relationshipActionsTypes.GET_RELATIONSHIP_LIST_RESPONSE:
      return getRelationshipListResponse(state, action);
    case relationshipActionsTypes.RESET_REALTIONSHIP_INQUIRY_STORE:
      return getResetRelStore(state);
    case relationshipActionsTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const RelationshipFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateReqRes = relationshipFilterListReq(state, action);
  const stateConfig = relationshipFilterConfig(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default RelationshipFilterList;
