import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { genEditRelationshipValActionType } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'GenEditRelationshipValFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const GenEditRelationshipValFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case genEditRelationshipValActionType.UPDATE_GERV_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case genEditRelationshipValActionType.REMOVE_GERV_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case genEditRelationshipValActionType.UPDATE_GERV_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    case genEditRelationshipValActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case genEditRelationshipValActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    default:
      return null;
  }
};

const GenEditRelationshipValFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case genEditRelationshipValActionType.LOG_GERV_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case genEditRelationshipValActionType.GET_GERV_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case genEditRelationshipValActionType.GET_GERV_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case genEditRelationshipValActionType.GET_GERV_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const GenEditRelationshipValFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = GenEditRelationshipValFilterListConfig(state, action);
  const stateReqRes = GenEditRelationshipValFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default GenEditRelationshipValFilterList;
