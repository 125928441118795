/**
 * @Description This is FasatRadioGroup component page.
 * This is a wrapper on "tRadioGroup" component from MUX without adding any custom functionlities
 * @FileName FasatRadioGroup.js
 * @Author UMA DUTTA-duttuma
 * @CreatedOn 29 January, 2021 6:00:00
 * @IssueID CDTFASATMOD- 502
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from '@manulife/mux';
import FasatFormErrors from '../fasatFormErrors/fasatFormErrors';
import './fasatRadioGroup.scss';

const FasatRadioGroup = (props) => {
  const { errors, name, onChange, selected, ...rest } = props;
  const [newSelect, setNewSelect] = useState(selected);
  const inputClass = 'fasatRadioGroup';
  const fasatInputErrorClass = 'withError';

  const setChangeValue = (newValue, e) => {
    setNewSelect(newValue);
    onChange(e);
  };

  return (
    <>
      <div
        className={`${inputClass} ${
          errors.length > 0 && errors[0].isError ? fasatInputErrorClass : ''
        }`}
      >
        <RadioGroup
          {...rest}
          name={name}
          selected={newSelect}
          onChange={(newValue, e) => setChangeValue(newValue, e)}

        />
        <FasatFormErrors name={name} errors={errors} />
      </div>
    </>
  );
};

FasatRadioGroup.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.string,
      isError: PropTypes.bool,
      msg: PropTypes.string,
    },
  )),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

FasatRadioGroup.defaultProps = {
  name: '',
  errors:
    [{
      id: 'name',
      isError: false,
      msg: '',
    }],
  selected: null,
};

export default FasatRadioGroup;
