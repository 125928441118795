/* eslint-disable no-debugger */
/**
 * @Description Reducer for business card rules
 * @FileName businessCardRulesReducer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { defaultCsActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'DefaultCompStructFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault || null,
      systemDefaultFilter: action.data.systemDefault || null,

    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      userDefaultFilter: action.data.userDefault,
      selectedFilter: action.data.selectedItem || null,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  selectedFilter: action.data ? action.data : null,
});

const logBcrListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const defaultCompStructFilterList1 = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case defaultCsActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case defaultCsActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case defaultCsActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case defaultCsActionTypes.LOG_COMPCAL_LIST_REQUEST:
      return logBcrListRequest(state, action);
    case defaultCsActionTypes.GET_DEFAULT_CS_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case defaultCsActionTypes.GET_CS_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case defaultCsActionTypes.GET_COMP_STRUCTURE_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };

    case defaultCsActionTypes.RESET_CS_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    default:
      return null;
  }
};

const defaultCompStructFilterList2 = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case defaultCsActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const defaultCompStructFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const cond1 = defaultCompStructFilterList1(state, action);
  const cond2 = defaultCompStructFilterList2(state, action);
  if (cond1 !== null) {
    return cond1;
  }
  if (cond2 !== null) {
    return cond2;
  }
  return state;
};

export default defaultCompStructFilterList;
