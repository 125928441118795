import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { fpaMonitoringAction } from '../actions/actionTypes';

export const REDUCER_NAME = 'FpaMonitoringFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
      selectedFilter: action.data.selectedItem || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const logFPAListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const logFPABatchNumReq = (state, action) => ({
  ...state,
  negativeRequest: action.data ? action.data : state.listRequest,
  negativeRequestedOn: new Date(),
  loading: true,
});

const updateTableConfig = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const FpaMonitoringFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case fpaMonitoringAction.UPDATE_FPA_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case fpaMonitoringAction.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case fpaMonitoringAction.REMOVE_FPA_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case fpaMonitoringAction.RESET_FPA_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case fpaMonitoringAction.RESET_FPA_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    case fpaMonitoringAction.GET_FPA_BATCH_NUM_LOG_REQ:
      return logFPABatchNumReq(state, action);
    // log error
    case fpaMonitoringAction.GET_FPA_BATCH_NUM_ERROR:
      return {
        ...state,
        batchNumRequestError: action.data,
        batchNumRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case fpaMonitoringAction.GET_FPA_BATCH_NUM_RESPONSE:
      return {
        ...state,
        batchNumData: action.data,
        batchNumRequestError: null,
        batchNumRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

const FpaMonitoringFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case fpaMonitoringAction.LOG_FPA_LIST_REQUEST:
      return logFPAListReq(state, action);
    // log error
    case fpaMonitoringAction.GET_FPA_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case fpaMonitoringAction.GET_FPA_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case fpaMonitoringAction.GET_FPA_ADJXERR_NUM_RESPONSE:
      return {
        ...state,
        listDataADJXERR: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // log error
    // store response
    case fpaMonitoringAction.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case fpaMonitoringAction.GET_FPA_BATCH_NUM_ELIGIBLE:
      return {
        ...state,
        eligibleBatchNum: action.data,
        eligibleBatchNumError: null,
        eligibleBatchNumProcessedOn: new Date(),
        loading: false,
      };
    case fpaMonitoringAction.GET_FPA_BATCH_NUM_NONELIGIBLE:
      return {
        ...state,
        nonEligibleBatchNum: action.data,
        nonEligibleBatchNumError: null,
        nonEligibleBatchNumProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const FpaMonitoringFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = FpaMonitoringFilterListConfig(state, action);
  const stateReqRes = FpaMonitoringFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default FpaMonitoringFilterList;
