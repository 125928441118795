/* eslint-disable no-unused-vars */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  relationshipActionsTypes,
  clientActionsTypes,
} from '../actions/actionTypes';
import {
  exportErrorMsg,
  formatResponse,
  Logger,
  processError,
  renderFileName,
  resetDeletetValue,
} from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
  setUserPrefError,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

const ERROR_MSG_LENGTH_EXPORT = 'The number of records has exceeded the maximum limit.';
const errorCode = 402;
const exportMaxLimitErrorObj = [
  {
    errorCode: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
    errorMessage: ERROR_MSG_LENGTH_EXPORT,
    errorMessageId: 'COMMON_EXCEPTION_EXPORT_MAX_LIMIT',
    type: 'RULE',
  },
];

export function* getRelationshipList(action) {
  Logger.verbose(`Action Detail For Get COMPCAL List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.CLIENT_RELATIONSHIP_LIST, action.data);
    yield all([
      put(getAction(relationshipActionsTypes.GET_RELATIONSHIP_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(clientActionsTypes.GET_CS_LIST_ERROR, err)),
      put(getAction(clientActionsTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* delRelationshipList(action) {
  Logger.verbose(`Action Detail For Get COMPCAL List : ${JSON.stringify(action)}`);
  yield all([
    put(getAction(relationshipActionsTypes.RESET_SERVER_VALUE, null)),
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.DELETE_RELATIONSHIP_LIST, action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
      yield put(getAction(relationshipActionsTypes.RESET_DELETE_VALUE, resetDeleteValue));
    }
    yield all([
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    // resetServerValue = { serverRequestError: processError(err) };
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      // put(getAction(clientActionsTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(relationshipActionsTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getClientRelationshipExportList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(relationshipActionsTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.CLIENT_RELATIONSHIP_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(relationshipActionsTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  yield takeEvery(relationshipActionsTypes.GET_RELATIONSHIP_LIST_REQUEST, getRelationshipList);
  yield takeEvery(relationshipActionsTypes.DELETE_CLIENT_RELATIONSHIP_REQUEST, delRelationshipList);
  yield takeEvery(relationshipActionsTypes.GET_CLINET_RELATIONSHIP_EXPORT, getClientRelationshipExportList);
}

export default moduleWatcher;
