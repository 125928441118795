/**
 * @Description Fasat Filet Operator
 * @FileName fasatOperatorInput.js
 * @Author Karthick. K - kkarthk
 * @CreatedOn 13 July, 2022 21:40:00
 * @IssueID - 4780
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { staticCommonLabelKeys } from '../../../moduleConstants';
import FasatDropdown from '../../fasatDropdown/fasatDropdown';
import { getCurrentLocale } from '../../../i18n';

const OPT_GROUP_BY = 'OPT_GROUP_BY';
const OPT_MIN = 'OPT_MIN';
const OPT_MAX = 'OPT_MAX';
const OPT_SUM = 'OPT_SUM';
const text = 'text';
const date = 'date';
const number = 'number';
const decimal = 'decimal';
const boolean = 'boolean';

const setConfig = ({ columnValue, operatorList, setFilterOpertorList,
  setOperatorValue, onOperatorChange, optrValue, t }) => {
  if (columnValue && operatorList) {
    let list = [];
    const formatOption = (operatorItem) => ({ ...operatorItem,
      label: t(operatorItem.operator),
      value: operatorItem.operator });
    if (columnValue.type === text || columnValue.type === boolean) {
      list = operatorList.filter(({ isGroupBy, operator }) => isGroupBy === true && operator === OPT_GROUP_BY)
        .map((operatorItem) => (formatOption(operatorItem)));
    }
    if (columnValue.type === date) {
      list = operatorList.filter(({ isGroupBy, operator }) => isGroupBy === true && (operator === OPT_GROUP_BY
       || operator === OPT_MIN || operator === OPT_MAX))
        .map((operatorItem) => (formatOption(operatorItem)));
    }
    if (columnValue.type === number || columnValue.type === decimal) {
      list = operatorList.filter(({ isGroupBy }) => isGroupBy === true)
        .map((operatorItem) => (formatOption(operatorItem)));
    }
    setFilterOpertorList(list);
    setOperatorValue(optrValue);
    onOperatorChange(optrValue);
  } else {
    setFilterOpertorList([]);
  }
};

const FasatOperatorInput = ({ columnValue,
  operatorList,
  onOperatorChange,
  customFilterCreteriaElement,
  preSelectedIndex,
  inputConfiguration,
  groupByActive,
  optValue,
}) => {
  const [t] = useTranslation();
  const locale = getCurrentLocale();
  const [operatorValue, setOperatorValue] = useState(optValue);
  const [filterOperatorList, setFilterOpertorList] = useState([]);
  const dropdownPlaceholder = t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT);
  const fcOperatorID = 'fcOperator';
  const optrValue = optValue || (columnValue && columnValue.type === number ? OPT_SUM : OPT_GROUP_BY);

  /**
    * generate Operator List as per Column Value Selection and Set Opertor value to OPT_EQUALS
    */
  useEffect(() => {
    setOperatorValue(null);
    setConfig({ columnValue, operatorList, setFilterOpertorList, setOperatorValue, onOperatorChange, optrValue, t });
  }, [columnValue, locale]);

  /**
    * Set operatot Value while auto population of operator
    */
  useEffect(() => {
    if (inputConfiguration.autoPopulate && preSelectedIndex > -1
       && customFilterCreteriaElement[preSelectedIndex].operator) {
      setOperatorValue(customFilterCreteriaElement[preSelectedIndex].operator);
      onOperatorChange(customFilterCreteriaElement[preSelectedIndex].operator);
    }
  }, [filterOperatorList]);

  /**
    * Set oerator Value as per selection and trigger event onOperatorChange
    * @param {string} item
    */
  const handleOperatorChange = (item) => {
    setOperatorValue(item);
    onOperatorChange(item);
  };

  return (
    <>
      <FasatDropdown
        dropdownItems={filterOperatorList}
        selected={0}
        id={fcOperatorID}
        onHandleChange={(val) => val && handleOperatorChange(val)}
        value={operatorValue}
        placeholder={dropdownPlaceholder}
        ariaLabel={t(staticCommonLabelKeys.FILTER_LABEL_OPERATOR)}
        disabled={!groupByActive && 'disabled'}
      />
    </>
  );
};
// #region component
FasatOperatorInput.propTypes = {
  operatorList: PropTypes.arrayOf(PropTypes.shape()),
  columnValue: PropTypes.shape({
    value: PropTypes.string,
    type: PropTypes.string,
    lookupKey: PropTypes.string,
  }),
  onOperatorChange: PropTypes.func,
  preSelectedIndex: PropTypes.number,
  groupByActive: PropTypes.bool,
  inputConfiguration: PropTypes.shape({
    isInputComponent: PropTypes.bool,
    isMultiselect: PropTypes.bool,
    isBetween: PropTypes.bool,
    isDatePickerComponent: PropTypes.bool,
    isDropdownComponent: PropTypes.bool,
    autoPopulate: PropTypes.bool,
  }),
  customFilterCreteriaElement: PropTypes.arrayOf(PropTypes.shape()),
  optValue: PropTypes.string,
};

FasatOperatorInput.defaultProps = {
  operatorList: [],
  columnValue: null,
  onOperatorChange: () => undefined,
  groupByActive: true,
  preSelectedIndex: -1,
  inputConfiguration: {
    isInputComponent: true,
    isMultiselect: false,
    isBetween: false,
    isDatePickerComponent: false,
    isDropdownComponent: false,
    autoPopulate: false,
  },
  customFilterCreteriaElement: null,
  optValue: null,
};
export default FasatOperatorInput;
