/**
 * @Description Report module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Goutam Pal - pgoutam
 * @CreatedOn 26 Aug 22
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  balSummaryActionTypes,
  prodSummaryActionTypes,
  reportActionTypes,
  commissionFeedActionTypes,
  interactiveActivityReportActionTypes,
} from '../actions/actionTypes';

import { exportErrorMsg, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { exportFileType, globalConstants } from '../../../../common/moduleConstants';

/**
  * Get LRO List
  * @param {any} action
  */
export function* getReportList(action) {
  Logger.verbose(`Action Detail For Get report List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(reportActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.REPORT_LIST, action.data);
    yield all([
      put(getAction(reportActionTypes.GET_REPORT_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(reportActionTypes.GET_REPORT_LIST_ERROR, err)),
      put(getAction(reportActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getProdSummaryExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(prodSummaryActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  const payload = action.data.formatExtObject ? action.data.formatExtObject : action.data;
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.PROD_SUMMARY_EXPORT, payload);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    if (action.data.popUpClose) {
      action.data.popUpClose();
    }
    yield put(processingCompleted());
  } catch (err) {
    if (
      err.response.status === globalConstants.errorCode402
      || err.response.status === globalConstants.errorCode403
    ) {
      const errorObject = exportErrorMsg(err.response.status, payload?.exportFileType);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(prodSummaryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getProdSummaryList(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(prodSummaryActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(prodSummaryActionTypes.LOG_PRODSUM_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.PROD_SUMMARY_LIST, action.data);
    yield all([
      put(getAction(prodSummaryActionTypes.GET_PRODSUM_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(prodSummaryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(prodSummaryActionTypes.GET_PRODSUM_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getBalSummaryExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(balSummaryActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  const payload = action.data.formatExtObject ? action.data.formatExtObject : action.data;
  try {
    const responseData = yield call(invokeApi, moduleAPI.BAL_SUMMARY_EXPORT, payload);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    if (action.data.popUpClose) {
      action.data.popUpClose();
    }
    yield put(processingCompleted());
  } catch (err) {
    if (
      err.response.status === globalConstants.errorCode403
      || err.response.status === globalConstants.errorCode402
    ) {
      const errorObject = exportErrorMsg(err.response.status, payload?.exportFileType);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(balSummaryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getBalSummaryList(action) {
  Logger.verbose(`Action Detail For Get Gen Edit Type List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(balSummaryActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(balSummaryActionTypes.LOG_BALSUM_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.BAL_SUMMARY_LIST, action.data);
    yield all([
      put(getAction(balSummaryActionTypes.GET_BALSUM_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(balSummaryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(balSummaryActionTypes.GET_BALSUM_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

export function* getCommissionFeddOnChange(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi, moduleAPI.COMMISSION_FEED_DATA, action.data);
    if (responseData) {
      resetUpsertValue = { onChangeData: responseData };
      yield all([
        put(getAction(commissionFeedActionTypes.GET_ONCHANGE_DETAILS_RESPONSE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(commissionFeedActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

export function* getGenerateRequest(action) {
  let resetUpsertValue = null;
  yield all([put(processingStarted()),
    put(getAction(commissionFeedActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
    put(getAction(commissionFeedActionTypes.GET_GENERATE_RESPONSE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.COMMISSION_GENERATE_REQUEST, action.data);
    if (responseData) {
      try {
        yield call(invokeApi, moduleAPI.COMMISSION_GENERATE_REQUEST_BATCH, responseData);
        yield all([
          put(getAction(commissionFeedActionTypes.GET_GENERATE_RESPONSE, 'Success')),
          put(processingCompleted()),
        ]);
      } catch (err) {
        resetUpsertValue = {
          upsertRequest: null,
          upsertRequestError: processError(err),
          upsertRequestedOn: null,
          upsertRequestProcessedOn: null,
          upsertData: null,
        };
        yield all([
          put(getAction(commissionFeedActionTypes.GET_GENERATE_RESPONSE, 'ComfeedFail')),
          put(getAction(commissionFeedActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
          put(processingCompleted())]);
      }
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(commissionFeedActionTypes.GET_GENERATE_RESPONSE, 'ComfeedFail')),
      put(getAction(commissionFeedActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

export function* getIARActivityReportData(action) {
  yield all([
    put(processingStarted()),
    put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.IAR_ACTIVITY_PERIOD_LOOKUP, action.data);
    yield all([
      put(getAction(interactiveActivityReportActionTypes.SET_AP_LOOKUP_VALUES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getCompensationTypesList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(interactiveActivityReportActionTypes.SET_COMP_TYPE_LIST_RESPONSE, null)),
    put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.IAR_COMP_TYPE_LIST, action.data);
    yield all([
      put(
        getAction(
          interactiveActivityReportActionTypes.SET_COMP_TYPE_LIST_RESPONSE,
          responseData.data,
        ),
      ),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(
        getAction(interactiveActivityReportActionTypes.SET_COMP_LIST_REQ_ERROR, processError(err)),
      ),
      put(processingCompleted()),
    ]);
  }
}
export function* getManulifeSellingCodesList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(interactiveActivityReportActionTypes.SET_MANULIFE_SELLING_CODES_LIST_RESPONSE, null)),
    put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.IAR_MSCodes_LIST, action.data);
    yield all([
      put(
        getAction(
          interactiveActivityReportActionTypes.SET_MANULIFE_SELLING_CODES_LIST_RESPONSE,
          responseData,
        ),
      ),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(
        getAction(interactiveActivityReportActionTypes.SET_COMP_LIST_REQ_ERROR, processError(err)),
      ),
      put(processingCompleted()),
    ]);
  }
}
export function* getCompensationTypesHierList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(interactiveActivityReportActionTypes.SET_COMP_TYPE_HIER_LIST_RESPONSE, undefined)),
    put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.IAR_COMP_TYPE_HIER_LIST,
      action.data,
    );
    yield all([
      put(
        getAction(
          interactiveActivityReportActionTypes.SET_COMP_TYPE_HIER_LIST_RESPONSE,
          responseData.data,
        ),
      ),

      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(
        getAction(interactiveActivityReportActionTypes.SET_COMP_LIST_REQ_ERROR, processError(err)),
      ),

      put(processingCompleted()),
    ]);
  }
}

export function* exportIarCompType(action) {
  yield all([
    put(processingStarted()),
    put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  let fileType = exportFileType.Excel;
  try {
    const payload = action.data?.formatExtObject;
    fileType = payload?.exportFileType;
    const responseData = yield call(
      invokeApi,
      moduleAPI.EXPORT_IAR_COMPTYPES,
      payload,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    action?.data?.popUpClose();
    yield put(processingCompleted());
  } catch (err) {
    if (err.response?.status === globalConstants.errorCode402 || err.response?.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status, fileType);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
      // close export modal and show message on list page
      action.data.popUpClose();
    } else {
      // Don't close modal show error in modal window
      resetServerValue = { exportRequestError: processError(err) };
    }
    yield all([
      put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* exportIarProduct(action) {
  yield all([
    put(processingStarted()),
    put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  let fileType = exportFileType.Excel;
  try {
    const payload = action.data?.formatExtObject;
    fileType = payload?.exportFileType;
    const responseData = yield call(
      invokeApi,
      moduleAPI.EXPORT_IAR_PRODUCT,
      payload,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    action?.data?.popUpClose();
    yield put(processingCompleted());
  } catch (err) {
    if (err.response?.status === globalConstants.errorCode402 || err.response?.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status, fileType);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
      // close export modal and show message on list page
      action.data.popUpClose();
    } else {
      // Don't close modal show error in modal window
      resetServerValue = { exportRequestError: processError(err) };
    }
    yield all([
      put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* exportIarMsc(action) {
  yield all([
    put(processingStarted()),
    put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  let fileType = exportFileType.Excel;
  try {
    const payload = action.data?.formatExtObject;
    fileType = payload?.exportFileType;
    const responseData = yield call(
      invokeApi,
      moduleAPI.EXPORT_IAR_MSC,
      payload,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    action?.data?.popUpClose();
    yield put(processingCompleted());
  } catch (err) {
    if (err.response?.status === globalConstants.errorCode402 || err.response?.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status, fileType);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
      // close export modal and show message on list page
      action.data.popUpClose();
    } else {
      // Don't close modal show error in modal window
      resetServerValue = { exportRequestError: processError(err) };
    }
    yield all([
      put(getAction(interactiveActivityReportActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  // REPORT
  yield takeEvery(reportActionTypes.GET_REPORT_LIST_REQUEST, getReportList);
  yield takeEvery(prodSummaryActionTypes.GET_PRODSUM_LIST_REQUEST, getProdSummaryList);
  yield takeEvery(prodSummaryActionTypes.GET_PRODSUM_EXPORT_REQUEST, getProdSummaryExportList);
  yield takeEvery(balSummaryActionTypes.GET_BALSUM_LIST_REQUEST, getBalSummaryList);
  yield takeEvery(balSummaryActionTypes.GET_BALSUM_EXPORT_REQUEST, getBalSummaryExportList);
  yield takeEvery(commissionFeedActionTypes.GET_ONCHANGE_DETAILS_REQUEST, getCommissionFeddOnChange);
  yield takeEvery(commissionFeedActionTypes.GET_GENERATE_REQUEST, getGenerateRequest);
  // Interactive activity report data
  yield takeEvery(
    interactiveActivityReportActionTypes.GET_IAR_ACTIVITY_PERIOD_LOOKUP,
    getIARActivityReportData,
  );
  yield takeEvery(
    interactiveActivityReportActionTypes.GET_COMP_TYPE_LIST,
    getCompensationTypesList,
  );
  yield takeEvery(
    interactiveActivityReportActionTypes.GET_MSCodes_List,
    getManulifeSellingCodesList,
  );
  yield takeEvery(
    interactiveActivityReportActionTypes.GET_COMP_TYPE_HIER_LIST,
    getCompensationTypesHierList,
  );

  yield takeEvery(interactiveActivityReportActionTypes.EXT_IAR_COMP_EXPORT, exportIarCompType);
  yield takeEvery(interactiveActivityReportActionTypes.EXT_IAR_MSC_EXPORT, exportIarMsc);
  yield takeEvery(interactiveActivityReportActionTypes.EXT_IAR_PRODUCT_EXPORT, exportIarProduct);
}

export default moduleWatcher;
