import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { taskInquiryActionTypes } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from '../../../report/redux/reducers/utilities/utility';
import { commonTypes } from '../../../../common/redux/actions/commonActions';

export const REDUCER_NAME = 'TaskInquiryFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const taskInqFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case taskInquiryActionTypes.UPDATE_TASKINQ_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case taskInquiryActionTypes.REMOVE_TASKINQ_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case taskInquiryActionTypes.UPDATE_TASKINQ_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const taskInqFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case taskInquiryActionTypes.LOG_TASKINQ_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case taskInquiryActionTypes.GET_TASKINQ_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case taskInquiryActionTypes.GET_TASKINQ_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case taskInquiryActionTypes.RESET_TASKINQ_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case taskInquiryActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case taskInquiryActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const TaskInquiryFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = taskInqFilterListConfig(state, action);
  const stateReqRes = taskInqFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default TaskInquiryFilterList;
