/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { bcrActionTypes, bcrDetailActionTypes,
  finArrangementsAdjActionTypes, massAgentTransferInqActionTypes, paymentControllerActionTypes,
  reqInquiryActionTypes,
  taskInquiryActionTypes } from '../actions/actionTypes';
import { formatResponse, exportErrorMsg, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
  setUserPrefError,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for BCR Listing
   */
export function* getBcrList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(bcrActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(bcrActionTypes.LOG_BCR_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_BCR_LIST,
      action.data);
    yield all([
      put(getAction(bcrActionTypes.GET_BCR_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(bcrActionTypes.GET_BCR_LIST_ERROR, err)),
      put(getAction(bcrActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

/**

   * This generator function will invoke the api

   * @param {object} action - action which will request for BCR Listing

   */

export function* getBcrbyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(bcrActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(bcrActionTypes.LOG_BCR_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_BCR_BY_ID,
      action.data);
    yield all([
      put(getAction(bcrActionTypes.GET_BCR_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(bcrActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(bcrActionTypes.GET_BCR_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
   * @param {object} action - action which will insert for new BCR
   */
export function* insertBcr(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_BCR_INSERT,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(bcrActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(bcrActionTypes.GET_BCR_LIST_REQUEST, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(bcrActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will update for existing BCR
   */
export function* updateBcr(action) {
  yield all([
    put(processingStarted()),
    put(getAction(bcrActionTypes.UPSERT_BCR_REQUEST, action.data.formatObject)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_BCR_UPDATE,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
    }
    yield all([
      put(getAction(bcrActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(bcrActionTypes.GET_BCR_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(bcrActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will delete existing BCR
   */
export function* deleteBcr(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_BCR_DELETE,
      action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
    }
    yield all([
      put(getAction(bcrActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(bcrActionTypes.RESET_UPSERT_ERROR, resetUpsertValue)),
      put(getAction(bcrActionTypes.GET_BCR_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(bcrActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(bcrActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for BCR Detail Listing
   */
export function* getBcrDetailList(action) {
  let resetServerValue = null;
  yield all([
    put(processingStarted()),
    put(setUserPrefError(resetServerValue)),
    put(getAction(bcrDetailActionTypes.LOG_BCR_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_BCR_DETAIL_LIST,
      action.data);
    yield all([
      put(getAction(bcrDetailActionTypes.GET_BCR_DETAIL_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(setUserPrefError(resetServerValue)), put(processingCompleted()),
      put(getAction(bcrDetailActionTypes.GET_BCR_DETAIL_ERROR, err)),
      put(processingCompleted())]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will request for BCR Export Listing
   */
export function* getBcrExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(bcrActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_BCR_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(bcrActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getFinArrangementsList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(finArrangementsAdjActionTypes.LOG_FINARRANGEMENTS_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FINARRANGEMENT_LIST,
      action.data);
    yield all([
      put(getAction(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, processError(err))),
      put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}
export function* getFinArrangementsTransferId(action) {
  yield all([
    put(processingStarted()),
    put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_ERROR)),
    put(getAction(finArrangementsAdjActionTypes.LOG_FINARRANGEMENTS_LIST_REQUEST, action.data)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FINARRANGEMENT_TRANSFER_ID,
      action.data);
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_ERROR)),
      put(getAction(finArrangementsAdjActionTypes.SET_TRANSFER_AGENT_NAME,
        { transferAdvisorName: responseData?.transferId })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* setFinArrangementsAddLock(action) {
  const { orgFinarrangement, setStore } = action.data;
  yield all([
    put(processingStarted()),
    put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(finArrangementsAdjActionTypes.LOG_FINARRANGEMENTS_LIST_REQUEST, orgFinarrangement)),
  ]);
  try {
    yield call(invokeApi, moduleAPI.ADMINISTRATION_FINARRANGEMENT_ADD_LOCK, orgFinarrangement);
    yield put(processingCompleted());
    setStore(true);
  } catch (err) {
    if (processError(err)[0].errorCode === 'ERR_9949' || processError(err)[0].errorCode === 'ERR_9953') {
      const resetServerValue = { serverRequestError: processError(err) };
      yield all([put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, processError(err))),
        put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, resetServerValue)),
        put(processingCompleted())]);
    } else {
      yield all([
        put(getAction(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_BY_ID_ERROR, processError(err))),
        put(processingCompleted()),
      ]);
    }
    setStore(false);
  }
}

export function* insertFinArrangements(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FINARRANGEMENT_INSERT,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
export function* cloneFinArrangements(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FINARRANGEMENT_CLONE,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

export function* getFFFinArrangementItem(action) {
  yield all([
    put(processingStarted()),
    put(getAction(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_BY_ID_RESPONSE, null)),
    put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.FF_FINE_ARRANGEMENT_ITEM_BY_ID,
      action.data.reqObjForId);
    yield all([
      put(getAction(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const concurrencyErrorCodes = ['ERR_9949', 'ERR_9971', 'ERR_9970'];
    if (concurrencyErrorCodes.includes(processError(err)[0].errorCode)) {
      const resetServerValue = { serverRequestError: processError(err) };
      yield all([put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, processError(err))),
        put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, resetServerValue)),
        put(processingCompleted())]);
    } else {
      yield all([
        put(getAction(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_BY_ID_ERROR, processError(err))),
        put(processingCompleted()),
      ]);
    }
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will update for existing BCR
   */
export function* updateFinArragements(action) {
  yield all([
    put(processingStarted()),
    put(getAction(finArrangementsAdjActionTypes.UPSERT_FINARRANGEMENTS_REQUEST, action.data.formatObject)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FINARRANGEMENT_UPDATE,
      action.data.formatObject);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
    }
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
   * This generator function will invoke the api
   * @param {object} action - action which will update for existing BCR
   */
export function* updateFinArragementsEndDate(action) {
  yield all([
    put(processingStarted()),
    put(getAction(finArrangementsAdjActionTypes.UPSERT_FINARRANGEMENTS_REQUEST, action.data.formatObject)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FINARRANGEMENT_UPDATE_ENDDATE,
      action.data.formatObject);
    if (action.data.formatObject) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
    }
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will delete existing BCR
   */
export function* deleteFinArrangements(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FINARRANGEMENT_DELETE,
      action.data.formatObject);
    if (responseData) {
      resetDeleteValue = {
        deleteRequest: null,
        deleteRequestError: null,
        deleteRequestedOn: null,
        deleteRequestProcessedOn: new Date(),
        deleteData: responseData,
      };
    }
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_ERROR, resetUpsertValue)),
      put(getAction(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(finArrangementsAdjActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for Fin Arrangements Export Listing
 */
export function* exportFinArrangements(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FINARRANGEMENT_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* checkTabFinArrngmntVisibility(action) {
  yield all([
    put(processingStarted()),
    put(getAction(finArrangementsAdjActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_FINARRANGEMENT_TAB_VISIBILITY, action.data);
    yield all([
      // put(getAction(finArrangementsAdjActionTypes.SET_FINARRANGEMENTS_TAB_VISIBILITY_REQUEST, undefined)),
      put(getAction(finArrangementsAdjActionTypes.SET_FINARRANGEMENTS_TAB_VISIBILITY_REQUEST, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      put(getAction(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_BY_ID_ERROR, processError(err))),
      put(processingCompleted()),
    ]);
  }
}

// requirement inquiry
export function* getReqInquiryExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(reqInquiryActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.REQ_INQUIRY_EXPORT,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(reqInquiryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getReqInquiryList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(reqInquiryActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(reqInquiryActionTypes.LOG_REQINQ_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.REQ_INQUIRY_LIST, action.data);
    yield all([
      put(getAction(reqInquiryActionTypes.GET_REQINQ_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(reqInquiryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(reqInquiryActionTypes.GET_REQINQ_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

// task inquiry
export function* getTaskInquiryExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(taskInquiryActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.TASK_INQUIRY_EXPORT,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(taskInquiryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getTaskInquiryList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(taskInquiryActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(taskInquiryActionTypes.LOG_TASKINQ_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.TASK_INQUIRY_LIST, action.data);
    yield all([
      put(getAction(taskInquiryActionTypes.GET_TASKINQ_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(taskInquiryActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(taskInquiryActionTypes.GET_TASKINQ_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

// mass agent tranfer inquiry
export function* getMassAgentTransferInquiryExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(massAgentTransferInqActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.MASS_AGENT_TRANSFER_INQUIRY_EXPORT,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(massAgentTransferInqActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getMassAgentTransferInquiryList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(massAgentTransferInqActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(massAgentTransferInqActionTypes.LOG_MATI_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.MASS_AGENT_TRANSFER_INQUIRY_LIST, action.data);
    yield all([
      put(getAction(massAgentTransferInqActionTypes.GET_MATI_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(massAgentTransferInqActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(massAgentTransferInqActionTypes.GET_MATI_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

// mass agent tranfer inquiry
export function* getPaymentControllerExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(paymentControllerActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.PAYMENT_CONTROLLER_EXPORT,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(paymentControllerActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getPaymentControllerList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(paymentControllerActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(paymentControllerActionTypes.LOG_PCM_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.PAYMENT_CONTROLLER_LIST, action.data);
    yield all([
      put(getAction(paymentControllerActionTypes.GET_PCM_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(paymentControllerActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(paymentControllerActionTypes.GET_PCM_LIST_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

function* moduleWatcher() {
  yield takeEvery(bcrActionTypes.GET_BCR_LIST_REQUEST, getBcrList);
  yield takeEvery(bcrActionTypes.INSERT_BCR_REQUEST, insertBcr);
  yield takeEvery(bcrActionTypes.UPDATE_BCR_REQUEST, updateBcr);
  yield takeEvery(bcrActionTypes.DELETE_BCR_REQUEST, deleteBcr);
  // bcr detail
  yield takeEvery(bcrDetailActionTypes.GET_BCR_DETAIL_LIST_REQUEST, getBcrDetailList);
  // bcr export
  yield takeEvery(bcrActionTypes.GET_BCR_EXPORT_REQUEST, getBcrExportList);
  // bcr itembyid
  yield takeEvery(bcrActionTypes.GET_BCR_BY_ID_REQUEST, getBcrbyID);
  yield takeEvery(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_LIST_REQUEST, getFinArrangementsList);
  yield takeEvery(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_TRANSFERID_REQ, getFinArrangementsTransferId);
  yield takeEvery(finArrangementsAdjActionTypes.SET_FIN_ARR_LOCK, setFinArrangementsAddLock);
  yield takeEvery(finArrangementsAdjActionTypes.INSERT_FINARRANGEMENTS_REQUEST, insertFinArrangements);
  yield takeEvery(finArrangementsAdjActionTypes.CLONE_FINARRANGEMENTS_REQUEST, cloneFinArrangements);
  yield takeEvery(finArrangementsAdjActionTypes.UPDATE_FINARRANGEMENTS_REQUEST, updateFinArragements);
  yield takeEvery(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_ITEM_BY_ID, getFFFinArrangementItem);
  yield takeEvery(finArrangementsAdjActionTypes.UPDATE_FINARRANGEMENTS_ENDDATE_REQUEST, updateFinArragementsEndDate);
  yield takeEvery(finArrangementsAdjActionTypes.DELETE_FINARRANGEMENTS_REQUEST, deleteFinArrangements);
  yield takeEvery(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_EXPORT_REQUEST, exportFinArrangements);
  yield takeEvery(finArrangementsAdjActionTypes.GET_FINARRANGEMENTS_TAB_VISIBILITY_REQUEST, checkTabFinArrngmntVisibility);
  yield takeEvery(reqInquiryActionTypes.GET_REQINQ_LIST_REQUEST, getReqInquiryList);
  yield takeEvery(reqInquiryActionTypes.GET_REQINQ_EXPORT_REQUEST, getReqInquiryExportList);
  yield takeEvery(taskInquiryActionTypes.GET_TASKINQ_LIST_REQUEST, getTaskInquiryList);
  yield takeEvery(taskInquiryActionTypes.GET_TASKINQ_EXPORT_REQUEST, getTaskInquiryExportList);
  yield takeEvery(massAgentTransferInqActionTypes.GET_MATI_LIST_REQUEST, getMassAgentTransferInquiryList);
  yield takeEvery(massAgentTransferInqActionTypes.GET_MATI_EXPORT_REQUEST, getMassAgentTransferInquiryExportList);
  yield takeEvery(paymentControllerActionTypes.GET_PCM_LIST_REQUEST, getPaymentControllerList);
  yield takeEvery(paymentControllerActionTypes.GET_PCM_EXPORT_REQUEST, getPaymentControllerExportList);
}

export default moduleWatcher;
