/**
 * @Description This file contains all the API configuration for Compensation Structure module
 * @FileName apiConfig.js
 * @Author Jayanta Sarkhel - sarkhja
 * @CreatedOn 15 march, 2021 9.30
 * @IssueID
*/

import { environment } from '../../../common/util';

const envData = environment();
/**
 * Module specific API endpoint configuration.
 */
const apiConfig = {
  ADMINISTRATION_CS_LIST: {
    endpoint: `${envData.apiHost}/api/comphierarchy/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_CS_BY_ID: {
    endpoint: `${envData.apiHost}/api/comphierarchy/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,

  },
  DEFAULT_CS_BY_ID: {
    endpoint: `${envData.apiHost}/api/defcomphierarchy/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,

  },
  COMPSTRUCTURE_INSERT: {
    endpoint: `${envData.apiHost}/api/comphierarchy`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  COMPSTRUCTURE_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/comphierarchy/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  COMPSTRUCTURE_RT_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/comphierarchy/exportratetable`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_COMPSTRUCTRE_UPDATE: {
    endpoint: `${envData.apiHost}/api/comphierarchy`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  DEFAULT_COMPSTRUCTRE_UPDATE: {
    endpoint: `${envData.apiHost}/api/defcomphierarchy`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_COMPSTRUCTRE_AGENT_NAME: {
    endpoint: `${envData.apiHost}/api/comphierarchy/agentname/@@agentCode`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  DEFAULT_COMPSTRUCTURE_INSERT: {
    endpoint: `${envData.apiHost}/api/defcomphierarchy`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  DEFAULT_COMPSTRUCTURE_DELETE: {
    endpoint: `${envData.apiHost}/api/defcomphierarchy`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  COMPSTRUCTURE_DELETE: {
    endpoint: `${envData.apiHost}/api/comphierarchy`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  COMPSTRUCTURE_VALIDATE_DELETE: {
    endpoint: `${envData.apiHost}/api/comphierarchy/validateDeleteRefrence`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_BCR_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/businesscardrules/businesscardruledetail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  DEFAULT_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/defcomphierarchy/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  DEFAULT_CS_LIST: {
    endpoint: `${envData.apiHost}/api/defcomphierarchy/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // LRO
  ADMINISTRATION_LRO_LIST: {
    endpoint: `${envData.apiHost}/api/lroeligibility/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // #region Rate Matster related apis
  RATE_MASTER_ADD_LOCK: {
    endpoint: `${envData.apiHost}/api/ratemaster/checkAndApplyLockonAdd`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_MASTER_LIST: {
    endpoint: `${envData.apiHost}/api/ratemaster/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_LRO_BY_ID: {
    endpoint: `${envData.apiHost}/api/lroeligibility/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_MASTER_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/ratedetail/list/ratedetail`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_LRO_INSERT: {
    endpoint: `${envData.apiHost}/api/lroeligibility`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_KEY_LIST: {
    endpoint: `${envData.apiHost}/api/ratedetail/list/ratekeyfields`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_LRO_UPDATE: {
    endpoint: `${envData.apiHost}/api/lroeligibility`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_LRO_DELETE: {
    endpoint: `${envData.apiHost}/api/lroeligibility`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  ADMINISTRATION_LRO_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/lroeligibility/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  GET_LRO_LOOKUP_REQUEST: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_DETAIL_LIST: {
    endpoint: `${envData.apiHost}/api/ratedetail/list/ratesfields`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_PARTICIPANT_LIST: {
    endpoint: `${envData.apiHost}/api/ratedetail/list/participationfields`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  RATE_MASTER_GET_BY_ID: {
    endpoint: `${envData.apiHost}/api/ratemaster/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_KEY_GET_BY_ID: {
    endpoint: `${envData.apiHost}/api/ratedetail/itembyid/ratekeyfields`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_KEY_ONLOAD: {
    endpoint: `${envData.apiHost}/api/ratedetail/RateKeyOnLoad`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_KEY_ONLOAD_EDIT: {
    endpoint: `${envData.apiHost}/api/ratedetail/Id/CheckForAttachedRates`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  COMP_HIER_TREE: {
    endpoint: `${envData.apiHost}/api/comphierarchy/tree`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_DETAIL_GET_BY_ID: {
    endpoint: `${envData.apiHost}/api/ratedetail/itembyid/ratesfields`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  COMP_RATE_TABLE: {
    endpoint: `${envData.apiHost}/api/comphierarchy/ratetable`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_PARTICIPANT_GET_BY_ID: {
    endpoint: `${envData.apiHost}/api/ratedetail/itembyid/participationfields`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  CS_COMMON_HEADER: {
    endpoint: `${envData.apiHost}/api/hierarchy/header`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_MASTER_ADD: {
    endpoint: `${envData.apiHost}/api/ratemaster`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  SCS_LIST: {
    endpoint: `${envData.apiHost}/api/salescreditsummary/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  SYSTEM_OPTIONS_VIEW: {
    endpoint: `${envData.apiHost}/api/lookups/systemoptions`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_KEY_ADD: {
    endpoint: `${envData.apiHost}/api/ratedetail/add/ratekeyfields`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_DETAIL_ADD: {
    endpoint: `${envData.apiHost}/api/ratedetail/add/RatesFields`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_PARTICIPANT_ADD: {
    endpoint: `${envData.apiHost}/api/ratedetail/add/participationfields`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },

  RATE_MASTER_UPDATE: {
    endpoint: `${envData.apiHost}/api/ratemaster`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_KEY_UPDATE: {
    endpoint: `${envData.apiHost}/api/ratedetail/edit/ratekeyfields`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_DETAIL_UPDATE: {
    endpoint: `${envData.apiHost}/api/ratedetail/edit/RatesFields`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_PARTICIPANT_UPDATE: {
    endpoint: `${envData.apiHost}/api/ratedetail/edit/ParticipationFields`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },

  RATE_MASTER_DELETE: {
    endpoint: `${envData.apiHost}/api/ratemaster`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_KEY_DELETE: {
    endpoint: `${envData.apiHost}/api/ratedetail/delete/RateKeyFields`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_DETAIL_DELETE: {
    endpoint: `${envData.apiHost}/api/ratedetail/delete/RatesFields`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_PARTICIPANT_DELETE: {
    endpoint: `${envData.apiHost}/api/ratedetail/delete/participationfields`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },

  // #endregion
  SCS_EXPORT: {
    endpoint: `${envData.apiHost}/api/salescreditsummary/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  RS_LOOKUPS_DATA: {
    endpoint: `${envData.apiHost}/api/lookups/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_SWEEP_LIST: {
    endpoint: `${envData.apiHost}/api/overridebands/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  INSERT_RATE_SWEEP: {
    endpoint: `${envData.apiHost}/api/overridebands`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  UPDATE_RATE_SWEEP: {
    endpoint: `${envData.apiHost}/api/overridebands`,
    type: 'PUT',
    authorizationRequired: false,
    showLoading: true,
  },
  DELETE_RATE_SWEEP: {
    endpoint: `${envData.apiHost}/api/overridebands`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_SWEEP_GET_BY_ID: {
    endpoint: `${envData.apiHost}/api/overridebands/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_MASTER_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/ratemaster/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_PARTICIPANTS_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/ratedetail/export/participationfields`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_MASTER_ONLOAD: {
    endpoint: `${envData.apiHost}/api/ratemaster/getDetailsRm`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_DETAILS_ONLOAD: {
    endpoint: `${envData.apiHost}/api/ratedetail/Id/ratekeyfieldsOnload`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_PARTICIPANT_ONLOAD: {
    endpoint: `${envData.apiHost}/api/ratedetail/Id/GetSysDateOnload`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_PARTICIPANTS_ONLOAD: {
    endpoint: `${envData.apiHost}/api/ratedetail/GetAgentLevel`,
    type: 'GET',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_MASTER_DETAILS_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/ratedetail/export/ratedetails`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_KEY_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/ratedetail/export/ratekeyfields`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/ratedetail/export/ratesfields`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_MASTER_SWEEP_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/overridebands/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_MASTER_IN_USE: {
    endpoint: `${envData.apiHost}/api/rateMaster/checkRateMasterInUse`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_KEY_IN_USE: {
    endpoint: `${envData.apiHost}/api/ratedetail/checkRateKeyInUse`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_SWEEP_CLONE_LIST: {
    endpoint: `${envData.apiHost}/api/overridebands/viewbands`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  RATE_SWEEP_CLONE_SAVE: {
    endpoint: `${envData.apiHost}/api/overridebands/clone`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  // External Rate
  EXT_RATE_LIST: {
    endpoint: `${envData.apiHost}/api/externalrates/list/externalrates`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_RATE_GET_BY_ID: {
    endpoint: `${envData.apiHost}/api/externalrates/item`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_RATE_GET_BY_NEW_RATE: {
    endpoint: `${envData.apiHost}/api/externalrates/newrate`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_RATE_SAVE: {
    endpoint: `${envData.apiHost}/api/externalrates/edit`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  EXT_RATE_EXPORT: {
    endpoint: `${envData.apiHost}/api/externalrates/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default apiConfig;
