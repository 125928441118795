/**
 * @Description This is FasatH2 component page.
 * This is a wrapper on "TextLink" component from MUX without adding any custom functionlities
 * @FileName FasatH2.js
 * @Author Imroz Dawar- dawarim
 * @CreatedOn 2 February, 2021 20:08:00
 * @IssueID CDTFASATMOD- 325
 */
import React from 'react';
import PropTypes from 'prop-types';
import './fasatH2.scss';

const FasatH2 = (props) => {
  const { children } = props;
  return (
    <h2 className="fasatH2">{children}</h2>
  );
};

FasatH2.propTypes = {
  children: PropTypes.string,
};

FasatH2.defaultProps = {
  children: '',
};

export default FasatH2;
