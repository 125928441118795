/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { manualAdjustmentsActionTypes } from '../actions/actionTypes';
import { processError } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import * as csModuleAPI from '../../../compcal/config/apiConfig';

export function* manualAdjustmentsOnLoad(action) {
  yield all([
    put(processingStarted()),
    put(getAction(manualAdjustmentsActionTypes.RESET_STATE, null)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.MANUAL_ADJUSTMENTS_ON_LOAD, action.data);
    yield all([
      put(getAction(manualAdjustmentsActionTypes.GET_ON_LOAD_RESPONSE, { data: responseData })),
      put(processingCompleted()),
    ]);
  } catch (err) {
    const resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(manualAdjustmentsActionTypes.RESET_ERROR, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getMAHeader(action) {
  yield all([
    put(processingStarted()),
    // put(getAction(fieldForceActionTypes.LOG_FF_DETAIL_REQUEST)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_FF_HEADER,
      action.data);
    yield all([
      put(getAction(manualAdjustmentsActionTypes.GET_HEADER_INFO_RESPONSE, responseData?.headerInfo || -1)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    yield all([
      // put(getAction(fieldForceActionTypes.GET_FF_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}
export function* getAgentName(action) {
  yield all([
    put(processingStarted()),
  ]);
  try {
    const responseData = yield call(invokeApi,
      csModuleAPI.default.ADMINISTRATION_COMPSTRUCTRE_AGENT_NAME, {}, { agentCode: action.data.agentCode });
    yield all([
      put(getAction(manualAdjustmentsActionTypes.GET_MA_TA_NAME_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(manualAdjustmentsActionTypes.GET_MA_TA_NAME_RESPONSE, '')),
      put(processingCompleted())]);
  }
}
export function* getPolicyInfo(action) {
  yield all([
    put(processingStarted()),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.MANUAL_ADJUSTMENTS_POLICY_INFO, action.data);
    yield all([
      put(getAction(manualAdjustmentsActionTypes.GET_POLICY_INFO_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(manualAdjustmentsActionTypes.RESET_POLICY_INFO, '')),
      put(processingCompleted())]);
  }
}

export function* getProductInfo(action) {
  yield all([
    put(processingStarted()),
    put(getAction(manualAdjustmentsActionTypes.RESET_PRODUCT_INFO, {})),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.MANUAL_ADJUSTMENTS_PRODUCT_INFO, action.data);
    yield all([
      put(getAction(manualAdjustmentsActionTypes.GET_PRODUCT_INFO_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(manualAdjustmentsActionTypes.RESET_PRODUCT_INFO, '')),
      put(processingCompleted())]);
  }
}

export function* processAdjustment(action) {
  const resetValues = {
    upsertRequest: null,
    upsertRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(manualAdjustmentsActionTypes.RESET_UPSERT_VALUE, resetValues)),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.PROCESS_MANUAL_ADJUSTMENT,
      action.data);
    if (responseData) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      if (action.data.jobReqId) {
        localStorage.setItem('jobReqId', action.data.jobReqId);
      }
      yield all([
        put(getAction(manualAdjustmentsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(manualAdjustmentsActionTypes.PROCESS_VALUES, responseData)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(manualAdjustmentsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

function* moduleWatcher() {
  yield takeEvery(manualAdjustmentsActionTypes.GET_HEADER_INFO_REQUEST, getMAHeader);
  yield takeEvery(manualAdjustmentsActionTypes.GET_ON_LOAD_REQUEST, manualAdjustmentsOnLoad);
  yield takeEvery(manualAdjustmentsActionTypes.GET_MA_TA_NAME_REQUEST, getAgentName);
  yield takeEvery(manualAdjustmentsActionTypes.GET_POLICY_INFO_REQUEST, getPolicyInfo);
  yield takeEvery(manualAdjustmentsActionTypes.GET_PRODUCT_INFO_REQUEST, getProductInfo);
  yield takeEvery(manualAdjustmentsActionTypes.PROCESS_ADJUSTMENT, processAdjustment);
}

export default moduleWatcher;
