/**
 * This file contains all the styled-components used in the template.
 * If you have a lot of components, it may be a good idea to break this
 * up into different files to keep things manageable.
 */
import styled from 'styled-components';

import { BREAKPOINTS, typography } from '@manulife/mux';

export const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: ${typography.fontFamily};
  font-weight: ${typography.fontWeight};
  font-size: 32px;
  line-height: 42px;
  justify-content: center;
  align-items: center;
  img {
    max-height: 64px;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const FlexItem = styled.span`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  padding: 5px;
`;

export const IconWrapper = styled.span`
  padding-left: 8px;
  padding-right: 8px;
`;

export const LayoutWrapper = styled.div`
  height: 100%;
  display: grid;
  display: -ms-grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  -ms-grid-columns: auto 1fr;
  -ms-grid-rows: auto 1fr;
  overflow: hidden;
`;
LayoutWrapper.displayName = 'LayoutWrapper';

export const HeaderWrapper = styled.div`
  grid-column: 1 / 3;
  grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
`;
LayoutWrapper.HeaderWrapper = 'HeaderWrapper';

export const SideNavWrapper = styled.div`
  grid-column: 1;
  grid-row: 1 / 3;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  & aside {
    position: relative;
    height: 100%;
    @media (max-width: ${BREAKPOINTS.TABLET_PORTRAIT}px) {
      position: absolute;
    }
    ul {
      li {
        a {
          white-space: normal;
        }
      }
    }
  }
  &.noAccessNav {
    a[data-testid="brand-link"], button[data-testid="brand-link-or-button"] {
      cursor: default;
    }
  }
`;
LayoutWrapper.SideNavWrapper = 'SideNavWrapper';

export const LayoutContainer = styled.div`
  grid-column: 2;
  grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: auto;
  padding-bottom: 2em;
`;
LayoutWrapper.LayoutContainer = 'LayoutContainer';

export const LayoutContent = styled.div`
  flex-grow: 1;
  background-color: #fafafa;
`;
LayoutWrapper.LayoutContent = 'LayoutContent';
