/**
 * @Description Reducer for licence process list
 * @FileName licenceProcessFilterList.js
 * @Author Meena Saini
 * @CreatedOn 3 March, 2023 10:00:00
 * @IssueID - Issue IDs
 */
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { fiedForceLetterActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'FFLetterFilterList';

const updateFilterConfig = (state, action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        ...state,
        selectedFilter,
        systemDefaultFilter: action.data.systemDefault || null,
        userDefaultFilter: action.data.userDefault || null,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        ...state,
        selectedFilter,
        userDefaultFilter: action.data.userDefault,
      };
    }
  }
  return {
    ...state,
    selectedFilter,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  selectedFilter: action.data ? action.data : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
});

const logExceptionsListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});
const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const FFLetterFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case fiedForceLetterActionTypes.GET_FF_LETTER_LIST_RES:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
        isChangeStatusSuccess: null,
      };
    case fiedForceLetterActionTypes.UPDATE_FF_LETTER_CONFIG:
      return updateFilterConfig(state, action);

    case fiedForceLetterActionTypes.UPDATE_FF_LETTER_TABLE_CONFIG:
      return updateFilterOption(state, action);

    case fiedForceLetterActionTypes.RESET_FF_LETTER_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
        isChangeStatusSuccess: null,
      };
    case fiedForceLetterActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);

    case fiedForceLetterActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };

    case fiedForceLetterActionTypes.LOG_FF_LETTER_LIST_REQUEST:
      return logExceptionsListRequest(state, action);

    case fiedForceLetterActionTypes.GET_FF_LETTER_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;

    default:
      return state;
  }
};

export default FFLetterFilterList;
