import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { planCodeActionTypes } from '../actions/actionTypes';
import { updateFilterConfig, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'PlanCodeFilterList';

const logListReq = (action, state) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const PlanCodeFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case planCodeActionTypes.UPDATE_PLC_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case planCodeActionTypes.UPDATE_PLC_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    case planCodeActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case planCodeActionTypes.REMOVE_PLC_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case planCodeActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    default:
      return null;
  }
};

const PlanCodeFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case planCodeActionTypes.LOG_PLC_LIST_REQUEST:
      return logListReq(action, state);
    // log error
    case planCodeActionTypes.GET_PLC_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case planCodeActionTypes.GET_PLC_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case planCodeActionTypes.GET_PLC_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case planCodeActionTypes.RESET_PLC_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const PlanCodeFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = PlanCodeFilterListConfig(state, action);
  const stateReqRes = PlanCodeFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default PlanCodeFilterList;
