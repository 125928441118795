import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { interfaceSetupActionType } from '../actions/actionTypes';
import { updateFilterConfig, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'InterfaceSetupFilterList';

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const logImListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const InterfaceSetupFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case interfaceSetupActionType.UPDATE_IS_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case interfaceSetupActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case interfaceSetupActionType.REMOVE_IS_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case interfaceSetupActionType.UPDATE_IS_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const InterfaceSetupFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case interfaceSetupActionType.LOG_IS_LIST_REQUEST:
      return logImListReq(state, action);
      // log error
    case interfaceSetupActionType.GET_IS_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
      // store response
    case interfaceSetupActionType.GET_IS_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case interfaceSetupActionType.GET_IS_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case interfaceSetupActionType.RESET_IS_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case interfaceSetupActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const InterfaceSetupFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = InterfaceSetupFilterListConfig(state, action);
  const stateReqRes = InterfaceSetupFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default InterfaceSetupFilterList;
