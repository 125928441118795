/**
 * @Description Reducer for licence process list
 * @FileName licenceProcessFilterList.js
 * @Author Meena Saini
 * @CreatedOn 3 March, 2023 10:00:00
 * @IssueID - Issue IDs
 */
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { licencesActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'LicenceFilterList';

const updateFilterConfig = (state, action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        ...state,
        systemDefaultFilter: action.data.systemDefault || null,
        selectedFilter,
        userDefaultFilter: action.data.userDefault || null,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        ...state,
        userDefaultFilter: action.data.userDefault,
        selectedFilter,
      };
    }
  }
  return {
    ...state,
    selectedFilter,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  selectedFilter: action.data ? action.data : null,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const logExceptionsListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const getLicenceFilterListState = (state, action = null) => {
  switch (action.type) {
    case licencesActionTypes.GET_LICENCE_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
        isChangeStatusSuccess: null,
      };
    case licencesActionTypes.UPDATE_LICENCEFILTER_CONFIG:
      return updateFilterConfig(state, action);

    case licencesActionTypes.UPDATE_LICENCETABLE_CONFIG:
      return updateFilterOption(state, action);

    case licencesActionTypes.RESET_LICENCE_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
        isChangeStatusSuccess: null,
      };
    case licencesActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);

    case licencesActionTypes.LOG_LICENCE_LIST_REQUEST:
      return logExceptionsListRequest(state, action);

    case licencesActionTypes.GET_LICENCE_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};
const getChangeStatusState = (state, action = null) => {
  switch (action.type) {
    case licencesActionTypes.LICENCE_CHANGE_STATUS_RES:
      return {
        ...state,
        isChangeStatusSuccess: action.data,
      };
    case licencesActionTypes.LOG_LOCK_CHANGE_STATUS_REQ:
      return {
        ...state,
        serverRequestError: null,
      };

    case licencesActionTypes.LOCK_CHANGE_STATUS_RES:
      return {
        ...state,
        isChangeStatusLocked: true,
      };
    case licencesActionTypes.RELEASE_CHANGE_STATUS:
      return {
        ...state,
        isChangeStatusLocked: false,
      };
    case licencesActionTypes.LOCK_CHANGE_STATUS_ERROR:
      return {
        ...state,
        serverRequestError: action.data,
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const LicenceFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const filterListData = getLicenceFilterListState(state, action);
  if (filterListData !== null) {
    return filterListData;
  }
  const changeStatusData = getChangeStatusState(state, action);
  if (changeStatusData !== null) {
    return changeStatusData;
  }
  return state;
};

export default LicenceFilterList;
