/**
 * @Description This is the container page for User Preferance Language
 * @FileName UserPrefLang.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 12 March, 2024 01:03:55
 * @IssueID Issue Detail
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import PubSub from 'pubsub-js';
import { useDispatch, useSelector } from 'react-redux';
import { FasatDropdown, FasatFormElement, FasatMessageCard, showFasatNotification } from '..';
import { staticCommonLabelKeys, globalConstants } from '../../moduleConstants';
import { checkDirtyData, resetUpsertError, saveUserLang } from '../../redux/actions/commonActions';
import { Logger } from '../../util';
import { fetchServerError } from '../fasatPage/fasatPage';
import { getCurrentLocale, setCurrentLocale, t } from '../../i18n';

let formik;
const { serverErrorMsgContainer, notificationTime } = globalConstants;
const ENGLISH = 'E';
const FRENCH = 'F';

export const errorMessage = (serverErrors) => (
  <div className={serverErrorMsgContainer}>
    <FasatMessageCard titleText={t(staticCommonLabelKeys.COMMON_LABEL_IMPORTANT_INFO)} variant="primary_alert">
      {serverErrors && serverErrors.map((errItem) => (fetchServerError(t, errItem)))}
    </FasatMessageCard>
  </div>
);

/**
 * @param {Array} options
 * Validate all form validation and lift event sunmitHandler
 * @returns {Array}
 */
export const validateAndSubmit = ({ dispatch }) => {
  if (formik.dirty) {
    if (formik.values) {
      const formatObject = { userLang: formik.values.userLang };
      dispatch(saveUserLang({ ...formatObject }));
    } else {
      Logger.verbose('Validation');
    }
  }
};

/**
 * @param {Array} options
 * Validate all form validation and lift event sunmitHandler
 * @returns {Array}
 */
export const handleSaveClick = ({ dispatch }) => {
  formik.validateForm().then(() => {
    if (formik.isValid) {
      validateAndSubmit({ dispatch });
    }
  });
};

export const onSaveLang = ({ upsertData, saveEventData, dispatch, currentLocale }) => {
  if (upsertData && saveEventData) {
    saveEventData.close();
    dispatch(checkDirtyData(false));
    showFasatNotification(`${t(staticCommonLabelKeys.COMMON_LABEL_USER_PREF_LANG_SUCC_MSG)}`, notificationTime);
    setTimeout(() => {
      if (currentLocale !== formik.values.userLang) {
        if (formik.values.userLang === ENGLISH) {
          setCurrentLocale('en');
        } else {
          setCurrentLocale('fr');
        }
      }
    });
  }
};

export const handleCloseEvent = ({ cancelEventData }) => {
  if (((!formik.dirty) && cancelEventData)) {
    cancelEventData.close();
  }
};
export const getServerErrors = ({ upsertRequestError, setServerErrors }) => {
  if (upsertRequestError) {
    setServerErrors(upsertRequestError);
  }
};

export const setLanguage = (localLang) => (localLang === 'en' ? ENGLISH : FRENCH);
const UserPrefLang = ({ onSaveTopic, onCancelTopic, formData, closeAddEditModal }) => {
  const currentLocale = setLanguage(getCurrentLocale());
  const [saveSubscriptionIds, setSaveSubscriptionIds] = useState([]);
  const [saveEventData, setSaveEventData] = useState(null);
  const [cancelEventData, setCancelEventData] = useState(null);
  const { upsertData, upsertRequestError } = useSelector(({ ApplicationState }) => ApplicationState);
  const [serverErrors, setServerErrors] = useState([]);
  const dispatch = useDispatch();
  formik = useFormik({ initialValues: formData });
  useEffect(() => {
    if (onSaveTopic && onSaveTopic !== '' && (saveSubscriptionIds || saveSubscriptionIds.length === 0)) {
      const subId = PubSub.subscribe(onSaveTopic, (msg, data) => {
        formik.handleSubmit();
        setSaveEventData(data);
        handleSaveClick({ dispatch });
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    if (onCancelTopic && onCancelTopic !== '') {
      const subId = PubSub.subscribe(onCancelTopic, (msg, data) => {
        setCancelEventData(data);
      });
      saveSubscriptionIds.push(subId);
      setSaveSubscriptionIds(saveSubscriptionIds);
    }
    return function cleanup() {
      saveSubscriptionIds.forEach((subId) => PubSub.unsubscribe(subId));
      dispatch(resetUpsertError());
    };
  }, []);
  useEffect(() => onSaveLang({ upsertData, saveEventData, dispatch, currentLocale }), [upsertData]);
  useEffect(() => {
    getServerErrors({ upsertRequestError, setServerErrors });
  }, [upsertRequestError]);
  useEffect(() => {
    dispatch(checkDirtyData(formik.dirty));
  }, [formik.dirty]);
  useEffect(() => handleCloseEvent({ closeAddEditModal, cancelEventData }), [cancelEventData, closeAddEditModal]);
  return (
    <form id="userPreferanceLang" onSubmit={(e) => e.preventDefault()}>
      {serverErrors.length > 0 && errorMessage(serverErrors)}
      <div>
        <FasatFormElement direction="bottom" id="langForm">
          <FasatDropdown
            dropdownItems={[{ label: 'English', value: ENGLISH }, { label: 'French', value: FRENCH }]}
            onHandleChange={(val) => formik.setFieldValue('userLang', val)}
            id="userLang"
            name="userLang"
            label={t(staticCommonLabelKeys.COMMON_LABEL_USER_PREF_LANG_TITLE)}
            // to be add when we get the i18n ariaLabel={`${t(staticCommonLabelKeys.COMMON_LABEL_PARENTVAL)}
            ariaLabel={t(staticCommonLabelKeys.COMMON_LABEL_USER_PREF_LANG_TITLE)}
            {...formik.getFieldProps('userLang')}
            selected={0}
            placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
          />
        </FasatFormElement>
      </div>
    </form>
  );
};

UserPrefLang.propTypes = {
  onSaveTopic: PropTypes.string.isRequired,
  onCancelTopic: PropTypes.string,
  closeAddEditModal: PropTypes.func,
  formData: PropTypes.shape({
    lang: PropTypes.string,
  }),
};

UserPrefLang.defaultProps = {
  onCancelTopic: '',
  closeAddEditModal: () => null,
  formData: {
    userLang: ENGLISH,
  },
};

export default UserPrefLang;
