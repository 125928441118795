/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  csActionTypes, csDetailActionTypes, defaultCsActionTypes,
  lroEligibilityActionTypes,
} from '../actions/actionTypes';

import { exportErrorMsg, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted,
  processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';
// eslint-disable-next-line no-unused-vars

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for COMPCAL Listing
 */
export function* getCompStructureList(action) {
  Logger.verbose(`Action Detail For Get COMPCAL List : ${JSON.stringify(action)}`);
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(csActionTypes.VALIDATE_DELETE_CS_RESPONSE, null)),
    put(getAction(csActionTypes.LOG_COMPCAL_LIST_REQUEST, action.data)),
    put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CS_LIST,
      action.data);
    yield all([
      put(getAction(csActionTypes.GET_COMP_STRUCTURE_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(csActionTypes.GET_CS_LIST_ERROR, err)),
      put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

export function* getDefaultCompStructureList(action) {
  Logger.verbose(`Action Detail For Get COMPCAL List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(defaultCsActionTypes.LOG_COMPCAL_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.DEFAULT_CS_LIST,
      action.data);
    yield all([
      put(getAction(defaultCsActionTypes.GET_COMP_STRUCTURE_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([put(getAction(defaultCsActionTypes.GET_CS_LIST_ERROR, err)),
      put(getAction(defaultCsActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted())]);
  }
}

/**

 * This generator function will invoke the api

 * @param {object} action - action which will request for COMPCAL Listing

 */

export function* getCsbyID(action) {
  let resetUpsertValue = null;
  resetUpsertValue = {
    upsertRequest: null,
    serverRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([put(processingStarted()),
    put(getAction(csActionTypes.RESET_SERVER_VALUE, resetUpsertValue))]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_CS_BY_ID,
      action.data);
    yield all([
      put(getAction(csActionTypes.GET_CS_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      serverRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(csActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(csActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
    // }
  }
}
/**
 * This generator function will invoke the api
 * @param {object} action - action which will insert for new COMPCAL
 */
export function* getDefaultCsbyID(action) {
  let resetUpsertValue = null;
  let resetServerValue = { serverRequestError: null };
  yield all([put(processingStarted()), put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue))]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.DEFAULT_CS_BY_ID,
      action.data);
    yield all([
      put(getAction(defaultCsActionTypes.GET_DEFAULT_CS_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      serverRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(csActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* insertCompStructure(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.COMPSTRUCTURE_INSERT,
      action.data.formatObject);
    if (responseData.conflictingRecords) {
      yield all([
        put(getAction(csDetailActionTypes.SET_CS_DATE_CONFLICT_RECORDS, responseData.conflictingRecords)),
        put(processingCompleted()),
      ]);
    }
    if (responseData.compHierarchy) {
      resetUpsertValue = {
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: new Date(),
        upsertData: responseData,
      };
      yield all([
        put(getAction(csActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
        put(getAction(csDetailActionTypes.RESET_CS_DATE_CONFLICT_RECORDS, action.data.reqObject)),
        put(processingCompleted()),
      ]);
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(csActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will update for existing COMPCAL
 */
export function* updateCompStructure(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_COMPSTRUCTRE_UPDATE,
      action.data.formatObject);
    if (responseData) {
      if (responseData.conflictingRecords) {
        yield all([
          put(getAction(csDetailActionTypes.SET_CS_DATE_CONFLICT_RECORDS, responseData.conflictingRecords)),
          put(processingCompleted()),
        ]);
      }
      if (responseData.compHierarchy) {
        resetUpsertValue = {
          upsertRequest: null,
          upsertRequestError: null,
          upsertRequestedOn: null,
          upsertRequestProcessedOn: new Date(),
          upsertData: responseData.compHierarchy,
        };
        yield all([
          put(getAction(csActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
          put(getAction(csDetailActionTypes.RESET_CS_DATE_CONFLICT_RECORDS, action.data.reqObject)),
          put(processingCompleted()),
        ]);
      }
    }
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(csActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will delete existing COMPCAL
 */
export function* deleteCs(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.COMPSTRUCTURE_DELETE,
      action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(csActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(csActionTypes.GET_COMPCAL_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(csActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will delete existing COMPCAL
 */
export function* validateDeleteCS(action) {
  let resetDeleteValue = null;
  resetDeleteValue = {
    deleteRequest: null,
    deleteRequestError: null,
    deleteRequestedOn: null,
    deleteRequestProcessedOn: new Date(),
    deleteData: null,
  };
  const resetUpsertValue = {
    upsertRequest: null,
    serverRequestError: null,
    upsertRequestedOn: null,
    upsertRequestProcessedOn: null,
    upsertData: null,
  };
  yield all([
    put(processingStarted()),
    put(getAction(csActionTypes.VALIDATE_DELETE_CS_RESPONSE, null)),
    put(getAction(csActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
    put(getAction(csActionTypes.RESET_SERVER_VALUE, resetUpsertValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.COMPSTRUCTURE_VALIDATE_DELETE,
      action.data);
    yield all([
      put(getAction(csActionTypes.VALIDATE_DELETE_CS_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(csActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* deleteDefaultCs(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.DEFAULT_COMPSTRUCTURE_DELETE,
      action.data.formatObject);
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(defaultCsActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(defaultCsActionTypes.GET_DEFAULT_COMPCAL_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(defaultCsActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
 * This generator function will invoke the api
 * @param {object} action - action which will request for COMPCAL Export Listing
 */
export function* getCompcalExportList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi, moduleAPI.COMPSTRUCTURE_EXPORT_LIST, action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getCompcalRateTableExportList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.COMPSTRUCTURE_RT_EXPORT_LIST,
      action.data);
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response?.status === globalConstants.errorCode402
      || err.response?.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getCompStructureTree(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.COMP_HIER_TREE,
      action.data);
    yield all([
      put(getAction(csActionTypes.GET_CS_TREE_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getCompRateTable(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.COMP_RATE_TABLE,
      action.data);
    yield all([
      put(getAction(csActionTypes.GET_RATE_TABLE_RES, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(csActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getDefaultExportList(action) {
  let resetServerValue = { serverRequestError: null };
  yield all([
    put(processingStarted()),
    put(getAction(defaultCsActionTypes.RESET_SERVER_VALUE, resetServerValue)),
  ]);
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.DEFAULT_EXPORT_LIST,
      action.data);
    const filename = 'FASATExport_Default_compensation_structure.xlsx';
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(defaultCsActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getAgentName(action) {
  yield all([
    put(processingStarted()),
  ]);
  const resposeAction = action.data.field === 'advisor' ? csDetailActionTypes.GET_CS_AGENT_NAME_RESPONSE
    : csDetailActionTypes.GET_NEXT_CS_AGENT_NAME_RESPONSE;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.ADMINISTRATION_COMPSTRUCTRE_AGENT_NAME, {}, { agentCode: action.data.agentCode });
    yield all([
      put(getAction(resposeAction, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(resposeAction, '')),
      put(processingCompleted())]);
  }
}

// default copensation structure
export function* insertDefaultCompStructure(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.DEFAULT_COMPSTRUCTURE_INSERT,
      action.data.formatObject);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(defaultCsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      // put(getAction(csActionTypes.GET_BCR_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(defaultCsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted())]);
  }
}
// UPDATE DEFAULT COMPENSATION STRUCTURE

export function* updateDefaultCompStructure(action) {
  yield all([
    put(processingStarted()),
  ]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(invokeApi,
      moduleAPI.DEFAULT_COMPSTRUCTRE_UPDATE,
      action.data.formatObject);
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(defaultCsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      // put(getAction(csActionTypes.GET_BCR_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(defaultCsActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

// LRO
/**
  * Get LRO List
  * @param {any} action
  */
export function* getLROList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(lroEligibilityActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(lroEligibilityActionTypes.LOG_LRO_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_LRO_LIST, action.data);
    yield all([
      put(getAction(lroEligibilityActionTypes.GET_LRO_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(lroEligibilityActionTypes.GET_LRO_LIST_ERROR, err)),
      put(getAction(lroEligibilityActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * Get Income Expense by id
  * @param {object} action
  */
export function* getLRObyID(action) {
  yield all([
    put(processingStarted()),
    put(getAction(lroEligibilityActionTypes.RESET_SERVER_VALUE, null)),
    put(getAction(lroEligibilityActionTypes.LOG_LRO_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_LRO_BY_ID, action.data);
    yield all([
      put(getAction(lroEligibilityActionTypes.GET_LRO_BY_ID_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(lroEligibilityActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(lroEligibilityActionTypes.GET_LRO_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will insert for LRO
  */
export function* insertLRO(action) {
  Logger.verbose(`Action Detail For Get Income Expese Map : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_LRO_INSERT,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(lroEligibilityActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(lroEligibilityActionTypes.GET_LRO_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(lroEligibilityActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
* This generator function will invoke the api
* @param {object} action - action which will update existing LRO
*/
export function* updateLRO(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_LRO_UPDATE,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([put(getAction(lroEligibilityActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(lroEligibilityActionTypes.GET_LRO_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(lroEligibilityActionTypes.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
 * This generator function will invoke the api
* @param {object} action - action which will delete existing LRO
*/
export function* deleteLRO(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_LRO_DELETE,
      action.data.formatObject,
    );
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(lroEligibilityActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(lroEligibilityActionTypes.GET_LRO_LIST_REQUEST, action.data.reqObject)),
      put(getAction(lroEligibilityActionTypes.RESET_UPSERT_ERROR, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(lroEligibilityActionTypes.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(lroEligibilityActionTypes.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}
/**
 * * This generator function will invoke the api
* @param {object} action - action which will request for SP Export Listing
*/
export function* getLROExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(lroEligibilityActionTypes.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_LRO_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const errorObject = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: errorObject };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(lroEligibilityActionTypes.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
export function* getLookUpsDataLRO(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_LRO_LOOKUP_REQUEST, action.data);
    yield all([
      put(getAction(lroEligibilityActionTypes.RESET_LRO_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(lroEligibilityActionTypes.RESET_LRO_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}
export function* getCSHeader(action) {
  yield all([

    put(processingStarted()),

    put(getAction(csDetailActionTypes.LOG_CS_HEADER_REQUEST)),

  ]);

  try {
    const responseData = yield call(invokeApi,

      moduleAPI.CS_COMMON_HEADER,

      action.data);

    yield all([

      put(getAction(csDetailActionTypes.GET_CS_HEADER_DETAIL_RESPONSE, responseData)),

      put(processingCompleted()),

    ]);
  } catch (err) {
    yield all([put(getAction(csActionTypes.GET_CS_HEADER_DETAIL_ERROR, processError(err))),
      put(processingCompleted())]);
  }
}

function* moduleWatcher() {
  yield takeEvery(csActionTypes.GET_COMPCAL_LIST_REQUEST, getCompStructureList);
  yield takeEvery(csActionTypes.INSERT_COMPSTRUCTURE_REQUEST, insertCompStructure);
  yield takeEvery(csActionTypes.GET_AGENT_NAME_BY_AGENT_CODE, getAgentName);
  yield takeEvery(csActionTypes.UPDATE_COMPSTRUCTURE_REQUEST, updateCompStructure);
  yield takeEvery(csActionTypes.DELETE_CS_REQUEST, deleteCs);
  yield takeEvery(csActionTypes.VALIDATE_DELETE_CS_REQUEST, validateDeleteCS);
  yield takeEvery(csActionTypes.GET_CS_TREE, getCompStructureTree);
  yield takeEvery(csActionTypes.GET_RATE_TABLE_REQ, getCompRateTable);

  // COMPCAL detail
  // COMPCAL export
  yield takeEvery(csActionTypes.GET_CS_EXPORT_REQUEST, getCompcalExportList);
  yield takeEvery(csActionTypes.GET_CSRT_EXPORT_REQUEST, getCompcalRateTableExportList);

  // COMPCAL itembyid
  yield takeEvery(csActionTypes.GET_CS_BY_ID_REQUEST, getCsbyID);
  yield takeEvery(defaultCsActionTypes.GET_DEFAULT_COMPCAL_LIST_REQUEST, getDefaultCompStructureList);
  yield takeEvery(defaultCsActionTypes.INSERT_DEFAULT_COMPSTRUCTURE_REQUEST, insertDefaultCompStructure);
  yield takeEvery(defaultCsActionTypes.GET_DEFAULT_CS_BY_ID_REQUEST, getDefaultCsbyID);
  yield takeEvery(defaultCsActionTypes.UPDATE_DEFAULT_COMPSTRUCTURE_REQUEST, updateDefaultCompStructure);
  yield takeEvery(defaultCsActionTypes.DELETE_DEFAULT_CS_REQUEST, deleteDefaultCs);
  // LRO
  yield takeEvery(lroEligibilityActionTypes.GET_LRO_LIST_REQUEST, getLROList);
  yield takeEvery(lroEligibilityActionTypes.GET_LRO_BY_ID_REQUEST, getLRObyID);
  yield takeEvery(lroEligibilityActionTypes.INSERT_LRO_REQUEST, insertLRO);
  yield takeEvery(lroEligibilityActionTypes.UPDATE_LRO_REQUEST, updateLRO);
  yield takeEvery(lroEligibilityActionTypes.DELETE_LRO_REQUEST, deleteLRO);
  yield takeEvery(lroEligibilityActionTypes.GET_LRO_EXPORT_REQUEST, getLROExportList);
  yield takeEvery(lroEligibilityActionTypes.GET_LRO_LOOKUP_REQUEST, getLookUpsDataLRO);
  yield takeEvery(defaultCsActionTypes.GET_DEFAULT_CS_EXPORT_REQUEST, getDefaultExportList);
  // CS Common header
  yield takeEvery(csDetailActionTypes.GET_CS_HEADER_REQUEST, getCSHeader);
}

export default moduleWatcher;
