import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { reqInquiryActionTypes } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from '../../../report/redux/reducers/utilities/utility';
import { commonTypes } from '../../../../common/redux/actions/commonActions';

export const REDUCER_NAME = 'ReqInquiryFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const reqInqFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case reqInquiryActionTypes.UPDATE_REQINQ_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case reqInquiryActionTypes.REMOVE_REQINQ_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case reqInquiryActionTypes.UPDATE_REQINQ_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const reqInqFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case reqInquiryActionTypes.LOG_REQINQ_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case reqInquiryActionTypes.GET_REQINQ_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case reqInquiryActionTypes.GET_REQINQ_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // case prodSummaryActionTypes.GET_GET_BY_ID_RESPONSE:
    //   return getEditItemDetailsResponse(state, action);
    case reqInquiryActionTypes.RESET_REQINQ_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case reqInquiryActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case reqInquiryActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const ReqInquiryFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = reqInqFilterListConfig(state, action);
  const stateReqRes = reqInqFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ReqInquiryFilterList;
