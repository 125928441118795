/**
 * @Description Fasat Grid Style Component
 * @FileName styleComponents.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 03 February, 2021 08:18:24
 * @IssueID -323
 */

import { colors, typography } from '@manulife/mux';
import styled from 'styled-components';
import checkmark from '../../../assets/images/checkmark.png';

export const Wrapper = styled.div`
  width: 90%;
  // margin: 0 auto;
  overflow-x: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #DFE0E2;
  }
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background-color: #DFE0E2;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #8e90a2;
  }
`;

export const TableUtility = styled.div`
  font-family: ${typography.fontFamily};
  border-bottom: 4px solid ${colors.light_grey};
  display: flex;
  justify-content: space-between;
`;

export const Table = styled.table`
  font-family: ${typography.fontFamily};
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #34384b;
  border-top: solid 4px ${colors.light_grey};
  border-collapse: collapse;

  caption {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  } 

  tr {
    border-bottom: 2px solid ${colors.light_grey}; 
    &:last-child .actions-col {
        width: 64px !important;
        max-width: 8% !important;
    }  
    th {
      font-weight: 600;
      vertical-align: bottom;
      padding: 8px;
      white-space: pre;
      .columnHeaderText {
        word-break: break-word;
        margin-right: 4px;
      }
      .sortIcons {
        width: 24px;
        height: 24px;
        vertical-align: bottom;
      }
      .headerUnbold {
        font-weight: 300;
      }
      &:last-child {
        min-width: auto !important;
        position: absolute !important;
        padding-left: 3.2%;
        padding-right: 1.4%;
        right: 26px;
        background-color: white;
        border-bottom: 2px solid #EDEDED;
        border-top: 4px solid #EDEDED;
        margin-top: -4px;
        box-shadow: -8px 0 10px 0 rgb(0 0 0 / 5%);
      } 
      &:first-child {
        padding-left: 16px;
      }
    }
    td {
      padding: 8px;
      vertical-align: text-top;
      font-weight: 400;
      white-space: pre;
      .editDeleteAction {
        display: flex;
        justify-content: flex-start;
      }
      .textBold {
        font-weight: 600;
      }
      &:last-child {    
        // width: 8% !important;
        width: fit-content !important;
        max-width: 8% !important;
        min-width: 64px !important;
        height: 25px;
        padding-left: 3.2%;
        padding-right: 1.4%;
        position: absolute;
        right: 26px;
        background-color: white;
        border-bottom: 2px solid #EDEDED;
        box-shadow: -8px 0 10px 0 rgb(0 0 0 / 5%);
        @-moz-document url-prefix() {
          margin-top: -30px !important;
        }
        button:first-child {
          margin-right: 8px;
        }
      } 
      &:first-child {
        padding-left: 16px;
      }
      &.amountCell {
        text-align: right;
      }
    }
    th, td {
      .gridCheckbox {
        background: rgb(255, 255, 255);
        border-style: solid;
        border-width: 1px;
        border-color: rgb(94, 96, 115);
        width: 28px;
        height: 28px;
        vertical-align: bottom;
        margin: 0px;
        cursor: pointer;
        border-radius: 0;
        appearance: none;
        &:hover, &:focus {
          border-width: 2px;
        }
        &:disabled {
          border-color: rgb(194, 195, 201);
          cursor: not-allowed !important;
          &:hover, &:focus {
            border-width: 1px;
            cursor: not-allowed !important;
          }
        }
        &:checked {
          border: 2px solid rgb(0, 167, 88);
          background: no-repeat ${colors.white} url(${checkmark}) 5px 7px;
        }
    }
  }  
`;

export const NoData = styled.div`
  padding: 0;
  font-size: 16px;
  color: ${colors.light_1_dark_navy}
`;
