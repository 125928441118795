/**
 * @Description This is fasatQuickLinks container page
 * @FileName fasatQuickLinks.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 27 January, 2021 11:15:07
 * @IssueID CDTFASATMOD-502
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../../../index.scss';
import './fasatQuickLinks.scss';
import { H2, colors } from '@manulife/mux';
import { Edit, Reload } from '@manulife/mux-cds-icons';
import FasatRouterLink from '../fasatRouterLink/fasatRouterLink';
import FasatActionBtn from '../fasatActionBtn/fasatActionBtn';
import { staticCommonLabelKeys } from '../../moduleConstants';

export const setQuickLinks = (quickLinks) => quickLinks.sort((a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});
export const userLinkArray = ({ usersQuicksLinks, quickLinks, avlLinks, setOrderLinks }) => {
  if (usersQuicksLinks?.length > 0 && quickLinks.length > 0) {
    const sortedLinks = [];
    usersQuicksLinks?.forEach((item) => {
      const link = avlLinks.find(({ value }) => value === item);
      if (link) {
        const sortLink = quickLinks.find(({ id }) => link.menuId === id);
        if (sortLink) {
          sortedLinks.push(sortLink);
          setOrderLinks(sortedLinks);
        }
      }
    });
  }
  if (usersQuicksLinks?.length === 0 && quickLinks?.length > 0) {
    setQuickLinks(quickLinks);
  }
};
export const linksItems = (item) => (
  <div className="quickLinkCol">
    <FasatRouterLink to={item.href}>{item.name}</FasatRouterLink>
  </div>
);
const FasatQuickLinks = ({ quickLinks, noLinkMsg, quickLinkLabel, personalizeClick, resetLinks }) => {
  const [orderLinks, setOrderLinks] = useState([]);
  const usersQuicksLinks = useSelector(({ ApplicationState }) => ApplicationState.usersQuicksLinks);
  const avlLinks = useSelector(({ ApplicationState }) => ApplicationState.allQuicksLinks);
  useEffect(() => {
    userLinkArray({ usersQuicksLinks, quickLinks, avlLinks, setOrderLinks });
  }, [usersQuicksLinks, quickLinks, avlLinks]);
  const [t] = useTranslation();
  return (
    <div className="fasatQuickLinks f-align-left" data-testid="FasatQuickLinksComponent" id="fasatQuickLinks">
      <div className="quickLinksHeader">
        <div className="headerLabel">
          <H2>{quickLinkLabel}</H2>
        </div>
        <div className="actionsArea">
          <FasatActionBtn
            disabled={usersQuicksLinks && usersQuicksLinks?.length === 0}
            hoverIcon={(
              <Reload color={colors.dark_2_green} style={{ width: '20px', height: '20px', marginTop: '5px' }} />
          )}
            icon={(<Reload style={{ width: '20px', height: '20px', marginTop: '5px' }} />)}
            onClick={() => resetLinks()}
          >
            {t(staticCommonLabelKeys.COMMON_BUTTON_RESET)}
          </FasatActionBtn>
          <FasatActionBtn
            hoverIcon={(
              <Edit color={colors.dark_2_green} style={{ width: '20px', height: '20px', marginTop: '5px' }} />
          )}
            icon={(<Edit color={colors.m_green} style={{ width: '20px', height: '20px', marginTop: '5px' }} />)}
            onClick={() => personalizeClick()}
          >
            {t(staticCommonLabelKeys.COMMON_LABEL_PERSONALIZE)}
            {' '}
          </FasatActionBtn>
        </div>
      </div>
      <div className="quickLinksSection">
        <div className="quickLinkRow">
          { (usersQuicksLinks?.length === 0 && quickLinks) && quickLinks.map((item) => (linksItems(item))) }
          { usersQuicksLinks?.length > 0 && orderLinks.map((item) => (linksItems(item))) }
          {!quickLinks && (
          <div className="noLinkSection">
            <div>{`${(noLinkMsg.split('.'))[0]}.`}</div>
            <div>{`${(noLinkMsg.split('.'))[1]}.`}</div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

FasatQuickLinks.propTypes = {
  quickLinks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    href: PropTypes.string,
  })),
  noLinkMsg: PropTypes.string,
  quickLinkLabel: PropTypes.string,
  personalizeClick: PropTypes.func,
  resetLinks: PropTypes.func,
};

FasatQuickLinks.defaultProps = {
  quickLinks: null,
  noLinkMsg: '',
  quickLinkLabel: '',
  personalizeClick: () => undefined,
  resetLinks: () => undefined,
};

export default FasatQuickLinks;
