import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { balSummaryActionTypes } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'BalSummaryFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const balSumFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case balSummaryActionTypes.UPDATE_BALSUM_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case balSummaryActionTypes.REMOVE_BALSUM_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case balSummaryActionTypes.UPDATE_BALSUM_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const balSumFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case balSummaryActionTypes.LOG_BALSUM_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case balSummaryActionTypes.GET_BALSUM_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case balSummaryActionTypes.GET_BALSUM_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // case balSummaryActionTypes.GET_GET_BY_ID_RESPONSE:
    //   return getEditItemDetailsResponse(state, action);
    case balSummaryActionTypes.RESET_BALSUM_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case balSummaryActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case balSummaryActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const BalSummaryFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = balSumFilterListConfig(state, action);
  const stateReqRes = balSumFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default BalSummaryFilterList;
