/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-debugger */
/**
 * @Description Admin module saga/watcher file.
 * @FileName moduleWatcher.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 22 January, 2021 08:51:22
 * @IssueID - 308
 */
import { takeEvery, put, call, all } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { chequeSecurityActionType } from '../actions/actionTypes';

import { formatResponse, exportErrorMsg, Logger, processError, renderFileName } from '../../../../common/util';
import invokeApi from '../../../../common/apiManagement/invokeApi';
import { getAction } from '../../../../../stateManagement/reduxUtil';
import {
  processingStarted, processingCompleted,
} from '../../../../common/redux/actions/commonActions';
import moduleAPI from '../../config/apiConfig';
import { globalConstants } from '../../../../common/moduleConstants';

// Cheque Security

/**
   * Get Cheque Security List
   * @param {any} action
   */
export function* getCHSList(action) {
  Logger.verbose(`Action Detail For Get Cheque Security List : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(chequeSecurityActionType.RESET_SERVER_VALUE, null)),
    put(getAction(chequeSecurityActionType.LOG_CHS_LIST_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_CHS_LIST, action.data);
    yield all([
      put(getAction(chequeSecurityActionType.GET_CHS_LIST_RESPONSE, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(chequeSecurityActionType.GET_CHS_LIST_ERROR, err)),
      put(getAction(chequeSecurityActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * Get Cheque Security by id
   * @param {object} action
   */
export function* getCHSbyID(action) {
  Logger.verbose(`Action Detail For Get CHS by id : ${JSON.stringify(action)}`);
  yield all([
    put(processingStarted()),
    put(getAction(chequeSecurityActionType.RESET_SERVER_VALUE, null)),
    put(getAction(chequeSecurityActionType.LOG_CHS_BY_ID_REQUEST, action.data)),
  ]);
  let resetServerValue = { serverRequestError: null };
  try {
    const responseData = yield call(invokeApi, moduleAPI.ADMINISTRATION_CHS_BY_ID, action.data);
    yield all([
      put(getAction(chequeSecurityActionType.GET_CHS_BY_ID_RESPONSE, formatResponse({ action, responseData }))),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetServerValue = { serverRequestError: processError(err) };
    yield all([
      put(getAction(chequeSecurityActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(getAction(chequeSecurityActionType.GET_CHS_BY_ID_ERROR, err)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will insert for Cheque Security
   */
export function* insertCHS(action) {
  Logger.verbose(`Action Detail For Get Cheque Security : ${JSON.stringify(action)}`);
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_CHS_INSERT,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(chequeSecurityActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(chequeSecurityActionType.GET_CHS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
      upsertData: null,
    };
    yield all([
      put(getAction(chequeSecurityActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will update existing Cheque Security
   */
export function* updateCHS(action) {
  yield all([put(processingStarted())]);
  let resetUpsertValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_CHS_UPDATE,
      action.data.formatObject,
    );
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: null,
      upsertRequestedOn: null,
      upsertRequestProcessedOn: new Date(),
      upsertData: responseData,
    };
    yield all([
      put(getAction(chequeSecurityActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(getAction(chequeSecurityActionType.GET_CHS_LIST_REQUEST, action.data.reqObject)),
      put(processingCompleted())]);
  } catch (err) {
    resetUpsertValue = {
      upsertRequest: null,
      upsertRequestError: processError(err),
      upsertRequestedOn: null,
      upsertRequestProcessedOn: null,
    };
    yield all([
      put(getAction(chequeSecurityActionType.RESET_UPSERT_VALUE, resetUpsertValue)),
      put(processingCompleted()),
    ]);
  }
}

/**
   * This generator function will invoke the api
   * @param {object} action - action which will delete existing Cheque Security
   */
export function* deleteCHS(action) {
  yield all([put(processingStarted())]);
  let resetDeleteValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_CHS_DELETE,
      action.data.formatObject,
    );
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: null,
      deleteRequestedOn: null,
      deleteRequestProcessedOn: new Date(),
      deleteData: responseData,
    };
    yield all([
      put(getAction(chequeSecurityActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(chequeSecurityActionType.GET_CHS_LIST_REQUEST, action.data.reqObject)),
      put(getAction(chequeSecurityActionType.RESET_UPSERT_ERROR, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    resetDeleteValue = {
      deleteRequest: null,
      deleteRequestError: processError(err),
      upsertRequestError: processError(err),
      deleteRequestedOn: new Date(),
      deleteRequestProcessedOn: null,
      deleteData: null,
    };
    yield all([
      put(getAction(chequeSecurityActionType.RESET_DELETE_VALUE, resetDeleteValue)),
      put(getAction(chequeSecurityActionType.RESET_UPSERT_VALUE, resetDeleteValue)),
      put(processingCompleted()),
    ]);
  }
}

export function* getLookUpsDataCHS(action) {
  yield put(processingStarted());
  try {
    const responseData = yield call(invokeApi, moduleAPI.GET_CHS_LOOKUP_REQUEST, action.data);
    yield all([
      put(getAction(chequeSecurityActionType.RESET_CHS_LOOKUPDATA, responseData)),
      put(processingCompleted()),
    ]);
  } catch (err) {
    // put request failed action
    yield all([
      put(getAction(chequeSecurityActionType.RESET_CHS_LOOKUPDATA, null)),
      put(processingCompleted()),
    ]);
  }
}

/**
  * This generator function will invoke the api
  * @param {object} action - action which will request for Cheque Security Export Listing
  */
export function* getCHSExportList(action) {
  yield all([
    put(processingStarted()),
    put(getAction(chequeSecurityActionType.RESET_SERVER_VALUE, null)),
  ]);
  let resetServerValue = null;
  try {
    const responseData = yield call(
      invokeApi,
      moduleAPI.ADMINISTRATION_CHS_EXPORT_LIST,
      action.data,
    );
    const filename = renderFileName(responseData);
    const urlData = window.URL.createObjectURL(new Blob([responseData.data]));
    FileSaver(urlData, filename);
    yield put(processingCompleted());
  } catch (err) {
    if (err.response.status === globalConstants.errorCode402 || err.response.status === globalConstants.errorCode403) {
      const exportMaxLimitErrorObj = exportErrorMsg(err.response.status);
      resetServerValue = { serverRequestError: exportMaxLimitErrorObj };
    } else {
      resetServerValue = { serverRequestError: processError(err) };
    }
    yield all([
      put(getAction(chequeSecurityActionType.RESET_SERVER_VALUE, resetServerValue)),
      put(processingCompleted()),
    ]);
  }
}
function* moduleWatcher() {
  // Check Security
  yield takeEvery(chequeSecurityActionType.GET_CHS_LIST_REQUEST, getCHSList);
  yield takeEvery(chequeSecurityActionType.GET_CHS_BY_ID_REQUEST, getCHSbyID);
  yield takeEvery(chequeSecurityActionType.INSERT_CHS_REQUEST, insertCHS);
  yield takeEvery(chequeSecurityActionType.UPDATE_CHS_REQUEST, updateCHS);
  yield takeEvery(chequeSecurityActionType.DELETE_CHS_REQUEST, deleteCHS);
  yield takeEvery(chequeSecurityActionType.GET_CHS_EXPORT_REQUEST, getCHSExportList);
  yield takeEvery(chequeSecurityActionType.GET_CHS_LOOKUP_REQUEST, getLookUpsDataCHS);
}
export default moduleWatcher;
