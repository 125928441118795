/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/**
 * @Description This is the wrapper for ToastConatiner from react-toastify
 * @FileName fasatToastContainer.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 17 February, 2021 07:10:47
 * @IssueID - NA
 */

import PropTypes from 'prop-types';
import { Slide, ToastContainer } from 'react-toastify';

const FasatToastContainer = (props) => {
  const { transition, ...others } = props;
  const fasatTransition = transition || Slide;
  return <ToastContainer enableMultiContainer transition={fasatTransition} {...others} />;
};
FasatToastContainer.propTypes = {
  transition: PropTypes.func,
};

FasatToastContainer.defaultProps = {
  transition: Slide,
};

export default FasatToastContainer;
