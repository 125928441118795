/**
 * @Description Fasat Sort Order Page
 * @FileName sortOrder.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 14 May, 2021 21:40:00
 * @IssueID - 436
 */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SortOrderRow from './sortOrderRow';
import './sortOrder.scss';
import { staticCommonLabelKeys } from '../../../moduleConstants';

// #region constants
// #endregion
// #region styled-components
// #endregion
// #region functions
// #endregion
// #region component

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()),
  removeSortedColumn: PropTypes.func,
  sortedColumn: PropTypes.func,
  showDuplicateMessage: PropTypes.func,
  customColumnOrder: PropTypes.arrayOf(PropTypes.shape()),
  customColumnGroupBy: PropTypes.arrayOf(PropTypes.shape()),
  groupByActive: PropTypes.bool,
};

const defaultProps = {
  columns: [],
  removeSortedColumn: () => undefined,
  customColumnOrder: [],
  sortedColumn: () => undefined,
  showDuplicateMessage: () => undefined,
  customColumnGroupBy: [],
  groupByActive: false,
};

const SortOrder = ({
  columns,
  sortedColumn,
  removeSortedColumn,
  customColumnOrder,
  showDuplicateMessage,
  customColumnGroupBy,
  groupByActive,
}) => {
  const [t] = useTranslation();
  const [totalRows, setTotalRows] = useState([{ uuid: uuidv4() }]);
  const [columnOptions, setColumnOptions] = useState([{ uuid: uuidv4() }]);
  const KeyValue = null;

  /**
   * Initiate total row as per selected column order
   */
  useEffect(() => {
    if (customColumnOrder && customColumnOrder.length > 0) {
      setTotalRows(customColumnOrder);
    } else {
      setTotalRows([{ uuid: uuidv4(), key: KeyValue, asc: null }]);
    }
  }, [customColumnOrder]);
  useEffect(() => {
    if (customColumnGroupBy && customColumnGroupBy.length > 0) {
      setColumnOptions(columns.filter((col) => customColumnGroupBy.some((grp) => col.value === grp.key)));
    } else {
      setColumnOptions(groupByActive ? [] : columns);
    }
  }, [customColumnGroupBy, groupByActive, columns]);

  /**
   * Adding a row
   */
  const addRow = () => {
    setTotalRows((arr) => [...arr, { uuid: uuidv4(), key: KeyValue, asc: null }]);
  };

  /**
   * Removing a row
   * @param {any} val
   */
  const removeRow = (val) => {
    const filteredCol = totalRows.filter(({ uuid }) => uuid !== val);
    setTotalRows(filteredCol);
  };

  /**
   * Class names
   */
  const soFilterTableClass = 'so-filterTable';
  const soFilterRowClass = 'so-filterRow';
  const soFilterCellClass = 'so-filterCell';
  const soSortOrderCellClass = 'so-sortOrderCell';
  const soActionCellClass = 'so-actionCell';
  const soBlankCellClass = 'so-blankCell';

  return (
    <div className={soFilterTableClass}>
      <div className={`${soFilterRowClass} deskDisp filterR`}>
        <div className={soFilterCellClass}>{t(staticCommonLabelKeys.FILTER_LABEL_COLUMN)}</div>
        <div className={`${soFilterCellClass} ${soSortOrderCellClass}`}>
          {t(staticCommonLabelKeys.FILTER_LABEL_SORT_ORDER)}
        </div>
        <div className={soActionCellClass} />
        <div className={soBlankCellClass} />
      </div>
      {totalRows.map(({ uuid, key, asc }, idx) => (
        <SortOrderRow
          key={uuid}
          item={key}
          asc={asc}
          addRow={addRow}
          removeRow={removeRow}
          configColumn={sortedColumn}
          uuid={uuid}
          index={idx}
          totalRows={totalRows}
          columnConfig={columnOptions}
          removeSortedColumn={removeSortedColumn}
          customColumnOrder={customColumnOrder}
          showDuplicateMessage={showDuplicateMessage}
          customColumnGroupBy={customColumnGroupBy}
        />
      ))}
    </div>
  );
};

SortOrder.propTypes = propTypes;
SortOrder.defaultProps = defaultProps;
// #endregion

export default SortOrder;
