/**
 * @Description FasatColumnOrder Capital First Letter File
 * @FileName capitalizeFirstLetter.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
/**
 * Capitalize the first letter of a given string.
 *
 * @param {string} str
 *
 * @returns {string}
 */
const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export default capitalizeFirstLetter;
