/* eslint-disable react/forbid-prop-types */
/**
 * @Description For protected routers
 * @FileName protectedRoute.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 25 January, 2021 12:17:55
 * @IssueID - 311
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import FasatErrorBoundary from '../fasatErrorBoundary/fasatErrorBoundary';
import { isHasUserClaim } from '../../util';

/**
 * This route would be protected and would only be available for the
 * authenticated and authorized user
 * @param {object}  - component and other Route properties
 */
export const FasatProtectedRoute = ({ component: Component,
  aclKey: AclKey, userClaim, ...rest }) => (
    <Route
      {...rest}
      render={() => {
      // check if there is any access token
      // dispatch token validation action
      // eslint-disable-next-line no-debugger
        if (isHasUserClaim(userClaim, AclKey)) {
          return (
            <FasatErrorBoundary fallback={<div> Error From route</div>}>
              <Component />
            </FasatErrorBoundary>
          );
        }
        return <Redirect to={{ pathname: '/' }} />;
      }}
    />
);

FasatProtectedRoute.propTypes = {
  component: PropTypes.object.isRequired,
  aclKey: PropTypes.object.isRequired,
  userClaim: PropTypes.object.isRequired,
};

/**
 * This route would be accessible to all. e.g. application home page
 * authenticated and authorized user
 * @param {object}  - component and other Route properties
 */
export const FasatPublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={() => (
      <FasatErrorBoundary fallback={<div> Error From route</div>}>
        <Component />
      </FasatErrorBoundary>
    )}
  />
);

FasatPublicRoute.propTypes = {
  component: PropTypes.object.isRequired,
};
