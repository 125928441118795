import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { rateDetailsActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'RateDetailsList';

const updateFilterConfig = (state, action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        ...state,
        selectedFilter,
        systemDefaultFilter: action.data.systemDefault || null,
        userDefaultFilter: action.data.userDefault || null,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        ...state,
        selectedFilter,
        userDefaultFilter: action.data.userDefault,
      };
    }
  }
  return {
    ...state,
    selectedFilter,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  selectedFilter: action.data ? action.data : null,
});

const logListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const rateDetailsList1 = (state, action = null) => {
  switch (action.type) {
    case rateDetailsActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case rateDetailsActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case rateDetailsActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case rateDetailsActionTypes.LOG_LIST_REQUEST:
      return logListRequest(state, action);
    case rateDetailsActionTypes.GET_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case rateDetailsActionTypes.GET_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case rateDetailsActionTypes.GET_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };

    default:
      return null;
  }
};

const rateDetailsList2 = (state, action = null) => {
  switch (action.type) {
    case rateDetailsActionTypes.RESET_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        getEditItemDetails: null,
        loading: false,
      };
    case rateDetailsActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case rateDetailsActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case rateDetailsActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case rateDetailsActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case rateDetailsActionTypes.GET_RATEDETAILS_ONLOAD_RESPONSE:
      return {
        ...state,
        onloadData: action.data.onloadData,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const rateDetailsList = (state = { ...basicFilterInitialState }, action = null) => {
  const rateMaster1 = rateDetailsList1(state, action);
  if (rateMaster1 !== null) {
    return rateMaster1;
  }
  const rateMaster2 = rateDetailsList2(state, action);
  if (rateMaster2 !== null) {
    return rateMaster2;
  }
  return state;
};

export default rateDetailsList;
