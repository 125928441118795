/* eslint-disable import/named */
/**
 * @Description Reducer for business card rules
 * @FileName clientRelationship.js
 * @Author Prasenjit Chakraborty - chakpra
 * @CreatedOn 13 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { relationshipActionsTypes } from '../actions/actionTypes';

const ClientRelationshipRule = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case relationshipActionsTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case relationshipActionsTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case relationshipActionsTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default ClientRelationshipRule;
