/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/**
 * @Description This is FasatMessageCard component page.
 * This is a wrapper on "MessageCard" component from MUX without adding any custom functionlities
 * @FileName fasatMessageCard.js
 * @FileName fasatMessageCard.stories.js
 * @Author GOUTAM PAL - pgoutam
 * @CreatedOn 06-October-2021
 * @IssueID CDTFASATMOD-324
 */
import React from 'react';
import { MessageCard, MESSAGE_VARIANT } from '@manulife/mux';
import './fasatMessageCard.scss';

const FasatMessageCard = (props) => {
  const { variant, ...rest } = props;
  return (
    <div className="fasatMessageCard">
      <MessageCard
        variant={variant === 'primary_alert' ? MESSAGE_VARIANT.PRIMARY_ALERT : variant}
        role="alert"
        aria-atomic="true"
        {...rest}
      >
        {props.children}
      </MessageCard>
    </div>
  );
};

export default FasatMessageCard;
