/**
 * @Description File description.
 * @FileName french.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 19 February, 2021 00:36:44
 * @IssueID - Issue IDs
*/

const agencyResourceFrench = {
  'agency.bcr.menu': 'F: Business card rules',
  'administration.menu': 'F : Admin',
};

export default agencyResourceFrench;
