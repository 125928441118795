import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { prodSummaryActionTypes } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'ProdSummaryFilterList';

const logErrListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});
const prodSumFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case prodSummaryActionTypes.UPDATE_PRODSUM_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case prodSummaryActionTypes.REMOVE_PRODSUM_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case prodSummaryActionTypes.UPDATE_PRODSUM_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    default:
      return null;
  }
};

const prodSumFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case prodSummaryActionTypes.LOG_PRODSUM_LIST_REQUEST:
      return logErrListReq(state, action);
    // log error
    case prodSummaryActionTypes.GET_PRODSUM_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case prodSummaryActionTypes.GET_PRODSUM_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // case prodSummaryActionTypes.GET_GET_BY_ID_RESPONSE:
    //   return getEditItemDetailsResponse(state, action);
    case prodSummaryActionTypes.RESET_PRODSUM_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case prodSummaryActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case prodSummaryActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const ProdSummaryFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = prodSumFilterListConfig(state, action);
  const stateReqRes = prodSumFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ProdSummaryFilterList;
