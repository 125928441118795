/**
 * @Description FasatTooltip component page
 * @FileName fasatTooltip.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 07 July, 2021 09:17:25
 * @IssueID Issue Detail
 */

import React from 'react';
import { Tooltip } from '@manulife/mux';
import './fasatTooltip.scss';

const FasatTooltip = (props) => <Tooltip {...props} />;

export default FasatTooltip;
