/* eslint-disable no-debugger */
/**
 * @Description Reducer for template adjustments
 * @FileName templateAdjustments.js
 * @Author Imroz Dawar - dawarim
 * @CreatedOn 25 February, 2022 04:15:36
 * @IssueID - Issue IDs
 */

import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { tempAdjActionTypes } from '../actions/actionTypes';

const initialStateExt = {
  approveRequest: null,
  approveRequestError: null,
  approveRequestedOn: null,
  approveRequestProcessedOn: null,
  approveData: null,
  detailLastImpData: null,
};

const TemplateAdjustments = (state = { ...basicListAddEditDltInitialState, ...initialStateExt }, action = null) => {
  switch (action.type) {
    case tempAdjActionTypes.IMPORT_TEMPADJ_RESPONSE:
      return {
        ...state,
        detailData: action.data.detailData,
      };
    case tempAdjActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case tempAdjActionTypes.SET_TEMPADJ_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case tempAdjActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case tempAdjActionTypes.RESET_DELETE_TEMPADJ_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case tempAdjActionTypes.RESET_APPROVE_TEMPADJ_VALUE:
      return {
        ...state,
        approveRequest: action.data.approveRequest,
        approveRequestError: action.data.approveRequestError,
        approveRequestedOn: action.data.approveRequestedOn,
        approveRequestProcessedOn: action.data.approveRequestProcessedOn,
        approveData: action.data.approveData,
      };
    case tempAdjActionTypes.RESET_IMPORT_TEMPADJ:
      return {
        ...state,
        detailData: action.data.detailData,
        detailRequest: action.data.detailRequest,
        detailRequestError: action.data.detailRequestError,
        detailRequestedOn: action.data.detailRequestedOn,
        detailRequestProcessedOn: action.data.detailRequestProcessedOn,
        detailLastImpData: action.data.detailLastImpData,
      };
    case tempAdjActionTypes.UPSERT_TEMPADJ_REQUEST:
      return {
        ...state,
        upsertRequestedOn: new Date(),
        upsertData: action.data.upsertData,
        loading: true,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default TemplateAdjustments;
