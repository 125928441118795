import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { dailyActivityFCRActionType } from '../actions/actionTypes';

export const REDUCER_NAME = 'DailyActivityFCRFilterList';

const logFCRListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const logFCRHistoryReq = (state, action) => ({
  ...state,
  historyRequest: action.data ? action.data : state.listRequest,
  historyRequestedOn: new Date(),
  loading: true,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const updateTableConfig = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
});

const DailyActivityFCRFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case dailyActivityFCRActionType.UPDATE_FCR_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case dailyActivityFCRActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case dailyActivityFCRActionType.REMOVE_FCR_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case dailyActivityFCRActionType.LOG_FCR_HISTORY_REQUEST:
      return logFCRHistoryReq(state, action);
      // log error
    case dailyActivityFCRActionType.GET_FCR_HISTORY_ERROR:
      return {
        ...state,
        historyRequestError: action.data,
        historyRequestProcessedOn: new Date(),
        loading: false,
      };
      // store response
    case dailyActivityFCRActionType.GET_FCR_HISTORY_RESPONSE:
      return {
        ...state,
        historyData: action.data,
        historyRequestError: null,
        historyRequestProcessedOn: new Date(),
        loading: false,
      };
    default:
      return null;
  }
};

const DailyActivityFCRFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case dailyActivityFCRActionType.LOG_FCR_LIST_REQUEST:
      return logFCRListReq(state, action);
    // log error
    case dailyActivityFCRActionType.GET_FCR_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case dailyActivityFCRActionType.GET_FCR_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case dailyActivityFCRActionType.GET_FCR_DATA_RESPONSE:
      return {
        ...state,
        flagData: action.data,
        flagRequestError: null,
        flagRequestProcessedOn: new Date(),
        loading: false,
      };
    case dailyActivityFCRActionType.GET_FCR_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case dailyActivityFCRActionType.RESET_FCR_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case dailyActivityFCRActionType.RESET_FCR_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    case dailyActivityFCRActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const DailyActivityFCRFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = DailyActivityFCRFilterListConfig(state, action);
  const stateReqRes = DailyActivityFCRFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default DailyActivityFCRFilterList;
