/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@manulife/mux';
import './fasatTextInput.scss';
import { currencyFormatToDecimal, currencyFormat, padWithZeroes } from '../../util';
import FasatFormErrors from '../fasatFormErrors/fasatFormErrors';
import { globalConstants } from '../../moduleConstants';

const reduceSonar = (value, setValues) => {
  if (value === null) {
    setValues('');
  }
};
const handleOnBlurFun = ({
  currencyInput, ev, locale, setValues, onBlur, durableInput, decimalPlaces, isParcent, defaultMagicNumber, _val }) => {
  if (currencyInput) {
    let dataResult;
    if (ev.target.value !== '' && ev.target.value !== '-') {
      const num = ev.target.value.replace(/\s/g, '');
      const splitedValue = currencyFormatToDecimal(num, locale);
      dataResult = currencyFormat(splitedValue, locale, decimalPlaces).trimEnd();
    } else {
      dataResult = '';
    }
    setValues(dataResult);
    onBlur(dataResult);
  } else if (durableInput && ev.target.value !== '') {
    const dataResult = padWithZeroes(ev.target.value, defaultMagicNumber);
    setValues(dataResult);
    onBlur(ev, dataResult);
  // eslint-disable-next-line no-restricted-globals
  } else if (isParcent && ev.target.value && !isNaN(ev.target.value)) {
    const dataResult = parseFloat(ev.target.value).toFixed(decimalPlaces || globalConstants.numberTwo);
    setValues(dataResult);
    onBlur(ev, _val);
  } else {
    const dataResult = ev.target.value;
    setValues(dataResult);
    onBlur(ev, _val);
  }
};

export const validateAssign = ({ dc, _val, value, beforeDc }) => {
  // eslint-disable-next-line no-param-reassign, no-debugger
  const regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
  const regexp3 = /^[0-9]*(\.[0-9]{0,3})?$/;
  const regexp4 = /^[0-9]*(\.[0-9]{0,4})?$/;
  const regxBeforeThree = /^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/;
  if (dc === globalConstants.number4 && !regexp4.test(_val.target.value)) {
    // eslint-disable-next-line no-param-reassign
    _val.target.value = value;
  }
  if (dc === globalConstants.number3 && !regexp3.test(_val.target.value)) {
    // eslint-disable-next-line no-param-reassign
    _val.target.value = value;
  }
  if (dc === globalConstants.number2 && !regexp.test(_val.target.value)) {
    // eslint-disable-next-line no-param-reassign
    _val.target.value = value;
  }
  if (beforeDc === globalConstants.number3 && !regxBeforeThree.test(_val.target.value)
    && _val.target.value.charAt(_val.target.value.length - 1) !== '.') {
    // eslint-disable-next-line no-param-reassign
    _val.target.value = value;
  }
};

export const numericDecimal = ({ dc, _val, value }) => {
  const regex = /^(\d{0,8})(\.\d{0,2})?$/;
  const regexSixPlace = /^(\d{0,6})(\.\d{0,6})?$/;
  if (dc === globalConstants.number2 && !regex.test(_val.target.value)) {
    // eslint-disable-next-line no-param-reassign
    _val.target.value = value;
  }
  if (dc === globalConstants.number6 && !regexSixPlace.test(_val.target.value)) {
    // eslint-disable-next-line no-param-reassign
    _val.target.value = value;
  }
};

const inputFiledNumWithDecimalPipe = ({ _val, value }) => {
  const numberWithPipe = /^[-.|, 0-9]+$/;
  if (!numberWithPipe.test(_val.target.value)) {
    // eslint-disable-next-line no-param-reassign
    _val.target.value = value;
  }
};

const numNotEmpty = ({ _val, digitPlace }) => {
  const regexpOnlyNum2 = /^\d{1,2}$/;
  const regexEmpty = /^\s*$/;
  const isEmpty = regexEmpty.test(_val.target.value);
  if (digitPlace === globalConstants.number2 && ((!regexpOnlyNum2.test(_val.target.value)) && !isEmpty)) {
    return true;
  }
  return false;
};
/**
   * Handles onInput event
   * @param {String} _val value
   */
const handleOnInput = ({ _val, dc, isParcent, value, onlyNumber, digitPlace, onInput, numDec, beforeDc, inputNum, inputNumWithPipe }) => {
  if (_val.target.value) {
    if (isParcent) {
      validateAssign({ dc, _val, value, beforeDc });
    }
    if (numDec) {
      numericDecimal({ dc, _val, value });
    }
    if (inputNum || inputNumWithPipe) {
      inputFiledNumWithDecimalPipe({ _val, value });
    }
  }
  if ((onlyNumber && digitPlace) && (numNotEmpty({ _val, digitPlace }))) {
    // eslint-disable-next-line no-param-reassign
    _val.target.value = value;
  }
  onInput(_val);
};

const FasatTextInput = (props) => {
  const { value, onChange, onBlur, onBlurEnabled, onFocus, onFocusEnabled, onInput, isParcent, autoComplete, onlyNumber,
    onInputEnabled, currencyInput, durableInput, locale, errors, name, maxLength, decimalPlaces, autoHandlerCng, numDec,
    digitPlace, beforeDc, inputNum, inputNumWithPipe, hasCustomError = false, ...rest } = props;

  const [values, setValues] = useState(value);

  useEffect(() => {
    setValues(value);
    reduceSonar(value, setValues);
  }, [value]);

  /**
   * Changes the value of the current element
   * @param {String} newValue value
   * @param {String} e current event
   */
  const setChangeValue = (newValue, e) => {
    setValues(newValue);
    if (autoComplete === 'on') {
      autoHandlerCng(newValue);
    } else {
      onChange(e);
    }
  };

  /**
   * Handles focus event
   * @param {String} _val value
   * @param {String} ev event
   */
  const handleOnFocus = (_val, ev) => {
    onFocus(ev);
  };

  /**
   * Handles blur event
   * @param {String} _val value
   * @param {String} ev event
   */
  const defaultMagicNumber = 3;
  const handleOnBlur = (_val, ev) => {
    handleOnBlurFun({
      currencyInput, ev, locale, setValues, onBlur, durableInput, isParcent, decimalPlaces, defaultMagicNumber, _val });
  };

  const textInputClass = 'fasatTextInput';
  const fasatTextInputErrorClass = 'withError';

  /**
   * Updates error response
   * @returns error response
   */
  const updateError = () => {
    const errorsResponse = [];
    if (errors.length > 0 && errors[0].isError) {
      errorsResponse.push({ isError: !hasCustomError });
    }
    return errorsResponse;
  };
  return (
    <div
      className={`${textInputClass} ${
        errors.length > 0 && errors[0].isError ? fasatTextInputErrorClass : ''
      }`}
    >
      <TextInput
        {...rest}
        value={values}
        name={name}
        onChange={(newValue, e) => setChangeValue(newValue, e)}
        onFocus={(val, ev) => onFocusEnabled && handleOnFocus(val, ev)}
        onBlur={(val, ev) => onBlurEnabled && handleOnBlur(val, ev)}
        onInput={(val) => onInputEnabled && handleOnInput({ _val: val, dc: decimalPlaces, isParcent, value, onlyNumber, digitPlace, onInput, numDec, beforeDc, inputNum, inputNumWithPipe })}
        maxLength={maxLength}
        errors={updateError()}
        customStyle={{
          rootStyle: {
            width: '100%',
          },
          inputStyle: {
            height: '44px',
          },
        }}
      />
      {updateError().length > 0 && <FasatFormErrors name={name} errors={errors} />}
    </div>
  );
};

FasatTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  autoHandlerCng: PropTypes.func,
  onBlur: PropTypes.func,
  onBlurEnabled: PropTypes.bool,
  onlyNumber: PropTypes.bool,
  numDec: PropTypes.bool,
  onFocus: PropTypes.func,
  onFocusEnabled: PropTypes.bool,
  onInput: PropTypes.func,
  onInputEnabled: PropTypes.bool,
  currencyInput: PropTypes.bool,
  durableInput: PropTypes.bool,
  isParcent: PropTypes.bool,
  locale: PropTypes.string,
  name: PropTypes.string,
  digitPlace: PropTypes.number,
  autoComplete: PropTypes.string,
  maxLength: PropTypes.number,
  decimalPlaces: PropTypes.number,
  beforeDc: PropTypes.number,
  hasCustomError: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.string,
      isError: PropTypes.bool,
      msg: PropTypes.string,
    },
  )),
  inputNum: PropTypes.bool,
  inputNumWithPipe: PropTypes.bool,
};

FasatTextInput.defaultProps = {
  value: '',
  name: '',
  maxLength: null,
  decimalPlaces: 0,
  digitPlace: 0,
  beforeDc: 0,
  onlyNumber: false,
  numDec: false,
  onBlurEnabled: false,
  onInputEnabled: false,
  isParcent: false,
  onFocusEnabled: false,
  currencyInput: false,
  durableInput: false,
  autoComplete: 'off',
  locale: 'en',
  hasCustomError: false,
  errors: [
    {
      id: '',
      isError: false,
      msg: '',
    },
  ],
  onBlur: () => undefined,
  onInput: () => undefined,
  onFocus: () => undefined,
  autoHandlerCng: () => undefined,
  inputNum: false,
  inputNumWithPipe: false,
};

export default FasatTextInput;
