/* eslint-disable no-debugger */
/**
 * @Description Fasat Grid Coulmn Input Text Filter
 * @FileName ColumnFilter.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 05 February, 2021 10:52:45
 * @IssueID -323
 */
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';
import { Icon } from '@manulife/mux';
import { FasatTextInput } from '../../../../../components';
import searchIcon from '../../assets/images/search-icon.svg';

export const asyncDebounceSetData = (setValue, setFilter, changeValue) => {
  setValue(changeValue.target.value);
  setFilter(changeValue.target.value || undefined);
};

const TextFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  const [value, setValue] = useState(filterValue);
  const debouncingTimeout = 1000;
  /**
   * debouncing method for column onChange filter
   */
  const onChange = useAsyncDebounce((changeValue) => {
    asyncDebounceSetData(setValue, setFilter, changeValue);
  }, debouncingTimeout);

  return (
    <FasatTextInput
      value={value || ''}
      onChange={(val) => onChange(val)}
      placeholder="Search"
      icon={<Icon path={searchIcon} alt="icon" />}
    />
  );
};

TextFilter.propTypes = {
  column: PropTypes.arrayOf(PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func,
  })).isRequired,
};

export default TextFilter;
