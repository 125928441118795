import { environment } from '../../../common/util';

const envData = environment();
const ClientRelationshipApiConfig = {
  CLIENT_RELATIONSHIP_LIST: {
    endpoint: `${envData.apiHost}/api/relationshipinquiry/list`,
    type: 'POST',
    authorizationRequired: false,
    showLoading: true,
  },
  DELETE_RELATIONSHIP_LIST: {
    endpoint: `${envData.apiHost}/api/relationshipinquiry`,
    type: 'DELETE',
    authorizationRequired: false,
    showLoading: true,
  },
  CLIENT_RELATIONSHIP_EXPORT_LIST: {
    endpoint: `${envData.apiHost}/api/relationshipinquiry/export`,
    type: 'DOWNLOAD',
    authorizationRequired: false,
    showLoading: true,
  },
};

export default ClientRelationshipApiConfig;
