/**
 * @Description This file contains all the Menu configuration for administration module
 * @FileName menuConfig.js
 * @Author Jayanta Sarkhel- sarkhja
 * @CreatedOn 15 february, 2022 16:41:03
 * @IssueID - 459, 311
 */
import { Calculator } from '@manulife/mux-cds-icons';
import { t } from '../../../common/i18n';
import { navigate } from '../../../common/util';
import { aclKeysConstant, routeConstant, staticLabelKeys } from '../moduleConstants';

/**
  * Menu configuration for the module
  */
const desktopMenus = () => [
  {
    id: 'compcal',
    sequence: 1,
    icon: <Calculator style={{ width: '20px', height: '20px' }} />,
    label: t(staticLabelKeys.COMCALC),
    aria: t(staticLabelKeys.COMCALC),
    urlPath: '#',
    url: '#',
    action: () => navigate('#'),
    aclKey: [aclKeysConstant.COMPCAL],
    acl: true,
    showInBatchProcess: false,
    subMenu: [
      {
        id: 'RATE_MASTER_MENU',
        label: t(staticLabelKeys.RF_RMRD_TITLE),
        urlPath: routeConstant.RATE_MASTER_LIST_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.RATE_MASTER_LIST_ROUTE),
        aclKey: [aclKeysConstant.RBAC_RM_MENU],
        acl: true,
      },
      {
        id: 'COMPENSATION_STRUCTURE_MENU',
        label: t(staticLabelKeys.CS_MENU),
        urlPath: routeConstant.COMPCAL_STRUCTURE_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.COMPCAL_STRUCTURE_ROUTE),
        aclKey: [aclKeysConstant.CS_CARD_RULES],
        acl: true,
        showInBatchProcess: false,
      },
      {
        id: 'DEFAULT_COMPENSATION_STRUCTURE_MENU',
        label: t(staticLabelKeys.DCS_MENU),
        urlPath: routeConstant.DEFAULT_COMPCAL_STRUCTURE_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.DEFAULT_COMPCAL_STRUCTURE_ROUTE),
        aclKey: [aclKeysConstant.DCS_CARD_RULES],
        acl: true,
        showInBatchProcess: false,
      },
      {
        id: 'SALES_CREDIT_SUMMARY_MENU',
        label: t(staticLabelKeys.SCS_MENU),
        urlPath: routeConstant.SCS_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.SCS_ROUTE),
        aclKey: [aclKeysConstant.RBAC_SCS_MENU],
        acl: true,
        showInBatchProcess: false,
      },
      {
        id: 'LRO_ELIGIBILITY_MENU',
        label: t(staticLabelKeys.LRO_ELIGIBILITY_MENU),
        urlPath: routeConstant.LRO_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.LRO_ROUTE),
        aclKey: [aclKeysConstant.RBAC_LRO_MENU],
        acl: true,
        showInBatchProcess: false,
      },
    ],
  },
];
const mobileMenus = desktopMenus;
export { desktopMenus, mobileMenus };
