import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { lroEligibilityActionTypes } from '../actions/actionTypes';

export const REDUCER_NAME = 'LROEligibilityFilterList';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const logLROListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const updateTableConfig = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  selectedFilter: action.data ? action.data : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
});

const LROEligibilityFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case lroEligibilityActionTypes.UPDATE_LRO_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case lroEligibilityActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case lroEligibilityActionTypes.REMOVE_LRO_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    default:
      return null;
  }
};

const LROEligibilityFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case lroEligibilityActionTypes.LOG_LRO_LIST_REQUEST:
      return logLROListReq(state, action);
    // log error
    case lroEligibilityActionTypes.GET_LRO_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case lroEligibilityActionTypes.GET_LRO_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case lroEligibilityActionTypes.GET_LRO_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case lroEligibilityActionTypes.RESET_LRO_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case lroEligibilityActionTypes.RESET_LRO_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    case lroEligibilityActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const LROEligibilityFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = LROEligibilityFilterListConfig(state, action);
  const stateReqRes = LROEligibilityFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default LROEligibilityFilterList;
