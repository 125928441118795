/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/**
 * @Description Fasat Filter Setting Page
 * @FileName index.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 24 May, 2021 21:40:00
 * @IssueID - 436
 */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EXPANDABLE_PANEL_VARIANT, Icon, colors } from '@manulife/mux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import FilterCriteria from './filterCriteria/filterCriteria';
import SortOrder from './sortOrder/sortOrder';
import GroupBy from './groupBy/groupBy';
import Distinct from './distinct/distinct';
import './index.scss';
import { animateFilterComponent, configSelColumn, configureCustFilCrt, CREATE, custFilList, formatFilterCriteriaParams,
  formatOrder, generateOptions, getCancelModal, getDuplicateModal, getFilterInstance, getListRequest,
  initialAccordionState, modalDublicateSelection, returnSaveButton, selectedFilIn, setDropdownItem, SHAREDFILTER,
  SYSDEFAULT, UPDATE, validateIsSharedFlag, configureGroupByColumn, setFilterType, validateNull, bridgeFilterMethod,
  configureSortColumn, scrollListBoxToTopPosition, grpByIsActive, formatParamsSearch,
  stringBlankModal, operatorBtwnValidate } from './filterUtil';
import { staticCommonLabelKeys as cmnLblKey, staticCommonLabelKeys } from '../../moduleConstants';
import { FasatButtonSelector, FasatActionBtn, FasatButton, FasatChip, FasatColumnOrder, FasatDropdown,
  FasatExpandablePanel, FasatLabel, FasatModal } from '..';
import { getAction } from '../../../../stateManagement/reduxUtil';
import { checkDirtyData, getCustomFilterList, getDeafultFilterSettings, getOperatorList,
  getRolesList, resetUpsertValue, setRoleList, updateUseOnFilter } from '../../redux/actions/commonActions';
import SaveFilter from './saveFilter/saveFilter';
import { getCurrentLocale } from '../../i18n';
import { formatColumnOrderForUI, initialReqObject, resetUpsertValue as resetUpsertVal } from '../../util';

const propTypes = {
  availableColumns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  lookupData: PropTypes.arrayOf(PropTypes.shape()),
  entityFilterConfig: PropTypes.shape({
    SEARCH_ACTION: PropTypes.string,
    UPDATE_CONFIGURATION: PropTypes.string,
    UPDATE_TABLE_CONFIGURATION: PropTypes.string,
    UPDATE_FILTER_CONFIG: PropTypes.string,
    REDUX_STATE: PropTypes.string,
    REMOVE_USER_DEFAULT_FILTER: PropTypes.string,
  }).isRequired,
  customPageSize: PropTypes.number.isRequired,
  entityId: PropTypes.number.isRequired,
  screenName: PropTypes.string,
  triggerActivateDataGrid: PropTypes.func,
  resetPreSelectedFilterConfig: PropTypes.func,
  loadPreSelectedFilterConfig: PropTypes.bool.isRequired,
  freezeColumn: PropTypes.string,
  paramForFreezeColumn: PropTypes.arrayOf(PropTypes.shape()),
  listActive: PropTypes.bool,
  activateDataGrid: PropTypes.bool,
  defaultNewCustomFilter: PropTypes.shape({}), // Props for craeting a new custom filter on load
  onSearchClick: PropTypes.func,
  isExtSrceen: PropTypes.shape(),
};
const defaultProps = {
  lookupData: null,
  screenName: '',
  triggerActivateDataGrid: () => undefined,
  freezeColumn: null,
  paramForFreezeColumn: [],
  resetPreSelectedFilterConfig: () => undefined,
  listActive: false,
  activateDataGrid: false,
  defaultNewCustomFilter: null,
  onSearchClick: () => undefined,
  isExtSrceen: null,
};
/**
 * class names, id names, for names, width(s) defined
 */
const filterSettingsPanelClass = 'filterSettingsPanel';
const filterExpandablePanelID = 'filterExpandablePanel';
const filterCriteriaPanelID = 'filterCriteriaPanelID';
const sortOrderPanelID = 'sortOrderPanelID';
const groupByPanelID = 'groupByPanelID';
const columnOrderPanelID = 'columnOrderPanelID';
const panelHeaderSectionClass = 'panelHeaderSection';
const panelHeaderTextClass = 'panelHeaderText';
const sysDefaultFilterHeaderClass = 'sysDefaultFilterHeader';
const panelActionAreaClass = 'panelActionArea';
const loadFilterClass = 'loadFilter';
const loadDropdownID = 'load';
const loadLabelFor = 'load-toggle';
const filterSettingsPanelLevel2Class = 'filterSettingsPanel_level2';
const panelFooterActionContainerClass = 'panelFooterActionContainer';
const filterSettingsPanelTextClass = 'filterSettingsPanelText';
const filterSubAccordionPanelTextClass = 'filterSubAccordionPanelText';
const modalWidth = '560px';
const UPDATE_USAGE = 'UPDATE_USAGE';
const ariaExpanded = 'aria-expanded';
const sumRecordCount = 'sumRecordCount';
const sortFilterList = () => [{ key: 'defaultFilter', asc: false }];
export const checkFilterIsDefault = (filterData) => !filterData.isSystemFilter && filterData.isDefault;
export const validateClone = (cloneFilter) => cloneFilter && cloneFilter.detail
    && cloneFilter.detail.columnOrders && cloneFilter.detail.columnOrders.length > 0;
export const validateUserDefault = (filterLoadCount, filterData) => !filterLoadCount
    && filterData.isDefault && !filterData.isSystemFilter;
export const validateSystemDefault = (filterLoadCount, filterData) => !filterLoadCount
    && filterData.isDefault && filterData.isSystemFilter;
const isActionUpdate = (action) => (action === UPDATE);
export const setPreUserDefault = ({ currentFilterObject,
  loggedInUser }) => (currentFilterObject && (currentFilterObject.owner === loggedInUser
        && (!currentFilterObject.isSystemFilter && currentFilterObject.isDefault)));
export const setLoggedInUser = (loggedUser) => (loggedUser && loggedUser.UserId);
export const setHeaderClass = (currentFilterObject) => (currentFilterObject
       && currentFilterObject.isSystemFilter
  ? `${sysDefaultFilterHeaderClass} ${panelHeaderTextClass}` : panelHeaderTextClass);
export const hasFilterName = (currentFilterObject) => (currentFilterObject && currentFilterObject.name);
export const hasFilterDescription = ({ currentFilterObject,
}) => (currentFilterObject && currentFilterObject.description);
export const extractID = (action, currentFilterObject) => (
  currentFilterObject && action === UPDATE ? currentFilterObject.id : ''
);
export const extractName = (action, currentFilterObject) => (
  currentFilterObject && action === UPDATE ? currentFilterObject.name : ''
);
export const extractDesc = (action, currentFilterObject) => (
  currentFilterObject && action === UPDATE ? currentFilterObject.description : ''
);
export const isCrtFilterDefOne = (currentFilterObject, action) => (currentFilterObject && isActionUpdate(action));

/**
       * Functionality on click of Search button
       */
const optionArray = ['OPT_BETWEEN', 'OPT_NOT_BETWEEN'];
export const searchFunc = ({ columnOrder, custFilCreteria, validateFilterCriteria, displayWarningMessage,
  setValidateFilterCriteria, triggerSearch, setDisplayWarningMessage, setDisplayBetWarningMsg,
  displayBetWarningMsg }) => {
  if (columnOrder.length > 0) {
    if (custFilCreteria.length > 0) {
      const filterValidate = operatorBtwnValidate(custFilCreteria);
      if (filterValidate.find((idx) => optionArray.includes(idx.operator)) !== undefined) {
        setDisplayBetWarningMsg(!displayBetWarningMsg);
      } else {
        triggerSearch();
        setValidateFilterCriteria(0);
      }
    } else {
      triggerSearch();
      setValidateFilterCriteria(0);
    }
  } else {
    setDisplayWarningMessage(!displayWarningMessage);
  }
};
/**
        * This is to configure the Pre selected unsaved Filter Criteria
        */
export const configFil = ({ listColumnOrder, setSelectedColumn, listGroupBy, setSelectedGroupByOption,
  listSortOptions, setSelectedSortOptions, setSelectedParametOptions, listParamOptions, isDistinctActive,
  setIsDistinct }) => {
  if (listColumnOrder) {
    setSelectedColumn(listColumnOrder);
  } else {
    setSelectedColumn([]);
  }
  if (listGroupBy) {
    setSelectedGroupByOption(listGroupBy);
  } else {
    setSelectedGroupByOption([]);
  }
  if (listSortOptions) {
    setSelectedSortOptions(listSortOptions);
  } else {
    setSelectedSortOptions(null);
  }
  if (listParamOptions) {
    setSelectedParametOptions(listParamOptions);
  } else {
    setSelectedParametOptions([]);
  }
  setIsDistinct(isDistinctActive);
};
const setSelColumn = (columnOrders) => columnOrders || [];
const setSortOpts = (sortOptions) => sortOptions || null;
/**
     * This is to configure the Pre selected Filter Criteria
     */
export const selectedFilFun = ({ filterData, setSelectedColumn, setSelectedGroupByOption,
  setSelectedSortOptions, setSelectedParametOptions, setGroupByActive, setIsDistinct }) => {
  if (filterData.detail) {
    setIsDistinct(filterData.detail.isDistinct);
    setSelectedColumn(setSelColumn(filterData.detail.columnOrders));
    if (filterData.detail.groupByParameters) {
      setSelectedGroupByOption(filterData.detail.groupByParameters);
      if (filterData.detail.groupByParameters.length > 0) {
        setGroupByActive(true);
      }
    }
    setSelectedSortOptions(setSortOpts(filterData.detail.sortOptions));

    if (filterData.detail.parameters) {
      setSelectedParametOptions(filterData.detail.parameters);
    } else {
      setSelectedParametOptions([]);
    }
  }
};
const filterDataFuc = ({ filterData, setDeselectColumnOrder, validateAndConfigureFilter, setCurrentFilterObject,
  filterLoadCount, setUsrDfltFltID, setFltLoadCnt, setIsSysDefaultFilter, configureDefaultFilter, isSYSDefault,
  setSelectedFilterType }) => {
  if (filterData) {
    setDeselectColumnOrder(false);
    const filterInstance = bridgeFilterMethod(filterData);
    setCurrentFilterObject(filterInstance);
    if (validateUserDefault(filterLoadCount, filterData)) {
      setUsrDfltFltID(filterData.id);
      setFltLoadCnt(1);
      setIsSysDefaultFilter(false);
    }
    const isSysDef = validateSystemDefault(filterLoadCount, filterData);
    if (isSysDef) {
      setIsSysDefaultFilter(true);
    }
    if (filterData.isDefault) {
      configureDefaultFilter(filterData);
    }
    if (!filterData.isDefault && !filterData.isSystemFilter) {
      setSelectedFilterType(SHAREDFILTER);
    }
    if (filterData.detail.groupByParameters === null) {
      const updatefilterdata = filterData;
      updatefilterdata.detail.groupByParameters = filterData.detail.groupByParameters || [];
      validateAndConfigureFilter(updatefilterdata, isSysDef);
    } else {
      validateAndConfigureFilter(filterData, isSysDef);
    }
  }
};
export const fasatChipFunc = ({ currentFilterObject, t, userDefaultFilterID, loggedInUser }) => {
  const tag = [];
  if (currentFilterObject) {
    if (userDefaultFilterID === currentFilterObject.id
           && currentFilterObject.owner === loggedInUser) {
      tag.push(t(cmnLblKey.FILTER_LABEL_MY_DEFAULT));
    }
    if (currentFilterObject.isShared) {
      tag.push(t(cmnLblKey.COMMON_LABEL_SHARED));
    }
    if (currentFilterObject.owner === loggedInUser && !currentFilterObject.isShared) {
      tag.push(t(cmnLblKey.FILTER_LABEL_PRIVATE));
    }
  }
  return (
    <>
      {tag.length > 0
           && (
           <div className="chipSection">
             {tag.map((item) => (
               <FasatChip
                 data={item}
                 hasCloseBtn={false}
                 isDefaultChip={item === t(cmnLblKey.FILTER_LABEL_MY_DEFAULT)}
                 key={item}
               />
             ))}
           </div>
           )}
    </>
  );
};
export const filterDescFunc = ({ t, currentFilterObject, label, createNewFilterFlag }) => {
  let filterTitle;
  if (currentFilterObject && currentFilterObject.isSystemFilter) {
    if (label === 'title') {
      filterTitle = createNewFilterFlag ? t(cmnLblKey.FILTER_LABEL_NEW) : t(cmnLblKey.FILTER_LABEL_SYSTEM_DEFAULT);
    } else {
      filterTitle = t(cmnLblKey.FILTER_LABEL_HELP);
    }
  }
  if (currentFilterObject && !currentFilterObject.isSystemFilter) {
    if (label === 'title') {
      filterTitle = currentFilterObject.name;
    } else {
      filterTitle = currentFilterObject.description;
    }
  }
  return filterTitle;
};
export const filterCriteriaFunc = ({ custFilCreteria, setCustFilCreteria, value }) => {
  if (value && value.key !== '') {
    const cloneCustomCreteria = custFilCreteria;
    const foundIndex = custFilCreteria.findIndex((x) => x.uuid === value.uuid);
    if (foundIndex >= 0) {
      cloneCustomCreteria[foundIndex] = value;
      setCustFilCreteria(cloneCustomCreteria);
    } else {
      setCustFilCreteria([...cloneCustomCreteria, value]);
    }
  }
};
export const resetFilter = (toggleValue, resetPreSelectedFilterConfig) => {
  if (!toggleValue && resetPreSelectedFilterConfig) {
    resetPreSelectedFilterConfig();
  }
};
const loadSaveModal = ({ formData, isSystemDefaultFilter, userDefaultFilterID, entityId, updateDefaultFilterID,
  entityFilterConfig, screenName, renderCustFilListCnt, sharedFilterCount, resetNewFilFlg }) => (
    <SaveFilter
      formData={formData}
      onSaveTopic="ADD_FILTER_SAVE"
      entityId={entityId}
      isSystemDefaultFilter={isSystemDefaultFilter}
      userDefaultFilterID={userDefaultFilterID}
      updateDefaultFilterID={updateDefaultFilterID}
      onCancelTopic="ADD_FILTER_CANCEL"
      updateFilterAction={entityFilterConfig.UPDATE_FILTER_CONFIG}
      screenName={screenName}
      sharedFilterCount={sharedFilterCount}
      renderFilterList={renderCustFilListCnt}
      resetNewFilterFlag={resetNewFilFlg}
      entityFilterConfig={entityFilterConfig}
    />
);
const loadAptFilter = ({ deletedFilterID, currentFilterObject, loadSelectedFilter, setUsrDfltFltID, dispatch,
  userDefaultFilterID, systemDefaultFilter, entityFilterConfig, userDefaultFilter }) => {
  if (deletedFilterID === currentFilterObject.id) {
    if (currentFilterObject.id === userDefaultFilterID) {
      loadSelectedFilter(systemDefaultFilter, true);
      setUsrDfltFltID(null);
      dispatch(getAction(entityFilterConfig.REMOVE_USER_DEFAULT_FILTER));
    } else {
      loadSelectedFilter(userDefaultFilter, true);
    }
  }
};
const configureFormData = ({ dispatch, action, currentFilterObject, customColumnGroupBy,
  customColumnSorting, loggedInUser, displayModal, isDistinct,
  setSaveNewFilter, custFilCreteria, columnOrder, entityId, setFormData, setDisplayModal }) => {
  dispatch(resetUpsertValue(resetUpsertVal));
  const configFormData = {
    action,
    customColumnGroupBy,
    isDistinct,
    customColumnSorting,
    custFilCreteria,
    columnOrder,
    entityId,
    id: extractID(action, currentFilterObject),
    name: extractName(action, currentFilterObject),
    description: extractDesc(action, currentFilterObject),
    isShared: validateIsSharedFlag({ action, currentFilterObject }),
    sharedWith: (currentFilterObject && action === UPDATE)
           && currentFilterObject.sharedWith ? currentFilterObject.sharedWith.join() : '',
    owner: loggedInUser,
    isDefault: (isCrtFilterDefOne(currentFilterObject, action)
      ? (currentFilterObject.owner === loggedInUser
            && (!currentFilterObject.isSystemFilter && currentFilterObject.isDefault)) : false),
    preUserDefaultFilter: (setPreUserDefault({ currentFilterObject,
      loggedInUser }) ? currentFilterObject.name : false),
  };
  setFormData(configFormData);
  setSaveNewFilter(action === CREATE);
  setDisplayModal(!displayModal);
};
const getColumnOrderBasedOnGrpBy = ({ isGroupByActive, groupByColumnOrder, columnOrder,
  systemDefaultFilter, isColumnOrderChange }) => {
  if (!isGroupByActive && !isColumnOrderChange) {
    return systemDefaultFilter.detail.columnOrders;
  }
  return isGroupByActive ? groupByColumnOrder : columnOrder;
};

const formatAndDispatchSearch = ({ dispatch, customColumnSorting, customColumnGroupBy, custFilCreteria, columnOrder,
  locale, paramForFreezeColumn, currentFilterObject, customPageSize, entityFilterConfig, formatFilterObject,
  createNewFilterFlag, listActive, groupByActive, systemDefaultFilter, isColumnOrderChange, isDistinct,
  isExtSrceen }) => {
  const sortedColOredr = customColumnSorting.map(({ key, asc }) => ({ key, asc }));
  const grpByColumnValues = customColumnGroupBy.map(({ key, operator }) => (
    { key, operator }));
  const formatedParameterObject = formatFilterCriteriaParams(custFilCreteria);
  const formatedParamObjSrch = formatParamsSearch(custFilCreteria);
  let paramObjSrch = null;
  const groupByColumnOrder = [];
  const isGroupByActive = groupByActive && grpByColumnValues.length > 0;
  if (paramForFreezeColumn) {
    paramObjSrch = [...paramForFreezeColumn, ...formatedParamObjSrch];
  } else {
    paramObjSrch = formatedParamObjSrch;
  }
  if (isGroupByActive) {
    grpByColumnValues.map((item) => groupByColumnOrder.push(item.key));
    groupByColumnOrder.push(sumRecordCount); // add additional column for sum of record count
  }
  // filter column order if group by selected
  const selectColumnOrder = getColumnOrderBasedOnGrpBy({ isGroupByActive,
    groupByColumnOrder,
    columnOrder,
    systemDefaultFilter,
    isColumnOrderChange });
  const filterDetails = {
    parameters: paramObjSrch,
    groupByParameters: grpByColumnValues,
    sortOptions: sortedColOredr,
    columnOrder: selectColumnOrder,
    groupByActive,
    isDistinct,
    isExtSrceen,
  };
  const filterInstance = getFilterInstance(currentFilterObject,
    formatedParameterObject,
    customColumnSorting,
    selectColumnOrder,
    grpByColumnValues,
    isDistinct);
  const exportType = 'NONE';
  const reqListObject = getListRequest({ customPageSize, filterDetails, locale, exportType, listActive });
  dispatch(getAction(entityFilterConfig.SEARCH_ACTION, reqListObject));
  dispatch(getAction(entityFilterConfig.UPDATE_TABLE_CONFIGURATION, filterInstance));
  window.scrollTo(0, 0);
  if (!createNewFilterFlag) {
    formatFilterObject(currentFilterObject);
  }
};
const operateLoadFilter = ({ setCreateNewFilterFlag, modalCLose, setshowCustFilModal, showCustFilModal, dispatch,
  filterData, userDefaultFilter, entityFilterConfig, setIsSysDefaultFilter }) => {
  setCreateNewFilterFlag(false);
  if (!modalCLose) {
    setshowCustFilModal(!showCustFilModal);
  }
  const cloneFilter = filterData;
  if (validateClone(cloneFilter) && typeof cloneFilter.detail.columnOrders[0] !== 'string') {
    const configureCOlumnOrder = formatColumnOrderForUI(cloneFilter.detail.columnOrders);
    cloneFilter.detail.columnOrders = configureCOlumnOrder;
  }
  const userDefault = userDefaultFilter;
  dispatch(getAction(entityFilterConfig.UPDATE_FILTER_CONFIG,
    { selectedItem: cloneFilter, userDefault }));
  setIsSysDefaultFilter(false);
  document.body.style.overflowY = 'auto';
};
const configureStoreFilObj = ({ entityFilterConfig, filterData, dispatch, loggedInUser }) => {
  const { preUserDefaultFilter } = filterData && (filterData.owner === loggedInUser
      && (checkFilterIsDefault(filterData)))
    ? filterData.name : false;
  const setParam = formatParamsSearch(filterData.detail?.parameters);
  const formatObject = {
    action: UPDATE_USAGE,
    filter: {
      id: filterData.id,
      owner: filterData.owner,
      entityId: filterData.entityId,
      name: filterData.name,
      description: filterData.description,
      isDefault: filterData.isDefault || false,
      isShared: filterData.isShared || false,
      isSystemFilter: false,
      sharedWith: filterData.sharedWith || [],
      detail: {
        parameters: setParam,
        groupByParameters: filterData.detail?.groupByParameters,
        isDistinct: filterData.detail?.isDistinct,
        sortOptions: filterData.detail?.sortOptions,
        columnOrders: filterData.detail && formatOrder(filterData.detail.columnOrders),
      },
    },
  };
  const requestPayload = {
    formatObject,
    preUserDefaultFilter,
    setAction: entityFilterConfig.UPDATE_FILTER_CONFIG,
  };
  dispatch(updateUseOnFilter(requestPayload));
};
const setCustomColumnState = ({ selectedSortOptions, setCustomColumnSorting }) => {
  if (selectedSortOptions) {
    const custSelSortOption = selectedSortOptions.map(({ key, asc }) => ({
      key,
      asc,
      uuid: uuidv4(),
    }));
    setCustomColumnSorting(custSelSortOption);
  } else {
    setCustomColumnSorting([]);
  }
};
const setCustomGroupbyState = ({ selectedGroupByOption, setCustomColumnGroupBy, setGroupByActive }) => {
  if (selectedGroupByOption) {
    const selectedGroupByColumn = selectedGroupByOption.map(({ key, operator }) => ({
      key,
      operator,
      uuid: uuidv4(),
    }));
    setCustomColumnGroupBy(selectedGroupByColumn);
  } else {
    setCustomColumnGroupBy(null);
  }
  if (selectedGroupByOption && selectedGroupByOption.length === 0) {
    setGroupByActive(false);
  }
};
const resetFilterOnEntityUpdate = ({ loadPreSelectedFilterConfig, entityFilterConfig, entityId, dispatch,
  renderCustFilListCnt, loggedInUser }) => {
  if (entityFilterConfig.UPDATE_FILTER_CONFIG && loadPreSelectedFilterConfig === false) {
    const requestPayload = { entityId: { entityId },
      setAction: entityFilterConfig.UPDATE_FILTER_CONFIG,
      loggedInUser,
    };
    dispatch(getDeafultFilterSettings(requestPayload));
  }
  renderCustFilListCnt();
};
const filterUtilityHeader = ({ t, currentFilterObject, getFilterTitleDesc, filterTag, createNewFilterFlag,
  createNewFilter, userDefaultFilter, sharedFilterCount, selectedFilterType, loadDropdown }) => (
    <>
      <div className={panelHeaderSectionClass}>
        <h2 className={setHeaderClass(currentFilterObject)}>
          {hasFilterName(currentFilterObject) ? getFilterTitleDesc('title')
            : t(cmnLblKey.FILTER_LABEL_NEW)}
        </h2>
        {!currentFilterObject?.isSystemDefaultFilter && filterTag()}
        <div className={panelActionAreaClass}>
          <div>
            {hasFilterName(currentFilterObject) && !createNewFilterFlag && (
            <FasatActionBtn
              icon={(<Icon color="none" fill={colors.m_green} manulifeName="plus" width="20px" height="20px" />)}
              hoverIcon={(
                <Icon color="none" fill={colors.dark_2_green} manulifeName="plus" width="20px" height="20px" />)}
              onClick={() => createNewFilter()}
            >
              {t(cmnLblKey.FILTER_LABEL_CREATE_NEW)}
            </FasatActionBtn>
            )}
          </div>
          <div className={loadFilterClass}>
            <FasatLabel isContent htmlFor={loadLabelFor}>
              {t(cmnLblKey.FILTER_LABEL_MANAGEMENT)}
            </FasatLabel>
            <FasatDropdown
              dropdownItems={generateOptions({ t, userDefaultFilter, sharedFilterCount })}
              id={loadDropdownID}
              placeholder={t(staticCommonLabelKeys.COMMON_PLACEHOLDER_SELECT)}
              value={selectedFilterType}
              onHandleChange={(loadDropdownValue) => loadDropdown(loadDropdownValue)}
              label={t(cmnLblKey.FILTER_LABEL_MANAGEMENT)}
            />
          </div>
        </div>
      </div>
      <p className={filterSettingsPanelTextClass}>
        {hasFilterDescription({ currentFilterObject }) && getFilterTitleDesc('desc')}
        {!hasFilterDescription({ currentFilterObject })
              && t(cmnLblKey.FILTER_LABEL_HELP)}
      </p>
    </>
);
const filterColumnOrder = ({ t, availableColumns, columnOrderChange, selectedColumn, deselectColumnOrder }) => (
  <FasatExpandablePanel
    id={columnOrderPanelID}
    variant={EXPANDABLE_PANEL_VARIANT.SECONDARY}
    title={t(cmnLblKey.FILTER_LABEL_VISIBILITY)}
  >
    <p className={filterSubAccordionPanelTextClass}>{t(cmnLblKey.FILTER_LABEL_VISIBILITY_HELP)}</p>
    <FasatColumnOrder
      options={availableColumns}
      columnOrderChange={columnOrderChange}
      selectedOptions={selectedColumn}
      deselectColumnOrder={deselectColumnOrder}
    />
  </FasatExpandablePanel>
);
const srtOrd = ({ t, availableColumns, sortedColumn, removeSortedColumn, customColumnGroupBy, groupByActive,
  customColumnSorting, showDuplicateMessage }) => (
    <FasatExpandablePanel
      id={sortOrderPanelID}
      variant={EXPANDABLE_PANEL_VARIANT.SECONDARY}
      title={t(cmnLblKey.FILTER_LABEL_SORT_ORDER)}
    >
      <p className={filterSubAccordionPanelTextClass}>{t(cmnLblKey.FILTER_LABEL_SORT_ORDER_HELP)}</p>
      <SortOrder
        columns={availableColumns}
        sortedColumn={sortedColumn}
        removeSortedColumn={removeSortedColumn}
        customColumnOrder={customColumnSorting}
        showDuplicateMessage={showDuplicateMessage}
        customColumnGroupBy={customColumnGroupBy}
        groupByActive={groupByActive}
      />
    </FasatExpandablePanel>
);
const groupByYesNo = (t, groupByActive, setGroupByActive, setCustomColumnGroupBy,
  setCustomColumnSorting, setIsColumnOrderChange) => (
    <FasatButtonSelector
      values={[{ label: t(cmnLblKey.COMMON_BUTTON_YES), value: true },
        { label: t(cmnLblKey.COMMON_BUTTON_NO), value: false }]}
      customStyle={{ rootStyle: { width: '240px', paddingTop: '0px', paddingBottom: '12px' } }}
      onHandleChange={(val) => grpByIsActive(val, setGroupByActive, setCustomColumnGroupBy,
        setCustomColumnSorting, setIsColumnOrderChange)}
      id="grpby_option"
      name="groupby-option"
      value={groupByActive}
    />
);
const grpBy = ({ t, operatorList, availableColumns, lookupData, setCustomColumnSorting,
  groupByColumn, removeGroupByColumn, setIsColumnOrderChange,
  customColumnGroupBy, setCustomColumnGroupBy, showDuplicateMessage, groupByActive, setGroupByActive }) => (
    <FasatExpandablePanel
      id={`${groupByPanelID}-${uuidv4()}`}
      variant={EXPANDABLE_PANEL_VARIANT.SECONDARY}
      title={t(cmnLblKey.FILTER_LABEL_GROUP_BY)}
    >
      <p className={filterSubAccordionPanelTextClass}>{t(cmnLblKey.FILTER_LABEL_GROUP_BY_HELP)}</p>
      {groupByYesNo(t, groupByActive, setGroupByActive, setCustomColumnGroupBy,
        setCustomColumnSorting, setIsColumnOrderChange)}
      {operatorList && (
      <GroupBy
        columns={availableColumns}
        groupByColumn={groupByColumn}
        groupByActive={groupByActive}
        removeGroupByColumn={removeGroupByColumn}
        customColumnGroupBy={customColumnGroupBy}
        showDuplicateMessage={showDuplicateMessage}
        operatorList={operatorList}
        lookupData={lookupData}
      />
      )}
    </FasatExpandablePanel>
);
const distinctPanel = ({ t, groupByActive, isDistinct, setIsDistinct, setCustomColumnSorting }) => (
  <FasatExpandablePanel
    id={`${groupByPanelID}-${uuidv4()}`}
    variant={EXPANDABLE_PANEL_VARIANT.SECONDARY}
    title={t(cmnLblKey.FILTER_LABEL_DISTINCT)}
  >
    <p className={filterSubAccordionPanelTextClass}>{t(cmnLblKey.FILTER_LABEL_DISTINCT_HELP)}</p>
    <Distinct
      isGroupByActive={groupByActive}
      isDistinct={isDistinct}
      setIsDistinct={setIsDistinct}
      setCustomColumnSorting={setCustomColumnSorting}
    />
  </FasatExpandablePanel>
);
const filCriteria = ({ t, operatorList, availableColumns, lookupData, setfiterCriteria, freezeColumn,
  removeFilCriElmt, custFilCreteria, showDuplicateMessage, validateFilterCriteria }) => (
    <FasatExpandablePanel
      id={filterCriteriaPanelID}
      variant={EXPANDABLE_PANEL_VARIANT.SECONDARY}
      title={t(cmnLblKey.FILTER_LABEL_CRITERIA)}
      expandedByDefault
    >
      <p className={filterSubAccordionPanelTextClass}>{t(cmnLblKey.FILTER_LABEL_CRITERIA_HELP)}</p>
      {operatorList && (
      <FilterCriteria
        columns={availableColumns}
        operatorList={operatorList}
        lookupData={lookupData}
        onValueChange={(value) => setfiterCriteria(value)}
        removeFilterCriteriaElement={removeFilCriElmt}
        customFilterCreteriaElement={custFilCreteria}
        showDuplicateMessage={showDuplicateMessage}
        freezeColumn={freezeColumn}
        validateFilterCriteria={validateFilterCriteria}
      />
      )}
    </FasatExpandablePanel>
);
const filterPanel = ({ t, loadPreSelectedFilterConfig, resetPreSelectedFilterConfig, headFilObj,
  createNewFilter, userDefaultFilter, search, selectedFilterType, loadDropdown, lookupData,
  availableColumns, filCriteriaObj, freezeColumn, custFilCreteria, validateFilterCriteria,
  sortedColumn, groupByColumn, removeSortedColumn, removeGroupByColumn, customColumnSorting,
  customColumnGroupBy, setCustomColumnGroupBy, columnOrderChange, currentFilterObject,
  showDuplicateMessage, selectedColumn, deselectColumnOrder, loggedInUser, openSaveFilterModal,
  groupByActive, setGroupByActive, setCustomColumnSorting, setIsColumnOrderChange, entityFilterConfig,
  isDistinct, setIsDistinct }) => (
    <FasatExpandablePanel
      id={filterExpandablePanelID}
      title={t(cmnLblKey.FILTER_LABEL_SETTING)}
      expandedByDefault={!loadPreSelectedFilterConfig}
      onToggle={(val) => resetFilter(val, resetPreSelectedFilterConfig)}
    >
      {filterUtilityHeader({ ...headFilObj, createNewFilter, userDefaultFilter, selectedFilterType, loadDropdown })}
      <div className={filterSettingsPanelLevel2Class}>
        {filCriteria({ ...filCriteriaObj, t, lookupData, freezeColumn, custFilCreteria, validateFilterCriteria })}
        {entityFilterConfig.IS_GROUPBY && (grpBy({ ...filCriteriaObj,
          t,
          availableColumns,
          groupByColumn,
          removeGroupByColumn,
          customColumnGroupBy,
          setCustomColumnGroupBy,
          showDuplicateMessage,
          groupByActive,
          setGroupByActive,
          setCustomColumnSorting,
          setIsColumnOrderChange }))}
        {entityFilterConfig.IS_DISTINCT
         && distinctPanel({ t, groupByActive, isDistinct, setIsDistinct, setCustomColumnSorting })}
        {srtOrd({ t,
          availableColumns,
          sortedColumn,
          groupByActive,
          removeSortedColumn,
          customColumnSorting,
          showDuplicateMessage,
          customColumnGroupBy })}
        {!groupByActive
         && filterColumnOrder({ t, availableColumns, columnOrderChange, selectedColumn, deselectColumnOrder })}
      </div>
      <div className={panelFooterActionContainerClass}>
        {returnSaveButton({ t, currentFilterObject, loggedInUser, openSaveFilterModal })}
        <FasatButton id="searchButton" onClick={search}>{t(cmnLblKey.COMMON_BUTTON_SEARCH)}</FasatButton>
      </div>
    </FasatExpandablePanel>
);
const FasatFilterSetting = ({ availableColumns, entityId, customPageSize, lookupData, entityFilterConfig,
  triggerActivateDataGrid, screenName, loadPreSelectedFilterConfig, freezeColumn, paramForFreezeColumn,
  resetPreSelectedFilterConfig, listActive, activateDataGrid, defaultNewCustomFilter, onSearchClick, isExtSrceen }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const locale = getCurrentLocale();
  const parameters = [{ key: 'entityId', operator: 'OPT_EQUALS', type: 'number', values: [entityId] }];
  const [columnOrder, setColumnOrder] = useState([]);
  const [isColumnOrderChange, setIsColumnOrderChange] = useState(true);
  const [selectedFilterType, setSelectedFilterType] = useState(SYSDEFAULT);
  const [customColumnSorting, setCustomColumnSorting] = useState([]);
  const [customColumnGroupBy, setCustomColumnGroupBy] = useState([]);
  const [isDistinct, setIsDistinct] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [groupByActive, setGroupByActive] = useState(false);
  const [validateFilterCriteria, setValidateFilterCriteria] = useState(0);
  const [showCustFilModal, setshowCustFilModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isSystemDefaultFilter, setIsSysDefaultFilter] = useState(true);
  const [userDefaultFilterID, setUsrDfltFltID] = useState(null);
  const [filterLoadCount, setFltLoadCnt] = useState(0);
  const [selectedSortOptions, setSelectedSortOptions] = useState(null);
  const [selectedGroupByOption, setSelectedGroupByOption] = useState([]);
  const [selectedParametOptions, setSelectedParametOptions] = useState(null);
  const [selectedColumn, setSelectedColumn1] = useState([]);
  const setSelectedColumn = (cols) => {
    // Created refrence before filtering the available fields to maintain the impact of call by reference
    const selectedColsArr = cols;
    const availableSelectedColumn = cols.filter((colum) => availableColumns.findIndex((availCol) => availCol.value === colum) >= 0);
    selectedColsArr.length = 0;
    selectedColsArr.push(...availableSelectedColumn);
    setSelectedColumn1(selectedColsArr);
  };
  const [currentFilterObject, setCurrentFilterObject] = useState(null);
  const [saveNewFilter, setSaveNewFilter] = useState(true);
  const [deselectColumnOrder, setDeselectColumnOrder] = useState(false);
  const [createNewFilterFlag, setCreateNewFilterFlag] = useState(false);
  const [custFilCreteria, setCustFilCreteria] = useState([]);
  const [displayDublicateSel, setdisplayDublicateSel] = useState(false);
  const [displayWarningMessage, setDisplayWarningMessage] = useState(false);
  const [displayBetWarningMsg, setDisplayBetWarningMsg] = useState(false);
  const [canceldisplayModal, setCancelDisplayModal] = useState(false);
  const reqObjFilter = initialReqObject({ locale, sortFilterList, parameters });
  const operatorList = useSelector((state) => state.ApplicationStateFilterList.operatorList);
  const sharedFilterCount = useSelector((state) => state.ApplicationStateFilterList.filterListCount);
  const selectedFilter = useSelector((state) => state[entityFilterConfig.REDUX_STATE].selectedFilter);
  const systemDefaultFilter = useSelector((state) => state[entityFilterConfig.REDUX_STATE].systemDefaultFilter);
  const userDefaultFilter = useSelector((state) => state[entityFilterConfig.REDUX_STATE].userDefaultFilter);
  const listSortOptions = useSelector((state) => state[entityFilterConfig.REDUX_STATE].listSortOptions);
  const listGroupBy = useSelector((state) => state[entityFilterConfig.REDUX_STATE].listGroupBy);
  const isDistinctActive = useSelector((state) => state[entityFilterConfig.REDUX_STATE].isDistinctActive);
  const listParamOptions = useSelector((state) => state[entityFilterConfig.REDUX_STATE].listParamOptions);
  const listColumnOrder = useSelector((state) => state[entityFilterConfig.REDUX_STATE].listColumnOrder);
  const loggedUser = useSelector(({ ApplicationStateRBAC }) => ApplicationStateRBAC.loggedUser);
  const gridData = useSelector((state) => state[entityFilterConfig.REDUX_STATE].listData);
  const dublicateSelection = t(cmnLblKey.FILTER_DUPLICATE_COL_SELECTED_MSG).split('.');
  const [createNewCustomFilter, setCreateNewCustomFilter] = useState(!!defaultNewCustomFilter);
  const loggedInUser = setLoggedInUser(loggedUser);
  const validateAndConfigureFilter = (filterData, isSYSDefault) => {
    if (!loadPreSelectedFilterConfig) {
      const configParamas = { setSelectedParametOptions, listParamOptions };
      selectedFilFun({ filterData,
        setSelectedColumn,
        setSelectedGroupByOption,
        setSelectedSortOptions,
        setSelectedParametOptions,
        setGroupByActive,
        setIsDistinct,
        isSYSDefault,
        ...configParamas });
    } else {
      const configParamas = { setSelectedParametOptions, listParamOptions };
      configFil({ listColumnOrder,
        setSelectedColumn,
        listGroupBy,
        setSelectedGroupByOption,
        isDistinctActive,
        setIsDistinct,
        listSortOptions,
        setSelectedSortOptions,
        ...configParamas });
    }
  };
  const configureDefaultFilter = (filterData) => setFilterType({ filterData, setSelectedFilterType });
  const setFilterData = (filterData, isSYSDefault) => {
    const setFilObjA = { filterData, setDeselectColumnOrder, setCurrentFilterObject, filterLoadCount, setUsrDfltFltID };
    const setFilObjB = { configureDefaultFilter, setSelectedFilterType, validateAndConfigureFilter, isSYSDefault };
    filterDataFuc({ ...setFilObjA, ...setFilObjB, setFltLoadCnt, setIsSysDefaultFilter });
  };
  const renderCustFilListCnt = () => dispatch(getCustomFilterList({ reqObjFilter, listCount: true }));
  useEffect(() => {
    const renderObj = { loadPreSelectedFilterConfig, entityFilterConfig, entityId, loggedInUser };
    resetFilterOnEntityUpdate({ ...renderObj, dispatch, renderCustFilListCnt });
  }, [entityId]);
  useEffect(() => {
    if (operatorList === null) {
      dispatch(getOperatorList());
    }
  }, [operatorList]);
  useEffect(() => animateFilterComponent(gridData, activateDataGrid), [gridData]);
  useEffect(() => {
    dispatch(setRoleList(null));
    dispatch(getRolesList());
  }, [operatorList]);
  useEffect(() => setCustomColumnState({ selectedSortOptions, setCustomColumnSorting }), [selectedSortOptions]);
  useEffect(() => setCustomGroupbyState({ selectedGroupByOption,
    setCustomColumnGroupBy,
    setGroupByActive }), [selectedGroupByOption]);
  useEffect(() => setColumnOrder(selectedColumn), [selectedColumn]);
  useEffect(() => configSelColumn({ isColumnOrderChange,
    setSelectedColumn,
    systemDefaultFilter }), [isColumnOrderChange]);
  const formatFilterObject = (filterData) => {
    configureStoreFilObj({ entityFilterConfig, filterData, dispatch, loggedInUser });
  };
  /**
    * Set User Selecetd Filter from the List
    * @param {object} filterData
  */
  const loadSelectedFilter = (filterData, modalCLose) => {
    setCreateNewCustomFilter(false);
    const loadFilObjA = { setCreateNewFilterFlag, modalCLose, setshowCustFilModal, showCustFilModal, dispatch };
    operateLoadFilter({ ...loadFilObjA, filterData, userDefaultFilter, entityFilterConfig, setIsSysDefaultFilter });
  };
  /**
   * format custom Parameter options as per selected Filter
   */
  useEffect(() => configureCustFilCrt({ selectedParametOptions, setCustFilCreteria }), [selectedParametOptions]);
  /**
        * Dispatch Serch Action
        */
  const triggerSearch = () => {
    triggerActivateDataGrid();
    const formatObjA = { dispatch,
      custFilCreteria,
      freezeColumn,
      columnOrder,
      createNewFilterFlag,
      systemDefaultFilter,
      isColumnOrderChange };
    const formatObjB = { locale, currentFilterObject, customPageSize, entityFilterConfig, formatFilterObject };
    formatAndDispatchSearch({ ...formatObjA,
      ...formatObjB,
      customColumnSorting,
      customColumnGroupBy,
      paramForFreezeColumn,
      listActive,
      groupByActive,
      isDistinct,
      isExtSrceen });
  };
  const search = () => {
    const searchParams = { displayWarningMessage, setValidateFilterCriteria, triggerSearch, setDisplayWarningMessage };
    searchFunc({ columnOrder, custFilCreteria, validateFilterCriteria, ...searchParams, setDisplayBetWarningMsg, displayBetWarningMsg });
    onSearchClick();
  };
  const columnOrderChange = (selectedOption) => {
    setColumnOrder(selectedOption);
    setIsColumnOrderChange(true);
  };
  const sortedColumn = (value) => configureSortColumn({ value, customColumnSorting, setCustomColumnSorting });
  const groupByColumn = (value) => configureGroupByColumn({ value, customColumnGroupBy, setCustomColumnGroupBy });
  const setfiterCriteria = (value) => filterCriteriaFunc({ custFilCreteria, setCustFilCreteria, value });
  /**
       * Reset Configuration for new filter Settings
       */
  const createNewFilter = (defaultOptions = null) => {
    setDeselectColumnOrder(true);
    scrollListBoxToTopPosition();
    setCurrentFilterObject({ entityId });
    const columns = [];
    availableColumns.map(({ value }) => columns.push(value));
    setSelectedColumn(columns);
    setSelectedSortOptions(systemDefaultFilter?.detail?.sortOptions);
    setSelectedParametOptions(defaultOptions ? defaultOptions.parameters : null);
    setSelectedGroupByOption([]);
    setIsSysDefaultFilter(false);
    initialAccordionState();
    setCreateNewFilterFlag(true);
  };
  useEffect(() => {
    if (createNewCustomFilter) {
      createNewFilter(defaultNewCustomFilter);
    } else {
      selectedFilIn({ setDeselectColumnOrder, setFilterData, selectedFilter, systemDefaultFilter, sharedFilterCount });
    }
  }, [selectedFilter, sharedFilterCount]);
  /**
       * renove object form the configure sorted column order
       * @param {object} value
       */
  const removeSortedColumn = (value) => {
    const filterOutColumn = customColumnSorting.filter((x) => x.uuid !== value.uuid);
    setCustomColumnSorting(filterOutColumn);
  };
  const removeGroupByColumn = (value) => {
    const filterOutColumn = customColumnGroupBy.filter((x) => x.uuid !== value.uuid);
    setCustomColumnGroupBy(filterOutColumn);
  };
  /**
       * On Save and Save As Click Handle format form data
       * @param {string} action
       */
  const openSaveFilterModal = (action) => {
    let saveTrigger = true;
    if (custFilCreteria) {
      const filterValidate = operatorBtwnValidate(custFilCreteria);
      if (filterValidate.find((idx) => optionArray.includes(idx.operator)) !== undefined) {
        setDisplayBetWarningMsg(!displayBetWarningMsg);
        saveTrigger = false;
      } else {
        setValidateFilterCriteria(0);
      }
    }
    if (saveTrigger) {
      const saveObjA = { dispatch,
        action,
        customColumnGroupBy,
        isDistinct,
        customColumnSorting,
        loggedInUser,
        displayModal,
        setSaveNewFilter };
      const saveObjB = { custFilCreteria, columnOrder, entityId, setFormData, currentFilterObject, setDisplayModal };
      configureFormData({ ...saveObjA, ...saveObjB });
    }
  };
  /**
       * Valiadet and display dropdown
       * @param {sting} val
       */
  const loadDropdown = (val) => {
    const dDObjA = { setCreateNewFilterFlag, setshowCustFilModal, showCustFilModal, setDeselectColumnOrder };
    const dDObjB = { val, userDefaultFilter, setFilterData, systemDefaultFilter };
    setDropdownItem({ ...dDObjA, ...dDObjB });
    initialAccordionState();
    scrollListBoxToTopPosition();
  };
  /**
       * remove object form the configure filter creteria
       * @param {object} value
       */
  const removeFilCriElmt = (value) => {
    const filterOutElement = custFilCreteria.filter((x) => x.uuid !== value);
    setCustFilCreteria(filterOutElement);
  };
  /**
       * Show duplicate Selection Modal Message
       */
  const showDuplicateMessage = () => setdisplayDublicateSel(!displayDublicateSel);
  /**
       * Update default filter ID
       * @param {string} defaultFilterID
       * @returns
       */
  const updateDefaultFilterID = (filID) => {
    setUsrDfltFltID(filID);
    setFltLoadCnt(1);
    setIsSysDefaultFilter(false);
  };
  const filterTag = () => fasatChipFunc({ currentFilterObject, t, userDefaultFilterID, loggedInUser });
  /**
       * Function to return Filter description
       * @returns filter description
       */
  const getFilterTitleDesc = (label) => filterDescFunc({ t, currentFilterObject, label, createNewFilterFlag });
  /**
       * detect recent deleted filter and load the respecpective filter in filter setting
       * @param {string} deletedFilterID
       */
  const triggerFilterListCount = (deletedFilterID) => {
    const paramsObjA = { deletedFilterID, currentFilterObject, loadSelectedFilter, setUsrDfltFltID, dispatch };
    const paramsObjB = { userDefaultFilterID, systemDefaultFilter, entityFilterConfig, userDefaultFilter };
    loadAptFilter({ ...paramsObjA, ...paramsObjB });
    renderCustFilListCnt();
  };
  const onCancelClickCancel = () => {
    setCancelDisplayModal(!canceldisplayModal);
    if (document.getElementsByClassName('saveFilterModal')) {
      document.querySelector('.saveFilterModal').parentElement.style.zIndex = '99996';
    }
  };
  const onClickDiscard = () => {
    dispatch(checkDirtyData(false));
    setDisplayModal(!displayModal);
  };
  const resetNewFilFlg = () => setCreateNewFilterFlag(false);
  const saveFilObjA = { isSystemDefaultFilter, userDefaultFilterID, renderCustFilListCnt, resetNewFilFlg };
  const saveFilObjB = { formData, entityFilterConfig, screenName, entityId, sharedFilterCount, updateDefaultFilterID };
  const headFilObj = { t, getFilterTitleDesc, filterTag, createNewFilterFlag, sharedFilterCount, currentFilterObject };
  const filCriteriaObj = { setfiterCriteria, removeFilCriElmt, showDuplicateMessage, availableColumns, operatorList };
  return (
    <div className={filterSettingsPanelClass}>
      {filterPanel({ t,
        loadPreSelectedFilterConfig,
        resetPreSelectedFilterConfig,
        headFilObj,
        createNewFilter,
        userDefaultFilter,
        search,
        selectedFilterType,
        loadDropdown,
        lookupData,
        availableColumns,
        filCriteriaObj,
        freezeColumn,
        custFilCreteria,
        validateFilterCriteria,
        sortedColumn,
        groupByColumn,
        removeSortedColumn,
        removeGroupByColumn,
        customColumnSorting,
        customColumnGroupBy,
        setCustomColumnGroupBy,
        columnOrderChange,
        showDuplicateMessage,
        selectedColumn,
        deselectColumnOrder,
        loggedInUser,
        currentFilterObject,
        openSaveFilterModal,
        groupByActive,
        setGroupByActive,
        setCustomColumnSorting,
        setIsColumnOrderChange,
        entityFilterConfig,
        isDistinct,
        setIsDistinct,
      })}
      <FasatModal
        title={saveNewFilter ? t(cmnLblKey.FILTER_LABEL_SAVE_AS) : t(cmnLblKey.FILTER_LABEL_SAVE)}
        display={displayModal}
        modalWidth={modalWidth}
        primaryButtonText={t(cmnLblKey.COMMON_BUTTON_SAVE)}
        primaryTopic="ADD_FILTER_SAVE"
        secondaryButtonText={t(cmnLblKey.COMMON_BUTTON_CANCEL)}
        secondaryTopic="ADD_FILTER_CANCEL"
        openModal="OPEN_ADD_FILTER_MODAL"
        modalClass="saveFilterModal"
        modalCosed={() => setDisplayModal(false)}
        isCheckDirty="yes"
        onCancelClickCancel={() => onCancelClickCancel()}
      >
        {formData && (loadSaveModal({ ...saveFilObjA, ...saveFilObjB }))}
        {getCancelModal({ canceldisplayModal, onCancelClickCancel, onClickDiscard, t })}
      </FasatModal>
      {custFilList({ t, showCustFilModal, setshowCustFilModal, loadSelectedFilter, triggerFilterListCount, entityId })}
      {modalDublicateSelection({ t, displayDublicateSel, setdisplayDublicateSel, dublicateSelection })}
      {getDuplicateModal({ t, setDisplayWarningMessage, displayWarningMessage })}
      {stringBlankModal({ t, setDisplayBetWarningMsg, displayBetWarningMsg })}
    </div>
  );
};
FasatFilterSetting.propTypes = propTypes;
FasatFilterSetting.defaultProps = defaultProps;
export default FasatFilterSetting;
