/**
 * @Description FasatColumnOrder Tooltip Text File
 * @FileName defalut.js
 * @Author ABHISEK KUNDU-kundabh
 * @CreatedOn 22 March, 2021 02:59:30
 * @IssueID 673
 */
import { staticCommonLabelKeys } from '../../../../../moduleConstants';

export default {
  availableFilterHeader: 'Filter available',
  availableHeader: staticCommonLabelKeys.FILTER_LABEL_AVAIL_COLUMNS,
  moveLeft: '',
  moveAllLeft: 'Move all left',
  moveRight: '',
  moveAllRight: 'Move all right',
  moveDown: '',
  moveUp: '',
  noAvailableOptions: 'No available options',
  noSelectedOptions: 'No selected options',
  selectedFilterHeader: 'Filter selected',
  selectedHeader: staticCommonLabelKeys.FILTER_LABEL_SELECTED_COLUMNS,
  moveTop: '',
  moveBottom: '',
};
