/**
 * @Description Reducer for template adjustments filter
 * @FileName templateAdjustmentsFilter.js
 * @Author Imroz Dawar - dawarim
 * @CreatedOn 25 February, 2022 04:15:36
 * @IssueID - Issue IDs
 */

import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { tempAdjActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'TemplateAdjustmentsFilterList';

const updateFilSetStore = (state, action) => ({
  ...state,
  selectedFilter: action.data.selectedItem || null,
  systemDefaultFilter: action.data.systemDefault || null,
  userDefaultFilter: action.data.userDefault || null,
});

const updateFilterConfig = (state, action) => {
  if (action.data && action.data.systemDefault) {
    return updateFilSetStore(state, action);
  }
  if (
    (action.data && action.data.userDefault === null)
    || (action.data && action.data.userDefault)
  ) {
    return {
      ...state,
      userDefaultFilter: action.data.userDefault,
      selectedFilter: action.data.selectedItem || null,
    };
  }
  return {
    ...state,
    selectedFilter: (action.data && action.data.selectedItem) || null,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});

const logTempAdjListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const formatListData = (listData) => {
  listData.data.map((item) => {
    const clone = item;
    clone.isDefaultCheck = item.wadStat === 'APP';
    return clone;
  });
  return listData;
};

const TemplateAdjustmentState1 = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case tempAdjActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case tempAdjActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case tempAdjActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case tempAdjActionTypes.LOG_TEMPADJ_LIST_REQUEST:
      return logTempAdjListRequest(state, action);
    case tempAdjActionTypes.GET_TEMPADJ_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    default:
      return null;
  }
};
const TemplateAdjustmentState2 = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case tempAdjActionTypes.GET_TEMPADJ_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case tempAdjActionTypes.GET_TEMPADJ_LIST_RESPONSE:
      return {
        ...state,
        listData: formatListData(action.data),
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };

    case tempAdjActionTypes.RESET_TEMPADJ_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case tempAdjActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case tempAdjActionTypes.TOGGLE_LIST_DELETE_BUTTON:
      return {
        ...state,
        enableDelete: action.data.enable,
      };
    case tempAdjActionTypes.TOGGLE_LIST_APPROVE_BUTTON:
      return {
        ...state,
        enableApprove: action.data.enable,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const TemplateAdjustmentsFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const state1 = TemplateAdjustmentState1(state, action);
  const state2 = TemplateAdjustmentState2(state, action);
  if (state1 !== null) {
    return state1;
  }
  if (state2 !== null) {
    return state2;
  }
  return state;
};

export default TemplateAdjustmentsFilterList;
