/**
 * @Description File description.
 * @FileName french.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 19 February, 2021 00:36:44
 * @IssueID - Issue IDs
*/

const french = {
  /**
   * Contents for Grid Footer Bar
   */
  'showing.label': 'F : Showing',
  'of.label': 'F : of',

  /**
   * Content for FILTER SETTINGS
   */
  'manage.custom.filter.label': 'F: Manage custom filters',
  'filter.settings.title': 'F: Filter setting',
  'new.filter.title': 'F: New filter',
  'system.default.filter.title': 'F: System default',
  'create.new.filter.label': 'F: Create new filter',
  'load.label': 'F: Manage filter',
  'filter.criteria.panel.title': 'F: Filter criteria',
  'sort.order.panel.title': 'F: Sort order',
  'sort.order.panel.text': 'F: Select the columns you want to apply the sorting order',
  'group.by.panel.title': 'F: Group by',
  'group.by.panel.text': 'Group by permet de définir les opérateurs pour regrouper les'
  + 'valeurs que vous souhaitez rechercher',
  'column.order.panel.title': 'F: Column order and visibility',
  'column.order.panel.text': 'F: Choose and arrange the columns as desired',
  'search.label': 'F: Search',
  'column.label': 'F: Column',
  'operator.label': 'F: Operator',
  'value.label': 'F: Value',
  'sort.order.label': 'F: Sort order',
  'group.by.label': 'F: Group by',
  'information.title': 'F: Duplicate selection',
  'save.label': 'F: Save',
  'save.as.label': 'F: Save as',
  'save.as.default.button.label': 'F: Yes, make default',
  'no.button.label': 'F: No',
  'GENERIC.NOTIFICATION': 'Une erreur de système est survenue. Veuillez réessayer plus tard. Si le problème persiste,'
  + ' envoyez-nous un courriel à l’adresse'
  + ' <span> <a style="color: green" href="mailto:DTSC@manulife.ca">DTSC@manulife.ca</a></span> ou'
  + ' appelez le service de soutien au 1 800 667-4266.',
  'ERROR.LABEL': 'Renseignements importants',
  'LABEL.LEGAL.ERR': 'Mentions juridiques',
  'LABEL.ACCESSIBILITY.ERR': 'Accessibilité',
  'LABEL.PRIVACY.POLICY.ERR': 'Politique de confidentialité',
  'LS.FOOTER.TEXT.ERROR': '© La Compagnie d’Assurance-Vie Manufacturers, 1999-2022',
  'PD.ADD.ERROR': 'Errrrrr displayed',
};

export default french;
