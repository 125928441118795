/**
 * @Description This is FasatDatePicker component page.
 * @FileName fasatDatePicker.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 17 February, 2021 07:23:35
 * @IssueID CDTFASATMOD- 570
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@manulife/mux';
import './fasatDatePicker.scss';

const FasatDatePicker = (props) => {
  const { dateValue, errors, hideDefaultErrorBorder } = props;
  const [dateVal, seDateVal] = useState('');
  useEffect(() => {
    if (dateValue) {
      seDateVal(dateValue);
    } else {
      seDateVal('');
    }
  }, [dateValue]);
  const fasatInputErrorClass = 'withError';
  let className = 'fasatDatePicker';
  className += hideDefaultErrorBorder ? ' customDatePickerError' : '';
  return (
    <div className={`${className} ${
      errors.length > 0 && errors[0].isError ? fasatInputErrorClass : ''
    }`}
    >
      <DatePicker
          // value={value !== null ? value : ''}
        value={dateVal}
        {...props}
        customStyle={{
          rootStyle: {
            width: '100%',
          },
        }}
      />
    </div>
  );
};

FasatDatePicker.propTypes = {
  dateValue: PropTypes.string,
  errors: PropTypes.shape(),
  id: PropTypes.string,
  className: PropTypes.string,
  dateInvalidError: PropTypes.string,
  dateRangeError: PropTypes.string,
  hideDefaultErrorBorder: PropTypes.bool,
};
FasatDatePicker.defaultProps = {
  dateValue: null,
  errors: [],
  id: 'datePicker',
  className: '',
  dateInvalidError: '',
  dateRangeError: '',
  hideDefaultErrorBorder: false,
};

export default FasatDatePicker;
