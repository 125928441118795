export const reqInquiryAction = {
  LOG_REQINQ_LIST_REQUEST: 'LOG_REQINQ_LIST_REQUEST',
  GET_REQINQ_LIST_REQUEST: 'GET_REQINQ_LIST_REQUEST',
  GET_REQINQ_LIST_RESPONSE: 'GET_REQINQ_LIST_RESPONSE',
  GET_REQINQ_LIST_ERROR: 'GET_REQINQ_LIST_ERROR',
  GET_REQINQ_LIST_LOG_REQ: 'GET_REQINQ_LIST_LOG_REQ',
  UPDATE_REQINQ_COLUMN_CONFIG: 'UPDATE_REQINQ_COLUMN_CONFIG',
  UPDATE_REQINQ_FILTER_CONFIG: 'UPDATE_REQINQ_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_REQINQ_TABLE_CONFIGURATION',
  SET_REQINQ_DETAILS_RESPONSE: 'SET_REQINQ_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_REQINQ_USER_DEFAULT_FILTER: 'REMOVE_REQINQ_USER_DEFAULT_FILTER',
  // Reset store
  RESET_REQINQ_STORE: 'RESET_REQINQ_STORE',
  // Export List for BCR
  GET_REQINQ_EXPORT_REQUEST: 'GET_REQINQ_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
export const taskInquiryAction = {
  LOG_TASKINQ_LIST_REQUEST: 'LOG_TASKINQ_LIST_REQUEST',
  GET_TASKINQ_LIST_REQUEST: 'GET_TASKINQ_LIST_REQUEST',
  GET_TASKINQ_LIST_RESPONSE: 'GET_TASKINQ_LIST_RESPONSE',
  GET_TASKINQ_LIST_ERROR: 'GET_TASKINQ_LIST_ERROR',
  GET_TASKINQ_LIST_LOG_REQ: 'GET_TASKINQ_LIST_LOG_REQ',
  UPDATE_TASKINQ_COLUMN_CONFIG: 'UPDATE_TASKINQ_COLUMN_CONFIG',
  UPDATE_TASKINQ_FILTER_CONFIG: 'UPDATE_TASKINQ_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TASKINQ_TABLE_CONFIGURATION',
  SET_TASKINQ_DETAILS_RESPONSE: 'SET_TASKINQ_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_TASKINQ_USER_DEFAULT_FILTER: 'REMOVE_TASKINQ_USER_DEFAULT_FILTER',
  // Reset store
  RESET_TASKINQ_STORE: 'RESET_TASKINQ_STORE',
  // Export List for BCR
  GET_TASKINQ_EXPORT_REQUEST: 'GET_TASKINQ_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};

// mass agent transfer inquiry
export const massAgentTransferInqAction = {
  LOG_MATI_LIST_REQUEST: 'LOG_MATI_LIST_REQUEST',
  GET_MATI_LIST_REQUEST: 'GET_MATI_LIST_REQUEST',
  GET_MATI_LIST_RESPONSE: 'GET_MATI_LIST_RESPONSE',
  GET_MATI_LIST_ERROR: 'GET_MATI_LIST_ERROR',
  GET_MATI_LIST_LOG_REQ: 'GET_MATI_LIST_LOG_REQ',
  UPDATE_MATI_COLUMN_CONFIG: 'UPDATE_MATI_COLUMN_CONFIG',
  UPDATE_MATI_FILTER_CONFIG: 'UPDATE_MATI_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_MATI_TABLE_CONFIGURATION',
  SET_MATI_DETAILS_RESPONSE: 'SET_MATI_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_MATI_USER_DEFAULT_FILTER: 'REMOVE_MATI_USER_DEFAULT_FILTER',
  // Reset store
  RESET_MATI_STORE: 'RESET_MATI_STORE',
  // Export List for BCR
  GET_MATI_EXPORT_REQUEST: 'GET_MATI_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};

// payment controller movement
export const paymentControllerAction = {
  LOG_PCM_LIST_REQUEST: 'LOG_PCM_LIST_REQUEST',
  GET_PCM_LIST_REQUEST: 'GET_PCM_LIST_REQUEST',
  GET_PCM_LIST_RESPONSE: 'GET_PCM_LIST_RESPONSE',
  GET_PCM_LIST_ERROR: 'GET_PCM_LIST_ERROR',
  GET_PCM_LIST_LOG_REQ: 'GET_PCM_LIST_LOG_REQ',
  UPDATE_PCM_COLUMN_CONFIG: 'UPDATE_PCM_COLUMN_CONFIG',
  UPDATE_PCM_FILTER_CONFIG: 'UPDATE_PCM_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_PCM_TABLE_CONFIGURATION',
  SET_PCM_DETAILS_RESPONSE: 'SET_PCM_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_PCM_USER_DEFAULT_FILTER: 'REMOVE_PCM_USER_DEFAULT_FILTER',
  // Reset store
  RESET_PCM_STORE: 'RESET_PCM_STORE',
  // Export List for BCR
  GET_PCM_EXPORT_REQUEST: 'GET_PCM_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
  // Exceptions action types
export const eiAction = {
  LOG_EXI_LIST_REQUEST: 'LOG_EI_LIST_REQUEST',
  GET_EXI_LIST_REQUEST: 'GET_EI_LIST_REQUEST',
  GET_EXI_LIST_RESPONSE: 'GET_EI_LIST_RESPONSE',
  GET_EXI_LIST_ERROR: 'GET_EI_LIST_ERROR',
  GET_EXI_LIST_LOG_REQ: 'GET_EI_LIST_LOG_REQ',
  // Get Edit Iten by Id
  LOG_EXI_BY_ID_REQUEST: 'LOG_EI_BY_ID_REQUEST',
  GET_EXI_BY_ID_REQUEST: 'GET_EI_BY_ID_REQUEST',
  GET_EXI_BY_ID_RESPONSE: 'GET_EI_BY_ID_RESPONSE',
  GET_EXI_BY_ID_ERROR: 'GET_EI_BY_ID_ERROR',
  GET_EXI_BY_ID_LOG_REQ: 'GET_EI_BY_ID_LOG_REQ',
  // Get business card rule detail
  GET_EXI_REQUEST: 'GET_EI_REQUEST',
  GET_EXI_RESPONSE: 'GET_EI_RESPONSE',
  GET_EXI_ERROR: 'GET_EI_ERROR',
  // Add/Update business card rule detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_EXI_REQUEST: 'UPSERT_EI_REQUEST',
  INSERT_EXI_REQUEST: 'INSERT_EI_REQUEST',
  INSERT_EXI_RESPONSE: 'INSERT_EI_RESPONSE',
  INSERT_EXI_ERROR: 'INSERT_EI_ERROR',
  UPDATE_EXI_REQUEST: 'UPDATE_EI_REQUEST',
  UPDATE_EXI_RESPONSE: 'UPDATE_EI_RESPONSE',
  UPDATE_EXI_ERROR: 'UPDATE_EI_ERROR',
  UPDATE_EXI_COLUMN_CONFIG: 'UPDATE_EI_COLUMN_CONFIG',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  // Delete business card rule
  DELETE_EXI_REQUEST: 'DELETE_EI_REQUEST',
  DELETE_EXI_RESPONSE: 'DELETE_EI_RESPONSE',
  DELETE_EXI_ERROR: 'DELETE_EI_ERROR',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  // Detail business card rule
  SET_EXI_DETAILS_RESPONSE: 'SET_EI_DETAILS_RESPONSE',
  // Export List for BCR
  GET_EXI_EXPORT_REQUEST: 'GET_EI_EXPORT_REQUEST',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Reset store
  RESET_EXI_STORE: 'RESET_EI_STORE',
};

export const deInquiryAction = {
  LOG_DEINQ_LIST_REQUEST: 'LOG_DEINQ_LIST_REQUEST',
  GET_DEINQ_LIST_REQUEST: 'GET_DEINQ_LIST_REQUEST',
  GET_DEINQ_LIST_RESPONSE: 'GET_DEINQ_LIST_RESPONSE',
  GET_DEINQ_LIST_ERROR: 'GET_DEINQ_LIST_ERROR',
  UPDATE_DEINQ_COLUMN_CONFIG: 'UPDATE_DEINQ_COLUMN_CONFIG',
  UPDATE_DEINQ_FILTER_CONFIG: 'UPDATE_DEINQ_FILTER_CONFIG',
  UPDATE_DEINQ_TABLE_CONFIGURATION: 'UPDATE_DEINQ_TABLE_CONFIGURATION',
  SET_DEINQ_DETAILS_RESPONSE: 'SET_DEINQ_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_DEINQ_USER_DEFAULT_FILTER: 'REMOVE_DEINQ_USER_DEFAULT_FILTER',
  // Reset store
  RESET_DEINQ_STORE: 'RESET_DEINQ_STORE',
  // Export List for BCR
  GET_DEINQ_EXPORT_REQUEST: 'GET_DEINQ_EXPORT_REQUEST',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
