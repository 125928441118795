/**
 * @Description This is FasatConfirmationMsg container page
 * @FileName fasatConfirmationMsg.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 12 February, 2021 01:06:05
 * @IssueID 325
 */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../../index.scss';
import './fasatConfirmationMsg.scss';

const FasatConfirmationMsg = ({ msg, msgInGreen }) => (
  <div className="fasatConfirmMsg f-align-left" aria-live="polite" data-testid="FasatConfirmationMsgComponent">
    {msg}
    {' '}
    <span className="msgInGreen">{msgInGreen}</span>
  </div>
);

FasatConfirmationMsg.propTypes = {
  msg: PropTypes.string,
  msgInGreen: PropTypes.string,
};

FasatConfirmationMsg.defaultProps = {
  msg: '',
  msgInGreen: null,
};

export default FasatConfirmationMsg;
