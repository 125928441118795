import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { dataDictActionType } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'DataDictionaryFilterList';

const resetServerValue = (state, data) => ({
  ...state,
  serverRequestError: data && data.serverRequestError,
  loading: false,
});

const getListRequest = ({ data, state }) => (data || state.listRequest);
const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const DataDictionaryFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  const { data } = action;
  switch (action.type) {
    // config list column configuration
    case dataDictActionType.UPDATE_DD_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case dataDictActionType.UPDATE_DD_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: data.sortOptions,
        listColumnOrder: data.columnOrder,
      };
    // log request
    case dataDictActionType.LOG_DD_LIST_REQUEST:
      return {
        ...state,
        listRequest: getListRequest({ data, state }),
        listRequestedOn: new Date(),
        loading: true,
      };
    case dataDictActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case dataDictActionType.REMOVE_DD_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    // Reset store
    case dataDictActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, data);
    default:
      return null;
  }
};

const DataDictionaryFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  const { data } = action;
  switch (action.type) {
    case dataDictActionType.LOG_DD_LIST_REQUEST:
      return {
        ...state,
        listRequest: getListRequest({ data, state }),
        listRequestedOn: new Date(),
        loading: true,
      };
    // log error
    case dataDictActionType.GET_DD_LIST_ERROR:
      return {
        ...state,
        listRequestError: data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case dataDictActionType.GET_DD_LIST_RESPONSE:
      return {
        ...state,
        listData: data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case dataDictActionType.GET_DD_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case dataDictActionType.RESET_DD_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const DataDictionaryFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = DataDictionaryFilterListConfig(state, action);
  const stateReqRes = DataDictionaryFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default DataDictionaryFilterList;
