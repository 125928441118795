/**
 * @Description Action types for
 * @FileName actionTypes.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 28 January, 2021 23:55:21
 * @IssueID - Issue IDs
 */

import { fieldForceDeductionActions, fieldForceRelationshipActions } from './fieldForceDeductionActions';
import {
  reqInquiryAction,
  taskInquiryAction,
  massAgentTransferInqAction,
  paymentControllerAction,
  eiAction,
  deInquiryAction,
} from './inquiryActionTypes';

export const reqInquiryActionTypes = reqInquiryAction;
export const taskInquiryActionTypes = taskInquiryAction;
export const massAgentTransferInqActionTypes = massAgentTransferInqAction;
export const paymentControllerActionTypes = paymentControllerAction;
export const eiActionTypes = eiAction;
export const deInquiryActionTypes = deInquiryAction;
// BCR action types
export const bcrActionTypes = {
  LOG_BCR_LIST_REQUEST: 'LOG_BCR_LIST_REQUEST',
  GET_BCR_LIST_REQUEST: 'GET_BCR_LIST_REQUEST',
  GET_BCR_LIST_RESPONSE: 'GET_BCR_LIST_RESPONSE',
  GET_BCR_LIST_ERROR: 'GET_BCR_LIST_ERROR',
  GET_BCR_LIST_LOG_REQ: 'GET_BCR_LIST_LOG_REQ',
  // Get Edit Iten by Id
  LOG_BCR_BY_ID_REQUEST: 'LOG_BCR_BY_ID_REQUEST',
  GET_BCR_BY_ID_REQUEST: 'GET_BCR_BY_ID_REQUEST',
  GET_BCR_BY_ID_RESPONSE: 'GET_BCR_BY_ID_RESPONSE',
  GET_BCR_BY_ID_ERROR: 'GET_BCR_BY_ID_ERROR',
  GET_BCR_BY_ID_LOG_REQ: 'GET_BCR_BY_ID_LOG_REQ',
  // Get business card rule detail
  GET_BC_REQUEST: 'GET_BC_REQUEST',
  GET_BC_RESPONSE: 'GET_BC_RESPONSE',
  GET_BC_ERROR: 'GET_BC_ERROR',
  // Add/Update business card rule detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_BCR_REQUEST: 'UPSERT_BCR_REQUEST',
  INSERT_BCR_REQUEST: 'INSERT_BCR_REQUEST',
  INSERT_BCR_RESPONSE: 'INSERT_BCR_RESPONSE',
  INSERT_BCR_ERROR: 'INSERT_BCR_ERROR',
  UPDATE_BCR_REQUEST: 'UPDATE_BCR_REQUEST',
  UPDATE_BCR_RESPONSE: 'UPDATE_BCR_RESPONSE',
  UPDATE_BCR_ERROR: 'UPDATE_BCR_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  // Delete business card rule
  DELETE_BCR_REQUEST: 'DELETE_BCR_REQUEST',
  DELETE_BCR_RESPONSE: 'DELETE_BCR_RESPONSE',
  DELETE_BCR_ERROR: 'DELETE_BCR_ERROR',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  // Detail business card rule
  SET_BCR_DETAILS_RESPONSE: 'SET_BCR_DETAILS_RESPONSE',
  // Export List for BCR
  GET_BCR_EXPORT_REQUEST: 'GET_BCR_EXPORT_REQUEST',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Reset store
  RESET_BCR_STORE: 'RESET_BCR_STORE',
};

// BCR detail action types
export const bcrDetailActionTypes = {
  LOG_BCR_DETAIL_REQUEST: 'LOG_BCR_DETAIL_REQUEST',
  GET_BCR_DETAIL_LIST_REQUEST: 'GET_BCR_DETAIL_LIST_REQUEST',
  GET_BCR_DETAIL_RESPONSE: 'GET_BCR_DETAIL_RESPONSE',
  GET_BCR_DETAIL_ERROR: 'GET_BCR_DETAIL_ERROR',
};

// template adjustments action types
export const tempAdjActionTypes = {
  LOG_TEMPADJ_LIST_REQUEST: 'LOG_TEMPADJ_LIST_REQUEST',
  GET_TEMPADJ_LIST_REQUEST: 'GET_TEMPADJ_LIST_REQUEST',
  GET_TEMPADJ_LIST_RESPONSE: 'GET_TEMPADJ_LIST_RESPONSE',
  GET_TEMPADJ_LIST_ERROR: 'GET_TEMPADJ_LIST_ERROR',
  GET_TEMPADJ_LIST_LOG_REQ: 'GET_TEMPADJ_LIST_LOG_REQ',
  // Get Edit Iten by Id
  LOG_TEMPADJ_BY_ID_REQUEST: 'LOG_TEMPADJ_BY_ID_REQUEST',
  GET_TEMPADJ_BY_ID_REQUEST: 'GET_TEMPADJ_BY_ID_REQUEST',
  GET_TEMPADJ_BY_ID_RESPONSE: 'GET_TEMPADJ_BY_ID_RESPONSE',
  GET_TEMPADJ_BY_ID_ERROR: 'GET_TEMPADJ_BY_ID_ERROR',
  GET_TEMPADJ_BY_ID_LOG_REQ: 'GET_TEMPADJ_BY_ID_LOG_REQ',
  // Get business card rule detail
  GET_BC_REQUEST: 'GET_BC_REQUEST',
  GET_BC_RESPONSE: 'GET_BC_RESPONSE',
  GET_BC_ERROR: 'GET_BC_ERROR',
  // Add/Update business card rule detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_TEMPADJ_REQUEST: 'UPSERT_TEMPADJ_REQUEST',
  INSERT_TEMPADJ_REQUEST: 'INSERT_TEMPADJ_REQUEST',
  INSERT_TEMPADJ_RESPONSE: 'INSERT_TEMPADJ_RESPONSE',
  INSERT_TEMPADJ_ERROR: 'INSERT_TEMPADJ_ERROR',
  UPDATE_TEMPADJ_REQUEST: 'UPDATE_TEMPADJ_REQUEST',
  UPDATE_TEMPADJ_RESPONSE: 'UPDATE_TEMPADJ_RESPONSE',
  UPDATE_TEMPADJ_ERROR: 'UPDATE_TEMPADJ_ERROR',
  RESET_DELETE_TEMPADJ_VALUE: 'RESET_TEMPADJ_DELETE_VALUE',
  RESET_APPROVE_TEMPADJ_VALUE: 'RESET_APPROVE_TEMPADJ_VALUE',
  RESET_IMPORT_TEMPADJ: 'RESET_IMPORT_TEMPADJ',
  LAST_IMPORT: 'LAST_IMPORT',
  // Delete business card rule
  DELETE_TEMPADJ_REQUEST: 'DELETE_TEMPADJ_REQUEST',
  DELETE_TEMPADJ_RESPONSE: 'DELETE_TEMPADJ_RESPONSE',
  DELETE_TEMPADJ_ERROR: 'DELETE_TEMPADJ_ERROR',
  APPROVE_TEMPADJ_REQUEST: 'APPROVE_TEMPADJ_REQUEST',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  UPDATE_TEMPADJ_COLUMN_CONFIG: 'UPDATE_TEMPADJ_COLUMN_CONFIG',
  // Detail business card rule
  SET_TEMPADJ_DETAILS_RESPONSE: 'SET_TEMPADJ_DETAILS_RESPONSE',
  // Export List for BCR
  GET_TEMPADJ_EXPORT_REQUEST: 'GET_TEMPADJ_EXPORT_REQUEST',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUETA',
  // Reset store
  RESET_TEMPADJ_STORE: 'RESET_TEMPADJ_STORE',
  // import TA
  IMPORT_TEMPADJ_REQUEST: 'IMPORT_TEMPADJ_REQUEST',
  IMPORT_TEMPADJ_RESPONSE: 'IMPORT_TEMPADJ_RESPONSE',
  IMPORT_TEMPADJ_ERROR: 'IMPORT_TEMPADJ_ERROR',
  RESET_DETAILDATA: 'RESET_DETAILDATA',
  //
  TOGGLE_LIST_APPROVE_BUTTON: 'ENABLE_TA_LIST_APPROVE',
  TOGGLE_LIST_DELETE_BUTTON: 'ENABLE_TA_LIST_DELETE',
};

// manual commission records action types
export const mcrDetailActionTypes = {
  LOG_MCR_LIST_REQUEST: 'LOG_MCR_LIST_REQUEST',
  GET_MCR_LIST_REQUEST: 'GET_MCR_LIST_REQUEST',
  GET_MCR_LIST_RESPONSE: 'GET_MCR_LIST_RESPONSE',
  GET_MCR_LIST_ERROR: 'GET_MCR_LIST_ERROR',
  GET_MCR_LIST_LOG_REQ: 'GET_MCR_LIST_LOG_REQ',
  // Get Edit Item by Id
  LOG_MCR_BY_ID_REQUEST: 'LOG_MCR_BY_ID_REQUEST',
  GET_MCR_BY_ID_REQUEST: 'GET_MCR_BY_ID_REQUEST',
  GET_MCR_BY_ID_RESPONSE: 'GET_MCR_BY_ID_RESPONSE',
  GET_MCR_BY_ID_ERROR: 'GET_MCR_BY_ID_ERROR',
  GET_MCR_BY_ID_LOG_REQ: 'GET_MCR_BY_ID_LOG_REQ',
  // Get manual commission records detail
  GET_BC_REQUEST: 'GET_BC_REQUEST',
  GET_BC_RESPONSE: 'GET_BC_RESPONSE',
  GET_BC_ERROR: 'GET_BC_ERROR',
  // Add/Update manual commission records detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_MCR_REQUEST: 'UPSERT_MCR_REQUEST',
  INSERT_MCR_REQUEST: 'INSERT_MCR_REQUEST',
  INSERT_MCR_RESPONSE: 'INSERT_MCR_RESPONSE',
  INSERT_MCR_ERROR: 'INSERT_MCR_ERROR',
  UPDATE_MCR_REQUEST: 'UPDATE_MCR_REQUEST',
  UPDATE_MCR_RESPONSE: 'UPDATE_MCR_RESPONSE',
  UPDATE_MCR_ERROR: 'UPDATE_MCR_ERROR',
  RESET_DELETE_MCR_VALUE: 'RESET_MCR_DELETE_VALUE',
  RESET_APPROVE_MCR_VALUE: 'RESET_APPROVE_MCR_VALUE',
  RESET_IMPORT_MCR: 'RESET_IMPORT_MCR',
  LAST_IMPORT: 'LAST_IMPORT',
  // Delete
  DELETE_MCR_REQUEST: 'DELETE_MCR_REQUEST',
  DELETE_MCR_RESPONSE: 'DELETE_MCR_RESPONSE',
  DELETE_MCR_ERROR: 'DELETE_MCR_ERROR',
  APPROVE_MCR_REQUEST: 'APPROVE_MCR_REQUEST',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  UPDATE_MCR_COLUMN_CONFIG: 'UPDATE_MCR_COLUMN_CONFIG',
  // Detail
  SET_MCR_DETAILS_RESPONSE: 'SET_MCR_DETAILS_RESPONSE',
  // Export List for MCR
  GET_MCR_EXPORT_REQUEST: 'GET_MCR_EXPORT_REQUEST',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUETA',
  // Reset store
  RESET_MCR_STORE: 'RESET_MCR_STORE',
  // import MCR
  IMPORT_MCR_REQUEST: 'IMPORT_MCR_REQUEST',
  IMPORT_MCR_RESPONSE: 'IMPORT_MCR_RESPONSE',
  IMPORT_MCR_ERROR: 'IMPORT_MCR_ERROR',
  RESET_DETAILDATA: 'RESET_DETAILDATA',
  //
  TOGGLE_LIST_APPROVE_BUTTON: 'ENABLE_MCR_LIST_APPROVE',
  TOGGLE_LIST_DELETE_BUTTON: 'ENABLE_MCR_LIST_DELETE',
  GET_MANUADJ_BY_ID_RESPONSE: 'GET_MANUADJ_BY_ID_RESPONSE',
  CHECK_REJ_USER: 'CHECK_REJ_USER',
  SET_CHCK_STAT_USR: 'SET_CHCK_STAT_USR',
};

// CLIENT SCREEN
export const clientActionsTypes = {
  LOG_COMCALSTRUCTURE_LIST_REQUEST: 'LOG_COMCALSTRUCTURE_LIST_REQUEST',
  GET_CLIENT_LIST_REQUEST: 'GET_CLIENT_LIST_REQUEST',
  GET_COMCALSTRUCTURE_LIST_RESPONSE: 'GET_COMCALSTRUCTURE_LIST_RESPONSE',
  GET_COMCALSTRUCTURE_LIST_ERROR: 'GET_COMCALSTRUCTURE_LIST_ERROR',
  GET_COMCALSTRUCTURE_LIST_LOG_REQ: 'GET_COMCALSTRUCTURE_LIST_LOG_REQ',
  LOG_CLIENT_LIST_REQUEST: 'LOG_CLIENT_LIST_REQUEST',
  // GET_BCR_LIST_REQUEST: 'GET_BCR_LIST_REQUEST',
  GET_CLIENT_STRUCTURE_LIST_RESPONSE: 'GET_CLIENT_STRUCTURE_LIST_RESPONSE',
  GET_CS_LIST_ERROR: 'GET_CS_LIST_ERROR',
  GET_CLIENT_BY_DETAILS_RESPONSE: 'GET_CLIENT_BY_DETAILS_RESPONSE',
  GET_CLIENT_LENGTH_RESPONSE: 'GET_CLIENT_LENGTH_RESPONSE',
  GET_CLIENT_EXSISTS_RESPONSE: 'GET_CLIENT_EXSISTS_RESPONSE',
  GET_CLIENT_EXSISTS_ERROR: 'GET_CLIENT_EXSISTS_ERROR',
  // GET_BCR_LIST_LOG_REQ: 'GET_BCR_LIST_LOG_REQ',
  // Get Edit Iten by Id
  // LOG_BCR_BY_ID_REQUEST: 'LOG_BCR_BY_ID_REQUEST',
  GET_CLIENT_BY_ID_REQUEST: 'GET_CLIENT_BY_ID_REQUEST',
  GET_CLIENT_BY_ID_RESPONSE: 'GET_CLIENT_BY_ID_RESPONSE',
  GET_CLIENT_DETAILS: 'GET_CLIENT_DETAILS',
  GET_CLIENT_LENGTH: 'GET_CLIENT_LENGTH',
  GET_CLIENT_RELATIONAL_TABLE: 'GET_CLIENT_RELATIONAL_TABLE',
  // GET_BCR_BY_ID_ERROR: 'GET_BCR_BY_ID_ERROR',
  // GET_BCR_BY_ID_LOG_REQ: 'GET_BCR_BY_ID_LOG_REQ',
  // Get business card rule detail
  // GET_BC_REQUEST: 'GET_BC_REQUEST',
  // GET_BC_RESPONSE: 'GET_BC_RESPONSE',
  // GET_BC_ERROR: 'GET_BC_ERROR',
  // Add/Update business card rule detail
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_CS_REQUEST: 'UPSERT_CS_REQUEST',
  INSERT_CLIENT_REQUEST: 'INSERT_CLIENT_REQUEST',
  // INSERT_BCR_RESPONSE: 'INSERT_BCR_RESPONSE',
  // INSERT_BCR_ERROR: 'INSERT_BCR_ERROR',
  UPDATE_CLIENT_REQUEST: 'UPDATE_CLIENT_REQUEST',
  // UPDATE_BCR_RESPONSE: 'UPDATE_BCR_RESPONSE',
  // UPDATE_BCR_ERROR: 'UPDATE_BCR_ERROR',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  // Delete business card rule
  DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST',
  // DELETE_BCR_RESPONSE: 'DELETE_BCR_RESPONSE',
  // DELETE_BCR_ERROR: 'DELETE_BCR_ERROR',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_CLIENT_TABLE_CONFIGURATION',
  // Detail business card rule
  SET_CLIENT_DETAILS_RESPONSE: 'SET_CLIENT_DETAILS_RESPONSE',
  // Export List for BCR
  GET_CS_EXPORT_REQUEST: 'GET_CLIENTS_EXPORT_REQUEST',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Reset store
  RESET_CLIENT_STORE: 'RESET_CLIENT_STORE',
  // by agent code
  GET_AGENT_NAME_BY_AGENT_CODE: 'GET_AGENT_NAME_BY_AGENT_CODE',
  GET_NEXT_AGENT_NAME_BY_AGENT_CODE: 'GET_NEXT_AGENT_NAME_BY_AGENT_CODE',
};

export const clientDetailActionTypes = {
  LOG_CS_DETAIL_REQUEST: 'LOG_CS_DETAIL_REQUEST',
  GET_CS_DETAIL_LIST_REQUEST: 'GET_CS_DETAIL_LIST_REQUEST',
  GET_CS_DETAIL_RESPONSE: 'GET_CS_DETAIL_RESPONSE',
  GET_CS_DETAIL_ERROR: 'GET_CS_DETAIL_ERROR',
  GET_CS_AGENT_NAME_RESPONSE: 'GET_CS_AGENT_NAME_RESPONSE',
  GET_NEXT_CS_AGENT_NAME_RESPONSE: 'GET_NEXT_CS_AGENT_NAME_RESPONSE',
};

export const fieldForcePaymentsActionTypes = {
  PAYMENTS_ON_LOAD_RESPONSE: 'FF_PAYMENTS_BY_ID_RESPONSE',
  PAYMENTS_UPDATE_DEFAULT_DATA: 'PAYMENTS_UPDATE_DEFAULT_DATA',
  PAYMENTS_ON_LOAD_REQUEST: 'FF_PAYMENTS_ON_LOAD_REQUEST',
  PAYMENTS_ADDRESS_DETAILS_REQUEST: 'PAYMENTS_ADDRESS_DETAILS_REQUEST',
  PAYMENTS_ADDRESS_DETAILS_RESPONSE: 'FF_PAYMENTS_ADDRESS_DETAILS_RESPONSE',
  PAYMENTS_PROCESS_RESPONSE: 'FF_PAYMENTS_PROCESS_RESPONSE',
  PAYMENTS_PROCESS_REQUEST: 'FF_PAYMENTS_PROCESS_REQUEST',
  RESET_SERVER_VALUE: 'FF_PAYMENTS_RESET_SERVER_VALUE',
  RESET_STORE: 'FF_PAYMENTS_RESET_STORE',
  PAYMENTS_JOB_REQUEST_RESPONSE: 'PAYMENTS_JOB_REQUEST_RESPONSE',
  PAYMENTS_JOB_REQUEST_ERROR: 'PAYMENTS_JOB_REQUEST_ERROR',
  RESET_JOB_REQUEST_RESPOSE: 'RESET_JOB_REQUEST_RESPOSE',
  PROCESS_VALUE: 'PROCESS_VALUE',
};

export const fieldForceActionTypes = {
  GET_FF_LOOKUP_REQUEST: 'GET_FF_LOOKUP_REQUEST',
  GET_FF_GENERAL_DETAIL_REQUEST: 'GET_FF_GENERAL_DETAIL_REQUEST',
  GET_FF_SEC_WEALTH_CODE: 'GET_FF_SEC_WEALTH_CODE',
  GET_FF_SEC_JOB_STATUS: 'GET_FF_SEC_JOB_STATUS',
  GET_FF_GEN_SEC_WEALTH_CODE_REQ: 'GET_FF_GEN_SEC_WEALTH_CODE_REQ',
  GET_FF_GEN_SEC_WEALTH_CODE_RES: 'GET_FF_GEN_SEC_WEALTH_CODE_RES',
  GET_FF_BY_ID_REQUEST: 'GET_FF_BY_ID_REQUEST',
  GET_FF_BY_ID_RESPONSE: 'GET_FF_BY_ID_RESPONSE',
  GET_FF_PAYEE_REQUEST: 'GET_FF_PAYEE_REQUEST',
  RESET_FF_PAYEE_NAME_RESPONSE: 'RESET_FF_PAYEE_NAME_RESPONSE',
  GET_FF_VALIDATE_STATUS_REQUEST: 'GET_FF_VALIDATE_STATUS_REQUEST',
  GET_FF_VALIDATE_STATUS_RESPONSE: 'GET_FF_VALIDATE_STATUS_RESPONSE',
  GET_FF_GST_STATUS_REQUEST: 'GET_FF_GST_STATUS_REQUEST',
  GET_FF_GST_STATUS_RESPONSE: 'GET_FF_GST_STATUS_RESPONSE',
  GET_FF_SYS_OPTION_REQUEST: 'GET_FF_SYS_OPTION_REQUEST',
  GET_FF_SYS_OPTION_RESPONSE: 'GET_FF_SYS_OPTION_RESPONSE',
  RESET_FF_VALIDATE_STATUS_RESPONSE: 'RESET_FF_VALIDATE_STATUS_RESPONSE',
  UPDATE_FF_REQUEST: 'UPDATE_FF_REQUEST',
  GET_JOB_STATUS: 'GET_JOB_STATUS',
  GET_FF_PAYEE_RESPONSE: 'GET_FF_PAYEE_RESPONSE',
  RESET_FF_PAYEE_RESPONSE: 'RESET_FF_PAYEE_RESPONSE',
  GET_FF_ORG_STRUCT_DETAIL: 'GET_FF_ORG_STRUCT_DETAIL',
  RESET_FF_ORG_STRUCT_DETAIL: 'RESET_FF_ORG_STRUCT_DETAIL',
  GET_FF_ORG_STRUCT_RESPONSE: 'GET_FF_ORG_STRUCT_RESPONSE',
  GET_FF_SEC_WEALTH_RESPONSE: 'GET_FF_SEC_WEALTH_RESPONSE',
  GET_FF_SEC_JOB_STATUS_RESPONSE: 'GET_FF_SEC_JOB_STATUS_RESPONSE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  SET_FF_DETAILS_RESPONSE: 'SET_FF_DETAILS_RESPONSE',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_DEDUCTION_PARAMS: 'RESET_DEDUCTION_PARAMS',
  RESET_DEDUCTION_PARAMS_COL: 'RESET_DEDUCTION_PARAMS_COL',
  RESET_DEDUCTION_PARAMS_SORT: 'RESET_DEDUCTION_PARAMS_SORT',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  GET_FF_DETAIL_RESPONSE: 'GET_FF_DETAIL_RESPONSE',
  RESET_FF_DETAIL_RESPONSE: 'RESET_FF_DETAIL_RESPONSE',
  RESET_FF_TAB_DETAIL_VALUE: 'RESET_FF_TAB_DETAIL_VALUE',
  RESET_FF_SUB_TAB_DETAIL_VALUE: 'RESET_FF_SUB_TAB_DETAIL_VALUE',
  LOG_FF_DETAIL_REQUEST: 'LOG_FF_DETAIL_REQUEST',
  RESET_FF_LOOKUPDATA: 'RESET_FF_LOOKUPDATA',
  GET_FF_DETAIL_ERROR: 'GET_FF_DETAIL_ERROR',
  GET_FF_CONCURRENCY_ERROR: 'GET_FF_CONCURRENCY_ERROR',
  RESET_FF_CONCURRENCY_ERROR: 'RESET_FF_CONCURRENCY_ERROR',
  GET_FF_SERVER_DATE_REQUEST: 'GET_FF_SERVER_DATE_REQUEST',
  GET_FF_SERVER_DATE_RESPONSE: 'GET_FF_SERVER_DATE_RESPONSE',
  RESET_FF_SERVER_DATE_RESPONSE: 'RESET_FF_SERVER_DATE_RESPONSE',
  RESET_FF_DETAIL_ERROR: 'RESET_FF_DETAIL_ERROR',
  GET_FF_LIST_REQUEST: 'GET_FF_LIST_REQUEST',
  GET_FF_HEADER_REQUEST: 'GET_FF_HEADER_REQUEST',
  UPDATE_FF_COLUMN_CONFIG: 'UPDATE_FF_COLUMN_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  GET_FF_EXPORT_REQUEST: 'GET_FF_EXPORT_REQUEST',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  RESET_SERVER_VALUE_ITEM: 'RESET_SERVER_VALUE_ITEM',
  LOG_FF_LIST_REQUEST: 'LOG_FF_LIST_REQUEST',
  GET_FF_LIST_RESPONSE: 'GET_FF_LIST_RESPONSE',
  GET_FF_LIST_ERROR: 'GET_FF_LIST_ERROR',
  RESET_FF_STORE: 'RESET_FF_STORE',
  GET_FF_DEDUCTION_LIST: 'GET_FF_DEDUCTION_LIST',
  GET_TASK_LOOKUP: 'GET_TASK_LOOKUP',
  RESET_FF_TASK_LOOKUPDATA: 'RESET_FF_TASK_LOOKUPDATA',
  VALIDATE_DETAILS_CHANGE: 'VALIDATE_DETAILS_CHANGE',
  VALIDATE_DETAILS_CHANGE_RESPONSE: 'VALIDATE_DETAILS_CHANGE_RESPONSE',
  GET_ASSOC_TASK_CNT: 'GET_ASSOC_TASK_CNT',
  GET_ASSOC_TASK_CNT_RES: 'GET_ASSOC_TASK_CNT_RES',
  INSERT_FF_TASK: 'INSERT_FF_TASK',
  UPDATE_FF_TASK: 'UPDATE_FF_TASK',
  GET_FF_DEDUCTION_EXPORT_REQUEST: 'GET_FF_DEDUCTION_EXPORT_REQUEST',
  SET_FF_GENERAL_CONCURRENCY: 'SET_FF_GENERAL_CONCURRENCY',

  GET_FF_TASK_LIST: 'GET_FF_TASK_LIST',
  GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST: 'GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST',
  GET_FF_ADD_EDIT_TASK_LOC_DETAILS: 'GET_FF_ADD_EDIT_TASK_LOC_DETAILS',
  GET_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE: 'GET_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE',
  GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST_RESPONSE: 'GET_FF_ADD_EDIT_TASK_DETAILS_DD_LIST_RESPONSE',
  VAL_FF_ADD_EDIT_TASK_LOC_DETAILS: 'VAL_FF_ADD_EDIT_TASK_LOC_DETAILS',
  VAL_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE: 'VAL_FF_ADD_EDIT_TASK_LOC_DETAILS_RESPONSE',
  REDEFAULT_FF_TASK: 'REDEFAULT_FF_TASK',
  SET_VALIDATE_LOCATION_ERR: 'SET_VALIDATE_LOCATION_ERR',
  REDEFAULT_FF_TASK_RESPONSE: 'REDEFAULT_FF_TASK_RESPONSE',
  GET_SYSOPT_HIER: 'GET_SYSOPT_HIER',
  GET_SYSOPT_HIER_RES: 'GET_SYSOPT_HIER_RES',

  RESET_FF_DETAIL_VALUE: 'RESET_FF_DETAIL_VALUE',
  GET_FF_HEADER_DETAIL_RESPONSE: 'GET_FF_HEADER_DETAIL_RESPONSE',
  GET_FF_DEDUCTION_LIST_RESPONSE: 'GET_FF_DEDUCTION_LIST_RESPONSE',
  GET_FF_FIN_ARRANGMENT_LIST_RESPONSE: 'GET_FF_FIN_ARRANGMENT_LIST_RESPONSE',
  GET_FF_DEDUCTION_DETAILS_RESPONSE: 'GET_FF_DEDUCTION_DETAILS_RESPONSE',

  GET_FF_TASK_LIST_RESPONSE: 'GET_FF_TASK_LIST_RESPONSE',
  GET_FF_TASK_DETAILS_RESPONSE: 'GET_FF_TASK_DETAILS_RESPONSE',

  GET_FF_ADDRESS_DETAIL_REQUEST: 'GET_FF_ADDRESS_DETAIL_REQUEST',
  GET_FF_ADDRESS_DETAIL_RESPONSE: 'GET_FF_ADDRESS_DETAIL_RESPONSE',
  GET_FF_ADDRESS_BY_CLIENTID_REQUEST: 'GET_FF_ADDRESS_BY_CLIENTID_REQUEST',
  GET_FF_ADDRESS_BY_CLIENTID_RESPONSE: 'GET_FF_ADDRESS_BY_CLIENTID_RESPONSE',
  RESET_FF_ADDRESS_BY_CLIENTID: 'RESET_FF_ADDRESS_BY_CLIENTID',
  SET_FF_ADDRESS_CONCURRENCY: 'SET_FF_ADDRESS_CONCURRENCY',
  GET_FF_TAB_DETAIL_ERROR: 'GET_FF_TAB_DETAIL_ERROR',
  UPDATE_FF_ADDRESS_REQUEST: 'UPDATE_FF_ADDRESS_REQUEST',
  GET_FF_HEADER_DETAIL_ERROR: 'GET_FF_HEADER_DETAIL_ERROR',
  GET_FF_TRAN_DETAILS: 'GET_FF_TRAN_DETAILS',
  GET_FF_TRAN_DETAILS_RESPONSE: 'GET_FF_TRAN_DETAILS_RESPONSE',
  INSERT_FIELDFORCE_DEDUCTION: 'INSERT_FIELDFORCE_DEDUCTION',
  UPDATE_FIELDFORCE_DEDUCTION: 'UPDATE_FIELDFORCE_DEDUCTION',
  GET_FF_DEDUCTION_ITEM_BY_ID: 'GET_FF_DEDUCTION_ITEM_BY_ID',
  GET_FF_DEDUCTION_BY_ID_RESPONSE: 'GET_FF_DEDUCTION_BY_ID_RESPONSE',
  GET_FF_DEDUCTION_DETAIL_TAB_ERROR: 'GET_FF_DEDUCTION_DETAIL_TAB_ERROR',
  GET_FF_DEDUCTION_NAME: 'GET_FF_DEDUCTION_NAME',
  GET_FF_DEDUCTION_BY_ID_NAME: 'GET_FF_DEDUCTION_BY_ID_NAME',
  GET_FF_TRANSACTION_LIST: 'GET_FF_TRANSACTION_LIST',
  GET_FF_TRANSACTION_LOOKUP: 'GET_FF_TRANSACTION_LOOKUP',
  RESET_FF_DEDUCTION_BY_ID_NAME: 'RESET_FF_DEDUCTION_BY_ID_NAME',
  GET_FF_DEDUCTION_BY_ID_RESPONSE_RESET: 'GET_FF_DEDUCTION_BY_ID_RESPONSE_RESET',
  GET_FF_DEDUCTION_BY_ID_NAME_RESET: 'GET_FF_DEDUCTION_BY_ID_NAME_RESET',
  DELETE_DEDUCTION_REQUEST: 'DELETE_DEDUCTION_REQUEST',
  GET_FF_TRAN_DETAIL_BY_ID_RESPONSE_RESET: 'GET_FF_TRAN_DETAIL_BY_ID_RESPONSE_RESET',
  GET_FF_DEDUCTION_ACTIVE: 'GET_FF_DEDUCTION_ACTIVE',
  FF_UPSERT_VALUE: 'FF_UPSERT_VALUE',
  FF_UPSERT_VALUE_RESET: 'FF_UPSERT_VALUE_RESET',
  DEDUCTION_ACTIVE_VALUE: 'DEDUCTION_ACTIVE_VALUE',

  INSERT_FIELDFORCE_TASK: 'INSERT_FIELDFORCE_TASK',
  UPDATE_FIELDFORCE_TASK: 'UPDATE_FIELDFORCE_TASK',
  GET_FF_TASK_ITEM_BY_ID: 'GET_FF_TASK_ITEM_BY_ID',
  SET_FF_TASK_BY_ID_RESPONSE: 'SET_FF_TASK_BY_ID_RESPONSE',
  GET_FF_TASK_DETAIL_TAB_ERROR: 'GET_FF_TASK_DETAIL_TAB_ERROR',
  DELETE_FF_TASK_REQUEST: 'DELETE_FF_TASK_REQUEST',
  GET_FF_TASK_EXPORT_REQUEST: 'GET_FF_TASK_EXPORT_REQUEST',
  SET_FF_TASK_CONCURRENCY: 'SET_FF_TASK_CONCURRENCY',
  GET_FF_TASK_CONCURRENCY_RES: 'GET_FF_TASK_CONCURRENCY_RES',

  GET_FF_DETAIL_BY_ID_REQUEST: 'GET_FF_DETAIL_BY_ID_REQUEST',
  GET_FF_DETAIL_BY_ID_RESPONSE: 'GET_FF_DETAIL_BY_ID_RESPONSE',
  GET_FF_ADDRESS_LOOKUP_P_REQUEST: 'GET_FF_ADDRESS_LOOKUP_P_REQUEST',
  GET_FF_ADDRESS_LOOKUP_P_RESPONSE: 'GET_FF_ADDRESS_LOOKUP_P_RESPONSE',
  GET_FF_ADDRESS_LOOKUP_S_REQUEST: 'GET_FF_ADDRESS_LOOKUP_S_REQUEST',
  GET_FF_ADDRESS_LOOKUP_S_RESPONSE: 'GET_FF_ADDRESS_LOOKUP_S_RESPONSE',
  GET_FF_DETAIL_BY_ID_RESPONSE_RESET: 'GET_FF_DETAIL_BY_ID_RESPONSE_RESET',
  // Financial (Banking)
  GET_FF_BANKING_DETAIL_REQUEST: 'GET_FF_BANKING_DETAIL_REQUEST',
  GET_FF_BANKING_DETAIL_RESPONSE: 'GET_FF_BANKING_DETAIL_RESPONSE',
  UPDATE_FF_BANKING_REQUEST: 'UPDATE_FF_BANKING_REQUEST',
  UPDATE_FF_BANKING_RESPONSE: 'UPDATE_FF_BANKING_RESPONSE',
  UPDATE_FF_BANKING_ERROR: 'UPDATE_FF_BANKING_ERROR',
  GET_FF_BANKING_ORS_PROPAY_REQUEST: 'GET_FF_BANKING_ORS_PROPAY_REQUEST',
  GET_FF_BANKING_ORS_PROPAY_RESPONSE: 'GET_FF_BANKING_ORS_PROPAY_RESPONSE',
  GET_FF_BANKING_PAYEE_STATUS_REQUEST: 'GET_FF_BANKING_PAYEE_STATUS_REQUEST',
  GET_FF_BANKING_PAYEE_STATUS_RESPONSE: 'GET_FF_BANKING_PAYEE_STATUS_RESPONSE',
  GET_FF_BANKING_PAYEE_CODE_REQUEST: 'GET_FF_BANKING_PAYEE_CODE_REQUEST',
  GET_FF_BANKING_PAYEE_CODE_RESPONSE: 'GET_FF_BANKING_PAYEE_CODE_RESPONSE',
  RESET_FF_BANKING_PAYEE_CODE: 'RESET_FF_BANKING_PAYEE_CODE',
  GET_FF_BANKING_PAYEE_NAME_REQUEST: 'GET_FF_BANKING_PAYEE_NAME_REQUEST',
  GET_FF_BANKING_PAYEE_NAME_RESPONSE: 'GET_FF_BANKING_PAYEE_NAME_RESPONSE',
  RESET_FF_BANKING_PAYEE_NAME: 'RESET_FF_BANKING_PAYEE_NAME',
  GET_FF_BANKING_BRANCH_NAME_REQUEST: 'GET_FF_BANKING_BRANCH_NAME_REQUEST',
  GET_FF_BANKING_BRANCH_NAME_RESPONSE: 'GET_FF_BANKING_BRANCH_NAME_RESPONSE',
  RESET_FF_BANKING_BRANCH_NAME: 'RESET_FF_BANKING_BRANCH_NAME',
  GET_FF_BANKING_SYSTEM_OPTIONS_REQUEST: 'GET_FF_BANKING_SYSTEM_OPTIONS_REQUEST',
  GET_FF_BANKING_SYSTEM_OPTIONS_RESPONSE: 'GET_FF_BANKING_SYSTEM_OPTIONS_RESPONSE',
  GET_FF_BANKING_ADDRESS_REQUEST: 'GET_FF_BANKING_ADDRESS_REQUEST',
  GET_FF_BANKING_ADDRESS_RESPONSE: 'GET_FF_BANKING_ADDRESS_RESPONSE',
  SET_FF_BANKING_CONCURRENCY: 'SET_FF_BANKING_CONCURRENCY',
  // FF MAT
  GET_FF_MAT_LIST: 'GET_FF_MAT_LIST',
  GET_FF_MAT_LIST_RESPONSE: 'GET_FF_MAT_LIST_RESPONSE',
  RESET_FF_MAT_LIST_RESPONSE: 'RESET_FF_MAT_LIST_RESPONSE',
  GET_FF_MAT_TRANSFER_ITEMS: 'GET_FF_MAT_TRANSFER_ITEMS',
  GET_FF_MAT_CORP_ITEMS: 'GET_FF_MAT_CORP_ITEMS',
  GET_FF_MAT_CORP_INFO: 'GET_FF_MAT_CORP_INFO',
  RESET_FF_MAT_CORP_INFO: 'RESET_FF_MAT_CORP_INFO',
  HAS_CORP_ERROR: 'HAS_CORP_ERROR',
  FF_TRANSFER_SUCCESS: 'FF_TRANSFER_SUCCESS',
  FF_TRANSFER_ERROR: 'FF_TRANSFER_ERROR',
  CLEAR_MASS_TRANSFER_SUCCESS: 'CLEAR_MASS_TRANSFER_SUCCESS',
  UPDATE_MAT_TABLE_CONFIGURATION: 'UPDATE_MAT_TABLE_CONFIGURATION',
  SET_FF_MAT_CONCURRENCY: 'SET_FF_MAT_CONCURRENCY',

  // Notes
  GET_FF_NOTES_DETAIL_REQUEST: 'GET_FF_NOTES_DETAIL_REQUEST',
  GET_FF_NOTES_DETAIL_RESPONSE: 'GET_FF_NOTES_DETAIL_RESPONSE',
  GET_FF_NOTES_DETAIL_BY_ID_REQUEST: 'GET_FF_NOTES_DETAIL_BY_ID_REQUEST',
  GET_FF_NOTES_DETAIL_BY_ID_RESPONSE: 'GET_FF_NOTES_DETAIL_BY_ID_RESPONSE',
  INSERT_FF_NOTES_REQUEST: 'INSERT_FF_NOTES_REQUEST',
  INSERT_FF_NOTES_RESPONSE: 'INSERT_FF_NOTES_RESPONSE',
  INSERT_FF_NOTES_ERROR: 'INSERT_FF_NOTES_ERROR',
  UPDATE_FF_NOTES_REQUEST: 'UPDATE_FF_NOTES_REQUEST',
  UPDATE_FF_NOTES_RESPONSE: 'UPDATE_FF_NOTES_RESPONSE',
  UPDATE_FF_NOTES_ERROR: 'UPDATE_FF_NOTES_ERROR',
  DELETE_FF_NOTES_REQUEST: 'DELETE_FF_NOTES_REQUEST',
  DELETE_FF_NOTES_RESPONSE: 'DELETE_FF_NOTES_RESPONSE',
  DELETE_FF_NOTES_ERROR: 'DELETE_FF_NOTES_ERROR',
  EXPORT_FF_NOTES_REQUEST: 'EXPORT_FF_NOTES_REQUEST',
  SET_FF_NOTES_CONCURRENCY: 'SET_FF_NOTES_CONCURRENCY',

  // FF Balance Period
  GET_FFBAL_PERIOD_LIST_REQUEST: 'GET_FFBAL_PERIOD_LIST_REQUEST',
  GET_FFBAL_PERIOD_LIST_REQUEST_EXTERNAL: 'GET_FFBAL_PERIOD_LIST_REQUEST_EXTERNAL',
  GET_FFBAL_PERIOD_LIST_RESPONSE: 'GET_FFBAL_PERIOD_LIST_RESPONSE',
  GET_FFBAL_ITEM_BY_PERIOD: 'GET_FFBAL_ITEM_BY_PERIOD',
  GET_FFBAL_ITEM_BY_PERIOD_EXTERNAL: 'GET_FFBAL_ITEM_BY_PERIOD_EXTERNAL',
  GET_FFPROD_ITEM_BY_MONTH: 'GET_FFPROD_ITEM_BY_MONTH',
  GET_FFBAL_ITEM_BY_PERIOD_YTD: 'GET_FFBAL_ITEM_BY_PERIOD_YTD',
  GET_FFBAL_ITEM_BY_PERIOD_YTD_EXTERNAL: 'GET_FFBAL_ITEM_BY_PERIOD_YTD_EXTERNAL',
  GET_FFPROD_ITEM_BY_YTD: 'GET_FFPROD_ITEM_BY_YTD',
  GET_FFBAL_ITEM_BY_PERIOD_RESPONSE: 'GET_FFBAL_ITEM_BY_PERIOD_RESPONSE',
  GET_FFPROD_ITEM_BY_MONTH_RESPONSE: 'GET_FFPROD_ITEM_BY_MONTH_RESPONSE',
  GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE: 'GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE',
  GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE_EXTERNAL: 'GET_FFBAL_ITEM_BY_PERIOD_YTD_RESPONSE',
  GET_FFPROD_ITEM_BY_YTD_RESPONSE: 'GET_FFPROD_ITEM_BY_YTD_RESPONSE',
  GET_FFBAL_MONTHLY_LIST_REQUEST: 'GET_FFBAL_MONTHLY_LIST_REQUEST',
  GET_FFBAL_MONTHLY_LIST_REQUEST_EXTERNAL: 'GET_FFBAL_MONTHLY_LIST_REQUEST_EXTERNAL',
  GET_FFBAL_MONTHLY_LIST_RESPONSE: 'GET_FFBAL_MONTHLY_LIST_RESPONSE',
  GET_FFBAL_SUMMARY_REQUEST: 'GET_FFBAL_SUMMARY_REQUEST',
  GET_FFBAL_SUMMARY_LIST_RESPONSE: 'GET_FFBAL_SUMMARY_LIST_RESPONSE',
  GET_FFBAL_SUMMARY_LIST_REQUEST: 'GET_FFBAL_SUMMARY_LIST_REQUEST',
  GET_FFBAL_HIERARCHY_REQUEST: 'GET_FFBAL_HIERARCHY_REQUEST',
  GETLOOKUPS: 'GETLOOKUPS',
  GET_FFBAL_HIERARCHY_LIST_RESPONSE: 'GET_FFBAL_HIERARCHY_LIST_RESPONSE',
  GET_FFBAL_HIERARCHY_LIST_REQUEST: 'GET_FFBAL_HIERARCHY_LIST_REQUEST',
  GET_FFPROD_BUIS_LIST_REQUEST: 'GET_FFPROD_BUIS_LIST_REQUEST',
  GET_FFPROD_BUIS_LIST_RESPONSE: 'GET_FFPROD_BUIS_LIST_RESPONSE',
  GET_FFPROD_HIER_LIST_REQUEST: 'GET_FFPROD_HIER_LIST_REQUEST',
  GET_FFPROD_HIER_LWR_LIST_REQUEST: 'GET_FFPROD_HIER_LWR_LIST_REQUEST',
  GET_FFPROD_PROD_LIST_REQUEST: 'GET_FFPROD_PROD_LIST_REQUEST',
  GET_FFPROD_MONTHCOMP_LIST_REQUEST: 'GET_FFPROD_MONTHCOMP_LIST_REQUEST',
  GET_SYSOPTS_REQUEST: 'GET_SYSOPTS_REQUEST',
  GETLKUPSMNTHYR: 'GETLKUPSMNTHYR',
  GET_FFBAL_PERIODMONTH_EXPORT_REQUEST: 'GET_FFBAL_PERIODMONTH_EXPORT_REQUEST',
  GET_FFBAL_PERIODMONTH_EXPORT_REQUEST_EXTERNAL: 'GET_FFBAL_PERIODMONTH_EXPORT_REQUEST_EXTERNAL',
  GET_FFBAL_SUMMARY_EXPORT_REQUEST: 'GET_FFBAL_SUMMARY_EXPORT_REQUEST',
  GET_FFBAL_HIERARCHY_EXPORT_REQUEST: 'GET_FFBAL_HIERARCHY_EXPORT_REQUEST',
  GET_FFPROD_BUIS_EXPORT_REQUEST: 'GET_FFPROD_BUIS_EXPORT_REQUEST',
  GET_FFPROD_HIER_EXPORT_REQUEST: 'GET_FFPROD_HIER_EXPORT_REQUEST',
  GET_FFPROD_PROD_EXPORT_REQUEST: 'GET_FFPROD_PROD_EXPORT_REQUEST',
  GET_FFPROD_MONTHCOMP_EXPORT_REQUEST: 'GET_FFPROD_MONTHCOMP_EXPORT_REQUEST',
  EXPORT_PRODHIER: 'EXPORT_PRODHIER',

  // EXTERNAL - Production

  GET_FFPROD_BUIS_LIST_REQUEST_EXT: 'GET_FFPROD_BUIS_LIST_REQUEST_EXT',
  GET_FFPROD_BUIS_LIST_RESPONSE_EXT: 'GET_FFPROD_BUIS_LIST_RESPONSE_EXT',
  GET_FFPROD_ITEM_BY_MONTH_EXT: 'GET_FFPROD_ITEM_BY_MONTH_EXT',
  GET_FFPROD_ITEM_BY_YTD_EXT: 'GET_FFPROD_ITEM_BY_YTD_EXT',
  GET_FFPROD_PROD_LIST_REQUEST_EXT: 'GET_FFPROD_PROD_LIST_REQUEST_EXT',
  GET_FFPROD_HIER_LIST_REQUEST_EXT: 'GET_FFPROD_HIER_LIST_REQUEST_EXT',
  GET_FFPROD_BUIS_EXPORT_REQUEST_EXT: 'GET_FFPROD_BUIS_EXPORT_REQUEST_EXT',
  GET_FFPROD_PROD_EXPORT_REQUEST_EXT: 'GET_FFPROD_PROD_EXPORT_REQUEST_EXT',

  // Administration
  GET_FF_ADMIN_DETAIL_REQUEST: 'GET_FF_ADMIN_DETAIL_REQUEST',
  GET_FF_ADMIN_DETAIL_RESPONSE: 'GET_FF_ADMIN_DETAIL_RESPONSE',
  UPDATE_FF_ADMIN_REQUEST: 'UPDATE_FF_ADMIN_REQUEST',
  UPDATE_FF_ADMIN_RESPONSE: 'UPDATE_FF_ADMIN_RESPONSE',
  UPDATE_FF_ADMIN_ERROR: 'UPDATE_FF_ADMIN_ERROR',
  GET_FF_ADMIN_DEBTREF_REQUEST: 'GET_FF_ADMIN_DEBTREF_REQUEST',
  GET_FF_ADMIN_DEBTREF_RESPONSE: 'GET_FF_ADMIN_DEBTREF_RESPONSE',
  SET_FF_ADMIN_CONCURRENCY: 'SET_FF_ADMIN_CONCURRENCY',
  // Delete GEN EDIT TYPE
  DELETE_CLIENT_NOTES__REQUEST: 'DELETE_CLIENT_NOTES__REQUEST',
  DELETE_CLIENT_NOTES__RESPONSE: 'DELETE_CLIENT_NOTES__RESPONSE',
  DELETE_CLIENT_NOTES__ERROR: 'DELETE_CLIENT_NOTES__ERROR',
  // Delete client license
  DELETE_FF_REQUEST: 'DELETE_FF_REQUEST',
  DELETE_FF_RESPONSE: 'DELETE_FF_RESPONSE',
  DELETE_FF_ERROR: 'DELETE_FF_ERROR',
  STATUS_FF_REQUEST: 'STATUS_FF_REQUEST',
  STATUS_FF_RESPONSE: 'STATUS_FF_RESPONSE',
  STATUS_FF_ERROR: 'STATUS_FF_ERROR',

  // REQUIREMENT
  GET_FF_REQUIREMENT_LIST_REQUEST: 'GET_FF_REQUIREMENT_LIST_REQUEST',
  GET_FF_REQUIREMENT_LIST_BY_ID_REQUEST: 'GET_FF_REQUIREMENT_LIST_BY_ID_REQUEST',
  INSERT_FF_REQUIREMENT_REQUEST: 'INSERT_FF_REQUIREMENT_REQUEST',
  UPDATE_FF_REQUIREMENT_REQUEST: 'UPDATE_FF_REQUIREMENT_REQUEST',
  DELETE_FF_REQUIREMENT_REQUEST: 'DELETE_FF_REQUIREMENT_REQUEST',
  GET_FF_REQUIREMENT_TABLE_RES: 'GET_FF_REQUIREMENT_TABLE_RES',
  GET_FF_REQUIREMENT_TABLE_COMMENT: 'GET_FF_REQUIREMENT_TABLE_COMMENT',
  GET_FF_REQUIREMENT_LOOK_UP: 'GET_FF_REQUIREMENT_LOOK_UP',
  GET_FF_REQUIREMENT_LOOK_UP_DATA: 'GET_FF_REQUIREMENT_LOOK_UP_DATA',
  NOTIFY_NOW_REQUIREMENT_REQUEST: 'NOTIFY_NOW_REQUIREMENT_REQUEST',
  NOTIFY_LATER_REQUIREMENT_REQUEST: 'NOTIFY_LATER_REQUIREMENT_REQUEST',
  NOTIFY_SUCCESS_STATUS: 'NOTIFY_SUCCESS_STATUS',
  FF_REQUIREMENT_SAVE_COMMENT: 'FF_REQUIREMENT_SAVE_COMMENT',
  FF_SUCCESS_SAVE_COMMENT: 'FF_SUCCESS_SAVE_COMMENT',
  FF_CLEAR_SAVE_COMMENT: 'FF_CLEAR_SAVE_COMMENT',
  FF_CLEAR_NOTIFY_STATUS: 'FF_CLEAR_NOTIFY_STATUS',
  SET_FF_REQUIREMENT_CONCURRENCY: 'SET_FF_REQUIREMENT_CONCURRENCY',

  // FF Relationship
  GET_FF_REL_LIST: 'GET_FF_REL_LIST',
  GET_FF_REL_LIST_RESPONSE: 'GET_FF_REL_LIST_RESPONSE',
  INSERT_FF_REL_REQUEST: 'INSERT_FF_REL_REQUEST',
  UPDATE_FF_REL_REQUEST: 'UPDATE_FF_REL_REQUEST',
  DELETE_FF_REL_REQUEST: 'DELETE_FF_REL_REQUEST',
  GET_FF_REL_ADVISOR_NAME: 'GET_FF_REL_ADVISOR_NAME',
  SET_FF_REL_ADVISOR_NAME: 'SET_FF_REL_ADVISOR_NAME',
  GET_FF_REL_EXPORT_REQUEST: 'GET_FF_REL_EXPORT_REQUEST',
  SET_FF_REL_CONCURRENCY: 'SET_FF_REL_CONCURRENCY',
  GET_FF_REL_BY_ID_RESPONSE: 'GET_FF_REL_BY_ID_RESPONSE',
  GET_FF_REL_DETAIL_TAB_ERROR: 'GET_FF_REL_DETAIL_TAB_ERROR',
};

export const ClientCommonActionTypes = {
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  GET_CLIENT_DETAIL_TAB_RESPONSE: 'GET_CLIENT_DETAIL_TAB_RESPONSE',
  GET_CLIENT_DETAIL_BY_ID_RESPONSE: 'GET_CLIENT_DETAIL_BY_ID_RESPONSE',
  GET_CLIENT_DETAIL_DEFAULT_RESPONSE: 'GET_CLIENT_DETAIL_DEFAULT_RESPONSE',
  GET_CLIENT_DETAIL_TAB_ERROR: 'GET_CLIENT_DETAIL_TAB_ERROR',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  UPSERT_CLIENT_DETAIL_TAB_REQUEST: 'UPSERT_CLIENT_DETAIL_TAB_REQUEST',
};
export const cliLicActionTypes = {
  GET_CLIENT_LICENCE_EXPORT_REQUEST: 'GET_CLIENT_LICENCE_EXPORT_REQUEST',
  LOG_LIC_LIST_REQUEST: 'LOG_LIC_LIST_REQUEST',
  GET_LIC_LIST_REQUEST: 'GET_LIC_LIST_REQUEST',
  GET_CLIENT_LIC_DEFAULT_REQUEST: 'GET_CLIENT_LIC_DEFAULT_REQUEST',
  GET_LIC_LIST_RESPONSE: 'GET_LIC_LIST_RESPONSE',
  GET_LIC_LIST_ERROR: 'GET_LIC_LIST_ERROR',
  GET_LIC_LIST_LOG_REQ: 'GET_LIC_LIST_LOG_REQ',

  // Get Edit Iten by Id
  LOG_LIC_BY_ID_REQUEST: 'LOG_LIC_BY_ID_REQUEST',
  GET_LIC_BY_ID_REQUEST: 'GET_LIC_BY_ID_REQUEST',
  GET_LIC_BY_ID_RESPONSE: 'GET_LIC_BY_ID_RESPONSE',
  GET_LIC_BY_ID_ERROR: 'GET_LIC_BY_ID_ERROR',
  GET_LIC_BY_ID_LOG_REQ: 'GET_LIC_BY_ID_LOG_REQ',
  // Get client license detail
  GET_BC_REQUEST: 'GET_BC_REQUEST',
  GET_BC_RESPONSE: 'GET_BC_RESPONSE',
  GET_BC_ERROR: 'GET_BC_ERROR',
  // Add/Update client license detail
  UPSERT_LIC_REQUEST: 'UPSERT_LIC_REQUEST',
  INSERT_LIC_REQUEST: 'INSERT_LIC_REQUEST',
  INSERT_LIC_RESPONSE: 'INSERT_LIC_RESPONSE',
  INSERT_LIC_ERROR: 'INSERT_LIC_ERROR',
  UPDATE_LIC_REQUEST: 'UPDATE_LIC_REQUEST',
  UPDATE_LIC_RESPONSE: 'UPDATE_LIC_RESPONSE',
  UPDATE_LIC_ERROR: 'UPDATE_LIC_ERROR',
  // Delete client license
  DELETE_LIC_REQUEST: 'DELETE_LIC_REQUEST',
  DELETE_LIC_RESPONSE: 'DELETE_LIC_RESPONSE',
  DELETE_LIC_ERROR: 'DELETE_LIC_ERROR',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  // Detail client license
  SET_LIC_DETAILS_RESPONSE: 'SET_LIC_DETAILS_RESPONSE',
  // Export List for LIC
  GET_LIC_EXPORT_REQUEST: 'GET_LIC_EXPORT_REQUEST',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Reset store
  RESET_LIC_STORE: 'RESET_LIC_STORE',
  GET_CLIENT_LICENCE_BY_ID_REQUEST: 'GET_CLIENT_LICENCE_BY_ID_REQUEST',
};

export const clientAddressActionTypes = {

  // Tab specific Actions
  GET_CLIENT_ADDRESS_LIST_REQUEST: 'GET_CLIENT_ADDRESS_LIST_REQUEST',
  GET_CLIENT_ADDRESS_DEFAULT_REQUEST: 'GET_CLIENT_ADDRESS_DEFAULT_REQUEST',
  DELETE_CLIENT_ADDRESS_REQUEST: 'DELETE_CLIENT_ADDRESS_REQUEST',
  ADD_CLIENT_ADDRESS_REQUEST: 'ADD_CLIENT_ADDRESS_REQUEST',
  UPDATE_CLIENT_ADDRESS_REQUEST: 'UPDATE_CLIENT_ADDRESS_REQUEST',
  GET_CLIENT_ADDRESS_EXPORT_REQUEST: 'GET_CLIENT_ADDRESS_EXPORT_REQUEST',
  GET_CLIENT_ADDRESS_BY_ID_REQUEST: 'GET_CLIENT_ADDRESS_BY_ID_REQUEST',
  GET_CLIENT_ADDRESS_EXIST_IN_REQUEST: 'GET_CLIENT_ADDRESS_EXIST_IN_REQUEST',
  GET_CLIENT_ADDRESS_VIEW_BY_ID_REQUEST: 'GET_CLIENT_ADDRESS_VIEW_BY_ID_REQUEST',
  // DELETE_CLIENT_ADDRESS_ERROR: 'DELETE_CLIENT_ADDRESS_ERROR',
  UPSERT_CLIENT_ADDRESS_REQUEST: 'UPSERT_CLIENT_ADDRESS_REQUEST',

  // common actions
  LOG_CLIENT_DETAIL_REQUEST: 'LOG_CLIENT_DETAIL_REQUEST',
  RESET_CLIENT_DETAIL_TAB_ALUE: 'RESET_CLIENT_DETAIL_TAB_ALUE',
  // UPSERT_CLIENT_RESPONSE: 'UPSERT_CLIENT_RESPONSE',
  // UPSERT_CLIENT_DETAIL_TAB_ERROR: 'UPSERT_CLIENT_DETAIL_TAB_ERROR',

  GET_CLIENT_TAB_LIST_REQUEST: 'GET_CLIENT_TAB_LIST_REQUEST',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};

export const clientBankingActionTypes = {

  // Update System Options groups
  // UPDATE_CLIENT_REQUEST: 'UPDATE_CLIENT_REQUEST',
  // UPDATE_CLIENT_GEN_PREF_REQUEST: 'UPDATE_CLIENT_GEN_PREF_REQUEST',
  // UPDATE_CLIENT_AGENT_PREF_REQUEST: 'UPDATE_CLIENT_AGENT_PREF_REQUEST',
  // UPDATE_CLIENT_RESPONSE: 'UPDATE_CLIENT_RESPONSE',
  // UPDATE_CLIENT_ERROR: 'UPDATE_CLIENT_ERROR',

  // Tab specific Actions
  GET_CLIENT_BANKING_RELATIONAL_TABLE: 'GET_CLIENT_BANKING_RELATIONAL_TABLE',
  GET_CLIENT_BANKING_LIST_REQUEST: 'GET_CLIENT_BANKING_LIST_REQUEST',
  GET_CLIENT_BANKING_EXPORT_REQUEST: 'GET_CLIENT_BANKING_EXPORT_REQUEST',
  DELETE_CLIENT_BANKING_REQUEST: 'DELETE_CLIENT_BANKING_REQUEST',
  ADD_CLIENT_BANKING_REQUEST: 'ADD_CLIENT_BANKING_REQUEST',
  // DELETE_CLIENT_BANKING_ERROR: 'DELETE_CLIENT_BANKING_ERROR',
  UPSERT_CLIENT_BANKING_REQUEST: 'UPSERT_CLIENT_BANKING_REQUEST',

  // common actions
  LOG_CLIENT_DETAIL_REQUEST: 'LOG_CLIENT_DETAIL_REQUEST',
  RESET_CLIENT_DETAIL_TAB_ALUE: 'RESET_CLIENT_DETAIL_TAB_ALUE',
  // UPSERT_CLIENT_RESPONSE: 'UPSERT_CLIENT_RESPONSE',
  // UPSERT_CLIENT_DETAIL_TAB_ERROR: 'UPSERT_CLIENT_DETAIL_TAB_ERROR',

  GET_CLIENT_TAB_LIST_REQUEST: 'GET_CLIENT_TAB_LIST_REQUEST',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',

  LOG_GET_LIST_REQUEST: 'LOG_GET_LIST_REQUEST',
  GET_GET_LIST_REQUEST: 'GET_GET_LIST_REQUEST',
  GET_GET_LIST_RESPONSE: 'GET_GET_LIST_RESPONSE',
  GET_GET_LIST_ERROR: 'GET_GET_LIST_ERROR',
  GET_GET_LIST_LOG_REQ: 'GET_GET_LIST_LOG_REQ',
  UPDATE_GET_COLUMN_CONFIG: 'UPDATE_GET_COLUMN_CONFIG',
  // Get Edit Item by Id
  LOG_CLB_BY_ID_REQUEST: 'LOG_CLB_BY_ID_REQUEST',
  GET_CLB_BY_ID_REQUEST: 'GET_CLB_BY_ID_REQUEST',
  GET_CLB_BY_ID_RESPONSE: 'GET_CLB_BY_ID_RESPONSE',
  GET_CLB_BY_ID_ERROR: 'GET_CLB_BY_ID_ERROR',
  GET_CLB_BY_ID_LOG_REQ: 'GET_CLB_BY_ID_LOG_REQ',
  // Get GEN EDIT TYPE detail
  GET_GET_REQUEST: 'GET_GET_REQUEST',
  GET_GET_RESPONSE: 'GET_GET_RESPONSE',
  GET_GET_ERROR: 'GET_GET_ERROR',
  // Add/Update GEN EDIT TYPE detail
  INSERT_CLIENT_BANKING_REQUEST: 'INSERT_CLIENT_BANKING_REQUEST',
  INSERT_CLIENT_BANKING__RESPONSE: 'UPSERT_CLIENT_BANKING__RESPONSE',
  INSERT_CLIENT_BANKING__ERROR: 'UPSERT_CLIENT_BANKING__ERROR',
  UPDATE_CLIENT_BANKING__REQUEST: 'UPDATE_CLIENT_BANKING__REQUEST',
  UPDATE_CLIENT_BANKING__RESPONSE: 'UPDATE_CLIENT_BANKING__RESPONSE',
  UPDATE_CLIENT_BANKING__ERROR: 'UPDATE_CLIENT_BANKING__ERROR',
  // Delete GEN EDIT TYPE
  DELETE_CLIENT_BANKING__REQUEST: 'DELETE_CLIENT_BANKING__REQUEST',
  DELETE_CLIENT_BANKING__RESPONSE: 'DELETE_CLIENT_BANKING__RESPONSE',
  DELETE_CLIENT_BANKING__ERROR: 'DELETE_CLIENT_BANKING__ERROR',
  // Lookup GEN EDIT TYPE
  GET_DEPENDENCY_EXIST_REQUEST: 'GET_DEPENDENCY_EXIST_REQUEST',
  GET_DEPENDENCY_EXIST_RESPONSE: 'GET_DEPENDENCY_EXIST_RESPONSE',
  GET_DEPENDENCY_EXIST_ERROR: 'GET_DEPENDENCY_EXIST_ERROR',
  // Update Filter Setting
  UPDATE_GET_FILTER_CONFIG: 'UPDATE_GET_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_GEN_EDIT_TABLE_CONFIGURATION',
  SET_GET_DETAILS_RESPONSE: 'SET_GET_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_GET_USER_DEFAULT_FILTER: 'REMOVE_GET_USER_DEFAULT_FILTER',
  // Reset store
  RESET_GET_STORE: 'RESET_GET_STORE',
  // Export List for BCR
  GET_GET_EXPORT_REQUEST: 'GET_GET_EXPORT_REQUEST',
  // Update Server Error Section
  // RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};

export const clientNotesActionTypes = {

  // Update System Options groups
  // UPDATE_CLIENT_REQUEST: 'UPDATE_CLIENT_REQUEST',
  // UPDATE_CLIENT_GEN_PREF_REQUEST: 'UPDATE_CLIENT_GEN_PREF_REQUEST',
  // UPDATE_CLIENT_AGENT_PREF_REQUEST: 'UPDATE_CLIENT_AGENT_PREF_REQUEST',
  // UPDATE_CLIENT_RESPONSE: 'UPDATE_CLIENT_RESPONSE',
  // UPDATE_CLIENT_ERROR: 'UPDATE_CLIENT_ERROR',

  // Tab specific Actions
  GET_CLIENT_NOTES_LIST_REQUEST: 'GET_CLIENT_NOTES_LIST_REQUEST',
  DELETE_CLIENT_NOTES_REQUEST: 'DELETE_CLIENT_NOTES_REQUEST',
  ADD_CLIENT_NOTES_REQUEST: 'ADD_CLIENT_NOTES_REQUEST',
  GET_CLIENT_NOTES_EXPORT_REQUEST: 'GET_CLIENT_NOTES_EXPORT_REQUEST',
  // DELETE_CLIENT_NOTES_ERROR: 'DELETE_CLIENT_NOTES_ERROR',
  UPSERT_CLIENT_NOTES_REQUEST: 'UPSERT_CLIENT_NOTES_REQUEST',

  // common actions
  LOG_CLIENT_DETAIL_REQUEST: 'LOG_CLIENT_DETAIL_REQUEST',
  RESET_CLIENT_DETAIL_TAB_ALUE: 'RESET_CLIENT_DETAIL_TAB_ALUE',
  // UPSERT_CLIENT_RESPONSE: 'UPSERT_CLIENT_RESPONSE',
  // UPSERT_CLIENT_DETAIL_TAB_ERROR: 'UPSERT_CLIENT_DETAIL_TAB_ERROR',

  GET_CLIENT_TAB_LIST_REQUEST: 'GET_CLIENT_TAB_LIST_REQUEST',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',

  LOG_GET_LIST_REQUEST: 'LOG_GET_LIST_REQUEST',
  GET_GET_LIST_REQUEST: 'GET_GET_LIST_REQUEST',
  GET_GET_LIST_RESPONSE: 'GET_GET_LIST_RESPONSE',
  GET_GET_LIST_ERROR: 'GET_GET_LIST_ERROR',
  GET_GET_LIST_LOG_REQ: 'GET_GET_LIST_LOG_REQ',
  UPDATE_GET_COLUMN_CONFIG: 'UPDATE_GET_COLUMN_CONFIG',
  // Get Edit Item by Id
  LOG_CLN_BY_ID_REQUEST: 'LOG_CLN_BY_ID_REQUEST',
  GET_CLN_BY_ID_REQUEST: 'GET_CLN_BY_ID_REQUEST',
  GET_CLN_BY_ID_RESPONSE: 'GET_CLN_BY_ID_RESPONSE',
  GET_CLN_BY_ID_ERROR: 'GET_CLN_BY_ID_ERROR',
  GET_CLN_BY_ID_LOG_REQ: 'GET_CLN_BY_ID_LOG_REQ',
  // Get GEN EDIT TYPE detail
  GET_GET_REQUEST: 'GET_GET_REQUEST',
  GET_GET_RESPONSE: 'GET_GET_RESPONSE',
  GET_GET_ERROR: 'GET_GET_ERROR',
  // Add/Update GEN EDIT TYPE detail
  INSERT_CLIENT_NOTES_REQUEST: 'INSERT_CLIENT_NOTES_REQUEST',
  INSERT_CLIENT_NOTES__RESPONSE: 'UPSERT_CLIENT_NOTES__RESPONSE',
  INSERT_CLIENT_NOTES__ERROR: 'UPSERT_CLIENT_NOTES__ERROR',
  UPDATE_CLIENT_NOTES__REQUEST: 'UPDATE_CLIENT_NOTES__REQUEST',
  UPDATE_CLIENT_NOTES__RESPONSE: 'UPDATE_CLIENT_NOTES__RESPONSE',
  UPDATE_CLIENT_NOTES__ERROR: 'UPDATE_CLIENT_NOTES__ERROR',
  // Delete GEN EDIT TYPE
  DELETE_CLIENT_NOTES__REQUEST: 'DELETE_CLIENT_NOTES__REQUEST',
  DELETE_CLIENT_NOTES__RESPONSE: 'DELETE_CLIENT_NOTES__RESPONSE',
  DELETE_CLIENT_NOTES__ERROR: 'DELETE_CLIENT_NOTES__ERROR',
  // Lookup GEN EDIT TYPE
  GET_DEPENDENCY_EXIST_REQUEST: 'GET_DEPENDENCY_EXIST_REQUEST',
  GET_DEPENDENCY_EXIST_RESPONSE: 'GET_DEPENDENCY_EXIST_RESPONSE',
  GET_DEPENDENCY_EXIST_ERROR: 'GET_DEPENDENCY_EXIST_ERROR',
  // Update Filter Setting
  UPDATE_GET_FILTER_CONFIG: 'UPDATE_GET_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_GEN_EDIT_TABLE_CONFIGURATION',
  SET_GET_DETAILS_RESPONSE: 'SET_GET_DETAILS_RESPONSE',
  // remove user default filter from redux stotre
  REMOVE_GET_USER_DEFAULT_FILTER: 'REMOVE_GET_USER_DEFAULT_FILTER',
  // Reset store
  RESET_GET_STORE: 'RESET_GET_STORE',
  // Export List for BCR
  GET_GET_EXPORT_REQUEST: 'GET_GET_EXPORT_REQUEST',
  // Update Server Error Section
  // RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};

// fin arrangements action types
export const finArrangementsAdjActionTypes = {
  LOG_FINARRANGEMENTS_LIST_REQUEST: 'LOG_FINARRANGEMENTS_LIST_REQUEST',
  GET_FINARRANGEMENTS_LIST_REQUEST: 'GET_FINARRANGEMENTS_LIST_REQUEST',
  GET_FINARRANGEMENTS_LIST_RESPONSE: 'GET_FINARRANGEMENTS_LIST_RESPONSE',
  GET_FINARRANGEMENTS_LIST_ERROR: 'GET_FINARRANGEMENTS_LIST_ERROR',
  GET_FINARRANGEMENTS_LIST_LOG_REQ: 'GET_FINARRANGEMENTS_LIST_LOG_REQ',
  // Get Edit Iten by Id
  LOG_FINARRANGEMENTS_BY_ID_REQUEST: 'LOG_FINARRANGEMENTS_BY_ID_REQUEST',
  GET_FINARRANGEMENTS_BY_ID_REQUEST: 'GET_FINARRANGEMENTS_BY_ID_REQUEST',
  GET_FINARRANGEMENTS_BY_ID_RESPONSE: 'GET_FINARRANGEMENTS_BY_ID_RESPONSE',
  GET_FINARRANGEMENTS_ITEM_BY_ID: 'GET_FINARRANGEMENTS_ITEM_BY_ID',
  GET_FINARRANGEMENTS_BY_ID_ERROR: 'GET_FINARRANGEMENTS_BY_ID_ERROR',
  GET_FINARRANGEMENTS_BY_ID_LOG_REQ: 'GET_FINARRANGEMENTS_BY_ID_LOG_REQ',
  GET_FINARRANGEMENTS_TRANSFERID_REQ: 'GET_FINARRANGEMENTS_TRANSFERID_REQ',
  GET_FINARRANGEMENTS_EXPORT_REQUEST: 'GET_FINARRANGEMENTS_EXPORT_REQUEST',
  // Add/Update
  SET_TRANSFER_AGENT_NAME: 'SET_FA_TRANSFER_AGENT_NAME',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_FINARRANGEMENTS_REQUEST: 'UPSERT_FINARRANGEMENTS_REQUEST',
  INSERT_FINARRANGEMENTS_REQUEST: 'INSERT_FINARRANGEMENTS_REQUEST',
  CLONE_FINARRANGEMENTS_REQUEST: 'CLONE_FINARRANGEMENTS_REQUEST',
  INSERT_FINARRANGEMENTS_RESPONSE: 'INSERT_FINARRANGEMENTS_RESPONSE',
  INSERT_FINARRANGEMENTS_ERROR: 'INSERT_FINARRANGEMENTS_ERROR',
  UPDATE_FINARRANGEMENTS_REQUEST: 'UPDATE_FINARRANGEMENTS_REQUEST',
  UPDATE_FINARRANGEMENTS_ENDDATE_REQUEST: 'UPDATE_FINARRANGEMENTS_ENDDATE_REQUEST',
  UPDATE_FINARRANGEMENTS_RESPONSE: 'UPDATE_FINARRANGEMENTS_RESPONSE',
  UPDATE_FINARRANGEMENTS_ERROR: 'UPDATE_FINARRANGEMENTS_ERROR',
  RESET_DELETE_FINARRANGEMENTS_VALUE: 'RESET_FINARRANGEMENTS_DELETE_VALUE',
  RESET_APPROVE_FINARRANGEMENTS_VALUE: 'RESET_APPROVE_FINARRANGEMENTS_VALUE',
  RESET_IMPORT_FINARRANGEMENTS: 'RESET_IMPORT_FINARRANGEMENTS',
  LAST_IMPORT: 'LAST_IMPORT',
  // Delete
  DELETE_FINARRANGEMENTS_REQUEST: 'DELETE_FINARRANGEMENTS_REQUEST',
  DELETE_FINARRANGEMENTS_RESPONSE: 'DELETE_FINARRANGEMENTS_RESPONSE',
  DELETE_FINARRANGEMENTS_ERROR: 'DELETE_FINARRANGEMENTS_ERROR',
  APPROVE_FINARRANGEMENTS_REQUEST: 'APPROVE_FINARRANGEMENTS_REQUEST',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  SET_FINARRANGEMENTS_DETAILS_RESPONSE: 'SET_FINARRANGEMENTS_DETAILS_RESPONSE',
  LOG_FINARRANGEMENTS_DETAIL_REQUEST: 'LOG_FINARRANGEMENTS_DETAIL_REQUEST',
  GET_FINARRANGEMENTES_BY_ID_RESPONSE: 'GET_FINARRANGEMENTES_BY_ID_RESPONSE',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'UPDATE_FIN_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_FIN_TABLE_CONFIGURATION',
  UPDATE_FINARRANGEMENTS_COLUMN_CONFIG: 'UPDATE_FINARRANGEMENTS_COLUMN_CONFIG',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Reset store
  RESET_FINARRANGEMENTS_STORE: 'RESET_FINARRANGEMENTS_STORE',
  //
  SET_FIN_ARR_LOCK: 'SET_FIN_ARR_LOCK',
  //
  UPDATE_FILTER_CONFIG_RESET_SELECTED: 'UPDATE_FILTER_CONFIG_RESET_SELECTED_FA',

  GET_FINARRANGEMENTS_TAB_VISIBILITY_REQUEST: 'GET_FINARRANGEMENTS_TAB_VISIBILITY_REQUEST',
  SET_FINARRANGEMENTS_TAB_VISIBILITY_REQUEST: 'SET_FINARRANGEMENTS_TAB_VISIBILITY_REQUEST',

};

export const fieldForceMovementActionTypes = {
  LOG_FIELDFORCEMOVEMENT_LIST_REQUEST: 'LOG_FIELDFORCEMOVEMENT_LIST_REQUEST',
  GET_FIELDFORCEMOVEMENT_LIST_REQUEST: 'GET_FIELDFORCEMOVEMENT_LIST_REQUEST',
  GET_FIELDFORCEMOVEMENT_LIST_RESPONSE: 'GET_FIELDFORCEMOVEMENT_LIST_RESPONSE',
  GET_FIELDFORCEMOVEMENTR_LIST_ERROR: 'GET_FIELDFORCEMOVEMENT_LIST_ERROR',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  RESET_FIELDFORCEMOVEMENT_STORE: 'RESET_FIELDFORCEMOVEMENT_STORE',
  UPDATE_BCR_COLUMN_CONFIG: 'UPDATE_BCR_COLUMN_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  GET_BCR_EXPORT_REQUEST: 'GET_BCR_EXPORT_REQUEST',
  SET_FFM_DETAILS_RESPONSE: 'SET_FFM_DETAILS_RESPONSE',
  GET_FIELDFORCEMOVEMENT_EXPORT_REQUEST: 'GET_FIELDFORCEMOVEMENT_EXPORT_REQUEST',
  SET_BCR_DETAILS_RESPONSE: 'SET_BCR_DETAILS_RESPONSE',
};

export const ffActionTypes = {
  LOG_FINARRANGEMENTS_LIST_REQUEST: 'LOG_FINARRANGEMENTS_LIST_REQUEST',
  GET_FINARRANGEMENTS_LIST_REQUEST: 'GET_FINARRANGEMENTS_LIST_REQUEST',
  GET_FINARRANGEMENTS_LIST_RESPONSE: 'GET_FINARRANGEMENTS_LIST_RESPONSE',
  GET_FINARRANGEMENTS_LIST_ERROR: 'GET_FINARRANGEMENTS_LIST_ERROR',
  GET_FINARRANGEMENTS_LIST_LOG_REQ: 'GET_FINARRANGEMENTS_LIST_LOG_REQ',
  // Get Edit Iten by Id
  LOG_FINARRANGEMENTS_BY_ID_REQUEST: 'LOG_FINARRANGEMENTS_BY_ID_REQUEST',
  GET_FINARRANGEMENTS_BY_ID_REQUEST: 'GET_FINARRANGEMENTS_BY_ID_REQUEST',
  GET_FINARRANGEMENTS_BY_ID_RESPONSE: 'GET_FINARRANGEMENTS_BY_ID_RESPONSE',
  GET_FINARRANGEMENTS_ITEM_BY_ID: 'GET_FINARRANGEMENTS_ITEM_BY_ID',
  GET_FINARRANGEMENTS_BY_ID_ERROR: 'GET_FINARRANGEMENTS_BY_ID_ERROR',
  GET_FINARRANGEMENTS_BY_ID_LOG_REQ: 'GET_FINARRANGEMENTS_BY_ID_LOG_REQ',
  GET_FINARRANGEMENTS_TRANSFERID_REQ: 'GET_FINARRANGEMENTS_TRANSFERID_REQ',
  GET_FINARRANGEMENTS_EXPORT_REQUEST: 'GET_FINARRANGEMENTS_EXPORT_REQUEST',
  // Add/Update
  SET_TRANSFER_AGENT_NAME: 'SET_FA_TRANSFER_AGENT_NAME',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPSERT_FINARRANGEMENTS_REQUEST: 'UPSERT_FINARRANGEMENTS_REQUEST',
  INSERT_FINARRANGEMENTS_REQUEST: 'INSERT_FINARRANGEMENTS_REQUEST',
  INSERT_FINARRANGEMENTS_RESPONSE: 'INSERT_FINARRANGEMENTS_RESPONSE',
  INSERT_FINARRANGEMENTS_ERROR: 'INSERT_FINARRANGEMENTS_ERROR',
  UPDATE_FINARRANGEMENTS_REQUEST: 'UPDATE_FINARRANGEMENTS_REQUEST',
  UPDATE_FINARRANGEMENTS_RESPONSE: 'UPDATE_FINARRANGEMENTS_RESPONSE',
  UPDATE_FINARRANGEMENTS_ERROR: 'UPDATE_FINARRANGEMENTS_ERROR',
  RESET_DELETE_FINARRANGEMENTS_VALUE: 'RESET_FINARRANGEMENTS_DELETE_VALUE',
  RESET_APPROVE_FINARRANGEMENTS_VALUE: 'RESET_APPROVE_FINARRANGEMENTS_VALUE',
  RESET_IMPORT_FINARRANGEMENTS: 'RESET_IMPORT_FINARRANGEMENTS',
  LAST_IMPORT: 'LAST_IMPORT',
  // Delete
  DELETE_FINARRANGEMENTS_REQUEST: 'DELETE_FINARRANGEMENTS_REQUEST',
  DELETE_FINARRANGEMENTS_RESPONSE: 'DELETE_FINARRANGEMENTS_RESPONSE',
  DELETE_FINARRANGEMENTS_ERROR: 'DELETE_FINARRANGEMENTS_ERROR',
  APPROVE_FINARRANGEMENTS_REQUEST: 'APPROVE_FINARRANGEMENTS_REQUEST',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  SET_FINARRANGEMENTS_DETAILS_RESPONSE: 'SET_FINARRANGEMENTS_DETAILS_RESPONSE',
  LOG_FINARRANGEMENTS_DETAIL_REQUEST: 'LOG_FINARRANGEMENTS_DETAIL_REQUEST',
  GET_FINARRANGEMENTES_BY_ID_RESPONSE: 'GET_FINARRANGEMENTES_BY_ID_RESPONSE',
  // Update Filter Setting
  UPDATE_FILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_TABLE_CONFIGURATION',
  UPDATE_FINARRANGEMENTS_COLUMN_CONFIG: 'UPDATE_FINARRANGEMENTS_COLUMN_CONFIG',
  // Remove User Default Filter From Redux Store
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  // Update Server Error Section
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  // Reset store
  RESET_FINARRANGEMENTS_STORE: 'RESET_FINARRANGEMENTS_STORE',
};

export const manualAdjustmentsActionTypes = {
  GET_ON_LOAD_REQUEST: 'GET_ON_LOAD_REQUEST',
  LOG_ON_LOAD_REQUEST: 'LOG_ON_LOAD_REQUEST',
  GET_ON_LOAD_RESPONSE: 'GET_ON_LOAD_RESPONSE',
  RESET_STATE: 'RESET_MANUAL_ADJUSTMENTS_STATE',
  RESET_STORE: 'RESET_MANUAL_ADJUSTMENTS_STORE',
  SET_ERROR: 'SERVER_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  // for fetching header info
  GET_HEADER_INFO_REQUEST: 'ADJ_GET_HEADER_INFO_REQUEST',
  GET_HEADER_INFO_RESPONSE: 'ADJ_GET_HEADER_INFO_RESPONSE',
  RESET_HEADER_INFO: 'ADJ_RESET_HEADER_INFO',
  // transferAgent Name
  GET_MA_TA_NAME_REQUEST: 'GET_MA_TA_NAME_REQUEST',
  GET_MA_TA_NAME_RESPONSE: 'GET_MA_TA_NAME_RESPONSE',
  RESET_MA_TA_NAME: 'RESET_MA_TA_NAME',
  // policy info
  GET_POLICY_INFO_REQUEST: 'GET_POLICY_INFO_REQUEST',
  GET_POLICY_INFO_RESPONSE: 'GET_POLICY_INFO_RESPONSE',
  RESET_POLICY_INFO: 'RESET_POLICY_INFO',
  // Process adjustment
  PROCESS_ADJUSTMENT: 'PROCESS_ADJUSTMENT',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE_MADJ',
  // product info
  GET_PRODUCT_INFO_REQUEST: 'GET_PRODUCT_INFO_REQUEST',
  GET_PRODUCT_INFO_RESPONSE: 'GET_PRODUCT_INFO_RESPONSE',
  RESET_PRODUCT_INFO: 'RESET_PRODUCT_INFO',
  // online job request
  MADJ_JOB_REQUEST_RESPONSE: 'MADJ_JOB_REQUEST_RESPONSE',
  MADJ_JOB_REQUEST_ERROR: 'MADJ_JOB_REQUEST_ERROR',
  PROCESS_VALUES: 'PROCESS_VALUES',
};

// RELATIONSHIP SCREEN
export const relationshipActionsTypes = {
  GET_RELATIONSHIP_LIST_REQUEST: 'GET_RELATIONSHIP_LIST_REQUEST',
  UPDATE_RELATIONSHIP_COLUMN_CONFIG: 'UPDATE_RELATIONSHIP_COLUMN_CONFIG',
  UPDATE_TABLE_CONFIGURATION: 'UPDATE_RELATIONSHIP_TABLE_CONFIGURATION',
  UPDATE_RELATIONSHIP_FILTER_CONFIG: 'UPDATE_RELATIONSHIP_FILTER_CONFIG',
  GET_CS_EXPORT_REQUEST: 'GET_CS_EXPORT_REQUEST',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  GET_RELATIONSHIP_LIST_RESPONSE: 'GET_RELATIONSHIP_LIST_RESPONSE',
  DELETE_CLIENT_RELATIONSHIP_REQUEST: 'DELETE_CLIENT_RELATIONSHIP_REQUEST',
  GET_CLINET_RELATIONSHIP_EXPORT: 'GET_CLINET_RELATIONSHIP_EXPORT',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  RESET_REALTIONSHIP_INQUIRY_STORE: 'RESET_REALTIONSHIP_INQUIRY_STORE',
};

// BCR detail action types
export const eiDetailActionTypes = {
  LOG_EXI_DETAIL_REQUEST: 'LOG_EI_DETAIL_REQUEST',
  GET_EXI_DETAIL_LIST_REQUEST: 'GET_EI_DETAIL_LIST_REQUEST',
  GET_EXI_DETAIL_RESPONSE: 'GET_EI_DETAIL_RESPONSE',
  GET_EXI_DETAIL_ERROR: 'GET_EI_DETAIL_ERROR',
};

// licence action type
export const licencesActionTypes = {
  GET_LICENCE_REQUEST: 'GET_LICENCES_REQUEST',
  GET_LICENCE_LIST_REQUEST: 'GET_LICENCES_LIST_REQUEST',
  GET_LICENCE_RESPONSE: 'GET_LICENCES_RESPONSE',
  GET_LICENCE_LIST_ERROR: 'GET_LICENCE_LIST_ERROR',
  UPDATE_LICENCEFILTER_CONFIG: 'UPDATE_FILTER_CONFIG',
  UPDATE_LICENCETABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  UPDATE_LICENCE_COLUMN_CONFIG: 'UPDATE_LICENCE_COLUMN_CONFIG',
  GET_LICENCE_EXPORT_REQUEST: 'GET_LICENCE_EXPORT_REQUEST',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  SET_LICENCE_DETAILS_RESPONSE: 'SET_LICENCE_DETAILS_RESPONSE',
  RESET_LICENCE_STORE: 'RESET_LICENCE_STORE',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  UPDATE_LICENCE_RESPONSE: 'UPDATE_LICENCE_RESPONSE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  LOG_LICENCE_LIST_REQUEST: 'LOG_LICENCE_LIST_REQUEST',
  SET_SELECTED_ROWS: 'SET_SELECTED_ROWS',
  SET_SELECTED_ROWS_DATA: 'SET_SELECTED_ROWS_DATA',
  LICENCE_CHANGE_STATUS_REQ: 'LICENCE_CHANGE_STATUS_REQ',
  LOG_LICENCE_CHANGE_STATUS_REQ: 'LOG_LICENCE_CHANGE_STATUS_REQ',
  LICENCE_CHANGE_STATUS_RES: 'LICENCE_CHANGE_STATUS_RES',
  LICENCE_CHANGE_STATUS_ERROR: 'LICENCE_CHANGE_STATUS_ERROR',
  LOCK_CHANGE_STATUS: 'LOCK_CHANGE_STATUS',
  LOG_LOCK_CHANGE_STATUS_REQ: 'LOG_LOCK_CHANGE_STATUS_REQ',
  LOCK_CHANGE_STATUS_RES: 'LOCK_CHANGE_STATUS_RES',
  LOCK_CHANGE_STATUS_ERROR: 'LOCK_CHANGE_STATUS_ERROR',
  RELEASE_CHANGE_STATUS: 'RELEASE_CHANGE_STATUS',
};

// fiedForceLetter action type
export const fiedForceLetterActionTypes = {
  GET_FF_LETTER_LIST_REQUEST: 'GET_FF_LETTER_LIST_REQUEST',
  LOG_FF_LETTER_LIST_REQUEST: 'LOG_FF_LETTER_LIST_REQUEST',
  GET_FF_LETTER_LIST_RES: 'GET_FF_LETTER_LIST_RES',
  GET_FF_LETTER_LIST_ERROR: 'GET_FF_LETTER_LIST_ERROR',
  GET_FF_LETTER_LIST_EXPORT_REQ: 'GET_FF_LETTER_LIST_EXPORT_REQ',
  UPDATE_FF_LETTER_CONFIG: 'UPDATE_FF_LETTER_CONFIG',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  REMOVE_USER_DEFAULT_FILTER: 'REMOVE_USER_DEFAULT_FILTER',
  RESET_FF_LETTER_STORE: 'RESET_FF_LETTER_STORE',
  UPDATE_FF_LETTER_TABLE_CONFIG: 'UPDATE_FF_LETTER_TABLE_CONFIG',
  RESET_UPSERT_VALUE: 'RESET_UPSERT_VALUE',
  RESET_DELETE_VALUE: 'RESET_DELETE_VALUE',
  SET_FF_LETTER_DETAILS_RESPONSE: 'SET_FF_LETTER_DETAILS_RESPONSE',
  RESET_UPSERT_ERROR: 'RESET_UPSERT_ERROR',
  UPDATE_FF_LETTER_COLUMN_CONFIG: 'UPDATE_FF_LETTER_COLUMN_CONFIG',
};

export const selectLetterActionTypes = {
  SELECT_LETTERS_INSERT_ERROR: 'SELECT_LETTERS_INSERT_ERROR',
  LOG_SELECT_LETTERS_INSERT_REQ: 'LOG_SELECT_LETTERS_INSERT_REQ',
  SELECT_LETTERS_INSERT_RES: 'SELECT_LETTERS_INSERT_RES',
  SELECT_LETTERS_INSERT_REQ: 'SELECT_LETTERS_INSERT_REQ',
  GET_LETTERS_LIST_REQ: 'GET_LETTERS_LIST_REQ',
  LOG_GET_LETTERS_LIST_REQ: 'LOG_GET_LETTERS_LIST_REQ',
  GET_LETTERS_LIST_RES: 'GET_LETTERS_LIST_RES',
  GET_LETTERS_LIST_ERROR: 'GET_LETTERS_LIST_ERROR,',
  GET_SELECTED_ROWS: 'GET_SELECTED_ROWS',
  DOWNLOAD_LETTERS_TEMPLATE_REQ: 'DOWNLOAD_LETTERS_TEMPLATE_REQ',
  DOWNLOAD_LETTERS_RES: 'DOWNLOAD_LETTERS_RES',
  DOWNLOAD_LETTERS_ERROR: 'DOWNLOAD_LETTERS_ERROR',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
  DOWNLOAD_LETTERS_API_ERROR: 'DOWNLOAD_LETTERS_API_ERROR',
};

export const fieldForceDeductionActionTypes = {
  ...fieldForceDeductionActions,
  ...fieldForceRelationshipActions,
};
