/**
 * @Description Route configuartion for module agency
 * @FileName routeConfig.js
 * @Author awesome-react-cli
 * @CreatedOn 08 February, 2021 16:39:31
 * @IssueID NA
 */
import { lazy } from 'react';
import { routeConstant, aclKeysConstant } from '../moduleConstants';

const FASATSecuritySettings = 'FASAT - Security Settings';

export const AdminHome = lazy(() => import(
  /* webpackChunkName:"AdminHomeChunk" */ '../containers/home/home'
));
export const GenEdit = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/genEditTypeList/genEditTypeList'
));
export const GenEditVal = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeValues" */ '../containers/genEditTypeValueList/genEditTypeValueList'
));
export const SystemParameter = lazy(() => import(
  /* webpackChunkName:"AgencySystemParameters" */ '../containers/systemParametersList/systemParametersList'
));
export const PlanCodeList = lazy(() => import(
  /* webpackChunkName:"AgencyPlanCode}" */ '../containers/planCodeList/planCodeList'
));
export const GenEditRelationshipType = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/genEditRelationshipTypeList/genEditRelationshipTypeList'
));
export const DataDictionary = lazy(() => import(
  /* webpackChunkName:"AgencyDataDictionaryList" */ '../containers/dataDictionaryList/dataDictionaryList'
));
export const PaymentThreshold = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/paymentThresholdList/paymentThresholdList'
));
export const GenEditRelationshipVal = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/genEditRelationshipValList/genEditRelationshipValList'
));
export const InterfaceMapping = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/interfaceMappingList/interfaceMappingList'
));
export const TransactionDefinition = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/transactionDefinitionList/transactionDefinitionList'
));
export const TransactionDefinitionTab = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/transactionDefinitionTab/transactionDefinitionTab'
));
export const TransactionDefinitionStepDetail = lazy(() => import(
  /* webpackChunkName:"tDStepDetail" */ '../containers/transactionDefinitionStep/transactionDefinitionStepDetail'
));
export const SecurityDetailStep = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/transactionDefinitionStep/securityDetailStep'
));
export const AssociatedTransactionStepDetail = lazy(() => import(
  /* webpackChunkName:"tDStepDetail" */ '../containers/transactionDefinitionStep/associatedTransactionStepDetail'
));
export const SecuritySettings = lazy(() => import(
  /* webpackChunkName:"AgencyGenEditTypeList" */ '../containers/securitySettingsList/manageGroupList'
));
export const EditSecuritySetup = lazy(() => import(
  /* webpackChunkName:"EditSecuritySetup" */ '../components/editSecuritySetup/editSecuritySetup'
));
export const SystemOptions = lazy(() => import(
  /* webpackChunkName:"SystemOptions" */ '../containers/systemOptions/systemOptions'
));
export const Notification = lazy(() => import(
  /* webpackChunkName:"Notifications" */ '../containers/notifications/notifications'
));
export const IncomeExpenseMap = lazy(() => import(
  /* webpackChunkName:"AgencyIncomeExpenseMapList" */ '../containers/incomeExpenseMapList/incomeExpenseMapList'
));
export const PeriodDefinition = lazy(() => import(
  /* webpackChunkName:"AgencyPeriodDefinitionList" */ '../containers/periodDefinitionList/periodDefinitionList'
));
export const ChequeSecurity = lazy(() => import(
  /* webpackChunkName:"AgencyChequeSecurityList" */ '../containers/chequeSecurityList/chequeSecurityList'
));
export const ConfigureLetters = lazy(() => import(
  /* webpackChunkName:"AgencyConfigureLettersList" */ '../containers/configureLettersList/configureLettersList'
));
export const BatchProcessing = lazy(() => import(
  /* webpackChunkName:"AgencyDailyProcessList" */ '../containers/dailyProcessTab/dailyProcessTab'
));

export const ProducerAuditTrail = lazy(() => import(
  '../containers/producerAuditTrail/producerAuditTrail'
));

export const InterfaceSetup = lazy(() => import(
  /* webpackChunkName:"AgencyPeriodDefinitionList" */ '../containers/interfaceSetup/interfaceSetupList'
));

export const FieldForceStructureList = lazy(() => import(
  '../containers/fieldForceStructure/structureList'
));

export const AddEditFFStructure = lazy(() => import(
  '../components/addEditFieldForceStructure/addEditFFStructure'
));
export const ManageUsersSettings = lazy(() => import(
  /* webpackChunkName:"AgencyManageUser" */ '../containers/manageUserList/manageUsersList'
));
export const ManageLevel = lazy(() => import(
  '../containers/manageLevel/manageLevelList'
));
export const FieldforceSecurity = lazy(() => import(
  /* webpackChunkName:"AgencyManageUser" */ '../containers/fieldForceSecurity/fieldForceSecurityList'
));
export const routeConfig = [
  {
    path: routeConstant.HOME,
    exact: true,
    component: AdminHome,
    id: 'AdminHome',
    title: 'FASAT - Admin Home',
    pageName: 'FASAT - Admin Home', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.ADMINISTRATION_HOME],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.GEN_EDIT_ROUTE,
    exact: true,
    component: GenEdit,
    id: 'AdminGenEdit',
    title: 'FASAT - Gen Edit',
    pageName: 'FASAT - Gen Edit', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.GEN_EDIT],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.GEN_EDIT_VAL_ROUTE,
    exact: true,
    component: GenEditVal,
    id: 'AdminGenEdit',
    title: 'Values',
    pageName: 'FASAT - Gen Edit Values', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_GET_PARENT_DETAIL],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.SYSTEM_PARAMETER_ROUTE,
    exact: true,
    component: SystemParameter,
    id: 'AdminSystemParamater',
    title: 'Values',
    pageName: 'FASAT - System Parameter', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.SYSPARMS_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.PLAN_CODE_ROUTE,
    exact: true,
    component: PlanCodeList,
    id: 'AdminPlanCode',
    title: 'Values',
    pageName: 'FASAT - Plan Code', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.PLC_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.GENERIC_EDIT_RELATIONSHIP_ROUTE,
    exact: true,
    component: GenEditRelationshipType,
    id: 'AdminGenEditRelationship',
    title: 'Values',
    pageName: 'FASAT - Gen Edit Relationship', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_GER_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.GENERIC_EDIT_RELATIONSHIP_VAL_ROUTE,
    exact: true,
    component: GenEditRelationshipVal,
    id: 'AdminGenEditRelationshipVal',
    title: 'FASAT - Generic Edit Relationship Values',
    pageName: 'FASAT - Generic Edit Relationship Values', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_GER_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.DATA_DICTIONARY_ROUTE,
    exact: true,
    component: DataDictionary,
    id: 'AdminDataDictionary',
    title: 'FASAT - Data Dictionary',
    pageName: 'FASAT - Data Dictionary', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.DD_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.PAYMENT_THRESHOLD_ROUTE,
    exact: true,
    component: PaymentThreshold,
    id: 'AdminPaymentThresold',
    title: 'FASAT - Payment Thresold',
    pageName: 'FASAT - Payment Thresold', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_PTH_MENU],
    acl: false,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.INTERFACE_MAPPING_ROUTE,
    exact: true,
    component: InterfaceMapping,
    id: 'AdminInterfaceMapping',
    title: 'FASAT - Interface Mapping',
    pageName: 'FASAT - Interface Mapping', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_IM_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.SECURITY_SETTINGS_ROUTE,
    exact: true,
    component: SecuritySettings,
    id: 'AdminSecuritySettings',
    title: FASATSecuritySettings,
    pageName: FASATSecuritySettings, // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_MG_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.EDIT_SECURITY_SETUP,
    exact: true,
    component: EditSecuritySetup,
    id: 'AdminEditSecuritySetup',
    title: FASATSecuritySettings,
    pageName: FASATSecuritySettings, // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_MG_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.SYSTEM_OPTIONS_ROUTE,
    exact: true,
    component: SystemOptions,
    id: 'AdminSystemOptions',
    title: 'FASAT - System options',
    pageName: 'FASAT - System options', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_SO_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.NOTIFICATIONS_ROUTE,
    exact: true,
    component: Notification,
    id: 'AdminNotification',
    title: 'FASAT - Notification',
    pageName: 'FASAT - Notification', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_NF_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.TRANSACTION_DEFINITION_ROUTE,
    exact: true,
    component: TransactionDefinition,
    id: 'AdminTransactionDefinition',
    title: 'FASAT - Transaction Definition',
    pageName: 'FASAT - Transaction Definition', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_TD_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.TRANSACTION_DEFINITION_DETAIL_ROUTE,
    exact: true,
    component: TransactionDefinitionTab,
    id: 'AdminTransactionDefinitionTab',
    title: 'FASAT - Transaction Definition Tab',
    pageName: 'FASAT - Transaction Definition Tab', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_TD_MENU], // TODOD
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.TRANSACTION_DEFINITION_STEP_ROUTE,
    exact: true,
    component: TransactionDefinitionStepDetail,
    id: 'AdminTransactionDefinitionDetailSteps',
    title: 'FASAT - Transaction Definition Detail Steps',
    pageName: 'FASAT - Transaction Definition Detail Steps', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_TD_ADD],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.INCOME_EXPENSE_MAP_ROUTE,
    exact: true,
    component: IncomeExpenseMap,
    id: 'AdminIncomeExpenseMap',
    title: 'FASAT - Income Expense Map',
    pageName: 'FASAT - Income Expense Map', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_IEM_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.PERIOD_DEFINITION_ROUTE,
    exact: true,
    component: PeriodDefinition,
    id: 'AdminPeriodDefinition',
    title: 'Values',
    pageName: 'FASAT - Period Definition', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_PD_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.TRANSACTION_DEFINITION_SECDETAIL_STEP_ROUTE,
    exact: true,
    component: SecurityDetailStep,
    id: 'AdminSecurityDetailStep',
    title: 'FASAT - Security Detail Steps',
    pageName: 'FASAT - Security Detail Steps', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_TD_ADD],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.TRANSACTION_DEFINITION_ASSOCTRANS_STEP_ROUTE,
    exact: true,
    component: AssociatedTransactionStepDetail,
    id: 'AdminAssociatedTransactionStepDetail',
    title: 'FASAT - Associated Transaction Detail Steps',
    pageName: 'FASAT - Associated Transaction Detail Steps', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_TD_ADD],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.CHEQUE_SECURITY_ROUTE,
    exact: true,
    component: ChequeSecurity,
    id: 'AdminChequeSecurity',
    title: 'FASAT - Cheque Security',
    pageName: 'FASAT - Cheque Security', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_CHS_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.CONFIGURE_LETTERS_ROUTE,
    exact: true,
    component: ConfigureLetters,
    id: 'AdminConfigureLetters',
    title: 'FASAT - ConfigureLetters',
    pageName: 'FASAT - ConfigureLetters', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_CLTR_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.BATCH_PROCESSING_ROUTE,
    exact: true,
    component: BatchProcessing,
    id: 'AdminDailyProcess',
    title: 'FASAT - Batch Processing',
    pageName: 'FASAT - Batch Processing', // page name to send in error message log service
    authenticationRequired: false,
    aclKey: [aclKeysConstant.RBAC_DP_MENU],
    acl: true,
    showInBatchProcess: true,
    public: false,
  },
  {
    path: routeConstant.INTERFACE_SETUP_ROUTE,
    exact: true,
    component: InterfaceSetup,
    id: 'AdminInterfaceSetup',
    title: 'FASAT - Interface Setup',
    pageName: 'FASAT - Interface Setup', // page name to send in error message log service
    authenticationRequired: false,
    aclKey: [aclKeysConstant.ADMIN_MENU],
    acl: true,
    public: false,
  },
  {
    path: routeConstant.PRODUCER_AUDIT_TRAIL_ROUTE,
    exact: true,
    component: ProducerAuditTrail,
    id: 'AdminProducerAuditTrail',
    title: 'FASAT - ProducerAuditTrail',
    pageName: 'FASAT - ProducerAuditTrail',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_PAT_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_STRUCTURE_ROUTE,
    exact: true,
    component: FieldForceStructureList,
    id: 'FieldForceStructure',
    title: 'FASAT - FieldForceStructure',
    pageName: 'FASAT - FieldForceStructure',
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_FFS_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_STRUCTURE_ROUTE_ADD,
    exact: true,
    component: AddEditFFStructure,
    id: 'FieldforceStructureAdd',
    title: 'FASAT - FieldforceStructureAdd',
    pageName: 'FASAT - FieldforceStructureAdd', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_FFS_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_STRUCTURE_ROUTE_EDIT,
    exact: true,
    component: AddEditFFStructure,
    id: 'FieldforceStructureEdit',
    title: 'FASAT - FieldforceStructureEdit',
    pageName: 'FASAT - FieldforceStructureEdit', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_FFS_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.MANAGE_USER_ROUTE,
    exact: true,
    component: ManageUsersSettings,
    id: 'AdminManageUser',
    title: 'FASAT - Manage User',
    pageName: 'FASAT - Manage User', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_SSU_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.MANAGE_LEVEL_ROUTE,
    exact: true,
    component: ManageLevel,
    id: 'AdminManageLevel',
    title: 'FASAT - Manage Level',
    pageName: 'FASAT - Manage Level', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_MG_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
  {
    path: routeConstant.FIELD_FORCE_SECURITY_ROUTE,
    exact: true,
    component: FieldforceSecurity,
    id: 'Fieldforcesecurity',
    title: 'FASAT - FieldforceSecurity',
    pageName: 'FASAT - FieldforceSecurity', // page name to send in error message log service
    authenticationRequired: true,
    aclKey: [aclKeysConstant.RBAC_FFSE_MENU],
    acl: true,
    showInBatchProcess: false,
    public: false,
  },
];
export default routeConfig;
