/**
 * @Description Reducer for License tab
 * @FileName clientLicense.js
 * @Author Imroz Dawar - dawarim
 * @CreatedOn 29 January, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { ClientCommonActionTypes } from '../actions/actionTypes';

const ClientTabs = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    case ClientCommonActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_RESPONSE:
      return {
        ...state,
        listData: action.data,
      };
    case ClientCommonActionTypes.GET_CLIENT_DETAIL_BY_ID_RESPONSE:
      return {
        ...state,
        detailDataById: action.data,
      };
    case ClientCommonActionTypes.GET_CLIENT_DETAIL_DEFAULT_RESPONSE:
      return {
        ...state,
        defaultData: action.data,
      };
    case ClientCommonActionTypes.GET_CLIENT_DETAIL_TAB_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case ClientCommonActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case ClientCommonActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case ClientCommonActionTypes.UPSERT_CLIENT_DETAIL_TAB_REQUEST:
      return {
        ...state,
        upsertRequestedOn: new Date(),
        upsertData: action.data.upsertData,
        loading: true,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default ClientTabs;
