/**
 * @Description Reducer for business card rules
 * @FileName structureFilter.js
 * @Author Prasenjit Chakraborty - chakpra
 * @CreatedOn 11 April, 2021 04:15:36
 * @IssueID - Issue IDs
 */

import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { ffStructureActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'StructureFilterList';

const updateFilterOption = (state, action) => ({
  ...state,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
});

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const getFFStructureListResponse = (state, action) => ({
  ...state,
  listData: action.data,
  listRequestError: null,
  listRequestProcessedOn: new Date(),
  loading: false,
});

const getResetFFStructureStore = (state) => ({
  ...state,
  listData: null,
  listRequestError: null,
  listRequestProcessedOn: null,
  serverRequestError: null,
  loading: false,
});

const ffStructureFilterListReq = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case ffStructureActionTypes.UPDATE_FF_STRUCTURE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case ffStructureActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    default:
      return null;
  }
};

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
});

const ffStructureFilterConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case ffStructureActionTypes.GET_FF_STRUCTURE_LIST_RESPONSE:
      return getFFStructureListResponse(state, action);
    case ffStructureActionTypes.RESET_FF_STRUCTURE_INQUIRY_STORE:
      return getResetFFStructureStore(state);
    case ffStructureActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const StructureFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateReqRes = ffStructureFilterListReq(state, action);
  const stateConfig = ffStructureFilterConfig(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default StructureFilterList;
