/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/**
 * @Description This is FasatRouterLink component page.
 * This is a wrapper on react-router-dom "Link" component from
 * MUX without adding any custom functionlities
 * @FileName fasaRoutertLink.js
 * @Author Samiran Das- dasamir
 * @CreatedOn 3 June, 2021 20:08:00
 */
import React from 'react';
import { Link } from 'react-router-dom';
import './fasatRouterLink.scss';

const FasatRouterLink = (props) => (
  <>
    <span className="fasatRouterLink">
      <Link {...props}>{props.children}</Link>
    </span>
  </>
);

export default FasatRouterLink;
