/**
 * @Description This is FasatButtonSelector component page.
 * This is a wrapper on "ButtonSelector" component from MUX without adding any custom functionlities
 * @FileName fasatButtonSelector.js
 * @Author Imroz Dawar- dawarim
 * @CreatedOn 5 February, 2021 12:02:10
 * @IssueID CDTFASATMOD- 325
 */
import React from 'react';
import { ButtonSelector } from '@manulife/mux';
import './fasatButtonSelector.scss';
import PropTypes from 'prop-types';

const fasatButtonSelector = 'fasatButtonSelector';
const FasatButtonSelector = (props) => {
  const { onHandleChange } = props;
  const changeHandler = (val) => {
    onHandleChange(val);
  };
  return (
    <div className={fasatButtonSelector}>
      <ButtonSelector {...props} onChange={changeHandler} />
    </div>
  );
};
FasatButtonSelector.propTypes = {
  onHandleChange: PropTypes.func,
};
FasatButtonSelector.defaultProps = {
  onHandleChange: () => undefined,
};
export default FasatButtonSelector;
