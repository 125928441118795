import adminResourceFrench from '../../../modules/admin/i18n/adminResourceFrench';
import agencyResourceFrench from '../../../modules/agency/i18n/agencyResourceFrench';
import activityResourcesFrench from '../../../modules/activity/i18n/activityResourcesFrench';
import french from '../french';
/**
 * @Description Resource file for french
 * @FileName french.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 15 January, 2021 05:06:13
 * @IssueID - 305
*/
const defaultMessage = {
  'default.message': 'F : Welcome...',
};

const frLang = {
  static: {
    ...defaultMessage,
    ...adminResourceFrench,
    ...agencyResourceFrench,
    ...activityResourcesFrench,
    ...french,
  },
};
export default frLang;
