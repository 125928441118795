/* eslint-disable no-unused-vars */
/**
 * @Description This file contains all the Menu configuration for administration module
 * @FileName menuConfig.js
 * @Author Abhisek Kundu - kundabh
 * @CreatedOn 26 february, 2022 16:41:03
 * @IssueID - 8376
 */
import { Calculator } from '@manulife/mux-cds-icons';
import { navigate } from '../../../common/util';
import { aclKeysConstant, routeConstant, staticLabelKeys } from '../moduleConstants';
import { t } from '../../../common/i18n';
/**
  * Menu configuration for the module
  */
const desktopMenus = () => [
  {
    id: 'paymentprocessing',
    sequence: 1,
    icon: <Calculator style={{ width: '20px', height: '20px' }} />,
    label: t(staticLabelKeys.PP_MENU),
    aria: t(staticLabelKeys.PP_MENU),
    urlPath: '#',
    url: '#',
    action: () => navigate('#'),
    aclKey: [aclKeysConstant.RBAC_PAYMENTS],
    acl: true,
    showInBatchProcess: false,
    subMenu: [
      {
        id: 'PAYMENT_PROCESSING_MENU',
        label: t(staticLabelKeys.PP_TITLE_LIST),
        urlPath: routeConstant.PAYMENT_PROCESS_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.PAYMENT_PROCESS_ROUTE),
        aclKey: [aclKeysConstant.RBAC_PP_MENU],
        acl: true,
        showInBatchProcess: false,
      },
    ],
  },
];
const mobileMenus = desktopMenus;
export { desktopMenus, mobileMenus };
