/**
 * @Description This file contains all the Menu configuration for administration module
 * @FileName menuConfig.js
 * @Author Jayanta Sarkhel- sarkhja
 * @CreatedOn 15 february, 2022 16:41:03
 * @IssueID - 459, 311
 */
import { Calculator } from '@manulife/mux-cds-icons';
import { t } from '../../../common/i18n';
import { navigate } from '../../../common/util';
import { aclKeysConstant, routeConstant, staticLabelKeys } from '../moduleConstants';
/**
  * Menu configuration for the module
  */
const desktopMenus = () => [
  {
    id: 'compcalRates',
    sequence: 1,
    icon: <Calculator style={{ width: '20px', height: '20px' }} />,
    label: t(staticLabelKeys.EXRATE_TITLE_RATES),
    aria: t(staticLabelKeys.EXRATE_TITLE_RATES),
    urlPath: '#',
    url: '#',
    action: () => navigate('#'),
    aclKey: [aclKeysConstant.RBAC_EXRATE],
    acl: true,
    showInBatchProcess: true,
    // external: true,
    subMenu: [
      {
        id: 'EXTERNAL_RATES_MENU',
        label: t(staticLabelKeys.EXRATE_TITLE_RATES),
        urlPath: routeConstant.EXTERNAL_RATE_ROUTE,
        url: '#',
        action: () => navigate(routeConstant.EXTERNAL_RATE_ROUTE),
        aclKey: [aclKeysConstant.RBAC_EXRATE_MENU],
        acl: true,
        showInBatchProcess: true,
        // external: true,
      },
    ],
  },
];
const mobileMenus = desktopMenus;
export { desktopMenus, mobileMenus };
