import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { genEditRelationshipActionType } from '../actions/actionTypes';
import { updateFilterReducer, updateTableConfig } from './utilities/utility';

export const REDUCER_NAME = 'GenEditRelationshipTypeFilterList';

const logGerListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const GenEditRelationshipTypeFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    case genEditRelationshipActionType.UPDATE_GER_FILTER_CONFIG:
      return {
        ...state,
        ...updateFilterReducer(action),
      };
    case genEditRelationshipActionType.REMOVE_GER_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case genEditRelationshipActionType.UPDATE_GER_COLUMN_CONFIG:
      return {
        ...state,
        listSortOptions: action.data.sortOptions,
        listColumnOrder: action.data.columnOrder,
      };
    case genEditRelationshipActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case genEditRelationshipActionType.RESET_GER_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case genEditRelationshipActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    default:
      return null;
  }
};

const GenEditRelationshipTypeFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case genEditRelationshipActionType.LOG_GER_LIST_REQUEST:
      return logGerListReq(state, action);
      // log error
    case genEditRelationshipActionType.GET_GER_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
      // store response
    case genEditRelationshipActionType.GET_GER_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case genEditRelationshipActionType.GET_GER_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};

const GenEditRelationshipTypeFilterList = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = GenEditRelationshipTypeFilterListConfig(state, action);
  const stateReqRes = GenEditRelationshipTypeFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default GenEditRelationshipTypeFilterList;
