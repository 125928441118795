import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { genEditValActionTypes, genEditValParentActionTypes } from '../actions/actionTypes';

const GenEditValues = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case genEditValActionTypes.SET_GEV_DETAILS_RESPONSE:
      return {
        ...state,
        subModuledetails: action.data,
      };
    case genEditValActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    // reset Upset Value
    case genEditValActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
      };
    // reset Delete Value
    case genEditValActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    // gev detail data
    case genEditValParentActionTypes.GET_GEV_PARENT_RESPONSE:
      return {
        ...state,
        detailData: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    // gev detail log request
    case genEditValParentActionTypes.LOG_GEV_PARENT_REQUEST:
      return {
        ...state,
        detailRequestedOn: new Date(),
        loading: true,
      };
    // gev detail upsert request
    case genEditValParentActionTypes.GET_GEV_RESET_UPSERT_VALUE:
      return {
        ...state,
        detailData: [],
        loading: true,
      };
    // gev error
    case genEditValParentActionTypes.GET_GEV_PARENT_ERROR:
      return {
        ...state,
        detailRequestError: action.data,
        detailRequestProcessedOn: new Date(),
        loading: false,
      };
    case commonTypes.RESETSTORE:
      return basicListAddEditDltInitialState;
    default:
      return state;
  }
};

export default GenEditValues;
