/**
 * @Description File description.
 * @FileName moduleConstants.js
 * @Author Arnab Sutar - sutaarn
 * @CreatedOn 25 January, 2021 08:39:44
 * @IssueID - 311
 */
/**
   * All the route constants for the module
   */
const ROUTE_HOME = '/reports';
export const routeConstant = {
  REP_HOME_ROUTE: `${ROUTE_HOME}/home`,
  PROD_SUMMARY_ROUTE: `${ROUTE_HOME}/prodSummary`,
  BAL_SUMMARY_ROUTE: `${ROUTE_HOME}/balSummary`,
  REP_REPOSITORY_ROUTE: `${ROUTE_HOME}/reportRepository`,
  REP_REPOSITORY_EXT_ROUTE: `${ROUTE_HOME}/reportRepositoryExt`,
  COMP_STATEMENT_ROUTE: `${ROUTE_HOME}/compStatement`,
  REPORT_CENTER_ROUTE: `${ROUTE_HOME}/repcenter`,
  TEMPLATE_ROUTE: `${ROUTE_HOME}/repcenter/template`,
  // COMMISSION_FEED_ROUTE: `${ROUTE_HOME}/commisioFeed`,
  INTERACTIVE_ACTIVITY_REPORT_ROUTE: `${ROUTE_HOME}/interactiveAcitvityReports`,
  COMMISSION_FEED_ROUTE: `${ROUTE_HOME}/commisionfeed`,
};

/**
   * ACL keys for the module
   */
export const aclKeysConstant = {
  ADMIN_MENU: 'RBAC_ADMINISTRATION',
  RBAC_REPORTS: 'RBAC_REPORTS',
  RBAC_REP_PRODUCTION_SUMMARY: 'RBAC_REP_PRODUCTION_SUMMARY',
  RBAC_REP_BALANCE_SUMMARY: 'RBAC_REP_BALANCE_SUMMARY',
  RBAC_PS_MENU: 'RBAC_PSU_MENU',
  RBAC_PS_EXPORT: 'RBAC_PSU_EXPORT',
  RBAC_BLS_MENU: 'RBAC_BLS_MENU',
  RBAC_BLS_EXPORT: 'RBAC_BLS_EXPORT',
  PRODUCTION_SUMMARY_MENU: 'RBAC_PSU_MENU',
  BALANCE_SUMMARY_MENU: 'RBAC_BLS_MENU',

  RBAC_CSR_MENU: 'RBAC_CSR_MENU',
  RBAC_CSR_DOWNLOAD_SELECTED: 'RBAC_CSR_DOWNLOAD_SELECTED',
  RBAC_CSR_PRINT: 'RBAC_CSR_PRINT',
  RBAC_CSR_VIEW: 'RBAC_CSR_VIEW',
  RBAC_CSR_EXPORT: 'RBAC_CSR_EXPORT',
  RBAC_COMP_STATEMENT: 'RBAC_COMP_STATEMENT',
  RBAC_CF_MENU: 'RBAC_CF_MENU',

  // Compensation statements
  RBAC_ECS_MENU: 'RBAC_ECS_MENU',
  RBAC_ECS_DOWNLOAD_SELECTED: 'RBAC_ECS_DOWNLOAD_SELECTED',
  RBAC_ECS_VIEW: 'RBAC_ECS_VIEW',
  RBAC_EXT_COMP_STATEMENT: 'RBAC_EXT_COMP_STATEMENT',
  COMPENSATION_STATEMENT_DASHBOARD_MENU: 'COMPENSATION_STATEMENT_DASHBOARD_MENU',
  RBAC_EXT_COMP_STATEMENT_DOWNLOAD: 'RBAC_EXT_COMP_STATEMENT_DOWNLOAD',
  // Interactive Activity Report
  RBAC_IAR_MENU: 'RBAC_IAR_MENU',
  RBAC_IAR_EXPORT: 'RBAC_IAR_EXPORT',

  RBAC_CMS_MENU: 'RBAC_CMS_MENU',
  RBAC_CMS_GENERATE_REPORT: 'RBAC_CMS_GENERATE_REPORT',
};

/**
   * i18n Keys for the module
   */
export const staticLabelKeys = {
  REPORT_MENU: 'RBAC_REPORTS',
  REP_PRODUCTION_SUMMARY: 'REP_PRODUCTION_SUMMARY',
  REP_BALANCE_SUMMARY: 'REP_BALANCE_SUMMARY',
  PRODSUM_PAGE_TITLE: 'PRODSUM_PAGE_TITLE',
  PRODSUM_LABEL_MSC: 'PRODSUM_LABEL_MSC',
  PRODSUM_LABEL_BFN: 'PRODSUM_LABEL_BFN',
  PRODSUM_LABEL_BLN: 'PRODSUM_LABEL_BLN',
  PRODSUM_LABEL_PAYEE: 'PRODSUM_LABEL_PAYEE',
  PRODSUM_LABEL_FFT: 'PRODSUM_LABEL_FFT',
  PRODSUM_LABEL_COMP: 'PRODSUM_LABEL_COMP',
  PRODSUM_LABEL_FFS: 'PRODSUM_LABEL_FFS',
  PRODSUM_LABEL_STATUSDT: 'PRODSUM_LABEL_STATUSDT',
  PRODSUM_LABEL_CONTRACTDT: 'PRODSUM_LABEL_CONTRACTDT',
  PRODSUM_LABEL_TERMINATIONDT: 'PRODSUM_LABEL_TERMINATIONDT',
  PRODSUM_LABEL_ACTIVEREC: 'PRODSUM_LABEL_ACTIVEREC',
  PRODSUM_LABEL_AFFILIATE: 'PRODSUM_LABEL_AFFILIATE',
  PRODSUM_LABEL_CORPORATION: 'PRODSUM_LABEL_CORPORATION',
  PRODSUM_LABEL_TEMPLEV: 'PRODSUM_LABEL_TEMPLEV',
  PRODSUM_LABEL_ALTERNATE: 'PRODSUM_LABEL_ALTERNATE',
  PRODSUM_LABEL_EFFECTIVEDT: 'PRODSUM_LABEL_EFFECTIVEDT',
  PRODSUM_LABEL_MOVEMENTDT: 'PRODSUM_LABEL_MOVEMENTDT',
  PRODSUM_LABEL_FYC_GROSS: 'PRODSUM_LABEL_FYC_GROSS',
  PRODSUM_LABEL_FYC_GROSS_YTD: 'PRODSUM_LABEL_FYC_GROSS_YTD',
  PRODSUM_LABEL_FYC: 'PRODSUM_LABEL_FYC',
  PRODSUM_LABEL_FYCYTD: 'PRODSUM_LABEL_FYCYTD',
  PRODSUM_LABEL_FYO: 'PRODSUM_LABEL_FYO',
  PRODSUM_LABEL_FYOYTD: 'PRODSUM_LABEL_FYOYTD',
  PRODSUM_LABEL_FYC_CHARGE: 'PRODSUM_LABEL_FYC_CHARGE',
  PRODSUM_LABEL_FYC_CHARGE_YTD: 'PRODSUM_LABEL_FYC_CHARGE_YTD',
  PRODSUM_LABEL_FYO_CHARGE: 'PRODSUM_LABEL_FYO_CHARGE',
  PRODSUM_LABEL_FYO_CHARGE_YTD: 'PRODSUM_LABEL_FYO_CHARGE_YTD',
  PRODSUM_LABEL_FYC_COMMISSION: 'PRODSUM_LABEL_FYC_COMMISSION',
  PRODSUM_LABEL_FYC_COMMISSION_YTD: 'PRODSUM_LABEL_FYC_COMMISSION_YTD',
  PRODSUM_LABEL_POLICY: 'PRODSUM_LABEL_POLICY',
  PRODSUM_LABEL_POLICY_YTD: 'PRODSUM_LABEL_POLICY_YTD',
  PRODSUM_LABEL_REN: 'PRODSUM_LABEL_REN',
  PRODSUM_LABEL_REN_YTD: 'PRODSUM_LABEL_REN_YTD',
  PRODSUM_LABEL_RENEWAL_COMM: 'PRODSUM_LABEL_RENEWAL_COMM',
  PRODSUM_LABEL_RENEWAL_COMM_YTD: 'PRODSUM_LABEL_RENEWAL_COMM_YTD',
  PRODSUM_LABEL_RENEWAL: 'PRODSUM_LABEL_RENEWAL',
  PRODSUM_LABEL_RENEWAL_YTD: 'PRODSUM_LABEL_RENEWAL_YTD',
  PRODSUM_LABEL_RENEWAL_PREM: 'PRODSUM_LABEL_RENEWAL_PREM',
  PRODSUM_LABEL_SALES: 'PRODSUM_LABEL_SALES',
  PRODSUM_LABEL_SALES_YTD: 'PRODSUM_LABEL_SALES_YTD',
  PRODSUM_LABEL_PERSISTENCY: 'PRODSUM_LABEL_PERSISTENCY',
  PRODSUM_LABEL_REFERRAL: 'PRODSUM_LABEL_REFERRAL',
  PRODSUM_LABEL_REFERRAL_YTD: 'PRODSUM_LABEL_REFERRAL_YTD',
  PRODSUM_LABEL_REFERRAL_GROSS: 'PRODSUM_LABEL_REFERRAL_GROSS',
  PRODSUM_LABEL_REFERRAL_GROSS_YTD: 'PRODSUM_LABEL_REFERRAL_GROSS_YTD',
  PRODSUM_LABEL_EQUALIZED: 'PRODSUM_LABEL_EQUALIZED',
  PRODSUM_LABEL_EQUALIZED_YTD: 'PRODSUM_LABEL_EQUALIZED_YTD',
  PRODSUM_LABEL_EQUALIZED_OVERRIDE: 'PRODSUM_LABEL_EQUALIZED_OVERRIDE',
  PRODSUM_LABEL_EQUALIZED_OVERRIDE_YTD: 'PRODSUM_LABEL_EQUALIZED_OVERRIDE_YTD',
  PRODSUM_LABEL_PERSISTENCY_LAPSES: 'PRODSUM_LABEL_PERSISTENCY_LAPSES',
  PRODSUM_LABEL_EXPORT_MOD_TITLE: 'PRODSUM_LABEL_EXPORT_MOD_TITLE',
  BLS_LABEL_EXPORT_MOD_TITLE: 'BLS_LABEL_EXPORT_MOD_TITLE',
  BLS_PAGE_TITLE: 'BLS_PAGE_TITLE',
  BLS_LABEL_COMP_SCH: 'BLS_LABEL_COMP_SCH',
  BLS_LABEL_FF_STATUS: 'BLS_LABEL_FF_STATUS',
  BLS_LABEL_TYPE: 'BLS_LABEL_TYPE',
  BLS_LABEL_BAL_FORWARD: 'BLS_LABEL_BAL_FORWARD',
  BLS_LABEL_CURR_BAL: 'BLS_LABEL_CURR_BAL',
  BLS_LABEL_WRITEOFF: 'BLS_LABEL_WRITEOFF',
  BLS_LABEL_WRITEOFF_OV: 'BLS_LABEL_WRITEOFF_OV',
  BLS_LABEL_WRITEOFF_AMT: 'BLS_LABEL_WRITEOFF_AMT',
  BLS_LABEL_WRITEOFF_AMT_OV: 'BLS_LABEL_WRITEOFF_AMT_OV',
  BLS_LABEL_RESCAP_ACCFW: 'BLS_LABEL_RESCAP_ACCFW',
  BLS_LABEL_RESCAP_ACCYTD: 'BLS_LABEL_RESCAP_ACCYTD',
  BLS_LABEL_ROLL_DEB_FW: 'BLS_LABEL_ROLL_DEB_FW',
  BLS_LABEL_ROLL_DEB_BAL: 'BLS_LABEL_ROLL_DEB_BAL',
  BLS_LABEL_MAX_DEB_ALLW: 'BLS_LABEL_MAX_DEB_ALLW',
  BLS_LABEL_MAXDEBALLW_EXP_DT: 'BLS_LABEL_MAXDEBALLW_EXP_DT',
  BLS_LABEL_REN_OV: 'BLS_LABEL_REN_OV',
  BLS_LABEL_DEDC: 'BLS_LABEL_DEDC',
  BLS_LABEL_MSC_ADJ: 'BLS_LABEL_MSC_ADJ',
  BLS_LABEL_TRANS: 'BLS_LABEL_TRANS',
  BLS_LABEL_PYMT: 'BLS_LABEL_PYMT',
  BLS_LABEL_RES_CAP: 'BLS_LABEL_RES_CAP',
  BLS_LABEL_ROLL_DEB_TRANS: 'BLS_LABEL_ROLL_DEB_TRANS',
  BLS_LABEL_TAX_EAR: 'BLS_LABEL_TAX_EAR',
  BLS_LABEL_TAX_BEN: 'BLS_LABEL_TAX_BEN',
  BLS_LABEL_MDRT: 'BLS_LABEL_MDRT',
  BLS_LABEL_REN_OV_YTD: 'BLS_LABEL_REN_OV_YTD',
  BLS_LABEL_DEDC_YTD: 'BLS_LABEL_DEDC_YTD',
  BLS_LABEL_MSC_ADJ_YTD: 'BLS_LABEL_MSC_ADJ_YTD',
  BLS_LABEL_TRANS_YTD: 'BLS_LABEL_TRANS_YTD',
  BLS_LABEL_PYMT_YTD: 'BLS_LABEL_PYMT_YTD',
  BLS_LABEL_RES_CAP_ACC_BAL: 'BLS_LABEL_RES_CAP_ACC_BAL',
  BLS_LABEL_DEB_TRANS_YTD: 'BLS_LABEL_DEB_TRANS_YTD',
  BLS_LABEL_TAX_BEN_YTD: 'BLS_LABEL_TAX_BEN_YTD',
  BLS_LABEL_TAX_EAR_YTD: 'BLS_LABEL_TAX_EAR_YTD',
  BLS_LABEL_MDRT_YTD: 'BLS_LABEL_MDRT_YTD',
  BLS_LABEL_DET_REC_CNT: 'BLS_LABEL_DET_REC_CNT',
  BLS_LABEL_PER_ID: 'BLS_LABEL_PER_ID',
  BLS_LABEL_PYMT_MTH: 'BLS_LABEL_PYMT_MTH',
  BLS_LABEL_BANK_NO: 'BLS_LABEL_BANK_NO',
  BLS_LABEL_BRCH_NO: 'BLS_LABEL_BRCH_NO',
  BLS_LABEL_ACCNO: 'BLS_LABEL_ACCNO',
  BLS_LABEL_TRANS_TO: 'BLS_LABEL_TRANS_TO',
  BLS_LABEL_PYMT_STAT: 'BLS_LABEL_PYMT_STAT',
  BLS_LABEL_TAXFRM: 'BLS_LABEL_TAXFRM',
  BLS_LABEL_INS_ADV: 'BLS_LABEL_INS_ADV',
  BLS_LABEL_INS_AFF: 'BLS_LABEL_INS_AFF',
  BLS_LABEL_INS_CORP: 'BLS_LABEL_INS_CORP',
  BLS_LABEL_INS_TEMP: 'BLS_LABEL_INS_TEMP',
  BLS_LABEL_INS_LOC: 'BLS_LABEL_INS_LOC',
  BLS_LABEL_INS_ALT: 'BLS_LABEL_INS_ALT',
  BLS_LABEL_INS_DIST: 'BLS_LABEL_INS_DIST',
  BLS_LABEL_INS_REG: 'BLS_LABEL_INS_REG',
  BLS_LABEL_INS_CHA: 'BLS_LABEL_INS_CHA',
  BLS_LABEL_INS_COM: 'BLS_LABEL_INS_COM',
  BLS_LABEL_NEG_DEB: 'BLS_LABEL_NEG_DEB',
  BLS_LABEL_NEG_DEB_FWD: 'BLS_LABEL_NEG_DEB_FWD',
  BLS_LABEL_NEG_DEB_TRANS: 'BLS_LABEL_NEG_DEB_TRANS',
  BLS_LABEL_GRP_PORBAL: 'BLS_LABEL_GRP_PORBAL',
  BLS_LABEL_CB_DEB_STRTDT: 'BLS_LABEL_CB_DEB_STRTDT',
  BLS_LABEL_NEG_DEB_STRTDT: 'BLS_LABEL_NEG_DEB_STRTDT',

  REPR_PAGE_TITLE: 'REPR_PAGE_TITLE',
  REP_LABEL_LOCATIONLEVEL: 'REP_LABEL_LOCATIONLEVEL',
  REP_LABEL_LOCATIONID: 'REP_LABEL_LOCATIONID',
  REP_LABEL_REPSTARTDT: 'REP_LABEL_REPSTARTDT',
  REP_LABEL_REPENDDT: 'REP_LABEL_REPENDDT',
  REP_LABEL_TASK: 'REP_LABEL_TASK',
  REP_LABEL_REPTITLE: 'REP_LABEL_REPTITLE',
  REP_LABEL_PHYFILEPATH: 'REP_LABEL_PHYFILEPATH',
  REP_LABEL_FILESIZE: 'REP_LABEL_FILESIZE',
  /* REP_LABEL_AVAILABLEWEB: 'REP_LABEL_AVAILABLEWEB',
  REP_LABEL_REPPAGES: 'REP_LABEL_REPPAGES',
  REP_LABEL_DET1: 'REP_LABEL_DET1',
  REP_LABEL_DET2: 'REP_LABEL_DET2',
  REP_LABEL_DET3: 'REP_LABEL_DET3',
  REP_LABEL_DET4: 'REP_LABEL_DET4',
  REP_LABEL_DET5: 'REP_LABEL_DET5',
  REP_LABEL_DET6: 'REP_LABEL_DET6',
  REP_LABEL_DET7: 'REP_LABEL_DET7',
  REP_LABEL_DET8: 'REP_LABEL_DET8',
  REP_LABEL_DET9: 'REP_LABEL_DET9',
  REP_LABEL_DET10: 'REP_LABEL_DET10', */

  CSR_PAGE_TITLE: 'CSR_PAGE_TITLE',
  CSR_LABEL_NAME: 'CSR_LABEL_NAME',
  CSR_LABEL_TASK: 'CSR_LABEL_TASK',
  CSR_LABEL_REPORT_TITLE: 'CSR_LABEL_REPORT_TITLE',
  CSR_LABEL_PHYSICAL_FILE_PATH: 'CSR_LABEL_PHYSICAL_FILE_PATH',
  CSR_LABEL_FILE_SIZE: 'CSR_LABEL_FILE_SIZE',
  CSR_LABEL_DOCUMENT_ID: 'CSR_LABEL_DOCUMENT_ID',
  CSR_LABEL_DOWNLOAD_SELECTED: 'CSR_LABEL_DOWNLOAD_SELECTED',
  CSR_LABEL_PRINT: 'CSR_LABEL_PRINT',
  CSR_LABEL_REP_START_DT: 'CSR_LABEL_REP_START_DT',
  CSR_LABEL_REP_END_DT: 'CSR_LABEL_REP_END_DT',
  CSR_WARN_DISCLAIM: 'CSR_WARN_DISCLAIM',

  CSR_PAGE_TITLE_EXT: 'CSR_PAGE_TITLE_EXT',
  CSR_LABEL_SEARCH_OPTIONS: 'CSR_LABEL_SEARCH_OPTIONS',
  CSR_LABEL_QUICK_SEARCH: 'CSR_LABEL_QUICK_SEARCH',
  CSR_LABEL_ADV_SEARCH: 'CSR_LABEL_ADV_SEARCH',
  CSR_LABEL_VIEW: 'CSR_LABEL_VIEW',
  CSR_LABEL_ORG_LEVEL: 'CSR_LABEL_ORG_LEVEL',
  CSR_LABEL_DISP_LAST: 'CSR_LABEL_DISP_LAST',
  CSR_LABEL_DISP_REPORTS: 'CSR_LABEL_DISP_REPORTS',
  CSR_LABEL_CLEAR_RESULTS: 'CSR_LABEL_CLEAR_RESULTS',
  CSR_LABEL_LAST_OR_COMP_NAME: 'CSR_LABEL_LAST_OR_COMP_NAME',

  CSR_PAGE_TITLE_DASHBOARD: 'CSR_PAGE_TITLE_DASHBOARD',
  CSR_LABEL_VIEW_ALL: 'CSR_LABEL_VIEW_ALL',
  CSR_LABEL_SHOW_RECENT: 'CSR_LABEL_SHOW_RECENT',

  IAR_LABEL_IAR_TITLE: 'IAR_LABEL_IAR_TITLE',
  IAR_LABEL_SHOW_CURR_PERIOD: 'IAR_LABEL_SHOW_CURR_PERIOD',
  IAR_LABEL_CLIENT_NAME: 'IAR_LABEL_CLIENT_NAME',
  IAR_LABEL_ACTIVITY_PERIOD: 'IAR_LABEL_ACTIVITY_PERIOD',
  IAR_LABEL_EXPORT: 'IAR_LABEL_EXPORT',
  IAR_LABEL_COMP_TYPE: 'IAR_LABEL_COMP_TYPE',
  IAR_LABEL_ACTIVITY_PERIOD_WEEK: 'IAR_LABEL_ACTIVITY_PERIOD_WEEK',
  IAR_LABEL_COMPENSATION_TYPES: 'IAR_LABEL_COMPENSATION_TYPES',
  IAR_LABEL_MANULIFE_SEELING_CODES: 'IAR_LABEL_MANULIFE_SEELING_CODES',
  IAR_LABEL_TAB_CONTENT: 'IAR_LABEL_TAB_CONTENT',
  IAR_LABEL_PERIOD_AMOUNT: 'IAR_LABEL_PERIOD_AMOUNT',
  IAR_LABEL_ADJUSTED_MONTH_AMOUNT: 'IAR_LABEL_ADJUSTED_MONTH_AMOUNT',
  IAR_LABEL_YTD_AMOUNT: 'IAR_LABEL_YTD_AMOUNT',
  IAR_LABEL_SELLING_CODE_NAME: 'IAR_LABEL_SELLING_CODE_NAME',
  IAR_LABEL_FROM_TO_PRODUCER: 'IAR_LABEL_FROM_TO_PRODUCER',
  IAR_LABEL_PLAN_NAME: 'IAR_LABEL_PLAN_NAME',
  IAR_LABEL_TRANSACTION_TYPE: 'IAR_LABEL_TRANSACTION_TYPE',
  IAR_LABEL_COMP_BASE: 'IAR_LABEL_COMP_BASE',
  IAR_LABEL_SHARE_PER: 'IAR_LABEL_SHARE_PER',
  IAR_LABEL_RATE_PER: 'IAR_LABEL_RATE_PER',
  IAR_LABEL_COMP_AMOUNT: 'IAR_LABEL_COMP_AMOUNT',
  IAR_MSG_FOR_HEADING: 'IAR_MSG_FOR_HEADING',
  IAR_MSG_NO_RECORD_FOUND: 'COMMON_LABEL_NO_RECORD_FOUND',

  CMFD_TITLE_COMM_FEED: 'CMFD_TITLE_COMM_FEED',
  CMFD_LABEL_PRD_MNTH: 'CMFD_LABEL_PRD_MNTH',
  CMFD_LABEL_INCLD_COMM: 'CMFD_LABEL_INCLD_COMM',
  CMFD_LABEL_INCLD_APCNT: 'CMFD_LABEL_INCLD_APCNT',
  CMFD_LABEL_INCLD_OVRD: 'CMFD_LABEL_INCLD_OVRD',
  CMFD_LABEL_INCLD_OTHR: 'CMFD_LABEL_INCLD_OTHR',
  CMFD_LABEL_FILE_PATH: 'CMFD_LABEL_FILE_PATH',
  CMFD_BUTTON_GEN_REQ: 'CMFD_BUTTON_GEN_REQ',
  CMFD_LABEL_XML_MAP: 'CMFD_LABEL_XML_MAP',
  CF_SUCCESS: 'CF_SUCCESS',
  CF_FAIL: 'CF_FAIL',

  CMS_PAGE_TITLE: 'RBAC_CMS_MENU',
  CMS_PERIOD_ACTIVE: 'CMS_PERIOD_ACTIVE',
  CMS_PERIOD_CURRENT: 'CMS_PERIOD_CURRENT',
  CMS_GENERATE_REPORT: 'CMS_GENERATE_REPORT',

  COMMON_LABEL_MLC_WEALTH_CODE: 'COMMON_LABEL_MLC_WEALTH_CODE',
};
