/**
 * @Description Container for chip component
 * @FileName index.js
 * @Author SAMAPTI TALUKDAR-talusam
 * @CreatedOn 30 March, 2021 02:47:30
 * @IssueID 720
 */

import React from 'react';
import PropTypes from 'prop-types';
import close from './assets/images/close.svg';
import './scss/style.scss';
import {
  ButtonClose,
} from './styleComponent';

const Chip = ({ chipText, onClick, hasCloseBtn, isDefaultChip, ariaCloseBtnLabel }) => {
  /**
  * Constant values for classNames, placeholders, types
  */
  const chipContainer = 'chipContainer';
  const closeSelectionAlt = 'Remove Selection';
  const defaultChipClass = 'defaultChip';

  return (
    <div className={`${chipContainer} ${isDefaultChip ? defaultChipClass : ''}`} key={chipText}>
      <span>
        {chipText}
      </span>
      {hasCloseBtn && (
        <ButtonClose
          onClick={() => onClick()}
          aria-label={ariaCloseBtnLabel}
        >
          <img
            alt={closeSelectionAlt}
            src={close}
          />
        </ButtonClose>
      )}
    </div>
  );
};

Chip.propTypes = {
  chipText: PropTypes.string,
  onClick: PropTypes.func,
  hasCloseBtn: PropTypes.bool,
  isDefaultChip: PropTypes.bool,
  ariaCloseBtnLabel: PropTypes.string,
};

Chip.defaultProps = {
  chipText: '',
  onClick: () => undefined,
  hasCloseBtn: true,
  isDefaultChip: false,
  ariaCloseBtnLabel: '',
};

export default Chip;
