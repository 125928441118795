import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { rateParticipantsActionTypes } from '../actions/actionTypes';

export const FILTER_REDUCER_NAME = 'RateParticipantsList';

const updateFilterConfig = (state, action) => {
  const selectedFilter = (action.data && action.data.selectedItem) || null;
  if (action.data) {
    if (action.data.systemDefault) {
      return {
        ...state,
        selectedFilter,
        systemDefaultFilter: action.data.systemDefault || null,
        userDefaultFilter: action.data.userDefault || null,
      };
    }
    if (action.data.userDefault === null || action.data.userDefault) {
      return {
        ...state,
        selectedFilter,
        userDefaultFilter: action.data.userDefault,
      };
    }
  }
  return {
    ...state,
    selectedFilter,
  };
};

const updateFilterOption = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  selectedFilter: action.data ? action.data : null,
});

const logListRequest = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
  upsertRequest: null,
  upsertRequestError: null,
  upsertData: null,
  listRequest: null,
  deleteRequest: null,

});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const RateParticipantsList1 = (state, action = null) => {
  switch (action.type) {
    case rateParticipantsActionTypes.UPDATE_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case rateParticipantsActionTypes.REMOVE_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    case rateParticipantsActionTypes.UPDATE_TABLE_CONFIGURATION:
      return updateFilterOption(state, action);
    case rateParticipantsActionTypes.LOG_LIST_REQUEST:
      return logListRequest(state, action);
    case rateParticipantsActionTypes.GET_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case rateParticipantsActionTypes.GET_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case rateParticipantsActionTypes.GET_LIST_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case rateParticipantsActionTypes.RESET_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        getEditItemDetails: null,
        loading: false,
      };
    default:
      return null;
  }
};
const RateParticipantsList2 = (state, action = null) => {
  switch (action.type) {
    case rateParticipantsActionTypes.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case rateParticipantsActionTypes.RESET_UPSERT_ERROR:
      return {
        ...state,
        upsertRequestError: [],
      };
    case rateParticipantsActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case rateParticipantsActionTypes.RESET_DELETE_VALUE:
      return {
        ...state,
        deleteRequest: action.data.deleteRequest,
        deleteRequestError: action.data.deleteRequestError,
        deleteRequestedOn: action.data.deleteRequestedOn,
        deleteRequestProcessedOn: action.data.deleteRequestProcessedOn,
        deleteData: action.data.deleteData,
      };
    case rateParticipantsActionTypes.GET_RATEPARTICIPANT_ONLOAD_RESPONSE:
      return {
        ...state,
        onloadData: action.data.onloadData,
      };
    case rateParticipantsActionTypes.GET_RATEPARTICIPANT_ONLOAD_RESPONSE_ERROR:
      return {
        ...state,
        onloadDataErr: action.data.onParticipantsLoad,
      };
    case rateParticipantsActionTypes.GET_RATEPARTICIPANT_ONLOADSYS_RESPONSE:
      return {
        ...state,
        onloadSysData: action.data.onloadData,
      };
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const RateParticipantsList = (state = { ...basicFilterInitialState }, action = null) => {
  const rateMasterDetail1 = RateParticipantsList1(state, action);
  if (rateMasterDetail1 !== null) {
    return rateMasterDetail1;
  }
  const rateMasterDetail2 = RateParticipantsList2(state, action);
  if (rateMasterDetail2 !== null) {
    return rateMasterDetail2;
  }
  return state;
};

export default RateParticipantsList;
