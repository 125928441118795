/* eslint-disable import/prefer-default-export */
export const codesAndHierarchyActionTypes = {
  LOG_CODES_AND_HIER_TREE_UPSTREAM_REQUEST: 'LOG_CODES_AND_HIER_TREE_UPSTREAM_REQUEST',
  GET_CODES_AND_HIER_TREE_UPSTREAM_REQUEST: 'GET_CODES_AND_HIER_TREE_UPSTREAM_REQUEST',
  GET_CODES_AND_HIER_TREE_UPSTREAM_RESPONSE: 'GET_CODES_AND_HIER_TREE_UPSTREAM_RESPONSE',

  CODE_AND_HIER_LIST_DOWNSTREAM_REQUEST: 'CODE_AND_HIER_LIST_DOWNSTREAM_REQUEST',

  LOG_CODES_AND_HIER_LIST_REQUEST: 'LOG_CODES_AND_HIER_LIST_REQUEST',
  GET_CODES_AND_HIER_LIST_REQUEST: 'GET_CODES_AND_HIER_LIST_REQUEST',
  GET_CODES_AND_HIER_LIST_RESPONSE: 'GET_CODES_AND_HIER_LIST_RESPONSE',

  GET_CODES_AND_HIER_MOVEMENT_REQUEST: 'GET_CODES_AND_HIER_MOVEMENT_REQUEST',
  GET_CODES_AND_HIER_MOVEMENT_RESPONSE: 'GET_CODES_AND_HIER_MOVEMENT_RESPONSE',
  EXPORT_CODES_AND_HIER_REQUEST: 'EXPORT_CODES_AND_HIER_REQUEST',

  GET_LEVEL_RBAC_DATA_REQUEST: 'GET_LEVEL_RBAC_DATA_REQUEST',
  GET_LEVEL_RBAC_DATA_RESPONSE: 'GET_LEVEL_RBAC_DATA_RESPONSE',

  // Get code nickname
  GET_CODE_NICKNAME_REQUEST: 'GET_CODE_NICKNAME_REQUEST',
  GET_CODE_NICKNAME_RESPONSE: 'GET_CODE_NICKNAME_RESPONSE',
  // Update code nick name
  UPDATE_CODE_NICKNAME_REQUEST: 'UPDATE_CODE_NICKNAME_REQUEST',
  UPDATE_CODE_NICKNAME_RESPONSE: 'UPDATE_CODE_NICKNAME_RESPONSE',

  // Org security data
  GET_ORG_SECURITY_DATA_REQUEST: 'GET_ORG_SECURITY_DATA_REQUEST',
  GET_ORG_SECURITY_DATA_RESPONSE: 'GET_ORG_SECURITY_DATA_RESPONSE',

  RESET_SERVER_VALUE: 'RESET_CODES_AND_HIER_SERVER_VALUE',
};

export const advisorDetailsActions = {
  GET_EXT_ADVISOR_DETAILS: 'GET_EXT_ADVISOR_DETAILS',
  GET_GENERAL_DETAIL_RESPONSE: 'GET_GENERAL_DETAIL_RESPONSE',
  GET_BANKING_DETAIL_RESPONSE: 'GET_BANKING_DETAIL_RESPONSE',
  GET_ADVISOR_DETAILS_ERROR: 'GET_ADVISOR_DETAILS_ERROR',
  GET_EXT_ADVISOR_REQUIREMENT_LIST: 'GET_EXT_ADVISOR_REQUIREMENT_LIST',
  GET_EXT_REQUIREMENT_LIST_RESPONSE: 'GET_EXT_REQUIREMENT_LIST_RESPONSE',
  GET_EXT_REQUIREMENT_LIST_ERROR: 'GET_EXT_REQUIREMENT_LIST_ERROR',
  GET_FF_REQUIREMENT_TABLE_RES: 'GET_FF_REQUIREMENT_TABLE_RES',
  GET_FF_DETAIL_ERROR: 'GET_FF_DETAIL_ERROR',
  EXT_REQUIREMENT_LIST_EXPORT: 'EXT_REQUIREMENT_LIST_EXPORT',
  RESET_SERVER_VALUE: 'RESET_EXT_ADVISOR_SERVER_VALUE',
  GET_EXT_REQUIREMENT_EXPORT_ERROR: 'GET_EXT_REQUIREMENT_EXPORT_ERROR',
};

export const debitBalanceActionTypes = {
  GET_CH_DEBIT_BALANCE_LIST: 'GET_CH_DEBIT_BALANCE_LIST',
  GET_CH_DEBIT_BALANCE_LIST_RESPONSE: 'GET_CH_DEBIT_BALANCE_LIST_RESPONSE',
  EXORT_CH_DEBIT_BALANCE_LIST: 'EXORT_CH_DEBIT_BALANCE_LIST',
  RESET_SERVER_VALUE: 'RESET_SERVER_DEBIT_BALANCE_VALUE',
  RESET_CH_DBEXPORT_SERVER_VALUE: 'RESET_CH_DBEXPORT_SERVER_VALUE',
};

export const topProducersActionTypes = {
  GET_EXT_TOP_PRODUCERS_LIST: 'GET_EXT_TOP_PRODUCERS_LIST',
  EXORT_EXT_TOP_PRODUCERS: 'EXORT_EXT_TOP_PRODUCERS',
  RESET_EXT_TP_EXPORT_ERROS: 'RESET_EXT_TP_EXPORT_ERROS',
  GET_EXT_TOP_PRODUCERS_LIST_RES: 'GET_EXT_TOP_PRODUCERS_LIST_RES',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',

  // GET_CH_DEBIT_BALANCE_LIST_RESPONSE: 'GET_CH_DEBIT_BALANCE_LIST_RESPONSE',
  // EXORT_CH_DEBIT_BALANCE_LIST: 'EXORT_CH_DEBIT_BALANCE_LIST',
  // RESET_SERVER_VALUE: 'RESET_SERVER_DEBIT_BALANCE_VALUE',
  // RESET_CH_DBEXPORT_SERVER_VALUE: 'RESET_CH_DBEXPORT_SERVER_VALUE',
};

export const chDetailsActionTypes = {
  GET_CS_HEADER_REQUEST: 'GET_CS_HEADER_REQUEST',
  GET_EXTERNAL_CH_DETAIL_ACTIVITY: 'GET_EXTERNAL_CH_DETAIL_ACTIVITY',
  EXT_DETAIL_ACTIVITY_EXPORT: 'EXT_DETAIL_ACTIVITY_EXPORT',
  GET_EXT_REQUIREMENT_EXPORT_ERROR: 'GET_EXT_REQUIREMENT_EXPORT_ERROR',
  SET_EXTERNAL_CH_DETAIL_ACTIVITY_LIST: 'SET_EXTERNAL_CH_DETAIL_ACTIVITY_LIST',
  GET_CH_ADVISOR_DETAILS_FLAG: 'GET_CH_ADVISOR_DETAILS_FLAG',
  GET_CH_ADVISOR_DETAILS_FLAG_ERROR: 'GET_CH_ADVISOR_DETAILS_FLAG_ERROR',
  GET_CH_ADVISOR_DETAILS_FLAG_RES: 'GET_CH_ADVISOR_DETAILS_FLAG_RES',
};

export const msActivityActionTypes = {
  GET_EXTERNAL_MS_ACTIVITY: 'GET_EXTERNAL_MS_ACTIVITY',
  SET_EXTERNAL_MS_ACTIVITY_LIST: 'SET_EXTERNAL_MS_ACTIVITY_LIST',
  GET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY: 'GET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY',
  SET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY_LIST: 'SET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY_LIST',
  RESET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY_LIST: 'RESET_EXTERNAL_FIRST_LEVEL_MS_ACTIVITY_LIST',
  GET_EXTERNAL_ALL_LEVEL_MS_ACTIVITY: 'GET_EXTERNAL_ALL_LEVEL_MS_ACTIVITY',
  SET_EXTERNAL_ALL_LEVEL_MS_ACTIVITY_LIST: 'SET_EXTERNAL_ALL_LEVEL_MS_ACTIVITY_LIST',
  EXPORT_MS_ACTIVITY_REQUEST: 'EXPORT_MS_ACTIVITY_REQUEST',
  RESET_SERVER_VALUE: 'RESET_SERVER_VALUE',
};
