import { basicListAddEditDltInitialState } from '../../../../../stateManagement/reduxUtil';
import { commissionFeedActionTypes } from '../actions/actionTypes';

const CommissionFeed = (state = { ...basicListAddEditDltInitialState }, action = null) => {
  switch (action.type) {
    // Configure Page Details
    case commissionFeedActionTypes.RESET_UPSERT_VALUE:
      return {
        ...state,
        upsertRequest: action.data.upsertRequest,
        upsertRequestError: action.data.upsertRequestError,
        upsertRequestedOn: action.data.upsertRequestedOn,
        upsertRequestProcessedOn: action.data.upsertRequestProcessedOn,
        upsertData: action.data.upsertData,
        loading: false,
      };
    case commissionFeedActionTypes.GET_ONCHANGE_DETAILS_RESPONSE:
      return {
        ...state,
        onChangeData: action.data.onChangeData,
      };
    case commissionFeedActionTypes.GET_GENERATE_RESPONSE:
      return {
        ...state,
        generateAction: action.data,
      };
    case commissionFeedActionTypes.RESET_SERVER_VALUE:
      return {
        ...state,
        serverRequestError: action.data && action.data.upsertRequestError,

      };
    case commissionFeedActionTypes.RESET_STORE: {
      return {
        ...state,
        upsertData: null,
        addressDetail: null,
        upsertRequest: null,
        upsertRequestError: null,
        upsertRequestedOn: null,
        upsertRequestProcessedOn: null,
      };
    }
    default:
      return state;
  }
};

export default CommissionFeed;
