import { basicFilterInitialState } from '../../../../../stateManagement/reduxUtil';
import { commonTypes } from '../../../../common/redux/actions/commonActions';
import { activityProcessedExtActionType } from '../actions/actionTypes';

export const REDUCER_NAME = 'ActivityProcessedFilterListExt';

const updateFilterConfig = (state, action) => {
  if (action.data.systemDefault) {
    return {
      ...state,
      systemDefaultFilter: action.data.systemDefault || null,
      userDefaultFilter: action.data.userDefault || null,
      selectedFilter: action.data.selectedItem || null,
    };
  }
  if (action.data.userDefault === null || action.data.userDefault) {
    return {
      ...state,
      selectedFilter: action.data.selectedItem || null,
      userDefaultFilter: action.data.userDefault,
    };
  }
  return {
    ...state,
    selectedFilter: action.data.selectedItem || null,
  };
};

const logAPListReq = (state, action) => ({
  ...state,
  listRequest: action.data ? action.data : state.listRequest,
  listRequestedOn: new Date(),
  loading: true,
});

const getEditItemDetailsResponse = (state, action) => ({
  ...state,
  getEditItemDetails: action.data,
});

const updateTableConfig = (state, action) => ({
  ...state,
  listParamOptions: action.data ? action.data.detail.parameters : null,
  listGroupBy: action.data ? action.data.detail.groupByParameters : null,
  listSortOptions: action.data ? action.data.detail.sortOptions : null,
  listColumnOrder: action.data ? action.data.detail.columnOrders : null,
  isDistinctActive: action.data ? action.data.detail.isDistinct : null,
  selectedFilter: action.data ? action.data : null,
});

const resetServerValue = (state, action) => ({
  ...state,
  serverRequestError: action.data && action.data.serverRequestError,
  loading: false,
});

const ActivityProcessedFilterListConfig = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // config list column configuration
    case activityProcessedExtActionType.UPDATE_AP_FILTER_CONFIG:
      return updateFilterConfig(state, action);
    case activityProcessedExtActionType.UPDATE_TABLE_CONFIGURATION:
      return updateTableConfig(state, action);
    case activityProcessedExtActionType.RESET_PARAM_STOR:
      return {
        ...state,
        listParamOptions: null,
        listGroupBy: [],
        listSortOptions: [],
        listColumnOrder: [],
        isDistinctActive: [],
        selectedFilter: null,
        listData: null,
      };

    case activityProcessedExtActionType.REMOVE_AP_USER_DEFAULT_FILTER:
      return {
        ...state,
        userDefaultFilter: null,
      };
    default:
      return null;
  }
};

const ActivityProcessedFilterListREQRES = (state = { ...basicFilterInitialState }, action = null) => {
  switch (action.type) {
    // log request
    case activityProcessedExtActionType.LOG_AP_LIST_REQUEST:
      return logAPListReq(state, action);
    // log error
    case activityProcessedExtActionType.GET_AP_LIST_ERROR:
      return {
        ...state,
        listRequestError: action.data,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    // store response
    case activityProcessedExtActionType.GET_AP_LIST_EXT_RESPONSE:
      return {
        ...state,
        listData: action.data,
        listRequestError: null,
        listRequestProcessedOn: new Date(),
        loading: false,
      };
    case activityProcessedExtActionType.GET_AP_BY_ID_RESPONSE:
      return getEditItemDetailsResponse(state, action);
    case activityProcessedExtActionType.RESET_AP_STORE:
      return {
        ...state,
        listData: null,
        listRequestError: null,
        listRequestProcessedOn: null,
        serverRequestError: null,
        loading: false,
      };
    case activityProcessedExtActionType.RESET_AP_LOOKUPDATA:
      return {
        ...state,
        pageLevelLookupData: action.data,
      };
    case activityProcessedExtActionType.RESET_SERVER_VALUE:
      return resetServerValue(state, action);
    case commonTypes.RESETSTORE:
      return basicFilterInitialState;
    default:
      return null;
  }
};
const ActivityProcessedFilterListExt = (state = { ...basicFilterInitialState }, action = null) => {
  const stateConfig = ActivityProcessedFilterListConfig(state, action);
  const stateReqRes = ActivityProcessedFilterListREQRES(state, action);
  if (stateConfig !== null) {
    return stateConfig;
  }
  if (stateReqRes !== null) {
    return stateReqRes;
  }
  return state;
};

export default ActivityProcessedFilterListExt;
